// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Bill} from '@supermove/models';
import {fontWeight, colors, typography} from '@supermove/styles';

// Components
import BillPaymentItem from './BillPaymentItem';

const Container = Styled.View`
  align-self: stretch;
`;

const Header = Styled.View`
  align-self: stretch;
  flex-direction: row;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  background-color: ${colors.gray.background};
`;

const Cell = Styled.View`
  width: 120px;
`;

const Text = Styled.H6`
  ${({vars}) => (vars.isCompact ? typography.h7() : '')}
  color: ${colors.gray.secondary};
  ${fontWeight(500)}
`;

const BillPaymentsHeader = ({isCompact, isEditable}) => {
  return (
    <React.Fragment>
      <Header>
        <Cell
          style={{
            flex: 1,
            minWidth: 140,
          }}
        >
          <Text vars={{isCompact}}>Payment Name</Text>
        </Cell>
        <Cell
          style={{
            width: 140,
          }}
        >
          <Text vars={{isCompact}}>Method</Text>
        </Cell>
        <Cell
          style={{
            alignItems: 'flex-end',
          }}
        >
          <Text vars={{isCompact}}>Amount</Text>
        </Cell>
        {isEditable && (
          <Cell
            style={{
              width: 40,
            }}
          />
        )}
      </Header>
      <Space height={10} />
    </React.Fragment>
  );
};

const Footer = Styled.View`
  align-self: stretch;
`;

const DashedLine = Styled.View`
  border-top-width: 1px;
  border-top-style: dashed;
  border-top-color: ${colors.gray.tertiary};
`;

const Item = Styled.View`
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding-horizontal: 10px;
`;

const Total = Styled.H6`
  ${({vars}) => (vars.isCompact ? typography.h7() : '')}
  ${fontWeight(700)}
`;

const Description = Styled.H7`
  ${({vars}) => (vars.isCompact ? typography.h8() : '')}
  color: ${colors.gray.tertiary};
`;

const BillPaymentsFooter = ({isCompact, bill, hideTotalBalanceDue}) => {
  const showBalanceWithoutTip = bill.tip > 0 && bill.estimateBalanceMin - bill.tip >= 0;

  if (hideTotalBalanceDue) {
    return null;
  }

  return (
    <Footer>
      <Space height={10} />
      <DashedLine />
      <Space height={10} />
      <Item>
        <Cell
          style={{
            alignItems: 'flex-start',
            width: 200,
          }}
        >
          <Total vars={{isCompact}}>Total Balance Due</Total>
        </Cell>
        <Cell
          style={{
            flex: 1,
            alignItems: 'flex-end',
          }}
        >
          <Total vars={{isCompact}}>
            {bill.isEstimateAvailable ? Bill.getEstimateBalance(bill) : 'TBD'}
          </Total>
          {showBalanceWithoutTip && (
            <Description vars={{isCompact}}>
              {`(${bill.isEstimateAvailable ? Bill.getEstimateBalanceWithoutTip(bill) : 'TBD'} ` +
                `without tip)`}
            </Description>
          )}
        </Cell>
      </Item>
    </Footer>
  );
};

const FlatListCell = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const FlatListSeparator = Styled.View`
  height: 10px;
`;

const EmptyContainer = Styled.View`
  padding-horizontal: 12px;
`;

const EmptyMessage = Styled.H7`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const PaymentItem = Styled.View`
`;

const BillPaymentsList = ({isCompact, isEditable, bill, refetch, style, hideTotalBalanceDue}) => {
  return (
    <Container style={style}>
      <FlatList
        data={bill.payments.filter((payment) => payment.isPaid)}
        extraData={bill}
        keyExtractor={(payment) => payment.id}
        renderItem={({item: payment}) => (
          <PaymentItem key={payment.id}>
            <BillPaymentItem
              isCompact={isCompact}
              isEditable={isEditable}
              payment={payment}
              refetch={refetch}
            />
          </PaymentItem>
        )}
        CellRendererComponent={FlatListCell}
        ItemSeparatorComponent={FlatListSeparator}
        ListHeaderComponent={() => (
          <BillPaymentsHeader isCompact={isCompact} isEditable={isEditable} />
        )}
        ListFooterComponent={() => (
          <BillPaymentsFooter
            isCompact={isCompact}
            bill={bill}
            hideTotalBalanceDue={hideTotalBalanceDue}
          />
        )}
        ListEmptyComponent={() => (
          <EmptyContainer>
            <EmptyMessage>No Payments</EmptyMessage>
          </EmptyContainer>
        )}
      />
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
BillPaymentsList.propTypes = {
  bill: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  refetch: PropTypes.func,
  hideTotalBalanceDue: PropTypes.bool,
};

BillPaymentsList.defaultProps = {
  isEditable: false,
  refetch: () => {},
  hideTotalBalanceDue: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillPaymentsList.fragment = gql`
  ${Bill.getEstimateBalance.fragment}
  ${Bill.getEstimateBalanceWithoutTip.fragment}
  ${BillPaymentItem.fragment}

  fragment BillPaymentsList on Bill {
    id
    isEstimateAvailable
    estimateBalanceMin
    payments {
      id
      isPaid
      ...BillPaymentItem
    }
    ...Bill_getEstimateBalance
    ...Bill_getEstimateBalanceWithoutTip
  }
`;

export default BillPaymentsList;
