// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useDeleteTruckMutation = ({truckId, onSuccess, onError}) => {
  const form = useForm({
    enableReinitialize: true,
    initialValues: {
      truckId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteTruckMutation.mutation,
    variables: {
      truckId: form.values.truckId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteTruckMutation.mutation = gql`
  mutation DeleteTruckMutation($truckId: Int!) {
    response: deleteTruck(truckId: $truckId) {
      ${gql.errors}
      truck {
        id
      }
    }
  }
`;

export default useDeleteTruckMutation;
