// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useDeleteCommercialEquipmentMutation = ({commercialEquipmentId, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      commercialEquipmentId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteCommercialEquipmentMutation.mutation,
    variables: {
      commercialEquipmentId: form.values.commercialEquipmentId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteCommercialEquipmentMutation.mutation = gql`
  mutation useDeleteCommercialEquipmentMutation($commercialEquipmentId: Int!) {
    response: deleteCommercialEquipment(commercialEquipmentId: $commercialEquipmentId) {
      ${gql.errors}
    }
  }
`;

export default useDeleteCommercialEquipmentMutation;
