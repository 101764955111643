const CUSTOM = 'CUSTOM';

// Workflow System Kind
const CUSTOMER_UPCOMING_PROJECT = 'CUSTOMER_UPCOMING_PROJECT';

const WorkflowKind = {
  CUSTOM,
  CUSTOMER_UPCOMING_PROJECT,
};

export default WorkflowKind;
