const SALESPERSON = 'SALESPERSON';
const COORDINATOR = 'COORDINATOR';

const ROLE_TO_LABEL = {
  [SALESPERSON]: '{PROJECT_SALESPERSON}',
  [COORDINATOR]: '{PROJECT_COORDINATOR}',
};

const ROLE_TO_FORM_PROPERTY = {
  [SALESPERSON]: 'projectBookedById',
  [COORDINATOR]: 'projectCoordinatedById',
};

const DROPDOWN_OPTIONS = [
  {
    value: SALESPERSON,
    label: ROLE_TO_LABEL[SALESPERSON],
  },
  {
    value: COORDINATOR,
    label: ROLE_TO_LABEL[COORDINATOR],
  },
];

const getDisplayRole = (assignedToRole) => {
  return ROLE_TO_LABEL[assignedToRole];
};

const getFormPropertyForUserId = (assignedToRole) => {
  return ROLE_TO_FORM_PROPERTY[assignedToRole];
};

export default {
  SALESPERSON,
  COORDINATOR,

  DROPDOWN_OPTIONS,

  getDisplayRole,
  getFormPropertyForUserId,
};
