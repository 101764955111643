// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Link, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Percent} from '@supermove/utils';

// App
import Panel from '@shared/design/components/Panel';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import SalesTaxTemplateForm from '@shared/modules/Billing/forms/SalesTaxTemplateForm';
import TextInputWithDropdown from 'modules/App/components/TextInputWithDropdown';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Text = Styled.Text`
  ${Typography.Body};
  color: ${colors.gray.secondary};
`;

const LinkText = Styled.Text`
  ${Typography.MicroLink}
`;

const ViewCell = ({label, placeholder, value}) => {
  return (
    <Row>
      <Column>
        <EditPanel.LabelText>{label}</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text style={{color: value ? colors.black : colors.gray.tertiary}}>
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const ProjectTypeSalesTaxTemplatePanelHeader = ({projectTypeName, isEditing, handleEdit}) => {
  return (
    <Panel.Header style={{alignItems: 'center'}}>
      <Column>
        <Row>
          <Panel.HeaderText>Sales Tax</Panel.HeaderText>
          <Space style={{flex: 1, minWidth: 12}} />
          <EditPanel.EditButton isEditing={isEditing} handleEdit={handleEdit} />
        </Row>
        <Space height={8} />
        <Panel.HeaderDescription>
          {`This default sales tax applies to all taxable bill items in new ${projectTypeName} projects, and will default to the `}
          <Link to={'/settings/company/accounting'}>
            <LinkText>{`organization's default sales tax rate`}</LinkText>
          </Link>
          {` when not overridden.`}
        </Panel.HeaderDescription>
      </Column>
    </Panel.Header>
  );
};

const ProjectTypeSalesTaxTemplatePanelEdit = ({
  form,
  field,
  variableOptions,
  lastUpdatedDisplay,
  projectTypeId,
  settingsSalesTaxTemplate,
}) => {
  const isSettingsSalesTaxTemplate = _.get(form.values, `${field}.settingsId`);
  return (
    <React.Fragment>
      <Row>
        <Column>
          <TextInputWithDropdown
            disabled={isSettingsSalesTaxTemplate}
            form={form}
            textValueField={`${field}.rate`}
            clearTextValue={''}
            dropdownValueField={`${field}.variableId`}
            label={'Default Sales Tax Rate'}
            dropdownOptions={variableOptions}
            placeholder={'Enter a percentage or select a variable'}
            onBlur={() => {
              const rateField = `${field}.rate`;
              if (_.get(form.values, `${field}.variableId`)) {
                form.setFieldValue(rateField, '');
              } else {
                form.setFieldValue(
                  rateField,
                  Percent.toForm(Percent.toFloat(_.get(form.values, rateField))),
                );
              }
            }}
            noOptionsMessage={'No variable options'}
            action={
              <React.Fragment>
                <Icon
                  source={isSettingsSalesTaxTemplate ? Icon.LinkSlash : Icon.ArrowRotateRight}
                  size={12}
                  color={colors.blue.interactive}
                />
                <Space width={8} />
              </React.Fragment>
            }
            actionText={isSettingsSalesTaxTemplate ? 'Override' : 'Restore to Default'}
            handleAction={() => {
              if (isSettingsSalesTaxTemplate) {
                form.setFieldValue(
                  field,
                  SalesTaxTemplateForm.toForm(SalesTaxTemplateForm.new({projectTypeId})),
                );
              } else {
                // Restore to settings sales tax template
                // projectTypeId is set on the form so that existing project type sales tax templates can be queried and deleted
                form.setFieldValue(
                  field,
                  SalesTaxTemplateForm.toForm(
                    SalesTaxTemplateForm.edit(settingsSalesTaxTemplate, {projectTypeId}),
                  ),
                );
              }
            }}
          />
          <Space height={8} />
          <Text>{`Last Updated: ${lastUpdatedDisplay}`}</Text>
        </Column>
        <Space width={24} />
        <Column />
      </Row>
    </React.Fragment>
  );
};

const ProjectTypeSalesTaxTemplatePanel = ({salesTaxTemplate}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <ViewCell
            label={'Default Sales Tax Rate'}
            placeholder={'Add a sales tax rate'}
            value={`${salesTaxTemplate.variable?.name || Percent.display(salesTaxTemplate.rate)}${
              salesTaxTemplate.settingsId ? ' (rate set in organization settings)' : ''
            }`}
          />
        </Column>
        <Space width={24} />
        <Column />
      </Row>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTypeSalesTaxTemplatePanel.fragment = gql`
  fragment ProjectTypeSalesTaxTemplatePanel on SalesTaxTemplate {
    id
    rate
    variable {
      id
      name
    }
    settingsId
  }
  fragment ProjectTypeSalesTaxTemplatePanel_SettingsSalesTaxTemplate on SalesTaxTemplate {
    id
    rate
    variableId
    settingsId
  }
`;

ProjectTypeSalesTaxTemplatePanel.Edit = ProjectTypeSalesTaxTemplatePanelEdit;
ProjectTypeSalesTaxTemplatePanel.Header = ProjectTypeSalesTaxTemplatePanelHeader;

export default ProjectTypeSalesTaxTemplatePanel;
