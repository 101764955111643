// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import AssignCollectionsToContainerForm from '@shared/modules/Storage/forms/AssignCollectionsToContainerForm';

const useAssignCollectionsToContainerMutation = ({
  assignCollectionsToContainerForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      assignCollectionsToContainerForm: AssignCollectionsToContainerForm.toForm(
        assignCollectionsToContainerForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useAssignCollectionsToContainerMutation.mutation,
    variables: {
      assignCollectionsToContainerForm: AssignCollectionsToContainerForm.toMutation(
        form.values.assignCollectionsToContainerForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useAssignCollectionsToContainerMutation.mutation = gql`
  mutation AssignCollectionsToContainerMutation($assignCollectionsToContainerForm: AssignCollectionsToContainerForm!) {
    response: assignCollectionsToContainer(assignCollectionsToContainerForm: $assignCollectionsToContainerForm) {
      ${gql.errors}
      container {
        id
      }
    }
  }
`;

export default useAssignCollectionsToContainerMutation;
