// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CommentForm from '@shared/modules/Comment/forms/CommentForm';
import JobCommentsBlock from 'modules/Job/components/JobCommentsBlock';
import ProjectsList from 'modules/Project/components/ProjectsList';

const useCreateCommentMutation = ({commentForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      commentForm: CommentForm.toForm(commentForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateCommentMutation.mutation,
    variables: {
      commentForm: CommentForm.toMutation(form.values.commentForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// NOTE(cooper): Fragments imported here ensure that data needed on these components
// gets refreshed without needing to manually refetch other queries
// --------------------------------------------------
useCreateCommentMutation.mutation = gql`
  ${ProjectsList.threadFragment}
  ${JobCommentsBlock.threadFragment}

  mutation useCreateCommentMutation($commentForm: CommentForm!) {
    response: createComment(commentForm: $commentForm) {
      ${gql.errors}
      comment {
        id
        thread {
          id
          ...ProjectsList_threadFragment
          ...JobCommentsBlock_threadFragment
        }
      }
    }
  }
`;

export default useCreateCommentMutation;
