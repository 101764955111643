// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';

const DropdownSelectedBadgeContainer = Styled.View`
  padding-vertical: 4px;
  padding-horizontal: 8px;
  background-color: ${colors.gray.border};
  border-radius: 16px;
  position: absolute;
`;

const DropdownSelectedBadgeText = Styled.Text`
  ${Typography.Label4}
  color: ${colors.gray.primary};
`;

const SelectedValue = ({children}) => {
  return (
    <DropdownSelectedBadgeContainer>
      <DropdownSelectedBadgeText>{children}</DropdownSelectedBadgeText>
    </DropdownSelectedBadgeContainer>
  );
};

const TextInputWithDropdown = ({
  form,
  textValueField,
  clearTextValue,
  dropdownValueField,
  label,
  index,
  dropdownOptions,
  placeholder,
  noOptionsMessage,
  onBlur,
  disabled,
  isRequired,
  isPortaled,
  action,
  actionText,
  handleAction,
  isResponsive,
}) => {
  const dropdownValue = _.get(form.values, dropdownValueField);
  const textValue = _.get(form.values, textValueField, '');
  return (
    <FieldInput
      {...form}
      index={index}
      label={label}
      name={dropdownValueField}
      errorName={textValueField}
      component={DropdownInput}
      action={action}
      actionText={actionText}
      handleAction={handleAction}
      isResponsive={isResponsive}
      input={{
        options: dropdownOptions,
        placeholder,
        noOptionsMessage: noOptionsMessage ? () => noOptionsMessage : null,
        inputValue: !dropdownValue ? textValue : undefined,
        setFieldValue: form.setFieldValue,
        disabled,
        required:
          isRequired &&
          !_.get(form.values, textValueField) &&
          !_.get(form.values, dropdownValueField),
        isSearchable: true,
        isClearable: true,
        isPortaled,
        style: {flex: 1, maxWidth: '100%'},
        onBlur,
        onInputChange: (text, {action}) => {
          if (action === 'input-change') {
            form.setFieldValue(textValueField, text);
            form.setFieldValue(dropdownValueField, null);
          }
        },
        onChangeValue: (value, option) => {
          // This handler gets executed when an option is selected as well as when the input
          // is cleared with the clear button.
          form.setFieldValue(textValueField, clearTextValue || _.get(option, 'label') || '');
          form.setFieldValue(dropdownValueField, value);
        },
        components: {
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          SingleValue: SelectedValue,
        },
      }}
      style={{flex: 1}}
    />
  );
};

export default TextInputWithDropdown;
