// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ConfirmationStepForm from '@shared/modules/Proposal/forms/ConfirmationStepForm';

const useEditConfirmationStepMutation = ({confirmationStepForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      confirmationStepForm: ConfirmationStepForm.toForm(confirmationStepForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useEditConfirmationStepMutation.mutation,
    variables: {
      confirmationStepForm: ConfirmationStepForm.toMutation(form.values.confirmationStepForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useEditConfirmationStepMutation.mutation = gql`
  mutation useEditConfirmationStepMutation($confirmationStepForm: ConfirmationStepForm!) {
    response: editConfirmationStep(confirmationStepForm: $confirmationStepForm) {
      ${gql.errors}
      confirmationSteps {
        id
      }
    }
  }
`;

export default useEditConfirmationStepMutation;
