const _delete = ({jobId, locationId}) => ({
  jobId,
  locationId,
});

const toForm = ({jobId, locationId}) => ({
  jobId,
  locationId,
});

const toMutation = ({jobId, locationId}) => ({
  jobId,
  locationId,
});

const JobLocationForm = {
  delete: _delete,
  toForm,
  toMutation,
};

export default JobLocationForm;
