// Libraries
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

const Wrapper = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const SignatureContainer = Styled.View`
`;

const DateContainer = Styled.View`
  width: 100px;
`;

const Divider = Styled.View`
  width: 100%;
  margin-vertical: 5px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.blue.accent};
`;

const Label = Styled.Text`
  ${Typography.Label3}
`;

const Value = Styled.View`
  justify-content: flex-end;
  height: 50px;
`;

const SignatureText = Styled.Text`
  ${Typography.PageHeading}
  font-family: 'Rouge Script';
  line-height: 32px;
`;

const DateText = Styled.Text`
  ${Typography.Body3}
`;

const DocumentV2OrganizationSignatureContent = ({index, organization}) => {
  return (
    <Wrapper index={index}>
      <Row data-avoid-page-break>
        <SignatureContainer
          style={{
            flex: 1,
            paddingRight: 40,
          }}
        >
          <Value>
            <SignatureText>{organization.businessSignature}</SignatureText>
          </Value>
          <Divider />
          <Label>{organization.businessSignature}</Label>
        </SignatureContainer>
        <DateContainer>
          <Value>
            <DateText>
              {Datetime.convertToDisplayDate(Datetime.today, Datetime.DISPLAY_SHORT_DATE)}
            </DateText>
          </Value>
          <Divider />
          <Label>Date</Label>
        </DateContainer>
        <Space height={20} />
      </Row>
      <Space height={10} />
    </Wrapper>
  );
};

const DocumentV2OrganizationSignaturePreview = ({index}) => {
  const project = MOCK_PROJECT_DATA;
  return (
    <DocumentV2OrganizationSignatureContent organization={project.organization} index={index} />
  );
};

const DocumentV2OrganizationSignatureQueried = ({index, project}) => {
  return (
    <DocumentV2OrganizationSignatureContent organization={project.organization} index={index} />
  );
};

const ProjectDocumentV2OrganizationSignature = ({project, index, isPreview}) => {
  return (
    <React.Fragment>
      {isPreview ? (
        <DocumentV2OrganizationSignaturePreview index={index} />
      ) : (
        <DocumentV2OrganizationSignatureQueried project={project} index={index} />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectDocumentV2OrganizationSignature.fragment = gql`
  fragment ProjectDocumentV2OrganizationSignature on Project {
    id
    organization {
      id
      businessSignature
    }
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_PROJECT_DATA = {
  id: 1,
  organization: {
    id: 1,
    businessSignature: 'Organization Signature',
  },
  signature: {
    id: 1,
    date: '2020-04-10',
  },
};

export default ProjectDocumentV2OrganizationSignature;
