// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {useResponsive} from '@supermove/hooks';

// App
import Drawer from '@shared/design/components/Drawer';
import Sheet from '@shared/design/components/Sheet';

const DrawerWithClose = ({
  isOpen,
  isResponsive,
  handleClose,
  headerText,
  width,
  shouldCloseOnClickOutside,
  isFixedHeightSheet,
  children,
  bodyProps,
}) => {
  const responsive = useResponsive();

  if (isResponsive && !responsive.desktop) {
    return (
      <Sheet
        headerText={headerText}
        isOpen={isOpen}
        handleClose={handleClose}
        shouldCloseOnClickOutside={shouldCloseOnClickOutside}
        isFixedHeight={isFixedHeightSheet}
        style={{padding: 16}}
      >
        {children}
      </Sheet>
    );
  }

  return (
    <Drawer isOpen={isOpen} handleClose={handleClose} width={width}>
      <Drawer.Header headerText={headerText} handleClose={handleClose} isClosable isResponsive />
      <Drawer.Body {...bodyProps}>{children}</Drawer.Body>
    </Drawer>
  );
};

DrawerWithClose.Text = Drawer.Text;
DrawerWithClose.WIDTH = Drawer.WIDTH;

// --------------------------------------------------
// Props
// --------------------------------------------------
DrawerWithClose.propTypes = {
  isOpen: PropTypes.bool,
  isResponsive: PropTypes.bool,
  handleClose: PropTypes.func,
  headerText: PropTypes.string,
  shouldCloseOnClickOutside: PropTypes.bool,
  isFixedHeightSheet: PropTypes.bool,
  width: PropTypes.number,
  children: PropTypes.object,
  bodyProps: PropTypes.object,
};

DrawerWithClose.defaultProps = {
  isOpen: false,
  isResponsive: true,
  handleClose: () => {},
  headerText: '',
  shouldCloseOnClickOutside: true,
  isFixedHeightSheet: false,
  width: Drawer.WIDTH.DEFAULT,
  children: null,
  bodyProps: {},
};

export default DrawerWithClose;
