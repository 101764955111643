// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover, useToast, useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Callout from '@shared/design/components/Callout';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import SyncAllCommercialCatalogBillsForm from '@shared/modules/CommercialCatalog/forms/SyncAllCommercialCatalogBillsForm';
import useSyncAllCommercialCatalogBillsMutation from '@shared/modules/CommercialCatalog/hooks/useSyncAllCommercialCatalogBillsMutation';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import UserRole from '@shared/modules/User/enums/UserRole';
import TextTooltip from 'modules/App/components/TextTooltip';
import useAppContext from 'modules/App/context/useAppContext';
import EditCommercialEquipmentModal from 'modules/CommercialCatalog/components/EditCommercialEquipmentModal';
import EditCommercialMaterialsModal from 'modules/CommercialCatalog/components/EditCommercialMaterialsModal';
import CommercialCatalogBillSection from 'modules/Job/V2/Move/components/CommercialCatalogBillSection';
import ConfirmSyncCommercialCatalogBillModal from 'modules/Job/V2/Move/components/ConfirmSyncCommercialCatalogBillModal';
import JobActionDisabledTooltip from 'modules/Project/V2/Show/Blocks/Job/components/JobActionDisabledTooltip';
import JobEquipmentList from 'modules/Project/V2/Show/Blocks/Job/components/JobEquipmentList';
import JobMaterialsList from 'modules/Project/V2/Show/Blocks/Job/components/JobMaterialsList';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TableHeader = Styled.Text`
  ${Typography.Subheading}
`;

const useSyncCommercialCatalog = ({job, refetch, urlFilters}) => {
  const confirmSyncCommercialCatalogBillModal = useModal({
    name: 'Confirm Sync Commercial Catalog Bill',
  });

  const successSyncCommercialCatalogBill = useToast({
    ToastComponent: SuccessToast,
    message: 'Equipment and materials bill generated!',
    actionText: 'View Bill',
    handleAction: () => urlFilters.handleReset({block: ProjectBlockKind.BILLING}, true),
  });

  const syncAllCommercialCatalogBillsForm = SyncAllCommercialCatalogBillsForm.edit(
    job.commercialCatalog.project,
  );

  const syncAllCommercialCatalogBillsMutation = useSyncAllCommercialCatalogBillsMutation({
    syncAllCommercialCatalogBillsForm,
    onSuccess: () => {
      successSyncCommercialCatalogBill.handleToast();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return {
    confirmSyncCommercialCatalogBillModal,
    successSyncCommercialCatalogBill,
    syncAllCommercialCatalogBillsMutation,
  };
};

const getIsEnabledEdit = ({job}) => {
  if (job.isFinal) {
    return false;
  }
  if (job.isComplete && !job.organization.features.isEnabledCanEditCompleteJob) {
    return false;
  }
  return true;
};

const getActionMenuActions = ({
  editCommercialEquipmentModal,
  editCommercialMaterialsModal,
  confirmSyncCommercialCatalogBillModal,
  syncAllCommercialCatalogBillsMutation,
  hasSyncedCommercialCatalogBill,
}) => {
  return [
    {
      text: `${hasSyncedCommercialCatalogBill ? 'Regenerate' : 'Generate'} bill`,
      onPress: hasSyncedCommercialCatalogBill
        ? confirmSyncCommercialCatalogBillModal.handleOpen
        : syncAllCommercialCatalogBillsMutation.handleSubmit,
      isLoadedAction: !hasSyncedCommercialCatalogBill,
      isLoading: syncAllCommercialCatalogBillsMutation.submitting,
    },
    {
      text: 'Edit equipment',
      onPress: editCommercialEquipmentModal.handleOpen,
    },
    {
      text: 'Edit materials',
      onPress: editCommercialMaterialsModal.handleOpen,
    },
  ];
};

const GenerateBillButton = ({
  job,
  confirmSyncCommercialCatalogBillModal,
  syncAllCommercialCatalogBillsMutation,
}) => {
  const {viewer} = useAppContext();
  const {hasSyncedCommercialCatalogBill} = job.commercialCatalog.project;

  return (
    <React.Fragment>
      {hasSyncedCommercialCatalogBill ? (
        <TextTooltip
          placement={'top'}
          text={
            job.isFinal
              ? UserRole.getJobActionDisabledTooltip(viewer?.role)
              : 'This will regenerate all items on the bill'
          }
        >
          <Row>
            <SecondaryButton
              iconLeft={Icon.ArrowRotateRight}
              text={'Regenerate Bill'}
              onPress={confirmSyncCommercialCatalogBillModal.handleOpen}
              isSmall
              isDisabled={job.isFinal}
            />
          </Row>
        </TextTooltip>
      ) : (
        <TextTooltip
          placement={'top'}
          text={
            job.isFinal
              ? UserRole.getJobActionDisabledTooltip(viewer?.role)
              : `We'll automatically add a bill that includes:\n` +
                `1. Any Materials with a price\n` +
                `2. Any Equipments with a price`
          }
        >
          <Row>
            <SecondaryButton
              iconLeft={Icon.DollarSign}
              text={'Generate Bill'}
              onPress={syncAllCommercialCatalogBillsMutation.handleSubmit}
              isSubmitting={syncAllCommercialCatalogBillsMutation.submitting}
              isSmall
              isDisabled={job.isFinal}
            />
          </Row>
        </TextTooltip>
      )}
    </React.Fragment>
  );
};

const EditActionsDropdown = ({editCommercialEquipmentModal, editCommercialMaterialsModal}) => {
  return (
    <DropdownButton
      iconLeft={Icon.Pen}
      text={'Edit'}
      isSmall
      menuWidth={160}
      menuPosition={DropdownButton.MENU_POSITION.RIGHT}
      actions={[
        {
          text: 'Edit equipment',
          onPress: editCommercialEquipmentModal.handleOpen,
        },
        {
          text: 'Edit materials',
          onPress: editCommercialMaterialsModal.handleOpen,
        },
      ]}
    />
  );
};

const HeaderButtons = ({
  job,
  editCommercialEquipmentModal,
  editCommercialMaterialsModal,
  confirmSyncCommercialCatalogBillModal,
  syncAllCommercialCatalogBillsMutation,
}) => {
  return (
    <Row>
      <GenerateBillButton
        viewer
        job={job}
        confirmSyncCommercialCatalogBillModal={confirmSyncCommercialCatalogBillModal}
        syncAllCommercialCatalogBillsMutation={syncAllCommercialCatalogBillsMutation}
      />
      {getIsEnabledEdit({job}) && (
        <React.Fragment>
          <Space width={12} />
          <EditActionsDropdown
            editCommercialEquipmentModal={editCommercialEquipmentModal}
            editCommercialMaterialsModal={editCommercialMaterialsModal}
          />
        </React.Fragment>
      )}
    </Row>
  );
};

const LastSyncCallout = ({syncedAt}) => {
  return (
    <Callout
      text={
        `The equipment & materials bill was last generated on ` +
        `${Datetime.convertToDisplayDate(syncedAt, 'MM/DD/YY')}.`
      }
    />
  );
};

const EquipmentAndMaterials = ({job}) => {
  return (
    <Column>
      {job.commercialCatalog.project.hasSyncedCommercialCatalogBill && (
        <React.Fragment>
          <LastSyncCallout
            syncedAt={job.commercialCatalog.project.lastSyncedCommercialCatalogBillAt}
          />
          <Space height={24} />
        </React.Fragment>
      )}
      <TableHeader>Equipment</TableHeader>
      <Space height={12} />
      <JobEquipmentList job={job} />
      <Space height={24} />
      <TableHeader>Materials</TableHeader>
      <Space height={12} />
      <JobMaterialsList job={job} />
    </Column>
  );
};

const JobEquipmentAndMaterialsBlockContent = ({
  job,
  refetch,
  urlFilters,
  editCommercialEquipmentModal,
  editCommercialMaterialsModal,
}) => {
  const {
    confirmSyncCommercialCatalogBillModal,
    successSyncCommercialCatalogBill,
    syncAllCommercialCatalogBillsMutation,
  } = useSyncCommercialCatalog({job, refetch, urlFilters});
  const responsive = useResponsive();
  const actionMenuPopover = usePopover();
  const {hasSyncedCommercialCatalogBill} = job.commercialCatalog.project;

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <ActionPanel
          BodyComponent={EquipmentAndMaterials}
          bodyComponentProps={{job}}
          ActionButtonComponent={HeaderButtons}
          actionButtonComponentProps={{
            job,
            editCommercialEquipmentModal,
            editCommercialMaterialsModal,
            confirmSyncCommercialCatalogBillModal,
            syncAllCommercialCatalogBillsMutation,
          }}
          title={'Equipment & Materials'}
          style={{flex: 1, width: '100%'}}
        />
      ) : (
        <React.Fragment>
          <MobileProjectBlockHeader
            title={`Equipment & Materials`}
            ActionsComponent={() => (
              <ActionMenuPopover
                popover={actionMenuPopover}
                actions={getActionMenuActions({
                  editCommercialEquipmentModal,
                  editCommercialMaterialsModal,
                  confirmSyncCommercialCatalogBillModal,
                  syncAllCommercialCatalogBillsMutation,
                  hasSyncedCommercialCatalogBill,
                })}
              >
                <JobActionDisabledTooltip job={job}>
                  <MobileProjectBlockHeader.EllipsisButton
                    onPress={actionMenuPopover.handleOpen}
                    isDisabled={job.isFinal}
                  />
                </JobActionDisabledTooltip>
              </ActionMenuPopover>
            )}
          />
          <EquipmentAndMaterials job={job} />
        </React.Fragment>
      )}
      <ConfirmSyncCommercialCatalogBillModal
        isOpen={confirmSyncCommercialCatalogBillModal.isOpen}
        onClose={confirmSyncCommercialCatalogBillModal.handleClose}
        commercialCatalog={job.commercialCatalog}
        refetch={() => {
          successSyncCommercialCatalogBill.handleToast();
          refetch();
        }}
      />
    </React.Fragment>
  );
};

const JobEquipmentAndMaterialsBlock = ({
  jobUuid,
  handleSetPositionY,
  layoutKey,
  index,
  urlFilters,
}) => {
  const editCommercialEquipmentModal = useModal({
    name: 'Edit Commercial Equipment Modal',
    enableTracking: true,
  });
  const editCommercialMaterialsModal = useModal({
    name: 'Edit Commercial Materials Modal',
    enableTracking: true,
  });

  return (
    <React.Fragment>
      <ProjectBlockWrapper
        index={index}
        query={JobEquipmentAndMaterialsBlock.query}
        queryVariables={{jobUuid}}
        layoutKey={layoutKey}
        handleSetPositionY={handleSetPositionY}
      >
        {({data, refetch}) => {
          return (
            <JobEquipmentAndMaterialsBlockContent
              job={data.job}
              refetch={refetch}
              urlFilters={urlFilters}
              editCommercialEquipmentModal={editCommercialEquipmentModal}
              editCommercialMaterialsModal={editCommercialMaterialsModal}
            />
          );
        }}
      </ProjectBlockWrapper>
      <EditCommercialEquipmentModal
        jobUuid={jobUuid}
        isOpen={editCommercialEquipmentModal.isOpen}
        handleClose={editCommercialEquipmentModal.handleClose}
      />
      <EditCommercialMaterialsModal
        jobUuid={jobUuid}
        isOpen={editCommercialMaterialsModal.isOpen}
        handleClose={editCommercialMaterialsModal.handleClose}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobEquipmentAndMaterialsBlock.query = gql`
  ${JobActionDisabledTooltip.fragment}
  ${JobEquipmentList.fragment}
  ${JobMaterialsList.fragment}
  ${CommercialCatalogBillSection.fragment}
  ${ConfirmSyncCommercialCatalogBillModal.fragment}
  ${SyncAllCommercialCatalogBillsForm.edit.fragment}

  query JobEquipmentAndMaterialsBlock($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      uuid
      isFinal
      isComplete
      organization {
        id
        features {
          isEnabledCanEditCompleteJob: isEnabled(feature: "CAN_EDIT_COMPLETE_JOB")
        }
      }
      commercialCatalog {
        id
        project {
          id
          hasSyncedCommercialCatalogBill
          lastSyncedCommercialCatalogBillAt
          ...SyncAllCommercialCatalogBillsForm_edit
        }
        ...ConfirmSyncCommercialCatalogBillModal
        ...CommercialCatalogBillSection
      }
      ...JobActionDisabledTooltip
      ...JobEquipmentList
      ...JobMaterialsList
    }
  }
`;

export default JobEquipmentAndMaterialsBlock;
