// Libraries
import _ from 'lodash';

const SENDER = 'SENDER';
const COMPANY = 'COMPANY';

const getDisplay = (kind) => _.capitalize(kind);

const ReplyToKind = {
  SENDER,
  COMPANY,

  getDisplay,
};

export default ReplyToKind;
