// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Select, {components as SelectComponents} from 'react-select';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive, useSheet} from '@supermove/hooks';
import {Typography, colors, INPUT_BORDER_COLOR} from '@supermove/styles';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import DropdownSheet from '@shared/design/components/DropdownInput/components/DropdownSheet';

const SelectOptionRow = Styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1
`;

const Container = Styled.View`
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
`;

const GrayText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const CheckboxContainer = Styled.View`
  align-items: center;
  justify-content: center;
  width: ${({desktop}: any) => (desktop ? '16px' : '18px')};
  height: ${({desktop}: any) => (desktop ? '16px' : '18px')};
  background-color: ${({isChecked}: any) => (isChecked ? colors.blue.interactive : colors.white)};
  border-color: ${({isChecked}: any) =>
    isChecked ? colors.blue.interactive : colors.gray.tertiary};
  border-radius: 4px;
  border-width: 2px;
`;

const controlStyle = {
  minHeight: 40,
  borderRadius: 3,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: INPUT_BORDER_COLOR,
};

const optionStyle = {
  display: 'block',
  fontSize: 14,
  lineHeight: '19px',
  fontWeight: 300,
};

const placeholderStyle = {
  fontFamily: 'Avenir',
  fontSize: 14,
  lineHeight: '19px',
  fontWeight: 300,
  color: colors.gray.tertiary,
};

const multiValueStyle = {
  paddingTop: 1,
  paddingBottom: 1,
  paddingLeft: 4,
  paddingRight: 4,
  borderRadius: 20,
};

const multiValueLabelStyle = {
  fontFamily: 'Avenir',
  fontSize: 14,
  lineHeight: '19px',
  fontWeight: 300,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const RemoveButton = Styled.ButtonV2`
  padding: 4px;
  padding-left: 8px;
  padding-right: 6px;
  align-self: center;
`;

const GroupContainer = Styled.View`
  flex: 1;
`;

const GroupLabelText = Styled.Text`
  ${Typography.Label}
  text-transform: capitalize;
`;

const GroupDivider = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const getInputBackgroundColor = ({disabled, isDropdownSheet, required}: any) => {
  if (!disabled && isDropdownSheet) {
    return colors.white;
  }
  if (disabled) {
    return colors.gray.disabled;
  }
  if (required) {
    return colors.alpha(colors.yellow.hover, 0.1);
  }
  return colors.white;
};

const GroupLabel = ({label}: any) => {
  return (
    <React.Fragment>
      <GroupLabelText>{label}</GroupLabelText>
      <Space height={8} />
    </React.Fragment>
  );
};

const CheckboxOption = ({
  children,
  isSelected,
  data,
  overrideCheckboxes,
  isSectionedList,
  isDescriptionBelow,
  responsive,
  ...props
}: any) => {
  const hasDescription = data?.description;
  const hasDescriptionInline = hasDescription && !isDescriptionBelow;
  const hasDescriptionBelow = hasDescription && isDescriptionBelow;

  if (data.isHidden) {
    return null;
  }

  return (
    <SelectComponents.Option {...props}>
      {/* HACK(dan): Because we reverted the global styles for the 'Group' component (see comment
      below in the customized Group component), we need to restore global styles for the 'Option'
      components that are children of the 'Group' component. */}
      <div id={'restoreGlobalStyles'}>
        <SelectOptionRow>
          {isSectionedList && <Space width={12} />}
          <CheckboxContainer isChecked={overrideCheckboxes || isSelected} {...responsive}>
            {(overrideCheckboxes || isSelected) && (
              <Icon color={colors.white} size={10} source={Icon.Check} />
            )}
          </CheckboxContainer>
          <Space width={8} />
          <Container>
            <Text responsive={responsive}>{children}</Text>
            {hasDescriptionBelow && <GrayText responsive={responsive}>{data.description}</GrayText>}
          </Container>
          {hasDescriptionInline && (
            <React.Fragment>
              <Space style={{flex: 1}} />
              <GrayText responsive={responsive}>{data.description}</GrayText>
            </React.Fragment>
          )}
        </SelectOptionRow>
      </div>
    </SelectComponents.Option>
  );
};

const MultiDropdownCheckboxInput = ({
  disabled,
  required,
  isOptionDisabled,
  label,
  name,
  placeholder,
  value,
  options,
  setFieldValue,
  onChangeValue,
  onInputChange,
  components,
  style,
  isPortaled,
  usePills,

  // HACK(cooper): This is an escape hatch for a situation where we want to mark all
  // checkboxes as if they're selected _without_ the actual values being selected
  overrideCheckboxes,

  // HACK(cooper): These two handlers are exposed for special circumstances and should not be
  // necessary for normal usage of this component
  onOptionAdded,

  onOptionRemoved,
  isSearchable,
  isDescriptionBelow,
  isResponsive,
}: any) => {
  const responsive = useResponsive();
  const displayDesktop = !isResponsive || responsive.desktop;
  const isDropdownSheet = !responsive.desktop;
  const mobileSheet = useSheet({name: 'Mobile Dropdown Sheet', enableTracking: false});
  const isSectionedList = options[0]?.options;
  const allOptions = isSectionedList ? _.flatten(_.map(options, 'options')) : options;

  return (
    <React.Fragment>
      <DropdownInput.SheetButtonWrapper
        responsive={responsive}
        mobileSheet={mobileSheet}
        isDisabled={disabled}
        isResponsiveSheet
      >
        <Select
          isMulti
          isDisabled={disabled || isDropdownSheet}
          isOptionDisabled={(option: any) => isOptionDisabled(option)}
          isSearchable={isSearchable || false}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          menuPortalTarget={isPortaled && document.body}
          name={name}
          placeholder={placeholder}
          value={_.map(value, (value) => _.find(allOptions, {value}))}
          options={options}
          formatGroupLabel={GroupLabel}
          onChange={(values: any, metadata: any) => {
            const value = values.map((value: any) => value.value);
            setFieldValue(name, value);

            // TODO(mark): Hack to avoid the Formik name: onChange.
            onChangeValue && onChangeValue(value);

            switch (metadata.action) {
              case 'select-option':
                onOptionAdded(metadata.option.value);
                break;
              case 'deselect-option':
                onOptionRemoved(metadata.option.value);
                break;
              case 'remove-value':
                onOptionRemoved(metadata.removedValue.value);
                break;
              default:
                break;
            }
          }}
          onInputChange={(value: any) => onInputChange(value)}
          components={{
            ClearIndicator: () => null,
            IndicatorSeparator: () => null,
            ...(!usePills && {
              MultiValueContainer: ({selectProps, data}: any) => {
                const values = selectProps.value;
                if (_.some(values)) {
                  return (
                    <Text responsive={isResponsive ? responsive : null} numberOfLines={1}>
                      {values[values.length - 1].label === data.label
                        ? data.label
                        : `${data.label}, `}
                    </Text>
                  );
                } else return '';
              },
            }),
            MultiValueRemove: (props: any) => {
              if (!responsive.desktop) {
                return null;
              }
              return (
                <RemoveButton onPress={props.innerProps.onClick}>
                  <Icon
                    {...props}
                    source={Icon.Times}
                    size={12}
                    color={props.data.hasError ? colors.red.warning : colors.gray.secondary}
                  />
                </RemoveButton>
              );
            },
            DropdownIndicator: (props: any) => {
              return (
                <MultiDropdownCheckboxInput.DropdownIndicator {...props}>
                  <Icon
                    source={props.selectProps.menuIsOpen ? Icon.ChevronUp : Icon.ChevronDown}
                    size={displayDesktop ? 14 : 16}
                    color={colors.gray.secondary}
                    style={{marginLeft: 4, marginRight: 4, marginTop: 2}}
                  />
                </MultiDropdownCheckboxInput.DropdownIndicator>
              );
            },
            Group: (props: any) => {
              const allOptions = props.selectProps.options;
              const isFirstGroup = _.get(props, 'data.label') === _.get(allOptions, '0.label');
              const isLastGroup =
                _.get(props, 'data.label') === _.get(allOptions, `${allOptions.length - 1}.label`);

              // HACK(dan): When it is a sectioned list, the 'Group' container wraps the label and the
              // group options. However, there is an additional div that wraps the group options that
              // we need to target to revert the global styles.
              return (
                <div id={'revertGlobalStyles'}>
                  <GroupContainer>
                    {!isFirstGroup && <GroupDivider />}
                    <Space height={12} />
                    <SelectComponents.Group {...props} />
                    {!isLastGroup && <Space height={4} />}
                  </GroupContainer>
                </div>
              );
            },
            Option: (props: any) => (
              <CheckboxOption
                {...props}
                overrideCheckboxes={overrideCheckboxes}
                isSectionedList={isSectionedList}
                isDescriptionBelow={isDescriptionBelow}
                responsive={responsive}
              />
            ),
            ...components,
          }}
          styles={{
            // @ts-expect-error TS(7006): Parameter 'current' implicitly has an 'any' type.
            container: (current, state) => ({
              ...current,
              pointerEvents: 'auto',
              cursor: state.isDisabled ? 'not-allowed' : 'text',
            }),
            // @ts-expect-error TS(7006): Parameter 'current' implicitly has an 'any' type.
            control: (current, state) => ({
              ...current,
              ...controlStyle,
              ...style,
              pointerEvents: state.isDisabled ? 'none' : 'auto',
              minHeight: displayDesktop ? 36 : 48,
              height: 'auto',
              width: '100%',

              // Custom 'required' feature to change the background-color.
              backgroundColor: getInputBackgroundColor({disabled, isDropdownSheet, required}),
            }),
            menu: (current: any) => ({
              ...current,
              display: 'block',
            }),
            menuList: (current: any) => ({
              ...current,
              display: 'block',
            }),
            // @ts-expect-error TS(7006): Parameter 'current' implicitly has an 'any' type.
            multiValue: (current, state) => ({
              ...current,
              ...multiValueStyle,

              backgroundColor: disabled
                ? colors.gray.disabled
                : state.data.hasError
                  ? colors.red.accent
                  : colors.gray.border,
            }),
            // @ts-expect-error TS(7006): Parameter 'current' implicitly has an 'any' type.
            multiValueLabel: (current, state) => ({
              ...current,
              ...multiValueLabelStyle,
              color: state.data.hasError ? colors.red.warning : colors.gray.primary,
            }),
            option: (current: any) => ({
              ...current,
              ...optionStyle,
            }),
            placeholder: (current: any) => ({
              ...current,
              ...placeholderStyle,
              fontSize: displayDesktop ? 14 : 16,
            }),
            // @ts-expect-error TS(7006): Parameter 'current' implicitly has an 'any' type.
            singleValue: (current, state) => ({
              ...current,
              color: state.isDisabled ? colors.gray.tertiary : colors.gray.primary,
            }),
            ...(!usePills && {
              valueContainer: (current: any) => ({
                ...current,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: 'block',
                input: {height: 0},
              }),
            }),
          }}
        />
      </DropdownInput.SheetButtonWrapper>
      {isDropdownSheet && (
        <DropdownSheet
          key={mobileSheet.key}
          isOpen={mobileSheet.isOpen}
          handleClose={mobileSheet.handleClose}
          headerText={label}
          options={options}
          name={name}
          value={value}
          onChangeValue={onChangeValue}
          setFieldValue={setFieldValue}
          onInputChange={onInputChange}
          showDescriptionInOption={isDescriptionBelow}
          isMultiSelect
          HeaderRightComponent={DropdownSheet.DoneButton}
        />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
MultiDropdownCheckboxInput.propTypes = {
  value: PropTypes.array,
  onInputChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  isOptionDisabled: PropTypes.func,
  components: PropTypes.object,
  isPortaled: PropTypes.bool,
  usePills: PropTypes.bool,
  overrideCheckboxes: PropTypes.bool,
  onOptionAdded: PropTypes.func,
  onOptionRemoved: PropTypes.func,
  isDescriptionBelow: PropTypes.bool,
};

MultiDropdownCheckboxInput.defaultProps = {
  value: [],
  onInputChange: () => {},
  setFieldValue: () => {},
  isOptionDisabled: () => {},
  components: {},
  isPortaled: false,
  usePills: false,
  overrideCheckboxes: false,
  onOptionAdded: () => {},
  onOptionRemoved: () => {},
  isDescriptionBelow: false,
};

MultiDropdownCheckboxInput.DropdownIndicator = SelectComponents.DropdownIndicator;
MultiDropdownCheckboxInput.CheckboxOption = CheckboxOption;

export default MultiDropdownCheckboxInput;
