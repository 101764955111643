// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CategoriesForm from '@shared/modules/Inventory/forms/CategoriesForm';

const useUpsertCategoriesMutation = ({categoriesForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      categoriesForm: CategoriesForm.toForm(categoriesForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpsertCategoriesMutation.mutation,
    variables: {
      categoriesForm: CategoriesForm.toMutation(form.values.categoriesForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpsertCategoriesMutation.mutation = gql`
  mutation useUpsertCategoriesMutation($categoriesForm: CategoriesForm!) {
    response: upsertCategories(categoriesForm: $categoriesForm) {
      ${gql.errors}
    }
  }
`;

export default useUpsertCategoriesMutation;
