const _new = ({jobId}) => {
  return {
    jobId: jobId || null,
  };
};

const toForm = ({jobId}) => {
  return {
    jobId,
  };
};

const toMutation = ({jobId}) => {
  return {
    jobId,
  };
};

const GenerateCrewDocumentsForJobForm = {
  new: _new,
  toForm,
  toMutation,
};

export default GenerateCrewDocumentsForJobForm;
