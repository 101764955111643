// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled, Space, Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, usePopover, useHover, useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Phone, Datetime} from '@supermove/utils';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import Table from '@shared/design/components/Table';
import TextMessageRecipientKind from '@shared/modules/Sms/enums/TextMessageRecipientKind';
import TextMessageTemplateCategory from '@shared/modules/Sms/enums/TextMessageTemplateCategory';
import TextMessageTemplateForm from '@shared/modules/Sms/forms/TextMessageTemplateForm';
import useDeleteTextMessageTemplateMutation from '@shared/modules/Sms/hooks/useDeleteTextMessageTemplateMutation';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';

const ContentContainer = Styled.View`
`;

const Container = Styled.View`
  min-height: 40px;
  justify-content: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Label2}
`;

const BodyText = Styled.Text`
  ${Typography.Body}
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const IconButton = Styled.ButtonV2`
  width: 16px;
  height: 16px;
  align-items: center;
`;

const MenuItemText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.primary};
`;

const DescriptionText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const TableContainer = Styled.View`
  min-width: 100%;
`;

const MenuItemTouchable = Styled.Touchable`
  padding-vertical: 4px;
  padding-horizontal: 12px;
  background-color: ${(props) => (props.isHovered ? colors.hover : 'transparent')};
`;

const getColumnDefinitions = ({navigator, refetch, isSystemTemplate}) => {
  return [
    // Name
    {
      // 4.7 matches the two tables together
      flex: isSystemTemplate ? 4.7 : 2.5,
      headerContent: () => {
        return <HeaderText>Name</HeaderText>;
      },
      cellContent: ({item: textMessageTemplate}) => {
        return <BodyText numberOfLines={1}>{textMessageTemplate.name}</BodyText>;
      },
    },
    // Recipients
    {
      isHidden: isSystemTemplate,
      flex: 2,
      headerContent: () => {
        return <HeaderText>Recipients</HeaderText>;
      },
      cellContent: ({item: textMessageTemplate}) => {
        // Combine the recipients into 1 list after formatting
        const projectUserRecipients = textMessageTemplate.projectUserRecipients.map(
          (projectUser) => {
            return TextMessageRecipientKind.getKindOption(projectUser).label;
          },
        );
        const teamRecipientsNames = textMessageTemplate.teamRecipients.map((team) => {
          return team.name;
        });

        const phoneNumberRecipientsDisplay = textMessageTemplate.phoneNumberRecipients.map(
          (phoneNumber) => {
            return Phone.display(phoneNumber);
          },
        );

        const joinedRecipientList = [
          ...phoneNumberRecipientsDisplay,
          ...projectUserRecipients,
          ...teamRecipientsNames,
        ];

        return <BodyText numberOfLines={1}>{joinedRecipientList.join(', ')}</BodyText>;
      },
    },
    // Type
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Type</HeaderText>;
      },
      cellContent: ({item: textMessageTemplate}) => {
        return (
          <BodyText numberOfLines={1}>
            {TextMessageTemplateCategory.getCategoryOption(textMessageTemplate.category).label}
          </BodyText>
        );
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Last Modified</HeaderText>;
      },
      cellContent: ({item: textMessageTemplate}) => {
        return (
          <Container>
            <BodyText numberOfLines={1}>
              {Datetime.convertToDisplayDatetime(textMessageTemplate.updatedAt)}
            </BodyText>
            {!!textMessageTemplate.updatedBy.fullName && (
              <React.Fragment>
                <Space height={4} />
                <DescriptionText>{`By ${textMessageTemplate.updatedBy.fullName}`}</DescriptionText>
              </React.Fragment>
            )}
          </Container>
        );
      },
    },
    // Actions
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Actions</HeaderText>;
      },
      cellContent: ({item: textMessageTemplate}) => {
        return (
          <React.Fragment>
            <Space width={16} />
            <TextMessageTemplateActions
              key={`TEXT_MESSAGE_TEMPLATE_ACTIONS-${textMessageTemplate.id}`}
              textMessageTemplate={textMessageTemplate}
              navigator={navigator}
              refetch={refetch}
              isSystemTemplate={isSystemTemplate}
            />
          </React.Fragment>
        );
      },
    },
  ];
};

const TextMessageTemplateActions = ({
  textMessageTemplate,
  navigator,
  refetch,
  isSystemTemplate,
}) => {
  const textMessageTemplateActionsPopover = usePopover();

  const deleteTextMessageTemplateModal = useModal({name: 'Delete Text Message Template Modal'});

  const textMessageTemplateForm = TextMessageTemplateForm.edit({textMessageTemplate});

  const {handleSubmit: handleDeleteTextMessageTemplate} = useDeleteTextMessageTemplateMutation({
    textMessageTemplateForm,
    onSuccess: () => {
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <React.Fragment>
      <Row>
        <IconButton
          onPress={() => {
            navigator.push(`/settings/communication/sms-templates/${textMessageTemplate.uuid}`);
          }}
        >
          <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
        </IconButton>
        <Space width={8} />
        {!isSystemTemplate && (
          <Popover.Content innerRef={textMessageTemplateActionsPopover.ref}>
            <IconButton onPress={textMessageTemplateActionsPopover.handleOpen}>
              <Icon source={Icon.EllipsisV} color={colors.gray.tertiary} size={16} />
            </IconButton>
          </Popover.Content>
        )}
      </Row>
      <TextTemplateActionsPopover
        key={`TEXT_MESSAGE_TEMPLATE-${textMessageTemplateActionsPopover.isOpen}`}
        textMessageTemplateActionsPopover={textMessageTemplateActionsPopover}
        textMessageTemplate={textMessageTemplate}
        navigator={navigator}
        refetch={refetch}
        deleteTextMessageTemplateModal={deleteTextMessageTemplateModal}
      />
      <DeleteModal
        isOpen={deleteTextMessageTemplateModal.isOpen}
        title={`Delete "${textMessageTemplate.name}?"`}
        subtitle={"You can't undo this action."}
        handleClose={deleteTextMessageTemplateModal.handleClose}
        handleDelete={handleDeleteTextMessageTemplate}
        deleteButtonText={'Remove'}
      />
    </React.Fragment>
  );
};

const TextTemplateActionsPopover = ({
  textMessageTemplateActionsPopover,
  deleteTextMessageTemplateModal,
}) => {
  const textMessageTemplateActions = [
    {
      label: 'Delete',
      onPress: () => {
        deleteTextMessageTemplateModal.handleOpen();
      },
    },
  ];
  return (
    <React.Fragment>
      <Popover
        placement={Popover.Positions.LeftStart}
        isOpen={textMessageTemplateActionsPopover.isOpen}
        handleOpen={textMessageTemplateActionsPopover.handleOpen}
        handleClose={textMessageTemplateActionsPopover.handleClose}
        reference={textMessageTemplateActionsPopover.ref}
        offset={[10, 0]}
      >
        <ResponsivePopover.StaticContainer width={90} style={{alignItems: 'center'}}>
          <Space height={8} />
          {textMessageTemplateActions.map(({label, onPress, isDisabled}, index) => {
            return (
              <ActionMenuItem
                key={index}
                onPress={() => {
                  onPress();
                  textMessageTemplateActionsPopover.handleClose();
                }}
                isDisabled={isDisabled}
              >
                {label}
              </ActionMenuItem>
            );
          })}
          <Space height={8} />
        </ResponsivePopover.StaticContainer>
      </Popover>
    </React.Fragment>
  );
};

const ActionMenuItem = ({onPress, isDisabled, children}) => {
  const {isHovered, ref} = useHover();
  return (
    <React.Fragment>
      <MenuItemTouchable
        onPress={onPress}
        disabled={isDisabled}
        isHovered={isHovered}
        ref={ref}
        activeOpacity={0.8}
      >
        <MenuItemText color={isDisabled ? colors.gray.tertiary : colors.gray.primary}>
          {children}
        </MenuItemText>
      </MenuItemTouchable>
      <Space height={2} />
    </React.Fragment>
  );
};

const SmsTemplatesTableContent = ({
  textMessageTemplates,
  organization,
  loading,
  refetch,
  setSelectedTextMessageTemplate,
  selectedTextMessageTemplate,
  isSystemTemplate,
}) => {
  const {navigator} = useNavigationDOM();

  return (
    <ScrollView>
      <ContentContainer style={{minHeight: 50, flex: 1}}>
        <TableContainer>
          <Table
            columnDefinitions={getColumnDefinitions({
              navigator,
              refetch,
              organization,
              isSystemTemplate,
            })}
            items={textMessageTemplates}
            loading={loading}
            emptyStateText={'No text message templates to display'}
            isClickable
            activeRowIndex={_.findIndex(textMessageTemplates, (textMessageTemplate) => {
              if (selectedTextMessageTemplate) {
                return selectedTextMessageTemplate.uuid === textMessageTemplate.uuid;
              }
              return false;
            })}
            onRowPress={(item) => {
              setSelectedTextMessageTemplate(item);
            }}
          />
        </TableContainer>
        <Space height={48} />
      </ContentContainer>
    </ScrollView>
  );
};

const SmsTemplatesTable = ({
  textMessageTemplates,
  organization,
  loading,
  refetch,
  setSelectedTextMessageTemplate,
  selectedTextMessageTemplate,
  isSystemTemplate,
}) => {
  return (
    <ContentContainer>
      <SmsTemplatesTableContent
        loading={loading}
        refetch={refetch}
        textMessageTemplates={textMessageTemplates}
        setSelectedTextMessageTemplate={setSelectedTextMessageTemplate}
        selectedTextMessageTemplate={selectedTextMessageTemplate}
        organization={organization}
        isSystemTemplate={isSystemTemplate}
      />
      <Space height={16} />
    </ContentContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SmsTemplatesTable.fragment = gql`
  fragment SmsTemplatesTable on TextMessageTemplate {
    id
    name
    category
    uuid
    systemTemplateKind
    body
    phoneNumberRecipients
    teamIdRecipients
    projectUserRecipients
    updatedAt
    updatedBy {
      id
      fullName
    }
    teamRecipients {
      id
      name
    }
  }
`;

export default SmsTemplatesTable;
