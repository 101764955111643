// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  Drawer,
  DropdownInput,
  Icon,
  Loading,
  ScrollView,
  Space,
  Styled,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useState, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SearchBar from '@shared/design/components/SearchBar';
import Table from '@shared/design/components/Table';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import CopyDocumentTemplatesForm from '@shared/modules/Document/forms/CopyDocumentTemplatesForm';
import useCopyDocumentTemplatesMutation from '@shared/modules/Document/hooks/useCopyDocumentTemplatesMutation';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import DocumentTemplatePreview from 'modules/Job/V2/Move/components/DocumentTemplatePreview';

const Container = Styled.View`
  flex: 1;
`;

const DrawerContainer = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const DocumentTemplatePreviewContainer = Styled.View`
  width: 864px;
  background-color: ${colors.gray.background};
  border-right-width: 1px;
  border-right-color: ${colors.gray.border};
`;

const ImportDocumentTemplateDrawerContainer = Styled.View`
  width: 448px;
  background-color: ${colors.white};
`;

const DocumentTemplatesContainer = Styled.View`
  padding-horizontal: 24px;
  flex: 1;
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 12px;
  padding-horizontal: 24px;
  justify-content: space-between;
  background-color: ${colors.white};
`;

const HeaderText = Styled.Text`
  ${Typography.Heading2}
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label2}
`;

const TableBodyText = Styled.Text`
  ${Typography.Body}
`;

const Touchable = Styled.Touchable`
`;

const IconContainer = Styled.View`
  align-items: center;
  justify-content: center;
  width: 40px;
`;

const Footer = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const CancelButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray700};
`;

const PrimaryButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const Header = ({text, handleClose}) => {
  return (
    <HeaderContainer>
      <HeaderText>{text}</HeaderText>
      <QuaternaryButton onPress={handleClose} style={{paddingRight: 0}}>
        <Icon source={Icon.Times} size={18} color={colors.gray.tertiary} />
      </QuaternaryButton>
    </HeaderContainer>
  );
};

const handlePreviewDocumentTemplate = ({
  previewedDocumentTemplate,
  setPreviewedDocumentTemplate,
  documentTemplateToPreview,
}) => {
  if (previewedDocumentTemplate) {
    if (previewedDocumentTemplate.id === documentTemplateToPreview.id) {
      setPreviewedDocumentTemplate(null);
      return;
    }
  }
  setPreviewedDocumentTemplate(documentTemplateToPreview);
};

const handleDocumentTemplateToggle = ({documentTemplate, form}) => {
  const selectedDocumentTemplateIdentifiers =
    form.values.copyDocumentTemplatesForm.documentTemplateIdentifiers;
  if (_.includes(selectedDocumentTemplateIdentifiers, documentTemplate.identifier)) {
    form.setFieldValue(
      'copyDocumentTemplatesForm.documentTemplateIdentifiers',
      _.filter(
        selectedDocumentTemplateIdentifiers,
        (documentTemplateIdentifier) => documentTemplateIdentifier !== documentTemplate.identifier,
      ),
    );
  } else {
    form.setFieldValue('copyDocumentTemplatesForm.documentTemplateIdentifiers', [
      ...selectedDocumentTemplateIdentifiers,
      documentTemplate.identifier,
    ]);
  }
};

const getColumnDefinitions = ({
  documentTemplates,
  previewedDocumentTemplate,
  setPreviewedDocumentTemplate,
  form,
}) => {
  const selectedDocumentTemplateIdentifiers =
    form.values.copyDocumentTemplatesForm.documentTemplateIdentifiers;
  return [
    {
      width: 40,
      headerContent: () => {
        const isAllSelected =
          documentTemplates.length === selectedDocumentTemplateIdentifiers.length;
        return (
          <Checkbox
            isChecked={isAllSelected}
            handleToggle={() => {
              if (isAllSelected) {
                form.setFieldValue('copyDocumentTemplatesForm.documentTemplateIdentifiers', []);
              } else {
                form.setFieldValue(
                  'copyDocumentTemplatesForm.documentTemplateIdentifiers',
                  documentTemplates.map((documentTemplate) => documentTemplate.identifier),
                );
              }
            }}
          />
        );
      },
      cellContent: ({item: documentTemplate}) => {
        return (
          <Checkbox
            isChecked={_.includes(selectedDocumentTemplateIdentifiers, documentTemplate.identifier)}
            handleToggle={() =>
              handleDocumentTemplateToggle({
                documentTemplate,
                form,
              })
            }
          />
        );
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <TableHeaderText>Document Template Name</TableHeaderText>;
      },
      cellContent: ({item: documentTemplate}) => {
        return <TableBodyText>{documentTemplate.name}</TableBodyText>;
      },
    },
    {
      headerContent: () => {
        return <React.Fragment />;
      },
      cellContent: ({item: documentTemplate}) => {
        return (
          <Touchable
            onPress={() => {
              handlePreviewDocumentTemplate({
                previewedDocumentTemplate,
                setPreviewedDocumentTemplate,
                documentTemplateToPreview: documentTemplate,
              });
            }}
          >
            <IconContainer>
              <Icon
                color={colors.blue.interactive}
                size={Icon.Sizes.Large}
                source={Icon.SearchPlus}
              />
            </IconContainer>
          </Touchable>
        );
      },
    },
  ];
};

const getOrganizationOptions = ({organizations, organizationSlug}) => {
  return _.sortBy(
    _.map(
      _.filter(organizations, (organization) => organization.slug !== organizationSlug),
      (organization) => {
        return {
          label: organization.name,
          value: organization.slug,
        };
      },
    ),
    ['label'],
    ['asc'],
  );
};

const DocumentTemplateTable = ({form, previewedDocumentTemplate, setPreviewedDocumentTemplate}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const sourceOrganizationSlug = _.get(
    form.values,
    'copyDocumentTemplatesForm.sourceOrganizationSlug',
    null,
  );
  const {data, loading} = useQuery(DocumentTemplateTable.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      slug: sourceOrganizationSlug,
    },
    skip: !sourceOrganizationSlug,
  });

  return (
    <React.Fragment>
      <SearchBar
        placeholder={'Search by document template...'}
        onChangeText={(text) => {
          setSearchTerm(text);
        }}
        style={{flex: 1}}
      />
      <Space height={16} />
      <ScrollView style={{flex: 1}}>
        <Container>
          <Loading loading={loading} as={PageLoadingIndicator}>
            {() => {
              const documentTemplates = sourceOrganizationSlug
                ? _.sortBy(
                    _.filter(
                      data.organization.documentTemplates,
                      (documentTemplate) =>
                        documentTemplate.activeOrMostRecentDocumentTemplateVersion &&
                        documentTemplate.name.toLowerCase().includes(searchTerm.toLowerCase()),
                    ),
                    ['name'],
                  )
                : [];
              return (
                <Table
                  columnDefinitions={getColumnDefinitions({
                    documentTemplates,
                    previewedDocumentTemplate,
                    setPreviewedDocumentTemplate,
                    form,
                  })}
                  emptyStateText={'No document templates'}
                  items={documentTemplates}
                  itemKey={'id'}
                  isClickable
                  onRowPress={(item) =>
                    handleDocumentTemplateToggle({
                      documentTemplate: item,
                      form,
                    })
                  }
                />
              );
            }}
          </Loading>
        </Container>
      </ScrollView>
    </React.Fragment>
  );
};

const ImportDocumentTemplateDrawerContent = ({
  organizations,
  organizationSlug,
  previewedDocumentTemplate,
  setPreviewedDocumentTemplate,
  refetch,
  handleClose,
}) => {
  const copyDocumentTemplatesForm = CopyDocumentTemplatesForm.new({
    destinationOrganizationSlug: organizationSlug,
  });
  const {form, submitting, handleSubmit} = useCopyDocumentTemplatesMutation({
    copyDocumentTemplatesForm,
    onSuccess: () => {
      refetch();
      handleClose();
      importDocumentTemplatesSuccessToast.handleToast();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const importDocumentTemplatesSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: `${
      form.values.copyDocumentTemplatesForm.documentTemplateIdentifiers.length
    } ${pluralize(
      'document',
      form.values.copyDocumentTemplatesForm.documentTemplateIdentifiers.length,
    )} imported`,
  });
  return (
    <DocumentTemplatesContainer>
      <Space height={24} />
      <FieldInput
        index={0}
        component={DropdownInput}
        label={'Organization'}
        input={{
          placeholder: 'Select Organization',
          options: getOrganizationOptions({organizations, organizationSlug}),
          value: form.values.copyDocumentTemplatesForm.sourceOrganizationSlug,
          setFieldValue: (name, value) => {
            form.setFieldValue('copyDocumentTemplatesForm.sourceOrganizationSlug', value);
            form.setFieldValue('copyDocumentTemplatesForm.documentTemplateIdentifiers', []);
          },
          style: {width: '100%'},
        }}
      />

      <Space height={16} />
      <DocumentTemplateTable
        form={form}
        previewedDocumentTemplate={previewedDocumentTemplate}
        setPreviewedDocumentTemplate={setPreviewedDocumentTemplate}
      />
      <Space height={16} />
      <Footer>
        <TertiaryButton onPress={handleClose}>
          <CancelButtonText>Cancel</CancelButtonText>
        </TertiaryButton>
        <Space width={34} />
        <Button
          onPress={handleSubmit}
          isDisabled={
            _.isEmpty(form.values.copyDocumentTemplatesForm.documentTemplateIdentifiers) ||
            submitting
          }
        >
          <PrimaryButtonText>Import</PrimaryButtonText>
        </Button>
      </Footer>
      <Space height={12} />
    </DocumentTemplatesContainer>
  );
};

const ImportDocumentTemplateDrawer = ({isOpen, handleClose, refetch}) => {
  const {data, loading} = useQuery(ImportDocumentTemplateDrawer.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
  });
  const [previewedDocumentTemplate, setPreviewedDocumentTemplate] = useState(null);

  return (
    <Drawer isOpen={isOpen} onClose={handleClose}>
      <DrawerContainer>
        {previewedDocumentTemplate && (
          <DocumentTemplatePreviewContainer>
            <Header
              text={`Preview: ${previewedDocumentTemplate.name}`}
              handleClose={() => {
                setPreviewedDocumentTemplate();
              }}
            />
            <Line />
            <Container>
              <DocumentTemplatePreview documentTemplateUuid={previewedDocumentTemplate.uuid} />
            </Container>
          </DocumentTemplatePreviewContainer>
        )}
        <ImportDocumentTemplateDrawerContainer>
          <Header
            text={'Document Library'}
            handleClose={() => {
              handleClose();
              setPreviewedDocumentTemplate();
            }}
          />
          <Line />
          <Loading loading={loading} as={PageLoadingIndicator}>
            {() => (
              <ImportDocumentTemplateDrawerContent
                organizationSlug={data.viewer.viewingOrganization.slug}
                organizations={data.organizations}
                setPreviewedDocumentTemplate={setPreviewedDocumentTemplate}
                previewedDocumentTemplate={previewedDocumentTemplate}
                handleClose={handleClose}
                refetch={refetch}
              />
            )}
          </Loading>
        </ImportDocumentTemplateDrawerContainer>
      </DrawerContainer>
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentTemplateTable.query = gql`
  query DocumentTemplateTable($slug: String!) {
    ${gql.query}
    organization(slug: $slug) {
      id
      documentTemplates: documentTemplatesWithActiveDocumentTemplateVersion {
        id
        name
        uuid
        identifier
        activeOrMostRecentDocumentTemplateVersion {
          id
        }
      }
    }
  }
`;

ImportDocumentTemplateDrawer.query = gql`
query ImportDocumentTemplateDrawer {
    ${gql.query}
        viewer {
            id
            viewingOrganization {
                id
                slug
            }
        }
        organizations {
            id
            name
            slug
        }
  }
`;

export default ImportDocumentTemplateDrawer;
