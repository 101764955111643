// Libraries
import React from 'react';

// Supermove
import {Link, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography} from '@supermove/styles';

// App
import DashboardCategory from '@shared/modules/Reports/enums/DashboardCategory';
import {PageLoadingIndicator} from 'modules/App/components';
import SidebarPage from 'modules/App/components/SidebarPage';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';

const Container = Styled.View`
  flex: 1;
`;

const EmptyStateContainer = Styled.View`
  flex: 1;
  align-items: center;
`;

const EmptyStateTitle = Styled.Text`
  ${Typography.Label}
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const RootDashboardPage = () => {
  return (
    <SidebarPage selected={'reports'} query={RootDashboardPage.query}>
      {({navigator, data}) => {
        const dashboards = (data?.organization?.dashboards || []).filter(
          (dashboard) => dashboard.category === DashboardCategory.GENERAL,
        );

        if (dashboards.length === 0) {
          return (
            <Container>
              <StandardOfficeHeader title={'Reports'} />
              <EmptyStateContainer>
                <Space height={80} />
                <EmptyStateTitle>There are no reports to show.</EmptyStateTitle>
                <Space height={8} />
                <EmptyStateTitle>
                  Manage your reports in the{' '}
                  <Link to={`/settings/reports`}>
                    <LinkText>Settings page</LinkText>
                  </Link>
                  .
                </EmptyStateTitle>
              </EmptyStateContainer>
            </Container>
          );
        }

        const dashboardId = dashboards[0].uuid;
        navigator.replace(`/reports/dashboards/${dashboardId}`);
        return (
          <Container>
            <StandardOfficeHeader title={'Reports'} />
            <PageLoadingIndicator />
          </Container>
        );
      }}
    </SidebarPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RootDashboardPage.query = gql`
  query RootDashboardPage {
    ${gql.query}
    organization {
      id
      dashboards {
        id
        uuid
        category
      }
    }
  }
`;

export default RootDashboardPage;
