// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import TipOptionForm from '@shared/modules/Organization/forms/TipOptionForm';

const edit = withFragment(
  (organization) => ({
    organizationId: organization.id,
    tipOptionForms: JSON.parse(organization.settings.tipOptions).map((tipOption) =>
      TipOptionForm.new(tipOption),
    ),
  }),
  gql`
    fragment OrganizationTipOptionsForm_edit on Organization {
      id
      settings {
        id
        tipOptions
      }
    }
  `,
);

const toForm = ({organizationId, tipOptionForms}) => ({
  organizationId,
  tipOptionForms: tipOptionForms.map((tipOptionForm) => TipOptionForm.toForm(tipOptionForm)),
});

const toMutation = ({organizationId, tipOptionForms}) => ({
  organizationId,
  tipOptionForms: tipOptionForms.map((tipOptionForm) => TipOptionForm.toMutation(tipOptionForm)),
});

const OrganizationTipOptionsForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationTipOptionsForm;
