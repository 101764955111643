// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({organizationId}) => ({
  organizationId,
  claimTypeId: null,
  name: '',
  color: '',
  description: '',
});

const edit = withFragment(
  (claimType) => ({
    claimTypeId: claimType.id,
    organizationId: claimType.organizationId,
    name: claimType.name,
    color: claimType.color,
    description: claimType.description,
  }),
  gql`
    fragment ClaimTypeForm_edit on ClaimType {
      id
      organizationId
      name
      color
      description
    }
  `,
);

const toForm = ({claimTypeId, organizationId, name, color, description}) => ({
  claimTypeId,
  organizationId,
  name,
  color,
  description,
});

const toMutation = ({claimTypeId, organizationId, name, color, description}) => ({
  claimTypeId,
  organizationId,
  name,
  color,
  description,
});

const ClaimTypeForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ClaimTypeForm;
