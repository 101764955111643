// Libraries
import * as Sentry from '@sentry/react';
import React from 'react';

// Components
import App from './App';
// Assets
import '@supermove/styles/css/Global.css';
import '@supermove/styles/css/Avenir.css';
import '@supermove/styles/css/Rouge.css';

const AppWrapper = (props) => <App />;

export default Sentry.withProfiler(AppWrapper);
