// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import CreateMoverPositionForm from '@shared/modules/User/forms/CreateMoverPositionForm';

const useCreateMoverPositionMutation = ({createMoverPositionForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      createMoverPositionForm: CreateMoverPositionForm.toForm(createMoverPositionForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateMoverPositionMutation.mutation,
    variables: {
      createMoverPositionForm: CreateMoverPositionForm.toMutation(
        form.values.createMoverPositionForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateMoverPositionMutation.mutation = gql`
  mutation useCreateMoverPositionMutation($createMoverPositionForm: CreateMoverPositionForm!) {
    response: createMoverPosition(createMoverPositionForm: $createMoverPositionForm) {
      ${gql.errors}
      moverPosition {
        id
      }
    }
  }
`;

export default useCreateMoverPositionMutation;
