// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';

// App
import Switch from '@shared/design/components/Switch';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import BillingProjectBillsList from 'modules/Project/Billing/components/BillingProjectBillsList';
import ProjectBillingPaymentsSection from 'modules/Project/V2/Show/components/ProjectBillingPaymentsSection';

const Row = Styled.View`
  flex-direction: row;
`;

const LoadingComponent = () => {
  return (
    <React.Fragment>
      <SkeletonLoader width={112} height={20} />
      <Space height={16} />
      <SkeletonLoader isFullWidth height={160} />
      <Space height={16} />
      <SkeletonLoader isFullWidth height={160} />
      <Space height={16} />
      <Row style={{justifyContent: 'flex-end'}}>
        <SkeletonLoader width={200} height={44} />
      </Row>
      <Space height={16} />
      <Row style={{justifyContent: 'flex-end'}}>
        <SkeletonLoader width={232} height={44} />
      </Row>
      <Space height={16} />
      <SkeletonLoader isFullWidth height={160} />
    </React.Fragment>
  );
};

const ProjectBills = ({
  project,
  refetch,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  isExpandAllBills,
  setIsExpandAllBills,
  isEnabledPaymentActions,
}) => {
  const responsive = useResponsive();
  const [isReordering, setIsReordering] = useState(false);

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <Switch isOn={isReordering} onChange={setIsReordering} labelRight={'Reorder Bills'} />
      ) : (
        <Switch
          isOn={isExpandAllBills}
          onChange={setIsExpandAllBills}
          labelRight={'Expand All Bills'}
          isMobile
        />
      )}
      <Space height={16} />
      <BillingProjectBillsList
        key={`${project.billsHash}_${project.bills.length}`}
        project={project}
        refetch={refetch}
        isEditable
        isReordering={isReordering}
        isCollapsed={!isExpandAllBills}
        descriptionField={descriptionField}
        getTruncatedKey={getTruncatedKey}
        truncated={truncated}
        setTruncated={setTruncated}
        enabledToggle={enabledToggle}
        setEnabledToggle={setEnabledToggle}
      />
      <Space height={16} />
      <ProjectBillingPaymentsSection
        project={project}
        refetch={refetch}
        isEnabledPaymentActions={isEnabledPaymentActions}
      />
    </React.Fragment>
  );
};

ProjectBills.SkeletonLoader = LoadingComponent;

// --------------------------------------------------
// Props
// --------------------------------------------------
ProjectBills.propTypes = {
  isExpandAllBills: PropTypes.bool,
  isEnabledPaymentActions: PropTypes.bool,
};

ProjectBills.defaultProps = {
  isExpandAllBills: true,
  isEnabledPaymentActions: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectBills.fragment = gql`
  ${BillingProjectBillsList.fragment}
  ${ProjectBillingPaymentsSection.fragment}

  fragment ProjectBills on Project {
    id
    billsHash
    bills {
      id
    }
    ...BillingProjectBillsList
    ...ProjectBillingPaymentsSection
  }
`;

export default ProjectBills;
