// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Datetime} from '@supermove/utils';

const edit = withFragment(
  (task) => ({
    taskId: task.id,
    dueAtDate: task.dueAt,
    dueAtTime: task.dueAt ? Datetime.fromDatetimeToUtc(task.dueAt) : '23:59',
  }),
  gql`
    fragment TaskDueAtForm_edit on Task {
      id
      dueAt
    }
  `,
);

const toForm = ({taskId, dueAtDate, dueAtTime}) => ({
  taskId,
  dueAtDate: Datetime.toFormDate(dueAtDate),
  dueAtTime: Datetime.toFormTime(dueAtTime),
});

const toMutation = ({taskId, dueAtDate, dueAtTime}) => {
  return {
    taskId,
    dueAtDate: dueAtDate ? Datetime.toMutationDate(dueAtDate) : null,
    dueAtTime: Datetime.toMutationTime(dueAtTime),
  };
};

const TaskForm = {
  edit,
  toForm,
  toMutation,
};

export default TaskForm;
