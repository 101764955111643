// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({organizationId, category}) => ({
  organizationId,
  category,
  projectTypeId: null,
  name: '',
  color: '',
  billingLibraryId: null,
  billTypeIds: [],
  isLongDistance: false,
  isJobLevelBilling: false,
});

const edit = withFragment(
  (projectType) => ({
    projectTypeId: projectType.id,
    category: projectType.category,
    organizationId: projectType.organizationId,
    billingLibraryId: String(projectType.billingLibraryId),
    color: projectType.color,
    name: projectType.name,
    billTypeIds: projectType.activeBillTypeIds,
    isLongDistance: projectType.isLongDistance,
    isJobLevelBilling: projectType.isJobLevelBilling,
  }),
  gql`
    fragment ProjectTypeForm_edit on ProjectType {
      id
      category
      organizationId
      billingLibraryId
      color
      name
      activeBillTypeIds
      isLongDistance
      isJobLevelBilling
    }
  `,
);

const toForm = ({
  projectTypeId,
  index,
  organizationId,
  category,
  billingLibraryId,
  color,
  name,
  billTypeIds,
  isLongDistance,
  isJobLevelBilling,
}) => ({
  projectTypeId,
  index,
  organizationId,
  category,
  billingLibraryId,
  color,
  name,
  billTypeIds,
  isLongDistance,
  isJobLevelBilling,
});

const toMutation = ({
  projectTypeId,
  index,
  organizationId,
  category,
  billingLibraryId,
  color,
  name,
  billTypeIds,
  isLongDistance,
  isJobLevelBilling,
}) => ({
  projectTypeId,
  index,
  organizationId,
  category,
  billingLibraryId,
  color,
  name,
  billTypeIds,
  isLongDistance,
  isJobLevelBilling,
});

const ProjectTypeForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ProjectTypeForm;
