const CARTON = 'CARTON';
const ITEM = 'ITEM';

const getLabel = (kind) => {
  switch (kind) {
    case CARTON:
      return 'Carton';
    case ITEM:
      return 'Item';
    default:
      return '';
  }
};

const ItemTypeKind = {
  CARTON,
  ITEM,
  getLabel,
};

export default ItemTypeKind;
