// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const ALL_PROJECT_TYPES = 'ALL_PROJECT_TYPES';

export const CodatIntegrationKind = {
  CODAT: 'CODAT',
  RUTTER: 'RUTTER',
};

const _new = ({organizationId, kind}) => ({
  kind: kind || CodatIntegrationKind.CODAT,
  codatIntegrationId: null,
  organizationId,
  defaultExternalInvoiceItemId: null,
  customExternalInvoiceItemId: null,
  shouldExportInvoiceSectionTitles: true,
  invoiceSectionExternalInvoiceItemId: null,
  tipExternalInvoiceItemId: null,
  salesTaxExternalInvoiceItemId: null,
  paymentMethodFeeExternalInvoiceItemId: null,
  isAutomaticExportEnabled: false,
  batchKickoffHour: null,
  isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes: false,
  projectTypeIdsToEnqueueForExportUponInvoiceFinalized: [],
  isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes: false,
  projectTypeIdsToEnqueueForExportUponPaymentRecorded: [],
  status: null,

  // Private
  isProjectTypeIdsToEnqueueForExportUponInvoiceFinalizedEnabled: false,
  isProjectTypeIdsToEnqueueForExportUponPaymentRecordedEnabled: false,
  projectTrackingCategorySourceVariableId: null,
  codatAccountReferenceJson: null,
  customSalesTaxRateVariableId: null,
  isEnabledCustomSalesTaxExports: false,

  // Rutter
  rutterPublicToken: null,
});

const edit = withFragment(
  (codatIntegration) => ({
    codatIntegrationId: codatIntegration.id,
    organizationId: codatIntegration.organizationId,
    defaultExternalInvoiceItemId: codatIntegration.defaultExternalInvoiceItemId,
    customExternalInvoiceItemId: codatIntegration.customExternalInvoiceItemId,
    shouldExportInvoiceSectionTitles: codatIntegration.shouldExportInvoiceSectionTitles,
    invoiceSectionExternalInvoiceItemId: codatIntegration.invoiceSectionExternalInvoiceItemId,
    tipExternalInvoiceItemId: codatIntegration.tipExternalInvoiceItemId,
    salesTaxExternalInvoiceItemId: codatIntegration.salesTaxExternalInvoiceItemId,
    paymentMethodFeeExternalInvoiceItemId: codatIntegration.paymentMethodFeeExternalInvoiceItemId,
    isAutomaticExportEnabled: codatIntegration.isAutomaticExportEnabled,
    batchKickoffHour: codatIntegration.batchKickoffHour,
    isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes:
      codatIntegration.isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes,
    projectTypeIdsToEnqueueForExportUponInvoiceFinalized:
      codatIntegration.projectTypeIdsToEnqueueForExportUponInvoiceFinalized,
    isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes:
      codatIntegration.isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes,
    projectTypeIdsToEnqueueForExportUponPaymentRecorded:
      codatIntegration.projectTypeIdsToEnqueueForExportUponPaymentRecorded,
    kind: codatIntegration.kind,
    // Private
    isProjectTypeIdsToEnqueueForExportUponInvoiceFinalizedEnabled:
      codatIntegration.isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes ||
      !!codatIntegration.projectTypeIdsToEnqueueForExportUponInvoiceFinalized.length,
    isProjectTypeIdsToEnqueueForExportUponPaymentRecordedEnabled:
      codatIntegration.isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes ||
      !!codatIntegration.projectTypeIdsToEnqueueForExportUponPaymentRecorded.length,
    projectTrackingCategorySourceVariableId:
      codatIntegration.projectTrackingCategorySourceVariableId,
    codatAccountReferenceJson: codatIntegration.codatAccountReferenceJson,
    isEnabledCustomSalesTaxExports: codatIntegration.isEnabledCustomSalesTaxExports,
    customSalesTaxRateVariableId: codatIntegration.customSalesTaxRateVariableId,
    rutterPublicToken: codatIntegration.rutterPublicToken,
    status: codatIntegration.status,
  }),
  gql`
    fragment CodatIntegrationForm_edit on CodatIntegration {
      id
      organizationId
      defaultExternalInvoiceItemId
      codatAccountReferenceJson
      customExternalInvoiceItemId
      shouldExportInvoiceSectionTitles
      invoiceSectionExternalInvoiceItemId
      tipExternalInvoiceItemId
      salesTaxExternalInvoiceItemId
      paymentMethodFeeExternalInvoiceItemId
      isAutomaticExportEnabled
      batchKickoffHour
      isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes
      projectTypeIdsToEnqueueForExportUponInvoiceFinalized
      isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes
      projectTypeIdsToEnqueueForExportUponPaymentRecorded
      projectTrackingCategorySourceVariableId
      customSalesTaxRateVariableId
      isEnabledCustomSalesTaxExports
      rutterPublicToken
      kind
      status
    }
  `,
);

const toForm = ({
  codatIntegrationId,
  organizationId,
  defaultExternalInvoiceItemId,
  customExternalInvoiceItemId,
  shouldExportInvoiceSectionTitles,
  invoiceSectionExternalInvoiceItemId,
  tipExternalInvoiceItemId,
  salesTaxExternalInvoiceItemId,
  paymentMethodFeeExternalInvoiceItemId,
  isAutomaticExportEnabled,
  batchKickoffHour,
  isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes,
  projectTypeIdsToEnqueueForExportUponInvoiceFinalized = [],
  isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes,
  projectTypeIdsToEnqueueForExportUponPaymentRecorded = [],

  // Private
  isProjectTypeIdsToEnqueueForExportUponInvoiceFinalizedEnabled,
  isProjectTypeIdsToEnqueueForExportUponPaymentRecordedEnabled,
  projectTrackingCategorySourceVariableId,
  codatAccountReferenceJson,
  customSalesTaxRateVariableId,
  isEnabledCustomSalesTaxExports,
  // Rutter
  rutterPublicToken,
  kind,
  status,
}) => {
  return {
    codatIntegrationId,
    organizationId,
    defaultExternalInvoiceItemId: _.toString(defaultExternalInvoiceItemId),
    customExternalInvoiceItemId: _.toString(customExternalInvoiceItemId),
    shouldExportInvoiceSectionTitles,
    isAutomaticExportEnabled,
    invoiceSectionExternalInvoiceItemId: _.toString(invoiceSectionExternalInvoiceItemId),
    tipExternalInvoiceItemId: _.toString(tipExternalInvoiceItemId),
    salesTaxExternalInvoiceItemId: _.toString(salesTaxExternalInvoiceItemId),
    paymentMethodFeeExternalInvoiceItemId: _.toString(paymentMethodFeeExternalInvoiceItemId),
    batchKickoffHour,
    isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes,
    projectTypeIdsToEnqueueForExportUponInvoiceFinalized:
      isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes
        ? [ALL_PROJECT_TYPES]
        : projectTypeIdsToEnqueueForExportUponInvoiceFinalized.map((id) => _.toString(id)),
    isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes,
    projectTypeIdsToEnqueueForExportUponPaymentRecorded:
      isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes
        ? [ALL_PROJECT_TYPES]
        : projectTypeIdsToEnqueueForExportUponPaymentRecorded.map((id) => _.toString(id)),

    // Private
    isProjectTypeIdsToEnqueueForExportUponInvoiceFinalizedEnabled,
    isProjectTypeIdsToEnqueueForExportUponPaymentRecordedEnabled,
    projectTrackingCategorySourceVariableId,
    codatAccountReferenceJson,
    customSalesTaxRateVariableId,
    isEnabledCustomSalesTaxExports,
    // Rutter
    rutterPublicToken,
    kind,
    status,
  };
};

const toMutation = ({
  codatIntegrationId,
  organizationId,
  defaultExternalInvoiceItemId,
  customExternalInvoiceItemId,
  shouldExportInvoiceSectionTitles,
  invoiceSectionExternalInvoiceItemId,
  salesTaxExternalInvoiceItemId,
  tipExternalInvoiceItemId,
  paymentMethodFeeExternalInvoiceItemId,
  isAutomaticExportEnabled,
  batchKickoffHour,
  isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes,
  projectTypeIdsToEnqueueForExportUponInvoiceFinalized,
  isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes,
  projectTypeIdsToEnqueueForExportUponPaymentRecorded,
  projectTrackingCategorySourceVariableId,
  codatAccountReferenceJson,
  customSalesTaxRateVariableId,
  isEnabledCustomSalesTaxExports,
  rutterPublicToken,
  kind,
  status,
}) => {
  return {
    codatIntegrationId,
    organizationId,
    batchKickoffHour,
    isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes,
    projectTypeIdsToEnqueueForExportUponInvoiceFinalized:
      isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes
        ? []
        : projectTypeIdsToEnqueueForExportUponInvoiceFinalized.map((id) => _.toNumber(id)),
    isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes,
    projectTypeIdsToEnqueueForExportUponPaymentRecorded:
      isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes
        ? []
        : projectTypeIdsToEnqueueForExportUponPaymentRecorded.map((id) => _.toNumber(id)),
    defaultExternalInvoiceItemId: defaultExternalInvoiceItemId
      ? _.toNumber(defaultExternalInvoiceItemId)
      : null,
    customExternalInvoiceItemId: customExternalInvoiceItemId
      ? _.toNumber(customExternalInvoiceItemId)
      : null,
    shouldExportInvoiceSectionTitles,
    isAutomaticExportEnabled,
    invoiceSectionExternalInvoiceItemId: invoiceSectionExternalInvoiceItemId
      ? _.toNumber(invoiceSectionExternalInvoiceItemId)
      : null,
    tipExternalInvoiceItemId: tipExternalInvoiceItemId
      ? _.toNumber(tipExternalInvoiceItemId)
      : null,
    salesTaxExternalInvoiceItemId: salesTaxExternalInvoiceItemId
      ? _.toNumber(salesTaxExternalInvoiceItemId)
      : null,
    paymentMethodFeeExternalInvoiceItemId: paymentMethodFeeExternalInvoiceItemId
      ? _.toNumber(paymentMethodFeeExternalInvoiceItemId)
      : null,
    projectTrackingCategorySourceVariableId,
    codatAccountReferenceJson,
    customSalesTaxRateVariableId,
    isEnabledCustomSalesTaxExports,
    // Rutter
    rutterPublicToken,
    kind,
    status,
  };
};

const CodatIntegrationForm = {
  new: _new,
  toForm,
  toMutation,
  edit,

  ALL_PROJECT_TYPES,
};

export default CodatIntegrationForm;
