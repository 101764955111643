// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useResponsive, useState, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import Button from '@shared/design/components/Button';
import Callout from '@shared/design/components/Callout';
import Tabs from '@shared/design/components/Tabs';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import ProjectTypeForm from '@shared/modules/Project/forms/ProjectTypeForm';
import useCreateProjectTypeMutation from '@shared/modules/Project/hooks/useCreateProjectTypeMutation';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import CreateProjectTypeModal from 'modules/Organization/Settings/ProjectTypes/components/CreateProjectTypeModal';
import CreateProjectTypeSuccessModal from 'modules/Organization/Settings/ProjectTypes/components/CreateProjectTypeSuccessModal';
import ProjectTypesTable from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypesTable';
import OrganizationSettingsTagsContent from 'modules/Organization/Settings/Tag/components/OrganizationSettingsTagsContent';
import SettingsContent from 'modules/Organization/Settings/components/SettingsContent';

const PageContentContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const PageContainer = Styled.View`
  flex: 1;
`;

const PageHeaderText = Styled.Text`
  ${Typography.PageHeading}
`;

const PageDescriptionText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const TabsContainer = Styled.View`
  margin-horizontal: 24px;
`;

const ProjectTypePageBreadcrumb = ({isStorageSettingsPage, isEnabledProjectTagSetting}) => {
  const {navigator} = useNavigationDOM();
  const storageBreadcrumbs = [
    {name: 'Settings', onPress: () => navigator.push('/settings')},
    {name: 'Storage', onPress: () => navigator.push('/settings/storage')},
    {name: isEnabledProjectTagSetting ? 'Projects' : 'Project Types'},
  ];
  const breadcrumbs = isStorageSettingsPage
    ? storageBreadcrumbs
    : storageBreadcrumbs.filter((breadcrumb) => breadcrumb.name !== 'Storage');

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};

const OrganizationSettingsProjectTypesContent = ({
  organization,
  refetch,
  isReordering,
  setIsReordering,
  isStorageSettingsPage,
}) => {
  const {navigator} = useNavigationDOM();
  const createProjectTypeModal = useModal({name: 'Create Project Type Modal'});
  const createProjectTypeSuccessModal = useModal({name: 'Create Project Type Success Modal'});
  const projectTypeForm = ProjectTypeForm.new({
    organizationId: organization.id,
    category: isStorageSettingsPage ? ProjectTypeCategory.STORAGE : ProjectTypeCategory.MOVE,
  });

  const {form, handleSubmit, submitting} = useCreateProjectTypeMutation({
    projectTypeForm,
    onSuccess: ({projectType}) => {
      refetch();
      form.setFieldValue(`projectTypeForm.projectTypeUuid`, projectType.uuid);
      createProjectTypeModal.handleClose();
      createProjectTypeSuccessModal.handleOpen();
    },
    onError: (errors) => console.log({errors}),
  });

  // Primary actions for CreateProjectTypeSuccessModal
  const handleCreateAnotherProjectType = () => {
    createProjectTypeSuccessModal.handleClose();
    form.resetForm();
    createProjectTypeModal.handleOpen();
  };
  const projectTypeUuid = _.get(form.values, `projectTypeForm.projectTypeUuid`);
  const handleViewProjectType = () => {
    navigator.push(`/settings/project-types/${projectTypeUuid}/job-types`);
  };

  const handleCloseCreateProjectTypeSuccessModal = () => {
    createProjectTypeSuccessModal.handleClose();
    form.resetForm();
  };

  return (
    <React.Fragment>
      {organization.isOwnerOfSettings && (
        <React.Fragment>
          <Button
            iconLeft={Icon.Plus}
            text={'Create Project Type'}
            onPress={createProjectTypeModal.handleOpen}
          />
          <Space height={12} />
        </React.Fragment>
      )}
      <CreateProjectTypeModal
        key={createProjectTypeModal.key}
        isOpen={createProjectTypeModal.isOpen}
        handleClose={() => {
          createProjectTypeModal.handleClose();
          form.resetForm();
        }}
        organizationId={organization.id}
        form={form}
        handleSubmit={handleSubmit}
        submitting={submitting}
        isStorageSettingsPage={isStorageSettingsPage}
      />
      <CreateProjectTypeSuccessModal
        key={createProjectTypeSuccessModal.key}
        form={form}
        field={'projectTypeForm'}
        isOpen={createProjectTypeSuccessModal.isOpen}
        handleClose={handleCloseCreateProjectTypeSuccessModal}
        handlePrimaryAction={
          isStorageSettingsPage ? handleCreateAnotherProjectType : handleViewProjectType
        }
        primaryActionText={isStorageSettingsPage ? 'Create Another' : 'View Project Type'}
        secondaryActionText={isStorageSettingsPage ? 'Back to Settings' : 'Back to List'}
      />
      <PageHeaderText>Project Types</PageHeaderText>
      <Space height={12} />
      <PageDescriptionText>
        Select the name of your project type to add, view or configure: Job Types, Billing,
        Variables and Valuation Coverage.
      </PageDescriptionText>
      <Space height={16} />
      {organization.isOwnerOfSettings ? (
        <ScrollView horizontal>
          <ProjectTypesTable
            organization={organization}
            refetch={refetch}
            isReordering={isReordering}
            setIsReordering={setIsReordering}
            isStorageSettingsPage={isStorageSettingsPage}
          />
        </ScrollView>
      ) : (
        <React.Fragment>
          <Callout
            text={`Project types are set by the company. Please contact the admins at ${organization.company.primaryOrganization.name} to make adjustments.`}
            width={780}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const getTabIndex = ({navigator, tabs}) => {
  const {location} = navigator;

  const splitLocationPath = location.pathname.split('/');

  return _.findIndex(tabs, {key: _.last(splitLocationPath)});
};

const getTabDefinitions = ({organization, isStorageSettingsPage}) => {
  const projectTypesTab = {
    key: 'project-types',
    label: 'Project Types',
    link: '/settings/project-types',
  };
  const tagsTab = {
    key: 'tags',
    label: 'Tags',
    link: '/settings/project-types/tags',
  };
  return [
    projectTypesTab,
    ...List.insertIf(
      organization.features.isEnabledProjectTagSetting && !isStorageSettingsPage,
      tagsTab,
    ),
  ];
};

const TabHeader = ({navigator, organization, isStorageSettingsPage}) => {
  const tabDefinitions = getTabDefinitions({organization, isStorageSettingsPage});
  const activeTabIndex = getTabIndex({navigator, tabs: tabDefinitions});

  return (
    <TabsContainer>
      <Tabs
        tabs={tabDefinitions}
        handlePressTab={({link}) => navigator.replace(link)}
        activeTabIndex={activeTabIndex}
      />
    </TabsContainer>
  );
};

const getTabContent = ({
  location,
  organization,
  refetch,
  isReordering,
  setIsReordering,
  isStorageSettingsPage,
}) => {
  const splitLocationPath = location.pathname.split('/');
  const tabPath = _.last(splitLocationPath);

  // Based on last path name we selective choose which content to render
  switch (tabPath) {
    case 'tags':
      return (
        <OrganizationSettingsTagsContent
          organization={organization}
          isStorageSettingsPage={isStorageSettingsPage}
        />
      );
    default:
      return (
        <OrganizationSettingsProjectTypesContent
          organization={organization}
          refetch={refetch}
          isReordering={isReordering}
          setIsReordering={setIsReordering}
          isStorageSettingsPage={isStorageSettingsPage}
        />
      );
  }
};

const OrganizationSettingsProjectTypes = () => {
  const {navigator} = useNavigationDOM();
  const {location} = navigator;
  const responsive = useResponsive();
  const [isReordering, setIsReordering] = useState(false);

  // Determine whether this is a storage project types page from the route
  const isStorageSettingsPage = location.pathname.includes('/storage');

  const {loading, data, refetch} = useQuery(OrganizationSettingsProjectTypes.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      category: isStorageSettingsPage ? ProjectTypeCategory.STORAGE : ProjectTypeCategory.MOVE,
    },
  });

  return (
    <SidebarPageV2 selected={'settings'}>
      <Loading loading={loading && !data} as={PageLoadingIndicator}>
        {() => {
          return (
            <PageContainer>
              <StandardOfficeHeader title={''} showLine={false}>
                <ProjectTypePageBreadcrumb
                  isStorageSettingsPage={isStorageSettingsPage}
                  isEnabledProjectTagSetting={
                    data.viewer.viewingOrganization.features.isEnabledProjectTagSetting
                  }
                />
              </StandardOfficeHeader>
              <TabHeader
                navigator={navigator}
                organization={data.viewer.viewingOrganization}
                isStorageSettingsPage={isStorageSettingsPage}
              />
              <Line />
              <PageContentContainer>
                <ScrollView style={{flex: 1}}>
                  <SettingsContent {...responsive} style={{marginTop: responsive.mobile ? 8 : 24}}>
                    {getTabContent({
                      location,
                      organization: data.viewer.viewingOrganization,
                      refetch,
                      isReordering,
                      setIsReordering,
                      isStorageSettingsPage,
                    })}
                  </SettingsContent>
                </ScrollView>
              </PageContentContainer>
            </PageContainer>
          );
        }}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsProjectTypes.query = gql`
  ${ProjectTypesTable.fragment}

  query OrganizationSettingsProjectTypes($category: String) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledProjectTagSetting: isEnabled(feature: "PROJECT_TAG_SETTING")
        }
        isOwnerOfSettings
        company {
          id
          primaryOrganization {
            id
            name
          }
        }
        projectTypesForCategory(category: $category) {
          id
          uuid
        }
        ...ProjectTypesTable
      }
    }
  }
`;

export default OrganizationSettingsProjectTypes;
