// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import RecurringTaskCadenceKind from '@shared/modules/Accounting/enums/RecurringTaskCadenceKind';
import RecurringTaskCadenceRepeatsOn from '@shared/modules/Accounting/enums/RecurringTaskCadenceRepeatsOn';
import RecurringTaskCadenceRepeatsOnKind from '@shared/modules/Accounting/enums/RecurringTaskCadenceRepeatsOnKind';
import RecurringTaskForm from '@shared/modules/Accounting/forms/RecurringTaskForm';
import useUpsertRecurringTaskMutation from '@shared/modules/Accounting/hooks/useUpsertRecurringTaskMutation';
import Line from 'modules/App/components/Line';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  width: 100%;
  z-index: ${(props) => 100 - props.index};
`;

const ReportCycleModalLeftSideTextContainer = Styled.View`
  width: 100px;
`;

const ReportCycleModalText = Styled.Text`
  ${Typography.Body2};
`;

const handleChangeCadenceKind = ({cadenceKind, form}) => {
  // Reset cadenceInterval when cadenceKind changes
  form.setFieldValue('recurringTaskForm.cadenceInterval', 1);

  // Set cadenceRepeatsOn accordingly
  if (cadenceKind === RecurringTaskCadenceKind.WEEKLY) {
    form.setFieldValue(
      'recurringTaskForm.cadenceRepeatsOnKind',
      RecurringTaskCadenceRepeatsOnKind.WEEKDAY,
    );
    form.setFieldValue(
      'recurringTaskForm.cadenceRepeatsOn',
      RecurringTaskCadenceRepeatsOn.CADENCE_REPEATS_ON_WEEKDAY_MAP.MONDAY.value,
    );
  } else if (cadenceKind === RecurringTaskCadenceKind.MONTHLY) {
    form.setFieldValue(
      'recurringTaskForm.cadenceRepeatsOnKind',
      RecurringTaskCadenceRepeatsOnKind.EXACT_DATE,
    );
    form.setFieldValue('recurringTaskForm.cadenceRepeatsOn', 1);
  } else if (cadenceKind === RecurringTaskCadenceKind.BI_MONTHLY) {
    form.setFieldValue(
      'recurringTaskForm.cadenceRepeatsOnKind',
      RecurringTaskCadenceRepeatsOnKind.FIRST_AND_FIFTEENTH,
    );
    form.setFieldValue('recurringTaskForm.cadenceRepeatsOn', null);
  }
};

const UpsertReportCycleModalContent = ({form}) => {
  return (
    <React.Fragment>
      <Row index={1}>
        <ReportCycleModalLeftSideTextContainer>
          <ReportCycleModalText>Repeat every</ReportCycleModalText>
        </ReportCycleModalLeftSideTextContainer>
        <Space width={16} />
        <FieldInput
          {...form}
          index={1}
          name={'recurringTaskForm.cadenceInterval'}
          component={DropdownInput}
          style={{flex: 1}}
          input={{
            disabled:
              form.values.recurringTaskForm.cadenceKind === RecurringTaskCadenceKind.BI_MONTHLY,
            options: _.map(_.range(1, 13), (interval) => ({
              label: _.toString(interval),
              value: interval,
            })),
            style: {flex: 1},
            setFieldValue: form.setFieldValue,
            components: {
              IndicatorSeparator: () => null,
            },
          }}
        />
        <Space width={16} />
        <FieldInput
          {...form}
          index={2}
          name={'recurringTaskForm.cadenceKind'}
          component={DropdownInput}
          style={{flex: 2}}
          input={{
            options: RecurringTaskCadenceKind.VALUES.map((cadenceKind) => ({
              label: RecurringTaskCadenceKind.getDisplayKind(cadenceKind),
              value: cadenceKind,
            })),
            style: {flex: 1},
            setFieldValue: form.setFieldValue,
            onChangeValue: (cadenceKind) => handleChangeCadenceKind({cadenceKind, form}),
            components: {
              IndicatorSeparator: () => null,
            },
          }}
        />
      </Row>
      <Space height={20} />
      <Row index={2}>
        <ReportCycleModalLeftSideTextContainer>
          <ReportCycleModalText>Repeat on</ReportCycleModalText>
        </ReportCycleModalLeftSideTextContainer>
        <Space width={16} />
        <FieldInput
          {...form}
          index={3}
          name={'recurringTaskForm.cadenceRepeatsOn'}
          component={DropdownInput}
          style={{flex: 1}}
          input={{
            disabled: form.values.recurringTaskForm.cadenceKind !== RecurringTaskCadenceKind.WEEKLY,
            options: RecurringTaskCadenceRepeatsOn.getCadenceRepeatsOnOptions(
              form.values.recurringTaskForm.cadenceRepeatsOnKind,
            ),
            style: {flex: 1},
            setFieldValue: form.setFieldValue,
            components: {
              IndicatorSeparator: () => null,
            },
          }}
        />
      </Row>
    </React.Fragment>
  );
};

const UpsertReportCycleModal = ({
  recurringTask,
  organizationId,
  recurringTaskKind,
  isOpen,
  handleClose,
  refetch,
}) => {
  const recurringTaskForm = RecurringTaskForm.upsert(recurringTask, {
    organizationId,
    kind: recurringTaskKind,
  });
  const {form, handleSubmit} = useUpsertRecurringTaskMutation({
    recurringTaskForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose} style={{overflow: 'visible'}}>
      <SmallModal.BoldText>Report Recurrence</SmallModal.BoldText>
      <Space height={20} />
      <UpsertReportCycleModalContent form={form} />
      <Space height={20} />
      <Line />
      <Space height={20} />
      <ReportCycleModalText>
        Next report cycle starts on{' '}
        {RecurringTaskCadenceKind.getNextReportCycleDate(
          form.values.recurringTaskForm.cadenceKind,
          {cadenceRepeatsOn: form.values.recurringTaskForm.cadenceRepeatsOn},
        )}
      </ReportCycleModalText>
      <Space height={20} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button color={colors.blue.interactive} onPress={handleSubmit}>
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpsertReportCycleModal.fragment = gql`
  ${RecurringTaskForm.upsert.fragment}

  fragment UpsertReportCycleModal on RecurringTask {
    id
    ...RecurringTaskForm_upsert
  }
`;

export default UpsertReportCycleModal;
