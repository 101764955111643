// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import AssignContainersToProjectForm from '@shared/modules/Storage/forms/AssignContainersToProjectForm';

const useAssignContainersToProjectMutation = ({
  assignContainersToProjectForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      assignContainersToProjectForm: AssignContainersToProjectForm.toForm(
        assignContainersToProjectForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useAssignContainersToProjectMutation.mutation,
    variables: {
      assignContainersToProjectForm: AssignContainersToProjectForm.toMutation(
        form.values.assignContainersToProjectForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useAssignContainersToProjectMutation.mutation = gql`
  mutation AssignContainersToProjectMutation($assignContainersToProjectForm: AssignContainersToProjectForm!) {
    response: assignContainersToProject(assignContainersToProjectForm: $assignContainersToProjectForm) {
      ${gql.errors}
      project {
        id
      }
    }
  }
`;

export default useAssignContainersToProjectMutation;
