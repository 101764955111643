// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Popover, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover, useState, useHover} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import Table from '@shared/design/components/Table';
import WebhookKind from '@shared/modules/Integration/enums/WebhookKind';
import WebhookForm from '@shared/modules/Integration/forms/WebhookForm';
import useDeleteWebhookMutation from '@shared/modules/Integration/hooks/useDeleteWebhookMutation';
import useUpdateWebhookMutation from '@shared/modules/Integration/hooks/useUpdateWebhookMutation';
import DeleteModal from 'modules/App/components/DeleteModal';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import Switch from 'modules/App/components/Switch';
import EditOrganizationWebhookModal from 'modules/Organization/Settings/Integrations/components/EditOrganizationWebhookModal';

const Row = Styled.View`
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const Description = Styled.Text`
  ${Typography.Micro}
`;

const HeaderText = Styled.Text`
  ${Typography.Label1}
;`;

const ActionButton = Styled.ButtonV2`
  min-width: 16px;
  align-items: center;
`;

const MenuItemButton = Styled.ButtonV2`
  background-color: ${({isHighlighted}) => (isHighlighted ? colors.hover : 'transparent')};
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  padding-vertical: 8px;
  padding-horizontal: 16px;
`;

const MenuItem = ({onPress, children}) => {
  const {isHovered, ref} = useHover();
  return (
    <MenuItemButton onPress={onPress} isHighlighted={isHovered} ref={ref}>
      <MenuItemText>{children}</MenuItemText>
    </MenuItemButton>
  );
};

const ActionsMenuPopover = ({popover, onDelete}) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={160}>
        <Space height={8} />
        <MenuItem
          onPress={() => {
            onDelete();
            popover.handleClose();
          }}
        >
          Delete
        </MenuItem>
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const EllipseButton = ({webhook, refetch}) => {
  const deleteWebhookModal = useModal({name: 'Delete Organization Webhook Modal'});
  const handleDeleteClick = () => {
    deleteWebhookModal.handleOpen();
  };
  const actionsPopover = usePopover();

  return (
    <React.Fragment>
      <Popover.Content innerRef={actionsPopover.ref}>
        <ActionButton onPress={actionsPopover.handleToggle}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </ActionButton>
      </Popover.Content>
      <ActionsMenuPopover popover={actionsPopover} onDelete={handleDeleteClick} />
      <DeleteWebhookModal
        key={deleteWebhookModal.key}
        deleteWebhookModal={deleteWebhookModal}
        webhook={webhook}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const handleToggle = ({form, field}) => {
  const isActive = _.get(form.values, `${field}.isActive`);
  form.setFieldValue(`${field}.isActive`, !isActive);
};

const ToggleSwitch = ({webhook, refetch}) => {
  const webhookForm = WebhookForm.edit(webhook);

  const {form, handleSubmit} = useUpdateWebhookMutation({
    webhookForm,
    onSuccess: () => {
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <Switch
      isOn={form.values.webhookForm.isActive}
      color={colors.blue.interactive}
      size={Switch.SIZE.MEDIUM}
      onChange={() => {
        handleToggle({form, field: 'webhookForm'});
        setTimeout(() => handleSubmit(), 0);
      }}
    />
  );
};

const DeleteWebhookModal = ({deleteWebhookModal, webhook, refetch}) => {
  const webhookForm = WebhookForm.edit(webhook);
  const {handleSubmit} = useDeleteWebhookMutation({
    webhookForm,
    onSuccess: () => {
      deleteWebhookModal.handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={deleteWebhookModal.isOpen}
      title={'Are you sure you want to delete this webhook?'}
      subtitle={"You can't undo this action."}
      handleClose={deleteWebhookModal.handleClose}
      handleConfirm={handleSubmit}
    />
  );
};

const webhooksTableColumnDefinitions = ({refetch, handleEditClick}) => [
  // Webhook Toggle
  {
    width: 100,
    headerContent: () => {
      return null;
    },
    cellContent: ({item: webhook}) => {
      return <ToggleSwitch webhook={webhook} refetch={refetch} />;
    },
  },
  // Name
  {
    width: 500,
    headerContent: () => {
      return <HeaderText>Name</HeaderText>;
    },
    cellContent: ({item: webhook}) => {
      return (
        <Row>
          <Text numberOfLines={1}>{webhook.name}</Text>
          {webhook.description ? (
            <Description numberOfLines={1}>{webhook.description}</Description>
          ) : null}
        </Row>
      );
    },
  },
  // Event
  {
    width: 150,
    headerContent: () => {
      return <HeaderText>Event</HeaderText>;
    },
    cellContent: ({item: webhook}) => {
      return <Text>{WebhookKind.getDisplayKind(webhook.kind)}</Text>;
    },
  },
  // URL
  {
    width: 250,
    headerContent: () => {
      return <HeaderText>URL</HeaderText>;
    },
    cellContent: ({item: webhook}) => {
      return <Text numberOfLines={1}>{webhook.url}</Text>;
    },
  },
  // Actions
  {
    width: 100,
    headerContent: () => {
      return <HeaderText>Actions</HeaderText>;
    },
    cellContent: ({item: webhook}) => {
      return (
        <React.Fragment>
          <ActionButton onPress={() => handleEditClick(webhook)}>
            <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
          </ActionButton>
          <Space width={12} />
          <EllipseButton webhook={webhook} refetch={refetch} />
        </React.Fragment>
      );
    },
  },
];

const WebhooksTable = ({webhooks, refetch, loading}) => {
  const [selectedWebhook, setSelectedWebhook] = useState(null);
  const editOrganizationWebhookModal = useModal({name: 'Edit Organization Webhook Modal'});

  const handleEditClick = (webhook) => {
    editOrganizationWebhookModal.handleOpen();
    setSelectedWebhook(webhook);
  };

  return (
    <React.Fragment>
      <ScrollView horizontal>
        <Table
          columnDefinitions={webhooksTableColumnDefinitions({
            refetch,
            handleEditClick,
          })}
          emptyStateText='No webhooks'
          items={webhooks}
          isOpen={editOrganizationWebhookModal.isOpen}
          loading={loading}
        />
      </ScrollView>
      {selectedWebhook && (
        <EditOrganizationWebhookModal
          key={editOrganizationWebhookModal.key}
          webhook={selectedWebhook}
          isOpen={editOrganizationWebhookModal.isOpen}
          handleClose={editOrganizationWebhookModal.handleClose}
          refetch={refetch}
        />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WebhooksTable.fragment = gql`
  ${EditOrganizationWebhookModal.fragment}
  ${WebhookForm.edit.fragment}

  fragment WebhooksTable on Webhook {
    id
    organizationId
    kind
    url
    isActive
    headers
    name
    description
    ...EditOrganizationWebhookModal
    ...WebhookForm_edit
  }
`;

export default WebhooksTable;
