// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {BillRuleForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useState, useTextInput} from '@supermove/hooks';
import {BillRule} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import EditBillLineItemCells from 'modules/Project/Billing/components/EditBillLineItemCells';

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
`;

const KindLabelContainer = Styled.View`
  padding-horizontal: 8px;
  padding-top: ${(props) => (props.index > 0 ? 7 : 6)}px;
`;

const DescriptionButton = Styled.Touchable`
  position: absolute;
  top: 7px;
  right: 8px;
`;

const Checkbox = Styled.Touchable`
  height: 18px;
  width: 18px;
  border-radius: 2px;
  border-width: 1px;
  border-color: ${(props) => (props.isSelected ? colors.blue.interactive : colors.gray.tertiary)};
  background-color: ${(props) => (props.isSelected ? colors.blue.interactive : 'transparent')};
  align-items: center;
  justify-content: center;
`;

const removeFormItem = (form, field, index) => {
  const billRuleForms = _.get(form.values, field);
  const updatedBillRuleForms = _.remove(billRuleForms, (form, i) => i !== index);
  form.setFieldValue(field, updatedBillRuleForms);
};

const NameColumn = ({form, billRuleForm, index, handleShowDescription}) => {
  const field = `billForm.billRuleForms.${index}.name`;
  const hasError = _.get(form.errors, field);
  const handleChangeText = (text) => {
    form.setFieldValue(field, text);
  };
  const isEditableName = BillRule.getIsEditableName(billRuleForm);
  return (
    <EditBillLineItemCells.LeftDataCell
      isLarge
      hasBorder
      hasError={hasError}
      isFirstItem={index === 0}
      disabled={!isEditableName}
    >
      <KindLabelContainer index={index}>
        <EditBillLineItemCells.CellCaption>
          {BillRule.getDisplayKind(billRuleForm)}
        </EditBillLineItemCells.CellCaption>
      </KindLabelContainer>
      <EditBillLineItemCells.NameInput
        value={billRuleForm.name}
        handleChangeText={handleChangeText}
        disabled={!isEditableName}
      />
      <DescriptionButton activeOpacity={0.8} onPress={handleShowDescription}>
        <EditBillLineItemCells.CellButtonText>+ Description</EditBillLineItemCells.CellButtonText>
      </DescriptionButton>
    </EditBillLineItemCells.LeftDataCell>
  );
};

const ValueColumn = ({form, billRuleForm, index}) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleChangeText = (text) => {
    form.setFieldValue(
      `billForm.billRuleForms.${index}.values`,
      BillRuleForm.getFormValues({...billRuleForm, values: [text]}),
    );
  };
  const isEditableValue = BillRule.getIsEditableValue(billRuleForm);

  return (
    <EditBillLineItemCells.DataCell
      hasBorder
      isFirstItem={index === 0}
      color={isEditableValue ? colors.white : colors.gray.border}
    >
      {!isFocused && (
        <EditBillLineItemCells.CellText vars={{hasPad: true}}>
          {BillRuleForm.getDisplayValues(billRuleForm)}
        </EditBillLineItemCells.CellText>
      )}
      {isEditableValue && (
        <EditBillLineItemCells.CellInput
          vars={{isFocused}}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChangeText={handleChangeText}
          defaultValue={_.get(billRuleForm, 'values.0')}
        />
      )}
    </EditBillLineItemCells.DataCell>
  );
};

const ActionColumn = ({billRuleForm, form, index}) => {
  const handleShowCustomerToggle = () => {
    const field = `billForm.billRuleForms.${index}.isVisibleToCustomer`;
    form.setFieldValue(field, !billRuleForm.isVisibleToCustomer);
  };
  return (
    <EditBillLineItemCells.RightDataCell hasPad hasBorder isFirstItem={index === 0}>
      <Checkbox
        isSelected={billRuleForm.isVisibleToCustomer}
        activeOpacity={0.8}
        onPress={handleShowCustomerToggle}
      >
        <Icon source={Icon.Check} size={12} color={colors.white} />
      </Checkbox>
    </EditBillLineItemCells.RightDataCell>
  );
};

const DeleteItemColumn = ({form, index}) => {
  const handleRemoveItem = () => {
    removeFormItem(form, 'billForm.billRuleForms', index);
  };
  return (
    <EditBillLineItemCells.IconCell>
      <Space height={3} />
      <EditBillLineItemCells.IconCircle
        activeOpacity={0.8}
        color={colors.gray.tertiary}
        onPress={handleRemoveItem}
      >
        <Icon source={Icon.Trash} size={12} color={colors.gray.tertiary} />
      </EditBillLineItemCells.IconCircle>
    </EditBillLineItemCells.IconCell>
  );
};

const EditBillBillRulesListItem = ({form, billRuleForm, index}) => {
  const descriptionInput = useTextInput();
  const [isShowingDescription, setIsShowingDescription] = useState(!!billRuleForm.description);
  const [inputHeight, setInputHeight] = useState();
  const [isUpdatingDescription, setIsUpdatingDescription] = useState(false);
  const handleUpdateForm = (text) => {
    form.setFieldValue(`billForm.billRuleForms.${index}.description`, text);
  };
  const handleOnFocus = () => {
    setIsUpdatingDescription(true);
  };
  const handleOnBlur = () => {
    setIsUpdatingDescription(false);
  };
  const isEditableDescription = BillRule.getIsEditableDescription(billRuleForm);

  return (
    <Container>
      <Row>
        <NameColumn
          form={form}
          billRuleForm={billRuleForm}
          index={index}
          handleShowDescription={() => {
            setIsShowingDescription(true);
            descriptionInput.handleFocus();
          }}
        />
        <ValueColumn form={form} billRuleForm={billRuleForm} index={index} />
        <ActionColumn billRuleForm={billRuleForm} form={form} index={index} />
        <Space width={2} />
        <DeleteItemColumn form={form} index={index} />
      </Row>
      {isShowingDescription && (
        <EditBillLineItemCells.FullWidthCell>
          <EditBillLineItemCells.DescriptionInputLabel>
            Description
          </EditBillLineItemCells.DescriptionInputLabel>
          <EditBillLineItemCells.DescriptionInput
            ref={descriptionInput.ref}
            value={billRuleForm.description}
            onChangeText={handleUpdateForm}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onContentSizeChange={(event) => setInputHeight(event.nativeEvent.contentSize.height)}
            height={inputHeight}
            vars={{isUpdating: isUpdatingDescription}}
            multiline
            disabled={!isEditableDescription}
          />
        </EditBillLineItemCells.FullWidthCell>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillBillRulesListItem.fragment = gql`
  ${BillRule.getIsEditableDescription.fragment}
  ${BillRule.getIsEditableName.fragment}
  ${BillRule.getIsEditableValue.fragment}

  fragment EditBillBillRulesListItem on BillRule {
    name
    description
    ...BillRule_getIsEditableDescription
    ...BillRule_getIsEditableName
    ...BillRule_getIsEditableValue
  }
`;

export default EditBillBillRulesListItem;
