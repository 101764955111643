import {colors} from '@supermove/styles';

const WORK = 'work';
const DRIVE = 'drive';
const BREAK = 'break';
const WAIT = 'wait';

const KIND_TO_DISPLAY = {
  [WORK]: 'Work',
  [DRIVE]: 'Drive',
  [BREAK]: 'Break',
  [WAIT]: 'Wait',
};

const KIND_TO_COLOR = {
  [WORK]: colors.green.status,
  [DRIVE]: colors.yellow.hover,
  [BREAK]: colors.gray.secondary,
  [WAIT]: colors.blue.accentDark,
};

const KIND_TO_BACKGROUND_COLOR = {
  [WORK]: colors.green.accent,
  [DRIVE]: colors.yellow.accent,
  [BREAK]: colors.gray.background,
  [WAIT]: colors.blue.accent,
};

const Values = [WORK, DRIVE, BREAK, WAIT];
const DropdownOptions = Values.map((kind) => ({
  value: kind,
  label: KIND_TO_DISPLAY[kind],
  color: KIND_TO_COLOR[kind],
}));

const getDisplay = (kind) => KIND_TO_DISPLAY[kind];
const getColor = (kind) => KIND_TO_COLOR[kind];
const getBackgroundColor = (kind) => KIND_TO_BACKGROUND_COLOR[kind];

export default {
  WORK,
  DRIVE,
  BREAK,
  WAIT,

  KIND_TO_DISPLAY,
  KIND_TO_COLOR,

  Values,
  DropdownOptions,

  getDisplay,
  getColor,
  getBackgroundColor,
};
