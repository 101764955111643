// Libraries
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (projectTypeDay) => ({
    projectTypeDayId: projectTypeDay.id,
    isSalesLocked: projectTypeDay.isSalesLocked,
  }),
  gql`
    fragment ProjectTypeDay_edit on ProjectTypeDay {
      id
      isSalesLocked
    }
  `,
);

const toForm = ({projectTypeDayId, isSalesLocked}) => ({
  isSalesLocked,
  projectTypeDayId,
});

const toMutation = ({projectTypeDayId, isSalesLocked}) => ({
  isSalesLocked,
  projectTypeDayId,
});

const ProjectTypeDayForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectTypeDayForm;
