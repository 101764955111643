// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Percent, withFragment} from '@supermove/utils';

const edit = withFragment(
  (salesTax) => ({
    salesTaxId: salesTax.id,
    rate: salesTax.rate,
    variableId: salesTax.variableId,
    projectId: salesTax.projectId,
  }),
  gql`
    fragment SalesTaxForm_edit on SalesTax {
      id
      rate
      variableId
      projectId
    }
  `,
);

const toForm = ({salesTaxId, rate, variableId, projectId}) => ({
  salesTaxId,
  rate: Percent.toForm(rate),
  variableId: _.toString(variableId),
  projectId,
});

const toMutation = ({salesTaxId, rate, variableId, projectId}) => ({
  salesTaxId,
  rate: rate ? Percent.toMutation(rate) : null,
  variableId: variableId ? _.toNumber(variableId) : null,
  projectId,
});

const SalesTaxForm = {
  edit,
  toForm,
  toMutation,
};

export default SalesTaxForm;
