// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ConfirmationStepsForm from '@shared/modules/Proposal/forms/ConfirmationStepsForm';

const useUpdateConfirmationStepsStepsJsonMutation = ({
  confirmationStepsForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      confirmationStepsForm: ConfirmationStepsForm.toForm(confirmationStepsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateConfirmationStepsStepsJsonMutation.mutation,
    variables: {
      confirmationStepsForm: ConfirmationStepsForm.toMutation(form.values.confirmationStepsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateConfirmationStepsStepsJsonMutation.mutation = gql`
  mutation useUpdateConfirmationStepsStepsJsonMutation($confirmationStepsForm: ConfirmationStepsForm!) {
    response: updateConfirmationStepsStepsJson(confirmationStepsForm: $confirmationStepsForm) {
      ${gql.errors}
      confirmationSteps {
        id
      }
    }
  }
`;

export default useUpdateConfirmationStepsStepsJsonMutation;
