// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import DefaultProjectTypeInventorySettingsForm from '@shared/modules/Inventory/forms/DefaultProjectTypeInventorySettingsForm';

const useSetDefaultProjectTypeDriverInventorySettingsMutation = ({
  defaultProjectTypeInventorySettingsForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      defaultProjectTypeInventorySettingsForm: DefaultProjectTypeInventorySettingsForm.toForm(
        defaultProjectTypeInventorySettingsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSetDefaultProjectTypeDriverInventorySettingsMutation.mutation,
    variables: {
      defaultProjectTypeInventorySettingsForm: DefaultProjectTypeInventorySettingsForm.toMutation(
        form.values.defaultProjectTypeInventorySettingsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSetDefaultProjectTypeDriverInventorySettingsMutation.mutation = gql`
  mutation useSetDefaultProjectTypeDriverInventorySettingsMutation($defaultProjectTypeInventorySettingsForm: DefaultProjectTypeInventorySettingsForm!) {
    response: setDefaultProjectTypeDriverInventorySettings(defaultProjectTypeInventorySettingsForm: $defaultProjectTypeInventorySettingsForm) {
      ${gql.errors}
    }
  }
`;

export default useSetDefaultProjectTypeDriverInventorySettingsMutation;
