// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

const edit = withFragment(
  (project) => ({
    projectId: project.id,
    followUpDate: project.followUpDate,
  }),
  gql`
    fragment ProjectFollowUpDateForm_edit on Project {
      id
      followUpDate
    }
  `,
);

const toForm = ({projectId, followUpDate}) => ({
  projectId,
  followUpDate: followUpDate ? Datetime.fromDate(followUpDate) : null,
});

const toMutation = ({projectId, followUpDate}) => ({
  projectId,
  followUpDate: followUpDate ? Datetime.toDate(followUpDate) : null,
});

const ProjectFollowUpDateForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectFollowUpDateForm;
