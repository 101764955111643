// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (category) => ({
    categoryId: category.id,
    organizationId: category.organizationId,
    kind: category.kind,
    name: category.name,
    isShared: category.isShared,
    inventoryLibraryId: category.inventoryLibraryId,
    isDeleted: category.isDeleted,

    // private
    isDirty: false,
  }),
  gql`
    fragment CategoryForm_edit on Category {
      id
      organizationId
      kind
      name
      isShared
      inventoryLibraryId
      isDeleted
    }
  `,
);

// only v2 upsert categories logic will have an inventoryLibraryId
const _new = ({organizationId, inventoryLibraryId = null}) => ({
  categoryId: undefined,
  organizationId,
  inventoryLibraryId,
  kind: '',
  name: '',
  isShared: true,
  isDeleted: false,

  // private
  isDirty: true,
});

const toForm = ({
  categoryId,
  organizationId,
  kind,
  name,
  isShared,
  inventoryLibraryId,
  isDeleted,

  // private
  isDirty,
}) => ({
  categoryId,
  organizationId,
  kind,
  name,
  isShared,
  inventoryLibraryId,
  isDeleted,

  // private
  isDirty,
});

const toMutation = ({
  categoryId,
  organizationId,
  kind,
  name,
  isShared,
  inventoryLibraryId,
  isDeleted,
}) => ({
  categoryId,
  organizationId,
  kind,
  name,
  isShared,
  inventoryLibraryId,
  isDeleted,
});

const CategoryForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default CategoryForm;
