// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import DayForm from '@shared/modules/Scheduling/forms/DayForm';

const useUpdateDayMutation = ({dayForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      dayForm: DayForm.toForm(dayForm),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateDayMutation.mutation,
    variables: {
      dayForm: DayForm.toMutation(form.values.dayForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateDayMutation.mutation = gql`
  mutation useUpdateDayMutation($dayForm: DayForm!) {
    response: updateDay(dayForm: $dayForm) {
      ${gql.errors}
      day {
        id
        notes
      }
    }
  }
`;

export default useUpdateDayMutation;
