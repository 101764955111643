// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useHover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import ProjectDateKind from '@shared/modules/Project/enums/ProjectDateKind';
import ProjectDatesForm from '@shared/modules/Project/forms/ProjectDatesForm';
import useUpdateProjectDatesMutation from '@shared/modules/Project/hooks/useUpdateProjectDatesMutation';
import Field from 'modules/App/components/Field';
import TextTooltip from 'modules/App/components/TextTooltip';

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index}px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  padding-horizontal: 16px;
`;

const Title = Styled.Text`
  ${Typography.Label1}
`;

const HeaderRow = Styled.View`
  flex-direction: row;
`;

const IconContainer = Styled.View`
  width: 16px;
  padding-top: 6px;
`;

const FieldContainer = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const getShortLabel = (job) => {
  if (job.day) {
    return Datetime.convertToDisplayDate(job.day.value, 'M/D/YY');
  } else if (job.startDate && job.endDate) {
    return `${Datetime.convertToDisplayDate(
      job.startDate,
      'M/D/YY',
    )} - ${Datetime.convertToDisplayDate(job.endDate, 'M/D/YY')}`;
  } else {
    return 'TBD';
  }
};

const getDateOptions = ({project}) => {
  return project.activeMoveJobs.map((job) => {
    return {
      value: job.uuid,
      label: getShortLabel(job),
      menuLabel: job.shortName,
    };
  });
};

const RowSpace = () => {
  const responsive = useResponsive();
  return <Space height={responsive.mobile ? 8 : 16} />;
};

const ProjectDateEditor = ({project, projectDateKind, index, refetch}) => {
  const {isHovered, ref: hoverRef} = useHover();
  const projectDatesForm = ProjectDatesForm.edit(project, {projectDateKind});
  const {form, handleSubmit} = useUpdateProjectDatesMutation({
    projectDatesForm,
    onSuccess: () => {},
    onError: (errors) => console.log(errors),
  });

  return (
    <React.Fragment>
      <FieldValue label={`${_.capitalize(projectDateKind)} Date`} value={null} />
      <FieldContainer ref={hoverRef} index={index}>
        <Field
          {...form}
          component={DropdownInput}
          name={'projectDatesForm.jobUuid'}
          input={{
            options: getDateOptions({project}),
            placeholder: 'None selected',
            setFieldValue: form.setFieldValue,
            isDiscreet: true,
            isHovered,
            onChangeValue: (value, _option, prevValue) => {
              if (value !== prevValue) {
                setTimeout(() => handleSubmit(), 0);
                refetch();
              }
            },
          }}
        />
      </FieldContainer>
    </React.Fragment>
  );
};

const JobProjectDatesBlock = ({sectionIndex, job, refetch}) => {
  return (
    <Section index={sectionIndex}>
      <Space height={12} />
      <HeaderRow>
        <Title>Project Dates</Title>
        <Space width={6} />
        <TextTooltip
          placement={'top'}
          text={`These dates are automatically set based on your job type settings and only appear in long distance projects. You can override this by hovering over the dates.`}
          style={{maxWidth: '250px'}}
        >
          <IconContainer>
            <Icon color={colors.gray.tertiary} size={Icon.Sizes.Small} source={Icon.InfoCircle} />
          </IconContainer>
        </TextTooltip>
      </HeaderRow>
      <RowSpace />
      {_.map(ProjectDateKind.VALUES, (projectDateKind, index) => (
        <React.Fragment key={projectDateKind}>
          <ProjectDateEditor
            project={job.project}
            projectDateKind={projectDateKind}
            index={index}
            refetch={refetch}
          />
          <RowSpace />
        </React.Fragment>
      ))}
    </Section>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectDatesBlock.fragment = gql`
  ${ProjectDatesForm.edit.fragment}

  fragment JobProjectDatesBlock on Job {
    id
    project {
      id
      activeMoveJobs {
        id
        uuid
        shortName
        startDate
        endDate
        day {
          id
          value
        }
      }
      ...ProjectDatesForm_edit
    }
  }
`;

export default JobProjectDatesBlock;
