const _new = ({id, property, stringValue, integerValue, handleMutationIntegerValue}) => ({
  id,
  property,
  stringValue,
  integerValue,

  // Private
  handleMutationIntegerValue,
});

const toForm = ({id, property, stringValue, integerValue, handleMutationIntegerValue}) => ({
  id,
  property,
  stringValue,
  integerValue,

  // Private
  handleMutationIntegerValue,
});

const toMutation = ({id, property, stringValue, integerValue, handleMutationIntegerValue}) => ({
  id,
  property,
  stringValue,
  integerValue: handleMutationIntegerValue
    ? handleMutationIntegerValue(integerValue)
    : integerValue,
});

const UpdateSinglePropertyForm = {
  new: _new,
  toForm,
  toMutation,
};

export default UpdateSinglePropertyForm;
