// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CompleteDocumentV2Form from '@shared/modules/Document/forms/CompleteDocumentV2Form';

const useCompleteDocumentV2Mutation = ({completeDocumentV2Form, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      completeDocumentV2Form: CompleteDocumentV2Form.toForm(completeDocumentV2Form),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCompleteDocumentV2Mutation.mutation,
    variables: {
      completeDocumentV2Form: CompleteDocumentV2Form.toMutation(form.values.completeDocumentV2Form),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCompleteDocumentV2Mutation.mutation = gql`
  mutation useCompleteDocumentV2Mutation($completeDocumentV2Form: CompleteDocumentV2Form!) {
    response: completeDocumentV2(completeDocumentV2Form: $completeDocumentV2Form) {
      ${gql.errors}
      document {
        id
        isCompleted
      }
    }
  }
`;

export default useCompleteDocumentV2Mutation;
