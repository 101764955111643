// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import OrganizationLogoForm from '@shared/modules/Organization/forms/OrganizationLogoForm';

// App

const useUpdateOrganizationLogoMutation = ({organizationLogoForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      organizationLogoForm: OrganizationLogoForm.toForm(organizationLogoForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationLogoMutation.mutation,
    variables: {
      organizationLogoForm: OrganizationLogoForm.toMutation(form.values.organizationLogoForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationLogoMutation.mutation = gql`
  ${OrganizationLogoForm.edit.fragment}
  mutation useUpdateOrganizationLogoMutation($organizationLogoForm: OrganizationLogoForm!) {
    response: updateOrganizationLogo(organizationLogoForm: $organizationLogoForm) {
      ${gql.errors}
      organization {
        id
        ...OrganizationLogoForm_edit
      }
    }
  }
`;

export default useUpdateOrganizationLogoMutation;
