// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {HTML, withFragment} from '@supermove/utils';

// App
import EmailTemplateBodyKind from '@shared/modules/Email/enums/EmailTemplateBodyKind';
import EmailTemplateRecipientForm from '@shared/modules/Email/forms/EmailTemplateRecipientForm';

const getCustomRecipientOptions = (emailTemplate) => {
  const customRecipients = [];
  emailTemplate.toEmailTemplateRecipients.forEach((recipient) => {
    if (recipient.email) {
      customRecipients.push({value: recipient.email, label: recipient.email});
    }
  });
  emailTemplate.ccEmailTemplateRecipients.forEach((recipient) => {
    if (recipient.email) {
      customRecipients.push({value: recipient.email, label: recipient.email});
    }
  });
  emailTemplate.bccEmailTemplateRecipients.forEach((recipient) => {
    if (recipient.email) {
      customRecipients.push({value: recipient.email, label: recipient.email});
    }
  });
  return customRecipients;
};

const EmailTemplateForm = {
  toMutation: ({
    emailTemplateId,
    organizationId,
    kind,
    name,
    toEmailTemplateRecipientForms,
    ccEmailTemplateRecipientForms,
    bccEmailTemplateRecipientForms,
    subject,
    body,
    bodyKind,
    attachmentIds,
  }) => ({
    emailTemplateId,
    organizationId,
    kind,
    name,
    toEmailTemplateRecipientForms: toEmailTemplateRecipientForms.map((form) =>
      EmailTemplateRecipientForm.toMutation(form),
    ),
    ccEmailTemplateRecipientForms: ccEmailTemplateRecipientForms.map((form) =>
      EmailTemplateRecipientForm.toMutation(form),
    ),
    bccEmailTemplateRecipientForms: bccEmailTemplateRecipientForms.map((form) =>
      EmailTemplateRecipientForm.toMutation(form),
    ),
    subject,
    body: bodyKind === EmailTemplateBodyKind.WYSIWYG ? HTML.fixRichTextEditor(body) : body,
    bodyKind,
    attachmentIds,
  }),

  toForm: ({
    emailTemplateId,
    organizationId,
    kind,
    name,
    toEmailTemplateRecipientForms,
    ccEmailTemplateRecipientForms,
    bccEmailTemplateRecipientForms,
    subject,
    body,
    bodyKind,
    attachmentIds,
    isShowingVariables,
    isShowingCc,
    isShowingBcc,
    toEmailTemplateRecipientValues,
    ccEmailTemplateRecipientValues,
    bccEmailTemplateRecipientValues,
    searchInputValue,
    customRecipientOptions,
  }) => ({
    emailTemplateId,
    organizationId,
    kind,
    name,
    toEmailTemplateRecipientForms: toEmailTemplateRecipientForms.map((form) =>
      EmailTemplateRecipientForm.toForm(form),
    ),
    ccEmailTemplateRecipientForms: ccEmailTemplateRecipientForms.map((form) =>
      EmailTemplateRecipientForm.toForm(form),
    ),
    bccEmailTemplateRecipientForms: bccEmailTemplateRecipientForms.map((form) =>
      EmailTemplateRecipientForm.toForm(form),
    ),
    subject,
    body: bodyKind === EmailTemplateBodyKind.WYSIWYG ? HTML.fixRichTextEditor(body) : body,
    bodyKind,
    attachmentIds,

    // Private
    isShowingVariables,
    isShowingCc,
    isShowingBcc,
    toEmailTemplateRecipientValues,
    ccEmailTemplateRecipientValues,
    bccEmailTemplateRecipientValues,
    searchInputValue,
    customRecipientOptions,
  }),

  edit: withFragment(
    (emailTemplate) => ({
      emailTemplateId: emailTemplate.id,
      organizationId: emailTemplate.organizationId,
      kind: emailTemplate.kind,
      name: emailTemplate.name,
      toEmailTemplateRecipientForms: emailTemplate.toEmailTemplateRecipients.map((recipient) =>
        EmailTemplateRecipientForm.edit(recipient),
      ),
      ccEmailTemplateRecipientForms: emailTemplate.ccEmailTemplateRecipients.map((recipient) =>
        EmailTemplateRecipientForm.edit(recipient),
      ),
      bccEmailTemplateRecipientForms: emailTemplate.bccEmailTemplateRecipients.map((recipient) =>
        EmailTemplateRecipientForm.edit(recipient),
      ),
      subject: emailTemplate.subject,
      body: emailTemplate.body,
      bodyKind: emailTemplate.bodyKind,
      attachmentIds: emailTemplate.emailTemplateAttachments.map(
        (emailTemplateAttachment) => emailTemplateAttachment.attachment.id,
      ),

      // Private
      isShowingVariables: false,
      isShowingCc: _.size(emailTemplate.ccEmailTemplateRecipients) > 0,
      isShowingBcc: _.size(emailTemplate.bccEmailTemplateRecipients) > 0,
      // TODO(dan) There should be a way to fully utilize recipient forms in the multidropdown
      // and not have to use these recipient values.
      toEmailTemplateRecipientValues: emailTemplate.toEmailTemplateRecipients.map((recipient) =>
        EmailTemplateRecipientForm.getRecipientValue(recipient),
      ),
      ccEmailTemplateRecipientValues: emailTemplate.ccEmailTemplateRecipients.map((recipient) =>
        EmailTemplateRecipientForm.getRecipientValue(recipient),
      ),
      bccEmailTemplateRecipientValues: emailTemplate.bccEmailTemplateRecipients.map((recipient) =>
        EmailTemplateRecipientForm.getRecipientValue(recipient),
      ),
      searchInputValue: '',
      customRecipientOptions: getCustomRecipientOptions(emailTemplate),
    }),
    gql`
      ${EmailTemplateRecipientForm.edit.fragment}

      fragment EmailTemplateForm_edit on EmailTemplate {
        id
        organizationId
        kind
        name
        toEmailTemplateRecipients {
          ...EmailTemplateRecipientForm_edit
        }
        ccEmailTemplateRecipients {
          ...EmailTemplateRecipientForm_edit
        }
        bccEmailTemplateRecipients {
          ...EmailTemplateRecipientForm_edit
        }
        subject
        body
        bodyKind
        emailTemplateAttachments {
          id
          attachment {
            id
          }
        }
      }
    `,
  ),

  new: ({organizationId}) => ({
    organizationId,
    kind: '',
    name: '',
    toEmailTemplateRecipientForms: [],
    ccEmailTemplateRecipientForms: [],
    bccEmailTemplateRecipientForms: [],
    subject: '',
    body: '',
    bodyKind: EmailTemplateBodyKind.WYSIWYG,
    attachmentIds: [],

    // Private
    isShowingVariables: false,
    isShowingCc: false,
    isShowingBcc: false,
    toEmailTemplateRecipientValues: [],
    ccEmailTemplateRecipientValues: [],
    bccEmailTemplateRecipientValues: [],
    searchInputValue: '',
    customRecipientOptions: [],
  }),
};

export default EmailTemplateForm;
