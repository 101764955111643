// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Popover, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import Table from '@shared/design/components/Table';
import UserRole from '@shared/modules/User/enums/UserRole';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import CreateMoverPositionModal from 'modules/Organization/Settings/Users/List/components/CreateMoverPositionModal';
import DeleteMoverPositionModal from 'modules/Organization/Settings/Users/List/components/DeleteMoverPositionModal';
import UpdateMoverPositionModal from 'modules/Organization/Settings/Users/List/components/UpdateMoverPositionModal';

const Text = Styled.Text`
  ${Typography.Body3}
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
;`;

const ActionsCell = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 5px;
`;

const EditButton = Styled.ButtonV2`
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const getPositionColumnDefinitions = ({refetch}) => [
  {
    width: 350,
    headerContent: () => {
      return <TableHeaderText>Position Name</TableHeaderText>;
    },
    cellContent: ({item: moverPosition}) => {
      return <Text>{moverPosition.name}</Text>;
    },
  },
  {
    width: 150,
    headerContent: () => {
      return <TableHeaderText>Can Drive Truck?</TableHeaderText>;
    },
    cellContent: ({item: moverPosition}) => {
      if (moverPosition.isDriver) {
        return <Icon color={colors.green.status} size={16} source={Icon.Check} />;
      }
      return null;
    },
  },
  {
    width: 100,
    headerContent: () => {
      return <TableHeaderText>Actions</TableHeaderText>;
    },
    cellContent: ({item: moverPosition}) => {
      return <MoverPositionActions moverPosition={moverPosition} refetch={refetch} />;
    },
  },
];

const MoverPositionActionsMenu = ({popover, deleteMoverPositionModal}) => {
  return (
    <ActionMenuPopover popover={popover} placement={Popover.Positions.BottomStart} width={208}>
      <ActionMenuPopover.MenuItem
        onPress={() => {
          deleteMoverPositionModal.handleOpen();
          popover.handleClose();
        }}
      >
        Remove
      </ActionMenuPopover.MenuItem>
    </ActionMenuPopover>
  );
};

const MoverPositionActions = ({moverPosition, refetch}) => {
  const updateMoverPositionModal = useModal({name: 'Update Mover Position Modal'});
  const deleteMoverPositionModal = useModal({name: 'Delete Mover Position Modal'});
  const moverPositionActionsPopover = usePopover();

  return (
    <React.Fragment>
      <ActionsCell>
        <EditButton onPress={updateMoverPositionModal.handleOpen}>
          <Icon color={colors.blue.interactive} size={16} source={Icon.Pen} />
        </EditButton>
        <Space width={12} />
        <Popover.Content innerRef={moverPositionActionsPopover.ref}>
          <MoreActionsButton onPress={moverPositionActionsPopover.handleOpen}>
            <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
          </MoreActionsButton>
        </Popover.Content>
      </ActionsCell>
      <MoverPositionActionsMenu
        popover={moverPositionActionsPopover}
        deleteMoverPositionModal={deleteMoverPositionModal}
      />
      <UpdateMoverPositionModal
        key={updateMoverPositionModal.key}
        isOpen={updateMoverPositionModal.isOpen}
        handleClose={updateMoverPositionModal.handleClose}
        moverPosition={moverPosition}
        refetch={refetch}
      />
      <DeleteMoverPositionModal
        key={deleteMoverPositionModal.key}
        isOpen={deleteMoverPositionModal.isOpen}
        handleClose={deleteMoverPositionModal.handleClose}
        moverPositionId={moverPosition.id}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const StaffMoverPositionsSection = ({organization, refetch}) => {
  const createMoverPositionModal = useModal({name: 'Create Mover Position Modal'});

  const moverPositions = organization.features.isEnabledMoverPositionMultibranch
    ? Organization.getCompanySettingsMoverPositionsByRole({organization, role: UserRole.EMPLOYEE})
    : organization.moverPositions;

  return (
    <React.Fragment>
      <Button text='Create Position' onPress={createMoverPositionModal.handleOpen} />
      <Space height={16} />
      <ScrollView>
        <Table
          columnDefinitions={getPositionColumnDefinitions({refetch})}
          emptyStateText={'No positions to display'}
          items={moverPositions}
          containerStyle={{width: '650px'}}
          isDense
        />
      </ScrollView>
      <CreateMoverPositionModal
        key={createMoverPositionModal.key}
        isOpen={createMoverPositionModal.isOpen}
        handleClose={createMoverPositionModal.handleClose}
        organizationId={organization.id}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
StaffMoverPositionsSection.propTypes = {
  organization: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

StaffMoverPositionsSection.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
StaffMoverPositionsSection.fragment = gql`
  ${UpdateMoverPositionModal.fragment}
  ${Organization.getCompanySettingsMoverPositionsByRole.fragment}

  fragment StaffMoverPositionsSection on Organization {
    id
    moverPositions {
      id
      name
      isDriver
      ...UpdateMoverPositionModal
    }
    companySettings {
      moverPositions {
        id
        isDriver
      }
    }
    features {
      isEnabledMoverPositionMultibranch: isEnabled(feature: "MOVER_POSITION_MULTIBRANCH")
    }
    ...Organization_getCompanySettingsMoverPositionsByRole
  }
`;

export default StaffMoverPositionsSection;
