// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {Payment} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import PopupIconOptions from 'modules/App/Popup/components/PopupIconOptions';
import DeletePaymentModal from 'modules/Bill/components/DeletePaymentModal';

const Item = Styled.View`
  z-index: 1;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 10px;
`;

const Cell = Styled.View`
  align-items: flex-end;
  width: 120px;
`;

const Text = Styled.H6`
`;

const Description = Styled.H7`
  color: ${colors.gray.tertiary};
`;

const Amount = Styled.H7`
  color: ${colors.gray.secondary};
`;

const Touchable = Styled.Touchable`
`;

const BillPaymentItem = ({isEditable, payment, refetch}) => {
  const {isOpen, handleOpen, handleClose} = useModal();

  return (
    <Item>
      <Cell
        style={{
          flex: 1,
          minWidth: 140,
          alignItems: 'flex-start',
        }}
      >
        <Text>{payment.name}</Text>
        <Description>{payment.description}</Description>
      </Cell>
      <Cell
        style={{
          width: 140,
          alignItems: 'flex-start',
        }}
      >
        <Text>{Payment.getDisplayMethod(payment)}</Text>
      </Cell>
      <Cell>
        <Amount>{Currency.display(payment.totalAmount)}</Amount>
      </Cell>
      {isEditable && (
        <Cell
          style={{
            width: 40,
          }}
        >
          <DeletePaymentModal
            isOpen={isOpen}
            payment={payment}
            handleClose={handleClose}
            refetch={refetch}
          />
          <PopupIconOptions
            offsetX={10}
            options={[
              {
                icon: Icon.Trash,
                label: 'Delete',
                isDisabled: Payment.isCreditCardMethod({method: payment.method}),
                handlePress: ({handleClose}) => {
                  // Close the popup.
                  handleClose();

                  // Open the modal.
                  handleOpen();
                },
              },
            ]}
            trigger={
              <Touchable
                style={{
                  justifyContent: 'center',
                  height: 20,
                  paddingHorizontal: 5,
                }}
              >
                <Icon
                  color={colors.gray.secondary}
                  size={Icon.Sizes.Large}
                  source={Icon.EllipsisH}
                />
              </Touchable>
            }
          />
        </Cell>
      )}
    </Item>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
BillPaymentItem.propTypes = {
  payment: PropTypes.object.isRequired,
};

BillPaymentItem.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillPaymentItem.fragment = gql`
  ${DeletePaymentModal.fragment}

  fragment BillPaymentItem on Payment {
    id
    method
    name
    description
    totalAmount
    ...DeletePaymentModal
  }
`;

export default BillPaymentItem;
