// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Inventory} from '@supermove/models';
import {uuid, withFragment} from '@supermove/utils';

// App
import ItemTypeKind from '@shared/modules/Inventory/enums/ItemTypeKind';
import ItemFormV2 from '@shared/modules/Inventory/forms/ItemFormV2';

const edit = withFragment(
  (room, {isCollapsed = true}) => ({
    roomId: room.id,
    inventoryId: room.inventoryId,
    roomTypeId: room.roomTypeId,
    name: room.name,
    description: room.description,
    isDeleted: room.isDeleted,
    itemForms: room.collection.filteredItemsForJobUuids.map((item, index) =>
      ItemFormV2.edit(item, {index}),
    ),
    collectionId: room.collectionId,
    primaryCategoryId: room?.roomType?.primaryCategoryId,
    uuid: room.uuid,

    // private
    isDirty: false,
    isCollapsed,
  }),
  gql`
    ${ItemFormV2.edit.fragment}

    fragment RoomItemsForm_edit on Room {
      id
      uuid
      inventoryId
      roomTypeId
      name
      description
      isDeleted
      collectionId
      roomType {
        id
        primaryCategoryId
      }
      collection {
        id
        # NOTE(cooper): We use the jobUuids to filter items to only those that are associated with the current job
        # Only applicable for driver inventory
        filteredItemsForJobUuids(jobUuids: $jobUuids) {
          id
          takeCount
          leaveCount
          ...ItemFormV2_edit
        }
      }
    }
  `,
);

const _new = ({
  inventoryId,
  roomTypeId,
  primaryCategoryId = null,
  name = '',
  description = '',
}) => ({
  roomId: undefined,
  inventoryId,
  roomTypeId,
  name,
  description,
  isDeleted: false,
  itemForms: [],
  collectionId: undefined,
  primaryCategoryId,
  uuid: uuid(),

  // private
  isDirty: true,
  isCollapsed: false,
});

const toForm = ({
  roomId,
  inventoryId,
  roomTypeId,
  name,
  description,
  isDeleted,
  itemForms,
  collectionId,
  primaryCategoryId,
  uuid,

  // private
  isDirty,
  isCollapsed,
}) => ({
  roomId,
  inventoryId,
  roomTypeId,
  name,
  description,
  isDeleted,
  itemForms,
  collectionId,
  primaryCategoryId,
  uuid,

  // private
  isDirty,
  isCollapsed,
});

const toMutation = ({
  roomId,
  inventoryId,
  roomTypeId,
  name,
  description,
  isDeleted,
  itemForms,
  collectionId,
  uuid,
}) => ({
  roomId,
  inventoryId,
  roomTypeId,
  name,
  description,
  isDeleted,
  collectionId,
  uuid,
  itemForms: _.reduce(
    itemForms,
    (filteredForms, itemForm) => {
      // Do not send to mutation if form hasn't changed
      if (!itemForm.isDirty) {
        return filteredForms;
      }
      return [...filteredForms, ItemFormV2.toMutation(itemForm)];
    },
    [],
  ),
});

const getInfo = (roomItemsForm, itemTypeKindFilters = [ItemTypeKind.CARTON, ItemTypeKind.ITEM]) => {
  let takeCount = 0;
  let leaveCount = 0;
  let volume = 0;
  let weight = 0;
  let price = 0;
  let hasUnsavedChanges = false;
  let itemCount = 0;
  let cartonCount = 0;
  roomItemsForm.itemForms.forEach((itemForm) => {
    if (itemForm.isDirty) {
      hasUnsavedChanges = true;
    }
    if (
      !roomItemsForm.isDeleted &&
      !itemForm.isDeleted &&
      _.includes(itemTypeKindFilters, itemForm.kind)
    ) {
      if (itemForm.take) {
        takeCount += Inventory.getFloatValue(itemForm.takeCount);
        if (!itemForm.isVoid) {
          volume +=
            Inventory.getFloatValue(itemForm.takeCount) * Inventory.getFloatValue(itemForm.volume);
          weight +=
            Inventory.getFloatValue(itemForm.takeCount) * Inventory.getFloatValue(itemForm.weight);
          price += _.toNumber(
            (Inventory.getFloatValue(itemForm.takeCount) * itemForm.price).toFixed(0),
          );
        }
        if (itemForm.kind === ItemTypeKind.ITEM) {
          itemCount += Inventory.getFloatValue(itemForm.takeCount);
        }
        if (itemForm.kind === ItemTypeKind.CARTON) {
          cartonCount += Inventory.getFloatValue(itemForm.takeCount);
        }
      } else {
        leaveCount += Inventory.getFloatValue(itemForm.leaveCount);
      }
    }
  });
  return {
    takeCount,
    leaveCount,
    volume: _.round(volume, 2),
    weight: _.round(weight, 2),
    price,
    hasUnsavedChanges,
    itemCount,
    cartonCount,
  };
};

const getLotAndRangeInfo = (roomItemsForm) => {
  const {itemForms} = roomItemsForm;

  // Separate out items for each lot/color combo
  const itemFormsByLotNumberAndColor = _.groupBy(itemForms, (itemForm) => {
    const {lotNumber, color} = itemForm;
    return `${lotNumber}_${color}`;
  });

  // Get the smallest and largest itemNumber within each lot/color combo
  return _.map(itemFormsByLotNumberAndColor, (itemForms, key) => {
    const {itemNumber: smallest} = _.minBy(itemForms, (itemForm) => {
      return _.toNumber(itemForm.itemNumber);
    });
    const {itemNumber: largest} = _.maxBy(itemForms, (itemForm) => {
      return _.toNumber(itemForm.itemNumber);
    });

    // Grab the lotNumber and color from the first itemForm, they should all be the same
    const {lotNumber, color} = itemForms[0];
    const range = smallest === largest ? smallest : `${smallest} - ${largest}`;
    return {lotNumber, color, range};
  });
};

const RoomItemsForm = {
  edit,
  new: _new,
  toForm,
  toMutation,

  // Helpers
  getInfo,
  getLotAndRangeInfo,
};

export default RoomItemsForm;
