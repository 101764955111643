// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Popover, Space, Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';

const ContentContainer = Styled.View`
  padding: 24px;

`;

const Header = Styled.Text`
  ${Typography.Heading2}
`;

const Body = Styled.Text`
  ${Typography.Body}
`;

const FooterRow = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const AddDayNotesPopover = ({
  organization,
  popover,
  width,
  form,
  handleSubmit,
  submitting,
  isClosableOutside,
  offset,
  isMultibranchDayView,
  placement,
}) => {
  return (
    <Popover
      placement={placement}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={offset}
      isClosableOutside={isClosableOutside}
    >
      <ResponsivePopover.StaticContainer width={width}>
        <ContentContainer>
          <Header>Day Notes</Header>
          {!_.isEmpty(organization) &&
            isMultibranchDayView &&
            organization?.features?.isEnabledDayNotesMultibranch && (
              <React.Fragment>
                <Space height={16} />
                <Body>{`Visible to the main branch ${
                  !organization?.isPrimaryMultibranch ? `and ${organization?.name}` : ''
                }`}</Body>
                <Space height={8} />
              </React.Fragment>
            )}
          <Space height={8} />
          <FieldInput
            {...form}
            name={'dayForm.notes'}
            input={{
              placeholder: 'Enter day notes.',
              style: {height: 120, paddingTop: 8},
              multiline: true,
            }}
          />
          <Space height={8} />
          <FooterRow>
            <TertiaryButton text={'Cancel'} onPress={popover.handleClose} disabled={submitting} />
            <Space width={16} />
            <Button
              iconLeft={Icon.Check}
              text={'Save'}
              onPress={handleSubmit}
              isSubmitting={submitting}
            />
          </FooterRow>
        </ContentContainer>
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
AddDayNotesPopover.propTypes = {
  offset: PropTypes.array,
  isClosableOutside: PropTypes.bool,
  isMultibranchDayView: PropTypes.bool,
  placement: PropTypes.string,
};

AddDayNotesPopover.defaultProps = {
  offset: [0, 4],
  isClosableOutside: false,
  isMultibranchDayView: false,
  placement: Popover.Positions.BottomStart,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddDayNotesPopover.fragment = gql`
  fragment AddDayNotesPopover on Organization {
    id
    isPrimaryMultibranch
    name
    features {
      isEnabledDayNotesMultibranch: isEnabled(feature: "DAY_NOTES_MULTIBRANCH")
    }
  }
`;

export default AddDayNotesPopover;
