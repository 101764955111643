// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import EditPanel from '@shared/design/components/Panel/EditPanel';
import RadioButton from '@shared/design/components/RadioButton';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const JobTypeGeneralSettingsTimesheetsPanelEdit = ({form, jobTypeFeature}) => {
  const [isEnabledPerPosition, setIsEnabledPerPosition] = useState(jobTypeFeature.isEnabled);

  return (
    <React.Fragment>
      <Row>
        <Column style={{width: 276}}>
          <EditPanel.LabelText>Billable timesheet type</EditPanel.LabelText>
          <Space height={12} />
          <RadioButton
            isOn={!isEnabledPerPosition}
            onChange={() => {
              setIsEnabledPerPosition(false);
              form.setFieldValue('jobTypeFeatureForm.isEnabled', false);
            }}
            label={'Standard'}
          />
          <Space height={8} />
          <RadioButton
            isOn={!!isEnabledPerPosition}
            onChange={() => {
              setIsEnabledPerPosition(true);
              form.setFieldValue('jobTypeFeatureForm.isEnabled', true);
            }}
            label={'Per Position'}
          />
        </Column>
        <Space width={24} />
        <Column style={{flex: 1}}>
          <EditPanel.Text style={{color: colors.gray.secondary}} numberOfLines={3}>
            Select the type of timesheet you would like to use for the job. Standard will bill your
            customers hourly, per position allows you to bill different rates per mover position.
          </EditPanel.Text>
        </Column>
      </Row>
    </React.Fragment>
  );
};

const JobTypeGeneralSettingsTimesheetsPanel = ({jobTypeFeature}) => {
  return (
    <Row>
      <Column style={{width: 276}}>
        <EditPanel.LabelText>Billable timesheet type</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text>{jobTypeFeature.isEnabled ? 'Per Position' : 'Standard'}</EditPanel.Text>
      </Column>
      <Space width={24} />
      <Column style={{flex: 1}}>
        <EditPanel.Text style={{color: colors.gray.secondary}} numberOfLines={3}>
          Select the type of timesheet you would like to use for the job. Standard will bill your
          customers hourly, per position allows you to bill different rates per mover position.
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTypeGeneralSettingsTimesheetsPanel.fragment = gql`
  fragment JobTypeGeneralSettingsTimesheetsPanel on JobTypeFeature {
    id
    isEnabled
  }
`;

JobTypeGeneralSettingsTimesheetsPanel.Edit = JobTypeGeneralSettingsTimesheetsPanelEdit;

export default JobTypeGeneralSettingsTimesheetsPanel;
