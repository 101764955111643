// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

import ReorderSlotForm from '@shared/modules/Dispatch/forms/ReorderSlotForm';

const edit = withFragment(
  ({slots}) => ({
    reorderSlotForms: slots.map((slot) => ReorderSlotForm.edit(slot)),
  }),
  gql`
    ${ReorderSlotForm.edit.fragment}

    fragment ReorderSlotsForm_edit on Slot {
      id
      ...ReorderSlotForm_edit
    }
  `,
);

const toForm = ({reorderSlotForms}) => ({
  reorderSlotForms: reorderSlotForms.map((slot) => ReorderSlotForm.toForm(slot)),
});

const toMutation = ({reorderSlotForms}) => ({
  reorderSlotForms: reorderSlotForms.map((slot) => ReorderSlotForm.toMutation(slot)),
});

const ReorderSlotsForm = {
  edit,
  toForm,
  toMutation,
};

export default ReorderSlotsForm;
