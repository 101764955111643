// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import JobTypeVariableSectionForm from '@shared/modules/Variable/forms/JobTypeVariableSectionForm';

const edit = withFragment(
  (jobType) => ({
    jobTypeId: jobType.id,
    jobTypeVariableSectionForms: jobType.jobTypeVariableSections.map((jobTypeVariableSection) =>
      JobTypeVariableSectionForm.edit(jobTypeVariableSection),
    ),

    // Private
    isEdit: false,
    selectedVariableIds: [],
  }),
  gql`
    ${JobTypeVariableSectionForm.edit.fragment}
    fragment JobTypeVariableSectionsForm_edit on JobType {
      id
      jobTypeVariableSections {
        id
        ...JobTypeVariableSectionForm_edit
      }
    }
  `,
);

const toForm = ({jobTypeId, jobTypeVariableSectionForms, isEdit, selectedVariableIds}) => ({
  jobTypeId,
  jobTypeVariableSectionForms: jobTypeVariableSectionForms.map((jobTypeVariableSectionForm) =>
    JobTypeVariableSectionForm.toForm(jobTypeVariableSectionForm),
  ),

  // Private
  isEdit,
  selectedVariableIds,
});

const toMutation = ({jobTypeId, jobTypeVariableSectionForms}) => ({
  jobTypeId,
  jobTypeVariableSectionForms: jobTypeVariableSectionForms.map((jobTypeVariableSectionForm) =>
    JobTypeVariableSectionForm.toMutation(jobTypeVariableSectionForm),
  ),
});

const removeVariableFormsById = ({jobTypeVariableSectionsForm, variableIdsToRemove}) => {
  const jobTypeVariableSectionForms = _.get(
    jobTypeVariableSectionsForm,
    'jobTypeVariableSectionForms',
    [],
  );
  return _.flatMap(jobTypeVariableSectionForms, (jobTypeVariableSectionForm) =>
    JobTypeVariableSectionForm.removeVariableFormsById({
      jobTypeVariableSectionForm,
      variableIdsToRemove,
    }),
  );
};

const getRemovedVariableFormsForDestination = ({
  form,
  jobTypeVariableSectionsForm,
  variableIdsToRemove,
  destinationField,
}) => {
  const removedVariableForms = removeVariableFormsById({
    jobTypeVariableSectionsForm,
    variableIdsToRemove,
  });
  const destinationSectionVariableFormsField = `${destinationField}.jobTypeVariableForms`;
  const existingVariablesInNewSection = _.get(form.values, destinationSectionVariableFormsField);
  return [...existingVariablesInNewSection, ...removedVariableForms];
};

const removeVariableSection = ({form, sectionFieldToRemove, destinationField}) => {
  const jobTypeVariableSectionsForm = _.get(form.values, 'jobTypeVariableSectionsForm');
  const variableSectionFormToDelete = _.get(form.values, sectionFieldToRemove);
  const variableIdsToRemove = JobTypeVariableSectionForm.getVariableIdsInSection({
    jobTypeVariableSectionForm: variableSectionFormToDelete,
  });
  const updatedVariableForms = getRemovedVariableFormsForDestination({
    form,
    jobTypeVariableSectionsForm,
    variableIdsToRemove,
    destinationField,
  });
  const destinationSectionVariableFormsField = `${destinationField}.jobTypeVariableForms`;
  _.set(form.values, destinationSectionVariableFormsField, updatedVariableForms);

  const existingSectionForms = _.get(
    form.values,
    'jobTypeVariableSectionsForm.jobTypeVariableSectionForms',
  );
  const sectionFormToRemove = _.get(form.values, sectionFieldToRemove);
  const updatedSectionForms = _.difference(existingSectionForms, [sectionFormToRemove]);
  jobTypeVariableSectionsForm.jobTypeVariableSectionForms = updatedSectionForms;
  form.setFieldValue(
    'jobTypeVariableSectionsForm.jobTypeVariableSectionForms',
    updatedSectionForms,
  );
};

const moveVariablesToSection = ({form, variableIdsToMove, destinationField}) => {
  const jobTypeVariableSectionsForm = _.get(form.values, 'jobTypeVariableSectionsForm');
  const updatedVariableForms = getRemovedVariableFormsForDestination({
    form,
    jobTypeVariableSectionsForm,
    variableIdsToRemove: variableIdsToMove,
    destinationField,
  });
  form.setFieldValue(`${destinationField}.jobTypeVariableForms`, updatedVariableForms);
};

const JobTypeVariableSectionsForm = {
  edit,
  toForm,
  toMutation,

  removeVariableSection,
  moveVariablesToSection,
};

export default JobTypeVariableSectionsForm;
