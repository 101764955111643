// Supermove
import {gql} from '@supermove/graphql';
import {Form, useForm, useFormMutation} from '@supermove/hooks';

// App
import {UpdateAttachmentFormType} from '@shared/modules/File/forms/UpdateAttachmentForm';
import UpdateAttachmentsForm, {
  UpdateAttachmentsFormType,
} from '@shared/modules/File/forms/UpdateAttachmentsForm';

interface UpdateAttachmentMutationWrapper {
  updateAttachmentsForm: {
    updateAttachmentForms: UpdateAttachmentFormType[];
  };
}

const useUpdateAttachmentsMutation = ({
  updateAttachmentsForm,
  onSuccess,
  onError,
}: {
  updateAttachmentsForm: UpdateAttachmentsFormType;
  onSuccess: () => void;
  onError: (e: any) => void;
}) => {
  const form: Form<UpdateAttachmentMutationWrapper> = useForm({
    initialValues: {
      updateAttachmentsForm: UpdateAttachmentsForm.toForm(updateAttachmentsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateAttachmentsMutation.mutation,
    variables: {
      updateAttachmentsForm: UpdateAttachmentsForm.toMutation(form.values.updateAttachmentsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateAttachmentsMutation.mutation = gql`
  mutation useUpdateAttachmentsMutation($updateAttachmentsForm: UpdateAttachmentsForm!) {
    response: updateAttachments(updateAttachmentsForm: $updateAttachmentsForm) {
      ${gql.errors}
    }
  }
`;

export default useUpdateAttachmentsMutation;
