// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {Datetime, withFragment} from '@supermove/utils';

const edit = withFragment(
  (job) => ({
    jobId: job.id,
    date: _.get(job, 'day.value'),
    startDate: job.startDate,
    endDate: job.endDate,
    isEstimatedRange: Job.getIsEstimatedRange(job),
  }),
  gql`
    ${Job.getIsEstimatedRange.fragment}
    fragment JobDateForm_edit on Job {
      id
      startDate
      endDate
      day {
        id
        value
      }
      ...Job_getIsEstimatedRange
    }
  `,
);

const toForm = ({jobId, date, startDate, endDate, isEstimatedRange}) => ({
  jobId,
  date: Datetime.toFormDate(date),
  startDate: Datetime.toFormDate(startDate),
  endDate: Datetime.toFormDate(endDate),
  isEstimatedRange,
});

const toMutation = ({jobId, date, startDate, endDate, isEstimatedRange}) => ({
  jobId,
  date: date ? Datetime.toMutationDate(date) : null,
  startDate: startDate ? Datetime.toMutationDate(startDate) : null,
  endDate: endDate ? Datetime.toMutationDate(endDate) : null,
  isEstimatedRange,
});

const JobDateForm = {
  edit,
  toForm,
  toMutation,
};

export default JobDateForm;
