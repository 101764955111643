const INCOMING = 'INCOMING';
const OUTGOING = 'OUTGOING';

const INCOMING_OPTION = {
  value: 'INCOMING',
  label: 'Incoming',
  key: 'incoming',
};

const OUTGOING_OPTION = {
  value: 'OUTGOING',
  label: 'Outgoing',
  key: 'outgoing',
};

const OPTIONS = [INCOMING_OPTION, OUTGOING_OPTION];

const KIND_OPTION_MAP = {
  [INCOMING]: INCOMING_OPTION,
  [OUTGOING]: OUTGOING_OPTION,
};

const getKindOption = (kind) => {
  return KIND_OPTION_MAP[kind];
};

const ShipmentKind = {
  OPTIONS,

  INCOMING,
  OUTGOING,

  getKindOption,
};

export default ShipmentKind;
