/* eslint-disable react-hooks/exhaustive-deps */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useEffect,
  useNavigationDOM,
  useQuery,
  useResponsive,
  useScrollView,
  useState,
} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import EmptyState from '@shared/design/components/EmptyState';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import Line from 'modules/App/components/Line';
import ProjectJobBlock from 'modules/Project/V2/Show/Blocks/Job/ProjectJobBlock';
import ProjectAccountingSummaryBlock from 'modules/Project/V2/Show/Blocks/ProjectAccountingSummaryBlock';
import ProjectBillingBlock from 'modules/Project/V2/Show/Blocks/ProjectBillingBlock';
import ProjectClaimsBlock from 'modules/Project/V2/Show/Blocks/ProjectClaimsBlock';
import ProjectClientsBlock from 'modules/Project/V2/Show/Blocks/ProjectClientsBlock';
import ProjectCostAndCompensationBlock from 'modules/Project/V2/Show/Blocks/ProjectCostAndCompensationBlock';
import ProjectInternalBlock from 'modules/Project/V2/Show/Blocks/ProjectInternalBlock';
import ProjectInventoryItemsBlock from 'modules/Project/V2/Show/Blocks/ProjectInventoryItemsBlock';
import ProjectInventorySummaryBlock from 'modules/Project/V2/Show/Blocks/ProjectInventorySummaryBlock';
import ProjectInvoiceBlock from 'modules/Project/V2/Show/Blocks/ProjectInvoiceBlock';
import ProjectJobsBlock from 'modules/Project/V2/Show/Blocks/ProjectJobsBlock';
import ProjectLocationsBlock from 'modules/Project/V2/Show/Blocks/ProjectLocationsBlock';
import ProjectOverviewBlock from 'modules/Project/V2/Show/Blocks/ProjectOverviewBlock';
import ProjectProposalsBlock from 'modules/Project/V2/Show/Blocks/ProjectProposalsBlock';
import ProjectSurveyBlock from 'modules/Project/V2/Show/Blocks/ProjectSurveyBlock';
import ProjectValuationCoverageBlock from 'modules/Project/V2/Show/Blocks/ProjectValuationCoverageBlock';

const BLOCK_PADDING = 24;

// This is the number of pixels from the top where we consider
// a block to be the current block
const POSITION_Y_MARKER_OFFSET = 200;

const getAdjustedPositionY = (positionY) => positionY + POSITION_Y_MARKER_OFFSET;

const getSectionBlocks = ({params, projectNavigationSections, isAllBlocks}) => {
  if (isAllBlocks) {
    return projectNavigationSections.reduce(
      (result, section) => [...result, ...section.blocks],
      [],
    );
  }
  const paramSection = projectNavigationSections.filter(
    (section) => section.name === params.section,
  )[0];
  const section = paramSection || projectNavigationSections[0];
  return section.blocks;
};

const getBlockSection = ({block, projectNavigationSections}) => {
  const section = projectNavigationSections.filter((section) => section.blocks.includes(block))[0];
  return section.name;
};

const getCurrentPositionYBlockKind = ({positionY, blockToPositionY, isAtBottom}) => {
  const blockKindAndPositionPairs = Object.entries(blockToPositionY);

  if (blockKindAndPositionPairs.length > 0) {
    blockKindAndPositionPairs.sort((a, b) => a[1] - b[1]);

    // If at top, return first block
    if (positionY === 0) {
      const [firstBlockKind] = blockKindAndPositionPairs[0];
      return firstBlockKind;
    }

    // If at bottom, return last block
    if (isAtBottom) {
      const [lastBlockKind] = blockKindAndPositionPairs[blockKindAndPositionPairs.length - 1];
      return lastBlockKind;
    }

    // A possible block is any block that starts prior to the current positionY
    const possibleBlocks = blockKindAndPositionPairs.filter(
      ([blockKind, blockPosition]) => blockPosition <= getAdjustedPositionY(positionY),
    );
    const currentPositionYBlock = possibleBlocks[possibleBlocks.length - 1];
    const [blockKind] = currentPositionYBlock;
    return blockKind;
  }

  return null;
};

const ProjectSectionBlock = ({
  kind,
  project,
  blockToPositionY,
  handleSetPositionY,
  layoutKey,
  urlFilters,
  isLoadedAllSectionBlocks,
  index,
  pageRefetch,
  refetchAndReset,
}) => {
  switch (kind) {
    case ProjectBlockKind.PROJECT_OVERVIEW:
      return (
        <ProjectOverviewBlock
          project={project}
          index={index}
          layoutKey={layoutKey}
          handleSetPositionY={handleSetPositionY}
          urlFilters={urlFilters}
          refetch={pageRefetch}
          refetchAndReset={refetchAndReset}
        />
      );
    case ProjectBlockKind.ACCOUNTING_SUMMARY:
      return (
        <ProjectAccountingSummaryBlock
          index={index}
          project={project}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.BILLING:
      return (
        <ProjectBillingBlock
          key={project.totalRevenue}
          index={index}
          project={project}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.CLAIMS:
      return (
        <ProjectClaimsBlock
          index={index}
          project={project}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.CLIENTS:
      return (
        <ProjectClientsBlock
          index={index}
          project={project}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.COST_AND_COMPENSATION:
      return (
        <ProjectCostAndCompensationBlock
          index={index}
          project={project}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
        />
      );
    case ProjectBlockKind.Job.JOB_DETAILS:
    case ProjectBlockKind.Job.STOPS:
    case ProjectBlockKind.Job.DISPATCH:
    case ProjectBlockKind.Job.EQUIPMENT_AND_MATERIALS:
    case ProjectBlockKind.Job.TIMESHEETS:
    case ProjectBlockKind.Job.JOB_TIMESHEET:
    case ProjectBlockKind.Job.CREW_HOURS_AND_TIPS:
    case ProjectBlockKind.Job.CREW_HOURS:
    case ProjectBlockKind.Job.TIP_PAYOUTS:
      return (
        <ProjectJobBlock
          index={index}
          project={project}
          kind={kind}
          blockToPositionY={blockToPositionY}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          jobUuid={urlFilters.get('jobUuid')}
          isReadyForMap={isLoadedAllSectionBlocks}
          urlFilters={urlFilters}
          pageRefetch={pageRefetch}
          refetchAndReset={refetchAndReset}
        />
      );
    case ProjectBlockKind.JOBS:
      return (
        <ProjectJobsBlock
          index={index}
          project={project}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.INVOICE:
      return (
        <ProjectInvoiceBlock
          index={index}
          project={project}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
        />
      );
    case ProjectBlockKind.INTERNAL:
      return (
        <ProjectInternalBlock
          index={index}
          project={project}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
        />
      );
    case ProjectBlockKind.LOCATIONS:
      return (
        <ProjectLocationsBlock
          index={index}
          project={project}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          // When a block finishes loading, the overall size of the section
          // expands to fit newly rendered content. When this happens, we
          // trigger each block to rerender so that we can correctly cache
          // the new layout and the position Y of each block to handle the
          // auto scrolling feature of the project page. This rerender however
          // causes the google maps component to rerender as well which consequently
          // logs a console error, 'Warning: Can't perform a React state update on
          // an unmounted component.' We resolve this console error by waiting for
          // all blocks to load before rendering the google map.
          isReadyForMap={isLoadedAllSectionBlocks}
        />
      );
    case ProjectBlockKind.PROPOSALS:
      return (
        <ProjectProposalsBlock
          index={index}
          project={project}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.SURVEY:
      return (
        <ProjectSurveyBlock
          index={index}
          project={project}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.VALUATION_COVERAGE:
      return (
        <ProjectValuationCoverageBlock
          index={index}
          project={project}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.INVENTORY_SUMMARY:
      return (
        <ProjectInventorySummaryBlock
          index={index}
          project={project}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
        />
      );
    case ProjectBlockKind.INVENTORY_ITEMS:
      return (
        <ProjectInventoryItemsBlock
          index={index}
          project={project}
          handleSetPositionY={handleSetPositionY}
        />
      );
    default:
      return null;
  }
};

const ProjectSectionContent = ({
  project,
  blocks,
  urlFilters,
  pageRefetch,
  refetchAndReset,
  isAllBlocks,
  projectNavigationSections,
}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const scrollView = useScrollView();

  const [blockToPositionY, setBlockToPositionY] = useState({});
  const [contentSize, setContentSize] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [currentJobUuid, setCurrentJobUuid] = useState();
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
  const [isInitialScrollComplete, setIsInitialScrollComplete] = useState(false);

  const blockPositionsSum = _.sum(Object.values(blockToPositionY));

  // Watch to possibly scroll to a block when the block param or a block position changes
  useEffect(() => {
    const loadedBlocksCount = Object.keys(blockToPositionY).length;
    if (loadedBlocksCount === blocks.length) {
      const currentPositionYBlock = getCurrentPositionYBlockKind({
        positionY,
        blockToPositionY,
        isAtBottom,
      });

      if (currentJobUuid && params.jobUuid && currentJobUuid !== params.jobUuid) {
        // In the case where the user is switching from one job to
        // another, we want to go to position 0 without animation
        scrollView.handleScrollTo({y: 0, animated: false});
      } else if (currentPositionYBlock && currentPositionYBlock !== params.block) {
        const y = blockToPositionY[params.block];
        if (!isInitialLoadComplete) {
          setIsInitialLoadComplete(true);
          if (!isInitialScrollComplete) {
            scrollView.handleScrollTo({y, animated: true});
            setIsInitialScrollComplete(true);
          }
        } else {
          scrollView.handleScrollTo({y, animated: true});
        }
      }

      // Keep track of the jobUuid param in order to handle the case
      // above where the user is switching from one job to another
      if (currentJobUuid !== params.jobUuid) {
        setCurrentJobUuid(params.jobUuid);
      }
    }
  }, [params.block, params.jobUuid, blockPositionsSum]);

  // Watch to possibly update the block param when the user is scrolling
  useEffect(() => {
    const currentPositionYBlock = getCurrentPositionYBlockKind({
      positionY,
      blockToPositionY,
      isAtBottom,
    });
    if (currentPositionYBlock && currentPositionYBlock !== params.block) {
      if (isAtBottom) {
        urlFilters.handleUpdate({block: blocks[blocks.length - 1]});
      } else {
        // If we are showing all blocks, we also need to handle updating the section
        urlFilters.handleUpdate({
          ...(isAllBlocks
            ? {section: getBlockSection({block: currentPositionYBlock, projectNavigationSections})}
            : {}),
          block: currentPositionYBlock,
        });
      }
    }
  }, [positionY]);

  // Handle edge cases when viewing a job
  if (ProjectBlockKind.JobBlocks.includes(params.block)) {
    // Show empty state if the project has no active jobs
    if (_.isEmpty(Project.getDisplayAllJobsExcludingChildJobs(project, params))) {
      return (
        <EmptyState.ContentContainer>
          <EmptyState
            icon={Icon.Search}
            title={'No jobs.'}
            message={'There are no jobs on this project.\nAdd a job and it will appear here.'}
            primaryActionIcon={Icon.Plus}
            primaryActionText={'Add Job'}
            handlePrimaryAction={() =>
              navigator.push(
                `/projects/${params.projectUuid}/edit/jobs?action=${Project.JOB_ACTIONS.ADD}`,
              )
            }
          />
          <Space height={100} />
        </EmptyState.ContentContainer>
      );
    }

    if (!urlFilters.get('jobUuid')) {
      // If we are on a job block without a jobUuid, ShowProjectPageV2 will update the url,
      // but we need to return null while the redirect is happening so that child components
      // don't attempt to query a job without a jobUuid.
      return null;
    }
  }

  return (
    <ScrollView
      ref={scrollView.ref}
      style={{flex: 1, backgroundColor: responsive.desktop ? colors.gray.background : colors.white}}
      contentContainerStyle={{padding: responsive.desktop ? BLOCK_PADDING : 0}}
      // We only want to receive one event for onScroll so we explicitly put the throttle at Infinity.
      scrollEventThrottle={Infinity}
      onScroll={({nativeEvent}) => {
        setIsInitialScrollComplete(true);
        const {layoutMeasurement, contentOffset, contentSize} = nativeEvent;

        // HACK(dan) If a small modal has a dropdown and the dropdown is long enough to scroll,
        // the scroll action on the dropdown will trigger this onScroll event and this onScroll
        // event will reflect the property values of the dropdown input. Since the width of the
        // small modal dropdown is 352px, we can gate setting state at a min width of 360px to
        // distinguish the scroll on these small modal dropdowns from the project section scroll.
        const isScrollingProjectSection = contentSize.width > 360;
        if (isScrollingProjectSection) {
          const isScrollAtBottom = layoutMeasurement.height + contentOffset.y >= contentSize.height;
          setIsAtBottom(isScrollAtBottom);
          setPositionY(contentOffset.y);
        }
      }}
      onContentSizeChange={(width, height) => {
        setContentSize(height);
      }}
      showsVerticalScrollIndicator={responsive.desktop}
    >
      {blocks.map((projectBlockKind, index) => {
        return (
          <React.Fragment key={projectBlockKind}>
            {index > 0 &&
              (responsive.desktop ? <Space height={24} /> : <Line style={{height: 4}} />)}
            <ProjectSectionBlock
              layoutKey={contentSize}
              kind={projectBlockKind}
              project={project}
              urlFilters={urlFilters}
              blockToPositionY={blockToPositionY}
              handleSetPositionY={({nativeEvent}) => {
                setBlockToPositionY((previousBlockToPositionY) => ({
                  ...previousBlockToPositionY,
                  [projectBlockKind]: nativeEvent.layout.y - BLOCK_PADDING,
                }));
              }}
              isLoadedAllSectionBlocks={Object.keys(blockToPositionY).length === blocks.length}
              index={index}
              pageRefetch={pageRefetch}
              refetchAndReset={refetchAndReset}
            />
          </React.Fragment>
        );
      })}
      <Space height={120} />
    </ScrollView>
  );
};

const ProjectSection = ({
  project,
  urlFilters,
  projectNavigationSections,
  pageRefetch,
  refetchAndReset,
  isAllBlocks,
}) => {
  const {params} = useNavigationDOM();
  const blocks = getSectionBlocks({params, projectNavigationSections, isAllBlocks});

  const {data, loading} = useQuery(ProjectSection.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid: project.uuid,
      hasProjectOverviewBlock: blocks.includes(ProjectBlockKind.PROJECT_OVERVIEW),
      hasAccountingSummaryBlock: blocks.includes(ProjectBlockKind.ACCOUNTING_SUMMARY),
      hasBillingBlock: blocks.includes(ProjectBlockKind.BILLING),
      hasClaimsBlock: blocks.includes(ProjectBlockKind.CLAIMS),
      hasClientsBlock: blocks.includes(ProjectBlockKind.CLIENTS),
      hasCostAndCompensationBlock: blocks.includes(ProjectBlockKind.COST_AND_COMPENSATION),
      hasInvoiceBlock: blocks.includes(ProjectBlockKind.INVOICE),
      hasLocationsBlock: blocks.includes(ProjectBlockKind.LOCATIONS),
      hasSurveyBlock: blocks.includes(ProjectBlockKind.SURVEY),
      hasInventorySummaryBlock: blocks.includes(ProjectBlockKind.INVENTORY_SUMMARY),
      hasInventoryItemsBlock: blocks.includes(ProjectBlockKind.INVENTORY_ITEMS),
      hasValuationCoverageBlock: blocks.includes(ProjectBlockKind.VALUATION_COVERAGE),
      hasJobsBlock: blocks.includes(ProjectBlockKind.JOBS),
    },
  });

  return (
    <Loading loading={loading}>
      {() => {
        return (
          <ProjectSectionContent
            project={data.project}
            blocks={blocks}
            urlFilters={urlFilters}
            pageRefetch={pageRefetch}
            refetchAndReset={refetchAndReset}
            isAllBlocks={isAllBlocks}
            projectNavigationSections={projectNavigationSections}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectSection.listener = gql`
  ${ProjectJobBlock.listener}

  fragment ProjectSection_listener on Project {
    id
    totalRevenue
    ...ProjectJobBlock_listener
  }
`;

ProjectSection.fragment = gql`
  ${ProjectSection.listener}

  fragment ProjectSection on Project {
    id
    uuid
    ...ProjectSection_listener
  }
`;

ProjectSection.query = gql`
  ${ProjectOverviewBlock.fragment}
  ${ProjectAccountingSummaryBlock.fragment}
  ${ProjectBillingBlock.fragment}
  ${ProjectClaimsBlock.fragment}
  ${ProjectClientsBlock.fragment}
  ${ProjectCostAndCompensationBlock.fragment}
  ${ProjectInvoiceBlock.fragment}
  ${ProjectJobBlock.fragment}
  ${ProjectLocationsBlock.fragment}
  ${ProjectSurveyBlock.fragment}
  ${ProjectInventorySummaryBlock.fragment}
  ${ProjectInventoryItemsBlock.fragment}
  ${ProjectValuationCoverageBlock.fragment}
  ${ProjectJobsBlock.fragment}
  ${ProjectSection.listener}
  ${Project.getDisplayAllJobsExcludingChildJobs.fragment}

  query ProjectSection(
    $projectUuid: String!
    $hasProjectOverviewBlock: Boolean!
    $hasAccountingSummaryBlock: Boolean!
    $hasBillingBlock: Boolean!
    $hasClaimsBlock: Boolean!
    $hasClientsBlock: Boolean!
    $hasCostAndCompensationBlock: Boolean!
    $hasInvoiceBlock: Boolean!
    $hasLocationsBlock: Boolean!
    $hasSurveyBlock: Boolean!
    $hasInventorySummaryBlock: Boolean!
    $hasInventoryItemsBlock: Boolean!
    $hasValuationCoverageBlock: Boolean!
    $hasJobsBlock: Boolean!
  ) {
    project(uuid: $projectUuid) {
      id
      activeJobsExcludingChildJobs {
        id
      }
      ...ProjectOverviewBlock @include(if: $hasProjectOverviewBlock)
      ...ProjectAccountingSummaryBlock @include(if: $hasAccountingSummaryBlock)
      ...ProjectBillingBlock @include(if: $hasBillingBlock)
      ...ProjectClaimsBlock @include(if: $hasClaimsBlock)
      ...ProjectClientsBlock @include(if: $hasClientsBlock)
      ...ProjectCostAndCompensationBlock @include(if: $hasCostAndCompensationBlock)
      ...ProjectInvoiceBlock @include(if: $hasInvoiceBlock)
      ...ProjectLocationsBlock @include(if: $hasLocationsBlock)
      ...ProjectSurveyBlock @include(if: $hasSurveyBlock)
      ...ProjectInventorySummaryBlock @include(if: $hasInventorySummaryBlock)
      ...ProjectInventoryItemsBlock @include(if: $hasInventoryItemsBlock)
      ...ProjectValuationCoverageBlock @include(if: $hasValuationCoverageBlock)
      ...ProjectJobsBlock @include(if: $hasJobsBlock)
      ...ProjectSection_listener
      ...Project_getDisplayAllJobsExcludingChildJobs
      ...ProjectJobBlock
    }
  }
`;

export default ProjectSection;
