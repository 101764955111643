// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import BillRuleTypeForm from '@shared/modules/Billing/forms/BillRuleTypeForm';
import useCreateBillRuleTypeMutation from '@shared/modules/Billing/hooks/useCreateBillRuleTypeMutation';
import BillRuleTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/BillRuleTypeDrawer';
import BillRuleTypeFields from 'modules/Organization/Settings/BillingLibraries/components/BillRuleTypeFields';

const AddBillRuleTypeDrawer = ({billingLibrary, refetch, userId, isOpen, handleClose}) => {
  const billRuleTypeForm = BillRuleTypeForm.new({
    billingLibraryId: billingLibrary.id,
    organizationId: billingLibrary.organizationId,
    userId,
  });
  const {form, submitting, handleSubmit} = useCreateBillRuleTypeMutation({
    billRuleTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <BillRuleTypeDrawer
      isOpen={isOpen}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      headerText={'Add Bill Rule'}
    >
      <BillRuleTypeFields form={form} field={'billRuleTypeForm'} />
    </BillRuleTypeDrawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddBillRuleTypeDrawer.fragment = gql`
  fragment AddBillRuleTypeDrawer on BillingLibrary {
    id
    organizationId
  }
`;

export default AddBillRuleTypeDrawer;
