// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Currency} from '@supermove/utils';

// Forms
import TimeRangeForm from './TimeRangeForm';

const newFromReportMove = withFragment(
  (reportMove) => {
    return {
      userId: null,
      jobId: reportMove.jobId ? String(reportMove.jobId) : null,
      moveId: reportMove.moveId ? String(reportMove.moveId) : null,
      position: '',
      positions: [],
      branchCode: '',
      timePayableAdjustment: 0,
      timeRangeInputs: reportMove.organization.defaultTimesheetTimeRanges.map((timeRange) => {
        return TimeRangeForm.edit(timeRange);
      }),
      tip: 0,
      // Private fields.
      user: null,
    };
  },
  gql`
    ${TimeRangeForm.edit.fragment}

    fragment ReportMoveUserForm_newFromReportMove on ReportMove {
      id
      jobId
      moveId
      organization {
        id
        defaultTimesheetTimeRanges {
          ...TimeRangeForm_edit
        }
      }
    }
  `,
);

const extendTimeRangeInputs = ({timeRangeInputs}) => {
  return _.concat(timeRangeInputs, _.last(timeRangeInputs));
};

const shrinkTimeRangeInputs = ({timeRangeInputs}) => {
  return _.initial(timeRangeInputs);
};

const ReportMoveUserForm = {
  toForm: ({
    userId,
    jobId,
    moveId,
    position,
    positions,
    branchCode,
    timePayableAdjustment,
    timeRangeInputs,
    tip,
    // Private fields.
    user,
  }) => ({
    userId,
    jobId,
    moveId,
    position,
    positions,
    branchCode,
    timePayableAdjustment,
    timeRangeInputs: extendTimeRangeInputs({timeRangeInputs}).map((timeRangeForm) => {
      return TimeRangeForm.toForm(timeRangeForm);
    }),
    tip: Currency.toForm(tip),
    // Private fields.
    user,
  }),

  toMutation: ({
    userId,
    jobId,
    moveId,
    position,
    positions,
    branchCode,
    timePayableAdjustment,
    timeRangeInputs,
    tip,
  }) => ({
    userId,
    jobId,
    moveId,
    position,
    positions,
    branchCode,
    timePayableAdjustment,
    timeRangeInputs: shrinkTimeRangeInputs({timeRangeInputs}).map((timeRangeForm) => {
      return TimeRangeForm.toMutation(timeRangeForm);
    }),
    tip: Currency.toMutation(tip),
  }),

  edit: withFragment(
    (reportMoveUser) => ({
      userId: reportMoveUser.userId ? String(reportMoveUser.userId) : null,
      jobId: reportMoveUser.jobId ? String(reportMoveUser.jobId) : null,
      moveId: reportMoveUser.moveId ? String(reportMoveUser.moveId) : null,
      position: reportMoveUser.position,
      positions: reportMoveUser.positions,
      branchCode: reportMoveUser.branchCode,
      timePayableAdjustment: reportMoveUser.timePayableAdjustment,
      timeRangeInputs: reportMoveUser.timeRanges.map((timeRange) => {
        return TimeRangeForm.edit(timeRange);
      }),
      tip: reportMoveUser.tip,
      // Private fields.
      user: reportMoveUser.user,
    }),
    gql`
      ${TimeRangeForm.edit.fragment}

      fragment ReportMoveUserForm_edit on ReportMoveUser {
        userId
        jobId
        moveId
        position
        positions
        branchCode
        timePayableAdjustment
        tip
        timeRanges {
          ...TimeRangeForm_edit
        }
      }
    `,
  ),

  newFromReportMove,
};

export default ReportMoveUserForm;
