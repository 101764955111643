// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {LineItem} from '@supermove/models';
import {Currency, Float, withFragment} from '@supermove/utils';

// App
import LineItemCategory from '@shared/modules/Billing/enums/LineItemCategory';

/**
 * If price is an empty string, convert it to 0 before a `toMutation` transformation.
 * This is necessary because of the `toForm` transformation on price.
 *
 * Additionally enforce that discount line items always have a negative price.
 */
const toMutationPrice = ({kind, price}) => {
  return kind === LineItem.KINDS.DISCOUNT
    ? Math.abs(Currency.toMutation(price || 0)) * -1
    : Currency.toMutation(price || 0);
};

// When submitting the form, if the user input a quantityTwo value
// but no quantity value, then quantityTwo gets saved as quantity
const toMutationQuantity = ({quantity, quantityTwo}) => {
  if (!_.isEmpty(quantityTwo) && _.isEmpty(quantity)) {
    return Float.toMutation(quantityTwo);
  }
  if (quantity === '') {
    return null;
  }
  return Float.toMutation(quantity);
};

// When submitting the form, if the user input a higher quantity value
// than the quantityTwo value, then we set quantityTwo to the quantity value
const toMutationQuantityTwo = ({quantityTwo, quantity}) => {
  if (
    !_.isEmpty(quantity) &&
    !_.isEmpty(quantityTwo) &&
    Float.toFloat(quantity) > Float.toFloat(quantityTwo)
  ) {
    return Float.toMutation(quantity);
  }
  if (quantityTwo === '') {
    return null;
  }
  return Float.toMutation(quantityTwo);
};

const getCategoryForKind = (kind) => {
  switch (kind) {
    case LineItem.KINDS.DISCOUNT:
      return LineItemCategory.EXTRA_DISCOUNT;
    case LineItem.KINDS.FLAT_RATE:
      return LineItemCategory.EXTRA_CHARGE;
    case LineItem.KINDS.HOURLY:
      return LineItemCategory.LABOR;
    case LineItem.KINDS.SUPPLIES:
      return LineItemCategory.SUPPLIES;
    case LineItem.KINDS.ADDITIONAL_HOURS:
      return LineItemCategory.ADDITIONAL_HOURS;
    case LineItem.KINDS.VALUATION_COVERAGE:
      return LineItemCategory.VALUATION_COVERAGE;
    default:
      return LineItemCategory.UNKNOWN;
  }
};

const getUnitForKind = (kind) => {
  switch (kind) {
    case LineItem.KINDS.HOURLY:
    case LineItem.KINDS.ADDITIONAL_HOURS:
      return 'hour';
    default:
      return '';
  }
};

const getDisplayPrice = (lineItemForm) => {
  // In the case that the user does not enter a price, we default the
  // price to $0.00. This matches what happens in LineItem.getDisplayPrice
  // when there is no price for the lineItem. This also ensures that a value
  // is always provided for the price and we always return a non empty string.
  const price = lineItemForm.price || '$0.00';
  switch (lineItemForm.kind) {
    case LineItem.KINDS.HOURLY:
    case LineItem.KINDS.ADDITIONAL_HOURS:
      return `${price} / hour`;
    default:
      return price;
  }
};

const getQuantity = (lineItemForm) => {
  return Float.toFloat(lineItemForm.quantity);
};

const getIsLineItem = (billItemForm) => {
  return LineItem.KINDS_ORDER.includes(billItemForm.kind);
};

const getHasQuantityFormula = (lineItemForm) => {
  return !!lineItemForm.minQuantityFormulaId || !!lineItemForm.maxQuantityFormulaId;
};

const validate = ({lineItemForm, formField}) => {
  const errors = {};
  if (!lineItemForm.kind) {
    _.set(errors, `${formField}.kind`, 'Please select a category');
  }
  if (!lineItemForm.name) {
    _.set(errors, `${formField}.name`, 'Please enter a name');
  }
  return errors;
};

const _new = ({kind}) => ({
  nameFormulaId: null,
  priceFormulaId: null,
  minQuantityFormulaId: null,
  maxQuantityFormulaId: null,
  billItemId: null,
  billItemTypeId: null,
  name: '',
  description: '',
  price: '',
  quantity: kind === LineItem.KINDS.FLAT_RATE ? '1' : '',
  quantityTwo: '',
  kind,
  category: '',
  unit: getUnitForKind(kind),
  moverPositionId: null,

  // Private
  isEditable: true,
});

const edit = withFragment(
  (lineItem) => ({
    nameFormulaId: lineItem.nameFormulaId,
    priceFormulaId: lineItem.priceFormulaId,
    minQuantityFormulaId: lineItem.minQuantityFormulaId,
    maxQuantityFormulaId: lineItem.maxQuantityFormulaId,
    billItemId: lineItem.billItemId,
    billItemTypeId: lineItem.billItemTypeId,
    name: lineItem.name,
    description: lineItem.description,
    price: lineItem.price,
    quantity: lineItem.quantity,
    quantityTwo: lineItem.quantityTwo,
    kind: lineItem.kind,
    category: lineItem.category,
    unit: lineItem.unit,
    moverPositionId: lineItem.moverPositionId,
  }),
  gql`
    fragment LineItemForm_edit on LineItem {
      nameFormulaId
      priceFormulaId
      minQuantityFormulaId
      maxQuantityFormulaId
      billItemId
      billItemTypeId
      name
      description
      price
      quantity
      quantityTwo
      kind
      category
      unit
      moverPositionId
    }
  `,
);

const editFromTemplateLineItem = withFragment(
  (templateLineItem) => ({
    nameFormulaId: templateLineItem.nameFormulaId,
    priceFormulaId: templateLineItem.priceFormulaId,
    minQuantityFormulaId: templateLineItem.minQuantityFormulaId,
    maxQuantityFormulaId: templateLineItem.maxQuantityFormulaId,
    billItemId: null,
    billItemTypeId: templateLineItem.billItemTypeId,
    name: templateLineItem.name,
    description: templateLineItem.description,
    price: templateLineItem.price,
    quantity: templateLineItem.kind === LineItem.KINDS.FLAT_RATE ? '1' : '',
    quantityTwo: '',
    kind: templateLineItem.kind,
    category: templateLineItem.category,
    unit: templateLineItem.unit,
  }),
  gql`
    fragment LineItemForm_editFromTemplateLineItem on TemplateLineItem {
      nameFormulaId
      priceFormulaId
      minQuantityFormulaId
      maxQuantityFormulaId
      name
      description
      price
      kind
      category
      unit
      moverPositionId
    }
  `,
);

const toForm = ({
  nameFormulaId,
  priceFormulaId,
  minQuantityFormulaId,
  maxQuantityFormulaId,
  billItemId,
  billItemTypeId,
  name,
  description,
  price,
  quantity,
  quantityTwo,
  kind,
  category,
  unit,
  moverPositionId,
}) => ({
  nameFormulaId,
  priceFormulaId,
  minQuantityFormulaId,
  maxQuantityFormulaId,
  billItemId,
  name,
  description: description || '',
  // If price is 0, convert it to an empty string.
  // This prevents forms from starting with $0.00 as an initial value.
  price: price ? Currency.toForm(price) : '',
  quantity: _.isNil(quantity) || (!quantity && !quantityTwo) ? '' : String(quantity),
  quantityTwo: !quantityTwo ? '' : String(quantityTwo),
  kind,
  category,
  unit,
  billItemTypeId,
  moverPositionId,

  // Private
  isEditable: false,
});

const toMutation = ({
  nameFormulaId,
  priceFormulaId,
  minQuantityFormulaId,
  maxQuantityFormulaId,
  billItemId,
  billItemTypeId,
  name,
  description,
  price,
  quantity,
  quantityTwo,
  kind,
  category,
  unit,
  moverPositionId,
}) => ({
  nameFormulaId,
  priceFormulaId,
  minQuantityFormulaId,
  maxQuantityFormulaId,
  billItemId,
  billItemTypeId,
  name,
  description,
  price: toMutationPrice({kind, price}),
  quantity: toMutationQuantity({quantity, quantityTwo}),
  quantityTwo: toMutationQuantityTwo({quantityTwo, quantity}),
  kind,
  category,
  unit,
  moverPositionId,
});

const LineItemForm = {
  UNIT: {
    MINUTE: 'MINUTE',
  },

  getDisplayPrice,
  getQuantity,
  getHasQuantityFormula,
  getCategoryForKind,
  getUnitForKind,
  getIsLineItem,
  validate,

  new: _new,
  edit,
  editFromTemplateLineItem,

  toForm,
  toMutation,
};

export default LineItemForm;
