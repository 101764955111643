// Supermove
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {withFragment} from '@supermove/utils';

import CapacityCalendarSlotMetricKind from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarSlotMetricKind';

const getMetricBySlotPriorityIndex = withFragment(
  ({capacityCalendarSlotMetrics, slotPriorityIndex}) => {
    return capacityCalendarSlotMetrics.find(
      (metric) => metric.slotPriorityIndex === slotPriorityIndex,
    );
  },
  gql`
    fragment CapacityCalendarSettingHelpers_getMetricBySlotPriorityIndex on CapacityCalendar {
      capacityCalendarSlotMetrics {
        id
        metric
        slotPriorityIndex
      }
    }
  `,
);

const getPrimaryCapacityMetricPercentage = withFragment(
  ({capacityCalendarDay, slotMetric}) => {
    switch (slotMetric.metric) {
      case CapacityCalendarSlotMetricKind.TOTAL_CREW_AVAILABLE_BY_POSITION:
        return (
          capacityCalendarDay.totalJobPositionCount / capacityCalendarDay.availablePositionCount
        );
      case CapacityCalendarSlotMetricKind.TOTAL_CREW_AVAILABLE_BY_POSITION_TRUCK_FALLBACK:
        return capacityCalendarDay.totalTruckCount / capacityCalendarDay.availablePositionCount;
      case CapacityCalendarSlotMetricKind.TOTAL_TRUCKS_AVAILABLE:
        return capacityCalendarDay.totalTruckCount / capacityCalendarDay.availableTruckCount;
      case CapacityCalendarSlotMetricKind.TOTAL_CREW_AVAILABLE:
        return (
          capacityCalendarDay.totalAllJobUserCount / capacityCalendarDay.availableAllMoverCount
        );
      default:
        return null;
    }
  },
  gql`
    fragment CapacityCalendarSettingHelpers_getPrimaryCapacityMetricPercentage on CapacityCalendar {
      capacityCalendarDays {
        date
        totalJobPositionCount
        availablePositionCount
        dayNotes
        totalTruckCount
        availableTruckCount
        totalAllJobUserCount
        availableAllMoverCount
      }
    }
  `,
);

const getCapacityCalendarBackgroundColor = withFragment(
  ({capacityCalendar, capacityCalendarDay}) => {
    const primaryCapacityMetricPercentage = getPrimaryCapacityMetricPercentage({
      capacityCalendarDay,
      slotMetric: getMetricBySlotPriorityIndex({
        capacityCalendarSlotMetrics: capacityCalendar.capacityCalendarSlotMetrics,
        slotPriorityIndex: 1,
      }),
    });
    if (primaryCapacityMetricPercentage < 0.7) {
      return {
        backgroundColor: colors.white,
        textColor: colors.gray.secondary,
      };
    } else if (primaryCapacityMetricPercentage < 0.9) {
      return {
        backgroundColor: colors.orange.accent,
        textColor: colors.orange.status,
      };
    } else {
      return {
        backgroundColor: colors.red.accent,
        textColor: colors.red.warning,
      };
    }
  },
  gql`
    ${getPrimaryCapacityMetricPercentage.fragment}
    ${getMetricBySlotPriorityIndex.fragment}
    fragment CapacityCalendarSettingHelpers_getCapacityCalendarBackgroundColor on CapacityCalendar {
      ...CapacityCalendarSettingHelpers_getPrimaryCapacityMetricPercentage
      ...CapacityCalendarSettingHelpers_getMetricBySlotPriorityIndex
    }
  `,
);

const CapacityCalendarSettingHelpers = {
  getCapacityCalendarBackgroundColor,
  getPrimaryCapacityMetricPercentage,
  getMetricBySlotPriorityIndex,
};

export default CapacityCalendarSettingHelpers;
