// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import JobEventForm from '@shared/modules/Job/forms/JobEventForm';

const useCreateJobEventJobTimesheetUpdatedMutation = ({jobEventForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      jobEventForm: JobEventForm.toForm(jobEventForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateJobEventJobTimesheetUpdatedMutation.mutation,
    variables: {
      jobEventForm: JobEventForm.toMutation(form.values.jobEventForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCreateJobEventJobTimesheetUpdatedMutation.mutation = gql`
  mutation useCreateJobEventJobTimesheetUpdatedMutation($jobEventForm: JobEventForm!) {
    response: createJobEventJobTimesheetUpdated(jobEventForm: $jobEventForm) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default useCreateJobEventJobTimesheetUpdatedMutation;
