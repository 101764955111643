// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useToggle} from '@supermove/hooks';

// App
import EditPanel from '@shared/design/components/Panel/EditPanel';
import CompanyTabletSettingsForm from '@shared/modules/Company/forms/CompanyTabletSettingsForm';
import useUpdateCompanyTabletSettingsMutation from '@shared/modules/Organization/hooks/useUpdateCompanyTabletSettingsMutation';
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';
import TabletLoginsPanel from 'modules/Organization/Settings/Company/components/TabletLoginsPanel';

const CompanyTabletsContent = ({organization}) => {
  const editTabletLoginsToggle = useToggle();
  const companyTabletSettingsForm = CompanyTabletSettingsForm.edit(organization);
  const {form, handleSubmit, submitting} = useUpdateCompanyTabletSettingsMutation({
    companyTabletSettingsForm,
    onSuccess: editTabletLoginsToggle.handleToggleOff,
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <ScrollView horizontal>
      <EditPanel
        index={0}
        bodyComponentProps={{organization}}
        editBodyComponentProps={{form}}
        BodyComponent={TabletLoginsPanel}
        EditBodyComponent={TabletLoginsPanel.Edit}
        title={'Tablet Logins'}
        handleSave={handleSubmit}
        isSubmitting={submitting}
        handleCancel={form.handleReset}
        isEditing={editTabletLoginsToggle.isOn}
        handleEdit={editTabletLoginsToggle.handleToggleOn}
        handleClose={editTabletLoginsToggle.handleToggleOff}
      />
    </ScrollView>
  );
};

const OrganizationSettingsCompanyTabletsPage = () => {
  const {loading, data} = useQuery(OrganizationSettingsCompanyTabletsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <CompanySettingsPage
      title={'Tablets'}
      description={'Manage the tablets for your crews, branches, and contractors.'}
    >
      <Loading loading={loading}>
        {() => <CompanyTabletsContent organization={data.viewer.viewingOrganization} />}
      </Loading>
    </CompanySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

OrganizationSettingsCompanyTabletsPage.query = gql`
  ${CompanyTabletSettingsForm.edit.fragment}

  query OrganizationSettingsCompanyTabletsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        ...CompanyTabletSettingsForm_edit
      }
    }
  }
`;

export default OrganizationSettingsCompanyTabletsPage;
