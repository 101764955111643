// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useDrawer, useModal, useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Table from '@shared/design/components/Table';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import ArchiveTagModal from 'modules/Tag/components/ArchiveTagModal';
import TagPill from 'modules/Tag/components/TagPill';
import UnarchiveTagModal from 'modules/Tag/components/UnarchiveTagModal';
import UpdateTagDrawer from 'modules/Tag/components/UpdateTagDrawer';

const TagsTableContainer = Styled.View`
`;

const Text = Styled.Text`
  ${Typography.Body}
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label}
;`;

const EditTagButton = Styled.ButtonV2`
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const getTagsColumnDefinitions = ({refetch, isSystemTag}) => [
  {
    flex: 2,
    headerContent: () => {
      return <TableHeaderText>Name</TableHeaderText>;
    },
    cellContent: ({item: tag}) => {
      return <TagPill tag={tag} maxWidth={300} hasDeleteButton={false} />;
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <TableHeaderText>Category</TableHeaderText>;
    },
    cellContent: ({item: tag}) => {
      return <Text>{tag.category}</Text>;
    },
  },
  {
    flex: 3,
    headerContent: () => {
      return <TableHeaderText>Description</TableHeaderText>;
    },
    cellContent: ({item: tag}) => {
      return <Text>{tag.description ? tag.description : '-'}</Text>;
    },
  },
  {
    flex: 1,
    isHidden: isSystemTag,
    headerContent: () => {
      return <TableHeaderText>Actions</TableHeaderText>;
    },
    cellContent: ({item: tag}) => {
      return <TagActions tag={tag} refetch={refetch} />;
    },
  },
];

const TagActionsMenu = ({popover, archiveTagModal, unarchiveTagModal, tag}) => {
  return (
    <ActionMenuPopover
      popover={popover}
      placement={Popover.Positions.BottomStart}
      width={200}
      offset={[-200, 4]}
    >
      {!tag.isArchived ? (
        <ActionMenuPopover.MenuItem
          onPress={() => {
            archiveTagModal.handleOpen();
            popover.handleClose();
          }}
        >
          Archive Tag
        </ActionMenuPopover.MenuItem>
      ) : (
        <ActionMenuPopover.MenuItem
          onPress={() => {
            unarchiveTagModal.handleOpen();
            popover.handleClose();
          }}
        >
          Unarchive Tag
        </ActionMenuPopover.MenuItem>
      )}
    </ActionMenuPopover>
  );
};

const TagActions = ({tag, refetch}) => {
  const tagActionsPopover = usePopover();
  const {navigator} = useNavigationDOM();

  const editTagDrawer = useDrawer({
    name: 'Edit Tag Drawer',
    enableTracking: true,
  });
  const archiveTagModal = useModal({
    name: 'Archive Tag Modal',
    enableTracking: true,
  });
  const unarchiveTagModal = useModal({
    name: 'Unarchive Tag Modal',
    enableTracking: true,
  });
  return (
    <React.Fragment>
      {!tag.isArchived && (
        <React.Fragment>
          <EditTagButton onPress={editTagDrawer.handleOpen}>
            <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
          </EditTagButton>
          <Space width={12} />
        </React.Fragment>
      )}
      <Popover.Content innerRef={tagActionsPopover.ref}>
        <MoreActionsButton onPress={tagActionsPopover.handleOpen}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </MoreActionsButton>
      </Popover.Content>
      <TagActionsMenu
        popover={tagActionsPopover}
        archiveTagModal={archiveTagModal}
        unarchiveTagModal={unarchiveTagModal}
        tag={tag}
      />
      <UpdateTagDrawer
        key={editTagDrawer.key}
        isOpen={editTagDrawer.isOpen}
        handleClose={editTagDrawer.handleClose}
        tag={tag}
        onSuccess={() => {
          editTagDrawer.handleClose();
          refetch();
        }}
      />
      <ArchiveTagModal
        key={archiveTagModal.key}
        isOpen={archiveTagModal.isOpen}
        handleClose={archiveTagModal.handleClose}
        tag={tag}
        onSuccess={() => {
          archiveTagModal.handleClose();
          refetch();
        }}
      />
      <UnarchiveTagModal
        key={unarchiveTagModal.key}
        isOpen={unarchiveTagModal.isOpen}
        handleClose={unarchiveTagModal.handleClose}
        tag={tag}
        onSuccess={() => {
          unarchiveTagModal.handleClose();
          navigator.push('/settings/project-types/tags?status=active');
        }}
      />
    </React.Fragment>
  );
};

const TagsTable = ({organization, refetch, tags, isSystemTag}) => {
  return (
    <TagsTableContainer>
      <Table
        columnDefinitions={getTagsColumnDefinitions({
          organization,
          refetch,
          isSystemTag,
        })}
        emptyStateText='No tags to display'
        items={tags}
        itemKey={'id'}
      />
    </TagsTableContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TagsTable.fragment = gql`
  ${UpdateTagDrawer.fragment}
  ${TagPill.fragment}
  ${ArchiveTagModal.fragment}
  ${UnarchiveTagModal.fragment}

  fragment TagsTable on Tag {
    id
    name
    emoji
    category
    description
    isArchived
    ...ArchiveTagModal
    ...UnarchiveTagModal
    ...TagPill
    ...UpdateTagDrawer
  }
`;

export default TagsTable;
