const _new = ({
  jobId,
  organizationId,

  // Private
  jobUuid,
}) => {
  return {
    jobId,
    organizationId,
    documentTemplateIdentifiers: [],

    // Private
    jobUuid,
  };
};

const toForm = ({
  jobId,
  organizationId,
  documentTemplateIdentifiers,

  // Private
  jobUuid,
}) => {
  return {
    jobId,
    organizationId,
    documentTemplateIdentifiers,

    // Private
    jobUuid,
  };
};

const toMutation = ({jobId, organizationId, documentTemplateIdentifiers}) => {
  return {
    jobId,
    organizationId,
    documentTemplateIdentifiers,
  };
};

const GenerateCustomDocumentsForJobForm = {
  new: _new,
  toForm,
  toMutation,
};

export default GenerateCustomDocumentsForJobForm;
