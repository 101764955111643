// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {BillItemType} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import Table from '@shared/design/components/Table';
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import Line from 'modules/App/components/Line';
import SidebarPage from 'modules/App/components/SidebarPage';
import TextTooltip from 'modules/App/components/TextTooltip';
import AddBillItemTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/AddBillItemTypeDrawer';
import BillItemTypeActions from 'modules/Organization/Settings/BillingLibraries/components/BillItemTypeActions';
import BillingLibraryAddBillItemModal from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryAddBillItemModal';
import BillingLibraryBillItemTypesTreeNavigation from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryBillItemTypesTreeNavigation';
import BillingLibraryPageHeader from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryPageHeader';
import BillingLibraryPageNavigationTabs from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryPageNavigationTabs';

const Container = Styled.View`
`;

const SectionContainer = Styled.View`
  width: ${({responsive}) => (responsive.desktop ? '100%' : '940px')};
`;

const TableLabelRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ContentContainer = Styled.View`
  flex: 1;
`;

const RowContainer = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const HeaderContainer = Styled.View`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? '24px' : '12px')};
`;

const BodyContainer = Styled.View`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? '24px' : '12px')};
  backgroundColor: ${colors.gray.background};
  flex: 1;
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.PageHeading}
;`;

const TableHeaderText = Styled.Text`
  ${Typography.Responsive.Heading1}
;`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const DescriptionText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const TableCellTextTag = Styled.Text`
  ${({vars = {}}) => (vars.hasFormula ? Typography.Label2 : Typography.Body3)}
  color: ${({vars = {}}) => (vars.hasFormula ? colors.orange.status : colors.gray.primary)};
`;

const IconContainer = Styled.View`
  width: 16px;
  padding-top: 2px;
`;

const PerPositionRatesCell = ({billItemType}) => {
  return (
    <React.Fragment>
      {billItemType.isParent && (
        <TextTooltip placement={'top'} text={`This fee item has position rates`}>
          <IconContainer>
            <Icon color={colors.blue.interactive} size={Icon.Sizes.Small} source={Icon.Users} />
          </IconContainer>
        </TextTooltip>
      )}
    </React.Fragment>
  );
};

const TableCellText = ({children, vars, style, tooltipContent}) => {
  const tableCellImplContent = (
    <TableCellTextTag vars={vars} style={style}>
      {children}
    </TableCellTextTag>
  );
  if (tooltipContent) {
    return (
      <React.Fragment>
        <TextTooltip text={tooltipContent}>{tableCellImplContent}</TextTooltip>
      </React.Fragment>
    );
  }
  return tableCellImplContent;
};

const CheckCell = ({isChecked}) => (
  <React.Fragment>
    {isChecked && (
      <IconContainer style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
        <Icon color={colors.gray.primary} size={16} source={Icon.Check} />
      </IconContainer>
    )}
  </React.Fragment>
);

const getAccountingItemTextWithChildrenBillItemType = ({billItemType}) => {
  const billItemTypesName = [];

  if (billItemType.externalInvoiceItem) {
    billItemTypesName.push(billItemType.externalInvoiceItem.name);
  }

  billItemType.childBillItemTypes.forEach((childBillItemType) => {
    if (childBillItemType.externalInvoiceItem) {
      billItemTypesName.push(childBillItemType.externalInvoiceItem.name);
    }
  });

  return billItemTypesName.join(', ');
};

const getSuppliesColumnDefinitions = ({refetch, userId, responsive, organization}) => [
  {
    flex: 1,
    headerContent: () => <Table.HeaderText>Item Name</Table.HeaderText>,
    cellContent: ({item: billItemType}) => (
      <TableCellText
        vars={{
          hasFormula: BillItemType.hasNameFormula(billItemType),
        }}
        tooltipContent={BillItemType.renderNameFormulaString(billItemType)}
      >
        {billItemType.name}
      </TableCellText>
    ),
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText />,
    cellContent: ({item: billItemType}) => <PerPositionRatesCell billItemType={billItemType} />,
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText>Value</Table.HeaderText>,
    cellContent: ({item: billItemType}) => (
      <TableCellText
        vars={{
          hasFormula: BillItemType.hasAmountFormula(billItemType),
        }}
        tooltipContent={BillItemType.renderAmountFormulaString(billItemType)}
      >
        {BillItemType.getDisplayValue(billItemType)}
      </TableCellText>
    ),
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText>Quantity</Table.HeaderText>,
    cellContent: ({item: billItemType}) => (
      <TableCellText
        vars={{
          hasFormula: BillItemType.hasQuantityFormula(billItemType),
        }}
      >
        {BillItemType.getDisplayQuantity(billItemType)}
      </TableCellText>
    ),
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText>Taxable</Table.HeaderText>,
    cellContent: ({item: billItemType}) => <CheckCell isChecked={billItemType.isTaxable} />,
  },
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Description</Table.HeaderText>,
    cellContent: ({item: billItemType}) => (
      <TableCellText>{billItemType.description}</TableCellText>
    ),
  },
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Accounting Item</Table.HeaderText>,
    cellContent: ({item: billItemType}) => (
      <TableCellText>{getAccountingItemTextWithChildrenBillItemType({billItemType})}</TableCellText>
    ),
  },
  {
    flex: 0.7,
    headerContent: () => <Table.HeaderText>Last Updated</Table.HeaderText>,
    cellContent: ({item: billItemType}) => (
      <Container>
        <TableCellText>{BillItemType.getDisplayUpdatedAt(billItemType)}</TableCellText>
        {!!billItemType.updatedBy && (
          <React.Fragment>
            <Space height={4} />
            <DescriptionText responsive={responsive}>
              {billItemType.updatedBy.fullName}
            </DescriptionText>
          </React.Fragment>
        )}
      </Container>
    ),
  },
  {
    flex: 1,
    headerContent: () => <Table.HeaderText />,
    cellContent: ({item: billItemType}) => (
      <BillItemTypeActions
        billItemType={billItemType}
        refetch={refetch}
        userId={userId}
        organization={organization}
      />
    ),
  },
];

const getFeesAndDiscountsColumnDefinitions = ({refetch, userId, responsive, organization}) => [
  {
    flex: 1,
    headerContent: () => <Table.HeaderText>Item Name</Table.HeaderText>,
    cellContent: ({item: billItemType}) => (
      <TableCellText
        vars={{
          hasFormula: BillItemType.hasNameFormula(billItemType),
        }}
        tooltipContent={BillItemType.renderNameFormulaString(billItemType)}
      >
        {billItemType.name}
      </TableCellText>
    ),
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText />,
    cellContent: ({item: billItemType}) => <PerPositionRatesCell billItemType={billItemType} />,
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText>Value</Table.HeaderText>,
    cellContent: ({item: billItemType}) => (
      <TableCellText
        vars={{
          hasFormula: BillItemType.hasAmountFormula(billItemType),
        }}
        tooltipContent={BillItemType.renderAmountFormulaString(billItemType)}
      >
        {BillItemType.getDisplayValue(billItemType)}
      </TableCellText>
    ),
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText>Quantity</Table.HeaderText>,
    cellContent: ({item: billItemType}) => (
      <TableCellText
        vars={{
          hasFormula: BillItemType.hasQuantityFormula(billItemType),
        }}
      >
        {BillItemType.getDisplayQuantity(billItemType)}
      </TableCellText>
    ),
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText>Taxable</Table.HeaderText>,
    cellContent: ({item: billItemType}) => <CheckCell isChecked={billItemType.isTaxable} />,
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText>After/Before</Table.HeaderText>,
    cellContent: ({item: billItemType}) => (
      <TableCellText>{BillItemType.getDisplayBillStage(billItemType)}</TableCellText>
    ),
  },
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Description</Table.HeaderText>,
    cellContent: ({item: billItemType}) => (
      <TableCellText>{billItemType.description}</TableCellText>
    ),
  },
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Accounting Item</Table.HeaderText>,
    cellContent: ({item: billItemType}) => (
      <TableCellText>{getAccountingItemTextWithChildrenBillItemType({billItemType})}</TableCellText>
    ),
  },
  {
    flex: 0.7,
    headerContent: () => <Table.HeaderText>Last Updated</Table.HeaderText>,
    cellContent: ({item: billItemType}) => (
      <Container>
        <TableCellText>{BillItemType.getDisplayUpdatedAt(billItemType)}</TableCellText>
        {!!billItemType.updatedBy && (
          <React.Fragment>
            <Space height={4} />
            <DescriptionText responsive={responsive}>
              {billItemType.updatedBy.fullName}
            </DescriptionText>
          </React.Fragment>
        )}
      </Container>
    ),
  },
  {
    flex: 1,
    headerContent: () => <Table.HeaderText />,
    cellContent: ({item: billItemType}) => (
      <BillItemTypeActions
        billItemType={billItemType}
        refetch={refetch}
        userId={userId}
        organization={organization}
      />
    ),
  },
];

const SuppliesSection = ({billingLibrary, refetch, userId, organization}) => {
  const responsive = useResponsive();
  const addSupplyModal = useModal();
  // (TODO) Kevin: This can be removed once FF is fully turned on
  const supplies = _.filter(
    billingLibrary.billItemTypesByCategory,
    (billItemType) => billItemType.category === BillItemTypeCategory.SUPPLIES,
  );
  return (
    <SectionContainer responsive={responsive}>
      <TableLabelRow responsive={responsive}>
        <TableHeaderText responsive={responsive}>Supplies</TableHeaderText>
        <Space style={{flex: 1}} />
        <Button iconLeft={Icon.Plus} onPress={addSupplyModal.handleOpen} text={'Add Supply'} />
      </TableLabelRow>
      <Space height={8} />
      <Table
        columnDefinitions={getSuppliesColumnDefinitions({
          refetch,
          userId,
          responsive,
          organization,
        })}
        items={supplies}
        emptyStateText={'No items to display'}
        headerStyle={{backgroundColor: colors.gray.background}}
        rowStyle={{alignItems: 'flex-start'}}
        isDense
      />
      <BillingLibraryAddBillItemModal
        key={addSupplyModal.key}
        isOpen={addSupplyModal.isOpen}
        handleClose={addSupplyModal.handleClose}
        billingLibrary={billingLibrary}
        category={BillItemTypeCategory.SUPPLIES}
        refetch={refetch}
        userId={userId}
      />
    </SectionContainer>
  );
};

const FeesSection = ({billingLibrary, refetch, userId, organization}) => {
  const responsive = useResponsive();
  const addFeeModal = useModal();
  // (TODO) Kevin: This can be removed once FF is fully turned on
  const fees = _.filter(
    billingLibrary.billItemTypesByCategory,
    (billItemType) => billItemType.category === BillItemTypeCategory.FEES,
  );
  return (
    <SectionContainer responsive={responsive}>
      <TableLabelRow>
        <TableHeaderText responsive={responsive}>Fees</TableHeaderText>
        <Space style={{flex: 1}} />
        <Button iconLeft={Icon.Plus} onPress={addFeeModal.handleOpen} text={'Add Fee'} />
      </TableLabelRow>
      <Space height={8} />
      <Table
        columnDefinitions={getFeesAndDiscountsColumnDefinitions({refetch, userId, organization})}
        items={fees}
        emptyStateText={'No items to display'}
        headerStyle={{backgroundColor: colors.gray.background}}
        rowStyle={{alignItems: 'flex-start'}}
        isDense
      />
      <BillingLibraryAddBillItemModal
        key={addFeeModal.key}
        isOpen={addFeeModal.isOpen}
        handleClose={addFeeModal.handleClose}
        billingLibrary={billingLibrary}
        category={BillItemTypeCategory.FEES}
        refetch={refetch}
        userId={userId}
      />
    </SectionContainer>
  );
};

const DiscountsSection = ({billingLibrary, refetch, userId}) => {
  const responsive = useResponsive();
  const addDiscountModal = useModal();
  // (TODO) Kevin: This can be removed once FF is fully turned on
  const discounts = _.filter(
    billingLibrary.billItemTypesByCategory,
    (billItemType) => billItemType.category === BillItemTypeCategory.DISCOUNTS,
  );
  return (
    <SectionContainer responsive={responsive}>
      <TableLabelRow>
        <TableHeaderText responsive={responsive}>Discounts</TableHeaderText>
        <Space style={{flex: 1}} />
        <Button iconLeft={Icon.Plus} onPress={addDiscountModal.handleOpen} text={'Add Discount'} />
      </TableLabelRow>
      <Space height={8} />
      <Table
        columnDefinitions={getFeesAndDiscountsColumnDefinitions({refetch, userId})}
        items={discounts}
        emptyStateText={'No items to display'}
        headerStyle={{backgroundColor: colors.gray.background}}
        rowStyle={{alignItems: 'flex-start'}}
        isDense
      />
      <BillingLibraryAddBillItemModal
        key={addDiscountModal.key}
        isOpen={addDiscountModal.isOpen}
        handleClose={addDiscountModal.handleClose}
        billingLibrary={billingLibrary}
        category={BillItemTypeCategory.DISCOUNTS}
        refetch={refetch}
        userId={userId}
      />
    </SectionContainer>
  );
};

const BillItemTypeSection = ({
  buttonText,
  headerText,
  columnDefinitions,
  category,
  billingLibrary,
  refetch,
  userId,
}) => {
  const responsive = useResponsive();
  const drawer = useDrawer({
    name: `${buttonText} Drawer`,
    useTracking: true,
  });
  // (TODO) Kevin: This can be removed once FF is fully turned on
  const items = _.filter(
    billingLibrary.billItemTypesByCategory,
    (billItemType) => billItemType.category === category && !billItemType.isChild,
  );
  return (
    <SectionContainer responsive={responsive}>
      <TableLabelRow>
        <Container>
          <TableHeaderText responsive={responsive}>{headerText}</TableHeaderText>
          <React.Fragment>
            <Space height={8} />
            <Text responsive={responsive} style={{color: colors.gray.secondary}}>
              {'Supermove will automatically calculate'}
              <LabelText responsive={responsive} style={{color: colors.orange.status}}>
                {' '}
                orange highlighted items{' '}
              </LabelText>
              {'based on saved formulas.'}
            </Text>
          </React.Fragment>
        </Container>
        <Space style={{flex: 1}} />
        <Button iconLeft={Icon.Plus} onPress={drawer.handleOpen} text={buttonText} />
      </TableLabelRow>
      <Space height={16} />
      <Table
        columnDefinitions={columnDefinitions}
        items={items}
        emptyStateText={'No items to display'}
        isDense
      />
      <AddBillItemTypeDrawer
        key={drawer.key}
        isOpen={drawer.isOpen}
        handleClose={drawer.handleClose}
        billingLibrary={billingLibrary}
        category={category}
        refetch={refetch}
        userId={userId}
      />
    </SectionContainer>
  );
};

const BillingLibraryBillItemTypesContent = ({
  organization,
  billingLibrary,
  refetch,
  userId,
  responsive,
}) => {
  const {isEnabledBillingEngineTimesheetVariables} = organization.features;
  return (
    <React.Fragment>
      <Space height={24} />
      <HeaderText responsive={responsive}>Bill Items</HeaderText>
      <Space height={8} />
      <Text responsive={responsive} style={{color: colors.gray.secondary}}>
        {
          'Manage your bill items, including supplies, fees, and discounts. Supermove will automatically calculate'
        }
        <LabelText responsive={responsive} style={{color: colors.orange.status}}>
          {' '}
          orange highlighted items{' '}
        </LabelText>
        {'based on saved formulas.'}
      </Text>
      <Space height={32} />
      {isEnabledBillingEngineTimesheetVariables ? (
        <React.Fragment>
          <BillItemTypeSection
            buttonText={'Add Supply'}
            headerText={'Supplies'}
            columnDefinitions={getSuppliesColumnDefinitions({
              refetch,
              userId,
              organization,
            })}
            category={BillItemTypeCategory.SUPPLIES}
            billingLibrary={billingLibrary}
            refetch={refetch}
            userId={userId}
          />
          <Space height={32} />
          <BillItemTypeSection
            buttonText={'Add Fee'}
            headerText={'Fees'}
            columnDefinitions={getFeesAndDiscountsColumnDefinitions({
              refetch,
              userId,
              organization,
            })}
            category={BillItemTypeCategory.FEES}
            billingLibrary={billingLibrary}
            refetch={refetch}
            userId={userId}
          />
          <Space height={32} />
          <BillItemTypeSection
            buttonText={'Add Discount'}
            headerText={'Discounts'}
            columnDefinitions={getFeesAndDiscountsColumnDefinitions({
              refetch,
              userId,
              organization,
            })}
            category={BillItemTypeCategory.DISCOUNTS}
            billingLibrary={billingLibrary}
            refetch={refetch}
            userId={userId}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <SuppliesSection
            billingLibrary={billingLibrary}
            refetch={refetch}
            userId={userId}
            organization={organization}
          />
          <Space height={36} />
          <FeesSection
            billingLibrary={billingLibrary}
            refetch={refetch}
            userId={userId}
            organization={organization}
          />
          <Space height={36} />
          <DiscountsSection
            billingLibrary={billingLibrary}
            refetch={refetch}
            userId={userId}
            organization={organization}
          />
        </React.Fragment>
      )}
      <Space height={36} />
    </React.Fragment>
  );
};

const BillingLibraryBillItemTypesContentV2 = ({
  organization,
  billingLibrary,
  refetch,
  userId,
  params,
}) => {
  return (
    <React.Fragment>
      <Space height={24} />
      <React.Fragment>
        {getTreeNavigationRenderItems({
          key: params.category,
          refetch,
          userId,
          billingLibrary,
          organization: billingLibrary.organization,
        })}
      </React.Fragment>
      <Space height={36} />
    </React.Fragment>
  );
};

const getTreeNavigationRenderItems = ({key, refetch, userId, billingLibrary, organization}) => {
  switch (key) {
    case 'discounts':
      return (
        <BillItemTypeSection
          buttonText={'Add Discount'}
          headerText={'Discounts'}
          columnDefinitions={getFeesAndDiscountsColumnDefinitions({
            refetch,
            userId,
            organization,
          })}
          category={BillItemTypeCategory.DISCOUNTS}
          billingLibrary={billingLibrary}
          refetch={refetch}
          userId={userId}
        />
      );
    case 'supplies':
      return (
        <BillItemTypeSection
          buttonText={'Add Supply'}
          headerText={'Supplies'}
          columnDefinitions={getSuppliesColumnDefinitions({
            refetch,
            userId,
            organization,
          })}
          category={BillItemTypeCategory.SUPPLIES}
          billingLibrary={billingLibrary}
          refetch={refetch}
          userId={userId}
        />
      );
    case 'fees':
    default:
      return (
        <BillItemTypeSection
          buttonText={'Add Fee'}
          headerText={'Fees'}
          columnDefinitions={getFeesAndDiscountsColumnDefinitions({
            refetch,
            userId,
            organization,
          })}
          category={BillItemTypeCategory.FEES}
          billingLibrary={billingLibrary}
          refetch={refetch}
          userId={userId}
        />
      );
  }
};

const getCategoryListFromPath = ({params}) => {
  switch (params.category) {
    case 'fees':
      return [BillItemTypeCategory.FEES];
    case 'discounts':
      return [BillItemTypeCategory.DISCOUNTS];
    case 'supplies':
      return [BillItemTypeCategory.SUPPLIES];
    default:
      return null;
  }
};

const BillingLibraryBillItemTypesPage = () => {
  const {params} = useNavigationDOM();
  const responsive = useResponsive();

  return (
    <SidebarPage
      selected={'settings'}
      query={BillingLibraryBillItemTypesPage.query}
      variables={{
        billingLibraryUuid: params.billingLibraryUuid,
        categories: getCategoryListFromPath({params}),
      }}
      fetchPolicy={'cache-and-network'}
    >
      {({data, refetch}) => {
        return (
          <Container style={{flex: 1}}>
            <BillingLibraryPageHeader billingLibrary={data.billingLibrary} />
            <HeaderContainer responsive={responsive}>
              <BillingLibraryPageNavigationTabs billingLibrary={data.billingLibrary} />
            </HeaderContainer>
            <Line />
            {data.viewer.viewingOrganization.features.isEnabledBillingEngineTimesheetVariables ? (
              <RowContainer responsive={responsive}>
                <BillingLibraryBillItemTypesTreeNavigation
                  billingLibraryUuid={data.billingLibrary.uuid}
                />
                <BodyContainer responsive={responsive}>
                  <ScrollView>
                    <BillingLibraryBillItemTypesContentV2
                      organization={data.viewer.viewingOrganization}
                      billingLibrary={data.billingLibrary}
                      refetch={refetch}
                      userId={data.viewer.id}
                      params={params}
                      responsive={responsive}
                    />
                  </ScrollView>
                </BodyContainer>
              </RowContainer>
            ) : (
              <ContentContainer responsive={responsive}>
                <BodyContainer responsive={responsive}>
                  <ScrollView horizontal contentContainerStyle={{flexGrow: 1}}>
                    <ScrollView>
                      <BillingLibraryBillItemTypesContent
                        organization={data.viewer.viewingOrganization}
                        billingLibrary={data.billingLibrary}
                        refetch={refetch}
                        userId={data.viewer.id}
                        responsive={responsive}
                      />
                    </ScrollView>
                  </ScrollView>
                </BodyContainer>
              </ContentContainer>
            )}
          </Container>
        );
      }}
    </SidebarPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryBillItemTypesPage.query = gql`
  ${BillingLibraryAddBillItemModal.fragment}
  ${AddBillItemTypeDrawer.fragment}
  ${BillingLibraryPageHeader.fragment}
  ${BillingLibraryPageNavigationTabs.fragment}
  ${BillingLibraryBillItemTypesTreeNavigation.fragment}
  ${BillItemTypeActions.fragment}
  ${BillItemType.getDisplayBillStage.fragment}
  ${BillItemType.getDisplayQuantity.fragment}
  ${BillItemType.getDisplayValue.fragment}
  ${BillItemType.getDisplayUpdatedAt.fragment}
  ${BillItemType.hasAmountFormula.fragment}
  ${BillItemType.hasNameFormula.fragment}
  ${BillItemType.hasQuantityFormula.fragment}
  ${BillItemType.renderAmountFormulaString.fragment}
  ${BillItemType.renderNameFormulaString.fragment}

  query BillingLibraryBillItemTypesPage($billingLibraryUuid: String!, $categories: [String]) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledBillingEngineTimesheetVariables: isEnabled(feature: "BILLING_ENGINE_TIMESHEET_VARIABLES")
        }
      }
    }
    billingLibrary(billingLibraryUuid: $billingLibraryUuid) {
      id
      name
      organization {
        id
        ...BillItemTypeActions_Organization
      }
      billItemTypesByCategory(categories: $categories) {
        id
        category
        name
        kind
        description
        isChild
        isParent
        isTaxable
        updatedBy {
          id
          fullName
        }
        externalInvoiceItem {
          id
          name
        }
        childBillItemTypes {
          id
          externalInvoiceItem {
            id
            name
          }
        }
        ...BillItemTypeActions
        ...BillItemType_getDisplayBillStage
        ...BillItemType_getDisplayValue
        ...BillItemType_getDisplayQuantity
        ...BillItemType_getDisplayUpdatedAt
        ...BillItemType_hasAmountFormula
        ...BillItemType_hasNameFormula
        ...BillItemType_hasQuantityFormula
        ...BillItemType_renderAmountFormulaString
        ...BillItemType_renderNameFormulaString
      }
      ...BillingLibraryBillItemTypesTreeNavigation
      ...BillingLibraryAddBillItemModal
      ...AddBillItemTypeDrawer
      ...BillingLibraryPageHeader
      ...BillingLibraryNavigationTabs
    }
  }
`;

export default BillingLibraryBillItemTypesPage;
