// App
import ProjectStatus from '@shared/modules/Project/enums/ProjectStatus';
import StorageProjectStatus from '@shared/modules/Project/enums/StorageProjectStatus';
import WorkflowModelDataTypes from '@shared/modules/Workflow/enums/WorkflowModelDataTypes';

// Task Properties
const TASK_IS_COMPLETED = 'TASK_IS_COMPLETED';

// Project Properties
const PROJECT_MOVE_STATUS = 'PROJECT_MOVE_STATUS';
const PROJECT_STORAGE_STATUS = 'PROJECT_STORAGE_STATUS';
const PROJECT_TAG = 'PROJECT_TAG';

// Job Properties
const JOB_TAG = 'JOB_TAG';

const MODEL_PROPERTY_OPTIONS = {
  [TASK_IS_COMPLETED]: {
    label: 'Is completed',
    value: TASK_IS_COMPLETED,
    dataType: WorkflowModelDataTypes.BOOLEAN,
    dbPropertyName: 'isCompleted',
  },
  [PROJECT_MOVE_STATUS]: {
    label: 'Move status',
    value: PROJECT_MOVE_STATUS,
    dataType: WorkflowModelDataTypes.ENUM,
    enumOptions: [...ProjectStatus.OPTIONS],
    dbPropertyName: 'status',
  },
  [PROJECT_STORAGE_STATUS]: {
    label: 'Storage status',
    value: PROJECT_STORAGE_STATUS,
    dataType: WorkflowModelDataTypes.ENUM,
    enumOptions: [...StorageProjectStatus.OPTIONS],
    dbPropertyName: 'status',
  },
  [PROJECT_TAG]: {
    label: 'Project Tag',
    value: PROJECT_TAG,
    dataType: WorkflowModelDataTypes.NUMBER,
    // TODO(KEVIN): Fix this db.PropertyName
    dbPropertyName: 'tagAssignment.tag.id',
  },
  [JOB_TAG]: {
    label: 'Job Tag',
    value: JOB_TAG,
    dataType: WorkflowModelDataTypes.NUMBER,
    // TODO(KEVIN): Fix this db.PropertyName
    dbPropertyName: 'tagAssignment.tag.id',
  },
};

const getEnumOptions = (modelProperty) => {
  return MODEL_PROPERTY_OPTIONS[modelProperty].enumOptions;
};

const getDbPropertyName = (modelProperty) => {
  return modelProperty && MODEL_PROPERTY_OPTIONS[modelProperty].dbPropertyName;
};

const WorkflowModelProperties = {
  // Enum Options
  TASK_IS_COMPLETED,
  PROJECT_MOVE_STATUS,
  PROJECT_STORAGE_STATUS,
  PROJECT_TAG,

  JOB_TAG,

  MODEL_PROPERTY_OPTIONS,

  getEnumOptions,
  getDbPropertyName,
};

export default WorkflowModelProperties;
