// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useSendBulkInvoicesToCodatMutation = ({invoiceIds, onSuccess, onError}) => {
  const form = useForm({
    enableReinitialize: true,
    initialValues: {
      invoiceIds,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSendBulkInvoicesToCodatMutation.mutation,
    variables: {
      invoiceIds: form.values.invoiceIds,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSendBulkInvoicesToCodatMutation.mutation = gql`
  mutation useSendBulkInvoicesToCodatMutation($invoiceIds: [Int]!) {
    response: sendBulkInvoicesToCodat(invoiceIds: $invoiceIds) {
      ${gql.errors}
    }
  }
`;

export default useSendBulkInvoicesToCodatMutation;
