// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useInlineFormMutation, useQuery, useState} from '@supermove/hooks';

// App
import Callout from '@shared/design/components/Callout';
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import CalendarKind from '@shared/modules/Settings/enums/CalendarKind';
import SettingsForm from '@shared/modules/Settings/forms/SettingsForm';
import SuperAdminSettingsPage from 'modules/Organization/Settings/SuperAdmin/components/SuperAdminSettingsPage';
import SuperAdminSettingsSection from 'modules/Organization/Settings/SuperAdmin/components/SuperAdminSettingsSection';
import SuperAdminSettingsSectionOption from 'modules/Organization/Settings/SuperAdmin/components/SuperAdminSettingsSectionOption';

const CalloutContainer = Styled.View`
`;

const useToggleSettingsIsMovesCalendarEnabledMutation = ({settingsForm}) => {
  return useInlineFormMutation({
    name: 'settingsForm',
    form: settingsForm,
    toForm: SettingsForm.toForm,
    toMutation: SettingsForm.toMutation,
    mutation: gql`
      mutation useToggleSettingsIsMovesCalendarEnabledMutation($settingsForm: SettingsForm!) {
        response: toggleSettingsIsMovesCalendarEnabled(settingsForm: $settingsForm) {
          ${gql.errors}
          settings {
            id
            isMovesCalendarEnabled
          }
        }
      }
    `,
  });
};

const useToggleSettingsIsBookingCalendarEnabledMutation = ({settingsForm}) => {
  return useInlineFormMutation({
    name: 'settingsForm',
    form: settingsForm,
    toForm: SettingsForm.toForm,
    toMutation: SettingsForm.toMutation,
    mutation: gql`
      mutation useToggleSettingsIsBookingCalendarEnabledMutation($settingsForm: SettingsForm!) {
        response: toggleSettingsIsBookingCalendarEnabled(settingsForm: $settingsForm) {
          ${gql.errors}
          settings {
            id
            isBookingCalendarEnabled
          }
        }
      }
    `,
  });
};

const useUpdateSettingsPrimaryCalendarMutation = ({settings, viewer, refetch}) => {
  return useInlineFormMutation({
    name: 'settingsForm',
    form: {
      settingsId: settings.id,
      primaryCalendar: settings.primaryCalendar,
      updatedById: viewer.id,
    },
    mutation: gql`
      mutation useUpdateSettingsPrimaryCalendarMutation($settingsForm: SettingsForm!) {
        response: updateSettingsPrimaryCalendar(settingsForm: $settingsForm) {
          ${gql.errors}
          settings {
            id
            primaryCalendar
          }
        }
      }
    `,
    onSuccess: refetch,
  });
};

const SuperAdminCalendarSettingsPageContent = ({settings, viewer, refetch}) => {
  const isDisabled = !viewer.viewingOrganization.features.isEnabledLegacyCalendarConfig;
  const {isBookingCalendarEnabled, isMovesCalendarEnabled} = settings;
  const settingsForm = SettingsForm.edit(settings, {updatedById: viewer.id});
  const toggleSettingsIsMovesCalendarEnabledMutation =
    useToggleSettingsIsMovesCalendarEnabledMutation({settingsForm, refetch});
  const toggleSettingsIsBookingCalendarEnabledMutation =
    useToggleSettingsIsBookingCalendarEnabledMutation({settingsForm});
  const updateSettingsPrimaryCalendarMutation = useUpdateSettingsPrimaryCalendarMutation({
    settings,
    viewer,
    refetch,
  });

  return (
    <SuperAdminSettingsSection title={'Move Calendars (Legacy)'}>
      <CalloutContainer>
        <Callout
          text={
            'All companies should be using the Capacity calendar. These settings are only for legacy customers.'
          }
        />
      </CalloutContainer>
      <Space height={16} />
      <SuperAdminSettingsSectionOption
        name={'Show Moves Calendar'}
        description={
          `Enables the Moves calendar in the “Moves” tab. ` +
          `This calendar shows an overview of how many jobs there are in a month, as well as a view of all the jobs on a given day.`
        }
        isDisabled={isDisabled}
        isEnabled={isMovesCalendarEnabled}
        onPress={toggleSettingsIsMovesCalendarEnabledMutation.handleSubmit}
      />
      <Space height={12} />
      <SuperAdminSettingsSectionOption
        name={'Show Booking Calendar'}
        description={`Enables the Booking calendar in the “Moves” tab. This calendar shows an overview of the jobs within a week.`}
        isDisabled={isDisabled}
        isEnabled={isBookingCalendarEnabled}
        onPress={toggleSettingsIsBookingCalendarEnabledMutation.handleSubmit}
      />
      <Space height={12} />
      <SuperAdminSettingsSectionOption
        name={'Primary Calendar'}
        description={`Select the primary calendar to be shown in the “Moves” tab. If the Moves calendar is switched off, then this will default to the Capacity calendar.`}
        isDisabled={isDisabled || !isMovesCalendarEnabled}
        ActionButtonComponent={FieldInput}
        actionButtonComponentProps={{
          ...updateSettingsPrimaryCalendarMutation.form,
          name: 'settingsForm.primaryCalendar',
          component: DropdownInput,
          isResponsive: true,
          input: {
            isDisabled: isDisabled || !isMovesCalendarEnabled,
            isPortaled: true,
            options: CalendarKind.getDropdownOptions(),
            style: {width: '100%'},
            setFieldValue: updateSettingsPrimaryCalendarMutation.form.setFieldValue,
            onChangeValue: (value) => {
              updateSettingsPrimaryCalendarMutation.form.setFieldValue(
                'settingsForm.primaryCalendar',
                value,
              );
              setTimeout(() => updateSettingsPrimaryCalendarMutation.handleSubmit(), 0);
            },
          },
          style: {flex: 1},
        }}
      />
      <Space height={12} />
    </SuperAdminSettingsSection>
  );
};

const SuperAdminCalendarSettingsPage = () => {
  const [refetchKey, setRefetchKey] = useState(0);
  const {loading, data, refetch} = useQuery(SuperAdminCalendarSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <SuperAdminSettingsPage
      title={'Calendars'}
      description={
        'Configure the calendars. These changes will apply to the company, and if applicable, its branches.'
      }
      isLoading={loading}
      viewer={data?.viewer}
    >
      <SuperAdminCalendarSettingsPageContent
        key={refetchKey}
        settings={data?.viewer.viewingOrganization.settings}
        viewer={data?.viewer}
        refetch={() => {
          refetch();
          setRefetchKey(refetchKey + 1);
        }}
      />
    </SuperAdminSettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SuperAdminCalendarSettingsPage.query = gql`
  ${SettingsForm.edit.fragment}
  ${SuperAdminSettingsPage.fragment}

  query SuperAdminCalendarSettingsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        settings {
          id
          isBookingCalendarEnabled
          isMovesCalendarEnabled
          ...SettingsForm_edit
        }
        features {
          isEnabledLegacyCalendarConfig: isEnabled(feature: "LEGACY_CALENDAR_CONFIG")
        }
      }
      ...SuperAdminSettingsPage
    }
  }
`;

export default SuperAdminCalendarSettingsPage;
