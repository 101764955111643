// Libraries
import _ from 'lodash';

// App
import WorkflowModelProperties from '@shared/modules/Workflow/enums/WorkflowModelProperties';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';

const MODEL_TYPE_TO_MODEL_PROPERTIES = {
  [WorkflowStepActionKind.CATEGORIES.TASK]: [
    WorkflowModelProperties.MODEL_PROPERTY_OPTIONS[WorkflowModelProperties.TASK_IS_COMPLETED],
  ],
  [WorkflowStepActionKind.CATEGORIES.PROJECT]: [
    WorkflowModelProperties.MODEL_PROPERTY_OPTIONS[WorkflowModelProperties.PROJECT_MOVE_STATUS],
    WorkflowModelProperties.MODEL_PROPERTY_OPTIONS[WorkflowModelProperties.PROJECT_STORAGE_STATUS],
  ],
};

const getModelPropertyOptionsFormStepReferenceKind = (stepReferenceKind) => {
  return MODEL_TYPE_TO_MODEL_PROPERTIES[stepReferenceKind];
};

const resetGetDataAndStopIfForm = ({form, field}) => {
  form.setFieldValue(`${field}.projectTagInclusionList`, []);
  form.setFieldValue(`${field}.projectTagExclusionList`, []);
  form.setFieldValue(`${field}.jobTagInclusionList`, []);
  form.setFieldValue(`${field}.jobTagExclusionList`, []);
  form.setFieldValue(`${field}.projectStatusEquals`, '');
  form.setFieldValue(`${field}.projectStatusDoesNotEqual`, '');
  form.setFieldValue(`${field}.taskIsCompleted`, null);
  form.setFieldValue(`${field}.taskIsNotCompleted`, null);
  form.setFieldValue(`${field}.checkProperty`, null);
  form.setFieldValue(`${field}.conditionProperty`, null);
};

const isGetDataAndStopIfFormCompleted = ({form, field}) => {
  const values = {
    projectTagInclusionList: _.get(form.values, `${field}.projectTagInclusionList`),
    projectTagExclusionList: _.get(form.values, `${field}.projectTagExclusionList`),
    jobTagInclusionList: _.get(form.values, `${field}.jobTagInclusionList`),
    jobTagExclusionList: _.get(form.values, `${field}.jobTagExclusionList`),
    projectStatusEquals: _.get(form.values, `${field}.projectStatusEquals`),
    projectStatusDoesNotEqual: _.get(form.values, `${field}.projectStatusDoesNotEqual`),
    taskIsCompleted: _.get(form.values, `${field}.taskIsCompleted`),
    taskIsNotCompleted: _.get(form.values, `${field}.taskIsNotCompleted`),
  };
  return Object.values(values).some((value) => !_.isEmpty(value));
};

const WorkflowGetDataStopIfHelpers = {
  getModelPropertyOptionsFormStepReferenceKind,
  resetGetDataAndStopIfForm,
  isGetDataAndStopIfFormCompleted,
};

export default WorkflowGetDataStopIfHelpers;
