// Libraries
import React from 'react';

// Supermove
import {Space, MultiDropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Payment} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import ProjectTypePaymentMethod from '@shared/modules/Project/enums/ProjectTypePaymentMethod';
import ProjectTypeBillSettingsForm from '@shared/modules/Project/forms/ProjectTypeBillingSettingsForm';
import useUpdateProjectTypeBillSettingsMutation from '@shared/modules/Project/hooks/useUpdateProjectTypeBillSettingsMutation';

const EditProjectTypeBillingSettingsModal = ({projectType, isOpen, handleClose, refetch}) => {
  const projectTypeBillingSettingsForm = ProjectTypeBillSettingsForm.edit(projectType);

  const {form, submitting, handleSubmit} = useUpdateProjectTypeBillSettingsMutation({
    projectTypeBillingSettingsForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: () => {},
  });

  const paymentMethods = [
    ProjectTypePaymentMethod.CASH,
    ProjectTypePaymentMethod.CHECK,
    ProjectTypePaymentMethod.EXTERNAL,
    ProjectTypePaymentMethod.PAYPAL,
    ProjectTypePaymentMethod.VENMO,
    ProjectTypePaymentMethod.INVOICE,
  ];

  if (projectType.organization.features.isEnabledAuthorizeDotNetExternalPayment) {
    paymentMethods.push(ProjectTypePaymentMethod.AUTHORIZE_DOT_NET);
  }
  if (projectType.organization.payengineMerchant?.isProcessingEnabled) {
    // Only display the PayEngine card method if the merchant has been configured
    paymentMethods.push(ProjectTypePaymentMethod.PAYENGINE_CREDIT_CARD);
    paymentMethods.push(ProjectTypePaymentMethod.PAYENGINE_SAVE_CARD);
  }

  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose} style={{overflow: 'visible'}}>
      <SmallModal.HeaderText>Configure Payment Settings</SmallModal.HeaderText>
      <Space height={20} />
      <FieldInput
        {...form}
        index={1}
        label={'Crew Payment Method'}
        name={'projectTypeBillingSettingsForm.crewPaymentMethods'}
        component={MultiDropdownInput}
        input={{
          options: paymentMethods.map((method) => ({
            label: Payment.getSettingsDisplayMethod({method}),
            value: method,
          })),
          placeholder: `Select Payment Method`,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
        }}
      />
      <Space height={36} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <SmallModal.Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          color={colors.blue.interactive}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

EditProjectTypeBillingSettingsModal.fragment = gql`
  fragment EditProjectTypeBillingSettingsModal on ProjectType {
    id
    organization {
      id
      payengineMerchant {
        id
        isProcessingEnabled
      }
      features {
        isEnabledAuthorizeDotNetExternalPayment: isEnabled(
          feature: "AUTHORIZE_DOT_NET_EXTERNAL_PAYMENT"
        )
      }
    }
  }
`;

export default EditProjectTypeBillingSettingsModal;
