// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer} from '@supermove/hooks';
import {User} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import Table from '@shared/design/components/Table';
import UserRole from '@shared/modules/User/enums/UserRole';
import UserStatus from '@shared/modules/User/enums/UserStatus';
import UpdateSuperAdminImpersonateMutation from 'modules/App/components/UpdateSuperAdminImpersonateMutation';
import StaffCommunicationButton from 'modules/Communication/components/StaffCommunicationButton';
import StaffActionsButton from 'modules/Organization/Settings/Users/List/components/StaffActionsButton';
import UpdateUserDrawer from 'modules/User/components/UpdateUserDrawer';

const Section = Styled.View`
  margin-bottom: 20px;
`;

const ActionsCell = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 5px;
`;

const Text = Styled.Text`
  ${Typography.Body3}
  font-style: ${({status}) => status === UserStatus.PENDING && 'italic'};
  color: ${({status}) => status === UserStatus.PENDING && colors.gray.secondary};
`;

const Button = Styled.LoadingButton`
  align-self: flex-start;
  height: 40px;
  padding-horizontal: 10px;
`;

const ActiveText = Styled.Text`
  ${Typography.Label1}
`;

const MANAGER_POSITION_ORDER = ['ceo', 'general manager', 'manager', 'assistant manager'];

const sortAdmins = (users) => {
  return _.sortBy(users, (user) => {
    if (!user.position) {
      return 100;
    } else {
      const index = MANAGER_POSITION_ORDER.indexOf(user.position.toLowerCase());
      if (index < 0) {
        return 99;
      } else {
        return index;
      }
    }
  });
};

const getStaffAdminSectionColumnDefinitions = ({
  canImpersonate,
  viewerRole,
  refetch,
  isEnabledOperationsAdmins,
}) => [
  {
    width: 30,
    headerContent: () => {
      return null;
    },
    cellContent: ({rowIndex}) => {
      return <Text>{rowIndex + 1}</Text>;
    },
  },
  {
    width: 70,
    isHidden: !canImpersonate,
    headerContent: () => {
      return <Table.HeaderText>Super</Table.HeaderText>;
    },
    cellContent: ({item: user}) => {
      return (
        <UpdateSuperAdminImpersonateMutation
          user={user}
          onSuccess={() => {
            window.location = '/';
          }}
        >
          {({loading, handleSubmit}) => (
            <Button loading={loading} onPress={handleSubmit}>
              {!loading && <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.User} />}
            </Button>
          )}
        </UpdateSuperAdminImpersonateMutation>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Name</Table.HeaderText>;
    },
    cellContent: ({item: user}) => {
      return (
        <Text numberOfLines={1} status={user.status}>
          {user.fullName} {user.status === UserStatus.PENDING && '(Pending)'}
        </Text>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Positions</Table.HeaderText>;
    },
    cellContent: ({item: user}) => {
      return <Text status={user.status}>{User.getPosition(user)}</Text>;
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Supermove Role</Table.HeaderText>;
    },
    cellContent: ({item: user}) => {
      return <Text status={user.status}>{User.getRole(user)}</Text>;
    },
    isHidden: !isEnabledOperationsAdmins,
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Phone Number</Table.HeaderText>;
    },
    cellContent: ({item: user}) => {
      return (
        <Text status={user.status}>
          {user.phoneNumber ? Phone.display(user.phoneNumber) : '---'}
        </Text>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Email</Table.HeaderText>;
    },
    cellContent: ({item: user}) => {
      return (
        <Text numberOfLines={1} status={user.status}>
          {user.email}
        </Text>
      );
    },
  },
  {
    flex: 1,
    isHidden: !UserRole.ADMIN_ROLES_PLUS_SUPER.includes(viewerRole),
    headerContent: () => {
      return <Table.HeaderText>Actions</Table.HeaderText>;
    },
    cellContent: ({item: user}) => {
      return (
        <StaffAdminSectionsActions
          user={user}
          viewerRole={viewerRole}
          refetch={refetch}
          isEnabledOperationsAdmins={isEnabledOperationsAdmins}
        />
      );
    },
  },
];

const StaffAdminSectionsActions = ({user, viewerRole, refetch, isEnabledOperationsAdmins}) => {
  const updateUserDrawer = useDrawer({name: 'Update User Drawer'});
  return (
    <React.Fragment>
      <ActionsCell>
        <React.Fragment>
          <StaffCommunicationButton
            user={user}
            viewerRole={viewerRole}
            refetch={refetch}
            isEnabledOperationsAdmins={isEnabledOperationsAdmins}
          />
          <Space width={8} />
          <UpdateUserDrawer
            key={updateUserDrawer.key}
            user={user}
            isOpen={updateUserDrawer.isOpen}
            handleClose={updateUserDrawer.handleClose}
            handleSuccess={() => {
              updateUserDrawer.handleClose();
              refetch();
            }}
            viewerRole={viewerRole}
            isEnabledOperationsAdmins={isEnabledOperationsAdmins}
          />
          {UserRole.ADMIN_ROLES_PLUS_SUPER.includes(viewerRole) && (
            <StaffActionsButton user={user} refetch={refetch} updateUserDrawer={updateUserDrawer} />
          )}
        </React.Fragment>
      </ActionsCell>
    </React.Fragment>
  );
};

const AdminSectionTable = ({
  canImpersonate,
  viewerRole,
  refetch,
  users,
  isEnabledOperationsAdmins,
}) => {
  return (
    <Table
      columnDefinitions={getStaffAdminSectionColumnDefinitions({
        canImpersonate,
        viewerRole,
        refetch,
        isEnabledOperationsAdmins,
      })}
      emptyStateText={'No users to display'}
      items={users}
      isDense
      rowStyle={{padding: '8', borderWidth: '0'}}
    />
  );
};

const ActiveInactiveAdminSection = ({
  canImpersonate,
  activeAdminUsers,
  inactiveAdminUsers,
  viewerRole,
  refetch,
  isEnabledOperationsAdmins,
}) => {
  return (
    <Section>
      <Section>
        <AdminSectionTable
          canImpersonate={canImpersonate}
          viewerRole={viewerRole}
          refetch={refetch}
          users={activeAdminUsers}
          isEnabledOperationsAdmins={isEnabledOperationsAdmins}
        />
      </Section>
      <Section>
        <ActiveText>Inactive ({inactiveAdminUsers.length})</ActiveText>
        <Space height={10} />
        <AdminSectionTable
          canImpersonate={canImpersonate}
          viewerRole={viewerRole}
          refetch={refetch}
          users={inactiveAdminUsers}
          isEnabledOperationsAdmins={isEnabledOperationsAdmins}
        />
      </Section>
    </Section>
  );
};

const StaffAdminSection = ({canImpersonate, viewerRole, refetch, organization}) => {
  return (
    <ScrollView>
      <ActiveInactiveAdminSection
        canImpersonate={canImpersonate}
        activeAdminUsers={sortAdmins(organization.activeAdminUsers)}
        inactiveAdminUsers={sortAdmins(organization.inactiveAdminUsers)}
        viewerRole={viewerRole}
        refetch={refetch}
        isEnabledOperationsAdmins={organization.features.isEnabledOperationsAdmins}
      />
    </ScrollView>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
StaffAdminSection.propTypes = {
  canImpersonate: PropTypes.bool,
  organization: PropTypes.object.isRequired,
  viewerRole: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

StaffAdminSection.defaultProps = {
  canImpersonate: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StaffAdminSection.fragment = gql`
  ${UpdateUserDrawer.fragment}
  ${StaffActionsButton.fragment}
  ${StaffCommunicationButton.fragment}

  fragment StaffAdminSection on Organization {
    id
    activeAdminUsers: filteredActiveUsersByRole(roles: [${UserRole.getGraphqlArgs(
      UserRole.ADMIN_ROLES,
    )}]) {
      id
      ...StaffAdminSection_User
    }
    inactiveAdminUsers: filteredInactiveUsersByRole(roles: [${UserRole.getGraphqlArgs(
      UserRole.ADMIN_ROLES,
    )}]) {
      id
      ...StaffAdminSection_User
    }
    features {
      isEnabledOperationsAdmins: isEnabled(feature: "OPERATIONS_ADMINS")
    }
  }

  fragment StaffAdminSection_User on User {
    id
    fullName
    phoneNumber
    email
    position
    features
    role
    isActive
    ...StaffActionsButton
    ...UpdateUserDrawer
    ...StaffCommunicationButton
  }
`;

export default StaffAdminSection;
