// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import TableV1 from '@shared/design/components/TableV1';
import CreateCommercialEquipmentTypeModal from 'modules/Organization/Settings/CommercialCatalog/components/CreateCommercialEquipmentTypeModal';
import CreateCommercialMaterialTypeModal from 'modules/Organization/Settings/CommercialCatalog/components/CreateCommercialMaterialTypeModal';
import DeleteCommercialEquipmentTypeModal from 'modules/Organization/Settings/CommercialCatalog/components/DeleteCommercialEquipmentTypeModal';
import DeleteCommercialMaterialTypeModal from 'modules/Organization/Settings/CommercialCatalog/components/DeleteCommercialMaterialTypeModal';
import UpdateCommercialEquipmentTypeModal from 'modules/Organization/Settings/CommercialCatalog/components/UpdateCommercialEquipmentTypeModal';
import UpdateCommercialMaterialTypeModal from 'modules/Organization/Settings/CommercialCatalog/components/UpdateCommercialMaterialTypeModal';

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const TableTitle = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const TableSection = ({index, count, title, children}) => {
  return (
    <Section index={index}>
      <TableTitle>{`${title} (${count})`}</TableTitle>
      {children}
    </Section>
  );
};

const Actions = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const Button = Styled.Button`
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const IconButton = Styled.LoadingButton`
  height: 30px;
  width: 30px;
`;

const IconButtonDelete = ({onPress}) => {
  return (
    <IconButton color={colors.Pink600} onPress={onPress}>
      <Icon color={colors.white} size={14} source={Icon.Trash} />
    </IconButton>
  );
};

const IconButtonEdit = ({onPress}) => {
  return (
    <IconButton color={colors.blue.interactive} onPress={onPress}>
      <Icon color={colors.white} size={14} source={Icon.Edit} />
    </IconButton>
  );
};

const EditCommercialEquipmentTypeButton = ({commercialEquipmentType, refetch}) => {
  const updateCommercialEquipmentTypeModal = useModal({
    name: 'Update Commercial Equipment Type Modal',
  });

  return (
    <React.Fragment>
      <IconButtonEdit onPress={updateCommercialEquipmentTypeModal.handleOpen} />
      <UpdateCommercialEquipmentTypeModal
        isOpen={updateCommercialEquipmentTypeModal.isOpen}
        commercialEquipmentType={commercialEquipmentType}
        handleClose={updateCommercialEquipmentTypeModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const DeleteCommercialEquipmentTypeButton = ({commercialEquipmentType, refetch}) => {
  const deleteCommercialEquipmentTypeModal = useModal({
    name: 'Delete Commercial Equipment Type Modal',
  });

  return (
    <React.Fragment>
      <IconButtonDelete onPress={deleteCommercialEquipmentTypeModal.handleOpen} />
      <DeleteCommercialEquipmentTypeModal
        isOpen={deleteCommercialEquipmentTypeModal.isOpen}
        commercialEquipmentType={commercialEquipmentType}
        handleClose={deleteCommercialEquipmentTypeModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const CommercialEquipmentTypeRow = ({commercialEquipmentType, refetch}) => {
  return (
    <TableV1.Row key={commercialEquipmentType.id}>
      <TableV1.Item width={220} text={commercialEquipmentType.name} />
      <TableV1.Item width={220} text={Currency.display(commercialEquipmentType.price)} />
      <TableV1.Item width={140}>
        <Row>
          <EditCommercialEquipmentTypeButton
            commercialEquipmentType={commercialEquipmentType}
            refetch={refetch}
          />
          <Space width={5} />
          <DeleteCommercialEquipmentTypeButton
            commercialEquipmentType={commercialEquipmentType}
            refetch={refetch}
          />
        </Row>
      </TableV1.Item>
    </TableV1.Row>
  );
};

const CommercialEquipmentTypeSection = ({organization, refetch}) => {
  const createCommercialEquipmentTypeModal = useModal({
    name: 'Create Commercial Equipment Type Modal',
  });

  return (
    <TableSection
      index={0}
      count={organization.commercialEquipmentTypes.length}
      title={'Equipment'}
    >
      <Space height={10} />
      <Actions>
        <Button onPress={createCommercialEquipmentTypeModal.handleOpen}>
          <Text>Create an Equipment</Text>
        </Button>
      </Actions>
      <CreateCommercialEquipmentTypeModal
        key={createCommercialEquipmentTypeModal.key}
        isOpen={createCommercialEquipmentTypeModal.isOpen}
        organization={organization}
        handleClose={createCommercialEquipmentTypeModal.handleClose}
        refetch={refetch}
      />
      <Space height={10} />
      <TableV1>
        <TableV1.Row>
          <TableV1.HeaderItem width={220} text={'Equipment Name'} />
          <TableV1.HeaderItem width={220} text={'Equipment Price'} />
          <TableV1.HeaderItem width={140} text={'Actions'} />
        </TableV1.Row>
        {organization.commercialEquipmentTypes.map((commercialEquipmentType) => (
          <CommercialEquipmentTypeRow
            key={commercialEquipmentType.id}
            commercialEquipmentType={commercialEquipmentType}
            refetch={refetch}
          />
        ))}
      </TableV1>
    </TableSection>
  );
};

const EditCommercialMaterialTypeButton = ({commercialMaterialType, refetch}) => {
  const updateCommercialMaterialTypeModal = useModal({
    name: 'Update Commercial Material Type Modal',
  });

  return (
    <React.Fragment>
      <IconButtonEdit onPress={updateCommercialMaterialTypeModal.handleOpen} />
      <UpdateCommercialMaterialTypeModal
        isOpen={updateCommercialMaterialTypeModal.isOpen}
        commercialMaterialType={commercialMaterialType}
        handleClose={updateCommercialMaterialTypeModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const DeleteCommercialMaterialTypeButton = ({commercialMaterialType, refetch}) => {
  const deleteCommercialMaterialTypeModal = useModal({
    name: 'Delete Commercial Material Type Modal',
  });

  return (
    <React.Fragment>
      <IconButtonDelete onPress={deleteCommercialMaterialTypeModal.handleOpen} />
      <DeleteCommercialMaterialTypeModal
        isOpen={deleteCommercialMaterialTypeModal.isOpen}
        commercialMaterialType={commercialMaterialType}
        handleClose={deleteCommercialMaterialTypeModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const CommercialMaterialTypeRow = ({commercialMaterialType, refetch}) => {
  return (
    <TableV1.Row key={commercialMaterialType.id}>
      <TableV1.Item width={220} text={commercialMaterialType.name} />
      <TableV1.Item width={220} text={Currency.display(commercialMaterialType.price)} />
      <TableV1.Item width={140}>
        <Row>
          <EditCommercialMaterialTypeButton
            commercialMaterialType={commercialMaterialType}
            refetch={refetch}
          />
          <Space width={5} />
          <DeleteCommercialMaterialTypeButton
            commercialMaterialType={commercialMaterialType}
            refetch={refetch}
          />
        </Row>
      </TableV1.Item>
    </TableV1.Row>
  );
};

const CommercialMaterialTypeSection = ({organization, refetch}) => {
  const createCommercialMaterialTypeModal = useModal({
    name: 'Create Commercial Material Type Modal',
  });

  return (
    <TableSection index={0} count={organization.commercialMaterialTypes.length} title={'Material'}>
      <Space height={10} />
      <Actions>
        <Button onPress={createCommercialMaterialTypeModal.handleOpen}>
          <Text>Create a Material</Text>
        </Button>
      </Actions>
      <CreateCommercialMaterialTypeModal
        key={createCommercialMaterialTypeModal.key}
        isOpen={createCommercialMaterialTypeModal.isOpen}
        organization={organization}
        handleClose={createCommercialMaterialTypeModal.handleClose}
        refetch={refetch}
      />
      <Space height={10} />
      <TableV1>
        <TableV1.Row>
          <TableV1.HeaderItem width={220} text={'Material Name'} />
          <TableV1.HeaderItem width={220} text={'Material Price'} />
          <TableV1.HeaderItem width={140} text={'Actions'} />
        </TableV1.Row>
        {organization.commercialMaterialTypes.map((commercialMaterialType) => (
          <CommercialMaterialTypeRow
            key={commercialMaterialType.id}
            commercialMaterialType={commercialMaterialType}
            refetch={refetch}
          />
        ))}
      </TableV1>
    </TableSection>
  );
};

const EquipmentAndMaterialContent = ({viewer, refetch}) => {
  return (
    <React.Fragment>
      <CommercialEquipmentTypeSection organization={viewer.viewingOrganization} refetch={refetch} />
      <Space height={40} />
      <CommercialMaterialTypeSection organization={viewer.viewingOrganization} refetch={refetch} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EquipmentAndMaterialContent.fragment = gql`
  ${CreateCommercialEquipmentTypeModal.fragment}
  ${DeleteCommercialEquipmentTypeModal.fragment}
  ${UpdateCommercialEquipmentTypeModal.fragment}
  ${CreateCommercialMaterialTypeModal.fragment}
  ${DeleteCommercialMaterialTypeModal.fragment}
  ${UpdateCommercialMaterialTypeModal.fragment}
  fragment EquipmentAndMaterialContent on User {
    ${gql.query}
    id
    viewingOrganization {
      id
      commercialEquipmentTypes {
        id
        name
        price
        ...DeleteCommercialEquipmentTypeModal
        ...UpdateCommercialEquipmentTypeModal
      }
      commercialMaterialTypes {
        id
        name
        price
        ...DeleteCommercialMaterialTypeModal
        ...UpdateCommercialMaterialTypeModal
      }
      ...CreateCommercialEquipmentTypeModal
      ...CreateCommercialMaterialTypeModal
    }
  }
`;

export default EquipmentAndMaterialContent;
