// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Float, withFragment} from '@supermove/utils';

// App
import JobForm from '@shared/modules/Job/forms/JobForm';

const handleCleanupForm = (projectJobsForm) => {
  const {jobForms} = projectJobsForm;
  const cleanedUpJobForms = jobForms.map((jobForm) => JobForm.handleEmptyLocationForms(jobForm));
  return {...projectJobsForm, jobForms: cleanedUpJobForms};
};

const getJobFormsForEstimatedRange = withFragment(
  ({job, project}) => {
    const activeRangeJobs = job.jobsForEstimatedRange.filter((job) => !job.isCancelled);
    const hasActiveChildJobs = activeRangeJobs.length > 1;

    if (hasActiveChildJobs) {
      const rangeJobs = project.activeJobsExcludingChildJobs.filter((job) =>
        _.some(job.jobsForEstimatedRange),
      );
      const number = _.findIndex(rangeJobs, ({id}) => id === job.id) + 1;
      return activeRangeJobs.map((estimatedRangeJob, index) => {
        // The first job in the list is parent job
        const isFirst = index === 0;
        return JobForm.editV2(estimatedRangeJob, {
          label: `${number}${isFirst ? '' : Float.toLowercaseLetter(index - 1)}`,
        });
      });
    }

    return [JobForm.editV2(job)];
  },
  gql`
    ${JobForm.editV2.fragment}

    fragment ProjectJobsForm_getJobFormsForEstimatedRange on Job {
      id
      identifier
      jobsForEstimatedRange {
        id
        isCancelled
        ...JobForm_editV2
      }
    }
  `,
);

const edit = withFragment(
  (project) => {
    return {
      projectId: project.id,
      jobForms: project.activeJobsExcludingChildJobs.reduce((result, job) => {
        return [
          ...result,
          ...(_.some(job.jobsForEstimatedRange)
            ? getJobFormsForEstimatedRange({job, project})
            : [JobForm.editV2(job)]),
        ];
      }, []),
    };
  },
  gql`
    ${JobForm.editV2.fragment}
    ${getJobFormsForEstimatedRange.fragment}

    fragment ProjectJobsForm_edit on Project {
      id
      activeJobsExcludingChildJobs {
        id
        jobsForEstimatedRange {
          id
          isCancelled
        }
        ...JobForm_editV2
        ...ProjectJobsForm_getJobFormsForEstimatedRange
      }
    }
  `,
);

const toForm = ({projectId, jobForms}) => ({
  projectId,
  jobForms: jobForms.map((jobForm) => JobForm.toForm(jobForm)),
});

const toMutation = ({projectId, jobForms}) => ({
  projectId,
  jobForms: jobForms.map((jobForm) => JobForm.toMutation(jobForm)),
});

const ProjectJobsForm = {
  edit,
  toForm,
  toMutation,

  handleCleanupForm,
};

export default ProjectJobsForm;
