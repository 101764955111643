// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import Badge from '@shared/design/components/Badge';
import Callout from '@shared/design/components/Callout';
import Tabs from '@shared/design/components/Tabs';
import TreeNavigation from '@shared/design/components/TreeNavigation';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import UserRole from '@shared/modules/User/enums/UserRole';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';

const Container = Styled.View`
  flex: 1;
`;

const CalloutContainer = Styled.View`
`;

const TabsContainer = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const PageHeadingContainer = Styled.View`
  flex-direction: ${({responsive}) => (responsive.mobile ? 'column' : 'row')};
  padding-horizontal: ${({responsive}) => (responsive.mobile ? '16' : '24')}px;
  padding-vertical: 16px;
  align-items: ${({responsive}) => (responsive.mobile ? 'flex-start' : 'center')};;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const PageHeadingText = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const NavigationContainer = Styled.View`
  border-right-width: 1px;
  border-color: ${colors.gray.border};
`;

const PageTitle = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const PageDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const PAGES = [
  {
    label: 'Calendars',
    route: '/settings/super-admin/calendars',
  },
  {
    label: 'Communication',
    route: '/settings/super-admin/communication',
  },
  {
    label: 'Payments',
    route: '/settings/super-admin/payments',
  },
  {
    label: 'Inventory',
    route: '/settings/super-admin/inventory',
  },
  {
    label: 'Storage',
    route: '/settings/super-admin/storage',
  },
  {
    label: 'Timesheets',
    route: '/settings/super-admin/timesheets',
  },
];

const getSettingsPages = ({viewer}) => {
  // Filter out any feature flag based routes here
  return PAGES.filter(({route}) => {
    return true;
  });
};

const getNavigationItems = (pages) =>
  pages.map(({label, route}) => ({
    label,
    value: route,
    valueKey: 'route',
  }));

const getTabs = (pages) => {
  return pages.map(({label, route}) => {
    return {
      key: _.lowerCase(label.replace(/\s/g, '_')),
      label,
      route,
    };
  });
};

const PageHeading = ({responsive}) => (
  <PageHeadingContainer responsive={responsive}>
    <PageHeadingText responsive={responsive}>Super Admin Console</PageHeadingText>
    <Space width={16} height={4} />
    <Badge label={'Only Visible to Supermove Employees'} color={colors.blue.interactive} />
  </PageHeadingContainer>
);

const TabNavigation = ({tabs, navigator}) => (
  <TabsContainer>
    <Space height={8} />
    <Tabs
      tabs={tabs}
      handlePressTab={({route}) => navigator.push(route)}
      activeTabIndex={tabs.findIndex((tab) => tab.route === navigator.location.pathname)}
      tabStyle={{paddingHorizontal: 24}}
      isSpacedTabs={false}
    />
  </TabsContainer>
);

const SuperAdminSettingsPageContent = ({responsive, title, description, isDisabled, children}) => {
  return (
    <React.Fragment>
      <PageTitle responsive={responsive}>{title}</PageTitle>
      {!!description && (
        <React.Fragment>
          <Space height={16} />
          <PageDescription responsive={responsive}>{description}</PageDescription>
        </React.Fragment>
      )}
      <Space height={24} />
      {isDisabled ? (
        <CalloutContainer>
          <Callout text={'You are viewing a branch. To make changes, switch to the main branch.'} />
        </CalloutContainer>
      ) : (
        children
      )}
    </React.Fragment>
  );
};

const CenteredLoading = () => (
  <Container style={{alignItems: 'center', justifyContent: 'center', width: '100%'}}>
    <PageLoadingIndicator />
  </Container>
);

const SuperAdminSettingsPage = ({
  title,
  description,
  isLoading,
  viewer,
  allowedOrganizationKinds,
  children,
}) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();

  if (!viewer) {
    return null;
  }

  if (!isLoading && viewer.role !== UserRole.SUPER_ADMIN) {
    navigator.push('/settings');
  }

  const BODY_PADDING = !responsive.desktop ? 16 : 24;
  const pages = getSettingsPages({viewer});
  const isDisabled = !_.includes(allowedOrganizationKinds, viewer.viewingOrganization.kind);

  return (
    <SidebarPageV2 selected={'settings'}>
      <Container>
        <PageHeading responsive={responsive} />
        {!responsive.desktop && <TabNavigation tabs={getTabs(pages)} navigator={navigator} />}
        <Container style={{flexDirection: 'row'}}>
          {responsive.desktop && (
            <NavigationContainer>
              <Space height={16} />
              <TreeNavigation
                width={200}
                handleSetValues={({route}) => navigator.push(route)}
                navigationItems={getNavigationItems(pages)}
                values={{route: navigator.location.pathname}}
              />
              <Space height={16} />
            </NavigationContainer>
          )}
          <ScrollView
            style={{backgroundColor: colors.gray.background}}
            contentContainerStyle={{
              paddingHorizontal: BODY_PADDING,
              alignItems: 'flex-start',
              flex: isLoading ? 1 : 0,
            }}
          >
            <Space height={BODY_PADDING} />
            <Loading as={CenteredLoading} loading={isLoading}>
              {() => (
                <SuperAdminSettingsPageContent
                  responsive={responsive}
                  title={title}
                  description={description}
                  isDisabled={isDisabled}
                >
                  {children}
                </SuperAdminSettingsPageContent>
              )}
            </Loading>
            <Space height={BODY_PADDING} />
          </ScrollView>
        </Container>
      </Container>
    </SidebarPageV2>
  );
};

//--------------------------------------------------
// Props
//--------------------------------------------------
SuperAdminSettingsPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
  viewer: PropTypes.object,
  allowedOrganizationKinds: PropTypes.arrayOf(PropTypes.string),
};

SuperAdminSettingsPage.defaultProps = {
  description: '',
  isLoading: false,
  viewer: null,
  allowedOrganizationKinds: [OrganizationKind.MAIN],
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SuperAdminSettingsPage.fragment = gql`
  fragment SuperAdminSettingsPage on User {
    id
    role
    viewingOrganization {
      id
      kind
    }
  }
`;

export default SuperAdminSettingsPage;
