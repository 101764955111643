// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (organization) => ({
    organizationId: organization.id,
    businessLicense: organization.businessLicense,
  }),
  gql`
    fragment OrganizationBusinessInfoLicenseForm_edit on Organization {
      id
      businessLicense
    }
  `,
);

const toForm = ({organizationId, businessLicense}) => ({
  organizationId,
  businessLicense,
});

const toMutation = ({organizationId, businessLicense}) => ({
  organizationId,
  businessLicense,
});

const OrganizationBusinessInfoLicenseForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationBusinessInfoLicenseForm;
