// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useUpdateAllReadAtInAppNotificationsMutation = ({receiverId, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      receiverId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateAllReadAtInAppNotificationsMutation.mutation,
    variables: {receiverId},
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateAllReadAtInAppNotificationsMutation.mutation = gql`
  mutation useUpdateAllReadAtInAppNotificationsMutation($receiverId: Int!){
    response: updateAllReadAtInAppNotifications(receiverId: $receiverId){
      ${gql.errors}
      user {
        id
        unreadNotificationsCount
      }
    }
  }
`;

export default useUpdateAllReadAtInAppNotificationsMutation;
