// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useState, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import Table from '@shared/design/components/Table';
import ItemTagItemKind from '@shared/modules/Inventory/enums/ItemTagItemKind';
import InventoryItemDrawer from 'modules/Inventory/InventoryItemDrawer';

const Container = Styled.View`
  background-color: ${colors.white}
`;

const CellContainer = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Label}
`;

const CellText = Styled.Text`
  ${Typography.Body}
  color: ${({color}) => color || colors.gray.primary};
`;

const getColumnDefinitions = (responsive) => {
  return [
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Item No.</HeaderText>;
      },
      cellContent: ({item}) => {
        return <CellText>{item.itemNumber}</CellText>;
      },
    },
    {
      flex: 3,
      headerContent: () => {
        return <HeaderText>Name</HeaderText>;
      },
      cellContent: ({item}) => {
        return (
          <CellContainer>
            <Row>
              {item.isVoid && (
                <>
                  <Badge
                    textColor={colors.red700}
                    backgroundColor={colors.red50}
                    label={'Void'}
                    size={Badge.SIZE.SMALL}
                  />
                  <Space width={8} />
                </>
              )}
              <CellText
                color={item.isVoid ? colors.gray.tertiary : colors.gray.primary}
                numberOfLines={1}
              >
                {item.name}
              </CellText>
            </Row>
          </CellContainer>
        );
      },
    },
    // Desktop only columns
    ...List.insertIf(
      !responsive.mobile,
      {
        flex: 1,
        headerContent: () => {
          return <HeaderText>Tags</HeaderText>;
        },
        cellContent: ({item}) => {
          const itemTagsCount = _.filter(
            item.itemTagItems,
            (itemTagItem) => itemTagItem.kind === ItemTagItemKind.ITEM_TAG,
          ).length;
          return (
            <CellContainer>
              <Row>
                <CellText color={colors.blue.interactive} numberOfLines={1}>
                  {itemTagsCount}
                </CellText>
                <Space width={4} />
                <Icon source={Icon.Tag} color={colors.blue.interactive} size={11} />
              </Row>
            </CellContainer>
          );
        },
      },
      {
        flex: 1,
        headerContent: () => {
          return <HeaderText>Exceptions</HeaderText>;
        },
        cellContent: ({item}) => {
          const exceptionsCount = _.filter(
            item.itemTagItems,
            (itemTagItem) => itemTagItem.kind === ItemTagItemKind.EXCEPTION,
          ).length;
          return (
            <CellContainer>
              <Row>
                <CellText color={colors.blue.interactive} numberOfLines={1}>
                  {exceptionsCount}
                </CellText>
                <Space width={4} />
                <Icon source={Icon.Wrench} color={colors.blue.interactive} size={12} />
              </Row>
            </CellContainer>
          );
        },
      },
      {
        flex: 1,
        headerContent: () => {
          return <HeaderText>Photos</HeaderText>;
        },
        cellContent: ({item}) => {
          return (
            <CellContainer>
              <Row>
                <CellText color={colors.blue.interactive} numberOfLines={1}>
                  {item.attachments.length}
                </CellText>
                <Space width={4} />
                <Icon source={Icon.Image} color={colors.blue.interactive} size={12} />
              </Row>
            </CellContainer>
          );
        },
      },
    ),
    // Mobile only columns
    ...List.insertIf(responsive.mobile, {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Details</HeaderText>;
      },
      cellContent: ({item}) => {
        const itemTagsCount = _.filter(
          item.itemTagItems,
          (itemTagItem) => itemTagItem.kind === ItemTagItemKind.ITEM_TAG,
        ).length;
        const exceptionsCount = _.filter(
          item.itemTagItems,
          (itemTagItem) => itemTagItem.kind === ItemTagItemKind.EXCEPTION,
        ).length;
        return (
          <CellContainer>
            <Row>
              <CellText color={colors.blue.interactive}>{itemTagsCount}</CellText>
              <Space width={4} />
              <Icon source={Icon.Tag} color={colors.blue.interactive} size={11} />
              <Space width={8} />
              <CellText color={colors.blue.interactive}>{exceptionsCount}</CellText>
              <Space width={4} />
              <Icon source={Icon.Wrench} color={colors.blue.interactive} size={12} />
              <Space width={8} />
              <CellText color={colors.blue.interactive}>{item.attachments.length}</CellText>
              <Space width={4} />
              <Icon source={Icon.Image} color={colors.blue.interactive} size={12} />
            </Row>
          </CellContainer>
        );
      },
    }),
  ];
};

const ListInventoryItems = ({items, loading}) => {
  const responsive = useResponsive();
  const [drawerItemUuid, setDrawerItemUuid] = useState('');
  const inventoryItemDrawer = useDrawer({
    name: 'Inventory Item Drawer',
    enableTracking: true,
  });

  return (
    <Container>
      <Table
        columnDefinitions={getColumnDefinitions(responsive)}
        items={items}
        emptyStateText={'No items to show'}
        loading={loading}
        rowStyle={{overflowY: 'visible', overflowX: 'visible'}}
        isClickable
        onRowPress={(item) => {
          setDrawerItemUuid(item.uuid);
          inventoryItemDrawer.handleOpen();
        }}
      />
      <InventoryItemDrawer
        isOpen={inventoryItemDrawer.isOpen}
        handleClose={inventoryItemDrawer.handleClose}
        itemUuid={drawerItemUuid}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListInventoryItems.fragment = gql`
  fragment ListInventoryItems on Item {
    id
    uuid
    name
    lotNumber
    itemNumber
    color
    isVoid
    itemTagItems {
      id
      kind
    }
    attachments {
      id
    }
  }
`;

export default ListInventoryItems;
