// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import DropdownSheetOption from '@shared/design/components/DropdownInput/components/DropdownSheetOption';

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
  width: 100%;
`;

const handlePressMultiSelectOption = ({option, currentValues, name, setFieldValue}: any) => {
  const updatedValues = currentValues.includes(option.value)
    ? currentValues.filter((val: any) => val !== option.value)
    : [...currentValues, option.value];
  setFieldValue(name, updatedValues);
};

const DropdownSheetOptionsList = ({
  options,
  name,
  value,
  onChangeValue,
  setFieldValue,
  isMultiSelect,
  showDescriptionInOption,
  handleClose,
  optionStyle,
  query,
  NoResultsComponent,
  noResultsComponentProps,
}: any) => {
  return (
    <React.Fragment>
      {_.isEmpty(options) && NoResultsComponent && (
        <NoResultsComponent {...noResultsComponentProps} searchQuery={query} />
      )}
      {options.map((option: any, index: any) => {
        return (
          <React.Fragment key={option.value || option.label}>
            {index > 0 && <Line />}
            <DropdownSheetOption
              option={option}
              // @ts-expect-error TS(2322): Type '{ option: any; index: any; showDescriptionIn... Remove this comment to see the full error message
              index={index}
              showDescriptionInOption={showDescriptionInOption}
              isSelected={isMultiSelect ? value.includes(option.value) : value === option.value}
              handleOption={() => {
                if (isMultiSelect) {
                  handlePressMultiSelectOption({option, currentValues: value, name, setFieldValue});
                } else {
                  setFieldValue(name, option.value);
                }
                onChangeValue(option.value, option);
                !isMultiSelect && handleClose();
              }}
              style={optionStyle}
            />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
DropdownSheetOptionsList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      handleAction: PropTypes.func,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          handleAction: PropTypes.func,
        }),
      ),
    }),
  ),
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
  onChangeValue: PropTypes.func,
  setFieldValue: PropTypes.func,
  isMultiSelect: PropTypes.bool,
  showDescriptionInOption: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  optionStyle: PropTypes.object,
  query: PropTypes.string,
  NoResultsComponent: PropTypes.func,
  noResultsComponentProps: PropTypes.object,
};

DropdownSheetOptionsList.defaultProps = {
  options: [],
  name: '',
  value: '',
  onChangeValue: () => {},
  setFieldValue: () => {},
  isMultiSelect: false,
  showDescriptionInOption: false,
  optionStyle: null,
  query: '',
  NoResultsComponent: null,
  noResultsComponentProps: {},
};

export default DropdownSheetOptionsList;
