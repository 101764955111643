// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {TaskTemplate} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import TaskActionKind from '@shared/modules/TaskManagement/enums/TaskActionKind';
import TaskTemplateDueInUnitType from '@shared/modules/TaskManagement/enums/TaskTemplateDueInUnitType';

const _new = ({organizationId, playbookTemplateId, index}) => ({
  taskTemplateId: null,
  name: '',
  taskName: '',
  details: '',
  dueInUnitType: TaskTemplateDueInUnitType.DAYS,
  dueInUnits: '',
  dueAtTime: '',
  assignedToRole: '',
  actionKind: null,

  assignedToUserId: null,
  assignedToTeamId: null,
  organizationId,
  playbookTemplateId,
  index,

  // Private
  assignedToValue: '',
});

const _delete = ({taskTemplateId}) => ({
  ..._new({}),
  taskTemplateId,
});

const edit = withFragment(
  (taskTemplate) => ({
    taskTemplateId: taskTemplate.id,
    name: taskTemplate.name,
    taskName: taskTemplate.taskName,
    details: taskTemplate.details,
    dueInUnitType: taskTemplate.dueInUnitType,
    dueInUnits: taskTemplate.dueInUnits,
    dueAtTime: taskTemplate.dueAtTime,
    assignedToRole: taskTemplate.assignedToRole,
    index: taskTemplate.index,
    actionKind: taskTemplate.actionKind,

    assignedToUserId: taskTemplate.assignedToUserId,
    assignedToTeamId: taskTemplate.assignedToTeamId,
    organizationId: taskTemplate.organizationId,
    playbookTemplateId: taskTemplate.playbookTemplateId,
    assignedToValue: TaskTemplate.getAssigneeDropdownValue(taskTemplate),
  }),
  gql`
    ${TaskTemplate.getAssigneeDropdownValue.fragment}
    fragment TaskTemplateForm_edit on TaskTemplate {
      id
      name
      taskName
      details
      dueInUnitType
      dueInUnits
      dueAtTime
      index
      assignedToRole
      actionKind
      assignedToUserId
      organizationId
      playbookTemplateId
      ...TaskTemplate_getAssigneeDropdownValue
    }
  `,
);

const toForm = ({
  taskTemplateId,
  name,
  taskName,
  details,
  dueInUnitType,
  dueInUnits,
  dueAtTime,
  assignedToRole,
  assignedToUserId,
  actionKind,
  assignedToTeamId,
  organizationId,
  playbookTemplateId,
  assignedToValue,
  index,
}) => ({
  taskTemplateId,
  name,
  taskName,
  details,
  dueInUnitType,
  actionKind: actionKind || TaskActionKind.NONE,
  dueInUnits: _.toString(dueInUnits),
  dueAtTime,
  assignedToRole,
  index,
  // On the UI the assignee dropdown for the task template can recognize
  // an id or a role appropriately and display accordingly.
  assignedToUserId,
  assignedToTeamId,
  organizationId,
  playbookTemplateId,

  // Private
  assignedToValue,
});

const toMutation = ({
  taskTemplateId,
  name,
  taskName,
  details,
  dueInUnitType,
  dueInUnits,
  actionKind,
  index,
  dueAtTime,
  assignedToRole,
  assignedToUserId,
  assignedToTeamId,
  organizationId,
  playbookTemplateId,
}) => {
  return {
    taskTemplateId,
    name,
    taskName,
    details,
    index,
    actionKind: actionKind === TaskActionKind.NONE ? null : actionKind,
    dueInUnitType,
    dueInUnits: dueInUnits ? _.toNumber(dueInUnits) : null,
    dueAtTime,
    assignedToRole,
    // Send the value if it's an actual id, clear it otherwise.
    assignedToUserId: _.toNumber(assignedToUserId) || null,
    assignedToTeamId: _.toNumber(assignedToTeamId) || null,
    organizationId,
    playbookTemplateId,
  };
};

const TaskForm = {
  new: _new,
  delete: _delete,
  edit,
  toForm,
  toMutation,
};

export default TaskForm;
