// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

import JobTruckForm from './JobTruckForm';

const newFromJob = withFragment(
  (job, {userId}) => ({
    jobId: job.id,
    userId,
    jobTruckForms: job.jobTrucks.map((jobTruck) => JobTruckForm.edit(jobTruck)),
  }),
  gql`
    ${JobTruckForm.edit.fragment}

    fragment JobConfirmTrucksForm_newFromJob on Job {
      id
      jobTrucks {
        id
        ...JobTruckForm_edit
      }
    }
  `,
);

const toForm = ({jobId, userId, jobTruckForms}) => ({
  jobId,
  userId,
  jobTruckForms: jobTruckForms.map((jobTruckForm) => JobTruckForm.toForm(jobTruckForm)),
});

const toMutation = ({jobId, userId, jobTruckForms}) => ({
  jobId,
  userId,
  jobTruckForms: jobTruckForms.map((jobTruckForm) => JobTruckForm.toMutation(jobTruckForm)),
});

const JobConfirmTrucksForm = {
  newFromJob,
  toForm,
  toMutation,
};

export default JobConfirmTrucksForm;
