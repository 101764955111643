// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import LocationForm from '@shared/modules/Location/forms/LocationForm';
import ContainerLocationKind from '@shared/modules/Storage/enums/ContainerLocationKind';

const _new = ({organizationId}) => ({
  containerId: null,
  containerTypeId: '',
  organizationId,
  warehouseId: '',
  name: '',
  warehouseCoordinateX: '',
  warehouseCoordinateY: '',
  warehouseCoordinateZ: '',
  notes: '',
  locationKind: ContainerLocationKind.WAREHOUSE,
  customLocationForm: LocationForm.new(),

  // Private
  isCustomLocation: false,
});

const edit = withFragment(
  ({container}) => ({
    containerId: container.id,
    containerTypeId: container.containerTypeId,
    organizationId: container.organizationId,
    warehouseId: container.warehouseId,
    name: container.name,
    warehouseCoordinateX: container.warehouseCoordinateX,
    warehouseCoordinateY: container.warehouseCoordinateY,
    warehouseCoordinateZ: container.warehouseCoordinateZ,
    notes: container.notes,
    locationKind: container.locationKind,
    customLocationForm:
      container.locationKind === ContainerLocationKind.CUSTOM
        ? LocationForm.edit(container.customLocation)
        : LocationForm.new(),

    // Private
    isCustomLocation: container.locationKind === ContainerLocationKind.CUSTOM,
  }),
  gql`
    ${LocationForm.edit.fragment}
    fragment ContainerForm_edit on Container {
      id
      containerTypeId
      organizationId
      warehouseId
      customLocation {
        id
        ...LocationForm_edit
      }
      name
      warehouseCoordinateX
      warehouseCoordinateY
      warehouseCoordinateZ
      notes
      locationKind
      createdById
      updatedById
    }
  `,
);

const toForm = ({
  containerId,
  containerTypeId,
  organizationId,
  warehouseId,
  name,
  warehouseCoordinateX,
  warehouseCoordinateY,
  warehouseCoordinateZ,
  notes,
  locationKind,
  customLocationForm,
  createdById,
  updatedById,
}) => ({
  containerId,
  containerTypeId: _.toString(containerTypeId),
  organizationId,
  warehouseId: _.toString(warehouseId),
  name,
  warehouseCoordinateX,
  warehouseCoordinateY,
  warehouseCoordinateZ,
  notes,
  locationKind,
  customLocationForm: customLocationForm ? LocationForm.toForm(customLocationForm) : '',
  createdById,
  updatedById,

  // Private
  isCustomLocation: locationKind === ContainerLocationKind.CUSTOM,
});

const toMutation = ({
  containerId,
  containerTypeId,
  organizationId,
  warehouseId,
  name,
  warehouseCoordinateX,
  warehouseCoordinateY,
  warehouseCoordinateZ,
  notes,
  customLocationForm,
  createdById,
  updatedById,
  isCustomLocation,
}) => {
  return {
    containerId,
    containerTypeId: _.toNumber(containerTypeId),
    organizationId,
    warehouseId: _.toNumber(warehouseId),
    name,
    warehouseCoordinateX,
    warehouseCoordinateY,
    warehouseCoordinateZ,
    notes,
    locationKind: isCustomLocation ? ContainerLocationKind.CUSTOM : ContainerLocationKind.WAREHOUSE,
    customLocationForm: isCustomLocation ? LocationForm.toMutation(customLocationForm) : null,
    createdById,
    updatedById,
  };
};

const ContainerForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ContainerForm;
