// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import DefaultProjectTypeInventorySettingsForm from '@shared/modules/Inventory/forms/DefaultProjectTypeInventorySettingsForm';

const _new = ({organizationId, kind}) => ({
  organizationId,
  inventoryLibraryId: null,
  name: '',
  kind,
  defaultProjectTypeInventorySettingsForms: [],
  newDefaultInventoryLibraryId: null,
});

const edit = withFragment(
  (inventoryLibrary) => ({
    inventoryLibraryId: inventoryLibrary.id,
    organizationId: inventoryLibrary.organizationId,
    name: inventoryLibrary.name,
    kind: inventoryLibrary.kind,
    defaultProjectTypeInventorySettingsForms: inventoryLibrary.projectTypes.map((projectType) =>
      // new instead of edit since we want to force the user to pick new inventory libraries on delete
      DefaultProjectTypeInventorySettingsForm.new({
        projectTypeId: projectType.id,
        projectTypeName: projectType.name,
        projectTypeColor: projectType.color,
      }),
    ),
    newDefaultInventoryLibraryId: null,
  }),
  gql`
    fragment InventoryLibraryForm_edit on InventoryLibrary {
      id
      organizationId
      name
      kind
      projectTypes {
        id
        name
        color
      }
    }
  `,
);

const toForm = ({
  inventoryLibraryId,
  organizationId,
  name,
  kind,
  defaultProjectTypeInventorySettingsForms,
  newDefaultInventoryLibraryId,
}) => ({
  inventoryLibraryId,
  organizationId,
  name,
  kind,
  defaultProjectTypeInventorySettingsForms: defaultProjectTypeInventorySettingsForms.map(
    (defaultProjectTypeInventorySettingsForms) =>
      DefaultProjectTypeInventorySettingsForm.toForm(defaultProjectTypeInventorySettingsForms),
  ),
  newDefaultInventoryLibraryId,
});

const toMutation = ({
  inventoryLibraryId,
  organizationId,
  name,
  kind,
  defaultProjectTypeInventorySettingsForms,
  newDefaultInventoryLibraryId,
}) => ({
  inventoryLibraryId,
  organizationId,
  name,
  kind,
  defaultProjectTypeInventorySettingsForms: defaultProjectTypeInventorySettingsForms.map(
    (defaultProjectTypeInventorySettingsForms) =>
      DefaultProjectTypeInventorySettingsForm.toMutation(defaultProjectTypeInventorySettingsForms),
  ),
  newDefaultInventoryLibraryId,
});

const InventoryLibraryForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default InventoryLibraryForm;
