// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Organization} from '@supermove/models';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownInput from '@shared/design/components/MultiDropdownInput';

const getRelatedOrganizationsOfficeStaffOptions = (organization) => {
  const relatedOrganizationsOfficeStaffOptions = _.flatten(
    organization.salesStaffOrganizations.map((organization) => {
      return Organization.getSalespersonOptionsV2(organization);
    }),
  );
  return _.sortBy(relatedOrganizationsOfficeStaffOptions, ['label']);
};

const getSalespersonOptions = ({organization, isEnabledShowIntegrationUser}) => {
  if (isEnabledShowIntegrationUser) {
    return getRelatedOrganizationsOfficeStaffOptions(organization);
  }
  return Organization.getGreaterOrganizationOfficeStaffDropdownOptions(organization);
};

const RelatedOrganizationsStaffDropdown = ({
  organizationSlug,
  isEnabledShowIntegrationUser,
  form,
  field,
  label,
  placeholder,
  isMultiSelect,
  isDisabled,
  isRequired, // Adds the current required pattern with label decorators
  required, // Adds the deprecated required pattern for the yellow input
  style,
  inputStyle,
  index,
  isResponsive,
  isPortaled,
}) => {
  const {data, loading} = useQuery(RelatedOrganizationsStaffDropdown.query, {
    variables: {
      organizationSlug,
      isEnabledShowIntegrationUser,
    },
  });
  return (
    <FieldInput
      {...form}
      component={isMultiSelect ? MultiDropdownInput : DropdownInput}
      name={field}
      label={label}
      index={index}
      style={style}
      isRequired={isRequired}
      isResponsive={isResponsive}
      input={{
        isPortaled,
        disabled: isDisabled,
        required,
        setFieldValue: form.setFieldValue,
        isLoading: loading,
        placeholder,
        options: loading
          ? []
          : getSalespersonOptions({
              organization: data.organization,
              isEnabledShowIntegrationUser,
            }),
        style: inputStyle || {flex: 1},
        showDescriptionInOption: true,
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RelatedOrganizationsStaffDropdown.query = gql`
  ${Organization.getGreaterOrganizationOfficeStaffDropdownOptions.fragment}
  ${Organization.getSalespersonOptionsV2.fragment}
  query RelatedOrganizationsStaffDropdown(
    $organizationSlug: String!
    $isEnabledShowIntegrationUser: Boolean!
  ) {
    organization(slug: $organizationSlug) {
      id
      salesStaffOrganizations {
        id
        ...Organization_getSalespersonOptionsV2 @include(if: $isEnabledShowIntegrationUser)
      }
      ...Organization_getGreaterOrganizationOfficeStaffDropdownOptions
        @skip(if: $isEnabledShowIntegrationUser)
    }
  }
`;

export default RelatedOrganizationsStaffDropdown;
