// Supermove
import _ from 'lodash';

// Supermove
import {uuid} from '@supermove/utils';

export interface StopActionFormType {
  workflowRunStepUuid: string;
}

const _new = (): StopActionFormType => ({
  workflowRunStepUuid: uuid(),
});

const edit = (stopActionForm: any): StopActionFormType => ({
  workflowRunStepUuid: stopActionForm.workflowRunStepUuid,
});

const toForm = (StopActionForm: StopActionFormType): StopActionFormType => ({
  workflowRunStepUuid: StopActionForm.workflowRunStepUuid,
});

const toMutation = (StopActionForm: StopActionFormType): StopActionFormType => ({
  workflowRunStepUuid: StopActionForm.workflowRunStepUuid,
});

const StopActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default StopActionForm;
