// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import CommercialCatalogForm from '@shared/modules/CommercialCatalog/forms/CommercialCatalogForm';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import ValuationCoverageForm from '@shared/modules/Insurance/forms/ValuationCoverageForm';

const edit = withFragment(
  (documentItem, {document}) => {
    return {
      itemKind: documentItem.itemKind,
      text: documentItem.text,
      isRequired: documentItem.isRequired,
      input: documentItem.input,
      valuationCoverageForm:
        documentItem.itemKind === DocumentItemKindV2.INPUT_VALUATION_COVERAGE
          ? ValuationCoverageForm.edit(document.job.valuationCoverage)
          : null,
      commercialCatalogForm: [
        DocumentItemKindV2.INPUT_COMMERCIAL_CATALOG_EQUIPMENTS,
        DocumentItemKindV2.INPUT_COMMERCIAL_CATALOG_MATERIALS,
      ].includes(documentItem.itemKind)
        ? CommercialCatalogForm.edit(document.job.commercialCatalog)
        : null,
    };
  },
  gql`
    ${CommercialCatalogForm.edit.fragment}
    ${ValuationCoverageForm.edit.fragment}

    fragment DocumentItemForm_edit on Document {
      id
      job {
        id
        commercialCatalog {
          id
          ...CommercialCatalogForm_edit
        }
        valuationCoverage {
          id
          ...ValuationCoverageForm_edit
        }
      }
    }
  `,
);

const toForm = ({
  itemKind,
  text,
  isRequired,
  input,
  commercialCatalogForm,
  valuationCoverageForm,
}) => {
  return {
    itemKind,
    text,
    isRequired,
    input,
    commercialCatalogForm: commercialCatalogForm
      ? CommercialCatalogForm.toForm(commercialCatalogForm)
      : null,
    valuationCoverageForm: valuationCoverageForm
      ? ValuationCoverageForm.toForm(valuationCoverageForm)
      : null,
  };
};

const toMutation = ({
  itemKind,
  text,
  isRequired,
  input,
  commercialCatalogForm,
  valuationCoverageForm,
}) => {
  return {
    itemKind,
    text,
    isRequired,
    input: JSON.stringify(input),
    commercialCatalogForm: commercialCatalogForm
      ? CommercialCatalogForm.toMutation(commercialCatalogForm)
      : null,
    valuationCoverageForm: valuationCoverageForm
      ? ValuationCoverageForm.toMutation(valuationCoverageForm)
      : null,
  };
};

const DocumentItemForm = {
  edit,
  toForm,
  toMutation,
};

export default DocumentItemForm;
