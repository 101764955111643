/**
 * NOTE(mark): This page is originally copied from the JobsCalendar. Since it will end up pretty
 * different, we are okay copying it initially.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Calendar, Emoji, Query, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMountEffect, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime, pluralize, URL} from '@supermove/utils';

// App
import OfficeHeader from 'modules/App/components/OfficeHeader';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import EstimatesCalendarActions from 'modules/Job/Estimate/Calendar/components/EstimatesCalendarActions';
import EstimatesDayDetails from 'modules/Job/Estimate/Calendar/components/EstimatesDayDetails';
import MultiBranchOrganizationField from 'modules/Organization/components/MultibranchOrganizationField';

const Wrapper = Styled.View`
  ${(props) => (props.mobile ? 'height: 750px' : 'flex: 1')};
  flex-direction: row;
  width: 100%;
`;

const Container = Styled.View`
  ${(props) => (props.mobile ? 'width: 100%' : 'flex: 1')};
  height: 100%;
  padding: ${(props) => (props.mobile ? 10 : 20)}px;
`;

const Section = Styled.View`
  flex: 1;
`;

const DateHeader = Styled.H7`
  padding-top: 5px;
  padding-left: 5px;
  ${fontWeight(500)}
`;

const DateCell = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-horizontal: 5px;
  background-color: ${(props) => props.backgroundColor};
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Touchable = Styled.Touchable`
  flex: 1;
`;

const JobsCount = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-transform: uppercase;
  text-align: center;
`;

const EmojiText = Styled.Text`
  margin-left: 3px;
`;

const Right = Styled.View`
  width: 576px;
  height: 100%;
`;

const ToolbarBlocker = Styled.View`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 200px;
  height: 40px;
  background-color: ${colors.white};
`;

const getFilteredUrlFromParams = (params, baseUrl) => {
  return URL.getUrlFromVariables(baseUrl, params);
};

const handleUpdateParam = ({baseUrl, navigator, params, paramKey, paramValue}) => {
  navigator.push(getFilteredUrlFromParams({...params, [paramKey]: paramValue || ''}, baseUrl));
};

const CalendarDateCell = ({isTarget, cellDate, data, navigator, params, location}) => {
  const responsive = useResponsive();
  const isPast = Datetime.isPast(cellDate);
  const isToday = Datetime.isToday(cellDate);
  let backgroundColor;

  if (isPast) {
    // #F1F2F6, 50%
    backgroundColor = 'rgba(241, 242, 246, 0.75)';
  } else if (isToday) {
    backgroundColor = '#EBEFFD';
  } else {
    backgroundColor = colors.white;
  }

  // Whether cell is highlighted by a border.
  const isHighlighted = isTarget && !responsive.mobile;
  const style = {
    borderRightWidth: isHighlighted ? 3 : 1,
    borderBottomWidth: isHighlighted ? 3 : 1,
    borderLeftWidth: isHighlighted ? 3 : 0,
    borderTopWidth: isHighlighted ? 3 : 0,
    borderColor: isHighlighted ? colors.blue.interactive : colors.gray.tertiary,
    borderStyle: 'solid',
  };

  if (!data || !data.viewer) {
    return <DateCell backgroundColor={backgroundColor} style={style} />;
  }

  const calendarDays = _.get(data, 'estimatesCalendar.calendarDays');
  const calendarDay = _.find(calendarDays, (calendarDay) => {
    return _.get(calendarDay, 'day.value') === cellDate;
  });

  const jobsCount = _.get(calendarDay, 'jobsCount') || 0;
  const isActive = jobsCount > 0;

  return (
    <Touchable
      onPress={() => {
        if (responsive.mobile) {
          navigator.push(`/jobs/estimates/calendar/${cellDate}`);
        } else if (!isTarget) {
          handleUpdateParam({
            baseUrl: location.pathname,
            navigator,
            params,
            paramKey: 'date',
            paramValue: cellDate,
          });
        }
      }}
      {...responsive}
    >
      <DateCell backgroundColor={backgroundColor} style={style}>
        {isActive && (
          <JobsCount vars={{isTarget}}>
            {!responsive.large ? jobsCount : pluralize('survey', jobsCount, true)}
            {!responsive.large && <Emoji component={EmojiText} name={'clipboard'} />}
          </JobsCount>
        )}
      </DateCell>
    </Touchable>
  );
};

const CalendarPane = ({data, date, isController, navigator, params, location}) => (
  <Section>
    {!isController && <ToolbarBlocker />}
    <Calendar
      date={date}
      isSundayFirst={false}
      onNavigate={
        isController &&
        ((navigateDate) => {
          const date = Datetime.toMutationDate(navigateDate);
          handleUpdateParam({
            baseUrl: location.pathname,
            navigator,
            params,
            paramKey: 'date',
            paramValue: date,
          });
        })
      }
      dateCellWrapperComponent={({value}) => {
        const cellDate = Datetime.toMutationDate(value); // The date of this calendar cell.
        const isTarget = cellDate === date; // Whether this calendar cell is the active date selected.

        return (
          <CalendarDateCell
            isTarget={isTarget}
            cellDate={cellDate}
            data={data}
            navigator={navigator}
            params={params}
            location={location}
          />
        );
      }}
      dateHeaderComponent={({date, label}) => <DateHeader>{label}</DateHeader>}
    />
  </Section>
);

const Calendars = ({date, navigator, params, location}) => (
  <Query
    fetchPolicy={'cache-and-network'}
    variables={{
      month: Datetime.toMutationMonth(date),
      slugs: params.slugs,
    }}
    query={EstimatesCalendar.query}
  >
    {({data}) => (
      <CalendarPane
        data={data}
        date={date}
        isController
        navigator={navigator}
        params={params}
        location={location}
      />
    )}
  </Query>
);

const EstimatesCalendar = ({searchParams, viewer}) => {
  const {navigator, params} = useNavigationDOM();
  const {location} = navigator;
  const responsive = useResponsive();

  useMountEffect(() => {
    if (!params.slugs) {
      handleUpdateParam({
        baseUrl: location.pathname,
        navigator,
        params,
        paramKey: 'slugs',
        paramValue:
          viewer.viewingOrganization.isPrimary &&
          viewer.viewingOrganization.features.isEnabledMultibranchFilterView
            ? ['ALL_ORGANIZATIONS']
            : [viewer.viewingOrganization.slug],
      });
    }
  });

  const organizations = _.get(viewer, 'viewingOrganization.company.organizations', []);

  return (
    <React.Fragment>
      <StandardOfficeHeader title={'Surveys Calendar'}>
        <OfficeHeader.Content style={{flex: 1, marginLeft: responsive.mobile ? '10px' : '24px'}}>
          <EstimatesCalendarActions
            viewer={viewer}
            params={params}
            location={location}
            navigator={navigator}
          />
          <Space style={{flex: 1}} />
          {viewer.viewingOrganization.features.isEnabledMultibranchFilterView &&
            viewer.viewingOrganization.canViewOtherBranchesData && (
              <MultiBranchOrganizationField
                value={params.slugs || []}
                noBranchOrganizations
                organizations={organizations}
                onChangeValue={(newSlugs) => {
                  handleUpdateParam({
                    baseUrl: location.pathname,
                    navigator,
                    params,
                    paramKey: 'slugs',
                    paramValue: newSlugs,
                  });
                }}
              />
            )}
        </OfficeHeader.Content>
      </StandardOfficeHeader>
      <Wrapper {...responsive}>
        <Container {...responsive} data-test-id='estimates-calendar-container'>
          <Calendars
            date={searchParams.date || Datetime.toMutationDate(Datetime.today)}
            navigator={navigator}
            params={params}
            location={location}
          />
        </Container>
        {!responsive.mobile && (
          <Right {...responsive}>
            <EstimatesDayDetails
              date={searchParams.date || Datetime.toMutationDate(Datetime.today)}
              params={params}
            />
          </Right>
        )}
      </Wrapper>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EstimatesCalendar.fragment = gql`
  ${EstimatesCalendarActions.fragment}
  ${MultiBranchOrganizationField.fragment}

  fragment EstimatesCalendar on User {
    id
    viewingOrganization {
      id
      slug
      isPrimary
      canViewOtherBranchesData
      features {
        isEnabledMultibranchFilterView: isEnabled(feature: "MULTIBRANCH_FILTER_VIEW")
      }
      ...MultiBranchOrganizationField
    }
    ...EstimatesCalendarActionsButtons
  }
`;

EstimatesCalendar.query = gql`
  query EstimatesCalendar($month: String!, $slugs: [String]) {
    ${gql.query}
    viewer {
      id
    }
    estimatesCalendar(month: $month, slugs: $slugs) {
      calendarDays {
        jobsCount
        day {
          id
          value
        }
      }
    }
  }
`;

export default EstimatesCalendar;
