// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, MultiDropdownInput, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Workflow, Inventory} from '@supermove/models';
import {Datetime} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import WorkflowTriggerIdentifiers from '@shared/modules/Workflow/enums/WorkflowTriggerIdentifier';
import TagDropdownInputField from 'modules/Tag/components/TagDropdownInputField';

const getWorkflowProjectTypeOptions = (form, workflow) => {
  const {triggerIdentifier} = form?.values.workflowForm;

  const projectTypeOptions = workflow.organization.moveProjectTypes.map((projectType) => ({
    label: projectType.name,
    value: projectType.id,
  }));

  if (WorkflowTriggerIdentifiers.isJobTypeRequiredWorkflow(triggerIdentifier)) {
    return projectTypeOptions;
  }

  const allProjectTypesOption = {label: 'ALL Project Types', value: -1};

  return [allProjectTypesOption, ...projectTypeOptions];
};

const getWorkflowStorageProjectTypeOptions = (workflow) => {
  const storageProjectTypeOptions = workflow.organization.storageProjectTypes.map(
    (projectType) => ({
      label: projectType.name,
      value: projectType.id,
    }),
  );

  const allProjectTypesOption = {label: 'ALL Storage Project Types', value: -1};

  return [allProjectTypesOption, ...storageProjectTypeOptions];
};

const getWorkflowJobTypeOptions = (form, workflow) => {
  // We only grab selected project type's job type
  const selectedProjectType = _.get(form.values, 'workflowForm.projectTypeIdList');

  const jobTypeOptions = workflow.organization.moveProjectTypes
    .filter((projectType) => {
      return selectedProjectType.includes(projectType.id);
    })
    .reduce((combinedJobTypeOptions, projectType) => {
      const jobTypeOptionsFromProjectType = projectType.jobTypes.map((jobType) => ({
        label: jobType.name,
        value: jobType.id,
        description: projectType.name,
      }));
      return [...combinedJobTypeOptions, ...jobTypeOptionsFromProjectType];
    }, []);

  return jobTypeOptions;
};

const JobTypesDropdown = ({form, field, workflow}) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${field}.jobTypeIdList`}
      label={'Job Type'}
      isRequired
      input={{
        options: getWorkflowJobTypeOptions(form, workflow),
        placeholder: 'Select job type(s)',
        isSearchable: true,
        isPortaled: true,
        setFieldValue: form.setFieldValue,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        style: {flex: 1},
        onChangeValue: (selections) => {
          form.setFieldValue(`${field}.jobTypeIdList`, selections);
        },
        onSelectValue: () => {},
        onDeselectValue: () => {},
        components: {
          Option: (props) => {
            return <MultiDropdownCheckboxInput.CheckboxOption {...props} />;
          },
        },
      }}
    />
  );
};

// For now create a V2, we can later use this once feature flag for scheduled job is turned to true for all
const ProjectTypesDropdownV2 = ({form, field, workflow}) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${field}.projectTypeIdList`}
      label={'Project Type'}
      isRequired
      input={{
        options: getWorkflowProjectTypeOptions(form, workflow),
        placeholder: 'Select project type(s)',
        isSearchable: true,
        isPortaled: true,
        setFieldValue: form.setFieldValue,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        style: {flex: 1},
        onChangeValue: (selections) => {
          // This checks if all the options have been selected,
          // if it is, then it selects the all project type option
          // -1 only applies on workflows that don't require job type
          if (
            !WorkflowTriggerIdentifiers.isJobTypeRequiredWorkflow(
              _.get(form.values, `${field}.triggerIdentifier`),
            )
          ) {
            const isLastSelectionAllProjectTypes =
              selections.length ===
              getWorkflowProjectTypeOptions(form, workflow).filter((option) => option.value !== -1)
                .length;
            const lastSelectionValue = _.last(selections);

            if (isLastSelectionAllProjectTypes || lastSelectionValue === -1) {
              form.setFieldValue(`${field}.projectTypeIdList`, [-1]);
            } else if (_.includes(selections, -1)) {
              const filteredSelections = _.filter(selections, (value) => value !== -1);
              form.setFieldValue(`${field}.projectTypeIdList`, filteredSelections);
            }
          }

          // Remove any job type that is not part of the project type
          const validJobTypeId = _.get(form.values, 'workflowForm.jobTypeIdList').filter((id) => {
            return !!_.find(getWorkflowJobTypeOptions(form, workflow), (item) => {
              return item.value === id;
            });
          });
          form.setFieldValue(`${field}.jobTypeIdList`, validJobTypeId);
        },
        onSelectValue: () => {},
        onDeselectValue: () => {},
        components: {
          Option: MultiDropdownCheckboxInput.CheckboxOption,
        },
      }}
    />
  );
};

const ProjectTypesDropdown = ({form, field, workflow, isStorage}) => {
  const triggerIdentifier = _.get(form.values, `${field}.triggerIdentifier`);

  return (
    <React.Fragment>
      <FieldInput.Memoized
        {...form}
        component={MultiDropdownInput}
        name={`${field}.projectTypeIdList`}
        label={'Project Type'}
        isRequired
        input={{
          options: isStorage
            ? getWorkflowStorageProjectTypeOptions(workflow)
            : getWorkflowProjectTypeOptions(form, workflow),
          placeholder: 'Select project type(s)',
          isSearchable: true,
          isPortaled: true,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
          onChangeValue: (selections) => {
            const lastSelectionValue = _.last(selections);
            const isLastSelectionAllProjectTypes = lastSelectionValue === -1;
            if (isLastSelectionAllProjectTypes) {
              form.setFieldValue(`${field}.projectTypeIdList`, [-1]);
            } else if (_.includes(selections, -1)) {
              const filteredSelections = _.filter(selections, (value) => value !== -1);
              form.setFieldValue(`${field}.projectTypeIdList`, filteredSelections);
            }
          },
        }}
      />
      <Space height={16} />
      {workflow.organization.features.isEnabledProjectTagWorkflow &&
        triggerIdentifier !== WorkflowTriggerIdentifiers.PROJECT_CREATED && (
          <React.Fragment>
            <TagDropdownInputFieldByCategory form={form} field={field} workflow={workflow} />
            <Space height={16} />
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

const StorageProjectTypesDropdown = ({form, field, workflow}) => {
  return (
    <React.Fragment>
      <FieldInput.Memoized
        {...form}
        component={MultiDropdownInput}
        name={`${field}.projectTypeIdList`}
        label={'Storage Project Type'}
        isRequired
        input={{
          options: getWorkflowStorageProjectTypeOptions(workflow),
          placeholder: 'Select storage project type(s)',
          isSearchable: true,
          isPortaled: true,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
          onChangeValue: (selections) => {
            const lastSelectionValue = _.last(selections);
            const isLastSelectionAllProjectTypes = lastSelectionValue === -1;
            if (isLastSelectionAllProjectTypes) {
              form.setFieldValue(`${field}.projectTypeIdList`, [-1]);
            } else if (_.includes(selections, -1)) {
              const filteredSelections = _.filter(selections, (value) => value !== -1);
              form.setFieldValue(`${field}.projectTypeIdList`, filteredSelections);
            }
          },
        }}
      />
      <Space height={16} />
      <TagDropdownInputFieldByCategory form={form} field={field} workflow={workflow} />
      <Space height={16} />
    </React.Fragment>
  );
};

const ProjectValueDropdown = ({form, field, workflow}) => {
  return (
    <React.Fragment>
      <FieldInput.Memoized
        {...form}
        component={DropdownInput}
        name={`${field}.triggerVariableIdentifier`}
        label={'Project Value'}
        isRequired
        input={{
          options: Workflow.getProjectValueDropdownOptions(workflow),
          placeholder: 'Select a project value',
          isSearchable: true,
          isPortaled: true,
          isClearable: true,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
        }}
      />
      <Space height={16} />
      {workflow.organization.features.isEnabledProjectTagWorkflow && (
        <React.Fragment>
          <TagDropdownInputFieldByCategory form={form} field={field} workflow={workflow} />
          <Space height={16} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const DaysBeforeField = ({form, field}) => {
  return (
    <FieldInput
      {...form}
      name={`${field}.triggerDaysBefore`}
      label={'Days Before'}
      isRequired
      input={{
        placeholder: 'Enter number of days',
        style: {flex: 1},
      }}
      handleBlur={(e) => {
        const numberValue = parseInt(Inventory.getFloatValue(e.currentTarget.value), 10);
        form.setFieldValue(`${field}.triggerDaysBefore`, numberValue);
      }}
    />
  );
};

const WorkflowHoursField = ({form, field, workflow}) => {
  return (
    <FieldInput
      {...form}
      component={DropdownInput}
      name={`${field}.triggerHour`}
      label={'At'}
      isRequired
      input={{
        options: Datetime.getHourDropdownOptions(workflow.organization.timezone),
        placeholder: 'Select time',
        isSearchable: true,
        isPortaled: true,
        isClearable: true,
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
      }}
    />
  );
};

const TagDropdownInputFieldByCategory = ({form, field, workflow, isTagField}) => {
  const triggerIdentifier = _.get(form.values, `${field}.triggerIdentifier`);
  const isJobTypeRequiredWorkflow =
    WorkflowTriggerIdentifiers.isJobTypeRequiredWorkflow(triggerIdentifier);
  const tags = workflow.organization.companySettings.tags.filter(
    (tag) =>
      (isJobTypeRequiredWorkflow
        ? [TagCategory.PROJECT, TagCategory.JOB].includes(tag.category)
        : tag.category === TagCategory.PROJECT) && !tag.isArchived,
  );

  const tagOptions = tags
    ? tags.map((tag) => ({
        value: tag.id,
        label: `${tag.emoji} ${tag.name}`,
      }))
    : [];

  if (WorkflowTriggerIdentifiers.getCategory(triggerIdentifier) !== 'TAGS') {
    tagOptions.unshift({value: -1, label: 'All Tags'});
  }

  return (
    <TagDropdownInputField
      options={tagOptions}
      placeholder={`Select tag`}
      label={`Select ${isJobTypeRequiredWorkflow ? '' : 'Project'} Tag(s)`}
      isPortaled
      isRequired={isTagField}
      value={_.get(form.values, `${field}.tagIdList`, [])}
      onChangeValue={(tagIds) => {
        const lastSelectionValue = _.last(tagIds);
        const isLastSelectionAllTags =
          tagIds.length === tagOptions.filter((option) => option.value !== -1).length;
        if (isLastSelectionAllTags || lastSelectionValue === -1) {
          form.setFieldValue(`${field}.tagIdList`, [-1]);
        } else if (_.includes(tagIds, -1)) {
          const filteredSelections = _.filter(tagIds, (value) => value !== -1);
          form.setFieldValue(`${field}.tagIdList`, filteredSelections);
        } else {
          form.setFieldValue(`${field}.tagIdList`, tagIds);
        }
      }}
    />
  );
};

const TagsField = ({form, field, workflow}) => {
  const isJobTypeRequiredWorkflow = WorkflowTriggerIdentifiers.isJobTypeRequiredWorkflow(
    _.get(form.values, `${field}.triggerIdentifier`),
  );

  return (
    <React.Fragment>
      <ProjectTypesDropdownV2 form={form} field={field} workflow={workflow} />
      <Space height={16} />
      {isJobTypeRequiredWorkflow && (
        <React.Fragment>
          <JobTypesDropdown form={form} field={field} workflow={workflow} />
          <Space height={16} />
        </React.Fragment>
      )}
      <TagDropdownInputFieldByCategory form={form} field={field} workflow={workflow} isTagField />
      <Space height={16} />
    </React.Fragment>
  );
};

const JobLevelWorkflowField = ({form, field, workflow}) => {
  const triggerIdentifier = _.get(form.values, `${field}.triggerIdentifier`);
  return (
    <React.Fragment>
      <ProjectTypesDropdownV2 form={form} field={field} workflow={workflow} />
      <Space height={16} />
      <JobTypesDropdown form={form} field={field} workflow={workflow} />
      <Space height={16} />
      {workflow.organization.features.isEnabledProjectTagWorkflow &&
        triggerIdentifier !== WorkflowTriggerIdentifiers.JOB_CREATED && (
          <React.Fragment>
            <TagDropdownInputFieldByCategory form={form} field={field} workflow={workflow} />
            <Space height={16} />
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

const ScheduledProjectField = ({form, field, workflow}) => {
  return (
    <React.Fragment>
      <ProjectTypesDropdownV2 form={form} field={field} workflow={workflow} />
      <Space height={16} />
      <DaysBeforeField form={form} field={field} />
      <Space height={16} />
      <WorkflowHoursField form={form} field={field} workflow={workflow} />
      <Space height={16} />
      {workflow.organization.features.isEnabledProjectTagWorkflow && (
        <React.Fragment>
          <TagDropdownInputFieldByCategory form={form} field={field} workflow={workflow} />
          <Space height={16} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ScheduledJobField = ({form, field, workflow}) => {
  return (
    <React.Fragment>
      <ProjectTypesDropdownV2 form={form} field={field} workflow={workflow} />
      <Space height={16} />
      <JobTypesDropdown form={form} field={field} workflow={workflow} />
      <Space height={16} />
      <DaysBeforeField form={form} field={field} />
      <Space height={16} />
      <WorkflowHoursField form={form} field={field} workflow={workflow} />
      <Space height={16} />
      {workflow.organization.features.isEnabledProjectTagWorkflow && (
        <React.Fragment>
          <TagDropdownInputFieldByCategory form={form} field={field} workflow={workflow} />
          <Space height={16} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const getWorkflowTriggerInput = ({triggerIdentifier, form, field, workflow}) => {
  switch (triggerIdentifier) {
    case WorkflowTriggerIdentifiers.JOB_CREATED:
    case WorkflowTriggerIdentifiers.JOB_COMPLETED:
    case WorkflowTriggerIdentifiers.JOB_CANCELLED:
      return <JobLevelWorkflowField form={form} field={field} workflow={workflow} />;
    case WorkflowTriggerIdentifiers.SCHEDULED_DAYS_BEFORE_JOB:
      return <ScheduledJobField form={form} field={field} workflow={workflow} />;
    case WorkflowTriggerIdentifiers.SCHEDULED_DAYS_BEFORE_PROJECT:
      return <ScheduledProjectField form={form} field={field} workflow={workflow} />;
    case WorkflowTriggerIdentifiers.PROJECT_VALUE_UPDATED:
      return (
        <React.Fragment>
          <Space height={16} />
          <ProjectValueDropdown form={form} field={field} workflow={workflow} />
        </React.Fragment>
      );
    case WorkflowTriggerIdentifiers.PROJECT_TAG_ADDED:
    case WorkflowTriggerIdentifiers.PROJECT_TAG_REMOVED:
    case WorkflowTriggerIdentifiers.JOB_TAG_ADDED:
    case WorkflowTriggerIdentifiers.JOB_TAG_REMOVED:
      return <TagsField form={form} field={field} workflow={workflow} />;
    case WorkflowTriggerIdentifiers.CONFIRMATION_COMPLETED:
    case WorkflowTriggerIdentifiers.CONFIRMATION_SENT:
    case WorkflowTriggerIdentifiers.CONFIRMATION_SKIPPED:
    case WorkflowTriggerIdentifiers.QUOTE_SENT:
    case WorkflowTriggerIdentifiers.QUOTE_ACCEPTED:
    case WorkflowTriggerIdentifiers.PROJECT_CREATED:
    case WorkflowTriggerIdentifiers.PROJECT_CANCELLED:
    case WorkflowTriggerIdentifiers.PROJECT_COMPLETED:
    case WorkflowTriggerIdentifiers.INVENTORY_VIRTUAL_WALKTHROUGH_COMPLETED:
    case WorkflowTriggerIdentifiers.TEXT_MESSAGE_RECEIVED:
      return <ProjectTypesDropdown form={form} field={field} workflow={workflow} />;
    case WorkflowTriggerIdentifiers.STORAGE_PROJECT_CANCELLED:
    case WorkflowTriggerIdentifiers.CUSTOMER_AUTOPAY_FAILED:
    case WorkflowTriggerIdentifiers.CUSTOMER_AUTOPAY_SUCCEEDED:
      return <StorageProjectTypesDropdown form={form} field={field} workflow={workflow} />;
    default:
      return null;
  }
};

const WorkflowTriggerInputsContent = ({form, field, workflow}) => {
  const triggerIdentifier = _.get(form.values, `${field}.triggerIdentifier`);

  return getWorkflowTriggerInput({triggerIdentifier, form, field, workflow});
};

const WorkflowTriggerInputs = ({form, field, workflow}) => {
  return <WorkflowTriggerInputsContent workflow={workflow} form={form} field={field} />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WorkflowTriggerInputs.fragment = gql`
  ${Workflow.getProjectValueDropdownOptions.fragment}
  ${TagDropdownInputField.fragment}

  fragment WorkflowTriggerInputs on Workflow {
    id
    projectTypeIdList
    jobTypeIdList
    organization {
      id
      features {
        isEnabledProjectTagWorkflow: isEnabled(feature: "PROJECT_TAG_WORKFLOW")
      }
      companySettings {
        tags {
          id
          name
          emoji
          category
          isArchived
          ...TagDropdownInputField
        }
      }
      moveProjectTypes: projectTypesForCategory(category: "MOVE", showHidden: true) {
        id
        name
        jobTypes {
          id
          name
        }
      }
      storageProjectTypes: projectTypesForCategory(category: "STORAGE", showHidden: true) {
        id
        name
        jobTypes {
          id
          name
        }
      }
      timezone
    }
    ...Workflow_getProjectValueDropdownOptions
  }
`;

export default WorkflowTriggerInputs;
