// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ReportDashboardForm from '@shared/modules/Reports/forms/ReportDashboardForm';

const useCreateReportDashboardMutation = ({reportDashboardForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      reportDashboardForm: ReportDashboardForm.toForm(reportDashboardForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateReportDashboardMutation.mutation,
    variables: {
      reportDashboardForm: ReportDashboardForm.toMutation(form.values.reportDashboardForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateReportDashboardMutation.mutation = gql`
  mutation useCreateReportDashboardMutation($reportDashboardForm: DashboardForm!) {
    response: createReportDashboard(reportDashboardForm: $reportDashboardForm) {
      ${gql.errors}
      dashboard {
        id
        name
        uuid
      }
    }
  }
`;

export default useCreateReportDashboardMutation;
