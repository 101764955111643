// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import RoomForm from '@shared/modules/Inventory/forms/RoomForm';

// App

const useCreateRoomMutation = ({roomForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      roomForm: RoomForm.toForm(roomForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateRoomMutation.mutation,
    variables: {
      roomForm: RoomForm.toMutation(form.values.roomForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateRoomMutation.mutation = gql`
  mutation useCreateRoomMutation($roomForm: RoomForm!) {
    response: createRoom(roomForm: $roomForm) {
      ${gql.errors}
      room {
        id
        uuid
      }
    }
  }
`;

export default useCreateRoomMutation;
