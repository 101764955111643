// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import CompensationReportStatus from '@shared/modules/Accounting/enums/CompensationReportStatus';
import UserRole from '@shared/modules/User/enums/UserRole';

const getRole = withFragment(
  (cost) => {
    const role = cost.moverPosition ? cost.moverPosition.role : cost.compensationUser.role;
    return UserRole.getDisplayRole(role);
  },
  gql`
    fragment Cost_getRole on Cost {
      id
      moverPosition {
        id
        role
      }
      compensationUser {
        id
        role
      }
    }
  `,
);

const getHasBalanceDue = withFragment(
  (cost) => {
    const remainingBalance = cost.total - cost.paidOutAmount;
    return cost.isPaidOut && remainingBalance > 0;
  },
  gql`
    fragment Cost_getHasBalanceDue on Cost {
      id
      isPaidOut
      total
      paidOutAmount
    }
  `,
);

const getRemainingBalance = withFragment(
  (cost) => {
    return cost.total - cost.paidOutAmount;
  },
  gql`
    fragment Cost_getRemainingBalance on Cost {
      id
      total
      paidOutAmount
    }
  `,
);

const getIsPaidOutOption = withFragment(
  (cost) => {
    const status = cost.isPaidOut
      ? CompensationReportStatus.PAID_OUT
      : CompensationReportStatus.NOT_PAID_OUT;
    return CompensationReportStatus.STATUS_OPTION_MAP[status];
  },
  gql`
    fragment Cost_getIsPaidOutOption on Cost {
      id
      isPaidOut
    }
  `,
);

const getDisplayIdentifier = withFragment(
  (cost) => {
    return cost.job
      ? `${cost.job.jobType.name}: ${cost.job.identifier}`
      : `Project ${cost.project.identifier}`;
  },
  gql`
    fragment Cost_getDisplayIdentifier on Cost {
      id
      job {
        id
        identifier
        jobType {
          id
          name
        }
      }
      project {
        id
        identifier
      }
    }
  `,
);

const Cost = {
  getRole,
  getHasBalanceDue,
  getRemainingBalance,
  getIsPaidOutOption,
  getDisplayIdentifier,
};

export default Cost;
