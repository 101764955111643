// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import CommercialEquipmentForm from '@shared/modules/CommercialCatalog/forms/CommercialEquipmentForm';
import CommercialMaterialForm from '@shared/modules/CommercialCatalog/forms/CommercialMaterialForm';

const edit = withFragment(
  (commercialCatalog) => ({
    commercialCatalogId: commercialCatalog.id,
    commercialEquipmentForms: commercialCatalog.commercialEquipments.map((commercialEquipment) => {
      return CommercialEquipmentForm.edit(commercialEquipment);
    }),
    commercialMaterialForms: commercialCatalog.commercialMaterials.map((commercialMaterial) => {
      return CommercialMaterialForm.edit(commercialMaterial);
    }),
  }),
  gql`
    ${CommercialEquipmentForm.edit.fragment}
    ${CommercialMaterialForm.edit.fragment}

    fragment CommercialCatalogForm_edit on CommercialCatalog {
      id
      commercialEquipments {
        id
        ...CommercialEquipmentForm_edit
      }
      commercialMaterials {
        id
        ...CommercialMaterialForm_edit
      }
    }
  `,
);

const _new = () => ({
  commercialCatalogId: undefined,
  commercialEquipmentForms: [],
  commercialMaterialForms: [],
});

const toForm = ({commercialCatalogId, commercialEquipmentForms, commercialMaterialForms}) => ({
  commercialCatalogId,
  commercialEquipmentForms: commercialEquipmentForms.map((commercialEquipmentForm) => {
    return CommercialEquipmentForm.toForm(commercialEquipmentForm);
  }),
  commercialMaterialForms: commercialMaterialForms.map((commercialMaterialForm) => {
    return CommercialMaterialForm.toForm(commercialMaterialForm);
  }),
});

const toMutation = ({commercialCatalogId, commercialEquipmentForms, commercialMaterialForms}) => ({
  commercialCatalogId,
  commercialEquipmentForms: commercialEquipmentForms.map((commercialEquipmentForm) => {
    return CommercialEquipmentForm.toMutation(commercialEquipmentForm);
  }),
  commercialMaterialForms: commercialMaterialForms.map((commercialMaterialForm) => {
    return CommercialMaterialForm.toMutation(commercialMaterialForm);
  }),
});

const CommercialCatalogForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default CommercialCatalogForm;
