const CODAT_OPERATIONS = {
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  CREATE_INVOICE: 'CREATE_INVOICE',
  UPDATE_INVOICE: 'UPDATE_INVOICE',
  CREATE_PAYMENT: 'CREATE_PAYMENT',
};

const CODAT_OPERATIONS_DISPLAY_MAP = {
  [CODAT_OPERATIONS.CREATE_CUSTOMER]: 'Create Customer',
  [CODAT_OPERATIONS.CREATE_INVOICE]: 'Create Invoice',
  [CODAT_OPERATIONS.UPDATE_INVOICE]: 'Update Invoice',
  [CODAT_OPERATIONS.CREATE_PAYMENT]: 'Create Payment',
};

const CODAT_DATA_TYPES = {
  Customer: 'customers',
  Invoice: 'invoices',
  Payment: 'payments',
};

const getCodatOperationDropdownOptions = () => {
  return Object.keys(CODAT_OPERATIONS).map((key) => ({
    value: CODAT_OPERATIONS[key],
    label: CODAT_OPERATIONS_DISPLAY_MAP[CODAT_OPERATIONS[key]],
  }));
};

const getCodatOperationTypeDropdownOptions = () => {
  return Object.entries(CODAT_DATA_TYPES).map(([label, value]) => ({
    value,
    label,
  }));
};

const getCodatOperationStatusDropdownOptions = () => {
  // we don't need dropdown options for pending and in-progress, because we don't want to filter by them
  return [CODAT_OPERATION_STATUSES.SUCCEEDED, CODAT_OPERATION_STATUSES.FAILED].map((status) => ({
    value: status,
    label: CODAT_OPERATION_STATUSES_DISPLAY_MAP[status],
  }));
};

const getDisplayOperation = (codatOperationType) => {
  return CODAT_OPERATIONS_DISPLAY_MAP[codatOperationType];
};

const CODAT_OPERATION_STATUSES = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  SKIPPED: 'SKIPPED',
};

const CODAT_OPERATION_STATUSES_DISPLAY_MAP = {
  [CODAT_OPERATION_STATUSES.PENDING]: 'Pending',
  [CODAT_OPERATION_STATUSES.IN_PROGRESS]: 'In Progress',
  [CODAT_OPERATION_STATUSES.SUCCEEDED]: 'Succeeded',
  [CODAT_OPERATION_STATUSES.FAILED]: 'Failed',
  [CODAT_OPERATION_STATUSES.SKIPPED]: 'Skipped',
};

const getDisplayStatus = (codatOperationStatus) => {
  return CODAT_OPERATION_STATUSES_DISPLAY_MAP[codatOperationStatus];
};

export default {
  getDisplayOperation,
  getDisplayStatus,
  getCodatOperationDropdownOptions,
  getCodatOperationStatusDropdownOptions,
  getCodatOperationTypeDropdownOptions,
};
