// Libraries
import React from 'react';

// App
import {Styled, Icon, Loading, Spreadsheet} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useSpreadsheet} from '@supermove/hooks';
import {colors} from '@supermove/styles';

import ModalComponent from '@shared/design/components/Modal';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const Button = Styled.ButtonV2`
`;

const ViewDataTableModalContent = ({data, handleClose}) => {
  const parsedJson = JSON.parse(JSON.parse(data.dataTable.jsonTable));
  const {spreadsheetColumns, spreadsheetData} = useSpreadsheet({
    jsonData: parsedJson,
    columnWidth: 150,
  });

  return (
    <React.Fragment>
      <ModalComponent.Header style={{justifyContent: 'space-between'}}>
        <ModalComponent.HeaderText>{data.dataTable.name}</ModalComponent.HeaderText>
        <Button onPress={handleClose}>
          <Icon source={Icon.Times} color={colors.gray.secondary} size={20} />
        </Button>
      </ModalComponent.Header>
      <ModalComponent.Body>
        <Spreadsheet columns={spreadsheetColumns} data={spreadsheetData} />
      </ModalComponent.Body>
    </React.Fragment>
  );
};

const ViewDataTableModal = ({isOpen, handleClose, dataTableUuid}) => {
  const {data, loading} = useQuery(ViewDataTableModal.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    variables: {
      uuid: dataTableUuid,
    },
  });

  return (
    <ModalComponent.Responsive isOpen={isOpen} width={ModalComponent.WIDTH.LARGE}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return data && <ViewDataTableModalContent data={data} handleClose={handleClose} />;
        }}
      </Loading>
    </ModalComponent.Responsive>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewDataTableModal.query = gql`

  query ViewDataTableModal($uuid: String!) {
    ${gql.query}
    dataTable(uuid: $uuid){
      id
      jsonTable
      name
    }
  }
`;

export default ViewDataTableModal;
