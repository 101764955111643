// App
import BillForm from '@shared/modules/Billing/forms/BillForm';

const toForm = ({projectId, billForms}) => ({
  projectId,
  billForms,
});

const toMutation = ({projectId, billForms}) => ({
  projectId,
  billForms: billForms.map((billForm) => {
    return BillForm.toMutation(billForm);
  }),
});

const PreviewBillForm = {
  toForm,
  toMutation,
};

export default PreviewBillForm;
