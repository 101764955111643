// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import BetaBadge from '@shared/design/components/BetaBadge';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.ButtonV2`
`;

const PreviousScreenText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.secondary};
`;

const CurrentScreenText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.blue.accentDark};
`;

const PreviousScreen = ({name, onPress, isBeta}: any) => {
  return (
    <React.Fragment>
      <Button onPress={onPress}>
        <Row>
          <PreviousScreenText numberOfLines={1}>{name}</PreviousScreenText>
          {isBeta && (
            <React.Fragment>
              <Space width={8} />
              <BetaBadge />
            </React.Fragment>
          )}
        </Row>
      </Button>
      <Space width={12} />
      <Icon source={Icon.ChevronRight} color={colors.gray.secondary} size={14} />
      <Space width={12} />
    </React.Fragment>
  );
};

const CurrentScreen = ({name, isBeta}: any) => {
  return (
    <React.Fragment>
      <CurrentScreenText numberOfLines={1}>{name}</CurrentScreenText>
      {isBeta && (
        <React.Fragment>
          <Space width={8} />
          <BetaBadge />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

/**
 * Breadcrumb takes in an array of objects with fields below
 * @param {string} name - Name of breadcrumb page
 * @param {function} onPress - Function that breadcrumb will use to navigate to the page
 */
const Breadcrumbs = ({breadcrumbs}: any) => {
  return (
    <Row style={{flex: 1}}>
      {breadcrumbs.map((breadcrumb: any, index: any) => {
        // If last item, then we only need the name and no onPress for the current page
        if (index === breadcrumbs.length - 1) {
          return <CurrentScreen name={breadcrumb.name} isBeta={breadcrumb.isBeta} key={index} />;
        }
        return (
          <PreviousScreen
            name={breadcrumb.name}
            onPress={breadcrumb.onPress}
            isBeta={breadcrumb.isBeta}
            key={index}
          />
        );
      })}
    </Row>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default Breadcrumbs;
