// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CodatBatchExportForm from '@shared/modules/Integration/forms/CodatBatchExportForm';

const useBatchExportToCodatMutation = ({codatBatchExportForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      codatBatchExportForm: CodatBatchExportForm.toForm(codatBatchExportForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useBatchExportToCodatMutation.mutation,
    variables: {
      codatBatchExportForm: CodatBatchExportForm.toMutation(form.values.codatBatchExportForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useBatchExportToCodatMutation.mutation = gql`
  mutation useBatchExportToCodatMutation($codatBatchExportForm: CodatBatchExportForm!) {
    response: batchExportToCodat(codatBatchExportForm: $codatBatchExportForm) {
      ${gql.errors}
    }
  }
`;

export default useBatchExportToCodatMutation;
