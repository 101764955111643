/**
 * Component - v2.0.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Formula} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemUnit from '@shared/modules/Billing/enums/BillItemUnit';
import BillStage from '@shared/modules/Billing/enums/BillStage';
import BillItemTypeForm from '@shared/modules/Billing/forms/BillItemTypeForm';
import useCreateBillItemTypeMutation from '@shared/modules/Billing/hooks/useCreateBillItemTypeMutation';
import TextInputWithDropdown from 'modules/App/components/TextInputWithDropdown';
import BillingLibraryAddBillItemModalV1 from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryAddBillItemModalV1';

const Row = Styled.View`
  z-index: ${({index}) => 100 - index};
  flex-direction: row;
`;

const Label = Styled.Text`
  ${Typography.Body3}
`;

const LeftToggleButton = Styled.ButtonV2`
  width: 176px;
  align-items: center;
  padding-vertical: 12px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-width: 1px;
  border-right-width: 0px;
  border-color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.gray.tertiary)};
  background-color: ${({isSelected, disabled}) =>
    isSelected ? colors.blue.accent : disabled ? colors.gray.border : colors.white};
`;

const RightToggleButton = Styled.ButtonV2`
  width: 176px;
  align-items: center;
  padding-vertical: 12px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-width: 1px;
  border-left-width: 0px;
  border-color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.gray.tertiary)};
  background-color: ${({isSelected, disabled}) =>
    isSelected ? colors.blue.accent : disabled ? colors.gray.border : colors.white};
`;

const ToggleDivider = Styled.View`
  border-right-width: 1px;
  border-color: ${colors.blue.interactive};
`;

const ToggleButtonText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const DropdownSelectedBadgeContainer = Styled.View`
  padding-vertical: 2px;
  padding-horizontal: 4px;
  background-color: ${colors.alpha(colors.orange.status, 0.1)}
  border-radius: 16px;
  border-color: ${colors.orange.status}
  border-width: 1px;
`;

const DropdownSelectedBadgeText = Styled.Text`
  ${Typography.Label4}
  color: ${colors.orange.status};
`;

const getHeaderText = (category) => {
  if (category === BillItemTypeCategory.SUPPLIES) {
    return 'Add Supply';
  }
  if (category === BillItemTypeCategory.FEES) {
    return 'Add Fee';
  }
  return 'Add Discount';
};

const getUnitOptions = (category) => {
  const unitOptions = [
    {label: '$', value: BillItemUnit.DOLLAR},
    {label: '%', value: BillItemUnit.PERCENT},
  ];
  if (category === BillItemTypeCategory.FEES) {
    unitOptions.push({label: '/ hour', value: BillItemUnit.HOUR});
  }
  return unitOptions;
};

const handleChangeUnit = ({unit, form, field}) => {
  if (unit === BillItemUnit.PERCENT) {
    form.setFieldValue(`${field}.billStage`, BillStage.POST_SUBTOTAL);
    form.setFieldValue(`${field}.amount`, '');
  } else if (unit === BillItemUnit.HOUR) {
    form.setFieldValue(`${field}.billStage`, BillStage.PRE_SUBTOTAL);
    form.setFieldValue(`${field}.percentage`, '');
  } else {
    form.setFieldValue(`${field}.percentage`, '');
  }
};

const handleBlurCurrency = ({form, field}) => {
  const currency = _.get(form.values, field);
  if (currency) {
    form.setFieldValue(field, Currency.toForm(Currency.convertToCents(currency)));
  }
};

const handleBlurPercent = ({form, field}) => {
  const percent = _.get(form.values, field);
  if (percent) {
    form.setFieldValue(field, Percent.toForm(Percent.toFloat(percent)));
  }
};

const DropdownSelectedBadge = () => {
  return (
    <DropdownSelectedBadgeContainer>
      <DropdownSelectedBadgeText>Formula</DropdownSelectedBadgeText>
    </DropdownSelectedBadgeContainer>
  );
};

const QuantityField = ({form, field, fieldIndex, formulaOptions}) => {
  return (
    <Row index={fieldIndex}>
      <TextInputWithDropdown
        form={form}
        textValueField={`${field}.minQuantity`}
        clearTextValue={''}
        dropdownValueField={`${field}.minQuantityFormulaId`}
        label={'Min Quantity'}
        dropdownOptions={formulaOptions}
        placeholder={'Formula or quantity'}
        noOptionsMessage={'No formula options'}
        DropdownSelectedBadge={DropdownSelectedBadge}
      />
      <Space width={8} />
      <TextInputWithDropdown
        form={form}
        textValueField={`${field}.maxQuantity`}
        clearTextValue={''}
        dropdownValueField={`${field}.maxQuantityFormulaId`}
        label={'Max Quantity'}
        dropdownOptions={formulaOptions}
        placeholder={'Formula or quantity'}
        noOptionsMessage={'No formula options'}
        DropdownSelectedBadge={DropdownSelectedBadge}
      />
    </Row>
  );
};

const PriceField = ({form, field, fieldIndex, formulaOptions}) => {
  return (
    <TextInputWithDropdown
      form={form}
      index={fieldIndex}
      textValueField={`${field}.amount`}
      clearTextValue={''}
      dropdownValueField={`${field}.amountFormulaId`}
      label={'Price'}
      dropdownOptions={formulaOptions}
      placeholder={'Formula or custom price'}
      noOptionsMessage={'No formula options'}
      onBlur={() => handleBlurCurrency({form, field: `${field}.amount`})}
      DropdownSelectedBadge={DropdownSelectedBadge}
    />
  );
};

const ValueField = ({form, field, fieldIndex, formulaOptions, category}) => {
  const unit = _.get(form.values, `${field}.unit`);
  const isUnitPercent = unit === BillItemUnit.PERCENT;
  return (
    <Row index={fieldIndex}>
      {isUnitPercent ? (
        <TextInputWithDropdown
          form={form}
          textValueField={`${field}.percentage`}
          clearTextValue={''}
          dropdownValueField={`${field}.amountFormulaId`}
          label={'Value'}
          dropdownOptions={formulaOptions}
          placeholder={'Formula or custom percent'}
          noOptionsMessage={'No formula options'}
          onBlur={() => handleBlurPercent({form, field: `${field}.percentage`})}
          DropdownSelectedBadge={DropdownSelectedBadge}
        />
      ) : (
        <TextInputWithDropdown
          form={form}
          textValueField={`${field}.amount`}
          clearTextValue={''}
          dropdownValueField={`${field}.amountFormulaId`}
          label={'Value'}
          dropdownOptions={formulaOptions}
          placeholder={'Formula or custom value'}
          noOptionsMessage={'No formula options'}
          onBlur={() => handleBlurCurrency({form, field: `${field}.amount`})}
          DropdownSelectedBadge={DropdownSelectedBadge}
        />
      )}
      <Space width={8} />
      <FieldInput
        {...form}
        label={'Unit'}
        name={`${field}.unit`}
        component={DropdownInput}
        input={{
          options: getUnitOptions(category),
          onChangeValue: (value) => handleChangeUnit({unit: value, form, field}),
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          style: {width: 116},
        }}
      />
    </Row>
  );
};

const BillStageField = ({form, field, fieldIndex}) => {
  const billStage = _.get(form.values, `${field}.billStage`);
  const isBefore = billStage === BillStage.PRE_SUBTOTAL;
  const unit = _.get(form.values, `${field}.unit`);
  const isUnitPercent = unit === BillItemUnit.PERCENT;
  const isUnitHour = unit === BillItemUnit.HOUR;

  return (
    <React.Fragment>
      <Label>Before / After Subtotal</Label>
      <Space height={4} />
      <Row index={fieldIndex}>
        <LeftToggleButton
          isSelected={isBefore}
          onPress={() => form.setFieldValue(`${field}.billStage`, BillStage.PRE_SUBTOTAL)}
          disabled={isUnitPercent}
        >
          <ToggleButtonText
            style={isBefore ? {fontWeight: '700', color: colors.blue.interactive} : null}
          >
            Before Subtotal
          </ToggleButtonText>
        </LeftToggleButton>
        <ToggleDivider />
        <RightToggleButton
          isSelected={!isBefore}
          onPress={() => form.setFieldValue(`${field}.billStage`, BillStage.POST_SUBTOTAL)}
          disabled={isUnitHour}
        >
          <ToggleButtonText
            style={!isBefore ? {fontWeight: '700', color: colors.blue.interactive} : null}
          >
            After Subtotal
          </ToggleButtonText>
        </RightToggleButton>
      </Row>
    </React.Fragment>
  );
};

const BillingLibraryAddBillItemModalContent = ({form, field, category, formulas}) => {
  const isCategorySupplies = category === BillItemTypeCategory.SUPPLIES;
  const isPreSubtotal = _.get(form.values, `${field}.billStage`) === BillStage.PRE_SUBTOTAL;
  const formulaOptions = Formula.getFormulaDropdownOptions(formulas);
  return (
    <React.Fragment>
      <TextInputWithDropdown
        form={form}
        index={0}
        textValueField={`${field}.name`}
        dropdownValueField={`${field}.nameFormulaId`}
        label={'Name'}
        dropdownOptions={formulaOptions}
        placeholder={'Formula or custom name'}
        noOptionsMessage={'No formula options'}
        DropdownSelectedBadge={DropdownSelectedBadge}
        isRequired
      />
      <Space height={20} />
      {isPreSubtotal && (
        <React.Fragment>
          <QuantityField form={form} field={field} fieldIndex={1} formulaOptions={formulaOptions} />
          <Space height={20} />
        </React.Fragment>
      )}
      {isCategorySupplies ? (
        <PriceField form={form} field={field} fieldIndex={2} formulaOptions={formulaOptions} />
      ) : (
        <React.Fragment>
          <ValueField
            form={form}
            field={field}
            fieldIndex={3}
            formulaOptions={formulaOptions}
            category={category}
          />
          <Space height={20} />
          <BillStageField form={form} field={field} fieldIndex={4} />
        </React.Fragment>
      )}
      <Space height={20} />
      <FieldInput
        {...form}
        index={5}
        label={'Description (Optional)'}
        name={`${field}.description`}
        input={{placeholder: 'Enter a description'}}
      />
    </React.Fragment>
  );
};

const BillingLibraryAddBillItemModal = ({
  billingLibrary,
  category,
  isOpen,
  handleClose,
  refetch,
  userId,
}) => {
  const {data} = useQuery(BillingLibraryAddBillItemModal.query, {
    fetchPolicy: 'cache-and-network',
  });
  const billItemTypeForm = BillItemTypeForm.new({
    billingLibraryId: billingLibrary.id,
    organizationId: billingLibrary.organizationId,
    category,
    userId,
  });
  const {form, submitting, handleSubmit} = useCreateBillItemTypeMutation({
    billItemTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose}>
      <SmallModal.HeaderText>{getHeaderText(category)}</SmallModal.HeaderText>
      <Space height={20} />
      <BillingLibraryAddBillItemModalContent
        form={form}
        field={'billItemTypeForm'}
        category={category}
        formulas={_.get(data, 'viewer.viewingOrganization.formulas', [])}
      />
      <Space height={36} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose} isDisabled={submitting}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          onPress={handleSubmit}
          color={colors.blue.interactive}
          isSubmitting={submitting}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryAddBillItemModal.query = gql`
  ${Formula.getFormulaDropdownOptions.fragment}
  query BillingLibraryAddBillItemModal {
    viewer {
      id
      viewingOrganization {
        id
        formulas {
          id
          ...Formula_getFormulaDropdownOptions
        }
      }
    }
  }
`;

BillingLibraryAddBillItemModal.fragment = gql`
  ${BillingLibraryAddBillItemModalV1.fragment}
  fragment BillingLibraryAddBillItemModal on BillingLibrary {
    id
    organizationId
    ...BillingLibraryAddBillItemModalV1
  }
`;

export default BillingLibraryAddBillItemModal;
