// Supermove
import {gql} from '@supermove/graphql';
import {useForm} from '@supermove/hooks';

// App
import BookingFiltersForm from '@shared/modules/Booking/forms/BookingFiltersForm';

const useBookingFiltersForm = ({viewerOrganization, selectedOrganizationSlug}) => {
  const form = useForm({
    initialValues: BookingFiltersForm.new({
      organization: viewerOrganization,
      selectedOrganizationSlug,
    }),
  });

  return form;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useBookingFiltersForm.fragment = gql`
  ${BookingFiltersForm.new.fragment}

  fragment useBookingFiltersForm on Organization {
    id
    ...BookingFiltersForm_new
  }
`;

export default useBookingFiltersForm;
