// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled, FlatList} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Collection, Datetime, Duration} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button/SecondaryButton';
import TimesheetBillableEntry from '@shared/modules/Timesheet/components/TimesheetBillableEntry';
import TimesheetBillableEntryKind from '@shared/modules/Timesheet/enums/TimesheetBillableEntryKind';
import TimesheetBlockKind from '@shared/modules/Timesheet/enums/TimesheetBlockKind';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Wrapper = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const TableContainer = Styled.View`
  border-left-width: 1px;
  border-color: ${colors.gray.border};
`;

const HeaderCell = Styled.View`
  padding-vertical: 3px;
  padding-horizontal: 5px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  justify-content: flex-end;
`;

const HeaderText = Styled.Text`
  ${Typography.Label3}
`;

const Cell = Styled.View`
  padding-vertical: 3px;
  padding-horizontal: 5px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const ButtonRow = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const sortJobUsers = ({jobUsers}) => {
  return _.sortBy(jobUsers, ['user.fullName']);
};

const formatTimeRanges = ({timeRanges}) => {
  return timeRanges.concat([_.last(timeRanges)]);
};

const getJobUserTimes = ({jobUser}) => {
  const timeRanges = formatTimeRanges({
    timeRanges: _.get(jobUser, 'timesheet.timeRanges', []),
  });

  return Collection.mapWith(timeRanges, (timeRange, index, {isLast}) => {
    return isLast ? timeRange.end : timeRange.start;
  });
};

const getColumnNames = ({job}) => {
  const timeRanges = formatTimeRanges({
    timeRanges: _.get(job, 'organization.defaultTimesheetTimeRanges', []),
  });

  return Collection.mapWith(timeRanges, (timeRange, index, {isLast}) => {
    return isLast ? timeRange.endName : timeRange.startName;
  });
};

const TimesheetBillableEntriesMock = () => {
  return (
    <React.Fragment>
      {MOCK_TIMESHEET_BILLABLE_ENTRIES_JOB.timesheetBillableEntries.map(
        (timesheetBillableEntry, index) => (
          <React.Fragment key={timesheetBillableEntry.id}>
            {index > 0 && <Space height={24} />}
            <TimesheetBillableEntry
              job={MOCK_TIMESHEET_BILLABLE_ENTRIES_JOB}
              timesheetBillableEntry={timesheetBillableEntry}
              isViewOnly
            />
          </React.Fragment>
        ),
      )}
    </React.Fragment>
  );
};

const CommercialJobUser = ({jobUser, job, columnNames}) => {
  return (
    <Row>
      {job.organization.features.isEnabledJobUserShowBranchCode && (
        <Cell key={0} style={{width: 80}}>
          <Text>{jobUser.branchCode}</Text>
        </Cell>
      )}
      <Cell key={1} style={{width: 140}}>
        <Text numberOfLines={1}>{jobUser.user.fullName}</Text>
      </Cell>
      {job.hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo && (
        <Cell key={2} style={{width: 140}}>
          <Text numberOfLines={1}>{jobUser.position}</Text>
          {job.organization.features.isEnabledJobUserShowOrganizationName && (
            <Text numberOfLines={1}>{jobUser.user.organization.name}</Text>
          )}
        </Cell>
      )}
      {columnNames.map((name, index) => {
        const jobUserTimes = getJobUserTimes({jobUser});
        const columnTime = _.get(jobUserTimes, index);
        return (
          <Cell key={`time-${index}`} style={{flex: 1}}>
            <Text numberOfLines={1}>
              {columnTime ? Datetime.convertToDisplayTime(columnTime) : '--'}
            </Text>
          </Cell>
        );
      })}
      <Cell key={3} style={{flex: 1}}>
        <Text numberOfLines={1}>{Duration.toHours(jobUser.timesheet.totalTimeDrive)}</Text>
      </Cell>
      <Cell key={4} style={{flex: 1}}>
        <Text numberOfLines={1}>{Duration.toHours(jobUser.timesheet.totalTimeWithoutBreak)}</Text>
      </Cell>
    </Row>
  );
};

const TableHeader = ({columnNames, job}) => (
  <Row>
    {job.organization.features.isEnabledJobUserShowBranchCode && (
      <HeaderCell style={{width: 80}}>
        <HeaderText>Branch / IC Code</HeaderText>
      </HeaderCell>
    )}
    <HeaderCell style={{width: 140}}>
      <HeaderText>Name</HeaderText>
    </HeaderCell>
    {job.hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo && (
      <HeaderCell style={{width: 140}}>
        <HeaderText>Position</HeaderText>
        {job.organization.features.isEnabledJobUserShowOrganizationName && (
          <HeaderText>Labor Source</HeaderText>
        )}
      </HeaderCell>
    )}
    {columnNames.map((name, index) => (
      <HeaderCell key={index} style={{flex: 1}}>
        <HeaderText>{name}</HeaderText>
      </HeaderCell>
    ))}
    <HeaderCell style={{flex: 1}}>
      <HeaderText>Travel Hours</HeaderText>
    </HeaderCell>
    <HeaderCell style={{flex: 1}}>
      <HeaderText>Total Hours</HeaderText>
    </HeaderCell>
  </Row>
);

const DocumentV2PerMoverTimesheetTable = ({job}) => {
  const columnNames = getColumnNames({job});
  return (
    <TableContainer>
      <TableHeader columnNames={columnNames} job={job} />
      <FlatList
        data={sortJobUsers({jobUsers: job.confirmedJobUsers})}
        keyExtractor={(jobUser) => jobUser.id}
        renderItem={({item: jobUser, index}) => (
          <CommercialJobUser jobUser={jobUser} job={job} columnNames={columnNames} />
        )}
      />
    </TableContainer>
  );
};

const TimesheetBillableEntries = ({job}) => {
  return (
    <React.Fragment>
      {job.timesheetBillableEntryKind === TimesheetBillableEntryKind.POSITION && (
        <React.Fragment>
          {job.timesheetBillableEntries.map((timesheetBillableEntry, index) => (
            <React.Fragment key={timesheetBillableEntry.id}>
              {index > 0 && <Space height={24} />}
              <TimesheetBillableEntry
                job={job}
                timesheetBillableEntry={timesheetBillableEntry}
                isViewOnly
              />
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const DocumentV2InputPerMoverTimesheetContent = ({job, isEditable}) => {
  const {navigator} = useNavigation();
  return (
    <Container>
      {isEditable && (
        <ButtonRow>
          <Button
            text={'Edit'}
            iconLeft={Icon.Pen}
            onPress={() => {
              // NOTE(dan) This is a crew app navigation as the document should
              // only be editable from crew app.
              navigator.push('EditCommercialTimesheetCrewJob', {
                uuid: job.uuid,
                success: 'BACK',
              });
            }}
          />
        </ButtonRow>
      )}
      <Space height={10} />
      {job.project.projectType.features.timesheetsV2 ? (
        <TimesheetBillableEntries job={job} />
      ) : (
        <DocumentV2PerMoverTimesheetTable job={job} />
      )}
    </Container>
  );
};

const DocumentV2InputPerMoverTimesheet = ({index, isPreview, job, isEditable}) => {
  return (
    <Wrapper index={index}>
      {isPreview ? (
        <TimesheetBillableEntriesMock />
      ) : (
        <DocumentV2InputPerMoverTimesheetContent job={job} isEditable={isEditable} />
      )}
    </Wrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentV2InputPerMoverTimesheet.fragment = gql`
  ${TimesheetBillableEntry.fragment}

  fragment DocumentV2InputPerMoverTimesheet on Job {
    id
    uuid
    hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo: hasJobFeature(
      kind: "CREW_COMMERCIAL_TIMESHEET_SHOW_ADDITIONAL_JOB_USER_INFO"
    )
    project {
      id
      projectType {
        id
        features {
          timesheetsV2
        }
      }
    }
    organization {
      id
      defaultTimesheetTimeRanges {
        startName
        endName
      }
      features {
        isEnabledJobUserShowBranchCode: isEnabled(feature: "JOB_USER_SHOW_BRANCH_CODE")
        isEnabledJobUserShowOrganizationName: isEnabled(feature: "JOB_USER_SHOW_ORGANIZATION_NAME")
      }
    }
    confirmedJobUsers {
      id
      position
      branchCode
      timesheet {
        timeRanges {
          start
          end
          startName
          endName
        }
        totalTimeDrive
        totalTimeWithoutBreak
      }
      user {
        id
        fullName
        organization {
          id
          name
        }
      }
    }
    ...TimesheetBillableEntry
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_TIMESHEET_BILLABLE_ENTRY_1 = {
  id: 1,
  kind: TimesheetBillableEntryKind.POSITION,
  job: {
    id: 1,
    organization: {
      id: 1,
    },
  },
  moverPosition: {
    id: 1,
    name: 'Position 1',
  },
  timesheetBlocks: [
    {
      id: 1,
      kind: TimesheetBlockKind.WORK,
      rangeFrom: '2021-08-01T15:00:00Z',
      rangeTo: '2021-08-01T16:30:00Z',
    },
    {
      id: 2,
      kind: TimesheetBlockKind.BREAK,
      rangeFrom: '2021-08-01T16:30:00Z',
      rangeTo: '2021-08-01T17:00:00Z',
    },
    {
      id: 3,
      kind: TimesheetBlockKind.DRIVE,
      rangeFrom: '2021-08-01T17:00:00Z',
      rangeTo: '2021-08-01T18:00:00Z',
    },
    {
      id: 4,
      kind: TimesheetBlockKind.WAIT,
      rangeFrom: '2021-08-01T18:00:00Z',
      rangeTo: '2021-08-01T18:30:00Z',
    },
    {
      id: 5,
      kind: TimesheetBlockKind.WORK,
      rangeFrom: '2021-08-01T18:30:00Z',
      rangeTo: '2021-08-01T19:30:00Z',
    },
  ],
};

const MOCK_TIMESHEET_BILLABLE_ENTRY_2 = {
  id: 2,
  kind: TimesheetBillableEntryKind.POSITION,
  job: {
    id: 1,
    organization: {
      id: 1,
    },
  },
  moverPosition: {
    id: 2,
    name: 'Position 2',
  },
  timesheetBlocks: [
    {
      id: 1,
      kind: TimesheetBlockKind.WORK,
      rangeFrom: '2021-08-01T14:00:00Z',
      rangeTo: '2021-08-01T16:30:00Z',
    },
    {
      id: 2,
      kind: TimesheetBlockKind.BREAK,
      rangeFrom: '2021-08-01T16:30:00Z',
      rangeTo: '2021-08-01T17:00:00Z',
    },
    {
      id: 3,
      kind: TimesheetBlockKind.DRIVE,
      rangeFrom: '2021-08-01T17:00:00Z',
      rangeTo: '2021-08-01T18:00:00Z',
    },
    {
      id: 5,
      kind: TimesheetBlockKind.WORK,
      rangeFrom: '2021-08-01T18:00:00Z',
      rangeTo: '2021-08-01T19:30:00Z',
    },
  ],
};

const MOCK_TIMESHEET_BILLABLE_ENTRIES_JOB = {
  id: 1,
  isComplete: true,
  timesheetBillableEntries: [MOCK_TIMESHEET_BILLABLE_ENTRY_1, MOCK_TIMESHEET_BILLABLE_ENTRY_2],
};

export default DocumentV2InputPerMoverTimesheet;
