// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useResetMoveMutation = ({moveId, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      moveId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useResetMoveMutation.mutation,
    variables: {
      moveId: form.values.moveId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useResetMoveMutation.mutation = gql`
  mutation useResetMoveMutation($moveId: Int!) {
    response: resetMove(moveId: $moveId) {
      ${gql.errors}
      move {
        id
      }
    }
  }
`;

export default useResetMoveMutation;
