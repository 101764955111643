// Supermove
import {gql} from '@supermove/graphql';
import {Distance, Json, withFragment} from '@supermove/utils';

const getDisplayReferral = withFragment(
  (job) => {
    if (job.referralSource === 'Other' && job.referralDetails) {
      return `Other: ${job.referralDetails}`;
    } else if (job.referralSource) {
      return job.referralSource;
    }
    return '';
  },
  gql`
    fragment JobRequest_getDisplayReferral on JobRequest {
      referralSource
      referralDetails
    }
  `,
);

const JobRequest = {
  // Getters
  getDisplayReferral,

  getAdditionalItems: (jobRequest) => {
    return Json.toObject(jobRequest.additionalItems);
  },
  getTotalDistanceText: ({totalDistance}) => {
    return Distance.display(totalDistance);
  },
};

export default JobRequest;
