// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (project) => ({
    projectId: project.id,
    bookedById: project.bookedById,
    additionalSalespersonIds: project.additionalSalespersonIds,
    coordinatedById: project.coordinatedById,
    referralSource: project.referralSource,
    referralDetails: project.referralDetails,
  }),
  gql`
    fragment ProjectInternalInformationForm_edit on Project {
      id
      bookedById
      additionalSalespersonIds
      coordinatedById
      referralSource
      referralDetails
    }
  `,
);

const toForm = ({
  projectId,
  bookedById,
  additionalSalespersonIds,
  coordinatedById,
  referralSource,
  referralDetails,
}) => ({
  projectId,
  bookedById,
  additionalSalespersonIds,
  coordinatedById,
  referralSource,
  referralDetails,
});

const toMutation = ({
  projectId,
  bookedById,
  additionalSalespersonIds,
  coordinatedById,
  referralSource,
  referralDetails,
}) => ({
  projectId,
  bookedById,
  additionalSalespersonIds,
  coordinatedById,
  referralSource,
  referralDetails,
});

const ProjectInternalInformationForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectInternalInformationForm;
