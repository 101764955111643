const _new = ({attachmentId}) => ({
  attachmentId,
});

const toForm = ({attachmentId}) => ({
  attachmentId,
});

const toMutation = ({attachmentId}) => ({
  attachmentId,
});

const DeleteAttachmentForm = {
  new: _new,
  toForm,
  toMutation,
};

export default DeleteAttachmentForm;
