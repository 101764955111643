// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography} from '@supermove/styles';
import {Datetime, pluralize} from '@supermove/utils';

// App
import Modal from '@shared/design/components/Modal';
import Table from '@shared/design/components/Table';

const FieldSpace = () => {
  return <Space height={12} width={8} />;
};

const HeaderText = Styled.Text`
  ${Typography.Label2}
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const COLUMN_DEFINITIONS = [
  {
    flex: 1,
    headerContent: () => {
      return <HeaderText>Collection Name</HeaderText>;
    },
    cellContent: ({item: collection}) => {
      return <Text>{collection.name}</Text>;
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <HeaderText>Date In</HeaderText>;
    },
    cellContent: ({item: collection}) => {
      return (
        <Text>
          {collection.startDate
            ? Datetime.convertToDisplayDate(collection.startDate, Datetime.DISPLAY_SHORT_DATE)
            : '-'}
        </Text>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <HeaderText>Date Out</HeaderText>;
    },
    cellContent: ({item: collection}) => {
      return (
        <Text>
          {collection.endDate
            ? Datetime.convertToDisplayDate(collection.endDate, Datetime.DISPLAY_SHORT_DATE)
            : '-'}
        </Text>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <HeaderText>Weight</HeaderText>;
    },
    cellContent: ({item: collection}) => {
      return <Text>{pluralize('lbs', collection.weight, '-')}</Text>;
    },
  },
];

const StorageProjectInventoryFields = ({collections}) => {
  return (
    <React.Fragment>
      <Modal.BlockHeader>Inventory Information</Modal.BlockHeader>
      <FieldSpace />
      <Table
        columnDefinitions={COLUMN_DEFINITIONS}
        emptyStateText='No collections'
        items={collections}
        itemKey={'id'}
        isDense
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageProjectInventoryFields.fragment = gql`
  fragment StorageProjectInventoryFields on Collection {
    id
    name
    startDate
    endDate
    weight
  }
`;

export default StorageProjectInventoryFields;
