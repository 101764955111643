const LEAD = 'LEAD';
const HOLD = 'HOLD';
const BOOKED = 'BOOKED';
const IN_PROGRESS = 'IN_PROGRESS';
const COMPLETED = 'COMPLETED';
const CANCELLED = 'CANCELLED';

const OPTIONS = [
  {
    value: LEAD,
    label: 'Lead',
  },
  {
    value: HOLD,
    label: 'Hold',
  },
  {
    value: BOOKED,
    label: 'Booked',
  },
  {
    value: IN_PROGRESS,
    label: 'In Progress',
  },
  {
    value: COMPLETED,
    label: 'Completed',
  },
  {
    value: CANCELLED,
    label: 'Cancelled',
  },
];

export default {
  // Enum Options
  LEAD,
  HOLD,
  BOOKED,
  IN_PROGRESS,
  COMPLETED,
  CANCELLED,

  OPTIONS,
};
