/* eslint-disable react-hooks/exhaustive-deps */
import {isEqual, uniqWith} from 'lodash';
import React from 'react';

import {useRef, useEffect, useState} from '@supermove/hooks';

import {getSSEUrl} from '@shared/modules/SSE/components/SSEConfig';
import {SSEEvent, SSEChannelSubscriptionRequest} from '@shared/modules/SSE/interfaces';

export interface SSEContextValue {
  events: SSEEvent[];
  subscribedChannels: SSEChannelSubscriptionRequest[];
  subscribe: (channels: SSEChannelSubscriptionRequest[]) => void;
  unsubscribe: (channels: SSEChannelSubscriptionRequest[]) => void;
  connected: boolean;
  flushEvents: () => void;
}

export const SSEContext = React.createContext<SSEContextValue>({
  events: [],
  subscribedChannels: [],
  subscribe: (channels) => {},
  unsubscribe: (channels) => {},
  connected: false,
  flushEvents: () => {},
});

interface SSEProviderProps {
  userId: number;
  organizationId: number;
  channels: SSEChannelSubscriptionRequest[];
  children: React.ReactNode;
}

const makeEventSource = ({
  userId,
  organizationId,
  channels,
  eventSourceRef,
  setEvents,
  attempt = 0,
}: {
  userId: number;
  organizationId: number;
  channels: SSEChannelSubscriptionRequest[];
  eventSourceRef: React.MutableRefObject<EventSource | null>;
  setEvents: React.Dispatch<React.SetStateAction<SSEEvent[]>>;
  attempt?: number;
}): EventSource => {
  const reconnectAttemptInterval = 5000;
  const maxJitter = 5000;
  const maxAttempts = 10;
  const eventSource = new EventSource(getSSEUrl({userId, organizationId, channels}));

  eventSource.onerror = (error) => {
    console.error('SSE error:', error);
    eventSource.close();
    eventSourceRef.current = null;

    if (attempt >= maxAttempts) {
      console.warn('Maximum reconnection attempts reached. Not reconnecting to SSE.');
      return;
    }

    const jitter = Math.random() * maxJitter;

    setTimeout(() => {
      console.log('Attempting to reconnect to SSE server...', attempt);
      eventSourceRef.current = makeEventSource({
        userId,
        organizationId,
        channels,
        eventSourceRef,
        setEvents,
        attempt: attempt + 1,
      });
    }, reconnectAttemptInterval + jitter);
  };

  eventSource.onopen = () => {
    console.log('SSE connection established');
  };

  eventSource.onmessage = (event: MessageEvent) => {
    const parsedEvent: SSEEvent = JSON.parse(event.data);
    if (event.lastEventId) {
      parsedEvent.eventId = event.lastEventId;
    }
    // clamp to 100 events
    setEvents((prevEvents) => [...prevEvents, parsedEvent].slice(-100));
  };

  return eventSource;
};

export const SSEProvider = ({
  userId,
  organizationId,
  channels: channelProp,
  children,
}: SSEProviderProps) => {
  const [events, setEvents] = useState<SSEEvent[]>([]);

  const [channels, setChannels] = useState<SSEChannelSubscriptionRequest[]>([]);
  const eventSourceRef = useRef<EventSource | null>(null);

  useEffect(() => {
    if (!isEqual(channels, channelProp)) {
      setChannels(channelProp);
    }
  }, [channelProp]);

  useEffect(() => {
    if (!userId || !organizationId) {
      console.log('Missing userId or organizationId, not opening SSE connection');
      return;
    }
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = null;
      console.log('SSE connection closed');
    }

    if (channels.length === 0) {
      console.log('No channels, not opening SSE connection');
      return;
    }
    eventSourceRef.current = makeEventSource({
      userId,
      organizationId,
      channels,
      eventSourceRef,
      setEvents,
    });

    // Event listener for tab visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // Tab is hidden, close the SSE connection
        eventSourceRef.current?.close();
        eventSourceRef.current = null;
        console.log('SSE connection closed');
      } else if (document.visibilityState === 'visible') {
        // Tab is visible, reconnect the SSE connection
        eventSourceRef.current = makeEventSource({
          userId,
          organizationId,
          channels,
          eventSourceRef,
          setEvents,
        });
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        eventSourceRef.current = null;
        console.log('SSE connection closed');
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      }
    };
  }, [userId, organizationId, channels, eventSourceRef]);

  const subscribe = (channels: SSEChannelSubscriptionRequest[]) => {
    console.log('Subscribing to channels', channels);
    setChannels((prevChannels: SSEChannelSubscriptionRequest[]) =>
      uniqWith([...prevChannels, ...channels], isEqual),
    );
  };

  const unsubscribe = (channels: SSEChannelSubscriptionRequest[]) => {
    console.log('unsubscribing from channels', channels);
    setChannels((prevChannels) =>
      prevChannels.filter(
        (prevChannel) => !channels.some((channel) => isEqual(prevChannel, channel)),
      ),
    );
  };

  return (
    <SSEContext.Provider
      value={{
        events,
        subscribedChannels: channels,
        subscribe,
        unsubscribe,
        connected: eventSourceRef.current?.readyState === EventSource.OPEN,
        flushEvents: () => setEvents([]),
      }}
    >
      {children}
    </SSEContext.Provider>
  );
};
