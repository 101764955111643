// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography, colors} from '@supermove/styles';

// App
import ProjectCommentsSidebar from 'modules/Project/components/ProjectCommentsSidebar';

const Button = Styled.ButtonV2`
  flex-direction: row;
  max-width: 100%;
  align-items: center;
`;

const CommentsCountText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary};
`;

const CommentPreviewText = Styled.Text`
  ${Typography.Link}
`;

const ProjectCommentsButton = ({project, style, sidebar}) => {
  const {mostRecentComment} = project.officeThread;
  const {isEnabledMostRecentCommentPreview} = project.organization.features;

  return (
    <React.Fragment>
      <Button style={style} onPress={() => sidebar.handleOpen()}>
        <CommentsCountText>
          {project.officeThread ? project.officeThread.commentsCount : 0}
        </CommentsCountText>
        <Space width={6} />
        <Icon source={Icon.CommentAlt} color={colors.gray.secondary} size={16} />
        {isEnabledMostRecentCommentPreview && mostRecentComment && (
          <React.Fragment>
            <Space width={8} />
            <CommentPreviewText numberOfLines={1}>{mostRecentComment.subject}</CommentPreviewText>
          </React.Fragment>
        )}
      </Button>
      <ProjectCommentsSidebar
        key={sidebar.key}
        isOpen={sidebar.isOpen}
        handleClose={sidebar.handleClose}
        projectUuid={project.uuid}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectCommentsButton.fragment = gql`
  fragment ProjectCommentsButton on Project {
    id
    uuid
    officeThread {
      id
      commentsCount
      mostRecentComment {
        id
        subject
      }
    }
    organization {
      id
      features {
        isEnabledMostRecentCommentPreview: isEnabled(feature: "MOST_RECENT_COMMENT_PREVIEW")
      }
    }
  }
`;

export default ProjectCommentsButton;
