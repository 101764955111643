// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Popover, ScrollView, Space, Styled} from '@supermove/components';
import {BillRuleForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useHover, useResponsive} from '@supermove/hooks';
import {BillRule} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

const Container = Styled.View`
  height: 300px;
  width: ${(props) => (props.isMobile ? '300px' : '716px')};
  border-width: 1px;
  border-color: ${colors.blue.interactive};
  background-color: ${colors.white};
`;

const ItemContainer = Styled.Touchable`
  background-color: ${(props) => (props.isHovered ? colors.hover : colors.white)};
  flex-direction: row;
  align-items: center;
  margin-vertical: 1px;
  padding-vertical: 4px;
  padding-horizontal: 8px;
`;

const ItemText = Styled.H7`
  color: ${colors.gray.tertiary};
  ${fontWeight(500)}
`;

const ItemTypeText = Styled.H8`
  color: ${colors.blue.interactive};
  ${fontWeight(700)}
  padding-left: 8px;
`;

const ItemCaption = Styled.H8`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  font-style: italic;
  margin-top: 1px;
`;

const handleAddBillRuleForm = ({form, billRuleForm}) => {
  const billRuleForms = _.get(form.values, 'billForm.billRuleForms');
  form.setFieldValue('billForm.billRuleForms', [...billRuleForms, billRuleForm]);
};

const BillRuleTypeItem = ({form, billRuleType, handleClose, handleScrollToEnd}) => {
  const billRuleForm = BillRuleForm.toForm(BillRuleForm.editFromBillRuleType(billRuleType));
  const {ref, isHovered} = useHover();
  const handlePress = async () => {
    handleClose();
    await handleAddBillRuleForm({form, billRuleForm});
    handleScrollToEnd({animated: true});
  };

  return (
    <ItemContainer ref={ref} isHovered={isHovered} onPress={handlePress}>
      <ItemText>
        {`${billRuleForm.name}${
          billRuleType.value ? ` (${BillRuleForm.getDisplayValues(billRuleForm)})` : ''
        }`}
      </ItemText>
    </ItemContainer>
  );
};

const BillRuleTypesList = ({form, billRuleTypes, handleCloseSearch, handleScrollToEnd}) => {
  return (
    <React.Fragment>
      {billRuleTypes.map((billRuleType, index) => {
        return (
          <BillRuleTypeItem
            billRuleType={billRuleType}
            key={index}
            form={form}
            handleClose={handleCloseSearch}
            handleScrollToEnd={handleScrollToEnd}
          />
        );
      })}
    </React.Fragment>
  );
};

const CustomBillRuleItem = ({customBillRuleKind, query, form, handleClose, handleScrollToEnd}) => {
  const {ref, isHovered} = useHover();
  const handlePress = async () => {
    handleClose();
    await handleAddBillRuleForm({
      form,
      billRuleForm: BillRuleForm.toForm({
        kind: customBillRuleKind.value,
        name: query,
        description: '',
        values: [],
        isVisibleToCustomer: true,
        valueFormulaId: null,
      }),
    });
    handleScrollToEnd({animated: true});
  };

  return (
    <ItemContainer ref={ref} isHovered={isHovered} onPress={handlePress} activeOpacity={0.8}>
      <ItemText>{query}</ItemText>
      {!!query && <Space width={8} />}
      <ItemCaption>{customBillRuleKind.label}</ItemCaption>
    </ItemContainer>
  );
};

const CustomBillRulesList = ({
  form,
  query,
  customBillRuleKinds,
  handleCloseSearch,
  handleScrollToEnd,
}) => {
  return (
    <React.Fragment>
      {customBillRuleKinds.map((customBillRuleKind, index) => {
        return (
          <CustomBillRuleItem
            key={index}
            customBillRuleKind={customBillRuleKind}
            query={query}
            form={form}
            handleClose={handleCloseSearch}
            handleScrollToEnd={handleScrollToEnd}
          />
        );
      })}
    </React.Fragment>
  );
};

const AddBillBillRulePopover = ({
  addBillBillRulePopover,
  billRuleTypes,
  form,
  query,
  setQuery,
  setIsSearching,
  handleScrollToEnd,
}) => {
  const handleCloseSearch = () => {
    setQuery('');
    setIsSearching(false);
    addBillBillRulePopover.handleClose();
  };

  const responsive = useResponsive();

  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      key={addBillBillRulePopover.isOpen}
      isOpen={addBillBillRulePopover.isOpen}
      handleOpen={addBillBillRulePopover.handleOpen}
      handleClose={addBillBillRulePopover.handleClose}
      reference={addBillBillRulePopover.ref}
      onComponentDidHide={() => {
        setIsSearching(false);
      }}
      fixed
      offset={[0, 2]}
    >
      <Container isMobile={responsive.mobile}>
        <ScrollView style={{height: 300}}>
          <Space height={12} />
          {billRuleTypes.length > 0 && (
            <React.Fragment>
              <ItemTypeText>Add From Library</ItemTypeText>
              <Space height={2} />
              <BillRuleTypesList
                form={form}
                billRuleTypes={billRuleTypes}
                handleCloseSearch={handleCloseSearch}
                handleScrollToEnd={handleScrollToEnd}
              />
              <Space height={12} />
            </React.Fragment>
          )}
          <ItemTypeText>Add Custom Item</ItemTypeText>
          <Space height={2} />
          <CustomBillRulesList
            form={form}
            query={query}
            customBillRuleKinds={BillRule.KINDS_DROPDOWN_OPTIONS}
            handleCloseSearch={handleCloseSearch}
            handleScrollToEnd={handleScrollToEnd}
          />
          <Space height={4} />
        </ScrollView>
      </Container>
    </Popover>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddBillBillRulePopover.fragment = gql`
  ${BillRuleForm.editFromBillRuleType.fragment}
  fragment AddBillBillRulePopover on BillRuleType {
    id
    value
    ...BillRuleForm_editFromBillRuleType
  }
`;

export default AddBillBillRulePopover;
