// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Badge from '@shared/design/components/Badge';
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Callout from '@shared/design/components/Callout';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import MultiDropdownInput from '@shared/design/components/MultiDropdownInput';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import ProjectTypesForm from '@shared/modules/Project/forms/ProjectTypesForm';
import useUpdateProjectTypeFeaturesTimesheetsV2Mutation from '@shared/modules/Project/hooks/useUpdateProjectTypeFeaturesTimesheetsV2Mutation';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SuperAdminSettingsPage from 'modules/Organization/Settings/SuperAdmin/components/SuperAdminSettingsPage';
import SuperAdminSettingsSection from 'modules/Organization/Settings/SuperAdmin/components/SuperAdminSettingsSection';
import SuperAdminSettingsSectionOption from 'modules/Organization/Settings/SuperAdmin/components/SuperAdminSettingsSectionOption';

const Column = Styled.View`
`;

const ProjectTypesRow = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const SELECT_ALL = 'SELECT_ALL';
const UNSELECT_ALL = 'UNSELECT_ALL';

const getProjectTypeOptions = ({organization, form}) => {
  const projectTypes = organization.projectTypes.filter(
    (projectType) => !projectType.features.timesheetsV2,
  );
  const selections = _.get(form.values, 'projectTypesForm.projectTypeIds');
  const isSelectedAll = selections.length === projectTypes.length;
  return [
    {
      label: (
        <React.Fragment>
          {isSelectedAll ? 'Unselect all' : 'Select all'}
          <Column>
            <Line style={{position: 'absolute', left: -12, bottom: -8, width: 400}} />
          </Column>
        </React.Fragment>
      ),
      value: isSelectedAll ? UNSELECT_ALL : SELECT_ALL,
    },
    ...projectTypes.map((projectType) => ({
      label: projectType.name,
      value: projectType.id,
    })),
  ];
};

const getSelectAllIds = ({organization}) => {
  const projectTypesNotYetEnabled = organization.projectTypes.filter(
    (projectType) => !projectType.features.timesheetsV2,
  );
  return projectTypesNotYetEnabled.map((projectType) => projectType.id);
};

const SelectProjectTypesModal = ({organization, isOpen, handleClose, refetch}) => {
  const {form, submitting, handleSubmit} = useUpdateProjectTypeFeaturesTimesheetsV2Mutation({
    projectTypesForm: ProjectTypesForm.new(),
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <SmallModal isOpen={isOpen} handleClose={handleClose}>
      <SmallModal.HeaderText>Select Project Types</SmallModal.HeaderText>
      <Space height={16} />
      <FieldInput
        {...form}
        name={'projectTypesForm.projectTypeIds'}
        label={'Project Types'}
        component={MultiDropdownInput}
        input={{
          options: getProjectTypeOptions({organization, form}),
          placeholder: 'Select project types',
          setFieldValue: form.setFieldValue,
          onChangeValue: (selections) => {
            if (_.last(selections) === SELECT_ALL) {
              form.setFieldValue(
                'projectTypesForm.projectTypeIds',
                getSelectAllIds({organization}),
              );
            }
            if (_.last(selections) === UNSELECT_ALL) {
              form.setFieldValue('projectTypesForm.projectTypeIds', []);
            }
          },
          style: {flex: 1},
          isPortaled: true,
        }}
      />
      <Space height={16} />
      <SmallModal.Footer isResponsive>
        <React.Fragment>
          <QuaternaryButton
            text={'Cancel'}
            textColor={colors.blue.interactive}
            onPress={handleClose}
            width={104}
          />
          <Space width={8} />
        </React.Fragment>
        <Button
          text={'Save'}
          onPress={handleSubmit}
          isSubmitting={submitting}
          iconLeft={Icon.Check}
          width={104}
        />
      </SmallModal.Footer>
    </SmallModal>
  );
};

const ActionComponent = ({organization, refetch}) => {
  const projectTypesModal = useModal({name: 'Timesheets V2 Project Types Modal'});
  const enabledProjectTypes = organization.projectTypes.filter(
    (projectType) => projectType.features.timesheetsV2,
  );
  const isFullyEnabled = enabledProjectTypes.length === organization.projectTypes.length;

  return (
    <Column>
      <ProjectTypesRow>
        {enabledProjectTypes.map((projectType) => {
          return (
            <Badge
              key={projectType.id}
              label={projectType.name}
              iconLeft={Icon.Circle}
              iconColor={projectType.color}
              style={{marginRight: 8, marginBottom: 8}}
            />
          );
        })}
      </ProjectTypesRow>
      {_.some(enabledProjectTypes) && <Space height={4} />}
      {isFullyEnabled ? (
        <Callout text={'Timesheets V2 has been enabled for all project types.'} />
      ) : (
        <SecondaryButton
          text={'Select Project Types'}
          iconLeft={Icon.Pen}
          isSmall
          onPress={projectTypesModal.handleOpen}
        />
      )}
      <SelectProjectTypesModal
        key={projectTypesModal.key}
        isOpen={projectTypesModal.isOpen}
        handleClose={projectTypesModal.handleClose}
        organization={organization}
        refetch={refetch}
      />
    </Column>
  );
};

const SuperAdminTimesheetsSettingsPageContent = ({organization, refetch}) => {
  return (
    <SuperAdminSettingsSection>
      <SuperAdminSettingsSectionOption
        name={'Enable Timesheets V2'}
        description={
          'Enable the new Timesheets experience that improves performance and includes the "Crew Hours" module for the selected Project Types.'
        }
        isEnabled
        isDisabledToggle
        onPress={() => {}}
        ActionButtonComponent={ActionComponent}
        actionButtonComponentProps={{organization, refetch}}
      />
    </SuperAdminSettingsSection>
  );
};

const SuperAdminTimesheetsSettingsPage = () => {
  const {loading, data, refetch} = useQuery(SuperAdminTimesheetsSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectTypeCategory: ProjectTypeCategory.MOVE,
    },
  });

  return (
    <SuperAdminSettingsPage
      title={'Timesheets'}
      description={
        'Configure Timesheets and any integrated features. These changes will apply to the company, and if applicable, its branches.'
      }
      isLoading={loading}
      viewer={data?.viewer}
      allowedOrganizationKinds={[OrganizationKind.MAIN, OrganizationKind.FRANCHISE]}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return (
            <SuperAdminTimesheetsSettingsPageContent
              organization={data.viewer.viewingOrganization}
              refetch={refetch}
            />
          );
        }}
      </Loading>
    </SuperAdminSettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SuperAdminTimesheetsSettingsPage.query = gql`
  ${SuperAdminSettingsPage.fragment}

  query SuperAdminTimesheetsSettingsPage($projectTypeCategory: String!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        projectTypes: projectTypesForCategory(category: $projectTypeCategory) {
          id
          name
          color
          features {
            timesheetsV2
          }
        }
      }
      ...SuperAdminSettingsPage
    }
  }
`;

export default SuperAdminTimesheetsSettingsPage;
