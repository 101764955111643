/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import TextTooltip from 'modules/App/components/TextTooltip';

const Text = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const IconContainer = Styled.View`
  width: 12px;
  align-items: center;
`;

const JobInventoryWeight = ({job}) => {
  const {weight} = job.project;

  return (
    <Row>
      <TextTooltip text={'Inventory Weight'}>
        <IconContainer>
          <Icon
            color={colors.gray.primary}
            size={10}
            source={Icon.WeightHanging}
            style={{marginTop: 2}}
          />
        </IconContainer>
      </TextTooltip>
      <Space width={6} />
      <Text color={colors.gray.secondary}>
        {weight > 0 ? pluralize('lb', weight, true) : 'N/A'}
      </Text>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobInventoryWeight.fragment = gql`
  fragment JobInventoryWeight on Job {
    id
    project {
      id
      weight
    }
  }
`;

export default JobInventoryWeight;
