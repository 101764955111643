// Libraries
import React from 'react';

// App
import BlueCheckboxField from 'modules/App/components/BlueCheckboxField';

const InputIsRequiredCheckBox = ({
  documentItem,
  documentItemIndex,
  setDraftDocumentContentJson,
}) => {
  return (
    <React.Fragment>
      <BlueCheckboxField
        values={{isRequired: !!documentItem.isRequired}}
        name={'isRequired'}
        label={'Required input(s)'}
        setFieldValue={(name, value) => {
          setDraftDocumentContentJson((prevState) => {
            prevState.documentItems[documentItemIndex].isRequired = value;
            return {
              ...prevState,
            };
          });
        }}
      />
    </React.Fragment>
  );
};

export default InputIsRequiredCheckBox;
