// Supermove
import {gql} from '@supermove/graphql';
import {Currency, Float, Percent, pluralize, withFragment} from '@supermove/utils';

import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemTypeKind from '@shared/modules/Billing/enums/BillItemTypeKind';
import BillItemUnit from '@shared/modules/Billing/enums/BillItemUnit';
import BillStage from '@shared/modules/Billing/enums/BillStage';

const FEE_HOUR = {
  category: BillItemTypeCategory.FEES,
  kind: BillItemTypeKind.AMOUNT,
  billStage: BillStage.PRE_SUBTOTAL,
  unit: BillItemUnit.HOUR,
  name: 'Hourly Rate',
  displayName: 'Hourly Rate',
};
const FEE_AMOUNT_PRE_SUBTOTAL = {
  category: BillItemTypeCategory.FEES,
  kind: BillItemTypeKind.AMOUNT,
  billStage: BillStage.PRE_SUBTOTAL,
  unit: null,
  name: 'Flat Rate',
  displayName: 'Flat Rate',
};
const SUPPLY = {
  category: BillItemTypeCategory.SUPPLIES,
  kind: BillItemTypeKind.AMOUNT,
  billStage: BillStage.PRE_SUBTOTAL,
  unit: null,
  name: 'Supplies',
  displayName: 'Supplies',
};
const DISCOUNT_AMOUNT_PRE_SUBTOTAL = {
  category: BillItemTypeCategory.DISCOUNTS,
  kind: BillItemTypeKind.AMOUNT,
  billStage: BillStage.PRE_SUBTOTAL,
  unit: null,
  name: 'Discount',
  displayName: 'Discount',
};
const FEE_AMOUNT_POST_SUBTOTAL = {
  category: BillItemTypeCategory.FEES,
  kind: BillItemTypeKind.AMOUNT,
  billStage: BillStage.POST_SUBTOTAL,
  unit: null,
  name: 'Flat Fee',
  displayName: 'Flat Fee (After Subtotal)',
};
const FEE_PERCENT = {
  category: BillItemTypeCategory.FEES,
  kind: BillItemTypeKind.PERCENTAGE,
  billStage: BillStage.POST_SUBTOTAL,
  unit: null,
  name: 'Percent Fee',
  displayName: 'Percent Fee (After Subtotal)',
};
const DISCOUNT_AMOUNT_POST_SUBTOTAL = {
  category: BillItemTypeCategory.DISCOUNTS,
  kind: BillItemTypeKind.AMOUNT,
  billStage: BillStage.POST_SUBTOTAL,
  unit: null,
  name: 'Flat Discount',
  displayName: 'Flat Discount (After Subtotal)',
};
const DISCOUNT_PERCENT = {
  category: BillItemTypeCategory.DISCOUNTS,
  kind: BillItemTypeKind.PERCENTAGE,
  billStage: BillStage.POST_SUBTOTAL,
  unit: null,
  name: 'Percent Discount',
  displayName: 'Percent Discount (After Subtotal)',
};
const CUSTOM_BILL_ITEM_VALUES = {
  FEE_HOUR,
  FEE_AMOUNT_PRE_SUBTOTAL,
  SUPPLY,
  DISCOUNT_AMOUNT_PRE_SUBTOTAL,
  FEE_AMOUNT_POST_SUBTOTAL,
  FEE_PERCENT,
  DISCOUNT_AMOUNT_POST_SUBTOTAL,
  DISCOUNT_PERCENT,
};
const CUSTOM_BILL_ITEMS = [
  FEE_HOUR,
  FEE_AMOUNT_PRE_SUBTOTAL,
  SUPPLY,
  DISCOUNT_AMOUNT_PRE_SUBTOTAL,
  FEE_AMOUNT_POST_SUBTOTAL,
  FEE_PERCENT,
  DISCOUNT_AMOUNT_POST_SUBTOTAL,
  DISCOUNT_PERCENT,
];
const FILTERS = [
  FEE_HOUR,
  FEE_AMOUNT_PRE_SUBTOTAL,
  SUPPLY,
  DISCOUNT_AMOUNT_PRE_SUBTOTAL,
  FEE_AMOUNT_POST_SUBTOTAL,
  FEE_PERCENT,
  DISCOUNT_AMOUNT_POST_SUBTOTAL,
  DISCOUNT_PERCENT,
];

const getDisplayPrice = withFragment(
  (billItem) => {
    if (billItem.kind === BillItemTypeKind.PERCENTAGE) {
      return Percent.display(billItem.percentage);
    }
    const formattedAmount = Currency.display(billItem.amount);
    if (billItem.unit === BillItemUnit.HOUR) {
      return `${formattedAmount} / hour`;
    }
    return formattedAmount;
  },
  gql`
    fragment BillItem_getDisplayPrice on BillItem {
      id
      kind
      unit
      percentage
      amount
    }
  `,
);

const getEstimateQuantity = withFragment(
  (billItem) => {
    if (!billItem.minQuantity && !billItem.maxQuantity) {
      return 'TBD';
    }

    const hasDifferentQuantityTwo =
      !!billItem.maxQuantity &&
      Float.toFloat(billItem.maxQuantity) > Float.toFloat(billItem.minQuantity);
    const text = hasDifferentQuantityTwo
      ? `${billItem.minQuantity} - ${billItem.maxQuantity}`
      : billItem.minQuantity;
    switch (billItem.unit) {
      case BillItemUnit.HOUR:
        if (hasDifferentQuantityTwo) {
          return `${text} ${pluralize(billItem.unit, billItem.maxQuantity)}`;
        }
        return `${text} ${pluralize(billItem.unit, billItem.minQuantity)}`;
      default:
        return text;
    }
  },
  gql`
    fragment BillItem_getEstimateQuantity on BillItem {
      id
      kind
      minQuantity
      maxQuantity
      unit
    }
  `,
);

const getEstimateTotal = withFragment(
  (billItem) => {
    if (!billItem.totalMin && !billItem.totalMax) {
      return 'TBD';
    }

    return Currency.formatRange({
      min: billItem.totalMin,
      max: billItem.totalMax,
    });
  },
  gql`
    fragment BillItem_getEstimateTotal on BillItem {
      id
      totalMin
      totalMax
    }
  `,
);

const getEstimateTotalPostSubtotal = withFragment(
  (billItem, {isEstimateAvailable}) => {
    if (isEstimateAvailable || billItem.kind === BillItemTypeKind.AMOUNT) {
      return Currency.formatRange({
        min: billItem.totalMin,
        max: billItem.totalMax,
      });
    }
    return 'TBD';
  },
  gql`
    fragment BillItem_getEstimateTotalPostSubtotal on BillItem {
      id
      kind
      totalMin
      totalMax
    }
  `,
);

const BillItem = {
  LINE_ITEM: 'LINE_ITEM',
  BILL_MODIFIER: 'BILL_MODIFIER',

  getDisplayPrice,
  getEstimateQuantity,
  getEstimateTotal,
  getEstimateTotalPostSubtotal,

  CUSTOM_BILL_ITEM_VALUES,
  CUSTOM_BILL_ITEMS,
  FILTERS,
};

export default BillItem;
