// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ValuationCoverageForm from '@shared/modules/Insurance/forms/ValuationCoverageForm';

// App

const useUpdateValuationCoverageForm = ({valuationCoverageForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      valuationCoverageForm: ValuationCoverageForm.toForm(valuationCoverageForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateValuationCoverageForm.mutation,
    variables: {
      valuationCoverageForm: ValuationCoverageForm.toMutation(form.values.valuationCoverageForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateValuationCoverageForm.mutation = gql`
  mutation useUpdateValuationCoverageForm($valuationCoverageForm: ValuationCoverageForm!) {
    response: updateValuationCoverage(valuationCoverageForm: $valuationCoverageForm) {
      ${gql.errors}
      valuationCoverage {
        id
      }
    }
  }
`;

export default useUpdateValuationCoverageForm;
