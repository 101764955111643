// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useMarkTextMessagesReadMutation = ({userId, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      userId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useMarkTextMessagesReadMutation.mutation,
    variables: {
      userId: form.values.userId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useMarkTextMessagesReadMutation.mutation = gql`
  mutation useMarkTextMessagesReadMutation($userId: Int!) {
    response: markTextMessagesRead(userId: $userId) {
      ${gql.errors}
    }
  }
`;

export default useMarkTextMessagesReadMutation;
