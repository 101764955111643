// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectClientsForm from '@shared/modules/Project/forms/ProjectClientsForm';

const useUpdateProjectClientsMutation = ({projectClientsForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      projectClientsForm: ProjectClientsForm.toForm(projectClientsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectClientsMutation.mutation,
    variables: {
      projectClientsForm: ProjectClientsForm.toMutation(form.values.projectClientsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateProjectClientsMutation.mutation = gql`
  ${ProjectClientsForm.edit.fragment}
  mutation useUpdateProjectClientsMutation($projectClientsForm: ProjectClientsForm!) {
    response: updateProjectClients(projectClientsForm: $projectClientsForm) {
      ${gql.errors}
      project {
        id
        ...ProjectClientsForm_edit
      }
    }
  }
`;

export default useUpdateProjectClientsMutation;
