// Libraries
import _ from 'lodash';

const _new = ({ids, property, intValue}) => ({
  ids,
  property,
  intValue,
});

const toForm = ({ids, property, intValue}) => ({
  ids,
  property,
  intValue,
});

const toMutation = ({ids, property, intValue}) => ({
  ids: ids.map((id) => _.toNumber(id)),
  property,
  intValue,
});

const BulkUpdateIntPropertyForm = {
  new: _new,
  toForm,
  toMutation,
};

export default BulkUpdateIntPropertyForm;
