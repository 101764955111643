// Librairies
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import OrganizationForm from '@shared/modules/Organization/forms/OrganizationForm';

const edit = withFragment(
  (organization, {organizationKind} = {}) => {
    const {organizations} = organization.company;
    const filteredOrganizations = organizationKind
      ? organizations.filter((org) => org.kind === organizationKind)
      : organizations;
    const editableOrganizations = organization.isPrimary
      ? filteredOrganizations
      : filteredOrganizations.filter((org) => org.id === organization.id);
    const orderedOrganizations = _.orderBy(
      editableOrganizations,
      [(organization) => organization.name.toLowerCase()],
      ['asc'],
    );

    return {
      organizationId: organization.id,
      organizationForms: orderedOrganizations.map((org) => OrganizationForm.edit(org)),
    };
  },
  gql`
    ${OrganizationForm.edit.fragment}
    fragment OrganizationForm_edit on Organization {
      id
      isPrimary
      company {
        id
        organizations {
          id
          kind
          ...OrganizationForm_edit
        }
      }
    }
  `,
);

const toForm = ({organizationId, organizationForms}) => ({
  organizationId,
  organizationForms: organizationForms.map((organizationForm) =>
    OrganizationForm.toForm(organizationForm),
  ),
});

const toMutation = ({organizationId, organizationForms}) => ({
  organizationId,
  organizationForms: organizationForms.map((organizationForm) =>
    OrganizationForm.toMutation(organizationForm),
  ),
});

const CompanyOrganizationsForm = {
  edit,
  toForm,
  toMutation,
};

export default CompanyOrganizationsForm;
