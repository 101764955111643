// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ClaimTypeForm from '@shared/modules/Claim/forms/ClaimTypeForm';

const useDeleteClaimTypeMutation = ({claimTypeForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      claimTypeForm: ClaimTypeForm.toForm(claimTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteClaimTypeMutation.mutation,
    variables: {
      claimTypeForm: ClaimTypeForm.toMutation(form.values.claimTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteClaimTypeMutation.mutation = gql`
  mutation useDeleteClaimTypeMutation($claimTypeForm: ClaimTypeForm!) {
    response: deleteClaimType(claimTypeForm: $claimTypeForm) {
      ${gql.errors}
      claimType {
        id
      }
    }
  }
`;

export default useDeleteClaimTypeMutation;
