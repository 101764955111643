// Libraries
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Organization, Payment} from '@supermove/models';

// Components
import FieldInput from '@shared/design/components/Field/FieldInput';

const FieldsContainer = Styled.View`
  z-index: 100;
`;

const RecordPaymentFields = ({form, field, organization}) => {
  const paymentMethods = Organization.getOfficePaymentMethods(organization);
  const options = Payment.getMethodDropdownOptions({methods: paymentMethods});

  return (
    <FieldsContainer>
      <FieldInput
        {...form}
        index={0}
        name={`${field}.name`}
        label={'Payment Name'}
        isRequired
        input={{
          placeholder: 'Payment for Invoice',
          autoFocus: true,
        }}
      />
      <Space height={8} />
      <FieldInput
        {...form}
        index={1}
        name={`${field}.amount`}
        label={'Payment Amount'}
        isRequired
        input={{
          placeholder: 'Payment amount',
          style: {width: '100%'},
        }}
      />
      <Space height={8} />
      <FieldInput
        {...form}
        index={2}
        component={DropdownInput}
        name={`${field}.method`}
        label={'Payment Method'}
        isRequired
        input={{
          options,
          placeholder: 'Choose payment method',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
        }}
      />
      <Space height={8} />
      <FieldInput
        {...form}
        index={3}
        name={`${field}.description`}
        label={'Payment Description'}
        input={{
          style: {height: 76, paddingTop: 8},
          multiline: true,
          placeholder: 'Enter payment description',
        }}
      />
    </FieldsContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RecordPaymentFields.fragment = gql`
  ${Organization.getOfficePaymentMethods.fragment}
  fragment RecordPaymentFields on Organization {
    id
    ...Organization_getOfficePaymentMethods
  }
`;

export default RecordPaymentFields;
