// Supermove
import {JobUserForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (crew) => ({
    crewId: crew.id,
    jobUserForms: crew.jobUsers.map((jobUser) => JobUserForm.edit(jobUser)),
  }),
  gql`
    ${JobUserForm.edit.fragment}

    fragment AssignUsersToCrewForm_edit on Crew {
      id
      jobUsers {
        id
        ...JobUserForm_edit
      }
    }
  `,
);

const toForm = ({crewId, jobUserForms}) => ({
  crewId,
  jobUserForms: jobUserForms.map((jobUserForm) => JobUserForm.toForm(jobUserForm)),
});

const toMutation = ({crewId, jobUserForms}) => ({
  crewId,
  jobUserForms: jobUserForms.map((jobUserForm) => JobUserForm.toMutation(jobUserForm)),
});

const AssignUsersToCrewForm = {
  // Initialize
  edit,

  // Conversion
  toForm,
  toMutation,
};

export default AssignUsersToCrewForm;
