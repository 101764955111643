// Supermove
import {gql} from '@supermove/graphql';
import {Currency, withFragment} from '@supermove/utils';

// App
import BillTipForm, {
  FormikForm as BillTipFormikForm,
  GraphQLForm as BillTipGraphQLForm,
  New as NewBillTipForm,
} from '@shared/modules/Billing/forms/BillTipForm';
import ProjectTypePaymentMethod from '@shared/modules/Project/enums/ProjectTypePaymentMethod';

type Constructor = {
  billId?: number;
  invoiceId?: number;
  jobId?: number;
  customerId?: number;
  amount: number;
  name: string;
  description?: string;
  method?: string;
  tipName?: string;
  tipAmount?: number;
  shouldErrorOnDuplicatePayment: boolean;
};

export type New = {
  billId: number | null;
  invoiceId: number | null;
  jobId: number | null;
  customerId?: number;
  amount: number;
  name: string;
  description: string;
  method?: string;
  billTipForm: NewBillTipForm;
  shouldErrorOnDuplicatePayment: boolean;
};

const _new = ({
  billId,
  invoiceId,
  jobId,
  customerId,
  amount,
  name,
  description,
  method,
  tipAmount,
  tipName,
  shouldErrorOnDuplicatePayment,
}: Constructor): New => ({
  billId: billId || null,
  invoiceId: invoiceId || null,
  jobId: jobId || null,
  customerId,
  amount,
  name,
  description: description || '',
  method,
  billTipForm: BillTipForm.new({billId, name: tipName, amount: tipAmount, jobId}),
  shouldErrorOnDuplicatePayment,
});

interface Invoice {
  id: number;
  identifier: string;
  remainingBalance: number;
  billingClient: {
    id: number;
    primaryContact: {
      id: number;
    };
  };
  project: {
    id: number;
    billingClient: {
      id: number;
      primaryContact: {
        id: number;
      };
    };
    currentPrimaryBill: {
      id: number;
    };
  };
}

const forInvoice = withFragment(
  // @ts-ignore
  (invoice: Invoice, {method}: {method: typeof ProjectTypePaymentMethod}) => {
    const client = invoice.project.billingClient;
    const billId = invoice.project.currentPrimaryBill.id;

    return {
      invoiceId: invoice.id,
      billId,
      customerId: client.primaryContact.id,
      name: `Payment for Invoice ${invoice.identifier}`,
      amount: invoice.remainingBalance > 0 ? invoice.remainingBalance : 0,
      method,
      description: '',
      billTipForm: BillTipForm.new({billId, name: '', amount: 0}),
      shouldErrorOnDuplicatePayment: true,
    };
  },
  gql`
    fragment BeginPaymentV3Form_forInvoice on Invoice {
      id
      identifier
      remainingBalance
      project {
        id
        billingClient {
          id
          primaryContact {
            id
          }
        }
        currentPrimaryBill {
          id
        }
      }
    }
  `,
);

export type FormikForm = {
  billId: number | null;
  invoiceId: number | null;
  customerId?: number;
  amount: string;
  name: string;
  description: string;
  method?: string;
  billTipForm: BillTipFormikForm;
  shouldErrorOnDuplicatePayment: boolean;
};

const toForm = ({
  billId,
  invoiceId,
  customerId,
  name,
  description,
  amount,
  method,
  billTipForm,
  shouldErrorOnDuplicatePayment,
}: New): FormikForm => ({
  billId: billId || null,
  invoiceId: invoiceId || null,
  customerId,
  amount: Currency.toForm(amount),
  name,
  description,
  method,
  billTipForm: BillTipForm.toForm(billTipForm),
  shouldErrorOnDuplicatePayment,
});

export type GraphQLForm = {
  billId: number | null;
  invoiceId: number | null;
  customerId?: number;
  amount: number;
  name: string;
  description: string;
  method?: string;
  billTipForm: BillTipGraphQLForm;
  shouldErrorOnDuplicatePayment: boolean;
};

const toMutation = ({
  billId,
  invoiceId,
  customerId,
  name,
  description,
  amount,
  method,
  billTipForm,
  shouldErrorOnDuplicatePayment,
}: FormikForm): GraphQLForm => ({
  billId,
  invoiceId,
  customerId,
  amount: Currency.toMutation(amount),
  name,
  description,
  method,
  billTipForm: BillTipForm.toMutation(billTipForm),
  shouldErrorOnDuplicatePayment,
});

const BeginPaymentV3Form = {
  new: _new,
  forInvoice,
  toForm,
  toMutation,
};

export default BeginPaymentV3Form;
