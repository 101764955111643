// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useEffect, useHover, useModal, useResponsive, useState} from '@supermove/hooks';
import {BillItem} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import EditBillItemAndValuesDrawer from 'modules/Project/Billing/components/EditBillItemAndValuesDrawer';
import FieldValueExpandable from 'modules/Project/V2/Show/Blocks/components/FieldValueExpandable';
import ConfirmInvoiceEditModal from 'modules/Storage/components/ConfirmInvoiceEditModal';

const Row = Styled.View`
  flex-direction: row;
`;

const PressableRow = Styled.ButtonV2`
  flex-direction: row;
`;

const SplitRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const FlexContainer = Styled.View`
  flex: ${(props) => props.flex};
`;

const CaptionText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${(props) => (props.color ? props.color : colors.gray.secondary)};
`;

const NameContainer = Styled.View`
  flex: 4;
  flex-direction: row;
`;

const IconContainer = Styled.View`
  padding-top: 4px;
  flex-direction: row;
  min-width: 16px;
  flex: 1;
`;

const BillItemName = Styled.Text`
  ${Typography.Body3}
  color: ${({color}) => color};
`;

const BillItemDetailContainer = Styled.View`
  align-items: flex-end;
  flex: ${(props) => props.flex};
`;

const BillItemDetailText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${(props) => props.color};
  text-align: right;
`;

const MicroLabelText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const QuestionIconCircle = Styled.View`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${colors.orange.status};
  align-items: center;
  justify-content: center;
`;

const ListEmptyMessageContainer = Styled.View`
  flex-direction: row;
  background-color: ${colors.gray.background};
  border-radius: 4px;
  padding: 8px;
  align-items: center;
`;

const ListEmptyActionText = Styled.Text`
  ${Typography.Body5}
  color: ${colors.orange.status};
`;

const BillItemDetail = ({icon, children, color, flex, responsive}) => {
  return (
    <BillItemDetailContainer flex={flex}>
      <Row>
        {icon}
        <BillItemDetailText color={color} responsive={responsive}>
          {children}
        </BillItemDetailText>
      </Row>
    </BillItemDetailContainer>
  );
};

const DiscountTag = () => {
  return (
    <React.Fragment>
      <Icon
        source={Icon.Tag}
        size={10}
        // TODO(dan) Find a cross platform solution for rotating icons
        style={{transform: 'rotate(90deg)', alignSelf: 'center'}}
        color={colors.green.status}
      />
      <Space width={4} />
    </React.Fragment>
  );
};

const QuantityColumn = ({billItem, isHighlighted, isDocument, isHovered}) => {
  const quantity = BillItem.getEstimateQuantity(billItem);
  const responsive = useResponsive();
  if (!responsive.desktop && !isDocument) {
    return <MicroText responsive={responsive}>{quantity}</MicroText>;
  }
  return (
    <BillItemDetail
      responsive={responsive}
      color={
        isHovered
          ? colors.blue.interactive
          : isHighlighted
            ? colors.gray.secondary
            : colors.gray.secondary
      }
      flex={2}
    >
      {quantity}
    </BillItemDetail>
  );
};

const PriceColumn = ({billItem, isTotalVisible, isHighlighted, isDocument, isHovered}) => {
  const responsive = useResponsive();
  const price = BillItem.getDisplayPrice(billItem);
  if (responsive.mobile && !isDocument) {
    return <MicroText responsive={responsive}>{price}</MicroText>;
  }
  return (
    <BillItemDetail
      color={
        isHovered
          ? colors.blue.interactive
          : isHighlighted
            ? colors.gray.secondary
            : colors.gray.secondary
      }
      flex={isTotalVisible ? 2 : 3}
      responsive={responsive}
    >
      {price}
    </BillItemDetail>
  );
};

const TotalColumn = ({billItem, isDocument, isHovered}) => {
  const responsive = useResponsive();
  const total = BillItem.getEstimateTotal(billItem);
  const totalColor = isHovered
    ? colors.blue.interactive
    : billItem.isCredit
      ? colors.green.status
      : colors.gray.primary;
  if (responsive.mobile && !isDocument) {
    return (
      <React.Fragment>
        {billItem.isCredit && <DiscountTag />}
        <MicroText style={{color: totalColor}} responsive={responsive}>
          {total}
        </MicroText>
      </React.Fragment>
    );
  }
  return (
    <BillItemDetail
      responsive={responsive}
      flex={3}
      color={totalColor}
      icon={billItem.isCredit ? <DiscountTag /> : null}
    >
      {total}
    </BillItemDetail>
  );
};

const FlexSpaceHolder = ({isQuantityVisible, isTotalVisible}) => {
  // This fills any additional space created from fields being disabled.
  // For every field we take away we add to the flex value to evenly compensate.
  let totalFlexSpace = 0;
  if (!isQuantityVisible) {
    totalFlexSpace += 2;
  }
  if (!isTotalVisible) {
    totalFlexSpace += 2;
  }
  return <BillItemDetailContainer flex={totalFlexSpace} />;
};

const Description = ({description}) => {
  return (
    <Row>
      <FlexContainer flex={5}>
        {!!description && <Space height={2} />}
        <CaptionText color={colors.gray.secondary}>{description}</CaptionText>
      </FlexContainer>
      <FlexContainer flex={6} />
    </Row>
  );
};

const MobileDescription = ({
  billItem,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
}) => {
  const billItemDescription = _.get(billItem, descriptionField);
  if (!billItemDescription) {
    return null;
  }

  return (
    <React.Fragment>
      <Space height={4} />
      <FieldValueExpandable
        data={billItem}
        property={descriptionField}
        numberOfLines={4}
        size={FieldValue.SIZE.MICRO}
        isResponsive
        style={{flex: undefined}}
        valueStyle={{color: colors.gray.secondary}}
        isTruncated={
          truncated[getTruncatedKey ? getTruncatedKey({billItemId: billItem.id}) : descriptionField]
        }
        isEnabledToggle={
          enabledToggle[
            getTruncatedKey ? getTruncatedKey({billItemId: billItem.id}) : descriptionField
          ]
        }
        setTruncated={setTruncated}
        setEnabledToggle={setEnabledToggle}
        truncatedKey={getTruncatedKey ? getTruncatedKey({billItemId: billItem.id}) : undefined}
      />
    </React.Fragment>
  );
};

const MobileDescriptionWithState = ({billItem}) => {
  const descriptionField = 'description';
  const [truncated, setTruncated] = useState({[descriptionField]: true});
  const [enabledToggle, setEnabledToggle] = useState({[descriptionField]: false});

  const billItemDescription = _.get(billItem, descriptionField);
  if (!billItemDescription) {
    return null;
  }

  return (
    <MobileDescription
      billItem={billItem}
      descriptionField={descriptionField}
      truncated={truncated}
      setTruncated={setTruncated}
      enabledToggle={enabledToggle}
      setEnabledToggle={setEnabledToggle}
    />
  );
};

const MobileName = ({name, responsive, isDisabled, onPress}) => (
  <PressableRow disabled={isDisabled} onPress={onPress}>
    <MicroLabelText style={{color: colors.gray.primary}} responsive={responsive}>
      {name}
    </MicroLabelText>
    <Space style={{flex: 1, minWidth: 8}} />
    <Icon
      source={Icon.Pen}
      color={isDisabled ? colors.gray.disabled : colors.blue.interactive}
      size={12}
      style={{marginTop: 2}}
    />
  </PressableRow>
);

const possiblyOpenEditBillItemAndValuesDrawer = ({
  invoice,
  confirmInvoiceEditModal,
  editBillItemAndValuesDrawer,
}) => {
  if (invoice.status === InvoiceStatus.PAID) {
    confirmInvoiceEditModal.handleOpen();
  } else {
    editBillItemAndValuesDrawer.handleOpen();
  }
};

const BillBillItem = ({
  bill,
  refetch,
  isDocument,
  isEditable,
  showQuantity,
  showTotal,
  billItem,
  isHighlighted,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  isEnabledBillItemDescriptionOnMobile,
}) => {
  const isInvoiceFinalized = bill.project.mainMoveInvoice.status === InvoiceStatus.FINALIZED;
  const isQuantityVisible = isEditable || showQuantity;
  const isTotalVisible = isEditable || showTotal;
  const responsive = useResponsive();
  const {ref, isHovered} = useHover();
  const editBillItemAndValuesDrawer = useDrawer({name: 'Edit Bill Item and Values Drawer'});
  const confirmInvoiceEditModal = useModal({name: 'Confirm Invocie Edit Modal'});
  const billItemForm = BillItemForm.toForm(BillItemForm.edit(billItem));
  const [isBillItemHovered, setIsBillItemHovered] = useState(false);
  useEffect(
    () => setIsBillItemHovered(isEditable && isHovered && !isInvoiceFinalized),
    [isHovered, isEditable, isInvoiceFinalized],
  );

  return (
    <React.Fragment>
      {!responsive.desktop && !isDocument ? (
        <React.Fragment>
          <MobileName
            isDisabled={isInvoiceFinalized}
            name={billItem.name}
            responsive={responsive}
            onPress={() =>
              possiblyOpenEditBillItemAndValuesDrawer({
                invoice: bill.project.mainMoveInvoice,
                confirmInvoiceEditModal,
                editBillItemAndValuesDrawer,
              })
            }
          />
          <Space height={4} />
          <SplitRow>
            <Row>
              {isQuantityVisible && (
                <React.Fragment>
                  <QuantityColumn
                    billItem={billItem}
                    isHighlighted={isHighlighted}
                    isDocument={isDocument}
                  />
                  <Space width={4} />
                  <Icon
                    source={Icon.Times}
                    color={colors.gray.secondary}
                    size={8}
                    style={{alignSelf: 'center'}}
                  />
                  <Space width={4} />
                </React.Fragment>
              )}
              <PriceColumn
                billItem={billItem}
                isTotalVisible={isTotalVisible}
                isHighlighted={isHighlighted}
                isDocument={isDocument}
              />
            </Row>
            <Row>
              {isTotalVisible && <TotalColumn billItem={billItem} isDocument={isDocument} />}
            </Row>
          </SplitRow>
          {isEnabledBillItemDescriptionOnMobile &&
            (setTruncated ? (
              <MobileDescription
                billItem={billItem}
                descriptionField={descriptionField}
                getTruncatedKey={getTruncatedKey}
                truncated={truncated}
                setTruncated={setTruncated}
                enabledToggle={enabledToggle}
                setEnabledToggle={setEnabledToggle}
              />
            ) : (
              <MobileDescriptionWithState billItem={billItem} />
            ))}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <PressableRow
            ref={ref}
            disabled={isInvoiceFinalized}
            onPress={() =>
              possiblyOpenEditBillItemAndValuesDrawer({
                invoice: bill.project.mainMoveInvoice,
                confirmInvoiceEditModal,
                editBillItemAndValuesDrawer,
              })
            }
          >
            <NameContainer>
              <BillItemName
                color={isBillItemHovered ? colors.blue.interactive : colors.gray.primary}
              >
                {billItem.name}
              </BillItemName>
              <IconContainer>
                <Space width={4} />
                {isBillItemHovered && (
                  <Icon source={Icon.Pen} color={colors.blue.interactive} size={12} />
                )}
              </IconContainer>
            </NameContainer>
            <FlexSpaceHolder
              isQuantityVisible={isQuantityVisible}
              isTotalVisible={isTotalVisible}
            />
            {isQuantityVisible && (
              <QuantityColumn
                billItem={billItem}
                isHighlighted={isHighlighted}
                isDocument={isDocument}
                isHovered={isBillItemHovered}
              />
            )}
            <PriceColumn
              billItem={billItem}
              isTotalVisible={isTotalVisible}
              isHighlighted={isHighlighted}
              isDocument={isDocument}
              isHovered={isBillItemHovered}
            />
            {isTotalVisible && (
              <TotalColumn
                billItem={billItem}
                isDocument={isDocument}
                isHovered={isBillItemHovered}
              />
            )}
          </PressableRow>
          <Description description={billItem.description} />
        </React.Fragment>
      )}
      <EditBillItemAndValuesDrawer
        key={editBillItemAndValuesDrawer.key}
        isOpen={editBillItemAndValuesDrawer.isOpen}
        handleClose={editBillItemAndValuesDrawer.handleClose}
        refetch={refetch}
        bill={bill}
        billItemForm={billItemForm}
      />
      <ConfirmInvoiceEditModal
        key={confirmInvoiceEditModal.key}
        invoice={bill.project.mainMoveInvoice}
        handleAction={editBillItemAndValuesDrawer.handleOpen}
        isOpen={confirmInvoiceEditModal.isOpen}
        handleClose={confirmInvoiceEditModal.handleClose}
      />
    </React.Fragment>
  );
};

const NoBillItemsActionMessage = () => {
  return (
    <ListEmptyMessageContainer>
      <QuestionIconCircle>
        <Icon source={Icon.Question} color={colors.white} size={7} />
      </QuestionIconCircle>
      <Space width={8} />
      <ListEmptyActionText>Press 'Edit' to start adding items to this bill</ListEmptyActionText>
    </ListEmptyMessageContainer>
  );
};

const NoBillItemsPlaceholderMessage = () => {
  return (
    <ListEmptyMessageContainer>
      <CaptionText color={colors.gray.tertiary}>No items added to this bill yet.</CaptionText>
    </ListEmptyMessageContainer>
  );
};

const BillsListItemPreSubtotalBillItemsList = ({
  bill,
  refetch,
  isDocument,
  isEditable,
  isHighlighted,
  showQuantity,
  showTotal,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  isEnabledBillItemDescriptionOnMobile,
}) => {
  return (
    <React.Fragment>
      {bill.billItemsPreSubtotal.map((billItem, index) => {
        return (
          <React.Fragment key={`BILL_ITEM-${index}`}>
            {index > 0 && <Space height={8} />}
            <BillBillItem
              bill={bill}
              refetch={refetch}
              isDocument={isDocument}
              isEditable={isEditable}
              isHighlighted={isHighlighted}
              showQuantity={showQuantity}
              showTotal={showTotal}
              billItem={billItem}
              descriptionField={descriptionField}
              getTruncatedKey={getTruncatedKey}
              truncated={truncated}
              setTruncated={setTruncated}
              enabledToggle={enabledToggle}
              setEnabledToggle={setEnabledToggle}
              isEnabledBillItemDescriptionOnMobile={isEnabledBillItemDescriptionOnMobile}
            />
          </React.Fragment>
        );
      })}
      {_.isEmpty(bill.billItemsPreSubtotal) && isEditable && <NoBillItemsActionMessage />}
      {_.isEmpty(bill.billItemsPreSubtotal) && !isEditable && <NoBillItemsPlaceholderMessage />}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillsListItemPreSubtotalBillItemsList.fragment = gql`
  ${BillItem.getDisplayPrice.fragment}
  ${BillItem.getEstimateQuantity.fragment}
  ${BillItem.getEstimateTotal.fragment}
  ${BillItemForm.edit.fragment}
  ${ConfirmInvoiceEditModal.fragment}
  ${EditBillItemAndValuesDrawer.fragment}

  fragment BillsListItemPreSubtotalBillItemsList on Bill {
    id
    billItemsPreSubtotal {
      id
      name
      description
      isCredit
      ...BillItem_getDisplayPrice
      ...BillItem_getEstimateQuantity
      ...BillItem_getEstimateTotal
      ...BillItemForm_edit
    }
    project {
      id
      mainMoveInvoice {
        id
        status
        ...ConfirmInvoiceEditModal
      }
    }
    ...EditBillItemAndValuesDrawer
  }
`;

export default BillsListItemPreSubtotalBillItemsList;
