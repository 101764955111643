// App
import TimesheetBlockKind from '@shared/modules/Timesheet/enums/TimesheetBlockKind';
import TimesheetBlockForm from '@shared/modules/Timesheet/forms/TimesheetBlockForm';

const _new = ({rangeFrom, rangeFromDate} = {}) => ({
  kind: TimesheetBlockKind.WORK,
  rangeFrom: rangeFrom || '',
  rangeTo: '',

  // Private
  isFixedRangeFrom: !!rangeFrom,
  rangeFromDate,
});

const toForm = ({kind, rangeFrom, rangeTo, isFixedRangeFrom, rangeFromDate}) => ({
  kind,
  rangeFrom: TimesheetBlockForm.getFormRangeTime({datetime: rangeFrom}),
  rangeTo: TimesheetBlockForm.getFormRangeTime({datetime: rangeTo}),

  // Private
  isFixedRangeFrom,
  rangeFromDate,
});

const toMutation = ({kind, rangeFrom, rangeTo, rangeFromDate}) => {
  return {
    kind,
    rangeFrom: TimesheetBlockForm.getMutationRangeFrom({date: rangeFromDate, rangeFrom}),
    rangeTo: TimesheetBlockForm.getMutationRangeTo({date: rangeFromDate, rangeFrom, rangeTo}),
  };
};

const CreateTimesheetBlockForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CreateTimesheetBlockForm;
