// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import UpdateTimesheetBillableEntryForm from '@shared/modules/Timesheet/forms/UpdateTimesheetBillableEntryForm';

const useUpdateTimesheetBillableEntryMutation = ({
  updateTimesheetBillableEntryForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      updateTimesheetBillableEntryForm: UpdateTimesheetBillableEntryForm.toForm(
        updateTimesheetBillableEntryForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateTimesheetBillableEntryMutation.mutation,
    variables: {
      updateTimesheetBillableEntryForm: UpdateTimesheetBillableEntryForm.toMutation(
        form.values.updateTimesheetBillableEntryForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateTimesheetBillableEntryMutation.mutation = gql`
  mutation useUpdateTimesheetBillableEntryMutation($updateTimesheetBillableEntryForm: UpdateTimesheetBillableEntryForm!) {
    response: updateTimesheetBillableEntry(updateTimesheetBillableEntryForm: $updateTimesheetBillableEntryForm) {
      ${gql.errors}
      timesheetBillableEntry {
        id
      }
    }
  }
`;

export default useUpdateTimesheetBillableEntryMutation;
