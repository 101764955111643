// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import BillForm from '@shared/modules/Billing/forms/BillForm';

const useDeleteBillMutation = ({billForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      billForm: BillForm.toForm(billForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteBillMutation.mutation,
    variables: {
      billForm: BillForm.toMutation(form.values.billForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteBillMutation.mutation = gql`
  mutation useDeleteBillMutation($billForm: BillForm!) {
    response: deleteBill(billForm: $billForm) {
      ${gql.errors}
      billId
    }
  }
`;

export default useDeleteBillMutation;
