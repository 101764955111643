// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import RelatedOrganizationsStaffDropdown from 'modules/Organization/components/RelatedOrganizationsStaffDropdown';
import SingleOrganizationStaffDropdown from 'modules/Organization/components/SingleOrganizationStaffDropdown';

const OrganizationStaffDropdown = ({
  organization,
  form,
  field,
  label,
  placeholder,
  isMultiSelect,
  style,
  inputStyle,
  index,
  isSalesperson,
  isCoordinator,
  isDisabled,
  isRequired, // Current required flag
  required, // Deprecated required flag
  isResponsive,
  isPortaled,
}) => {
  return (
    <React.Fragment>
      {(isSalesperson || isCoordinator) &&
      organization.features.isEnabledShowSalespersonsFromAllOrganizations ? (
        <RelatedOrganizationsStaffDropdown
          isDisabled={isDisabled}
          isRequired={isRequired}
          required={required}
          organizationSlug={organization.slug}
          isEnabledShowIntegrationUser={
            (isCoordinator && organization.features.isEnabledShowIntegrationCoordinator) ||
            (isSalesperson && organization.features.isEnabledShowIntegrationSalesperson)
          }
          form={form}
          field={field}
          label={label}
          placeholder={placeholder}
          isMultiSelect={isMultiSelect}
          style={style}
          inputStyle={inputStyle}
          index={index}
          isResponsive={isResponsive}
          isPortaled={isPortaled}
        />
      ) : (
        <SingleOrganizationStaffDropdown
          isDisabled={isDisabled}
          isRequired={isRequired}
          required={required}
          organizationSlug={organization.slug}
          isEnabledShowIntegrationUser={
            (isCoordinator && organization.features.isEnabledShowIntegrationCoordinator) ||
            (isSalesperson && organization.features.isEnabledShowIntegrationSalesperson)
          }
          form={form}
          field={field}
          label={label}
          placeholder={placeholder}
          isMultiSelect={isMultiSelect}
          style={style}
          inputStyle={inputStyle}
          index={index}
          isResponsive={isResponsive}
          isPortaled={isPortaled}
        />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
OrganizationStaffDropdown.propTypes = {
  organization: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isMultiSelect: PropTypes.bool,
  style: PropTypes.object,
  inputStyle: PropTypes.object,
  index: PropTypes.number,
  isSalesperson: PropTypes.bool,
  isCoordinator: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  required: PropTypes.bool,
  isResponsive: PropTypes.bool,
  isPortaled: PropTypes.bool,
};

OrganizationStaffDropdown.defaultProps = {
  isMultiSelect: false,
  style: null,
  inputStyle: null,
  index: null,
  isSalesperson: false,
  isCoordinator: false,
  isDisabled: false,
  isRequired: false,
  required: false,
  isResponsive: false,
  isPortaled: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationStaffDropdown.fragment = gql`
  fragment OrganizationStaffDropdown on Organization {
    id
    slug
    features {
      isEnabledShowSalespersonsFromAllOrganizations: isEnabled(
        feature: "SHOW_SALESPERSONS_FROM_ALL_ORGANIZATIONS"
      )
      isEnabledShowIntegrationCoordinator: isEnabled(feature: "SHOW_INTEGRATION_COORDINATOR")
      isEnabledShowIntegrationSalesperson: isEnabled(feature: "SHOW_INTEGRATION_SALESPERSON")
    }
  }
`;

export default OrganizationStaffDropdown;
