// Libraries
import _ from 'lodash';

// App
import {PayEngine} from '@shared/modules/Payment/components/PayEngineCreditCardInput';
import {transactionErrorMessage} from '@shared/modules/Payment/hooks/payengineErrors';

type TokenizeCreditCardErrorsArgs = {
  tokenizeCreditCardErrors: PayEngine.TokenizeError;
  handleCardErrorMessage: (errorMessage: string) => void;
};

type ChargeCreditCardErrorsArgs = {
  chargeCardErrors: unknown;
  handleCardErrorMessage: (errorMessage: string) => void;
};

const usePayengine = () => {
  const formatCreateCardResponse = (createCardResponse: PayEngine.CreditCard) => {
    return {
      env: createCardResponse.env,
      token: createCardResponse.token,
      cardData: {
        addressZip: createCardResponse.card_data.address_zip,
        brand: createCardResponse.card_data.brand,
        expMonth: createCardResponse.card_data.exp_month,
        expYear: createCardResponse.card_data.exp_year,
        id: createCardResponse.card_data.id,
        last4: createCardResponse.card_data.last_4,
        name: createCardResponse.card_data.name,
      },
    };
  };

  const handleTokenizeCreditCardErrors = ({
    tokenizeCreditCardErrors,
    handleCardErrorMessage,
  }: TokenizeCreditCardErrorsArgs) => {
    console.error(`Error tokenizing credit card: `, tokenizeCreditCardErrors);

    const statusErrorMessage = _.get(tokenizeCreditCardErrors, 'data.message');
    if (statusErrorMessage) {
      return handleCardErrorMessage(`${statusErrorMessage}.`);
    }

    const errorsList = Object.values(tokenizeCreditCardErrors);
    const hasListedErrors = _.some(_.get(errorsList, '0.errorMessages'));
    if (hasListedErrors) {
      const errorsListString = errorsList.reduce(
        (result, error, index) =>
          `${result}${index > 0 ? '\n' : ''} ${_.capitalize(
            _.last(_.get(error, 'errorMessages')),
          )}.`,
        '',
      );
      return handleCardErrorMessage(errorsListString);
    }

    return handleCardErrorMessage('Invalid credit card.');
  };

  const handleChargeCreditCardErrors = ({
    chargeCardErrors,
    handleCardErrorMessage,
  }: ChargeCreditCardErrorsArgs) => {
    console.error(`Error charging credit card:`, {chargeCardErrors});
    handleCardErrorMessage(transactionErrorMessage(chargeCardErrors));
  };

  return {
    formatCreateCardResponse,
    handleTokenizeCreditCardErrors,
    handleChargeCreditCardErrors,
  };
};

export default usePayengine;
