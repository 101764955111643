// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useUpdateProjectBookingStatusMutation = ({projectId, bookingStatus, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      projectId,
      bookingStatus,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectBookingStatusMutation.mutation,
    variables: {
      projectId: form.values.projectId,
      bookingStatus: form.values.bookingStatus,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateProjectBookingStatusMutation.mutation = gql`
  mutation useUpdateProjectBookingStatusMutation($projectId: Int!, $bookingStatus: String!) {
    response: updateProjectBookingStatus(projectId: $projectId, bookingStatus: $bookingStatus) {
      ${gql.errors}
      project {
        id
      }
    }
  }
`;

export default useUpdateProjectBookingStatusMutation;
