// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState, useEffect, useQuery, usePopover, useResponsive, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SearchBar from '@shared/design/components/SearchBar';
import Table from '@shared/design/components/Table';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import CustomSurveyTypes from '@shared/modules/Inventory/enums/CustomSurveyTypes';
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const MAX_TABLE_HEIGHT_MARGIN = 500;
const ROW_ITEM_HEIGHT = 46;

const SidebarContainer = Styled.View`
  padding-vertical: 24px;
  padding-horizontal: ${({isDense}) => (isDense ? '16px' : '24px')};
  background-color: ${colors.gray.background};
  border-top-style: solid;
  border-top-color: ${colors.gray.border};
  border-top-width: 1px;
  border-right-style: solid;
  border-right-color: ${colors.gray.border};
  border-right-width: 1px;
  flex-direction: column;
  min-width: 224px;
  max-width: 400px;
  flex: 1;
`;

const Row = Styled.View`
  align-items: center;
  flex-direction: row;
`;

const SectionText = Styled.Text`
  ${Typography.Heading2}
`;

const ItemsContainer = Styled.View`
  border: 1px solid ${colors.gray.border};
  border-radius: 4px;
`;

const ItemName = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.primary};
`;

const ItemDetails = Styled.Text`
  ${Typography.Micro}
`;

const NotesHeader = Styled.ButtonV2`
  flex-direction: column;
`;

const FilterPopoverContainer = Styled.View`
  max-height: 300px;
  background-color: ${colors.white};
  width: 272px;
  border-radius: 4px;
  padding: 12px 0px;
  border: 1px solid ${colors.gray.border};
  overflow-y: auto;
`;

const toggleFilteredCategory = ({
  selectedCategoryId,
  filteredCategoryIds,
  setFilteredCategoryIds,
}) => {
  setFilteredCategoryIds(_.xor([selectedCategoryId], filteredCategoryIds));
  return;
};

const getVirtualizedHeights = ({itemHeight, window}) => {
  const maxHeight = window.innerHeight - MAX_TABLE_HEIGHT_MARGIN;
  return {
    virtualizedItemHeight: itemHeight,
    virtualizedTableHeight: maxHeight,
  };
};

const getColumnDefinitions = ({large}) => {
  const columns = [
    {
      flex: 2,
      cellContent: ({item: itemType}) => <ItemName>{itemType.name}</ItemName>,
    },
    {
      flex: 1,
      isHidden: !large,
      cellContent: ({item: itemType}) => {
        return (
          <ItemDetails>
            {itemType.volume} cu ft, {itemType.weight} lbs
          </ItemDetails>
        );
      },
    },
  ];
  return columns;
};

const scrollToBottomOfSelectedRoom = ({activeRoomRef}) => {
  // Scroll to end of the selected room list
  if (activeRoomRef) {
    setTimeout(() => activeRoomRef.current.scrollToItem(activeRoomRef.current.props.itemCount), 0);
  }
};

const InventoryItemsContent = ({
  itemsData,
  inventoryRoomsForm,
  filteredCategoryIds,
  setFilteredCategoryIds,
  searchTerm,
  setSearchTerm,
  activeRoomRef,
  setItemTypeKindFilters,
}) => {
  const [filteredItemTypes, setFilteredItemTypes] = useState([]);
  const popover = usePopover();
  const responsive = useResponsive();
  const isDense = responsive.tablet || responsive.mobile;
  const noRoomsErrorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Add a room before adding items.',
  });

  useEffect(() => {
    setFilteredItemTypes(
      getFilteredItemTypes({
        searchTerm,
        filteredCategoryIds,
        itemTypes: itemsData.inventoryLibrary.itemTypes,
      }),
    );
  }, [searchTerm, filteredCategoryIds, itemsData]);

  const {virtualizedItemHeight, virtualizedTableHeight} = getVirtualizedHeights({
    itemHeight: ROW_ITEM_HEIGHT,
    window,
  });

  return (
    <React.Fragment>
      <Row>
        <SearchBar
          placeholder={'Search'}
          iconColor={colors.gray.tertiary}
          containerStyle={{flex: 1}}
          style={{width: '100%'}}
          onChangeText={(value) => {
            setSearchTerm(value);
          }}
          valueOverride={searchTerm}
          isClearable
        />
        <Space width={isDense ? 8 : 16} />
        <Popover.Content innerRef={popover.ref}>
          <SecondaryButton
            onPress={popover.handleToggle}
            text={`(${filteredCategoryIds.length})`}
            iconLeft={Icon.Filter}
            style={{minWidth: 65}}
          />
        </Popover.Content>
      </Row>
      <Space height={16} />
      <ItemsContainer>
        <Table.Virtualized
          columnDefinitions={getColumnDefinitions(responsive)}
          isEnabledHeader={false}
          emptyStateText={'No items found...'}
          items={filteredItemTypes}
          virtualizedItemHeight={virtualizedItemHeight}
          virtualizedTableHeight={virtualizedTableHeight}
          itemKey={'id'}
          isClickable
          onRowPress={(item) => {
            InventoryRoomsForm.addItemToInventoryRoomsForm({
              inventoryRoomsForm,
              itemType: item,
              noRoomsErrorToast,
              setItemTypeKindFilters,
            });
            const {selectedRoomIndex} = inventoryRoomsForm.values.inventoryRoomsForm;
            if (
              selectedRoomIndex > 0 &&
              !inventoryRoomsForm.values.inventoryRoomsForm.roomItemsForms[selectedRoomIndex]
                .isDeleted
            ) {
              scrollToBottomOfSelectedRoom({activeRoomRef});
            }
          }}
        />
      </ItemsContainer>
      <FilterPopover
        popover={popover}
        categories={itemsData.inventoryLibrary.categories}
        setFilteredCategoryIds={setFilteredCategoryIds}
        filteredCategoryIds={filteredCategoryIds}
      />
    </React.Fragment>
  );
};

const InventoryNotesContent = ({inventoryRoomsForm}) => {
  const [showNotes, setShowNotes] = useState(false);

  return (
    <React.Fragment>
      <NotesHeader onPress={() => setShowNotes(!showNotes)}>
        <Row>
          <Icon
            source={showNotes ? Icon.ChevronDown : Icon.ChevronRight}
            color={colors.gray.primary}
            size={Icon.Sizes.Medium}
          />
          <Space width={8} />
          <SectionText>Notes</SectionText>
        </Row>
      </NotesHeader>
      {showNotes && (
        <React.Fragment>
          <Space height={16} />
          <FieldInput
            {...inventoryRoomsForm}
            name={'inventoryRoomsForm.notes'}
            input={{
              style: {height: 176, paddingTop: 8},
              multiline: true,
              placeholder: 'Enter survey notes',
            }}
            setFieldValue={(name, text) => {
              inventoryRoomsForm.setFieldValue('inventoryRoomsForm.notes', text);
              inventoryRoomsForm.setFieldValue(`inventoryRoomsForm.isDirty`, true);
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const InventoryNotes = ({inventoryRoomsForm}) => {
  return <InventoryNotesContent inventoryRoomsForm={inventoryRoomsForm} />;
};

const getItemTypeMatchesSearch = ({searchTerm, itemType}) => {
  return (
    (searchTerm && itemType.name.toLowerCase().includes(searchTerm.toLowerCase())) || !searchTerm
  );
};

const getFilteredItemTypes = ({searchTerm, itemTypes, filteredCategoryIds}) => {
  return _.filter(itemTypes, (itemType) => {
    const itemTypeCategoryIds = _.map(itemType.itemTypeCategories, (itemTypeCategory) =>
      _.toString(itemTypeCategory.categoryId),
    );
    const categoryFiltersMatchItemCategory = _.some(filteredCategoryIds, (categoryId) =>
      _.includes(itemTypeCategoryIds, categoryId),
    );
    return (
      (_.isEmpty(filteredCategoryIds) || categoryFiltersMatchItemCategory) &&
      getItemTypeMatchesSearch({searchTerm, itemType})
    );
  });
};

const FilterOption = ({category, setFilteredCategoryIds, filteredCategoryIds}) => {
  const isSelected = _.includes(filteredCategoryIds, category.id);
  return (
    <Checkbox
      isChecked={isSelected}
      label={category.name}
      style={{flex: 1, justifyContent: 'flex-start', margin: 12}}
      handleToggle={() =>
        toggleFilteredCategory({
          selectedCategoryId: category.id,
          filteredCategoryIds,
          setFilteredCategoryIds,
        })
      }
    />
  );
};

const FilterPopover = ({popover, categories, setFilteredCategoryIds, filteredCategoryIds}) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
    >
      <FilterPopoverContainer>
        {categories.map((category) => {
          return (
            <FilterOption
              key={category.id}
              category={category}
              setFilteredCategoryIds={setFilteredCategoryIds}
              filteredCategoryIds={filteredCategoryIds}
            />
          );
        })}
      </FilterPopoverContainer>
    </Popover>
  );
};

const InventoryItems = ({
  inventoryLibraryUuid,
  inventoryRoomsForm,
  filteredCategoryIds,
  setFilteredCategoryIds,
  searchTerm,
  setSearchTerm,
  activeRoomRef,
  setItemTypeKindFilters,
}) => {
  const {loading: itemsLoading, data: itemsData} = useQuery(InventoryItems.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      inventoryLibraryUuid,
    },
  });

  return (
    <Loading loading={itemsLoading} as={PageLoadingIndicator}>
      {() => (
        <InventoryItemsContent
          itemsData={itemsData}
          inventoryRoomsForm={inventoryRoomsForm}
          filteredCategoryIds={filteredCategoryIds}
          setFilteredCategoryIds={setFilteredCategoryIds}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          activeRoomRef={activeRoomRef}
          setItemTypeKindFilters={setItemTypeKindFilters}
        />
      )}
    </Loading>
  );
};

const EditInventoryPageSidebarContent = ({
  inventoryLibraryUuid,
  inventoryRoomsForm,
  filteredCategoryIds,
  setFilteredCategoryIds,
  searchTerm,
  setSearchTerm,
  activeRoomRef,
  setItemTypeKindFilters,
}) => {
  return (
    <React.Fragment>
      <InventoryItems
        inventoryLibraryUuid={inventoryLibraryUuid}
        inventoryRoomsForm={inventoryRoomsForm}
        filteredCategoryIds={filteredCategoryIds}
        setFilteredCategoryIds={setFilteredCategoryIds}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        activeRoomRef={activeRoomRef}
        setItemTypeKindFilters={setItemTypeKindFilters}
      />
      <Space height={24} />
      <InventoryNotes inventoryRoomsForm={inventoryRoomsForm} />
    </React.Fragment>
  );
};

const EditInventoryPageSidebar = ({
  inventoryLibraryUuid,
  inventoryRoomsForm,
  filteredCategoryIds,
  setFilteredCategoryIds,
  searchTerm,
  setSearchTerm,
  activeRoomRef,
  setItemTypeKindFilters,
}) => {
  const noRoomsErrorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Add a room before adding items.',
  });
  const responsive = useResponsive();

  return (
    <SidebarContainer isDense={responsive.tablet || responsive.mobile}>
      <Row style={{justifyContent: 'space-between'}}>
        <SectionText>Items</SectionText>
        <TertiaryButton
          text={'Custom Item'}
          iconLeft={Icon.Plus}
          onPress={() => {
            InventoryRoomsForm.addItemToInventoryRoomsForm({
              inventoryRoomsForm,
              itemType: CustomSurveyTypes.ITEM,
              noRoomsErrorToast,
              setItemTypeKindFilters,
            });
            const {selectedRoomIndex} = inventoryRoomsForm.values.inventoryRoomsForm;
            if (
              selectedRoomIndex > 0 &&
              !inventoryRoomsForm.values.inventoryRoomsForm.roomItemsForms[selectedRoomIndex]
                .isDeleted
            ) {
              scrollToBottomOfSelectedRoom({activeRoomRef});
            }
          }}
        />
      </Row>
      <Space height={24} />
      <EditInventoryPageSidebarContent
        inventoryLibraryUuid={inventoryLibraryUuid}
        inventoryRoomsForm={inventoryRoomsForm}
        filteredCategoryIds={filteredCategoryIds}
        setFilteredCategoryIds={setFilteredCategoryIds}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        activeRoomRef={activeRoomRef}
        setItemTypeKindFilters={setItemTypeKindFilters}
      />
      <Space height={100} />
    </SidebarContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryItems.query = gql`
  ${InventoryRoomsForm.addItemToInventoryRoomsForm.fragment}
  query InventoryItems($inventoryLibraryUuid: String!) {
    inventoryLibrary(uuid: $inventoryLibraryUuid) {
      id
      itemTypes {
        id
        name
        weight
        volume
        price
        kind
        isDerivedWeight
        itemTypeCategories {
          id
          categoryId
          category {
            id
            name
          }
        }
        ...InventoryRoomsForm_addItemToInventoryRoomsForm
      }
      categories {
        id
        name
      }
    }
  }
`;

export default EditInventoryPageSidebar;
