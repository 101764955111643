const _new = ({projectTypeId, feature, isEnabled}) => ({
  projectTypeId,
  feature,
  isEnabled,
});

const toForm = ({projectTypeId, feature, isEnabled}) => ({
  projectTypeId,
  feature,
  isEnabled,
});

const toMutation = ({projectTypeId, feature, isEnabled}) => ({
  projectTypeId,
  feature,
  isEnabled,
});

const ProjectTypeFeatureForm = {
  new: _new,
  toForm,
  toMutation,
};

export default ProjectTypeFeatureForm;
