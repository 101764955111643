// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {DeviceInfo} from '@supermove/sdk';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';

const Container = Styled.View`
`;

const Content = Styled.View`
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Label = Styled.Text`
  margin-right: 20px;
  ${Typography.Body2};
  color: ${colors.gray.secondary};
`;

const InputText = Styled.Text`
  ${Typography.Body3};
  color: ${colors.gray.secondary};
`;

const TextAreaEdit = ({value, index, form, field, isRequired}) => {
  const valueField = `${field}.input.value.values.${index}.1`;
  return (
    <Container>
      <Space height={12} />
      <FieldInput.LabelText size={FieldInput.SIZE.MEDIUM}>{value[0]}</FieldInput.LabelText>
      <Space height={4} />
      <FieldInput.Memoized
        {...form}
        name={valueField}
        size={FieldInput.SIZE.MEDIUM}
        input={{
          required: isRequired && !_.get(form.values, valueField),
          style: {height: 120, paddingTop: 8},
          multiline: true,
          placeholder: 'Enter value',
          ...(DeviceInfo.getPlatform() === DeviceInfo.PlatformType.WEB
            ? {}
            : {textAlignVertical: 'top'}),
        }}
      />
    </Container>
  );
};

const TextAreaView = ({value}) => {
  return (
    <Container>
      <Content>
        <Label numberOfLines={1}>{value[0]}</Label>
        <Space height={4} />
        <InputText>{value[1] || 'None'}</InputText>
      </Content>
      <Line />
    </Container>
  );
};

const DocumentV2TextAreas = ({input, form, field, isEditable, isRequired}) => {
  return (
    <React.Fragment>
      <Line />
      {input.value.values.map((value, index) => {
        return (
          <React.Fragment key={index}>
            {isEditable ? (
              <TextAreaEdit
                value={value}
                index={index}
                form={form}
                field={field}
                isRequired={isRequired}
              />
            ) : (
              <TextAreaView value={value} />
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default DocumentV2TextAreas;
