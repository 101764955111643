// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import TextTooltip from 'modules/App/components/TextTooltip';

const Text = Styled.Text`
  ${Typography.Body}
`;

const IconContainer = Styled.View`
`;

const CheckboxRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const NewVariableSectionModal = ({
  isOpen,
  handleClose,
  parentForm,
  sectionFormsField,
  form,
  field,
  variableSectionFormField,
}) => {
  return (
    <SmallModal isOpen={isOpen}>
      <SmallModal.HeaderText>{'Edit Section'}</SmallModal.HeaderText>
      <Space height={16} />
      <FieldInput
        {...form}
        name={`${field}.name`}
        label={'Section Name'}
        input={{
          placeholder: 'Enter name',
          required: true,
        }}
      />
      <Space height={16} />
      <CheckboxRow>
        <Checkbox
          // if the parentForm's section is default, we don't allow it to be unchecked
          isDisabled={_.get(parentForm.values, `${variableSectionFormField}.isDefault`)}
          isChecked={_.get(form.values, `${field}.isDefault`)}
          handleToggle={() => {
            const isDefault = _.get(form.values, `${field}.isDefault`);
            form.setFieldValue(`${field}.isDefault`, !isDefault);
          }}
          childrenRight
        >
          <Space width={8} />
          <Text>Set as default?</Text>
        </Checkbox>
        <Space width={8} />
        <TextTooltip text={'Any new variable(s) added will initially be grouped in this section'}>
          <IconContainer>
            <Icon color={colors.gray.tertiary} size={Icon.Sizes.Medium} source={Icon.InfoCircle} />
          </IconContainer>
        </TextTooltip>
      </CheckboxRow>
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <SmallModal.Button
          isDisabled={!_.get(form.values, `${field}.name`)}
          onPress={() => {
            const existingSectionForms = _.get(parentForm.values, sectionFormsField);
            const sectionForm = _.get(form.values, field);
            const isSectionDefault = _.get(sectionForm, 'isDefault');
            if (isSectionDefault) {
              const sectionForms = _.map(existingSectionForms, (sectionForm) => ({
                ...sectionForm,
                isDefault: false,
              }));
              parentForm.setFieldValue(sectionFormsField, sectionForms);
            }
            setTimeout(() => parentForm.setFieldValue(variableSectionFormField, sectionForm), 0);
            handleClose();
          }}
          color={colors.blue.interactive}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default NewVariableSectionModal;
