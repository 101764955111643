// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {BillItem} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import BillItemUnit from '@shared/modules/Billing/enums/BillItemUnit';
import BillStage from '@shared/modules/Billing/enums/BillStage';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import UserRole from '@shared/modules/User/enums/UserRole';
import InvoiceBillBillItemsPostSubtotalListItem from 'modules/Storage/components/InvoiceBillBillItemsPostSubtotalListItem';
import InvoiceBillBillItemsPreSubtotalListItem from 'modules/Storage/components/InvoiceBillBillItemsPreSubtotalListItem';

const Row = Styled.View`
  flex-direction: row;
`;

const BillItemsListContainer = Styled.View`
  z-index: ${({index = 0}) => 200 - index};  
`;

const FullWidthContainer = Styled.View`
  width: 100%;
`;

const OptionContainer = Styled.View`
  background-color: ${({color}) => color};
  padding-vertical: 5px;
  padding-horizontal: 12px;
`;

const OptionTypeText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.blue.interactive};
  padding-left: 12px;
`;

const OptionText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.tertiary};
  padding-vertical: 1px;
`;

const OptionAfterSubtotalText = Styled.Text`
  ${Typography.Body4}
  font-style: italic;
  color: ${colors.gray.primary};
  padding-top: 4px;
`;

const CustomOptionTypeText = Styled.Text`
  ${Typography.Body4}
  font-style: italic;
  color: ${colors.gray.primary};
  padding-top: 4px;
`;

const getDisableEditPrices = ({organization, userRole}) => {
  const {isEnabledSalespersonCanEditBillPrices} = organization.features;
  const isSalesperson = userRole === UserRole.SALESPERSON;
  const disabledBySalespersonFeatureFlag = !isEnabledSalespersonCanEditBillPrices && isSalesperson;
  return disabledBySalespersonFeatureFlag;
};

const getDisableAddCustomItems = ({organization, userRole}) => {
  const {isEnabledSalespersonCanCreateBillCustomItems} = organization.features;
  const isSalesperson = userRole === UserRole.SALESPERSON;
  const disabledBySalespersonFeatureFlag =
    !isEnabledSalespersonCanCreateBillCustomItems && isSalesperson;
  return disabledBySalespersonFeatureFlag;
};

const handleAddBillItemForm = ({form, billItemForm, billFormField}) => {
  const field =
    billItemForm.billStage === BillStage.PRE_SUBTOTAL
      ? `${billFormField}.billItemFormsPreSubtotal`
      : `${billFormField}.billItemFormsPostSubtotal`;
  const billItemForms = _.get(form.values, field);
  const addedForm = BillItemForm.toForm({...billItemForm, index: billItemForms.length});
  form.setFieldValue(field, [...billItemForms, addedForm]);
};

const getValidBillItemTypesPreSubtotal = ({form, billItemTypes, bill, billFormField}) => {
  const billItemFormsPreSubtotal = _.get(
    form.values,
    `${billFormField}.billItemFormsPreSubtotal`,
    [],
  );
  const hourlyRateBillItems = billItemFormsPreSubtotal.filter((billItemForm) => {
    return billItemForm.unit === BillItemUnit.HOUR;
  });
  const hasHourlyRate = hourlyRateBillItems.length > 0;
  const {isEnabledMultipleHourlyRateBillingItems} = bill.organization.features;

  if (hasHourlyRate && !isEnabledMultipleHourlyRateBillingItems) {
    return billItemTypes.filter((billItemType) => {
      return billItemType.unit !== BillItemUnit.HOUR;
    });
  }

  return billItemTypes;
};

const getBillItemTypeOptions = ({form, bill, search, billFormField}) => {
  const {billItemTypesPreSubtotal, billItemTypesPostSubtotal} =
    bill.project.projectType.billingLibrary;
  const validBillItemTypesPreSubtotal = getValidBillItemTypesPreSubtotal({
    form,
    billItemTypes: billItemTypesPreSubtotal,
    bill,
    billFormField,
  });
  const billItemTypes = [...validBillItemTypesPreSubtotal, ...billItemTypesPostSubtotal];
  const billItemTypeOptions = billItemTypes
    .filter((billItemType) => !billItemType.isParent)
    .map((billItemType) => {
      const billItemForm = BillItemForm.editFromBillItemType(billItemType, {billId: bill.id});
      return {
        value: billItemType.id,
        label: BillItemForm.getDropdownLabel(billItemForm),
        billItemForm,
      };
    });
  const customBillItemOptions = BillItem.CUSTOM_BILL_ITEMS.map((customBillItem, index) => {
    const billItemForm = BillItemForm.new({...customBillItem, billId: bill.id});
    return {
      value: `${search} ${customBillItem.name}`,
      label: (
        <React.Fragment>
          <OptionText>{`${search ? `${search} ` : ''}`}</OptionText>
          <CustomOptionTypeText>{customBillItem.name}</CustomOptionTypeText>
        </React.Fragment>
      ),
      billItemForm: {...billItemForm, name: search},
      isCustom: true,
      index,
    };
  });
  const defaultOptions = [...billItemTypeOptions, ...customBillItemOptions];

  // When entering text into the dropdown input, ReactSelect handles filtering the items by the search,
  // but it does not prioritize the matches in any way. Here we additionally sort the items so that
  // direct matches to the beginning of the string are prioritized first.
  if (search) {
    const sortedOptions = _.sortBy(defaultOptions, (option) => {
      const label = _.get(option, 'label');
      const isMatch = _.isString(label)
        ? _.toLower(label.slice(0, search.length)) === _.toLower(search)
        : false;
      if (isMatch) {
        return 0;
      }
      return 1;
    });
    return sortedOptions;
  }
  return defaultOptions;
};

const BillItemTypeOption = ({
  data: {billItemForm, isCustom, index, label},
  innerProps,
  isFocused,
  options,
}) => {
  const firstOption = _.get(options, '0');
  const isFirstLibraryOption =
    billItemForm.billItemTypeId === firstOption.value &&
    billItemForm.billStage === BillStage.PRE_SUBTOTAL;
  const isFirstCustomOption = isCustom && index === 0;
  return (
    <React.Fragment>
      {isFirstLibraryOption && (
        <React.Fragment>
          <Space height={8} />
          <OptionTypeText>Add From Library</OptionTypeText>
          <Space height={4} />
        </React.Fragment>
      )}
      {isFirstCustomOption && (
        <React.Fragment>
          <Space height={8} />
          <OptionTypeText>Add Custom Item</OptionTypeText>
          <Space height={4} />
        </React.Fragment>
      )}
      <OptionContainer
        {...innerProps}
        isFocused={isFocused}
        color={isFocused ? colors.hover : 'transparent'}
      >
        <Row>
          <OptionText color={colors.gray.primary}>{label}</OptionText>
          <Space style={{flex: 1, minWidth: 4}} />
          <OptionAfterSubtotalText color={colors.gray.secondary}>
            {billItemForm.billStage === BillStage.POST_SUBTOTAL ? 'after subtotal' : ''}
          </OptionAfterSubtotalText>
        </Row>
      </OptionContainer>
    </React.Fragment>
  );
};

const SearchAndBillItemsPostSubtotalList = ({
  bill,
  form,
  billFormField,
  disableEditPrices,
  disableAddCustomItems,
  billItemsPreSubtotalHeight,
  setCurrentBillIndex,
  index,
}) => {
  const [search, setSearch] = useState('');
  const billItemFormsPostSubtotal = _.get(
    form.values,
    `${billFormField}.billItemFormsPostSubtotal`,
  );
  const hasBillItemFormsPostSubtotal = !_.isEmpty(billItemFormsPostSubtotal);
  const hasBillItemsPreSubtotal = billItemsPreSubtotalHeight > 0;

  return (
    <React.Fragment>
      {hasBillItemFormsPostSubtotal && (
        <React.Fragment>
          {billItemFormsPostSubtotal.map((billItemForm, index) => {
            return (
              <InvoiceBillBillItemsPostSubtotalListItem
                key={`${index}_${billItemForm.name}`}
                form={form}
                field={`${billFormField}.billItemFormsPostSubtotal`}
                billFormField={billFormField}
                billItemForm={billItemForm}
                index={index}
                disableEditPrices={disableEditPrices}
              />
            );
          })}
          <Space height={20} />
        </React.Fragment>
      )}
      {!disableAddCustomItems && (
        <FullWidthContainer style={{zIndex: 999}}>
          <FieldInput
            {...form}
            component={DropdownInput}
            input={{
              placeholder: 'Add items from library or add a custom item',
              backgroundColor: 'transparent',
              isSearchable: true,
              options: getBillItemTypeOptions({form, bill, search, billFormField}),
              setFieldValue: () => {},
              onChangeValue: (value, {billItemForm}) => {
                setCurrentBillIndex(index);
                handleAddBillItemForm({form, billItemForm, billFormField});
              },
              style: {
                width: '100%',
                borderTopWidth: hasBillItemsPreSubtotal ? 0 : 1,
                borderRadius: 0,
                borderColor: colors.gray.border,
                paddingLeft: 20,
              },
              components: {
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
                Option: (optionData) => <BillItemTypeOption {...optionData} />,
              },
              onInputChange: (text, {action}) => {
                setSearch(text);
              },
            }}
            style={{width: '100%', paddingHorizontal: 22}}
          />
          <Icon
            source={Icon.Search}
            size={12}
            color={colors.gray.tertiary}
            style={{position: 'absolute', top: 11, left: 32}}
          />
        </FullWidthContainer>
      )}
    </React.Fragment>
  );
};

const InvoiceBillBillItemsList = ({
  form,
  bill,
  billFormField,
  userRole,
  index,
  setCurrentBillIndex,
}) => {
  const disableEditPrices = getDisableEditPrices({organization: bill.organization, userRole});
  const disableAddCustomItems = getDisableAddCustomItems({
    organization: bill.organization,
    userRole,
  });
  const [billItemsPreSubtotalHeight, setBillItemsPreSubtotalHeight] = useState(0);

  return (
    <BillItemsListContainer>
      <FullWidthContainer
        onLayout={({nativeEvent}) => setBillItemsPreSubtotalHeight(nativeEvent.layout.height)}
      >
        {_.get(form, `values.${billFormField}.billItemFormsPreSubtotal`).map(
          (billItemForm, billItemIndex) => {
            return (
              <InvoiceBillBillItemsPreSubtotalListItem
                key={`${billItemIndex}_${billItemForm.name}`}
                form={form}
                field={`${billFormField}.billItemFormsPreSubtotal`}
                billItemForm={billItemForm}
                index={billItemIndex}
                disableEditPrices={disableEditPrices}
                billFormField={billFormField}
                setCurrentBillIndex={setCurrentBillIndex}
              />
            );
          },
        )}
      </FullWidthContainer>
      <SearchAndBillItemsPostSubtotalList
        bill={bill}
        form={form}
        billFormField={billFormField}
        disableEditPrices={disableEditPrices}
        disableAddCustomItems={disableAddCustomItems}
        billItemsPreSubtotalHeight={billItemsPreSubtotalHeight}
        index={index}
        setCurrentBillIndex={setCurrentBillIndex}
      />
    </BillItemsListContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceBillBillItemsList.fragment = gql`
  ${BillItemForm.editFromBillItemType.fragment}

  fragment InvoiceBillBillItemsList on Bill {
    id
    organization {
      id
      features {
        isEnabledMultipleHourlyRateBillingItems: isEnabled(
          feature: "MULTIPLE_HOURLY_RATE_BILLING_ITEMS"
        )
        isEnabledSalespersonCanEditBillPrices: isEnabled(
          feature: "SALESPERSON_CAN_EDIT_BILL_PRICES"
        )
        isEnabledSalespersonCanCreateBillCustomItems: isEnabled(
          feature: "SALESPERSON_CAN_CREATE_BILL_CUSTOM_ITEMS"
        )
      }
    }
    project {
      id
      projectType {
        id
        billingLibrary {
          id
          billItemTypesPreSubtotal {
            id
            name
            billStage
            isParent
            ...BillItemForm_editFromBillItemType
          }
          billItemTypesPostSubtotal {
            id
            name
            billStage
            isParent
            ...BillItemForm_editFromBillItemType
          }
        }
      }
    }
  }
`;

export default InvoiceBillBillItemsList;
