const _new = ({dayId, sortOption}) => ({
  dayId,
  sortOption,
});

const toForm = ({dayId, sortOption}) => ({
  dayId,
  sortOption,
});

const toMutation = ({dayId, sortOption}) => ({
  dayId,
  sortOption,
});

const SortDaySlotsForm = {
  new: _new,
  toForm,
  toMutation,
};

export default SortDaySlotsForm;
