// Libraries
import _ from 'lodash';

// Supermove
import {uuid} from '@supermove/utils';

// App
// @ts-ignore
import ProjectStatus from '@shared/modules/Project/enums/ProjectStatus';
// @ts-ignore
import WorkflowFormulaBuilder from '@shared/modules/Workflow/enums/WorkflowFormulaBuilder';
// @ts-ignore
import WorkflowModelProperties from '@shared/modules/Workflow/enums/WorkflowModelProperties';
// @ts-ignore
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';

const FORM_FIELD_TO_PRIVATE_PROPERTY: {
  [key: string]: {
    checkProperty: string;
    conditionProperty: string;
    selectedProperty?: (value: any) => string | null;
  };
} = {
  projectTagInclusionList: {
    checkProperty: WorkflowStepActionKind.CATEGORIES.TAGS,
    conditionProperty: WorkflowFormulaBuilder.IS,
  },
  projectTagExclusionList: {
    checkProperty: WorkflowStepActionKind.CATEGORIES.TAGS,
    conditionProperty: WorkflowFormulaBuilder.IS_NOT,
  },
  jobTagInclusionList: {
    checkProperty: WorkflowStepActionKind.CATEGORIES.TAGS,
    conditionProperty: WorkflowFormulaBuilder.IS,
  },
  jobTagExclusionList: {
    checkProperty: WorkflowStepActionKind.CATEGORIES.TAGS,
    conditionProperty: WorkflowFormulaBuilder.IS_NOT,
  },
  projectStatusEquals: {
    checkProperty: WorkflowStepActionKind.CATEGORIES.PROJECT,
    conditionProperty: WorkflowFormulaBuilder.IS,
    selectedProperty: (value: any) => {
      if (ProjectStatus.OPTIONS.map((option: any, index: any) => option.value).includes(value)) {
        return WorkflowModelProperties.PROJECT_MOVE_STATUS;
      } else return WorkflowModelProperties.PROJECT_STORAGE_STATUS;
    },
  },
  projectStatusDoesNotEqual: {
    checkProperty: WorkflowStepActionKind.CATEGORIES.PROJECT,
    conditionProperty: WorkflowFormulaBuilder.IS_NOT,
    selectedProperty: (value: any) => {
      if (ProjectStatus.OPTIONS.map((option: any, index: any) => option.value).includes(value)) {
        return WorkflowModelProperties.PROJECT_MOVE_STATUS;
      } else return WorkflowModelProperties.PROJECT_STORAGE_STATUS;
    },
  },
  taskIsCompleted: {
    checkProperty: WorkflowStepActionKind.CATEGORIES.TASK,
    conditionProperty: WorkflowFormulaBuilder.IS,
    selectedProperty: () => {
      return WorkflowModelProperties.TASK_IS_COMPLETED;
    },
  },
  taskIsNotCompleted: {
    checkProperty: WorkflowStepActionKind.CATEGORIES.TASK,
    conditionProperty: WorkflowFormulaBuilder.IS_NOT,
    selectedProperty: () => {
      return WorkflowModelProperties.TASK_IS_COMPLETED;
    },
  },
};

export interface GetDataAndStopIfActionFormType {
  workflowRunStepUuid: string;
  projectTagInclusionList: string[] | null;
  projectTagExclusionList: string[] | null;
  jobTagInclusionList: string[] | null;
  jobTagExclusionList: string[] | null;
  projectStatusEquals: string | null;
  projectStatusDoesNotEqual: string | null;
  taskIsCompleted: string | null;
  taskIsNotCompleted: string | null;

  // Private
  checkProperty: string | null | void;
  selectedProperty: string | null;
  conditionProperty: string | null;
}

export interface GetDataAndStopIfActionFormMutationType {
  workflowRunStepUuid: string;
  projectTagInclusionList: string[] | null;
  projectTagExclusionList: string[] | null;
  jobTagInclusionList: string[] | null;
  jobTagExclusionList: string[] | null;
  projectStatusEquals: string | null;
  projectStatusDoesNotEqual: string | null;
  taskIsCompleted: string | null;
  taskIsNotCompleted: string | null;
}

const _new = (): GetDataAndStopIfActionFormType => ({
  workflowRunStepUuid: uuid(),
  projectTagInclusionList: [],
  projectTagExclusionList: [],
  jobTagInclusionList: [],
  jobTagExclusionList: [],
  projectStatusEquals: '',
  projectStatusDoesNotEqual: '',
  taskIsCompleted: '',
  taskIsNotCompleted: '',

  // Private
  checkProperty: '',
  selectedProperty: '',
  conditionProperty: '',
});

const edit = (getDataAndStopIfActionForm: any): GetDataAndStopIfActionFormType => ({
  workflowRunStepUuid: getDataAndStopIfActionForm.workflowRunStepUuid,
  projectTagInclusionList: getDataAndStopIfActionForm.projectTagInclusionList,
  projectTagExclusionList: getDataAndStopIfActionForm.projectTagExclusionList,
  jobTagInclusionList: getDataAndStopIfActionForm.jobTagInclusionList,
  jobTagExclusionList: getDataAndStopIfActionForm.jobTagExclusionList,
  projectStatusEquals: getDataAndStopIfActionForm.projectStatusEquals,
  projectStatusDoesNotEqual: getDataAndStopIfActionForm.projectStatusDoesNotEqual,
  taskIsCompleted: getDataAndStopIfActionForm.taskIsCompleted,
  taskIsNotCompleted: getDataAndStopIfActionForm.taskIsNotCompleted,

  // Private
  ..._getPrivateEditFormProperty(getDataAndStopIfActionForm),
});

const toForm = (
  getDataAndStopIfActionForm: GetDataAndStopIfActionFormType,
): GetDataAndStopIfActionFormType => ({
  workflowRunStepUuid: getDataAndStopIfActionForm.workflowRunStepUuid,
  projectTagInclusionList: getDataAndStopIfActionForm.projectTagInclusionList,
  projectTagExclusionList: getDataAndStopIfActionForm.projectTagExclusionList,
  jobTagInclusionList: getDataAndStopIfActionForm.jobTagInclusionList,
  jobTagExclusionList: getDataAndStopIfActionForm.jobTagExclusionList,
  projectStatusEquals: getDataAndStopIfActionForm.projectStatusEquals,
  projectStatusDoesNotEqual: getDataAndStopIfActionForm.projectStatusDoesNotEqual,
  taskIsCompleted: getDataAndStopIfActionForm.taskIsCompleted,
  taskIsNotCompleted: getDataAndStopIfActionForm.taskIsNotCompleted,

  // Private
  checkProperty: getDataAndStopIfActionForm.checkProperty,
  selectedProperty: getDataAndStopIfActionForm.selectedProperty,
  conditionProperty: getDataAndStopIfActionForm.conditionProperty,
});

const toMutation = (
  getDataAndStopIfActionForm: GetDataAndStopIfActionFormMutationType,
): GetDataAndStopIfActionFormMutationType => ({
  workflowRunStepUuid: getDataAndStopIfActionForm.workflowRunStepUuid,
  projectTagInclusionList: getDataAndStopIfActionForm.projectTagInclusionList,
  projectTagExclusionList: getDataAndStopIfActionForm.projectTagExclusionList,
  jobTagInclusionList: getDataAndStopIfActionForm.jobTagInclusionList,
  jobTagExclusionList: getDataAndStopIfActionForm.jobTagExclusionList,
  projectStatusEquals: getDataAndStopIfActionForm.projectStatusEquals,
  projectStatusDoesNotEqual: getDataAndStopIfActionForm.projectStatusDoesNotEqual,
  taskIsCompleted: getDataAndStopIfActionForm.taskIsCompleted,
  taskIsNotCompleted: getDataAndStopIfActionForm.taskIsNotCompleted,
});

const _getPrivateEditFormProperty = (
  form: any,
): Pick<
  GetDataAndStopIfActionFormType,
  'checkProperty' | 'selectedProperty' | 'conditionProperty'
> => {
  const defaultPrivateProperties = {
    checkProperty: null,
    selectedProperty: null,
    conditionProperty: null,
  };
  for (const [key, value] of Object.entries(form)) {
    if (!_.isEmpty(value) && key !== 'workflowRunStepUuid') {
      const privateProperty = FORM_FIELD_TO_PRIVATE_PROPERTY[key];
      const selectedProperty = privateProperty?.selectedProperty
        ? privateProperty.selectedProperty(value)
        : null;
      return {
        checkProperty: privateProperty?.checkProperty || null,
        conditionProperty: privateProperty?.conditionProperty || null,
        selectedProperty: selectedProperty || null,
      };
    }
  }
  return defaultPrivateProperties;
};

const GetDataAndStopIfActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default GetDataAndStopIfActionForm;
