// Supermove
import {useContext} from '@supermove/hooks';
import {invariant} from '@supermove/utils';

// App
import {AppContext} from 'modules/App/context/AppContextProvider';

const useAppContext = () => {
  const context = useContext(AppContext);
  invariant(context, 'useAppContext must be used within a AppContextProvider');

  return context;
};

export default useAppContext;
