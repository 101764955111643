// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

const Row = Styled.View`
  flex-direction: row;
`;

const Text = Styled.Text`
  ${Typography.Body3}
  color: ${({color}) => color};
`;

const EmptySpace = Styled.View`
  flex: 6;
`;

const BillTipsContainer = Styled.View`
  flex: 7;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const NameContainer = Styled.View`
  flex: 1;
  padding-left: 12px;
  flex-direction: row;
  align-items: center;
`;

const NameRow = Styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: ${(props) => (props.mobile ? 'flex-start' : 'flex-end')};
`;

const AmountContainer = Styled.View`
  justify-content: center;
  align-items: flex-end;
`;

const BillTipName = ({billTip, isFirst}) => {
  const responsive = useResponsive();
  return (
    <NameContainer isFirst={isFirst} {...responsive}>
      <NameRow {...responsive}>
        <Space width={8} />
        <Text color={colors.gray.primary}>Tip</Text>
        {!!billTip.name && (
          <React.Fragment>
            <Space width={4} />
            <Text color={colors.gray.primary} numberOfLines={1}>{`(${billTip.name}`}</Text>
            <Text color={colors.gray.primary}>{`)`}</Text>
          </React.Fragment>
        )}
      </NameRow>
    </NameContainer>
  );
};

const BillTipAmount = ({billTip, isFirst}) => {
  const responsive = useResponsive();

  return (
    <AmountContainer isFirst={isFirst} style={!responsive.mobile && {flex: 1}}>
      {!!billTip && (
        <Text color={colors.gray.primary}>{Currency.format({value: billTip.amount})}</Text>
      )}
    </AmountContainer>
  );
};

const BillTip = ({billTip, isFirst}) => {
  return (
    <Row>
      <BillTipName billTip={billTip} isFirst={isFirst} />
      <BillTipAmount billTip={billTip} isFirst={isFirst} />
    </Row>
  );
};

const BillingDocumentBillBillTips = ({bill}) => {
  const responsive = useResponsive();
  return (
    <Row>
      {!responsive.mobile && <EmptySpace />}
      <BillTipsContainer>
        {!_.isEmpty(bill.billTips) &&
          bill.billTips.map((billTip, index) => {
            const isFirst = index === 0;
            return (
              <React.Fragment key={billTip.id}>
                {!isFirst && <Space height={8} />}
                <BillTip billTip={billTip} isFirst={isFirst} />
              </React.Fragment>
            );
          })}
      </BillTipsContainer>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingDocumentBillBillTips.fragment = gql`
  fragment BillingDocumentBillBillTips on Bill {
    id
    billTips {
      id
      name
      amount
    }
  }
`;

export default BillingDocumentBillBillTips;
