// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getAssigneeDropdownValue = withFragment(
  ({assignedToUserId, assignedToTeamId, assignedToRole}) => {
    if (assignedToUserId) {
      return `USER-${assignedToUserId}`;
    }
    if (assignedToTeamId) {
      return `TEAM-${assignedToTeamId}`;
    }
    return assignedToRole || '';
  },
  gql`
    fragment TaskTemplate_getAssigneeDropdownValue on TaskTemplate {
      id
      assignedToUserId
      assignedToTeamId
      assignedToRole
    }
  `,
);

const TaskTemplate = {
  getAssigneeDropdownValue,
};

export default TaskTemplate;
