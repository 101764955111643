// Libraries
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

import CrewForm from '@shared/modules/Dispatch/forms/CrewForm';

const edit = withFragment(
  (job) => ({
    crewForms: job.crews.map((crew) => CrewForm.edit(crew)),
    jobId: job.id,
  }),
  gql`
    ${CrewForm.edit.fragment}
    fragment CreateCrewsForJobForm_edit on Job {
      id
      crews {
        id
        ...CrewForm_edit
      }
    }
  `,
);

const toForm = ({crewForms, jobId}) => ({
  crewForms: crewForms.map((crewForm) => CrewForm.toForm(crewForm)),
  jobId,
});

const toMutation = ({crewForms, jobId}) => ({
  crewForms: crewForms.map((crewForm) => CrewForm.toMutation(crewForm)),
  jobId,
});

const CreateCrewsForJobForm = {
  edit,
  toForm,
  toMutation,
};

export default CreateCrewsForJobForm;
