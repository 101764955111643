// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Dropdown, Styled, Space, Tooltip} from '@supermove/components';
import {useHover, useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import DropdownSheet from '@shared/design/components/DropdownInput/components/DropdownSheet';
import SearchBar from '@shared/design/components/SearchBar';

const Container = Styled.View`
`;

const ActionButtonContainer = Styled.View`
  padding-vertical: 12px;
  background-color: ${colors.white};
  width: 100%;
`;

const SearchContainer = Styled.View`
  padding-horizontal: 12px;
`;

const ActionButton = Styled.ButtonV2`
  padding-vertical: ${({isDense}: {isDense: boolean}) => (isDense ? '6' : '12')}px;
  padding-right: 12px;
  padding-left: 12px;
  background-color: ${({color}: {color: string}) => color};
`;

const ActionText = Styled.Text`
  ${Typography.Body3};
  color: ${(props: {color?: string}) => (props.color ? props.color : colors.gray.primary)};
`;

const TooltipText = Styled.Text`
  ${Typography.Body};
  color: ${colors.white};
`;

const Line = Styled.View`
  flex: 1;
  height: 1px;
  background-color: ${colors.gray.border};
`;

const IconButton = Styled.ButtonV2`
  width: 16px;
  height: 16px;
  align-items: center;
`;

const MENU_POSITION = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  TOP: 'TOP',
  CENTER: 'CENTER',
};

const getMenuPositionStyle = ({
  menuPosition,
  isSmall,
}: {
  menuPosition: string;
  isSmall: boolean;
}) => {
  switch (menuPosition) {
    case MENU_POSITION.LEFT:
      return {left: 0};
    case MENU_POSITION.RIGHT:
      return {right: 0};
    case MENU_POSITION.TOP:
      return {top: 0, transform: `translateY(calc(-100% - ${isSmall ? 36 : 40}px))`};
    case MENU_POSITION.CENTER:
      return {left: '50%', transform: 'translateX(-50%)'};
    default:
      return {};
  }
};

const getIconRight = ({isVisibleArrow, isOpen}: {isVisibleArrow: boolean; isOpen: boolean}) => {
  if (!isVisibleArrow) {
    return null;
  }
  if (isOpen) {
    return Icon.ChevronUp;
  }
  return Icon.ChevronDown;
};

const getActionButtonColor = ({
  isSelected,
  isHovered,
}: {
  isSelected: boolean;
  isHovered: boolean;
}) => {
  if (isSelected) {
    return colors.blue.interactive;
  }
  if (isHovered) {
    return colors.hover;
  }
  return colors.white;
};

const getTextColor = ({
  isDisabled,
  isSelected,
  textColor,
}: {
  isDisabled: boolean;
  isSelected: boolean;
  textColor: string;
}) => {
  if (isDisabled) {
    return colors.gray.tertiary;
  }
  if (isSelected) {
    return colors.white;
  }
  return textColor;
};

const TooltipWrapper = ({tooltip, children}: {tooltip: string; children: React.ReactNode}) => {
  if (tooltip) {
    return (
      <Tooltip
        overlay={<TooltipText>{tooltip}</TooltipText>}
        placement={'left'}
        mouseEnterDelay={0.0}
        mouseLeaveDelay={0.0}
      >
        {children}
      </Tooltip>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const ActionItem = ({
  textColor,
  onPress,
  text,
  tooltip,
  isDisabled,
  isSelected,
  handleClose,
  onSelect,
  style,
  isDense,
}: {
  textColor: string;
  onPress: () => void;
  text: string;
  tooltip: string;
  isDisabled: boolean;
  isSelected: boolean;
  handleClose: () => void;
  onSelect: () => void;
  style: object;
  isDense: boolean;
}) => {
  const {isHovered, ref} = useHover();

  return (
    <TooltipWrapper tooltip={tooltip}>
      <ActionButton
        ref={ref}
        color={getActionButtonColor({isSelected, isHovered})}
        disabled={isDisabled}
        onPress={() => {
          onPress();
          onSelect();
          handleClose();
        }}
        style={style}
        isDense={isDense}
      >
        <ActionText color={getTextColor({isDisabled, isSelected, textColor})}>{text}</ActionText>
      </ActionButton>
    </TooltipWrapper>
  );
};

const DropdownContainer = ({
  dropdownContainerStyle,
  isVisible,
  isSearchable,
  setSearchTerm,
  children,
}: {
  dropdownContainerStyle?: object;
  isVisible?: boolean;
  isSearchable?: boolean;
  setSearchTerm: Function;
  children?: React.ReactNode;
}) => {
  return isVisible ? (
    <React.Fragment>
      {isSearchable ? (
        <Container
          style={{
            backgroundColor: colors.white,
            overflow: 'hidden',
            position: 'absolute',
            top: '100%',
            ...dropdownContainerStyle,
          }}
        >
          <Space height={12} />
          <SearchContainer>
            <SearchBar
              autoFocus
              placeholder={'Search...'}
              onChangeText={(text) => {
                setSearchTerm(text);
              }}
              style={{flex: 1}}
            />
          </SearchContainer>
          <Space height={12} />
          <Line />
          {children}
        </Container>
      ) : (
        children
      )}
    </React.Fragment>
  ) : null;
};

const dropdownContainerStyle = {
  borderWidth: '1px',
  borderColor: colors.gray.border,
  borderRadius: 4,
  boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.15)',
  marginTop: 4,
};

export interface DropdownButtonProps {
  children?: React.ReactNode;
  isDisabled?: boolean;
  isWidthOfContainer?: boolean;
  width?: number | string | null;
  menuWidth?: number | null;
  menuPosition?: string;
  buttonStyle?: object;
  style?: object;
  actions: any[];
  ButtonComponent?: React.FC<any>;
  isVisibleArrow?: boolean;
  iconLeft?: string | null;
  isSmall?: boolean;
  isSubmitting?: boolean;
  isResponsive?: boolean;
  onSelect?: () => void;
  actionButtonContainerStyle?: object;
  ActionItemComponent?: React.FC<any>;
  sheetLabel?: string;
  value?: any;
  maxDropdownHeight?: number | null;
  isDense?: boolean;
  isSearchable?: boolean;
  text: string;
  menuStyle?: object;
  isMobileEllipsis?: boolean;
  innerRef?: any;
}

const DropdownButton = ({
  children,
  isDisabled = false,
  buttonStyle = {},
  style = {},
  actions = [],
  isWidthOfContainer = false,
  ButtonComponent = Button,
  width = null,
  menuWidth = null,
  menuPosition = MENU_POSITION.LEFT,
  text,
  isVisibleArrow = true,
  iconLeft = null,
  isSmall = false,
  isSubmitting = false,
  isResponsive = false,
  onSelect = () => {},
  actionButtonContainerStyle = {},
  ActionItemComponent = ActionItem,
  sheetLabel = 'Actions',
  value,
  maxDropdownHeight = null,
  isDense = false,
  isSearchable = false,
  menuStyle = {},
  isMobileEllipsis = false,
  innerRef = null,
}: DropdownButtonProps) => {
  const responsive = useResponsive();
  const isDropdownSheet = !responsive.desktop;
  const buttonWidth = isWidthOfContainer ? '100%' : width;
  const [searchTerm, setSearchTerm] = useState('');
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const handleCloseSheet = () => {
    setIsSheetOpen(false);
    setSearchTerm('');
  };

  const filteredActions = searchTerm
    ? actions.filter((action) => action.text.toLowerCase().includes(searchTerm.toLowerCase()))
    : actions;
  return (
    <React.Fragment>
      <Container style={style} ref={innerRef}>
        <Dropdown
          trigger={({handleToggle, isOpen}: {handleToggle: () => void; isOpen: boolean}) => (
            <React.Fragment>
              {isMobileEllipsis && responsive.mobile ? (
                <IconButton
                  onPress={() => {
                    if (isDropdownSheet) {
                      setIsSheetOpen(true);
                    } else {
                      handleToggle();
                      setSearchTerm('');
                    }
                  }}
                >
                  <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
                </IconButton>
              ) : (
                <ButtonComponent
                  isSmall={isSmall}
                  isSubmitting={isSubmitting}
                  isWidthOfContainer={isWidthOfContainer}
                  isDisabled={isDisabled}
                  iconLeft={iconLeft}
                  iconRight={getIconRight({isVisibleArrow, isOpen})}
                  style={{
                    ...buttonStyle,
                    width: buttonWidth,
                  }}
                  onPress={() => {
                    if (isDropdownSheet) {
                      setIsSheetOpen(true);
                    } else {
                      handleToggle();
                      setSearchTerm('');
                    }
                  }}
                  text={text}
                  isResponsive={isResponsive}
                >
                  {children}
                </ButtonComponent>
              )}
            </React.Fragment>
          )}
        >
          {({isOpen, handleClose}: {isOpen: boolean; handleClose: () => void}) => {
            const isVisible = isOpen && !isDropdownSheet;
            return (
              <DropdownContainer
                dropdownContainerStyle={dropdownContainerStyle}
                isVisible={isVisible}
                isSearchable={isSearchable}
                setSearchTerm={setSearchTerm}
              >
                <Dropdown.Content
                  key={isOpen}
                  isVisible={isVisible}
                  style={{
                    position: isSearchable ? 'relative' : 'absolute',
                    width: menuWidth || buttonWidth,
                    maxHeight: maxDropdownHeight || 'none',
                    ...(isSearchable ? {} : dropdownContainerStyle),
                    ...getMenuPositionStyle({menuPosition, isSmall}),
                    ...menuStyle,
                  }}
                >
                  <ActionButtonContainer style={actionButtonContainerStyle}>
                    {_.isEmpty(filteredActions) && (
                      <Container style={{paddingHorizontal: 12}}>
                        <ActionText color={colors.gray.disabled}>No items</ActionText>
                      </Container>
                    )}
                    {filteredActions.map((item: any, index: number) => {
                      return (
                        <ActionItemComponent
                          {...item}
                          key={index}
                          index={index}
                          handleClose={() => {
                            handleClose();
                            setSearchTerm('');
                          }}
                          onSelect={onSelect}
                          isDense={isDense}
                        />
                      );
                    })}
                  </ActionButtonContainer>
                </Dropdown.Content>
              </DropdownContainer>
            );
          }}
        </Dropdown>
      </Container>
      {isDropdownSheet && (
        <DropdownSheet
          isOpen={isSheetOpen}
          handleClose={handleCloseSheet}
          headerText={sheetLabel}
          options={DropdownSheet.formatActionsForDropdownSheet({
            actions,
            handleClose: handleCloseSheet,
          })}
          isSearchable={false}
          value={value}
        />
      )}
    </React.Fragment>
  );
};

DropdownButton.MENU_POSITION = MENU_POSITION;
DropdownButton.ActionItem = ActionItem;

// --------------------------------------------------
// Props
// --------------------------------------------------
ActionItem.propTypes = {
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  handleClose: PropTypes.func,
  onSelect: PropTypes.func,
  style: PropTypes.object,
};

ActionItem.defaultProps = {
  textColor: null,
  isDisabled: false,
  isSelected: false,
  handleClose: () => {},
  onSelect: () => {},
  style: null,
};

DropdownButton.propTypes = {
  isDisabled: PropTypes.bool,
  isWidthOfContainer: PropTypes.bool,
  width: PropTypes.number,
  menuWidth: PropTypes.number,
  menuPosition: PropTypes.string,
  buttonStyle: PropTypes.object,
  style: PropTypes.object,
  actions: PropTypes.array.isRequired,
  ButtonComponent: PropTypes.func,
  isVisibleArrow: PropTypes.bool,
  iconLeft: PropTypes.string,
  isSmall: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isResponsive: PropTypes.bool,
  onSelect: PropTypes.func,
  actionButtonContainerStyle: PropTypes.object,
  ActionItemComponent: PropTypes.func,
  sheetLabel: PropTypes.string,
  maxDropdownHeight: PropTypes.number,
  isDense: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isMobileEllipsis: PropTypes.bool,
};

DropdownButton.defaultProps = {
  isDisabled: false,
  isWidthOfContainer: false,
  width: null,
  menuWidth: null,
  menuPosition: MENU_POSITION.LEFT,
  buttonStyle: {},
  style: {},
  ButtonComponent: Button,
  isVisibleArrow: true,
  iconLeft: null,
  isSmall: false,
  isSubmitting: false,
  isResponsive: false,
  onSelect: () => {},
  actionButtonContainerStyle: {},
  ActionItemComponent: ActionItem,
  sheetLabel: 'Actions',
  maxDropdownHeight: null,
  isDense: false,
  isSearchable: false,
  isMobileEllipsis: false,
};

export default DropdownButton;
