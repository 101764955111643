// Libraries
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Sheet from '@shared/design/components/Sheet';

const BodyContainer = Styled.View`
  padding-horizontal: 16px;
  padding-top: 8px;
  padding-bottom: 16px;
`;

const FooterActionsContainer = Styled.View`
  flex-direction: ${({isVerticallyStackedFooter}: any) =>
    isVerticallyStackedFooter ? 'column-reverse' : 'row'};
  padding-horizontal: 16px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const LoadingIndicator = Styled.Loading`
`;

export interface SheetFooterProps {
  handlePrimaryAction: () => void;
  primaryActionText: string;
  primaryActionIcon?: string;
  primaryActionColor?: string;
  handleSecondaryAction?: () => void;
  secondaryActionText?: string;
  secondaryActionIcon?: string;
  isSubmitting?: boolean;
  isDisabled: boolean;
  isVerticallyStackedFooter?: boolean;
}

const SheetFooter = ({
  handlePrimaryAction,
  primaryActionText,
  primaryActionIcon,
  primaryActionColor,
  handleSecondaryAction,
  secondaryActionText,
  secondaryActionIcon,
  isSubmitting,
  isDisabled,
  isVerticallyStackedFooter,
}: SheetFooterProps) => {
  return (
    <React.Fragment>
      <Line />
      <Space height={16} />
      <FooterActionsContainer isVerticallyStackedFooter={isVerticallyStackedFooter}>
        {handleSecondaryAction && (
          <React.Fragment>
            <SecondaryButton
              text={secondaryActionText}
              iconLeft={secondaryActionIcon}
              onPress={handleSecondaryAction}
              isDisabled={isSubmitting}
              isResponsive
              isWidthOfContainer
              style={{flex: 1}}
            />
            <Space width={16} height={16} />
          </React.Fragment>
        )}
        <Button
          text={primaryActionText}
          iconLeft={primaryActionIcon}
          onPress={handlePrimaryAction}
          isDisabled={isDisabled}
          isSubmitting={isSubmitting}
          isResponsive
          isWidthOfContainer
          style={{flex: 1}}
          color={primaryActionColor || colors.blue.interactive}
        />
      </FooterActionsContainer>
      <Space height={16} />
    </React.Fragment>
  );
};

export interface SheetWithFooterProps {
  isOpen: boolean;
  handleClose: () => void;
  handleGoBack?: () => void;
  shouldCloseOnClickOutside?: boolean;
  headerText: string;
  description?: string | React.ReactElement;
  headerStyle?: object;
  style?: object;
  isFixedHeight?: boolean;
  height?: string;
  isUsingLoadingComponent?: boolean;
  headerComponent?: React.ReactElement;
  HeaderLeftComponent?: React.ReactNode;
  HeaderRightComponent?: React.ReactNode;
  bodyContainerStyle?: object;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  isFixedFooter?: boolean;
  isVerticallyStackedFooter?: boolean;
  handlePrimaryAction?: () => void;
  primaryActionText: string;
  primaryActionIcon?: string;
  primaryActionColor?: string;
  handleSecondaryAction?: () => void;
  secondaryActionText?: string;
  secondaryActionIcon?: string;
  scrollViewRef?: object;
  children: any;
}

const SheetWithFooter = ({
  isOpen = false,
  handleClose = () => {},
  handleGoBack,
  shouldCloseOnClickOutside = true,
  headerComponent,
  headerText = '',
  description,
  headerStyle,
  style,
  isFixedHeight = false,
  height,
  isUsingLoadingComponent = false,
  HeaderLeftComponent,
  HeaderRightComponent,

  // SheetWithFooter props
  bodyContainerStyle,
  isSubmitting = false,
  isDisabled = false,
  isFixedFooter = false,
  isVerticallyStackedFooter = false,
  handlePrimaryAction = () => {},
  primaryActionText = '',
  primaryActionIcon,
  primaryActionColor = colors.blue.interactive,
  handleSecondaryAction,
  secondaryActionText = '',
  secondaryActionIcon,
  scrollViewRef,
  children,
}: SheetWithFooterProps) => {
  const FooterComponent = () => {
    return (
      <SheetFooter
        handlePrimaryAction={handlePrimaryAction}
        primaryActionText={primaryActionText}
        primaryActionIcon={primaryActionIcon}
        handleSecondaryAction={handleSecondaryAction}
        secondaryActionText={secondaryActionText}
        secondaryActionIcon={secondaryActionIcon}
        isSubmitting={isSubmitting}
        isDisabled={isDisabled}
        isVerticallyStackedFooter={isVerticallyStackedFooter}
        primaryActionColor={primaryActionColor}
      />
    );
  };

  return (
    <Sheet
      isOpen={isOpen}
      handleClose={handleClose}
      handleGoBack={handleGoBack}
      shouldCloseOnClickOutside={shouldCloseOnClickOutside}
      headerComponent={headerComponent}
      headerText={headerText}
      description={description}
      headerStyle={headerStyle}
      style={style}
      isFixedHeight={isFixedHeight}
      height={height}
      isDisabledClose={isSubmitting}
      // When using the loading component it will open as a completely separate component
      // from this sheet that contains our content. The loading pattern is then to close
      // that sheet and open this one when loading is completed. So for animations we want
      // the loading component to animate in, then we want no animation when this sheet
      // swaps in for the loading component, and then we want this sheet to animate out.
      isAnimated={!isUsingLoadingComponent || !isOpen}
      HeaderLeftComponent={HeaderLeftComponent as any}
      HeaderRightComponent={HeaderRightComponent as any}
    >
      <ScrollView style={{flex: 1}} ref={scrollViewRef}>
        <BodyContainer style={bodyContainerStyle}>{children}</BodyContainer>
        {!isFixedFooter && <FooterComponent />}
      </ScrollView>
      {isFixedFooter && <FooterComponent />}
    </Sheet>
  );
};

export interface LoadingComponentProps {
  isOpen: boolean;
  handleClose: () => void;
  headerText: string;
  description?: string | React.ReactElement;
  isFixedHeight: boolean;
  height?: string;
  primaryActionText: string;
  primaryActionIcon?: string;
  secondaryActionText?: string;
  secondaryActionIcon?: string;
  handleSecondaryAction?: () => void;
  shouldCloseOnClickOutside?: boolean;
  isVerticallyStackedFooter?: boolean;
  style?: object;
}

const LoadingComponent = ({
  isOpen = false,
  handleClose = () => {},
  headerText = '',
  description,
  isFixedHeight = false,
  height,
  primaryActionText = '',
  primaryActionIcon,
  secondaryActionText = '',
  secondaryActionIcon,
  handleSecondaryAction,
  shouldCloseOnClickOutside = true,
  isVerticallyStackedFooter = false,
  style,
}: LoadingComponentProps) => {
  return (
    <Sheet
      headerText={headerText}
      description={description}
      isOpen={isOpen}
      handleClose={handleClose}
      isFixedHeight={isFixedHeight}
      height={height}
      shouldCloseOnClickOutside={shouldCloseOnClickOutside}
      // See comment for 'isAnimated' in the SheetWithFooter component to understand
      // how 'isAnimated' is used here.
      isAnimated={isOpen}
      style={style}
    >
      <BodyContainer style={{flex: 1, justifyContent: 'center'}}>
        <LoadingIndicator size={'large'} color={colors.gray.secondary} />
      </BodyContainer>
      <SheetFooter
        handlePrimaryAction={() => {}}
        primaryActionText={primaryActionText}
        primaryActionIcon={primaryActionIcon}
        handleSecondaryAction={handleSecondaryAction}
        secondaryActionText={secondaryActionText}
        secondaryActionIcon={secondaryActionIcon}
        isDisabled
        isVerticallyStackedFooter={isVerticallyStackedFooter}
      />
    </Sheet>
  );
};

SheetWithFooter.HeaderActionIcon = Sheet.HeaderActionIcon;
SheetWithFooter.PreventPropagationContainer = Sheet.PreventPropagationContainer;
SheetWithFooter.LoadingComponent = LoadingComponent;

export default SheetWithFooter;
