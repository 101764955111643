// App
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Forms
import PayableItemForm from './PayableItemForm';

const PayableUserDayForm = {
  toForm: ({payableUserDayId, payableItemForms}) => ({
    payableUserDayId,
    payableItemForms: payableItemForms.map((payableItemForm) => {
      return PayableItemForm.toForm(payableItemForm);
    }),
  }),

  toMutation: ({payableUserDayId, payableItemForms}) => ({
    payableUserDayId,
    payableItemForms: payableItemForms.map((payableItemForm) => {
      return PayableItemForm.toMutation(payableItemForm);
    }),
  }),

  edit: withFragment(
    (payableUserDay) => ({
      payableUserDayId: payableUserDay.id,
      payableItemForms: payableUserDay.payableItems.map((payableItem) => {
        return PayableItemForm.edit(payableItem);
      }),
    }),
    gql`
      ${PayableItemForm.edit.fragment}

      fragment PayableUserDayForm_edit on PayableUserDay {
        id
        payableItems {
          ...PayableItemForm_edit
        }
      }
    `,
  ),
};

export default PayableUserDayForm;
