// App
import {HTML} from '@supermove/utils';

const _new = ({jobId, subject, body, attachmentIds}) => {
  return {
    jobId,
    subject,
    body,
    attachmentIds,
  };
};

const toForm = ({jobId, subject, body, attachmentIds}) => ({
  jobId,
  subject,
  body,
  attachmentIds,
});

const toMutation = ({jobId, subject, body, attachmentIds}) => ({
  jobId,
  subject,
  body: HTML.fixRichTextEditor(body),
  attachmentIds,
});

const SendEmailForJobForm = {
  toForm,
  toMutation,
  new: _new,
};

export default SendEmailForJobForm;
