// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({organizationId, userId, kind = null}) => {
  return {
    name: '',
    dataTableId: null,
    organizationId,
    sourceId: null,
    data: null,
    createdById: userId,
    updatedById: userId,
    kind,
  };
};

const edit = withFragment(
  (dataTable, {userId}) => ({
    name: dataTable.name,
    dataTableId: dataTable.id,
    organizationId: dataTable.organizationId,
    sourceId: dataTable.sourceId,
    data: {name: dataTable.filename},
    createdById: dataTable.createdById,
    updatedById: userId,
    kind: dataTable.kind,
  }),
  gql`
    fragment DataTableForm_edit on DataTable {
      id
      name
      organizationId
      sourceId
      createdById
      updatedById
      filename
      kind
    }
  `,
);

const toForm = ({
  dataTableId,
  organizationId,
  sourceId,
  data,
  createdById,
  updatedById,
  name,
  kind,
}) => ({
  dataTableId,
  organizationId,
  sourceId,
  data,
  createdById,
  updatedById,
  name,
  kind,
});

const toMutation = ({
  dataTableId,
  organizationId,
  sourceId,
  data,
  createdById,
  updatedById,
  name,
  kind,
}) => ({
  dataTableId,
  organizationId,
  sourceId,
  data: data instanceof File ? data : null, // If the data is not a file then it means the user is just editing the name of an existing DataTable object
  createdById,
  updatedById,
  name,
  kind,
});

const DataTableForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default DataTableForm;
