// Libararies
import React from 'react';

// Supermove
import {Styled, Space, Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useQuery,
  useNavigationDOM,
  usePopover,
  useModal,
  useHover,
  useSidebar,
} from '@supermove/hooks';
import {Location, Job, JobUser, Tag} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Table from '@shared/design/components/Table';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import TextTooltip from 'modules/App/components/TextTooltip';
import JobDispatchNotesPopover from 'modules/Dispatch/Calendar/Day/components/JobDispatchNotesPopover';
import CrewTrucksPopover from 'modules/Dispatch/Crew/components/CrewTrucksPopover';
import CrewUsersAndDriversPopover from 'modules/Dispatch/Crew/components/CrewUsersAndDriversPopover';
import JobArrivalTime from 'modules/Job/JobCard/components/JobArrivalTime';
import JobDispatchNotes from 'modules/Job/JobCard/components/JobDispatchNotes';
import JobDispatchStatus from 'modules/Job/JobCard/components/JobDispatchStatus';
import JobProjectComments from 'modules/Job/JobCard/components/JobProjectComments';
import CreateCrewsForJobButton from 'modules/Job/V2/Move/components/CreateCrewsForJobButton';
import CreateCrewsForJobPopoverContent from 'modules/Job/V2/Move/components/CreateCrewsForJobPopoverContent';
import JobJobUsersScheduleModal from 'modules/Job/V2/Move/components/JobJobUsersScheduleModal';
import ViewLaborSourcesPopoverContent from 'modules/Job/V2/Move/components/ViewLaborSourcesPopoverContent';
import TagEmojiButton from 'modules/Tag/components/TagEmojiButton';
import UpsertJobTagModal from 'modules/Tag/components/UpsertJobTagModal';

const Container = Styled.View`
  flex: 1;
`;

const TableHeaderContainer = Styled.View`
  flex-direction: column;
  align-items: flex-start;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const FullLengthRow = Styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${colors.gray.background};
  border-radius: 4px;
  border: 1px solid ${colors.gray.border};
  padding: 8px;
  margin: 8px;
`;

const ContainerWithBorder = Styled.View`
  display: flex;
  border: 1px solid ${({isHovered, isModalOpen}) =>
    isHovered || isModalOpen ? colors.blue.interactive : colors.gray.border};
  border-radius: 4px;
  padding-horizontal: 8px;
`;

const ProjectTypeColorIndicator = Styled.Text`
  color: ${({color}) => color};
`;

const LinkWrapper = Styled.ButtonV2`
  flex: 1;
`;

const DispatchCountText = Styled.Text`
  ${Typography.Micro}
  color: ${({isModalOpen}) => (isModalOpen ? colors.blue.interactive : colors.gray.primary)};
`;

const RowWrap = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`;

const FlexContainer = Styled.View`
  flex: ${({flex}) => flex};
`;

const DispatchNotesContainer = Styled.View`
  max-width: 120px;
`;

const JobTagButton = ({job, refetch}) => {
  const upsertJobTagModal = useModal({name: 'Upsert Job Tag Modal', enableTracking: true});
  const tags = Tag.getJobAndProjectTagAndTagAssignment(job);
  const tagIds = tags.map((tag) => tag.id);

  const refetchDayViewAndTable = () => {
    refetch({shouldFetchJobsListTable: true});
  };

  return (
    <React.Fragment>
      <TagEmojiButton
        tags={tags}
        numberOfVisibleSelections={2}
        upsertTagModal={upsertJobTagModal}
      />
      <UpsertJobTagModal
        key={`${upsertJobTagModal.key}_${job.id}`}
        tagIds={tagIds}
        job={job}
        refetch={refetchDayViewAndTable}
        isOpen={upsertJobTagModal.isOpen}
        handleClose={upsertJobTagModal.handleClose}
      />
    </React.Fragment>
  );
};

const getCapacityCalendarJobsListTableColumnDefinitions = ({navigator, refetch}) => [
  {
    flex: 0.2,
    headerContent: () => {
      return null;
    },
    cellContent: ({item: job}) => {
      return (
        <Table.PreventPropagationContainer>
          <Row>
            <JobDispatchStatus job={job} />
          </Row>
          {job.organization.features.isEnabledProjectTag && (
            <React.Fragment>
              <Space height={6} />
              <Row>
                <JobTagButton job={job} refetch={refetch} />
              </Row>
            </React.Fragment>
          )}
        </Table.PreventPropagationContainer>
      );
    },
  },
  {
    flex: 2.5,
    headerContent: () => {
      return (
        <TableHeaderContainer>
          <Table.HeaderText numberOfLines={1}>Job</Table.HeaderText>
          <Table.HeaderSubtext numberOfLines={1}>Job Type</Table.HeaderSubtext>
        </TableHeaderContainer>
      );
    },
    cellContent: ({item: job}) => {
      return (
        <Container>
          <Row>
            <Table.CellText numberOfLines={1}>
              <ProjectTypeColorIndicator color={job.project.projectType.color}>
                •
              </ProjectTypeColorIndicator>
              <Space width={6} />
              {job.project.projectType.name} {job.identifier}
            </Table.CellText>
          </Row>
          <Row>
            <Space width={13} />
            <Table.CellSubtext numberOfLines={1}>{job.jobType.name}</Table.CellSubtext>
          </Row>
        </Container>
      );
    },
  },
  {
    flex: 3,
    headerContent: () => {
      return (
        <TableHeaderContainer>
          <Table.HeaderText numberOfLines={1}>Origin & Destination</Table.HeaderText>
          <Table.HeaderSubtext numberOfLines={1}>Mileage</Table.HeaderSubtext>
        </TableHeaderContainer>
      );
    },
    cellContent: ({item: job}) => {
      return (
        <Container>
          <Row>
            <Icon source={Icon.MapPin} color={colors.gray.secondary} size={10} />
            <Space width={6} />
            <Table.CellText numberOfLines={1}>
              {Location.getDisplayCityState(job.startLocation)}
              {' - '}
              {Location.getDisplayCityState(job.endLocation)}
            </Table.CellText>
          </Row>
          <Row>
            <Icon source={Icon.Road} color={colors.gray.secondary} size={10} />
            <Space width={6} />
            <Table.CellSubtext>{Job.getTotalDistanceText(job)}</Table.CellSubtext>
          </Row>
        </Container>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return (
        <Container>
          <Table.HeaderText numberOfLines={1}>Customer Name</Table.HeaderText>
          <Table.HeaderSubtext numberOfLines={1}>Weight / Volume</Table.HeaderSubtext>
        </Container>
      );
    },
    cellContent: ({item: job}) => {
      const {client} = job.project;
      const isPrimaryClientNameDifferent = client.name !== client.primaryContact.fullName;
      const clientDisplayText = `${client.name}${
        isPrimaryClientNameDifferent ? ` (${client.primaryContact.fullName})` : ''
      }`;
      return (
        <Container>
          <Row>
            <Icon source={Icon.User} color={colors.gray.secondary} size={10} />
            <Space width={6} />
            <LinkWrapper onPress={() => navigator.push(`/clients/${job.project.client.uuid}`)}>
              <Table.CellLink numberOfLines={1}>{clientDisplayText}</Table.CellLink>
            </LinkWrapper>
          </Row>
          <Row>
            <Icon source={Icon.WeightHanging} color={colors.gray.secondary} size={10} />
            <Space width={6} />
            <Table.CellSubtext>{job.project.weight} lbs</Table.CellSubtext>
            <Space width={12} />
            <Icon source={Icon.RulerTriangle} color={colors.gray.secondary} size={10} />
            <Space width={6} />
            <Table.CellSubtext>{job.project.volume} ft³</Table.CellSubtext>
          </Row>
        </Container>
      );
    },
  },
  {
    flex: 1.75,
    minWidth: 120,
    headerContent: () => {
      return (
        <TableHeaderContainer>
          <Table.HeaderText numberOfLines={1}>Arrival Time</Table.HeaderText>
          <Table.HeaderSubtext numberOfLines={1}>Est. Hours</Table.HeaderSubtext>
        </TableHeaderContainer>
      );
    },
    cellContent: ({item: job}) => {
      return (
        <Container>
          <Row>
            <Icon source={Icon.Clock} color={colors.gray.secondary} size={10} />
            <Space width={6} />
            <Table.CellText>
              <JobArrivalTime job={job} isLarge />
            </Table.CellText>
          </Row>
          <Row>
            <Icon source={Icon.HourglassClock} color={colors.gray.secondary} size={10} />
            <Space width={6} />
            <Table.CellSubtext>{job.estimatedHourRange}</Table.CellSubtext>
          </Row>
        </Container>
      );
    },
  },
  {
    flex: 0.75,
    headerContent: () => {
      return (
        <TableHeaderContainer>
          <Table.HeaderText numberOfLines={1}>Trucks</Table.HeaderText>
          <Table.HeaderSubtext numberOfLines={1}>Movers</Table.HeaderSubtext>
        </TableHeaderContainer>
      );
    },
    cellContent: ({item: job}) => {
      const moverNamesText = job.assignedJobUsers
        .map((jobUser) => jobUser.user.fullName)
        .join('\n');

      const truckNamesText = job.jobTrucks.map((jobTruck) => jobTruck.truck.name).join('\n');

      return (
        <Container>
          <TextTooltip text={truckNamesText || 'No trucks assigned'} placement={'left'}>
            <Row>
              <Icon source={Icon.Truck} color={colors.gray.secondary} size={10} />
              <Space width={6} />
              <Table.CellText>{Job.getDispatchJobTrucksCount(job)}</Table.CellText>
            </Row>
          </TextTooltip>
          <Space width={8} />
          <TextTooltip text={moverNamesText || 'No movers assigned'} placement={'left'}>
            <Row>
              <Icon source={Icon.User} color={colors.gray.secondary} size={12} />
              <Space width={6} />
              <Table.CellSubtext>{Job.getDispatchJobUsersCount(job)}</Table.CellSubtext>
            </Row>
          </TextTooltip>
        </Container>
      );
    },
  },
  {
    flex: 1.75,
    headerContent: () => {
      return (
        <TableHeaderContainer>
          <Table.HeaderText numberOfLines={1}>Comments</Table.HeaderText>
          <Table.HeaderSubtext numberOfLines={1}>Notes</Table.HeaderSubtext>
        </TableHeaderContainer>
      );
    },
    cellContent: ({item: job}) => {
      return (
        <Table.PreventPropagationContainer>
          <CommentsAndNotesTableRow job={job} />
        </Table.PreventPropagationContainer>
      );
    },
  },
];

const CommentsAndNotesTableRow = ({job}) => {
  const projectCommentsSidebar = useSidebar({
    name: 'ProjectCommentsSidebar',
    enableTracking: true,
  });
  const jobDispatchNotesPopover = usePopover();
  return (
    <React.Fragment>
      <Row>
        <JobProjectComments job={job} sidebar={projectCommentsSidebar} hideTooltip />
      </Row>
      <Space height={8} />
      <Row>
        <DispatchNotesContainer>
          <JobDispatchNotes job={job} popover={jobDispatchNotesPopover} numberOfLines={1} />
        </DispatchNotesContainer>
      </Row>
      <JobDispatchNotesPopover
        job={job}
        popover={jobDispatchNotesPopover}
        placement={Popover.Positions.Left}
        refetch={() => null}
      />
    </React.Fragment>
  );
};

const DispatchLaborSourcePopover = ({popover, job, viewer, refetch}) => {
  const isContractor = viewer.viewingOrganization.id !== job.project.organization.id;
  return (
    <Popover
      placement={Popover.Positions.BottomEnd}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={320} maxHeight={700}>
        {isContractor ? (
          <ViewLaborSourcesPopoverContent job={job} viewer={viewer} />
        ) : (
          <CreateCrewsForJobPopoverContent
            job={job}
            handleClose={popover.handleClose}
            refetch={refetch}
            shouldRunUpsertCostsAndBillingEngineAsync
          />
        )}
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const DispatchLaborSourceEditButton = ({job, viewer, refetch}) => {
  const dispatchLaborSourcePopover = usePopover();

  return (
    <React.Fragment>
      <Popover.Content innerRef={dispatchLaborSourcePopover.ref}>
        <TertiaryButton onPress={dispatchLaborSourcePopover.handleOpen}>
          <Icon source={Icon.Pen} size={12} color={colors.blue.interactive} />
        </TertiaryButton>
      </Popover.Content>
      <DispatchLaborSourcePopover
        popover={dispatchLaborSourcePopover}
        job={job}
        viewer={viewer}
        refetch={() => refetch({shouldFetchJobsListTable: true})}
      />
    </React.Fragment>
  );
};

const DispatchTrucksButton = ({crew, refetch, job}) => {
  const editTrucksPopover = usePopover();
  const {isHovered, ref} = useHover();

  return (
    <React.Fragment>
      <Row ref={ref}>
        <Popover.Content innerRef={editTrucksPopover.ref}>
          <TertiaryButton onPress={editTrucksPopover.handleOpen}>
            <ContainerWithBorder isHovered={isHovered} isModalOpen={editTrucksPopover.isOpen}>
              <Row>
                <Icon
                  source={Icon.Truck}
                  color={editTrucksPopover.isOpen ? colors.blue.interactive : colors.gray.secondary}
                  size={10}
                />
                <Space width={6} />
                <DispatchCountText isModalOpen={editTrucksPopover.isOpen}>
                  {Job.getDispatchJobTrucksCount(job)}
                </DispatchCountText>
              </Row>
            </ContainerWithBorder>
          </TertiaryButton>
        </Popover.Content>
      </Row>
      <CrewTrucksPopover
        isOpen={editTrucksPopover.isOpen}
        handleOpen={editTrucksPopover.handleOpen}
        handleClose={editTrucksPopover.handleClose}
        popoverRef={editTrucksPopover.ref}
        crewId={crew.id}
        placement={Popover.Positions.RightStart}
        offset={[0, 4]}
        shouldRunUpsertCostsAndBillingEngineAsync
        refetch={() => {
          refetch();
          // When a new truck gets saved, it will force this popover to close when the
          // data refetches and relocates the job card to a different slot. Instead of
          // waiting for the delayed update, we close the modal right after the refetch.
          editTrucksPopover.handleClose();
        }}
      />
    </React.Fragment>
  );
};

const DispatchMoversButton = ({crew, refetch, job}) => {
  const editMoversPopover = usePopover();
  const jobJobUsersScheduleModal = useModal();
  const {isHovered, ref} = useHover();

  return (
    <React.Fragment>
      <Row ref={ref}>
        <Popover.Content innerRef={editMoversPopover.ref}>
          <TertiaryButton onPress={editMoversPopover.handleOpen}>
            <ContainerWithBorder isHovered={isHovered} isModalOpen={editMoversPopover.isOpen}>
              <Row>
                <Icon
                  source={Icon.User}
                  color={editMoversPopover.isOpen ? colors.blue.interactive : colors.gray.secondary}
                  size={12}
                />
                <Space width={6} />
                <DispatchCountText isModalOpen={editMoversPopover.isOpen}>
                  {Job.getDispatchJobUsersCount(job)}
                </DispatchCountText>
              </Row>
            </ContainerWithBorder>
          </TertiaryButton>
        </Popover.Content>
      </Row>
      <CrewUsersAndDriversPopover
        isOpen={editMoversPopover.isOpen}
        key={`${editMoversPopover.isOpen}-MOVERS`}
        handleOpen={editMoversPopover.handleOpen}
        handleClose={editMoversPopover.handleClose}
        popoverRef={editMoversPopover.ref}
        crew={crew}
        placement={Popover.Positions.Right}
        refetch={refetch}
        jobJobUsersScheduleModal={jobJobUsersScheduleModal}
        offset={[-32, 4]}
        shouldRunUpsertCostsAndBillingEngineAsync
      />
      <JobJobUsersScheduleModal
        jobUuid={job.uuid}
        isOpen={jobJobUsersScheduleModal.isOpen}
        handleClose={() => {
          jobJobUsersScheduleModal.handleClose();
          refetch();
        }}
      />
    </React.Fragment>
  );
};

const DispatchTrucksContent = ({crew, refetch, job}) => {
  const activeJobTrucksForOrganization = job.jobTrucks.filter(
    (activeJobTruck) => activeJobTruck.truck.organizationId === crew.organizationId,
  );
  return (
    <Row>
      <DispatchTrucksButton crew={crew} refetch={refetch} job={job} />
      <RowWrap>
        {activeJobTrucksForOrganization &&
          activeJobTrucksForOrganization.map((jobTruck) => (
            <React.Fragment key={jobTruck.id}>
              <Space width={4} />
              <DispatchCountText>{jobTruck.truck.name}</DispatchCountText>
            </React.Fragment>
          ))}
      </RowWrap>
    </Row>
  );
};

const DispatchMoversContent = ({crew, refetch, job}) => {
  const assignedJobUsersForOrganization = job.assignedJobUsers.filter(
    (assignedJobUser) => assignedJobUser.user.organizationId === crew.organizationId,
  );
  return (
    <Row>
      <DispatchMoversButton crew={crew} refetch={refetch} job={job} />
      <RowWrap>
        {assignedJobUsersForOrganization.map((assignedJobUser, index) => (
          <React.Fragment key={assignedJobUser.id}>
            <TextTooltip text={JobUser.getTooltipStatus(assignedJobUser)}>
              <Row>
                <Space width={4} />
                <Icon source={Icon.Circle} color={assignedJobUser.statusColor} size={6} />
                <Space width={4} />
                <DispatchCountText>
                  {JobUser.getFirstNameAndLastInitial(assignedJobUser)}
                </DispatchCountText>
              </Row>
            </TextTooltip>
          </React.Fragment>
        ))}
      </RowWrap>
    </Row>
  );
};

const DispatchLaborSourceList = ({crews, job, viewer, refetch}) => {
  const canViewAndEditOtherOrgs =
    viewer.viewingOrganization.hasMultipleOrganizations && viewer.viewingOrganization.isPrimary;
  if (!canViewAndEditOtherOrgs) {
    crews = crews.filter((crew) => crew.organization.id === viewer.viewingOrganization.id);
  }
  return (
    <Container>
      {crews.map((crew, index) => {
        const isLastItem = index === crews.length - 1;
        return (
          <React.Fragment key={index}>
            <Row>
              {canViewAndEditOtherOrgs && (
                <FlexContainer flex={1.5}>
                  <Row>
                    <Table.CellText>{crew.organization.name}</Table.CellText>
                    <Space width={8} />
                    <DispatchLaborSourceEditButton job={job} viewer={viewer} refetch={refetch} />
                  </Row>
                </FlexContainer>
              )}
              <FlexContainer flex={1.5}>
                <DispatchTrucksContent crew={crew} refetch={refetch} job={job} />
              </FlexContainer>
              <FlexContainer flex={3}>
                <DispatchMoversContent crew={crew} refetch={refetch} job={job} />
              </FlexContainer>
            </Row>
            {!isLastItem && <Space height={14} />}
          </React.Fragment>
        );
      })}
    </Container>
  );
};

const DispatchRowHookComponent = ({isJobsListTableExpanded, viewer, refetch, item}) => {
  if (!isJobsListTableExpanded) {
    return null;
  }
  return (
    <FullLengthRow>
      {item.crews.length > 0 ? (
        <DispatchLaborSourceList crews={item.crews} job={item} viewer={viewer} refetch={refetch} />
      ) : (
        <CreateCrewsForJobButton
          job={item}
          viewer={viewer}
          refetch={() => refetch({shouldFetchJobsListTable: true})}
          isButtonTertiary
        />
      )}
    </FullLengthRow>
  );
};

const CapacityCalendarJobsListTable = ({
  selectedDate,
  organization,
  isJobsListTableExpanded,
  refetchDayView,
  setTotalJobsForDay,
  isTrainingOnly,
}) => {
  const {navigator, params} = useNavigationDOM();
  const {
    loading,
    data,
    refetch: refetchJobsListTable,
  } = useQuery(CapacityCalendarJobsListTable.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      slugs: params.slugs
        ? params.slugs
        : organization.isPrimary
          ? ['ALL_ORGANIZATIONS']
          : [organization.slug],
      date: selectedDate,
      projectTypes: params.projectTypes,
      projectTagIds: params.projectTagIds,
      jobTagIds: params.jobTagIds,
      salespersons: params.salespersons,
      searchQuery: params.searchQuery,
      isTrainingOnly: isTrainingOnly || false,
    },
  });
  const refetch = ({shouldFetchJobsListTable = false} = {}) => {
    refetchDayView();
    // Instead of refetching here in every case, we return data from
    // useSetCrewSlotsToCrewMutation and useSetCrewSlotsToCrewMutation in order to
    // update the frontend without needing to reach the loading state. See those
    // files for the data we are returning
    if (shouldFetchJobsListTable) {
      refetchJobsListTable();
    }
  };
  const columnDefinitions = getCapacityCalendarJobsListTableColumnDefinitions({
    navigator,
    refetch,
  });

  if (loading)
    return <Table.SkeletonLoader columnDefinitions={columnDefinitions} numberOfRows={5} />;

  if (data) {
    setTotalJobsForDay(data.filteredJobsV2.length);
  }
  return (
    <React.Fragment>
      <Table
        columnDefinitions={columnDefinitions}
        emptyStateText={`No ${isTrainingOnly ? 'Training ' : ''}Jobs`}
        items={data.filteredJobsV2}
        isDense
        itemKey={'id'}
        onRowPress={(job) => navigator.push(`/jobs/${job.uuid}`)}
        isClickable
        rowHook={{
          // The component doesn't need to use the hook or the argument, but we need to pass them in
          hook: () => {},
          hookArgument: {},
          Component: DispatchRowHookComponent,
          componentProps: {
            isJobsListTableExpanded,
            viewer: data.viewer,
            refetch,
          },
        }}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityCalendarJobsListTable.query = gql`
  ${Location.getDisplayCityState.fragment}
  ${JobDispatchStatus.fragment}
  ${Job.getDispatchJobTrucksCount.fragment}
  ${Job.getDispatchJobUsersCount.fragment}
  ${CreateCrewsForJobButton.fragment}
  ${CrewUsersAndDriversPopover.fragment}
  ${JobUser.getTooltipStatus.fragment}
  ${JobUser.getFirstNameAndLastInitial.fragment}
  ${JobProjectComments.fragment}
  ${JobDispatchNotesPopover.fragment}
  ${JobDispatchNotes.fragment}
  ${Tag.getJobAndProjectTagAndTagAssignment.fragment}
  ${JobArrivalTime.fragment}

  query CapacityCalendarJobsListTable(
    $slugs: [String!]!,
    $date: String!,
    $projectTypes: [Int!],
    $projectTagIds: [Int!],
    $jobTagIds: [Int!],
    $salespersons: [Int!],
    $searchQuery: String,
    $isTrainingOnly: Boolean,
  ) {
    ${gql.query}
    filteredJobsV2(
      slugs: $slugs,
      date: $date,
      projectTypes: $projectTypes,
      projectTagIds: $projectTagIds,
      jobTagIds: $jobTagIds,
      salespersons: $salespersons,
      searchQuery: $searchQuery,
      isTrainingOnly: $isTrainingOnly,
    ) {
      id
      name
      uuid
      identifier
      totalDistance
      estimatedHourRange
      ...JobArrivalTime
      organization {
        id
        isPrimary
        features {
          isEnabledProjectTag: isEnabled(feature: "PROJECT_TAG")
        }
      }
      jobTrucks {
        id
        truck {
          id
          name
          organizationId
        }
      }
      assignedJobUsers {
        id
        statusColor
        user {
          id
          position
          organizationId
          fullName
        }
        ...JobUser_getTooltipStatus
        ...JobUser_getFirstNameAndLastInitial
      }
      jobType {
        id
        name
      }
      project {
        id
        weight
        volume
        projectType {
          id
          name
          color
        }
        client {
          id
          uuid
          name
          primaryContact {
            id
            fullName
          }
        }
      }
      ...Tag_getJobAndProjectTagAndTagAssignment
      startLocation {
          id
          ...Location_getDisplayCityState
        }
        endLocation {
          id
          ...Location_getDisplayCityState
        }
        crews {
          id
          organizationId
          organization {
            id
            name
          }
          ...CrewUsersAndDriversPopover
        }
        ...Job_getDispatchJobTrucksCount
        ...Job_getDispatchJobUsersCount
        ...JobDispatchStatus
        ...CreateCrewsForJobButton_Job
        ...JobDispatchNotesPopover
        ...JobDispatchNotes
        ...JobProjectComments
    }
    viewer {
      id
      viewingOrganization {
        id
        hasMultipleOrganizations
        name
        isPrimary
      }
      ...CreateCrewsForJobButton_Viewer
    }
  }
`;

export default CapacityCalendarJobsListTable;
