// Supermove
import {gql} from '@supermove/graphql';
import {useMutation, useMutationCallbacks} from '@supermove/hooks';

const useDeletePaymentMutation = ({paymentId, onSuccess, onError}) => {
  const [handleSubmit, {loading, data}] = useMutation(useDeletePaymentMutation.mutation, {
    variables: {paymentId},
  });

  // Trigger callbacks when the data field changes.
  useMutationCallbacks({data, onSuccess, onError});

  return {
    submitting: loading,
    handleSubmit,
  };
};

useDeletePaymentMutation.mutation = gql`
  mutation useDeletePaymentMutation($paymentId: Int!) {
    response: deletePayment(paymentId: $paymentId) {
      ${gql.errors}
      payment {
        id
      }
    }
  }
`;

export default useDeletePaymentMutation;
