// Libraries
import _ from 'lodash';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Organization} from '@supermove/models';

// App
import {SSEEventType} from '@shared/modules/SSE/interfaces';
import CalendarKind from '@shared/modules/Settings/enums/CalendarKind';
import UserRole from '@shared/modules/User/enums/UserRole';

const getCanViewJobEstimateCalendar = ({viewer}) => {
  return _.get(viewer, 'viewingOrganization.features.isEnabledJobEstimateCalendar', false);
};

const getCanViewDispatch = ({viewer}) => {
  return UserRole.MANAGER_ROLES_PLUS_SUPER.includes(_.get(viewer, 'role', false));
};

const getCanViewLive = ({viewer}) => {
  return UserRole.MANAGER_ROLES_PLUS_SUPER.includes(_.get(viewer, 'role', false));
};

const getCanViewStorage = ({viewer}) => {
  return _.get(viewer, 'viewingOrganization.settings.isStorageEnabled', false);
};

const getCanViewAccounting = ({viewer}) => {
  return UserRole.ADMIN_ROLES_PLUS_SUPER.includes(_.get(viewer, 'role', false));
};

const getCanViewPayments = ({viewer}) => {
  return (
    UserRole.ADMIN_ROLES_PLUS_SUPER.includes(_.get(viewer, 'role', false)) &&
    Organization.getIsEnabledPayengine(viewer.viewingOrganization)
  );
};

const getAccountingUrl = ({viewer}) => {
  if (getCanViewAccountingInvoices({viewer})) {
    return '/accounting/invoices/move';
  } else if (getCanViewAccountingReports({viewer})) {
    if (getCanViewPayrollReports({viewer})) {
      return '/accounting/employee-payroll';
    } else if (getCanViewSalesPayroll({viewer})) {
      return '/accounting/sales-payroll';
    } else if (getCanViewCrewPayroll({viewer})) {
      return '/accounting/crew-payroll';
    }
  }
  return '/accounting/reports';
};

const getCanViewAccountingInvoices = ({viewer}) => {
  return UserRole.ADMIN_ROLES_PLUS_SUPER.includes(_.get(viewer, 'role', false));
};

const getCanViewAccountingReports = ({viewer}) => {
  return UserRole.ADMIN_ROLES_PLUS_SUPER.includes(_.get(viewer, 'role', false));
};

const getCanViewReports = ({viewer}) => {
  return (
    UserRole.ADMIN_ROLES_PLUS_SUPER.includes(_.get(viewer, 'role', false)) &&
    _.get(viewer, 'viewingOrganization.features.isEnabledReports', false)
  );
};

const getCanViewCrewPayroll = ({viewer}) => {
  return (
    !_.get(viewer, 'viewingOrganization.features.isEnabledHideCrewPayrollTab', false) &&
    UserRole.ADMIN_ROLES_PLUS_SUPER.includes(_.get(viewer, 'role', false)) &&
    _.get(viewer, 'viewingOrganization.features.isEnabledPayrollForCrew', false)
  );
};

const getCanViewMoverCompensationReports = ({viewer}) => {
  return _.get(viewer, 'viewingOrganization.settings.isCostAndCompensationEnabled', false);
};

const getCanViewSalesPayroll = ({viewer}) => {
  return (
    UserRole.ADMIN_ROLES_PLUS_SUPER.includes(_.get(viewer, 'role', false)) &&
    _.get(viewer, 'viewingOrganization.features.isEnabledPayrollForSales', false)
  );
};

const getCanViewPayroll = ({viewer}) => {
  return getCanViewCrewPayroll({viewer}) || getCanViewSalesPayroll({viewer});
};

const getPayrollUrl = ({viewer}) => {
  if (getCanViewSalesPayroll({viewer})) {
    return '/payroll/sales';
  }
  return '/payroll/crew';
};

// Payroll V2
const getCanViewPayrollReports = ({viewer}) => {
  return (
    UserRole.ADMIN_ROLES_PLUS_SUPER.includes(_.get(viewer, 'role', false)) &&
    _.get(viewer, 'viewingOrganization.settings.isCostAndCompensationEnabled', false)
  );
};

export const getSidebarItems = ({viewer}) => {
  if (viewer.role === 'employee') {
    return [
      {
        key: 'schedule',
        to: '/1/schedule',
        name: 'Schedule',
        source: Icon.CalendarCheck,
      },
      getCanViewMoverCompensationReports({viewer}) && {
        key: 'compensation',
        to: '/1/accounting/compensation-reports',
        name: 'Payroll',
        source: Icon.HandHoldingUsd,
      },
      {
        key: 'dispatchtripview',
        to: '/1/dispatch/trips/active',
        name: 'Trips',
        source: Icon.Road,
      },
    ].filter(Boolean);
  }

  const getMovesTabUrl = () => {
    if (
      _.get(viewer, 'viewingOrganization.settings.primaryCalendar', '') === CalendarKind.CAPACITY
    ) {
      return '/moves/capacity/calendar';
    } else {
      return '/calendar';
    }
  };

  const baseTabs = [
    {
      key: 'moves',
      to: getMovesTabUrl(),
      name: 'Moves',
      source: Icon.CalendarCheck,
      sseRefetchTrigger: SSEEventType.MOVE_REQUEST_COUNT_UPDATED,
    },
    {
      key: 'tasks',
      to: '/tasks',
      name: 'Tasks',
      source: Icon.CheckCircle,
      sseRefetchTrigger: SSEEventType.DUE_TASK_COUNT_UPDATED,
    },
    getCanViewJobEstimateCalendar({viewer}) && {
      key: 'estimates',
      to: '/jobs/estimates/calendar',
      name: 'Surveys',
      source: Icon.ClipboardList,
    },
    getCanViewDispatch({viewer}) && {
      key: 'dispatch',
      to: '/dispatch',
      name: 'Dispatch',
      source: Icon.Truck,
    },
    getCanViewLive({viewer}) && {
      key: 'live',
      to: '/live',
      name: 'Live',
      source: Icon.MapMarkedAlt,
    },
    getCanViewStorage({viewer}) && {
      key: 'storage',
      to: '/storage/list',
      name: 'Storage',
      source: Icon.BoxOpen,
    },
    getCanViewPayments({viewer}) && {
      key: 'payments',
      to: '/payments',
      name: 'Payments',
      source: Icon.DollarSign,
    },
    getCanViewAccounting({viewer}) && {
      key: 'accounting',
      to: getAccountingUrl({viewer}),
      name: 'Accounting',
      source: Icon.Calculator,
    },
    {
      key: 'claims',
      to: '/claims',
      name: 'Claims',
      source: Icon.MoneyCheckDollarPen,
    },
    {
      key: 'customers',
      to: '/clients/customers',
      name: 'Clients',
      source: Icon.User,
    },
    getCanViewReports({viewer}) && {
      key: 'reports',
      to: '/reports',
      name: 'Reports',
      source: Icon.ChartLine,
    },
    !getCanViewAccounting({viewer}) &&
      !getCanViewPayrollReports({viewer}) &&
      getCanViewPayroll({viewer}) && {
        key: 'payroll',
        to: getPayrollUrl({viewer}),
        name: 'Payroll',
        source: Icon.HandHoldingUsd,
      },
    // Payroll V2
    !getCanViewAccounting({viewer}) &&
      getCanViewPayrollReports({viewer}) && {
        key: 'payroll',
        to: '/accounting/payroll-reports',
        name: 'Payroll',
        source: Icon.HandHoldingUsd,
      },
    !getCanViewPayrollReports({viewer}) &&
      getCanViewMoverCompensationReports({viewer}) && {
        key: 'compensation',
        to: '/accounting/compensation-reports',
        name: 'Payroll',
        source: Icon.HandHoldingUsd,
      },
  ].filter(Boolean);

  return baseTabs;
};

getSidebarItems.fragment = gql`
  ${Organization.getIsEnabledPayengine.fragment}
  fragment User_getSidebarItems on User {
    id
    role
    viewingOrganization {
      id
      features {
        isEnabledJobEstimateCalendar: isEnabled(feature: "JOB_ESTIMATE_CALENDAR")
        isEnabledHideCrewPayrollTab: isEnabled(feature: "HIDE_CREW_PAYROLL_TAB")
        isEnabledPayrollForCrew: isEnabled(feature: "PAYROLL_FOR_CREW")
        isEnabledPayrollForSales: isEnabled(feature: "PAYROLL_FOR_SALES")
        isEnabledReports: isEnabled(feature: "REPORTS")
      }
      settings {
        id
        isBookingCalendarEnabled
        isMovesCalendarEnabled
        isMovesTabPrimary
        isStorageEnabled
        isCostAndCompensationEnabled
        primaryCalendar
      }
      ...Organization_getIsEnabledPayengine
    }
  }
`;
