// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Float, withFragment} from '@supermove/utils';

// App
import ExpirationTimeframeUnitKind from '@shared/modules/Proposal/enums/ExpirationTimeframeUnitKind';

const _new = ({organizationId, projectTypeId}) => ({
  confirmationStepsId: null,
  organizationId,
  projectTypeId,
  kind: '',
  stepsJson: [],
  emailTemplateId: null,
  isEnabledAutomaticExpiration: false,
  defaultExpirationTimeframeUnitKind: ExpirationTimeframeUnitKind.HOURS,
  defaultExpirationTimeframe: '',
  shouldAttachDocumentsInEmail: false,

  // private
  isEnabled: false,
});

const edit = withFragment(
  (confirmationSteps) => ({
    confirmationStepsId: confirmationSteps.id,
    organizationId: confirmationSteps.organizationId,
    projectTypeId: confirmationSteps.projectTypeId,
    kind: confirmationSteps.kind,
    stepsJson: confirmationSteps.stepsJson,
    emailTemplateId: confirmationSteps.emailTemplateId,
    isEnabledAutomaticExpiration: confirmationSteps.isEnabledAutomaticExpiration,
    defaultExpirationTimeframeUnitKind:
      confirmationSteps.defaultExpirationTimeframeUnitKind || ExpirationTimeframeUnitKind.HOURS,
    defaultExpirationTimeframe: confirmationSteps.defaultExpirationTimeframe,
    shouldAttachDocumentsInEmail: confirmationSteps.shouldAttachDocumentsInEmail,

    // private
    isEnabled: confirmationSteps.isEnabled,
  }),
  gql`
    fragment ConfirmationStepsForm_edit on ConfirmationSteps {
      id
      organizationId
      projectTypeId
      kind
      stepsJson
      emailTemplateId
      isEnabled
      isEnabledAutomaticExpiration
      defaultExpirationTimeframeUnitKind
      defaultExpirationTimeframe
      shouldAttachDocumentsInEmail
    }
  `,
);

const toForm = ({
  confirmationStepsId,
  organizationId,
  projectTypeId,
  kind,
  stepsJson,
  emailTemplateId,
  isEnabledAutomaticExpiration,
  defaultExpirationTimeframeUnitKind,
  defaultExpirationTimeframe,
  shouldAttachDocumentsInEmail,

  // private
  isEnabled,
}) => ({
  confirmationStepsId,
  organizationId,
  projectTypeId,
  kind,
  stepsJson,
  emailTemplateId: _.toString(emailTemplateId),
  isEnabledAutomaticExpiration,
  defaultExpirationTimeframeUnitKind,
  defaultExpirationTimeframe: Float.toForm(defaultExpirationTimeframe),
  shouldAttachDocumentsInEmail,

  // private
  isEnabled,
});

const toMutation = ({
  confirmationStepsId,
  organizationId,
  projectTypeId,
  kind,
  stepsJson,
  emailTemplateId,
  isEnabledAutomaticExpiration,
  defaultExpirationTimeframeUnitKind,
  defaultExpirationTimeframe,
  shouldAttachDocumentsInEmail,
}) => ({
  confirmationStepsId,
  organizationId,
  projectTypeId,
  kind,
  stepsJson,
  emailTemplateId: emailTemplateId ? _.toNumber(emailTemplateId) : null,
  isEnabledAutomaticExpiration,
  defaultExpirationTimeframeUnitKind,
  defaultExpirationTimeframe: Float.toMutation(defaultExpirationTimeframe),
  shouldAttachDocumentsInEmail,
});

const ConfirmationStepsForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ConfirmationStepsForm;
