// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getNoDemoSlug = (slug) => {
  return slug.replace('-demo', '');
};

const getQuoteSteps = withFragment(
  (organization) => {
    switch (getNoDemoSlug(organization.slug)) {
      case 'changingspacesmoving':
      case 'changingspacesmoving-birmingham':
      case 'changingspacesmoving-huntsville':
        return [
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_QUOTE',
          },
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_INVENTORY',
          },
        ];
      case 'delaneyworldwide':
        return [
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'easternshoretransfer':
        return [
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'hamptonroadsmoving':
        return [
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'lakecoeurdalenemovers':
        return [
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'metrovan':
        return [
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'moyerandsons':
        return [
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'qrmovers':
        return [
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'ridgewoodmoving':
        return [
          {
            isDefault: false,
            kind: 'QUOTE_STEP_ACCEPT_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'simonikallied':
        return [
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'strattonandsons':
        return [
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'sunrisemoving':
        return [
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'truckandi':
        return [
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'universalmoversllc':
        return [
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_QUOTE',
          },
        ];
      default:
        return [
          {
            isDefault: true,
            kind: 'QUOTE_STEP_ACCEPT_QUOTE',
          },
        ];
    }
  },
  gql`
    fragment Organization_getQuoteSteps on Organization {
      id
      slug
    }
  `,
);

export default getQuoteSteps;
