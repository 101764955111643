// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({organizationId}) => ({
  organizationId,
  claimStatusId: null,
  name: '',
  color: '',
  rank: -1,
  isDefault: false,
});

const edit = withFragment(
  (claimStatus) => ({
    claimStatusId: claimStatus.id,
    organizationId: claimStatus.organizationId,
    name: claimStatus.name,
    color: claimStatus.color,
    rank: claimStatus.rank,
    isDefault: claimStatus.isDefault,
  }),
  gql`
    fragment ClaimStatusForm_edit on ClaimStatus {
      id
      organizationId
      name
      color
      rank
      isDefault
    }
  `,
);

const toForm = ({claimStatusId, organizationId, name, color, rank, isDefault}) => ({
  claimStatusId,
  organizationId,
  name,
  color,
  rank,
  isDefault,
});

const toMutation = ({claimStatusId, organizationId, name, color, rank, isDefault}) => ({
  claimStatusId,
  organizationId,
  name,
  color,
  rank,
  isDefault,
});

const ClaimStatusForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ClaimStatusForm;
