import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import TimesheetBlockKind from '@shared/modules/Timesheet/enums/TimesheetBlockKind';
import TimesheetBlockForm from '@shared/modules/Timesheet/forms//TimesheetBlockForm';
import CreateTimesheetBlockForm from '@shared/modules/Timesheet/forms/CreateTimesheetBlockForm';
import DeleteTimesheetBlockForm from '@shared/modules/Timesheet/forms/DeleteTimesheetBlockForm';
import UpdateTimesheetBlockForm from '@shared/modules/Timesheet/forms/UpdateTimesheetBlockForm';

const getAdjustmentAmountSeconds = ({
  adjustmentAmountMultiplier,
  adjustmentAmountHours,
  adjustmentAmountMinutes,
}) => {
  const hours = _.toNumber(adjustmentAmountHours) || 0;
  const minutes = _.toNumber(adjustmentAmountMinutes) || 0;
  return adjustmentAmountMultiplier * (hours * 3600 + minutes * 60);
};

const getAdjustmentAmountMinutes = (updateTimesheetPayrollEntryForm) => {
  return getAdjustmentAmountSeconds(updateTimesheetPayrollEntryForm) / 60;
};

const getHasAdjustment = ({adjustmentAmountHours, adjustmentAmountMinutes}) =>
  !!(adjustmentAmountHours || adjustmentAmountMinutes);

const edit = withFragment(
  (timesheetPayrollEntry) => {
    const {adjustmentsFromTimesheet} = timesheetPayrollEntry.timeWorked;

    return {
      timesheetPayrollEntryId: timesheetPayrollEntry.id,
      timesheetBlocksCreate: [],
      timesheetBlocksUpdate: timesheetPayrollEntry.effectiveTimesheetBlocks
        .filter((timesheetBlock) => !timesheetBlock.isBillable)
        .map((timesheetBlock) => UpdateTimesheetBlockForm.edit(timesheetBlock)),
      timesheetBlocksDelete: [],
      adjustmentReason: timesheetPayrollEntry.adjustmentReason,

      // Private
      adjustmentAmountHours: Math.floor(Math.abs(adjustmentsFromTimesheet) / 3600),
      adjustmentAmountMinutes: Math.floor(Math.abs(adjustmentsFromTimesheet % 3600) / 60),
      adjustmentAmountMultiplier: adjustmentsFromTimesheet > 0 ? 1 : -1,
    };
  },
  gql`
    ${UpdateTimesheetBlockForm.edit.fragment}

    fragment UpdateTimesheetPayrollEntryForm_edit on TimesheetPayrollEntry {
      id
      adjustmentReason
      timeWorked {
        adjustmentsFromTimesheet
      }
      effectiveTimesheetBlocks {
        id
        ...UpdateTimesheetBlockForm_edit
      }
    }
  `,
);

const getTimesheetMinutes = ({timesheetBlocksCreate, timesheetBlocksUpdate}) => {
  const totalCreateMinutes = timesheetBlocksCreate.reduce(
    (total, createTimesheetBlockForm) =>
      createTimesheetBlockForm.kind === TimesheetBlockKind.BREAK
        ? total
        : total + TimesheetBlockForm.getMinutes(createTimesheetBlockForm),
    0,
  );
  const totalUpdateMinutes = timesheetBlocksUpdate.reduce(
    (total, updateTimesheetBlockForm) =>
      updateTimesheetBlockForm.kind === TimesheetBlockKind.BREAK
        ? total
        : total + TimesheetBlockForm.getMinutes(updateTimesheetBlockForm),
    0,
  );
  return totalCreateMinutes + totalUpdateMinutes;
};

const toForm = ({
  timesheetPayrollEntryId,
  timesheetBlocksCreate,
  timesheetBlocksUpdate,
  timesheetBlocksDelete,
  adjustmentReason,
  adjustmentAmountHours,
  adjustmentAmountMinutes,
  adjustmentAmountMultiplier,
}) => ({
  timesheetPayrollEntryId,
  timesheetBlocksCreate: timesheetBlocksCreate.map(CreateTimesheetBlockForm.toForm),
  timesheetBlocksUpdate: timesheetBlocksUpdate.map(UpdateTimesheetBlockForm.toForm),
  timesheetBlocksDelete: timesheetBlocksDelete.map(DeleteTimesheetBlockForm.toForm),
  adjustmentReason,

  // Private
  adjustmentAmountHours: adjustmentAmountHours ? _.toString(adjustmentAmountHours) : '',
  adjustmentAmountMinutes: adjustmentAmountMinutes ? _.toString(adjustmentAmountMinutes) : '',
  adjustmentAmountMultiplier,
});

const toMutation = ({
  timesheetPayrollEntryId,
  timesheetBlocksCreate,
  timesheetBlocksUpdate,
  timesheetBlocksDelete,
  adjustmentReason,
  adjustmentAmountHours,
  adjustmentAmountMinutes,
  adjustmentAmountMultiplier,
}) => ({
  timesheetPayrollEntryId,
  timesheetBlocksCreate: timesheetBlocksCreate.map(CreateTimesheetBlockForm.toMutation),
  timesheetBlocksUpdate: timesheetBlocksUpdate.map(UpdateTimesheetBlockForm.toMutation),
  timesheetBlocksDelete: timesheetBlocksDelete.map(DeleteTimesheetBlockForm.toMutation),
  adjustmentAmountSeconds: getAdjustmentAmountSeconds({
    adjustmentAmountHours,
    adjustmentAmountMinutes,
    adjustmentAmountMultiplier,
  }),
  adjustmentReason,
});

const UpdateTimesheetPayrollEntryForm = {
  edit,
  toForm,
  toMutation,

  // Helpers
  getAdjustmentAmountSeconds,
  getAdjustmentAmountMinutes,
  getHasAdjustment,
  getTimesheetMinutes,
};

export default UpdateTimesheetPayrollEntryForm;
