// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DateInput, DropdownInput, Icon, Space, Styled, TimeInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import JobForm from '@shared/modules/Job/forms/JobForm';
import TextTooltip from 'modules/App/components/TextTooltip';
import ProjectJobSection from 'modules/Project/Update/components/ProjectJobSection';

const EmptySpace = Styled.View`
  flex: 1;
`;

const SectionTitle = Styled.Text`
  ${Typography.Label1}
  color: ${colors.gray.primary};
`;

const FieldGroupContainer = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  z-index: ${({index = 0}) => 100 - index};
`;

const FieldSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 8 : 0)}px;
  margin-left: ${(props) => (props.mobile ? 0 : 8)}px;
`;

const TextInput = Styled.TextInput.H7`
`;

const RowField = Styled.View`
  z-index: ${({index = 0}) => 100 - index};
`;

const RowFieldLabel = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
  margin-bottom: 4px;
`;

const RowFieldFields = Styled.View`
  flex-direction: row;
`;

const RowFieldSpace = Styled.View`
  margin-left: 8px;
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const DateRangeButtonRow = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonTouchable = Styled.ButtonV2`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const DateRangeToggleButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const DateRangeToggleText = Styled.Text`
  ${Typography.Label2}
  color: ${({color}) => color};
`;

const IconContainer = Styled.View`
  width: 16px;
`;

const AddJobDescription = ({form, jobField, index}) => {
  const responsive = useResponsive();
  const isDescriptionVisible = _.get(form.values, `${jobField}.isDescriptionVisible`);

  return (
    <React.Fragment>
      <FieldGroupContainer index={2} {...responsive}>
        <EmptySpace />
        {!isDescriptionVisible ? (
          <ButtonTouchable
            onPress={() => form.setFieldValue(`${jobField}.isDescriptionVisible`, true)}
          >
            <Icon color={colors.blue.interactive} size={Icon.Sizes.Small} source={Icon.Plus} />
            <Space width={4} />
            <ButtonText>Add Job Description</ButtonText>
          </ButtonTouchable>
        ) : (
          <ButtonTouchable
            onPress={() => {
              form.setFieldValue(`${jobField}.isDescriptionVisible`, false);
              form.setFieldValue(`${jobField}.description`, '');
            }}
          >
            <Icon color={colors.blue.interactive} size={Icon.Sizes.Small} source={Icon.Trash} />
            <Space width={4} />
            <ButtonText>Clear Job Description</ButtonText>
          </ButtonTouchable>
        )}
      </FieldGroupContainer>
      {isDescriptionVisible && (
        <FieldGroupContainer index={index} {...responsive}>
          <FieldInput.Memoized
            {...form}
            index={0}
            name={`${jobField}.description`}
            label={'Job Description'}
            input={{
              placeholder: 'Enter any details for the crew',
              multiline: true,
              numberOfLines: 4,
              style: {
                paddingVertical: 9,
                minHeight: 56,
              },
            }}
            style={{
              width: '100%',
            }}
          />
        </FieldGroupContainer>
      )}
    </React.Fragment>
  );
};

const getProjectTypeJobTypeIdOptions = ({organization, form, jobField, isNewJob}) => {
  const jobTypeKind = _.get(form.values, `${jobField}.kind`);
  const projectTypeId = _.get(form.values, 'projectForm.projectTypeId');
  const projectType = _.find(organization.moveProjectTypes, (projectType) => {
    return String(projectType.id) === String(projectTypeId);
  });
  const jobTypes = projectType ? projectType.jobTypes : [];
  const includeMoveJobTypes = isNewJob || !['ESTIMATE', 'REQUEST'].includes(jobTypeKind);

  // We keep all jobTypes for the "new" job case. We disallow changing the ESTIMATE and REQUEST
  // types to other job types.
  const filteredJobTypes = jobTypes.filter((jobType) => {
    return includeMoveJobTypes || ['ESTIMATE', 'REQUEST'].includes(jobType.kind);
  });

  return filteredJobTypes.map((jobType) => ({
    value: Number(jobType.id),
    label: jobType.name,
  }));
};

const getJobTypeForJobTypeId = ({organization, jobTypeId}) => {
  const jobTypes = _.flatten(
    organization.moveProjectTypes.map((projectType) => projectType.jobTypes),
  );

  return _.find(jobTypes, (jobType) => {
    return String(jobType.id) === String(jobTypeId);
  });
};

const handleToggleDateRange = ({form, field, isEstimatedRange}) => {
  // When toggling the date, we reset the current values that we're toggling away
  // from, but the date value and the startDate value get passed to each other.
  if (isEstimatedRange) {
    form.setFieldValue(`${field}.isEstimatedRange`, false);
    form.setFieldValue(`${field}.date`, _.get(form.values, `${field}.startDate`));
    form.setFieldValue(`${field}.startDate`, '');
    form.setFieldValue(`${field}.endDate`, '');
  } else {
    form.setFieldValue(`${field}.isEstimatedRange`, true);
    form.setFieldValue(`${field}.startDate`, _.get(form.values, `${field}.date`));
    form.setFieldValue(`${field}.date`, '');
  }
};

const shouldResetJobDate = ({prevJobTypeId, nextJobTypeId, jobTypes}) => {
  // Only returns true if switching from an unblocked to a blocked job type
  // i.e. from ESTIMATE to MOVE
  const unblockedJobTypes = ['ESTIMATE', 'REQUEST'];
  const jobType = jobTypes.find((jobType) => jobType.id === _.toString(nextJobTypeId));
  const prevJobType = jobTypes.find((jobType) => jobType.id === _.toString(prevJobTypeId));
  const isPrevUnblocked = prevJobType && unblockedJobTypes.includes(prevJobType.kind);
  const isNextBlocked = jobType && !unblockedJobTypes.includes(jobType.kind);
  return isPrevUnblocked && isNextBlocked;
};

const JobIdentifierNameRow = ({form, jobField, organization, isNewJob}) => {
  const responsive = useResponsive();
  const {isEnabledCreateProjectDispatchLocks} = organization.features;
  const orgJobTypes = _.flatten(
    organization.moveProjectTypes.map((projectType) => projectType.jobTypes),
  );

  return (
    <React.Fragment>
      <FieldInput.Memoized
        {...form}
        index={0}
        name={`${jobField}.jobTypeId`}
        component={DropdownInput}
        label={'Job Type'}
        input={{
          options: getProjectTypeJobTypeIdOptions({organization, form, jobField, isNewJob}),
          placeholder: 'Select Job Type',
          isSearchable: true,
          setFieldValue: (field, jobTypeId) => {
            const jobType = getJobTypeForJobTypeId({organization, jobTypeId});

            form.setFieldValue(`${jobField}.jobTypeId`, jobTypeId);
            form.setFieldValue(`${jobField}.kind`, jobType.kind);
            form.setFieldValue(`${jobField}.jobTypeName`, jobType.name);
          },
          onChangeValue: (value, option, prevValue) => {
            if (
              isEnabledCreateProjectDispatchLocks &&
              shouldResetJobDate({
                prevJobTypeId: prevValue,
                nextJobTypeId: value,
                jobTypes: orgJobTypes,
              })
            ) {
              form.setFieldValue(`${jobField}.date`, '');
            }
            form.setFieldValue(`${jobField}.valueForms`, []);
          },
          required: !_.get(form.values, `${jobField}.kind`),
          style: {flex: 1},
        }}
        style={
          !responsive.mobile && {
            flex: 1,
          }
        }
      />
      <FieldSpace {...responsive} />
      {organization.features.isEnabledJobIdentifierField && (
        <React.Fragment>
          <FieldInput.Memoized
            {...form}
            index={1}
            name={`${jobField}.identifier`}
            label={'Job Identifier'}
            input={{
              placeholder: 'Enter Job Identifier',
              required: !_.get(form.values, `${jobField}.identifier`),
            }}
            style={
              !responsive.mobile && {
                flex: 1,
              }
            }
          />
          <FieldSpace {...responsive} />
        </React.Fragment>
      )}
      <FieldInput.Memoized
        {...form}
        index={2}
        name={`${jobField}.name`}
        label={'Job Name'}
        input={{
          placeholder: 'Enter Job Name',
        }}
        style={
          !responsive.mobile && {
            flex: 1,
          }
        }
      />
      <FieldSpace {...responsive} />
      {!organization.features.isEnabledJobIdentifierField && <EmptySpace style={{flex: 1}} />}
    </React.Fragment>
  );
};

const StartTimeFields = ({form, jobField, organization, isRequired, index}) => {
  return (
    <RowField style={{flex: 2}} index={index}>
      <RowFieldLabel>{organization.jobStartTimeField}</RowFieldLabel>
      <RowFieldFields>
        <FieldInput.Memoized
          {...form}
          index={1}
          component={TimeInput}
          name={`${jobField}.startTime1`}
          input={{
            component: TextInput,
            required: isRequired && !_.get(form.values, `${jobField}.startTime1`),
            placeholder: 'Start',
            setFieldValue: form.setFieldValue,
          }}
          style={{
            flex: 1,
          }}
        />
        <RowFieldSpace />
        <FieldInput.Memoized
          {...form}
          index={2}
          component={TimeInput}
          name={`${jobField}.startTime2`}
          input={{
            component: TextInput,
            required: isRequired && !_.get(form.values, `${jobField}.startTime1`),
            placeholder: 'End',
            setFieldValue: form.setFieldValue,
          }}
          style={{
            flex: 1,
          }}
        />
      </RowFieldFields>
    </RowField>
  );
};

const DateRangeToggle = ({form, field, isEstimatedRange}) => {
  return (
    <DateRangeButtonRow>
      <DateRangeToggleButton onPress={() => handleToggleDateRange({form, field, isEstimatedRange})}>
        <Icon
          source={isEstimatedRange ? Icon.Trash : Icon.Plus}
          size={10}
          color={colors.blue.interactive}
        />
        <Space width={5} />
        <DateRangeToggleText color={colors.blue.interactive}>End Date</DateRangeToggleText>
      </DateRangeToggleButton>
    </DateRangeButtonRow>
  );
};

const JobDateEstimatedRangeFields = ({form, jobField, isRequired}) => {
  return (
    <RowFieldFields>
      <FieldInput.Memoized
        {...form}
        index={0}
        component={DateInput}
        name={`${jobField}.startDate`}
        label={'Job Date'}
        input={{
          placeholder: 'Start Date',
          required: isRequired && !_.get(form.values, `${jobField}.startDate`),
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          style: {
            width: '100%',
          },
        }}
        style={{
          flex: 1,
        }}
      />
      <RowFieldSpace />
      <FieldInput.Memoized
        {...form}
        index={0}
        component={DateInput}
        name={`${jobField}.endDate`}
        input={{
          placeholder: 'End Date',
          required: isRequired && !_.get(form.values, `${jobField}.endDate`),
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          style: {
            width: '100%',
          },
        }}
        action={<DateRangeToggle form={form} field={jobField} isEstimatedRange />}
        style={{
          flex: 1,
        }}
      />
    </RowFieldFields>
  );
};

const JobDateDayField = ({form, jobField, isRequired, organization}) => {
  const responsive = useResponsive();
  const {isEnabledCreateProjectDispatchLocks} = organization.features;
  const projectTypeId = _.get(form.values, 'projectForm.projectTypeId');
  const projectType = organization.moveProjectTypes.find(
    (projectType) => projectType.id === _.toString(projectTypeId),
  );
  const jobTypeId = _.get(form.values, `${jobField}.jobTypeId`);
  const jobType =
    projectType && projectType.jobTypes.find((jobType) => jobType.id === _.toString(jobTypeId));
  const hasDispatchLocks = jobType && !['ESTIMATE', 'REQUEST'].includes(jobType.kind);
  const excludeDates =
    projectType && isEnabledCreateProjectDispatchLocks && hasDispatchLocks
      ? projectType.futureSalesLockedProjectTypeDays.map(
          (projectTypeDay) => projectTypeDay.day.date,
        )
      : [];
  return (
    <RowFieldFields>
      <FieldInput.Memoized
        {...form}
        index={0}
        component={DateInput}
        label={
          <React.Fragment>
            Job Date{' '}
            {excludeDates.length !== 0 && (
              <TextTooltip text={`Disabled dates have been locked by dispatch.`}>
                <IconContainer>
                  <Icon
                    color={colors.gray.tertiary}
                    size={Icon.Sizes.Medium}
                    source={Icon.InfoCircle}
                  />
                </IconContainer>
              </TextTooltip>
            )}
          </React.Fragment>
        }
        name={`${jobField}.date`}
        input={{
          disabled: isEnabledCreateProjectDispatchLocks && !projectTypeId,
          placeholder: 'Enter Job Date',
          required: isRequired && !_.get(form.values, `${jobField}.date`),
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          style: {
            width: '100%',
          },
          excludeDates,
        }}
        action={<DateRangeToggle form={form} field={jobField} isEstimatedRange={false} />}
        style={{
          flex: 1,
        }}
      />
      {responsive.mobile && (
        <React.Fragment>
          <RowFieldSpace />
          <Space style={{flex: 1}} />
        </React.Fragment>
      )}
    </RowFieldFields>
  );
};

const JobDateField = ({form, jobField, isRequired, index, organization}) => {
  const isEstimatedRangeField = `${jobField}.isEstimatedRange`;
  const isEstimatedRange = _.get(form.values, isEstimatedRangeField);
  return (
    <RowField style={{flex: 2}} index={index}>
      {isEstimatedRange ? (
        <JobDateEstimatedRangeFields form={form} jobField={jobField} isRequired={isRequired} />
      ) : (
        <JobDateDayField
          form={form}
          jobField={jobField}
          isRequired={isRequired}
          organization={organization}
        />
      )}
    </RowField>
  );
};

const EstimateHoursField = ({form, jobField, index, organization}) => {
  const {isEnabledBillingDefaultRecommendations} = organization.features;
  const {isMinEstimateHoursInputVisible, isMaxEstimateHoursInputVisible} =
    JobForm.getDispatchFieldVisibility(_.get(form.values, jobField), {
      isEnabledBillingDefaultRecommendations,
    });
  if (!isMinEstimateHoursInputVisible && !isMaxEstimateHoursInputVisible) {
    return null;
  }
  return (
    <RowField style={{flex: 2}} index={index}>
      <RowFieldLabel>{'# of Estimated Hours'}</RowFieldLabel>
      <RowFieldFields>
        {isMinEstimateHoursInputVisible && (
          <FieldInput.Memoized
            {...form}
            index={1}
            name={`${jobField}.estimateHours1`}
            input={{
              placeholder: 'Min',
              keyboardType: 'phone-pad',
            }}
            style={{
              flex: 1,
            }}
          />
        )}
        {isMinEstimateHoursInputVisible && isMaxEstimateHoursInputVisible && <RowFieldSpace />}
        <FieldInput.Memoized
          {...form}
          index={2}
          name={`${jobField}.estimateHours2`}
          input={{
            placeholder: 'Max',
            keyboardType: 'phone-pad',
          }}
          style={{
            flex: 1,
          }}
        />
      </RowFieldFields>
    </RowField>
  );
};

const DefaultMoveFields = ({form, jobField, organization, isNewJob}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <FieldGroupContainer index={0} {...responsive}>
        <JobIdentifierNameRow
          form={form}
          jobField={jobField}
          organization={organization}
          isNewJob={isNewJob}
        />
      </FieldGroupContainer>
      <Space height={8} />
      <FieldGroupContainer index={1} {...responsive}>
        <JobDateField index={0} form={form} jobField={jobField} organization={organization} />
        <FieldSpace {...responsive} />
        <StartTimeFields index={1} jobField={jobField} form={form} organization={organization} />
        <FieldSpace {...responsive} />
        <EstimateHoursField index={2} form={form} jobField={jobField} organization={organization} />
      </FieldGroupContainer>
      <Space height={8} />
      <AddJobDescription index={2} form={form} jobField={jobField} />
    </React.Fragment>
  );
};

const EstimateMoveFields = ({form, jobField, organization, isNewJob}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <FieldGroupContainer index={0} {...responsive}>
        <JobIdentifierNameRow
          form={form}
          jobField={jobField}
          organization={organization}
          isNewJob={isNewJob}
        />
      </FieldGroupContainer>
      <Space height={8} />
      <FieldGroupContainer index={1} {...responsive}>
        <JobDateField
          index={0}
          form={form}
          jobField={jobField}
          organization={organization}
          isRequired
        />
        <FieldSpace {...responsive} />
        <StartTimeFields index={1} jobField={jobField} form={form} organization={organization} />
      </FieldGroupContainer>
    </React.Fragment>
  );
};

const CommercialMoveFields = ({form, jobField, organization, isNewJob}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <FieldGroupContainer index={0} {...responsive}>
        <JobIdentifierNameRow
          form={form}
          jobField={jobField}
          organization={organization}
          isNewJob={isNewJob}
        />
      </FieldGroupContainer>
      <Space height={8} />
      <FieldGroupContainer index={1} {...responsive}>
        <JobDateField
          index={0}
          form={form}
          jobField={jobField}
          organization={organization}
          isRequired
        />
        <FieldSpace {...responsive} />
        <StartTimeFields
          index={1}
          jobField={jobField}
          form={form}
          organization={organization}
          isRequired
        />
        <FieldSpace {...responsive} />
        <EstimateHoursField index={2} form={form} jobField={jobField} organization={organization} />
      </FieldGroupContainer>
      <Space height={8} />
      <AddJobDescription index={2} form={form} jobField={jobField} />
    </React.Fragment>
  );
};

const ProjectJobJobInfoBlockFields = ({form, jobField, organization, isNewJob}) => {
  const kind = _.get(form.values, `${jobField}.kind`);

  switch (kind) {
    case Job.KIND.COMMERCIAL:
      return (
        <CommercialMoveFields
          form={form}
          jobField={jobField}
          organization={organization}
          isNewJob={isNewJob}
        />
      );
    case Job.KIND.ESTIMATE:
      return (
        <EstimateMoveFields
          form={form}
          jobField={jobField}
          organization={organization}
          isNewJob={isNewJob}
        />
      );
    default:
      return (
        <DefaultMoveFields
          form={form}
          jobField={jobField}
          organization={organization}
          isNewJob={isNewJob}
        />
      );
  }
};

const ProjectJobJobInfoBlock = ({index, form, jobField, organization, isNewJob}) => {
  return (
    <ProjectJobSection
      index={index}
      title={
        <React.Fragment>
          <SectionTitle>Job Information</SectionTitle>
          <EmptySpace />
        </React.Fragment>
      }
    >
      <ProjectJobJobInfoBlockFields
        form={form}
        jobField={jobField}
        organization={organization}
        isNewJob={isNewJob}
      />
    </ProjectJobSection>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectJobJobInfoBlock.fragment = gql`
  fragment ProjectJobJobInfoBlock on Organization {
    id
    jobStartTimeField
    features {
      isEnabledJobIdentifierField: isEnabled(feature: "JOB_IDENTIFIER_FIELD")
      isEnabledCreateProjectDispatchLocks: isEnabled(feature: "CREATE_PROJECT_DISPATCH_LOCKS")
      isEnabledBillingDefaultRecommendations: isEnabled(feature: "BILLING_DEFAULT_RECOMMENDATIONS")
    }
    moveProjectTypes: projectTypesForCategory(category: "MOVE") {
      id
      kind
      name
      jobTypes {
        id
        kind
        name
      }
      futureSalesLockedProjectTypeDays {
        id
        day {
          id
          date
        }
      }
    }
  }
`;

export default ProjectJobJobInfoBlock;
