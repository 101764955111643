// Libraries
import React from 'react';

// Supermove
import {DropdownInput, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Config} from '@supermove/sdk';
import {colors} from '@supermove/styles';
import {Document} from '@supermove/utils';

import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ViewCell = ({label, placeholder = 'Select default item', value}) => {
  return (
    <Row>
      <Column>
        <EditPanel.LabelText>{label}</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text style={{color: value ? colors.black : colors.gray.tertiary}}>
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const handleFileDownload = (codatIntegration) => async () => {
  console.log('codatIntegration', codatIntegration);
  const url = `${Config.getAPIHost()}/v1/files/tracking_categories/${codatIntegration.id}`;
  const response = await fetch(url, {
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${codatIntegration.organization.apiToken}`,
    },
  });
  const blob = await response.blob();
  Document.clickFile({file: blob, filename: 'filename.csv'});
};

const DownloadTrackingCategoriesButton = ({codatIntegration}) => {
  return (
    <SecondaryButton
      onPress={handleFileDownload(codatIntegration)}
      text='Download Tracking Categories CSV'
    />
  );
};

const CodatTrackingCategorySettingsPanel = ({codatIntegration, switchToEditMode}) => {
  const {
    projectTrackingCategorySourceVariableId,
    organization: {variables},
  } = codatIntegration;

  const selectedVariable = variables.find(
    (variable) => Number(variable.id) === Number(projectTrackingCategorySourceVariableId),
  );
  return (
    <React.Fragment>
      <Row>
        <Column>
          <ViewCell
            label={'Project Tracking Category Source Variable'}
            value={selectedVariable?.name}
            placeholder='Select source variable'
          />
        </Column>
        <Space style={{flex: 1}} />
        <Column>
          <DownloadTrackingCategoriesButton codatIntegration={codatIntegration} />
        </Column>
      </Row>
    </React.Fragment>
  );
};

CodatTrackingCategorySettingsPanel.Edit = ({codatIntegration, form}) => {
  const {
    organization: {variables},
  } = codatIntegration;
  const options = variables.map((variable) => ({label: variable.name, value: variable.id}));

  return (
    <React.Fragment>
      <Row style={{alignItems: 'center'}}>
        <Column>
          <FieldInput
            {...form}
            label={'Source Variable'}
            name={`codatIntegrationForm.projectTrackingCategorySourceVariableId`}
            isRequired
            component={DropdownInput}
            input={{
              isPortaled: true,
              options,
              placeholder: 'Select Variable',
              setFieldValue: form.setFieldValue,
              style: {
                flex: 1,
              },
            }}
            style={{
              width: 360,
            }}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

CodatTrackingCategorySettingsPanel.Loading = () => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <EditPanel.LabelText>{'Project Tracking Category Source Variable'}</EditPanel.LabelText>
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={128} />
        </Column>
      </Row>
    </React.Fragment>
  );
};

CodatTrackingCategorySettingsPanel.fragment = gql`
  fragment CodatTrackingCategorySettingsPanel on CodatIntegration {
    id
    projectTrackingCategorySourceVariableId
    organization {
      id
      apiToken
      variables {
        id
        name
      }
    }
  }
`;

export default CodatTrackingCategorySettingsPanel;
