// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import TagAssignmentForm from '@shared/modules/Tag/forms/TagAssignmentForm';

const useUpsertTagAssignmentMutation = ({tagAssignmentForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      tagAssignmentForm: TagAssignmentForm.toForm(tagAssignmentForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpsertTagAssignmentMutation.mutation,
    variables: {
      tagAssignmentForm: TagAssignmentForm.toMutation(form.values.tagAssignmentForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpsertTagAssignmentMutation.mutation = gql`
  mutation useUpsertTagAssignmentMutation($tagAssignmentForm: TagAssignmentForm!) {
    response: upsertTagAssignment(tagAssignmentForm: $tagAssignmentForm) {
      ${gql.errors}
      tagAssignments {
        id
      }
    }
  }
`;

export default useUpsertTagAssignmentMutation;
