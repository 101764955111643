// Libraries
import React from 'react';

// Supermove
import {Link, DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import ExploDashboard from '@shared/modules/Reports/components/ExploDashboard';
import DashboardCategory from '@shared/modules/Reports/enums/DashboardCategory';
import AccountingPageHeader from 'modules/Accounting/components/AccountingPageHeader';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';

const Container = Styled.View`
  flex: 1;
`;

const BackgroundContainer = Styled.View`
  background-color: ${colors.gray.background}
  flex: 1;
`;

const ViewReportContainer = Styled.View`
  margin-horizontal: 24px;
`;

const EmptyStateContainer = Styled.View`
  flex: 1;
  align-items: center;
`;

const EmptyStateTitle = Styled.Text`
  ${Typography.Label}
`;

const SubheadingTitle = Styled.Text`
  ${Typography.Subheading}
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const SectionContainer = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const accountingDashboardReports = ({dashboards}) =>
  dashboards.reduce((filteredDashboards, dashboard) => {
    if (dashboard.category !== DashboardCategory.ACCOUNTING) {
      return filteredDashboards;
    }
    return [
      ...filteredDashboards,
      {
        label: dashboard.name,
        value: dashboard.uuid,
      },
    ];
  }, []);

const ViewReportDropdown = ({dashboards, dashboardUuid, setDashboardUuid}) => {
  const dashboardReportOptions = accountingDashboardReports({dashboards});
  if (dashboardReportOptions.length === 0) {
    return (
      <EmptyStateContainer>
        <Space height={80} />
        <EmptyStateTitle>There are no accounting reports to show.</EmptyStateTitle>
        <Space height={8} />
        <EmptyStateTitle>
          Manage your reports in the{' '}
          <Link to={`/settings/reports`}>
            <LinkText>Settings page</LinkText>
          </Link>
          .
        </EmptyStateTitle>
      </EmptyStateContainer>
    );
  }
  return (
    <ViewReportContainer>
      <Space height={24} />
      <FieldInput
        name={'report'}
        value={dashboardUuid}
        label={'View Report'}
        component={DropdownInput}
        input={{
          options: dashboardReportOptions,
          placeholder: 'Select accounting report',
          style: {width: '100%'},
          setFieldValue: (_name, value) => {
            setDashboardUuid(value);
          },
        }}
        style={{
          width: 360,
        }}
        index={0}
      />
      <Space height={36} />
    </ViewReportContainer>
  );
};

const AccountingReportsPage = () => {
  const [dashboardUuid, setDashboardUuid] = useState('');
  const {loading, data} = useQuery(AccountingReportsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid: dashboardUuid,
    },
  });

  if (loading) {
    return (
      <SidebarPageV2 selected={'accounting'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  return (
    <SidebarPageV2 selected={'accounting'}>
      <Container>
        <AccountingPageHeader organization={data.viewer.viewingOrganization} />
        <BackgroundContainer>
          <SectionContainer index={0}>
            <ViewReportDropdown
              dashboards={data.viewer.viewingOrganization.dashboards}
              dashboardUuid={dashboardUuid}
              setDashboardUuid={setDashboardUuid}
            />
          </SectionContainer>
          {data.dashboard && (
            <React.Fragment>
              <ViewReportContainer>
                <SubheadingTitle>Last Viewed Report: {data.dashboard.name}</SubheadingTitle>
              </ViewReportContainer>
              <Space height={16} />
              <ExploDashboard
                viewerId={data.viewer.id}
                name={data.dashboard.name}
                dashboardId={data.dashboard.id}
                exploDashboardEmbedId={data.dashboard.exploDashboardEmbedId}
                exploUserGroupToken={data.dashboard.organization.exploUserGroupToken}
                exploVariables={data.dashboard.exploVariables}
              />
            </React.Fragment>
          )}
        </BackgroundContainer>
      </Container>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AccountingReportsPage.query = gql`
  ${AccountingPageHeader.fragment}

  query AccountingReportsPage($uuid: String!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        dashboards {
          id
          name
          uuid
          category
        }
        ...AccountingPageHeader
      }
    }
    dashboard(dashboardUuid: $uuid) { 
      id 
      name
      exploDashboardEmbedId
      exploVariables
      organization {
        id
        exploUserGroupToken
      }
    }
  }
`;

export default AccountingReportsPage;
