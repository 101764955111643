// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import AuthorizeDotNetPanel from 'modules/Organization/Settings/Company/components/AuthorizeDotNetPanel';
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';
import CrewShowSavedCardsPanel from 'modules/Organization/Settings/Company/components/CrewShowSavedCardsPanel';
import OfficePaymentMethodsPanel from 'modules/Organization/Settings/Company/components/OfficePaymentMethodsPanel';
import PayEnginePanel from 'modules/Organization/Settings/Company/components/PayEnginePanel';
import PaymentFeesPanel from 'modules/Organization/Settings/Company/components/PaymentFeesPanel';
import StripePanel from 'modules/Organization/Settings/Company/components/StripePanel';
import TipOptionsPanel from 'modules/Organization/Settings/Company/components/TipOptionsPanel';

const SectionTitle = Styled.Text`
  ${Typography.Responsive.Heading1}
`;

const Heading = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const Row = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const PaymentMethods = ({organization, responsive, refetch}) => {
  return (
    <React.Fragment>
      <SectionTitle responsive={responsive}>Office App</SectionTitle>
      <Space height={24} />
      <OfficePaymentMethodsPanel organization={organization} />
      <Space height={24} />
      <SectionTitle responsive={responsive}>Crew App</SectionTitle>
      <Space height={24} />
      <PaymentFeesPanel
        organization={organization}
        refetch={refetch}
        index={0}
        isViewingChildBranch={!organization.isPrimary}
      />
      <Space height={24} />
      <CrewShowSavedCardsPanel
        organization={organization}
        index={1}
        isViewingChildBranch={!organization.isPrimary}
      />
      <Space height={24} />
      <TipOptionsPanel
        organization={organization}
        index={2}
        isViewingChildBranch={!organization.isPrimary}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const Processors = ({organization, role, responsive}) => {
  return (
    <React.Fragment>
      <Heading responsive={responsive}>Processors</Heading>
      <Space height={16} />
      <Description responsive={responsive}>
        Configure the payment processors used on Supermove.
      </Description>
      <Space height={24} />
      <Row index={0}>
        <PayEnginePanel organization={organization} role={role} />
      </Row>
      {organization.settings.isStripePayengineGatewayEnabled && (
        <React.Fragment>
          <Space height={24} />
          <Row index={1}>
            <StripePanel organization={organization} redirectPath={'/settings/company/payments'} />
          </Row>
        </React.Fragment>
      )}
      {organization.features.isEnabledPayengineGatewayAuthorizeDotNet && (
        <React.Fragment>
          <Space height={24} />
          <Row index={2}>
            <AuthorizeDotNetPanel organization={organization} />
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyPaymentsPageContent = ({viewer, refetch}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <PaymentMethods
        organization={viewer.viewingOrganization}
        responsive={responsive}
        refetch={refetch}
      />
      <Space height={24} />
      <Processors
        organization={viewer.viewingOrganization}
        role={viewer.role}
        responsive={responsive}
      />
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyPaymentsPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsCompanyPaymentsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <CompanySettingsPage
      title={'Payments'}
      description={'Manage payment fees and configure payment settings.'}
    >
      <Loading loading={loading}>
        {() => (
          <OrganizationSettingsCompanyPaymentsPageContent viewer={data?.viewer} refetch={refetch} />
        )}
      </Loading>
    </CompanySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyPaymentsPage.query = gql`
  ${CrewShowSavedCardsPanel.fragment}
  ${OfficePaymentMethodsPanel.fragment}
  ${StripePanel.fragment}
  ${PayEnginePanel.fragment}
  ${PaymentFeesPanel.fragment}
  ${AuthorizeDotNetPanel.fragment}
  ${TipOptionsPanel.fragment}
  query OrganizationSettingsCompanyPaymentsPage {
    ${gql.query}
    viewer {
      id
      role
      viewingOrganization {
        id
        isPrimary
        features {
          isEnabledPayengineGatewayAuthorizeDotNet: isEnabled(feature: "PAYENGINE_GATEWAY_AUTHORIZE_DOT_NET")
        }
        settings {
          id
          isStripePayengineGatewayEnabled
        }
        ...CrewShowSavedCardsPanel
        ...OfficePaymentMethodsPanel
        ...StripePanel
        ...PayEnginePanel
        ...PaymentFeesPanel
        ...AuthorizeDotNetPanel
        ...TipOptionsPanel
      }
    }
  }
`;

export default OrganizationSettingsCompanyPaymentsPage;
