// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (slotType) => ({
    slotTypeId: slotType.id,
    index: slotType.index,
    driverId: slotType.driverId,
    organizationId: slotType.organizationId,
    truckId: slotType.truckId,
  }),
  gql`
    fragment SlotTypeForm_edit on SlotType {
      id
      index
      driverId
      organizationId
      truckId
    }
  `,
);

const _new = ({organizationId, index}) => ({
  index,
  truckId: undefined,
  driverId: undefined,
  slotTypeId: undefined,
  organizationId,
});

const toForm = ({index, truckId, driverId, slotTypeId, organizationId}) => {
  return {
    index,
    truckId,
    driverId,
    slotTypeId,
    organizationId,
  };
};

const toMutation = ({index, truckId, driverId, slotTypeId, organizationId}) => {
  return {
    index,
    truckId,
    driverId,
    slotTypeId,
    organizationId,
  };
};

const SlotTypeForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default SlotTypeForm;
