// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {useQuery, useResponsive} from '@supermove/hooks';

const GetPositionWrapper = Styled.View`
  z-index: ${({index}: {index: number}) => 100 - index};
`;

interface ProjectBlockWrapperProps {
  index: number;
  query: any;
  queryVariables: any;
  layoutKey: string;
  handleSetPositionY: () => void;
  SkeletonComponent?: React.FC;
  style?: any;
  children: any;
}

const ProjectBlockWrapper = ({
  index,
  query,
  queryVariables,
  layoutKey,
  handleSetPositionY,
  SkeletonComponent,
  style,
  children,
}: ProjectBlockWrapperProps) => {
  const {data, loading, refetch} = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
  });

  const responsive = useResponsive();

  // Because we use cache-and-network, there is an edge case when navigating
  // to the project page directly to a new job that was just created from the
  // edit project jobs page. The project page attempts to load the job data
  // from the cache, but there is intially no job data because the job was
  // just created. This check ensures that we have either the project or job
  // before rendering the block.
  const isDataReady = data && (data.project || data.job);

  return (
    <Loading loading={loading || !isDataReady} as={SkeletonComponent}>
      {() => {
        return (
          <GetPositionWrapper
            index={index}
            key={layoutKey}
            onLayout={handleSetPositionY}
            style={
              style ||
              (responsive.desktop
                ? null
                : {
                    paddingHorizontal: 16,
                    paddingVertical: 24,
                  })
            }
          >
            {children({data, refetch})}
          </GetPositionWrapper>
        );
      }}
    </Loading>
  );
};

export default ProjectBlockWrapper;
