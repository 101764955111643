// Libraries
import PropTypes from 'prop-types';
import React from 'react';

import {Permissions} from '@supermove/sdk';

// APIs
import StripeTerminal from '../../apis/StripeTerminal';

class StripeTerminalProvider extends React.Component {
  state = {
    isInitialized: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.checkAndInitialize();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async checkAndInitialize() {
    const status = await Permissions.check(Permissions.LOCATION);
    if (this.mounted && status === Permissions.RESULTS.AUTHORIZED) {
      await this.initialize();
    }
  }

  async initialize() {
    const {host, endpoint, stripeAccountId} = this.props;
    try {
      console.log('Initializing Stripe Terminal');
      await StripeTerminal.initialize({
        host,
        endpoint,
        stripeAccountId,
      });

      if (this.mounted) {
        console.log('Successfully initialized Stripe Terminal');
        this.setState({isInitialized: true});
      }
    } catch (error) {
      console.log('Initialize error', {error});
    }
  }

  render() {
    const {isInitialized} = this.state;
    return this.props.children({isInitialized});
  }
}

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
StripeTerminalProvider.propTypes = {
  host: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  stripeAccountId: PropTypes.string.isRequired,
};

StripeTerminalProvider.defaultProps = {};

export default StripeTerminalProvider;
