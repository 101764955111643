// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

import AssignUsersToCrewForm from '@shared/modules/Dispatch/forms/AssignUsersToCrewForm';
import CrewSlotForm from '@shared/modules/Dispatch/forms/CrewSlotForm';

const edit = withFragment(
  (crew, {shouldRunUpsertCostsAndBillingEngineAsync}) => ({
    crewSlotForms: crew.crewSlots.map((crewSlot) => CrewSlotForm.edit(crewSlot)),
    assignUsersToCrewForm: AssignUsersToCrewForm.edit(crew),
    shouldRunUpsertCostsAndBillingEngineAsync: shouldRunUpsertCostsAndBillingEngineAsync || false,
  }),
  gql`
    ${CrewSlotForm.edit.fragment}
    ${AssignUsersToCrewForm.edit.fragment}

    fragment AssignUsersToCrewWithCrewSlotsForm_edit on Crew {
      id
      crewSlots {
        id
        ...CrewSlotForm_edit
      }
      ...AssignUsersToCrewForm_edit
    }
  `,
);

const toForm = ({
  crewSlotForms,
  assignUsersToCrewForm,
  shouldRunUpsertCostsAndBillingEngineAsync,
}) => ({
  crewSlotForms: crewSlotForms.map((crewSlotForm) => CrewSlotForm.toForm(crewSlotForm)),
  assignUsersToCrewForm: AssignUsersToCrewForm.toForm(assignUsersToCrewForm),
  shouldRunUpsertCostsAndBillingEngineAsync,
});

const toMutation = ({
  crewSlotForms,
  assignUsersToCrewForm,
  shouldRunUpsertCostsAndBillingEngineAsync,
}) => ({
  crewSlotForms: crewSlotForms.map((crewSlotForm) => CrewSlotForm.toMutation(crewSlotForm)),
  assignUsersToCrewForm: AssignUsersToCrewForm.toMutation(assignUsersToCrewForm),
  shouldRunUpsertCostsAndBillingEngineAsync,
});

const AssignUsersToCrewWithCrewSlotsForm = {
  // Initialize
  edit,

  // Conversion
  toForm,
  toMutation,
};

export default AssignUsersToCrewWithCrewSlotsForm;
