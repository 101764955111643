// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Datetime} from '@supermove/utils';

const getServerDate = withFragment(
  (textMessage) => {
    return textMessage.sentAt
      ? Datetime.toDisplayDate(Datetime.fromDatetime(textMessage.sentAt), Datetime.SERVER_DATE)
      : null;
  },
  gql`
    fragment TextMessage_getServerDate on TextMessage {
      id
      sentAt
    }
  `,
);

const getDisplayDate = withFragment(
  (textMessage) => {
    return textMessage.sentAt
      ? Datetime.toDisplayDate(Datetime.fromDatetime(textMessage.sentAt), 'M/DD/YYYY')
      : null;
  },
  gql`
    fragment TextMessage_getDisplayDate on TextMessage {
      id
      sentAt
    }
  `,
);

const getDisplayTime = withFragment(
  (textMessage) => {
    return textMessage.sentAt
      ? Datetime.toDisplayTime(Datetime.fromDatetime(textMessage.sentAt))
      : null;
  },
  gql`
    fragment TextMessage_getDisplayTime on TextMessage {
      id
      sentAt
    }
  `,
);

const TextMessage = {
  getServerDate,
  getDisplayDate,
  getDisplayTime,
};

export default TextMessage;
