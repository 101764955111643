// Supermove
import _ from 'lodash';

// Supermove
import {uuid} from '@supermove/utils';

export interface TransformWorkflowActionFormType {
  workflowRunStepUuid: string;
  transformWorkflowFormulaString: string | null;
  transformPath: string | null;
}

const _new = (): TransformWorkflowActionFormType => ({
  workflowRunStepUuid: uuid(),
  transformWorkflowFormulaString: null,
  transformPath: null,
});

const edit = (transformWorkflowActionForm: any): TransformWorkflowActionFormType => ({
  workflowRunStepUuid: transformWorkflowActionForm.workflowRunStepUuid,
  transformWorkflowFormulaString: transformWorkflowActionForm.transformWorkflowFormulaString,
  transformPath: transformWorkflowActionForm.transformPath,
});

const toForm = (
  TransformWorkflowActionForm: TransformWorkflowActionFormType,
): TransformWorkflowActionFormType => ({
  workflowRunStepUuid: TransformWorkflowActionForm.workflowRunStepUuid,
  transformWorkflowFormulaString: TransformWorkflowActionForm.transformWorkflowFormulaString,
  transformPath: TransformWorkflowActionForm.transformPath,
});

const toMutation = (
  TransformWorkflowActionForm: TransformWorkflowActionFormType,
): TransformWorkflowActionFormType => ({
  workflowRunStepUuid: TransformWorkflowActionForm.workflowRunStepUuid,
  transformWorkflowFormulaString: TransformWorkflowActionForm.transformWorkflowFormulaString,
  transformPath: TransformWorkflowActionForm.transformPath,
});

const TransformWorkflowActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default TransformWorkflowActionForm;
