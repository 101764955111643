// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Json} from '@supermove/utils';

const _getNotificationSettingsJsonValue = (organization, key) => {
  const notificationSettings = _.get(organization, 'settings.notifications', '{}');
  const json = Json.toCamelCaseKeys(Json.parse(notificationSettings));
  return json[key];
};

const edit = withFragment(
  (organization) => ({
    organizationId: organization.id,
    allowEmployeeText: _getNotificationSettingsJsonValue(organization, 'allowEmployeeText'),
    sendReminderSetSchedule: _getNotificationSettingsJsonValue(
      organization,
      'sendReminderSetSchedule',
    ),
    allowMoverAcceptDeclineJob: _getNotificationSettingsJsonValue(
      organization,
      'allowMoverAcceptDeclineJob',
    ),
  }),
  gql`
    fragment OrganizationMoverNotificationSettingsForm_edit on Organization {
      id
      settings {
        id
        notifications
      }
    }
  `,
);

const toForm = ({
  organizationId,
  allowEmployeeText,
  sendReminderSetSchedule,
  allowMoverAcceptDeclineJob,
}) => ({
  organizationId,
  allowEmployeeText,
  sendReminderSetSchedule,
  allowMoverAcceptDeclineJob,
});

const toMutation = ({
  organizationId,
  allowEmployeeText,
  sendReminderSetSchedule,
  allowMoverAcceptDeclineJob,
}) => ({
  organizationId,
  allowEmployeeText,
  sendReminderSetSchedule,
  allowMoverAcceptDeclineJob,
});

const OrganizationMoverNotificationSettingsForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationMoverNotificationSettingsForm;
