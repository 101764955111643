// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ImportInventoryLibraryCategoriesForm from '@shared/modules/File/forms/ImportInventoryLibraryCategoriesForm';

const useImportInventoryLibraryCategoriesMutation = ({
  importInventoryLibraryCategoriesForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      importInventoryLibraryCategoriesForm: ImportInventoryLibraryCategoriesForm.toForm(
        importInventoryLibraryCategoriesForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useImportInventoryLibraryCategoriesMutation.mutation,
    variables: {
      importInventoryLibraryCategoriesForm: ImportInventoryLibraryCategoriesForm.toMutation(
        form.values.importInventoryLibraryCategoriesForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useImportInventoryLibraryCategoriesMutation.mutation = gql`
  mutation useImportInventoryLibraryCategoriesMutation(
    $importInventoryLibraryCategoriesForm: ImportInventoryLibraryCategoriesForm!
  ) {
    response: importInventoryLibraryCategories(
      importInventoryLibraryCategoriesForm: $importInventoryLibraryCategoriesForm
    ) {
      ${gql.errors}
    }
  }
`;

export default useImportInventoryLibraryCategoriesMutation;
