// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

export interface PayengineCreditCardFormInterface {
  payengineCreditCardId: number | null;
  projectId: number | null;
  isDefault: boolean | undefined;
  nickname: string;
}

interface PayengineCreditCard {
  id: number;
  isDefault: boolean;
  nickname: string;
}

interface PayengineCreditCardFormArgsNew {
  isDefault?: boolean;
  projectId?: number;
}

const _new = ({isDefault, projectId}: PayengineCreditCardFormArgsNew = {}) => ({
  payengineCreditCardId: null,
  projectId: projectId || null,
  isDefault,
  nickname: '',
});

const edit = withFragment(
  (payengineCreditCard: PayengineCreditCard, {projectId}: {projectId?: number} = {}) => ({
    payengineCreditCardId: payengineCreditCard.id,
    isDefault: payengineCreditCard.isDefault,
    nickname: payengineCreditCard.nickname,
    projectId: projectId || null,
  }),
  gql`
    fragment PayengineCreditCardForm_edit on PayEngineCreditCard {
      id
      isDefault
      nickname
    }
  `,
);

const toForm = ({
  payengineCreditCardId,
  isDefault,
  nickname,
  projectId,
}: PayengineCreditCardFormInterface) => ({
  payengineCreditCardId,
  isDefault,
  nickname,
  projectId,
});

const toMutation = ({
  payengineCreditCardId,
  isDefault,
  nickname,
  projectId,
}: PayengineCreditCardFormInterface) => ({
  payengineCreditCardId,
  isDefault,
  nickname,
  projectId,
});

const PayengineCreditCardForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default PayengineCreditCardForm;
