const _new = (projectId) => ({
  projectId,
  containerIds: [],
});

const toForm = ({projectId, containerIds}) => ({
  projectId,
  containerIds,
});

const toMutation = ({projectId, containerIds}) => {
  return {
    projectId,
    containerIds,
  };
};

const AssignContainersToProjectForm = {
  new: _new,
  toForm,
  toMutation,
};

export default AssignContainersToProjectForm;
