// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectStatusForm from '@shared/modules/Project/forms/ProjectStatusForm';

const useUpdateProjectStatusMutation = ({projectStatusForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      projectStatusForm: ProjectStatusForm.toForm(projectStatusForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectStatusMutation.mutation,
    variables: {
      projectStatusForm: ProjectStatusForm.toMutation(form.values.projectStatusForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateProjectStatusMutation.mutation = gql`
  mutation useUpdateProjectStatusMutation($projectStatusForm: ProjectStatusForm!) {
    response: updateProjectStatus(projectStatusForm: $projectStatusForm) {
      ${gql.errors}
      project {
        id
        status
      }
    }
  }
`;

export default useUpdateProjectStatusMutation;
