// Libraries
import _ from 'lodash';

const CUSTOMER = 'CUSTOMER';
const MOVER = 'MOVER';
const OFFICE = 'OFFICE';

const CATEGORY_OPTIONS = {
  CUSTOMER: {
    value: CUSTOMER,
    label: 'Customer',
  },
  MOVER: {
    value: MOVER,
    label: 'Mover',
  },
  OFFICE: {
    value: OFFICE,
    label: 'Office',
  },
};

const getCategoryOption = (category) => {
  return CATEGORY_OPTIONS[category];
};

const getNonCustomerCategories = () => {
  return [MOVER, OFFICE];
};

export default {
  CUSTOMER,
  MOVER,
  OFFICE,

  DROPDOWN_OPTIONS: _.values(CATEGORY_OPTIONS),
  getCategoryOption,
  getNonCustomerCategories,
};
