// Supermove
import {Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const OfficeHeader = Styled.View`
  flex-direction: row;
  background-color: ${colors.white};
  align-items: center;
  z-index: 100;
`;

// --------------------------------------------------
// OfficeHeader.Content
// --------------------------------------------------
const OfficeHeaderContent = Styled.View`
  flex-direction: row;
  align-items: center;
`;

// --------------------------------------------------
// OfficeHeader.Title
// --------------------------------------------------
const OfficeHeaderTitle = Styled.Text`
  ${Typography.Heading1};
`;

OfficeHeader.Content = OfficeHeaderContent;
OfficeHeader.Title = OfficeHeaderTitle;

export default OfficeHeader;
