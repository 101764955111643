// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, useState, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import Drawer from '@shared/design/components/Drawer';
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SearchBar from '@shared/design/components/SearchBar';
import Sheet from '@shared/design/components/Sheet';
import Table from '@shared/design/components/Table';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import GenerateCustomDocumentsForJobForm from '@shared/modules/Job/forms/GenerateCustomDocumentsForJobForm';
import useGenerateDocumentsForJobMutation from '@shared/modules/Job/hooks/useGenerateCustomDocumentsForJobMutation';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import DocumentTemplatePreview from 'modules/Job/V2/Move/components/DocumentTemplatePreview';

const Container = Styled.View`
  flex: 1;
`;

const DrawerContainer = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const DocumentTemplatePreviewContainer = Styled.View`
  width: 864px;
  background-color: ${colors.gray.background};
  border-right-width: 1px;
  border-right-color: ${colors.gray.border};
`;

const DocumentTemplateDrawerContainer = Styled.View`
  width: 448px;
  background-color: ${colors.white};
`;

const TableHeaderText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const TableBodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const Touchable = Styled.Touchable`
`;

const IconContainer = Styled.View`
  align-items: center;
  justify-content: center;
  width: 40px;
`;

const SheetActionContainer = Styled.View`
  padding: 16px;
`;

const handlePreviewDocumentTemplate = ({
  previewedDocumentTemplate,
  setPreviewedDocumentTemplate,
  documentTemplateToPreview,
}) => {
  if (previewedDocumentTemplate) {
    if (previewedDocumentTemplate.id === documentTemplateToPreview.id) {
      setPreviewedDocumentTemplate(null);
      return;
    }
  }
  setPreviewedDocumentTemplate(documentTemplateToPreview);
};

const handleDocumentTemplateToggle = ({documentTemplate, form}) => {
  const currentIdentifiers = _.get(
    form.values,
    'generateCustomDocumentsForJobForm.documentTemplateIdentifiers',
  );
  const updatedIdentifiers = _.xor(currentIdentifiers, [documentTemplate.identifier]);
  form.setFieldValue(
    'generateCustomDocumentsForJobForm.documentTemplateIdentifiers',
    updatedIdentifiers,
  );
};

const getColumnDefinitions = ({
  documentTemplates,
  previewedDocumentTemplate,
  setPreviewedDocumentTemplate,
  form,
  responsive,
}) => [
  {
    width: 40,
    headerContent: () => {
      const field = 'generateCustomDocumentsForJobForm.documentTemplateIdentifiers';
      const allDocumentTemplateIdentifiers = documentTemplates.map(
        (documentTemplate) => documentTemplate.identifier,
      );
      const selectedDocumentTemplateIdentifiers = _.get(form.values, field);
      const isAllSelected = _.isEqual(
        selectedDocumentTemplateIdentifiers,
        allDocumentTemplateIdentifiers,
      );
      return (
        <Checkbox
          isChecked={isAllSelected}
          handleToggle={() => {
            if (isAllSelected) {
              form.setFieldValue(field, []);
            } else {
              form.setFieldValue(field, allDocumentTemplateIdentifiers);
            }
          }}
        />
      );
    },
    cellContent: ({item: documentTemplate}) => {
      return (
        <Checkbox
          isChecked={_.includes(
            _.get(form.values, 'generateCustomDocumentsForJobForm.documentTemplateIdentifiers'),
            documentTemplate.identifier,
          )}
          handleToggle={() => handleDocumentTemplateToggle({documentTemplate, form})}
        />
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <TableHeaderText responsive={responsive}>Document Template Name</TableHeaderText>;
    },
    cellContent: ({item: document}) => {
      return <TableBodyText responsive={responsive}>{document.name}</TableBodyText>;
    },
  },
  {
    headerContent: () => {
      return <React.Fragment />;
    },
    cellContent: ({item: documentTemplate}) => {
      return (
        <Touchable
          onPress={() => {
            handlePreviewDocumentTemplate({
              previewedDocumentTemplate,
              setPreviewedDocumentTemplate,
              documentTemplateToPreview: documentTemplate,
            });
          }}
        >
          <IconContainer>
            <Icon
              color={colors.blue.interactive}
              size={Icon.Sizes.Large}
              source={Icon.SearchPlus}
            />
          </IconContainer>
        </Touchable>
      );
    },
  },
];

const DocumentTemplateViewerContent = ({
  project,
  documentTemplates,
  previewedDocumentTemplate,
  setPreviewedDocumentTemplate,
  form,
}) => {
  const responsive = useResponsive();
  const [searchTerm, setSearchTerm] = useState('');
  documentTemplates = _.sortBy(
    _.filter(
      documentTemplates,
      (documentTemplate) =>
        documentTemplate.activeOrMostRecentDocumentTemplateVersion &&
        documentTemplate.name.toLowerCase().includes(searchTerm.toLowerCase()),
    ),
    ['name'],
  );
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        label={'Job'}
        index={0}
        name={'generateCustomDocumentsForJobForm.jobId'}
        component={DropdownInput}
        isRequired
        isResponsive
        input={{
          isSingleOptionSelected: true,
          isPortaled: true,
          options: _.map(project.activeJobs, (job) => ({
            label: `${job.jobType.name} ${job.identifier}`,
            value: job.id,
            jobUuid: job.uuid,
          })),
          style: {flex: 1},
          setFieldValue: () => {},
          onChangeValue: (jobId, {jobUuid}) => {
            form.setFieldValue('generateCustomDocumentsForJobForm.jobId', jobId);
            form.setFieldValue('generateCustomDocumentsForJobForm.jobUuid', jobUuid);
          },
        }}
      />
      <Space height={16} />
      {_.get(form.values, 'generateCustomDocumentsForJobForm.jobId') && (
        <React.Fragment>
          <SearchBar
            placeholder={'Search by document template...'}
            onChangeText={setSearchTerm}
            style={{flex: 1}}
            isResponsive
          />
          <Space height={16} />
          <Table
            columnDefinitions={getColumnDefinitions({
              documentTemplates,
              previewedDocumentTemplate,
              setPreviewedDocumentTemplate,
              form,
              responsive,
            })}
            emptyStateText={'No document templates'}
            items={documentTemplates}
            itemKey={'id'}
            isClickable
            onRowPress={(item) => handleDocumentTemplateToggle({documentTemplate: item, form})}
          />
        </React.Fragment>
      )}
      <Space height={16} />
    </React.Fragment>
  );
};

const DocumentTemplateDrawer = ({
  loading,
  project,
  previewedDocumentTemplate,
  setPreviewedDocumentTemplate,
  isOpen,
  handleClose,
  documentTemplateIdentifierCount,
  form,
  submitting,
  handleSubmit,
}) => {
  return (
    <Drawer isOpen={isOpen} handleClose={handleClose}>
      <DrawerContainer>
        {previewedDocumentTemplate && (
          <DocumentTemplatePreviewContainer>
            <Drawer.Header
              headerText={`Preview: ${previewedDocumentTemplate.name}`}
              handleClose={() => setPreviewedDocumentTemplate()}
              isClosable
            />
            <Container>
              <DocumentTemplatePreview
                documentTemplateUuid={previewedDocumentTemplate.uuid}
                jobUuid={_.get(form.values, 'generateCustomDocumentsForJobForm.jobUuid')}
              />
            </Container>
          </DocumentTemplatePreviewContainer>
        )}
        <DocumentTemplateDrawerContainer>
          <Drawer.Header headerText={'Document Library'} />
          <Drawer.Body bodyScrollStyle={{flex: 1}}>
            <Loading loading={loading || !project} as={PageLoadingIndicator}>
              {() => (
                <DocumentTemplateViewerContent
                  project={project}
                  documentTemplates={project.organization.documentTemplatesByCategory}
                  setPreviewedDocumentTemplate={setPreviewedDocumentTemplate}
                  previewedDocumentTemplate={previewedDocumentTemplate}
                  handleClose={() => {
                    handleClose();
                    setPreviewedDocumentTemplate();
                  }}
                  form={form}
                  submitting={submitting}
                  handleSubmit={handleSubmit}
                />
              )}
            </Loading>
          </Drawer.Body>
          <Drawer.Footer
            isDisabled={_.isEmpty(
              _.get(form.values, 'generateCustomDocumentsForJobForm.documentTemplateIdentifiers'),
            )}
            isSubmitting={submitting}
            primaryAction={handleSubmit}
            secondaryAction={handleClose}
            primaryActionText={`Generate (${documentTemplateIdentifierCount})`}
            secondaryActionText={'Cancel'}
          />
        </DocumentTemplateDrawerContainer>
      </DrawerContainer>
    </Drawer>
  );
};

const ClosePreviewButton = ({setPreviewedDocumentTemplate}) => {
  return (
    <TertiaryButton onPress={() => setPreviewedDocumentTemplate()}>
      <Icon source={Icon.ChevronLeft} color={colors.gray.primary} size={20} />
    </TertiaryButton>
  );
};

const DocumentTemplateSheet = ({
  loading,
  project,
  previewedDocumentTemplate,
  setPreviewedDocumentTemplate,
  isOpen,
  handleClose,
  documentTemplateIdentifierCount,
  form,
  submitting,
  handleSubmit,
}) => {
  return (
    <Sheet
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={previewedDocumentTemplate ? 'Document Preview' : 'Document Library'}
      HeaderLeftComponent={() => {
        if (previewedDocumentTemplate) {
          return <ClosePreviewButton setPreviewedDocumentTemplate={setPreviewedDocumentTemplate} />;
        }
        return null;
      }}
      style={{height: '75%'}}
    >
      <Loading loading={loading || !project} as={PageLoadingIndicator}>
        {() => (
          <React.Fragment>
            <ScrollView
              style={{
                ...(previewedDocumentTemplate
                  ? {
                      marginHorizontal: 16,
                      marginTop: 8,
                      marginBottom: 16,
                      borderWidth: 1,
                      borderColor: colors.gray.border,
                      backgroundColor: colors.white,
                    }
                  : {
                      paddingHorizontal: 16,
                    }),
              }}
            >
              {previewedDocumentTemplate ? (
                <DocumentTemplatePreview
                  documentTemplateUuid={previewedDocumentTemplate.uuid}
                  jobUuid={_.get(form.values, 'generateCustomDocumentsForJobForm.jobUuid')}
                  style={{padding: 16}}
                  isContentOnly
                />
              ) : (
                <DocumentTemplateViewerContent
                  project={project}
                  documentTemplates={project.organization.documentTemplatesByCategory}
                  setPreviewedDocumentTemplate={setPreviewedDocumentTemplate}
                  previewedDocumentTemplate={previewedDocumentTemplate}
                  handleClose={() => {
                    handleClose();
                    setPreviewedDocumentTemplate();
                  }}
                  form={form}
                  submitting={submitting}
                  handleSubmit={handleSubmit}
                />
              )}
            </ScrollView>
            {!previewedDocumentTemplate && (
              <React.Fragment>
                <Line />
                <SheetActionContainer>
                  <Button
                    isResponsive
                    isWidthOfContainer
                    iconLeft={Icon.Check}
                    text={`Generate (${documentTemplateIdentifierCount})`}
                    onPress={handleSubmit}
                  />
                </SheetActionContainer>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Loading>
    </Sheet>
  );
};

const DocumentTemplateViewer = ({jobId, jobUuid, project, documentTemplateViewer, refetch}) => {
  const responsive = useResponsive();
  const {data, loading} = useQuery(DocumentTemplateViewer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid: project.uuid,
    },
    skip: !documentTemplateViewer.isOpen,
  });
  const [previewedDocumentTemplate, setPreviewedDocumentTemplate] = useState(null);

  const generateCustomDocumentsForJobForm = GenerateCustomDocumentsForJobForm.new({
    jobId,
    jobUuid,
    organizationId: project.organizationId,
  });
  const {form, submitting, handleSubmit} = useGenerateDocumentsForJobMutation({
    generateCustomDocumentsForJobForm,
    onSuccess: () => {
      refetch();
      documentTemplateViewer.handleClose();
      generateCustomDocumentsSuccessToast.handleToast();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const documentTemplateIdentifierCount = _.size(
    _.get(form.values, 'generateCustomDocumentsForJobForm.documentTemplateIdentifiers'),
  );
  const generateCustomDocumentsSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: `${documentTemplateIdentifierCount} ${pluralize(
      'document',
      documentTemplateIdentifierCount,
    )} generated`,
  });

  if (responsive.desktop) {
    return (
      <DocumentTemplateDrawer
        loading={loading}
        project={data?.project}
        previewedDocumentTemplate={previewedDocumentTemplate}
        setPreviewedDocumentTemplate={setPreviewedDocumentTemplate}
        isOpen={documentTemplateViewer.isOpen}
        handleClose={documentTemplateViewer.handleClose}
        documentTemplateIdentifierCount={documentTemplateIdentifierCount}
        form={form}
        submitting={submitting}
        handleSubmit={handleSubmit}
      />
    );
  }

  return (
    <DocumentTemplateSheet
      loading={loading}
      project={data?.project}
      previewedDocumentTemplate={previewedDocumentTemplate}
      setPreviewedDocumentTemplate={setPreviewedDocumentTemplate}
      isOpen={documentTemplateViewer.isOpen}
      handleClose={documentTemplateViewer.handleClose}
      documentTemplateIdentifierCount={documentTemplateIdentifierCount}
      form={form}
      submitting={submitting}
      handleSubmit={handleSubmit}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentTemplateViewer.query = gql`
  query DocumentTemplateViewer($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      activeJobs {
        id
        uuid
        identifier
        jobType {
          id
          name
        }
      }
      organization {
        id
        documentTemplatesByCategory(categories: ["JOB"]){
          id
          name
          uuid
          identifier
          activeOrMostRecentDocumentTemplateVersion {
            id
          }
        }
      }
    }
  }
`;

DocumentTemplateViewer.fragment = gql`
  fragment DocumentTemplateViewer on Project {
    id
    uuid
    organizationId
  }
`;

export default DocumentTemplateViewer;
