// App
import useUploadFileForm from '@shared/modules/File/hooks/useUploadFileForm';
import useUpdateOrganizationLogoMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationLogoMutation';

const useUploadOrganizationLogoPhoto = ({
  uploadFileForm,
  organizationLogoForm,
  onSuccess,
  onError,
}) => {
  // Update organization logo (#2)
  const {
    form: formOrganizationLogo,
    submitting: submittingOrganizationLogo,
    handleSubmit: handleSubmitOrganizationLogo,
  } = useUpdateOrganizationLogoMutation({
    organizationLogoForm,
    onSuccess,
    onError,
  });

  // Upload and save file (#1)
  const {
    form: formUploadFile,
    submitting: submittingUploadFile,
    handleSubmit: handleSubmitUploadFile,
  } = useUploadFileForm({
    uploadFileForm,
    onSuccess: async ({file}) => {
      await formOrganizationLogo.setFieldValue(`organizationLogoForm.logoFileId`, file.id);
      handleSubmitOrganizationLogo();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return {
    form: formUploadFile,
    submitting: submittingUploadFile || submittingOrganizationLogo || uploadFileForm.isSubmitting,
    handleSubmit: handleSubmitUploadFile,
    formOrganizationLogo,
  };
};

export default useUploadOrganizationLogoPhoto;
