// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Shared
import UpdateJobValuesForm from '@shared/modules/Billing/forms/UpdateJobValuesForm';
import ValueForm from '@shared/modules/Billing/forms/ValueForm';

const edit = withFragment(
  (project) => ({
    projectId: project.id,
    updateJobValuesForms: project.activeJobs.map((job) => UpdateJobValuesForm.edit(job)),
    valueForms: project.values.map((value) => ValueForm.edit(value)),
  }),
  gql`
    ${UpdateJobValuesForm.edit.fragment}
    ${ValueForm.edit.fragment}

    fragment UpdateProjectValuesForm_edit on Project {
      id
      activeJobs {
        id
        ...UpdateJobValuesForm_edit
      }
      values {
        id
        ...ValueForm_edit
      }
    }
  `,
);

const toForm = ({projectId, updateJobValuesForms, valueForms}) => ({
  projectId,
  updateJobValuesForms: updateJobValuesForms.map((updateJobValuesForm) => {
    return UpdateJobValuesForm.toForm(updateJobValuesForm);
  }),
  valueForms: valueForms.map((valueForm) => ValueForm.toForm(valueForm)),
});

const toMutation = ({projectId, updateJobValuesForms, valueForms}) => ({
  projectId,
  updateJobValuesForms: updateJobValuesForms.map((updateJobValuesForm) => {
    return UpdateJobValuesForm.toMutation(updateJobValuesForm);
  }),
  valueForms: valueForms.map((valueForm) => ValueForm.toMutation(valueForm)),
});

const UpdateProjectValuesForm = {
  // Initialize
  edit,

  // Serialize
  toForm,
  toMutation,
};

export default UpdateProjectValuesForm;
