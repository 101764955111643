// Libraries
import React from 'react';

// App
import {ScrollView, Space, Styled, Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal, useNavigationDOM, usePopover, useToast} from '@supermove/hooks';
import {Organization, User} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Json, Phone} from '@supermove/utils';

// Components
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Table from '@shared/design/components/Table';
import Tabs from '@shared/design/components/Tabs';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import useResendMoverAppInvitationTextMutation from '@shared/modules/User/hooks/useResendMoverAppInvitationTextMutation';
import {UpdateSuperAdminImpersonateMutation} from 'modules/App/components';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import TextTooltip from 'modules/App/components/TextTooltip';
import StaffCommunicationButton from 'modules/Communication/components/StaffCommunicationButton';
import JobUserMoverPosition from 'modules/JobUser/components/JobUserMoverPosition';
import PasscodeCell from 'modules/Organization/Settings/Company/components/PasscodeCell';
import CreateTeamDrawer from 'modules/Organization/Settings/Staff/components/CreateTeamDrawer';
import TeamsTable from 'modules/Organization/Settings/Staff/components/TeamsTable';
import CreateEmployeeModal from 'modules/User/components/CreateEmployeeModal';
import DeleteUserModal from 'modules/User/components/DeleteUserModal';
import UpdateEmployeeModal from 'modules/User/components/UpdateEmployeeModal';

const PageContentContainer = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
`;

const Section = Styled.View`
  margin-bottom: 20px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const PositionsContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ActionsCell = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 5px;
  flex: 1;
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const HeaderText = Styled.Text`
  ${Typography.Label}
`;

const Text = Styled.Text`
  ${Typography.Body}
`;

const ActiveText = Styled.Text`
  ${Typography.Subheading}
`;

const TABS = {
  CREW: 'crew',
  TEAMS: 'teams',
};

const getTabIndex = ({tab}) => {
  switch (tab) {
    case TABS.CREW:
      return 0;
    case TABS.TEAMS:
      return 1;
    default:
      return 0;
  }
};

const handleTabPress = ({route, navigator}) => {
  navigator.push(`/settings/staff/movers/${route}`);
};

const getPageSubTabs = ({organization}) => {
  return [
    {
      label: 'Crew',
      count: organization.activeMovers.length,
      route: TABS.CREW,
    },
    {
      label: 'Teams',
      count: organization.crewTeams.length,
      route: TABS.TEAMS,
      prependSeparator: true,
    },
  ];
};

const OfficeMoverContent = ({
  tab,
  canImpersonate,
  organization,
  viewer,
  refetch,
  crewTeams,
  isCrewTeam,
}) => {
  switch (tab) {
    case TABS.CREW:
      return (
        <ActiveInactiveMoversSection
          canImpersonate={canImpersonate}
          activeMovers={organization.activeMovers}
          inactiveMovers={organization.inactiveMovers}
          organization={organization}
          viewerRole={viewer.role}
          refetch={refetch}
        />
      );
    case TABS.TEAMS:
      return (
        <TeamsTable teams={crewTeams} refetch={refetch} viewer={viewer} isCrewTeam={isCrewTeam} />
      );
    default:
      return null;
  }
};

const getStaffMoversSectionColumnDefinitions = ({
  canImpersonate,
  organization,
  refetch,
  viewerRole,
}) => [
  {
    flex: 1,
    headerContent: () => {
      return null;
    },
    cellContent: ({rowIndex}) => {
      return <Text>{rowIndex + 1}</Text>;
    },
  },
  {
    flex: 2,
    isHidden: !canImpersonate,
    headerContent: () => {
      return <HeaderText>Super</HeaderText>;
    },
    cellContent: ({item: user}) => {
      return (
        <UpdateSuperAdminImpersonateMutation
          user={user}
          onSuccess={() => {
            window.location = '/';
          }}
        >
          {({loading, handleSubmit}) => (
            <Button loading={loading} onPress={handleSubmit}>
              {!loading && <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.User} />}
            </Button>
          )}
        </UpdateSuperAdminImpersonateMutation>
      );
    },
  },
  {
    flex: 4,
    minWidth: 170,
    headerContent: () => {
      return <HeaderText>Name</HeaderText>;
    },
    cellContent: ({item: user}) => {
      return <Text>{user.fullName}</Text>;
    },
  },
  {
    flex: 3,
    headerContent: () => {
      return <HeaderText>Driver</HeaderText>;
    },
    cellContent: ({item: user}) => {
      return <Text>{user.hasDriverMoverPosition ? 'Yes' : 'No'}</Text>;
    },
  },
  {
    flex: 8,
    minWidth: 250,
    headerContent: () => {
      return <HeaderText>Positions</HeaderText>;
    },
    cellContent: ({item: user}) => {
      return <Positions user={user} />;
    },
  },
  {
    flex: 3,
    isHidden: !organization.shouldCreateCommercialMoves,
    headerContent: () => {
      return <HeaderText>Branch Code</HeaderText>;
    },
    cellContent: ({item: user}) => {
      return <Text>{user.branchCode}</Text>;
    },
  },
  {
    flex: 3,
    minWidth: 150,
    headerContent: () => {
      return <HeaderText>Phone Number</HeaderText>;
    },
    cellContent: ({item: user}) => {
      return <Text>{user.phoneNumber ? Phone.display(user.phoneNumber) : '---'}</Text>;
    },
  },
  {
    flex: 3,
    headerContent: () => {
      return <HeaderText>Tablet Passcode</HeaderText>;
    },
    cellContent: ({item: user}) => {
      return user.tabletPin === '' ? <Text>-</Text> : <PasscodeCell passcode={user.tabletPin} />;
    },
  },
  {
    flex: 3,
    minWidth: 150,
    headerContent: () => {
      return <HeaderText>Can View Move Details</HeaderText>;
    },
    cellContent: ({item: user}) => {
      if (Json.parse(user.features).VIEW_CUSTOMER_INFO) {
        return <Icon color={colors.gray.primary} size={Icon.Sizes.Large} source={Icon.Check} />;
      }
    },
  },
  {
    flex: 4,
    minWidth: 50,
    headerContent: () => {
      return <HeaderText>Actions</HeaderText>;
    },
    cellContent: ({item: user}) => {
      return (
        <StaffMoversSectionActions
          user={user}
          refetch={refetch}
          viewerRole={viewerRole}
          organization={organization}
        />
      );
    },
  },
];

const Positions = ({user}) => {
  const userMoverPositions = User.getSortedUserMoverPositions(user);

  return (
    <PositionsContainer>
      {userMoverPositions.map((userMoverPosition, index) => (
        <React.Fragment key={userMoverPosition.id}>
          {index > 0 && <Space width={4} />}
          <JobUserMoverPosition.Badge
            isPrimary={userMoverPosition.isPrimary}
            color={colors.gray.secondary}
            name={userMoverPosition.moverPosition.name}
            isDisabled
            style={{marginVertical: 2}}
          />
        </React.Fragment>
      ))}
    </PositionsContainer>
  );
};

const MoverActionsMenu = ({user, popover, deleteUserModal, updateEmployeeModal, organization}) => {
  const successResendTextToast = useToast({
    ToastComponent: SuccessToast,
    message: `Invitation sent!`,
    isClosable: true,
  });

  const {handleSubmit} = useResendMoverAppInvitationTextMutation({
    userId: user.id,
    onSuccess: () => {
      successResendTextToast.handleToast();
    },
    onError: (errors) => {
      console.log(errors);
    },
  });

  return (
    <ActionMenuPopover
      popover={popover}
      placement={Popover.Positions.LeftEnd}
      width={208}
      offset={[4, 202]}
    >
      <ActionMenuPopover.MenuItem
        onPress={() => {
          updateEmployeeModal.handleOpen();
          popover.handleClose();
        }}
      >
        Edit
      </ActionMenuPopover.MenuItem>
      {organization.features.isEnabledNewMoverApp && (
        <ActionMenuPopover.MenuItem onPress={handleSubmit}>
          Resend invitation
        </ActionMenuPopover.MenuItem>
      )}
      <ActionMenuPopover.MenuItem
        onPress={() => {
          deleteUserModal.handleOpen();
          popover.handleClose();
        }}
      >
        Remove
      </ActionMenuPopover.MenuItem>
    </ActionMenuPopover>
  );
};

const StaffMoversSectionActions = ({user, refetch, viewerRole, organization}) => {
  const updateEmployeeModal = useModal({name: 'UpdateEmployeeModal'});
  const deleteUserModal = useModal({name: 'DeleteUserModal'});
  const moverActionsPopover = usePopover();
  const organizationSettingsNotification = Organization.getNotifications(organization);
  const isMoverTextDisabled = !organizationSettingsNotification.allowEmployeeText;

  return (
    <React.Fragment>
      <ActionsCell>
        <TextTooltip
          text={
            isMoverTextDisabled &&
            'Sending SMS to movers is disabled. Update this setting on the permissions tab.'
          }
        >
          <Container>
            <StaffCommunicationButton
              user={user}
              viewerRole={viewerRole}
              refetch={refetch}
              isDisabled={isMoverTextDisabled}
            />
          </Container>
        </TextTooltip>
        <Space width={8} />
        <Popover.Content innerRef={moverActionsPopover.ref}>
          <MoreActionsButton onPress={moverActionsPopover.handleOpen}>
            <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
          </MoreActionsButton>
        </Popover.Content>
      </ActionsCell>
      <MoverActionsMenu
        user={user}
        popover={moverActionsPopover}
        deleteUserModal={deleteUserModal}
        updateEmployeeModal={updateEmployeeModal}
        organization={organization}
      />
      <DeleteUserModal
        deleteUserModal={deleteUserModal}
        handleClose={deleteUserModal.handleClose}
        userId={user.id}
        refetch={refetch}
      />
      <UpdateEmployeeModal
        userUuid={user.uuid}
        refetch={refetch}
        isOpen={updateEmployeeModal.isOpen}
        handleClose={updateEmployeeModal.handleClose}
      />
    </React.Fragment>
  );
};

const MoversSectionTable = ({canImpersonate, viewerRole, refetch, organization, users}) => {
  return (
    <Table
      columnDefinitions={getStaffMoversSectionColumnDefinitions({
        canImpersonate,
        organization,
        viewerRole,
        refetch,
      })}
      emptyStateText={'No users to display'}
      items={users}
      isDense
      rowStyle={{padding: '8', borderWidth: '0'}}
    />
  );
};

const ActiveInactiveMoversSection = ({
  canImpersonate,
  activeMovers,
  inactiveMovers,
  organization,
  viewerRole,
  refetch,
}) => {
  return (
    <ScrollView>
      <Section>
        <Section>
          <MoversSectionTable
            canImpersonate={canImpersonate}
            viewerRole={viewerRole}
            refetch={refetch}
            users={activeMovers}
            organization={organization}
          />
        </Section>
        <Section>
          <ActiveText>Inactive ({inactiveMovers.length})</ActiveText>
          <Space height={10} />
          <MoversSectionTable
            canImpersonate={canImpersonate}
            viewerRole={viewerRole}
            refetch={refetch}
            users={inactiveMovers}
            organization={organization}
          />
        </Section>
      </Section>
    </ScrollView>
  );
};

const StaffMoversSectionV2 = ({organization, viewer, refetch}) => {
  const {navigator, params} = useNavigationDOM();
  const createTeamDrawer = useDrawer({name: 'Create Team Drawer'});
  const activeTabIndex = getTabIndex({tab: params.subTab});

  return (
    <ScrollView horizontal contentContainerStyle={{flex: 1, minWidth: 800}}>
      <PageContentContainer>
        <Row>
          <Space style={{flex: 1}} />
          <SecondaryButton text={'Create Team'} onPress={createTeamDrawer.handleOpen} />
          <Space width={12} />
          <CreateEmployeeModal
            organization={organization}
            refetch={refetch}
            trigger={({handleOpen}) => <Button text={'Create Crew Member'} onPress={handleOpen} />}
          />
          <Space width={12} />
          <Button
            text={'View Crew Schedules'}
            onPress={() => navigator.push('/settings/staff/schedules')}
          />
        </Row>
        <Space height={16} />
        <Tabs
          tabs={getPageSubTabs({organization})}
          handlePressTab={({route}) => handleTabPress({route, navigator})}
          activeTabIndex={activeTabIndex}
        />
        <OfficeMoverContent
          tab={params.subTab}
          canImpersonate={viewer.role === 'super'}
          organization={organization}
          viewer={viewer}
          refetch={refetch}
          crewTeams={organization.crewTeams}
          isCrewTeam
        />
      </PageContentContainer>
      <CreateTeamDrawer
        key={createTeamDrawer.key}
        isOpen={createTeamDrawer.isOpen}
        organization={organization}
        isCrewTeam
        handleClose={() => {
          createTeamDrawer.handleClose();
          if (params.subTab === TABS.TEAMS) {
            return refetch();
          }
          navigator.push(`/settings/staff/movers/${TABS.TEAMS}`);
        }}
      />
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StaffMoversSectionV2.fragment = gql`
  ${CreateEmployeeModal.fragment}
  ${StaffCommunicationButton.fragment}
  ${TeamsTable.fragment}
  ${CreateTeamDrawer.fragment}
  ${Organization.getNotifications.fragment}
  ${User.getSortedUserMoverPositions.fragment}

  fragment StaffMoversSectionV2 on Organization {
    id
    shouldCreateCommercialMoves
    features {
      isEnabledNewMoverApp: isEnabled(feature: "NEW_MOVER_APP")
    }
    crewTeams {
      id
      members {
        id
        role
      }
      ...TeamsTable
    }
    activeMovers: filteredActiveUsersByRole(roles: ["employee"]) {
      id
      uuid
      fullName
      hasDriverMoverPosition
      lastName
      phoneNumber
      email
      features
      role
      tabletPin
      branchCode
      userMoverPositions {
        id
        isPrimary
        moverPosition {
          id
          name
        }
      }
      ...StaffCommunicationButton
      ...User_getSortedUserMoverPositions
    }
    inactiveMovers: filteredInactiveUsersByRole(roles: ["employee"]) {
      id
      uuid
      fullName
      hasDriverMoverPosition
      lastName
      phoneNumber
      email
      features
      role
      tabletPin
      branchCode
      userMoverPositions {
        id
        isPrimary
        moverPosition {
          id
          name
        }
      }
      ...StaffCommunicationButton
    }
    ...CreateEmployeeModal
    ...CreateTeamDrawer
    ...Organization_getNotifications
  }

  fragment StaffMoversSectionV2_Viewer on User {
    id
    ...TeamsTable_Viewer
  }
`;

export default StaffMoversSectionV2;
