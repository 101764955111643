/* eslint-disable react-hooks/exhaustive-deps */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled, Space, Link, Tooltip} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, usePagination, useEffect, useForm} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {Datetime, URL} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Table from '@shared/design/components/Table';
import CodatOperation from '@shared/modules/Integration/enums/CodatOperation';
import FilteredCodatPushOperationsForm from '@shared/modules/Integration/forms/FilteredCodatPushOperationsForm';
import OrganizationSettingsCompanyIntegrationsAccountingHistoryPageFilters from 'modules/Organization/Settings/Company/components/OrganizationSettingsCompanyIntegrationsAccountingHistoryPageFilters';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const LinkStyle = {
  fontFamily: 'Avenir, sans-serif',
  color: colors.blue.interactive,
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '20px',
  textDecorationLine: 'underline',
  textDecorationColor: colors.blue.interactive,
  cursor: 'pointer',
  width: '100%',
};

const LinkWrapper = Styled.ButtonV2`
  flex: 1;
`;

const TableContainer = Styled.View`
  min-width: 100%;
`;

const TooltipText = Styled.Text`
  color: ${colors.white};
`;

const getId = (item) => {
  const dataTypeMap = {
    invoices: (item) => `Invoice ${item.invoiceExport?.invoice?.identifier || 'Unknown '}`,
    payments: (item) =>
      item.paymentExport?.payment?.id
        ? `Payment ${item.paymentExport.payment.id} for Invoice ${item.paymentExport.payment.invoice.identifier}`
        : '(Payment Deleted)',
    customers: (item) => `Client ${item.clientExport?.client?.name || 'Unknown '}`,
    unknown: (item) => 'Unknown',
  };

  const {dataType} = item;
  return dataTypeMap[dataType](item);
};

const getIdLink = (item) => {
  const dataTypeMap = {
    invoices: (item) => `/invoices/${item.invoiceExport?.invoice?.uuid}/view`,
    payments: (item) =>
      item.paymentExport?.payment?.invoice?.uuid
        ? `/invoices/${item.paymentExport?.payment?.invoice?.uuid}/view`
        : null,
    customers: (item) => `/clients/${item.clientExport?.client?.uuid}`,
    unknown: (item) => '',
  };

  const {dataType} = item;
  return dataTypeMap[dataType](item);
};

const getProject = (item) => {
  const dataTypeMap = {
    invoices: (item) => item.invoiceExport?.invoice?.project,
    payments: (item) => item.paymentExport?.payment?.invoice?.project,
    customers: (item) => undefined,
    unknown: (item) => undefined,
  };
  const {dataType} = item;
  return dataTypeMap[dataType](item);
};

const RutterAsyncJobTooltip = ({codatPushOperation}) => {
  const {loading, data} = useQuery(RutterAsyncJobTooltip.query, {
    skip: !codatPushOperation.codatPushOperationKey,
    fetchPolicy: 'network-only',
    variables: {
      codatPushOperationKey: codatPushOperation.codatPushOperationKey,
    },
  });
  return (
    <TooltipText>
      {loading ? 'Loading...' : data?.codatPushOperationByKey?.rutterAsyncJobData || 'No data'}
    </TooltipText>
  );
};

RutterAsyncJobTooltip.query = gql`
  query RutterAsyncJobTooltip($codatPushOperationKey: String!) {
    codatPushOperationByKey(codatPushOperationKey: $codatPushOperationKey) {
      id
      rutterAsyncJobData
    }
  }
`;

const getCodatPushOperationColumnDefinitions = ({codatIntegration, viewer}) => {
  const columns = [
    {
      width: 150,
      headerContent: () => <Table.HeaderText>Timestamp</Table.HeaderText>,
      cellContent: ({item, index}) => (
        <Table.CellText>{Datetime.convertToDisplayDatetime(item.createdAt)}</Table.CellText>
      ),
    },
    {
      width: 150,
      headerContent: () => <Table.HeaderText>Type</Table.HeaderText>,
      cellContent: ({item, index}) => (
        <Table.CellText>{CodatOperation.getDisplayOperation(item.operation)}</Table.CellText>
      ),
    },
    {
      width: 150,
      headerContent: () => <Table.HeaderText>Item</Table.HeaderText>,
      cellContent: ({item, index}) => {
        const idLink = getIdLink(item);
        return idLink ? (
          <LinkWrapper>
            <Link style={LinkStyle} to={idLink}>
              {getId(item)}
            </Link>
          </LinkWrapper>
        ) : (
          <Table.CellText>{getId(item)}</Table.CellText>
        );
      },
    },
    {
      width: 100,
      headerContent: () => <Table.HeaderText>Project</Table.HeaderText>,
      cellContent: ({item, index}) => {
        const project = getProject(item);
        if (!project) {
          return <Table.CellText>-</Table.CellText>;
        }
        return (
          <LinkWrapper>
            <Link style={LinkStyle} to={`/projects/${project.uuid}`}>
              {project.identifier}
            </Link>
          </LinkWrapper>
        );
      },
    },

    {
      width: 100,
      headerContent: () => <Table.HeaderText>Status</Table.HeaderText>,
      cellContent: ({item, index}) => (
        <Table.CellText>{CodatOperation.getDisplayStatus(item.status)}</Table.CellText>
      ),
    },
    {
      width: 150,
      headerContent: () => <Table.HeaderText>Batch</Table.HeaderText>,
      cellContent: ({item, index}) => {
        if (!item.batch) {
          return <Table.CellText>-</Table.CellText>;
        }
        const linkToBatch = `/settings/company/accounting/${codatIntegration.uuid}/exports/${item.batch.uuid}`;
        const batchText = `Batch ${item.batch.id}`;
        return (
          <LinkWrapper>
            <Link style={LinkStyle} to={linkToBatch}>
              {batchText}
            </Link>
          </LinkWrapper>
        );
      },
    },
  ];
  if (viewer?.role === 'super' && codatIntegration.kind === 'RUTTER') {
    columns.push({
      width: 150,
      headerContent: () => <Table.HeaderText>Rutter Async Job ID</Table.HeaderText>,
      cellContent: ({item, index}) => (
        <Tooltip overlay={() => <RutterAsyncJobTooltip codatPushOperation={item} />}>
          <Table.CellText>{item.codatPushOperationKey}</Table.CellText>
        </Tooltip>
      ),
    });
  }
  columns.push({
    flex: 3,
    headerContent: () => <Table.HeaderText>Errors</Table.HeaderText>,
    cellContent: ({item, index}) => {
      const isEmpty = item.validation === '{}';
      return !isEmpty ? (
        <Table.CellText style={{textWrap: 'nowrap'}}>{item.prettyErrorMessage}</Table.CellText>
      ) : (
        <Table.CellText>-</Table.CellText>
      );
    },
  });
  return columns;
};

const CodatPushOperationTable = ({data, codatIntegration, viewer, loading}) => {
  return (
    <Table
      columnDefinitions={getCodatPushOperationColumnDefinitions({codatIntegration, viewer})}
      emptyStateText='No accounting logs to display'
      items={_.orderBy(
        data,
        [(codatPushOperation) => _.toLower(codatPushOperation.createdAt)],
        ['desc'],
      )}
      itemKey={'id'}
      isDense
      loading={loading}
    />
  );
};

const AccountingHistoryContent = ({
  organization,
  currentPage,
  setCurrentPage,
  form,
  loading,
  viewer,
}) => {
  const {codatPushOperations} =
    organization?.codatIntegrationByUuid?.filteredCodatPushOperationsPaginatedList || {};
  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(
      organization || {},
      'codatIntegrationByUuid.filteredCodatPushOperationsPaginatedList.paginationMetadata',
    ),
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });
  return (
    <React.Fragment>
      <OrganizationSettingsCompanyIntegrationsAccountingHistoryPageFilters form={form} />
      <Space height={20} />
      <ScrollView horizontal>
        <TableContainer style={{width: '1200px'}}>
          <ScrollView horizontal style={{width: '1200px'}}>
            <CodatPushOperationTable
              data={codatPushOperations}
              viewer={viewer}
              codatIntegration={organization?.codatIntegrationByUuid}
              pagination={pagination}
              loading={loading}
            />
          </ScrollView>
          <Space height={20} />
          <PaginationBar pagination={pagination} />
        </TableContainer>
      </ScrollView>
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyIntegrationsAccountingHistoryPage = () => {
  const {navigator, params} = useNavigationDOM();
  const {uuid} = params;
  const form = useForm({
    initialValues: {
      filteredCodatPushOperationsForm: FilteredCodatPushOperationsForm.toForm(params),
    },
  });
  const currentPage = Number(form.values.filteredCodatPushOperationsForm.page);
  const {loading, data} = useQuery(
    OrganizationSettingsCompanyIntegrationsAccountingHistoryPage.query,
    {
      fetchPolicy: 'network-only',
      variables: {
        uuid,
        pagination: {
          page: currentPage,
          resultsPerPage: 20,
        },
        ...FilteredCodatPushOperationsForm.toVariables(form.values.filteredCodatPushOperationsForm),
      },
    },
  );

  // Update the URL params whenever the filters are updated
  useEffect(() => {
    navigator.replace(
      URL.getUrlFromVariables(
        `/settings/company/accounting/${uuid}/logs`,
        FilteredCodatPushOperationsForm.toQueryParams(form.values.filteredCodatPushOperationsForm),
      ),
    );
  }, [form.values.filteredCodatPushOperationsForm]);
  // Return to page 1 whenever new filters are applied
  useEffect(() => {
    form.setFieldValue('filteredCodatPushOperationsForm.page', 1);
  }, [
    form.values.filteredCodatPushOperationsForm.actions,
    form.values.filteredCodatPushOperationsForm.types,
    form.values.filteredCodatPushOperationsForm.fromDate,
    form.values.filteredCodatPushOperationsForm.toDate,
    form.values.filteredCodatPushOperationsForm.searchQuery,
    form.values.filteredCodatPushOperationsForm.statuses,
  ]);

  return (
    <SettingsPage
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {
          name: 'Company Settings',
          onPress: () => navigator.push('/settings/company/business-info'),
        },
        {name: 'Accounting', onPress: () => navigator.push('/settings/company/accounting')},
        {name: 'Accounting Logs'},
      ]}
      tabs={[
        {
          label: 'General',
          url: `/settings/company/accounting/${params.uuid}/general`,
        },
        {
          label: 'Import',
          url: `/settings/company/accounting/${params.uuid}/imports`,
        },
        {
          label: 'Logs',
          url: `/settings/company/accounting/${params.uuid}/logs`,
        },
        {
          label: 'Exports',
          url: `/settings/company/accounting/${params.uuid}/exports`,
        },
      ]}
    >
      <AccountingHistoryContent
        loading={loading}
        organization={data?.viewer?.viewingOrganization}
        viewer={data?.viewer}
        currentPage={currentPage}
        setCurrentPage={(page) => form.setFieldValue('filteredCodatPushOperationsForm.page', page)}
        form={form}
      />
    </SettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyIntegrationsAccountingHistoryPage.query = gql`
  ${usePagination.fragment}
  query OrganizationSettingsCompanyIntegrationsAccountingHistoryPage(
    $pagination: PaginationInput!
    $uuid: String!
    $fromDate: String
    $searchQuery: String
    $toDate: String
    $actions: [String]
    $types: [String]
    $statuses: [String]
  ) {
    viewer {
      id
      role
      viewingOrganization {
        id
        codatIntegrationByUuid(uuid: $uuid) {
          id
          kind
          filteredCodatPushOperationsPaginatedList(
            pagination: $pagination
            fromDate: $fromDate
            toDate: $toDate
            actions: $actions
            types: $types
            statuses: $statuses
            searchQuery: $searchQuery
          ) {
            codatPushOperations: results {
              id
              createdAt
              codatPushOperationKey
              dataType
              errorMessage
              operation
              status
              prettyErrorMessage
              batch {
                id
                uuid
              }
              clientExport {
                id
                client {
                  id
                  name
                  uuid
                }
              }
              invoiceExport {
                id
                invoice {
                  id
                  identifier
                  uuid
                  project {
                    id
                    uuid
                    identifier
                  }
                }
              }
              paymentExport {
                id
                payment {
                  id
                  invoice {
                    id
                    identifier
                    uuid
                    project {
                      id
                      uuid
                      identifier
                    }
                  }
                }
              }
            }
            paginationMetadata {
              ...usePagination
            }
          }
        }
      }
    }
  }
`;

export default OrganizationSettingsCompanyIntegrationsAccountingHistoryPage;
