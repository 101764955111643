// App
import ReferralSourceForm from '@shared/modules/Company/forms/ReferralSourceForm';

const OrganizationReferralSourcesForm = {
  toForm: ({organizationId, referralSourceForms}) => ({
    organizationId,
    referralSourceForms: referralSourceForms.map((referralSourceForm) =>
      ReferralSourceForm.toForm(referralSourceForm),
    ),
  }),

  toMutation: ({organizationId, referralSourceForms}) => ({
    organizationId,
    referralSourceForms: referralSourceForms.map((referralSourceForm) =>
      ReferralSourceForm.toMutation(referralSourceForm),
    ),
  }),
};

export default OrganizationReferralSourcesForm;
