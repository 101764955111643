// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const FEATURE_FLAGGED_TEMPLATES = ['RISKY_MOVE', 'CHANGE_ORDER'];

const sortDocumentTemplatesByName = withFragment(
  (documentTemplates) => {
    return _.orderBy(documentTemplates, (documentTemplate) => documentTemplate.name.toLowerCase(), [
      'asc',
    ]);
  },
  gql`
    fragment DocumentTemplate_sortDocumentTemplatesByName on DocumentTemplateV2 {
      id
      name
    }
  `,
);

const DocumentTemplate = {
  FEATURE_FLAGGED_TEMPLATES,

  sortDocumentTemplatesByName,
};

export default DocumentTemplate;
