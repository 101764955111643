// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (project) => {
    return {
      projectId: project.id,
      isTest: !project.isTest,
    };
  },
  gql`
    fragment ProjectIsTestForm_edit on Project {
      id
      isTest
    }
  `,
);

const toForm = ({projectId, isTest}) => {
  return {
    projectId,
    isTest,
  };
};

const toMutation = ({projectId, isTest}) => {
  return {
    projectId,
    isTest,
  };
};

const ProjectIsTestForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectIsTestForm;
