// Supermove
import {gql} from '@supermove/graphql';
import {Currency, Percent, withFragment} from '@supermove/utils';

const KIND_PERCENTAGE = 'PERCENTAGE';
const KIND_AMOUNT = 'AMOUNT';

const KIND_DISPLAY_PERCENTAGE = 'Percentage';
const KIND_DISPLAY_AMOUNT = 'Flat Fee';

const getDisplayKind = withFragment(
  (billModifier) => {
    switch (billModifier.kind) {
      case KIND_PERCENTAGE:
        return KIND_DISPLAY_PERCENTAGE;
      case KIND_AMOUNT:
        return KIND_DISPLAY_AMOUNT;
      default:
        return null;
    }
  },
  gql`
    fragment BillModifier_getDisplayKind on BillModifier {
      kind
    }
  `,
);

const getDisplayName = withFragment(
  (billModifier) => {
    switch (billModifier.kind) {
      case KIND_PERCENTAGE:
        return `${billModifier.name} (${Percent.display(billModifier.percentage)})`;
      case KIND_AMOUNT:
      default:
        return billModifier.name;
    }
  },
  gql`
    fragment BillModifier_getDisplayName on BillModifier {
      kind
      name
      percentage
    }
  `,
);

const getDisplayValue = withFragment(
  (billModifier) => {
    switch (billModifier.kind) {
      case BillModifier.KINDS.AMOUNT:
        return Currency.display(billModifier.amount, {shouldHideCentsIfZero: true});
      case BillModifier.KINDS.PERCENTAGE:
        return Percent.display(billModifier.percentage);
      default:
        return null;
    }
  },
  gql`
    fragment BillModifier_getDisplayValue on BillModifier {
      kind
      percentage
      amount
    }
  `,
);

const getDisplayTotal = withFragment(
  (billModifier, {isEstimateAvailable}) => {
    if (billModifier.kind === KIND_AMOUNT) {
      return Currency.format({
        value: billModifier.estimateTotalMin,
        shouldHideCentsIfZero: true,
      });
    }

    if (!isEstimateAvailable) {
      return 'TBD';
    }

    return Currency.formatRange({
      min: billModifier.estimateTotalMin,
      max: billModifier.estimateTotalMax,
      shouldHideCentsIfZero: true,
    });
  },
  gql`
    fragment BillModifier_getDisplayTotal on BillModifier {
      kind
      estimateTotalMin
      estimateTotalMax
    }
  `,
);

const BillModifier = {
  KINDS: {
    AMOUNT: KIND_AMOUNT,
    PERCENTAGE: KIND_PERCENTAGE,
  },

  KINDS_DROPDOWN_OPTIONS: [
    {label: KIND_DISPLAY_AMOUNT, value: KIND_AMOUNT},
    {label: KIND_DISPLAY_PERCENTAGE, value: KIND_PERCENTAGE},
  ],

  KINDS_DROPDOWN_OPTIONS_ALT: [
    {label: `${KIND_DISPLAY_AMOUNT} (After Subtotal)`, value: KIND_AMOUNT},
    {label: `${KIND_DISPLAY_PERCENTAGE} (After Subtotal)`, value: KIND_PERCENTAGE},
  ],

  KINDS_TAB_OPTIONS: [
    {label: KIND_DISPLAY_AMOUNT, value: KIND_AMOUNT},
    {label: KIND_DISPLAY_PERCENTAGE, value: KIND_PERCENTAGE},
  ],

  getDisplayKind,
  getDisplayName,
  getDisplayTotal,
  getDisplayValue,
};

export default BillModifier;
