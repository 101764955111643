// Libraries
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography, colors} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import OrganizationLogo from '@shared/modules/Organization/components/OrganizationLogo';

const Wrapper = Styled.View`
  align-items: center;
  z-index: ${(props) => 100 - props.index};
`;

const Name = Styled.Text`
  ${Typography.Label3}
  text-align: center;
`;

const Text = Styled.Text`
  ${Typography.Body3};
  text-align: center;
`;

const Logo = Styled.Text`
  ${Typography.Heading4}
  background-color: ${colors.black};
  color: ${colors.white};
  font-weight: 900;
  text-align: center;
  padding-vertical: 16px;
  padding-horizontal: 24px;
  width: 300px;
  height: 62px;
`;

const DocumentV2DocumentHeaderOrganizationContent = ({index, organization, isPreview}) => {
  return (
    <Wrapper index={index}>
      {isPreview ? (
        <Logo>Organization Logo</Logo>
      ) : (
        <OrganizationLogo
          organization={organization}
          style={{
            width: 195,
            height: 80,
          }}
        />
      )}
      <Space height={8} />
      <Name>{organization.businessName}</Name>
      <Text>{organization.businessLicense}</Text>
      <Text>{organization.businessAddress}</Text>
      <Text>{Phone.display(organization.phoneNumber)}</Text>
    </Wrapper>
  );
};

const DocumentV2DocumentHeaderOrganizationPreview = ({index}) => {
  const organization = MOCK_ORGANIZATION_DATA;
  return (
    <DocumentV2DocumentHeaderOrganizationContent
      index={index}
      organization={organization}
      isPreview
    />
  );
};

const DocumentV2DocumentHeaderOrganizationQueried = ({index, project}) => {
  const {organization} = project;
  return (
    <DocumentV2DocumentHeaderOrganizationContent
      index={index}
      organization={organization}
      isPreview={false}
    />
  );
};

const ProjectDocumentV2DocumentHeaderOrganization = ({index, project, isPreview}) => {
  return (
    <React.Fragment>
      {isPreview ? (
        <DocumentV2DocumentHeaderOrganizationPreview index={index} />
      ) : (
        <DocumentV2DocumentHeaderOrganizationQueried project={project} index={index} />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectDocumentV2DocumentHeaderOrganization.fragment = gql`
  ${OrganizationLogo.fragment}
  fragment ProjectDocumentV2DocumentHeaderOrganization on Project {
    id
    organization {
      id
      slug
      phoneNumber
      businessAddress
      businessLicense
      businessName
      ...OrganizationLogo
    }
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_ORGANIZATION_DATA = {
  id: 1,
  slug: 'slug',
  phoneNumber: '1234567890',
  businessAddress: '123 Main St',
  businessLicense: '123456789',
  businessName: 'Business Name',
};

export default ProjectDocumentV2DocumentHeaderOrganization;
