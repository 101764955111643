import Expression, {CallExpression, ExpressionJSON} from '@shared/ast/Expression';
import {FormulaEvaluator} from '@shared/formulas/src/FormulaEvaluator';

interface Formula {
  astJson: string;
  identifier: string;
}

export default class ExtractFormulaIdentifiersPlugin {
  private formulaIdentifiers: string[] = [];

  private formulaStack: string[] = [];

  private formulas: Formula[];

  private currentFormulaIdentifier: string;

  constructor(formulas: Formula[], currentFormulaIdentifier: string, formulaStack?: string[]) {
    this.formulas = formulas;
    this.currentFormulaIdentifier = currentFormulaIdentifier;
    this.formulaStack = formulaStack || [];
  }

  evaluate(expression: Expression): void {
    if (expression instanceof CallExpression && expression.name === 'formula') {
      const formulaIdentifier = expression.args[0].value;
      if (this.currentFormulaIdentifier === formulaIdentifier) {
        throw new Error('A formula cannot reference itself');
      }
      if (this.formulaStack.includes(formulaIdentifier)) {
        throw new Error(`Loop detected: ${formulaIdentifier}`);
      }
      this.formulaIdentifiers.push(formulaIdentifier);
      this.formulaStack.push(formulaIdentifier);

      const formula = this.formulas.find((f) => f.identifier === formulaIdentifier);
      if (!formula) {
        throw new Error(`Formula not found: ${formulaIdentifier}`);
      }
      if (formula) {
        const evaluator = new FormulaEvaluator({
          plugins: [
            new ExtractFormulaIdentifiersPlugin(
              this.formulas,
              formulaIdentifier,
              this.formulaStack,
            ),
          ],
          customFunctionImplementations: {
            formula: {
              call: (arg: any) => 0,
              checkArity: () => {},
              checkTypes: () => {},
            },
          },
        });

        evaluator.evaluate(Expression.fromJSON(JSON.parse(formula.astJson)));
        const nestedFormulaIdentifiers = evaluator.plugins[0].getResult();
        this.formulaIdentifiers.push(...nestedFormulaIdentifiers);
      }
      this.formulaStack.pop();
    }
  }

  onVisit(expression: Expression): void {
    this.evaluate(expression);
  }

  getResult(): string[] {
    return [...new Set(this.formulaIdentifiers)];
  }
}

export {ExtractFormulaIdentifiersPlugin};
