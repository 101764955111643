// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = withFragment(
  ({billingLibrary, userId}) => ({
    billTypeId: null,
    billingLibraryId: billingLibrary.id,
    organizationId: billingLibrary.organizationId,
    name: '',
    description: '',
    billItemTypeIds: [],
    billRuleTypeIds: [],
    isDeleted: false,
    createdById: userId,
    updatedById: userId,
  }),
  gql`
    fragment BillTypeForm_new on BillingLibrary {
      id
      organizationId
    }
  `,
);

const edit = withFragment(
  (billType, {userId}) => ({
    billTypeId: billType.id,
    billingLibraryId: billType.billingLibraryId,
    organizationId: billType.organizationId,
    name: billType.name,
    description: billType.description,
    billItemTypeIds: billType.billItemTypeIds,
    billRuleTypeIds: billType.billRuleTypeIds,
    isDeleted: billType.isDeleted,
    updatedById: userId,
    createdById: billType.createdById,
  }),
  gql`
    fragment BillTypeForm_edit on BillType {
      id
      billingLibraryId
      organizationId
      name
      description
      billItemTypeIds
      billRuleTypeIds
      isDeleted
    }
  `,
);

const toForm = ({
  billTypeId,
  billingLibraryId,
  organizationId,
  name,
  description,
  billItemTypeIds,
  billRuleTypeIds,
  isDeleted,
  createdById,
  updatedById,
}) => {
  return {
    billTypeId,
    billingLibraryId,
    organizationId,
    name,
    description,
    billItemTypeIds: billItemTypeIds.map((id) => _.toString(id)),
    billRuleTypeIds: billRuleTypeIds.map((id) => _.toString(id)),
    isDeleted,
    createdById,
    updatedById,
  };
};

const toMutation = ({
  billTypeId,
  billingLibraryId,
  organizationId,
  name,
  description,
  billItemTypeIds,
  billRuleTypeIds,
  isDeleted,
  createdById,
  updatedById,
}) => {
  return {
    billTypeId,
    billingLibraryId,
    organizationId,
    name,
    description,
    billItemTypeIds,
    billRuleTypeIds,
    isDeleted,
    createdById,
    updatedById,
  };
};

const BillTypeForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default BillTypeForm;
