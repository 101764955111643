// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Calendar, Emoji, Icon, Query, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Day} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime, pluralize} from '@supermove/utils';

// App
import OfficeHeader from 'modules/App/components/OfficeHeader';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import CalendarDayDetails from 'modules/Calendar/components/CalendarDayDetails';
import CalendarDayDetailsV1 from 'modules/Calendar/components/CalendarDayDetailsV1';
import SalesCalendarActions from 'modules/Calendar/components/SalesCalendarActions';

const Wrapper = Styled.View`
  ${(props) => (props.mobile ? 'height: 750px' : 'flex: 1')};
  flex-direction: row;
  width: 100%;
`;

const Container = Styled.View`
  ${(props) => (props.mobile ? 'width: 100%' : 'flex: 1')};
  height: 100%;
  padding: ${(props) => (props.mobile ? 10 : 20)}px;
`;

const Section = Styled.View`
  flex: 1;
`;

const DateHeader = Styled.H7`
  padding-top: 5px;
  padding-left: 5px;
  ${fontWeight(500)}
`;

const DateCell = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-horizontal: 5px;
  background-color: ${(props) => props.backgroundColor};
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Touchable = Styled.Touchable`
  flex: 1;
`;

const JobsCount = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-transform: uppercase;
  text-align: center;
`;

const EmojiText = Styled.Text`
  margin-left: 3px;
`;

const Status = Styled.View`
  position: absolute;
  left: 0px;
  bottom: 0px;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.mobile ? 'center' : 'flex-end')};
  width: 100%;
  height: 30px;
  padding-horizontal: 5px;
`;

const StatusText = Styled.H7`
  margin-right: 2px;
  color: ${({vars}) => vars.color};
  ${fontWeight(700)}
`;

const Right = Styled.View`
  width: 576px;
  height: 100%;
`;

const ToolbarBlocker = Styled.View`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 200px;
  height: 40px;
  background-color: ${colors.white};
`;

const CalendarDateCellStatus = ({calendarDay}) => {
  const responsive = useResponsive();
  const statusColor = Day.getCalendarStatusColor(calendarDay);
  const statusIcon = Day.getCalendarStatusIcon(calendarDay);

  return (
    <Status {...responsive}>
      {!!calendarDay.status.value && (
        <StatusText vars={{color: statusColor}}>{calendarDay.status.value}</StatusText>
      )}
      {!!statusIcon && <Icon color={statusColor} size={Icon.Sizes.Medium} source={statusIcon} />}
    </Status>
  );
};

const CalendarDateCell = ({isTarget, cellDate, data}) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();
  const isPast = Datetime.isPast(cellDate);
  const isToday = Datetime.isToday(cellDate);
  let backgroundColor;

  if (isPast) {
    // #F1F2F6, 50%
    backgroundColor = 'rgba(241, 242, 246, 0.75)';
  } else if (isToday) {
    backgroundColor = '#EBEFFD';
  } else {
    backgroundColor = colors.white;
  }

  // Whether cell is highlighted by a border.
  const isHighlighted = isTarget && !responsive.mobile;
  const style = {
    borderRightWidth: isHighlighted ? 3 : 1,
    borderBottomWidth: isHighlighted ? 3 : 1,
    borderLeftWidth: isHighlighted ? 3 : 0,
    borderTopWidth: isHighlighted ? 3 : 0,
    borderColor: isHighlighted ? colors.blue.interactive : colors.gray.tertiary,
    borderStyle: 'solid',
  };

  if (!data || !data.viewer) {
    return <DateCell backgroundColor={backgroundColor} style={style} />;
  }

  const calendarDays = _.get(data, 'calendarV2.calendarDays');
  const calendarDay = _.find(calendarDays, (calendarDay) => {
    return _.get(calendarDay, 'day.value') === cellDate;
  });

  const jobsCount = _.get(calendarDay, 'jobsCount') || 0;
  const isActive = jobsCount > 0;
  const hasStatus = _.get(calendarDay, 'status.kind') !== null;

  return (
    <Touchable
      onPress={() => {
        if (responsive.mobile) {
          navigator.push(`/manage/${cellDate}`);
        } else if (!isTarget) {
          navigator.replace(`/calendar?date=${cellDate}`);
        }
      }}
      {...responsive}
    >
      <DateCell backgroundColor={backgroundColor} style={style}>
        {isActive && (
          <JobsCount vars={{isTarget}}>
            {!responsive.large ? jobsCount : pluralize('job', jobsCount, true)}
            {!responsive.large && <Emoji component={EmojiText} name={'articulated_lorry'} />}
          </JobsCount>
        )}
        {isActive && hasStatus && <CalendarDateCellStatus calendarDay={calendarDay} data={data} />}
      </DateCell>
    </Touchable>
  );
};

const CalendarPane = ({data, date, isController, navigator}) => (
  <Section>
    {!isController && <ToolbarBlocker />}
    <Calendar
      date={date}
      isSundayFirst={false}
      onNavigate={
        isController &&
        ((navigateDate) =>
          navigator.replace(`/calendar?date=${Datetime.toMutationDate(navigateDate)}`))
      }
      dateCellWrapperComponent={({value}) => {
        const cellDate = Datetime.toMutationDate(value); // The date of this calendar cell.
        const isTarget = cellDate === date; // Whether this calendar cell is the active date selected.

        return <CalendarDateCell isTarget={isTarget} cellDate={cellDate} data={data} />;
      }}
      dateHeaderComponent={({date, label}) => <DateHeader>{label}</DateHeader>}
    />
  </Section>
);

const Calendars = ({date, navigator, viewer}) => (
  <Query
    fetchPolicy={'cache-and-network'}
    variables={{
      month: Datetime.toMutationMonth(date),
      kinds: ['MOVE'],
    }}
    query={JobsCalendar.query}
  >
    {({data}) => (
      <React.Fragment>
        <CalendarPane data={data} date={date} isController navigator={navigator} />
      </React.Fragment>
    )}
  </Query>
);

const JobsCalendar = ({searchParams, viewer, isHeaderHidden}) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();
  return (
    <React.Fragment>
      {isHeaderHidden ? null : (
        <StandardOfficeHeader title={'Moves Calendar'}>
          <OfficeHeader.Content style={{flex: 1, marginLeft: responsive.mobile ? '10px' : '24px'}}>
            <SalesCalendarActions viewer={viewer} />
          </OfficeHeader.Content>
        </StandardOfficeHeader>
      )}
      <Wrapper {...responsive}>
        <Container {...responsive} data-test-id='moves-calendar-container'>
          <Calendars
            date={searchParams.date || Datetime.toMutationDate(Datetime.today)}
            navigator={navigator}
            viewer={viewer}
          />
        </Container>
        {!responsive.mobile && (
          <Right {...responsive} data-test-id='moves-calendar-right-panel'>
            {viewer.viewingOrganization.features.isEnabledMovesCalendarJobCardV2 ? (
              <CalendarDayDetails
                date={searchParams.date || Datetime.toMutationDate(Datetime.today)}
              />
            ) : (
              <CalendarDayDetailsV1
                date={searchParams.date || Datetime.toMutationDate(Datetime.today)}
              />
            )}
          </Right>
        )}
      </Wrapper>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobsCalendar.fragment = gql`
  ${SalesCalendarActions.fragment}

  fragment JobsCalendar on User {
    id
    viewingOrganization {
      id
      hasMultipleJobTypes
      features {
        isEnabledMovesCalendarJobCardV2: isEnabled(feature: "MOVES_CALENDAR_JOB_CARD_V2")
      }
    }
    ...SalesCalendarActions
  }
`;

JobsCalendar.query = gql`
  query JobsCalendar($month: String!, $kinds: [String]!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
      }
    }
    calendarV2(month: $month, kinds: $kinds) {
      calendarDays {
        jobsCount
        day {
          id
          value
        }
        status {
          kind
          value
        }
      }
    }
  }
`;

export default JobsCalendar;
