// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, DropdownInput, Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import ProjectDateKind from '@shared/modules/Project/enums/ProjectDateKind';
import TextTooltip from 'modules/App/components/TextTooltip';

const IconContainer = Styled.View`
  width: 16px;
`;

const JobTypeModalFields = ({form, projectType, isUpdate}) => {
  const isUpdateAndNotEstimateOrMove =
    isUpdate &&
    ![Job.KIND.MOVE, Job.KIND.ESTIMATE].includes(_.get(form.values, 'jobTypeForm.kind'));
  const isPartialEdit = isUpdateAndNotEstimateOrMove || !projectType.organization.isOwnerOfSettings;
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        label={'Name'}
        index={0}
        name={'jobTypeForm.name'}
        input={{
          autoFocus: true,
          required: !_.get(form.values, 'jobTypeForm.name'),
          placeholder: 'Enter job type name',
          disabled: isPartialEdit,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        label={
          <React.Fragment>
            Type
            <Space width={4} />
            <TextTooltip placement={'top'} text={`Determines which calendar your job will appear`}>
              <IconContainer>
                <Icon
                  color={colors.gray.tertiary}
                  size={Icon.Sizes.Medium}
                  source={Icon.InfoCircle}
                />
              </IconContainer>
            </TextTooltip>
          </React.Fragment>
        }
        name={'jobTypeForm.kind'}
        component={DropdownInput}
        index={1}
        input={{
          required: !_.get(form.values, 'jobTypeForm.kind'),
          options: [Job.KIND.MOVE, Job.KIND.ESTIMATE].map((kind) => ({
            label: Job.getKindLabel(kind),
            value: kind,
          })),
          placeholder: 'Select type',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
          disabled: isPartialEdit,
          onChangeValue: (value) => {
            // Clear project date settings
            if (value === Job.KIND.ESTIMATE) {
              form.setFieldValue(`jobTypeForm.projectDateSettings`, []);
              form.setFieldValue(`jobTypeForm.setsPackDates`, false);
              form.setFieldValue(`jobTypeForm.setsLoadDates`, false);
              form.setFieldValue(`jobTypeForm.setsDeliveryDates`, false);
            }
          },
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        label={'Default Bill Template(s)'}
        name={'jobTypeForm.billTypeIds'}
        component={MultiDropdownCheckboxInput}
        index={2}
        input={{
          options: projectType.billingLibrary.billTypes.map((billType) => ({
            label: billType.name,
            value: _.toNumber(billType.id),
          })),
          placeholder: 'Select billing template(s)',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
        }}
      />
      {projectType.isLongDistance &&
        projectType.organization.features.isEnabledAutomaticProjectDates &&
        form.values.jobTypeForm.kind !== Job.KIND.ESTIMATE && (
          <React.Fragment>
            <Space height={16} />
            <FieldInput
              {...form}
              index={3}
              label={
                <React.Fragment>
                  Default Project Date Type(s)
                  <Space width={4} />
                  <TextTooltip
                    placement={'top'}
                    text={`Sets whether this job type will automatically determine the pack, load, and/or delivery dates for long distance trips. This can be overridden in the job page.`}
                    style={{width: 200}}
                  >
                    <IconContainer>
                      <Icon
                        color={colors.gray.tertiary}
                        size={Icon.Sizes.Medium}
                        source={Icon.InfoCircle}
                      />
                    </IconContainer>
                  </TextTooltip>
                </React.Fragment>
              }
              name={'jobTypeForm.projectDateSettings'}
              component={MultiDropdownCheckboxInput}
              input={{
                options: ProjectDateKind.VALUES.map((projectDateKind) => ({
                  value: projectDateKind,
                  label: `${_.capitalize(projectDateKind)} Date(s)`,
                })),
                placeholder: `Select project date types(s)`,
                setFieldValue: form.setFieldValue,
                onChangeValue: (selectedProjectDateKinds) => {
                  ProjectDateKind.VALUES.forEach((projectDateKind) => {
                    const jobTypeSettingsKey =
                      ProjectDateKind.PROJECT_DATE_KIND_TO_JOB_TYPE_SETTINGS_MAP[projectDateKind];
                    if (_.includes(selectedProjectDateKinds, projectDateKind)) {
                      form.setFieldValue(`jobTypeForm.${jobTypeSettingsKey}`, true);
                    } else {
                      form.setFieldValue(`jobTypeForm.${jobTypeSettingsKey}`, false);
                    }
                  });
                },
              }}
              style={{flex: 1}}
            />
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobTypeModalFields.propTypes = {
  isUpdate: PropTypes.bool,
};

JobTypeModalFields.defaultProps = {
  isUpdate: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTypeModalFields.fragment = gql`
  fragment JobTypeModalFields on ProjectType {
    id
    isLongDistance
    billingLibrary {
      id
      billTypes {
        id
        name
      }
    }
    organization {
      id
      isOwnerOfSettings
      features {
        isEnabledAutomaticProjectDates: isEnabled(feature: "AUTOMATIC_PROJECT_DATES")
      }
    }
  }
`;
export default JobTypeModalFields;
