// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import OrganizationReplyToForm from '@shared/modules/Organization/forms/OrganizationReplyToForm';

const useUpdateOrganizationReplyToMutation = ({organizationReplyToForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      organizationReplyToForm: OrganizationReplyToForm.toForm(organizationReplyToForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationReplyToMutation.mutation,
    variables: {
      organizationReplyToForm: OrganizationReplyToForm.toMutation(
        form.values.organizationReplyToForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationReplyToMutation.mutation = gql`
  mutation useUpdateOrganizationReplyToMutation($organizationReplyToForm: OrganizationReplyToForm!) {
    response: updateOrganizationReplyTo(organizationReplyToForm: $organizationReplyToForm) {
      ${gql.errors}
      organization {
        id
      }
    }
  }
`;

export default useUpdateOrganizationReplyToMutation;
