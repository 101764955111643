/* eslint-disable react-hooks/exhaustive-deps */

// Libraries
import React from 'react';

// Supermove
import {CurrencyInput, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useQuery, useResponsive, useState, useToast} from '@supermove/hooks';
import {PayengineCreditCard} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import Callout from '@shared/design/components/Callout';
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import Drawer from '@shared/design/components/Drawer';
import FieldInput from '@shared/design/components/Field/FieldInput';
import RadioButton from '@shared/design/components/RadioButton';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import BeginPaymentV3Form from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import PayengineCreditCardForm from '@shared/modules/Payment/forms/PayengineCreditCardForm';
import {transactionContactMessage} from '@shared/modules/Payment/hooks/payengineErrors';
import useChargePayEngineCreditCardV2 from '@shared/modules/Payment/hooks/useChargePayEngineCreditCardV2';
import ProjectTypePaymentMethod from '@shared/modules/Project/enums/ProjectTypePaymentMethod';
import InvoiceCreditCardsRadioButtonList from 'modules/Accounting/components/InvoiceCreditCardsRadioButtonList';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ClientCreditCardFields from 'modules/Client/components/ClientCreditCardFields';
import ClientCreditCardInformationFields from 'modules/Client/components/ClientCreditCardInformationFields';

const Container = Styled.View`
  width: 100%;
`;

const AddCardButton = Styled.ButtonV2`
  flex-direction: row;
  padding-vertical: 16px;
  padding-horizontal: 8px;
  border-radius: 4px;
  background-color: ${({isSelected}) => (isSelected ? colors.blue.accent : 'transparent')};
`;

const Column = Styled.View`
  flex: 1;
`;

const AddCardText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const AddCardOption = ({
  client,
  form,
  field,
  creditCard,
  setCreditCard,
  errorMessage,
  setErrorMessage,
  setCreditCardClient,
  isDisabled,
}) => {
  const responsive = useResponsive();
  const isAddingCard = !creditCard.id;

  useEffect(() => {
    if (isAddingCard) {
      form.setFieldValue(`${field}.saveToClientId`, client.id);
    } else {
      form.setFieldValue(`${field}.saveToClientId`, null);
    }
  }, [isAddingCard]);

  return (
    <AddCardButton
      isSelected={isAddingCard}
      onPress={() => {
        setCreditCard({});
        setErrorMessage('');
      }}
      disabled={isAddingCard || isDisabled}
    >
      <RadioButton isOn={isAddingCard} />
      <Space width={8} />
      <Column>
        <AddCardText responsive={responsive}>{`Add a new card`}</AddCardText>
        {isAddingCard && (
          <React.Fragment>
            {errorMessage && (
              <React.Fragment>
                <Space height={16} />
                <ErrorCallout text={errorMessage} />
              </React.Fragment>
            )}
            <Space height={16} />
            <ClientCreditCardFields
              client={client}
              form={form}
              field={`${field}.payengineCreditCardForm`}
              setCreditCardClient={setCreditCardClient}
            />
          </React.Fragment>
        )}
      </Column>
    </AddCardButton>
  );
};

const ChargeCreditCardFields = ({
  invoice,
  form,
  field,
  creditCard,
  setCreditCard,
  errorMessage,
  setErrorMessage,
  setCreditCardClient,
  isDisabled,
}) => {
  const client = invoice.project.billingClient;
  const paymentField = `${field}.beginPaymentV3Form`;

  return (
    <Container>
      <FieldInput
        {...form}
        label={'Payment Name'}
        name={`${paymentField}.name`}
        isRequired
        isResponsive
        input={{placeholder: `Enter a payment name`}}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        component={CurrencyInput}
        label={'Payment Amount'}
        name={`${paymentField}.amount`}
        isRequired
        isResponsive
        input={{
          component: FieldInput.TextInput,
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          placeholder: `Enter a payment amount`,
        }}
      />
      <Space height={16} />
      <Callout text={`Remaining Balance: ${Currency.display(invoice.remainingBalance)}`} />
      <Space height={16} />
      <FieldInput.LabelText isResponsive isRequired>
        Payment Method
      </FieldInput.LabelText>
      <Space height={4} />
      <InvoiceCreditCardsRadioButtonList
        invoice={invoice}
        handleSelectCard={(card) => {
          form.setFieldValue(`${field}.saveToClientId`, null);
          form.setFieldValue(
            `${field}.payengineCreditCardForm`,
            PayengineCreditCardForm.toForm(PayengineCreditCardForm.edit(card)),
          );
          setCreditCard(card);
          setCreditCardClient(PayengineCreditCard.getCreditCardClient(card));
          setErrorMessage('');
        }}
        selectedCardId={creditCard.id}
        isDisabled={isDisabled}
        errorMessage={errorMessage}
      />
      <AddCardOption
        client={client}
        form={form}
        field={field}
        creditCard={creditCard}
        setCreditCard={setCreditCard}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        setCreditCardClient={setCreditCardClient}
        isDisabled={isDisabled}
      />
    </Container>
  );
};

const InvoiceChargeCreditCardDrawerContent = ({invoice, onSuccess, handleClose}) => {
  const client = invoice.project.billingClient;
  const defaultCard = client.creditCards.find((card) => card.isDefault);

  const [creditCard, setCreditCard] = useState(defaultCard || {});
  const [errorMessage, setErrorMessage] = useState('');

  const beginPaymentV3Form = BeginPaymentV3Form.forInvoice(invoice, {
    method: ProjectTypePaymentMethod.PAYENGINE_CREDIT_CARD,
  });
  const {form, handleSubmit, submitting, setCreditCardClient} = useChargePayEngineCreditCardV2({
    beginPaymentV3Form,
    createCardResponse: {
      token: creditCard.token,
    },
    onSuccess,
    handleErrorMessage: (message) => {
      setErrorMessage(`${message} ${transactionContactMessage(invoice.project.organization.name)}`);
    },
    handleTokenizeErrorMessage: setErrorMessage,
    creditCard: defaultCard,
  });

  return (
    <React.Fragment>
      <Drawer.Body>
        <ChargeCreditCardFields
          invoice={invoice}
          form={form}
          field={'chargePayengineCreditCardForm'}
          creditCard={creditCard}
          setCreditCard={setCreditCard}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          setCreditCardClient={setCreditCardClient}
          isDisabled={submitting}
        />
      </Drawer.Body>
      <Drawer.Footer
        isSubmitting={submitting}
        primaryAction={handleSubmit}
        secondaryAction={handleClose}
        primaryActionText={'Charge'}
        isResponsive
      />
    </React.Fragment>
  );
};

const InvoiceChargeCreditCardDrawer = ({invoiceUuid, isOpen, handleClose, refetch}) => {
  const {loading, data} = useQuery(InvoiceChargeCreditCardDrawer.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {invoiceUuid},
  });

  const chargeCreditCardSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Card charged successfully',
    isClosable: true,
  });

  return (
    <Drawer isOpen={isOpen} width={Drawer.WIDTH.DEFAULT}>
      <Drawer.Header headerText={'Charge Credit Card'} handleClose={handleClose} isResponsive />
      <Loading loading={loading || !data} as={PageLoadingIndicator}>
        {() => {
          return (
            <InvoiceChargeCreditCardDrawerContent
              invoice={data.invoiceByUuid}
              onSuccess={() => {
                chargeCreditCardSuccessToast.handleToast();
                handleClose();
                refetch();
              }}
              handleClose={handleClose}
            />
          );
        }}
      </Loading>
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceChargeCreditCardDrawer.query = gql`
  ${BeginPaymentV3Form.forInvoice.fragment}
  ${ClientCreditCardInformationFields.fragment}
  ${InvoiceCreditCardsRadioButtonList.fragment}
  ${PayengineCreditCard.getCreditCardClient.fragment}
  ${PayengineCreditCardForm.edit.fragment}
  ${useChargePayEngineCreditCardV2.fragment}

  query InvoiceChargeCreditCardDrawer($invoiceUuid: String!) {
    ${gql.query}
    invoiceByUuid(invoiceUuid: $invoiceUuid) {
      id
      remainingBalance
      project {
        id
        billingClient {
          id
          creditCards {
            id
            token
            isDefault
            ...PayengineCreditCardForm_edit
            ...PayengineCreditCard_getCreditCardClient
            ...useChargePayEngineCreditCardV2
          }
          ...ClientCreditCardInformationFields
        }
        organization {
          id
          name
        }
      }
      ...BeginPaymentV3Form_forInvoice
      ...InvoiceCreditCardsRadioButtonList
    }
  }
`;

export default InvoiceChargeCreditCardDrawer;
