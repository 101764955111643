// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import OrganizationOfficePaymentMethodsForm from '@shared/modules/Organization/forms/OrganizationOfficePaymentMethodsForm';

// App

const useUpdateOrganizationOfficePaymentMethodsMutation = ({
  organizationOfficePaymentMethodsForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      organizationOfficePaymentMethodsForm: OrganizationOfficePaymentMethodsForm.toForm(
        organizationOfficePaymentMethodsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationOfficePaymentMethodsMutation.mutation,
    variables: {
      organizationOfficePaymentMethodsForm: OrganizationOfficePaymentMethodsForm.toMutation(
        form.values.organizationOfficePaymentMethodsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationOfficePaymentMethodsMutation.mutation = gql`
  ${OrganizationOfficePaymentMethodsForm.edit.fragment}
  mutation useUpdateOrganizationOfficePaymentMethodsMutation($organizationOfficePaymentMethodsForm: OrganizationOfficePaymentMethodsForm!) {
    response: updateOrganizationOfficePaymentMethods(organizationOfficePaymentMethodsForm: $organizationOfficePaymentMethodsForm) {
      ${gql.errors}
      organization {
        id
        ...OrganizationOfficePaymentMethodsForm_edit
      }
    }
  }
`;

export default useUpdateOrganizationOfficePaymentMethodsMutation;
