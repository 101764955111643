// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import ReorderBillsForm from '@shared/modules/Billing/forms/ReorderBillsForm';
import useReorderBillsMutation from '@shared/modules/Billing/hooks/useReorderBillsMutation';
import ProjectBillTipsList from 'modules/Project/Billing/BillTip/components/ProjectBillTipsList';
import BillTotalTip from 'modules/Project/Billing/components/BillTotalTip';
import BillsListItem from 'modules/Project/Billing/components/BillsListItem';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const LabelContainer = Styled.View`
  border-top-width: ${({vars}) => (vars.isEditable ? '1px' : '0px')};
  border-bottom-width: ${({vars}) => (vars.isEditable ? '1px' : '0px')};
  border-left-width: ${({vars}) => (vars.isEditable ? '1px' : '0px')};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-color: ${colors.gray.border};
  padding-left: ${(props) => (props.isMobile ? '0px' : '12px')};
  flex-direction: row;
  justify-content: ${(props) => props.justify};
  align-items: center;
`;

const AmountContainer = Styled.View`
  justify-content: center;
  align-items: flex-end;
  padding-vertical: ${({vars}) => (vars.isEditable ? '12px' : '0px')};
  border-top-width: ${({vars}) => (vars.isEditable ? '1px' : '0px')};
  border-bottom-width: ${({vars}) => (vars.isEditable ? '1px' : '0px')};
  flex: ${(props) => props.flex};
  border-color: ${colors.gray.border};
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
  padding-vertical: ${({vars}) => (vars && vars.isEditable ? '12px' : '0px')};
`;

const RightSideBorder = Styled.View`
  justify-content: center;
  align-items: flex-end;
  padding-vertical: 12px;
  padding-right: 12px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-color: ${colors.gray.border};
`;

const getSortedBills = ({bills, orderedBillForms}) => {
  const sortedBills = [];

  orderedBillForms.forEach((billForm) => {
    const matchingBill = _.find(bills, (bill) => String(bill.id) === String(billForm.billId));
    if (matchingBill) {
      sortedBills.push(matchingBill);
    }
  });

  return sortedBills;
};

const TotalLabel = ({isEditable, isProposal}) => {
  const responsive = useResponsive();

  return (
    <LabelContainer
      style={{backgroundColor: colors.white}}
      vars={{isEditable}}
      justify={'flex-end'}
    >
      <LabelText vars={{isEditable}} responsive={responsive}>
        {isProposal ? 'Estimated Total' : 'Grand Total'}
      </LabelText>
    </LabelContainer>
  );
};

const GrandTotal = ({isEditable, isProposal, isTotalAvailable, minTotal, maxTotal}) => {
  const responsive = useResponsive();

  return (
    <Row style={responsive.mobile ? {flex: 1} : {width: 400, alignSelf: 'flex-end'}}>
      {responsive.mobile ? (
        <TotalLabel isEditable={isEditable} isProposal={isProposal} />
      ) : (
        <TotalLabel isEditable={isEditable} isProposal={isProposal} />
      )}
      <AmountContainer style={{backgroundColor: colors.white}} vars={{isEditable}} flex={3}>
        <LabelText responsive={responsive}>
          {isTotalAvailable ? Currency.formatRange({min: minTotal, max: maxTotal}) : 'TBD'}
        </LabelText>
      </AmountContainer>
      {isEditable && <RightSideBorder />}
    </Row>
  );
};

const BillingProjectBillsList = ({
  isDocument,
  isEditable,
  isReordering,
  isProposal,
  isCollapsed,
  includeAllBills,
  showEmptyTotals,
  showQuantity,
  showTotal,
  showGrandTotal,
  showBillRules,
  highlightedJobId,
  project,
  refetch,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
}) => {
  const responsive = useResponsive();
  const {activeJobsAggregateBill, currentAggregateBill} = project;
  const aggregateBill = includeAllBills ? activeJobsAggregateBill : currentAggregateBill;
  const {isTotalAvailable, minTotal, maxTotal, bills, primaryBill} = aggregateBill;
  const reorderBillsForm = ReorderBillsForm.edit(bills);
  const {form, handleSubmit} = useReorderBillsMutation({
    reorderBillsForm,
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      console.log({error});
    },
  });

  const isGrandTotalVisible =
    (isEditable || showGrandTotal) && (showEmptyTotals || isTotalAvailable);

  return (
    <Container>
      {getSortedBills({bills, orderedBillForms: form.values.reorderBillsForm.billForms}).map(
        (bill, index) => {
          return (
            <React.Fragment key={`${index}_BILL`}>
              <BillsListItem
                bill={bill}
                index={index}
                refetch={refetch}
                isDocument={isDocument}
                isEditable={isEditable}
                isReordering={isReordering}
                isHighlighted={
                  !!highlightedJobId && String(bill.jobId) === String(highlightedJobId)
                }
                isCollapsed={isCollapsed}
                reorderBillsForm={form}
                handleSubmit={handleSubmit}
                showQuantity={showQuantity}
                showTotal={showTotal}
                showBillRules={showBillRules}
                showEmptyTotals={showEmptyTotals}
                descriptionField={descriptionField}
                getTruncatedKey={getTruncatedKey}
                truncated={truncated}
                setTruncated={setTruncated}
                enabledToggle={enabledToggle}
                setEnabledToggle={setEnabledToggle}
                isEnabledBillItemDescriptionOnMobile={
                  project.organization.features.isEnabledBillItemDescriptionOnMobile
                }
              />
              <Space height={isEditable ? 8 : 12} />
            </React.Fragment>
          );
        },
      )}
      {isGrandTotalVisible && (
        <React.Fragment>
          <Space height={8} />
          {(primaryBill.hasBillTips || isEditable) && (
            <React.Fragment>
              {project.organization.features.isEnabledSplitTips ? (
                <React.Fragment>
                  <BillTotalTip
                    bill={primaryBill}
                    projectUuid={project.uuid}
                    isEditable={isEditable}
                    refetch={refetch}
                  />
                  <Space height={16} />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <ProjectBillTipsList
                    project={project}
                    isEditable={isEditable}
                    primaryBill={primaryBill}
                    refetch={refetch}
                  />
                  <Space height={responsive.desktop ? 8 : 16} />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          <GrandTotal
            isEditable={isEditable}
            isProposal={isProposal}
            isTotalAvailable={isTotalAvailable}
            minTotal={minTotal}
            maxTotal={maxTotal}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
BillingProjectBillsList.propTypes = {
  showEmptyTotals: PropTypes.bool,
  showQuantity: PropTypes.bool,
  showTotal: PropTypes.bool,
  showGrandTotal: PropTypes.bool,
  includeAllBills: PropTypes.bool,
  isProposal: PropTypes.bool,
  descriptionField: PropTypes.string,
  getTruncatedKey: PropTypes.func,
  truncated: PropTypes.object,
  setTruncated: PropTypes.func,
  enabledToggle: PropTypes.object,
  setEnabledToggle: PropTypes.func,
};

BillingProjectBillsList.defaultProps = {
  showEmptyTotals: true,
  showQuantity: true,
  showTotal: true,
  showGrandTotal: true,
  includeAllBills: true,
  isProposal: false,
  descriptionField: undefined,
  getTruncatedKey: undefined,
  truncated: undefined,
  setTruncated: undefined,
  enabledToggle: undefined,
  setEnabledToggle: undefined,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingProjectBillsList.fragment = gql`
  ${BillTotalTip.fragment}
  ${BillsListItem.fragment}
  ${ProjectBillTipsList.fragment}
  ${ReorderBillsForm.edit.fragment}

  fragment BillingProjectBillsList_AggregateBill on AggregateBill {
    minTotal
    maxTotal
    isTotalAvailable
    primaryBill {
      id
      tip
      total
      hasBillTips
      ...BillTotalTip
      ...ProjectBillTipsList
    }
    bills {
      id
      ...BillsListItem
      ...ReorderBillsForm_edit
    }
  }

  fragment BillingProjectBillsList on Project {
    id
    uuid
    organization {
      id
      features {
        isEnabledBillItemDescriptionOnMobile: isEnabled(feature: "BILL_ITEM_DESCRIPTION_ON_MOBILE")
        isEnabledSplitTips: isEnabled(feature: "SPLIT_TIPS")
      }
    }
    activeJobsAggregateBill {
      ...BillingProjectBillsList_AggregateBill
    }
    currentAggregateBill {
      ...BillingProjectBillsList_AggregateBill
    }
    ...ProjectBillTipsList_Project
  }
`;

export default BillingProjectBillsList;
