// Libraries
import PropTypes from 'prop-types';
import React from 'react';
import {Elements, StripeProvider, injectStripe} from 'react-stripe-elements';

// Supermove
import {useMemo} from '@supermove/hooks';
import {Config} from '@supermove/sdk';

// Payments
import Stripe from '../../apis/Stripe';

const Form = injectStripe(({children, stripe: injectedStripe}) => {
  const stripe = useMemo(() => Stripe.wrap(injectedStripe), [injectedStripe]);
  return children({stripe});
});

const StripeForm = ({children, stripeAccountId}) => {
  return (
    <StripeProvider apiKey={Config.getStripePublishableKey()} stripeAccount={stripeAccountId}>
      <Elements>
        <Form>{children}</Form>
      </Elements>
    </StripeProvider>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
StripeForm.propTypes = {
  children: PropTypes.func.isRequired,

  // Stripe Connect account ID (ID of our customer's Stripe account).
  stripeAccountId: PropTypes.string.isRequired,
};

StripeForm.defaultProps = {};

export default StripeForm;
