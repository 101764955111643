// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Organization, TimesheetBlock} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Duration} from '@supermove/utils';

// App
import TimesheetPayrollEntriesLegend from '@shared/modules/Timesheet/components/TimesheetPayrollEntriesLegend';
import TimesheetBlockKind from '@shared/modules/Timesheet/enums/TimesheetBlockKind';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TimelineBlockWrapper = Styled.View`
  flex-direction: row;
`;

const TimelineBlockTypeContainer = Styled.View`
  padding: 8px;
  border-top-left-radius: ${({isFirst}) => (isFirst ? 4 : 0)}px;
  border-bottom-left-radius: ${({isFirst}) => (isFirst ? 4 : 0)}px;
  border-top-right-radius: ${({isLast}) => (isLast ? 4 : 0)}px;
  border-bottom-right-radius: ${({isLast}) => (isLast ? 4 : 0)}px;
  background-color: ${({color}) => color};
`;

const TimelineBlockText = Styled.Text`
  ${Typography.Mobile.Micro}
  color: ${({color}) => color};
`;

const TimelineBlockTimeText = Styled.Text`
  ${Typography.Mobile.Micro}
`;

const TimelineBlock = ({
  timesheetBlock,
  timezone,
  isFirst,
  isLast,
  blockMinutes,
  totalMinutes,
  startTime,
  isComplete,
  isCondensed,
}) => {
  const {kind} = timesheetBlock;

  return (
    <TimelineBlockWrapper style={{flex: totalMinutes ? blockMinutes / totalMinutes : 1}}>
      <Column style={{flex: 1}}>
        <TimelineBlockTypeContainer
          isFirst={isFirst}
          isLast={isLast}
          color={kind ? TimesheetBlockKind.getColor(kind) : colors.gray.disabled}
        >
          <TimelineBlockText numberOfLines={1} color={kind ? colors.white : colors.gray.tertiary}>
            {TimesheetBlockKind.getDisplay(kind) || 'In progress...'}
          </TimelineBlockText>
        </TimelineBlockTypeContainer>
        <Space height={4} />
        <Row>
          <Space width={8} />
          {(!isCondensed || isFirst) && (
            <TimelineBlockTimeText numberOfLines={1}>
              {startTime || TimesheetBlock.getDisplayRangeFrom(timesheetBlock, {timezone})}
            </TimelineBlockTimeText>
          )}
          {isLast && isComplete && (
            <React.Fragment>
              <Space style={{flex: 1}} />
              <TimelineBlockTimeText numberOfLines={1}>
                {TimesheetBlock.getDisplayRangeTo(timesheetBlock, {timezone})}
              </TimelineBlockTimeText>
            </React.Fragment>
          )}
        </Row>
      </Column>
    </TimelineBlockWrapper>
  );
};

const TotalTime = ({timesheetBillableEntry, kind, responsive}) => {
  const totalMinutes = timesheetBillableEntry.timesheetBlocks.reduce((total, timesheetBlock) => {
    const blockMinutes = TimesheetBlock.getMinutes(timesheetBlock);
    if (blockMinutes && timesheetBlock.kind === kind) {
      return total + blockMinutes;
    }
    return total;
  }, 0);

  const displayTime = Duration.toDisplayTime(totalMinutes, {
    hoursLabel: 'H',
    minutesLabel: 'M',
    alwaysShowHours: true,
    alwaysShowMinutes: true,
  });

  return (
    <TimesheetPayrollEntriesLegend.LegendKey
      label={`${TimesheetBlockKind.getDisplay(kind)} ${displayTime}`}
      color={TimesheetBlockKind.getColor(kind)}
      responsive={responsive}
    />
  );
};

const TimesheetBillableEntryTimeline = ({
  timesheetBillableEntry,
  isComplete,
  showTotals,
  isCondensed,
}) => {
  const responsive = useResponsive();
  const {timesheetBlocks} = timesheetBillableEntry;
  const {organization} = timesheetBillableEntry.job;
  const totalMinutes = timesheetBlocks.reduce((total, timesheetBlock) => {
    return total + TimesheetBlock.getMinutes(timesheetBlock);
  }, 0);
  const totalBlocks = isComplete ? timesheetBlocks.length : timesheetBlocks.length + 1;

  // When we generate documents on a headless browser, the timezone is in utc. In this
  // case we need to manually pass in a timezone so that the document shows the correct
  // times. If we pass in a timezone when local has a timezone, then the timezone gets
  // double computed.
  const isLocalUtc = new Date().getTimezoneOffset() === 0;

  return (
    <Column>
      <Row>
        {timesheetBlocks.map((timesheetBlock, index) => {
          const isFirst = index === 0;
          const isLast = index === totalBlocks - 1;
          const blockMinutes = TimesheetBlock.getMinutes(timesheetBlock, 60);
          return (
            <React.Fragment key={timesheetBlock.id}>
              {!isFirst && <Space width={1} />}
              <TimelineBlock
                timesheetBlock={timesheetBlock}
                timezone={isLocalUtc ? organization.timezone : null}
                isFirst={isFirst}
                isLast={isLast}
                blockMinutes={blockMinutes}
                totalMinutes={totalMinutes}
                isComplete={isComplete}
                isCondensed={isCondensed}
              />
              {index === timesheetBlocks.length - 1 && !isComplete && (
                <React.Fragment>
                  <Space width={1} />
                  <TimelineBlock
                    timesheetBlock={{kind: null}}
                    isLast
                    blockMinutes={60}
                    totalMinutes={totalMinutes}
                    startTime={TimesheetBlock.getDisplayRangeTo(timesheetBlock, {
                      timezone: isLocalUtc ? organization.timezone : null,
                    })}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          );
        })}
      </Row>
      {showTotals && (
        <React.Fragment>
          <Space height={8} />
          <Row>
            {Organization.getTimesheetBlockKinds(organization).map((kind, index) => {
              return (
                <React.Fragment key={kind}>
                  {index > 0 && <Space width={12} />}
                  <TotalTime
                    timesheetBillableEntry={timesheetBillableEntry}
                    kind={kind}
                    responsive={responsive}
                  />
                </React.Fragment>
              );
            })}
          </Row>
          <Space height={8} />
        </React.Fragment>
      )}
    </Column>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TimesheetBillableEntryTimeline.fragment = gql`
  ${TimesheetBlock.getMinutes.fragment}
  ${TimesheetBlock.getDisplayRangeFrom.fragment}
  ${TimesheetBlock.getDisplayRangeTo.fragment}
  ${Organization.getTimesheetBlockKinds.fragment}

  fragment TimesheetBillableEntryTimeline on TimesheetBillableEntry {
    id
    job {
      id
      organization {
        id
        timezone
        ...Organization_getTimesheetBlockKinds
      }
    }
    timesheetBlocks {
      id
      kind
      rangeTo
      ...TimesheetBlock_getMinutes
      ...TimesheetBlock_getDisplayRangeFrom
      ...TimesheetBlock_getDisplayRangeTo
    }
  }
`;

export default TimesheetBillableEntryTimeline;
