// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useNavigationDOM,
  useQuery,
  useState,
  useModal,
  useToast,
  useMountEffect,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Json, Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import DocumentTemplateVersionForm from '@shared/modules/Document/forms/DocumentTemplateVersionForm';
import usePublishDocumentTemplateVersionMutation from '@shared/modules/Document/hooks/usePublishDocumentTemplateVersionMutation';
import useUpdateDocumentTemplateVersionMutation from '@shared/modules/Document/hooks/useUpdateDocumentTemplateVersionMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import DocumentTemplateVersionEditorTemplatePreview from 'modules/Organization/Settings/Document/components/DocumentTemplateVersionEditorTemplatePreview';
import DocumentTemplateVersionEditorTemplateStructureEditor from 'modules/Organization/Settings/Document/components/DocumentTemplateVersionEditorTemplateStructureEditor';
import DocumentTemplateVersionItemInputEditor from 'modules/Organization/Settings/Document/components/DocumentTemplateVersionItemInputEditor';

const PageContainer = Styled.View`
  flex: 1;
`;

const PageContentContainer = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const DocumentTemplateEditorContainer = Styled.View`
  width: 20vw;
  min-width: 300px;
  border-right-width: 1px;
  border-color: ${colors.gray.border};
`;

const DocumentTemplatePreviewContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
  border-left-width: 1px;
  border-color: ${colors.gray.border};
`;

const DocumentTemplateSectionItemInputEditorContainer = Styled.View`
  width: 30vw;
  min-width: 300px;
  border-color: ${colors.gray.border};
`;

const HeaderText = Styled.Text`
  ${Typography.Label1};
`;

const LastSavedText = Styled.Text`
  ${Typography.Body3};
`;

const DocumentTemplateVersionEditorHeaderContainer = Styled.View`
  paddingVertical: 16px;
  paddingHorizontal: 24px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  flex-direction: row;
  align-items: center;
  height: 56px;
  width: 100%;
  z-index: 99999;
`;

const BackButton = Styled.ButtonV2`
`;

const ItemInputEditorBlankContainer = Styled.View`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ItemInputEditorBlankContainerText = Styled.Text`
  ${Typography.Heading6};
  color: ${colors.gray.tertiary};
`;

const DocumentTemplateVersionEditorHeader = ({
  documentTemplateVersion,
  draftDocumentContentJson,
  documentTemplateUuid,
  refetch,
  navigator,
  isDocumentChanged,
  setIsDocumentChanged,
}) => {
  const documentTemplateVersionForm = DocumentTemplateVersionForm.edit({
    ...documentTemplateVersion,
    documentContentJsonForm: draftDocumentContentJson,
  });

  const updateDocumentSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Document draft saved',
  });
  const {handleSubmit: handleUpdateDocumentTemplateVersion} =
    useUpdateDocumentTemplateVersionMutation({
      documentTemplateVersionForm,
      onSuccess: () => {
        setIsDocumentChanged(false);
        updateDocumentSuccessToast.handleToast();
        refetch();
      },
      onError: () => {},
    });

  const publishDocumentSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Document template published',
  });
  const {handleSubmit: handlePublishDocumentTemplateVersion} =
    usePublishDocumentTemplateVersionMutation({
      documentTemplateVersionForm,
      onSuccess: () => {
        publishDocumentSuccessToast.handleToast();
        navigator.push(`/settings/documents/document-templates/${documentTemplateUuid}`);
      },
      onError: (errors) => console.log({errors}),
    });

  const exitEditDocumentTemplateModal = useModal({name: 'Exit Document Template Modal'});
  const publishDocumentTemplateModal = useModal({name: 'Publish Document Template Modal'});
  return (
    <DocumentTemplateVersionEditorHeaderContainer>
      <BackButton
        onPress={() =>
          isDocumentChanged
            ? exitEditDocumentTemplateModal.handleOpen()
            : navigator.push(`/settings/documents/document-templates/${documentTemplateUuid}`)
        }
      >
        <Icon source={Icon.ChevronLeft} color={colors.black} size={24} />
      </BackButton>
      <ActionModal
        title={'Exit this page?'}
        message={'Any unsaved changes will be deleted.'}
        icon={Icon.ExclamationTriangle}
        color={colors.red.warning}
        key={exitEditDocumentTemplateModal.key}
        isOpen={exitEditDocumentTemplateModal.isOpen}
        handlePrimaryAction={() => {
          navigator.push(`/settings/documents/document-templates/${documentTemplateUuid}`);
        }}
        handleSecondaryAction={exitEditDocumentTemplateModal.handleClose}
        primaryActionText={'Exit Page'}
        secondaryActionText={'Cancel'}
      />
      <Space width={16} />
      <HeaderText>{documentTemplateVersion.name}</HeaderText>
      <Space style={{flex: 1}} />
      <LastSavedText>
        Last Saved: {Datetime.convertToDisplayDatetime(documentTemplateVersion.updatedAt)}
      </LastSavedText>
      <Space width={16} />
      <SecondaryButton
        isDisabled={!isDocumentChanged}
        text={'Save as Draft'}
        onPress={handleUpdateDocumentTemplateVersion}
      />
      <Space width={16} />
      <Button width={90} onPress={publishDocumentTemplateModal.handleOpen} text={'Publish'} />
      <ActionModal
        title={`Publish ${documentTemplateVersionForm.name}?`}
        message={
          'Once published, new jobs will use the updated document. Old jobs will not be affected.'
        }
        icon={Icon.File}
        key={publishDocumentTemplateModal.key}
        isOpen={publishDocumentTemplateModal.isOpen}
        handlePrimaryAction={() => {
          handlePublishDocumentTemplateVersion();
        }}
        handleSecondaryAction={publishDocumentTemplateModal.handleClose}
        primaryActionText={'Publish'}
        secondaryActionText={'Cancel'}
      />
    </DocumentTemplateVersionEditorHeaderContainer>
  );
};

const DocumentTemplateVersionEditorContent = ({
  documentTemplateVersion,
  navigator,
  documentTemplateUuid,
  refetch,
}) => {
  const [draftDocumentContentJson, setDraftDocumentContentJson] = useState(
    Json.toObject(documentTemplateVersion.documentContentJson),
  );
  const getInitialSelectedDocumentItem = (documentItems) => {
    if (documentItems.length > 0) {
      return {
        documentItem: documentItems[0],
        index: 0,
      };
    } else {
      return {};
    }
  };
  const [selectedDocumentItem, setSelectedDocumentItem] = useState(
    getInitialSelectedDocumentItem(draftDocumentContentJson.documentItems),
  );
  const [isDocumentChanged, setIsDocumentChanged] = useState(false);

  const updateDraftDocumentContentJson = (value) => {
    setDraftDocumentContentJson(value);
    setIsDocumentChanged(true);
  };

  useMountEffect(() => {
    // Convert legacy text document items to rich text document items.
    // The itemKind will become TEXT and the text will have neccesary HTML tags.
    if (draftDocumentContentJson) {
      const copiedDraftDocumentContentJson = _.cloneDeep(draftDocumentContentJson);
      const {documentItems, hasUpdatedDocumentItems} = DocumentItemKindV2.convertTextItems({
        documentItems: copiedDraftDocumentContentJson.documentItems,
      });
      if (hasUpdatedDocumentItems) {
        copiedDraftDocumentContentJson.documentItems = documentItems;
        updateDraftDocumentContentJson(copiedDraftDocumentContentJson);
      }
    }
  });

  return (
    <PageContainer>
      <DocumentTemplateVersionEditorHeader
        documentTemplateVersion={documentTemplateVersion}
        draftDocumentContentJson={draftDocumentContentJson}
        documentTemplateUuid={documentTemplateUuid}
        refetch={refetch}
        navigator={navigator}
        isDocumentChanged={isDocumentChanged}
        setIsDocumentChanged={setIsDocumentChanged}
      />
      <PageContentContainer>
        <DocumentTemplateEditorContainer>
          <DocumentTemplateVersionEditorTemplateStructureEditor
            organization={documentTemplateVersion.organization}
            draftDocumentContentJson={draftDocumentContentJson}
            setDraftDocumentContentJson={updateDraftDocumentContentJson}
            setSelectedDocumentItem={setSelectedDocumentItem}
            selectedDocumentItem={selectedDocumentItem}
            documentTemplateCategory={documentTemplateVersion.documentTemplate.category}
          />
        </DocumentTemplateEditorContainer>
        <DocumentTemplateSectionItemInputEditorContainer>
          {_.isEmpty(selectedDocumentItem) ? (
            <ItemInputEditorBlankContainer>
              <ItemInputEditorBlankContainerText>
                {'Select a document item to customize'}
              </ItemInputEditorBlankContainerText>
            </ItemInputEditorBlankContainer>
          ) : (
            <DocumentTemplateVersionItemInputEditor
              documentItem={selectedDocumentItem.documentItem}
              documentItemIndex={selectedDocumentItem.index}
              setDraftDocumentContentJson={updateDraftDocumentContentJson}
              documentTemplateVersion={documentTemplateVersion}
            />
          )}
        </DocumentTemplateSectionItemInputEditorContainer>
        <DocumentTemplatePreviewContainer>
          <ScrollView horizontal>
            <DocumentTemplateVersionEditorTemplatePreview
              selectedDocumentItem={selectedDocumentItem}
              documentContentJson={draftDocumentContentJson}
              setSelectedDocumentItem={setSelectedDocumentItem}
            />
          </ScrollView>
        </DocumentTemplatePreviewContainer>
      </PageContentContainer>
    </PageContainer>
  );
};

const DocumentTemplateVersionEditorPage = () => {
  const {params, navigator} = useNavigationDOM();

  const {loading, data, refetch} = useQuery(DocumentTemplateVersionEditorPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.documentTemplateVersionUuid,
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <DocumentTemplateVersionEditorContent
      refetch={refetch}
      navigator={navigator}
      documentTemplateUuid={params.documentTemplateUuid}
      documentTemplateVersion={data.documentTemplateVersion}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentTemplateVersionEditorPage.query = gql`
  ${DocumentTemplateVersionForm.edit.fragment}
  ${DocumentTemplateVersionItemInputEditor.fragment}
  ${DocumentTemplateVersionEditorTemplateStructureEditor.fragment}

  query DocumentTemplateVersionEditorPage(
    $uuid: String!,
  ) {
    ${gql.query}
    documentTemplateVersion(uuid: $uuid) {
      id
      uuid
      name
      version
      documentContentJson
      updatedAt
      documentTemplate {
        id
        category
      }
      organization {
        id
        ...DocumentTemplateVersionEditorTemplateStructureEditor
      }
      ...DocumentTemplateVersionForm_edit
      ...DocumentTemplateVersionItemInputEditor
    }
  }
`;

export default DocumentTemplateVersionEditorPage;
