// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {PayrollReport} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, pluralize} from '@supermove/utils';

// App
import Table from '@shared/design/components/Table';
import PayrollReportStatus from '@shared/modules/Accounting/enums/PayrollReportStatus';

const Column = Styled.View`
  flex: 1;
`;

const Button = Styled.ButtonV2`
`;

const ButtonText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.blue.interactive};
`;

const CellText = Styled.Text`
  ${Typography.Body4}
`;

const StatusIndicator = Styled.ButtonV2`
  flex-direction: row;
  padding-vertical: 5px;
  padding-horizontal: 8px;
  width: ${({mobile}) => (mobile ? '64px' : '104px')};
  background-color: ${({isPaidOut}) => (isPaidOut ? colors.green.accent : colors.gray.background)};
  border-radius: 4px;
`;

const StatusIndicatorText = Styled.Text`
  ${Typography.Label4};
  color: ${({color}) => color};
`;

const getPayrollReportColumns = ({navigator}) => [
  {
    flex: 2,
    headerContent: ({mobile}) => {
      return (
        <Column>
          <Table.HeaderText>{mobile ? 'Period' : 'Report Period'}</Table.HeaderText>
          <Table.HeaderSubtext>{mobile ? '# of Emp.' : 'No. of Employees'}</Table.HeaderSubtext>
        </Column>
      );
    },
    cellContent: ({item: payrollReport, mobile}) => {
      return (
        <Column>
          <CellText>{PayrollReport.getDateRange(payrollReport, {isShortened: true})}</CellText>
          <Space height={2} />
          <CellText style={{color: colors.gray.secondary}}>
            {mobile
              ? payrollReport.compensationReportsCount
              : pluralize(
                  PayrollReport.getDisplayKind(payrollReport),
                  payrollReport.compensationReportsCount,
                  true,
                )}
          </CellText>
        </Column>
      );
    },
  },
  {
    flex: 2,
    headerContent: ({mobile}) => {
      return (
        <Table.HeaderText numberOfLines={1}>{mobile ? 'Comp.' : 'Compensation'}</Table.HeaderText>
      );
    },
    cellContent: ({item: payrollReport}) => {
      const compensation = payrollReport.totalCompensation;
      return (
        <CellText>
          {Currency.display(compensation, {shouldHideCentsIfZero: compensation === 0})}
        </CellText>
      );
    },
  },
  {
    flex: 2,
    headerContent: ({mobile}) => {
      return (
        <Column>
          <Table.HeaderText>Paid</Table.HeaderText>
          <Table.HeaderSubtext>{`Remaining${mobile ? '' : ' Bal.'}`}</Table.HeaderSubtext>
        </Column>
      );
    },
    cellContent: ({item: payrollReport}) => {
      const paidAmount = payrollReport.totalPaidOut;
      const balance = PayrollReport.getRemainingBalance(payrollReport);
      return (
        <Column>
          <CellText>
            {Currency.display(paidAmount, {shouldHideCentsIfZero: paidAmount === 0})}
          </CellText>
          <CellText style={{color: balance > 0 ? colors.red.warning : colors.gray.secondary}}>
            {Currency.display(balance, {shouldHideCentsIfZero: balance === 0})}
          </CellText>
        </Column>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Status</Table.HeaderText>;
    },
    cellContent: ({item: payrollReport, mobile}) => {
      const isPaidOut = payrollReport.status === PayrollReportStatus.PAID_OUT;

      return (
        <React.Fragment>
          <StatusIndicator disabled isPaidOut={isPaidOut} mobile={mobile}>
            <StatusIndicatorText color={isPaidOut ? colors.green.status : colors.gray.tertiary}>
              {PayrollReportStatus.getDisplayStatus(payrollReport.status)}
            </StatusIndicatorText>
          </StatusIndicator>
        </React.Fragment>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Action</Table.HeaderText>;
    },
    cellContent: ({item: payrollReport}) => {
      return (
        <Button onPress={() => navigator.push(`/accounting/payroll-reports/${payrollReport.uuid}`)}>
          <ButtonText>View All</ButtonText>
        </Button>
      );
    },
  },
];

const PayrollReportsListV2 = ({payrollReports}) => {
  const {navigator} = useNavigationDOM();
  return (
    <Table.FixedHeaderScroll
      columnDefinitions={getPayrollReportColumns({navigator})}
      items={payrollReports}
      emptyStateText={'There are no payroll reports of this type'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PayrollReportsListV2.fragment = gql`
  ${PayrollReport.getDateRange.fragment}
  ${PayrollReport.getDisplayKind.fragment}
  ${PayrollReport.getRemainingBalance.fragment}

  fragment PayrollReportsListV2 on PayrollReport {
    id
    uuid
    kind
    compensationReportsCount
    totalCompensation
    totalPaidOut
    status
    ...PayrollReport_getDateRange
    ...PayrollReport_getDisplayKind
    ...PayrollReport_getRemainingBalance
  }
`;

export default PayrollReportsListV2;
