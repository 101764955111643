// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import UserForm from '@shared/modules/User/forms/UserForm';

const useUpdateUserMutation = ({userForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      userForm: UserForm.toForm(userForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateUserMutation.mutation,
    variables: {
      userForm: UserForm.toMutation(form.values.userForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateUserMutation.mutation = gql`
  mutation UpdateUserMutation($userForm: UserForm!) {
    response: updateUser(userForm: $userForm) {
      user {
        id
        names
        email
      }
      ${gql.errors}
    }
  }
`;

export default useUpdateUserMutation;
