// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SlotTypeForm from '@shared/modules/Dispatch/forms/SlotTypeForm';

const useUpdateSlotTypeMutation = ({slotTypeForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      slotTypeForm: SlotTypeForm.toForm(slotTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateSlotTypeMutation.mutation,
    variables: {
      slotTypeForm: SlotTypeForm.toMutation(form.values.slotTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateSlotTypeMutation.mutation = gql`
  mutation useUpdateSlotTypeMutation($slotTypeForm: SlotTypeForm!) {
    response: updateSlotType(slotTypeForm: $slotTypeForm) {
      ${gql.errors}
      slotType {
        id
      }
    }
  }
`;

export default useUpdateSlotTypeMutation;
