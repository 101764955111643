// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Organization, Payment} from '@supermove/models';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';

const BillingProjectPaymentMethodsDropdown = ({form, bill}) => {
  const paymentMethods = Organization.getOfficePaymentMethods(bill.organization);
  const options = Payment.getMethodDropdownOptions({methods: paymentMethods});

  return (
    <FieldInput
      {...form}
      component={DropdownInput}
      name={'beginPaymentForm.method'}
      label={'Payment Method'}
      isResponsive
      input={{
        options,
        required: !form.values.beginPaymentForm.method,
        placeholder: 'Choose payment method',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
        isPortaled: true,
      }}
    />
  );
};

BillingProjectPaymentMethodsDropdown.fragment = gql`
  ${Organization.getOfficePaymentMethods.fragment}

  fragment BillingProjectPaymentMethodsDropdown on Bill {
    id
    organization {
      id
      ...Organization_getOfficePaymentMethods
    }
  }
`;

export default BillingProjectPaymentMethodsDropdown;
