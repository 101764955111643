// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {BillRule} from '@supermove/models';
import {fontWeight, colors, typography} from '@supermove/styles';

const Container = Styled.View`
  align-self: stretch;
`;

const Header = Styled.View`
  align-self: stretch;
  flex-direction: row;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  background-color: ${colors.gray.background};
`;

const FlatListCell = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const FlatListSeparator = Styled.View`
  height: 10px;
`;

const Item = Styled.View`
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding-horizontal: 10px;
`;

const Cell = Styled.View`
  width: 100px;
`;

const Text = Styled.H6`
  ${({vars}) => (vars.isCompact ? typography.h7() : '')}
  color: ${colors.gray.secondary};
  ${fontWeight(500)}
`;

const Name = Styled.H6`
  ${({vars}) => (vars.isCompact ? typography.h7() : '')}
  ${fontWeight(500)}
`;

const Description = Styled.H7`
  ${({vars}) => (vars.isCompact ? typography.h8() : '')}
  color: ${colors.gray.secondary};
`;

const Quantity = Styled.H6`
  ${({vars}) => (vars.isCompact ? typography.h7() : '')}
  text-align: right;
`;

const EmptyContainer = Styled.View`
  padding-horizontal: 12px;
`;

const EmptyMessage = Styled.H7`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const ListHeader = ({isCompact}) => (
  <Header>
    <Cell
      style={{
        flex: 1,
      }}
    >
      <Text vars={{isCompact}}>Rule</Text>
    </Cell>
    <Cell />
  </Header>
);

const BillRuleItem = ({isCompact, billRule}) => {
  return (
    <Item>
      <Cell
        style={{
          flex: 1,
        }}
      >
        <Name vars={{isCompact}}>{billRule.name}</Name>
        <Description vars={{isCompact}}>{billRule.description}</Description>
      </Cell>
      <Cell
        style={{
          alignItems: 'flex-end',
        }}
      >
        <Quantity vars={{isCompact}}>{BillRule.getDisplayValues(billRule)}</Quantity>
      </Cell>
    </Item>
  );
};

const BillBillRulesList = ({isCompact, isCustomer, bill, style}) => {
  return (
    <Container style={style}>
      <FlatList
        data={isCustomer ? bill.billRulesForCustomer : bill.billRules}
        keyExtractor={(billRule, index) => String(index)}
        renderItem={({item: billRule}) => (
          <BillRuleItem isCompact={isCompact} billRule={billRule} />
        )}
        CellRendererComponent={FlatListCell}
        ItemSeparatorComponent={FlatListSeparator}
        ListHeaderComponent={() => (
          <React.Fragment>
            <ListHeader isCompact={isCompact} />
            <FlatListSeparator />
          </React.Fragment>
        )}
        ListEmptyComponent={() => (
          <EmptyContainer>
            <EmptyMessage>No billing rules</EmptyMessage>
          </EmptyContainer>
        )}
      />
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
BillBillRulesList.propTypes = {
  isCompact: PropTypes.bool,
  isCustomer: PropTypes.bool,
  bill: PropTypes.object.isRequired,
  style: PropTypes.object,
};

BillBillRulesList.defaultProps = {
  isCompact: false,
  isCustomer: false,
  style: {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillBillRulesList.fragment = gql`
  ${BillRule.getDisplayValues.fragment}

  fragment BillBillRulesList on Bill {
    id
    billRules {
      name
      description
      ...BillRule_getDisplayValues
    }
    billRulesForCustomer {
      name
      description
      ...BillRule_getDisplayValues
    }
  }
`;

export default BillBillRulesList;
