// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import SummaryItemItem from '@shared/modules/Inventory/Summary/components/SummaryItemItem';

const Item = Styled.View`
  flex: 1;
`;

const SummaryItemsList = ({listKey, kind, items, showNotes, showVolume}) => {
  const numberOfColumns = 2;
  const numberOfFillerItems = numberOfColumns - (items.length % numberOfColumns);
  const fillerItems = _.fill(Array(numberOfFillerItems), {});
  const allItems = _.concat(items, fillerItems);

  return (
    <FlatList
      // Flatlist has an issue with large lists, by specifying the exact number
      // of items to render, it makes sure that it reaches the end
      initialNumToRender={allItems.length}
      listKey={listKey}
      numColumns={numberOfColumns}
      data={allItems}
      keyExtractor={(item, index) => String(index)}
      renderItem={({item}) => {
        if (_.isEmpty(item)) {
          return <Item />;
        }
        return (
          <Item>
            <SummaryItemItem
              kind={kind}
              item={item}
              showNotes={showNotes}
              showVolume={showVolume}
            />
          </Item>
        );
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SummaryItemsList.fragment = gql`
  ${SummaryItemItem.fragment}

  fragment SummaryItemsList on Item {
    id
    ...SummaryItemItem
  }
`;

export default SummaryItemsList;
