// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useCallback, useEffect, useMountEffect, useQuery, useState} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';

export const AppContext = React.createContext(null);

const AppContextProvider = ({children}) => {
  const [viewer, setViewer] = useState();
  const [globalNavigationTab, setGlobalNavigationTab] = useState('');
  const [isGlobalNavSidebarExpanded, setIsGlobalNavSidebarExpanded] = useState(false);
  const {data} = useQuery(AppContextProvider.query, {fetchPolicy: 'cache-and-network'});

  useEffect(() => {
    if (data) {
      setViewer(data.viewer);
    }
  }, [data]);

  const handleCheckSidebarIsExpanded = async () => {
    const value = await Storage.getItem('IS_GLOBAL_NAV_SIDEBAR_EXPANDED');
    return value === 'true';
  };
  useMountEffect(() => {
    handleCheckSidebarIsExpanded().then((value) => {
      setIsGlobalNavSidebarExpanded(value);
    });
  });

  const toggleIsGlobalNavSidebarExpanded = useCallback(async () => {
    const nextIsGlobalNavSidebarExpanded = !isGlobalNavSidebarExpanded;
    setIsGlobalNavSidebarExpanded(nextIsGlobalNavSidebarExpanded);
    await Storage.setItem('IS_GLOBAL_NAV_SIDEBAR_EXPANDED', nextIsGlobalNavSidebarExpanded);
  }, [isGlobalNavSidebarExpanded]);

  const store = {
    viewer,
    isGlobalNavSidebarExpanded,
    toggleIsGlobalNavSidebarExpanded,
    globalNavigationTab,
    setGlobalNavigationTab,
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AppContextProvider.query = gql`
  query AppContextProvider {
    ${gql.query}
    viewer {
      id
      fullName
      role
      unreadNotificationsCount
      isPrimaryOrganizationAdmin
      isAuthorizedAccountingActions
      organization {
        id
        name
      }
      viewingOrganization {
        id
        name
        slug
        hasMultipleOrganizations
        features {
          isEnabledGlobalSearchV2: isEnabled(feature: "GLOBAL_SEARCH_V2")
          isEnabledMultibranchOrganizationSwitcher: isEnabled(feature: "MULTIBRANCH_ORGANIZATION_SWITCHER")
        }
      }
    }
  }
`;

export default AppContextProvider;
