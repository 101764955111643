// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import AssignDriverToSlotForm from '@shared/modules/Dispatch/forms/AssignDriverToSlotForm';

const useAssignDriverToSlotMutation = ({assignDriverToSlotForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      assignDriverToSlotForm: AssignDriverToSlotForm.toForm(assignDriverToSlotForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useAssignDriverToSlotMutation.mutation,
    variables: {
      assignDriverToSlotForm: AssignDriverToSlotForm.toMutation(form.values.assignDriverToSlotForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useAssignDriverToSlotMutation.mutation = gql`
  mutation useAssignDriverToSlotMutation($assignDriverToSlotForm: AssignDriverToSlotForm!) {
    response: assignDriverToSlot(assignDriverToSlotForm: $assignDriverToSlotForm) {
      ${gql.errors}
      slot {
        id
      }
    }
  }
`;

export default useAssignDriverToSlotMutation;
