const CAPACITY = 'CAPACITY';
const MOVES_V1 = 'MOVES_V1';

const calendarKinds = [CAPACITY, MOVES_V1];

const getLabel = (calendarKind) => {
  switch (calendarKind) {
    case CAPACITY:
      return 'Capacity';
    case MOVES_V1:
      return 'Moves';
    default:
      return calendarKind;
  }
};

const getDropdownOptions = () =>
  calendarKinds.map((kind) => ({
    label: getLabel(kind),
    value: kind,
  }));

const CalendarKind = {
  CAPACITY,
  MOVES_V1,

  getDropdownOptions,
};

export default CalendarKind;
