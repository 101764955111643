const _new = ({inventoryLibraryId, kind}) => ({
  fileId: null,
  inventoryLibraryId,
  kind,
});

const toForm = ({fileId, inventoryLibraryId, kind}) => ({
  fileId,
  inventoryLibraryId,
  kind,
});

const toMutation = ({fileId, inventoryLibraryId, kind}) => ({
  fileId,
  inventoryLibraryId,
  kind,
});

const ImportInventoryLibraryItemTagsForm = {
  new: _new,
  toForm,
  toMutation,
};

export default ImportInventoryLibraryItemTagsForm;
