// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (project) => ({
    projectId: project.id,
    name: project.name,
    description: project.description,
  }),
  gql`
    fragment ProjectInformationForm_edit on Project {
      id
      name
      description
    }
  `,
);

const toForm = ({projectId, name, description}) => ({
  projectId,
  name,
  description,
});

const toMutation = ({projectId, name, description}) => ({
  projectId,
  name,
  description,
});

const ProjectInformationForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectInformationForm;
