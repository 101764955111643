// Libraries
import _ from 'lodash';

const _new = ({ids, property, booleanValue}) => ({
  ids,
  property,
  booleanValue,
});

const toForm = ({ids, property, booleanValue}) => ({
  ids,
  property,
  booleanValue,
});

const toMutation = ({ids, property, booleanValue}) => ({
  ids: ids.map((id) => _.toNumber(id)),
  property,
  booleanValue,
});

const BulkUpdateBooleanPropertyForm = {
  new: _new,
  toForm,
  toMutation,
};

export default BulkUpdateBooleanPropertyForm;
