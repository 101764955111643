// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToggle} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Callout from '@shared/design/components/Callout';
import FieldInput from '@shared/design/components/Field/FieldInput';
import FieldValue from '@shared/design/components/Field/FieldValue';
import Panel from '@shared/design/components/Panel';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import RadioButton from '@shared/design/components/RadioButton';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import ReplyToKind from '@shared/modules/Organization/enums/ReplyToKind';
import OrganizationReplyToForm from '@shared/modules/Organization/forms/OrganizationReplyToForm';
import useUpdateOrganizationReplyToMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationReplyToMutation';

const Column = Styled.View``;

const Row = Styled.View`
  flex-direction: row;
`;

const EmailReplyToPanelBody = ({organization, isViewingDependentBranch}) => {
  const {replyToKind, replyToEmail} = organization.settings;
  return isViewingDependentBranch ? (
    <Callout text={'You are viewing a branch. To make changes, switch to the main branch.'} />
  ) : (
    <FieldValue
      label={'Reply-To'}
      value={
        replyToKind === ReplyToKind.COMPANY ? replyToEmail : ReplyToKind.getDisplay(replyToKind)
      }
    />
  );
};

const EmailReplyToPanelEditBody = ({form, field}) => {
  const replyToKind = _.get(form.values, `${field}.replyToKind`);
  return (
    <React.Fragment>
      <RadioButton
        isOn={replyToKind === ReplyToKind.SENDER}
        onChange={() => {
          form.setFieldValue(`${field}.replyToKind`, ReplyToKind.SENDER);
          form.setFieldValue(`${field}.replyToEmail`, '');
        }}
        label={'Reply-to sender'}
        hint={'When selected, all replies to emails sent via Supermove will go to the sender.'}
      />
      <Space height={16} />
      <RadioButton
        isOn={replyToKind === ReplyToKind.COMPANY}
        onChange={() => form.setFieldValue(`${field}.replyToKind`, ReplyToKind.COMPANY)}
        label={'Reply-to company'}
        hint={
          'When selected, all replies to emails sent via Supermove will go to a single company email address.'
        }
      />
      {replyToKind === ReplyToKind.COMPANY && (
        <React.Fragment>
          <Space height={8} />
          <Row>
            <Space width={24} />
            <FieldInput
              {...form}
              name={`${field}.replyToEmail`}
              input={{
                placeholder: 'Enter email',
                autoFocus: true,
              }}
              style={{width: 352}}
            />
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Header = ({isEditing, handleEdit, isViewingDependentBranch, hasEditPermissions}) => (
  <Panel.Header>
    <Column style={{flex: 1}}>
      <Row style={{alignItems: 'center'}}>
        <Panel.HeaderText>Reply-To</Panel.HeaderText>
        <Space style={{flex: 1, minWidth: 12}} />
        {hasEditPermissions && !isViewingDependentBranch && (
          <EditPanel.EditButton isEditing={isEditing} handleEdit={handleEdit} />
        )}
      </Row>
      <Space height={8} />
      <Panel.Text style={{color: colors.gray.secondary}}>
        {`This will specify the reply-to for emails sent via Supermove.`}
      </Panel.Text>
    </Column>
  </Panel.Header>
);

const EmailReplyToPanel = ({organization, index, refetch, hasEditPermissions}) => {
  const emailReplyToToggle = useToggle({name: 'Email Reply To Toggle'});
  const organizationReplyToForm = OrganizationReplyToForm.edit(organization);
  const {form, handleSubmit, submitting} = useUpdateOrganizationReplyToMutation({
    organizationReplyToForm,
    onSuccess: () => {
      refetch();
      emailReplyToToggle.handleToggleOff();
    },
    onError: (errors) => console.log({errors}),
  });
  const isViewingDependentBranch = !OrganizationKind.getIsIndependent(organization.kind);

  return (
    <EditPanel
      index={index}
      BodyComponent={EmailReplyToPanelBody}
      bodyComponentProps={{organization, isViewingDependentBranch}}
      EditBodyComponent={EmailReplyToPanelEditBody}
      editBodyComponentProps={{form, field: 'organizationReplyToForm'}}
      HeaderComponent={Header}
      headerComponentProps={{
        isEditing: emailReplyToToggle.isOn,
        handleEdit: emailReplyToToggle.handleToggleOn,
        isViewingDependentBranch,
        hasEditPermissions,
      }}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={emailReplyToToggle.isOn}
      handleEdit={emailReplyToToggle.handleToggleOn}
      handleClose={emailReplyToToggle.handleToggleOff}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailReplyToPanel.fragment = gql`
  fragment EmailReplyToPanel on Organization {
    id
    kind
    settings {
      id
      replyToKind
      replyToEmail
    }
  }
`;

export default EmailReplyToPanel;
