// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Modal, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useQuery, useResponsive, useScrollView} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ClientForm from '@shared/modules/Client/forms/ClientForm';
import useCreateClientMutation from '@shared/modules/Client/hooks/useCreateClientMutation';
import Line from 'modules/App/components/Line';
import MessageModal from 'modules/App/components/MessageModal';
import EditClientModalFields from 'modules/Client/Show/components/EditClientModalFields';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const PageContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${({mobile}) => (mobile ? 0 : 64)}px;
`;

const ModalContainer = Styled.View`
  flex: 1;
  width: ${({mobile}) => (mobile ? '100%' : '800px')};
  border-radius: ${({mobile}) => (mobile ? '0px' : '16px')};
  background-color: ${colors.white};
  overflow: hidden;
`;

const SectionContainer = Styled.View`
  padding-vertical: 16px;
  padding-horizontal: ${({mobile}) => (mobile ? 16 : 24)}px;
  background-color: ${({color}) => color};
`;

const HeaderText = Styled.Text`
  ${Typography.Label1}
`;

const FooterButton = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 120px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;

const FooterButtonText = Styled.Text`
  ${Typography.Label1}
  color: ${(props) => props.color};
`;

const ActivityIndicator = Styled.Loading`
  flex: 1;
`;

const LoadingIndicator = () => {
  return (
    <ModalContainer>
      <ActivityIndicator size={'small'} color={colors.gray.secondary} />
    </ModalContainer>
  );
};

const NewClientModalHeader = () => {
  return (
    <SectionContainer color={colors.white}>
      <HeaderText>Create Client Profile</HeaderText>
    </SectionContainer>
  );
};

const NewClientModalFooter = ({handleClose, handleSubmit, submitting}) => {
  return (
    <SectionContainer color={colors.white}>
      <Row style={{flex: 1, justifyContent: 'flex-end'}}>
        <FooterButton color={'transparent'} onPress={handleClose} disabled={submitting}>
          <FooterButtonText color={colors.gray.secondary}>Cancel</FooterButtonText>
        </FooterButton>
        <Space width={16} />
        <FooterButton
          color={submitting ? colors.gray.border : colors.blue.interactive}
          onPress={handleSubmit}
          disabled={submitting}
        >
          {submitting ? (
            <ActivityIndicator color={colors.white} size={'small'} />
          ) : (
            <FooterButtonText color={colors.white}>Save</FooterButtonText>
          )}
        </FooterButton>
      </Row>
    </SectionContainer>
  );
};

const NewClientModalContent = ({organization, handleClose, refetch}) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();
  const warningModal = useModal();
  const clientForm = ClientForm.new({
    organizationId: organization.id,
    isOkayDuplicatePhoneNumber: false,
  });
  const scrollView = useScrollView();
  const {form, handleSubmit, submitting} = useCreateClientMutation({
    clientForm,
    onSuccess: ({client}) => {
      refetch();
      navigator.push(`/clients/${client.uuid}`);
    },
    onError: (errors) => {
      const firstError = _.first(errors);
      if (firstError.field === 'client_form.duplicate_phone_number') {
        form.setFieldValue('clientForm.warningMessage', firstError.message);
        warningModal.handleOpen();
      } else {
        scrollView.handleScrollToTop({animated: true});
      }
    },
  });
  return (
    <React.Fragment>
      <NewClientModalHeader />
      <Line />
      <ScrollView
        ref={scrollView.ref}
        style={{
          flex: 1,
          backgroundColor: colors.gray.background,
          paddingVertical: 16,
          paddingHorizontal: responsive.mobile ? 8 : 24,
        }}
      >
        <EditClientModalFields form={form} field={'clientForm'} organization={organization} />
      </ScrollView>
      <Line />
      <NewClientModalFooter
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
      <MessageModal
        isOpen={warningModal.isOpen}
        handleClose={warningModal.handleClose}
        message={_.get(form.values, 'clientForm.warningMessage')}
        confirmText={'Create'}
        isSubmitting={submitting}
        handleConfirm={() => {
          form.setFieldValue('clientForm.isOkayDuplicatePhoneNumber', true);
          setTimeout(() => handleSubmit(), 0);
          warningModal.handleClose();
        }}
      />
    </React.Fragment>
  );
};

const NewClientModal = ({isOpen, handleClose, refetch}) => {
  const responsive = useResponsive();
  const {loading, data} = useQuery(NewClientModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
  });

  return (
    <Modal.Content onClose={() => {}} isOpen={isOpen}>
      <PageContainer {...responsive}>
        <ModalContainer {...responsive}>
          <Loading loading={loading} as={LoadingIndicator}>
            {() => {
              return data ? (
                <NewClientModalContent
                  organization={data.viewer.viewingOrganization}
                  handleClose={handleClose}
                  refetch={refetch}
                />
              ) : null;
            }}
          </Loading>
        </ModalContainer>
      </PageContainer>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NewClientModal.query = gql`
  ${EditClientModalFields.fragment}

  query NewClientModal {
    viewer {
      id
      viewingOrganization {
        id
        ...EditClientModalFields
      }
    }
  }
`;

export default NewClientModal;
