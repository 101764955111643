// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {BillModifier} from '@supermove/models';
import {Currency, Percent, withFragment} from '@supermove/utils';

const getDisplayValue = (billModifierForm) => {
  switch (billModifierForm.kind) {
    case BillModifier.KINDS.AMOUNT:
      return billModifierForm.amount;
    case BillModifier.KINDS.PERCENTAGE:
      return billModifierForm.percentage;
    default:
      return null;
  }
};

const getIsAmount = (billModifierForm) => {
  return billModifierForm.kind === BillModifier.KINDS.AMOUNT;
};

const getIsPercentage = (billModifierForm) => {
  return billModifierForm.kind === BillModifier.KINDS.PERCENTAGE;
};

const getIsBillModifier = (billItemForm) => {
  return getIsAmount(billItemForm) || getIsPercentage(billItemForm);
};

const validate = ({billModifierForm, formField}) => {
  const isAmount = getIsAmount(billModifierForm);
  const isPercentage = getIsPercentage(billModifierForm);
  const errors = {};
  if (!billModifierForm.kind) {
    _.set(errors, `${formField}.kind`, 'Please select a category');
  }
  if (!billModifierForm.name) {
    _.set(errors, `${formField}.name`, 'Please enter a name');
  }
  if (isAmount && !billModifierForm.amount) {
    _.set(errors, `${formField}.amount`, 'Please enter an amount');
  }
  if (isPercentage && !billModifierForm.percentage) {
    _.set(errors, `${formField}.percentage`, 'Please enter an amount');
  }
  return errors;
};

const _new = ({kind}) => ({
  billItemId: null,
  billItemTypeId: null,
  nameFormulaId: null,
  amountFormulaId: null,
  kind,
  name: '',
  description: '',
  percentage: '',
  amount: '',

  // Private
  isEditable: true,
});

const edit = withFragment(
  (billModifier) => ({
    billItemId: billModifier.billItemId,
    billItemTypeId: billModifier.billItemTypeId,
    nameFormulaId: billModifier.nameFormulaId,
    amountFormulaId: billModifier.amountFormulaId,
    kind: billModifier.kind,
    name: billModifier.name,
    description: billModifier.description,
    percentage: billModifier.percentage,
    amount: billModifier.amount,

    // Private
    isEditable: false,
  }),
  gql`
    fragment BillModifierForm_edit on BillModifier {
      billItemId
      billItemTypeId
      nameFormulaId
      amountFormulaId
      kind
      name
      description
      percentage
      amount
    }
  `,
);

const editFromBillModifierType = withFragment(
  (billModifierType) => ({
    billItemId: null,
    billItemTypeId: billModifierType.billItemTypeId,
    nameFormulaId: billModifierType.nameFormulaId,
    amountFormulaId: billModifierType.amountFormulaId,
    kind: billModifierType.kind,
    name: billModifierType.name,
    description: billModifierType.description,
    percentage: billModifierType.percentage,
    amount: billModifierType.amount,

    // Private
    isEditable: false,
  }),
  gql`
    fragment BillModifierForm_editFromBillModifierType on BillModifierType {
      id
      billItemTypeId
      nameFormulaId
      amountFormulaId
      kind
      name
      description
      percentage
      amount
    }
  `,
);

const toForm = ({
  billItemId,
  billItemTypeId,
  nameFormulaId,
  amountFormulaId,
  kind,
  name,
  description,
  percentage,
  amount,
  isEditable,
}) => ({
  billItemId,
  billItemTypeId,
  nameFormulaId,
  amountFormulaId,
  kind,
  name,
  description,
  percentage: percentage ? Percent.toForm(percentage) : '',
  amount: amount ? Currency.toForm(amount) : '',

  // Private
  isEditable,
});

const toMutation = ({
  billItemId,
  billItemTypeId,
  nameFormulaId,
  amountFormulaId,
  kind,
  name,
  description,
  percentage,
  amount,
}) => ({
  billItemId,
  billItemTypeId,
  nameFormulaId,
  amountFormulaId,
  kind,
  name,
  description,
  percentage: percentage ? Percent.toMutation(percentage) : 0,
  amount: amount ? Currency.toMutation(amount) : 0,
});

const BillModifierForm = {
  getDisplayValue,
  getIsAmount,
  getIsPercentage,
  getIsBillModifier,
  validate,

  new: _new,
  edit,
  editFromBillModifierType,

  toForm,
  toMutation,
};

export default BillModifierForm;
