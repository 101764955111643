// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import OrganizationTipOptionsForm from '@shared/modules/Organization/forms/OrganizationTipOptionsForm';

const useUpdateOrganizationTipOptionsMutation = ({
  organizationTipOptionsForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      organizationTipOptionsForm: OrganizationTipOptionsForm.toForm(organizationTipOptionsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationTipOptionsMutation.mutation,
    variables: {
      organizationTipOptionsForm: OrganizationTipOptionsForm.toMutation(
        form.values.organizationTipOptionsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationTipOptionsMutation.mutation = gql`
  mutation useUpdateOrganizationTipOptionsMutation($organizationTipOptionsForm: OrganizationTipOptionsForm!) {
    response: updateOrganizationTipOptions(organizationTipOptionsForm: $organizationTipOptionsForm) {
      ${gql.errors}
      organization {
        id
        settings {
          id
          tipOptions
        }
      }
    }
  }
`;

export default useUpdateOrganizationTipOptionsMutation;
