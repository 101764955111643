// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import PaymentFeeForm from '@shared/modules/Organization/forms/PaymentFeeForm';

const edit = withFragment(
  (organization) => ({
    organizationId: organization.id,
    paymentFeeForms: JSON.parse(organization.settings.jobRatesData).map((paymentFee) =>
      PaymentFeeForm.editFromJobRateData(paymentFee),
    ),
  }),
  gql`
    fragment OrganizationPaymentFeesForm_edit on Organization {
      id
      settings {
        id
        jobRatesData
      }
    }
  `,
);

const editWithNewPaymentFee = withFragment(
  (organization) => {
    const organizationPaymentFeesForm = OrganizationPaymentFeesForm.edit(organization);
    return {
      ...organizationPaymentFeesForm,
      paymentFeeForms: [...organizationPaymentFeesForm.paymentFeeForms, PaymentFeeForm.new()],
    };
  },
  gql`
    ${edit.fragment}
    fragment OrganizationPaymentFeesForm_editWithNewPaymentFee on Organization {
      id
      ...OrganizationPaymentFeesForm_edit
    }
  `,
);

const toForm = ({organizationId, paymentFeeForms}) => ({
  organizationId,
  paymentFeeForms: paymentFeeForms.map((paymentFeeForm) => PaymentFeeForm.toForm(paymentFeeForm)),
});

const toMutation = ({organizationId, paymentFeeForms}) => ({
  organizationId,
  paymentFeeForms: paymentFeeForms.map((paymentFeeForm) =>
    PaymentFeeForm.toMutation(paymentFeeForm),
  ),
});

const OrganizationPaymentFeesForm = {
  edit,
  editWithNewPaymentFee,
  toForm,
  toMutation,
};

export default OrganizationPaymentFeesForm;
