// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useDeleteClientMutation = ({clientId, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      clientId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteClientMutation.mutation,
    variables: {
      clientId: form.values.clientId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteClientMutation.mutation = gql`
  mutation useDeleteClientMutation($clientId: Int!) {
    response: deleteClient(clientId: $clientId) {
      ${gql.errors}
      client {
        id
      }
    }
  }
`;

export default useDeleteClientMutation;
