// Supermove
import {BulkUpdateIntPropertyForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useBulkUpdateClaimStatusMutation = ({bulkUpdateIntPropertyForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      bulkUpdateIntPropertyForm: BulkUpdateIntPropertyForm.toForm(bulkUpdateIntPropertyForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useBulkUpdateClaimStatusMutation.mutation,
    variables: {
      bulkUpdateIntPropertyForm: BulkUpdateIntPropertyForm.toMutation(
        form.values.bulkUpdateIntPropertyForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useBulkUpdateClaimStatusMutation.mutation = gql`
  mutation useBulkUpdateClaimStatusMutation($bulkUpdateIntPropertyForm: BulkUpdateIntPropertyForm!) {
    response: bulkUpdateClaimStatus(bulkUpdateIntPropertyForm: $bulkUpdateIntPropertyForm) {
      ${gql.errors}
      claims {
        id
      }
    }
  }
`;

export default useBulkUpdateClaimStatusMutation;
