// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography, colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  background-color: ${colors.white};
  margin-horizontal: 8px;
`;

const Info = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const Name = Styled.H7`
  flex: 1;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const NotesText = Styled.Text`
  ${Typography.Micro}
`;

const Volume = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const CountContainer = Styled.View`
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.isHighlighted
      ? colors.alpha(colors.green.status, 0.1)
      : colors.alpha(colors.gray.tertiary, 0.1)};
`;

const Count = Styled.H7`
  ${fontWeight(500)}
  color: ${({vars = {}}) => (vars.isHighlighted ? colors.green.status : colors.gray.tertiary)};
`;

const Counter = ({isColored, count}) => {
  const isZero = count === 0;
  const isHighlighted = isColored && !isZero;

  return (
    <CountContainer isHighlighted={isHighlighted}>
      <Count vars={{isHighlighted}}>{count}</Count>
    </CountContainer>
  );
};

const ItemCounter = ({kind, item}) => {
  const {takeCount} = item;
  const {leaveCount} = item;
  const isTake = kind === 'TAKE';
  const count = isTake ? takeCount : leaveCount;

  return <Counter isColored={isTake} count={count} />;
};

const SummaryItemItem = ({kind, item, showNotes, showVolume = true}) => {
  return (
    <React.Fragment>
      <Container>
        <ItemCounter kind={kind} item={item} />
        <Space width={10} />
        <Info>
          <Name numberOfLines={1}>{item.name}</Name>
          {showVolume && (
            <React.Fragment>
              <Space width={10} />
              <Volume>{item.volume}</Volume>
            </React.Fragment>
          )}
        </Info>
      </Container>
      {showNotes && !!item.notes && (
        <React.Fragment>
          <Space height={8} />
          <Container>
            <NotesText numberOfLines={1}>{item.notes}</NotesText>
          </Container>
        </React.Fragment>
      )}
      <Space height={5} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SummaryItemItem.fragment = gql`
  fragment SummaryItemItem on Item {
    id
    name
    takeCount
    leaveCount
    volume
    notes
  }
`;

export default SummaryItemItem;
