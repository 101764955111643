// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = withFragment(
  ({documentId}) => {
    return {
      documentId,
    };
  },
  gql`
    fragment DownloadDocumentForm_new on Document {
      id
    }
  `,
);

const toForm = ({documentId}) => {
  return {
    documentId,
  };
};

const toMutation = ({documentId}) => {
  return {
    documentId,
  };
};

const DownloadDocumentForm = {
  new: _new,
  toForm,
  toMutation,
};

export default DownloadDocumentForm;
