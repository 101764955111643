// Libraries
import _ from 'lodash';
import {MutationFunctionOptions} from 'react-apollo';

// Supermove
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {PayengineCreditCard} from '@supermove/models';

// App
import {PayEngine} from '@shared/modules/Payment/components/PayEngineCreditCardInput';
import {New as BeginPaymentV3NewForm} from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import {CreateCardResponse} from '@shared/modules/Payment/types';

import useChargePayEngineCreditCardMutationV2, {
  ChargePayEngineCreditCardForm,
  GraphQLResponse,
} from './useChargePayEngineCreditCardMutationV2';
import usePayengine from './usePayengine';

type Args = {
  beginPaymentV3Form: BeginPaymentV3NewForm;
  jobId?: number;
  saveToClientId?: number;
  onSuccess: () => void;
  handleTokenizeErrorMessage: (errorMessage: any) => void;
  handleErrorMessage: (errorMessage: any) => void;
  creditCard?: {
    env: string;
    token: string;
    cardId: string;
    name: string;
    brand: string;
    expMonth: number;
    expYear: number;
    addressZip: string;
    last4: string;
  };
};

type TokenizeCreditCardErrorsArgs = {
  tokenizeCreditCardErrors: Record<string, any>;
  handleCardErrorMessage: (errorMessage: string) => void;
};

type ChargeCreditCardErrorsArgs = {
  chargeCardErrors: unknown;
  handleCardErrorMessage: (errorMessage: string) => void;
};

/**
 * Charges a credit card via PayEngine and optionally saves the card for future payments.
 *
 * The returned functions should be used in this order:
 *   1. setCreditCardClient
 *        Must be passed as a prop to the PayEngineCreditCardInput component
 *   2. handleTokenizeCreditCard
 *        Tokenizes the credit card info on PayEngineCreditCardInput. This makes an API call to
 *        PayEngine's servers and _does not_ hit our API
 *   3. handleChargeCreditCard
 *        Initiates a charge on the given credit card. The card may also be saved for future usage.
 *
 * @param beginPaymentV3Form payment details provided on the BeginPayment mutation
 * @param jobId optional Job ID to associate with this payment
 * @param saveToClientId if set, the credit card used for this payment will be saved to this Client
 * @param onSuccess callback to invoke when the payment is successful
 * @param handleTokenizeErrorMessage callback to handle invalid card error messages
 * @param handleErrorMessage callback to handle failed charges
 *
 */
const useChargePayEngineCreditCardV2 = ({
  beginPaymentV3Form,
  jobId,
  saveToClientId,
  onSuccess,
  handleTokenizeErrorMessage,
  handleErrorMessage,
  creditCard,
}: Args) => {
  const [creditCardClient, setCreditCardClient] = useState<PayEngine.CreditCardClient>(
    creditCard
      ? PayengineCreditCard.getCreditCardClient(creditCard)
      : {
          createCard: () => {
            throw Error('PayEngine credit card client is not initialized');
          },
        },
  );
  const [tokenizeIsSubmitting, setTokenizeIsSubmitting] = useState(false);
  const [createCardResponse, setCreateCardResponse] = useState<CreateCardResponse | undefined>(
    undefined,
  );

  const {form, handleSubmit, submitting} = useChargePayEngineCreditCardMutationV2({
    beginPaymentV3Form,
    jobId,
    saveToClientId,
    createCardResponse,
  });

  const {formatCreateCardResponse, handleTokenizeCreditCardErrors, handleChargeCreditCardErrors} =
    usePayengine();

  const handleTokenizeCreditCard = async (): Promise<PayEngine.CreditCard> => {
    try {
      setTokenizeIsSubmitting(true);
      setCreateCardResponse(undefined);
      const card = await creditCardClient.createCard();
      setCreateCardResponse(formatCreateCardResponse(card));
      return card;
    } finally {
      setTokenizeIsSubmitting(false);
    }
  };

  const handleChargeCreditCard = async (
    options?: MutationFunctionOptions<GraphQLResponse, ChargePayEngineCreditCardForm>,
  ) => {
    const response = await handleSubmit(options);
    if (response.errors) {
      throw response.errors;
    }
    if (response.data?.response?.errors) {
      throw response.data.response.errors;
    }
    if (!response.data?.response) {
      throw new Error('Received empty GraphQL response in ChargePayEngineCreditCard');
    }
    return response.data.response;
  };

  const handleTokenizeCardAndSubmitPayment = async () => {
    handleTokenizeErrorMessage('');
    try {
      await handleTokenizeCreditCard();
    } catch (tokenizeCreditCardErrors) {
      return handleTokenizeCreditCardErrors({
        tokenizeCreditCardErrors: tokenizeCreditCardErrors as PayEngine.TokenizeError,
        handleCardErrorMessage: handleTokenizeErrorMessage,
      });
    }
    try {
      await handleChargeCreditCard();
    } catch (chargeCardErrors) {
      return handleChargeCreditCardErrors({
        chargeCardErrors: chargeCardErrors as unknown,
        handleCardErrorMessage: handleErrorMessage,
      });
    }
    onSuccess();
  };

  return {
    form,
    submitting: tokenizeIsSubmitting || submitting,
    setCreditCardClient,
    handleSubmit: handleTokenizeCardAndSubmitPayment,
  };
};

useChargePayEngineCreditCardV2.fragment = gql`
  ${PayengineCreditCard.getCreditCardClient.fragment}

  fragment useChargePayEngineCreditCardV2 on PayEngineCreditCard {
    id
    ...PayengineCreditCard_getCreditCardClient
  }
`;

export default useChargePayEngineCreditCardV2;
