// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import PreviewBillItemForm from '@shared/modules/Billing/forms/PreviewBillItemForm';

const usePreviewBillItemMutation = ({previewBillItemForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      previewBillItemForm,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: usePreviewBillItemMutation.mutation,
    variables: {
      previewBillItemForm: PreviewBillItemForm.toMutation(form.values.previewBillItemForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
usePreviewBillItemMutation.mutation = gql`
  mutation usePreviewBillItemMutation($previewBillItemForm: PreviewBillItemForm!) {
    response: previewBillItem(previewBillItemForm: $previewBillItemForm) {
      ${gql.errors}
      billItem {
        name
        amount
        minQuantity
        maxQuantity
      }
    }
  }
`;

export default usePreviewBillItemMutation;
