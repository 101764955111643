// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Shared
import BillForm from '@shared/modules/Billing/forms/BillForm';

const edit = withFragment(
  (bills) => ({
    billForms: bills.map((bill) => {
      return BillForm.edit(bill);
    }),
  }),
  gql`
    ${BillForm.edit.fragment}

    fragment ReorderBillsForm_edit on Bill {
      id
      ...BillForm_edit
    }
  `,
);

const toForm = ({billForms}) => ({
  billForms: billForms.map((billForm) => {
    return BillForm.toForm(billForm);
  }),
});

const toMutation = ({billForms}) => ({
  billForms: billForms.map((billForm) => {
    return BillForm.toMutation(billForm);
  }),
});

const ReorderBillsForm = {
  edit,
  toForm,
  toMutation,
};

export default ReorderBillsForm;
