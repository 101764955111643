// Libraries
import _ from 'lodash';

const MAIN = 'MAIN';
const BRANCH = 'BRANCH';
const CONTRACTOR = 'CONTRACTOR';
const FRANCHISE = 'FRANCHISE';

const getIsIndependent = (kind) => _.includes([MAIN, FRANCHISE], kind);

const OrganizationKind = {
  MAIN,
  BRANCH,
  CONTRACTOR,
  FRANCHISE,

  getIsIndependent,
};

export default OrganizationKind;
