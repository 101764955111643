const _new = ({organizationId}) => ({
  name: '',
  isDriver: false,
  organizationId,
});

const toForm = ({name, isDriver, organizationId}) => ({
  name,
  isDriver,
  organizationId,
});

const toMutation = ({name, isDriver, organizationId}) => ({
  name,
  isDriver,
  organizationId,
});

const CreateMoverPositionForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CreateMoverPositionForm;
