const ACH = 'ACH';
const CASH = 'CASH';
const CHECK = 'CHECK';
const EXTERNAL = 'EXTERNAL';
const PAYPAL = 'PAYPAL';
const PAYENGINE_CREDIT_CARD = 'PAYENGINE_CREDIT_CARD';
const STRIPE_CREDIT_CARD = 'STRIPE_CREDIT_CARD';
const STRIPE_TERMINAL = 'STRIPE_TERMINAL';
const VENMO = 'VENMO';
const AUTHORIZE_DOT_NET = 'AUTHORIZE_DOT_NET';
const INVOICE = 'INVOICE';
const STRIPE_SAVE_CARD = 'STRIPE_SAVE_CARD';
const PAYENGINE_SAVE_CARD = 'PAYENGINE_SAVE_CARD';

export default {
  ACH,
  CASH,
  CHECK,
  EXTERNAL,
  PAYPAL,
  PAYENGINE_CREDIT_CARD,
  STRIPE_CREDIT_CARD,
  STRIPE_TERMINAL,
  VENMO,
  AUTHORIZE_DOT_NET,
  INVOICE,
  STRIPE_SAVE_CARD,
  PAYENGINE_SAVE_CARD,
  VALUES: [
    CASH,
    CHECK,
    EXTERNAL,
    PAYPAL,
    PAYENGINE_CREDIT_CARD,
    STRIPE_CREDIT_CARD,
    // TODO(kevin): Enable this once we support stripe terminal on the crew app
    // STRIPE_TERMINAL
    VENMO,
    AUTHORIZE_DOT_NET,
    INVOICE,
    STRIPE_SAVE_CARD,
    PAYENGINE_SAVE_CARD,
  ],
};
