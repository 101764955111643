// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectLocationsForm from '@shared/modules/Project/forms/ProjectLocationsForm';

const useUpdateProjectLocationsMutation = ({projectLocationsForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      projectLocationsForm: ProjectLocationsForm.toForm(projectLocationsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectLocationsMutation.mutation,
    variables: {
      projectLocationsForm: ProjectLocationsForm.toMutation(form.values.projectLocationsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateProjectLocationsMutation.mutation = gql`
  ${ProjectLocationsForm.edit.fragment}

  mutation useUpdateProjectLocationsMutation($projectLocationsForm: ProjectLocationsForm!) {
    response: updateProjectLocations(projectLocationsForm: $projectLocationsForm) {
      ${gql.errors}
      project {
        id
        ...ProjectLocationsForm_edit
      }
    }
  }
`;

export default useUpdateProjectLocationsMutation;
