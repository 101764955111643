// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (roomType) => ({
    roomTypeId: roomType.id,
    organizationId: roomType.organizationId,
    primaryCategoryId: roomType.primaryCategoryId ? String(roomType.primaryCategoryId) : undefined,
    name: roomType.name,
    inventoryLibraryId: roomType.inventoryLibraryId,
    isDeleted: roomType.isDeleted,

    // private
    isDirty: false,
  }),
  gql`
    fragment RoomTypeForm_edit on RoomType {
      id
      organizationId
      primaryCategoryId
      name
      inventoryLibraryId
      isDeleted
    }
  `,
);

const _new = ({organizationId, inventoryLibraryId = null}) => ({
  roomTypeId: undefined,
  organizationId,
  primaryCategoryId: undefined,
  name: '',
  inventoryLibraryId,
  isDeleted: false,

  // private
  isDirty: true,
});

const toForm = ({
  roomTypeId,
  organizationId,
  primaryCategoryId,
  name,
  inventoryLibraryId,
  isDeleted,

  // private
  isDirty,
}) => ({
  roomTypeId,
  organizationId,
  primaryCategoryId,
  name,
  inventoryLibraryId,
  isDeleted,

  // private
  isDirty,
});

const toMutation = ({
  roomTypeId,
  organizationId,
  primaryCategoryId,
  name,
  inventoryLibraryId,
  isDeleted,
}) => ({
  roomTypeId,
  organizationId,
  primaryCategoryId,
  name,
  inventoryLibraryId,
  isDeleted,
});

const RoomTypeForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default RoomTypeForm;
