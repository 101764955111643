const _new = ({organizationId}) => {
  return {
    organizationId,
    data: null,
  };
};

const toForm = ({organizationId, data}) => ({
  organizationId,
  data,
});

const toMutation = ({organizationId, data}) => ({
  organizationId,
  data,
});

const BulkContainersForm = {
  new: _new,
  toForm,
  toMutation,
};

export default BulkContainersForm;
