// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SendTextMessageToUserForm from '@shared/modules/Sms/forms/SendTextMessageToUserForm';

const useSendTextMessageToUserMutation = ({sendTextMessageToUserForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      sendTextMessageToUserForm: SendTextMessageToUserForm.toForm(sendTextMessageToUserForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSendTextMessageToUserMutation.mutation,
    variables: {
      sendTextMessageToUserForm: SendTextMessageToUserForm.toMutation(
        form.values.sendTextMessageToUserForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useSendTextMessageToUserMutation.mutation = gql`
  mutation useSendTextMessageToUserMutation($sendTextMessageToUserForm: SendTextMessageToUserForm!) {
    response: sendTextMessageToUser(sendTextMessageToUserForm: $sendTextMessageToUserForm) {
      ${gql.errors}
      textMessage {
        id
      }
    }
  }
`;

export default useSendTextMessageToUserMutation;
