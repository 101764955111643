// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Json, Phone, withFragment} from '@supermove/utils';

import UserRole from '@shared/modules/User/enums/UserRole';

const canViewCustomerInfo = ({role, features}) => {
  const jsonFeatures = Json.parse(features);
  return (
    !!_.get(jsonFeatures, 'VIEW_CUSTOMER_INFO') || UserRole.MANAGER_ROLES_PLUS_SUPER.includes(role)
  );
};

canViewCustomerInfo.fragment = gql`
  fragment User_canViewCustomerInfo on User {
    role
    features
  }
`;

const getFullName = (user) => {
  return user.firstName;
};

const getSortedUserMoverPositions = withFragment(
  ({userMoverPositions}) => {
    return userMoverPositions.sort((a, b) => {
      // Put primary position first
      if (a.isPrimary) {
        return -1;
      }
      if (b.isPrimary) {
        return 1;
      }

      // Sort remaining positions alphabetically by name
      return a.moverPosition.name.localeCompare(b.moverPosition.name);
    });
  },
  gql`
    fragment User_getSortedUserMoverPositions on User {
      id
      userMoverPositions {
        id
        isPrimary
        moverPosition {
          id
          name
        }
      }
    }
  `,
);

const User = {
  canViewCustomerInfo,

  filterByIds: ({users, ids}) => {
    return users.filter((user) => ids.includes(user.id));
  },

  getContactInfo: withFragment(
    ({aliasName, fullName, phoneNumber, email}) => {
      return [aliasName, fullName, Phone.display(phoneNumber), email].filter(Boolean).join('\n');
    },
    gql`
      fragment User_getContactInfo on User {
        aliasName
        fullName
        phoneNumber
        email
      }
    `,
  ),

  getFullName,

  getInitials: (user) => {
    // Since firstName is now the full name, we need to split by space and join.
    const names = user.firstName.split(' ');
    const firstLetters = names.map((name) => _.startCase(_.first(name)));
    return firstLetters.join('');
  },

  getPosition: (user) => {
    return _.startCase(_.toLower(user.position));
  },
  getRole: (user) => {
    return _.startCase(_.toLower(user.role));
  },
  getCustomerDisplayName: (user) => {
    // Returns customer's alias name if one exists,
    // otherwise returns first and last name.
    return user.aliasName || getFullName(user);
  },

  getSortedUserMoverPositions,
};

export default User;
