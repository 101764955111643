// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Shared
import ReportDashboardForm from '@shared/modules/Reports/forms/ReportDashboardForm';

const edit = withFragment(
  (reportDashboards) => ({
    reportDashboardForms: reportDashboards.map((reportDashboard) => {
      return ReportDashboardForm.edit(reportDashboard);
    }),
  }),
  gql`
    ${ReportDashboardForm.edit.fragment}

    fragment ReorderReportDashboardsForm_edit on Dashboard {
      id
      ...ReportDashboardForm_edit
    }
  `,
);

const toForm = ({reportDashboardForms}) => ({
  reportDashboardForms: reportDashboardForms.map((reportDashboardForm) => {
    return ReportDashboardForm.toForm(reportDashboardForm);
  }),
});

const toMutation = ({reportDashboardForms}) => ({
  reportDashboardForms: reportDashboardForms.map((reportDashboardForm) => {
    return ReportDashboardForm.toMutation(reportDashboardForm);
  }),
});

const ReorderReportDashboardsForm = {
  edit,
  toForm,
  toMutation,
};

export default ReorderReportDashboardsForm;
