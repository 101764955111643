// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled, Tabs} from '@supermove/components';
import {useEffect, useResponsive, useTabs} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Callout from '@shared/design/components/Callout';
import FieldInput from '@shared/design/components/Field/FieldInput';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const SlideContainer = Styled.View`
  padding-horizontal: 24px;
`;

const ensureThreeElements = (array) => {
  return _.concat(array, _.fill(Array(3 - array.length), null));
};

const useEntriesSlidesHandler = ({tabs, timesheetEntryGroups}) => {
  useEffect(() => {
    if (tabs.selectedIndex >= timesheetEntryGroups.length) {
      tabs.setSelectedIndex(Math.max(timesheetEntryGroups.length - 1, 0));
    }
  }, [tabs.selectedIndex, timesheetEntryGroups]); // eslint-disable-line react-hooks/exhaustive-deps
};

const SwipeButtons = ({tabs, children}) => {
  return (
    <Row style={{alignItems: 'center', paddingHorizontal: 24}}>
      {/* Wrap this button so the zIndex brings the hit slop area over the slide viewer content */}
      <Container style={{zIndex: 99999}}>
        <TertiaryButton onPress={tabs.handlePrev} isDisabled={!tabs.hasPrev} isHitSlop>
          <Icon
            source={Icon.ChevronLeft}
            size={16}
            color={tabs.hasPrev ? colors.blue.interactive : colors.gray.disabled}
          />
        </TertiaryButton>
      </Container>
      {children}
      <TertiaryButton onPress={tabs.handleNext} isDisabled={!tabs.hasNext} isHitSlop>
        <Icon
          source={Icon.ChevronRight}
          size={16}
          color={tabs.hasNext ? colors.blue.interactive : colors.gray.disabled}
        />
      </TertiaryButton>
    </Row>
  );
};

const TimesheetEntriesSlideViewer = ({
  timesheetEntries,
  TimesheetEntryCardComponent,
  timesheetEntryCardComponentProps = {},
  timesheetEntryIdsError,
  emptyMessage,
}) => {
  const responsive = useResponsive();
  const timesheetEntryGroups = _.chunk(timesheetEntries, 6);
  const tabs = useTabs({initialIndex: 0, numberOfTabs: timesheetEntryGroups.length});
  useEntriesSlidesHandler({tabs, timesheetEntryGroups});

  return (
    <Container>
      {_.isEmpty(timesheetEntries) ? (
        <SlideContainer>
          <Callout text={emptyMessage} />
        </SlideContainer>
      ) : (
        <SwipeButtons tabs={tabs}>
          <Tabs.SlideViewer tabs={tabs}>
            {timesheetEntryGroups.map((sixEntryGroup) => {
              return (
                <Container key={sixEntryGroup[0].id} style={{flex: 1}}>
                  {_.chunk(sixEntryGroup, 3).map((threeEntryGroup, index) => {
                    const timesheetEntries = ensureThreeElements(threeEntryGroup);
                    return (
                      <SlideContainer key={threeEntryGroup[0].id}>
                        {index > 0 && <Space height={16} />}
                        <Row>
                          {timesheetEntries.map((timesheetEntry, index) => {
                            return (
                              <React.Fragment key={timesheetEntry?.id || index}>
                                {index > 0 && <Space width={16} />}
                                <TimesheetEntryCardComponent
                                  timesheetEntry={timesheetEntry}
                                  responsive={responsive}
                                  {...timesheetEntryCardComponentProps}
                                />
                              </React.Fragment>
                            );
                          })}
                        </Row>
                      </SlideContainer>
                    );
                  })}
                </Container>
              );
            })}
          </Tabs.SlideViewer>
        </SwipeButtons>
      )}
      {timesheetEntryIdsError && (
        <SlideContainer>
          <Space height={8} />
          <FieldInput.CaptionText isResponsive hasErrors>
            {timesheetEntryIdsError}
          </FieldInput.CaptionText>
        </SlideContainer>
      )}
      {timesheetEntryGroups.length > 1 && (
        <React.Fragment>
          <Space height={16} />
          <Row style={{justifyContent: 'center'}}>
            {timesheetEntryGroups.map((group, index) => {
              const isSelected = index === tabs.selectedIndex;
              return (
                <React.Fragment key={group[0].id}>
                  {index > 0 && <Space width={8} />}
                  <Icon
                    source={Icon.Circle}
                    size={8}
                    color={isSelected ? colors.gray.tertiary : colors.gray.border}
                  />
                </React.Fragment>
              );
            })}
          </Row>
        </React.Fragment>
      )}
    </Container>
  );
};

export default TimesheetEntriesSlideViewer;
