// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import CollectionFormV2 from '@shared/modules/Storage/forms/CollectionFormV2';

const useUpdateCollectionMutation = ({collectionForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      collectionForm: CollectionFormV2.toForm(collectionForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCollectionMutation.mutation,
    variables: {
      collectionForm: CollectionFormV2.toMutation(form.values.collectionForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCollectionMutation.mutation = gql`
  mutation UpdateCollectionMutation($collectionForm: CollectionFormV2) {
    response: updateCollectionV2(collectionForm: $collectionForm) {
      ${gql.errors}
      collection {
        id
      }
    }
  }
`;

export default useUpdateCollectionMutation;
