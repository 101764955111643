// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = withFragment(
  ({team, sender}) => {
    return {
      teamId: team.id,
      senderId: sender.id,
      body: '',
      isManual: true,
      textMessageTemplateId: null,

      // Private
      headerBody: `[To ${team.name} from ${sender.fullName}]\n\n`,
    };
  },
  gql`
    fragment SendTextMessageToTeamForm_new on Team {
      id
      name
    }
    fragment SendTextMessageToTeamForm_Viewer on User {
      id
      fullName
    }
  `,
);

const toForm = ({teamId, senderId, body, textMessageTemplateId, headerBody, isManual}) => ({
  teamId,
  senderId,
  body: headerBody + body,
  textMessageTemplateId,
  headerBody,
  isManual,
});

const toMutation = ({teamId, senderId, body, textMessageTemplateId, isManual}) => ({
  teamId: _.toNumber(teamId),
  senderId: _.toNumber(senderId),
  body,
  textMessageTemplateId,
  isManual,
});

const SendTextMessageToTeamForm = {
  new: _new,
  toForm,
  toMutation,
};

export default SendTextMessageToTeamForm;
