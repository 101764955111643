// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {KeyboardView, Space, Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useModal, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import ErrorModal from '@shared/design/components/Modal/SmallModal/ErrorModal';
import useUpdateRecurringInvoiceMutation from '@shared/modules/Billing/hooks/useUpdateRecurringInvoiceMutation';
import PayEngineCreditCardInput from '@shared/modules/Payment/components/PayEngineCreditCardInput';
import BeginPaymentV3Form from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import {
  transactionContactMessage,
  transactionErrorMessage,
} from '@shared/modules/Payment/hooks/payengineErrors';
import useChargePayEngineCreditCard from '@shared/modules/Payment/hooks/useChargePayEngineCreditCard';

const PaymentText = Styled.Text`
  ${Typography.Heading2}
`;

const Text = Styled.Text`
  ${Typography.Body}
  color: ${(props) => props.color}
`;

const Container = Styled.View`
  width: 100%;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const AutomaticPaymentCallout = Styled.View`
  background-color: ${colors.blue.accent}
  border-radius: 4px;
  padding: 12px;
`;

const AutomaticPaymentSection = ({invoice, refetch, setIsEditingCreditCard}) => {
  const updateRecurringInvoiceMutation = useUpdateRecurringInvoiceMutation({
    recurringInvoiceId: invoice.recurringInvoice.id,
    onSuccess: (response) => {},
    onError: (errors) => {},
  });

  return (
    <Container>
      <AutomaticPaymentCallout>
        <Row>
          <Icon source={Icon.Check} color={colors.blue.interactive} size={14} />
          <Space width={12} />
          <Text
            color={colors.blue.interactive}
          >{`This invoice will be automatically paid on ${Datetime.convertToDisplayDate(
            invoice.dueDate,
            Datetime.DISPLAY_SHORT_DATE,
          )} using your saved card.`}</Text>
        </Row>
      </AutomaticPaymentCallout>
      <Space height={24} />
      <Row>
        <Container style={{flex: 1}}>
          <Button
            isWidthOfContainer
            iconLeft={Icon.Pen}
            text={'Update Card'}
            onPress={() => {
              setIsEditingCreditCard(true);
            }}
          />
        </Container>
        <Space width={8} />
        <Container style={{flex: 1}}>
          <SecondaryButton
            isWidthOfContainer
            iconLeft={Icon.Times}
            text={'Cancel Autopay'}
            onPress={async () => {
              await updateRecurringInvoiceMutation.handleSubmit();
              refetch();
            }}
          />
        </Container>
      </Row>
    </Container>
  );
};

const CreditCardPaymentSection = ({invoice, onSuccess}) => {
  const client = invoice.project.billingClient;

  const [isAutopayChecked, setIsAutopayChecked] = useState(!!invoice.isAutopayEnabled);
  const [errorMessage, setErrorMessage] = useState(`Something went wrong.`);
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const onLoad = React.useCallback(() => {
    setIsFormLoaded(true);
  }, [setIsFormLoaded]);

  const paymentErrorModal = useModal({name: 'Invoice Payment Error Modal'});
  const beginPaymentV3Form = BeginPaymentV3Form.new({
    billId: invoice.project.currentPrimaryBill.id,
    invoiceId: invoice.id,
    customerId: client.primaryContact.id,
    name: `Payment for Invoice ${invoice.identifier}`,
    amount: invoice.remainingBalance,
    method: 'PAYENGINE_CREDIT_CARD',
    tipAmount: 0,
    tipName: null,
  });
  const chargePayEngineCreditCard = useChargePayEngineCreditCard({
    beginPaymentV3Form,
    saveToClientId: client.id,

    // Save card as default only if invoice is recurring and autopay is checked since recurring
    // invoices enrolled in autopay are supposed to use the client's default credit card. Otherwise,
    // let the backend decide if the card should be default (i.e. yes if it's the client's first
    // card, else no).
    saveAsDefault: invoice.isRecurring && isAutopayChecked ? true : null,
  });

  const updateRecurringInvoiceMutation = useUpdateRecurringInvoiceMutation({
    recurringInvoiceId: invoice.recurringInvoice?.id,
    onSuccess: (response) => {},
    onError: (errors) => {},
  });

  const onPress = async () => {
    try {
      await chargePayEngineCreditCard.handleTokenizeCreditCard();
    } catch (error) {
      console.error(`Error tokenizing credit card: `, error);
      setErrorMessage(`Invalid credit card details.`);
      paymentErrorModal.handleOpen();
      return;
    }

    try {
      const response = await chargePayEngineCreditCard.handleChargeCreditCard();
      updateRecurringInvoiceMutation.form.setFieldValue(
        `recurringInvoiceForm.payengineCreditCardId`,
        response.savedCardId,
      );
    } catch (error) {
      console.error(`Error charging credit card: `, error);
      setErrorMessage(
        `${transactionErrorMessage(error)} ${transactionContactMessage(invoice.organization.name)}`,
      );
      paymentErrorModal.handleOpen();
      return;
    }

    if (isAutopayChecked) {
      try {
        const updateRecurringInvoiceResponse = await updateRecurringInvoiceMutation.handleSubmit();
        const errors =
          updateRecurringInvoiceResponse?.errors ||
          updateRecurringInvoiceResponse?.data?.response?.errors;
        if (errors) {
          throw errors;
        }
      } catch (error) {
        console.error(`Error saving credit card: `, error);
        setErrorMessage(
          `Credit card was charged successfully, but could not be saved for use on future payments.`,
        );
        paymentErrorModal.handleOpen();
        return;
      }
    }
    onSuccess();
  };

  const isSubmitting =
    chargePayEngineCreditCard.submitting || updateRecurringInvoiceMutation.submitting;

  return (
    <Container>
      <KeyboardView>
        <PayEngineCreditCardInput
          setCreditCardClient={chargePayEngineCreditCard.setCreditCardClient}
          mode={invoice.organization.mode}
          onLoad={onLoad}
        />
      </KeyboardView>
      <Space height={24} />
      {invoice.isRecurring && (
        <>
          <Checkbox
            isChecked={isAutopayChecked}
            childrenRight
            handleToggle={() => setIsAutopayChecked(!isAutopayChecked)}
          >
            <Space width={16} />
            <Text>{`I give permission for ${invoice.organization.name} to automatically charge this card for future recurring invoices.`}</Text>
          </Checkbox>
          <Space height={24} />
        </>
      )}
      <Button
        isWidthOfContainer
        text={`Pay ${Currency.display(invoice.remainingBalance)}`}
        isDisabled={!isFormLoaded}
        isSubmitting={isSubmitting}
        onPress={_.throttle(onPress, 3000)}
      />
      <ErrorModal
        title={'Unable to process credit card.'}
        subtitle={errorMessage}
        isOpen={paymentErrorModal.isOpen}
        handleClose={paymentErrorModal.handleClose}
      />
    </Container>
  );
};

const PayEnginePaymentsSection = ({invoice, refetch}) => {
  const [isEditingCreditCard, setIsEditingCreditCard] = useState(false);
  const {navigator} = useNavigationDOM();

  return (
    <>
      <PaymentText>Make a payment</PaymentText>
      <Space height={24} />
      {invoice.recurringInvoice?.id && invoice.isAutopayEnabled && !isEditingCreditCard ? (
        <AutomaticPaymentSection
          invoice={invoice}
          refetch={refetch}
          setIsEditingCreditCard={setIsEditingCreditCard}
        />
      ) : (
        <CreditCardPaymentSection
          invoice={invoice}
          onSuccess={() => {
            // HACK(cassie): Status doesn't change immediately, set a timeout to avoid redirect to /review and back
            setTimeout(
              () =>
                navigator.push(`/0/${invoice.organization.slug}/invoices/${invoice.uuid}/success`),
              1000,
            );
          }}
        />
      )}
      <Space height={24} />
    </>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PayEnginePaymentsSection.fragment = gql`
  fragment PayEnginePaymentsSection on Invoice {
    id
    identifier
    remainingBalance
    dueDate
    isRecurring
    recurringInvoice {
      id
    }
    isAutopayEnabled
    project {
      id
      currentPrimaryBill {
        id
      }
      billingClient {
        id
        primaryContact {
          id
        }
      }
    }
    organization {
      id
      name
      mode
    }
  }
`;

export default PayEnginePaymentsSection;
