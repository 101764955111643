// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getAttachmentCategoryName = withFragment(
  (attachmentCategory) => {
    switch (attachmentCategory.kind) {
      case 'CREW_PHOTO':
        return 'Crew Photo';
      case 'INSTRUCTIONS_FOR_CREW':
        return 'Instructions For Crew';
      case 'OFFICE_ONLY':
        return 'Office Only';
      default:
        return 'Unknown';
    }
  },
  gql`
    fragment AttachmentCategory_getAttachmentCategoryName on AttachmentCategory {
      id
      kind
    }
  `,
);

const AttachmentCategory = {
  getAttachmentCategoryName,
};

export default AttachmentCategory;
