// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Shared
import ValueForm from '@shared/modules/Billing/forms/ValueForm';

const edit = withFragment(
  (job) => ({
    jobId: job.id,
    valueForms: job.values.map((value) => ValueForm.edit(value)),

    // Private
    name: job.shortName,
  }),
  gql`
    ${ValueForm.edit.fragment}

    fragment UpdateJobValuesForm_edit on Job {
      id
      shortName
      values {
        id
        ...ValueForm_edit
      }
    }
  `,
);

const toForm = ({
  jobId,
  valueForms,

  // Private
  name,
}) => ({
  jobId,
  valueForms: valueForms.map((valueForm) => ValueForm.toForm(valueForm)),

  // Private
  name,
});

const toMutation = ({jobId, valueForms}) => ({
  jobId,
  valueForms: valueForms.map((valueForm) => ValueForm.toMutation(valueForm)),
});

const UpdateJobValuesForm = {
  // Initialize
  edit,

  // Serialize
  toForm,
  toMutation,
};

export default UpdateJobValuesForm;
