// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({organizationId}) => ({
  name: null,
  version: null,
  status: null,
  isDeleted: false,
  documentContentJson: null,
  identifier: null,
  documentContentJsonForm: {},
  organizationId,
  documentTemplateId: null,
});

const edit = withFragment(
  (documentTemplateVersion) => ({
    name: documentTemplateVersion.name,
    version: documentTemplateVersion.version,
    status: documentTemplateVersion.status,
    documentContentJson: documentTemplateVersion.documentContentJson,
    identifier: documentTemplateVersion.identifier,
    documentContentJsonForm: documentTemplateVersion.documentContentJsonForm,
    organizationId: documentTemplateVersion.organizationId,
    documentTemplateId: documentTemplateVersion.documentTemplateId,
  }),
  gql`
    fragment DocumentTemplateVersionForm_edit on DocumentTemplateVersion {
      id
      name
      version
      status
      isDeleted
      documentContentJson
      identifier
      organizationId
      documentTemplateId
    }
  `,
);

const toForm = ({
  name,
  version,
  status,
  isDeleted,
  documentContentJson,
  identifier,
  organizationId,
  createdById,
  updatedById,
  documentTemplateId,
}) => ({
  name,
  version,
  status,
  isDeleted,
  documentContentJson,
  identifier,
  organizationId,
  createdById,
  updatedById,
  documentTemplateId,
});

const toMutation = ({
  name,
  version,
  status,
  isDeleted,
  documentContentJson,
  identifier,
  organizationId,
  createdById,
  updatedById,
  documentTemplateId,
}) => ({
  name,
  version,
  status,
  isDeleted,
  documentContentJson,
  identifier,
  organizationId,
  createdById,
  updatedById,
  documentTemplateId,
});

const DocumentTemplateVersionForm = {
  edit,
  toForm,
  toMutation,
  new: _new,
};

export default DocumentTemplateVersionForm;
