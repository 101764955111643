// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useQuery} from '@supermove/hooks';
import {JobUser} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import TextTooltip from 'modules/App/components/TextTooltip';
import RIGHT_PANEL_QUERIES from 'modules/Dispatch/Calendar/Day/constants/RIGHT_PANEL_QUERIES';
import CrewTrucksPopover from 'modules/Dispatch/Crew/components/CrewTrucksPopover';
import CrewUsersAndDriversPopover from 'modules/Dispatch/Crew/components/CrewUsersAndDriversPopover';
import JobUserMoverPosition from 'modules/JobUser/components/JobUserMoverPosition';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Indicator = Styled.Loading`
  margin-top: 57px;
  margin-bottom: 28px;
`;

const TitleRow = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TitleText = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props.color ? props.color : colors.black)};
`;

const TitleSubText = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
`;

const ListItemText = Styled.H7`
  color: ${colors.black};
  ${fontWeight(500)}
`;

const JobUserStatusDot = Styled.View`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
  margin-top: 6px;
`;

const EditButton = Styled.ButtonV2`
  height: 21px;
  width: 21px;
`;

const JobUserMoverDisplay = Styled.View`
  flex-direction: row;
`;

const NameAndBadgesContainer = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`;

const CrewSlotTruckItem = ({crewSlot}) => {
  return (
    <React.Fragment>
      <ListItemText>{`${crewSlot.truck.name}${
        crewSlot.truck.size ? `, ${crewSlot.truck.size}` : ''
      }`}</ListItemText>
      <Space height={4} />
    </React.Fragment>
  );
};

const CrewSlotTrucksSection = ({crew, editTrucksPopover}) => {
  const filteredCrewSlots = crew.crewSlotsForStartDateV2.filter((crewSlot) => !!crewSlot.truck);
  const sortedCrewSlots = _.sortBy(filteredCrewSlots, ['truck.name', 'slot.index']);

  return (
    <React.Fragment>
      <TitleRow>
        <TitleText color={colors.gray.primary}>
          {`${filteredCrewSlots.length}/${crew.numberOfRequiredTrucks} Trucks`}
        </TitleText>
        <Popover.Content innerRef={editTrucksPopover.ref}>
          <EditButton onPress={editTrucksPopover.handleToggle}>
            <Icon source={Icon.Pen} size={16} color={colors.blue.interactive} />
          </EditButton>
        </Popover.Content>
      </TitleRow>
      <Space height={4} />
      {sortedCrewSlots.map((crewSlot) => (
        <CrewSlotTruckItem crewSlot={crewSlot} key={crewSlot.id} />
      ))}
    </React.Fragment>
  );
};

const JobUserMoversItem = ({jobUser, refetch, handleOpenChildPopover, handleCloseChildPopover}) => {
  return (
    <JobUserMoverDisplay>
      <TextTooltip text={JobUser.getTooltipStatus(jobUser)}>
        <JobUserStatusDot color={jobUser.statusColor} />
      </TextTooltip>
      <Space width={8} />
      <NameAndBadgesContainer>
        <ListItemText numberOfLines={1}>{jobUser.user.fullName}</ListItemText>
        <Space width={8} />
        <JobUserMoverPosition
          jobUser={jobUser}
          refetch={refetch}
          handleOpenChildPopover={handleOpenChildPopover}
          handleCloseChildPopover={handleCloseChildPopover}
        />
        <Space width={4} />
      </NameAndBadgesContainer>
      <Space height={4} />
    </JobUserMoverDisplay>
  );
};

const JobUserMoversSection = ({
  crew,
  editMoversPopover,
  refetch,
  handleOpenChildPopover,
  handleCloseChildPopover,
}) => {
  const sortedJobUsers = _.sortBy(crew.jobUsers, [`user.fullName`]);
  const numberOfConfirmedMovers = crew.jobUsers.filter(
    (jobUser) => jobUser.status === 'CONFIRMED',
  ).length;

  return (
    <React.Fragment>
      <TitleRow>
        <Row>
          <TitleText
            color={colors.gray.primary}
          >{`${crew.jobUsers.length}/${crew.numberOfRequiredMovers} Movers`}</TitleText>
          <Space width={8} />
          <TitleSubText>
            {`${numberOfConfirmedMovers}/${crew.numberOfRequiredMovers} confirmed`}
          </TitleSubText>
        </Row>
        <Popover.Content innerRef={editMoversPopover.ref}>
          <EditButton onPress={editMoversPopover.handleToggle}>
            <Icon source={Icon.Pen} size={16} color={colors.blue.interactive} />
          </EditButton>
        </Popover.Content>
      </TitleRow>
      <Space height={4} />
      {sortedJobUsers.map((jobUser, index) => (
        <React.Fragment key={jobUser.id}>
          {index !== 0 && <Space height={4} />}
          <JobUserMoversItem
            jobUser={jobUser}
            refetch={refetch}
            handleOpenChildPopover={handleOpenChildPopover}
            handleCloseChildPopover={handleCloseChildPopover}
          />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

const JobDispatchPopoverCrewContent = ({
  crew,
  refetch,
  setIsClosable,
  handleClose,
  jobJobUsersScheduleModal,
}) => {
  const editTrucksPopover = usePopover();
  const editMoversPopover = usePopover();
  const handleOpenChildPopover = (handleOpen) => {
    return () => {
      setIsClosable(false);
      handleOpen();
    };
  };
  const handleCloseChildPopover = (handleOpen) => {
    return () => {
      handleOpen();
      setIsClosable(true);
    };
  };
  return (
    <React.Fragment>
      <CrewSlotTrucksSection crew={crew} editTrucksPopover={editTrucksPopover} />
      <Space height={16} />
      <JobUserMoversSection
        crew={crew}
        editMoversPopover={editMoversPopover}
        refetch={refetch}
        handleOpenChildPopover={handleOpenChildPopover}
        handleCloseChildPopover={handleCloseChildPopover}
      />
      <CrewTrucksPopover
        isOpen={editTrucksPopover.isOpen}
        key={`${editTrucksPopover.isOpen}-TRUCKS`}
        handleOpen={handleOpenChildPopover(editTrucksPopover.handleOpen)}
        handleClose={handleCloseChildPopover(editTrucksPopover.handleClose)}
        popoverRef={editTrucksPopover.ref}
        crewId={crew.id}
        placement={Popover.Positions.RightStart}
        offset={[0, 4]}
        refetch={() => {
          refetch();
          // When a new truck gets saved, it will force this popover to close when the
          // data refetches and relocates the job card to a different slot. Instead of
          // waiting for the delayed update, we close the modal right after the refetch.
          handleClose();
        }}
        refetchQueries={RIGHT_PANEL_QUERIES}
      />
      <CrewUsersAndDriversPopover
        isOpen={editMoversPopover.isOpen}
        key={`${editMoversPopover.isOpen}-MOVERS`}
        handleOpen={handleOpenChildPopover(editMoversPopover.handleOpen)}
        handleClose={handleCloseChildPopover(editMoversPopover.handleClose)}
        popoverRef={editMoversPopover.ref}
        crew={crew}
        placement={Popover.Positions.Right}
        refetch={refetch}
        jobJobUsersScheduleModal={jobJobUsersScheduleModal}
        offset={[-32, 4]}
        shouldRunUpsertCostsAndBillingEngineAsync
      />
    </React.Fragment>
  );
};

const LoadingIndicator = () => {
  return <Indicator color={colors.gray.secondary} />;
};

const JobDispatchPopoverCrew = ({
  crewId,
  refetch,
  setIsClosable,
  handleClose,
  jobJobUsersScheduleModal,
}) => {
  const {
    loading,
    data,
    refetch: refetchPopover,
  } = useQuery(JobDispatchPopoverCrew.query, {
    fetchPolicy: 'network-only',
    variables: {crewId},
  });

  const handleRefetch = () => {
    refetch();
    refetchPopover();
  };

  return (
    <Loading loading={loading} as={LoadingIndicator}>
      {() => {
        return (
          <JobDispatchPopoverCrewContent
            crew={data.crew}
            refetch={handleRefetch}
            setIsClosable={setIsClosable}
            handleClose={handleClose}
            jobJobUsersScheduleModal={jobJobUsersScheduleModal}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDispatchPopoverCrew.query = gql`
  ${CrewUsersAndDriversPopover.fragment}
  ${JobUser.getTooltipStatus.fragment}
  ${JobUserMoverPosition.fragment}

  query JobDispatchPopoverCrew($crewId: Int!) {
    crew(crewId: $crewId) {
      id
      numberOfRequiredTrucks
      numberOfRequiredMovers
      crewSlotsForStartDateV2 {
        id
        truck {
          id
          name
          size
        }
        slot {
          id
          index
        }
      }
      jobUsers {
        id
        status
        statusColor
        user {
          id
          fullName
        }
        ...JobUserMoverPosition
        ...JobUser_getTooltipStatus
      }
      job {
        id
        uuid
      }
      ...CrewUsersAndDriversPopover
    }
  }
`;

export default JobDispatchPopoverCrew;
