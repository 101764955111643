// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({jobId}) => ({
  jobId,
  additionalNotes: '',
  dispatchNotes: '',
  officeNotes: '',
  noteToCustomer: '',
  noteFromCustomer: '',
});

const edit = withFragment(
  (job) => ({
    jobId: job.id,
    additionalNotes: job.additionalNotes,
    dispatchNotes: job.dispatchNotes,
    officeNotes: job.officeNotes,
    noteToCustomer: job.noteToCustomer,
    noteFromCustomer: job.noteFromCustomer,
  }),
  gql`
    fragment JobNotesForm_edit on Job {
      id
      additionalNotes
      dispatchNotes
      officeNotes
      noteToCustomer
      noteFromCustomer
    }
  `,
);

const toForm = ({
  jobId,
  additionalNotes,
  dispatchNotes,
  officeNotes,
  noteToCustomer,
  noteFromCustomer,
}) => ({
  jobId,
  additionalNotes,
  dispatchNotes: dispatchNotes || '',
  officeNotes,
  noteToCustomer,
  noteFromCustomer,
});

const toMutation = ({
  jobId,
  additionalNotes,
  dispatchNotes,
  officeNotes,
  noteToCustomer,
  noteFromCustomer,
}) => ({
  jobId,
  additionalNotes,
  dispatchNotes,
  officeNotes,
  noteToCustomer,
  noteFromCustomer,
});

const JobNotesForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default JobNotesForm;
