// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({projectTypeId, projectTypeName = '', projectTypeColor = ''}) => ({
  projectTypeId,
  inventoryLibraryId: null,
  surveyMethodId: null,

  // private
  projectTypeName,
  projectTypeColor,
});

const edit = withFragment(
  (projectType) => ({
    projectTypeId: projectType.id,
    inventoryLibraryId: projectType.defaultInventoryLibraryId,
    surveyMethodId: projectType.defaultSurveyMethodId,

    // private
    projectTypeName: projectType.name,
    projectTypeColor: projectType.color,
  }),
  gql`
    fragment DefaultProjectTypeInventorySettingsForm_edit on ProjectType {
      id
      name
      color
      defaultInventoryLibraryId
      defaultSurveyMethodId
    }
  `,
);

const toForm = ({
  projectTypeId,
  inventoryLibraryId,
  surveyMethodId,
  projectTypeName,
  projectTypeColor,
}) => ({
  projectTypeId,
  inventoryLibraryId,
  surveyMethodId,

  // private
  projectTypeName,
  projectTypeColor,
});

const toMutation = ({projectTypeId, inventoryLibraryId, surveyMethodId}) => ({
  projectTypeId,
  inventoryLibraryId,
  surveyMethodId,
});

const DefaultProjectTypeInventorySettingsForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default DefaultProjectTypeInventorySettingsForm;
