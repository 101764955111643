// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight} from '@supermove/styles';

// App
import BillingProjectBillsListV1 from 'modules/Project/Billing/components/BillingProjectBillsListV1';
import BillingProjectPaymentsList from 'modules/Project/Billing/components/BillingProjectPaymentsList';

const Container = Styled.View`
  display: block;
  width: 100%;
`;

const Title = Styled.H5`
  ${fontWeight(700)}
`;

const DocumentBillingInformation = ({showBillRules, showPayments, job}) => {
  return (
    <Container>
      <Title>Billing Items</Title>
      <React.Fragment>
        <BillingProjectBillsListV1
          isDocument
          includeAllBills={false}
          showBillRules={showBillRules}
          project={job.project}
        />
        {showPayments && (
          <BillingProjectPaymentsList includeAllBills={false} project={job.project} />
        )}
      </React.Fragment>
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
DocumentBillingInformation.propTypes = {
  job: PropTypes.object.isRequired,
  showBillRules: PropTypes.bool,
  showPayments: PropTypes.bool,
};

DocumentBillingInformation.defaultProps = {
  showBillRules: false,
  showPayments: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentBillingInformation.fragment = gql`
  ${BillingProjectBillsListV1.fragment}
  ${BillingProjectPaymentsList.fragment}

  fragment DocumentBillingInformation on Job {
    id
    project {
      id
      ...BillingProjectBillsListV1
      ...BillingProjectPaymentsList
    }
    currentBill {
      id
      hasBillRulesForCustomer
      hasPayments
    }
  }
`;

export default DocumentBillingInformation;
