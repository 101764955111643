// Libraries
import React from 'react';

// Supermove
import {
  ResponsivePopover as BaseResponsivePopover,
  Icon,
  Space,
  Styled,
} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';

const PopoverContainer = Styled.View`
  width: ${(props) => (props.width ? `${props.width}px` : null)};
  height: ${(props) => (props.height ? `${props.height}px` : null)};
  max-height: ${(props) => (props.maxHeight ? `${props.maxHeight}px` : null)};
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: ${colors.white};
  overflow: visible;
`;

const MobileModalContainer = Styled.View`
  flex: 1;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.gray.background};
  width: 100%;
`;

const StandardHeaderContainer = Styled.View`
  padding-horizontal: 16px;
`;

const MobileHeaderContainer = Styled.View`
  flex-direction: row;
`;

const HeaderRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const HeaderCaptionRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HeaderTitleText = Styled.Text`
  ${Typography.Label2}
`;

const HeaderCaptionText = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.tertiary};
`;

const HeaderRightSide = Styled.View`
  flex-direction: row;
  align-items: flex-start;
`;

const HeaderButton = Styled.Button`
  height: auto;
  background-color: ${colors.white};
  box-shadow: none;
`;

const HeaderColumn = Styled.View`
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const FooterActions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const MobileFooterActions = Styled.View`
  padding-horizontal: 12px;
`;

const CancelButton = Styled.Button`
  background-color: ${colors.white};
  box-shadow: none;
  height: 36px;
`;

const MobileSaveButton = Styled.LoadingButton`
  box-shadow: none;
  height: 56px;
  align-self: stretch;
`;

const FooterButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${(props) => props.color};
`;

const ResponsivePopoverContainer = ({backgroundColor, width, maxHeight, height, children}) => {
  const responsive = useResponsive();
  if (responsive.mobile) {
    return (
      <MobileModalContainer backgroundColor={backgroundColor}>{children}</MobileModalContainer>
    );
  }
  return (
    <PopoverContainer width={width} maxHeight={maxHeight} height={height}>
      {children}
    </PopoverContainer>
  );
};

const StandardHeader = ({title, caption, headerRight, headerCaptionRight}) => {
  return (
    <StandardHeaderContainer>
      <HeaderRow>
        <HeaderTitleText>{title}</HeaderTitleText>
        <HeaderRightSide>{headerRight}</HeaderRightSide>
      </HeaderRow>
      <Space height={4} />
      <HeaderRow>
        <HeaderCaptionText>{caption}</HeaderCaptionText>
        {headerCaptionRight}
      </HeaderRow>
    </StandardHeaderContainer>
  );
};

const MobileHeader = ({title, caption, handleClose, headerRight, headerCaptionRight}) => {
  return (
    <MobileHeaderContainer>
      <Space width={16} />
      <HeaderButton onPress={handleClose}>
        <Icon source={Icon.Times} size={19} color={colors.gray.primary} />
      </HeaderButton>
      <Space width={20} />
      <HeaderColumn>
        <HeaderTitleText>{title}</HeaderTitleText>
        <HeaderCaptionRow>
          <HeaderCaptionText>{caption}</HeaderCaptionText>
          {headerCaptionRight}
        </HeaderCaptionRow>
      </HeaderColumn>
      {headerRight}
      <Space width={16} />
    </MobileHeaderContainer>
  );
};

const ResponsivePopoverHeader = ({
  title,
  caption,
  headerRight,
  headerCaptionRight,
  handleClose,
}) => {
  const responsive = useResponsive();
  if (responsive.mobile) {
    return (
      <MobileHeader
        title={title}
        caption={caption}
        headerRight={headerRight}
        headerCaptionRight={headerCaptionRight}
        handleClose={handleClose}
      />
    );
  }
  return (
    <StandardHeader
      title={title}
      caption={caption}
      headerRight={headerRight}
      headerCaptionRight={headerCaptionRight}
      handleClose={handleClose}
    />
  );
};

const StandardFooter = ({
  handleClose,
  handleSubmit,
  handleSubmitText,
  loading,
  disabled,
  submitButtonWidth,
}) => {
  return (
    <FooterActions>
      <CancelButton onPress={handleClose} disabled={disabled || loading}>
        <FooterButtonText color={colors.gray.tertiary}>Cancel</FooterButtonText>
      </CancelButton>
      <Space width={24} />
      <Button
        onPress={handleSubmit}
        isDisabled={disabled || loading}
        isSubmitting={loading}
        text={handleSubmitText}
        width={submitButtonWidth}
      />
      <Space width={16} />
    </FooterActions>
  );
};

const MobileFooter = ({handleSubmit, handleSubmitText, disabled, loading}) => {
  return (
    <MobileFooterActions>
      <MobileSaveButton onPress={handleSubmit} disabled={disabled} loading={loading}>
        <FooterButtonText color={colors.white}>{handleSubmitText}</FooterButtonText>
      </MobileSaveButton>
    </MobileFooterActions>
  );
};

const ResponsivePopoverFooter = ({
  handleClose,
  handleSubmit,
  handleSubmitText,
  submitButtonWidth,
  disabled,
  loading,
}) => {
  const responsive = useResponsive();
  if (responsive.mobile) {
    return (
      <MobileFooter
        handleSubmit={handleSubmit}
        handleSubmitText={handleSubmitText}
        disabled={disabled}
        loading={loading}
      />
    );
  }
  return (
    <StandardFooter
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      handleSubmitText={handleSubmitText}
      loading={loading}
      disabled={disabled}
      submitButtonWidth={submitButtonWidth}
    />
  );
};

const ResponsivePopover = (props) => {
  return <BaseResponsivePopover {...props} />;
};

// All the below components may be used as children
// inside of a ResponsivePopover.

// Use ResponsivePopover.StaticContainer when
// you DO NOT want a full screen modal on mobile.
ResponsivePopover.StaticContainer = PopoverContainer;

// Use ResponsivePopover.Container when
// you DO want a full screen modal on mobile.
ResponsivePopover.Container = ResponsivePopoverContainer;

// Use ResponsivePopover.Header when you want
// automatic styling for your header between desktop and mobile.
ResponsivePopover.Header = ResponsivePopoverHeader;

// Use ResponsivePopover.footer when you want
// automatic stying for your footer between desktop and mobile.
ResponsivePopover.Footer = ResponsivePopoverFooter;

export default ResponsivePopover;
