// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App

const Container = Styled.View`
  flex-direction: column;
  flex: 1;
  width: 232px;
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
`;

const ClickableCell = Styled.ButtonV2`
  width: 100%;
  padding-horizontal: 12px;
  padding-top: 8px;
  padding-bottom: 12px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
  background-color: ${(props) => (props.isSelected ? colors.hover : colors.white)};
`;

const NameText = Styled.Text`
  ${Typography.Label2}
  color: ${(props) => props.color};
`;

const KindText = Styled.Text`
  ${Typography.Label4}
  color: ${(props) => props.color};
`;

const VariableProjectAndJobTypeList = ({projectType}) => {
  const {navigator} = useNavigationDOM();
  const isProjectTypeSelected = _.includes(
    navigator.location.pathname,
    `/project-types/${projectType.uuid}/variables`,
  );

  return (
    <Container>
      <ScrollView>
        <ClickableCell
          isSelected={isProjectTypeSelected}
          onPress={() => navigator.push(`/settings/project-types/${projectType.uuid}/variables`)}
        >
          <KindText color={isProjectTypeSelected ? colors.gray.primary : colors.gray.secondary}>
            Project Type
          </KindText>
          <Space height={4} />
          <NameText color={isProjectTypeSelected ? colors.gray.primary : colors.gray.secondary}>
            {projectType.name}
          </NameText>
        </ClickableCell>
        {projectType.jobTypes.map((jobType) => {
          const isJobTypeSelected = _.includes(
            navigator.location.pathname,
            `/job-types/${jobType.uuid}/variables`,
          );
          return (
            <ClickableCell
              key={jobType.id}
              isSelected={isJobTypeSelected}
              onPress={() =>
                navigator.push(
                  `/settings/project-types/${projectType.uuid}/job-types/${jobType.uuid}/variables`,
                )
              }
            >
              <KindText color={isJobTypeSelected ? colors.gray.primary : colors.gray.secondary}>
                Job Type
              </KindText>
              <Space height={4} />
              <NameText color={isJobTypeSelected ? colors.gray.primary : colors.gray.secondary}>
                {jobType.name}
              </NameText>
            </ClickableCell>
          );
        })}
      </ScrollView>
    </Container>
  );
};

VariableProjectAndJobTypeList.fragment = gql`
  fragment VariableProjectAndJobTypeList on ProjectType {
    id
    name
    uuid
    jobTypes {
      id
      name
      uuid
    }
  }
`;

export default VariableProjectAndJobTypeList;
