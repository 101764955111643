// Supermove
import {gql} from '@supermove/graphql';
import {Currency, withFragment, Datetime} from '@supermove/utils';

// App
import Expression from '@shared/ast/Expression';
import FormulaPrettyPrinter from '@shared/formulas/src/FormulaPrettyPrinter';
import BillRuleKind from '@shared/modules/Billing/enums/BillRuleKind';

const getValueType = (kind) => {
  switch (kind) {
    case 'WAIVE_OVERTIME':
      return 'NONE';
    case 'CUSTOM':
      return 'STRING';
    case 'BEFORE_JOB_MINIMUM_PAYMENT':
    case 'MINIMUM_DOLLAR_AMOUNT':
    case 'NOT_TO_EXCEED_PRICE':
      return 'CURRENCY';
    case 'ADDITIONAL_HOURS':
    case 'MINIMUM_HOURS':
    default:
      return 'FLOAT';
  }
};

const getDisplayKind = withFragment(
  ({kind}) => {
    switch (kind) {
      case BillRuleKind.ADDITIONAL_HOURS:
        return '# Additional Hours';
      case BillRuleKind.BEFORE_JOB_MINIMUM_PAYMENT:
        return 'Minimum Payment Before the Move';
      case BillRuleKind.MINIMUM_DOLLAR_AMOUNT:
        return '$ Amount Minimum';
      case BillRuleKind.MINIMUM_HOURS:
        return '# Hours Minimum';
      case BillRuleKind.WAIVE_OVERTIME:
        return 'Waive Overtime';
      case BillRuleKind.NOT_TO_EXCEED_PRICE:
        return 'Not to Exceed Price';
      case BillRuleKind.CUSTOM:
      default:
        return 'Custom Rule';
    }
  },
  gql`
    fragment BillRuleType_getDisplayKind on BillRuleType {
      id
      kind
    }
  `,
);

const getDisplayValue = withFragment(
  ({kind, value, valueFormulaId, stringValue, valueFormula, showValueFormulaName = false}) => {
    if (valueFormulaId) {
      return showValueFormulaName ? valueFormula.name : 'TBD';
    }
    switch (kind) {
      case BillRuleKind.BEFORE_JOB_MINIMUM_PAYMENT:
      case BillRuleKind.MINIMUM_DOLLAR_AMOUNT:
      case BillRuleKind.NOT_TO_EXCEED_PRICE:
        return value ? Currency.display(value, {shouldHideCentsIfZero: true}) : '';
      case BillRuleKind.ADDITIONAL_HOURS:
      case BillRuleKind.MINIMUM_HOURS:
        return value ? `${value} hours` : '';
      case BillRuleKind.CUSTOM:
        return stringValue || '';
      default:
        return '';
    }
  },
  gql`
    fragment BillRuleType_getDisplayValue on BillRuleType {
      id
      kind
      value
      valueFormulaId
      stringValue
      valueFormula {
        id
        name
      }
    }
  `,
);

const getDisplayUpdatedAt = withFragment(
  (billRuleType) => {
    return Datetime.convertToDisplayDatetime(billRuleType.updatedAt);
  },
  gql`
    fragment BillRuleType_getDisplayUpdatedAt on BillRuleType {
      id
      updatedAt
    }
  `,
);

const hasDescriptionFormula = withFragment(
  (billRuleType) => {
    return !!billRuleType.descriptionFormulaId;
  },
  gql`
    fragment BillRuleType_hasDescriptionFormula on BillRuleType {
      id
      descriptionFormulaId
    }
  `,
);

const renderDescriptionFormulaString = withFragment(
  (billRuleType) => {
    if (billRuleType.descriptionFormula && billRuleType.descriptionFormula.astJson) {
      return new FormulaPrettyPrinter().print(
        Expression.fromJSON(JSON.parse(billRuleType.descriptionFormula.astJson)),
      );
    }
  },
  gql`
    fragment BillRuleType_renderDescriptionFormulaString on BillRuleType {
      id
      descriptionFormula {
        id
        astJson
      }
    }
  `,
);

const hasNameFormula = withFragment(
  (billRuleType) => {
    return !!billRuleType.nameFormulaId;
  },
  gql`
    fragment BillRuleType_hasNameFormula on BillRuleType {
      id
      nameFormulaId
    }
  `,
);

const renderNameFormulaString = withFragment(
  (billRuleType) => {
    if (billRuleType.nameFormula && billRuleType.nameFormula.astJson) {
      return new FormulaPrettyPrinter().print(
        Expression.fromJSON(JSON.parse(billRuleType.nameFormula.astJson)),
      );
    }
  },
  gql`
    fragment BillRuleType_renderNameFormulaString on BillRuleType {
      id
      nameFormula {
        id
        astJson
      }
    }
  `,
);

const hasValueFormula = withFragment(
  (billRuleType) => {
    return !!billRuleType.valueFormulaId;
  },
  gql`
    fragment BillRuleType_hasValueFormula on BillRuleType {
      id
      valueFormulaId
    }
  `,
);

const renderValueFormulaString = withFragment(
  (billRuleType) => {
    if (billRuleType.valueFormula && billRuleType.valueFormula.astJson) {
      return new FormulaPrettyPrinter().print(
        Expression.fromJSON(JSON.parse(billRuleType.valueFormula.astJson)),
      );
    }
  },
  gql`
    fragment BillRuleType_renderValueFormulaString on BillRuleType {
      id
      valueFormula {
        id
        astJson
      }
    }
  `,
);

const BillRuleType = {
  OPTIONS: [
    {label: 'CUSTOM', value: 'CUSTOM'},
    {label: 'WAIVE_OVERTIME', value: 'WAIVE_OVERTIME'},
    {label: 'BEFORE_JOB_MINIMUM_PAYMENT', value: 'BEFORE_JOB_MINIMUM_PAYMENT'},
    {label: 'MINIMUM_DOLLAR_AMOUNT', value: 'MINIMUM_DOLLAR_AMOUNT'},
    {label: 'NOT_TO_EXCEED_PRICE', value: 'NOT_TO_EXCEED_PRICE'},
    {label: 'ADDITIONAL_HOURS', value: 'ADDITIONAL_HOURS'},
    {label: 'MINIMUM_HOURS', value: 'MINIMUM_HOURS'},
  ],
  getValueType,
  getDisplayKind,
  getDisplayValue,
  getDisplayUpdatedAt,

  // Formulas
  hasDescriptionFormula,
  hasNameFormula,
  hasValueFormula,
  renderDescriptionFormulaString,
  renderNameFormulaString,
  renderValueFormulaString,
};

export default BillRuleType;
