// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import CapacityCalendarSettingForm from '@shared/modules/CapacityCalendarSetting/forms/CapacityCalendarSettingForm';

const useUpsertCapacityCalendarSettings = ({capacityCalendarSettingForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      capacityCalendarSettingForm: CapacityCalendarSettingForm.toForm(capacityCalendarSettingForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpsertCapacityCalendarSettings.mutation,
    variables: {
      capacityCalendarSettingForm: CapacityCalendarSettingForm.toMutation(
        form.values.capacityCalendarSettingForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpsertCapacityCalendarSettings.mutation = gql`
  mutation useUpsertCapacityCalendarSettings($capacityCalendarSettingForm: CapacityCalendarSettingForm!) {
    response: upsertCapacityCalendarSettings(capacityCalendarSettingForm: $capacityCalendarSettingForm) {
      ${gql.errors}
      capacityCalendarSetting {
        id
      }
    }
  }
`;

export default useUpsertCapacityCalendarSettings;
