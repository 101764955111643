// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (confirmation) => ({
    confirmationId: confirmation.id,
  }),
  gql`
    fragment ConfirmationForm_edit on Confirmation {
      id
    }
  `,
);

const toForm = ({confirmationId}) => ({confirmationId});

const toMutation = ({confirmationId}) => ({confirmationId});

const ConfirmationForm = {
  edit,
  toForm,
  toMutation,
};

export default ConfirmationForm;
