// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useResendMoverAppInvitationTextMutation = ({userId, onSuccess, onError}) => {
  const form = useForm({
    enableReinitialize: true,
    initialValues: {
      userId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useResendMoverAppInvitationTextMutation.mutation,
    variables: {
      resendMoverAppInvitationTextForm: {
        userId: form.values.userId,
      },
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useResendMoverAppInvitationTextMutation.mutation = gql`
  mutation useResendMoverAppInvitationTextMutation($resendMoverAppInvitationTextForm: ResendMoverAppInvitationTextForm) {
    response: resendMoverAppInvitationText(resendMoverAppInvitationTextForm: $resendMoverAppInvitationTextForm) {
      ${gql.errors}
      ok
    }
  }
`;

export default useResendMoverAppInvitationTextMutation;
