// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {DropdownInput as BaseDropdownInput, Icon, Styled} from '@supermove/components';
import {useMountEffect, useResponsive, useSheet} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import DropdownSheet from '@shared/design/components/DropdownInput/components/DropdownSheet';

const Button = Styled.ButtonV2`
`;

const DropdownIndicatorComponent = ({responsive, isDisabled}: any) => {
  return (
    <Icon
      source={Icon.ChevronDown}
      size={responsive.desktop ? 14 : 16}
      color={isDisabled ? colors.gray.disabled : colors.gray.secondary}
      style={{marginLeft: 4, marginRight: 4, marginTop: 2}}
    />
  );
};

const getSelectedSectionedOption = ({value, options}: any) => {
  const optionSection = _.find(options, (section) =>
    _.find(_.find(section.options, (option) => option.value === value) || ''),
  );
  return optionSection ? _.find(optionSection.options, (option) => option.value === value) : '';
};

const getFilterConfig = ({additionalSearchKeys}: any) => {
  return {
    // This tells react-select what value to search options on
    // @ts-expect-error TS(7031): Binding element 'dropdownOption' implicitly has an... Remove this comment to see the full error message
    stringify: ({data: dropdownOption}) => {
      return `${dropdownOption.label}${additionalSearchKeys.reduce(
        (acc: any, key: any) => `${acc} ${_.get(dropdownOption, key)}`,
        '',
      )}`;
    },
  };
};

const getHasMoreThanFourOptions = ({options, isSectionedList}: any) => {
  if (isSectionedList) {
    const allOptions = _.flatMap(options, (section) => section.options);
    return _.size(allOptions) > 4;
  }
  return _.size(options) > 4;
};

const SheetButtonWrapper = ({
  responsive,
  mobileSheet,
  isDisabled,
  isResponsiveSheet,
  children,
}: any) => {
  if (!responsive.desktop && isResponsiveSheet) {
    return (
      <Button onPress={mobileSheet.handleOpen} disabled={isDisabled}>
        {children}
      </Button>
    );
  }

  return children;
};

const DropdownInput = ({
  // Shared props for both mobile and desktop
  name,

  value,
  onChangeValue,
  setFieldValue,
  onInputChange,
  isClearable,
  isDisabled,
  isLoading,
  isSearchable,
  isResponsiveSheet,
  showDescriptionInOption,
  options,
  placeholder,

  // Mobile sheet only props
  label,

  // Customization props
  style,

  isSingleOptionSelected,
  additionalSearchKeys,

  // DropdownInput through props
  ...props
}: any) => {
  const mobileSheet = useSheet({name: 'Mobile Dropdown Sheet', enableTracking: false});
  const responsive = useResponsive();
  const isSectionedList = options[0]?.options;

  useMountEffect(() => {
    if (isSingleOptionSelected && _.size(options) === 1) {
      const option = options[0];
      const prevValue = null;
      const newValue = option ? option.value : null;
      setFieldValue(name, newValue);
      onChangeValue(newValue, option, prevValue);
    }
  });

  return (
    <React.Fragment>
      <SheetButtonWrapper
        responsive={responsive}
        mobileSheet={mobileSheet}
        isResponsiveSheet={isResponsiveSheet}
        isDisabled={isDisabled}
      >
        <BaseDropdownInput
          {...props}
          name={name}
          value={value}
          onChangeValue={onChangeValue}
          setFieldValue={setFieldValue}
          onInputChange={onInputChange}
          isClearable={isClearable}
          isLoading={isLoading}
          showDescriptionInOption={showDescriptionInOption}
          options={options}
          disabled={isDisabled}
          isOptionDisabled={(option: any) => option.isDisabled}
          // Customizations
          placeholder={isDisabled ? '' : placeholder}
          selectedOption={isSectionedList ? getSelectedSectionedOption({value, options}) : null}
          filterOption={
            _.some(additionalSearchKeys)
              ? DropdownInput.createFilter(getFilterConfig({additionalSearchKeys}))
              : null
          }
          fontSize={responsive.desktop ? 14 : 16}
          style={{
            height: responsive.desktop ? 36 : 48,
            ...style,
          }}
          DropdownIndicatorComponent={() => (
            <DropdownIndicatorComponent responsive={responsive} isDisabled={isDisabled} />
          )}
          optionStyle={isSectionedList ? {paddingLeft: 24} : null}
          menuIsOpen={responsive.desktop || !isResponsiveSheet ? undefined : false}
          isSearchable={isSearchable && responsive.desktop}
        />
      </SheetButtonWrapper>
      <DropdownSheet
        key={mobileSheet.key}
        isOpen={mobileSheet.isOpen}
        handleClose={mobileSheet.handleClose}
        headerText={label}
        options={options}
        name={name}
        value={value}
        onChangeValue={onChangeValue}
        setFieldValue={setFieldValue}
        onInputChange={onInputChange}
        // @ts-expect-error TS(2322): Type '{ key: any; isOpen: any; handleClose: any; h... Remove this comment to see the full error message
        isClearable={isClearable}
        isLoading={isLoading}
        isSearchable={isSearchable && getHasMoreThanFourOptions({options, isSectionedList})}
        showDescriptionInOption={showDescriptionInOption}
        additionalSearchKeys={additionalSearchKeys}
      />
    </React.Fragment>
  );
};

DropdownInput.createFilter = BaseDropdownInput.createFilter;
DropdownInput.OptionWithDescription = BaseDropdownInput.OptionWithDescription;
DropdownInput.OptionWithDescriptionOnRight = BaseDropdownInput.OptionWithDescriptionOnRight;
DropdownInput.OptionContainer = BaseDropdownInput.OptionContainer;
DropdownInput.SingleValueContainer = BaseDropdownInput.SingleValueContainer;
DropdownInput.Option = BaseDropdownInput.Option;
DropdownInput.OptionLabel = BaseDropdownInput.OptionLabel;
DropdownInput.OptionSecondaryLabel = BaseDropdownInput.OptionSecondaryLabel;

// Design system properties
DropdownInput.getSelectedSectionedOption = getSelectedSectionedOption;
DropdownInput.DropdownIndicatorComponent = DropdownIndicatorComponent;
DropdownInput.SheetButtonWrapper = SheetButtonWrapper;

// --------------------------------------------------
// Props
// --------------------------------------------------
DropdownInput.propTypes = {
  // Shared props for both mobile and desktop
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
  onChangeValue: PropTypes.func,
  setFieldValue: PropTypes.func,
  onInputChange: PropTypes.func,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isResponsiveSheet: PropTypes.bool,
  isSearchable: PropTypes.bool,
  showDescriptionInOption: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      handleAction: PropTypes.func,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          handleAction: PropTypes.func,
        }),
      ),
    }),
  ).isRequired,

  // Mobile sheet only props
  label: PropTypes.string,

  // Customization props
  isSingleOptionSelected: PropTypes.bool,
  style: PropTypes.object,
  additionalSearchKeys: PropTypes.arrayOf(PropTypes.string),
};

DropdownInput.defaultProps = {
  // Shared props for both mobile and desktop
  name: null,
  value: '',
  onChangeValue: () => {},
  setFieldValue: () => {},
  onInputChange: () => {},
  isClearable: false,
  isDisabled: false,
  isLoading: false,
  isResponsiveSheet: true,
  isSearchable: true,
  showDescriptionInOption: false,

  // Mobile sheet only props
  label: null,

  // Customization props
  isSingleOptionSelected: false,
  style: {},
  additionalSearchKeys: [],
};

export default DropdownInput;
