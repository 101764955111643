// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CollectionForm from '@shared/modules/Inventory/forms/CollectionForm';

const useUpdateCollectionMutationWithCollectionFragment = ({
  collectionForm,
  collectionFragment,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      collectionForm: CollectionForm.toForm(collectionForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: getMutation({collectionFragment}),
    variables: {
      collectionForm: CollectionForm.toMutation(form.values.collectionForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const getMutation = ({collectionFragment}) => {
  return gql`
    ${collectionFragment}
    mutation useUpdateCollectionMutationWithCollectionFragment($collectionForm: CollectionForm!) {
      response: updateCollection(collectionForm: $collectionForm) {
        ${gql.errors}
        collection {
          id
          ...collectionFragment
        }
      }
    }
  `;
};

export default useUpdateCollectionMutationWithCollectionFragment;
