// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useUnfinalizeBulkInvoicesMutation = ({invoiceIds, onSuccess, onError}) => {
  const form = useForm({
    enableReinitialize: true,
    initialValues: {
      invoiceIds,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUnfinalizeBulkInvoicesMutation.mutation,
    variables: {
      invoiceIds: form.values.invoiceIds,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUnfinalizeBulkInvoicesMutation.mutation = gql`
  mutation useUnfinalizeBulkInvoicesMutation($invoiceIds: [Int]!) {
    response: unfinalizeBulkInvoices(invoiceIds: $invoiceIds) {
      ${gql.errors}
    }
  }
`;

export default useUnfinalizeBulkInvoicesMutation;
