// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Shared
import SurveyMethodForm from '@shared/modules/Inventory/forms/SurveyMethodForm';

const edit = withFragment(
  (surveyMethods) => ({
    surveyMethodForms: surveyMethods.map((surveyMethod) => {
      return SurveyMethodForm.edit(surveyMethod);
    }),
  }),
  gql`
    ${SurveyMethodForm.edit.fragment}
    fragment ReorderSurveyMethodsForm_edit on SurveyMethod {
      id
      ...SurveyMethodForm_edit
    }
  `,
);

const toForm = ({surveyMethodForms}) => ({
  surveyMethodForms: surveyMethodForms.map((surveyMethodForm) => {
    return SurveyMethodForm.toForm(surveyMethodForm);
  }),
});

const toMutation = ({surveyMethodForms}) => ({
  surveyMethodForms: surveyMethodForms.map((surveyMethodForm) => {
    return SurveyMethodForm.toMutation(surveyMethodForm);
  }),
});

const ReorderSurveyMethodsForm = {
  edit,
  toForm,
  toMutation,
};

export default ReorderSurveyMethodsForm;
