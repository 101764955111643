// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {BillItem} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

const Row = Styled.View`
  flex-direction: row;
`;

const SplitRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const BillItemName = Styled.Text`
  ${Typography.Body3}
  flex: 4;
`;

const BillItemDetailContainer = Styled.View`
  align-items: flex-end;
  flex: ${(props) => props.flex};
`;

const BillItemDetailText = Styled.Text`
  ${Typography.Body3}
  color: ${(props) => props.color};
  text-align: right;
`;

const MobileBillItemText = Styled.Text`
  ${Typography.Body3}
  color: ${(props) => props.color};
`;

const AlignRightColumn = Styled.View`
  flex: 8;
  align-items: flex-end;
`;

const BillItemDetail = ({icon, children, color, flex}) => {
  return (
    <BillItemDetailContainer flex={flex}>
      <Row>
        {icon}
        <BillItemDetailText color={color}>{children}</BillItemDetailText>
      </Row>
    </BillItemDetailContainer>
  );
};

const DiscountTag = () => {
  return (
    <React.Fragment>
      <Icon
        source={Icon.Tag}
        size={10}
        // TODO(dan) Find a cross platform solution for rotating icons
        style={{transform: 'rotate(90deg)', marginTop: 5}}
        color={colors.green.status}
      />
      <Space width={4} />
    </React.Fragment>
  );
};

const BillBillItem = ({billItem, bill}) => {
  const responsive = useResponsive();
  const billItemTotal = BillItem.getEstimateTotalPostSubtotal(billItem, {
    isEstimateAvailable: bill.isEstimateAvailable,
  });
  const amountColor = billItem.isCredit ? colors.green.status : colors.gray.primary;
  if (responsive.mobile) {
    return (
      <SplitRow>
        <MobileBillItemText color={colors.gray.primary}>{billItem.name}</MobileBillItemText>
        <Row>
          {billItem.isCredit && <DiscountTag />}
          <MobileBillItemText color={amountColor}>{billItemTotal}</MobileBillItemText>
        </Row>
      </SplitRow>
    );
  }
  return (
    <Row>
      <AlignRightColumn>
        <BillItemName>{billItem.name}</BillItemName>
      </AlignRightColumn>
      <BillItemDetail
        flex={3}
        color={amountColor}
        icon={billItem.isCredit ? <DiscountTag /> : null}
      >
        {billItemTotal}
      </BillItemDetail>
    </Row>
  );
};

const BillsListItemPostSubtotalBillItemsListV1 = ({bill}) => {
  return (
    <React.Fragment>
      {bill.billItemsPostSubtotal.map((billItem, index) => {
        return (
          <React.Fragment key={`${index}_BILL_ITEM_POST_SUBTOTAL`}>
            <Space height={4} />
            <BillBillItem billItem={billItem} bill={bill} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillsListItemPostSubtotalBillItemsListV1.fragment = gql`
  ${BillItem.getEstimateTotalPostSubtotal.fragment}

  fragment BillsListItemPostSubtotalBillItemsListV1 on Bill {
    id
    isEstimateAvailable
    billItemsPostSubtotal {
      name
      isCredit
      ...BillItem_getEstimateTotalPostSubtotal
    }
  }
`;

export default BillsListItemPostSubtotalBillItemsListV1;
