// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import DashboardCategory from '@shared/modules/Reports/enums/DashboardCategory';

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const ReportDashboardModalFields = ({form, field}) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        name={`${field}.name`}
        label={'Report Name'}
        isRequired
        input={{
          placeholder: 'Enter name',
          required: !_.get(form.values, `${field}.name`),
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        name={`${field}.exploDashboardEmbedId`}
        label={'Embed Explo ID'}
        isRequired
        input={{
          placeholder: 'Enter Explo ID',
          required: !_.get(form.values, `${field}.exploDashboardEmbedId`),
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        name={`${field}.category`}
        label={'Report Category'}
        tooltip={
          'By selecting a category, this report will appear within that section of Supermove, otherwise, it will appear in the Reports Tab.'
        }
        isRequired
        component={DropdownInput}
        input={{
          required: !_.get(form.values, `${field}.category`),
          options: [
            {label: 'General', value: DashboardCategory.GENERAL},
            {label: 'Accounting', value: DashboardCategory.ACCOUNTING},
          ],
          isPortaled: true,
          style: {flex: 1},
          setFieldValue: form.setFieldValue,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        name={`${field}.exploVariables`}
        label={'Explo Variables'}
        tooltip={'JSON of custom Explo variables passed in to this report.'}
        component={TextInput}
        input={{
          placeholder: '{"foo": "abc"}',
          required: false,
          multiline: true,
          style: {paddingVertical: 9, minHeight: 120},
        }}
      />
      <Space height={16} />
    </React.Fragment>
  );
};

export default ReportDashboardModalFields;
