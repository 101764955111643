// Supermove
import {gql} from '@supermove/graphql';
import {Currency, Float, withFragment} from '@supermove/utils';

const edit = withFragment(
  (commercialMaterial) => ({
    commercialMaterialId: commercialMaterial.id,
    identifier: commercialMaterial.identifier,
    name: commercialMaterial.name,
    quantityRequested: commercialMaterial.quantityRequested,
    quantityDelivered: commercialMaterial.quantityDelivered,
    price: commercialMaterial.price,
    commercialCatalogId: commercialMaterial.commercialCatalogId,
  }),
  gql`
    fragment CommercialMaterialForm_edit on CommercialMaterial {
      id
      identifier
      name
      quantityRequested
      quantityDelivered
      price
      commercialCatalogId
    }
  `,
);

const _new = ({commercialCatalogId, commercialMaterialTypeId, name, price}) => ({
  commercialMaterialId: undefined,
  identifier: undefined,
  name,
  price,
  quantityRequested: 0,
  quantityDelivered: 0,
  commercialCatalogId,
  commercialMaterialTypeId,
});

const toForm = ({
  commercialMaterialId,
  identifier,
  name,
  quantityRequested,
  quantityDelivered,
  price,
  commercialCatalogId,
  commercialMaterialTypeId,
}) => ({
  commercialMaterialId,
  identifier,
  name,
  quantityRequested: Float.toForm(quantityRequested),
  quantityDelivered: Float.toForm(quantityDelivered),
  price: Currency.toForm(price),
  commercialCatalogId,
  commercialMaterialTypeId,
});

const toMutation = ({
  commercialMaterialId,
  identifier,
  name,
  quantityRequested,
  quantityDelivered,
  price,
  commercialCatalogId,
  commercialMaterialTypeId,
}) => ({
  commercialMaterialId,
  identifier,
  name,
  quantityRequested: Float.toMutation(quantityRequested),
  quantityDelivered: Float.toMutation(quantityDelivered),
  price: Currency.toMutation(price),
  commercialCatalogId,
  commercialMaterialTypeId,
});

const CommercialMaterialForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default CommercialMaterialForm;
