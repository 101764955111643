// Libraries
import React from 'react';

// Supermove
import {Styled, Icon, Popover, Space} from '@supermove/components';
import {useModal, usePopover, useHover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import useDeleteReportDashboardMutation from '@shared/modules/Reports/hooks/useDeleteReportDashboardMutation';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import EditReportDashboardModal from 'modules/Organization/Settings/Reports/components/EditReportDashboardModal';

const ItemContainer = Styled.View`
  background-color: ${colors.white};
  max-width: 464px;
  border-radius: 8px;
  padding: 12px 16px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  z-index: ${(props) => 100 - props.index};
  flex: 1;
`;

const ReportDashboardText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.black};
`;

const ActionButton = Styled.ButtonV2`
  min-width: 16px;
  align-items: center;
`;

const IconButton = Styled.ButtonV2`
  padding-horizontal: 8px;
`;

const MenuItemButton = Styled.ButtonV2`
  background-color: ${({isHighlighted}) => (isHighlighted ? colors.hover : 'transparent')};
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  padding-vertical: 8px;
  padding-horizontal: 16px;
`;

const Container = Styled.View`
  flex-direction: row;
  flex: 1;
  /* Must use padding here to get spacing between components in drag and drop list */
  /* Drag and drop does some weird stuff with the Space component so it does not show up properly */
  padding-vertical: 4px;
`;

const ActionContainer = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const MenuItem = ({onPress, children}) => {
  const {isHovered, ref} = useHover();
  return (
    <MenuItemButton onPress={onPress} isHighlighted={isHovered} ref={ref}>
      <MenuItemText>{children}</MenuItemText>
    </MenuItemButton>
  );
};

const ActionsMenuPopover = ({popover, onDelete}) => {
  return (
    <Popover
      placement={Popover.Positions.Right}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[10, 0]}
    >
      <ResponsivePopover.StaticContainer width={160}>
        <Space height={8} />
        <MenuItem
          onPress={() => {
            onDelete();
            popover.handleClose();
          }}
        >
          Delete
        </MenuItem>
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const EllipseButton = ({reportDashboardForm, refetch}) => {
  const deleteReportDashboardModal = useModal({name: 'Delete Report Dashboard Modal'});
  const actionsPopover = usePopover();
  const {handleSubmit} = useDeleteReportDashboardMutation({
    reportDashboardForm,
    onSuccess: () => {
      refetch();
    },
    onError: () => {},
  });
  return (
    <React.Fragment>
      <Popover.Content innerRef={actionsPopover.ref}>
        <ActionButton onPress={actionsPopover.handleToggle}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </ActionButton>
      </Popover.Content>
      <ActionsMenuPopover
        popover={actionsPopover}
        onDelete={deleteReportDashboardModal.handleOpen}
      />
      <DeleteModal
        key={deleteReportDashboardModal.key}
        title={`Delete ${reportDashboardForm.name}`}
        subtitle={`You can't undo this action.`}
        isOpen={deleteReportDashboardModal.isOpen}
        handleClose={deleteReportDashboardModal.handleClose}
        handleDelete={handleSubmit}
      />
    </React.Fragment>
  );
};

const ReportDashboardItem = ({reportDashboardForm, index, refetch}) => {
  const editReportDashboardModal = useModal({name: 'Edit Report Dashboard Modal'});
  return (
    <React.Fragment>
      <Container>
        <ItemContainer index={index}>
          <ReportDashboardText>{reportDashboardForm.name}</ReportDashboardText>
          <ActionContainer>
            <IconButton onPress={editReportDashboardModal.handleOpen}>
              <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
            </IconButton>
            <EllipseButton reportDashboardForm={reportDashboardForm} refetch={refetch} />
          </ActionContainer>
        </ItemContainer>
      </Container>
      <EditReportDashboardModal
        key={editReportDashboardModal.key}
        isOpen={editReportDashboardModal.isOpen}
        handleClose={editReportDashboardModal.handleClose}
        reportDashboardForm={reportDashboardForm}
        refetch={refetch}
        editReportDashboardModal={editReportDashboardModal}
      />
    </React.Fragment>
  );
};

export default ReportDashboardItem;
