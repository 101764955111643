// Libararies
import React from 'react';

// Supermove
import {Popover, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, usePopover} from '@supermove/hooks';
import {Location} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Table from '@shared/design/components/Table';
import AssignDriverAndTrucksToTripForm from '@shared/modules/Dispatch/forms/AssignDriverAndTrucksToTripForm';
import useAssignDriverAndTrucksToTripMutation from '@shared/modules/Dispatch/hooks/useAssignDriverAndTrucksToTripMutation';
import AssignDriverPopover from 'modules/Dispatch/LongDistance/components/AssignDriverPopover';
import AssignTrucksPopover from 'modules/Dispatch/LongDistance/components/AssignTrucksPopover';
import EditTripStatusDropdown from 'modules/Dispatch/LongDistance/components/EditTripStatusDropdown';

const HeaderText = Styled.Text`
  ${Typography.Label3}
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const Container = Styled.View`
  flex: 1;
`;

const LinkButton = Styled.ButtonV2`
`;

const LinkButtonText = Styled.Text`
  ${Typography.Link2}
  text-decoration: underline;
  text-decoration-color: ${colors.blue.interactive};
`;

const DriverColumn = ({trip, refetch}) => {
  const assignDriverAndTrucksPopover = usePopover({name: 'Assign Driver and Trucks Popover'});
  const assignDriverAndTrucksToTripForm = AssignDriverAndTrucksToTripForm.edit({trip});
  const {form, handleSubmit} = useAssignDriverAndTrucksToTripMutation({
    assignDriverAndTrucksToTripForm,
    onSuccess: () => {
      refetch();
      assignDriverAndTrucksPopover.handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  return trip.driver ? (
    <Text>{trip.driver.fullName}</Text>
  ) : (
    <Container style={{width: 'fit-content'}}>
      <Popover.Content innerRef={assignDriverAndTrucksPopover.ref}>
        <LinkButton
          style={{width: 'fit-content'}}
          onPress={assignDriverAndTrucksPopover.handleOpen}
        >
          <LinkButtonText>Assign Driver</LinkButtonText>
        </LinkButton>
      </Popover.Content>
      <AssignDriverPopover
        assignDriverAndTrucksPopover={assignDriverAndTrucksPopover}
        form={form}
        handleSubmit={handleSubmit}
      />
    </Container>
  );
};

const TrucksColumn = ({trip, refetch}) => {
  const assignDriverAndTrucksPopover = usePopover({name: 'Assign Driver and Trucks Popover'});
  const assignDriverAndTrucksToTripForm = AssignDriverAndTrucksToTripForm.edit({trip});
  const {form, handleSubmit} = useAssignDriverAndTrucksToTripMutation({
    assignDriverAndTrucksToTripForm,
    onSuccess: () => {
      refetch();
      assignDriverAndTrucksPopover.handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  const truckNames = trip.tripSegments[0].trucks.map((truck) => truck.name).join(', ');
  return truckNames ? (
    <Table.CellText>{truckNames}</Table.CellText>
  ) : (
    <Container style={{width: 'fit-content'}}>
      <Popover.Content innerRef={assignDriverAndTrucksPopover.ref}>
        <LinkButton
          style={{width: 'fit-content'}}
          onPress={assignDriverAndTrucksPopover.handleOpen}
        >
          <LinkButtonText>Assign Truck(s)</LinkButtonText>
        </LinkButton>
      </Popover.Content>
      <AssignTrucksPopover
        assignDriverAndTrucksPopover={assignDriverAndTrucksPopover}
        form={form}
        handleSubmit={handleSubmit}
      />
    </Container>
  );
};

const getTripsTableColumnDefinitions = ({refetchTableData, refetchTabData, navigator}) => {
  return [
    // Status
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Status</HeaderText>;
      },
      cellContent: ({item: trip}) => {
        return (
          <EditTripStatusDropdown
            trip={trip}
            onSuccess={() => {
              refetchTabData();
              refetchTableData();
            }}
          />
        );
      },
    },
    // Trip
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Trip Name</HeaderText>;
      },
      cellContent: ({item: trip}) => {
        // TODO(Dathan): Update text to link to trip once we have the path
        return (
          <Container style={{width: 'fit-content'}}>
            <LinkButton
              style={{width: 'fit-content'}}
              onPress={() => navigator.push(`/dispatch/long-distance/trips/${trip.uuid}/shipments`)}
            >
              <LinkButtonText>{trip.name}</LinkButtonText>
            </LinkButton>
          </Container>
        );
      },
    },
    // Driver
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Driver</HeaderText>;
      },
      cellContent: ({item: trip}) => {
        return <DriverColumn trip={trip} refetch={refetchTableData} />;
      },
    },
    // Truck
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Truck(s)</HeaderText>;
      },
      cellContent: ({item: trip}) => {
        return <TrucksColumn trip={trip} refetch={refetchTableData} />;
      },
    },
    // Start Date
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Start Date</HeaderText>;
      },
      cellContent: ({item: trip}) => {
        return (
          <Text>
            {trip.startDate ? Datetime.convertToDisplayDate(trip.startDate, 'M/D/YY') : '-'}
          </Text>
        );
      },
    },
    // End Date
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>End Date</HeaderText>;
      },
      cellContent: ({item: trip}) => {
        return (
          <Text>{trip.endDate ? Datetime.convertToDisplayDate(trip.endDate, 'M/D/YY') : '-'}</Text>
        );
      },
    },
    // Total Weight
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Total Weight</HeaderText>;
      },
      cellContent: ({item: trip}) => {
        return <Text>{trip.totalWeight} lbs</Text>;
      },
    },
    // Total Volume
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Total Volume</HeaderText>;
      },
      cellContent: ({item: trip}) => {
        return <Text>{trip.totalVolume} ft³</Text>;
      },
    },
    // Origin
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Origin</HeaderText>;
      },
      cellContent: ({item: trip}) => {
        return <Text>{Location.getDisplayCityStateZip(trip.startLocation, '-')}</Text>;
      },
    },
    // Destination
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Destination</HeaderText>;
      },
      cellContent: ({item: trip}) => {
        return <Text>{Location.getDisplayCityStateZip(trip.endLocation, '-')}</Text>;
      },
    },
  ];
};

const TripsTable = ({trips, refetchTabData, refetchTableData, loading}) => {
  const {navigator} = useNavigationDOM();
  const tripsTableColumnDefinitions = getTripsTableColumnDefinitions({
    refetchTabData,
    refetchTableData,
    navigator,
  });

  return (
    <Container style={{minWidth: 1200}}>
      <Table.FixedHeaderScroll
        columnDefinitions={tripsTableColumnDefinitions}
        emptyStateText='No trips'
        items={trips}
        itemKey={'id'}
        loading={loading}
        isDense
        scrollViewContentContainerStyle={{paddingBottom: 340}}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TripsTable.fragment = gql`
  ${AssignDriverAndTrucksToTripForm.edit.fragment}
  ${Location.getDisplayCityStateZip.fragment}
  fragment TripsTable on Trip {
    id
    uuid
    status
    startDate
    endDate
    totalWeight
    totalVolume
    driver {
      id
      fullName
    }
    tripSegments {
      id
      trucks {
        id
        name
      }
    }
    startLocation {
      id
      ...Location_getDisplayCityStateZip
    }
    endLocation {
      id
      ...Location_getDisplayCityStateZip
    }
    ...AssignDriverAndTrucksToTripForm_edit
  }
`;

export default TripsTable;
