// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useToggle} from '@supermove/hooks';
import {Payment} from '@supermove/models';
import {colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import EmptyState from '@shared/design/components/EmptyState';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import ProjectTypePaymentMethod from '@shared/modules/Project/enums/ProjectTypePaymentMethod';
import ProjectTypeBillingSettingsForm from '@shared/modules/Project/forms/ProjectTypeBillingSettingsForm';
import useUpdateProjectTypePaymentSettingsMutation from '@shared/modules/Project/hooks/useUpdateProjectTypePaymentSettingsMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import Switch from 'modules/App/components/Switch';
import ProjectTypeSettingsPageHeader from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSettingsPageHeader';

const View = Styled.View`
  flex: 1;
`;

const PageContentContainer = Styled.View`
  flex: 1;
  padding-left: 24px;
  background-color: ${colors.gray.background};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const EmptyPaymentSettingsBody = ({onPrimaryAction}) => {
  return (
    <EmptyState
      title={'No payment methods added.'}
      message={'To get started, add a payment method.'}
      primaryActionIcon={Icon.Plus}
      primaryActionText={'Add Payment Method'}
      handlePrimaryAction={onPrimaryAction}
    />
  );
};

const ShowPaymentSettingsPanel = ({projectType}) => {
  return (
    <Row>
      <View>
        <EditPanel.LabelText>Crew App</EditPanel.LabelText>
        <EditPanel.Text style={{color: colors.gray.secondary}}>
          Payment methods for the Crew App.
        </EditPanel.Text>
        <Space height={16} />
        {projectType.crewPaymentMethods.map((method, index) => (
          <Row key={index} style={{paddingBottom: '8px'}}>
            <EditPanel.Text>{Payment.getSettingsDisplayMethod({method})}</EditPanel.Text>
          </Row>
        ))}
      </View>
    </Row>
  );
};

const EditPaymentSettingsPanel = ({projectType, form}) => {
  const paymentMethods = [
    ProjectTypePaymentMethod.CASH,
    ProjectTypePaymentMethod.CHECK,
    ProjectTypePaymentMethod.EXTERNAL,
    ProjectTypePaymentMethod.PAYPAL,
    ProjectTypePaymentMethod.VENMO,
    ProjectTypePaymentMethod.INVOICE,
    ...List.insertIf(
      projectType.organization.features.isEnabledAuthorizeDotNetExternalPayment,
      ProjectTypePaymentMethod.AUTHORIZE_DOT_NET,
    ),
    ...List.insertIf(
      projectType.organization.payengineMerchant?.isProcessingEnabled,
      ProjectTypePaymentMethod.PAYENGINE_CREDIT_CARD,
      ProjectTypePaymentMethod.PAYENGINE_SAVE_CARD,
    ),
  ];

  const {crewPaymentMethods} = form.values.projectTypeBillingSettingsForm;
  const setPaymentMethod = (method) => {
    const newPaymentMethods = _.xor(crewPaymentMethods, [method]);
    form.setFieldValue('projectTypeBillingSettingsForm.crewPaymentMethods', newPaymentMethods);
  };

  return (
    <Row>
      <View>
        <EditPanel.LabelText>Crew App</EditPanel.LabelText>
        <EditPanel.Text style={{color: colors.gray.secondary}}>
          Payment methods for the Crew App.
        </EditPanel.Text>
        <Space height={16} />
        {paymentMethods.map((method, index) => (
          <Row key={index} style={{paddingBottom: '8px'}}>
            <Switch
              isOn={_.includes(crewPaymentMethods, method)}
              onChange={() => setPaymentMethod(method)}
            />
            <Space width={8} />
            <EditPanel.Text>{Payment.getSettingsDisplayMethod({method})}</EditPanel.Text>
          </Row>
        ))}
      </View>
    </Row>
  );
};

const PaymentMethodSection = ({projectType, refetch}) => {
  const editPaymentSettingsToggle = useToggle();
  const projectTypeBillingSettingsForm = ProjectTypeBillingSettingsForm.edit(projectType);
  const {form, submitting, handleSubmit} = useUpdateProjectTypePaymentSettingsMutation({
    projectTypeBillingSettingsForm,
    onSuccess: () => {
      editPaymentSettingsToggle.handleToggleOff();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      index={0}
      bodyComponentProps={{projectType}}
      editBodyComponentProps={{projectType, form}}
      emptyBodyComponentProps={{onPrimaryAction: editPaymentSettingsToggle.handleToggleOn}}
      EmptyBodyComponent={EmptyPaymentSettingsBody}
      isEmpty={!projectType.crewPaymentMethods.length && !editPaymentSettingsToggle.isOn}
      BodyComponent={ShowPaymentSettingsPanel}
      EditBodyComponent={EditPaymentSettingsPanel}
      title={'Payment Methods'}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editPaymentSettingsToggle.isOn}
      handleEdit={editPaymentSettingsToggle.handleToggleOn}
      handleClose={editPaymentSettingsToggle.handleToggleOff}
    />
  );
};

const ProjectTypePaymentSettingsContent = ({projectTypeUuid}) => {
  const {loading, data, refetch} = useQuery(ProjectTypePaymentSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectTypeUuid,
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <View>
      <ProjectTypeSettingsPageHeader projectType={data.projectTypeByUuid} />
      <PageContentContainer>
        <Space height={24} />
        <ScrollView>
          <PaymentMethodSection projectType={data.projectTypeByUuid} refetch={refetch} />
          <Space height={16} />
        </ScrollView>
        <Space height={48} />
      </PageContentContainer>
    </View>
  );
};

const ProjectTypePaymentSettingsPage = () => {
  const {params} = useNavigationDOM();

  return (
    <SidebarPageV2 selected={'settings'}>
      <ProjectTypePaymentSettingsContent projectTypeUuid={params.projectTypeUuid} />
    </SidebarPageV2>
  );
};

ProjectTypePaymentSettingsPage.query = gql`
  ${ProjectTypeSettingsPageHeader.fragment}
  ${ProjectTypeBillingSettingsForm.edit.fragment}

  query ProjectTypePaymentSettingsPage($projectTypeUuid: String!) {
    ${gql.query}

    projectTypeByUuid(projectTypeUuid: $projectTypeUuid) {
      id
      crewPaymentMethods
      organization {
        id
        payengineMerchant {
          id
          isProcessingEnabled
        }
        features {
          isEnabledAuthorizeDotNetExternalPayment: isEnabled(feature: "AUTHORIZE_DOT_NET_EXTERNAL_PAYMENT")
        }
      }
      ...ProjectTypeSettingsPageHeader
      ...ProjectTypeBillingSettingsForm_edit
    }
  }
`;

export default ProjectTypePaymentSettingsPage;
