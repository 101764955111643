// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import useDeleteClientMutation from '@shared/modules/Client/hooks/useDeleteClientMutation';
import DeleteModal from 'modules/App/components/DeleteModal';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import ClientChatButton from 'modules/Client/Show/components/ClientChatButton';
import EditClientModal from 'modules/Client/Show/components/EditClientModal';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.ButtonV2`
  padding-vertical: 8px;
`;

const MenuItemTouchable = Styled.Touchable`
  background-color: ${(props) => (props.isHovered ? colors.hover : 'transparent')};
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  padding-vertical: 4px;
  padding-horizontal: 12px;
  color: ${colors.gray.primary};
`;

const DeleteClientModal = ({isOpen, handleClose, clientId, refetch}) => {
  const {handleSubmit} = useDeleteClientMutation({
    clientId,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={'Are you sure you want to delete this client?'}
      subtitle={"You can't undo this action."}
      handleClose={handleClose}
      handleConfirm={handleSubmit}
    />
  );
};

const ActionMenuItem = ({onPress, children}) => {
  const {isHovered, ref} = useHover();

  return (
    <React.Fragment>
      <MenuItemTouchable onPress={onPress} isHovered={isHovered} ref={ref} activeOpacity={0.8}>
        <MenuItemText>{children}</MenuItemText>
      </MenuItemTouchable>
      <Space height={2} />
    </React.Fragment>
  );
};

const ClientActionsPopover = ({clientActionsPopover, deleteClientModal}) => {
  const handleOpenDeleteClientModal = () => {
    deleteClientModal.handleOpen();
    clientActionsPopover.handleClose();
  };
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={clientActionsPopover.isOpen}
      handleOpen={clientActionsPopover.handleOpen}
      handleClose={clientActionsPopover.handleClose}
      reference={clientActionsPopover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={120}>
        <Space height={8} />
        <ActionMenuItem onPress={handleOpenDeleteClientModal}>Delete</ActionMenuItem>
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const ClientActions = ({client, refetch, organization}) => {
  const editClientModal = useModal({name: 'EDIT_CLIENT_MODAL'});
  const deleteClientModal = useModal({name: 'DELETE_CLIENT_MODAL'});
  const clientActionsPopover = usePopover();
  return (
    <Row>
      <Button onPress={editClientModal.handleOpen}>
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </Button>
      <Space width={10} />
      {!organization.features.isEnabledClientMultibranchMigration ? (
        <Popover.Content innerRef={clientActionsPopover.ref}>
          <Button
            style={{paddingHorizontal: 10}}
            onPress={() => clientActionsPopover.handleToggle()}
          >
            <Icon
              source={Icon.EllipsisV}
              color={clientActionsPopover.isOpen ? colors.hover : colors.gray.secondary}
              size={16}
            />
          </Button>
        </Popover.Content>
      ) : (
        <ClientChatButton
          client={client}
          isLarge
          noText
          handleMissingPhoneNumber={editClientModal.handleOpen}
        />
      )}
      <EditClientModal
        key={editClientModal.key}
        isOpen={editClientModal.isOpen}
        handleClose={editClientModal.handleClose}
        clientUuid={client.uuid}
        refetch={refetch}
      />
      <DeleteClientModal
        key={deleteClientModal.key}
        isOpen={deleteClientModal.isOpen}
        handleClose={deleteClientModal.handleClose}
        clientId={client.id}
        refetch={refetch}
      />
      <ClientActionsPopover
        clientActionsPopover={clientActionsPopover}
        deleteClientModal={deleteClientModal}
        client={client}
        refetch={refetch}
      />
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClientActions.fragment = gql`
  ${ClientChatButton.fragment}

  fragment ClientActions on Client {
    id
    uuid
    organization {
      id
      features {
        isEnabledClientMultibranchMigration: isEnabled(feature: "CLIENT_MULTIBRANCH_MIGRATION")
      }
    }
    ...ClientChatButton
  }
`;

export default ClientActions;
