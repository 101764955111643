// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import BeginPaymentForm from '@shared/modules/Payment/forms/BeginPaymentForm';

const useBeginPaymentMutation = ({beginPaymentForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      beginPaymentForm: BeginPaymentForm.toForm(beginPaymentForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useBeginPaymentMutation.mutation,
    variables: {
      beginPaymentForm: BeginPaymentForm.toMutation(form.values.beginPaymentForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useBeginPaymentMutation.mutation = gql`
  mutation useBeginPaymentMutation($beginPaymentForm: BeginPaymentForm!) {
    response: beginPaymentV2(beginPaymentForm: $beginPaymentForm) {
      ${gql.errors}
      payment {
        id
        stripePaymentClientSecret
        bill {
          id
          total
          customer {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export default useBeginPaymentMutation;
