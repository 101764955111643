// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import DeleteAttachmentForm from '@shared/modules/File/forms/DeleteAttachmentForm';
import useDeleteAttachmentForm from '@shared/modules/File/hooks/useDeleteAttachmentForm';

const DeleteAttachmentModal = ({
  attachmentId,
  attachmentName,
  deleteAttachmentModal,
  onDeleteSuccess,
}) => {
  const deleteAttachmentForm = DeleteAttachmentForm.new({attachmentId});
  const {handleSubmit} = useDeleteAttachmentForm({
    deleteAttachmentForm,
    onSuccess: () => {
      deleteAttachmentModal.handleClose();
      onDeleteSuccess();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DeleteModal
      handleDelete={handleSubmit}
      handleClose={deleteAttachmentModal.handleClose}
      isOpen={deleteAttachmentModal.isOpen}
      title={`Remove "${attachmentName}"?`}
      subtitle={`You can't undo this action.`}
    />
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
DeleteAttachmentModal.propTypes = {
  attachmentId: PropTypes.string.isRequired,
  attachmentName: PropTypes.string.isRequired,
  deleteAttachmentModal: PropTypes.object.isRequired,
  onDeleteSuccess: PropTypes.func,
};

DeleteAttachmentModal.defaultProps = {
  onDeleteSuccess: () => {},
};

export default DeleteAttachmentModal;
