// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

import JobUserForm from './JobUserForm';

const newFromJob = withFragment(
  (job, {userId, appInstallId}) => ({
    jobId: job.id,
    userId,
    appInstallId,
    jobUserForms: job.jobUsers.map((jobUser) => JobUserForm.edit(jobUser)),
  }),
  gql`
    ${JobUserForm.edit.fragment}

    fragment JobConfirmCrewForm_newFromJob on Job {
      id
      jobUsers: activeJobUsers {
        id
        ...JobUserForm_edit
      }
    }
  `,
);

const toForm = ({jobId, userId, appInstallId, jobUserForms}) => ({
  jobId,
  userId,
  appInstallId,
  jobUserForms: jobUserForms.map((jobUserForm) => JobUserForm.toForm(jobUserForm)),
});

const toMutation = ({jobId, userId, appInstallId, jobUserForms}) => ({
  jobId,
  userId,
  appInstallId,
  jobUserForms: jobUserForms.map((jobUserForm) => JobUserForm.toMutation(jobUserForm)),
});

const JobConfirmCrewForm = {
  newFromJob,
  toForm,
  toMutation,
};

export default JobConfirmCrewForm;
