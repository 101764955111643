// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  CurrencyInput,
  DropdownInput,
  PercentInput,
  Icon,
  Modal,
  Space,
  Styled,
} from '@supermove/components';
import {useForm} from '@supermove/hooks';
import {BillItem} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import BillItemTypeKind from '@shared/modules/Billing/enums/BillItemTypeKind';
import BillStage from '@shared/modules/Billing/enums/BillStage';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const MobileContainer = Styled.View`
  background-color: ${colors.gray.background};
  flex: 1;
`;

const HeaderContainer = Styled.View`
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  padding-horizontal: 4px;
`;

const TitleText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.primary};
`;

const HeaderButton = Styled.ButtonV2`
  padding-horizontal: 12px;
  align-items: center;
`;

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const FieldsContainer = Styled.View`
`;

const IndexContainer = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const InputLabelText = Styled.Text`
  ${Typography.Label1}
  color: ${colors.gray.primary};
  padding-bottom: 4px;
`;

const QuantitySeparatorText = Styled.Text`
  ${Typography.Body2}
  color: ${colors.gray.primary};
  padding-horizontal: 8px;
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  height: 40px;
  padding-horizontal: 30px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;

const ButtonText = Styled.Text`
  ${Typography.Heading6}
  color: ${(props) => props.color};
`;

const ActivityIndicator = Styled.Loading`
`;

const validate = ({billItemForm}) => {
  return BillItemForm.validate({billItemForm, field: 'billItemForm'});
};

const handleChangeCategory = ({form, customBillItemForm}) => {
  form.setFieldValue('billItemForm', customBillItemForm);
};

const getCustomBillItemOptions = ({billId}) => {
  const options = BillItem.CUSTOM_BILL_ITEMS.map((customBillItem) => ({
    value: customBillItem.name,
    label: customBillItem.displayName,
    customBillItemForm: BillItemForm.new({...customBillItem, billId, name: ''}),
  }));
  return options;
};

const EditBillHeader = ({handleClose}) => {
  return (
    <HeaderContainer>
      <Space height={16} />
      <Row>
        <HeaderButton onPress={handleClose}>
          <Icon source={Icon.ArrowLeft} size={20} color={colors.gray.primary} />
        </HeaderButton>
        <TitleText>Add Custom Item</TitleText>
      </Row>
      <Space height={16} />
    </HeaderContainer>
  );
};

const CustomBillItemDropdown = ({billId, form, field}) => {
  return (
    <IndexContainer index={0}>
      <InputLabelText>Category</InputLabelText>
      <FieldInput
        {...form}
        component={DropdownInput}
        name={'selectedCustomBillItem'}
        input={{
          options: getCustomBillItemOptions({billId}),
          placeholder: 'Select category',
          setFieldValue: form.setFieldValue,
          required: !_.get(form.values, field),
          style: {
            flex: 1,
            borderColor: colors.gray.border,
          },
          onChangeValue: (value, {customBillItemForm}) =>
            handleChangeCategory({form, customBillItemForm}),
        }}
      />
    </IndexContainer>
  );
};

const BillItemName = ({form, field}) => {
  return (
    <IndexContainer index={1}>
      <InputLabelText>Name</InputLabelText>
      <FieldInput
        {...form}
        name={field}
        input={{
          placeholder: 'Enter name',
          required: !_.get(form.values, field),
        }}
      />
    </IndexContainer>
  );
};

const BillItemQuantity = ({form, field1, field2}) => {
  return (
    <IndexContainer index={3}>
      <InputLabelText>Quantity</InputLabelText>
      <Row>
        <FieldInput
          {...form}
          name={field1}
          input={{
            placeholder: 'Min',
          }}
          style={{flex: 1}}
        />
        <QuantitySeparatorText>to</QuantitySeparatorText>
        <FieldInput
          {...form}
          name={field2}
          input={{
            placeholder: 'Max',
          }}
          style={{flex: 1}}
        />
      </Row>
    </IndexContainer>
  );
};

const BillItemAmount = ({form, field}) => {
  const {billItemForm} = form.values;
  const isDiscount = BillItemForm.getIsDiscount(billItemForm);
  const isSupply = BillItemForm.getIsSupply(billItemForm);
  const isHourly = BillItemForm.getIsHourly(billItemForm);
  return (
    <IndexContainer index={3}>
      <InputLabelText>{isSupply || isHourly ? 'Price' : 'Amount'}</InputLabelText>
      <FieldInput
        {...form}
        name={field}
        component={CurrencyInput}
        input={{
          component: FieldInput.TextInput,
          placeholder: isDiscount ? '-$100.00' : '$100.00',
          required: !_.get(form.values, field),
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          keyboardType: 'default',
          options: {
            enableEmptyValues: true,
          },
        }}
      />
    </IndexContainer>
  );
};

const BillItemPercentage = ({form, field}) => {
  const isDiscount = BillItemForm.getIsDiscount(form.values.billItemForm);
  return (
    <IndexContainer index={3}>
      <InputLabelText>Amount</InputLabelText>
      <FieldInput
        {...form}
        name={field}
        component={PercentInput}
        input={{
          component: FieldInput.TextInput,
          placeholder: isDiscount ? '-10%' : '10%',
          required: !_.get(form.values, field),
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          keyboardType: 'default',
          options: {
            enableEmptyValues: true,
          },
        }}
      />
    </IndexContainer>
  );
};

const PreSubtotalBillItemFields = ({form}) => {
  const field = 'billItemForm';
  const isTaxable = _.get(form.values, `${field}.isTaxable`);
  return (
    <React.Fragment>
      <BillItemAmount form={form} field={`${field}.amount`} />
      <Space height={16} />
      <BillItemQuantity
        form={form}
        field1={`${field}.minQuantity`}
        field2={`${field}.maxQuantity`}
      />
      <Space height={16} />
      <Checkbox
        isChecked={isTaxable}
        handleToggle={() => form.setFieldValue(`${field}.isTaxable`, !isTaxable)}
        label={'Taxable'}
      />
    </React.Fragment>
  );
};

const PostSubtotalBillItemFields = ({form}) => {
  const {kind} = form.values.billItemForm;
  if (kind === BillItemTypeKind.AMOUNT) {
    return <BillItemAmount form={form} field={'billItemForm.amount'} />;
  }
  if (kind === BillItemTypeKind.PERCENTAGE) {
    return <BillItemPercentage form={form} field={'billItemForm.percentage'} />;
  }
  return null;
};

const EditBillFields = ({billId, form}) => {
  const {billStage} = form.values.billItemForm;
  return (
    <FieldsContainer>
      <Space height={20} />
      <CustomBillItemDropdown billId={billId} form={form} field={'billItemForm.kind'} />
      <Space height={16} />
      <BillItemName form={form} field={'billItemForm.name'} />
      <Space height={16} />
      {billStage === BillStage.PRE_SUBTOTAL ? (
        <PreSubtotalBillItemFields form={form} />
      ) : (
        <PostSubtotalBillItemFields form={form} />
      )}
      <Space height={16} />
    </FieldsContainer>
  );
};

const EditBillFooter = ({form, handleClose, submitting}) => {
  const disabled = !form.values.selectedCustomBillItem || submitting;
  return (
    <Actions>
      <Button color={colors.gray.background} onPress={handleClose} disabled={submitting}>
        <ButtonText color={colors.gray.tertiary}>Cancel</ButtonText>
      </Button>
      <Space width={8} />
      <Button
        // We wrap in a set timeout to ensure all fields complete any onBlur
        // logic before submitting the form.
        onPress={() => setTimeout(() => form.submitForm(), 0)}
        color={disabled ? colors.gray.border : colors.blue.interactive}
        disabled={disabled}
      >
        {submitting ? (
          <ActivityIndicator color={colors.white} size={'small'} />
        ) : (
          <ButtonText color={colors.white}>Done</ButtonText>
        )}
      </Button>
    </Actions>
  );
};

const EditBillCustomItemModal = ({billId, isOpen, handleClose, handleSubmit}) => {
  const form = useForm({
    initialValues: {
      billItemForm: BillItemForm.new({billId}),
      selectedCustomBilItem: null,
    },
    validate,
    onSubmit: () => {
      handleSubmit(form.values.billItemForm);
      handleClose();
    },
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <MobileContainer>
        <EditBillHeader handleClose={handleClose} />
        <Space height={16} />
        <ContentContainer>
          <IndexContainer index={0}>
            <EditBillFields billId={billId} form={form} />
          </IndexContainer>
          <Space height={16} />
          <IndexContainer index={1}>
            <EditBillFooter form={form} handleClose={handleClose} />
          </IndexContainer>
        </ContentContainer>
      </MobileContainer>
    </Modal.Content>
  );
};

export default EditBillCustomItemModal;
