// Supermove
import {Currency} from '@supermove/utils';

const MoveUserInput = {
  toForm: ({user, userId, jobId, moveId, timePayableAdjustment, tip}) => ({
    user,
    userId,
    jobId,
    moveId,
    timePayableAdjustment,
    tip: Currency.toForm(tip),
  }),

  toMutation: ({userId, jobId, moveId, timePayableAdjustment, tip}) => ({
    userId,
    jobId,
    moveId,
    timePayableAdjustment: timePayableAdjustment || 0,
    tip: Currency.toMutation(tip),
  }),

  create: ({user, jobId, moveId}) => ({
    user,
    userId: user.id,
    jobId,
    moveId,
    timePayableAdjustment: '0',
    tip: 0,
  }),
};

export default MoveUserInput;
