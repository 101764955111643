// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {BillRule} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

const FlexContainer = Styled.View`
  flex: ${(props) => props.flex};
`;

const CaptionText = Styled.H8`
  ${Typography.Responsive.Micro}
`;

const BillRuleContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) => (props.isMobile ? colors.blue.accent : colors.gray.background)};
  border-radius: 4px;
  padding: 8px;
`;

const BillRuleMainText = Styled.Text`
  ${Typography.Body}
`;

const getBillRules = ({bill, isEditable}) => {
  // If the bill is editable, then it is not a customer facing bill.
  // Only moving company facing bills are editable, so these bills
  // should not hide bill rules based on isVisibleToCustomer.
  if (isEditable) {
    return bill.billRules;
  }
  return _.filter(bill.billRules, 'isVisibleToCustomer');
};

const BillBillRule = ({billRule}) => {
  const responsive = useResponsive();
  return (
    <BillRuleContainer isMobile={responsive.mobile}>
      <FlexContainer flex={1}>
        <BillRuleMainText>{billRule.name}</BillRuleMainText>
        <CaptionText>{billRule.description}</CaptionText>
      </FlexContainer>
      <BillRuleMainText responsive={responsive}>
        {BillRule.getDisplayValues(billRule)}
      </BillRuleMainText>
    </BillRuleContainer>
  );
};

const BillsListItemBillRulesList = ({bill, isEditable}) => {
  const billRules = getBillRules({bill, isEditable});
  return (
    <React.Fragment>
      <Space height={8} />
      {billRules.map((billRule, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && <Space height={8} />}
            <BillBillRule billRule={billRule} />
          </React.Fragment>
        );
      })}
      {!isEditable && <Space height={16} />}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillsListItemBillRulesList.fragment = gql`
  ${BillRule.getDisplayValues.fragment}

  fragment BillsListItemBillRulesList on Bill {
    id
    billRules {
      name
      description
      values
      isVisibleToCustomer
      ...BillRule_getDisplayValues
    }
  }
`;

export default BillsListItemBillRulesList;
