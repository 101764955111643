/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import TextTooltip from 'modules/App/components/TextTooltip';

const Text = Styled.Text`
  ${({vars}) => (vars.isLarge ? Typography.Body : Typography.Micro)}
  color: ${colors.gray.secondary};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const IconContainer = Styled.View`
  width: 12px;
  align-items: center;
`;

const JobArrivalTime = ({job, showIcon, isLarge}) => {
  return (
    <Row>
      {showIcon && (
        <React.Fragment>
          <TextTooltip text={'Arrival Time'}>
            <IconContainer>
              <Icon
                source={Icon.Clock}
                size={12}
                color={colors.gray.secondary}
                style={{marginTop: 1}}
              />
            </IconContainer>
          </TextTooltip>
          <Space width={6} />
        </React.Fragment>
      )}
      {/* TODO(dan) Backend should handle saving the time for holds as 12am to 12pm. Then we can
      look at the time without depending on job.kind */}
      {job.kind === 'HOLD' ? (
        <Text numberOfLines={1} vars={{isLarge}}>
          All Day
        </Text>
      ) : (
        <Text numberOfLines={1} vars={{isLarge}}>
          {job.startTime1 ? Datetime.toDisplayTime(Datetime.fromTime(job.startTime1)) : 'TBD'}
          {` - `}
          {job.startTime2 ? Datetime.toDisplayTime(Datetime.fromTime(job.startTime2)) : 'TBD'}
        </Text>
      )}
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobArrivalTime.fragment = gql`
  fragment JobArrivalTime on Job {
    id
    kind
    startTime1
    startTime2
  }
`;

export default JobArrivalTime;
