// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ReorderProjectTypeRoomTypesForm from '@shared/modules/Inventory/forms/ReorderProjectTypeRoomTypesForm';

const useReorderProjectTypeRoomTypesMutation = ({
  reorderProjectTypeRoomTypesForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      reorderProjectTypeRoomTypesForm: ReorderProjectTypeRoomTypesForm.toForm(
        reorderProjectTypeRoomTypesForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useReorderProjectTypeRoomTypesMutation.mutation,
    variables: {
      reorderProjectTypeRoomTypesForm: ReorderProjectTypeRoomTypesForm.toMutation(
        form.values.reorderProjectTypeRoomTypesForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useReorderProjectTypeRoomTypesMutation.mutation = gql`
  mutation useReorderProjectTypeRoomTypesMutation($reorderProjectTypeRoomTypesForm: ReorderProjectTypeRoomTypesForm!) {
    response: reorderProjectTypeRoomTypes(reorderProjectTypeRoomTypesForm: $reorderProjectTypeRoomTypesForm) {
      ${gql.errors}
      projectTypeRoomTypes {
        id
      }
    }
  }
`;

export default useReorderProjectTypeRoomTypesMutation;
