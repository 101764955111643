// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useDeleteCommercialMaterialMutation = ({commercialMaterialId, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      commercialMaterialId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteCommercialMaterialMutation.mutation,
    variables: {
      commercialMaterialId: form.values.commercialMaterialId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteCommercialMaterialMutation.mutation = gql`
  mutation useDeleteCommercialMaterialMutation($commercialMaterialId: Int!) {
    response: deleteCommercialMaterial(commercialMaterialId: $commercialMaterialId) {
      ${gql.errors}
    }
  }
`;

export default useDeleteCommercialMaterialMutation;
