// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (compensationReport) => ({
    compensationReportId: compensationReport.id,
    userId: compensationReport.userId,
    status: compensationReport.status,
    isDeleted: compensationReport.isDeleted,

    // Private
    identifier: compensationReport.identifier,
  }),
  gql`
    fragment CompensationReportForm_edit on CompensationReport {
      id
      userId
      identifier
      status
      isDeleted
    }
  `,
);

const toForm = ({compensationReportId, userId, status, isDeleted, identifier}) => ({
  compensationReportId,
  userId,
  status,
  isDeleted,

  // Private
  identifier,
});

const toMutation = ({compensationReportId, userId, status, isDeleted}) => ({
  compensationReportId,
  userId,
  status,
  isDeleted,
});

const CompensationReportForm = {
  edit,
  toForm,
  toMutation,
};

export default CompensationReportForm;
