// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Shared
import ValueForm from '@shared/modules/Billing/forms//ValueForm';

const edit = withFragment(
  (project) => ({
    projectId: project.id,
    valueForms: [],
  }),
  gql`
    fragment UpdateValuesForm_edit on Project {
      id
    }
  `,
);

const toForm = ({projectId, valueForms}) => ({
  projectId,
  valueForms,
});

const toMutation = ({projectId, valueForms}) => ({
  projectId,
  valueForms: valueForms.map((valueForm) => {
    return ValueForm.toMutation(valueForm);
  }),
});

const handleSubmit = ({form, field, updateValuesObject, setIsSubmitting, handleSubmit}) => {
  /*
  NOTES(dan)
  1.  This submit logic relies on all onBlur handlers on inputs to run before submitting.
      requestAnimationFrame ensures that both desktop and mobile run the submit logic on
      the next repaint, at which all onBlur handlers would have been completed.
  2.  The updateValuesObject is used so that we don't need to rely on a form to cache
      updates. The ui will update per input as the user types, and we cache these updates
      in the updateValuesObject and set these values to our form when we submit it. Using
      a form (or useState) has proven to be buggy here as inputs did not reliably reflect
      the updated state.
  3.  setTimeout ensures that the handleSubmit runs after the form has been updated.
  4.  We need to manually handle the submitting state here because we've wrapped this
      component with React.memo, and it's setup to only rerender when it's opened or
      reopened. We need to do this because we're caching updates in a plain JS object and
      rerenders would reset the object. Because of this, we can't rely on the submitting
      property from the mutation.
  */
  requestAnimationFrame(() => {
    setIsSubmitting(true);

    const {activeElement} = document;
    if (activeElement && typeof activeElement.blur === 'function') {
      activeElement.blur();
    }

    form.setFieldValue(`${field}.valueForms`, _.values(updateValuesObject));
    setTimeout(async () => {
      await handleSubmit();
      setIsSubmitting(false);
    }, 0);
  });
};

const UpdateValuesForm = {
  // Initialize
  edit,

  // Serialize
  toForm,
  toMutation,

  handleSubmit,
};

export default UpdateValuesForm;
