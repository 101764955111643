/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';

// App
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import SettingsHeaderNavigation from 'modules/Organization/Settings/components/SettingsHeaderNavigation';

const BillingLibraryPageHeader = ({billingLibrary}) => {
  const {navigator} = useNavigationDOM();

  return (
    <StandardOfficeHeader title={''} showLine={false}>
      <SettingsHeaderNavigation>
        <SettingsHeaderNavigation.PreviousScreen onPress={() => navigator.push('/settings')}>
          Settings
        </SettingsHeaderNavigation.PreviousScreen>
        <SettingsHeaderNavigation.PreviousScreen
          onPress={() => navigator.push('/settings/billing/billing-libraries')}
        >
          Billing Libraries
        </SettingsHeaderNavigation.PreviousScreen>
        <SettingsHeaderNavigation.CurrentScreen>
          {billingLibrary.name}
        </SettingsHeaderNavigation.CurrentScreen>
      </SettingsHeaderNavigation>
    </StandardOfficeHeader>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryPageHeader.fragment = gql`
  fragment BillingLibraryPageHeader on BillingLibrary {
    id
    name
  }
`;

export default BillingLibraryPageHeader;
