// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import PreviewBillForm from '@shared/modules/Billing/forms/PreviewBillForm';

const usePreviewBillMutation = ({previewBillForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      previewBillForm,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: usePreviewBillMutation.mutation,
    variables: {
      previewBillForm: PreviewBillForm.toMutation(form.values.previewBillForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
usePreviewBillMutation.mutation = gql`
  mutation usePreviewBillMutation($previewBillForm: PreviewBillForm!) {
    response: previewBill(previewBillForm: $previewBillForm) {
      ${gql.errors}
      billTotalsPreview {
        subtotal
        total
      }
    }
  }
`;

export default usePreviewBillMutation;
