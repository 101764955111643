// Libraries
import _ from 'lodash';

// Supermove
import {List} from '@supermove/utils';

const ADMIN = 'admin';
const CONTRACTOR = 'CONTRACTOR';
const CUSTOMER = 'customer';
const EMPLOYEE = 'employee';
const INTEGRATION = 'INTEGRATION';
const MANAGER = 'manager';
const OPS_ADMIN = 'OPS_ADMIN';
const SALES = 'SALES';
const SALESPERSON = 'salesperson';
const SUPER_ADMIN = 'super';

const CUSTOM_DISPLAY_ROLE_MAP: {[key: string]: string} = {
  [EMPLOYEE]: 'Mover',
};

const ROLES = [ADMIN, CUSTOMER, EMPLOYEE, MANAGER, SALESPERSON, SUPER_ADMIN, CONTRACTOR];

// Contains the display order for roles when showing compensation
const ROLES_FOR_COMPENSATION = [SALESPERSON, CONTRACTOR, EMPLOYEE];

const STAFF_ADMIN_ROLES = [ADMIN];

const ADMIN_ROLES = [...STAFF_ADMIN_ROLES, OPS_ADMIN];

const MANAGER_ROLES = [...ADMIN_ROLES, MANAGER];

const OFFICE_ROLES = [...MANAGER_ROLES, SALESPERSON, SALES];

const STAFF_ADMIN_ROLES_PLUS_SUPER = [SUPER_ADMIN, ...STAFF_ADMIN_ROLES];

const ADMIN_ROLES_PLUS_SUPER = [SUPER_ADMIN, ...ADMIN_ROLES];

const MANAGER_ROLES_PLUS_SUPER = [SUPER_ADMIN, ...MANAGER_ROLES];

const OFFICE_ROLES_PLUS_SUPER = [SUPER_ADMIN, ...OFFICE_ROLES];

const OFFICE_ROLES_ONLY = [MANAGER, SALESPERSON, SALES];

const getDisplayRole = (userRole: string): string => {
  return CUSTOM_DISPLAY_ROLE_MAP[userRole] || _.startCase(_.lowerCase(userRole));
};

const getRolesForCompensation = () => {
  // In addition to ROLES_FOR_COMPENSATION we want to include all the other roles
  // to cover edge cases and for future proofing.
  const nonCompensationRoles = _.filter(ROLES, (role) => !_.includes(ROLES_FOR_COMPENSATION, role));
  return [...ROLES_FOR_COMPENSATION, ...nonCompensationRoles];
};

const getDropdownOptions = ({isEnabledOperationsAdmins, viewerRole}: any) => {
  return [
    ...List.insertIf([SUPER_ADMIN, ADMIN].includes(viewerRole), {
      label: 'Admin - All permissions',
      value: ADMIN,
    }),
    ...List.insertIf(isEnabledOperationsAdmins, {
      label: 'Operations Admin - All permissions except accounting',
      value: OPS_ADMIN,
    }),
    {
      label: 'Manager - View all projects',
      value: MANAGER,
    },
    {
      label: 'Salesperson - View only assigned projects',
      value: SALESPERSON,
    },
  ];
};

const getCopilotDropdownOptions = () => {
  return [
    {label: 'Sales', value: SALES},
    {label: 'Admin', value: ADMIN},
  ];
};

const getIsStaffAdmin = (userRole: string = '') => STAFF_ADMIN_ROLES_PLUS_SUPER.includes(userRole);

const getIsOfficeEmployee = (userRole: string = '') => [MANAGER, SALESPERSON].includes(userRole);

const getJobActionDisabledTooltip = (userRole: string = '') =>
  getIsStaffAdmin(userRole)
    ? 'This job is finalized. To make edits, unfinalize it.'
    : 'Contact an admin to unfinalize this job.';

// Wraps each role in double quotes to be used as arguments in GraphQL.
const getGraphqlArgs = (roles: string[]) => roles.map((role) => `"${role}"`);

const UserRole = {
  ADMIN,
  CONTRACTOR,
  CUSTOMER,
  EMPLOYEE,
  INTEGRATION,
  MANAGER,
  OPS_ADMIN,
  SALESPERSON,
  SUPER_ADMIN,

  ROLES,
  ADMIN_ROLES,
  MANAGER_ROLES,
  OFFICE_ROLES,
  STAFF_ADMIN_ROLES_PLUS_SUPER,
  ADMIN_ROLES_PLUS_SUPER,
  MANAGER_ROLES_PLUS_SUPER,
  OFFICE_ROLES_PLUS_SUPER,
  OFFICE_ROLES_ONLY,

  getDisplayRole,
  getRolesForCompensation,
  getDropdownOptions,
  getCopilotDropdownOptions,
  getIsStaffAdmin,
  getIsOfficeEmployee,
  getGraphqlArgs,
  getJobActionDisabledTooltip,
};

export default UserRole;
