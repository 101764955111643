// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import OrganizationNotificationEmailsForm from '@shared/modules/Organization/forms/OrganizationNotificationEmailsForm';

const useUpdateOrganizationNotificationEmailsMutation = ({
  organizationNotificationEmailsForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      organizationNotificationEmailsForm: OrganizationNotificationEmailsForm.toForm(
        organizationNotificationEmailsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationNotificationEmailsMutation.mutation,
    variables: {
      organizationNotificationEmailsForm: OrganizationNotificationEmailsForm.toMutation(
        form.values.organizationNotificationEmailsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationNotificationEmailsMutation.mutation = gql`
  mutation useUpdateOrganizationNotificationEmailsMutation($organizationNotificationEmailsForm: OrganizationNotificationEmailsForm!) {
    response: updateOrganizationNotificationEmails(organizationNotificationEmailsForm: $organizationNotificationEmailsForm) {
      ${gql.errors}
      organization {
        id
      }
    }
  }
`;

export default useUpdateOrganizationNotificationEmailsMutation;
