// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Column = Styled.View`
  flex: 1;
  padding-vertical: 8px;
  padding-horizontal: 24px;
  border-width: 1px;
  border-color: ${colors.gray.border}
`;

const BlackBoldText = Styled.Text`
  ${Typography.Label}
`;

const Heading = Styled.Text`
  ${Typography.Heading2}
`;

const GrayBodyText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const EmptyStateText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.tertiary};
`;

const NoInventoryTable = () => {
  return (
    <Container>
      <Heading>Inventory Summary</Heading>
      <Space height={12} />
      <Row>
        <Column>
          <GrayBodyText>Total Items</GrayBodyText>
        </Column>
        <Column style={{borderLeftWidth: 0, borderBottomWidth: 0}} />
      </Row>
      <Row>
        <Column style={{borderTopWidth: 0}}>
          <GrayBodyText>Items with Exceptions</GrayBodyText>
        </Column>
        <Column
          style={{
            borderLeftWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            alignItems: 'center',
          }}
        >
          <EmptyStateText>No inventory captured for this job.</EmptyStateText>
        </Column>
      </Row>
      <Row>
        <Column style={{borderTopWidth: 0}}>
          <GrayBodyText>Rooms</GrayBodyText>
        </Column>
        <Column style={{borderLeftWidth: 0, borderTopWidth: 0}} />
      </Row>
    </Container>
  );
};

const DocumentV2GeneralInventorySummaryContent = ({inventory}) => {
  const totalItems = inventory.collection.filteredItemsForJobUuids.length;
  const totalItemsWithExceptions = inventory.collection.itemsWithExceptionsForJobUuids.length;
  const totalRooms = inventory.totalRoomCountForJobUuids;

  if (totalItems === 0) {
    return <NoInventoryTable />;
  }
  return (
    <Container>
      <Heading>Inventory Summary</Heading>
      <Space height={12} />
      <Row>
        <Column>
          <GrayBodyText>Total Items</GrayBodyText>
        </Column>
        <Column style={{borderLeftWidth: 0, flex: 2}}>
          <BlackBoldText>{totalItems}</BlackBoldText>
        </Column>
      </Row>
      <Row>
        <Column style={{borderTopWidth: 0}}>
          <GrayBodyText>Items with Exceptions</GrayBodyText>
        </Column>
        <Column style={{borderLeftWidth: 0, borderTopWidth: 0, flex: 2}}>
          <BlackBoldText>{totalItemsWithExceptions}</BlackBoldText>
        </Column>
      </Row>
      <Row>
        <Column style={{borderTopWidth: 0}}>
          <GrayBodyText>Rooms</GrayBodyText>
        </Column>
        <Column style={{borderLeftWidth: 0, borderTopWidth: 0, flex: 2}}>
          <BlackBoldText>{totalRooms}</BlackBoldText>
        </Column>
      </Row>
    </Container>
  );
};

const DocumentV2GeneralInventorySummaryPreview = () => {
  const inventory = MOCK_INVENTORY_DATA;
  return <DocumentV2GeneralInventorySummaryContent inventory={inventory} />;
};

const DocumentV2GeneralInventorySummaryNonPreview = ({job}) => {
  if (!job.driverInventory) {
    return <NoInventoryTable />;
  }
  return <DocumentV2GeneralInventorySummaryContent inventory={job.driverInventory} />;
};

const ProjectDocumentV2GeneralInventorySummary = ({isPreview, job}) => {
  return (
    <React.Fragment>
      {isPreview ? (
        <DocumentV2GeneralInventorySummaryPreview />
      ) : (
        <DocumentV2GeneralInventorySummaryNonPreview job={job} />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
ProjectDocumentV2GeneralInventorySummary.propTypes = {
  isPreview: PropTypes.bool,
};
ProjectDocumentV2GeneralInventorySummary.defaultProps = {
  isPreview: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectDocumentV2GeneralInventorySummary.fragment = gql`
  fragment ProjectDocumentV2GeneralInventorySummary on Job {
    id
    driverInventory {
      id
      collection {
        id
        filteredItemsForJobUuids(jobUuids: [$jobUuid]) {
          id
        }
        itemsWithExceptionsForJobUuids(jobUuids: [$jobUuid]) {
          id
        }
      }
      totalRoomCountForJobUuids(jobUuids: [$jobUuid])
    }
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_INVENTORY_DATA = {
  id: 1,
  collection: {
    id: 1,
    filteredItemsForJobUuids: [
      {
        id: 1,
      },
    ],
    itemsWithExceptionsForJobUuids: [
      {
        id: 1,
      },
    ],
  },
  totalRoomCountForJobUuids: 1,
};

export default ProjectDocumentV2GeneralInventorySummary;
