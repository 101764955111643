// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import UpdateContainerOutDatesForProjectForm from '@shared/modules/Storage/forms/UpdateContainerOutDatesForProjectForm';

const useUpdateContainerOutDatesForProjectMutation = ({
  updateContainerOutDatesForProjectForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      updateContainerOutDatesForProjectForm: UpdateContainerOutDatesForProjectForm.toForm(
        updateContainerOutDatesForProjectForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateContainerOutDatesForProjectMutation.mutation,
    variables: {
      updateContainerOutDatesForProjectForm: UpdateContainerOutDatesForProjectForm.toMutation(
        form.values.updateContainerOutDatesForProjectForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateContainerOutDatesForProjectMutation.mutation = gql`
  mutation UpdateContainerOutDatesForProjectMutation($updateContainerOutDatesForProjectForm: UpdateContainerOutDatesForProjectForm!) {
    response: updateContainerOutDatesForProject(updateContainerOutDatesForProjectForm: $updateContainerOutDatesForProjectForm) {
      ${gql.errors}
      project {
        id
      }
    }
  }
`;

export default useUpdateContainerOutDatesForProjectMutation;
