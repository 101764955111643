// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// Local
import TertiaryButton from '../Button/TertiaryButton';

const getColor = ({
  isSecondary,
  isDestructive,
  isDisabled,
}: {
  isSecondary?: boolean;
  isDestructive?: boolean;
  isDisabled?: boolean;
}) => {
  if (isDisabled) {
    return colors.gray.disabled;
  }
  if (isDestructive) {
    return colors.red.warning;
  }
  if (isSecondary) {
    return colors.gray.secondary;
  }
  return colors.blue.interactive;
};

const IconButton = ({
  source,
  onPress,
  isSecondary,
  isDestructive,
  isDisabled,
  buttonStyle = {},
  children,
  size = 16,
}: {
  source: string | string[];
  onPress: () => void;
  isSecondary?: boolean;
  isDestructive?: boolean;
  isDisabled?: boolean;
  buttonStyle?: object;
  children?: React.ReactNode;
  size?: number;
}) => {
  return (
    <TertiaryButton
      style={{
        width: 24,
        height: 24,
        alignItems: 'center',
        justifyContent: 'center',
        ...buttonStyle,
      }}
      onPress={onPress}
      isDisabled={isDisabled}
    >
      {children || (
        <Icon
          source={source}
          size={size}
          color={getColor({isSecondary, isDestructive, isDisabled})}
        />
      )}
    </TertiaryButton>
  );
};

IconButton.SOURCE = Icon;

export default IconButton;
