// Supermove
import {Datetime} from '@supermove/utils';
// App

const AVAILABLE_FILTERS = ['fromDate', 'toDate', 'actions', 'types', 'statuses'];

const _new = () => ({
  actions: null,
  types: null,
  fromDate: null,
  toDate: null,
  searchQuery: null,
  statuses: null,
  page: 1,
});

const toForm = ({fromDate, toDate, actions, types, searchQuery, page, statuses}) => ({
  actions,
  types,
  fromDate: fromDate ? Datetime.fromDate(fromDate) : null,
  toDate: toDate ? Datetime.fromDate(toDate) : null,
  searchQuery,
  page: page || 1,
  statuses,
});

const toQueryParams = ({fromDate, toDate, actions, types, searchQuery, page, statuses}) => ({
  fromDate: fromDate ? Datetime.toDate(fromDate) : null,
  toDate: toDate ? Datetime.toDate(toDate) : null,
  searchQuery,
  actions,
  types,
  page,
  statuses,
});

const toVariables = ({fromDate, toDate, actions, types, searchQuery, page, statuses}) => ({
  fromDate: fromDate ? Datetime.toDate(fromDate) : null,
  toDate: toDate ? Datetime.toDate(toDate) : null,
  searchQuery,
  actions,
  types,
  page,
  statuses,
});

const getNumberOfFiltersApplied = ({form}) =>
  AVAILABLE_FILTERS.reduce((appliedFiltersCount, filter) => {
    const formFilter = form.values.filteredCodatPushOperationsForm[filter];
    // Do not include empty arrays in applied filters count
    if (formFilter && formFilter.length !== 0) {
      return appliedFiltersCount + 1;
    }

    return appliedFiltersCount;
  }, 0);

const FilteredCodatPushOperationsForm = {
  new: _new,
  toForm,
  toQueryParams,
  getNumberOfFiltersApplied,
  toVariables,
};

export default FilteredCodatPushOperationsForm;
