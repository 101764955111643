// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FileDragInput as FileDragInputBase, Space, Styled} from '@supermove/components';
import {useResponsive, useHover} from '@supermove/hooks';
import {colors, fontWeight, Typography} from '@supermove/styles';

// Local
import IconButton from '@shared/design/components/IconButton';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const FileDragInputContainer = Styled.View`
  min-height: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-style: dashed;
  border-width: 1px;
  padding: 24px;
  cursor: ${({isHighlighted}: {isHighlighted: boolean}) => (isHighlighted ? 'pointer' : 'default')};
  border-color: ${({isHighlighted}: {isHighlighted: boolean}) =>
    isHighlighted ? colors.blue.interactive : colors.gray.tertiary};
`;

const FileDragInputText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
`;

const FileDragInputLinkText = Styled.Text`
  ${Typography.Responsive.Link}
  ${fontWeight(700)}
`;

const FileDragInputCaptionText = Styled.Text`
  ${Typography.Responsive.Micro}
  text-align: center;
  color: ${colors.gray.tertiary};
`;

const LoadingIndicator = Styled.Loading`
`;

const FileDragInputContent = ({
  isUploading,
  isImagesOnly,
  caption,
  handleRemove,
  handleRemoveText,
}: {
  isUploading?: boolean;
  isImagesOnly?: boolean;
  caption?: string;
  handleRemove?: () => void;
  handleRemoveText?: string;
}) => {
  const responsive = useResponsive();

  if (isUploading) {
    return (
      <Row>
        <LoadingIndicator size={'small'} color={colors.blue.interactive} />
        <Space width={8} />
        <FileDragInputText responsive={responsive}>Uploading...</FileDragInputText>
      </Row>
    );
  }
  if (handleRemove && handleRemoveText) {
    return (
      <Row>
        <FileDragInputText responsive={responsive}>{handleRemoveText}</FileDragInputText>
        <Space width={8} />
        <IconButton source={IconButton.SOURCE.Xmark} onPress={handleRemove} isDestructive />
      </Row>
    );
  }
  return (
    <React.Fragment>
      <FileDragInputText responsive={responsive}>
        {responsive.desktop ? `Drag ${isImagesOnly ? 'an image' : 'a file'} or ` : ''}
        <FileDragInputLinkText responsive={responsive}>
          {responsive.desktop ? 'click to upload' : 'Tap to upload'}
        </FileDragInputLinkText>
      </FileDragInputText>
      {(isImagesOnly || caption) && (
        <React.Fragment>
          <Space height={8} />
          <FileDragInputCaptionText responsive={responsive}>
            {isImagesOnly ? 'PNG, JPG' : caption}
          </FileDragInputCaptionText>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const FileDragInput = ({
  accept,
  caption,
  handleUploadFile = () => null,
  handleUploadFiles,
  handleRemove,
  handleRemoveText,
  isUploading,
  isDisabled,
  isImagesOnly,
  disableDrag,
  disableClick,
  containerStyle,
}: {
  accept?: string;
  caption?: string;
  handleUploadFile?: (file: object) => void;
  handleUploadFiles?: (files: object[]) => void;
  handleRemove?: () => void;
  handleRemoveText?: string;
  isUploading?: boolean;
  isDisabled?: boolean;
  isImagesOnly?: boolean;
  disableDrag?: boolean;
  disableClick?: boolean;
  containerStyle?: object;
}) => {
  const {ref, isHovered} = useHover();
  const isInputDisabled = isUploading || isDisabled || !!(handleRemove && handleRemoveText);
  const isClickDisabled = isInputDisabled || disableClick;
  const isDragDisabled = isInputDisabled || disableDrag;

  return (
    <FileDragInputBase
      accept={isImagesOnly ? 'image/*' : accept}
      disabled={isInputDisabled}
      disableDrag={isDragDisabled}
      disableClick={isClickDisabled}
      onFilesChange={
        handleUploadFiles ? handleUploadFiles : (files: any) => handleUploadFile(_.get(files, '0'))
      }
    >
      {({isDragActive}: {isDragActive: boolean}) => (
        <FileDragInputContainer
          ref={ref}
          isHighlighted={!isDragDisabled && (isDragActive || isHovered)}
          style={containerStyle}
        >
          <FileDragInputContent
            isUploading={isUploading}
            isImagesOnly={isImagesOnly}
            caption={caption}
            handleRemove={handleRemove}
            handleRemoveText={handleRemoveText}
          />
        </FileDragInputContainer>
      )}
    </FileDragInputBase>
  );
};

export default FileDragInput;
