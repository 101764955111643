// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {Animation, colors, Typography} from '@supermove/styles';

const Container = Styled.View`
`;

const LabelText = Styled.Text`
  ${({vars}: any) => vars.typography}
  color: ${({color}: any) => color};
`;

const ValueText = Styled.Text`
  ${({vars}: any) => vars.typography}
  color: ${({vars}: any) => (vars.isLink ? colors.blue.interactive : colors.gray.primary)};
  text-decoration: ${({vars}: any) => (vars.isLink ? 'underline' : null)};
  text-decoration-color: ${colors.blue.interactive};
`;

const EmptyText = Styled.Text`
  ${({vars}: any) => vars.typography}
  color: ${colors.gray.tertiary};
  font-style: italic;
`;

const FIELD_SIZE = {
  MICRO: 'MICRO',
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
};

const Loader = Styled.View`
  height: ${({height}: any) => height}px;
  width: ${({isFullWidth, width}: any) => (isFullWidth ? '100%' : `${width}px`)};
  border-radius: 4px;
  background-color: ${({color}: any) => color};
  ${Animation.pulse}
`;

const getLabelTypography = ({size, isResponsive}: any) => {
  if (size === FIELD_SIZE.MICRO) {
    return (isResponsive && Typography.Responsive.MicroLabel) || Typography.MicroLabel;
  }
  return (isResponsive && Typography.Responsive.Label) || Typography.Label;
};

const getValueTypography = ({size, isResponsive}: any) => {
  switch (size) {
    case FIELD_SIZE.MEDIUM:
      return (isResponsive && Typography.Responsive.Subheading) || Typography.Subheading;
    case FIELD_SIZE.MICRO:
      return (isResponsive && Typography.Responsive.Micro) || Typography.Micro;
    default:
      return (isResponsive && Typography.Responsive.Body) || Typography.Body;
  }
};

const getSkeletonFieldHeight = ({size}: any) => {
  switch (size) {
    case FIELD_SIZE.MEDIUM:
      return 24;
    case FIELD_SIZE.MICRO:
      return 18;
    default:
      return 20;
  }
};

const LoadingComponent = ({label, style, isFullWidth, width, size = FIELD_SIZE.SMALL}: any) => {
  return (
    <Container style={style}>
      {!!label && (
        <React.Fragment>
          <Label size={size}>{label}</Label>
          <Space height={8} />
        </React.Fragment>
      )}
      <Loader isFullWidth={isFullWidth} width={width} height={getSkeletonFieldHeight({size})} />
    </Container>
  );
};

const Label = ({labelColor, size, style, isResponsive, responsive, children}: any) => {
  return (
    <LabelText
      vars={{typography: getLabelTypography({size, isResponsive})}}
      responsive={responsive}
      color={labelColor || colors.gray.secondary}
      style={style}
    >
      {children}
    </LabelText>
  );
};

const Value = ({
  size,
  style,
  children,
  isLink,
  numberOfLines,
  isResponsive,
  responsive,
  onPressValue,
  onLayoutValue,
}: any) => {
  return (
    <ValueText
      vars={{typography: getValueTypography({size, isResponsive}), isLink}}
      style={style}
      numberOfLines={numberOfLines}
      onPress={onPressValue}
      onLayout={onLayoutValue}
      responsive={responsive}
    >
      {children}
    </ValueText>
  );
};

const Empty = ({
  size,
  style,
  numberOfLines,
  isResponsive,
  responsive,
  onLayoutValue,
  children,
}: any) => {
  return (
    <EmptyText
      vars={{typography: getValueTypography({size, isResponsive})}}
      style={style}
      numberOfLines={numberOfLines}
      onLayout={onLayoutValue}
      responsive={responsive}
    >
      {children}
    </EmptyText>
  );
};

const Content = ({
  newTab,
  numberOfLines,
  value,
  link,
  empty,
  size,
  isResponsive,
  responsive,
  onPressValue,
  onLayoutValue,
  valueStyle,
}: any) => {
  if (!value) {
    return (
      <Empty
        numberOfLines={numberOfLines}
        size={size}
        isResponsive={isResponsive}
        onLayoutValue={onLayoutValue}
        responsive={responsive}
      >
        {empty}
      </Empty>
    );
  }

  if (link) {
    return (
      <a target={newTab ? '_blank' : '_self'} href={link}>
        <Value
          isLink
          numberOfLines={numberOfLines}
          size={size}
          isResponsive={isResponsive}
          responsive={responsive}
          onLayoutValue={onLayoutValue}
          style={valueStyle}
        >
          {value}
        </Value>
      </a>
    );
  }

  return (
    <Value
      numberOfLines={numberOfLines}
      size={size}
      isResponsive={isResponsive}
      responsive={responsive}
      onPressValue={onPressValue}
      onLayoutValue={onLayoutValue}
      style={valueStyle}
    >
      {value}
    </Value>
  );
};

const LabelSpace = ({isResponsive, responsive}: any) => {
  return <Space height={isResponsive && !responsive.desktop ? 2 : 8} />;
};

const FieldValue = ({
  newTab,
  numberOfLines,
  label,
  labelColor,
  value,
  link,
  empty,
  style,
  valueStyle,
  size,
  isResponsive,
  onPressValue,
  onLayoutValue,
}: any) => {
  const responsive = useResponsive();

  return (
    <Container style={style}>
      {!!label && (
        <React.Fragment>
          <Label
            labelColor={labelColor}
            size={size}
            isResponsive={isResponsive}
            responsive={responsive}
          >
            {label}
          </Label>
          <LabelSpace isResponsive={isResponsive} responsive={responsive} />
        </React.Fragment>
      )}
      <Content
        newTab={newTab}
        numberOfLines={numberOfLines}
        value={value}
        link={link}
        empty={empty}
        size={size}
        isResponsive={isResponsive}
        responsive={responsive}
        onPressValue={onPressValue}
        onLayoutValue={onLayoutValue}
        valueStyle={valueStyle}
      />
    </Container>
  );
};

FieldValue.EmptyText = Empty;
FieldValue.LabelText = Label;
FieldValue.ValueText = Value;
FieldValue.SIZE = FIELD_SIZE;
FieldValue.SkeletonLoader = LoadingComponent;
FieldValue.LabelSpace = LabelSpace;

export default FieldValue;
