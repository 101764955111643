// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SettingsForm from '@shared/modules/Settings/forms/SettingsForm';

const useToggleSettingsIsCrewInventoryEnabledMutation = ({settingsForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      settingsForm: SettingsForm.toForm(settingsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useToggleSettingsIsCrewInventoryEnabledMutation.mutation,
    variables: {
      settingsForm: SettingsForm.toMutation(form.values.settingsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useToggleSettingsIsCrewInventoryEnabledMutation.mutation = gql`
  mutation useToggleSettingsIsCrewInventoryEnabledMutation($settingsForm: SettingsForm!) {
    response: toggleSettingsIsCrewInventoryEnabled(settingsForm: $settingsForm) {
      ${gql.errors}
      settings {
        id
        isCrewInventoryEnabled
      }
    }
  }
`;

export default useToggleSettingsIsCrewInventoryEnabledMutation;
