// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ContainerForm from '@shared/modules/Storage/forms/ContainerForm';

const useCreateContainerMutation = ({containerForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      containerForm: ContainerForm.toForm(containerForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateContainerMutation.mutation,
    variables: {
      containerForm: ContainerForm.toMutation(form.values.containerForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateContainerMutation.mutation = gql`
  mutation CreateContainerMutation($containerForm: ContainerForm!) {
    response: createContainer(containerForm: $containerForm) {
      ${gql.errors}
      container {
        id
      }
    }
  }
`;

export default useCreateContainerMutation;
