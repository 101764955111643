// Libraries

// Supermove
import {gql} from '@supermove/graphql';
import {Datetime} from '@supermove/utils';

const edit = (timeRange) => ({
  kind: timeRange.kind,
  name: timeRange.name,
  start: timeRange.start || '',
  end: timeRange.end || '',
  startIsRequired: timeRange.startIsRequired || false,
  endIsRequired: timeRange.endIsRequired || false,
});

edit.fragment = gql`
  fragment TimeRangeForm_edit on TimeRange {
    kind
    name
    start
    end
    startIsRequired
    endIsRequired
  }
`;

const TimeRangeForm = {
  toForm: ({kind, name, start, end, startIsRequired, endIsRequired}) => ({
    kind,
    name,
    start: Datetime.toFormTime(start),
    end: Datetime.toFormTime(end),

    // Rendering only
    startIsRequired,
    endIsRequired,
  }),

  toMutation: ({kind, name, start, end}) => ({
    kind,
    name,
    start: Datetime.toMutationTime(start),
    end: Datetime.toMutationTime(end),
  }),

  edit,
};

export default TimeRangeForm;
