// Supermove
import _ from 'lodash';

import {gql} from '@supermove/graphql';
import {titleize, withFragment} from '@supermove/utils';

// Apps
import DataTableVariableAccessKind from '@shared/modules/Billing/enums/DataTableVariableAccessKind';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import DataTableVariableForm from '@shared/modules/Billing/forms/DataTableVariableForm';

const getDisplayFormat = (variableForm) => {
  return VariableFormat.getDisplayName(variableForm.format);
};

const getDisplayKind = (variableForm) => {
  return titleize(variableForm.kind.replace(/_/g, ' '));
};

const getHasDataTable = (variableForm) => {
  return !!variableForm.dataTableVariableForm;
};

const getIsUsingTariff = (variableForm) => {
  return (
    _.get(variableForm, 'dataTableVariableForm.tableAccessKind') ===
    DataTableVariableAccessKind.WEIGHT_DISTANCE_TO_RATE
  );
};

const _new = ({organizationId, userId, category = null}) => {
  return {
    organizationId,
    identifier: '',
    name: '',
    description: '',
    kind: null,
    category,
    format: null,
    isDerived: false,
    createdById: userId,
    updatedById: userId,
    dataTableVariableForm: null,

    // Private
    isExistingVariable: false,
    isAddingNewDataTable: false,
  };
};

const edit = withFragment(
  (variable, {userId}) => ({
    organizationId: variable.organizationId,
    identifier: variable.identifier,
    name: variable.name,
    description: variable.description,
    kind: variable.kind,
    category: variable.category,
    format: variable.format,
    isDerived: variable.isDerived,
    createdById: variable.createdById,
    updatedById: userId,
    dataTableVariableForm: variable.dataTableVariable
      ? DataTableVariableForm.edit(variable.dataTableVariable)
      : null,

    // Private
    isExistingVariable: true,
    isAddingNewDataTable: false,
  }),
  gql`
    ${DataTableVariableForm.edit.fragment}
    fragment VariableForm_edit on Variable {
      id
      organizationId
      identifier
      name
      description
      kind
      category
      format
      isDerived
      updatedById
      createdById
      dataTableVariable {
        id
        ...DataTableVariableForm_edit
      }
    }
  `,
);

const toForm = ({
  organizationId,
  identifier,
  name,
  description,
  kind,
  category,
  format,
  isDerived,
  createdById,
  updatedById,
  dataTableVariableForm,
  isExistingVariable,
  isAddingNewDataTable,
}) => ({
  organizationId,
  identifier,
  name,
  description,
  kind,
  category,
  format,
  isDerived,
  createdById,
  updatedById,
  dataTableVariableForm: dataTableVariableForm
    ? DataTableVariableForm.toForm(dataTableVariableForm)
    : null,

  // Private
  isExistingVariable,
  isAddingNewDataTable,
});

const toMutation = ({
  organizationId,
  identifier,
  name,
  description,
  kind,
  category,
  format,
  isDerived,
  createdById,
  updatedById,
  dataTableVariableForm,
}) => ({
  organizationId,
  identifier,
  name,
  description,
  kind,
  category,
  format,
  isDerived,
  createdById,
  updatedById,
  dataTableVariableForm: dataTableVariableForm
    ? DataTableVariableForm.toMutation(dataTableVariableForm)
    : null,
});

const VariableForm = {
  toForm,
  toMutation,
  new: _new,
  edit,

  getDisplayFormat,
  getDisplayKind,
  getHasDataTable,
  getIsUsingTariff,
};

export default VariableForm;
