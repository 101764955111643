// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import DataTableForm from '@shared/modules/Billing/forms/DataTableForm';

const _new = () => {
  return {
    dataTableVariableId: null,
    dataTableId: null,
    inputVariableId: null,
    variableId: null,
    tableAccessKind: '',
    shouldUseBreakpoint: false,
    dataTableForm: null,
  };
};

const edit = withFragment(
  (dataTableVariable) => ({
    dataTableVariableId: dataTableVariable.id,
    dataTableId: _.toString(dataTableVariable.dataTableId),
    inputVariableId: dataTableVariable.inputVariableId
      ? _.toString(dataTableVariable.inputVariableId)
      : null,
    variableId: _.toString(dataTableVariable.variableId),
    tableAccessKind: dataTableVariable.tableAccessKind,
    shouldUseBreakpoint: dataTableVariable.shouldUseBreakpoint,
    dataTableForm: null,
  }),
  gql`
    ${DataTableForm.edit.fragment}
    fragment DataTableVariableForm_edit on DataTableVariable {
      id
      dataTableId
      inputVariableId
      variableId
      tableAccessKind
      shouldUseBreakpoint
      dataTable {
        id
        ...DataTableForm_edit
      }
    }
  `,
);

const toForm = ({
  dataTableVariableId,
  dataTableId,
  inputVariableId,
  variableId,
  tableAccessKind,
  shouldUseBreakpoint,
  dataTableForm,
}) => ({
  dataTableVariableId,
  dataTableId,
  inputVariableId,
  variableId,
  tableAccessKind,
  shouldUseBreakpoint,
  dataTableForm: dataTableForm ? DataTableForm.toForm(dataTableForm) : null,
});

const toMutation = ({
  dataTableVariableId,
  dataTableId,
  inputVariableId,
  variableId,
  tableAccessKind,
  shouldUseBreakpoint,
  dataTableForm,
}) => ({
  dataTableVariableId,
  dataTableId,
  inputVariableId,
  variableId,
  tableAccessKind,
  shouldUseBreakpoint,
  dataTableForm: dataTableForm ? DataTableForm.toMutation(dataTableForm) : null,
});

const DataTableVariableForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default DataTableVariableForm;
