// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CompanyTabletSettingsForm from '@shared/modules/Company/forms/CompanyTabletSettingsForm';

const useUpdateCompanyTabletSettingsMutation = ({
  companyTabletSettingsForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      companyTabletSettingsForm: CompanyTabletSettingsForm.toForm(companyTabletSettingsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCompanyTabletSettingsMutation.mutation,
    variables: {
      companyTabletSettingsForm: CompanyTabletSettingsForm.toMutation(
        form.values.companyTabletSettingsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------

useUpdateCompanyTabletSettingsMutation.mutation = gql`
  mutation useUpdateCompanyTabletSettingsMutation($companyTabletSettingsForm: CompanyTabletSettingsForm!) {
    response: updateCompanyTabletSettings(companyTabletSettingsForm: $companyTabletSettingsForm) {
      ${gql.errors}
      organizations {
        id
        pin
      }
    }
  }
`;

export default useUpdateCompanyTabletSettingsMutation;
