// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useQuery,
  useModal,
  useResponsive,
  usePagination,
  useState,
  useDrawer,
} from '@supermove/hooks';
import {useNavigationDOM} from '@supermove/navigation';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import ConfirmationModal from '@shared/design/components/Modal/SmallModal/ConfirmationModal';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Table from '@shared/design/components/Table';
import CodatIntegrationForm from '@shared/modules/Integration/forms/CodatIntegrationForm';
import useSyncCodatLineItemsForCodatIntegrationMutation from '@shared/modules/Integration/hooks/useSyncCodatLineItemsForCodatIntegrationMutation';
import EditBillItemTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/EditBillItemTypeDrawer';
import ImportExternalInvoiceItemsModal from 'modules/Organization/Settings/Company/components/ImportExternalInvoiceItemsModal';

const TableContainer = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  justify-content: space-between;
  flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
  z-index: ${({index}) => 100 - index};
`;

const TitleRow = Styled.View`
  flex-direction: row
`;

const Container = Styled.View`
`;

const FlexContainer = Styled.View`
  flex: 1;
`;

const RightPageContainer = Styled.View`
  flex: 1;
  padding-horizontal: 24px;
  background-color: ${colors.gray.background}
`;

const Column = Styled.View`
`;

const Title = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const MicroGrayText = Styled.Text`
  padding-top: 10px;
  ${Typography.Responsive.Micro}
  color: ${colors.gray.tertiary};
`;

const ButtonContainer = Styled.View`
  flex-direction: row;
`;

const LinkButton = Styled.ButtonV2`
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const getExternalInvoiceItemsColumnDefinitions = ({viewer, refetch, responsive, organization}) => [
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Name</Table.HeaderText>,
    cellContent: ({item}) => {
      return <Table.CellText>{item.name}</Table.CellText>;
    },
  },
  {
    flex: 3,
    headerContent: () => <Table.HeaderText>Description</Table.HeaderText>,
    cellContent: ({item}) => {
      return <Table.CellText>{item.description}</Table.CellText>;
    },
  },
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Category</Table.HeaderText>,
    cellContent: ({item}) => {
      return <Table.CellText>{item.category}</Table.CellText>;
    },
  },
  {
    flex: 3,
    headerContent: () => <Table.HeaderText>Bill Item Types</Table.HeaderText>,
    cellContent: ({item}) => {
      return (
        <Table.CellText>
          {item.billItemTypes.map((billItemType, index) => (
            <React.Fragment key={index}>
              <EditBillItemTypeLinkButton
                billItemType={billItemType}
                viewer={viewer}
                refetch={refetch}
                responsive={responsive}
                organization={organization}
              />
              {index !== item.billItemTypes.length - 1 && <Table.CellText>{', '}</Table.CellText>}
            </React.Fragment>
          ))}
        </Table.CellText>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Last Imported</Table.HeaderText>,
    cellContent: ({item}) => {
      return (
        <Table.CellText>{Datetime.convertToDisplayDatetime(item.lastSyncedAt)}</Table.CellText>
      );
    },
  },
];

const EditBillItemTypeLinkButton = ({billItemType, viewer, refetch, responsive, organization}) => {
  const editBillItemTypeDrawer = useDrawer({
    name: 'Edit Bill Item Type Drawer',
    useTracking: true,
  });
  const {parentBillItemType} = billItemType;
  return (
    <React.Fragment>
      <LinkButton onPress={editBillItemTypeDrawer.handleOpen}>
        <LinkText responsive={responsive}>{`${billItemType.name}${
          _.isEmpty(billItemType.moverPosition) ? '' : ` (${billItemType.moverPosition.name})`
        }`}</LinkText>
      </LinkButton>
      <EditBillItemTypeDrawer
        key={editBillItemTypeDrawer.key}
        isOpen={editBillItemTypeDrawer.isOpen}
        handleClose={editBillItemTypeDrawer.handleClose}
        billItemType={parentBillItemType || billItemType}
        refetch={refetch}
        userId={viewer.id}
        organization={organization}
      />
    </React.Fragment>
  );
};

const AccountingItemsContent = ({organization, refetch, loading, pagination, viewer}) => {
  const responsive = useResponsive();
  const importExternalInvoiceItemsModal = useModal({name: 'Import External Invoice Items Modal'});
  const syncCodatLineItemsModal = useModal({name: 'Sync Codat Line Items Modal'});
  const {codatIntegrationByUuid} = organization;
  const textObject = {
    title: 'Import CSV File: External Invoice Items',
    description:
      'Upload a CSV file of external invoice items into the system. The headers of the CSV file must exactly match the list below.',
    textValidationNames: [
      'ID: required',
      'Identifier:Required',
      'Name: required',
      'Description: required',
      'Category: required',
      'Bill Item Type ID: required',
    ],
    textHeaders: 'ID,Identifier,Name,Description,Category,Bill Item Type ID',
  };
  const {handleSubmit, submitting} = useSyncCodatLineItemsForCodatIntegrationMutation({
    codatIntegrationForm: CodatIntegrationForm.edit(codatIntegrationByUuid),
    onSuccess: () => {
      syncCodatLineItemsModal.handleClose();
      refetch();
    },
    onError: (errors) => console.log(errors),
  });

  return (
    <RightPageContainer>
      <Space height={24} />
      <Row index={0} isMobile={responsive.mobile} style={{maxWidth: '100%'}}>
        <Column>
          <TitleRow>
            <Title responsive={responsive}>Accounting Items Settings</Title>
            <Space width={16} />
            <Badge
              label={organization.codatIntegrationByUuid.sourceKindDisplayName}
              color={colors.gray.secondary}
              size={Badge.SIZE.LARGE}
            />
          </TitleRow>
          <Space height={12} />
          <Description responsive={responsive}>
            {`View, import, and export items from your accounting integration ` +
              `to automatically match with your Supermove billing libraries.`}
          </Description>
        </Column>
        {responsive.mobile && <Space height={12} />}
        <ButtonContainer>
          {codatIntegrationByUuid.codatLastSync && (
            <React.Fragment>
              <Space height={12} />
              <MicroGrayText
                responsive={responsive}
              >{`Last Imported: ${Datetime.convertToDisplayDatetime(
                codatIntegrationByUuid.codatLastSync,
              )}`}</MicroGrayText>
              <Space width={16} />
            </React.Fragment>
          )}
          <SecondaryButton
            text={'Import'}
            onPress={syncCodatLineItemsModal.handleOpen}
            iconLeft={Icon.Sync}
          />
          <Space width={16} />
          <DropdownButton
            text={'CSV'}
            actions={[]}
            isDisabled
            ButtonComponent={SecondaryButton}
            iconRight={Icon.ChevronDown}
            menuWidth={160}
            menuPosition={DropdownButton.MENU_POSITION.RIGHT}
          />
        </ButtonContainer>
      </Row>
      <Space height={24} />
      <TableContainer>
        <Table.FixedHeaderScroll
          columnDefinitions={getExternalInvoiceItemsColumnDefinitions({
            viewer,
            refetch,
            responsive,
            organization: organization.codatIntegrationByUuid.organization,
          })}
          loading={loading}
          containerStyle={{overflow: 'visible'}}
          scrollViewStyle={{paddingBottom: 24}}
          style={{flex: 1}}
          emptyStateText={'No external invoice items to display'}
          items={codatIntegrationByUuid.externalInvoiceItemsPaginatedList.externalInvoiceItems}
          itemKey={'id'}
          isDense
        />
      </TableContainer>
      <Space height={24} />
      <Container>
        <PaginationBar pagination={pagination} />
      </Container>
      <Space height={24} />
      <ImportExternalInvoiceItemsModal
        key={importExternalInvoiceItemsModal.key}
        isOpen={importExternalInvoiceItemsModal.isOpen}
        handleClose={importExternalInvoiceItemsModal.handleClose}
        refetch={refetch}
        textObject={textObject}
      />
      <ConfirmationModal
        icon={Icon.Sync}
        title={'Import accounting items?'}
        subtitle={'Please allow 5-10 minutes for this to complete.'}
        isOpen={syncCodatLineItemsModal.isOpen}
        key={syncCodatLineItemsModal.key}
        handleSecondaryAction={syncCodatLineItemsModal.handleClose}
        handlePressOutside={syncCodatLineItemsModal.handleClose}
        secondaryActionText={'Cancel'}
        primaryActionText={'Confirm'}
        handlePrimaryAction={handleSubmit}
        isSubmitting={submitting}
      />
    </RightPageContainer>
  );
};

const AccountingItemSettingsLoading = ({viewer, refetch, organization}) => {
  const responsive = useResponsive();

  return (
    <RightPageContainer style={{height: '100%'}}>
      <Space height={24} />
      <Row index={0} isMobile={responsive.mobile} style={{maxWidth: '1280px'}}>
        <Column>
          <Title responsive={responsive}>Accounting Items Settings</Title>
          <Space height={12} />
          <Description responsive={responsive}>
            {`View, import, and export items from your accounting integration ` +
              `to automatically match with your Supermove billing libraries.`}
          </Description>
        </Column>
        <Space height={12} />
        <ButtonContainer>
          <SecondaryButton text={'Import Items'} isDisabled />
          <Space width={16} />
          <DropdownButton
            text={'CSV'}
            isDisabled
            actions={[]}
            ButtonComponent={SecondaryButton}
            iconLeft={Icon.Upload}
            menuWidth={160}
          />
        </ButtonContainer>
      </Row>
      <Space height={24} />
      <TableContainer>
        <Table
          columnDefinitions={getExternalInvoiceItemsColumnDefinitions({
            viewer,
            refetch,
            organization,
          })}
          emptyStateText='No external invoice items to display'
          scrollViewStyle={{paddingBottom: 24}}
          style={{flex: 1}}
          items={[]}
          loading
          itemKey={'id'}
          isDense
        />
      </TableContainer>
      <Space height={24} />
    </RightPageContainer>
  );
};

const AccountingItems = () => {
  const {params} = useNavigationDOM();
  const {uuid} = params;
  const [currentPage, setCurrentPage] = useState(1);
  const {loading, data, refetch} = useQuery(AccountingItems.query, {
    // 'network-only' is used to avoid using empty cached values
    fetchPolicy: 'network-only',
    variables: {
      pagination: {
        page: currentPage,
        resultsPerPage: 20,
      },
      uuid,
    },
  });

  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(
      data,
      'viewer.viewingOrganization.codatIntegrationByUuid.externalInvoiceItemsPaginatedList.paginationMetadata',
    ),
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });

  return (
    <FlexContainer>
      {loading ? (
        <AccountingItemSettingsLoading />
      ) : (
        <AccountingItemsContent
          organization={data.viewer.viewingOrganization}
          refetch={refetch}
          pagination={pagination}
          viewer={data.viewer}
          loading={loading}
        />
      )}
    </FlexContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AccountingItems.query = gql`
  ${CodatIntegrationForm.edit.fragment}
  ${usePagination.fragment}
  ${EditBillItemTypeDrawer.fragment}

  query AccountingItems(
    $pagination: PaginationInput!
    $uuid: String!
  ) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        apiToken
        codatIntegrationByUuid(uuid: $uuid){
          id
          codatLastSync
          sourceKindDisplayName
          organization {
            id
            ...EditBillItemTypeDrawer_Organization
          }
          externalInvoiceItemsPaginatedList(
            pagination: $pagination
          ) {
            externalInvoiceItems: results {
              category
              description
              id
              identifier
              name
              lastSyncedAt
              billItemTypes {
                id
                name
                moverPosition {
                  id
                  name
                }
                parentBillItemType {
                  id
                  name
                  ...EditBillItemTypeDrawer
                }
                ...EditBillItemTypeDrawer
              }
            }
            paginationMetadata {
              ...usePagination
            }
          }
      ...CodatIntegrationForm_edit
        }
      }
    }
  }
`;

export default AccountingItems;
