// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ReorderSurveyMethodsForm from '@shared/modules/Inventory/forms/ReorderSurveyMethodsForm';

const useReorderSurveyMethodsMutation = ({reorderSurveyMethodsForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      reorderSurveyMethodsForm: ReorderSurveyMethodsForm.toForm(reorderSurveyMethodsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useReorderSurveyMethodsMutation.mutation,
    variables: {
      reorderSurveyMethodsForm: ReorderSurveyMethodsForm.toMutation(
        form.values.reorderSurveyMethodsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useReorderSurveyMethodsMutation.mutation = gql`
  mutation useReorderSurveyMethodsMutation($reorderSurveyMethodsForm: ReorderSurveyMethodsForm!) {
    response: reorderSurveyMethods(reorderSurveyMethodsForm: $reorderSurveyMethodsForm) {
      ${gql.errors}
      surveyMethods {
        id
      }
    }
  }
`;

export default useReorderSurveyMethodsMutation;
