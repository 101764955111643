// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (jobTypeFeature) => ({
    jobTypeFeatureId: jobTypeFeature.id,
    jobTypeId: jobTypeFeature.jobTypeId,
    organizationId: jobTypeFeature.organizationId,
    updatedById: jobTypeFeature.updatedById,
    createdById: jobTypeFeature.createdById,
    kind: jobTypeFeature.kind,
    isEnabled: jobTypeFeature.isEnabled,
  }),
  gql`
    fragment JobTypeFeatureForm_edit on JobTypeFeature {
      id
      organizationId
      jobTypeId
      updatedById
      createdById
      kind
      isEnabled
    }
  `,
);

const toForm = ({
  jobTypeFeatureId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled,
}) => ({
  jobTypeFeatureId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled,
});

const toMutation = ({
  jobTypeFeatureId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled,
}) => ({
  jobTypeFeatureId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled,
});

const JobTypeFeatureForm = {
  edit,
  toForm,
  toMutation,
};

export default JobTypeFeatureForm;
