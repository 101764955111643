// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useEffect, useState} from '@supermove/hooks';
import {Datetime} from '@supermove/utils';

// App
import TimesheetEntriesSlideViewer from '@shared/modules/Timesheet/components/TimesheetEntriesSlideViewer';
import TimesheetEntryCard from '@shared/modules/Timesheet/components/TimesheetEntryCard';
import CreateTimesheetBlockForm from '@shared/modules/Timesheet/forms/CreateTimesheetBlockForm';
import TimesheetBlockForm from '@shared/modules/Timesheet/forms/TimesheetBlockForm';

const getValidTimesheetPayrollEntries = ({form, field, job}) => {
  const timesheetPayrollEntries = _.flatten(
    job.timesheetBillableEntries.map((billableEntry) => billableEntry.timesheetPayrollEntries),
  );
  const createTimesheetBlockForm = _.get(form.values, `${field}.createTimesheetBlockForms.0`);
  const {rangeFrom, rangeTo} = CreateTimesheetBlockForm.toMutation(createTimesheetBlockForm);
  const validTimesheetPayrollEntries = timesheetPayrollEntries.filter((entry) => {
    const overlaps = entry.effectiveTimesheetBlocks.map((block) => {
      if (!block.rangeFrom || !block.rangeTo) {
        return false;
      }
      const blockFromDatetime = Datetime.fromDatetime(block.rangeFrom, true);
      const blockToDatetime = Datetime.fromDatetime(block.rangeTo, true);
      return blockFromDatetime.isBefore(rangeTo) && rangeFrom.isBefore(blockToDatetime);
    });
    return !overlaps.some(Boolean);
  });

  // Sort primarily by position then by user fullName.
  return validTimesheetPayrollEntries.sort((a, b) => {
    return a.user.fullName.localeCompare(b.user.fullName);
  });
};

const useSetValidEntriesHandler = ({form, field, job, setValidEntries}) => {
  const startTimeInput = _.get(form.values, `${field}.createTimesheetBlockForms.0.rangeFrom`);
  const endTimeInput = _.get(form.values, `${field}.createTimesheetBlockForms.0.rangeTo`);
  const entriesField = `${field}.timesheetPayrollEntryIds`;

  useEffect(() => {
    const isValidStartTime = TimesheetBlockForm.getIsValidTime({time: startTimeInput});
    const isValidEndTime = TimesheetBlockForm.getIsValidTime({time: endTimeInput});
    if (isValidStartTime && isValidEndTime) {
      form.setFieldValue(entriesField, []);
      setValidEntries(getValidTimesheetPayrollEntries({form, field, job}));
    }
  }, [startTimeInput, endTimeInput]); // eslint-disable-line react-hooks/exhaustive-deps
};

const TimesheetPayrollEntryCard = ({timesheetEntry: timesheetPayrollEntry, form, field}) => {
  if (!timesheetPayrollEntry) {
    return <TimesheetEntryCard.Placeholder />;
  }

  const timesheetPayrollEntryIdsField = `${field}.timesheetPayrollEntryIds`;
  const selectedIds = _.get(form.values, timesheetPayrollEntryIdsField);
  const isSelected = selectedIds.includes(timesheetPayrollEntry.id);
  const {moverPosition, user} = timesheetPayrollEntry;

  return (
    <TimesheetEntryCard
      isSelected={isSelected}
      onPress={() => {
        const updatedIds = _.xor(selectedIds, [timesheetPayrollEntry.id]);
        form.setFieldValue(timesheetPayrollEntryIdsField, updatedIds);
      }}
      label={user.fullName}
      description={moverPosition.name}
    />
  );
};

const TimesheetPayrollEntrySelector = ({form, field, job}) => {
  const [validEntries, setValidEntries] = useState([]);

  useSetValidEntriesHandler({form, field, job, setValidEntries});

  const timesheetPayrollEntryIdsError = _.get(form.errors, `${field}.timesheetPayrollEntryIds`);

  return (
    <TimesheetEntriesSlideViewer
      timesheetEntries={validEntries}
      timesheetEntryIdsError={timesheetPayrollEntryIdsError}
      TimesheetEntryCardComponent={TimesheetPayrollEntryCard}
      timesheetEntryCardComponentProps={{form, field}}
      emptyMessage={'All movers have already logged an activity that conflicts with this time.'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TimesheetPayrollEntrySelector.fragment = gql`
  fragment TimesheetPayrollEntrySelector on Job {
    id
    timesheetBillableEntries {
      id
      timesheetPayrollEntries {
        id
        moverPosition {
          id
          name
        }
        user {
          id
          fullName
        }
        effectiveTimesheetBlocks {
          id
          rangeFrom
          rangeTo
        }
      }
    }
  }
`;

export default TimesheetPayrollEntrySelector;
