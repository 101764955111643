// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SalesTaxTemplateForm, {
  SalesTaxTemplateFormType,
} from '@shared/modules/Billing/forms/SalesTaxTemplateForm';

const useUpsertProjectTypeSalesTaxTemplateMutation = ({
  salesTaxTemplateForm,
  onSuccess,
  onError,
}: {
  salesTaxTemplateForm: SalesTaxTemplateFormType;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}) => {
  const form = useForm<SalesTaxTemplateFormType>({
    initialValues: {
      salesTaxTemplateForm: SalesTaxTemplateForm.toForm(salesTaxTemplateForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpsertProjectTypeSalesTaxTemplateMutation.mutation,
    variables: {
      salesTaxTemplateForm: SalesTaxTemplateForm.toMutation(form.values.salesTaxTemplateForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpsertProjectTypeSalesTaxTemplateMutation.mutation = gql`
  mutation useUpsertProjectTypeSalesTaxTemplateMutation($salesTaxTemplateForm: SalesTaxTemplateForm!) {
    response: upsertProjectTypeSalesTaxTemplate(salesTaxTemplateForm: $salesTaxTemplateForm) {
      ${gql.errors}
      salesTaxTemplate {
        id
      }
    }
  }
`;

export default useUpsertProjectTypeSalesTaxTemplateMutation;
