const _new = () => {
  return {
    data: null,
  };
};

const toForm = ({data}) => ({
  data,
});

const toMutation = ({data}) => ({
  data,
});

const BulkStorageCustomersForm = {
  new: _new,
  toForm,
  toMutation,
};

export default BulkStorageCustomersForm;
