// Supermove
import {gql} from '@supermove/graphql';
import {Currency, withFragment} from '@supermove/utils';

const edit = withFragment(
  (commercialMaterialType) => ({
    commercialMaterialTypeId: commercialMaterialType.id,
    organizationId: commercialMaterialType.organizationId,
    name: commercialMaterialType.name,
    price: commercialMaterialType.price,
  }),
  gql`
    fragment CommercialMaterialTypeForm_edit on CommercialMaterialType {
      id
      organizationId
      name
      price
    }
  `,
);

const _new = ({organizationId}) => ({
  commercialMaterialTypeId: undefined,
  organizationId,
  name: '',
  price: 0,
});

const toForm = ({commercialMaterialTypeId, organizationId, name, price}) => ({
  commercialMaterialTypeId,
  organizationId,
  name,
  price: Currency.toForm(price),
});

const toMutation = ({commercialMaterialTypeId, organizationId, name, price}) => ({
  commercialMaterialTypeId,
  organizationId,
  name,
  price: Currency.toMutation(price),
});

const CommercialMaterialTypeForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default CommercialMaterialTypeForm;
