// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ProjectTypeForm from '@shared/modules/Project/forms/ProjectTypeForm';

const useUpdateProjectTypeIndexMutation = ({projectTypeForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      projectTypeForm: ProjectTypeForm.toForm(projectTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectTypeIndexMutation.mutation,
    variables: {
      projectTypeForm: ProjectTypeForm.toMutation(form.values.projectTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateProjectTypeIndexMutation.mutation = gql`
  mutation useUpdateProjectTypeIndexMutation($projectTypeForm: ProjectTypeForm!) {
    response: updateProjectTypeIndex(projectTypeForm: $projectTypeForm) {
      ${gql.errors}
      projectType {
        id
        index
      }
    }
  }
`;

export default useUpdateProjectTypeIndexMutation;
