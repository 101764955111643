// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import JobNotesForm from '@shared/modules/Job/forms/JobNotesForm';

const useUpdateJobNotesMutation = ({jobNotesForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      jobNotesForm: JobNotesForm.toForm(jobNotesForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateJobNotesMutation.mutation,
    variables: {
      jobNotesForm: JobNotesForm.toMutation(form.values.jobNotesForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateJobNotesMutation.mutation = gql`
  mutation useUpdateJobNotesMutation($jobNotesForm: JobNotesForm!) {
    response: updateJobNotes(jobNotesForm: $jobNotesForm) {
      ${gql.errors}
      job {
        id
        dispatchNotes
      }
    }
  }
`;

export default useUpdateJobNotesMutation;
