// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const CardButton = Styled.ButtonV2`
  padding: 12px;
  background-color: ${({isSelected}) => (isSelected ? colors.blue.accent : colors.white)}
  border-radius: 4px;
  border-width: 1px;
  border-color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.gray.secondary)};
`;

const UserName = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${({color}) => color};
`;

const MoverPositionText = Styled.Text`
  ${Typography.Body}
  color: ${({color}) => color};
`;

const PlaceholderCard = () => {
  return <Space style={{flex: 1}} />;
};

const TimesheetEntryCard = ({isSelected, isDisabled, onPress, label, description, responsive}) => {
  const textColor = isSelected ? colors.blue.interactive : colors.gray.secondary;

  return (
    <Container style={{flex: 1}}>
      <CardButton isSelected={isSelected} disabled={isDisabled} onPress={onPress}>
        <Row>
          <UserName color={textColor} numberOfLines={1} responsive={responsive}>
            {label}
          </UserName>
          <Space style={{flex: 1}} />
          {isSelected && <Icon source={Icon.CircleCheck} size={16} color={textColor} />}
        </Row>
        <Space height={4} />
        <MoverPositionText color={textColor}>{description}</MoverPositionText>
      </CardButton>
    </Container>
  );
};

TimesheetEntryCard.Placeholder = PlaceholderCard;

export default TimesheetEntryCard;
