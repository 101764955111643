// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDebouncedCallback, useModal, useNavigationDOM, useUrlFilters} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SearchBar from '@shared/design/components/SearchBar';
import BulkEditClaimStatusModal from 'modules/Claim/components/BulkEditClaimStatusModal';
import ClaimFiltersButton from 'modules/Claim/components/ClaimFiltersButton';

const FiltersRow = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const SelectedItemsText = Styled.Text`
  ${Typography.Label}
  color: ${({color}) => color};
`;

const SearchInput = ({urlFilters, handleResetSelections}) => {
  const {params} = useNavigationDOM();
  const handleUpdateQuery = useDebouncedCallback((text) => {
    urlFilters.handleUpdate({query: text});
    handleResetSelections();
  }, 500);
  return (
    <SearchBar
      onChangeText={handleUpdateQuery}
      placeholder='Search by project identifier, client name, or claim identifier'
      style={{width: '436px'}}
      defaultValue={params.query}
    />
  );
};

const ListClaimsFilters = ({organization, selectedClaims, handleResetSelections, refetch}) => {
  const editClaimStatusModal = useModal({
    name: 'Edit Claim Status Modal',
  });
  const urlFilters = useUrlFilters({
    getRoute: () => '/claims',
    filtersForUpdate: {page: '1'},
    onUpdate: handleResetSelections,
    filterKeys: [
      'query',
      'claimTypeIds',
      'projectTypeIds',
      'projectTagIds',
      'projectStatuses',
      'minAmount',
      'maxAmount',
      'slugs',
    ],
  });

  return (
    <React.Fragment>
      <FiltersRow>
        <SearchInput urlFilters={urlFilters} handleResetSelections={handleResetSelections} />
        <Space width={16} />
        <ClaimFiltersButton urlFilters={urlFilters} organization={organization} />
        <Space style={{flex: 1}} />
        <SelectedItemsText
          color={selectedClaims.length ? colors.blue.interactive : colors.gray.tertiary}
        >{`${pluralize('claim', _.size(selectedClaims), true)} selected`}</SelectedItemsText>
        <Space width={16} />
        <Button
          text={'Edit Claim Status'}
          isDisabled={_.size(selectedClaims) === 0}
          onPress={editClaimStatusModal.handleOpen}
        />
      </FiltersRow>
      <BulkEditClaimStatusModal
        key={editClaimStatusModal.key}
        isOpen={editClaimStatusModal.isOpen}
        handleClose={editClaimStatusModal.handleClose}
        selectedClaims={selectedClaims}
        handleResetSelections={handleResetSelections}
        organization={organization}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListClaimsFilters.fragment = gql`
  ${BulkEditClaimStatusModal.fragment}
  ${ClaimFiltersButton.fragment}
  fragment ListClaimsFilters on Organization {
    id
    ...BulkEditClaimStatusModal
    ...ClaimFiltersButton
  }
`;

export default ListClaimsFilters;
