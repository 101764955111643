// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

// Shared
import TripStatus from '@shared/modules/Dispatch/enums/TripStatus';
import ShipmentForm from '@shared/modules/Dispatch/forms/ShipmentForm';
import LocationForm from '@shared/modules/Location/forms/LocationForm';

const _getShipmentIdsFormShipmentForms = (shipmentForms) => {
  const shipmentIds = _.reduce(
    shipmentForms,
    (result, shipmentForm) => [...result, shipmentForm.shipmentId],
    [],
  );
  return shipmentIds;
};

const _getTripLocationsAndDatesFromShipments = withFragment(
  ({shipments}) => {
    // First, we need to get a master list of all end dates mapped to corresponding location
    // Pack and load should map to start location, and delivery to end location
    const allEndDatesToProject = [];

    _.forEach(shipments, (shipment) => {
      const {
        packJobEndDate,
        packJobScheduledDate,
        loadJobEndDate,
        loadJobScheduledDate,
        deliveryJobEndDate,
        deliveryJobScheduledDate,
      } = shipment.project;

      if (packJobScheduledDate) {
        allEndDatesToProject.push({
          date: packJobScheduledDate,
          location: shipment.project.startLocation,
        });
      } else if (packJobEndDate) {
        allEndDatesToProject.push({
          date: packJobEndDate,
          location: shipment.project.startLocation,
        });
      }

      if (loadJobScheduledDate) {
        allEndDatesToProject.push({
          date: loadJobScheduledDate,
          location: shipment.project.startLocation,
        });
      } else if (loadJobEndDate) {
        allEndDatesToProject.push({
          date: loadJobEndDate,
          location: shipment.project.startLocation,
        });
      }

      if (deliveryJobScheduledDate) {
        allEndDatesToProject.push({
          date: deliveryJobScheduledDate,
          location: shipment.project.endLocation,
        });
      } else if (deliveryJobEndDate) {
        allEndDatesToProject.push({
          date: deliveryJobEndDate,
          location: shipment.project.endLocation,
        });
      }
    });

    // For the origin date and location, we find the earliest date
    const origin = _.minBy(allEndDatesToProject, (endDateToProject) => endDateToProject.date);

    // For the destination date and location, we find the latest date
    const destination = _.maxBy(allEndDatesToProject, (endDateToProject) => endDateToProject.date);

    return {
      startDate: origin && origin.date,
      startLocation: origin && origin.location,
      endDate: destination && destination.date,
      endLocation: destination && destination.location,
    };
  },
  gql`
    ${LocationForm.edit.fragment}
    fragment TripForm_getTripLocationsAndDatesFromShipments on Shipment {
      id
      project {
        id
        packJobEndDate
        packJobScheduledDate
        deliveryJobEndDate
        deliveryJobScheduledDate
        loadJobEndDate
        loadJobScheduledDate
        startLocation {
          id
          ...LocationForm_edit
        }
        endLocation {
          id
          ...LocationForm_edit
        }
      }
    }
  `,
);

const newWithShipments = withFragment(
  ({organizationId, shipments}) => {
    const {startDate, startLocation, endDate, endLocation} = _getTripLocationsAndDatesFromShipments(
      {shipments},
    );

    return {
      tripId: null,
      organizationId,
      name: '',
      status: TripStatus.PENDING,
      driverId: null,
      officeNotes: '',
      driverNotes: '',
      truckIds: [],

      // Private
      shipmentForms: shipments ? shipments.map((shipment) => ShipmentForm.edit(shipment)) : [],
      tripUuid: null,
      startDate,
      startLocationForm: startLocation ? LocationForm.edit(startLocation) : LocationForm.new(),
      endDate,
      endLocationForm: endLocation ? LocationForm.edit(endLocation) : LocationForm.new(),
    };
  },
  gql`
    ${ShipmentForm.edit.fragment}
    ${_getTripLocationsAndDatesFromShipments.fragment}
    fragment TripForm_newWithShipments on Shipment {
      id
      ...ShipmentForm_edit
      ...TripForm_getTripLocationsAndDatesFromShipments
    }
  `,
);

const edit = withFragment(
  (trip) => ({
    tripId: trip.id,
    organizationId: trip.organizationId,
    name: trip.name,
    status: trip.status,
    driverId: trip.driverId,
    officeNotes: trip.officeNotes,
    driverNotes: trip.driverNotes,
    truckIds: trip.tripSegments[0].truckIds,

    // Private
    shipmentForms: trip.shipments.map((shipment) => ShipmentForm.edit(shipment)),
    tripUuid: trip.uuid,
    startDate: trip.startDate,
    startLocationForm: trip.startLocation
      ? LocationForm.edit(trip.startLocation)
      : LocationForm.new(),
    endDate: trip.endDate,
    endLocationForm: trip.endLocation ? LocationForm.edit(trip.endLocation) : LocationForm.new(),
  }),
  gql`
    ${LocationForm.edit.fragment}
    ${ShipmentForm.edit.fragment}
    fragment TripForm_edit on Trip {
      id
      uuid
      organizationId
      driverId
      name
      status
      startDate
      endDate
      officeNotes
      driverNotes
      startLocation {
        id
        ...LocationForm_edit
      }
      endLocation {
        id
        ...LocationForm_edit
      }
      shipments {
        id
        ...ShipmentForm_edit
      }
      tripSegments {
        id
        truckIds
      }
    }
  `,
);

const duplicate = withFragment(
  (trip) => ({
    tripId: null,
    organizationId: trip.organizationId,
    name: `Copy of ${trip.name}`,
    status: TripStatus.PENDING,
    startLocationForm: trip.startLocation
      ? LocationForm.edit(trip.startLocation)
      : LocationForm.new(),
    startDate: trip.startDate,
    endLocationForm: trip.endLocation ? LocationForm.edit(trip.endLocation) : LocationForm.new(),
    endDate: trip.endDate,
    driverId: trip.driverId,
    officeNotes: trip.officeNotes,
    driverNotes: trip.driverNotes,
    truckIds: trip.tripSegments[0].truckIds,

    // Private
    shipmentForms: [],
    tripUuid: null,
  }),
  gql`
    ${LocationForm.edit.fragment}
    fragment TripForm_duplicate on Trip {
      id
      organizationId
      driverId
      name
      status
      startDate
      endDate
      officeNotes
      driverNotes
      startLocation {
        id
        ...LocationForm_edit
      }
      endLocation {
        id
        ...LocationForm_edit
      }
      tripSegments {
        id
        truckIds
      }
    }
  `,
);

const toForm = ({
  tripId,
  organizationId,
  name,
  status,
  startLocationForm,
  startDate,
  endLocationForm,
  endDate,
  driverId,
  officeNotes,
  driverNotes,
  shipmentForms,
  tripUuid,
  truckIds,
}) => ({
  tripId,
  organizationId,
  name,
  status,
  startLocationForm: LocationForm.toForm(startLocationForm),
  startDate: Datetime.toFormDate(startDate),
  endLocationForm: LocationForm.toForm(endLocationForm),
  endDate: Datetime.toFormDate(endDate),
  driverId: _.toString(driverId),
  officeNotes,
  driverNotes,
  truckIds: truckIds.map((truckId) => _.toString(truckId)),

  // Private
  shipmentForms: shipmentForms.map((shipmentForm) => ShipmentForm.toForm(shipmentForm)),
  tripUuid,
});

const toMutation = ({
  tripId,
  organizationId,
  name,
  status,
  startLocationForm,
  startDate,
  endLocationForm,
  endDate,
  driverId,
  officeNotes,
  driverNotes,
  shipmentForms,
  truckIds,
}) => ({
  tripId,
  organizationId,
  name,
  status,
  startLocationForm: startLocationForm.address ? LocationForm.toMutation(startLocationForm) : null,
  startDate: startDate ? Datetime.toMutationDate(startDate) : null,
  endLocationForm: endLocationForm.address ? LocationForm.toMutation(endLocationForm) : null,
  endDate: endDate ? Datetime.toMutationDate(endDate) : null,
  driverId: driverId ? _.toNumber(driverId) : null,
  officeNotes,
  driverNotes,
  shipmentIds: _getShipmentIdsFormShipmentForms(shipmentForms),
  truckIds: truckIds.map((truckId) => _.toNumber(truckId)),
});

const TripForm = {
  newWithShipments,
  edit,
  duplicate,
  toForm,
  toMutation,
};

export default TripForm;
