// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, ScrollView, DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Responsive, useNavigationDOM, useQuery, useResponsive, useToggle} from '@supermove/hooks';
import {ProjectTypeModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import InvoicePaymentTerm from '@shared/modules/Billing/enums/InvoicePaymentTerm';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import SalesTaxTemplateForm from '@shared/modules/Billing/forms/SalesTaxTemplateForm';
import useUpsertProjectTypeSalesTaxTemplateMutation from '@shared/modules/Billing/hooks/useUpsertProjectTypeSalesTaxTemplateMutation';
import DocumentTemplateCategory from '@shared/modules/Document/enums/DocumentTemplateCategory';
import ProjectTypeBillingSettingsForm from '@shared/modules/Project/forms/ProjectTypeBillingSettingsForm';
import useUpdateProjectTypeAccountingSettingsMutation, {
  UpdateProjectTypeAccountingSettingsMutationFormType,
} from '@shared/modules/Project/hooks/useUpdateProjectTypeAccountingSettingsMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import AccountingBillingSettingsPanel from 'modules/Organization/Settings/ProjectTypes/components/AccountingBillingSettingsPanel';
import ProjectTypeSalesTaxTemplatePanel from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSalesTaxTemplatePanel';
import ProjectTypeSettingsPageHeader from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSettingsPageHeader';

const View = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const LinkTextContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link};
`;

const Label = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
`;

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const PageHeading = Styled.Text`
  ${Typography.Responsive.PageHeading};
`;

const PageDescription = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${colors.gray.secondary};
`;

interface ShowInvoiceSettingsPanelProps {
  projectType: ProjectTypeModel;
  responsive: Responsive;
}

const ShowInvoiceSettingsPanel = ({projectType, responsive}: ShowInvoiceSettingsPanelProps) => {
  return (
    <>
      <Row>
        <View>
          <EditPanel.LabelText>Automatically finalize invoices</EditPanel.LabelText>
          <Space height={8} />
          <EditPanel.Text>
            {projectType.isEnabledAutomaticallyFinalizeInvoices ? 'Yes' : 'No'}
          </EditPanel.Text>
        </View>
      </Row>
      <Space height={24} />
      <Row>
        <View>
          <EditPanel.LabelText>Invoice Terms</EditPanel.LabelText>
          <Space height={8} />
          <EditPanel.Text>
            {InvoicePaymentTerm.getDisplayName(projectType.defaultInvoicePaymentTerm)}
          </EditPanel.Text>
        </View>
      </Row>
      <Space height={24} />
      <Row>
        <View>
          <EditPanel.LabelText>Invoice Document</EditPanel.LabelText>
          <Space height={8} />
          {projectType.defaultInvoiceDocumentTemplate ? (
            <a
              href={`/settings/documents/document-templates/${projectType.defaultInvoiceDocumentTemplate.uuid}`}
              target={'_blank'}
            >
              <LinkText responsive={responsive}>
                {projectType.defaultInvoiceDocumentTemplate.name}
              </LinkText>
            </a>
          ) : (
            <EditPanel.Text>None selected</EditPanel.Text>
          )}
        </View>
      </Row>
    </>
  );
};

interface EditInvoiceSettingsPanelProps {
  projectType: ProjectTypeModel;
  form: UpdateProjectTypeAccountingSettingsMutationFormType;
}

const EditInvoiceSettingsPanel = ({form, projectType}: EditInvoiceSettingsPanelProps) => {
  return (
    <>
      <Row>
        <View>
          <Row>
            <SwitchField
              form={form}
              field={'projectTypeBillingSettingsForm.isEnabledAutomaticallyFinalizeInvoices'}
              labelRight={'Automatically finalize invoices'}
            />
          </Row>
          <Space height={8} />
          <EditPanel.Text style={{color: colors.gray.secondary}}>
            When enabled, invoices will automatically be locked and finalized when the final job is
            complete and remaining balance across all invoices is $0. When disabled, invoices must
            be manually finalized.
          </EditPanel.Text>
        </View>
      </Row>
      <Space height={24} />
      <Row>
        <View>
          <Row>
            <FieldInput
              {...form}
              index={0}
              label={'Invoice Terms'}
              name={`projectTypeBillingSettingsForm.defaultInvoicePaymentTerm`}
              component={DropdownInput}
              input={{
                isPortaled: true,
                options: InvoicePaymentTerm.DROPDOWN_OPTIONS,
                setFieldValue: form.setFieldValue,
                style: {width: '360px'},
              }}
            />
          </Row>
          <Space height={8} />
          <EditPanel.Text style={{color: colors.gray.secondary}}>
            Set the invoice terms for this project type. You can override this default when
            previewing and sending an invoice in the project.
          </EditPanel.Text>
        </View>
      </Row>
      <Space height={24} />
      <Row>
        <View>
          <Row>
            <FieldInput
              {...form}
              index={1}
              label={'Invoice Document'}
              name={`projectTypeBillingSettingsForm.defaultInvoiceDocumentTemplateId`}
              component={DropdownInput}
              input={{
                isPortaled: true,
                options: projectType.organization.documentTemplatesByCategory.map(
                  (documentTemplate) => {
                    return {
                      value: _.toNumber(documentTemplate.id),
                      label: documentTemplate.name,
                    };
                  },
                ),
                setFieldValue: form.setFieldValue,
                style: {width: '360px'},
              }}
            />
          </Row>
          <Space height={8} />
          <EditPanel.Text style={{color: colors.gray.secondary}}>
            Select the default invoice document that will be sent to the customer. You can override
            the default when previewing and sending an invoice in the project.
          </EditPanel.Text>
        </View>
      </Row>
    </>
  );
};

interface ShowEmailSettingsPanelProps {
  projectType: ProjectTypeModel;
  responsive: Responsive;
}

const ShowEmailSettingsPanel = ({projectType, responsive}: ShowEmailSettingsPanelProps) => {
  const {
    defaultInvoiceEmailTemplate,
    defaultInvoiceReceiptEmailTemplate,
    shouldAttachDocumentsInInvoiceEmail,
    shouldAttachDocumentsInReceiptEmail,
  } = projectType;
  return (
    <>
      <Row>
        <View>
          <Label responsive={responsive}>Invoice Email</Label>
          <Space height={8} />
          {defaultInvoiceEmailTemplate ? (
            <a href={'/settings/communication/email-templates'} target={'_blank'}>
              <LinkTextContainer>
                <Icon source={Icon.ExternalLinkAlt} color={colors.blue.interactive} size={12} />
                <Space width={4} />
                <LinkText responsive={responsive}>{defaultInvoiceEmailTemplate.name}</LinkText>
              </LinkTextContainer>
            </a>
          ) : (
            <EditPanel.Text>None</EditPanel.Text>
          )}
        </View>
        <View>
          <Label responsive={responsive}>Include PDFs in Invoice Emails</Label>
          <Space height={8} />
          <EditPanel.Text>{shouldAttachDocumentsInInvoiceEmail ? 'Yes' : 'No'}</EditPanel.Text>
        </View>
      </Row>
      <Space height={24} />
      <Row>
        <View>
          <Label responsive={responsive}>Receipt Email</Label>
          <Space height={8} />
          {defaultInvoiceReceiptEmailTemplate ? (
            <a href={'/settings/communication/email-templates'} target={'_blank'}>
              <LinkTextContainer>
                <Icon source={Icon.ExternalLinkAlt} color={colors.blue.interactive} size={12} />
                <Space width={4} />
                <LinkText responsive={responsive}>
                  {defaultInvoiceReceiptEmailTemplate.name}
                </LinkText>
              </LinkTextContainer>
            </a>
          ) : (
            <EditPanel.Text>None</EditPanel.Text>
          )}
        </View>
        <View>
          <Label responsive={responsive}>Include PDFs in Receipt Emails</Label>
          <Space height={8} />
          <EditPanel.Text>{shouldAttachDocumentsInReceiptEmail ? 'Yes' : 'No'}</EditPanel.Text>
        </View>
      </Row>
    </>
  );
};

interface EditEmailSettingsPanelProps {
  form: UpdateProjectTypeAccountingSettingsMutationFormType;
  projectType: ProjectTypeModel;
  responsive: Responsive;
}

const EditEmailSettingsPanel = ({form, projectType, responsive}: EditEmailSettingsPanelProps) => {
  const {invoiceEmailTemplates, invoiceReceiptEmailTemplates} = projectType.organization;

  return (
    <>
      <Row>
        <View>
          <Row>
            <FieldInput
              {...form}
              index={0}
              label={'Invoice Email'}
              isRequired
              name={`projectTypeBillingSettingsForm.defaultInvoiceEmailTemplateId`}
              component={DropdownInput}
              input={{
                isPortaled: true,
                options: _.isEmpty(invoiceEmailTemplates)
                  ? []
                  : invoiceEmailTemplates.map((emailTemplate) => {
                      return {
                        value: _.toNumber(emailTemplate.id),
                        label: emailTemplate.name,
                      };
                    }),
                setFieldValue: form.setFieldValue,
                style: {width: '360px'},
              }}
            />
          </Row>
          <Space height={8} />
          <EditPanel.Text style={{color: colors.gray.secondary}}>
            Select the default invoice email that will be sent to the customer. You can override the
            default when previewing and sending an invoice in the project.
          </EditPanel.Text>
        </View>
      </Row>
      <Space height={18} />
      <Row>
        <SwitchField
          form={form}
          field={'projectTypeBillingSettingsForm.shouldAttachDocumentsInInvoiceEmail'}
        />
        <Space width={8} />
        <View>
          <EditPanel.Text>Include PDFs in Invoice Emails</EditPanel.Text>
          <Space height={4} />
          <MicroText responsive={responsive}>
            When enabled, all emails will automatically attach the most recent documents as PDFs to
            the invoice email sent to the customer.
          </MicroText>
        </View>
      </Row>
      <Space height={24} />
      <Row>
        <View>
          <Row>
            <SwitchField
              form={form}
              field={'projectTypeBillingSettingsForm.isReceiptEmailEnabled'}
              onChangeValue={(switchValue) => {
                if (!switchValue) {
                  form.setFieldValue(
                    'projectTypeBillingSettingsForm.defaultInvoiceReceiptEmailTemplateId',
                    null,
                  );
                }
              }}
            />
            <Space width={8} />
            <EditPanel.Text>Automatically Send Receipt After a Full Payment Is Made</EditPanel.Text>
          </Row>
          <Space height={16} />
          {_.get(form.values, 'projectTypeBillingSettingsForm.isReceiptEmailEnabled') && (
            <>
              <Row>
                <FieldInput
                  {...form}
                  index={1}
                  label={'Receipt Email'}
                  isRequired
                  name={'projectTypeBillingSettingsForm.defaultInvoiceReceiptEmailTemplateId'}
                  component={DropdownInput}
                  input={{
                    isPortaled: true,
                    options: _.isEmpty(invoiceReceiptEmailTemplates)
                      ? []
                      : invoiceReceiptEmailTemplates.map((receiptEmailTemplate) => {
                          return {
                            value: _.toNumber(receiptEmailTemplate.id),
                            label: receiptEmailTemplate.name,
                          };
                        }),
                    setFieldValue: form.setFieldValue,
                    style: {width: '360px'},
                  }}
                />
              </Row>
              <Space height={8} />
              <EditPanel.Text style={{color: colors.gray.secondary}}>
                Select the default receipt email that will be sent to the customer. You can override
                the default when previewing and sending an invoice in the project.
              </EditPanel.Text>
            </>
          )}
        </View>
      </Row>
      {form.values.projectTypeBillingSettingsForm.isReceiptEmailEnabled && (
        <React.Fragment>
          <Space height={18} />
          <Row>
            <SwitchField
              form={form}
              field={'projectTypeBillingSettingsForm.shouldAttachDocumentsInReceiptEmail'}
            />
            <Space width={8} />
            <View>
              <EditPanel.Text>Include PDFs in Receipt Emails</EditPanel.Text>
              <Space height={4} />
              <MicroText responsive={responsive} style={{color: colors.gray.secondary}}>
                When enabled, all emails will automatically attach the most recent documents as PDFs
                to the receipt email sent to the customer.
              </MicroText>
            </View>
          </Row>
        </React.Fragment>
      )}
    </>
  );
};

interface AccountSettingsSectionsProps {
  projectType: ProjectTypeModel;
  index: number;
  refetch: () => void;
}

const AccountingSettingsSections = ({
  projectType,
  index,
  refetch,
}: AccountSettingsSectionsProps) => {
  const editInvoiceSettingsToggle = useToggle();
  const editEmailSettingsToggle = useToggle();

  const projectTypeBillingSettingsForm = ProjectTypeBillingSettingsForm.edit(projectType);
  const {form, submitting, handleSubmit} = useUpdateProjectTypeAccountingSettingsMutation({
    projectTypeBillingSettingsForm,
    onSuccess: () => {
      editInvoiceSettingsToggle.handleToggleOff();
      editEmailSettingsToggle.handleToggleOff();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <>
      <EditPanel
        index={index}
        bodyComponentProps={{projectType}}
        editBodyComponentProps={{form, projectType}}
        BodyComponent={ShowInvoiceSettingsPanel}
        EditBodyComponent={EditInvoiceSettingsPanel}
        title={'Invoices'}
        handleSave={handleSubmit}
        isSubmitting={submitting}
        handleCancel={form.handleReset}
        isEditing={editInvoiceSettingsToggle.isOn}
        handleEdit={editInvoiceSettingsToggle.handleToggleOn}
        handleClose={editInvoiceSettingsToggle.handleToggleOff}
      />
      <Space height={24} />
      <EditPanel
        index={index + 1}
        bodyComponentProps={{projectType}}
        editBodyComponentProps={{form, projectType}}
        BodyComponent={ShowEmailSettingsPanel}
        EditBodyComponent={EditEmailSettingsPanel}
        title={'Emails'}
        handleSave={handleSubmit}
        isSubmitting={submitting}
        handleCancel={form.handleReset}
        isEditing={editEmailSettingsToggle.isOn}
        handleEdit={editEmailSettingsToggle.handleToggleOn}
        handleClose={editEmailSettingsToggle.handleToggleOff}
      />
    </>
  );
};

interface SalesTaxSectionProps {
  projectType: ProjectTypeModel;
  index: number;
  refetch: () => void;
}

const SalesTaxSection = ({projectType, index, refetch}: SalesTaxSectionProps) => {
  const editSalesTaxToggle = useToggle();
  const {organization} = projectType;
  const salesTaxTemplate = projectType.salesTaxTemplate || organization.settings.salesTaxTemplate;
  const salesTaxTemplateForm = SalesTaxTemplateForm.edit(salesTaxTemplate);
  const {form, submitting, handleSubmit} = useUpsertProjectTypeSalesTaxTemplateMutation({
    salesTaxTemplateForm,
    onSuccess: () => {
      refetch();
      editSalesTaxToggle.handleToggleOff();
    },
    onError: (errors: unknown) => {
      console.log({errors});
    },
  });
  const variableOptions = organization.variables
    .filter((variable) =>
      _.includes([VariableFormat.PERCENT, VariableFormat.DROPDOWN_PERCENT], variable.format),
    )
    .map((variable) => ({
      label: variable.name,
      value: variable.id,
    }));
  const updatedAtDisplay = Datetime.convertToDisplayDatetime(
    salesTaxTemplate.updatedAt,
    'MM/DD/YY [at] h:mm A',
  );

  return (
    <EditPanel
      index={index}
      HeaderComponent={ProjectTypeSalesTaxTemplatePanel.Header}
      headerComponentProps={{
        projectTypeName: projectType.name,
        isEditing: editSalesTaxToggle.isOn,
        handleEdit: editSalesTaxToggle.handleToggleOn,
      }}
      BodyComponent={ProjectTypeSalesTaxTemplatePanel}
      bodyComponentProps={{salesTaxTemplate}}
      EditBodyComponent={ProjectTypeSalesTaxTemplatePanel.Edit}
      editBodyComponentProps={{
        form,
        field: 'salesTaxTemplateForm',
        variableOptions,
        lastUpdatedDisplay: `${updatedAtDisplay} by ${salesTaxTemplate.updatedBy.fullName}`,
        projectTypeId: projectType.id,
        settingsSalesTaxTemplate: organization.settings.salesTaxTemplate,
      }}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editSalesTaxToggle.isOn}
      handleEdit={editSalesTaxToggle.handleToggleOn}
      handleClose={editSalesTaxToggle.handleToggleOff}
    />
  );
};

interface ProjectTypeAccountingSettingsContentProps {
  projectTypeUuid: string;
}

const ProjectTypeAccountingSettingsContent = ({
  projectTypeUuid,
}: ProjectTypeAccountingSettingsContentProps) => {
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(ProjectTypeAccountingSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectTypeUuid,
      categories: [DocumentTemplateCategory.INVOICE],
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <View>
      <ProjectTypeSettingsPageHeader projectType={data.projectTypeByUuid} />
      <ScrollView
        horizontal
        contentContainerStyle={{backgroundColor: colors.gray.background, flexGrow: 1}}
      >
        <ScrollView contentContainerStyle={{flex: 1, paddingHorizontal: 24}}>
          <Space height={24} />
          <PageHeading responsive={responsive}>Accounting</PageHeading>
          <Space height={16} />
          <PageDescription
            responsive={responsive}
          >{`Manage billing, sales tax, invoice settings, and invoice emails for ${data.projectTypeByUuid.name}.`}</PageDescription>
          <Space height={24} />
          <AccountingBillingSettingsPanel
            projectType={data.projectTypeByUuid}
            index={0}
            refetch={refetch}
          />
          <Space height={24} />
          <SalesTaxSection projectType={data.projectTypeByUuid} index={1} refetch={refetch} />
          <Space height={24} />
          <AccountingSettingsSections
            projectType={data.projectTypeByUuid}
            index={2}
            refetch={refetch}
          />
          <Space height={100} />
        </ScrollView>
      </ScrollView>
    </View>
  );
};

const ProjectTypeAccountingSettingsPage = () => {
  const {params} = useNavigationDOM();

  return (
    <SidebarPageV2 selected={'settings'}>
      <ProjectTypeAccountingSettingsContent projectTypeUuid={params.projectTypeUuid} />
    </SidebarPageV2>
  );
};

ProjectTypeAccountingSettingsPage.query = gql`
  ${AccountingBillingSettingsPanel.fragment}
  ${ProjectTypeSettingsPageHeader.fragment}
  ${ProjectTypeBillingSettingsForm.edit.fragment}
  ${ProjectTypeSalesTaxTemplatePanel.fragment}
  ${SalesTaxTemplateForm.edit.fragment}

  fragment ProjectTypeAccountingSettingsPage_SalesTaxTemplate on SalesTaxTemplate {
    id
    updatedAt
    updatedBy {
      id
      fullName
    }
    ...ProjectTypeSalesTaxTemplatePanel
    ...SalesTaxTemplateForm_edit
  }

  query ProjectTypeAccountingSettingsPage(
    $projectTypeUuid: String!,
    $categories: [String]!,
  ) {
    ${gql.query}

    viewer {
      id
      viewingOrganization {
        id
      }
    }

    projectTypeByUuid(projectTypeUuid: $projectTypeUuid) {
      id
      name
      isEnabledAutomaticallyFinalizeInvoices
      defaultInvoicePaymentTerm
      defaultInvoiceDocumentTemplate {
        id
        name
        uuid
      }
      defaultInvoiceEmailTemplate {
        id
        name
      }
      defaultInvoiceReceiptEmailTemplate {
        id
        name
      }
      organization {
        id
        documentTemplatesByCategory(categories: $categories) {
          id
          name
        }
        invoiceEmailTemplates: emailTemplatesByKind(emailTemplateKinds: ["CUSTOM_INVOICE"]) {
          id
          name
        }
        invoiceReceiptEmailTemplates: emailTemplatesByKind(emailTemplateKinds: ["CUSTOM_INVOICE_RECEIPT"]) {
          id
          name
        }
        settings {
          id
          salesTaxTemplate {
            id
            ...ProjectTypeAccountingSettingsPage_SalesTaxTemplate
            ...ProjectTypeSalesTaxTemplatePanel_SettingsSalesTaxTemplate
          }
        }
        variables {
          id
          name
          format
        }
      }
      salesTaxTemplate {
        id
        ...ProjectTypeAccountingSettingsPage_SalesTaxTemplate
      }
      ...AccountingBillingSettingsPanel
      ...ProjectTypeSettingsPageHeader
      ...ProjectTypeBillingSettingsForm_edit
    }
  }
`;

export default ProjectTypeAccountingSettingsPage;
