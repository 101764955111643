// Libararies
import _ from 'lodash';
import React from 'react';
import {useLocation} from 'react-router-dom';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled, DragAndDropList} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useModal,
  useEffect,
  useResponsive,
  useState,
  useNavigationDOM,
  useDragAndDrop,
  useToast,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import {decodeBase64Url} from '@shared/modules/Reports/components/ExploDashboard';
import DashboardCategory from '@shared/modules/Reports/enums/DashboardCategory';
import ReorderReportDashboardsForm from '@shared/modules/Reports/forms/ReorderReportDashboardsForm';
import useReorderReportDashboardsMutation from '@shared/modules/Reports/hooks/useReorderReportDashboardsMutation';
import SidebarPage from 'modules/App/components/SidebarPage';
import CreateReportDashboardModal from 'modules/Organization/Settings/Reports/components/CreateReportDashboardModal';
import ReportDashboardItem from 'modules/Organization/Settings/Reports/components/ReportDashboardItem';
import ReportDashboardsPageHeader from 'modules/Organization/Settings/Reports/components/ReportDashboardsPageHeader';
import SettingsContent from 'modules/Organization/Settings/components/SettingsContent';

const PageContainer = Styled.View`
  flex: 1;
`;

const PageContentContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const PageHeaderText = Styled.Text`
  ${Typography.Heading4}
`;

const PageDescriptionText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const ReportDashboardsTitle = Styled.Text`
  ${Typography.Label1}
  color: ${colors.black};
`;

const filteredDashboards = ({dashboards, category}) => {
  return _.filter(dashboards, (dashboard) => dashboard.category === category);
};

const onReorder = ({form, fromIndex, toIndex, handleSubmit}) => {
  const dashboardForms = _.cloneDeep(form.values.reorderReportDashboardsForm.reportDashboardForms);
  const reorderedDashboardForms = List.move({list: dashboardForms, fromIndex, toIndex});
  reorderedDashboardForms.forEach((reportDashboardForm, index) => {
    reportDashboardForm.index = index;
  });
  form.setFieldValue('reorderReportDashboardsForm.reportDashboardForms', reorderedDashboardForms);
  setTimeout(handleSubmit, 0);
};

const ReportDashboardsContent = ({dashboards, isAccountingReports, refetch}) => {
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();
  const reorderReportDashboardsForm = ReorderReportDashboardsForm.edit(dashboards);
  const {form, handleSubmit} = useReorderReportDashboardsMutation({
    reorderReportDashboardsForm,
    onSuccess: () => {
      refetch();
      handleReorderEnd();
    },
    onError: () => {
      handleReorderEnd();
    },
  });

  return (
    <React.Fragment>
      <ReportDashboardsTitle>
        {isAccountingReports ? 'Accounting' : 'General'} ({dashboards.length})
      </ReportDashboardsTitle>
      <Space height={16} />
      <DragAndDropList
        isReordering={isReordering}
        onReorder={({fromIndex, toIndex}) => {
          handleReorderStart();
          onReorder({
            form,
            fromIndex,
            toIndex,
            handleSubmit,
          });
        }}
      >
        {form.values.reorderReportDashboardsForm.reportDashboardForms.map(
          (reportDashboardForm, index) => {
            return (
              <ReportDashboardItem
                key={reportDashboardForm.uuid}
                reportDashboardForm={reportDashboardForm}
                refetch={refetch}
                index={index}
                isFirstItem={index === 0}
                isLastItem={index === dashboards.length - 1}
              />
            );
          },
        )}
      </DragAndDropList>
    </React.Fragment>
  );
};

const OrganizationSettingsReportDashboardsPageContent = ({
  generalDashboards,
  accountingDashboards,
  organization,
  refetch,
}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const location = useLocation();
  const createReportDashboardModal = useModal({
    name: 'Create Report Dashboard Modal',
    onClose: () => {
      navigator.replace(navigator.location.pathname);
    },
  });
  const createReportDashboardSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: `Report created`,
    actionText: 'View Dashboard',
    handleAction: ({newReportDashboardUuid}) => {
      navigator.push(`/reports/dashboards/${newReportDashboardUuid}`);
    },
  });

  // Modal is opened based on the URL fragment, so that Explo dashboards can generate a link to save
  // a copy of a dashboard
  useEffect(() => {
    if (location.hash === '#create') {
      createReportDashboardModal.handleOpen();
    }
  }, [location.hash]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <ScrollView style={{flex: 1}}>
        <SettingsContent {...responsive} style={{marginTop: responsive.mobile ? 8 : 24}}>
          <Button
            iconLeft={Icon.Plus}
            text={'Create Report'}
            onPress={() => {
              navigator.replace(`${navigator.location.pathname}#create`);
            }}
          />
          <Space height={24} />
          <PageHeaderText>Manage Reports</PageHeaderText>
          <Space height={12} />
          <PageDescriptionText>
            Create and edit your reports. Move reports up and down in the order you want them to
            appear.
          </PageDescriptionText>
          <Space height={24} />
          <ReportDashboardsContent dashboards={generalDashboards} refetch={refetch} />
          <Space height={24} />
          <ReportDashboardsContent
            dashboards={accountingDashboards}
            isAccountingReports
            refetch={refetch}
          />
        </SettingsContent>
      </ScrollView>
      <CreateReportDashboardModal
        key={createReportDashboardModal.key}
        isOpen={createReportDashboardModal.isOpen}
        handleClose={createReportDashboardModal.handleClose}
        handleCreateSuccess={(dashboard) => {
          createReportDashboardSuccessToast.handleToast({
            actionPayload: {newReportDashboardUuid: dashboard.uuid},
          });
        }}
        organizationId={organization.id}
        name={params.name}
        exploDashboardEmbedId={params.exploDashboardEmbedId}
        exploVariables={decodeBase64Url(params.exploVariables)}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const OrganizationSettingsReportDashboardsPage = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  return (
    <SidebarPage selected={'settings'} query={OrganizationSettingsReportDashboardsPage.query}>
      {({data, refetch}) => {
        const generalDashboards = filteredDashboards({
          dashboards: data.viewer.viewingOrganization.dashboards,
          category: DashboardCategory.GENERAL,
        });
        const accountingDashboards = filteredDashboards({
          dashboards: data.viewer.viewingOrganization.dashboards,
          category: DashboardCategory.ACCOUNTING,
        });
        const refetchWithKeyUpdate = () => {
          refetch();
          setRefreshKey(refreshKey + 1);
        };
        return (
          <PageContainer>
            <Loading loading={!data}>
              {() => (
                <React.Fragment>
                  <ReportDashboardsPageHeader />
                  <PageContentContainer>
                    <OrganizationSettingsReportDashboardsPageContent
                      key={refreshKey} // This key is important to make sure list updates when report is added or deleted
                      generalDashboards={generalDashboards}
                      accountingDashboards={accountingDashboards}
                      refetch={refetchWithKeyUpdate}
                      organization={data.viewer.viewingOrganization}
                    />
                    <Space height={70} />
                  </PageContentContainer>
                </React.Fragment>
              )}
            </Loading>
          </PageContainer>
        );
      }}
    </SidebarPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsReportDashboardsPage.query = gql`
  ${ReorderReportDashboardsForm.edit.fragment}

  query OrganizationSettingsReportDashboardsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        dashboards {
          id
          uuid
          ...ReorderReportDashboardsForm_edit
        }
      }
    }
  }
`;

export default OrganizationSettingsReportDashboardsPage;
