// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled, DragAndDropList} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDragAndDrop, useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ReorderClaimStatusesForm from '@shared/modules/Claim/forms/ReorderClaimStatusesForm';
import useReorderClaimStatusesMutation from '@shared/modules/Claim/hooks/useReorderClaimStatusesMutation';
import ClaimStatusItem from 'modules/Organization/Settings/Company/components/ClaimStatusItem';
import CreateClaimStatusModal from 'modules/Organization/Settings/Company/components/CreateClaimStatusModal';

const ClaimStatusTitle = Styled.Text`
  ${Typography.Heading2}
`;

const TableContainer = Styled.View`
  flex: 1;
  max-width: 780px;
`;

const TableHeaderContainer = Styled.View`
  border: 1px solid ${colors.gray.border};
  border-top-left-radius: ${(props) => (props.hasBottomBorder ? '0px' : '4px')};
  border-top-right-radius: ${(props) => (props.hasBottomBorder ? '0px' : '4px')};
  border-bottom-left-radius: ${(props) => (props.hasBottomBorder ? '4px' : '0px')};
  border-bottom-right-radius: ${(props) => (props.hasBottomBorder ? '4px' : '0px')};
  border-bottom-width: ${(props) => (props.hasBottomBorder ? '1px' : '0px')};
  padding-horizontal: 24px;
  padding-vertical: 16px;
  background-color: ${colors.white}
`;

const getClaimStatusesByRank = (claimStatuses) => {
  return _.sortBy(claimStatuses, ['rank']);
};

const onReorder = ({form, fromIndex, toIndex, handleSubmit}) => {
  const claimStatusForms = _.cloneDeep(form.values.reorderClaimStatusesForm.claimStatusForms);
  const reorderedClaimStatusForms = List.move({list: claimStatusForms, fromIndex, toIndex});
  reorderedClaimStatusForms.forEach((claimStatusForm, index) => {
    claimStatusForm.rank = index;
  });
  form.setFieldValue('reorderClaimStatusesForm.claimStatusForms', reorderedClaimStatusForms);
  setTimeout(handleSubmit, 0);
};

const DragAndDropClaimList = ({
  form,
  isReordering,
  sortedClaimStatuses,
  handleSubmit,
  refetch,
  handleReorderStart,
  isOrganizationPrimary,
}) => {
  return (
    <DragAndDropList
      isDisabled={!isOrganizationPrimary}
      isReordering={isReordering}
      onReorder={({fromIndex, toIndex}) => {
        handleReorderStart();
        onReorder({
          form,
          fromIndex,
          toIndex,
          handleSubmit,
        });
      }}
    >
      {form.values.reorderClaimStatusesForm.claimStatusForms.map((claimStatusForm) => {
        const claimStatus = _.find(
          sortedClaimStatuses,
          (claimStatus) => claimStatus.id === claimStatusForm.claimStatusId,
        );
        return (
          <ClaimStatusItem
            key={claimStatusForm.claimStatusId}
            claimStatusForm={claimStatusForm}
            refetch={refetch}
            claimStatus={claimStatus}
            isOrganizationPrimary={isOrganizationPrimary}
          />
        );
      })}
    </DragAndDropList>
  );
};

const OrganizationClaimStatuses = ({
  organization,
  refetch,
  isEnabledClaimsProjectOrganizationMigration,
}) => {
  const createClaimStatusModal = useModal({name: 'Create Claim Status Modal'});
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();
  const sortedClaimStatuses = getClaimStatusesByRank(organization.companySettings.claimStatuses);
  const reorderClaimStatusesForm = ReorderClaimStatusesForm.edit(sortedClaimStatuses);
  const {form, handleSubmit} = useReorderClaimStatusesMutation({
    reorderClaimStatusesForm,
    onSuccess: () => {
      refetch();
      handleReorderEnd();
    },
    onError: () => {
      handleReorderEnd();
    },
  });

  return (
    <TableContainer>
      <TableHeaderContainer>
        <ClaimStatusTitle>Statuses</ClaimStatusTitle>
      </TableHeaderContainer>
      <TableHeaderContainer hasBottomBorder>
        <Space height={8} />
        <DragAndDropClaimList
          form={form}
          isReordering={isReordering}
          sortedClaimStatuses={sortedClaimStatuses}
          handleReorderStart={handleReorderStart}
          handleSubmit={handleSubmit}
          refetch={refetch}
          isOrganizationPrimary={
            organization.isPrimary || !isEnabledClaimsProjectOrganizationMigration
          }
        />
        <Space height={16} />
        {organization.isPrimary ||
          (!isEnabledClaimsProjectOrganizationMigration && (
            <TertiaryButton
              text={'Create Claim Status'}
              iconLeft={Icon.Plus}
              onPress={createClaimStatusModal.handleOpen}
            />
          ))}
        <Space height={8} />
      </TableHeaderContainer>
      <Space height={86} />
      <CreateClaimStatusModal
        key={createClaimStatusModal.key} // Ensures values are empty when modal is opened
        isOpen={createClaimStatusModal.isOpen}
        handleClose={createClaimStatusModal.handleClose}
        organizationId={organization.id}
        refetch={refetch}
      />
    </TableContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationClaimStatuses.fragment = gql`
  ${ClaimStatusItem.fragment}
  fragment OrganizationClaimStatuses on Organization {
    id
    isPrimary
    companySettings {
      claimStatuses {
        id
        name
        color
        rank
        isDefault
        ...ClaimStatusItem
      }
    }
  }
`;

export default OrganizationClaimStatuses;
