// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useDeleteSlotTypeMutation = ({slotTypeId, organizationId, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      slotTypeId,
      organizationId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteSlotTypeMutation.mutation,
    variables: {
      slotTypeId: form.values.slotTypeId,
      organizationId: form.values.organizationId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useDeleteSlotTypeMutation.mutation = gql`
  mutation useDeleteSlotTypeMutation($slotTypeId: Int!, $organizationId: Int!) {
    response: deleteSlotType(slotTypeId: $slotTypeId, organizationId: $organizationId) {
      ${gql.errors}
      slotType {
        id
      }
    }
  }
`;

export default useDeleteSlotTypeMutation;
