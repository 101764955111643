// Libraries
import React from 'react';

// Supermove
import {Icon, Link, Styled, Space, ScrollView, Loading, Tooltip} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import Callout from '@shared/design/components/Callout';
import Table from '@shared/design/components/Table';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import ContainerTypeActionsButton from 'modules/Organization/Settings/Storage/Containers/components/ContainerTypeActionsButton';
import CreateContainerTypeModal from 'modules/Organization/Settings/Storage/Containers/components/CreateContainerTypeModal';
import StorageSettingsPageHeader from 'modules/Organization/Settings/Storage/components/StorageSettingsPageHeader';
import SettingsContent from 'modules/Organization/Settings/components/SettingsContent';

const PageContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const Section = Styled.View`
  margin-bottom: 20px;
`;

const Title = Styled.Text`
  ${Typography.Heading4}
`;

const Subtitle = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary};
`;

const TableCellText = Styled.Text`
  ${Typography.Body3}
`;

const TableCellItalicizedText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.tertiary};
  font-style: italic;
`;

const ToolTipText = Styled.Text`
  ${Typography.Body4};
  color: ${colors.white};
`;

const LinkText = Styled.Text`
  ${Typography.Link3};
  color: ${colors.blue.interactive};
  text-decoration: underline;
  text-decoration-color: ${colors.blue.interactive};
`;

const getContainerTypesTableColumnDefinitions = ({
  refetch,
  userId,
  containerCountsByContainerType,
}) => [
  {
    width: 200,
    headerContent: () => <TableHeaderText>Name</TableHeaderText>,
    cellContent: ({item: containerType}) => (
      <TableCellText numberOfLines={1}>{containerType.name}</TableCellText>
    ),
  },
  {
    width: 180,
    headerContent: () => (
      <TableHeaderText>
        Dimensions
        <Tooltip
          placement={'top'}
          overlay={() => {
            return <ToolTipText>{`Length x width x height`}</ToolTipText>;
          }}
        >
          <TableHeaderText>
            <Space width={5} />
            <Icon source={Icon.InfoCircle} color={colors.gray.tertiary} size={13} />
          </TableHeaderText>
        </Tooltip>
      </TableHeaderText>
    ),
    cellContent: ({item: containerType}) => {
      const hasDimensions =
        containerType.dimensionLength ||
        containerType.dimensionWidth ||
        containerType.dimensionHeight;
      const dimensionsLength = containerType.dimensionLength ? (
        <TableCellText>{`${containerType.dimensionLength} ft`}</TableCellText>
      ) : (
        <TableCellItalicizedText>{'-- ft'}</TableCellItalicizedText>
      );
      const dimensionsWidth = containerType.dimensionWidth ? (
        <TableCellText>{`${containerType.dimensionWidth} ft`}</TableCellText>
      ) : (
        <TableCellItalicizedText>{'-- ft'}</TableCellItalicizedText>
      );
      const dimensionsHeight = containerType.dimensionHeight ? (
        <TableCellText>{`${containerType.dimensionHeight} ft`}</TableCellText>
      ) : (
        <TableCellItalicizedText>{'-- ft'}</TableCellItalicizedText>
      );
      return hasDimensions ? (
        <TableCellText>
          {dimensionsLength} x {dimensionsWidth} x {dimensionsHeight}
        </TableCellText>
      ) : (
        <TableCellItalicizedText>None</TableCellItalicizedText>
      );
    },
  },
  {
    width: 150,
    headerContent: () => <TableHeaderText>Weight Capacity</TableHeaderText>,
    cellContent: ({item: containerType}) =>
      containerType.weightCapacity ? (
        <TableCellText numberOfLines={1}>{`${containerType.weightCapacity} lbs`}</TableCellText>
      ) : (
        <TableCellItalicizedText>{'-- lbs'}</TableCellItalicizedText>
      ),
  },
  {
    width: 350,
    headerContent: () => <TableHeaderText>Description</TableHeaderText>,
    cellContent: ({item: containerType}) =>
      containerType.notes ? (
        <TableCellText numberOfLines={1}>{containerType.notes}</TableCellText>
      ) : (
        <TableCellItalicizedText>None</TableCellItalicizedText>
      ),
  },
  {
    width: 100,
    headerContent: () => (
      <TableHeaderText>
        Count
        <Tooltip
          placement={'top'}
          overlay={() => {
            return <ToolTipText>{`Number of containers of this type`}</ToolTipText>;
          }}
        >
          <TableHeaderText>
            <Space width={5} />
            <Icon source={Icon.InfoCircle} color={colors.gray.tertiary} size={13} />
          </TableHeaderText>
        </Tooltip>
      </TableHeaderText>
    ),
    cellContent: ({item: containerType}) => {
      // Match containerType.id with containerId
      const containerTypeCount = containerCountsByContainerType.filter(
        (containerCountByContainerType) =>
          containerType.id === containerCountByContainerType.containerTypeId.toString(),
      );
      // Since container types have unique IDs, containerTypeCount will either be length 0 or length 1
      return (
        <TableCellText>
          {containerTypeCount.length > 0 ? containerTypeCount[0].count : 0}
        </TableCellText>
      );
    },
  },
  {
    width: 100,
    headerContent: () => <TableHeaderText style={{paddingLeft: 2}}>Actions</TableHeaderText>,
    cellContent: ({item: containerType}) => (
      <ContainerTypeActionsButton containerType={containerType} userId={userId} refetch={refetch} />
    ),
  },
];

const ContainerTypeTable = ({refetch, containerTypes, userId, containerCountsByContainerType}) => {
  return (
    <ScrollView horizontal>
      <Table.FixedHeaderScroll
        columnDefinitions={getContainerTypesTableColumnDefinitions({
          refetch,
          userId,
          containerCountsByContainerType,
        })}
        emptyStateText={'No container types to display'}
        items={containerTypes}
        isDense
        rowStyle={{padding: '8', borderWidth: '0'}}
        scrollViewContentContainerStyle={{height: '60vh'}}
      />
    </ScrollView>
  );
};

const ContainerTypesSection = ({
  refetch,
  userId,
  containerTypes,
  containerCountsByContainerType,
  organization,
}) => {
  return (
    <Section>
      <Title>Container Types</Title>
      <Space height={12} />
      <Subtitle>
        This is where you can create and edit container types. If you want to create or edit
        containers,{' '}
        <Link to={`/storage`}>
          <LinkText>you can do that in the Storage Tab</LinkText>
        </Link>
        .
      </Subtitle>
      <Space height={24} />
      {!organization.features.isEnabledMultibranchStorage || organization.isPrimary ? (
        <ContainerTypeTable
          refetch={refetch}
          userId={userId}
          containerTypes={containerTypes}
          containerCountsByContainerType={containerCountsByContainerType}
        />
      ) : (
        <React.Fragment>
          <Callout
            text={`Container types are set by the company. Please contact the admins at ${organization.company.primaryOrganization.name} to make adjustments.`}
            width={780}
          />
        </React.Fragment>
      )}
    </Section>
  );
};

const ContainerTypesSettingsPageContent = () => {
  const createContainerTypeModal = useModal({name: 'Create Container Type Modal'});
  const {loading, data, refetch} = useQuery(ContainerTypesSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
  });
  return (
    <PageContainer>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <React.Fragment>
            <StorageSettingsPageHeader organization={data.viewer.viewingOrganization} />
            <ScrollView>
              <SettingsContent>
                {data.viewer.viewingOrganization.isPrimary && (
                  <Button
                    text={'Create Container Type'}
                    iconLeft={Icon.Plus}
                    onPress={createContainerTypeModal.handleOpen}
                  />
                )}
                <Space height={12} />
                <ContainerTypesSection
                  refetch={refetch}
                  userId={data.viewer.id}
                  containerTypes={data.viewer.viewingOrganization.containerTypes}
                  containerCountsByContainerType={data.filteredContainerCountsByContainerType}
                  organization={data.viewer.viewingOrganization}
                />
              </SettingsContent>
              <CreateContainerTypeModal
                key={createContainerTypeModal.key} // Ensures values are empty when modal is opened
                isOpen={createContainerTypeModal.isOpen}
                handleClose={createContainerTypeModal.handleClose}
                organizationId={data.viewer.viewingOrganization.company.primaryOrganization.id}
                userId={data.viewer.id}
                refetch={refetch}
              />
            </ScrollView>
          </React.Fragment>
        )}
      </Loading>
    </PageContainer>
  );
};

const ContainerTypesSettingsPage = () => {
  return (
    <SidebarPageV2 selected={'settings'}>
      <ContainerTypesSettingsPageContent />
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ContainerTypesSettingsPage.query = gql`
  ${ContainerTypeActionsButton.fragment}
  ${StorageSettingsPageHeader.fragment} 
  
  query ContainerTypesSettingsPage {
    ${gql.query}
    filteredContainerCountsByContainerType {
      containerTypeId
      count
    }
    viewer {
      id
      viewingOrganization {
        id
        isPrimary
        company {
          id
          primaryOrganization {
            id
            name
          }
        }
        containerTypes {
          id
          name
          dimensionLength
          dimensionWidth
          dimensionHeight
          weightCapacity
          notes
          ...ContainerTypeActionsButton
        }
        features {
          isEnabledMultibranchStorage: isEnabled(feature: "MULTIBRANCH_STORAGE")
        }
        ...StorageSettingsPageHeader
      }
    }
  }
`;

export default ContainerTypesSettingsPage;
