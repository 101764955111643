// Libraries
import _ from 'lodash';

// Supermove
import {uuid} from '@supermove/utils';

export interface FindTaskFromPreviousStepActionFormType {
  workflowRunStepUuid: string;
  taskIdReferencePath: string | null;
}

const _new = (): FindTaskFromPreviousStepActionFormType => ({
  workflowRunStepUuid: uuid(),
  taskIdReferencePath: null,
});

const edit = (findTaskFromPreviousStepActionForm: any): FindTaskFromPreviousStepActionFormType => ({
  workflowRunStepUuid: findTaskFromPreviousStepActionForm.workflowRunStepUuid,
  taskIdReferencePath: findTaskFromPreviousStepActionForm.taskIdReferencePath,
});

const toForm = (
  findTaskFromPreviousStepActionForm: FindTaskFromPreviousStepActionFormType,
): FindTaskFromPreviousStepActionFormType => ({
  workflowRunStepUuid: findTaskFromPreviousStepActionForm.workflowRunStepUuid,
  taskIdReferencePath: findTaskFromPreviousStepActionForm.taskIdReferencePath,
});

const toMutation = (
  findTaskFromPreviousStepActionForm: FindTaskFromPreviousStepActionFormType,
): FindTaskFromPreviousStepActionFormType => ({
  workflowRunStepUuid: findTaskFromPreviousStepActionForm.workflowRunStepUuid,
  taskIdReferencePath: findTaskFromPreviousStepActionForm.taskIdReferencePath,
});

const FindTaskFromPreviousStepActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default FindTaskFromPreviousStepActionForm;
