// Supermove

const _new = ({batchId}) => ({
  batchId,
});

const toForm = ({batchId}) => {
  return {
    batchId,
  };
};

const toMutation = ({batchId}) => {
  return {
    batchId,
  };
};

const KillBatchForm = {
  new: _new,
  toForm,
  toMutation,
};

export default KillBatchForm;
