// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Tabs, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {URL} from '@supermove/utils';

// App
import PayrollReportKind from '@shared/modules/Accounting/enums/PayrollReportKind';

const TabText = Styled.Text`
  ${Typography.Label1}
  color: ${({vars}) => (vars.isSelected ? colors.blue.interactive : colors.gray.tertiary)};
`;

const SelectedTabBottomBorder = Styled.View`
  left: 0px;
  bottom: 0px;
  position: absolute;
  background-color: ${colors.blue.interactive};
  width: 100%;
  height: 4px;
  border-radius: 2px;
`;

const PAYROLL_REPORT_TABS = {
  EMPLOYEES: [
    {
      key: PayrollReportKind.ALL_EMPLOYEE,
      label: 'Employees',
    },
  ],
  CONTRACTORS: [
    {
      key: PayrollReportKind.CONTRACTOR,
      label: 'Contractors',
    },
  ],
};

const getPayrollReportTabs = ({hasMultipleOrganizations}) => {
  if (hasMultipleOrganizations) {
    return [...PAYROLL_REPORT_TABS.EMPLOYEES, ...PAYROLL_REPORT_TABS.CONTRACTORS];
  }

  return PAYROLL_REPORT_TABS.EMPLOYEES;
};

const Tab = ({tab, isSelected, handlePress}) => {
  return (
    <React.Fragment>
      <Tabs.TabContainer
        onPress={handlePress}
        isSelected={isSelected}
        style={{
          marginHorizontal: 12,
          borderBottomWidth: 0,
        }}
      >
        <TabText vars={{isSelected}}>
          {tab.label}
          {isSelected && <SelectedTabBottomBorder />}
        </TabText>
      </Tabs.TabContainer>
    </React.Fragment>
  );
};

const ListPayrollReportsTabNavigation = ({selectedTab, baseRoute, organization}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const tabs = getPayrollReportTabs({
    hasMultipleOrganizations: organization.hasMultipleOrganizations,
  });
  const initialIndex = _.findIndex(tabs, {key: selectedTab});

  return (
    <React.Fragment>
      {responsive.mobile && <Space height={8} />}
      <Tabs.TabBar
        selectedIndex={initialIndex}
        handleChangeIndex={(index) =>
          navigator.push(URL.getUrlFromVariables(baseRoute, {kind: tabs[index].key}))
        }
        TabComponent={Tab}
        style={{
          borderBottomWidth: 1,
          borderBottomColor: colors.gray.border,
          paddingHorizontal: responsive.mobile ? 0 : 12,
        }}
        showFullWidthBar
        fullWidthBarColor={colors.white}
        scrollEnabled
      >
        {tabs.map(({key, label}) => (
          <Tabs.Tab key={key} tab={{label, value: key}} />
        ))}
      </Tabs.TabBar>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListPayrollReportsTabNavigation.fragment = gql`
  fragment ListPayrollReportsTabNavigation on Organization {
    id
    hasMultipleOrganizations
  }
`;

export default ListPayrollReportsTabNavigation;
