// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SyncAllCommercialCatalogBillsForm from '@shared/modules/CommercialCatalog/forms/SyncAllCommercialCatalogBillsForm';

const useSyncAllCommercialCatalogBillsMutation = ({
  syncAllCommercialCatalogBillsForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      syncAllCommercialCatalogBillsForm: SyncAllCommercialCatalogBillsForm.toForm(
        syncAllCommercialCatalogBillsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSyncAllCommercialCatalogBillsMutation.mutation,
    variables: {
      syncAllCommercialCatalogBillsForm: SyncAllCommercialCatalogBillsForm.toMutation(
        form.values.syncAllCommercialCatalogBillsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSyncAllCommercialCatalogBillsMutation.mutation = gql`
  mutation useSyncAllCommercialCatalogBillsMutation($syncAllCommercialCatalogBillsForm: SyncAllCommercialCatalogBillsForm!) {
    response: syncAllCommercialCatalogBills(syncAllCommercialCatalogBillsForm: $syncAllCommercialCatalogBillsForm) {
      ${gql.errors}
      project {
        id
      }
    }
  }
`;

export default useSyncAllCommercialCatalogBillsMutation;
