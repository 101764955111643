// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectTipsForm, {ProjectTipsFormType} from '@shared/modules/Billing/forms/ProjectTipsForm';

const useUpdateProjectJobUserTipsMutation = ({
  projectTipsForm,
  onSuccess,
  onError,
}: {
  projectTipsForm: ProjectTipsFormType;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}) => {
  const form = useForm<ProjectTipsFormType>({
    initialValues: {
      projectTipsForm: ProjectTipsForm.toForm(projectTipsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectJobUserTipsMutation.mutation,
    variables: {
      projectTipsForm: ProjectTipsForm.toMutation(form.values.projectTipsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateProjectJobUserTipsMutation.mutation = gql`
  mutation useUpdateProjectJobUserTipsMutation($projectTipsForm: ProjectTipsForm!) {
    response: updateProjectJobUserTips(projectTipsForm: $projectTipsForm) {
      ${gql.errors}
      project {
        id
      }
    }
  }
`;

export default useUpdateProjectJobUserTipsMutation;
