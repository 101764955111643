// Libraries
import React from 'react';

// Supermove
import {BrainfishWidget, useZendeskWebWidget} from '@supermove/analytics';
import {MaintenancePage, SupermoveApp} from '@supermove/app';
import {Styled, UpdateChecker, ToastContainer} from '@supermove/components';
import {useEffect, useNavigationDOM, useResponsive, useState, useTouch} from '@supermove/hooks';
import {Environment} from '@supermove/sdk';

// App
import {getManagerSSEChannels} from '@shared/modules/SSE/components/SSEConfig';
import {SSEProvider} from '@shared/modules/SSE/context/SSEContext';
import {AppInfo} from 'config';
import Router from 'core/Router';
import GlobalHeader from 'modules/App/Global/GlobalHeader';
import GlobalHeaderMobile from 'modules/App/Global/GlobalHeaderMobile';
import GlobalNavigationSidebar from 'modules/App/Global/GlobalNavigationSidebar';
import ErrorModal from 'modules/App/components/ErrorModal';
import UpdateAppBanner from 'modules/App/components/UpdateAppBanner';
import AppContextProvider from 'modules/App/context/AppContextProvider';
import useAppContext from 'modules/App/context/useAppContext';

const AppContentRow = Styled.View`
  flex-direction: row;
  flex: 1;
`;

const RouteContentContainer = Styled.View`
  flex: 1;
`;

const getIsCustomerPage = ({navigator}) => {
  return navigator.location.pathname.includes('/0/');
};

const getIsMoverPage = ({navigator}) => {
  return navigator.location.pathname.includes('/1/');
};

const AppPageContent = () => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const {viewer} = useAppContext();
  const isCustomerPage = getIsCustomerPage({navigator});
  const isMoverPage = getIsMoverPage({navigator});
  // NOTE: The viewer check is a safeguard to prevent downstream components from erroring if they rely on the viewer without a null check.
  // This is existing functionality that ideally can be removed in the future.
  // const isGlobalNavVisible = viewer && !isCustomerPage && !isMoverPage;
  const isGlobalNavVisible = viewer && !isCustomerPage && !isMoverPage;

  return (
    <React.Fragment>
      {isGlobalNavVisible && (!responsive.desktop ? <GlobalHeaderMobile /> : <GlobalHeader />)}
      <AppContentRow>
        {isGlobalNavVisible && responsive.desktop && <GlobalNavigationSidebar />}
        <RouteContentContainer>
          <Router pageContext={{appNamespace: 'office'}} />
        </RouteContentContainer>
      </AppContentRow>
    </React.Fragment>
  );
};

const ManagerApp = ({viewer, setFeatures}) => {
  const sseChannels = getManagerSSEChannels({viewer});

  // HACK(mark): Since we don't have a separate graphql query outside of the SupermoveApp component,
  // this is the only way to get the viewer's feature flags.
  useEffect(() => {
    if (viewer) {
      setFeatures(viewer.viewingOrganization.features);
    }
  }, [viewer, setFeatures]);

  // Poll every 30 seconds to show the update banner.
  return (
    <React.Fragment>
      <UpdateChecker
        name={'Manager'}
        buildNumber={AppInfo.getBuildNumber()}
        pollInterval={1000 * 30}
      >
        {({isVisible, isRequired}) => (isVisible ? <UpdateAppBanner /> : null)}
      </UpdateChecker>
      <AppContextProvider>
        <SSEProvider
          userId={viewer?.id}
          organizationId={viewer?.viewingOrganization?.id}
          channels={sseChannels}
        >
          <AppPageContent />
        </SSEProvider>
      </AppContextProvider>
      <ToastContainer />
    </React.Fragment>
  );
};

const ExternalWidgets = ({features, error}) => {
  const zendeskWebWidgetKey = Environment.get('ZENDESK_WEB_WIDGET_KEY');
  const brainfishApiKey = Environment.get('BRAINFISH_API_KEY');
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const {isTouch} = useTouch();
  const [isZendeskChatVisible, setIsZendeskChatVisible] = useState(false);
  const [isBrainfishWidgetVisible, setIsBrainfishWidgetVisible] = useState(false);
  const zendesk = useZendeskWebWidget({
    widgetKey: zendeskWebWidgetKey,
    isVisible: isZendeskChatVisible,
    options: {
      webWidget: {
        offset: {
          horizontal: '85px',
          vertical: '5px',
        },
      },
    },
  });

  useEffect(() => {
    // Dynamically show/hide the support widgets based on the current page and device type.
    const shouldHide =
      !responsive.desktop ||
      getIsCustomerPage({navigator}) ||
      getIsMoverPage({navigator}) ||
      isTouch;

    setIsZendeskChatVisible(features.isEnabledOfficeZendeskSupportWidget && !shouldHide);
    setIsBrainfishWidgetVisible(features.isEnabledOfficeBrainfishSupportWidget && !shouldHide);
  }, [responsive, navigator, isTouch, features]);

  useEffect(() => {
    if (error) {
      zendesk.open();
    }
  }, [error, zendesk]);

  return (
    <React.Fragment>
      <BrainfishWidget
        apiKey={brainfishApiKey}
        isVisible={isBrainfishWidgetVisible}
        onOpenChat={({query}) => {
          // TODO(mark): Send the actual query from the user to the Zendesk widget.
          zendesk.show();
          zendesk.open();
        }}
      />
    </React.Fragment>
  );
};

const App = () => {
  const showMaintenancePage = Environment.get('SHOW_MAINTENANCE_PAGE');
  const [error, setError] = useState(null);

  // HACK(mark): We use state to manage the feature flags since we don't have a way to query
  // from the outside component.
  const [features, setFeatures] = useState({});

  if (showMaintenancePage) {
    return <MaintenancePage />;
  }

  return (
    <ErrorModal
      trigger={({handleOpen}) => (
        <React.Fragment>
          <SupermoveApp
            name={'Manager'}
            version={AppInfo.getVersion()}
            buildNumber={AppInfo.getBuildNumber()}
            onError={(error) => {
              handleOpen();
              setError(error);
            }}
            showOfflineOverlay={false}
          >
            {({viewer}) => <ManagerApp viewer={viewer} setFeatures={setFeatures} />}
          </SupermoveApp>
          <ExternalWidgets features={features} error={error} />
        </React.Fragment>
      )}
    />
  );
};

export default App;
