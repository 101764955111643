const SURVEY = 'SURVEY';
const DRIVER_INVENTORY = 'DRIVER_INVENTORY';

const getDisplayHeader = (inventoryLibraryKind) => {
  switch (inventoryLibraryKind) {
    case InventoryLibraryKind.DRIVER_INVENTORY:
      return 'Driver';
    case InventoryLibraryKind.SURVEY:
      return 'Survey';
    default:
      return '';
  }
};

const getDisplayKind = (inventoryLibraryKind) => {
  return inventoryLibraryKind ? inventoryLibraryKind.toLowerCase().replace('_', ' ') : '';
};

const InventoryLibraryKind = {
  SURVEY,
  DRIVER_INVENTORY,
  getDisplayHeader,
  getDisplayKind,
};

export default InventoryLibraryKind;
