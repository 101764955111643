// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectLocationsForm from '@shared/modules/Project/forms/ProjectLocationsForm';

const useDeleteProjectLocationsMutation = ({projectLocationsForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      projectLocationsForm: ProjectLocationsForm.toForm(projectLocationsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteProjectLocationsMutation.mutation,
    variables: {
      projectLocationsForm: ProjectLocationsForm.toMutation(form.values.projectLocationsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteProjectLocationsMutation.mutation = gql`
  mutation useDeleteProjectLocationsMutation($projectLocationsForm: ProjectLocationsForm!) {
    response: deleteProjectLocations(projectLocationsForm: $projectLocationsForm) {
      ${gql.errors}
      project {
        id
      }
    }
  }
`;

export default useDeleteProjectLocationsMutation;
