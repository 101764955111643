/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal} from '@supermove/hooks';
import {BillItemType} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import useDeleteBillItemTypeMutation from '@shared/modules/Billing/hooks/useDeleteBillItemTypeMutation';
import DeleteModal from 'modules/App/components/DeleteModal';
import EditBillItemTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/EditBillItemTypeDrawer';
import EditBillItemTypeModal from 'modules/Organization/Settings/BillingLibraries/components/EditBillItemTypeModal';

const Row = Styled.View`
  flex-direction: row;
`;

const IconButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const DeleteBillItemTypeModal = ({billItemType, isOpen, handleClose, refetch}) => {
  const {handleSubmit} = useDeleteBillItemTypeMutation({
    billItemTypeId: billItemType.id,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DeleteModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={handleSubmit}
      title={'Are you sure you want to delete this bill item?'}
      subtitle={
        "This will delete this bill item and remove it from all bill templates. You can't undo this action."
      }
    />
  );
};

const BillItemTypeActions = ({billItemType, refetch, userId, organization}) => {
  const editModal = useModal();
  const deleteModal = useModal();
  const editBillItemTypeDrawer = useDrawer({
    name: 'Edit Bill Item Type Drawer',
    useTracking: true,
  });
  const isSynced = BillItemType.getIsSynced(billItemType);
  const {isEnabledBillingEngineTimesheetVariables} = billItemType.organization.features;
  return (
    <Row>
      <Space width={16} />
      <IconButton
        onPress={() => {
          isEnabledBillingEngineTimesheetVariables
            ? editBillItemTypeDrawer.handleOpen()
            : editModal.handleOpen();
        }}
      >
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </IconButton>
      <Space width={16} />
      <IconButton onPress={deleteModal.handleOpen} disabled={isSynced}>
        <Icon
          source={Icon.Trash}
          color={isSynced ? colors.gray.border : colors.gray.secondary}
          size={16}
        />
      </IconButton>
      <Space width={16} />
      <EditBillItemTypeModal
        key={`EDIT_BILL_ITEM_TYPE_MODAL-${editModal.isOpen}`}
        isOpen={editModal.isOpen}
        handleClose={editModal.handleClose}
        billItemType={billItemType}
        refetch={refetch}
        userId={userId}
      />
      <EditBillItemTypeDrawer
        key={editBillItemTypeDrawer.key}
        isOpen={editBillItemTypeDrawer.isOpen}
        handleClose={editBillItemTypeDrawer.handleClose}
        billItemType={billItemType}
        refetch={refetch}
        userId={userId}
        organization={organization}
      />
      <DeleteBillItemTypeModal
        key={`DELETE_BILL_ITEM_TYPE_MODAL-${deleteModal.isOpen}`}
        isOpen={deleteModal.isOpen}
        handleClose={deleteModal.handleClose}
        billItemType={billItemType}
        refetch={refetch}
        userId={userId}
      />
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillItemTypeActions.fragment = gql`
  ${BillItemType.getIsSynced.fragment}
  ${EditBillItemTypeModal.fragment}
  ${EditBillItemTypeDrawer.fragment}

  fragment BillItemTypeActions on BillItemType {
    id
    organization {
      id
      features {
        isEnabledBillingEngineTimesheetVariables: isEnabled(
          feature: "BILLING_ENGINE_TIMESHEET_VARIABLES"
        )
      }
    }
    ...BillItemType_getIsSynced
    ...EditBillItemTypeModal
    ...EditBillItemTypeDrawer
  }

  fragment BillItemTypeActions_Organization on Organization {
    id
    ...EditBillItemTypeDrawer_Organization
  }
`;

export default BillItemTypeActions;
