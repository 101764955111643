// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {FlatList, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Payment} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Line = Styled.View`
  flex: 1;
  border-color: ${colors.gray.border};
  border-bottom-width: 1px;
`;

const Container = Styled.View`
`;

const HeaderText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.primary};
`;

const PaymentText = Styled.Text`
  ${Typography.Body3}
  color: ${({color}) => color};
  text-align: ${(props) => props.align};
`;

const PaymentTextBold = Styled.Text`
  ${Typography.Label2}
  color: ${({color}) => color};
  text-align: ${(props) => props.align};
`;

const MiniIconCircle = Styled.View`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${colors.orange.status};
  align-items: center;
  justify-content: center;
`;

const NoPaymentsMessageContainer = Styled.View`
  flex-direction: row;
  background-color: ${colors.gray.background};
  border-radius: 4px;
  padding: 8px;
  align-items: center;
`;

const NoPaymentsText = Styled.Text`
  ${Typography.Body5}
  color: ${colors.orange.status};
`;

const BalanceDueText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.primary};
`;

const PaymentsListItem = ({payment}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <Row>
        <Row style={{flex: 4, alignItems: responsive.mobile ? 'flex-start' : 'center'}}>
          {responsive.mobile ? (
            <PaymentTextBold align={'left'} color={colors.gray.primary}>
              {payment.name}
            </PaymentTextBold>
          ) : (
            <PaymentText align={'left'} color={colors.gray.primary}>
              {payment.name}
            </PaymentText>
          )}
        </Row>
        {!responsive.mobile && (
          <React.Fragment>
            <Row style={{flex: 2, justifyContent: 'flex-end'}}>
              <PaymentText align={'right'} color={colors.gray.primary}>
                {Payment.getDisplayMethod(payment)}
              </PaymentText>
            </Row>
            <Row style={{flex: 2, justifyContent: 'flex-end'}}>
              <Space width={16} />
              <PaymentText align={'right'} color={colors.gray.primary}>
                {payment.description}
              </PaymentText>
            </Row>
            <Row style={{flex: 3, justifyContent: 'flex-end'}}>
              <PaymentText align={'right'} color={colors.gray.primary}>
                {Currency.display(payment.totalAmount, {shouldHideCentsIfZero: true})}
              </PaymentText>
            </Row>
          </React.Fragment>
        )}
      </Row>
      {responsive.mobile && (
        <React.Fragment>
          <Space height={4} />
          <Row style={{justifyContent: 'space-between'}}>
            <Row style={{flex: 1}}>
              <Space width={40} />
              <PaymentText align={'right'} color={colors.gray.primary}>
                {Payment.getDisplayMethod(payment)}
              </PaymentText>
            </Row>
            <PaymentText align={'right'} color={colors.gray.primary}>
              {Currency.display(payment.totalAmount, {shouldHideCentsIfZero: true})}
            </PaymentText>
          </Row>
          {!!payment.description && (
            <React.Fragment>
              <Space height={4} />
              <Row>
                <Space width={40} />
                <PaymentText align={'right'} color={colors.gray.tertiary}>
                  {payment.description}
                </PaymentText>
              </Row>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const NoPaymentsMessage = () => {
  return (
    <NoPaymentsMessageContainer>
      <MiniIconCircle>
        <Icon source={Icon.Question} color={colors.white} size={7} />
      </MiniIconCircle>
      <Space width={8} />
      <NoPaymentsText>No payments have been recorded for this project</NoPaymentsText>
    </NoPaymentsMessageContainer>
  );
};

const BalanceDue = ({isTotalAvailable, minBalance, maxBalance}) => {
  const responsive = useResponsive();
  const balance = isTotalAvailable
    ? Currency.formatRange({min: minBalance, max: maxBalance})
    : 'TBD';

  if (responsive.mobile) {
    return (
      <Row style={{justifyContent: 'space-between'}}>
        <BalanceDueText>Balance Due</BalanceDueText>
        <BalanceDueText>{balance}</BalanceDueText>
      </Row>
    );
  }
  return (
    <Row>
      <Row style={{flex: 8, justifyContent: 'flex-end'}}>
        <BalanceDueText>Balance Due</BalanceDueText>
      </Row>
      <Row style={{flex: 3, justifyContent: 'flex-end'}}>
        <BalanceDueText>{balance}</BalanceDueText>
      </Row>
    </Row>
  );
};

const BillingDocumentProjectPayments = ({hasAllBills, hasBalance, project}) => {
  const {activeJobsAggregateBill, currentAggregateBill} = project;
  const aggregateBill = hasAllBills ? activeJobsAggregateBill : currentAggregateBill;
  const {isTotalAvailable, minBalance, maxBalance, payments} = aggregateBill;

  return (
    <Container>
      <Row>
        <Icon source={Icon.CreditCard} size={16} color={colors.gray.primary} />
        <Space width={8} />
        <HeaderText>Payments</HeaderText>
      </Row>
      <Space height={8} />
      <Line />
      <Space height={8} />
      <FlatList
        data={payments}
        keyExtractor={(payment) => payment.id}
        renderItem={({item: payment}) => {
          return <PaymentsListItem payment={payment} />;
        }}
        ListEmptyComponent={() => <NoPaymentsMessage />}
        ItemSeparatorComponent={() => <Space height={8} />}
      />
      <Space height={8} />
      <Line />
      {hasBalance && (
        <React.Fragment>
          <Space height={12} />
          <BalanceDue
            isTotalAvailable={isTotalAvailable}
            minBalance={minBalance}
            maxBalance={maxBalance}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
BillingDocumentProjectPayments.propTypes = {
  hasAllBills: PropTypes.bool,
  hasBalance: PropTypes.bool,
};

BillingDocumentProjectPayments.defaultProps = {
  hasAllBills: true,
  hasBalance: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingDocumentProjectPayments.fragment = gql`
  fragment BillingDocumentProjectPayments_AggregateBill on AggregateBill {
    isTotalAvailable
    minBalance
    maxBalance
    payments {
      id
      name
      method
      description
      totalAmount
      isPaid
    }
  }

  fragment BillingDocumentProjectPayments on Project {
    id
    activeJobsAggregateBill {
      ...BillingDocumentProjectPayments_AggregateBill
    }
    currentAggregateBill {
      ...BillingDocumentProjectPayments_AggregateBill
    }
  }
`;

export default BillingDocumentProjectPayments;
