// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Currency, withFragment} from '@supermove/utils';

// App
import UpdateAttachmentForm from '@shared/modules/File/forms/UpdateAttachmentForm';

const getIsUploadingAttachment = (claimForm) => {
  // Attachments get uploaded immediately upon selection. If an attachmentForm does
  // not have an attachmentId, then the attachment was just added and is in the middle
  // of being uploaded. Once uploaded the form will update with the attachmentId.
  return !!_.find(claimForm.attachmentForms, (attachmentForm) => !attachmentForm.attachmentId);
};

const _new = ({organizationId, projectId}) => ({
  claimId: null,
  organizationId,
  projectId,
  claimTypeId: null,
  claimStatusId: null,
  notes: '',
  amount: '',
  attachmentForms: [],
});

const _delete = ({claimId}) => ({
  ..._new({}),
  claimId,
});

const edit = withFragment(
  (claim) => ({
    claimId: claim.id,
    organizationId: claim.organizationId,
    projectId: claim.projectId,
    claimTypeId: claim.claimTypeId,
    claimStatusId: claim.claimStatusId,
    notes: claim.notes,
    amount: claim.amount,
    attachmentForms: claim.attachments.map((attachment) => UpdateAttachmentForm.edit(attachment)),
  }),
  gql`
    ${UpdateAttachmentForm.edit.fragment}
    fragment ClaimForm_edit on Claim {
      id
      organizationId
      projectId
      claimTypeId
      claimStatusId
      notes
      amount
      attachments {
        id
        ...UpdateAttachmentForm_edit
      }
    }
  `,
);

const toForm = ({
  claimId,
  organizationId,
  projectId,
  claimTypeId,
  claimStatusId,
  notes,
  amount,
  attachmentForms,
}) => ({
  claimId,
  organizationId,
  projectId,
  claimTypeId: _.toString(claimTypeId),
  claimStatusId: _.toString(claimStatusId),
  notes,
  amount: _.isNil(amount) ? '' : Currency.toForm(amount),
  attachmentForms: attachmentForms.map((attachmentForm) =>
    UpdateAttachmentForm.toForm(attachmentForm),
  ),
});

const toMutation = ({
  claimId,
  organizationId,
  projectId,
  claimTypeId,
  claimStatusId,
  notes,
  amount,
  attachmentForms,
}) => ({
  claimId,
  organizationId,
  projectId,
  claimTypeId: _.toNumber(claimTypeId),
  claimStatusId: _.toNumber(claimStatusId),
  notes,
  amount: amount === '' ? null : Currency.toMutation(amount),
  attachmentForms: attachmentForms.map((attachmentForm) =>
    UpdateAttachmentForm.toMutation(attachmentForm),
  ),
});

const ClaimTypeForm = {
  new: _new,
  delete: _delete,
  edit,
  toForm,
  toMutation,

  // Helpers
  getIsUploadingAttachment,
};

export default ClaimTypeForm;
