// Supermove
import {gql} from '@supermove/graphql';
import {Currency, Percent, Float, withFragment, Datetime} from '@supermove/utils';

// App
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';

const _new = (projectTypeId) => ({
  projectTypeVariableId: null,
  variableId: null,
  projectTypeId,
  isVisibleForCreateProject: false,
  isRequiredForCreateProject: false,
  isEnabled: true,
  defaultAmount: null,
  defaultStringValue: null,
  isDeleted: false,
  isDirty: false,
});

const edit = withFragment(
  (projectTypeVariable, {format, defaultAmountOptions, name, isDerived, description}) => ({
    projectTypeVariableId: projectTypeVariable.id,
    variableId: projectTypeVariable.variableId,
    projectTypeId: projectTypeVariable.projectTypeId,
    isVisibleForCreateProject: projectTypeVariable.isVisibleForCreateProject,
    isRequiredForCreateProject: projectTypeVariable.isRequiredForCreateProject,
    defaultAmount: projectTypeVariable.defaultAmount,
    defaultStringValue: projectTypeVariable.defaultStringValue,
    isEnabled: projectTypeVariable.isEnabled,
    isDeleted: projectTypeVariable.isDeleted,

    // Private
    format,
    defaultAmountOptions,
    name,
    isDerived,
    description,
    isDirty: false,
  }),
  gql`
    fragment ProjectTypeVariableForm_edit on ProjectTypeVariable {
      id
      variableId
      projectTypeId
      isVisibleForCreateProject
      isRequiredForCreateProject
      isEnabled
      defaultAmount
      defaultStringValue
      isDeleted
    }
  `,
);

const toForm = ({
  projectTypeVariableId,
  variableId,
  projectTypeId,
  isVisibleForCreateProject,
  isRequiredForCreateProject,
  defaultAmount,
  defaultStringValue,
  isEnabled,
  isDeleted,

  // Private
  format,
  defaultAmountOptions,
  name,
  isDerived,
  description,
  isDirty,
}) => ({
  projectTypeVariableId,
  variableId,
  projectTypeId,
  isVisibleForCreateProject,
  isRequiredForCreateProject,
  isEnabled,
  defaultAmount: amountToForm(defaultAmount, format),
  defaultStringValue: stringValueToForm(defaultStringValue, format),
  isDeleted,

  // Private
  format,
  defaultAmountOptions,
  name,
  isDerived,
  description,
  isDirty,
});

const toMutation = ({
  projectTypeVariableId,
  variableId,
  projectTypeId,
  isVisibleForCreateProject,
  isRequiredForCreateProject,
  defaultAmount,
  defaultStringValue,
  isEnabled,
  isDeleted,

  // private
  format,
}) => ({
  projectTypeVariableId,
  variableId,
  projectTypeId,
  isVisibleForCreateProject,
  isRequiredForCreateProject,
  isEnabled,
  defaultAmount: amountToMutation(defaultAmount, format),
  defaultStringValue: stringValueToMutation(defaultStringValue, format),
  isDeleted,
});

const amountToForm = (defaultAmount, projectTypeVariableFormat) => {
  switch (projectTypeVariableFormat) {
    case VariableFormat.CITY_VALUE_CURRENCY:
    case VariableFormat.CURRENCY:
    case VariableFormat.VALUE_TABLE_CURRENCY:
      return Currency.toForm(defaultAmount);
    case VariableFormat.PERCENT:
      return Percent.toForm(defaultAmount);
    default:
      // To prevent toForm to parse 0 into empty strings for FE Rendering
      if (defaultAmount === 0) {
        return '0';
      }
      return Float.toForm(defaultAmount);
  }
};

const amountToMutation = (defaultAmount, projectTypeVariableFormat) => {
  switch (projectTypeVariableFormat) {
    case VariableFormat.CITY_VALUE_CURRENCY:
    case VariableFormat.CURRENCY:
    case VariableFormat.VALUE_TABLE_CURRENCY:
      return Currency.toMutation(defaultAmount);
    case VariableFormat.PERCENT:
      return Percent.toMutation(defaultAmount);
    default:
      return Float.toMutation(defaultAmount);
  }
};

const stringValueToForm = (defaultStringValue, projectTypeVariableFormat) => {
  switch (projectTypeVariableFormat) {
    case VariableFormat.DATE:
      return Datetime.toFormDate(defaultStringValue);
    case VariableFormat.TIME:
      return Datetime.toFormTime(defaultStringValue);
    default:
      return defaultStringValue;
  }
};

const stringValueToMutation = (defaultStringValue, projectTypeVariableFormat) => {
  switch (projectTypeVariableFormat) {
    case VariableFormat.DATE:
      return defaultStringValue ? Datetime.toMutationDate(defaultStringValue) : '';
    case VariableFormat.TIME:
      return defaultStringValue ? Datetime.toMutationTime(defaultStringValue) : '';
    default:
      return defaultStringValue;
  }
};

const ProjectTypeVariableForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
  amountToForm,
  amountToMutation,
};

export default ProjectTypeVariableForm;
