// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, CurrencyInput, Space, Styled, Modal, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useState, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import useUpdateRecurringInvoiceMutation from '@shared/modules/Billing/hooks/useUpdateRecurringInvoiceMutation';
import BeginPaymentV3Form from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import {
  transactionContactMessage,
  transactionErrorMessage,
} from '@shared/modules/Payment/hooks/payengineErrors';
import useChargePayEngineCreditCardMutation from '@shared/modules/Payment/hooks/useChargePayEngineCreditCardMutation';
import ProjectTypePaymentMethod from '@shared/modules/Project/enums/ProjectTypePaymentMethod';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

import SavePayEngineCreditCardForCustomerModal from './SavePayEngineCreditCardForCustomerModal';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  width: ${(props) => (props.mobile ? '100%' : 400)}px;
  background-color: ${colors.white};
  border-radius: 4px;
  padding-horizontal: 24px;
`;

const TitleText = Styled.Text`
  ${Typography.Heading2}
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Subtitle = Styled.Text`
  ${Typography.Body}
`;

const Container = Styled.View`
  width: 100%;
`;

const WarningCallout = Styled.View`
  flex-direction: row;
  padding-vertical: 8px;
  padding-horizontal: 24px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: ${colors.red.accent};
  background-color: ${colors.red.accent};
`;

const Text = Styled.Text`
  ${Typography.Body}
  color: ${(props) => props.color}
`;

const InformationCallout = Styled.View`
  background-color: ${colors.blue.accent}
  border-radius: 4px;
  padding: 12px;
`;

const CloseButton = Styled.Touchable``;

// NOTE(cooper): We're not able to use our usual formik strategy for handling errors in this case,
// so we roll our own errors system using these field names. See below for more details
const NAME_FIELD = 'name';
const AMOUNT_FIELD = 'amount';
const PAYMENT_METHOD_FIELD = 'paymentMethod';
const FIELD_NAMES = [NAME_FIELD, AMOUNT_FIELD, PAYMENT_METHOD_FIELD];

const ChargeCreditCardFields = ({invoice, onSuccess, handleClose, refetch}) => {
  const client = invoice.project.billingClient;

  const [isAutopayChecked, setIsAutopayChecked] = useState(!!invoice.isAutopayEnabled);

  const [paymentName, setPaymentName] = useState(`Payment for Invoice ${invoice.identifier}`);
  const [paymentAmount, setPaymentAmount] = useState(Currency.toForm(invoice.remainingBalance));
  const [creditCard, setCreditCard] = useState({});
  const [errors, setErrors] = useState([]);

  const mutation = useChargePayEngineCreditCardMutation({
    beginPaymentV3Form: BeginPaymentV3Form.new({
      billId: invoice.project.currentPrimaryBill.id,
      invoiceId: invoice.id,
      customerId: client.primaryContact.id,
      name: paymentName,
      amount: Currency.toMutation(paymentAmount),
      method: ProjectTypePaymentMethod.PAYENGINE_CREDIT_CARD,
      tipAmount: 0,
      tipName: null,
      shouldErrorOnDuplicatePayment: true,
    }),
    createCardResponse: {
      token: creditCard.token,
    },
  });

  const updateRecurringInvoiceMutation = useUpdateRecurringInvoiceMutation({
    recurringInvoiceId: invoice.recurringInvoice?.id,
    onSuccess: (response) => {},
    onError: (errors) => {},
  });

  const handleChargeCreditCard = async () => {
    try {
      const response = await mutation.handleSubmit();

      if (response.errors) {
        throw response.errors;
      }
      if (response.data?.response?.errors) {
        throw response.data.response.errors;
      }
      if (!response.data?.response) {
        throw new Error('Received empty GraphQL response in ChargePayEngineCreditCard');
      }

      updateRecurringInvoiceMutation.form.setFieldValue(
        `recurringInvoiceForm.payengineCreditCardId`,
        creditCard.id,
      );
    } catch (error) {
      console.error(`Error charging credit card: `, error);
      const errors = _.isArray(error)
        ? error.reduce((errors, error) => {
            const errorField = error.field?.split('.')[1];
            if (_.includes(FIELD_NAMES, errorField)) {
              return {
                ...errors,
                [errorField]: error.message,
              };
            }
            return errors;
          }, {})
        : {
            genericError: `${transactionErrorMessage(error)} ${transactionContactMessage(
              invoice.organization.name,
            )}`,
          };
      setErrors(errors);
      return;
    }

    if (isAutopayChecked) {
      try {
        const updateRecurringInvoiceResponse = await updateRecurringInvoiceMutation.handleSubmit();
        const errors =
          updateRecurringInvoiceResponse?.errors ||
          updateRecurringInvoiceResponse?.data?.response?.errors;
        if (errors) {
          throw errors;
        }
      } catch (error) {
        console.error(`Error saving credit card: `, error);
        setErrors({
          genericError:
            'Credit card was charged successfully, but could not be saved to autopay future payments.',
        });
        return;
      }
    }
    onSuccess();
  };

  const isSubmitting = mutation.mutationResult.loading || updateRecurringInvoiceMutation.submitting;

  return (
    <Container>
      <FieldInput
        label={'Name'}
        name={NAME_FIELD}
        errors={errors}
        touched={errors}
        index={0}
        isRequired
        input={{
          value: paymentName,
          onChangeText: (name) => {
            setPaymentName(name);
            setErrors({
              ...errors,
              [NAME_FIELD]: null,
            });
          },
          placeholder: `Enter a payment name`,
        }}
      />
      <Space height={8} />
      <FieldInput
        label={'Amount'}
        component={CurrencyInput}
        name={AMOUNT_FIELD}
        errors={errors}
        touched={errors}
        index={1}
        isRequired
        input={{
          component: FieldInput.TextInput,
          value: paymentAmount,
          onChangeText: (amount) => {
            setPaymentAmount(amount);
            setErrors({
              ...errors,
              [AMOUNT_FIELD]: null,
            });
          },
          setFieldValue: () => {},
          setFieldTouched: () => {},
          placeholder: `Enter a payment amount`,
        }}
      />
      <Space height={8} />
      <FieldInput
        index={2}
        component={DropdownInput}
        label={'Credit Card'}
        isRequired
        input={{
          options: client.payengineCreditCards.map((creditCard) => {
            return {
              label: creditCard.cardName,
              description: `Expires ${creditCard.expireDate}`,
              value: creditCard.id,
            };
          }),
          components: {
            Option: DropdownInput.OptionWithDescriptionOnRight,
          },
          placeholder: 'Select Credit Card',
          setFieldValue: () => {},
          value: creditCard.id,
          onChangeValue: (creditCardId) => {
            const creditCard = _.find(
              client.payengineCreditCards,
              (card) => card.id === creditCardId,
            );
            setCreditCard(creditCard);
          },
          style: {width: '100%'},
        }}
        style={{width: '100%'}}
      />
      <SavePayEngineCreditCardForCustomerModal
        clientId={client.id}
        mode={invoice.organization.mode}
        onClose={() => {
          setCreditCard({});
          refetch();
        }}
        trigger={({handleOpen}) => (
          <Button color={colors.blue.accent} onPress={handleOpen} style={{marginTop: 10}}>
            <Text style={{color: colors.blue.interactive}}>+ Add Card</Text>
          </Button>
        )}
      />
      {invoice.isRecurring && (
        <>
          <Space height={16} />
          <Checkbox
            isChecked={isAutopayChecked}
            childrenRight
            handleToggle={() => setIsAutopayChecked(!isAutopayChecked)}
          >
            <Space width={16} />
            <Text>{`Automatically charge this card for future recurring invoices`}</Text>
          </Checkbox>
        </>
      )}
      {errors.genericError && (
        <React.Fragment>
          <Space height={8} />
          <WarningCallout>
            <Text style={{color: colors.red.warning}}>{errors.genericError}</Text>
          </WarningCallout>
        </React.Fragment>
      )}
      <Space height={16} />
      <Row style={{justifyContent: 'flex-end'}}>
        <Button
          text={'Cancel'}
          color={colors.white}
          textColor={colors.gray.secondary}
          onPress={handleClose}
          isDisabled={isSubmitting}
        />
        <Space width={8} />
        <Button
          text={'Charge'}
          color={colors.blue.interactive}
          isSubmitting={isSubmitting}
          isDisabled={!(paymentName && paymentAmount && creditCard.token)}
          onPress={_.throttle(handleChargeCreditCard, 3000)}
        />
      </Row>
      <Space height={24} />
    </Container>
  );
};

const AutomaticPaymentCallout = ({invoice, refetch, setIsEditingCreditCard}) => {
  const updateRecurringInvoiceMutation = useUpdateRecurringInvoiceMutation({
    recurringInvoiceId: invoice.recurringInvoice.id,
    onSuccess: (response) => {},
    onError: (errors) => {},
  });

  return (
    <Container>
      <InformationCallout>
        <Row>
          <Icon source={Icon.Check} color={colors.blue.interactive} size={14} />
          <Space width={12} />
          <Text
            color={colors.blue.interactive}
          >{`This invoice will be automatically paid on ${Datetime.convertToDisplayDate(
            invoice.dueDate,
            Datetime.DISPLAY_SHORT_DATE,
          )} using ${invoice.recurringInvoice.payengineCreditCard.cardName}.`}</Text>
        </Row>
      </InformationCallout>
      <Space height={24} />
      <Row>
        <Container style={{flex: 1}}>
          <Button
            isWidthOfContainer
            iconLeft={Icon.Pen}
            text={'Update Card'}
            onPress={() => {
              setIsEditingCreditCard(true);
            }}
          />
        </Container>
        <Space width={8} />
        <Container style={{flex: 1}}>
          <SecondaryButton
            isWidthOfContainer
            iconLeft={Icon.Times}
            text={'Cancel Autopay'}
            onPress={async () => {
              await updateRecurringInvoiceMutation.handleSubmit();
              refetch();
            }}
          />
        </Container>
      </Row>
      <Space height={24} />
    </Container>
  );
};

const ChargeCreditCardModalContent = ({invoice, onSuccess, refetch, handleClose}) => {
  const [isEditingCreditCard, setIsEditingCreditCard] = useState(false);
  if (invoice.recurringInvoice?.id && invoice.isAutopayEnabled && !isEditingCreditCard) {
    return (
      <AutomaticPaymentCallout
        invoice={invoice}
        refetch={refetch}
        setIsEditingCreditCard={setIsEditingCreditCard}
      />
    );
  }

  return (
    <>
      <Subtitle>
        Select a credit card and enter an amount to charge. The credit card will be charged when you
        press "Charge".
      </Subtitle>
      <Space height={16} />
      <ChargeCreditCardFields
        invoice={invoice}
        onSuccess={onSuccess}
        handleClose={handleClose}
        refetch={refetch}
      />
    </>
  );
};

const ChargeCreditCardModal = ({invoiceUuid, isOpen, handleClose}) => {
  const {loading, data, refetch} = useQuery(ChargeCreditCardModal.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    variables: {invoiceUuid},
  });

  const chargeCreditCardSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Card charged successfully',
    isClosable: true,
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <ScreenContainer pointerEvents={'box-none'}>
        <ModalContainer>
          <Space height={24} />
          <Row style={{alignItems: 'center'}}>
            <TitleText>Charge Credit Card</TitleText>
            <Space style={{flex: 1}} />
            <CloseButton onPress={handleClose}>
              <Icon source={Icon.Times} color={colors.gray.secondary} size={14} />
            </CloseButton>
          </Row>
          <Space height={16} />
          {loading || !data ? (
            <>
              <Space height={16} />
              <PageLoadingIndicator />
              <Space height={48} />
            </>
          ) : (
            <ChargeCreditCardModalContent
              invoice={data.invoiceByUuid}
              onSuccess={() => {
                chargeCreditCardSuccessToast.handleToast();
                handleClose();
              }}
              handleClose={handleClose}
              refetch={refetch}
            />
          )}
        </ModalContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ChargeCreditCardModal.query = gql`
  query ChargeCreditCardModal($invoiceUuid: String!) {
    ${gql.query}
    invoiceByUuid(invoiceUuid: $invoiceUuid) {
      id
      identifier
      remainingBalance
      dueDate
      isRecurring
      recurringInvoice {
        id
        payengineCreditCard {
          id
          cardName
          expireDate
        }
      }
      isAutopayEnabled
      project {
        id
        currentPrimaryBill {
          id
        }
        billingClient {
          id
          primaryContact {
            id
          }
          payengineCreditCards {
            id
            token
            cardName
            expireDate
          }
        }
      }
      organization {
        id
        name
        mode
      }
    }
  }
`;

export default ChargeCreditCardModal;
