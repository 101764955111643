// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import InventorySurveyMethodForm from '@shared/modules/Inventory/forms/InventorySurveyMethodForm';

const useUpdateInventorySurveyMethod = ({inventorySurveyMethodForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      inventorySurveyMethodForm: InventorySurveyMethodForm.toForm(inventorySurveyMethodForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateInventorySurveyMethod.mutation,
    variables: {
      inventorySurveyMethodForm: InventorySurveyMethodForm.toMutation(
        form.values.inventorySurveyMethodForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateInventorySurveyMethod.mutation = gql`
  mutation useUpdateInventorySurveyMethod($inventorySurveyMethodForm: InventorySurveyMethodForm!) {
    response: updateInventorySurveyMethod(inventorySurveyMethodForm: $inventorySurveyMethodForm) {
      ${gql.errors}
      inventory {
        id
        surveyMethodId
      }
    }
  }
`;

export default useUpdateInventorySurveyMethod;
