// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {Phone, withFragment, Datetime} from '@supermove/utils';

// App
import CallDirection from '@shared/modules/Call/enums/CallDirection';
import CallStatus from '@shared/modules/Call/enums/CallStatus';

const getLeadDisplayName = withFragment(
  (call) => {
    return call?.lead?.client?.primaryContact?.fullName;
  },
  gql`
    fragment Call_getLeadDisplayName on Call {
      id
      lead {
        id
        client {
          id
          primaryContact {
            id
            fullName
          }
        }
      }
    }
  `,
);

const getDisplayPhoneNumber = withFragment(
  (call) => {
    const {calleePhoneNumber, callerPhoneNumber, direction} = call;
    if (direction === CallDirection.INBOUND) {
      return Phone.display(callerPhoneNumber);
    } else {
      return Phone.display(calleePhoneNumber);
    }
  },
  gql`
    fragment Call_getDisplayPhoneNumber on Call {
      id
      direction
      calleePhoneNumber
      callerPhoneNumber
    }
  `,
);

const getPhoneNumber = withFragment(
  (call) => {
    const {calleePhoneNumber, callerPhoneNumber, direction} = call;
    if (direction === CallDirection.INBOUND) {
      return callerPhoneNumber;
    } else {
      return calleePhoneNumber;
    }
  },
  gql`
    fragment Call_getPhoneNumber on Call {
      id
      direction
      calleePhoneNumber
      callerPhoneNumber
    }
  `,
);

const getCallBadge = withFragment(
  (call) => {
    switch (true) {
      case call.direction === CallDirection.OUTBOUND:
        return {
          icon: Icon.PhoneOutgoing,
          color: colors.blue.interactive,
          text: 'Outgoing',
        };
      case call.status === CallStatus.MISSED:
        return {
          icon: Icon.PhoneMissed,
          color: colors.red.warning,
          text: 'Missed',
        };
      default:
        return {
          icon: Icon.PhoneIncoming,
          color: colors.green.status,
          text: 'Incoming',
        };
    }
  },
  gql`
    fragment Call_getCallBadge on Call {
      id
      direction
      status
    }
  `,
);

const getDisplayLocation = withFragment(
  (call) => {
    const {calleeCity, calleeState, callerCity, callerState, direction} = call;
    if (direction === CallDirection.INBOUND) {
      if (!callerCity || !callerState) {
        return '';
      }
      return `${callerCity}, ${callerState}`;
    } else {
      if (!calleeCity || !calleeState) {
        return '';
      }
      return `${calleeCity}, ${calleeState}`;
    }
  },
  gql`
    fragment Call_getDisplayLocation on Call {
      id
      direction
      calleeCity
      calleeState
      callerCity
      callerState
    }
  `,
);

const getDisplayTime = withFragment(
  (call) => {
    // Fall back to createdAt if endTime is not available
    const endTime = call.endTime || call.createdAt;
    switch (call.status) {
      case CallStatus.MISSED:
      case CallStatus.COMPLETE:
      case CallStatus.ERROR:
      case CallStatus.CANCELLED:
        return Datetime.toDisplayTime(Datetime.fromDatetime(endTime));
      case CallStatus.OUTGOING_RINGING_TO_NUMBER:
      case CallStatus.OUTGOING_RINGING_FROM_NUMBER:
      case CallStatus.IN_PROGRESS:
        return 'In Progress';
      default:
        if (call.endTime) {
          return Datetime.toDisplayTime(Datetime.fromDatetime(endTime));
        }
        return;
    }
  },
  gql`
    fragment Call_getDisplayTime on Call {
      id
      endTime
      createdAt
      status
    }
  `,
);

const getDisplayDate = withFragment(
  (call, format) => {
    const endTime = call.endTime || call.createdAt;
    return Datetime.toDisplayDate(Datetime.fromDatetime(endTime), format);
  },
  gql`
    fragment Call_getDisplayDate on Call {
      id
      endTime
      createdAt
    }
  `,
);

const getElapsedTime = withFragment(
  (call) => {
    const startTime = call.startTime || call.createdAt;
    return Datetime.getElapsedSeconds(Datetime.fromDatetime(startTime));
  },
  gql`
    fragment Call_getElapsedTime on Call {
      id
      startTime
      createdAt
    }
  `,
);

const Call = {
  getDisplayPhoneNumber,
  getPhoneNumber,
  getCallBadge,
  getDisplayTime,
  getDisplayLocation,
  getLeadDisplayName,
  getDisplayDate,
  getElapsedTime,
};

export default Call;
