// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

const ProjectStatusContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding-vertical: 4px;
  padding-horizontal: 8px;
  border-radius: 4px;
  background-color: ${({color}) => colors.getBackgroundColor(color)};
`;

const ProjectStatusText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${({color}) => color};
`;

const ProjectStatusBadge = ({project}) => {
  const responsive = useResponsive();
  const status = Project.getProjectStatus(project);
  const color = Project.getProjectStatusColor(project);
  const icon = Project.getProjectStatusIcon(project);

  return (
    <ProjectStatusContainer color={color}>
      <Icon source={icon} size={Icon.Sizes.Medium} color={color} />
      <Space width={8} />
      <ProjectStatusText color={color} responsive={responsive}>
        {status}
      </ProjectStatusText>
    </ProjectStatusContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectStatusBadge.fragment = gql`
  ${Project.getProjectStatus.fragment}
  ${Project.getProjectStatusColor.fragment}
  ${Project.getProjectStatusIcon.fragment}
  fragment ProjectStatusBadge on Project {
    id
    ...Project_getProjectStatus
    ...Project_getProjectStatusColor
    ...Project_getProjectStatusIcon
  }
`;

export default ProjectStatusBadge;
