// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  Space,
  Styled,
  ScrollView,
  DropdownInput,
  RadioInput,
  DateInput,
  Icon,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Callout from '@shared/design/components/Callout';
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import RecurringTaskCadenceKind from '@shared/modules/Accounting/enums/RecurringTaskCadenceKind';
import RecurringTaskKind from '@shared/modules/Accounting/enums/RecurringTaskKind';
import RecurringTaskForm from '@shared/modules/Accounting/forms/RecurringTaskForm';
import InvoicePaymentTerm from '@shared/modules/Billing/enums/InvoicePaymentTerm';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import ProjectAttachmentUploader from '@shared/modules/File/components/ProjectAttachmentUploader';
import SwitchField from 'modules/App/components/SwitchField';
import TextTooltip from 'modules/App/components/TextTooltip';

const TooltipChildContainer = Styled.View`
`;

const Container = Styled.View`
  flex: 1;
`;

const DateLabelContainer = Styled.View`
  width: 60px;
`;

const CadenceLabelContainer = Styled.View`
  width: 100px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  z-index: ${({index}) => (index ? 200 - index : 200)};
`;

const RowAlignNormal = Styled.View`
  flex-direction: row;
  z-index: ${({index}) => (index ? 200 - index : 200)};
`;

const LabelText = Styled.Text`
  ${Typography.Subheading}
`;

const BodyText = Styled.Text`
  ${Typography.Body}
`;

const DueDateContainer = Styled.View`
  border-radius: 4px;
  border-color: ${colors.gray.border};
  border-width: 1px;
  padding: 12px;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const TextTooltipWrapper = ({isVisible, placement, text, children}) => {
  return isVisible ? (
    <TextTooltip placement={placement} text={text}>
      {children}
    </TextTooltip>
  ) : (
    children
  );
};

const InvoiceStartEndDateField = ({
  form,
  field,
  isStart,
  startEndDateFieldRadioOptions,
  setStartEndDateFieldRadioOptions,
  disabled,
}) => {
  const startEndFieldName = isStart
    ? `${field}.recurringTaskForm.startThresholdDate`
    : `${field}.recurringTaskForm.endThresholdDate`;

  const startEndDateFieldRadioOption = startEndDateFieldRadioOptions[isStart ? 'start' : 'end'];
  return (
    <Container>
      <RadioInput
        disabled={disabled}
        isSelected={!startEndDateFieldRadioOption}
        labelstyle={Typography.Body3}
        color={colors.blue.interactive}
        label={isStart ? 'Immediately' : 'Never'}
        value={!startEndDateFieldRadioOption}
        onSelect={(value) => {
          if (
            startEndDateFieldRadioOption
              ? value !== startEndDateFieldRadioOption
              : value === startEndDateFieldRadioOption
          ) {
            form.setFieldValue(startEndFieldName, null);
            setStartEndDateFieldRadioOptions((prevState) => {
              prevState[isStart ? 'start' : 'end'] = !startEndDateFieldRadioOption;
              return {
                ...prevState,
              };
            });
          }
        }}
      />
      <Space height={12} />
      <Row>
        <RadioInput
          disabled={disabled}
          isSelected={startEndDateFieldRadioOption}
          labelstyle={Typography.Body3}
          color={colors.blue.interactive}
          label={isStart ? 'After' : 'On'}
          value={startEndDateFieldRadioOption}
          onSelect={(value) => {
            if (
              startEndDateFieldRadioOption
                ? value !== startEndDateFieldRadioOption
                : value === startEndDateFieldRadioOption
            ) {
              form.setFieldValue(startEndFieldName, null);
              setStartEndDateFieldRadioOptions((prevState) => {
                prevState[isStart ? 'start' : 'end'] = !startEndDateFieldRadioOption;
                return {
                  ...prevState,
                };
              });
            }
          }}
        />
        <Space width={4} />
        <FieldInput
          {...form}
          component={DateInput}
          name={startEndFieldName}
          input={{
            placeholder: 'MM/DD/YY',
            setFieldValue: form.setFieldValue,
            style: {flex: 1, width: '100%'},
            disabled: !startEndDateFieldRadioOption,
          }}
          style={{flex: 1, width: '100%'}}
        />
      </Row>
    </Container>
  );
};

const RecurringInvoiceOnFields = ({form, field, project, disableRecurringFields}) => {
  const repeatOnTheOptions = new Array(31).fill().map((item, index) => {
    return {
      value: index + 1,
      label: `${Datetime.getOrdinalForEntireMonth(index + 1)} of the month`,
    };
  });

  const isAutopayField = 'invoiceForm.isAutopay';
  const isAutopay = _.get(form.values, isAutopayField);

  // Set radio button options for start/end threshold date
  const [startEndDateFieldRadioOptions, setStartEndDateFieldRadioOptions] = useState({
    start: !!_.get(form, 'values.invoiceForm.recurringTaskForm.startThresholdDate'),
    end: !!_.get(form, 'values.invoiceForm.recurringTaskForm.endThresholdDate'),
  });
  const {isEnabledAutopayRecurringInvoicesWithDefaultCard} = project.organization.features;

  return (
    <React.Fragment>
      <Space height={16} />
      <Row index={1}>
        <CadenceLabelContainer>
          <BodyText>Repeat every</BodyText>
        </CadenceLabelContainer>
        <Space width={12} />
        <FieldInput
          {...form}
          index={1}
          component={DropdownInput}
          name={`${field}.recurringTaskForm.cadenceInterval`}
          input={{
            disabled: disableRecurringFields,
            options: new Array(12).fill().map((item, index) => {
              return {
                value: index + 1,
                label: index + 1,
              };
            }),
            setFieldValue: form.setFieldValue,
            style: {flex: 1, width: '80px'},
            components: {
              IndicatorSeparator: () => null,
            },
          }}
        />
        <Space width={12} />
        <FieldInput
          {...form}
          index={1}
          component={DropdownInput}
          name={`${field}.recurringTaskForm.cadenceKind`}
          input={{
            options: [{value: RecurringTaskCadenceKind.MONTHLY, label: 'Month(s)'}],
            style: {flex: 1},
            disabled: true,
            components: {
              IndicatorSeparator: () => null,
            },
          }}
          style={{flex: 1}}
        />
      </Row>
      <Space height={16} />
      <Row index={2}>
        <CadenceLabelContainer>
          <BodyText>Repeat on the</BodyText>
        </CadenceLabelContainer>
        <Space width={12} />
        <FieldInput
          {...form}
          index={1}
          component={DropdownInput}
          name={`${field}.recurringTaskForm.cadenceRepeatsOn`}
          input={{
            disabled: disableRecurringFields,
            options: repeatOnTheOptions,
            setFieldValue: form.setFieldValue,
            style: {flex: 1},
            components: {
              IndicatorSeparator: () => null,
            },
          }}
          style={{flex: 1}}
        />
      </Row>
      <Space height={16} />
      <RowAlignNormal index={3}>
        <DateLabelContainer>
          <Space height={4} />
          <BodyText>Starting</BodyText>
        </DateLabelContainer>
        <Space width={12} />
        <InvoiceStartEndDateField
          form={form}
          field={field}
          isStart
          project={project}
          startEndDateFieldRadioOptions={startEndDateFieldRadioOptions}
          setStartEndDateFieldRadioOptions={setStartEndDateFieldRadioOptions}
          disabled={disableRecurringFields}
        />
      </RowAlignNormal>
      <Space height={16} />
      <RowAlignNormal index={4}>
        <DateLabelContainer>
          <Space height={4} />
          <BodyText>Ending</BodyText>
        </DateLabelContainer>
        <Space width={12} />
        <InvoiceStartEndDateField
          form={form}
          field={field}
          project={project}
          startEndDateFieldRadioOptions={startEndDateFieldRadioOptions}
          setStartEndDateFieldRadioOptions={setStartEndDateFieldRadioOptions}
          disabled={disableRecurringFields}
        />
      </RowAlignNormal>
      {isEnabledAutopayRecurringInvoicesWithDefaultCard && (
        <React.Fragment>
          <Space height={16} />
          <TextTooltipWrapper
            isVisible={project.billingClient.creditCards.length === 0}
            text={'Cannot enroll recurring invoice in autopay if client has no credit cards saved'}
            placement={'left'}
          >
            <TooltipChildContainer>
              <Checkbox
                label={'Automatically Charge Default Card'}
                hint={
                  'When selected, this will automatically charge the default card the client has on file on the invoice due date.'
                }
                isDisabled={project.billingClient.creditCards.length === 0}
                isChecked={isAutopay}
                handleToggle={() => form.setFieldValue(isAutopayField, !isAutopay)}
              />
            </TooltipChildContainer>
          </TextTooltipWrapper>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const getDescriptionString = ({invoiceDate, dueDate}) => {
  // invoiceDate could be a string 'MM/DD/YY', adding a check so we don't run into errors
  const isToday = _.isString(invoiceDate) ? null : Datetime.isToday(invoiceDate);
  const invoiceDateString = !invoiceDate
    ? 'MM/DD/YY'
    : Datetime.convertToDisplayDate(invoiceDate, Datetime.DISPLAY_SHORT_DATE);
  const dueDateString = !dueDate
    ? 'MM/DD/YY'
    : invoiceDate &&
        Datetime.convertToDisplayDate(invoiceDate) === Datetime.convertToDisplayDate(dueDate)
      ? 'receipt'
      : Datetime.convertToDisplayDate(dueDate, Datetime.DISPLAY_SHORT_DATE);
  return `The invoice will be sent ${
    isToday ? 'today' : `on ${invoiceDateString}`
  } and will be due on ${dueDateString}.`;
};

const setNonRecurringInvoiceSettingsDueDateDescription = ({form}) => {
  const invoiceDate = _.get(form.values, 'invoiceForm.date');
  const paymentTerm = _.get(form.values, 'invoiceForm.paymentTerm');

  if (paymentTerm === InvoicePaymentTerm.NO_DUE_DATE) {
    // invoiceDate could be a string 'MM/DD/YY', adding a check so we don't run into errors
    return `The invoice will be sent on ${
      invoiceDate && !_.isString(invoiceDate)
        ? Datetime.convertToDisplayDate(invoiceDate, Datetime.DISPLAY_SHORT_DATE)
        : 'MM/DD/YY'
    }.`;
  }

  if (invoiceDate) {
    return getDescriptionString({
      invoiceDate,
      dueDate: InvoicePaymentTerm.getNextDateWithPaymentTerm(invoiceDate, paymentTerm),
    });
  }

  if (!invoiceDate || !paymentTerm) {
    return getDescriptionString({invoiceDate: null, dueDate: null});
  }
};

const setRecurringInvoiceSettingsDueDateDescription = ({form}) => {
  const cadenceKind = _.get(form.values, 'invoiceForm.recurringTaskForm.cadenceKind');
  const cadenceRepeatsOn = _.get(form.values, 'invoiceForm.recurringTaskForm.cadenceRepeatsOn');
  const startDate = _.get(form.values, 'invoiceForm.recurringTaskForm.startThresholdDate');
  const paymentTerm = _.get(form.values, 'invoiceForm.paymentTerm');
  const invoiceDate = Datetime.convertDisplayDateToDate(
    RecurringTaskCadenceKind.getNextReportCycleDate(cadenceKind, {cadenceRepeatsOn}, startDate),
    'MM/DD/YYYY',
  );

  if (paymentTerm === InvoicePaymentTerm.NO_DUE_DATE) {
    // invoiceDate could be a string 'MM/DD/YY', adding a check so we don't run into errors
    return `The invoice will be sent on ${
      invoiceDate && !_.isString(invoiceDate)
        ? Datetime.convertToDisplayDate(invoiceDate, Datetime.DISPLAY_SHORT_DATE)
        : 'MM/DD/YY'
    }.`;
  }

  return getDescriptionString({
    invoiceDate,
    dueDate: InvoicePaymentTerm.getNextDateWithPaymentTerm(invoiceDate, paymentTerm),
  });
};

const InvoiceSettingsFields = ({form, field, project, allowRecurring}) => {
  const invoiceEmailTemplate = project.organization.invoiceEmailTemplate.map(
    (emailTemplate, index) => {
      return {
        value: emailTemplate.id,
        label: emailTemplate.name,
      };
    },
  );

  const disableRecurringFields = _.get(form.values, `${field}.disableRecurringFields`);
  const isInvoiceDueDateVisible = [InvoiceStatus.SCHEDULED, InvoiceStatus.DRAFT].includes(
    _.get(form.values, 'invoiceForm.status'),
  );

  return (
    <ScrollView>
      <LabelText>Invoice Terms</LabelText>
      <Space height={16} />
      {allowRecurring && (
        <React.Fragment>
          {disableRecurringFields && (
            <React.Fragment>
              <Callout
                text={
                  "If you'd like to edit recurring invoice settings, you must do so on the most recent invoice in the chain."
                }
              />
              <Space height={16} />
            </React.Fragment>
          )}
          <Row index={0}>
            <SwitchField
              {...form}
              index={0}
              name={`${field}.isRecurring`}
              disabled={disableRecurringFields}
              onChangeValue={(value) => {
                if (_.get(form.values, value)) {
                  form.setFieldValue('invoiceForm.recurringTaskForm', null);
                } else {
                  form.setFieldValue(
                    'invoiceForm.recurringTaskForm',
                    RecurringTaskForm.newForMonthly({
                      organizationId: project.organization.id,
                      kind: RecurringTaskKind.INVOICE,
                      cadenceKind: RecurringTaskCadenceKind.MONTHLY,
                    }),
                  );
                  form.setFieldValue('invoiceForm.date', null);
                }
              }}
            />
            <Space width={8} />
            <BodyText>Recurring invoice</BodyText>
          </Row>
        </React.Fragment>
      )}
      {_.get(form, 'values.invoiceForm.isRecurring') ? (
        <RecurringInvoiceOnFields
          form={form}
          field={field}
          project={project}
          disableRecurringFields={disableRecurringFields}
        />
      ) : (
        <React.Fragment>
          {allowRecurring && <Space height={16} />}
          <FieldInput
            {...form}
            component={DateInput}
            isRequired
            label={'Invoice Date'}
            name={`invoiceForm.date`}
            input={{
              placeholder: 'MM/DD/YY',
              setFieldValue: form.setFieldValue,
              style: {flex: 1, width: '100%'},
            }}
          />
        </React.Fragment>
      )}
      <Space height={16} />
      <FieldInput
        {...form}
        index={5}
        isRequired
        component={DropdownInput}
        name={`${field}.paymentTerm`}
        label={'Payment Terms'}
        input={{
          options: InvoicePaymentTerm.DROPDOWN_OPTIONS,
          placeholder: 'Select payment term',
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
          components: {
            IndicatorSeparator: () => null,
          },
        }}
      />
      <Space height={16} />
      {isInvoiceDueDateVisible && (
        <React.Fragment>
          <DueDateContainer>
            <Icon source={Icon.Calendar} color={colors.green.status} size={16} />
            <Space width={8} />
            <BodyText>
              {_.get(form.values, 'invoiceForm.isRecurring')
                ? setRecurringInvoiceSettingsDueDateDescription({form})
                : setNonRecurringInvoiceSettingsDueDateDescription({form})}
            </BodyText>
          </DueDateContainer>
          <Space height={16} />
        </React.Fragment>
      )}
      <LabelText>Email Settings</LabelText>
      <Space height={16} />
      <FieldInput
        {...form}
        index={6}
        label={'Invoice Template'}
        isRequired
        name={`invoiceForm.documentTemplateId`}
        component={DropdownInput}
        input={{
          options: project.organization.documentTemplatesByCategory.map((documentTemplate) => {
            return {
              value: documentTemplate.id,
              label: documentTemplate.name,
            };
          }),
          placeholder: 'Select a invoice document template',
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={7}
        label={'Email Template'}
        isRequired
        component={DropdownInput}
        name={`${field}.emailTemplateId`}
        tooltip={
          'This is the email we will send with the invoice. You can create and edit templates in settings.'
        }
        input={{
          options: invoiceEmailTemplate,
          placeholder: 'Choose email template',
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
          components: {
            IndicatorSeparator: () => null,
          },
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={8}
        label={'Email Receipt Template'}
        component={DropdownInput}
        name={`${field}.emailReceiptTemplateId`}
        tooltip={
          'Selecting a default receipt template will automatically send the receipt email whenever a payment is made on an invoice. If you prefer not to send receipt emails, leave this blank.'
        }
        input={{
          options: _.isEmpty(project.organization.invoiceReceiptEmailTemplate)
            ? []
            : project.organization.invoiceReceiptEmailTemplate.map((emailTemplate, index) => {
                return {
                  value: _.toString(emailTemplate.id),
                  label: emailTemplate.name,
                };
              }),
          placeholder: 'Choose email receipt template',
          setFieldValue: form.setFieldValue,
          isClearable: true,
          style: {flex: 1},
          components: {
            IndicatorSeparator: () => null,
          },
        }}
      />
      {project.organization.features.isEnabledInvoiceEmailAttachments && (
        <Container>
          <Space height={16} />
          <LabelText>Attachments</LabelText>
          <Space height={16} />
          <ProjectAttachmentUploader
            form={form}
            field={'invoiceForm.attachmentForms'}
            project={project}
          />
        </Container>
      )}
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceSettingsFields.fragment = gql`
  ${ProjectAttachmentUploader.fragment}

  fragment InvoiceSettingsFields on Project {
    id
    organization {
      id
      slug
      documentTemplatesByCategory(categories: ["INVOICE"]) {
        id
        name
      }
      emailTemplates {
        id
        name
        kind
      }
      invoiceEmailTemplate: emailTemplatesByKind(emailTemplateKinds: ["CUSTOM_INVOICE"]) {
        id
        name
      }
      invoiceReceiptEmailTemplate: emailTemplatesByKind(
        emailTemplateKinds: ["CUSTOM_INVOICE_RECEIPT"]
      ) {
        id
        name
      }
      features {
        isEnabledInvoiceEmailAttachments: isEnabled(feature: "INVOICE_EMAIL_ATTACHMENTS")
        isEnabledAutopayRecurringInvoicesWithDefaultCard: isEnabled(
          feature: "AUTOPAY_RECURRING_INVOICES_WITH_DEFAULT_CARD"
        )
      }
    }
    billingClient {
      id
      creditCards {
        id
      }
    }
    ...ProjectAttachmentUploader
  }
`;

export default InvoiceSettingsFields;
