// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ImportInventoryLibraryRoomTypesForm from '@shared/modules/File/forms/ImportInventoryLibraryRoomTypesForm';

const useImportInventoryLibraryRoomTypesMutation = ({
  importInventoryLibraryRoomTypesForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      importInventoryLibraryRoomTypesForm: ImportInventoryLibraryRoomTypesForm.toForm(
        importInventoryLibraryRoomTypesForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useImportInventoryLibraryRoomTypesMutation.mutation,
    variables: {
      importInventoryLibraryRoomTypesForm: ImportInventoryLibraryRoomTypesForm.toMutation(
        form.values.importInventoryLibraryRoomTypesForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useImportInventoryLibraryRoomTypesMutation.mutation = gql`
  mutation useImportInventoryLibraryRoomTypesMutation(
    $importInventoryLibraryRoomTypesForm: ImportInventoryLibraryRoomTypesForm!
  ) {
    response: importInventoryLibraryRoomTypes(
      importInventoryLibraryRoomTypesForm: $importInventoryLibraryRoomTypesForm
    ) {
      ${gql.errors}
    }
  }
`;

export default useImportInventoryLibraryRoomTypesMutation;
