// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import CartonCategoryItem from '@shared/modules/Inventory/Summary/components/CartonCategoryItem';

const EmptyContainer = Styled.View`
  align-items: center;
`;

const EmptyTitle = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const getAllItemsFromCartonCategories = (cartonCategories) => {
  return _.flatten(cartonCategories.map((cartonCategory) => cartonCategory.collection.items));
};

const getHasCarton = (inventory) => {
  const allItems = getAllItemsFromCartonCategories(inventory.cartonCategories);
  return _.some(allItems, 'takeCount');
};

const CartonCategoriesList = ({inventory, isDisabled, showNotes, showWeight, showVolume}) => {
  const hasCarton = getHasCarton(inventory);
  return (
    <FlatList
      data={hasCarton ? inventory.cartonCategories : []}
      keyExtractor={(cartonCategory) => cartonCategory.id}
      ItemSeparatorComponent={() => <Space height={12} />}
      renderItem={({item: cartonCategory}) => (
        <CartonCategoryItem
          cartonCategory={cartonCategory}
          isDisabled={isDisabled}
          showNotes={showNotes}
          showWeight={showWeight}
          showVolume={showVolume}
        />
      )}
      ListEmptyComponent={() => (
        <EmptyContainer>
          <EmptyTitle>Your carton summary is empty.</EmptyTitle>
          <Space height={8} />
        </EmptyContainer>
      )}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CartonCategoriesList.fragment = gql`
  ${CartonCategoryItem.fragment}

  fragment CartonCategoriesList on Inventory {
    id
    cartonCategories {
      id
      collection {
        id
        items {
          id
          takeCount
        }
      }
      ...CartonCategoryItem
    }
  }
`;

export default CartonCategoriesList;
