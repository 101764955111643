// Libraries
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import InputIsRequiredCheckBox from 'modules/Organization/Settings/Document/components/InputIsRequiredCheckBox';

const TextInput = Styled.TextInput`
  ${Typography.Body3}
  border-width: 1px;
  border-color: ${colors.gray.border};
  width: 100%;
  padding-vertical: 9px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const DeleteFieldColumn = Styled.View`
  justify-content: center;
  align-items: flex-end;
  padding-left: 16px;
`;

const addFieldInput = ({setDraftDocumentContentJson, documentItemIndex}) => {
  setDraftDocumentContentJson((prevState) => {
    prevState.documentItems[documentItemIndex].input.value.values.push(['', '']);
    return {
      ...prevState,
    };
  });
};

const removeFieldInput = ({setDraftDocumentContentJson, documentItemIndex, fieldIndex}) => {
  setDraftDocumentContentJson((prevState) => {
    prevState.documentItems[documentItemIndex].input.value.values.splice(fieldIndex, 1);
    return {
      ...prevState,
    };
  });
};

const InputTextAreasInputEditor = ({
  documentItem,
  documentItemIndex,
  setDraftDocumentContentJson,
}) => {
  const {values} = documentItem.input.value;
  return (
    <React.Fragment>
      <InputIsRequiredCheckBox
        documentItem={documentItem}
        documentItemIndex={documentItemIndex}
        setDraftDocumentContentJson={setDraftDocumentContentJson}
      />
      <Space height={24} />
      {values.map((value, index) => (
        <React.Fragment>
          <Row>
            <React.Fragment>
              <TextInput
                autoFocus
                key={index}
                placeholder={'Text Area Title'}
                value={value[0]}
                multiline
                onChangeText={(text) => {
                  setDraftDocumentContentJson((prevState) => {
                    prevState.documentItems[documentItemIndex].input.value.values[index][0] = text;
                    return {
                      ...prevState,
                    };
                  });
                }}
              />
            </React.Fragment>
            {values.length > 1 && (
              <DeleteFieldColumn>
                <TertiaryButton
                  iconLeft={Icon.Trash}
                  iconSize={12}
                  textColor={colors.gray.secondary}
                  onPress={() =>
                    removeFieldInput({
                      setDraftDocumentContentJson,
                      documentItemIndex,
                      fieldIndex: index,
                    })
                  }
                />
              </DeleteFieldColumn>
            )}
          </Row>
          <Space height={32} />
        </React.Fragment>
      ))}
      <TertiaryButton
        iconLeft={Icon.Plus}
        text={'Add Item'}
        onPress={() => addFieldInput({setDraftDocumentContentJson, documentItemIndex})}
      />
    </React.Fragment>
  );
};

export default InputTextAreasInputEditor;
