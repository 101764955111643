// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useCompleteJobManuallyMutation = ({job, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {},
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCompleteJobManuallyMutation.mutation,
    variables: {
      jobId: job.id,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCompleteJobManuallyMutation.mutation = gql`
  mutation useCompleteJobManuallyMutation($jobId: Int!) {
    response: completeJobManually(jobId: $jobId) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default useCompleteJobManuallyMutation;
