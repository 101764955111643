// App
import RecurringTaskKind from '@shared/modules/Accounting/enums/RecurringTaskKind';

const ALL_EMPLOYEE = 'ALL_EMPLOYEE';
const CONTRACTOR = 'CONTRACTOR';

const getRecurringTaskKind = (payrollReportKind) => {
  switch (payrollReportKind) {
    case ALL_EMPLOYEE:
      return RecurringTaskKind.EMPLOYEE_PAYROLL_REPORT;
    case CONTRACTOR:
      return RecurringTaskKind.CONTRACTOR_PAYROLL_REPORT;
    default:
      return null;
  }
};

export default {
  ALL_EMPLOYEE,
  CONTRACTOR,

  getRecurringTaskKind,
};
