// Supermove
import {Currency, Percent} from '@supermove/utils';

// App
import PaymentFeeKind from '@shared/modules/Organization/enums/PaymentFeeKind';

const _new = () => ({
  name: '',
  kind: PaymentFeeKind.PERCENT,
  amount: '',
  percentage: '',
});

const editFromJobRateData = ({name, amount, percentage}) => ({
  name,
  kind: amount
    ? PaymentFeeKind.RATE
    : percentage === 1
      ? PaymentFeeKind.NO_FEE
      : PaymentFeeKind.PERCENT,
  amount: amount || '',
  percentage: percentage || '',
});

const toForm = ({name, kind, amount, percentage}) => ({
  name,
  kind,
  amount: amount ? Currency.toForm(amount) : '',
  // percentage is stored as a multiplier but the input is relative (ie. 1.03 vs 3%)
  percentage: percentage ? Percent.toForm(percentage - 1) : '',
});

const toMutation = ({name, kind, amount, percentage}) => ({
  name,
  kind,
  amount: amount ? Currency.toMutation(amount) : null,
  percentage: percentage ? Percent.toMutation(percentage) : null,
});

const PaymentFeeForm = {
  new: _new,
  editFromJobRateData,
  toForm,
  toMutation,
};

export default PaymentFeeForm;
