// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Callout from '@shared/design/components/Callout';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import UnfinalizeInvoiceModal from 'modules/Storage/components/UnfinalizeInvoiceModal';

const FinalizedInvoiceCallout = ({invoice, refetch, isAuthorizedAccountingActions}) => {
  const unfinalizeInvoiceModal = useModal({name: 'Unfinalize Invoice Modal', enableTracking: true});
  const isInvoiceFinalized = invoice.status === InvoiceStatus.FINALIZED;

  if (!isInvoiceFinalized) {
    return null;
  }

  return (
    <React.Fragment>
      <Callout
        text={
          isAuthorizedAccountingActions ? (
            <React.Fragment>
              {`Invoice is paid and finalized. `}
              <Callout.Link
                text={'Unfinalize'}
                color={colors.gray.primary}
                onPress={unfinalizeInvoiceModal.handleOpen}
              />
              {` it to make changes.`}
            </React.Fragment>
          ) : (
            'You do not have permission to unfinalize. Please contact an admin to make changes.'
          )
        }
      />
      <Space height={16} />
      <UnfinalizeInvoiceModal
        invoice={invoice}
        isOpen={unfinalizeInvoiceModal.isOpen}
        handleClose={unfinalizeInvoiceModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
FinalizedInvoiceCallout.fragment = gql`
  ${UnfinalizeInvoiceModal.fragment}
  fragment FinalizedInvoiceCallout on Invoice {
    id
    status
    ...UnfinalizeInvoiceModal
  }
`;

export default FinalizedInvoiceCallout;
