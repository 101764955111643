// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (organization) => ({
    organizationId: organization.id,
    virtualWalkthroughPhotoFileId: organization.virtualWalkthroughPhotoFileId,

    // Private
    isUploadingPhoto: false,
  }),
  gql`
    fragment OrganizationVirtualWalkthroughSettingsForm_edit on Organization {
      id
      virtualWalkthroughPhotoFileId
    }
  `,
);

const toForm = ({organizationId, virtualWalkthroughPhotoFileId}) => ({
  organizationId,
  virtualWalkthroughPhotoFileId,

  // Private
  isUploadingPhoto: false,
});

const toMutation = ({organizationId, virtualWalkthroughPhotoFileId}) => ({
  organizationId,
  virtualWalkthroughPhotoFileId,
});

const OrganizationVirtualWalkthroughSettingsForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationVirtualWalkthroughSettingsForm;
