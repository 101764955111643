// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Formula} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import {EvaluateFormulaStringInput} from '@shared/formulas/src/library/EvaluateFormulaStringInput';
import evaluateFormulaString from '@shared/formulas/src/logic/evaluateFormulaString';

const getAstJson = (formulaForm) => {
  const {formulaString} = formulaForm;
  const {formulaAST} = evaluateFormulaString(new EvaluateFormulaStringInput(formulaString));
  return formulaAST;
};

const _new = ({organizationId, userId}) => ({
  identifier: '',
  organizationId,
  name: '',
  description: '',
  astJson: {},
  createdById: userId,
  updatedById: userId,

  // Private
  formulaString: '',
});

const edit = withFragment(
  (formula, {userId}) => ({
    identifier: formula.identifier,
    organizationId: formula.organizationId,
    name: formula.name,
    description: formula.description,
    astJson: formula.astJson,
    createdById: null,
    updatedById: userId,

    // Private
    formulaString: Formula.getFormulaString(formula),
  }),
  gql`
    ${Formula.getFormulaString.fragment}
    fragment FormulaForm_edit on Formula {
      id
      identifier
      organizationId
      name
      description
      astJson
      ...Formula_getFormulaString
    }
  `,
);

const toForm = ({
  identifier,
  organizationId,
  name,
  description,
  astJson,
  formulaString,
  createdById,
  updatedById,
}) => {
  return {
    identifier,
    organizationId,
    name,
    description,
    astJson: getAstJson({formulaString, astJson}),
    createdById,
    updatedById,

    // Private
    formulaString,
  };
};

const toMutation = ({
  identifier,
  organizationId,
  name,
  description,
  astJson,
  formulaString,
  createdById,
  updatedById,
}) => {
  return {
    identifier: identifier || _.toUpper(_.snakeCase(name)),
    organizationId,
    name,
    description,
    astJson: JSON.stringify(getAstJson({formulaString, astJson})),
    createdById,
    updatedById,
  };
};

const FormulaForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default FormulaForm;
