// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import EmailForm from '@shared/modules/Email/forms/EmailForm';

const useSendEmailForProjectMutation = ({viewerId, project, emailTemplate, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      projectId: project.id,
      emailForm: emailTemplate
        ? EmailForm.newFromProjectWithEmailTemplate({
            viewerId,
            project,
            emailTemplate,
            kind: emailTemplate.kind,
          })
        : EmailForm.newFromProject({project, viewerId}),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSendEmailForProjectMutation.mutation,
    variables: {
      projectId: project.id,
      emailForm: EmailForm.toMutation(form.values.emailForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSendEmailForProjectMutation.fragment = gql`
  ${EmailForm.newFromProject.fragment}
  ${EmailForm.newFromProjectWithEmailTemplate.fragment}

  fragment useSendEmailForProjectMutation_Project on Project {
    id
    ...EmailForm_newFromProject
    ...EmailForm_newFromProjectWithEmailTemplate_Project
  }

  fragment useSendEmailForProjectMutation_EmailTemplate on EmailTemplate {
    id
    ...EmailForm_newFromProjectWithEmailTemplate_EmailTemplate
  }
`;

useSendEmailForProjectMutation.mutation = gql`
  mutation SendEmailForProjectMutation(
    $projectId: Int!,
    $emailForm: EmailForm!,
  ) {
    response: sendEmailForProject(
      projectId: $projectId,
      emailForm: $emailForm,
    ) {
      ${gql.errors}
      email {
        id
      }
    }
  }
`;

export default useSendEmailForProjectMutation;
