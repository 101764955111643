// Supermove
import {gql} from '@supermove/graphql';
import {CostItem} from '@supermove/models';
import {Currency, Percent, withFragment} from '@supermove/utils';

// App
import CostItemKind from '@shared/modules/Billing/enums/CostItemKind';
import CostItemUnit from '@shared/modules/Billing/enums/CostItemUnit';

const COST_ITEM_UNIT_TO_KIND_WITH_BILL_MAP = {
  [CostItemUnit.CENTS]: CostItemKind.PER_BILL_TOTAL,
  [CostItemUnit.PERCENT]: CostItemKind.PER_BILL_TOTAL,
  [CostItemUnit.CENTS_PER_POUND]: CostItemKind.PER_WEIGHT,
  [CostItemUnit.CENTS_PER_MILE]: CostItemKind.PER_MILE,
};

const COST_ITEM_UNIT_TO_KIND_WITHOUT_BILL_MAP = {
  [CostItemUnit.CENTS]: CostItemKind.PER_GRAND_TOTAL,
  [CostItemUnit.PERCENT]: CostItemKind.PER_GRAND_TOTAL,
  [CostItemUnit.CENTS_PER_POUND]: CostItemKind.PER_WEIGHT,
  [CostItemUnit.CENTS_PER_MILE]: CostItemKind.PER_MILE,
};

const COST_ITEM_TYPE_ID_FOR_CUSTOM_COST_ITEM = 0;

const getKindFromUnit = ({unit, billId}) => {
  if (billId) {
    return COST_ITEM_UNIT_TO_KIND_WITH_BILL_MAP[unit];
  }
  return COST_ITEM_UNIT_TO_KIND_WITHOUT_BILL_MAP[unit];
};

const getRateToMutation = ({rate, unit}) => {
  switch (unit) {
    case CostItemUnit.PERCENT:
      return Percent.toMutation(rate);
    default:
      return Currency.toMutation(rate);
  }
};

const getTotalWithoutBillItem = ({
  unit,
  kind,
  weight,
  totalDistance,
  billSubtotal,
  projectRevenue,
  rate,
}) => {
  switch (unit) {
    case CostItemUnit.CENTS:
      return Currency.toForm(rate, {shouldHideCentsIfZero: true});
    case CostItemUnit.PERCENT:
      if (kind === CostItemKind.PER_BILL_TOTAL) {
        return Currency.toForm(billSubtotal * rate, {shouldHideCentsIfZero: true});
      }
      return Currency.toForm(projectRevenue * rate, {shouldHideCentsIfZero: true});
    case CostItemUnit.CENTS_PER_POUND:
      return Currency.toForm(weight * rate, {shouldHideCentsIfZero: true});
    case CostItemUnit.CENTS_PER_ONE_HUNDRED_POUNDS:
      return Currency.toForm((weight / 100) * rate, {shouldHideCentsIfZero: true});
    case CostItemUnit.CENTS_PER_MILE:
      return Currency.toForm(totalDistance * rate, {shouldHideCentsIfZero: true});
    default:
      return '$0';
  }
};

const getBaseValueWithoutBillItem = ({
  unit,
  kind,
  weight,
  totalDistance,
  billSubtotal,
  projectRevenue,
}) => {
  switch (unit) {
    case CostItemUnit.PERCENT:
      if (kind === CostItemKind.PER_BILL_TOTAL) {
        return Currency.display(billSubtotal, {shouldHideCentsIfZero: true});
      }
      return Currency.display(projectRevenue, {shouldHideCentsIfZero: true});
    case CostItemUnit.CENTS_PER_POUND:
    case CostItemUnit.CENTS_PER_ONE_HUNDRED_POUNDS:
      return `${weight} lbs`;
    case CostItemUnit.CENTS_PER_MILE:
      return `${totalDistance} mi`;
    default:
      return '';
  }
};

const _new = ({costId, billId}) => {
  return {
    costItemId: null,
    costItemTypeId: null,
    costId,
    billId,
    billItemId: null,
    name: '',
    kind: '',
    unit: '',
    rate: '',
    index: null,
    isDeleted: false,

    // Private
    total: Currency.display(0, {shouldHideCentsIfZero: true}),
    baseValue: '',
    isCustomCostItem: false,
  };
};

const newCustomCostItem = ({costId, billId, name, rate, unit, total, baseValue}) => {
  return {
    costItemId: null,
    costItemTypeId: COST_ITEM_TYPE_ID_FOR_CUSTOM_COST_ITEM,
    costId,
    billId,
    billItemId: null,
    name,
    kind: billId ? CostItemKind.PER_BILL_TOTAL : CostItemKind.PER_GRAND_TOTAL,
    unit,
    rate,
    index: null,
    isDeleted: false,

    // Private
    total,
    baseValue,
    isCustomCostItem: true,
  };
};

const newFromBillItemForm = (billItemForm, {costId}) => {
  return {
    costItemId: null,
    costItemTypeId: null,
    costId,
    billId: billItemForm.billId,
    billItemId: billItemForm.billItemId,
    name: billItemForm.name,
    kind: CostItemKind.PER_BILL_ITEM,
    unit: CostItemUnit.CENTS,
    rate: '',
    index: billItemForm.index,
    isDeleted: false,

    // Private
    total: Currency.display(0, {shouldHideCentsIfZero: true}),
    baseValue: '',
    isCustomCostItem: false,
  };
};

const newFromCostItemType = withFragment(
  (costItemType, {billId, costId, rate, weight, totalDistance, billSubtotal, projectRevenue}) => {
    const {unit} = costItemType;
    return {
      costItemId: null,
      costItemTypeId: costItemType.id,
      costId,
      billId,
      billItemId: null,
      name: costItemType.name,
      kind: costItemType.kind,
      unit,
      rate: CostItem.getDisplayRate({rate, unit}),
      index: null,
      isDeleted: false,

      // Private
      total: getTotalWithoutBillItem({
        unit,
        kind: costItemType.kind,
        weight,
        totalDistance,
        billSubtotal,
        projectRevenue,
        rate,
      }),
      baseValue: getBaseValueWithoutBillItem({
        unit,
        kind: costItemType.kind,
        weight,
        totalDistance,
        billSubtotal,
        projectRevenue,
      }),
      isCustomCostItem: false,
    };
  },
  gql`
    fragment CostItemForm_newFromCostItemType on CostItemType {
      id
      name
      kind
      unit
    }
  `,
);

const edit = withFragment(
  (costItem, {weight, totalDistance, billSubtotal, projectRevenue} = {}) => {
    return {
      costItemId: costItem.id,
      // Setting to a string because costItemType.id comes in
      // as a string and we need matching values for the dropdown.
      // If there is no costItemTypeId, then this is a custom
      // cost item.
      costItemTypeId: costItem.costItemTypeId
        ? String(costItem.costItemTypeId)
        : COST_ITEM_TYPE_ID_FOR_CUSTOM_COST_ITEM,
      costId: costItem.costId,
      billId: costItem.billId,
      billItemId: costItem.billItemId,
      name: costItem.name,
      kind: costItem.kind,
      unit: costItem.unit,
      rate: CostItem.getDisplayRate(costItem),
      index: costItem.index,
      isDeleted: costItem.isDeleted,

      // Private
      total: costItem.total,
      baseValue: getBaseValueWithoutBillItem({
        unit: costItem.unit,
        kind: costItem.kind,
        weight,
        totalDistance,
        billSubtotal,
        projectRevenue,
      }),
      isCustomCostItem: !costItem.costItemTypeId && !costItem.billItemId,
    };
  },
  gql`
    ${CostItem.getDisplayRate.fragment}

    fragment CostItemForm_edit on CostItem {
      id
      costItemTypeId
      billId
      costId
      name
      kind
      unit
      index
      total
      isDeleted
      ...CostItem_getDisplayRate
    }
  `,
);

const toForm = ({
  costItemId,
  costItemTypeId,
  costId,
  billId,
  billItemId,
  name,
  kind,
  unit,
  rate,
  index,
  isDeleted,

  // Private
  total,
  baseValue,
  isCustomCostItem,
}) => {
  return {
    costItemId,
    costItemTypeId,
    costId,
    billId,
    billItemId,
    name,
    kind,
    unit,
    rate,
    index,
    isDeleted,

    // Private
    total: Currency.toForm(total, {shouldHideCentsIfZero: true}),
    baseValue,
    isCustomCostItem,
  };
};

const toMutation = ({
  costItemId,
  costItemTypeId,
  costId,
  billId,
  billItemId,
  name,
  kind,
  unit,
  rate,
  index,
  isDeleted,
}) => {
  const rateForMutation = getRateToMutation({rate, unit});
  return {
    costItemId,
    costItemTypeId:
      costItemTypeId === COST_ITEM_TYPE_ID_FOR_CUSTOM_COST_ITEM ? null : costItemTypeId,
    costId,
    billId,
    billItemId,
    name,
    kind,
    unit: unit === '' ? null : unit,
    rate: rateForMutation,
    index,
    isDeleted: isDeleted || !rateForMutation,
  };
};

const CostItemForm = {
  new: _new,
  newCustomCostItem,
  newFromBillItemForm,
  newFromCostItemType,
  edit,
  toForm,
  toMutation,

  getKindFromUnit,
};

export default CostItemForm;
