/**
 * Component - v2.1.0
 */

// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {PdfView, Styled, Space, VideoPlayer, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {downloadFromUrl} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';

const Image = Styled.Image`
  height: 100%;
`;

const Text = Styled.Text`
  ${Typography.Body3}
  flex: 1;
  align-self: center;
  text-align: center;
  word-break: break-all;
`;

const Background = Styled.View`
  padding-horizontal: 16px;
  flex: 1;
  align-items: center;
  width: 100%;
  background-color: ${colors.gray.background};
`;

const FileViewer = ({file, height, width, style}) => {
  switch (file.mimetype) {
    case 'application/pdf':
      return <PdfView source={{uri: file.downloadUrl}} />;
    case 'image/png':
    case 'image/jpeg':
      return (
        <Image
          resizeMode={'contain'}
          source={{uri: file.downloadUrl}}
          accessibilityLabel={file.filename}
        />
      );
    case 'video/mp4':
    case 'video/wav':
    case 'video/quicktime':
      return (
        <VideoPlayer
          controls
          url={file.playbackUrl}
          height={height}
          width={width}
          style={{flex: 1, ...style}}
          isHLS
        />
      );
    default:
      return (
        <Background>
          <Space style={{flex: 5}} />
          <Text>No preview available for: {file.filename}</Text>
          <Space height={8} />
          <SecondaryButton
            text={'Download'}
            iconLeft={Icon.FileDownload}
            onPress={() => {
              downloadFromUrl(file.downloadUrl);
            }}
          />
          <Space style={{flex: 5}} />
        </Background>
      );
  }
};

// --------------------------------------------------
// Props
// --------------------------------------------------
FileViewer.propTypes = {
  file: PropTypes.object.isRequired,
  style: PropTypes.object,
};

FileViewer.defaultProps = {
  style: {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
FileViewer.fragment = gql`
  fragment FileViewer on File {
    id
    filename
    mimetype
    downloadUrl
    playbackUrl
  }
`;

export default FileViewer;
