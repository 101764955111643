// Supermove
import _ from 'lodash';

// Supermove
import {uuid} from '@supermove/utils';

export interface UpdateTagAssignmentActionFormType {
  workflowRunStepUuid: string;
  tagIds: string[] | null;
  projectId: string | null;
  jobId: string | null;
}

const _new = (): UpdateTagAssignmentActionFormType => ({
  workflowRunStepUuid: uuid(),
  tagIds: [],
  projectId: null,
  jobId: null,
});

const edit = (updateTagAssignmentActionForm: any): UpdateTagAssignmentActionFormType => ({
  workflowRunStepUuid: updateTagAssignmentActionForm.workflowRunStepUuid,
  tagIds: updateTagAssignmentActionForm.tagIds,
  projectId: updateTagAssignmentActionForm.projectId,
  jobId: updateTagAssignmentActionForm.jobId,
});

const toForm = (
  updateTagAssignmentActionForm: UpdateTagAssignmentActionFormType,
): UpdateTagAssignmentActionFormType => ({
  workflowRunStepUuid: updateTagAssignmentActionForm.workflowRunStepUuid,
  tagIds: updateTagAssignmentActionForm.tagIds,
  projectId: updateTagAssignmentActionForm.projectId,
  jobId: updateTagAssignmentActionForm.jobId,
});

const toMutation = (
  updateTagAssignmentActionForm: UpdateTagAssignmentActionFormType,
): UpdateTagAssignmentActionFormType => ({
  workflowRunStepUuid: updateTagAssignmentActionForm.workflowRunStepUuid,
  tagIds: updateTagAssignmentActionForm.tagIds,
  projectId: updateTagAssignmentActionForm.projectId,
  jobId: updateTagAssignmentActionForm.jobId,
});

const UpdateTagAssignmentActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default UpdateTagAssignmentActionForm;
