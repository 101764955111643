// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Currency, Float, withFragment} from '@supermove/utils';

const edit = withFragment(
  (item) => ({
    itemId: item.id,
    collectionId: item.collectionId,
    name: item.name,
    takeCount: item.takeCount,
    leaveCount: item.leaveCount,
    weight: item.weight,
    volume: item.volume,
    price: item.price,
    isDerivedWeight: item.isDerivedWeight,

    // Private
    densityFactor: item.weight / item.volume,
    categoryIds: _.get(item, 'itemType.itemTypeCategories', []).map((itemTypeCategory) => {
      return String(itemTypeCategory.categoryId);
    }),
    kind: _.get(item, 'itemType.kind'),
  }),
  gql`
    fragment ItemForm_edit on Item {
      id
      collectionId
      name
      takeCount
      leaveCount
      weight
      volume
      price
      isDerivedWeight
      itemType {
        id
        kind
        itemTypeCategories {
          id
          categoryId
        }
      }
    }
  `,
);

const _new = ({
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,
  densityFactor,
  kind,
}) => ({
  itemId: undefined,
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,

  // Private
  densityFactor,
  categoryIds: [],
  kind,
});

const getDensityFactor = ({densityFactor}) => {
  return Float.toFloat(densityFactor);
};

const getLeaveCount = ({leaveCount}) => {
  return Float.toFloat(leaveCount);
};

const getPrice = ({price}) => {
  return Currency.convertToCents(price);
};

const getTakeCount = ({takeCount}) => {
  return Float.toFloat(takeCount);
};

const getVolume = ({volume}) => {
  return Float.toFloat(volume);
};

const getWeight = ({weight}) => {
  return Float.toFloat(weight);
};

const toForm = ({
  itemId,
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,

  // Private
  densityFactor,
  categoryIds,
  kind,
}) => ({
  itemId,
  collectionId,
  name,
  takeCount: Float.toForm(takeCount),
  leaveCount: Float.toForm(leaveCount),
  weight: Float.toForm(weight),
  volume: Float.toForm(volume),
  price: Currency.toForm(price),
  isDerivedWeight,

  // Private
  densityFactor: Float.toForm(densityFactor),
  categoryIds,
  kind,
});

const toMutation = ({
  itemId,
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,
}) => ({
  itemId,
  collectionId,
  name,
  takeCount: Float.toMutation(takeCount),
  leaveCount: Float.toMutation(leaveCount),
  weight: Float.toMutation(weight),
  volume: Float.toMutation(volume),
  price: Currency.toMutation(price),
  isDerivedWeight,
});

const ItemForm = {
  // Initialize
  edit,
  new: _new,

  // Getters
  getDensityFactor,
  getLeaveCount,
  getPrice,
  getTakeCount,
  getVolume,
  getWeight,

  // Conversion
  toForm,
  toMutation,
};

export default ItemForm;
