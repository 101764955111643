// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getIsAssignedToProjectType = withFragment(
  (billingLibrary) => {
    return billingLibrary.projectTypes.length > 0;
  },
  gql`
    fragment BillingLibrary_getIsAssignedToProjectType on BillingLibrary {
      id
      projectTypes {
        id
      }
    }
  `,
);

const getIsDefaultBillingLibrary = withFragment(
  (billingLibrary) => {
    return (
      String(billingLibrary.organization.defaultBillingLibraryId) === String(billingLibrary.id)
    );
  },
  gql`
    fragment BillingLibrary_getIsDefaultBillingLibrary on BillingLibrary {
      id
      organization {
        id
        defaultBillingLibraryId
      }
    }
  `,
);

const BillingLibrary = {
  getIsAssignedToProjectType,
  getIsDefaultBillingLibrary,
};

export default BillingLibrary;
