// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (project) => ({
    projectId: project.id,
  }),
  gql`
    fragment ManuallyCompleteProjectConfirmationForm_edit on Project {
      id
    }
  `,
);

const toForm = ({projectId}) => ({projectId});

const toMutation = ({projectId}) => ({projectId});

const ManuallyCompleteProjectConfirmationForm = {
  edit,
  toForm,
  toMutation,
};

export default ManuallyCompleteProjectConfirmationForm;
