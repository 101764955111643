// Supermove
import {gql} from '@supermove/graphql';
import {Currency, withFragment} from '@supermove/utils';

const edit = withFragment(
  (commercialEquipmentType) => ({
    commercialEquipmentTypeId: commercialEquipmentType.id,
    organizationId: commercialEquipmentType.organizationId,
    name: commercialEquipmentType.name,
    price: commercialEquipmentType.price,
  }),
  gql`
    fragment CommercialEquipmentTypeForm_edit on CommercialEquipmentType {
      id
      organizationId
      name
      price
    }
  `,
);

const _new = ({organizationId}) => ({
  commercialEquipmentTypeId: undefined,
  organizationId,
  name: '',
  price: 0,
});

const toForm = ({commercialEquipmentTypeId, organizationId, name, price}) => ({
  commercialEquipmentTypeId,
  organizationId,
  name,
  price: Currency.toForm(price),
});

const toMutation = ({commercialEquipmentTypeId, organizationId, name, price}) => ({
  commercialEquipmentTypeId,
  organizationId,
  name,
  price: Currency.toMutation(price),
});

const CommercialEquipmentTypeForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default CommercialEquipmentTypeForm;
