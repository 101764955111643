// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import TruckForm from '@shared/modules/Dispatch/forms/TruckForm';

const useUpdateTruckMutation = ({truckForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: TruckForm.toForm(truckForm),
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateTruckMutation.mutation,
    variables: TruckForm.toMutation(form.values),
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateTruckMutation.mutation = gql`
  mutation UpdateTruckMutation(    
    $truckId: Int!,
    $name: String!,
    $size: String!,
    $notes: String,
    $status: String!,
  ) {
    response: updateTruck(
      truckId: $truckId
      name: $name,
      size: $size,
      notes: $notes,
      status: $status,
    ) {
      ${gql.errors}
      truck {
        id
      }
    }
  }
`;

export default useUpdateTruckMutation;
