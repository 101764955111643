// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled, Tabs} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useResponsive, useTabs, Responsive} from '@supermove/hooks';
import {Job, ProjectModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import IconButton from '@shared/design/components/IconButton';
import LargeModal from '@shared/design/components/Modal/LargeModal';
import FullPageSheet from '@shared/design/components/Sheet/FullPageSheet';
import {JobTipsFormTypeForm} from '@shared/modules/Billing/forms/JobTipsForm';
import ProjectTipsForm, {
  ProjectTipsFormType,
  UserWithTipType,
} from '@shared/modules/Billing/forms/ProjectTipsForm';
import useUpdateProjectJobUserTipsMutation from '@shared/modules/Billing/hooks/useUpdateProjectJobUserTipsMutation';
import {JobUserFormTypeForm} from '@shared/modules/Dispatch/forms/JobUserForm';
import Line from 'modules/App/components/Line';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import EditTipPayoutsJobUserInputs from 'modules/Project/Billing/components/EditTipPayoutsJobUserInputs';
import EditTipPayoutsJobsHeader from 'modules/Project/Billing/components/EditTipPayoutsJobsHeader';

const ICON_WIDTH = 24 as const;
const HORIZONTAL_PADDING_DESKTOP = 24 as const;
const HORIZONTAL_PADDING_MOBILE = 16 as const;

const ContentContainer = Styled.View`
  ${({responsive}: {responsive: Responsive}) => (responsive.desktop ? 'flex-shrink: 1' : 'flex: 1')};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const LeftColumn = Styled.View`
  ${({responsive}: {responsive: Responsive}) => (responsive.desktop ? 'width: 156px;' : '')};
  margin-left: ${({responsive}: {responsive: Responsive}) => (responsive.desktop ? HORIZONTAL_PADDING_DESKTOP : HORIZONTAL_PADDING_MOBILE)}px;
`;

const CenterColumn = Styled.View`
  flex: 1;
`;

const RightColumn = Styled.View`
  ${({responsive}: {responsive: Responsive}) => (responsive.desktop ? `width: ${ICON_WIDTH}px;` : '')};
  margin-right: ${({responsive}: {responsive: Responsive}) => (responsive.desktop ? HORIZONTAL_PADDING_DESKTOP : HORIZONTAL_PADDING_MOBILE)}px;
`;

const JobColumnContainer = Styled.View`
  flex: 1;
  ${({responsive}: {responsive: Responsive}) => (responsive.desktop ? `max-width: 25%;` : '')};
`;

const MobileJobAmountContainer = Styled.View`
  padding-horizontal: 16px;
`;

const Cell = Styled.View`
  height: ${({responsive}: {responsive: Responsive}) => (responsive.desktop ? '42px' : undefined)};
`;

const BodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const RemainingText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${({color}: {color: string}) => color};
`;

const NameText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const JobTipAmountText = Styled.Text`
  ${Typography.Responsive.Label}
  align-self: flex-end;
`;

const FooterActionsContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  background-color: ${colors.white};
  padding-horizontal: ${({responsive}: {responsive: Responsive}) => (responsive.desktop ? HORIZONTAL_PADDING_DESKTOP : HORIZONTAL_PADDING_MOBILE)}px;
  padding-vertical: 16px;
`;

const SkeletonLoaderContainer = Styled.View`
  padding: 24px;
`;

const LoadingComponent = () => {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} />
      <Space height={16} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} />
      <Space height={16} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} />
    </SkeletonLoaderContainer>
  );
};

const MoverNamesColumn = ({form, field}: {form: any; field: string}) => {
  const movers = _.get(form.values, `${field}.movers`);
  const responsive = useResponsive();
  const allJobUserForms = _.get(form.values, `${field}.jobTipsForms`).flatMap(
    (jobTipsForm: JobTipsFormTypeForm) => jobTipsForm.jobUserForms,
  );

  return (
    <React.Fragment>
      {movers.map((mover: UserWithTipType) => {
        const jobUserTipTotal = _.sumBy(allJobUserForms, (jobUserForm: JobUserFormTypeForm) => {
          return _.toString(jobUserForm.userId) === mover.id
            ? Currency.convertToCents(jobUserForm.tipAmount)
            : 0;
        });
        return (
          <React.Fragment key={mover.id}>
            <Cell responsive={responsive}>
              <NameText>{mover.fullName}</NameText>
              <Space height={2} />
              <BodyText>{Currency.display(jobUserTipTotal)}</BodyText>
            </Cell>
            <Space height={16} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const ProjectJobUserTips = ({
  form,
  field,
  tabs,
  groupedJobTipsForms,
}: {
  form: any;
  field: string;
  tabs: any;
  groupedJobTipsForms: JobTipsFormTypeForm[][];
}) => {
  const responsive = useResponsive();
  const groupSize = groupedJobTipsForms[0].length;

  return (
    <React.Fragment>
      {groupedJobTipsForms.map((jobTipsForms: JobTipsFormTypeForm[], index: number) => {
        const isCurrentGroup = index === tabs.selectedIndex;

        if (!isCurrentGroup) {
          return null;
        }

        return (
          <React.Fragment key={index}>
            {!responsive.desktop && (
              <MobileJobAmountContainer>
                <Line />
                <Space height={4} />
                <JobTipAmountText responsive={responsive}>
                  {jobTipsForms[0].billTipForm.amount}
                </JobTipAmountText>
                <Space height={16} />
              </MobileJobAmountContainer>
            )}
            <ScrollView>
              <Row>
                {responsive.desktop && (
                  <LeftColumn responsive={responsive}>
                    <MoverNamesColumn form={form} field={field} />
                  </LeftColumn>
                )}
                <Space width={16} />
                <CenterColumn>
                  <EditTipPayoutsJobUserInputs
                    form={form}
                    field={field}
                    jobTipsForms={jobTipsForms}
                    ColumnComponent={JobColumnContainer}
                    CellComponent={Cell}
                    jobTipsFormIndexOffset={index * groupSize}
                  />
                </CenterColumn>
                {responsive.desktop && <RightColumn responsive={responsive} />}
              </Row>
            </ScrollView>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const TipPayoutsFooter = ({
  form,
  field,
  project,
  handleClose,
  handleSubmit,
  submitting,
}: {
  form: any;
  field: string;
  project: ProjectModel;
  handleClose: () => void;
  handleSubmit: () => void;
  submitting: boolean;
}) => {
  const {totalTip} = project.currentPrimaryBill;
  const remainingAmount = totalTip - _.get(form.values, `${field}.totalTipAmount`);
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <Line />
      <Space height={16} />
      <Row>
        <LeftColumn responsive={responsive}>
          <NameText responsive={responsive}>Total</NameText>
        </LeftColumn>
        <Space width={16} />
        <CenterColumn style={{alignItems: 'flex-end'}}>
          <NameText responsive={responsive}>{Currency.display(totalTip)}</NameText>
        </CenterColumn>
        <Space width={16} />
        {responsive.desktop && <RightColumn responsive={responsive} />}
      </Row>
      <Row>
        <CenterColumn style={{alignItems: 'flex-end'}}>
          <RemainingText
            color={remainingAmount ? colors.red.warning : colors.gray.tertiary}
            responsive={responsive}
          >
            {`${Currency.display(Math.abs(remainingAmount))} ${remainingAmount < 0 ? 'over' : 'remaining'}`}
          </RemainingText>
        </CenterColumn>
        <Space width={16} />
        {responsive.desktop && <RightColumn responsive={responsive} />}
      </Row>
      <Space height={16} />
      {remainingAmount !== 0 && (
        <React.Fragment>
          <Row
            style={
              responsive.desktop
                ? {
                    paddingLeft: HORIZONTAL_PADDING_DESKTOP,
                    paddingRight: HORIZONTAL_PADDING_DESKTOP + ICON_WIDTH + 16, // 16 for the space between the columns
                  }
                : {paddingHorizontal: HORIZONTAL_PADDING_MOBILE}
            }
          >
            <ErrorCallout
              text={`The total amount for movers doesn't match the total tip amount.`}
            />
          </Row>
          <Space height={16} />
        </React.Fragment>
      )}
      <Line />
      <FooterActionsContainer responsive={responsive}>
        {responsive.desktop && (
          <React.Fragment>
            <QuaternaryButton
              text={'Cancel'}
              onPress={handleClose}
              style={{width: 104}}
              textColor={colors.blue.interactive}
            />
            <Space width={16} />
          </React.Fragment>
        )}
        <Button
          text={'Save'}
          onPress={() => {
            // Use a setImmediate here to ensure that the job bill tip has been
            // updated by the onBlur event of the last modified input.
            setImmediate(handleSubmit);
          }}
          isSubmitting={submitting}
          iconLeft={IconButton.SOURCE.Check}
          isDisabled={!!remainingAmount}
          {...(responsive.desktop
            ? {
                style: {width: 104},
              }
            : {
                style: {flex: 1},
                isWidthOfContainer: true,
              })}
          isResponsive
        />
      </FooterActionsContainer>
    </React.Fragment>
  );
};

interface EditTipPayoutsContentProps {
  project: any;
  handleClose: () => void;
  refetch: () => void;
}

const EditTipPayoutsContent = ({project, handleClose, refetch}: EditTipPayoutsContentProps) => {
  const {params} = useNavigationDOM();
  const responsive = useResponsive();
  const projectTipsForm: ProjectTipsFormType = ProjectTipsForm.edit(project, {
    isDynamicJobTip: true,
  });
  const {form, handleSubmit, submitting} = useUpdateProjectJobUserTipsMutation({
    projectTipsForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });

  const field = 'projectTipsForm';
  const jobTipsForms: JobTipsFormTypeForm[] = _.get(form.values, `${field}.jobTipsForms`);
  const groupedJobTipsForms: JobTipsFormTypeForm[][] = _.chunk(
    jobTipsForms,
    responsive.desktop ? 5 : 1,
  );
  const hasMultiplePages = groupedJobTipsForms.length > 1;
  const initialIndex = groupedJobTipsForms.findIndex((jobTipsForms: JobTipsFormTypeForm[]) => {
    return jobTipsForms.some((jobTipsForm: JobTipsFormTypeForm) => {
      return jobTipsForm.jobUuid === params.jobUuid;
    });
  });
  const tabs = useTabs({initialIndex, numberOfTabs: groupedJobTipsForms.length});

  return (
    <ContentContainer responsive={responsive}>
      <Space height={responsive.desktop ? 16 : 8} />
      <Row>
        <LeftColumn responsive={responsive} style={{alignItems: 'flex-end'}}>
          {hasMultiplePages && (
            <IconButton
              source={IconButton.SOURCE.AngleLeft}
              onPress={tabs.handlePrev}
              isDisabled={!tabs.hasPrev}
            />
          )}
        </LeftColumn>
        <Space width={16} />
        <CenterColumn>
          <Tabs.SlideViewer tabs={tabs}>
            {groupedJobTipsForms.map((jobTipsForms: JobTipsFormTypeForm[], index: number) => {
              return (
                <React.Fragment key={index}>
                  <Tabs.Slide>
                    <EditTipPayoutsJobsHeader
                      jobTipsForms={jobTipsForms}
                      ColumnComponent={JobColumnContainer}
                    />
                  </Tabs.Slide>
                  <Space width={16} />
                </React.Fragment>
              );
            })}
          </Tabs.SlideViewer>
        </CenterColumn>
        <RightColumn responsive={responsive}>
          {hasMultiplePages && (
            <IconButton
              source={IconButton.SOURCE.AngleRight}
              onPress={tabs.handleNext}
              isDisabled={!tabs.hasNext}
            />
          )}
        </RightColumn>
      </Row>
      <Space height={responsive.desktop ? 16 : 8} />
      <ProjectJobUserTips
        form={form}
        field={field}
        tabs={tabs}
        groupedJobTipsForms={groupedJobTipsForms}
      />
      <TipPayoutsFooter
        form={form}
        field={field}
        project={project}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
    </ContentContainer>
  );
};

interface EditTipPayoutsModalProps {
  projectUuid: string;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}

const EditTipPayoutsModal = ({
  projectUuid,
  isOpen,
  handleClose,
  refetch,
}: EditTipPayoutsModalProps) => {
  const responsive = useResponsive();
  const {data, loading} = useQuery(EditTipPayoutsModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {projectUuid},
    skip: !isOpen,
  });

  if (responsive.desktop) {
    return (
      <LargeModal
        isOpen={isOpen}
        title={'Edit Tip Payouts'}
        isScrollable={false}
        style={{flexShrink: 1}}
        bodyStyle={{flex: 1, paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}
      >
        <Loading loading={!data || loading} as={LoadingComponent}>
          {() => (
            <EditTipPayoutsContent
              project={data.project}
              handleClose={handleClose}
              refetch={refetch}
            />
          )}
        </Loading>
      </LargeModal>
    );
  }

  return (
    <FullPageSheet headerText={'Edit Tip Payouts'} isOpen={isOpen} handleClose={handleClose}>
      <Loading loading={!data || loading} as={LoadingComponent}>
        {() => (
          <EditTipPayoutsContent
            project={data.project}
            handleClose={handleClose}
            refetch={refetch}
          />
        )}
      </Loading>
    </FullPageSheet>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditTipPayoutsModal.query = gql`
  ${Job.getJobDisplayDate.fragment}
  ${ProjectTipsForm.edit.fragment}

  query EditTipPayoutsModal($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      currentPrimaryBill {
        id
        totalTip
      }
      ...ProjectTipsForm_edit
    }
  }
`;

export default EditTipPayoutsModal;
