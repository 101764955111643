// Libraries
import _ from 'lodash';

type Value =
  | 'CURRENCY'
  | 'DISTANCE'
  | 'FLOAT'
  | 'INTEGER'
  | 'PERCENT'
  | 'WEIGHT'
  | 'STRING'
  | 'DATE'
  | 'TIME'
  | 'DROPDOWN_STRING'
  | 'DROPDOWN_CURRENCY'
  | 'DROPDOWN_DISTANCE'
  | 'DROPDOWN_FLOAT'
  | 'DROPDOWN_INTEGER'
  | 'DROPDOWN_PERCENT'
  | 'DROPDOWN_WEIGHT'
  | 'REFERRAL_SOURCE'
  | 'CITY_VALUE_CURRENCY'
  | 'VALUE_TABLE_CURRENCY'
  | 'PER_POSITION_FLOAT';

type LabelMap = {
  [Key in Value]: string;
};

const CURRENCY = 'CURRENCY';
const DISTANCE = 'DISTANCE';
const FLOAT = 'FLOAT';
const INTEGER = 'INTEGER';
const PERCENT = 'PERCENT';
const WEIGHT = 'WEIGHT';
const STRING = 'STRING';
const DATE = 'DATE';
const TIME = 'TIME';
const DROPDOWN_STRING = 'DROPDOWN_STRING';
const DROPDOWN_CURRENCY = 'DROPDOWN_CURRENCY';
const DROPDOWN_DISTANCE = 'DROPDOWN_DISTANCE';
const DROPDOWN_FLOAT = 'DROPDOWN_FLOAT';
const DROPDOWN_INTEGER = 'DROPDOWN_INTEGER';
const DROPDOWN_PERCENT = 'DROPDOWN_PERCENT';
const DROPDOWN_WEIGHT = 'DROPDOWN_WEIGHT';
const REFERRAL_SOURCE = 'REFERRAL_SOURCE';

const CITY_VALUE_CURRENCY = 'CITY_VALUE_CURRENCY';
const VALUE_TABLE_CURRENCY = 'VALUE_TABLE_CURRENCY';

// Dynamic Variables
const PER_POSITION_FLOAT = 'PER_POSITION_FLOAT';
const PER_POSITION_STRING = 'PER_POSITION_STRING';

const VALUES: Value[] = [CURRENCY, DATE, DISTANCE, FLOAT, INTEGER, PERCENT, STRING, TIME, WEIGHT];

const TABLE_VALUES: Value[] = [
  DROPDOWN_CURRENCY,
  DROPDOWN_DISTANCE,
  DROPDOWN_FLOAT,
  DROPDOWN_INTEGER,
  DROPDOWN_PERCENT,
  STRING,
  DROPDOWN_WEIGHT,
];

const VALUE_LABELS: LabelMap = {
  CURRENCY: 'Currency',
  DISTANCE: 'Distance',
  FLOAT: 'Float',
  INTEGER: 'Integer',
  PERCENT: 'Percent',
  WEIGHT: 'Weight',
  STRING: 'String',
  DROPDOWN_STRING: 'Dropdown String',
  DROPDOWN_CURRENCY: 'Currency',
  DROPDOWN_DISTANCE: 'Distance',
  DROPDOWN_FLOAT: 'Float',
  DROPDOWN_INTEGER: 'Integer',
  DROPDOWN_PERCENT: 'Percent',
  DROPDOWN_WEIGHT: 'Weight',
  REFERRAL_SOURCE: 'Referral Source',
  CITY_VALUE_CURRENCY: 'City Value Currency',
  VALUE_TABLE_CURRENCY: 'Value Table Currency',
  PER_POSITION_FLOAT: 'Per Position Float',
  DATE: 'Date',
  TIME: 'Time',
};

const DYNAMIC_VARIABLE_FORMATS = {
  PER_POSITION_FLOAT,
  PER_POSITION_STRING,
};

const getDisplayName = (variableFormat: Value) => {
  return VALUE_LABELS[variableFormat];
};

const DROPDOWN_OPTIONS = VALUES.map((value) => ({value, label: VALUE_LABELS[value]}));

export default {
  CURRENCY,
  DISTANCE,
  FLOAT,
  INTEGER,
  PERCENT,
  WEIGHT,
  STRING,
  DATE,
  TIME,
  DROPDOWN_STRING,
  DROPDOWN_CURRENCY,
  DROPDOWN_DISTANCE,
  DROPDOWN_FLOAT,
  DROPDOWN_INTEGER,
  DROPDOWN_PERCENT,
  DROPDOWN_WEIGHT,
  REFERRAL_SOURCE,

  CITY_VALUE_CURRENCY,
  VALUE_TABLE_CURRENCY,

  PER_POSITION_FLOAT,
  PER_POSITION_STRING,
  DYNAMIC_VARIABLE_FORMATS,

  VALUES,
  TABLE_VALUES,
  DROPDOWN_OPTIONS,
  getDropdownOptions: () => DROPDOWN_OPTIONS,
  TABLE_DROPDOWN_OPTIONS: TABLE_VALUES.map((tableValue) => ({
    value: tableValue,
    label: VALUE_LABELS[tableValue],
  })),
  getStringVariableFormats: () => [STRING, DROPDOWN_STRING, DATE, TIME],
  getDisplayName,
};
