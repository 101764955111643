/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import TextTooltip from 'modules/App/components/TextTooltip';

const Text = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const IconContainer = Styled.View`
  width: 12px;
  align-items: center;
`;

const getProjectTotalRevenue = ({project}) => {
  const {totalRevenue} = project;
  if (totalRevenue === 0) {
    return 'TBD';
  }
  return Currency.format({value: totalRevenue});
};

const ProjectGrandTotal = ({project}) => {
  return (
    <Row>
      <TextTooltip text={'Project Total'}>
        <IconContainer>
          <Icon
            color={colors.gray.secondary}
            size={12}
            source={Icon.DollarSign}
            style={{marginTop: 1}}
          />
        </IconContainer>
      </TextTooltip>
      <Space width={4} />
      <Text color={colors.gray.secondary}>{getProjectTotalRevenue({project})}</Text>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectGrandTotal.fragment = gql`
  fragment ProjectGrandTotal on Project {
    id
    totalRevenue
  }
`;

export default ProjectGrandTotal;
