// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, TimeInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Callout from '@shared/design/components/Callout';
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import FieldInput from '@shared/design/components/Field/FieldInput';
import LargeModal from '@shared/design/components/Modal/LargeModal';
import TimesheetBlockKindSelector from '@shared/modules/Timesheet/components/TimesheetBlockKindSelector';
import TimesheetPayrollEntrySelector from '@shared/modules/Timesheet/components/TimesheetPayrollEntrySelector';
import CreateTimesheetPayrollBlocksForm from '@shared/modules/Timesheet/forms/CreateTimesheetPayrollBlocksForm';
import TimesheetBlockForm from '@shared/modules/Timesheet/forms/TimesheetBlockForm';
import useCreateTimesheetPayrollBlocksMutation from '@shared/modules/Timesheet/hooks/useCreateTimesheetPayrollBlocksMutation';

const Row = Styled.View`
  flex-direction: row;
`;

const ContentContainer = Styled.View`
  padding-horizontal: 24px;
`;

const SectionLabel = Styled.Text`
  ${Typography.Responsive.Label}
`;

const handleResetErrors = ({form}) => {
  if (_.some(form.errors)) {
    form.setErrors({});
  }
};

const handleSetTimesheetBlockDate = ({form, field, job, startTime}) => {
  // If the start time is before 6am, we assume that the user is
  // inputting time for the next day.
  const timesheetsStartDate = _.get(
    job,
    'timesheetBillableEntries.0.timesheetBlocksDateRange.start',
  );
  const formDate = _.get(form.values, `${field}.date`);
  const isValidTime = TimesheetBlockForm.getIsValidTime({time: startTime});
  const dayStartTime = Datetime.fromTime('6:00 AM', Datetime.FORM_TIME);
  const inputTime = Datetime.fromTime(startTime, Datetime.FORM_TIME);

  if (timesheetsStartDate === formDate && isValidTime && inputTime.isBefore(dayStartTime)) {
    const nextDay = Datetime.fromDate(timesheetsStartDate).add(1, 'days');
    const nextDayString = Datetime.toDate(nextDay);
    form.setFieldValue(`${field}.date`, nextDayString);
  } else if (timesheetsStartDate !== formDate) {
    form.setFieldValue(`${field}.date`, timesheetsStartDate);
  }
};

const TimeInputs = ({form, field, job}) => {
  return (
    <Row>
      <FieldInput
        {...form}
        component={TimeInput}
        name={`${field}.rangeFrom`}
        label={'Start Time'}
        isResponsive
        isRequired
        input={{
          component: FieldInput.TextInput,
          placeholder: 'Enter start time',
          setFieldValue: form.setFieldValue,
          onChangeTime: (startTime) => {
            handleResetErrors({form});
            handleSetTimesheetBlockDate({form, field, job, startTime});
          },
        }}
        style={{flex: 1}}
      />
      <Space width={16} />
      <FieldInput
        {...form}
        component={TimeInput}
        name={`${field}.rangeTo`}
        label={'End Time'}
        isResponsive
        isRequired
        input={{
          component: FieldInput.TextInput,
          placeholder: 'Enter end time',
          setFieldValue: form.setFieldValue,
          onChangeTime: () => handleResetErrors({form}),
        }}
        style={{flex: 1}}
      />
    </Row>
  );
};

const AddTimesheetPayrollBlockModalContent = ({form, job, responsive}) => {
  const field = 'createTimesheetPayrollBlocksForm';
  const timesheetBlockField = `${field}.createTimesheetBlockForms.0`;
  const timesheetBlockForm = _.get(form.values, timesheetBlockField);
  const {rangeFrom, rangeTo} = timesheetBlockForm;
  const isValidTimes =
    TimesheetBlockForm.getIsValidTime({time: rangeFrom}) &&
    TimesheetBlockForm.getIsValidTime({time: rangeTo});
  const isSameTimes = rangeFrom === rangeTo;

  return (
    <React.Fragment>
      <Space height={24} />
      <ContentContainer>
        <TimesheetBlockKindSelector form={form} field={timesheetBlockField} job={job} />
        <Space height={24} />
        <TimeInputs form={form} field={timesheetBlockField} job={job} />
        <Space height={24} />
        <SectionLabel responsive={responsive}>Crew Members</SectionLabel>
      </ContentContainer>
      <Space height={8} />
      {!isValidTimes || isSameTimes ? (
        <ContentContainer>
          {!isValidTimes ? (
            <Callout
              text={
                'Enter time range first. Crew members that are eligible for this time range will appear below.'
              }
            />
          ) : (
            <WarningCallout text={'Start and end times cannot be the same.'} />
          )}
        </ContentContainer>
      ) : (
        <TimesheetPayrollEntrySelector form={form} field={field} job={job} />
      )}
      <Space height={24} />
    </React.Fragment>
  );
};

const AddTimesheetPayrollBlockModal = ({isOpen, handleClose, job, onUpdate}) => {
  const responsive = useResponsive();
  const createTimesheetPayrollBlocksForm = CreateTimesheetPayrollBlocksForm.newForJob({job});
  const {form, handleSubmit, submitting} = useCreateTimesheetPayrollBlocksMutation({
    createTimesheetPayrollBlocksForm,
    onSuccess: () => {
      handleClose();
      onUpdate();
    },
    onError: (error) => console.log({error}),
  });

  return (
    <LargeModal
      isOpen={isOpen}
      title={'Add Time'}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
      primaryActionText={'Save'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
      screenContainerStyle={{paddingHorizontal: responsive.mobile ? 0 : 96}}
      bodyStyle={{padding: 0}}
      isScrollable
      isResponsive
    >
      <AddTimesheetPayrollBlockModalContent form={form} job={job} responsive={responsive} />
    </LargeModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddTimesheetPayrollBlockModal.fragment = gql`
  ${CreateTimesheetPayrollBlocksForm.newForJob.fragment}
  ${TimesheetPayrollEntrySelector.fragment}
  ${TimesheetBlockKindSelector.fragment}

  fragment AddTimesheetPayrollBlockModal on Job {
    id
    timesheetBillableEntries {
      id
      timesheetBlocksDateRange {
        start
      }
    }
    ...CreateTimesheetPayrollBlocksForm_newForJob
    ...TimesheetPayrollEntrySelector
    ...TimesheetBlockKindSelector
  }
`;

export default AddTimesheetPayrollBlockModal;
