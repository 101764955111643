// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ClientForm from '@shared/modules/Client/forms/ClientForm';

const useUpdateClientMutation = ({clientForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      clientForm: ClientForm.toForm(clientForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateClientMutation.mutation,
    variables: {
      clientForm: ClientForm.toMutation(form.values.clientForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateClientMutation.mutation = gql`
  ${ClientForm.edit.fragment}
  mutation useUpdateClientMutation($clientForm: ClientForm!) {
    response: updateClient(clientForm: $clientForm) {
      ${gql.errors}
      client {
        id
        ...ClientForm_edit
      }
    }
  }
`;

export default useUpdateClientMutation;
