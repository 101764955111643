/**
 * TODO(herrick): Replace this file with ProjectJobInfoBlock to consolidate
 * logic once create/update project have been migrated.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DateInput, Icon, Space, Styled, TimeInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {fontWeight, colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import JobForm from '@shared/modules/Job/forms/JobForm';
import ResponsiveTextInput from 'modules/App/components/ResponsiveTextInput';
import TextTooltip from 'modules/App/components/TextTooltip';
import JobTypeDropdown from 'modules/Job/components/JobTypeDropdown';

const Section = Styled.View`
  z-index: ${(props) => 100 - props.index}px;
`;

const Title = Styled.H4`
  margin-bottom: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Row = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  align-items: flex-start;
  z-index: ${({index = 0}) => 100 - index};
`;

const RowSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 10 : 15)}px;
`;

const RowField = Styled.View`
`;

const RowFieldLabel = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const RowFieldFields = Styled.View`
  flex-direction: row;
`;

const RowFieldSpace = Styled.View`
  margin-left: 10px;
`;

const FieldSpace = Styled.View`
  margin-top: ${(props) => (props.mobile ? 10 : 0)}px;
  margin-left: ${(props) => (props.mobile ? 0 : 10)}px;
`;

const TextInput = Styled.TextInput`
  ${Typography.Body3}
`;

const DateRangeToggleButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const DateRangeToggleText = Styled.Text`
  ${Typography.Label2}
  color: ${({color}) => color};
`;

const IconContainer = Styled.View`
  width: 16px;
`;

const getFieldName = ({field, name}) => {
  return field ? `${field}.${name}` : name;
};

const getFieldValue = ({form, field, name}) => {
  return _.get(form.values, `${field}.${name}`);
};

const handleToggleDateRange = ({form, field, wasEstimatedRange}) => {
  // When toggling the date, we reset the current values that we're toggling away
  // from, but the date value and the startDate value get passed to each other.
  if (wasEstimatedRange) {
    form.setFieldValue(`${field}.isEstimatedRange`, false);
    form.setFieldValue(`${field}.date`, _.get(form.values, `${field}.startDate`));
    form.setFieldValue(`${field}.startDate`, '');
    form.setFieldValue(`${field}.endDate`, '');
  } else {
    form.setFieldValue(`${field}.isEstimatedRange`, true);
    form.setFieldValue(`${field}.startDate`, _.get(form.values, `${field}.date`));
    form.setFieldValue(`${field}.date`, '');
  }
};

const DateRangeToggle = ({form, field, isEstimatedRange, disabled}) => {
  const color = disabled ? colors.gray.tertiary : colors.blue.interactive;

  return (
    <DateRangeToggleButton
      onPress={() => handleToggleDateRange({form, field, wasEstimatedRange: isEstimatedRange})}
      disabled={disabled}
    >
      <Icon source={isEstimatedRange ? Icon.Trash : Icon.Plus} size={10} color={color} />
      <Space width={5} />
      <DateRangeToggleText color={color}>End Date</DateRangeToggleText>
    </DateRangeToggleButton>
  );
};

const SingleDateInput = ({disabled, field, form, label, required, organization}) => {
  const {isEnabledCreateProjectDispatchLocks} = organization.features;
  const dateFieldName = getFieldName({field, name: 'date'});
  const date = _.get(form.values, dateFieldName);
  const projectTypeId = _.get(form.values, 'projectForm.projectTypeId');
  const projectType = organization.moveProjectTypes.find(
    (projectType) => projectType.id === _.toString(projectTypeId),
  );
  const jobTypeId = _.get(form.values, `${field}.jobTypeId`);
  const jobType = organization.jobTypes.find((jobType) => jobType.id === _.toString(jobTypeId));
  const hasDispatchLocks = jobType && !['ESTIMATE', 'REQUEST'].includes(jobType.kind);
  const excludeDates =
    projectType && isEnabledCreateProjectDispatchLocks && hasDispatchLocks
      ? projectType.futureSalesLockedProjectTypeDays.map(
          (projectTypeDay) => projectTypeDay.day.date,
        )
      : [];

  const isDateInputDisabled = isEnabledCreateProjectDispatchLocks && !projectTypeId;

  return (
    // FieldInput is not memoized because the DateInput is listening for changes to excludeDates
    // excludeDates is being set when ProjectTypeDropdown is changed
    <FieldInput
      {...form}
      index={0}
      component={DateInput}
      name={dateFieldName}
      label={
        <React.Fragment>
          {label}{' '}
          {excludeDates.length !== 0 && (
            <TextTooltip placement={'right'} text={`Disabled dates have been locked by dispatch.`}>
              <IconContainer>
                <Icon
                  color={colors.gray.tertiary}
                  size={Icon.Sizes.Medium}
                  source={Icon.InfoCircle}
                />
              </IconContainer>
            </TextTooltip>
          )}
        </React.Fragment>
      }
      action={
        <DateRangeToggle
          form={form}
          field={field}
          isEstimatedRange={false}
          disabled={isDateInputDisabled}
        />
      }
      input={{
        disabled: disabled || isDateInputDisabled,
        required: required && !date,
        placeholder: 'Enter date',
        setFieldValue: form.setFieldValue,
        style: {
          width: 250,
        },
        excludeDates,
      }}
      style={{width: 250}}
    />
  );
};

const DateRangeInputs = ({disabled, field, form, label, required}) => {
  const startDateFieldName = getFieldName({field, name: 'startDate'});
  const startDate = _.get(form.values, startDateFieldName);
  const endDateFieldName = getFieldName({field, name: 'endDate'});
  const endDate = _.get(form.values, endDateFieldName);

  return (
    <RowFieldFields style={{alignItems: 'flex-start'}}>
      <FieldInput
        {...form}
        index={0}
        component={DateInput}
        name={startDateFieldName}
        label={label}
        input={{
          disabled,
          required: required && !startDate,
          placeholder: 'Start date',
          setFieldValue: form.setFieldValue,
          style: {
            width: 120,
          },
        }}
      />
      <Space width={10} />
      <FieldInput
        {...form}
        index={0}
        component={DateInput}
        name={getFieldName({field, name: 'endDate'})}
        action={
          <React.Fragment>
            <Space style={{flex: 1}} />
            <DateRangeToggle form={form} field={field} isEstimatedRange />
          </React.Fragment>
        }
        input={{
          disabled,
          required: required && !endDate,
          placeholder: 'End date',
          setFieldValue: (endDatefield, endDate) => {
            const isSameDay = Datetime.isSameDay(startDate, endDate);

            // If the dates are equal, we set it to be a scheduled job.
            if (isSameDay) {
              handleToggleDateRange({form, field, wasEstimatedRange: true});
            } else {
              // If a date is supplied and it's different from the startDate, we set it as normal.
              form.setFieldValue(endDatefield, endDate);
            }
          },
          style: {
            width: 120,
          },
        }}
      />
    </RowFieldFields>
  );
};

const DateField = ({disabled, field, form, label, required, organization}) => {
  const isEstimatedRange = _.get(form.values, `${field}.isEstimatedRange`);

  return (
    <Row index={0}>
      <RowFieldFields style={{alignItems: 'flex-end'}}>
        {isEstimatedRange ? (
          <DateRangeInputs
            disabled={disabled}
            field={field}
            form={form}
            label={label}
            required={required}
          />
        ) : (
          <SingleDateInput
            disabled={disabled}
            field={field}
            form={form}
            label={label}
            required={required}
            organization={organization}
          />
        )}
      </RowFieldFields>
    </Row>
  );
};

const JobIdentifierRow = ({disabled, index, field, form, responsive, organization}) => {
  if (!organization.features.isEnabledJobIdentifierField) {
    return null;
  }

  return (
    <React.Fragment>
      <Row index={index} {...responsive}>
        <FieldInput.Memoized
          {...form}
          index={0}
          name={getFieldName({field, name: 'identifier'})}
          label={'Job Identifier'}
          input={{
            disabled,
            required: !getFieldValue({form, field, name: 'identifier'}),
            placeholder: 'Enter job identifier',
          }}
          style={{
            width: responsive.mobile ? '100%' : 250,
          }}
        />
      </Row>
      <RowSpace {...responsive} />
    </React.Fragment>
  );
};

const StartTimeFields = ({disabled, field, form, organization}) => {
  return (
    <RowField>
      <RowFieldLabel>{organization.jobStartTimeField}</RowFieldLabel>
      <Space height={4} />
      <RowFieldFields>
        <FieldInput.Memoized
          {...form}
          index={1}
          component={TimeInput}
          name={getFieldName({field, name: 'startTime1'})}
          input={{
            disabled,
            required:
              organization.features.isEnabledJobStartTime1IsRequired &&
              !getFieldValue({form, field, name: 'startTime1'}),
            component: TextInput,
            placeholder: 'Start',
            setFieldValue: form.setFieldValue,
          }}
          style={{
            width: 120,
          }}
        />
        <RowFieldSpace />
        <FieldInput.Memoized
          {...form}
          index={2}
          component={TimeInput}
          name={getFieldName({field, name: 'startTime2'})}
          input={{
            disabled,
            component: TextInput,
            placeholder: 'End',
            setFieldValue: form.setFieldValue,
          }}
          style={{
            width: 120,
          }}
        />
      </RowFieldFields>
    </RowField>
  );
};

const EstimatedHoursRow = ({disabled, index, field, form, responsive, organization}) => {
  const {isEnabledBillingDefaultRecommendations} = organization.features;
  const {isMinEstimateHoursInputVisible, isMaxEstimateHoursInputVisible} =
    JobForm.getDispatchFieldVisibility(_.get(form.values, field), {
      isEnabledBillingDefaultRecommendations,
    });
  if (!isMinEstimateHoursInputVisible && !isMaxEstimateHoursInputVisible) {
    return null;
  }
  return (
    <Row index={index} {...responsive}>
      {isMinEstimateHoursInputVisible && (
        <FieldInput.Memoized
          {...form}
          index={1}
          name={getFieldName({field, name: 'estimateHours1'})}
          label={'Estimate Hours Min'}
          input={{
            disabled,
            placeholder: 'Enter estimate hours min',
            keyboardType: 'numeric',
          }}
          style={{
            width: 250,
          }}
        />
      )}
      {isMinEstimateHoursInputVisible && isMaxEstimateHoursInputVisible && (
        <FieldSpace {...responsive} />
      )}
      {isMaxEstimateHoursInputVisible && (
        <FieldInput.Memoized
          {...form}
          index={2}
          name={getFieldName({field, name: 'estimateHours2'})}
          label={'Estimate Hours Max'}
          input={{
            disabled,
            placeholder: 'Enter estimate hours max',
            keyboardType: 'numeric',
          }}
          style={{
            width: 250,
          }}
        />
      )}
    </Row>
  );
};

const JobDescriptionRow = ({disabled, index, field, form, responsive, organization}) => {
  return (
    <Row index={index} {...responsive}>
      <Section index={0}>
        <FieldInput.LabelText>Job Description (shown to customer)</FieldInput.LabelText>
        <Space height={4} />
        <ResponsiveTextInput.Memoized
          form={form}
          field={getFieldName({field, name: 'description'})}
          minHeight={100}
          input={{
            style: {
              width: responsive.mobile ? '100%' : 510,
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 9,
              paddingBottom: 9,
            },
            placeholder: 'Enter a description for the job. This will be shown to the customer.',
            disabled,
          }}
        />
      </Section>
    </Row>
  );
};

const JobTypeRow = ({
  disabled,
  index,
  field,
  form,
  responsive,
  organization,
  shouldResetJobDateOnJobTypeChange,
  jobFormField,
}) => {
  return (
    <React.Fragment>
      <Row data-test-id='form-job-type-dropdown-row' index={index} {...responsive}>
        <JobTypeDropdown
          organization={organization}
          projectTypeId={_.get(form.values, 'projectForm.projectTypeId')}
          field={field}
          form={form}
          shouldResetJobDateOnJobTypeChange={shouldResetJobDateOnJobTypeChange}
          jobFormField={jobFormField}
        />
        <FieldSpace {...responsive} />
        <FieldInput.Memoized
          {...form}
          index={1}
          name={getFieldName({field, name: 'name'})}
          label={'Job Name'}
          input={{
            disabled,
            placeholder: 'Enter job name',
          }}
          style={{
            width: responsive.mobile ? '100%' : 250,
          }}
        />
      </Row>
      <RowSpace {...responsive} />
    </React.Fragment>
  );
};

const EstimateSection = ({disabled, field, form, responsive, organization}) => {
  return (
    <React.Fragment>
      <Row index={2} {...responsive}>
        <DateField
          disabled={disabled}
          required={organization.features.isEnabledJobStartDateIsRequired}
          field={field}
          form={form}
          label={'Date'}
          organization={organization}
        />
        <FieldSpace {...responsive} />
        <StartTimeFields
          disabled={disabled}
          field={field}
          form={form}
          organization={organization}
        />
      </Row>
    </React.Fragment>
  );
};

const ResidentialMoveSection = ({disabled, field, form, responsive, organization}) => {
  return (
    <React.Fragment>
      <Row index={2} {...responsive}>
        <DateField
          disabled={disabled}
          required={organization.features.isEnabledJobStartDateIsRequired}
          field={field}
          form={form}
          label={'Date'}
          organization={organization}
        />
        <FieldSpace {...responsive} />
        <StartTimeFields
          disabled={disabled}
          field={field}
          form={form}
          organization={organization}
        />
      </Row>
      <RowSpace {...responsive} />
      <EstimatedHoursRow
        disabled={disabled}
        index={3}
        field={field}
        form={form}
        responsive={responsive}
        organization={organization}
      />
    </React.Fragment>
  );
};

const CommercialMoveSection = ({disabled, field, form, responsive, organization}) => {
  return (
    <React.Fragment>
      <Row index={2} {...responsive}>
        <DateField
          disabled={disabled}
          required={organization.features.isEnabledJobStartDateIsRequired}
          field={field}
          form={form}
          label={'Move Date'}
          organization={organization}
        />
        <FieldSpace {...responsive} />
        <RowField>
          <RowFieldLabel>{organization.jobStartTimeField}</RowFieldLabel>
          <Space height={4} />
          <RowFieldFields>
            <FieldInput.Memoized
              {...form}
              index={1}
              component={TimeInput}
              name={getFieldName({field, name: 'startTime1'})}
              input={{
                disabled,
                required:
                  organization.features.isEnabledJobStartTime1IsRequired &&
                  !getFieldValue({form, field, name: 'startTime1'}),
                component: TextInput,
                placeholder: 'Start',
                setFieldValue: form.setFieldValue,
              }}
              style={{
                width: 120,
              }}
            />
            <RowFieldSpace />
            <FieldInput.Memoized
              {...form}
              index={2}
              component={TimeInput}
              name={getFieldName({field, name: 'startTime2'})}
              input={{
                disabled,
                required: !getFieldValue({form, field, name: 'startTime2'}),
                component: TextInput,
                placeholder: 'End',
                setFieldValue: form.setFieldValue,
              }}
              style={{
                width: 120,
              }}
            />
          </RowFieldFields>
        </RowField>
      </Row>
      <RowSpace {...responsive} />
      <EstimatedHoursRow
        disabled={disabled}
        index={3}
        field={field}
        form={form}
        responsive={responsive}
        organization={organization}
      />
    </React.Fragment>
  );
};

const DefaultMoveSection = ({disabled, field, form, responsive, organization}) => {
  return (
    <React.Fragment>
      <Row index={2} {...responsive}>
        <DateField
          disabled={disabled}
          required={organization.features.isEnabledJobStartDateIsRequired}
          field={field}
          form={form}
          label={'Date'}
          organization={organization}
        />
        <FieldSpace {...responsive} />
        <StartTimeFields
          disabled={disabled}
          field={field}
          form={form}
          organization={organization}
        />
      </Row>
      <RowSpace {...responsive} />
      <EstimatedHoursRow
        disabled={disabled}
        index={3}
        field={field}
        form={form}
        responsive={responsive}
        organization={organization}
      />
    </React.Fragment>
  );
};

const JobScheduleCustomFields = ({disabled, field, form, responsive, organization}) => {
  const kind = _.get(form.values, getFieldName({field, name: 'kind'}));

  switch (kind) {
    case Job.KIND.COMMERCIAL:
      return (
        <CommercialMoveSection
          disabled={disabled}
          field={field}
          form={form}
          responsive={responsive}
          organization={organization}
        />
      );
    case Job.KIND.ESTIMATE:
      return (
        <EstimateSection
          disabled={disabled}
          field={field}
          form={form}
          responsive={responsive}
          organization={organization}
        />
      );
    case Job.KIND.RESIDENTIAL:
      return (
        <ResidentialMoveSection
          disabled={disabled}
          field={field}
          form={form}
          responsive={responsive}
          organization={organization}
        />
      );
    default:
      return (
        <DefaultMoveSection
          disabled={disabled}
          field={field}
          form={form}
          responsive={responsive}
          organization={organization}
        />
      );
  }
};

const JobScheduleFields = ({
  disabled,
  index,
  field,
  form,
  responsive,
  organization,
  shouldResetJobDateOnJobTypeChange,
  jobFormField,
}) => {
  return (
    <Section index={index}>
      <Title vars={responsive}>Job Information</Title>
      <JobIdentifierRow
        disabled={disabled}
        index={0}
        field={field}
        form={form}
        responsive={responsive}
        organization={organization}
      />
      <JobTypeRow
        disabled={disabled}
        index={1}
        field={field}
        form={form}
        responsive={responsive}
        organization={organization}
        shouldResetJobDateOnJobTypeChange={shouldResetJobDateOnJobTypeChange}
        jobFormField={jobFormField}
      />
      <JobScheduleCustomFields
        disabled={disabled}
        field={field}
        form={form}
        responsive={responsive}
        organization={organization}
      />
      <RowSpace {...responsive} />
      <JobDescriptionRow
        disabled={disabled}
        index={4}
        field={field}
        form={form}
        responsive={responsive}
        organization={organization}
      />
    </Section>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobScheduleFields.fragment = gql`
  ${JobTypeDropdown.fragment}

  fragment JobScheduleFields on Organization {
    id
    jobStartTimeField
    features {
      isEnabledJobIdentifierField: isEnabled(feature: "JOB_IDENTIFIER_FIELD")
      isEnabledJobStartDateIsRequired: isEnabled(feature: "JOB_START_DATE_IS_REQUIRED")
      isEnabledJobStartTime1IsRequired: isEnabled(feature: "JOB_START_TIME_1_IS_REQUIRED")
      isEnabledCreateProjectDispatchLocks: isEnabled(feature: "CREATE_PROJECT_DISPATCH_LOCKS")
      isEnabledBillingDefaultRecommendations: isEnabled(feature: "BILLING_DEFAULT_RECOMMENDATIONS")
    }
    moveProjectTypes: projectTypesForCategory(category: "MOVE") {
      id
      futureSalesLockedProjectTypeDays {
        id
        day {
          id
          date
        }
      }
    }
    jobTypes {
      id
      kind
    }
    ...JobTypeDropdown
  }
`;

export default JobScheduleFields;
