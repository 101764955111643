// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState, useQuery} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

const PLACEHOLDER_HEIGHT = 200;

const Wrapper = Styled.View`
  margin-top: 10px;
  z-index: ${(props) => 100 - props.index};
`;

const NoImageContainer = Styled.View`
  width: 100%;
  height: ${PLACEHOLDER_HEIGHT}px;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  background-color: ${colors.gray.background};
`;

const LoadingIndicator = Styled.Loading`
`;

const PlaceholderText = Styled.Text`
  ${Typography.MicroLabel}
  font-style: italic;
`;

const ImageContainer = Styled.View`
`;

const Image = Styled.Image`
`;

const POSITION = {
  LEFT: 'flex-start',
  CENTER: 'center',
  RIGHT: 'flex-end',
};

const ImagePlaceholder = () => {
  return (
    <NoImageContainer>
      <PlaceholderText>Image not added</PlaceholderText>
    </NoImageContainer>
  );
};

const ImageLoading = () => {
  return (
    <NoImageContainer>
      <LoadingIndicator size={'large'} color={colors.gray.secondary} />
    </NoImageContainer>
  );
};

const getBaseDimensions = ({input}) => {
  const {width, height} = input.metadata;
  if (input.width || input.height) {
    return {
      width: input.width ? _.toNumber(input.width) : (input.height / height) * width,
      height: input.height ? _.toNumber(input.height) : (input.width / width) * height,
    };
  }

  return {width, height};
};

const getDisplayDimensions = ({input, containerWidth}) => {
  const baseDimensions = getBaseDimensions({input});
  if (baseDimensions.width <= containerWidth) {
    return baseDimensions;
  }

  const aspectRatio = baseDimensions.width / baseDimensions.height;
  const width = containerWidth;
  const height = width / aspectRatio;
  return {width, height};
};

const getDocumentImageUrl = ({document, input}) => {
  return _.find(document.imageFiles, {id: input.metadata.fileId}).imageUrl;
};

const ImageDisplay = ({imageUrl, input, containerWidth}) => {
  return (
    <Image
      resizeMode={'contain'}
      source={{uri: imageUrl}}
      style={getDisplayDimensions({input, containerWidth})}
    />
  );
};

const DynamicImage = ({input, containerWidth}) => {
  const {data, loading} = useQuery(DocumentV2Image.query, {
    fetchPolicy: 'network-only',
    variables: {fileId: input.metadata.fileId},
  });

  if (loading || !data) {
    return <ImageLoading />;
  }

  return (
    <ImageDisplay imageUrl={data.file.imageUrl} input={input} containerWidth={containerWidth} />
  );
};

const ImageViewer = ({input, document}) => {
  const [containerWidth, setContainerWidth] = useState(0);
  return (
    <ImageContainer
      style={{alignItems: input.position}}
      onLayout={({nativeEvent}) => setContainerWidth(nativeEvent.layout.width)}
    >
      {!document ? (
        <DynamicImage input={input} containerWidth={containerWidth} />
      ) : (
        <ImageDisplay
          imageUrl={getDocumentImageUrl({document, input})}
          input={input}
          containerWidth={containerWidth}
        />
      )}
    </ImageContainer>
  );
};

const DocumentV2Image = ({index, input, document}) => {
  const {fileId} = input.metadata;

  return (
    <Wrapper index={index}>
      {!fileId ? <ImagePlaceholder /> : <ImageViewer input={input} document={document} />}
    </Wrapper>
  );
};

DocumentV2Image.POSITION = POSITION;

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentV2Image.fragment = gql`
  fragment DocumentV2Image on Document {
    id
    imageFiles {
      id
      imageUrl
    }
  }
`;

DocumentV2Image.query = gql`
  query DocumentV2Image($fileId: Int!) {
    ${gql.query}
    file(fileId: $fileId) {
      id
      imageUrl
    }
  }
`;

export default DocumentV2Image;
