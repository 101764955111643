// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import ProjectDateKind from '@shared/modules/Project/enums/ProjectDateKind';

// This projectDateSettings is an abstraction that makes it easy to plug these
// fields into our dropdown component, see how we use this in JobTypeModalFields.js
const makeProjectDateSettings = (jobTypeSettings) => {
  return ProjectDateKind.VALUES.reduce((projectDateSettings, projectDateKind) => {
    const jobTypeSettingsKey =
      ProjectDateKind.PROJECT_DATE_KIND_TO_JOB_TYPE_SETTINGS_MAP[projectDateKind];
    if (jobTypeSettings[jobTypeSettingsKey]) {
      return [...projectDateSettings, projectDateKind];
    }

    return projectDateSettings;
  }, []);
};

const _new = ({organizationId, projectTypeId}) => ({
  organizationId,
  projectTypeId,
  jobTypeId: null,
  name: '',
  kind: Job.KIND.MOVE,
  billTypeIds: [],
  setsPackDates: false,
  setsLoadDates: false,
  setsDeliveryDates: false,
});

const edit = withFragment(
  (jobType) => ({
    jobTypeId: jobType.id,
    name: jobType.name,
    kind: jobType.kind,
    index: jobType.index,
    billTypeIds: jobType.billTypes.map((billType) => {
      return _.parseInt(billType.id);
    }),
    organizationId: jobType.organization.id,
    projectTypeId: jobType.projectType.id,
    setsPackDates: jobType.setsPackDates,
    setsLoadDates: jobType.setsLoadDates,
    setsDeliveryDates: jobType.setsDeliveryDates,

    // Private
    projectDateSettings: makeProjectDateSettings(jobType),
  }),
  gql`
    fragment JobTypeForm_edit on JobType {
      id
      name
      kind
      index
      billTypes {
        id
      }
      setsPackDates
      setsLoadDates
      setsDeliveryDates
      organization {
        id
      }
      projectType {
        id
      }
    }
  `,
);

const toForm = ({
  jobTypeId,
  index,
  name,
  billTypeIds,
  organizationId,
  projectTypeId,
  kind,
  setsPackDates,
  setsLoadDates,
  setsDeliveryDates,

  // Private
  projectDateSettings,
}) => ({
  jobTypeId,
  index,
  name,
  billTypeIds,
  organizationId,
  projectTypeId,
  kind,
  setsPackDates,
  setsLoadDates,
  setsDeliveryDates,

  // Private
  projectDateSettings,
});

const toMutation = ({
  jobTypeId,
  index,
  name,
  billTypeIds,
  organizationId,
  projectTypeId,
  kind,
  setsPackDates,
  setsLoadDates,
  setsDeliveryDates,
}) => {
  return {
    jobTypeId,
    index,
    name,
    billTypeIds,
    organizationId,
    projectTypeId,
    kind,
    setsPackDates,
    setsLoadDates,
    setsDeliveryDates,
  };
};

const JobTypeForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default JobTypeForm;
