// Supermove
import {BillModifierForm, BillRuleForm, LineItemForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {Bill} from '@supermove/models';
import {Currency, withFragment} from '@supermove/utils';

// App
import BillItemUnit from '@shared/modules/Billing/enums/BillItemUnit';
import BillStage from '@shared/modules/Billing/enums/BillStage';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';

const getBillItemFormsFromBillItemTypesForBill = withFragment(
  (bill) => {
    return bill.project.projectType.billingLibrary.billItemTypes
      .filter((billItemType) => !billItemType.isParent)
      .map((billItemType) => BillItemForm.editFromBillItemType(billItemType, {billId: bill.id}));
  },
  gql`
    ${BillItemForm.editFromBillItemType.fragment}
    fragment BillForm_getBillItemFormsFromBillItemTypesForBill on Bill {
      id
      project {
        id
        projectType {
          id
          billingLibrary {
            id
            billItemTypes {
              id
              isParent
              ...BillItemForm_editFromBillItemType
            }
          }
        }
      }
    }
  `,
);

const getValidBillItemFormsPreSubtotal = (billForm) => {
  const hourlyRateBillItems = billForm.billItemFormsPreSubtotal.filter((billItemForm) => {
    return billItemForm.unit === BillItemUnit.HOUR;
  });
  const hasHourlyRate = hourlyRateBillItems.length > 0;

  if (!billForm.isEnabledMultipleHourlyRateBillingItems && hasHourlyRate) {
    return billForm.allBillItemFormsPreSubtotal.filter((billItemForm) => {
      return billItemForm.unit !== BillItemUnit.HOUR;
    });
  }

  return billForm.allBillItemFormsPreSubtotal;
};

const _new = withFragment(
  (project) => ({
    projectId: project.id,
    customerId: project.customerId,
    organizationId: project.organization.id,
    companyId: project.organization.company.id,
    jobId: null,
    title: '',
    description: '',
    isPrimary: false,
    billRuleForms: [],
    lineItemForms: [],
    billModifierForms: [],
    billItemFormsPreSubtotal: [],
    billItemFormsPostSubtotal: [],
    deletedBillItemForms: [],
    index: project.currentBills.filter((bill) => !bill.isPrimary).length,
    tip: 0,

    // Private
    allLineItemForms: [],
    allBillModifierForms: [],
    allBillRuleForms: [],
    allBillItemFormsPreSubtotal: [],
    allBillItemFormsPostSubtotal: [],
    isEnabledMultipleHourlyRateBillingItems:
      project.organization.features.isEnabledMultipleHourlyRateBillingItems,
  }),
  gql`
    fragment BillForm_new on Project {
      id
      customerId
      organization {
        id
        company {
          id
        }
        features {
          isEnabledMultipleHourlyRateBillingItems: isEnabled(
            feature: "MULTIPLE_HOURLY_RATE_BILLING_ITEMS"
          )
        }
      }
      currentBills {
        id
        isPrimary
      }
    }
  `,
);

const newFromBillType = withFragment(
  (project, {billType}) => ({
    billTypeId: billType.id,
    projectId: project.id,
    customerId: project.customerId,
    organizationId: project.organization.id,
    companyId: project.organization.company.id,
    jobId: null,
    title: billType.name,
    description: billType.description,
    isPrimary: false,
    tip: 0,
    index: project.currentBills.filter((bill) => !bill.isPrimary).length,
    billRuleForms: billType.billRuleTypes.map((billRuleType) => {
      return BillRuleForm.editFromBillRuleType(billRuleType);
    }),
    billItemFormsPreSubtotal: billType.billItemTypes
      .map((billItemType, index) =>
        BillItemForm.toForm({
          ...BillItemForm.editFromBillItemType(billItemType, {billId: null}),
          index,
        }),
      )
      .filter((billItemForm) => billItemForm.billStage === BillStage.PRE_SUBTOTAL),
    billItemFormsPostSubtotal: billType.billItemTypes
      .map((billItemType, index) =>
        BillItemForm.toForm({
          ...BillItemForm.editFromBillItemType(billItemType, {billId: null}),
          index,
        }),
      )
      .filter((billItemForm) => billItemForm.billStage === BillStage.POST_SUBTOTAL),
    deletedBillItemForms: [],

    // NOTE(cooper): Line items and bill modifiers are deprecated in favor of bill items
    lineItemForms: [],
    billModifierForms: [],

    // Private
    allLineItemForms: [],
    allBillModifierForms: [],
    allBillRuleForms: [],
    allBillItemFormsPreSubtotal: [],
    allBillItemFormsPostSubtotal: [],
  }),
  gql`
    ${BillRuleForm.editFromBillRuleType.fragment}
    ${BillItemForm.editFromBillItemType.fragment}

    fragment BillForm_newFromBillType on Project {
      id
      customerId
      organization {
        id
        company {
          id
        }
        features {
          isEnabledMultipleHourlyRateBillingItems: isEnabled(
            feature: "MULTIPLE_HOURLY_RATE_BILLING_ITEMS"
          )
        }
      }
      currentBills {
        id
        isPrimary
      }
      projectType {
        id
        billingLibrary {
          id
          billTypes {
            id
            name
            description
            billItemTypes {
              id
              ...BillItemForm_editFromBillItemType
            }
            billRuleTypes {
              id
              ...BillRuleForm_editFromBillRuleType
            }
          }
        }
      }
    }
  `,
);

const edit = withFragment(
  (bill) => ({
    billId: bill.id,
    projectId: bill.projectId,
    customerId: bill.customerId,
    organizationId: bill.organizationId,
    companyId: bill.companyId,
    jobId: bill.jobId,
    title: bill.title,
    description: bill.description,
    kind: bill.kind,
    isPrimary: bill.isPrimary,
    tip: bill.tip,
    index: bill.index,
    billRuleForms: bill.billRules.map((billRule) => {
      return BillRuleForm.edit(billRule);
    }),
    lineItemForms: bill.lineItems.map((lineItem) => {
      return LineItemForm.edit(lineItem);
    }),
    billModifierForms: bill.billModifiers.map((billModifier) => {
      return BillModifierForm.edit(billModifier);
    }),
    billItemFormsPreSubtotal: bill.billItemsPreSubtotal.map((billItem) => {
      return BillItemForm.edit(billItem);
    }),
    billItemFormsPostSubtotal: bill.billItemsPostSubtotal.map((billItem) => {
      return BillItemForm.edit(billItem);
    }),
    deletedBillItemForms: [],

    // Private
    allLineItemForms: [],
    allBillModifierForms: [],
    allBillRuleForms: [],
    allBillItemFormsPreSubtotal: [],
    allBillItemFormsPostSubtotal: [],
  }),
  gql`
    ${BillItemForm.edit.fragment}
    ${BillRuleForm.edit.fragment}
    ${LineItemForm.edit.fragment}
    ${BillModifierForm.edit.fragment}

    fragment BillForm_edit on Bill {
      id
      projectId
      companyId
      jobId
      customerId
      organizationId
      title
      description
      kind
      isPrimary
      tip
      index
      billRules {
        ...BillRuleForm_edit
      }
      lineItems {
        ...LineItemForm_edit
      }
      billModifiers {
        ...BillModifierForm_edit
      }
      billItemsPreSubtotal {
        ...BillItemForm_edit
      }
      billItemsPostSubtotal {
        ...BillItemForm_edit
      }
    }
  `,
);

const editWithAllOptions = withFragment(
  (bill) => ({
    ...edit(bill),

    // Private
    allLineItemForms: Bill.getTemplateLineItemsForBill(bill).map((templateLineItem) => {
      return LineItemForm.editFromTemplateLineItem(templateLineItem);
    }),
    allBillModifierForms: Bill.getBillModifierTypesForBill(bill).map((billModifierType) => {
      return BillModifierForm.editFromBillModifierType(billModifierType);
    }),
    allBillRuleForms: Bill.getBillRuleTypesForBill(bill).map((billRuleType) => {
      return BillRuleForm.editFromBillRuleType(billRuleType);
    }),
    allBillItemFormsPreSubtotal: getBillItemFormsFromBillItemTypesForBill(bill).filter(
      (billItemForm) => billItemForm.billStage === BillStage.PRE_SUBTOTAL,
    ),
    allBillItemFormsPostSubtotal: getBillItemFormsFromBillItemTypesForBill(bill).filter(
      (billItemForm) => billItemForm.billStage === BillStage.POST_SUBTOTAL,
    ),
    isEnabledMultipleHourlyRateBillingItems:
      bill.organization.features.isEnabledMultipleHourlyRateBillingItems,
  }),
  gql`
    ${edit.fragment}
    ${getBillItemFormsFromBillItemTypesForBill.fragment}
    ${BillModifierForm.editFromBillModifierType.fragment}
    ${BillRuleForm.editFromBillRuleType.fragment}
    ${LineItemForm.editFromTemplateLineItem.fragment}
    ${Bill.getTemplateLineItemsForBill.fragment}
    ${Bill.getBillModifierTypesForBill.fragment}
    ${Bill.getBillRuleTypesForBill.fragment}

    fragment BillForm_editWithAllOptions on Bill {
      id
      organization {
        id
        settings {
          id
          templateLineItems {
            ...LineItemForm_editFromTemplateLineItem
          }
        }
        features {
          isEnabledMultipleHourlyRateBillingItems: isEnabled(
            feature: "MULTIPLE_HOURLY_RATE_BILLING_ITEMS"
          )
        }
        billModifierTypes {
          id
          ...BillModifierForm_editFromBillModifierType
        }
        billRuleTypes {
          id
          ...BillRuleForm_editFromBillRuleType
        }
      }
      ...BillForm_edit
      ...Bill_getTemplateLineItemsForBill
      ...Bill_getBillModifierTypesForBill
      ...Bill_getBillRuleTypesForBill
      ...BillForm_getBillItemFormsFromBillItemTypesForBill
    }
  `,
);

const toForm = ({
  billId,
  billTypeId,
  projectId,
  customerId,
  companyId,
  jobId,
  organizationId,
  title,
  description,
  kind,
  isPrimary,
  tip,
  index,
  billRuleForms,
  lineItemForms,
  billModifierForms,
  billItemFormsPreSubtotal,
  billItemFormsPostSubtotal,
  deletedBillItemForms,
  allLineItemForms,
  allBillModifierForms,
  allBillRuleForms,
  allBillItemFormsPreSubtotal,
  allBillItemFormsPostSubtotal,
  isEnabledMultipleHourlyRateBillingItems,
}) => ({
  billId,
  billTypeId,
  projectId,
  customerId,
  companyId,
  jobId,
  organizationId,
  title,
  description,
  kind,
  isPrimary,
  index,
  tip: Currency.toForm(tip),
  billRuleForms: billRuleForms.map((billRuleForm) => {
    return BillRuleForm.toForm(billRuleForm);
  }),
  lineItemForms: lineItemForms.map((lineItemForm) => {
    return LineItemForm.toForm(lineItemForm);
  }),
  billModifierForms: billModifierForms.map((billModifierForm) => {
    return BillModifierForm.toForm(billModifierForm);
  }),
  billItemFormsPreSubtotal: billItemFormsPreSubtotal.map((billItemForm) => {
    return BillItemForm.toForm(billItemForm);
  }),
  billItemFormsPostSubtotal: billItemFormsPostSubtotal.map((billItemForm) => {
    return BillItemForm.toForm(billItemForm);
  }),
  deletedBillItemForms,

  // Private
  allLineItemForms: allLineItemForms.map((lineItemForm) => {
    return LineItemForm.toForm(lineItemForm);
  }),
  allBillModifierForms: allBillModifierForms.map((billModifierForm) => {
    return BillModifierForm.toForm(billModifierForm);
  }),
  allBillRuleForms: allBillRuleForms.map((billRuleForm) => {
    return BillRuleForm.toForm(billRuleForm);
  }),
  allBillItemFormsPreSubtotal: allBillItemFormsPreSubtotal.map((billItemForm) => {
    return BillItemForm.toForm(billItemForm);
  }),
  allBillItemFormsPostSubtotal: allBillItemFormsPostSubtotal.map((billItemForm) => {
    return BillItemForm.toForm(billItemForm);
  }),
  isEnabledMultipleHourlyRateBillingItems,
});

const toMutation = ({
  billId,
  billTypeId,
  projectId,
  customerId,
  companyId,
  jobId,
  organizationId,
  title,
  description,
  kind,
  isPrimary,
  tip,
  index,
  billRuleForms,
  lineItemForms,
  billModifierForms,
  billItemFormsPreSubtotal,
  billItemFormsPostSubtotal,
  deletedBillItemForms,
}) => ({
  billId,
  billTypeId,
  projectId,
  customerId,
  companyId,
  jobId,
  organizationId,
  title,
  description,
  kind,
  isPrimary,
  index,
  tip: Currency.toMutation(tip),
  billRuleForms: billRuleForms.map((billRuleForm) => {
    return BillRuleForm.toMutation(billRuleForm);
  }),
  lineItemForms: lineItemForms.map((lineItemForm) => {
    return LineItemForm.toMutation(lineItemForm);
  }),
  billModifierForms: billModifierForms.map((billModifierForm) => {
    return BillModifierForm.toMutation(billModifierForm);
  }),
  billItemFormsPreSubtotal: billItemFormsPreSubtotal.map((billItemForm, index) => {
    return BillItemForm.toMutation({...billItemForm, index});
  }),
  billItemFormsPostSubtotal: billItemFormsPostSubtotal.map((billItemForm, index) => {
    return BillItemForm.toMutation({...billItemForm, index});
  }),
  deletedBillItemForms: deletedBillItemForms.map((billItemForm) => {
    return BillItemForm.toMutation({...billItemForm, isDeleted: true, index: null});
  }),
});

const BillForm = {
  getValidBillItemFormsPreSubtotal,

  edit,
  editWithAllOptions,
  new: _new,
  newFromBillType,
  toForm,
  toMutation,
};

export default BillForm;
