// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import SettingsForm from '@shared/modules/Settings/forms/SettingsForm';
import useToggleSettingsIsEmailAppendViaSupermoveEnabledMutation from '@shared/modules/Settings/hooks/useToggleSettingsIsEmailAppendViaSupermoveEnabledMutation';
import SuperAdminSettingsPage from 'modules/Organization/Settings/SuperAdmin/components/SuperAdminSettingsPage';
import SuperAdminSettingsSection from 'modules/Organization/Settings/SuperAdmin/components/SuperAdminSettingsSection';
import SuperAdminSettingsSectionOption from 'modules/Organization/Settings/SuperAdmin/components/SuperAdminSettingsSectionOption';

const SuperAdminCommunicationSettingsPageContent = ({settings, viewer}) => {
  const settingsForm = SettingsForm.edit(settings, {updatedById: viewer.id});
  const {handleSubmit} = useToggleSettingsIsEmailAppendViaSupermoveEnabledMutation({
    settingsForm,
    onSuccess: () => {},
    onError: (errors) => console.log({errors}),
  });
  return (
    <SuperAdminSettingsSection title={'Email'}>
      <SuperAdminSettingsSectionOption
        name={'Include Supermove Branding'}
        description={'Append “via Supermove” to the sender name for all emails.'}
        isEnabled={settings.isEmailAppendViaSupermoveEnabled}
        onPress={handleSubmit}
      />
    </SuperAdminSettingsSection>
  );
};

const SuperAdminCommunicationSettingsPage = () => {
  const {loading, data} = useQuery(SuperAdminCommunicationSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <SuperAdminSettingsPage
      title={'Communication'}
      description={
        'Configure email and SMS features. These changes will apply to the company, and if applicable, its branches.'
      }
      isLoading={loading}
      viewer={data?.viewer}
    >
      <SuperAdminCommunicationSettingsPageContent
        settings={data?.viewer.viewingOrganization.settings}
        viewer={data?.viewer}
      />
    </SuperAdminSettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SuperAdminCommunicationSettingsPage.query = gql`
  ${SettingsForm.edit.fragment}
  ${SuperAdminSettingsPage.fragment}

  query SuperAdminCommunicationSettingsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        settings {
          id
          isEmailAppendViaSupermoveEnabled
          ...SettingsForm_edit
        }
      }
      ...SuperAdminSettingsPage
    }
  }
`;

export default SuperAdminCommunicationSettingsPage;
