// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ListProjectsPageContent from 'modules/Project/List/components/ListProjectsPageContent';

const PageContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const ListProjectsPage = () => {
  const {loading, data} = useQuery(ListProjectsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <PageContainer>
      <ListProjectsPageContent
        organization={data.viewer.viewingOrganization}
        jobRequestsCount={data.jobRequestCounter.new}
      />
    </PageContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListProjectsPage.query = gql`
  ${ListProjectsPageContent.fragment}

  query ListProjectsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...ListProjectsPageContent
      }
    }
    jobRequestCounter {
      new
    }
  }
`;

export default ListProjectsPage;
