// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';

const useLoginForm = ({onSuccess}) => {
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useLoginForm.mutation,
    variables: {
      email: form.values.email,
      password: form.values.password,
    },
    onSuccess: async ({token, user}) => {
      if (token) {
        await Storage.setItem('token', token);
        await Storage.setItem('userId', user.id);
        onSuccess();
      }
    },
    onError: (errors) => {},
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useLoginForm.mutation = gql`
  mutation LoginPage($email: String!, $password: String!) {
    response: logIn(email: $email, password: $password) {
      ${gql.errors}
      token
      user {
        id
      }
    }
  }
`;

export default useLoginForm;
