// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {BillRule} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

const FlexContainer = Styled.View`
  flex: ${(props) => props.flex};
`;

const CaptionText = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const BillRuleContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) => (props.isMobile ? colors.blue.accent : colors.gray.background)};
  border-radius: 4px;
  padding: 8px;
`;

const BillRuleMainText = Styled.Text`
  ${Typography.Body3}
`;

const BillBillRule = ({billRule}) => {
  const responsive = useResponsive();
  return (
    <BillRuleContainer isMobile={responsive.mobile}>
      <FlexContainer flex={1}>
        <BillRuleMainText>{billRule.name}</BillRuleMainText>
        <CaptionText>{billRule.description}</CaptionText>
      </FlexContainer>
      <BillRuleMainText>{BillRule.getDisplayValues(billRule)}</BillRuleMainText>
    </BillRuleContainer>
  );
};

const BillingDocumentBillBillRules = ({bill}) => {
  const billRules = _.filter(bill.billRules, 'isVisibleToCustomer');
  return (
    <React.Fragment>
      {billRules.map((billRule, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && <Space height={8} />}
            <BillBillRule billRule={billRule} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillingDocumentBillBillRules.fragment = gql`
  ${BillRule.getDisplayValues.fragment}

  fragment BillingDocumentBillBillRules on Bill {
    id
    billRules {
      name
      description
      values
      isVisibleToCustomer
      ...BillRule_getDisplayValues
    }
  }
`;

export default BillingDocumentBillBillRules;
