// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import CostItemKind from '@shared/modules/Billing/enums/CostItemKind';
import CostItemUnit from '@shared/modules/Billing/enums/CostItemUnit';

const getDisplaySuffixFromUnit = (unit) => {
  switch (unit) {
    case CostItemUnit.CENTS_PER_HOUR:
      return '/hr';
    case CostItemUnit.CENTS_PER_MILE:
      return '/mile';
    case CostItemUnit.CENTS_PER_POUND:
      return '/lb';
    case CostItemUnit.CENTS_PER_ONE_HUNDRED_POUNDS:
      return '/100lb';
    default:
      return '';
  }
};

const getDisplayKind = withFragment(
  ({kind}) => {
    switch (kind) {
      case CostItemKind.PER_BILL_ITEM:
        return 'Per Item';
      case CostItemKind.PER_BILL_TOTAL:
        return 'Per Bill';
      case CostItemKind.PER_GRAND_TOTAL:
        return 'Per Grand Total';
      case CostItemKind.PER_WEIGHT:
        return 'Per Weight';
      case CostItemKind.PER_MILE:
        return 'Per Mile';
      default:
        return '';
    }
  },
  gql`
    fragment CostItemType_getDisplayKind on CostItemType {
      id
      kind
    }
  `,
);

const getDisplayName = withFragment(
  ({kind, name, billItemType}) => {
    if (kind === CostItemKind.PER_BILL_ITEM) {
      return billItemType.moverPosition
        ? `${billItemType.name} (${billItemType.moverPosition.name})`
        : billItemType.name;
    } else {
      return name;
    }
  },
  gql`
    fragment CostItemType_getDisplayName on CostItemType {
      id
      kind
      name
      billItemType {
        id
        name
        moverPosition {
          id
          name
        }
      }
    }
  `,
);

const CostItemType = {
  getDisplayKind,
  getDisplayName,
  getDisplaySuffixFromUnit,
};

export default CostItemType;
