// Libraries
import _ from 'lodash';

const SPECIFIC_USER = 'SPECIFIC_USER';
const ROUND_ROBIN = 'ROUND_ROBIN';

const KIND_OPTIONS = {
  SPECIFIC_USER: {
    value: SPECIFIC_USER,
    label: 'Specific User',
  },
  ROUND_ROBIN: {
    value: ROUND_ROBIN,
    label: 'Round Robin',
  },
};

const getKindOption = (kind) => {
  return KIND_OPTIONS[kind];
};

export default {
  SPECIFIC_USER,
  ROUND_ROBIN,

  DROPDOWN_OPTIONS: _.values(KIND_OPTIONS),
  getKindOption,
};
