// Supermove
import {ToggleBooleanPropertyForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useUpdateTaskIsCompletedMutation = ({toggleBooleanPropertyForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      toggleBooleanPropertyForm: ToggleBooleanPropertyForm.toForm(toggleBooleanPropertyForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateTaskIsCompletedMutation.mutation,
    variables: {
      toggleBooleanPropertyForm: ToggleBooleanPropertyForm.toMutation(
        form.values.toggleBooleanPropertyForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateTaskIsCompletedMutation.mutation = gql`
  mutation UpdateTaskIsCompletedMutation($toggleBooleanPropertyForm: ToggleBooleanPropertyForm) {
    response: updateTaskIsCompleted(toggleBooleanPropertyForm: $toggleBooleanPropertyForm) {
      ${gql.errors}
      task {
        id
        isCompleted
        completedAt
        discussedAt
      }
    }
  }
`;

export default useUpdateTaskIsCompletedMutation;
