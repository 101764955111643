// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import JobTypeVariableForm from '@shared/modules/Job/forms/JobTypeVariableForm';

const _new = (jobTypeId) => ({
  jobTypeVariableSectionId: null,
  jobTypeId,
  name: '',
  isDefault: false,
  jobTypeVariableForms: [],
});

const edit = withFragment(
  (jobTypeVariableSection) => ({
    jobTypeVariableSectionId: jobTypeVariableSection.id,
    jobTypeId: jobTypeVariableSection.jobTypeId,
    name: jobTypeVariableSection.name,
    isDefault: jobTypeVariableSection.isDefault,
    jobTypeVariableForms: jobTypeVariableSection.orderedVariables
      .filter((variable) => {
        // filter out dynamic variables not editable on FE, like PER_POSITION...
        return !_.has(VariableFormat.DYNAMIC_VARIABLE_FORMATS, variable.format);
      })
      .map((variable) => {
        const jobTypeVariable = _.find(jobTypeVariableSection.jobType.jobTypeVariables, {
          variableId: _.toNumber(variable.id),
        });
        const variableOptions = JSON.parse(variable.variableOptionsV3);
        if (jobTypeVariable) {
          return JobTypeVariableForm.edit(
            {
              ...jobTypeVariable,
            },
            {
              name: variable.name,
              format: variable.format,
              defaultAmountOptions: variableOptions,
              isDerived: variable.isDerived,
              description: variable.description,
            },
          );
        } else {
          return JobTypeVariableForm.edit(
            {
              defaultAmount: 0,
              isRequiredForCreateProject: false,
              isVisibleForCreateProject: !!variable.isVisibleForCreateProject,
              jobTypeId: jobTypeVariableSection.jobTypeId,
              variableId: _.toNumber(variable.id),
              isEnabled: true,
            },
            {
              name: variable.name,
              format: variable.format,
              defaultAmountOptions: variableOptions,
              isDerived: variable.isDerived,
              description: variable.description,
            },
          );
        }
      }),

    // Private
    isCollapsed: false,
  }),
  gql`
    ${JobTypeVariableForm.edit.fragment}
    fragment JobTypeVariableSectionForm_edit on JobTypeVariableSection {
      id
      jobTypeId
      name
      isDefault
      orderedVariables {
        id
        name
        isVisibleForCreateProject
        format
        variableOptionsV3
        isDerived
        description
      }
      jobType {
        id
        jobTypeVariables {
          id
          ...JobTypeVariableForm_edit
        }
      }
    }
  `,
);

const toForm = ({
  jobTypeVariableSectionId,
  jobTypeId,
  name,
  isDefault,
  jobTypeVariableForms,

  // Private
  isCollapsed,
}) => ({
  jobTypeVariableSectionId,
  jobTypeId,
  name,
  isDefault,
  jobTypeVariableForms: jobTypeVariableForms.map((jobTypeVariableForm) =>
    JobTypeVariableForm.toForm(jobTypeVariableForm),
  ),

  // Private
  isCollapsed,
});

const toMutation = ({
  jobTypeVariableSectionId,
  jobTypeId,
  name,
  isDefault,
  jobTypeVariableForms,
}) => ({
  jobTypeVariableSectionId,
  jobTypeId,
  name,
  isDefault,
  // We only pass the dirty/modified forms to the backend
  // Only these Job Type Variables will be upsertted
  jobTypeVariableForms: jobTypeVariableForms
    .filter((jobTypeVariableForm) => _.get(jobTypeVariableForm, 'isDirty'))
    .map((jobTypeVariableForm) => JobTypeVariableForm.toMutation(jobTypeVariableForm)),
  // We pass all orderedVariableIds based on the unfiltered list of jobTypeVariableForms
  orderedVariableIds: getVariableIdsFromJobTypeVariableForms({jobTypeVariableForms}),
});

const removeVariableFormsById = ({jobTypeVariableSectionForm, variableIdsToRemove}) => {
  const jobTypeVariableForms = _.get(jobTypeVariableSectionForm, 'jobTypeVariableForms', []);
  return _.remove(jobTypeVariableForms, (jobTypeVariableForm) =>
    _.includes(variableIdsToRemove, _.get(jobTypeVariableForm, 'variableId')),
  );
};

const getVariableIdsFromJobTypeVariableForms = ({jobTypeVariableForms}) => {
  return _.map(jobTypeVariableForms, (jobTypeVariableForm) =>
    _.get(jobTypeVariableForm, 'variableId'),
  );
};

const getVariableIdsInSection = ({jobTypeVariableSectionForm}) => {
  const jobTypeVariableForms = _.get(jobTypeVariableSectionForm, 'jobTypeVariableForms', []);
  return getVariableIdsFromJobTypeVariableForms({jobTypeVariableForms});
};

const JobTypeVariableSectionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,

  removeVariableFormsById,
  getVariableIdsInSection,
};

export default JobTypeVariableSectionForm;
