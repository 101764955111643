// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Table from '@shared/design/components/Table';
import ProjectWidgetKind from '@shared/modules/Project/enums/ProjectWidgetKind';
import Line from 'components/Line';
import StatusBadge from 'modules/App/components/StatusBadge';
import ClaimActionsButton from 'modules/Project/Claim/components/ClaimActionsButton';
import EditProjectClaimDrawer from 'modules/Project/Claim/components/EditProjectClaimDrawer';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmptyText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
  padding-vertical: 16px;
  align-self: center;
`;

const MobileLabelText = Styled.Text`
  ${Typography.Mobile.Label}
`;

const ClaimTypeColor = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({color}) => color};
`;

const ProjectClaimCardButton = Styled.ButtonV2`
`;

const sortClaims = (claims) => {
  return _.orderBy(claims, ['createdAt']);
};

const ClaimStatusBadge = ({label, color}) => {
  return (
    <Row style={{flex: 1, overflow: 'hidden'}}>
      <StatusBadge color={color} label={label} size={StatusBadge.SIZE.LARGE} />
    </Row>
  );
};

const EditClaimButton = ({claim, project, refetch}) => {
  const editClaimDrawer = useDrawer({name: 'Edit Claim Drawer'});
  return (
    <React.Fragment>
      <TertiaryButton onPress={editClaimDrawer.handleOpen}>
        <Icon source={Icon.Pen} size={16} color={colors.blue.interactive} />
      </TertiaryButton>
      <EditProjectClaimDrawer
        key={editClaimDrawer.key}
        claim={claim}
        projectUuid={project.uuid}
        isOpen={editClaimDrawer.isOpen}
        handleClose={editClaimDrawer.handleClose}
        onSuccess={() => {
          editClaimDrawer.handleClose();
          refetch();
        }}
      />
    </React.Fragment>
  );
};

const ClaimIdentifierButton = ({claim, project, refetch}) => {
  const viewClaimDrawer = useDrawer({name: 'Edit Claim Drawer'});
  return (
    <Table.PreventPropagationContainer>
      <TertiaryButton onPress={viewClaimDrawer.handleOpen}>
        <Table.CellLink>{claim.identifier}</Table.CellLink>
      </TertiaryButton>
      <EditProjectClaimDrawer
        key={viewClaimDrawer.key}
        claim={claim}
        projectUuid={project.uuid}
        isOpen={viewClaimDrawer.isOpen}
        handleClose={viewClaimDrawer.handleClose}
        onSuccess={() => {
          viewClaimDrawer.handleClose();
          refetch();
        }}
        isViewMode
      />
    </Table.PreventPropagationContainer>
  );
};

const ClaimStatus = ({claim}) => {
  const {name, color} = claim.claimStatus;
  return <ClaimStatusBadge label={name} color={color} />;
};

const ClaimType = ({claim}) => {
  const {color, name} = claim.claimType;
  return (
    <Row style={{flex: 1}}>
      <ClaimTypeColor color={color} />
      <Space width={8} />
      <Table.CellText numberOfLines={1}>{name}</Table.CellText>
    </Row>
  );
};

const ClaimAmount = ({claim}) => {
  return (
    <Table.CellText>
      {_.isNil(claim.amount) ? 'TBD' : Currency.display(claim.amount)}
    </Table.CellText>
  );
};

const getColumnDefinitions = ({project, refetch}) => {
  return [
    {
      flex: 2,
      headerContent: () => {
        return <Table.HeaderText>Status</Table.HeaderText>;
      },
      cellContent: ({item: claim}) => {
        return <ClaimStatus claim={claim} />;
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText>ID</Table.HeaderText>;
      },
      cellContent: ({item: claim}) => {
        return <ClaimIdentifierButton claim={claim} project={project} refetch={refetch} />;
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <Table.HeaderText>Type</Table.HeaderText>;
      },
      cellContent: ({item: claim}) => {
        return <ClaimType claim={claim} />;
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <Table.HeaderText>Amount</Table.HeaderText>;
      },
      cellContent: ({item: claim}) => {
        return <ClaimAmount claim={claim} />;
      },
    },
    {
      flex: 3,
      headerContent: () => {
        return <Table.HeaderText>Last Action</Table.HeaderText>;
      },
      cellContent: ({item: claim}) => {
        return (
          <Container style={{flex: 1}}>
            <Table.CellText numberOfLines={1}>
              {claim.isEdited ? 'Claim updated' : 'Claim created'}
            </Table.CellText>
            <Space height={2} />
            <Table.CellText style={{color: colors.gray.secondary}} numberOfLines={1}>
              {Datetime.convertToDisplayDatetime(claim.updatedAt, 'MM/DD/YY, h:mm A')}
              {' by '}
              {claim.updatedBy.fullName}
            </Table.CellText>
          </Container>
        );
      },
    },
    {
      width: 88,
      headerContent: () => {
        return <Table.HeaderText>Actions</Table.HeaderText>;
      },
      cellContent: ({item: claim}) => {
        return (
          <Row
            onStartShouldSetResponder={(event) => true}
            onTouchEnd={(e) => {
              e.stopPropagation();
            }}
          >
            <EditClaimButton claim={claim} project={project} refetch={refetch} />
            <Space width={4} />
            <ClaimActionsButton claim={claim} refetch={refetch} />
          </Row>
        );
      },
    },
  ];
};

const RowHookComponent = ({isOpen, handleClose, item, project, refetch}) => {
  return (
    <EditProjectClaimDrawer
      key={isOpen}
      claim={item}
      projectUuid={project.uuid}
      isOpen={isOpen}
      handleClose={handleClose}
      onSuccess={() => {
        handleClose();
        refetch();
      }}
      isViewMode
    />
  );
};

const ProjectClaims = ({project, refetch}) => {
  return (
    <React.Fragment>
      {_.isEmpty(project.claims) ? (
        <EmptyText>There are no claims for this project</EmptyText>
      ) : (
        <Table
          columnDefinitions={getColumnDefinitions({
            project,
            refetch,
          })}
          items={sortClaims(project.claims)}
          containerStyle={{borderWidth: 0}}
          isDense
          isClickable
          rowHook={{
            hook: useDrawer,
            hookArgument: {name: 'Edit Claim Drawer'},
            Component: RowHookComponent,
            componentProps: {project, refetch},
          }}
        />
      )}
    </React.Fragment>
  );
};

const ProjectClaimsCard = ({claim, project, refetch, urlFilters}) => {
  const editClaimDrawer = useDrawer({name: 'Edit Claim Drawer'});

  return (
    <React.Fragment>
      <ProjectClaimCardButton onPress={editClaimDrawer.handleOpen}>
        <Space height={8} />
        <Row style={{justifyContent: 'space-between'}}>
          <MobileLabelText>{`Claim ${claim.identifier}`}</MobileLabelText>
          <Row>
            <Space width={8} />
            <ClaimStatus claim={claim} />
            <Space width={8} />
            <Icon source={Icon.AngleRight} size={14} color={colors.gray.secondary} />
          </Row>
        </Row>
        <Space height={8} />
        <ClaimType claim={claim} />
        <Space height={8} />
        <ClaimAmount claim={claim} />
        <Space height={8} />
      </ProjectClaimCardButton>
      <EditProjectClaimDrawer
        key={editClaimDrawer.key}
        claim={claim}
        projectUuid={project.uuid}
        isOpen={editClaimDrawer.isOpen}
        handleClose={editClaimDrawer.handleClose}
        onSuccess={() => {
          editClaimDrawer.handleClose();
          refetch();
        }}
        isViewMode
        handlePressSms={() => {
          editClaimDrawer.handleClose();
          urlFilters.handleUpdate({widget: ProjectWidgetKind.SMS}, true);
        }}
        handlePressEmail={() => {
          editClaimDrawer.handleClose();
          urlFilters.handleUpdate({widget: ProjectWidgetKind.EMAILS}, true);
        }}
      />
    </React.Fragment>
  );
};

const ProjectClaimsCardList = ({project, refetch, urlFilters}) => {
  const claims = sortClaims(project.claims);
  return (
    <React.Fragment>
      {_.isEmpty(claims) ? (
        <EmptyText style={{alignSelf: 'flex-start', paddingVertical: 0}}>No claims</EmptyText>
      ) : (
        claims.map((claim) => (
          <React.Fragment key={claim.id}>
            <Line />
            <ProjectClaimsCard
              key={claim.id}
              claim={claim}
              project={project}
              refetch={refetch}
              urlFilters={urlFilters}
            />
          </React.Fragment>
        ))
      )}
    </React.Fragment>
  );
};

ProjectClaims.Mobile = ProjectClaimsCardList;

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectClaims.fragment = gql`
  ${ClaimActionsButton.fragment}
  ${EditProjectClaimDrawer.fragment}

  fragment ProjectClaims on Project {
    ${gql.query}
    id
    uuid
    claims {
      id
      identifier
      amount
      updatedAt
      isEdited
      createdAt
      claimStatus {
        id
        name
        color
      }
      claimType {
        id
        name
        color
      }
      updatedBy {
        id
        fullName
      }
      ...ClaimActionsButton
      ...EditProjectClaimDrawer
    }
  }
`;

ProjectClaims.Mobile.fragment = gql`
  ${EditProjectClaimDrawer.fragment}

  fragment ProjectClaims_Mobile on Project {
    id
    claims {
      id
      identifier
      amount
      createdAt
      claimStatus {
        id
        name
        color
      }
      claimType {
        id
        name
        color
      }
      ...EditProjectClaimDrawer
    }
  }
`;

export default ProjectClaims;
