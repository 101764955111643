// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import OrganizationMoverNotificationSettingsForm from '@shared/modules/Organization/forms/OrganizationMoverNotificationSettingsForm';

// App

const useUpdateOrganizationMoverNotificationSettingsMutation = ({
  organizationMoverNotificationSettingsForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      organizationMoverNotificationSettingsForm: OrganizationMoverNotificationSettingsForm.toForm(
        organizationMoverNotificationSettingsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationMoverNotificationSettingsMutation.mutation,
    variables: {
      organizationMoverNotificationSettingsForm:
        OrganizationMoverNotificationSettingsForm.toMutation(
          form.values.organizationMoverNotificationSettingsForm,
        ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationMoverNotificationSettingsMutation.mutation = gql`
  ${OrganizationMoverNotificationSettingsForm.edit.fragment}
  mutation useUpdateOrganizationMoverNotificationSettingsMutation($organizationMoverNotificationSettingsForm: OrganizationMoverNotificationSettingsForm!) {
    response: updateOrganizationMoverNotificationSettings(organizationMoverNotificationSettingsForm: $organizationMoverNotificationSettingsForm) {
      ${gql.errors}
      organization {
        id
        ...OrganizationMoverNotificationSettingsForm_edit
      }
    }
  }
`;

export default useUpdateOrganizationMoverNotificationSettingsMutation;
