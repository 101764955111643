// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

const Title = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const SuperAdminSettingsSection = ({title, children}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      {!!title && (
        <React.Fragment>
          <Title responsive={responsive}>{title}</Title>
          <Space height={16} />
        </React.Fragment>
      )}
      {children}
    </React.Fragment>
  );
};

// ------------------------------------
// Props
// ------------------------------------
SuperAdminSettingsSection.propTypes = {
  title: PropTypes.string,
};

SuperAdminSettingsSection.defaultProps = {
  title: '',
};

export default SuperAdminSettingsSection;
