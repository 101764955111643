// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import DashboardCategory from '@shared/modules/Reports/enums/DashboardCategory';
import DashboardKind from '@shared/modules/Reports/enums/DashboardKind';

const _new = ({organizationId, name, exploDashboardEmbedId, exploVariables}) => ({
  dashboardId: null,
  organizationId,
  kind: DashboardKind.EXPLO,
  category: DashboardCategory.GENERAL,
  name: name || '',
  exploDashboardEmbedId: exploDashboardEmbedId || '',
  exploVariables: exploVariables || null,
  index: null,
  uuid: null,
});

const toForm = ({
  id,
  organizationId,
  kind,
  category,
  name,
  exploDashboardEmbedId,
  exploVariables,
  index,
  uuid,
}) => ({
  organizationId,
  kind,
  category,
  name,
  exploDashboardEmbedId,
  exploVariables,
  id,
  index,
  uuid,
});

const toMutation = ({
  organizationId,
  kind,
  category,
  name,
  exploDashboardEmbedId,
  exploVariables,
  id,
  index,
}) => ({
  organizationId,
  kind,
  category,
  name,
  exploDashboardEmbedId,
  exploVariables: exploVariables && exploVariables.trim() ? exploVariables : null,
  dashboardId: id,
  index,
});

const edit = withFragment(
  (reportDashboard) => ({
    organizationId: reportDashboard.organizationId,
    kind: reportDashboard.kind,
    category: reportDashboard.category,
    name: reportDashboard.name,
    exploDashboardEmbedId: reportDashboard.exploDashboardEmbedId,
    exploVariables: reportDashboard.exploVariables,
    id: reportDashboard.id,
    index: reportDashboard.index,
    uuid: reportDashboard.uuid,
  }),
  gql`
    fragment ReportDashboardForm_edit on Dashboard {
      id
      organizationId
      kind
      category
      name
      exploDashboardEmbedId
      exploVariables
      index
      uuid
    }
  `,
);

const ReportDashboardForm = {
  new: _new,
  toForm,
  toMutation,
  edit,
};

export default ReportDashboardForm;
