// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CreateTimesheetBillableBlocksForm from '@shared/modules/Timesheet/forms/CreateTimesheetBillableBlocksForm';

const useCreateTimesheetBillableBlocksMutation = ({
  createTimesheetBillableBlocksForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      createTimesheetBillableBlocksForm: CreateTimesheetBillableBlocksForm.toForm(
        createTimesheetBillableBlocksForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateTimesheetBillableBlocksMutation.mutation,
    variables: {
      createTimesheetBillableBlocksForm: CreateTimesheetBillableBlocksForm.toMutation(
        form.values.createTimesheetBillableBlocksForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCreateTimesheetBillableBlocksMutation.mutation = gql`
  mutation useCreateTimesheetBillableBlocksMutation($createTimesheetBillableBlocksForm: CreateTimesheetBillableBlocksForm!) {
    response: createTimesheetBillableBlocks(createTimesheetBillableBlocksForm: $createTimesheetBillableBlocksForm) {
      ${gql.errors}
      timesheetBlocks {
        id
      }
    }
  }
`;

export default useCreateTimesheetBillableBlocksMutation;
