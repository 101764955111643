// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (slot) => ({
    slotId: slot.id,
    truckId: slot.truckId,
  }),
  gql`
    fragment TruckToSlotForm_edit on Slot {
      id
      truckId
    }
  `,
);

const _new = ({truckId, slotId}) => ({
  truckId,
  slotId,
});

const toForm = ({truckId, slotId}) => {
  return {
    truckId,
    slotId,
  };
};

const toMutation = ({truckId, slotId}) => {
  return {
    truckId,
    slotId,
  };
};

const AssignTruckToSlotForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default AssignTruckToSlotForm;
