// Supermove
import {gql} from '@supermove/graphql';
import {Currency, Percent, withFragment} from '@supermove/utils';

// App
import CostItemUnit from '@shared/modules/Billing/enums/CostItemUnit';

const getDisplayRate = withFragment(
  ({rate, unit}) => {
    switch (unit) {
      case CostItemUnit.PERCENT:
        return Percent.display(rate);
      default:
        return Currency.display(rate, {shouldHideCentsIfZero: true});
    }
  },
  gql`
    fragment CostItem_getDisplayRate on CostItem {
      id
      rate
      unit
    }
  `,
);

const getDisplayRelatedTotal = withFragment(
  ({relatedTotal, unit}) => {
    if (unit === CostItemUnit.PERCENT) {
      return Currency.display(relatedTotal, {shouldHideCentsIfZero: true});
    }
    return '';
  },
  gql`
    fragment CostItem_getDisplayRelatedTotal on CostItem {
      id
      unit
      relatedTotal
    }
  `,
);

const CostItem = {
  getDisplayRate,
  getDisplayRelatedTotal,
};

export default CostItem;
