// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import UpdateJobTypeForJobForm from '@shared/modules/Project/forms/UpdateJobTypeForJobForm';

const edit = withFragment(
  (project) => ({
    projectId: project.id,
    projectTypeId: project.projectType.id,
    isBillingReset: false,
    isBillingValuesReset: false,
    updateJobTypeForJobForms: Job.sortJobsBySequence(project.activeJobs).map((job) =>
      UpdateJobTypeForJobForm.edit(job),
    ),

    // Private
    billingLibraryId: _.toString(project.projectType.billingLibraryId),
    projectTypeName: project.projectType.name,
  }),
  gql`
    ${Job.sortJobsBySequence.fragment}
    ${UpdateJobTypeForJobForm.edit.fragment}
    fragment UpdateProjectAndJobTypesForProjectForm_edit on Project {
      id
      projectType {
        id
        billingLibraryId
        name
      }
      activeJobs {
        id
        ...Job_sortJobsBySequence
        ...UpdateJobTypeForJobForm_edit
      }
    }
  `,
);

const toForm = ({
  projectId,
  projectTypeId,
  isBillingReset,
  isBillingValuesReset,
  updateJobTypeForJobForms,

  // Private
  billingLibraryId,
  projectTypeName,
}) => ({
  projectId,
  projectTypeId: _.toString(projectTypeId),
  isBillingReset,
  isBillingValuesReset,
  updateJobTypeForJobForms: updateJobTypeForJobForms.map((updateJobTypeForJobForm) =>
    UpdateJobTypeForJobForm.toForm(updateJobTypeForJobForm),
  ),

  // Private
  billingLibraryId: _.toString(billingLibraryId),
  projectTypeName,
});

const toMutation = ({
  projectId,
  projectTypeId,
  isBillingReset,
  isBillingValuesReset,
  updateJobTypeForJobForms,
}) => ({
  projectId,
  projectTypeId,
  isBillingReset,
  isBillingValuesReset,
  updateJobTypeForJobForms: updateJobTypeForJobForms.map((updateJobTypeForJobForm) =>
    UpdateJobTypeForJobForm.toMutation(updateJobTypeForJobForm),
  ),
});

const UpdateProjectAndJobTypesForProjectForm = {
  edit,
  toForm,
  toMutation,
};

export default UpdateProjectAndJobTypesForProjectForm;
