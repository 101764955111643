// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Popover, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, usePopover, useResponsive} from '@supermove/hooks';
import {Payment, Project} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import Callout from '@shared/design/components/Callout';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import DeletePaymentModal from 'modules/Bill/components/DeletePaymentModal';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Line = Styled.View`
  flex: 1;
  border-color: ${colors.gray.border};
  border-bottom-width: 1px;
  border-style: ${(props) => props.borderStyle};
`;

const Container = Styled.View`
  padding-horizontal: ${(props) => (props.isEditable ? '12px' : '0px')};
`;

const HeaderText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const IconContainerTouchable = Styled.Touchable`
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border-color: ${colors.gray.border};
  background-color: ${colors.gray.background};
`;

const PaymentText = Styled.H7`
  color: ${({color}) => color};
  ${fontWeight(500)}
  text-align: ${(props) => props.align};
`;

const PaymentTextBold = Styled.H7`
  color: ${({color}) => color};
  ${fontWeight(700)}
  text-align: ${(props) => props.align};
`;

const BalanceDueText = Styled.H7`
  color: ${(props) => props.color};
  ${fontWeight(700)}
`;

const PaymentOptionTouchable = Styled.Touchable`
  background-color: ${(props) => (props.isHighlighted ? colors.hover : colors.white)};
  padding-vertical: 4px;
  flex-direction: row;
`;

const PaymentOptionText = Styled.H7`
  color: ${(props) => props.color};
  ${fontWeight(500)}
`;

const PaymentActionOption = ({label, handlePress, isDisabled}) => {
  const {ref, isHovered} = useHover();
  return (
    <PaymentOptionTouchable
      ref={ref}
      isHighlighted={isHovered}
      activeOpacity={0.8}
      disabled={isDisabled}
      onPress={handlePress}
    >
      <Space width={12} />
      <PaymentOptionText color={isDisabled ? colors.gray.tertiary : colors.gray.primary}>
        {label}
      </PaymentOptionText>
    </PaymentOptionTouchable>
  );
};

const PaymentActionsPopover = ({payment, popover, deletePaymentModal}) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 2]}
    >
      <ResponsivePopover.StaticContainer width={180}>
        <Space height={8} />
        <PaymentActionOption
          label={'Delete'}
          isDisabled={payment.invoice && payment.invoice.status === InvoiceStatus.FINALIZED}
          handlePress={() => {
            popover.handleClose();
            deletePaymentModal.handleOpen();
          }}
        />
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const PaymentsListItem = ({payment, refetch, isEditable}) => {
  const paymentActionsPopover = usePopover();
  const deletePaymentModal = useModal();
  const responsive = useResponsive();
  const isCreditCardMethod = Payment.isCreditCardMethod({method: payment.method});

  return (
    <React.Fragment>
      <Row>
        <Row style={{flex: 4, alignItems: responsive.mobile ? 'flex-start' : 'center'}}>
          {isEditable && (
            <React.Fragment>
              <Popover.Content innerRef={paymentActionsPopover.ref}>
                <IconContainerTouchable
                  disabled={isCreditCardMethod}
                  activeOpacity={0.8}
                  onPress={paymentActionsPopover.handleToggle}
                >
                  <Icon
                    source={Icon.EllipsisV}
                    size={16}
                    color={isCreditCardMethod ? colors.gray.disabled : colors.blue.interactive}
                  />
                </IconContainerTouchable>
              </Popover.Content>
              <Space width={16} />
            </React.Fragment>
          )}
          {responsive.mobile ? (
            <PaymentTextBold align={'left'} color={colors.gray.primary}>
              {payment.name}
            </PaymentTextBold>
          ) : (
            <PaymentText align={'left'} color={colors.gray.primary}>
              {payment.name}
            </PaymentText>
          )}
        </Row>
        {!responsive.mobile && (
          <React.Fragment>
            <Row style={{flex: 2, justifyContent: 'flex-end'}}>
              <PaymentText align={'right'} color={colors.gray.primary}>
                {Payment.getDisplayMethod(payment)}
              </PaymentText>
            </Row>
            <Row style={{flex: 2, justifyContent: 'flex-end'}}>
              <Space width={16} />
              <PaymentText align={'right'} color={colors.gray.primary}>
                {payment.description}
              </PaymentText>
            </Row>
            <Row style={{flex: 3, justifyContent: 'flex-end'}}>
              <PaymentText align={'right'} color={colors.gray.primary}>
                {Currency.display(payment.totalAmount, {shouldHideCentsIfZero: true})}
              </PaymentText>
            </Row>
          </React.Fragment>
        )}
      </Row>
      {responsive.mobile && (
        <React.Fragment>
          <Space height={4} />
          <Row style={{justifyContent: 'space-between'}}>
            <Row style={{flex: 1}}>
              <Space width={40} />
              <PaymentText align={'right'} color={colors.gray.primary}>
                {Payment.getDisplayMethod(payment)}
              </PaymentText>
            </Row>
            <PaymentText align={'right'} color={colors.gray.primary}>
              {Currency.display(payment.totalAmount, {shouldHideCentsIfZero: true})}
            </PaymentText>
          </Row>
          {!!payment.description && (
            <React.Fragment>
              <Space height={4} />
              <Row>
                <Space width={40} />
                <PaymentText align={'right'} color={colors.gray.tertiary}>
                  {payment.description}
                </PaymentText>
              </Row>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <PaymentActionsPopover
        popover={paymentActionsPopover}
        deletePaymentModal={deletePaymentModal}
        payment={payment}
      />
      <DeletePaymentModal
        isOpen={deletePaymentModal.isOpen}
        handleClose={deletePaymentModal.handleClose}
        payment={payment}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const NoPaymentsMessage = () => {
  return <Callout text={'No payments have been recorded for this invoice.'} />;
};

const BalanceDue = ({isEditable, isTotalAvailable, minBalance, maxBalance, balanceColor}) => {
  const color = isEditable ? balanceColor : colors.gray.primary;
  const responsive = useResponsive();
  const balance = isTotalAvailable
    ? Currency.formatRange({min: minBalance, max: maxBalance})
    : 'TBD';

  if (responsive.mobile) {
    return (
      <Row style={{justifyContent: 'space-between'}}>
        <BalanceDueText color={color}>Balance Due</BalanceDueText>
        <BalanceDueText color={color}>{balance}</BalanceDueText>
      </Row>
    );
  }
  return (
    <Row>
      <Row style={{flex: 8, justifyContent: 'flex-end'}}>
        <BalanceDueText color={color}>Balance Due</BalanceDueText>
      </Row>
      <Row style={{flex: 3, justifyContent: 'flex-end'}}>
        <BalanceDueText color={color}>{balance}</BalanceDueText>
      </Row>
    </Row>
  );
};

const BillingProjectPaymentsList = ({
  isEditable,
  includeAllBills,
  showBalance,
  project,
  refetch,
  isHeaderVisible,
}) => {
  const {activeJobsAggregateBill, currentAggregateBill} = project;
  const aggregateBill = includeAllBills ? activeJobsAggregateBill : currentAggregateBill;
  const {isTotalAvailable, minBalance, maxBalance, payments} = aggregateBill;

  return (
    <Container isEditable={isEditable}>
      {isHeaderVisible && (
        <React.Fragment>
          <Row>
            <Icon source={Icon.CreditCard} size={16} color={colors.gray.primary} />
            <Space width={8} />
            <HeaderText>Payments</HeaderText>
          </Row>
          <Space height={8} />
          <Line borderStyle={'solid'} />
          <Space height={8} />
        </React.Fragment>
      )}
      {_.some(payments) ? (
        payments.map((payment, index) => {
          return (
            <React.Fragment key={payment.id}>
              {index > 0 && <Space height={8} />}
              <PaymentsListItem payment={payment} refetch={refetch} isEditable={isEditable} />
            </React.Fragment>
          );
        })
      ) : (
        <NoPaymentsMessage />
      )}
      <Space height={8} />
      <Line borderStyle={'dashed'} />
      {showBalance && (
        <React.Fragment>
          <Space height={12} />
          <BalanceDue
            isEditable={isEditable}
            isTotalAvailable={isTotalAvailable}
            minBalance={minBalance}
            maxBalance={maxBalance}
            balanceColor={Project.getProjectBillBalanceColor({project, includeAllBills})}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
BillingProjectPaymentsList.propTypes = {
  includeAllBills: PropTypes.bool,
  showBalance: PropTypes.bool,
  isHeaderVisible: PropTypes.bool,
};

BillingProjectPaymentsList.defaultProps = {
  includeAllBills: true,
  showBalance: true,
  isHeaderVisible: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingProjectPaymentsList.fragment = gql`
  ${DeletePaymentModal.fragment}
  ${Project.getProjectBillBalanceColor.fragment}

  fragment BillingProjectPaymentsList_AggregateBill on AggregateBill {
    isTotalAvailable
    minBalance
    maxBalance
    payments {
      id
      name
      method
      description
      totalAmount
      isPaid
      invoice {
        id
        status
      }
      ...DeletePaymentModal
    }
  }

  fragment BillingProjectPaymentsList on Project {
    id
    activeJobsAggregateBill {
      ...BillingProjectPaymentsList_AggregateBill
    }
    currentAggregateBill {
      ...BillingProjectPaymentsList_AggregateBill
    }
    ...Project_getProjectBillBalanceColor
  }
`;

export default BillingProjectPaymentsList;
