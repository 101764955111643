// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ManuallyCompleteProjectConfirmationForm from '@shared/modules/Proposal/forms/ManuallyCompleteProjectConfirmationForm';

const useManuallyCompleteProjectConfirmationMutation = ({
  manuallyCompleteProjectConfirmationForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      manuallyCompleteProjectConfirmationForm: ManuallyCompleteProjectConfirmationForm.toForm(
        manuallyCompleteProjectConfirmationForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useManuallyCompleteProjectConfirmationMutation.mutation,
    variables: {
      manuallyCompleteProjectConfirmationForm: ManuallyCompleteProjectConfirmationForm.toMutation(
        form.values.manuallyCompleteProjectConfirmationForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useManuallyCompleteProjectConfirmationMutation.mutation = gql`
  mutation useManuallyCompleteProjectConfirmationMutation($manuallyCompleteProjectConfirmationForm: ManuallyCompleteProjectConfirmationForm!) {
    response: manuallyCompleteProjectConfirmation(manuallyCompleteProjectConfirmationForm: $manuallyCompleteProjectConfirmationForm) {
      ${gql.errors}
      confirmation {
        id
      }
    }
  }
`;

export default useManuallyCompleteProjectConfirmationMutation;
