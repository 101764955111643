// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import BillItemTypeForm from '@shared/modules/Billing/forms/BillItemTypeForm';

const useUpdateBillItemTypeMutation = ({billItemTypeForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      billItemTypeForm: BillItemTypeForm.toForm(billItemTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateBillItemTypeMutation.mutation,
    variables: {
      billItemTypeForm: BillItemTypeForm.toMutation(form.values.billItemTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateBillItemTypeMutation.mutation = gql`
  mutation useUpdateBillItemTypeMutation($billItemTypeForm: BillItemTypeForm!) {
    response: updateBillItemType(billItemTypeForm: $billItemTypeForm) {
      ${gql.errors}
      billItemType {
        id
      }
    }
  }
`;

export default useUpdateBillItemTypeMutation;
