// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CompensationReportForm from '@shared/modules/Accounting/forms/CompensationReportForm';

const useUpdateCompensationReportMutation = ({compensationReportForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      compensationReportForm: CompensationReportForm.toForm(compensationReportForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCompensationReportMutation.mutation,
    variables: {
      compensationReportForm: CompensationReportForm.toMutation(form.values.compensationReportForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateCompensationReportMutation.mutation = gql`
  ${CompensationReportForm.edit.fragment}
  mutation useUpdateCompensationReportMutation($compensationReportForm: CompensationReportForm!) {
    response: updateCompensationReport(compensationReportForm: $compensationReportForm) {
      ${gql.errors}
      compensationReport {
        id
        ...CompensationReportForm_edit
      }
    }
  }
`;

export default useUpdateCompensationReportMutation;
