// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import KillBatchForm from '@shared/modules/Integration/forms/KillBatchForm';

const useKillBatchMutation = ({killBatchForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      killBatchForm: KillBatchForm.toForm(killBatchForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useKillBatchMutation.mutation,
    variables: {
      killBatchForm: KillBatchForm.toMutation(form.values.killBatchForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useKillBatchMutation.mutation = gql`
  mutation useKillBatchMutation($killBatchForm: KillBatchForm!) {
    response: killBatch(killBatchForm: $killBatchForm) {
      ${gql.errors}
    }
  }
`;

export default useKillBatchMutation;
