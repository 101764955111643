// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ProjectFollowUpDateForm from '@shared/modules/Project/forms/ProjectFollowUpDateForm';

const useUpdateProjectFollowUpDateMutation = ({projectFollowUpDateForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      projectFollowUpDateForm: ProjectFollowUpDateForm.toForm(projectFollowUpDateForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectFollowUpDateMutation.mutation,
    variables: {
      projectFollowUpDateForm: ProjectFollowUpDateForm.toMutation(
        form.values.projectFollowUpDateForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateProjectFollowUpDateMutation.mutation = gql`
  mutation useUpdateProjectFollowUpDateMutation($projectFollowUpDateForm: ProjectFollowUpDateForm!) {
    response: updateProjectFollowUpDate(projectFollowUpDateForm: $projectFollowUpDateForm) {
      ${gql.errors}
      project {
        id
        followUpDate
      }
    }
  }
`;

export default useUpdateProjectFollowUpDateMutation;
