// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import TeamMemberForm from '@shared/modules/TaskManagement/forms/TeamMemberForm';

const _new = ({organizationId, teamKind}) => ({
  teamId: null,
  organizationId,
  name: '',
  kind: teamKind,
  teamMemberForms: [],

  // Private
  teamMemberUserIds: [],
});

const _delete = ({teamId}) => ({
  ..._new({}),
  teamId,
});

const edit = withFragment(
  (team) => ({
    teamId: team.id,
    organizationId: team.organizationId,
    name: team.name,
    kind: team.kind,
    teamMemberForms: team.teamMembers.map((teamMember) => TeamMemberForm.edit(teamMember)),

    // Private
    teamMemberUserIds: team.teamMembers.map((teamMember) => teamMember.userId),
  }),
  gql`
    ${TeamMemberForm.edit.fragment}
    fragment TeamForm_edit on Team {
      id
      organizationId
      name
      kind
      teamMembers {
        id
        userId
        ...TeamMemberForm_edit
      }
    }
  `,
);

const toForm = ({teamId, organizationId, name, kind, teamMemberForms, teamMemberUserIds}) => ({
  teamId,
  organizationId,
  name,
  kind,
  teamMemberForms: teamMemberForms.map((teamMemberForm) => TeamMemberForm.toForm(teamMemberForm)),

  // Private
  teamMemberUserIds: teamMemberUserIds.map((id) => _.toString(id)),
});

const toMutation = ({teamId, organizationId, name, kind, teamMemberForms}) => {
  return {
    teamId,
    organizationId,
    name,
    kind,
    teamMemberForms: teamMemberForms.map((teamMemberForm) =>
      TeamMemberForm.toMutation(teamMemberForm),
    ),
  };
};

const TaskForm = {
  new: _new,
  delete: _delete,
  edit,
  toForm,
  toMutation,
};

export default TaskForm;
