// Libraries
import React from 'react';

// Supermove
import {Styled, Space, Icon, Popover, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, usePopover, useState, useMountEffect} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime, URL, pluralize} from '@supermove/utils';

// App
import DayForm from '@shared/modules/Scheduling/forms/DayForm';
import useUpsertDayMutation from '@shared/modules/Scheduling/hooks/useUpsertDayMutation';
import AddDayNotesPopover from 'modules/App/Day/components/AddDayNotesPopover';
import Line from 'modules/App/components/Line';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import CapacityCalendarDayPickerBlock from 'modules/Calendar/Capacity/components/CapacityCalendarDayPickerBlock';
import CapacityCalendarDaySlotMetrics from 'modules/Calendar/Capacity/components/CapacityCalendarDaySlotMetrics';
import CapacityCalendarJobsListTable from 'modules/Calendar/Capacity/components/CapacityCalendarJobsListTable';
import CapacityCalendarToolbarHeader from 'modules/Calendar/Capacity/components/CapacityCalendarToolbarHeader';

const PageContainer = Styled.View`
    flex: 1;
    background-color: ${colors.gray.background};
`;

// TODO(shopkins): Make this a more generic component in the design system
const Skeleton = Styled.View`
  height: ${({height}) => height};
  width: ${({width}) => width};
  background-color: ${colors.gray.border};
  border-radius: 5px;
`;

const PageContentContainer = Styled.View`
    flex: 1;
    flex-direction: row;
    background-color: ${colors.gray.background};
`;

const PageContentContainerLeftPanel = Styled.View`
    width: 321px;
`;

const Row = Styled.View`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const DayNotesRow = Styled.View`
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const CapacityCalendarDayPickerBlockContainer = Styled.View`
  min-height: 294px;
  width: 100%;
  background-color: ${colors.white};
`;

const PageContentContainerRightPanel = Styled.View`
    border-left-width: 1px;
    border-style: solid;
    border-color: ${colors.gray.border};
    flex: 1;
    padding-horizontal: 16px;
`;

const DayDetailsContainer = Styled.View`
    flex: 1;
    padding: 20px;
`;

const DayDetailsMetricsContainer = Styled.View`
    height: 150px;
    border-radius: 4px;
    border: 1px solid ${colors.gray.border};
    background-color: ${colors.gray.background};
`;

const NotesContainer = Styled.View`
  flex: 1;
`;

const DayNotesSectionContainer = Styled.Touchable`
  flex: 1;
`;

const NotesContentContainer = Styled.View`
  flex: 1;
  padding: 12px;
  border-radius: 4px;
  flex-basis: auto;
  border: 1px solid ${colors.gray.border};
  background-color: ${colors.gray.background};
`;

const DayText = Styled.Text`
    ${Typography.Subheading}
    color: ${colors.gray.primary};
`;

const DayNotesText = Styled.Text`
    ${Typography.Body}
`;

const DayAddNotesText = Styled.Text`
    ${Typography.Body}
    color: ${colors.gray.tertiary};
`;

const DayNotesHeading = Styled.Text`
    ${Typography.MicroLabel}
    color: ${colors.gray.secondary};
`;

const MicroGrayBody = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.tertiary};
`;

const IconButton = Styled.ButtonV2`
  width: 16px;
  height: 16px;
  align-items: center;
`;

const DayNotesTextButton = Styled.Text`
    ${Typography.Link}
`;

const SubheadingText = Styled.Text`
  ${Typography.Subheading}
`;

const getFilteredUrlFromParams = (params, baseUrl) => {
  return URL.getUrlFromVariables(baseUrl, params);
};

const handleUpdateParam = ({baseUrl, navigator, params, paramKey, paramValue}) => {
  navigator.push(getFilteredUrlFromParams({...params, [paramKey]: paramValue || ''}, baseUrl));
};

// TODO(Kevin): Remove this when we turn ff fully on
const DayNotesSectionV1 = ({
  day,
  addDayNotesPopover,
  organizationId,
  selectedDate,
  refetchDayView,
}) => {
  const dayForm = DayForm.upsert(day, {organizationId, value: selectedDate});
  const {form, handleSubmit, submitting} = useUpsertDayMutation({
    dayForm,
    onSuccess: () => {
      addDayNotesPopover.handleClose();
      refetchDayView();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <React.Fragment>
      {day && day.notes ? (
        <DayNotesText numberOfLines={3}>{day.notes}</DayNotesText>
      ) : (
        <DayNotesTextButton onPress={addDayNotesPopover.handleOpen}>
          Add day notes
        </DayNotesTextButton>
      )}
      <AddDayNotesPopover
        key={addDayNotesPopover.isOpen}
        popover={addDayNotesPopover}
        placement={'right-end'}
        width={400}
        form={form}
        handleSubmit={handleSubmit}
        submitting={submitting}
        organization={day ? day.organization : null}
      />
    </React.Fragment>
  );
};

const DayNotesSection = ({
  index,
  day,
  organization,
  viewingOrganization,
  selectedDate,
  refetchDayView,
}) => {
  const organizationId = organization.id;
  const addDayNotesPopover = usePopover();
  const dayForm = DayForm.upsert(day, {organizationId, value: selectedDate});
  const {form, handleSubmit, submitting} = useUpsertDayMutation({
    dayForm,
    onSuccess: () => {
      addDayNotesPopover.handleClose();
      refetchDayView();
    },
    onError: (errors) => console.log({errors}),
  });
  const isBlockEditForMainBranch = !viewingOrganization.isPrimary && organization.isPrimary;

  return (
    <Popover.Content innerRef={addDayNotesPopover.ref}>
      <DayNotesSectionContainer
        disabled={isBlockEditForMainBranch}
        onPress={addDayNotesPopover.handleOpen}
      >
        <DayNotesRow>
          <Icon source={Icon.StickyNote} color={colors.gray.secondary} size={12} />
          <Space width={4} />
          <DayNotesHeading>{organization.name}</DayNotesHeading>
          <Space style={{flex: 1}} />
          {!isBlockEditForMainBranch && (
            <Icon source={Icon.Pen} color={colors.blue.interactive} size={12} />
          )}
        </DayNotesRow>
        <Space height={4} />
        {day && day.notes ? (
          <DayNotesText>{day.notes}</DayNotesText>
        ) : (
          <DayAddNotesText>Click to add day notes.</DayAddNotesText>
        )}
        <Space height={4} />
        {day && (
          <MicroGrayBody>{`Last updated: ${Datetime.convertToDisplayDatetime(day.updatedAt)}${
            day.notesUpdatedBy ? ` by ${day.notesUpdatedBy.fullName}` : ''
          }`}</MicroGrayBody>
        )}
        <AddDayNotesPopover
          key={`${addDayNotesPopover.isOpen}_${index}`}
          popover={addDayNotesPopover}
          width={400}
          form={form}
          handleSubmit={handleSubmit}
          submitting={submitting}
          isClosableOutside
          offset={[270, 8]}
          organization={organization}
          isMultibranchDayView
        />
      </DayNotesSectionContainer>
    </Popover.Content>
  );
};

const CapacityCalendarDayView = ({organization}) => {
  const {params, navigator} = useNavigationDOM();
  const {location} = navigator;
  const addDayNotesPopover = usePopover();
  const [isJobsListTableExpanded, setIsJobsListTableExpanded] = useState(false);
  const [totalJobsForDay, setTotalJobsForDay] = useState(null);
  const [totalTrainingJobsForDay, setTotalTrainingJobsForDay] = useState(null);

  const [selectedDate, setSelectedDate] = useState(
    params.date || Datetime.toMutationDate(Datetime.today),
  );

  useMountEffect(() => {
    // If required params are not set in the route on mount,
    // set the correct variables
    if (!params.slugs) {
      handleUpdateParam({
        baseUrl: location.pathname,
        navigator,
        params,
        paramKey: 'slugs',
        paramValue: organization.isPrimary ? ['ALL_ORGANIZATIONS'] : [organization.slug],
      });
    }
  });

  const {
    data,
    loading,
    refetch: refetchDayView,
  } = useQuery(CapacityCalendarDayView.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      day: selectedDate,
      slugs: params.slugs
        ? params.slugs
        : organization.isPrimary
          ? ['ALL_ORGANIZATIONS']
          : [organization.slug],
    },
  });

  return (
    <PageContainer>
      <PageContentContainer>
        <ScrollView style={{maxWidth: 321, paddingBottom: 48, backgroundColor: colors.white}}>
          <PageContentContainerLeftPanel>
            <CapacityCalendarDayPickerBlockContainer>
              <CapacityCalendarDayPickerBlock
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                organization={organization}
              />
            </CapacityCalendarDayPickerBlockContainer>
            <Line />
            <DayDetailsContainer>
              <Row>
                <DayText>{Datetime.toDisplayDateWithWeekdayName(new Date(selectedDate))}</DayText>
              </Row>
              <Space height={16} />
              <DayDetailsMetricsContainer>
                {loading ? (
                  <CapacityCalendarDaySlotMetrics.SkeletonLoader />
                ) : (
                  <CapacityCalendarDaySlotMetrics
                    organization={data?.viewer?.viewingOrganization}
                    capacityCalendar={data.capacityCalendarSingleDay}
                    cellDate={selectedDate}
                    shouldHideDayNotes
                  />
                )}
              </DayDetailsMetricsContainer>
              <Space height={16} />
              <Row>
                {loading || !params.slugs ? (
                  <NotesContentContainer>
                    <Skeleton height={'15px'} width={'100%'} />
                  </NotesContentContainer>
                ) : (
                  <NotesContainer>
                    {data?.viewer?.viewingOrganization?.features?.isEnabledDayNotesMultibranch ? (
                      <React.Fragment>
                        {
                          // We need to loop through each org instead of days because a day object is not always created
                          // For Franchise/Branch orgs, we will filter it out
                          Organization.getOrganizationBySlugsWithParent({
                            slugs: params.slugs,
                            organization: data.viewer.viewingOrganization,
                          }).map((organization, index) => {
                            const organizationDay = data.days.find(
                              (day) => day.organization.id === organization.id,
                            );
                            return (
                              <React.Fragment key={index}>
                                <NotesContentContainer>
                                  <DayNotesSection
                                    index={index}
                                    refetchDayView={refetchDayView}
                                    day={organizationDay}
                                    organization={organization}
                                    viewingOrganization={data.viewer.viewingOrganization}
                                    selectedDate={selectedDate}
                                  />
                                  <Space width={8} />
                                </NotesContentContainer>
                                <Space height={12} />
                              </React.Fragment>
                            );
                          })
                        }
                      </React.Fragment>
                    ) : (
                      <Popover.Content innerRef={addDayNotesPopover.ref}>
                        <NotesContentContainer>
                          <Row>
                            <DayNotesSectionV1
                              refetchDayView={refetchDayView}
                              day={data.day}
                              addDayNotesPopover={addDayNotesPopover}
                              organizationId={data.viewer.viewingOrganization.id}
                              selectedDate={selectedDate}
                            />
                            <Space width={8} />
                            <IconButton onPress={addDayNotesPopover.handleOpen}>
                              <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
                            </IconButton>
                          </Row>
                        </NotesContentContainer>
                      </Popover.Content>
                    )}
                  </NotesContainer>
                )}
              </Row>
              <Space height={48} />
            </DayDetailsContainer>
          </PageContentContainerLeftPanel>
        </ScrollView>
        <PageContentContainerRightPanel>
          <CapacityCalendarToolbarHeader
            isDayView
            organization={organization}
            canViewOtherBranchesData={organization.canViewOtherBranchesData}
            isJobsListTableExpanded={isJobsListTableExpanded}
            setIsJobsListTableExpanded={setIsJobsListTableExpanded}
            totalJobsForDay={totalJobsForDay}
          />
          <ScrollView style={{flex: 1}}>
            <CapacityCalendarJobsListTable
              selectedDate={selectedDate}
              organization={organization}
              isJobsListTableExpanded={isJobsListTableExpanded}
              refetchDayView={refetchDayView}
              setTotalJobsForDay={setTotalJobsForDay}
            />
            <Space height={16} />
            {organization.features.isEnabledTrainingJobCapacityCalendar && (
              <React.Fragment>
                {totalTrainingJobsForDay !== null ? (
                  <DayNotesRow>
                    <Space width={16} />
                    <SubheadingText>
                      {pluralize('Training Job', totalTrainingJobsForDay || 0, true)}
                    </SubheadingText>
                  </DayNotesRow>
                ) : (
                  <SkeletonLoader width={50} height={20} />
                )}
                <Space height={16} />
                <CapacityCalendarJobsListTable
                  isTrainingOnly
                  selectedDate={selectedDate}
                  organization={organization}
                  isJobsListTableExpanded={isJobsListTableExpanded}
                  refetchDayView={refetchDayView}
                  setTotalJobsForDay={setTotalTrainingJobsForDay}
                />
              </React.Fragment>
            )}
            <Space height={24} />
          </ScrollView>
        </PageContentContainerRightPanel>
      </PageContentContainer>
    </PageContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityCalendarDayView.query = gql`
  ${CapacityCalendarDaySlotMetrics.fragment}
  ${DayForm.edit.fragment}
  ${AddDayNotesPopover.fragment}
  ${Organization.getOrganizationBySlugsWithParent.fragment}

  query CapacityCalendarDayView($day: String!, $slugs: [String!]!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        isPrimary
        company {
          id
          organizations {
            id
            name
            slug
            isPrimary
            ...AddDayNotesPopover
          }
        }
        features {
          isEnabledDayNotesMultibranch: isEnabled(feature: "DAY_NOTES_MULTIBRANCH")
        }
        ...Organization_getOrganizationBySlugsWithParent
      }
      ...CapacityCalendarDaySlotMetrics_Viewer
    }
    capacityCalendarSingleDay(day: $day, slugs: $slugs) {
      ...CapacityCalendarDaySlotMetrics 
	  }
    day(date: $day) {
      id
      organization {
        id
        ...AddDayNotesPopover
      }
      ...DayForm_edit
    }
    days(date: $day, slugs: $slugs) {
      id
      organization {
        id
        ...AddDayNotesPopover
      }
      updatedAt
      notesUpdatedBy {
        id
        fullName
      }
      ...DayForm_edit
    }
  }
`;

CapacityCalendarDayView.fragment = gql`
  ${CapacityCalendarToolbarHeader.fragment}

  fragment CapacityCalendarDayView on Organization {
    id
    slug
    isPrimary
    hasMultipleOrganizations
    canViewOtherBranchesData
    features {
      isEnabledTrainingJobCapacityCalendar: isEnabled(feature: "TRAINING_JOB_CAPACITY_CALENDAR")
    }
    ...CapacityCalendarToolbarHeader
  }
`;

export default CapacityCalendarDayView;
