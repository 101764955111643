// TODO(dan) Remove once shared useBeginPaymentMutation is fully implemented

// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const BeginPaymentMutation = ({
  billId,
  customerId,
  name,
  description,
  partialAmount,
  paymentMethod,
  onSuccess,
  onError,
  children,
}) => (
  <Mutation
    variables={{
      billId,
      customerId,
      name,
      description,
      partialAmount,
      paymentMethod,
    }}
    mutation={BeginPaymentMutation.mutation}
    onCompleted={({response: {error, errors, payment}}) => {
      if (!error && !errors) {
        onSuccess(payment);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading, error}) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// D  ata
// --------------------------------------------------
BeginPaymentMutation.mutation = gql`
  mutation BeginPaymentMutation(
    $billId: Int!,
    $customerId: Int!,
    $name: String!,
    $description: String!,
    $partialAmount: Int,
    $paymentMethod: String!,
  ) {
    response: beginPayment(
      billId: $billId,
      customerId: $customerId,
      name: $name,
      description: $description,
      partialAmount: $partialAmount,
      paymentMethod: $paymentMethod,
    ) {
      ${gql.errors}
      error
      payment {
        id
        stripePaymentClientSecret
        bill {
          id
          total
          customer {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export default BeginPaymentMutation;
