// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ProjectTypeForm from '@shared/modules/Project/forms/ProjectTypeForm';

const useUpdateProjectTypeMutation = ({projectTypeForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      projectTypeForm: ProjectTypeForm.toForm(projectTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectTypeMutation.mutation,
    variables: {
      projectTypeForm: ProjectTypeForm.toMutation(form.values.projectTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateProjectTypeMutation.mutation = gql`
  mutation useUpdateProjectTypeMutation($projectTypeForm: ProjectTypeForm!) {
    response: updateProjectType(projectTypeForm: $projectTypeForm) {
      ${gql.errors}
      projectType {
        id
      }
    }
  }
`;

export default useUpdateProjectTypeMutation;
