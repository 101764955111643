// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import OrganizationInventorySettingsForm from '@shared/modules/Inventory/forms/OrganizationInventorySettingsForm';

// App

const useUpdateOrganizationInventorySettingsMutation = ({
  organizationInventorySettingsForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      organizationInventorySettingsForm: OrganizationInventorySettingsForm.toForm(
        organizationInventorySettingsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationInventorySettingsMutation.mutation,
    variables: {
      organizationInventorySettingsForm: OrganizationInventorySettingsForm.toMutation(
        form.values.organizationInventorySettingsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationInventorySettingsMutation.mutation = gql`
  mutation useUpdateOrganizationInventorySettingsMutation(
    $organizationInventorySettingsForm: OrganizationInventorySettingsForm!,
  ) {
    response: updateOrganizationInventorySettings(
      organizationInventorySettingsForm: $organizationInventorySettingsForm,
    ) {
      ${gql.errors}
      organization {
        id
      }
    }
  }
`;

export default useUpdateOrganizationInventorySettingsMutation;
