// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Styled, Space, DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, useModal, useToast, useState, useForm} from '@supermove/hooks';
import {Workflow, Project} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import Drawer from '@shared/design/components/Drawer';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import SheetWithFooter from '@shared/design/components/Sheet/SheetWithFooter';
import Table from '@shared/design/components/Table';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ManuallyTriggerWorkflowForm from '@shared/modules/Workflow/forms/ManuallyTriggerWorkflowForm';
import WorkflowForm from '@shared/modules/Workflow/forms/WorkflowForm';
import useManuallyTriggerWorkflowMutation from '@shared/modules/Workflow/hooks/useManuallyTriggerWorkflowMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import WorkflowBuilder from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowBuilder';

const DrawerContainer = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const WorkflowDrawerContainer = Styled.View`
  width: 448px;
  background-color: ${colors.white};
`;

const WorkflowPreviewContainer = Styled.View`
  width: 500px;
  background-color: ${colors.gray.background};
  border-right-width: 1px;
  border-right-color: ${colors.gray.border};
`;

const TableHeaderText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const TableBodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const WorkflowPreviewTitle = Styled.Text`
  ${Typography.Subheading}
`;

const WorkflowPreviewDescription = Styled.Text`
  ${Typography.Body}
`;

const getColumnDefinitions = ({responsive}) => [
  {
    flex: 1,
    headerContent: () => {
      return <TableHeaderText responsive={responsive}>Automation Name</TableHeaderText>;
    },
    cellContent: ({item: workflow}) => {
      return <TableBodyText responsive={responsive}>{workflow.name}</TableBodyText>;
    },
  },
];

const ManuallyRunWorkflowDrawerContent = ({workflows, responsive, form, setPreviewingWorkflow}) => {
  return (
    <Table
      columnDefinitions={getColumnDefinitions({
        responsive,
      })}
      emptyStateText={'No workflows'}
      items={workflows}
      itemKey={'id'}
      onRowPress={(workflow) => {
        form.setFieldValue('manuallyTriggerWorkflowForm.workflowId', workflow.id);
        setPreviewingWorkflow(workflow);
      }}
      activeRowIndex={_.findIndex(workflows, {
        id: form.values.manuallyTriggerWorkflowForm.workflowId,
      })}
      isClickable
    />
  );
};

const WorkflowPreviewSection = ({workflow}) => {
  const workflowForm = useForm({
    initialValues: {workflowForm: WorkflowForm.edit(workflow)},
  });
  return (
    <React.Fragment>
      <WorkflowPreviewTitle>{workflow.name}</WorkflowPreviewTitle>
      {workflow.description && (
        <WorkflowPreviewDescription>{workflow.description}</WorkflowPreviewDescription>
      )}
      <WorkflowBuilder workflow={workflow} form={workflowForm} isPreview />
    </React.Fragment>
  );
};

const ManuallyRunWorkflowDrawer = ({runWorkflowManuallyDrawer, project, refetch}) => {
  const [previewingWorkflow, setPreviewingWorkflow] = useState(null);
  const successManuallyTriggerWorkflow = useToast({
    ToastComponent: SuccessToast,
    message: `Workflow successfully triggered.`,
    isClosable: true,
  });

  const responsive = useResponsive();
  const confirmTriggerWorkflowModal = useModal({name: 'Confirm Trigger Workflow Modal'});
  const {data, loading} = useQuery(ManuallyRunWorkflowDrawer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid: project?.uuid,
    },
    skip: !runWorkflowManuallyDrawer.isOpen || !project,
  });
  const manuallyTriggerWorkflowForm = ManuallyTriggerWorkflowForm.new({
    projectId: project?.id,
  });
  const {form, submitting, handleSubmit} = useManuallyTriggerWorkflowMutation({
    manuallyTriggerWorkflowForm,
    onSuccess: () => {
      confirmTriggerWorkflowModal.handleClose();
      runWorkflowManuallyDrawer.handleClose();
      successManuallyTriggerWorkflow.handleToast();
      refetch();
    },
    onError: (error) => console.log(error),
  });
  const sortedWorkflows = _.sortBy(data?.project?.organization?.activeWorkflows, 'name');
  const selectedWorkflowId = _.get(form.values, 'manuallyTriggerWorkflowForm.workflowId');
  const selectedWorkflow = _.find(data?.project?.organization?.activeWorkflows, {
    id: selectedWorkflowId,
  });
  const isJobTriggerIdentifier =
    selectedWorkflow && Workflow.getIsJobTriggerIdentifier(selectedWorkflow.triggerIdentifier);

  return (
    <React.Fragment>
      {responsive.mobile ? (
        <SheetWithFooter
          isOpen={runWorkflowManuallyDrawer.isOpen}
          handleClose={runWorkflowManuallyDrawer.handleClose}
          headerText={'Run Automation'}
          primaryActionText={'Run'}
          handlePrimaryAction={confirmTriggerWorkflowModal.handleOpen}
        >
          <Loading loading={loading || !project} as={PageLoadingIndicator}>
            {() => (
              <ManuallyRunWorkflowDrawerContent
                workflows={sortedWorkflows}
                responsive={responsive}
                form={form}
                setPreviewingWorkflow={setPreviewingWorkflow}
              />
            )}
          </Loading>
        </SheetWithFooter>
      ) : (
        <Drawer
          isOpen={runWorkflowManuallyDrawer.isOpen}
          handleClose={runWorkflowManuallyDrawer.handleClose}
        >
          <DrawerContainer>
            {previewingWorkflow && (
              <WorkflowPreviewContainer>
                <Drawer.Header
                  headerText={'Preview Automation'}
                  isClosable
                  handleClose={() => setPreviewingWorkflow(null)}
                />
                <Drawer.Body bodyScrollStyle={{flex: 1}}>
                  <WorkflowPreviewSection
                    workflow={previewingWorkflow}
                    key={previewingWorkflow.id}
                  />
                </Drawer.Body>
              </WorkflowPreviewContainer>
            )}
            <WorkflowDrawerContainer>
              <Drawer.Header
                headerText={'Run Automation'}
                isClosable
                handleClose={runWorkflowManuallyDrawer.handleClose}
              />
              <Drawer.Body bodyScrollStyle={{flex: 1}}>
                <Loading loading={loading || !project} as={PageLoadingIndicator}>
                  {() => (
                    <ManuallyRunWorkflowDrawerContent
                      workflows={sortedWorkflows}
                      responsive={responsive}
                      form={form}
                      setPreviewingWorkflow={setPreviewingWorkflow}
                    />
                  )}
                </Loading>
              </Drawer.Body>
              <Drawer.Footer
                isDisabled={submitting}
                isSubmitting={submitting}
                primaryAction={confirmTriggerWorkflowModal.handleOpen}
                primaryActionText={'Run'}
                secondaryAction={runWorkflowManuallyDrawer.handleClose}
              />
            </WorkflowDrawerContainer>
          </DrawerContainer>
        </Drawer>
      )}
      <ActionModal
        icon={Icon.Bolt}
        title={`Run "${selectedWorkflow?.name}" automation?`}
        message={isJobTriggerIdentifier && 'Please select a job to run this automation for.'}
        isOpen={confirmTriggerWorkflowModal.isOpen}
        handlePressOutside={confirmTriggerWorkflowModal.handleClose}
        handlePrimaryAction={handleSubmit}
        handleSecondaryAction={() => {
          confirmTriggerWorkflowModal.handleClose();
          form.setFieldValue('manuallyTriggerWorkflowForm.workflowId', null);
          form.setFieldValue('manuallyTriggerWorkflowForm.jobId', null);
        }}
        primaryActionText={'Run'}
        secondaryActionText={'Cancel'}
        isSubmitting={submitting}
        isDisabled={
          isJobTriggerIdentifier && !_.get(form.values, 'manuallyTriggerWorkflowForm.jobId')
        }
      >
        {isJobTriggerIdentifier && (
          <React.Fragment>
            <Space height={16} />
            <FieldInput
              {...form}
              name={`manuallyTriggerWorkflowForm.jobId`}
              isRequired
              component={DropdownInput}
              input={{
                isPortaled: true,
                options: Project.getActiveJobsDropdownOptions(project),
                isClearable: true,
                placeholder: 'Select job',
                setFieldValue: form.setFieldValue,
                style: {
                  flex: 1,
                },
              }}
              style={{
                width: '80%',
              }}
            />
            <Space height={16} />
          </React.Fragment>
        )}
      </ActionModal>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ManuallyRunWorkflowDrawer.query = gql`
    ${Workflow.getIsJobTriggerIdentifier.fragment}
    ${WorkflowBuilder.fragment}
    ${WorkflowForm.edit.fragment}

    query ManuallyRunWorkflowDrawer($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
        activeJobs {
          id
          uuid
          identifier
          jobType {
            id
            name
          }
        }
        organization {
        id
        activeWorkflows {
          id
          name
          description
          ...Workflow_getIsJobTriggerIdentifier
          ...WorkflowBuilder
          ...WorkflowForm_edit
        }
    }
    }
    }
`;

ManuallyRunWorkflowDrawer.fragment = gql`
  ${Project.getActiveJobsDropdownOptions.fragment}
  fragment ManuallyRunWorkflowDrawer on Project {
    id
    uuid
    organizationId
    ...Project_getActiveJobsDropdownOptions
  }
`;

export default ManuallyRunWorkflowDrawer;
