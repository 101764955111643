// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import JobDispatchInfoForm from '@shared/modules/Job/forms/JobDispatchInfoForm';

const useUpdateJobDispatchInfoMutation = ({jobDispatchInfoForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      jobDispatchInfoForm: JobDispatchInfoForm.toForm(jobDispatchInfoForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateJobDispatchInfoMutation.mutation,
    variables: {
      jobDispatchInfoForm: JobDispatchInfoForm.toMutation(form.values.jobDispatchInfoForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateJobDispatchInfoMutation.mutation = gql`
  mutation useUpdateJobDispatchInfoMutation($jobDispatchInfoForm: JobDispatchInfoForm!) {
    response: updateJobDispatchInfo(jobDispatchInfoForm: $jobDispatchInfoForm) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default useUpdateJobDispatchInfoMutation;
