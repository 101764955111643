// Supermove
import {Datetime} from '@supermove/utils';

const _new = ({projectId, containerIds, inDate}) => ({
  projectId,
  containerIds,
  inDate: inDate || null,
});

const toForm = ({projectId, containerIds, inDate}) => ({
  projectId,
  containerIds,
  inDate: Datetime.toFormDate(inDate),
});

const toMutation = ({projectId, containerIds, inDate}) => {
  return {
    projectId,
    containerIds,
    inDate: inDate ? Datetime.toMutationDate(inDate) : null,
  };
};

const UpdateContainerInDatesForProjectForm = {
  new: _new,
  toForm,
  toMutation,
};

export default UpdateContainerInDatesForProjectForm;
