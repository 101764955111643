// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (projectTypeValuationCoverage) => ({
    projectTypeId: projectTypeValuationCoverage.projectTypeId,
    valuationCoverageDataTableId: projectTypeValuationCoverage.valuationCoverageDataTableId,
  }),
  gql`
    fragment ProjectTypeValuationCoverageForm_edit on ProjectTypeValuationCoverage {
      valuationCoverageDataTableId
      projectTypeId
    }
  `,
);

const toForm = ({valuationCoverageDataTableId, projectTypeId}) => ({
  valuationCoverageDataTableId,
  projectTypeId,
});

const toMutation = ({valuationCoverageDataTableId, projectTypeId}) => ({
  valuationCoverageDataTableId,
  projectTypeId,
});

const ProjectTypeValuationCoverageForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectTypeValuationCoverageForm;
