// Libraries
import PropTypes from 'prop-types';
import React from 'react';
import {CardElement} from 'react-stripe-elements';

// Assets
import './Stripe.css';

const StripeCardInput = ({
  isDisabled,
  onChange, // From Stripe: (event) => {} where event contains validation inforamtion.
  onReady, // From Stripe: (element) => {} where element is the underlying card element node.
}) => (
  <CardElement
    className={'stripe-card-element'}
    disabled={isDisabled}
    onChange={onChange}
    onReady={onReady}
    style={{
      /* stylelint-disable selector-type-no-unknown, unit-allowed-list */
      base: {
        'fontSize': '16px',
        'color': '#424770',
        'letterSpacing': '0.025em',
        'fontFamily': 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    }}
  />
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
StripeCardInput.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onReady: PropTypes.func,
};

StripeCardInput.defaultProps = {
  isDisabled: false,
  onChange: () => {},
  onReady: () => {},
};

export default StripeCardInput;
