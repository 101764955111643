// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const Wrapper = Styled.View`
  margin-bottom: 10px;
  z-index: ${(props) => 100 - props.index};
`;

const SectionBody = Styled.Text`
  margin-top: 5px;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.gray.primary};
`;

const DocumentV2Paragraph = ({paragraph, index}) => {
  return (
    <Wrapper index={index}>
      <SectionBody>{paragraph}</SectionBody>
    </Wrapper>
  );
};

export default DocumentV2Paragraph;
