// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import DataTableForm from '@shared/modules/Billing/forms/DataTableForm';

const useUploadDataTableMutation = ({dataTableForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      dataTableForm: DataTableForm.toForm(dataTableForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUploadDataTableMutation.mutation,
    variables: {
      dataTableForm: DataTableForm.toMutation(form.values.dataTableForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUploadDataTableMutation.mutation = gql`
  mutation useUploadDataTableMutation($dataTableForm: DataTableForm!) {
    response: uploadDataTable(dataTableForm: $dataTableForm) {
      ${gql.errors}
      dataTable {
        id
      }
    }
  }
`;

export default useUploadDataTableMutation;
