// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import ReorderBillsForm from '@shared/modules/Billing/forms/ReorderBillsForm';
import useReorderBillsMutation from '@shared/modules/Billing/hooks/useReorderBillsMutation';
import ProjectBillTipsListV1 from 'modules/Project/Billing/BillTip/components/ProjectBillTipsListV1';
import BillsListItemV1 from 'modules/Project/Billing/components/BillsListItemV1';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const FlexContainer = Styled.View`
  align-items: flex-end;
  justify-content: center;
  flex: ${(props) => props.flex};
`;

const LabelContainer = Styled.View`
  border-top-width: ${({vars}) => (vars.isEditable ? '1px' : '0px')};
  border-bottom-width: ${({vars}) => (vars.isEditable ? '1px' : '0px')};
  border-left-width: ${({vars}) => (vars.isEditable ? '1px' : '0px')};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-color: ${colors.gray.border};
  padding-left: ${(props) => (props.isMobile ? '0px' : '12px')};
  flex-direction: row;
  justify-content: ${(props) => props.justify};
  align-items: center;
`;

const BoldLabelText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(900)}
  padding-vertical: ${({vars}) => (vars.isEditable ? '12px' : '0px')};
`;

const AmountContainer = Styled.View`
  justify-content: center;
  align-items: flex-end;
  padding-vertical: ${({vars}) => (vars.isEditable ? '12px' : '0px')};
  border-top-width: ${({vars}) => (vars.isEditable ? '1px' : '0px')};
  border-bottom-width: ${({vars}) => (vars.isEditable ? '1px' : '0px')};
  flex: ${(props) => props.flex};
  border-color: ${colors.gray.border};
`;

const BoldAmountText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(900)}
`;

const RightSideBorder = Styled.View`
  justify-content: center;
  align-items: flex-end;
  padding-vertical: 12px;
  padding-right: 12px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-color: ${colors.gray.border};
`;

const getSortedBills = ({bills, orderedBillForms}) => {
  const sortedBills = [];

  orderedBillForms.forEach((billForm) => {
    const matchingBill = _.find(bills, (bill) => String(bill.id) === String(billForm.billId));
    if (matchingBill) {
      sortedBills.push(matchingBill);
    }
  });

  return sortedBills;
};

const TotalLabel = ({isEditable, isProposal}) => {
  return (
    <LabelContainer vars={{isEditable}} justify={'flex-end'}>
      <BoldLabelText vars={{isEditable}}>
        {isProposal ? 'Estimated Total' : 'Grand Total'}
      </BoldLabelText>
    </LabelContainer>
  );
};

const GrandTotal = ({isEditable, isProposal, isTotalAvailable, minTotal, maxTotal}) => {
  const responsive = useResponsive();

  return (
    <Row style={{backgroundColor: colors.white}}>
      {isEditable && !responsive.mobile && <Space width={13} />}
      {responsive.mobile ? (
        <TotalLabel isEditable={isEditable} isProposal={isProposal} />
      ) : (
        <FlexContainer vars={{isEditable}} flex={8}>
          <TotalLabel isEditable={isEditable} isProposal={isProposal} />
        </FlexContainer>
      )}
      <AmountContainer vars={{isEditable}} flex={3}>
        <BoldAmountText>
          {isTotalAvailable ? Currency.formatRange({min: minTotal, max: maxTotal}) : 'TBD'}
        </BoldAmountText>
      </AmountContainer>
      {isEditable && <RightSideBorder />}
    </Row>
  );
};

const BillingProjectBillsListV1 = ({
  isDocument,
  isEditable,
  isReordering,
  isProposal,
  includeAllBills,
  showEmptyTotals,
  showQuantity,
  showTotal,
  showGrandTotal,
  showBillRules,
  highlightedJobId,
  project,
  refetch,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
}) => {
  const {activeJobsAggregateBill, currentAggregateBill} = project;
  const aggregateBill = includeAllBills ? activeJobsAggregateBill : currentAggregateBill;
  const {isTotalAvailable, minTotal, maxTotal, bills, primaryBill} = aggregateBill;
  const reorderBillsForm = ReorderBillsForm.edit(bills);
  const {form, handleSubmit} = useReorderBillsMutation({
    reorderBillsForm,
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      console.log({error});
    },
  });

  const isGrandTotalVisible =
    (isEditable || showGrandTotal) && (showEmptyTotals || isTotalAvailable);

  return (
    <Container>
      {getSortedBills({bills, orderedBillForms: form.values.reorderBillsForm.billForms}).map(
        (bill, index) => {
          return (
            <React.Fragment key={`${index}_BILL`}>
              <BillsListItemV1
                bill={bill}
                index={index}
                refetch={refetch}
                isDocument={isDocument}
                isEditable={isEditable}
                isReordering={isReordering}
                isHighlighted={
                  !!highlightedJobId && String(bill.jobId) === String(highlightedJobId)
                }
                reorderBillsForm={form}
                handleSubmit={handleSubmit}
                showQuantity={showQuantity}
                showTotal={showTotal}
                showBillRules={showBillRules}
                showEmptyTotals={showEmptyTotals}
                descriptionField={descriptionField}
                getTruncatedKey={getTruncatedKey}
                truncated={truncated}
                setTruncated={setTruncated}
                enabledToggle={enabledToggle}
                setEnabledToggle={setEnabledToggle}
                isEnabledBillItemDescriptionOnMobile={
                  project.organization.features.isEnabledBillItemDescriptionOnMobile
                }
              />
              <Space height={isEditable ? 8 : 12} />
            </React.Fragment>
          );
        },
      )}
      {isGrandTotalVisible && (
        <React.Fragment>
          <Space height={8} />
          {(primaryBill.hasBillTips || isEditable) && (
            <React.Fragment>
              <ProjectBillTipsListV1
                project={project}
                isEditable={isEditable}
                primaryBill={primaryBill}
                refetch={refetch}
              />
              <Space height={8} />
            </React.Fragment>
          )}
          <GrandTotal
            isEditable={isEditable}
            isProposal={isProposal}
            isTotalAvailable={isTotalAvailable}
            minTotal={minTotal}
            maxTotal={maxTotal}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
BillingProjectBillsListV1.propTypes = {
  showEmptyTotals: PropTypes.bool,
  showQuantity: PropTypes.bool,
  showTotal: PropTypes.bool,
  showGrandTotal: PropTypes.bool,
  includeAllBills: PropTypes.bool,
  isProposal: PropTypes.bool,
  descriptionField: PropTypes.string,
  getTruncatedKey: PropTypes.func,
  truncated: PropTypes.object,
  setTruncated: PropTypes.func,
  enabledToggle: PropTypes.object,
  setEnabledToggle: PropTypes.func,
};

BillingProjectBillsListV1.defaultProps = {
  showEmptyTotals: true,
  showQuantity: true,
  showTotal: true,
  showGrandTotal: true,
  includeAllBills: true,
  isProposal: false,
  descriptionField: undefined,
  getTruncatedKey: undefined,
  truncated: undefined,
  setTruncated: undefined,
  enabledToggle: undefined,
  setEnabledToggle: undefined,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingProjectBillsListV1.fragment = gql`
  ${BillsListItemV1.fragment}
  ${ProjectBillTipsListV1.fragment}
  ${ReorderBillsForm.edit.fragment}

  fragment BillingProjectBillsListV1_AggregateBill on AggregateBill {
    minTotal
    maxTotal
    isTotalAvailable
    primaryBill {
      id
      tip
      total
      hasBillTips
      ...ProjectBillTipsListV1
    }
    bills {
      id
      ...BillsListItemV1
      ...ReorderBillsForm_edit
    }
  }

  fragment BillingProjectBillsListV1 on Project {
    id
    organization {
      id
      features {
        isEnabledBillItemDescriptionOnMobile: isEnabled(feature: "BILL_ITEM_DESCRIPTION_ON_MOBILE")
      }
    }
    activeJobsAggregateBill {
      ...BillingProjectBillsListV1_AggregateBill
    }
    currentAggregateBill {
      ...BillingProjectBillsListV1_AggregateBill
    }
    ...ProjectBillTipsListV1_Project
  }
`;

export default BillingProjectBillsListV1;
