// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {BillItemType} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemTypeForm from '@shared/modules/Billing/forms/BillItemTypeForm';
import useUpdateBillItemTypeMutation from '@shared/modules/Billing/hooks/useUpdateBillItemTypeMutation';
import EditBillItemTypeModalFields from 'modules/Organization/Settings/BillingLibraries/components/EditBillItemTypeModalFields';

const getHeaderText = (category) => {
  if (category === BillItemTypeCategory.SUPPLIES) {
    return 'Edit Supply';
  }
  if (category === BillItemTypeCategory.FEES) {
    return 'Edit Fee';
  }
  return 'Edit Discount';
};

const EditBillItemTypeModal = ({billItemType, isOpen, handleClose, refetch, userId}) => {
  const {category} = billItemType;
  const billItemTypeForm = BillItemTypeForm.edit(billItemType, {userId});
  const {form, submitting, handleSubmit} = useUpdateBillItemTypeMutation({
    billItemTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose}>
      <SmallModal.HeaderText>{getHeaderText(category)}</SmallModal.HeaderText>
      <Space height={20} />
      <EditBillItemTypeModalFields form={form} field={'billItemTypeForm'} />
      <Space height={36} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose} isDisabled={submitting}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          onPress={handleSubmit}
          color={colors.blue.interactive}
          isSubmitting={submitting}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillItemTypeModal.fragment = gql`
  ${BillItemType.getDisplayBillStage.fragment}
  ${BillItemType.hasAmountFormula.fragment}
  ${BillItemType.hasNameFormula.fragment}
  ${BillItemTypeForm.edit.fragment}

  fragment EditBillItemTypeModal on BillItemType {
    id
    category
    ...BillItemTypeForm_edit
  }
`;

export default EditBillItemTypeModal;
