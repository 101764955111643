// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import CostForm from '@shared/modules/Billing/forms/CostForm';

const useUpdateCostMutation = ({costForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      costForm: CostForm.toForm(costForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCostMutation.mutation,
    variables: {
      costForm: CostForm.toMutation(form.values.costForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCostMutation.mutation = gql`
  mutation useUpdateCostMutation($costForm: CostForm!) {
    response: updateCost(costForm: $costForm) {
      ${gql.errors}
      cost {
        id
      }
    }
  }
`;

export default useUpdateCostMutation;
