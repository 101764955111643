// Libraries
import React from 'react';

// Supermove
import {Styled, Space, ScrollView, RichTextInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import VariableKind from '@shared/modules/Billing/enums/VariableKind';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import DocumentTemplateCategory from '@shared/modules/Document/enums/DocumentTemplateCategory';
import HardcodedSystemVariables from '@shared/modules/Variable/enums/HardcodedSystemVariables';
import ImageEditor from 'modules/Organization/Settings/Document/components/ImageEditor';
import InputGridEditor from 'modules/Organization/Settings/Document/components/InputGridEditor';
import InputIsRequiredCheckBox from 'modules/Organization/Settings/Document/components/InputIsRequiredCheckBox';
import InputRadioButtonsWithTextInputsEditor from 'modules/Organization/Settings/Document/components/InputRadioButtonsWithTextInputsEditor';
import InputTextAreasInputEditor from 'modules/Organization/Settings/Document/components/InputTextAreasInputEditor';
import InputTextInputEditor from 'modules/Organization/Settings/Document/components/InputTextInputEditor';
import SurveyDocumentCheckbox from 'modules/Organization/Settings/Document/components/SurveyDocumentCheckbox';
import VariableList from 'modules/Variable/components/VariableList';

const HeaderText = Styled.Text`
  ${Typography.Label1};
`;

const TextInput = Styled.TextInput`
  ${Typography.Body3}
  border-width: 1px;
  border-color: ${colors.gray.border};
  width: 100%;
  padding-vertical: 9px;
`;

// RichTextInput has toolbar popovers and dropdowns that need to be above all other surrounding elements
const RichTextContainer = Styled.View`
  z-index: 99999;
`;

const BlankItemInputContainer = Styled.View`
`;

const BlankItemInputContainerText = Styled.Text`
  ${Typography.Body3};
  color: ${colors.gray.tertiary};
`;

const GenerateTextInput = ({
  documentItem,
  setDraftDocumentContentJson,
  documentTemplate,
  documentItemIndex,
  isSmall,
  placeholder,
  organization,
  isRichText,
}) => {
  return (
    <React.Fragment>
      {isRichText ? (
        <RichTextContainer>
          <RichTextInput
            placeholder={placeholder}
            value={documentItem.text}
            setFieldValue={(name, value) => {
              setDraftDocumentContentJson((prevState) => {
                prevState.documentItems[documentItemIndex].text = value;
                return {
                  ...prevState,
                };
              });
            }}
            hasCustomToolbar
            // paddingBottom allows for the link popover to be clickable below the text input
            // it must be "contained" within RichTextInput to be clickable, regardless of z-index
            style={{flex: 1, minHeight: '360px', paddingBottom: 24}}
          />
        </RichTextContainer>
      ) : (
        <React.Fragment>
          <TextInput
            autoFocus
            placeholder={placeholder}
            value={documentItem.text}
            multiline
            onChangeText={(text) => {
              setDraftDocumentContentJson((prevState) => {
                prevState.documentItems[documentItemIndex].text = text;
                return {
                  ...prevState,
                };
              });
            }}
            style={{
              minHeight: isSmall ? '100px' : '300px',
            }}
          />
          <Space height={48} />
        </React.Fragment>
      )}
      <VariableList
        organization={organization}
        hardcodedSystemVariables={HardcodedSystemVariables.getProjectAndFilteredVariablesByKind(
          HardcodedSystemVariables.DOCUMENT_VARIABLES,
          [documentTemplate.category],
        )}
        variableKindsToExclude={
          documentTemplate.category === DocumentTemplateCategory.INVOICE ? [VariableKind.JOB] : []
        }
      />
    </React.Fragment>
  );
};

const generateDefaultTemplateForItemKind = (
  documentItem,
  setDraftDocumentContentJson,
  documentItemIndex,
  documentTemplateVersion,
) => {
  switch (documentItem.itemKind) {
    case DocumentItemKindV2.SECTION_TITLE:
      return (
        <GenerateTextInput
          organization={documentTemplateVersion.organization}
          documentItem={documentItem}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentTemplate={documentTemplateVersion.documentTemplate}
          documentItemIndex={documentItemIndex}
          isSmall
          placeholder={'Enter section title'}
        />
      );
    case DocumentItemKindV2.TEXT_TITLE:
      return (
        <GenerateTextInput
          organization={documentTemplateVersion.organization}
          documentItem={documentItem}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentTemplate={documentTemplateVersion.documentTemplate}
          documentItemIndex={documentItemIndex}
          isSmall
          placeholder={'Enter paragraph title'}
        />
      );
    case DocumentItemKindV2.TEXT_PARAGRAPH:
      return (
        <GenerateTextInput
          organization={documentTemplateVersion.organization}
          documentItem={documentItem}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentTemplate={documentTemplateVersion.documentTemplate}
          documentItemIndex={documentItemIndex}
          placeholder={'Enter paragraph text'}
        />
      );
    case DocumentItemKindV2.TEXT:
      return (
        <GenerateTextInput
          key={documentItemIndex}
          isRichText
          organization={documentTemplateVersion.organization}
          documentItem={documentItem}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentTemplate={documentTemplateVersion.documentTemplate}
          documentItemIndex={documentItemIndex}
          placeholder={'Enter paragraph text'}
        />
      );
    case DocumentItemKindV2.IMAGE:
      return (
        <ImageEditor
          documentItem={documentItem}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentItemIndex={documentItemIndex}
        />
      );
    case DocumentItemKindV2.INPUT_RADIO_BUTTONS:
      return (
        <InputRadioButtonsWithTextInputsEditor
          documentItem={documentItem}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentItemIndex={documentItemIndex}
        />
      );
    case DocumentItemKindV2.INPUT_TEXT_INPUTS:
      return (
        <InputTextInputEditor
          documentItem={documentItem}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentItemIndex={documentItemIndex}
        />
      );
    case DocumentItemKindV2.INPUT_TEXT_AREAS:
      return (
        <InputTextAreasInputEditor
          documentItem={documentItem}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentItemIndex={documentItemIndex}
        />
      );
    case DocumentItemKindV2.INPUT_GRID:
      return (
        <InputGridEditor
          documentItem={documentItem}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentItemIndex={documentItemIndex}
        />
      );
    case DocumentItemKindV2.INPUT_SIGNATURE:
      return (
        <React.Fragment>
          <InputIsRequiredCheckBox
            documentItem={documentItem}
            documentItemIndex={documentItemIndex}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
          />
        </React.Fragment>
      );
    case DocumentItemKindV2.GENERIC_SIGNATURE:
      return (
        <React.Fragment>
          <InputIsRequiredCheckBox
            documentItem={documentItem}
            documentItemIndex={documentItemIndex}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
          />
        </React.Fragment>
      );
    case DocumentItemKindV2.INVENTORY_SUMMARY:
    case DocumentItemKindV2.SURVEY_CARTONS_SUMMARY:
    case DocumentItemKindV2.SURVEY_ITEMS_SUMMARY:
    case DocumentItemKindV2.SURVEY_ROOMS_SUMMARY:
      return (
        <React.Fragment>
          <SurveyDocumentCheckbox
            label={'Show Notes'}
            field={'showNotes'}
            isChecked={documentItem.input?.showNotes || false}
            documentItem={documentItem}
            documentItemIndex={documentItemIndex}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
          />
          <Space height={8} />
          <SurveyDocumentCheckbox
            label={'Show Volume'}
            field={'showVolume'}
            isChecked={documentItem.input?.showVolume !== false}
            documentItem={documentItem}
            documentItemIndex={documentItemIndex}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
          />
          <Space height={8} />
          <SurveyDocumentCheckbox
            label={'Show Weight'}
            field={'showWeight'}
            isChecked={documentItem.input?.showWeight !== false}
            documentItem={documentItem}
            documentItemIndex={documentItemIndex}
            setDraftDocumentContentJson={setDraftDocumentContentJson}
          />
        </React.Fragment>
      );
    default:
      return (
        <BlankItemInputContainer>
          <BlankItemInputContainerText>
            {'This document item does not have any editable values'}
          </BlankItemInputContainerText>
        </BlankItemInputContainer>
      );
  }
};

const DocumentTemplateVersionItemInputEditor = ({
  documentItem,
  documentItemIndex,
  setDraftDocumentContentJson,
  documentTemplateVersion,
}) => {
  return (
    <ScrollView style={{flex: 1}} contentContainerStyle={{padding: 24}}>
      <HeaderText>{DocumentItemKindV2.getItemDisplayName(documentItem.itemKind)}</HeaderText>
      <Space height={12} />
      {generateDefaultTemplateForItemKind(
        documentItem,
        setDraftDocumentContentJson,
        documentItemIndex,
        documentTemplateVersion,
      )}
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentTemplateVersionItemInputEditor.fragment = gql`
  ${VariableList.fragment}
  fragment DocumentTemplateVersionItemInputEditor on DocumentTemplateVersion {
    id
    organization {
      id
      ...VariableList
    }
    documentTemplate {
      id
      category
    }
  }
`;

export default DocumentTemplateVersionItemInputEditor;
