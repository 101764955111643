// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CreateTimesheetPayrollBlocksForm from '@shared/modules/Timesheet/forms/CreateTimesheetPayrollBlocksForm';

const useCreateTimesheetPayrollBlocksMutation = ({
  createTimesheetPayrollBlocksForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      createTimesheetPayrollBlocksForm: CreateTimesheetPayrollBlocksForm.toForm(
        createTimesheetPayrollBlocksForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateTimesheetPayrollBlocksMutation.mutation,
    variables: {
      createTimesheetPayrollBlocksForm: CreateTimesheetPayrollBlocksForm.toMutation(
        form.values.createTimesheetPayrollBlocksForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCreateTimesheetPayrollBlocksMutation.mutation = gql`
  mutation useCreateTimesheetPayrollBlocksMutation($createTimesheetPayrollBlocksForm: CreateTimesheetPayrollBlocksForm!) {
    response: createTimesheetPayrollBlocks(createTimesheetPayrollBlocksForm: $createTimesheetPayrollBlocksForm) {
      ${gql.errors}
      timesheetBlocks {
        id
      }
    }
  }
`;

export default useCreateTimesheetPayrollBlocksMutation;
