// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from 'modules/App/components/TextTooltip';

const Text = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const IconContainer = Styled.View`
`;

const JobInventoryVolume = ({job}) => {
  const {volume} = job.project;

  return (
    <Row>
      <TextTooltip text={'Inventory Volume'}>
        <IconContainer>
          <Icon
            color={colors.gray.primary}
            size={10}
            source={Icon.RulerTriangle}
            style={{marginTop: 2}}
          />
        </IconContainer>
      </TextTooltip>
      <Space width={6} />
      <Text color={colors.gray.secondary}>{volume > 0 ? `${volume} ft³` : 'N/A'}</Text>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobInventoryVolume.fragment = gql`
  fragment JobInventoryVolume on Job {
    id
    project {
      id
      volume
    }
  }
`;

export default JobInventoryVolume;
