// Supermove
import {Currency} from '@supermove/utils';

const _new = ({name, invoice}) => ({
  invoiceId: invoice.id,
  name: name || '',
  amount: invoice.remainingBalance,
  method: '',
  description: '',
});

const toForm = ({invoiceId, name, amount, method, description}) => ({
  invoiceId,
  name,
  amount: Currency.toForm(amount),
  method,
  description,
});

const toMutation = ({invoiceId, name, amount, method, description}) => ({
  invoiceId,
  name,
  amount: Currency.toMutation(amount),
  method,
  description,
});

const RecordPaymentForm = {
  new: _new,
  toForm,
  toMutation,
};

export default RecordPaymentForm;
