// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Task} from '@supermove/models';
import {withFragment, Datetime} from '@supermove/utils';

// Apps
import TaskTemplateAssignedToRole from '@shared/modules/TaskManagement/enums/TaskTemplateAssignedToRole';

const _new = ({
  organizationId,
  projectId,
  taskTemplateId,
  projectUuid,
  projectBookedById,
  projectCoordinatedById,
}) => ({
  taskId: null,
  name: '',
  details: '',
  dueAtDate: '',
  dueAtTime: '',
  isCompleted: false,
  comment: '',

  assignedToUserId: null,
  assignedToTeamId: null,
  organizationId,
  projectId,
  taskTemplateId,

  // Private
  projectsSearchQuery: '',
  projectUuid,
  projectBookedById,
  projectCoordinatedById,
  assigneeDropdownValue: '',
});

const _delete = ({taskId}) => ({
  ..._new({}),
  taskId,
});

const edit = withFragment(
  (task) => ({
    taskId: task.id,
    name: task.name,
    details: task.details,
    dueAtDate: task.dueAt,
    dueAtTime: task.dueAt ? Datetime.fromDatetimeToUtc(task.dueAt) : '',
    isCompleted: task.isCompleted,
    comment: '',

    assignedToUserId: task.assignedToUserId,
    assignedToTeamId: task.assignedToTeamId,
    organizationId: task.organizationId,
    projectId: task.projectId,
    taskTemplateId: task.taskTemplateId,

    // Private
    projectsSearchQuery: '',
    projectUuid: task.project.uuid,
    projectBookedById: task.project.bookedById,
    projectCoordinatedById: task.project.coordinatedById,
    assigneeDropdownValue: Task.getAssigneeDropdownValue(task),
  }),
  gql`
    ${Task.getAssigneeDropdownValue.fragment}
    fragment TaskForm_edit on Task {
      id
      name
      details
      dueAt
      isCompleted
      assignedToUserId
      assignedToTeamId
      organizationId
      projectId
      taskTemplateId
      project {
        id
        uuid
        bookedById
        coordinatedById
      }
      ...Task_getAssigneeDropdownValue
    }
  `,
);

const toForm = ({
  taskId,
  name,
  details,
  dueAtDate,
  dueAtTime,
  isCompleted,
  comment,
  assignedToUserId,
  assignedToTeamId,
  organizationId,
  projectId,
  taskTemplateId,
  projectsSearchQuery,
  projectUuid,
  projectBookedById,
  projectCoordinatedById,
  assigneeDropdownValue,
}) => ({
  taskId,
  name,
  details,
  dueAtDate: Datetime.toFormDate(dueAtDate),
  dueAtTime: dueAtTime ? Datetime.toFormTime(dueAtTime) : '',
  isCompleted,
  comment,
  assignedToUserId,
  assignedToTeamId,
  organizationId,
  projectId: _.toString(projectId),
  taskTemplateId: _.toString(taskTemplateId),

  // Private
  projectsSearchQuery,
  projectUuid,
  projectBookedById,
  projectCoordinatedById,
  assigneeDropdownValue,
});

const toMutation = ({
  taskId,
  name,
  details,
  dueAtDate,
  dueAtTime,
  isCompleted,
  comment,
  assignedToUserId,
  assignedToTeamId,
  organizationId,
  projectId,
  taskTemplateId,
}) => {
  return {
    taskId,
    name,
    details,
    dueAtDate: dueAtDate ? Datetime.toMutationDate(dueAtDate) : null,
    dueAtTime: Datetime.toMutationTime(dueAtTime),
    isCompleted,
    comment,
    assignedToUserId,
    assignedToTeamId,
    organizationId,
    projectId: projectId || null,
    taskTemplateId: taskTemplateId || null,
  };
};

const TaskForm = {
  new: _new,
  delete: _delete,
  edit,
  toForm,
  toMutation,

  getFormPropertyForUserIdByAssignedToRole: TaskTemplateAssignedToRole.getFormPropertyForUserId,
};

export default TaskForm;
