// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import LocationForm from '@shared/modules/Location/forms/LocationForm';

const useUpdateProjectLocationMutation = ({locationForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      locationForm: LocationForm.toForm(locationForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectLocationMutation.mutation,
    variables: {
      locationForm: LocationForm.toMutation(form.values.locationForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateProjectLocationMutation.mutation = gql`
  ${LocationForm.edit.fragment}

  mutation useUpdateProjectLocationMutation($locationForm: LocationForm!) {
    response: updateProjectLocation(locationForm: $locationForm) {
      ${gql.errors}
      location {
        id
        ...LocationForm_edit
      }
    }
  }
`;

export default useUpdateProjectLocationMutation;
