// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import UserRole from '@shared/modules/User/enums/UserRole';

const _new = ({organizationId, recipientId, senderId, body}) => ({
  organizationId,
  recipientId,
  senderId,
  body,
  isManual: true,
  textMessageTemplateId: null,
});

const newForCommunicationChatButton = withFragment(
  ({organizationId, recipient, sender}) => {
    return {
      organizationId,
      recipientId: recipient.id,
      senderId: sender.id,
      body: '',
      isManual: true,
      textMessageTemplateId: null,

      // Private
      // This full name is passed from the viewer
      bodyHeader: recipient.role !== UserRole.CUSTOMER ? `[From ${sender.fullName}]\n\n` : '',
    };
  },
  gql`
    fragment SendTextMessageToUserForm_newForCommunicationChatButton on User {
      id
      role
    }
  `,
);

const toForm = ({
  organizationId,
  recipientId,
  senderId,
  body,
  isManual,
  textMessageTemplateId,
  bodyHeader,
}) => ({
  organizationId,
  recipientId,
  senderId,
  body: bodyHeader ? bodyHeader + body : body,
  isManual,
  textMessageTemplateId,

  // Private
  bodyHeader,
});

const toMutation = ({
  organizationId,
  recipientId,
  senderId,
  body,
  isManual,
  textMessageTemplateId,
}) => ({
  organizationId,
  recipientId,
  senderId,
  body,
  isManual,
  textMessageTemplateId,
});

const SendTextMessageToUserForm = {
  new: _new,
  newForCommunicationChatButton,
  toForm,
  toMutation,
};

export default SendTextMessageToUserForm;
