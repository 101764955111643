// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useDebouncedCallback} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {URL, pluralize} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import Tabs from '@shared/design/components/Tabs';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import CapacityProjectListFilters from 'modules/Calendar/Capacity/components/CapacityProjectListFilters';
import MultiBranchOrganizationField from 'modules/Organization/components/MultibranchOrganizationField';

const DateHeaderText = Styled.Text`
  ${Typography.Subheading}
`;

const HeaderRowContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Row = Styled.View`
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

const ToolbarHeader = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  padding-top: ${({isDayView}) => (isDayView ? '20px' : '0px')};
  padding-right: ${({isDayView}) => (isDayView ? '20px' : '0px')};
`;

const ExpandedDispatchViewButton = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.gray.border)};
  background-color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.white)};
  padding-horizontal: 12px;
  height: 42px;
  width: 50px;
`;

const tabs = [
  {
    label: 'Month',
    url: '/moves/capacity/calendar/month',
  },
  {
    label: 'Day',
    url: '/moves/capacity/calendar/day',
  },
];

const getFilteredUrlFromParams = (params, baseUrl) => {
  return URL.getUrlFromVariables(baseUrl, params);
};

const handleUpdateParam = ({baseUrl, navigator, params, paramKey, paramValue}) => {
  navigator.push(getFilteredUrlFromParams({...params, [paramKey]: paramValue || ''}, baseUrl));
};

const handleClearProjectsListFilters = ({baseUrl, navigator, params}) => {
  navigator.replace(
    getFilteredUrlFromParams(
      {...params, projectTypes: null, salespersons: null, projectTagIds: null, jobTagIds: null},
      baseUrl,
    ),
  );
};

const SearchInput = () => {
  const {navigator, params} = useNavigationDOM();
  const {location} = navigator;
  const handleUpdateQuery = useDebouncedCallback((text) => {
    handleUpdateParam({
      baseUrl: location.pathname,
      navigator,
      params,
      paramKey: 'searchQuery',
      paramValue: text,
    });
  }, 500);
  return (
    <SearchBar
      onChangeText={handleUpdateQuery}
      placeholder='Search by customer, locations, or job type'
      style={{width: '400px'}}
      defaultValue={params.query}
    />
  );
};

const CapacityCalendarToolbarHeader = ({
  onNavigate,
  label,
  isDayView,
  organization,
  canViewOtherBranchesData,
  isJobsListTableExpanded,
  totalJobsForDay,
  setIsJobsListTableExpanded,
}) => {
  const {navigator, params} = useNavigationDOM();
  const {location} = navigator;
  const currentTabIndex = _.findIndex(tabs, (tab) => navigator.location.pathname.includes(tab.url));
  const organizations = _.get(organization, 'company.organizations', []);

  return (
    <ToolbarHeader isDayView={isDayView}>
      <Row>
        <HeaderRowContainer>
          {!isDayView ? (
            <React.Fragment>
              <Space width={8} />
              <SecondaryButton onPress={() => onNavigate('TODAY')} text={'Today'} />
              <Space width={16} />
              <SecondaryButton onPress={() => onNavigate('PREV')}>
                <Icon source={Icon.ChevronLeft} color={colors.blue.interactive} size={16} />
              </SecondaryButton>
              <Space width={8} />
              <SecondaryButton onPress={() => onNavigate('NEXT')}>
                <Icon source={Icon.ChevronRight} color={colors.blue.interactive} size={16} />
              </SecondaryButton>
              <Space width={16} />
              <DateHeaderText>{label}</DateHeaderText>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Space width={16} />
              {totalJobsForDay !== null ? (
                <DateHeaderText>{pluralize('Job', totalJobsForDay, true)}</DateHeaderText>
              ) : (
                <SkeletonLoader width={50} height={20} />
              )}
              <Space width={16} />
              <SearchInput />
              <Space width={16} />
              <CapacityProjectListFilters
                handleUpdateParam={handleUpdateParam}
                organization={organization}
                handleClearProjectsListFilters={handleClearProjectsListFilters}
              />
            </React.Fragment>
          )}
        </HeaderRowContainer>
        <HeaderRowContainer>
          {isDayView && (
            <React.Fragment>
              <ExpandedDispatchViewButton
                isSelected={isJobsListTableExpanded}
                onPress={() => setIsJobsListTableExpanded(!isJobsListTableExpanded)}
              >
                <Icon
                  source={Icon.Truck}
                  size={16}
                  color={isJobsListTableExpanded ? colors.white : colors.gray.tertiary}
                />
              </ExpandedDispatchViewButton>
              <Space width={8} />
            </React.Fragment>
          )}
          <Tabs
            tabs={tabs}
            handlePressTab={(tab) => navigator.push(URL.getUrlFromVariables(tab.url, params))}
            activeTabIndex={currentTabIndex}
            isEnclosed
          />
          <Space width={8} />
          {canViewOtherBranchesData && (
            <MultiBranchOrganizationField
              value={params.slugs || []}
              organizations={organizations}
              onChangeValue={(newSlugs) => {
                handleUpdateParam({
                  baseUrl: location.pathname,
                  navigator,
                  params,
                  paramKey: 'slugs',
                  paramValue: newSlugs,
                });
              }}
            />
          )}
        </HeaderRowContainer>
      </Row>
    </ToolbarHeader>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityCalendarToolbarHeader.fragment = gql`
  ${CapacityProjectListFilters.fragment}
  ${MultiBranchOrganizationField.fragment}

  fragment CapacityCalendarToolbarHeader on Organization {
    id
    isPrimary
    hasMultipleOrganizations
    ...CapacityProjectListFilters
    ...MultiBranchOrganizationField
  }
`;

export default CapacityCalendarToolbarHeader;
