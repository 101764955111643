// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography, colors} from '@supermove/styles';

// App
import OrganizationLogo from '@shared/modules/Organization/components/OrganizationLogo';

const Wrapper = Styled.View`
  align-items: center;
  z-index: ${(props) => 100 - props.index};
`;

const Logo = Styled.Text`
  ${Typography.Heading4}
  background-color: ${colors.black};
  color: ${colors.white};
  font-weight: 900;
  text-align: center;
  padding-vertical: 16px;
  padding-horizontal: 24px;
  width: 300px;
  height: 62px;
`;

const DocumentV2OrganizationLogoContent = ({index, organization, isPreview}) => {
  return (
    <Wrapper index={index}>
      {isPreview ? (
        <Logo>Organization Logo</Logo>
      ) : (
        <OrganizationLogo
          organization={organization}
          style={{
            width: 195,
            height: 80,
          }}
        />
      )}
    </Wrapper>
  );
};

const DocumentV2OrganizationLogoPreview = ({index}) => {
  return <DocumentV2OrganizationLogoContent index={index} isPreview />;
};

const DocumentV2OrganizationLogoQueried = ({index, project}) => {
  const {organization} = project;
  return (
    <DocumentV2OrganizationLogoContent
      index={index}
      organization={organization}
      isPreview={false}
    />
  );
};

const ProjectDocumentV2OrganizationLogo = ({index, project, isPreview}) => {
  return (
    <React.Fragment>
      {isPreview ? (
        <DocumentV2OrganizationLogoPreview index={index} />
      ) : (
        <DocumentV2OrganizationLogoQueried project={project} index={index} />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectDocumentV2OrganizationLogo.fragment = gql`
  ${OrganizationLogo.fragment}
  fragment ProjectDocumentV2OrganizationLogo on Project {
    id
    organization {
      id
      ...OrganizationLogo
    }
  }
`;

export default ProjectDocumentV2OrganizationLogo;
