const _new = () => ({
  projectTypeIds: [],
});

const toForm = ({projectTypeIds}) => ({
  projectTypeIds,
});

const toMutation = ({projectTypeIds}) => ({
  projectTypeIds,
});

const ProjectTypesForm = {
  new: _new,
  toForm,
  toMutation,
};

export default ProjectTypesForm;
