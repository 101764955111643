// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Datetime} from '@supermove/utils';

const edit = withFragment(
  (organization) => ({
    organizationId: organization.id,
    name: organization.name,

    // Business Info
    businessName: organization.businessName,
    businessAddress: organization.businessAddress,
    businessStory: organization.businessStory,
    businessValues: organization.businessValues,
    businessFoundingDate: organization.businessFoundingDate,

    // private
    slug: organization.slug,
  }),
  gql`
    fragment OrganizationForm_edit on Organization {
      id
      name
      slug
      businessName
      businessAddress
      businessStory
      businessValues
      businessFoundingDate
    }
  `,
);

const toForm = (organization) => ({
  organizationId: organization.organizationId,
  name: organization.name,

  // Business Info
  businessName: organization.businessName,
  businessAddress: organization.businessAddress,
  businessStory: organization.businessStory,
  businessValues: organization.businessValues,
  businessFoundingDate: Datetime.toFormDate(organization.businessFoundingDate),

  // private
  slug: organization.slug,
});

const toMutation = (organization) => ({
  organizationId: organization.organizationId,
  name: organization.name,

  // Business Info
  businessName: organization.businessName,
  businessAddress: organization.businessAddress,
  businessStory: organization.businessStory,
  businessValues: organization.businessValues,
  businessFoundingDate: Datetime.toMutationDate(organization.businessFoundingDate),
});

const OrganizationForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationForm;
