// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ReorderSlotsForm from '@shared/modules/Dispatch/forms/ReorderSlotsForm';

const useReorderSlotsMutation = ({reorderSlotsForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      reorderSlotsForm: ReorderSlotsForm.toForm(reorderSlotsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useReorderSlotsMutation.mutation,
    variables: {
      reorderSlotsForm: ReorderSlotsForm.toMutation(form.values.reorderSlotsForm),
    },
    onSuccess: ({slots}) => onSuccess({slots, form}),
    onError: (errors) => onError({errors, form}),
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useReorderSlotsMutation.mutation = gql`
  mutation useReorderSlotsMutation($reorderSlotsForm: ReorderSlotsForm!) {
    response: reorderSlots(reorderSlotsForm: $reorderSlotsForm) {
      ${gql.errors}
      slots {
        id
      }
    }
  }
`;

export default useReorderSlotsMutation;
