// Supermove
import {colors} from '@supermove/styles';

const ACTIVE = 'ACTIVE';
const UNPLANNED = 'UNPLANNED';
const PLANNED = 'PLANNED';
const IN_TRANSIT = 'IN_TRANSIT';
const DELIVERED = 'DELIVERED';
const CANCELLED = 'CANCELLED';

const ACTIVE_OPTION = {
  value: 'ACTIVE',
  label: 'Active',
};

const UNPLANNED_OPTION = {
  value: 'UNPLANNED',
  label: 'Unplanned',
  backgroundColor: colors.red.accent,
  textColor: colors.red.warning,
};

const PLANNED_OPTION = {
  value: 'PLANNED',
  label: 'Planned',
  backgroundColor: colors.blue.accent,
  textColor: colors.gray.primary,
};

const IN_TRANSIT_OPTION = {
  value: 'IN_TRANSIT',
  label: 'In Transit',
  backgroundColor: colors.blue.accent,
  textColor: colors.blue.interactive,
};

const DELIVERED_OPTION = {
  value: 'DELIVERED',
  label: 'Delivered',
  backgroundColor: colors.green.accent,
  textColor: colors.green.status,
};

const CANCELLED_OPTION = {
  value: 'CANCELLED',
  label: 'Cancelled',
  backgroundColor: colors.gray.background,
  textColor: colors.gray.secondary,
  isHidden: true,
};

const OPTIONS = [
  UNPLANNED_OPTION,
  PLANNED_OPTION,
  IN_TRANSIT_OPTION,
  DELIVERED_OPTION,
  CANCELLED_OPTION,
];

const STATUS_OPTION_MAP = {
  [ACTIVE]: ACTIVE_OPTION,
  [UNPLANNED]: UNPLANNED_OPTION,
  [PLANNED]: PLANNED_OPTION,
  [IN_TRANSIT]: IN_TRANSIT_OPTION,
  [DELIVERED]: DELIVERED_OPTION,
  [CANCELLED]: CANCELLED_OPTION,
};

const getStatusOption = (status) => {
  return STATUS_OPTION_MAP[status];
};

const ShipmentStatus = {
  OPTIONS,

  ACTIVE,
  UNPLANNED,
  PLANNED,
  IN_TRANSIT,
  DELIVERED,
  CANCELLED,

  getStatusOption,
};

export default ShipmentStatus;
