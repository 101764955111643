// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from 'modules/App/components/TextTooltip';

const ContentContainer = Styled.View`
  background-color: ${({color}) => colors.getBackgroundColor(color)};
  padding-horizontal: ${({showLabel}) => (showLabel ? 12 : 8)}px;
  padding-vertical: 4px;
  border-radius: 4px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Label = Styled.Text`
  ${Typography.Responsive.MicroLabel};
  color: ${({color}) => color};
`;

const JobDispatchStatus = ({job, showLabel}) => {
  const responsive = useResponsive();
  const color = Job.getCalendarPrimaryStatusColor(job);
  const label = Job.getCalendarPrimaryStatusText(job);

  if (showLabel) {
    return (
      <ContentContainer color={color} showLabel={showLabel}>
        <Row>
          <Icon
            source={Job.getCalendarPrimaryStatusIcon(job)}
            size={responsive.desktop ? 12 : 16}
            color={color}
          />
          <Space width={8} />
          <Label color={color} responsive={responsive}>
            {label}
          </Label>
        </Row>
      </ContentContainer>
    );
  }

  return (
    <TextTooltip text={label}>
      <ContentContainer color={color}>
        <Icon source={Job.getCalendarPrimaryStatusIcon(job)} size={12} color={color} />
      </ContentContainer>
    </TextTooltip>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDispatchStatus.fragment = gql`
  ${Job.getCalendarPrimaryStatusColor.fragment}
  ${Job.getCalendarPrimaryStatusIcon.fragment}
  ${Job.getCalendarPrimaryStatusText.fragment}

  fragment JobDispatchStatus on Job {
    id
    ...Job_getCalendarPrimaryStatusColor
    ...Job_getCalendarPrimaryStatusIcon
    ...Job_getCalendarPrimaryStatusText
  }
`;

export default JobDispatchStatus;
