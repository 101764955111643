// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (inventory) => ({
    // To String is intentional here
    // Ensures value is passed into the dropdown component as a string
    inventoryId: _.toString(inventory.id),
    surveyMethodId: _.toString(inventory.surveyMethodId),
  }),
  gql`
    fragment InventorySurveyMethodForm_edit on Inventory {
      id
      surveyMethodId
    }
  `,
);

const toForm = ({inventoryId, surveyMethodId}) => ({
  inventoryId,
  surveyMethodId,
});

const toMutation = ({inventoryId, surveyMethodId}) => ({
  inventoryId,
  surveyMethodId,
});

const InventorySurveyMethodForm = {
  edit,
  toForm,
  toMutation,
};

export default InventorySurveyMethodForm;
