// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import CommercialMaterialTypeForm from '@shared/modules/CommercialCatalog/forms/CommercialMaterialTypeForm';

// App

const useUpdateCommercialMaterialTypeMutation = ({
  commercialMaterialTypeForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      commercialMaterialTypeForm: CommercialMaterialTypeForm.toForm(commercialMaterialTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCommercialMaterialTypeMutation.mutation,
    variables: {
      commercialMaterialTypeForm: CommercialMaterialTypeForm.toMutation(
        form.values.commercialMaterialTypeForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCommercialMaterialTypeMutation.mutation = gql`
  mutation useUpdateCommercialMaterialTypeMutation($commercialMaterialTypeForm: CommercialMaterialTypeForm!) {
    response: updateCommercialMaterialType(commercialMaterialTypeForm: $commercialMaterialTypeForm) {
      ${gql.errors}
      commercialMaterialType {
        id
      }
    }
  }
`;

export default useUpdateCommercialMaterialTypeMutation;
