// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

const Table = Styled.View`
  width: 100%;
`;

const TableHeader = Styled.View`
  flex-direction: row;
`;

const Cell = Styled.View`
  width: 120px;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const CellHeaderText = Styled.H8`
  ${fontWeight(700)}
`;

const Text = Styled.H8`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const JobValuationCoverageOptionsTable = ({job}) => {
  const {valuationCoverageTable} = job.jobType.valuationCoverageConfig;

  return (
    <Table>
      {valuationCoverageTable.map((row, rowIndex) => {
        if (rowIndex === 0) {
          return (
            <TableHeader key={rowIndex}>
              {row.map((column, columnIndex) => (
                <Cell key={columnIndex}>
                  <CellHeaderText>{column}</CellHeaderText>
                </Cell>
              ))}
            </TableHeader>
          );
        }

        return (
          <Row key={rowIndex}>
            {row.map((column, columnIndex) => (
              <Cell key={columnIndex}>
                <Text>{column}</Text>
              </Cell>
            ))}
          </Row>
        );
      })}
    </Table>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobValuationCoverageOptionsTable.fragment = gql`
  fragment JobValuationCoverageOptionsTable on Job {
    id
    jobType {
      id
      valuationCoverageConfig {
        valuationCoverageTable
      }
    }
  }
`;

export default JobValuationCoverageOptionsTable;
