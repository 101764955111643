// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useSheet} from '@supermove/hooks';
import {JobUser, Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Line from 'modules/App/components/Line';
import TextTooltip from 'modules/App/components/TextTooltip';
import StaffCommunicationButton from 'modules/Communication/components/StaffCommunicationButton';
import JobUserMoverPosition from 'modules/JobUser/components/JobUserMoverPosition';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TrucksAndMoversListItemContainer = Styled.View`
  border-color: ${colors.gray.border};
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 8 : 12)}px;
  padding-vertical: ${({responsive}) => (responsive.desktop ? 4 : 8)}px;
`;

const MoversListItem = Styled.View`
  border-color: ${colors.gray.border};
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 8 : 12)}px;
  padding-vertical: ${({responsive}) => (responsive.desktop ? 4 : 8)}px;
`;

const TrucksAndMoversListText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const JobUserStatusDotContainer = Styled.View`
  width: 32px
  align-items: center;
  justify-content: center;
`;

const JobUserStatusDot = Styled.View`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const getTruckTrip = ({job, truck}) => {
  return _.find(job.trips, (trip) => _.includes(trip.truckIds, _.toNumber(truck.id)));
};

const ListItemIcon = ({icon, responsive}) => {
  return (
    <Icon
      source={icon}
      size={responsive.desktop ? 12 : 16}
      color={colors.gray.secondary}
      style={{width: responsive.desktop ? 16 : 18, alignSelf: 'center'}}
    />
  );
};

const ItemTooltip = ({text, children}) => {
  if (text) {
    return (
      <TextTooltip text={text} placement={'top'}>
        <Column>{children}</Column>
      </TextTooltip>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const TruckItem = ({job, truck, responsive}) => {
  const {navigator} = useNavigationDOM();
  const trip = getTruckTrip({job, truck});

  return (
    <TrucksAndMoversListItemContainer responsive={responsive}>
      <Row>
        <ListItemIcon icon={Icon.Truck} responsive={responsive} />
        <JobUserStatusDotContainer />
        <TrucksAndMoversListText responsive={responsive}>{truck.name}</TrucksAndMoversListText>
        {trip && (
          <React.Fragment>
            <Space width={8} />
            <TertiaryButton
              onPress={() => navigator.push(`/dispatch/long-distance/trips/${trip.uuid}/shipments`)}
            >
              <LinkText responsive={responsive}>(trip truck)</LinkText>
            </TertiaryButton>
          </React.Fragment>
        )}
      </Row>
    </TrucksAndMoversListItemContainer>
  );
};

const JobUserItem = ({jobUser, job, refetch, responsive, viewerRole}) => {
  const {navigator} = useNavigationDOM();
  const updateJobUserMoverPositionsSheet = useSheet({name: 'Update JobUser MoverPositions Sheet'});
  const organizationSettingsNotification = Organization.getNotifications(jobUser.user.organization);

  return (
    <React.Fragment>
      <MoversListItem responsive={responsive} onPress={updateJobUserMoverPositionsSheet.handleOpen}>
        <Row>
          <ItemTooltip
            text={jobUser.trip && 'A trip driver can only be removed by editing the trip.'}
          >
            <ListItemIcon icon={jobUser.trip ? Icon.Lock : Icon.User} responsive={responsive} />
          </ItemTooltip>
          <ItemTooltip text={JobUser.getTooltipStatus(jobUser)}>
            <JobUserStatusDotContainer>
              <JobUserStatusDot color={jobUser.statusColor} />
            </JobUserStatusDotContainer>
          </ItemTooltip>
          <TrucksAndMoversListText responsive={responsive}>
            {jobUser.user.fullName}
          </TrucksAndMoversListText>
          {jobUser.trip && (
            <React.Fragment>
              <Space width={8} />
              <TertiaryButton
                onPress={() =>
                  navigator.push(`/dispatch/long-distance/trips/${jobUser.trip.uuid}/shipments`)
                }
              >
                <LinkText responsive={responsive}>(trip driver)</LinkText>
              </TertiaryButton>
            </React.Fragment>
          )}
          {responsive.desktop ? (
            <React.Fragment>
              <Space width={8} />
              <JobUserMoverPosition
                jobUser={jobUser}
                refetch={refetch}
                isDisabled={job.isFinal}
                style={{marginVertical: 1}}
              />
              <Space style={{flex: 1}} />
            </React.Fragment>
          ) : (
            <Space style={{flex: 1}} />
          )}
          <StaffCommunicationButton
            user={jobUser.user}
            viewerRole={viewerRole}
            refetch={refetch}
            isDisabled={!organizationSettingsNotification.allowEmployeeText}
            icon={Icon.CommentSms}
            iconSize={16}
            iconColor={colors.gray.secondary}
          />
        </Row>
        {!responsive.desktop && (
          <Row>
            <Space width={30} />
            <JobUserMoverPosition
              jobUser={jobUser}
              refetch={refetch}
              responsive={responsive}
              isDisabled={job.isFinal}
              style={{marginVertical: 1, marginTop: 8}}
            />
          </Row>
        )}
      </MoversListItem>
    </React.Fragment>
  );
};

const JobCrewsListTruckJobUser = ({index, children}) => {
  return (
    <React.Fragment>
      {index > 0 && <Line />}
      {children}
    </React.Fragment>
  );
};

JobCrewsListTruckJobUser.Truck = TruckItem;
JobCrewsListTruckJobUser.JobUser = JobUserItem;

// --------------------------------------------------
// Data
// --------------------------------------------------
JobCrewsListTruckJobUser.fragment = gql`
  fragment JobCrewsListTruckJobUser on Job {
    id
    isFinal
    trips {
      id
      uuid
      truckIds
    }
  }
`;

JobCrewsListTruckJobUser.Truck.fragment = gql`
  fragment JobCrewsListTruckJobUser_Truck on Truck {
    id
    name
  }
`;

JobCrewsListTruckJobUser.JobUser.fragment = gql`
  ${JobUser.getTooltipStatus.fragment}
  ${JobUserMoverPosition.fragment}
  ${StaffCommunicationButton.fragment}
  ${Organization.getNotifications.fragment}

  fragment JobCrewsListTruckJobUser_JobUser on JobUser {
    id
    statusColor
    user {
      id
      fullName
      organization {
        id
        ...Organization_getNotifications
      }
      ...StaffCommunicationButton
    }
    trip {
      id
      uuid
    }
    ...JobUser_getTooltipStatus
    ...JobUserMoverPosition
  }
`;

export default JobCrewsListTruckJobUser;
