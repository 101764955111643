// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Checkbox, Styled, Space, Icon, Popover, DateInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useNavigationDOM,
  useQuery,
  usePopover,
  useHover,
  useModal,
  useState,
  usePagination,
  useToast,
} from '@supermove/hooks';
import {Location, Container} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Table from '@shared/design/components/Table';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ContainerLocationKind from '@shared/modules/Storage/enums/ContainerLocationKind';
import UpdateContainerInDatesForProjectForm from '@shared/modules/Storage/forms/UpdateContainerInDatesForProjectForm';
import UpdateContainerOutDatesForProjectForm from '@shared/modules/Storage/forms/UpdateContainerOutDatesForProjectForm';
import useRemoveContainersFromProjectMutation from '@shared/modules/Storage/hooks/useRemoveContainersFromProjectMutation';
import useUpdateContainerInDatesForProjectMutation from '@shared/modules/Storage/hooks/useUpdateContainerInDatesForProjectMutation';
import useUpdateContainerOutDatesForProjectMutation from '@shared/modules/Storage/hooks/useUpdateContainerOutDatesForProjectMutation';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import CreateMoveProjectModal from 'modules/Job/V2/Move/components/CreateMoveProjectModal';
import CreateProjectSuccessModal from 'modules/Project/components/CreateProjectSuccessModal';
import ContainerSidebarLink from 'modules/Storage/Containers/ContainerSidebarLink';

const SectionContainer = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const ContainerPanelHeaderContainer = Styled.View`
  flex-direction: row;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Label}
`;

const SelectedItemsText = Styled.Text`
  ${Typography.Label}
  color: ${({color}) => color};
`;

const VerticalLine = Styled.View`
  width: 1px;
  height: 36px;
  background-color: ${colors.gray.border};
`;

const Text = Styled.Text`
  ${Typography.Body}
`;

const IconButton = Styled.ButtonV2`
`;

const MenuOptionButton = Styled.ButtonV2`
  background-color: ${(props) => (props.isHovered ? colors.hover : 'transparent')};
  padding-vertical: 4px;
  padding-horizontal: 12px;
`;

const MenuItemText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.primary};
`;

const getColumnDefinitions = ({
  project,
  refetch,
  selectedContainers,
  onContainerSelect,
  allContainerIds,
  onSelectAll,
  onDeselectAll,
}) => [
  {
    width: 48,
    headerContent: () => {
      const allContainersSelected =
        _.isEqual(allContainerIds, selectedContainers) && allContainerIds.length;
      return (
        <Checkbox
          size={16}
          iconSize={12}
          checked={allContainersSelected}
          onChange={() => {
            if (allContainersSelected) {
              onDeselectAll();
            } else {
              onSelectAll();
            }
          }}
          color={colors.blue.interactive}
          borderColor={allContainersSelected ? colors.blue.interactive : colors.gray.secondary}
        />
      );
    },
    cellContent: ({item: container}) => {
      const isSelected = _.includes(selectedContainers, container.id);
      return (
        <Checkbox
          size={16}
          iconSize={12}
          checked={isSelected}
          onChange={() => onContainerSelect(container.id)}
          color={colors.blue.interactive}
          borderColor={isSelected ? colors.blue.interactive : colors.gray.secondary}
        />
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <HeaderText>Warehouse</HeaderText>;
    },
    cellContent: ({item: container}) => {
      if (container.locationKind === ContainerLocationKind.CUSTOM) {
        return <Text numberOfLines={1}>None</Text>;
      }
      return <Text numberOfLines={1}>{container.warehouse.name}</Text>;
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <HeaderText>Container ID</HeaderText>;
    },
    cellContent: ({item: container}) => {
      return <ContainerSidebarLink container={container} refetch={refetch} />;
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <HeaderText>Location</HeaderText>;
    },
    cellContent: ({item: container}) => {
      return container.locationKind === ContainerLocationKind.CUSTOM ? (
        <Text numberOfLines={1}>
          {container.customLocation ? Location.getDisplayLocation(container.customLocation) : '--'}
        </Text>
      ) : (
        <Text numberOfLines={1}>{Container.getDisplayCoordinates(container) || '--'}</Text>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <HeaderText>Date In (MM/DD/YY)</HeaderText>;
    },
    cellContent: ({item: container}) => {
      return <Text>{Container.getDisplayDateIn(container)}</Text>;
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <HeaderText>Date Out (MM/DD/YY)</HeaderText>;
    },
    cellContent: ({item: container}) => {
      return <Text>{Container.getDisplayDateOut(container)}</Text>;
    },
  },
  // Actions
  {
    flex: 1,
    headerContent: () => {
      return <HeaderText>Actions</HeaderText>;
    },
    cellContent: ({item: container}) => (
      <ContainerActions project={project} container={container} refetch={refetch} />
    ),
  },
];

const UpdateContainerInDatesModal = ({
  selectedContainers,
  project,
  isOpen,
  handleClose,
  resetSelectedContainers,
  seedDate,
  refetch,
}) => {
  const updateContainerInDatesForProjectForm = UpdateContainerInDatesForProjectForm.new({
    projectId: project.id,
    containerIds: selectedContainers,
    inDate: seedDate,
  });
  const {form, handleSubmit} = useUpdateContainerInDatesForProjectMutation({
    updateContainerInDatesForProjectForm,
    onSuccess: () => {
      handleClose();
      resetSelectedContainers && resetSelectedContainers();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose}>
      <SmallModal.BoldText>Edit Container Date In</SmallModal.BoldText>
      <Space height={16} />
      <FieldInput
        {...form}
        index={0}
        component={DateInput}
        name={'updateContainerInDatesForProjectForm.inDate'}
        label={'Date In'}
        isRequired
        input={{
          isPortaled: true,
          placeholder: 'MM/DD/YYYY',
          setFieldValue: form.setFieldValue,
          style: {
            width: '100%',
          },
        }}
        style={{flex: 1}}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <TertiaryButton text={'Cancel'} onPress={handleClose} />
        <Space width={24} />
        <Button text={'Save'} iconLeft={Icon.Check} onPress={handleSubmit} />
      </SmallModal.Footer>
    </SmallModal>
  );
};

const UpdateContainerOutDatesModal = ({
  selectedContainers,
  project,
  isOpen,
  handleClose,
  resetSelectedContainers,
  seedDate,
  refetch,
}) => {
  const updateContainerOutDatesForProjectForm = UpdateContainerOutDatesForProjectForm.new({
    projectId: project.id,
    containerIds: selectedContainers,
    outDate: seedDate,
  });
  const {form, handleSubmit} = useUpdateContainerOutDatesForProjectMutation({
    updateContainerOutDatesForProjectForm,
    onSuccess: () => {
      handleClose();
      resetSelectedContainers && resetSelectedContainers();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose}>
      <SmallModal.BoldText>Edit Container Date Out</SmallModal.BoldText>
      <Space height={16} />
      <FieldInput
        {...form}
        index={0}
        component={DateInput}
        name={'updateContainerOutDatesForProjectForm.outDate'}
        label={'Date Out'}
        isRequired
        input={{
          isPortaled: true,
          placeholder: 'MM/DD/YYYY',
          setFieldValue: form.setFieldValue,
          style: {
            width: '100%',
          },
        }}
        style={{flex: 1}}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <TertiaryButton text={'Cancel'} onPress={handleClose} />
        <Space width={24} />
        <Button text={'Save'} iconLeft={Icon.Check} onPress={handleSubmit} />
      </SmallModal.Footer>
    </SmallModal>
  );
};

const RemoveContainersModal = ({
  selectedContainers,
  project,
  isOpen,
  handleClose,
  refetch,
  containerName,
}) => {
  const containersRemovedToast = useToast({
    ToastComponent: SuccessToast,
    message: `Container(s) removed from project ${project.identifier}`,
    isClosable: true,
  });

  const {handleSubmit} = useRemoveContainersFromProjectMutation({
    removeContainersFromProjectForm: {containerIds: selectedContainers, projectId: project.id},
    onSuccess: () => {
      handleClose();
      refetch();
      containersRemovedToast.handleToast();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Remove ${
        selectedContainers.length === 1 && containerName ? `"${containerName}"` : 'containers'
      } from project?`}
      subtitle={
        'Removing a container from the project will remove any record of it from the project. '
      }
      handleClose={handleClose}
      handleDelete={handleSubmit}
      deleteButtonText={'Remove'}
    />
  );
};

const ActionMenuItem = ({onPress, children}) => {
  const {isHovered, ref} = useHover();
  return (
    <MenuOptionButton onPress={onPress} isHovered={isHovered} ref={ref}>
      <MenuItemText>{children}</MenuItemText>
    </MenuOptionButton>
  );
};

const ContainerActionsPopover = ({
  project,
  containerActionsPopover,
  updateContainerInDateModal,
  updateContainerOutDateModal,
  removeContainerModal,
}) => {
  return (
    <Popover
      placement={Popover.Positions.RightStart}
      isOpen={containerActionsPopover.isOpen}
      handleOpen={containerActionsPopover.handleOpen}
      handleClose={containerActionsPopover.handleClose}
      reference={containerActionsPopover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={160}>
        <Space height={8} />
        <ActionMenuItem
          onPress={() => {
            updateContainerInDateModal.handleOpen();
            containerActionsPopover.handleClose();
          }}
        >
          Edit date in
        </ActionMenuItem>
        <Space height={8} />
        <ActionMenuItem
          onPress={() => {
            updateContainerOutDateModal.handleOpen();
            containerActionsPopover.handleClose();
          }}
        >
          Edit date out
        </ActionMenuItem>
        <Space height={8} />
        <ActionMenuItem
          onPress={() => {
            removeContainerModal.handleOpen();
            containerActionsPopover.handleClose();
          }}
        >
          Remove
        </ActionMenuItem>
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const ContainerActions = ({container, project, refetch}) => {
  const containerActionsPopover = usePopover();
  const updateContainerInDateModal = useModal({name: 'Update Container In Date Modal'});
  const updateContainerOutDateModal = useModal({name: 'Update Container Out Date Modal'});
  const removeContainerModal = useModal({name: 'Remove Container Modal'});

  return (
    <React.Fragment>
      <Row>
        <Popover.Content innerRef={containerActionsPopover.ref}>
          <IconButton onPress={containerActionsPopover.handleToggle}>
            <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
          </IconButton>
        </Popover.Content>
      </Row>
      <ContainerActionsPopover
        key={`CONTAINER_ACTIONS_POPOVER-${containerActionsPopover.isOpen}`}
        project={project}
        containerActionsPopover={containerActionsPopover}
        updateContainerInDateModal={updateContainerInDateModal}
        updateContainerOutDateModal={updateContainerOutDateModal}
        removeContainerModal={removeContainerModal}
      />
      <RemoveContainersModal
        key={removeContainerModal.key}
        isOpen={removeContainerModal.isOpen}
        handleClose={removeContainerModal.handleClose}
        selectedContainers={[container.id]}
        containerName={container.name}
        project={project}
        refetch={refetch}
      />
      <UpdateContainerInDatesModal
        key={updateContainerInDateModal.key}
        isOpen={updateContainerInDateModal.isOpen}
        handleClose={updateContainerInDateModal.handleClose}
        selectedContainers={[container.id]}
        seedDate={container.startDateForProject}
        project={project}
        refetch={refetch}
      />
      <UpdateContainerOutDatesModal
        key={updateContainerOutDateModal.key}
        isOpen={updateContainerOutDateModal.isOpen}
        handleClose={updateContainerOutDateModal.handleClose}
        selectedContainers={[container.id]}
        seedDate={container.endDateForProject}
        project={project}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const ContainerPanelHeader = ({
  project,
  selectedContainers,
  refetch,
  viewer,
  resetSelectedContainers,
}) => {
  const {navigator, params} = useNavigationDOM();
  const createMoveProjectForStorageProjectModal = useModal({
    name: 'Create Move Project For Storage Project Modal',
    enableTracking: true,
  });
  const createMoveProjectSuccessModal = useModal({name: 'Create Move Project Success Modal'});
  const updateContainerInDatesModal = useModal({name: 'Update Container In Dates Modal'});
  const updateContainerOutDatesModal = useModal({name: 'Update Container Out Dates Modal'});
  const removeContainersModal = useModal({name: 'Remove Containers Modal'});

  const containerPanelActions = [
    {
      text: 'Edit date in',
      isDisabled: !selectedContainers.length,
      onPress: updateContainerInDatesModal.handleOpen,
    },
    {
      text: 'Edit date out',
      isDisabled: !selectedContainers.length,
      onPress: updateContainerOutDatesModal.handleOpen,
    },
    {
      text: 'Remove containers',
      isDisabled: !selectedContainers.length,
      onPress: removeContainersModal.handleOpen,
    },
    {
      text: 'Create outgoing move',
      isDisabled: !selectedContainers.length,
      onPress: createMoveProjectForStorageProjectModal.handleOpen,
    },
  ];

  return (
    <ContainerPanelHeaderContainer>
      <Space style={{flex: 1}} />
      <Row>
        <SelectedItemsText
          color={selectedContainers.length ? colors.blue.interactive : colors.gray.tertiary}
        >
          {`${_.size(selectedContainers)} ${pluralize(
            'containers',
            _.size(selectedContainers),
          )} selected`}
        </SelectedItemsText>
        <Space width={12} />
        <VerticalLine />
        <Space width={12} />
        <DropdownButton
          text={'Actions'}
          actions={containerPanelActions}
          menuWidth={200}
          menuPosition={DropdownButton.MENU_POSITION.RIGHT}
          ButtonComponent={SecondaryButton}
        />
        <Space width={12} />
        <Button
          text={'Assign Container(s)'}
          iconLeft={Icon.Plus}
          onPress={() => {
            navigator.push(`/storage/containers/assign-to-project/${project.uuid}`);
          }}
        />
      </Row>
      <CreateMoveProjectModal
        key={createMoveProjectForStorageProjectModal.key}
        isOpen={createMoveProjectForStorageProjectModal.isOpen}
        handleClose={createMoveProjectForStorageProjectModal.handleClose}
        refetch={refetch}
        project={project}
        viewerId={viewer.id}
        containerIds={selectedContainers}
        isDisabledClientFields
        isDisabledInventoryInformation
        createProjectSuccessModal={createMoveProjectSuccessModal}
        isJobDateRequired
      />
      <CreateProjectSuccessModal
        key={params.new}
        projectUuid={params.new}
        isOpen={createMoveProjectSuccessModal.isOpen}
        handleClose={createMoveProjectSuccessModal.handleClose}
        title={'Move project created!'}
        backButtonText={'Back to storage project'}
        handleViewProject={() => navigator.push(`/projects/${params.new}`)}
      />
      <UpdateContainerInDatesModal
        key={updateContainerInDatesModal.key}
        isOpen={updateContainerInDatesModal.isOpen}
        handleClose={updateContainerInDatesModal.handleClose}
        selectedContainers={selectedContainers}
        resetSelectedContainers={resetSelectedContainers}
        project={project}
        refetch={refetch}
      />
      <UpdateContainerOutDatesModal
        key={updateContainerOutDatesModal.key}
        isOpen={updateContainerOutDatesModal.isOpen}
        handleClose={updateContainerOutDatesModal.handleClose}
        selectedContainers={selectedContainers}
        resetSelectedContainers={resetSelectedContainers}
        project={project}
        refetch={refetch}
      />
      <RemoveContainersModal
        key={removeContainersModal.key}
        isOpen={removeContainersModal.isOpen}
        handleClose={removeContainersModal.handleClose}
        selectedContainers={selectedContainers}
        project={project}
        refetch={refetch}
      />
    </ContainerPanelHeaderContainer>
  );
};

const ContainersPanel = ({project, projectContainerStatusKind, refetch: refetchParent}) => {
  const [selectedContainers, setSelectedContainers] = useState([]);

  const handleContainerSelect = (containerId) => {
    const newSelectedContainers = _.xor(selectedContainers, [containerId]);
    setSelectedContainers(newSelectedContainers);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const {
    loading,
    data,
    refetch: refetchSelf,
  } = useQuery(ContainersPanel.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid: project.uuid,
      projectContainerStatusKind,
      pagination: {
        page: currentPage,
        resultsPerPage: 5,
      },
      projectId: project.id,
    },
  });

  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(data, 'project.paginatedList.paginationMetadata'),
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });

  const refetch = () => {
    refetchSelf();
    refetchParent();
  };

  const containers = data ? data.project.paginatedList.containers : [];

  return (
    <React.Fragment>
      <SectionContainer index={0}>
        <ContainerPanelHeader
          project={project}
          selectedContainers={selectedContainers}
          viewer={data ? data.viewer : {}}
          refetch={refetch}
          resetSelectedContainers={() => setSelectedContainers([])}
        />
      </SectionContainer>
      <Space height={16} />
      <SectionContainer index={1}>
        <Table
          columnDefinitions={getColumnDefinitions({
            project,
            refetch,
            selectedContainers,
            onContainerSelect: handleContainerSelect,
            allContainerIds: containers.map((container) => container.id),
            onSelectAll: () => setSelectedContainers(containers.map((container) => container.id)),
            onDeselectAll: () => setSelectedContainers([]),
          })}
          emptyStateText='No containers to display'
          items={containers}
          loading={loading}
        />
      </SectionContainer>
      <Space height={16} />
      <PaginationBar pagination={pagination} />
      <Space height={16} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ContainersPanel.query = gql`
  ${Location.getDisplayLocation.fragment}
  ${Container.getDisplayCoordinates.fragment}
  ${Container.getDisplayDateIn.fragment}
  ${Container.getDisplayDateOut.fragment}
  ${ContainerSidebarLink.fragment}
  ${CreateMoveProjectModal.fragment}
  ${usePagination.fragment}

  query ContainersPanel(
    $projectUuid: String!
    $pagination: PaginationInput!
    $projectId: Int!
    $projectContainerStatusKind: String!
  ) {
    ${gql.query}
    viewer {
      id
    }
    project(uuid: $projectUuid) {
      id
      paginatedList: containersPaginatedList(
        pagination: $pagination
        projectId: $projectId
        projectContainerStatusKind: $projectContainerStatusKind
      ) {
        containers: results {
          id
          name
          locationKind
          startDateForProject(projectId: $projectId)
          endDateForProject(projectId: $projectId)
          warehouse {
            id
            name
          }
          customLocation {
            id
            ...Location_getDisplayLocation
          }
          ...Container_getDisplayCoordinates
          ...Container_getDisplayDateIn
          ...Container_getDisplayDateOut
          ...ContainerSidebarLink
        }
        paginationMetadata {
          ...usePagination
        }
      }
      ...CreateMoveProjectModal
    }
  }
`;

ContainersPanel.fragment = gql`
  fragment ContainersPanel on Project {
    id
    identifier
    uuid
  }
`;

export default ContainersPanel;
