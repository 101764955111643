/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from 'modules/App/components/TextTooltip';

const DispatchNotesText = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
  text-decoration: underline;
  text-decoration-color: ${colors.gray.secondary};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.ButtonV2`
`;

const IconContainer = Styled.View`
  width: 12px;
  align-items: center;
`;

const DispatchNotesTextWrapper = ({dispatchNotes, isHovered, children}) => {
  if (dispatchNotes) {
    return (
      <TextTooltip
        text={dispatchNotes}
        style={{width: 320}}
        visible={isHovered}
        placement={'bottomLeft'}
      >
        {children}
      </TextTooltip>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const JobDispatchNotes = ({job, numberOfLines, popover}) => {
  const {ref, isHovered} = useHover();
  const {dispatchNotes} = job;
  return (
    <Popover.Content innerRef={popover.ref}>
      <Button onPress={popover.handleToggle} ref={ref}>
        <Row>
          <TextTooltip text={'Dispatch Notes'}>
            <IconContainer>
              <Icon
                source={Icon.StickyNote}
                color={colors.gray.primary}
                size={11}
                style={{marginBottom: 2}}
              />
            </IconContainer>
          </TextTooltip>
          <Space width={6} />
          <DispatchNotesTextWrapper dispatchNotes={job.dispatchNotes} isHovered={isHovered}>
            <DispatchNotesText
              numberOfLines={numberOfLines}
              style={{
                fontStyle: dispatchNotes ? null : 'italic',
                fontWeight: isHovered || popover.isOpen ? '700' : '500',
              }}
            >
              {dispatchNotes || 'Click to add a note'}
            </DispatchNotesText>
          </DispatchNotesTextWrapper>
        </Row>
      </Button>
    </Popover.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDispatchNotes.fragment = gql`
  fragment JobDispatchNotes on Job {
    id
    dispatchNotes
  }
`;

export default JobDispatchNotes;
