// Supermove
import {gql} from '@supermove/graphql';
import {Currency, Float, withFragment} from '@supermove/utils';

const edit = withFragment(
  (commercialEquipment) => ({
    commercialEquipmentId: commercialEquipment.id,
    identifier: commercialEquipment.identifier,
    name: commercialEquipment.name,
    quantityRequested: commercialEquipment.quantityRequested,
    quantityDelivered: commercialEquipment.quantityDelivered,
    price: commercialEquipment.price,
    commercialCatalogId: commercialEquipment.commercialCatalogId,
  }),
  gql`
    fragment CommercialEquipmentForm_edit on CommercialEquipment {
      id
      identifier
      name
      quantityRequested
      quantityDelivered
      price
      commercialCatalogId
    }
  `,
);

const _new = ({commercialCatalogId, commercialEquipmentTypeId, name, price}) => ({
  commercialEquipmentId: undefined,
  identifier: undefined,
  name,
  price,
  quantityRequested: 0,
  quantityDelivered: 0,
  commercialCatalogId,
  commercialEquipmentTypeId,
});

const toForm = ({
  commercialEquipmentId,
  identifier,
  name,
  quantityRequested,
  quantityDelivered,
  price,
  commercialCatalogId,
  commercialEquipmentTypeId,
}) => ({
  commercialEquipmentId,
  identifier,
  name,
  quantityRequested: Float.toForm(quantityRequested),
  quantityDelivered: Float.toForm(quantityDelivered),
  price: Currency.toForm(price),
  commercialCatalogId,
  commercialEquipmentTypeId,
});

const toMutation = ({
  commercialEquipmentId,
  identifier,
  name,
  quantityRequested,
  quantityDelivered,
  price,
  commercialCatalogId,
  commercialEquipmentTypeId,
}) => ({
  commercialEquipmentId,
  identifier,
  name,
  quantityRequested: Float.toMutation(quantityRequested),
  quantityDelivered: Float.toMutation(quantityDelivered),
  price: Currency.toMutation(price),
  commercialCatalogId,
  commercialEquipmentTypeId,
});

const CommercialEquipmentForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default CommercialEquipmentForm;
