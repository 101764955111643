// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SlotForm from '@shared/modules/Dispatch/forms/SlotForm';

const useCreateSlotMutation = ({slotForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      slotForm: SlotForm.toForm(slotForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateSlotMutation.mutation,
    variables: {
      slotForm: SlotForm.toMutation(form.values.slotForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCreateSlotMutation.mutation = gql`
  mutation useCreateSlotMutation($slotForm: SlotForm!) {
    response: createSlot(slotForm: $slotForm) {
      ${gql.errors}
      slot {
        id
      }
    }
  }
`;

export default useCreateSlotMutation;
