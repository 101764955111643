// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';

// App
import Modal from '@shared/design/components/Modal';
import ClientFields from 'modules/Client/components/ClientFields';
import MoveProjectJobFields from 'modules/Job/V2/Move/components/MoveProjectJobFields';
import MoveProjectProjectFields from 'modules/Job/V2/Move/components/MoveProjectProjectFields';
import StorageProjectInventoryFields from 'modules/Job/V2/Storage/components/StorageProjectInventoryFields';
import BillingValuesFields from 'modules/Project/Billing/components/BillingValuesFields';
import ProjectInternalFields from 'modules/Project/components/ProjectInternalFields';
import ProjectJobAdditionalFields from 'modules/Project/components/ProjectJobAdditionalFields';
import ProjectJobDispatchFields from 'modules/Project/components/ProjectJobDispatchFields';
import ProjectJobLocationFields from 'modules/Project/components/ProjectJobLocationFields';

const CustomerInformation = ({
  form,
  field,
  index,
  disabled,
  organization,
  isShowingBillingClient,
}) => {
  const isClientSearchEnabled =
    _.get(form.values, `${field}.isClientSearchEnabled`) || isShowingBillingClient;
  return (
    <Modal.Block index={index}>
      <ClientFields
        organization={organization}
        form={form}
        field={isShowingBillingClient ? `${field}.billingClientForm` : `${field}.clientForm`}
        isSearchEnabled={isClientSearchEnabled || isShowingBillingClient}
        disabled={disabled}
        isShowingBillingClient={isShowingBillingClient}
      />
    </Modal.Block>
  );
};

const ProjectInformation = ({form, field, index, organization}) => {
  return (
    <Modal.Block index={index}>
      <MoveProjectProjectFields form={form} field={field} organization={organization} />
    </Modal.Block>
  );
};

const JobInformation = ({form, field, index, organization, isJobDateRequired}) => {
  return (
    <Modal.Block index={index}>
      <MoveProjectJobFields
        form={form}
        field={field}
        organization={organization}
        isJobDateRequired={isJobDateRequired}
      />
    </Modal.Block>
  );
};

const InventoryInformation = ({form, field, index, organization, collections}) => {
  return (
    <Modal.Block index={index}>
      <StorageProjectInventoryFields
        form={form}
        field={field}
        organization={organization}
        collections={collections}
      />
    </Modal.Block>
  );
};

const BillingInformation = ({form, field, index}) => {
  return (
    <Modal.Block index={index}>
      <BillingValuesFields form={form} field={field} />
    </Modal.Block>
  );
};

const DispatchInformation = ({form, field, index, organization}) => {
  return (
    <Modal.Block index={index}>
      <ProjectJobDispatchFields
        form={form}
        field={`${field}.jobForms.0`}
        organization={organization}
      />
    </Modal.Block>
  );
};

const LocationInformation = ({form, field, index, organization}) => {
  const responsive = useResponsive();
  return (
    <Modal.Block index={index}>
      <ProjectJobLocationFields
        index={0}
        field={`${field}.jobForms.0`}
        form={form}
        responsive={responsive}
        organization={organization}
      />
    </Modal.Block>
  );
};

const AdditionalInformation = ({form, field, index, organization}) => {
  return (
    <Modal.Block index={index}>
      <ProjectJobAdditionalFields
        form={form}
        jobField={`${field}.jobForms.0`}
        organization={organization}
      />
    </Modal.Block>
  );
};

const InternalInformation = ({form, field, index, organization}) => {
  return (
    <Modal.Block index={index}>
      <ProjectInternalFields
        form={form}
        field={field}
        organization={organization}
        hasStaffInformation
      />
    </Modal.Block>
  );
};

const MoveProjectModalFields = ({
  form,
  field,
  isDisabledClientFields,
  isDisabledInventoryInformation,
  collections,
  isJobDateRequired,
}) => {
  const {data, loading} = useQuery(MoveProjectModalFields.query, {
    fetchPolicy: 'cache-and-network',
  });
  return (
    <Loading loading={loading}>
      {() => {
        const organization = data.viewer.viewingOrganization;
        return (
          <React.Fragment>
            <CustomerInformation
              form={form}
              field={field}
              index={0}
              disabled={isDisabledClientFields}
              organization={organization}
            />
            <Space height={16} />
            <CustomerInformation
              form={form}
              field={field}
              index={1}
              organization={organization}
              isShowingBillingClient
            />
            <Space height={16} />
            <ProjectInformation form={form} field={field} index={2} organization={organization} />
            <Space height={16} />
            <JobInformation
              form={form}
              field={field}
              index={3}
              organization={organization}
              isJobDateRequired={isJobDateRequired}
            />
            {!isDisabledInventoryInformation && (
              <React.Fragment>
                <Space height={16} />
                <InventoryInformation
                  form={form}
                  field={field}
                  index={4}
                  organization={organization}
                  collections={collections}
                />
              </React.Fragment>
            )}
            <Space height={16} />
            <BillingInformation form={form} field={field} index={5} />
            <Space height={16} />
            <DispatchInformation form={form} field={field} index={6} organization={organization} />
            <Space height={16} />
            <LocationInformation form={form} field={field} index={7} organization={organization} />
            <Space height={16} />
            <AdditionalInformation
              form={form}
              field={field}
              index={8}
              organization={organization}
            />
            <Space height={16} />
            {/* Indexing is due to dropdown inputs opening upwards */}
            <InternalInformation form={form} field={field} index={7} organization={organization} />
          </React.Fragment>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MoveProjectModalFields.query = gql`
  ${ClientFields.fragment}
  ${MoveProjectJobFields.fragment}
  ${MoveProjectProjectFields.fragment}
  ${ProjectInternalFields.fragment}
  ${ProjectJobAdditionalFields.fragment}
  ${ProjectJobDispatchFields.fragment}
  ${ProjectJobLocationFields.fragment}
  query MoveProjectModalFields {
    viewer {
      id
      viewingOrganization {
        id
        ...ClientFields
        ...MoveProjectJobFields
        ...MoveProjectProjectFields
        ...ProjectInternalFields
        ...ProjectJobAdditionalFields
        ...ProjectJobDispatchFields
        ...ProjectJobLocationFields
      }
    }
  }
`;

MoveProjectModalFields.fragment = gql`
  ${StorageProjectInventoryFields.fragment}
  fragment MoveProjectModalFields on Collection {
    id
    ...StorageProjectInventoryFields
  }
`;

export default MoveProjectModalFields;
