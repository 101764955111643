// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import TimesheetBlockKind from '@shared/modules/Timesheet/enums/TimesheetBlockKind';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const LegendRow = Styled.View`
  flex-direction: row;
  align-items: center;
  height: 24px;
`;

const LegendRowLabel = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
`;

const LegendColorSolid = Styled.View`
  width: ${({width}) => width}px;
  height: ${({height}) => height}px;
  background-color: ${({color}) => color};
`;

const LegendLabel = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const LegendPatternRow = Styled.View`
  flex-direction: row;
  align-items: center;
  height: 4px;
`;

const LegendPatternSpotRow = Styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const LegendColorSpot = Styled.View`
  width: 2px;
  height: 2px;
  background-color: ${({color}) => color};
`;

const LegendPatternedBackground = ({color, rowCount, columnCount}) => {
  return (
    <React.Fragment>
      {Array.from({length: rowCount}, (_, index) => (
        <LegendPatternRow key={index}>
          {Array.from({length: columnCount}, (_, spotIndex) => (
            <LegendPatternSpotRow
              key={spotIndex}
              style={{justifyContent: !(index % 2) ? 'flex-end' : 'center'}}
            >
              <LegendColorSpot color={color} />
            </LegendPatternSpotRow>
          ))}
        </LegendPatternRow>
      ))}
    </React.Fragment>
  );
};

const LegendColor = ({color, backgroundColor, height, width}) => {
  if (!backgroundColor) {
    return <LegendColorSolid color={color} height={height} width={width} />;
  }
  return (
    <LegendColorSolid color={backgroundColor} height={height} width={width}>
      <LegendPatternedBackground color={color} rowCount={height / 4} columnCount={width / 8} />
    </LegendColorSolid>
  );
};

const LegendKey = ({label, color, backgroundColor, responsive}) => {
  return (
    <LegendRow>
      <LegendColor color={color} backgroundColor={backgroundColor} height={16} width={16} />
      <Space width={8} />
      <LegendLabel responsive={responsive}>{label}</LegendLabel>
    </LegendRow>
  );
};

const LegendColumn = ({kind, responsive}) => {
  const label = TimesheetBlockKind.getDisplay(kind);
  const color = TimesheetBlockKind.getColor(kind);
  const backgroundColor = TimesheetBlockKind.getBackgroundColor(kind);
  return (
    <Column>
      <LegendKey label={label} color={color} responsive={responsive} />
      <Space height={4} />
      <LegendKey
        label={label}
        color={color}
        backgroundColor={backgroundColor}
        responsive={responsive}
      />
    </Column>
  );
};

const TimesheetPayrollEntriesLegend = ({responsive, paddingHorizontal}) => {
  return (
    <React.Fragment>
      <Row style={{paddingLeft: paddingHorizontal}}>
        <Column>
          <LegendRow>
            <LegendRowLabel responsive={responsive}>Job Time</LegendRowLabel>
          </LegendRow>
          <Space height={4} />
          <LegendRow>
            <LegendRowLabel responsive={responsive}>Crew Time</LegendRowLabel>
          </LegendRow>
        </Column>
        <Space width={24} />
        <LegendColumn kind={TimesheetBlockKind.WORK} responsive={responsive} />
        <Space width={24} />
        <LegendColumn kind={TimesheetBlockKind.DRIVE} responsive={responsive} />
        <Space width={24} />
        <LegendColumn kind={TimesheetBlockKind.BREAK} responsive={responsive} />
        <Space width={24} />
        <LegendColumn kind={TimesheetBlockKind.WAIT} responsive={responsive} />
      </Row>
    </React.Fragment>
  );
};

TimesheetPayrollEntriesLegend.ColorComponent = LegendColor;
TimesheetPayrollEntriesLegend.LegendKey = LegendKey;

export default TimesheetPayrollEntriesLegend;
