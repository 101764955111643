// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import UpdateJobUserForm from '@shared/modules/User/forms/UpdateJobUserForm';

const useUpdateJobUserV2Mutation = ({updateJobUserForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      updateJobUserForm: UpdateJobUserForm.toForm(updateJobUserForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateJobUserV2Mutation.mutation,
    variables: {
      updateJobUserForm: UpdateJobUserForm.toMutation(form.values.updateJobUserForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateJobUserV2Mutation.mutation = gql`
  mutation useUpdateJobUserV2Mutation($updateJobUserForm: UpdateJobUserForm!) {
    response: updateJobUserV2(updateJobUserForm: $updateJobUserForm) {
      ${gql.errors}
      jobUser {
        id
      }
    }
  }
`;

export default useUpdateJobUserV2Mutation;
