// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

// App
import ProjectDateKind from '@shared/modules/Project/enums/ProjectDateKind';

const edit = withFragment(
  (project, {projectDateKind}) => {
    const jobUuidKey = ProjectDateKind.PROJECT_DATE_KIND_TO_JOB_UUID_MAP[projectDateKind];
    const startDateKey = ProjectDateKind.PROJECT_DATE_KIND_TO_START_DATE_MAP[projectDateKind];
    const endDateKey = ProjectDateKind.PROJECT_DATE_KIND_TO_END_DATE_MAP[projectDateKind];
    const scheduledDateKey =
      ProjectDateKind.PROJECT_DATE_KIND_TO_SCHEDULED_DATE_MAP[projectDateKind];

    return {
      projectId: project.id,
      startDate: project[startDateKey],
      endDate: project[endDateKey],
      scheduledDate: project[scheduledDateKey],
      kind: projectDateKind,
      jobUuid: project[jobUuidKey],
    };
  },
  gql`
    fragment ProjectDatesForm_edit on Project {
      id
      packJobUuid
      packJobStartDate
      packJobEndDate
      packJobScheduledDate
      loadJobUuid
      loadJobStartDate
      loadJobEndDate
      loadJobScheduledDate
      deliveryJobUuid
      deliveryJobStartDate
      deliveryJobEndDate
      deliveryJobScheduledDate
    }
  `,
);

const toForm = ({projectId, startDate, endDate, kind, jobUuid, scheduledDate}) => ({
  projectId,
  jobUuid,
  kind,
  startDate: startDate ? Datetime.fromDate(startDate) : null,
  endDate: endDate ? Datetime.fromDate(endDate) : null,
  scheduledDate: scheduledDate ? Datetime.fromDate(scheduledDate) : null,
});

const toMutation = ({projectId, startDate, endDate, kind, jobUuid, scheduledDate}) => ({
  projectId,
  jobUuid,
  kind,
  startDate: startDate ? Datetime.toMutationDate(startDate) : null,
  endDate: endDate ? Datetime.toMutationDate(endDate) : null,
  scheduledDate: scheduledDate ? Datetime.toMutationDate(scheduledDate) : null,
});

const ProjectDatesForm = {
  edit,
  toForm,
  toMutation,
};

export default ProjectDatesForm;
