// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (slot) => ({
    slotId: slot.id,
    index: slot.index,

    // Private
    isReordering: false,
  }),
  gql`
    fragment ReorderSlotForm_edit on Slot {
      id
      index
    }
  `,
);

const toForm = ({slotId, index, isReordering}) => ({
  slotId,
  index,

  // Private
  isReordering,
});

const toMutation = ({slotId, index}) => ({
  slotId,
  index,
});

const ReorderSlotForm = {
  edit,
  toForm,
  toMutation,
};

export default ReorderSlotForm;
