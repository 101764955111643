// App
import {AttachmentModel} from '@supermove/models';

import AttachmentForm, {AttachmentFormType} from '@shared/modules/File/forms/AttachmentForm';
import UpdateAttachmentForm, {
  UpdateAttachmentFormType,
} from '@shared/modules/File/forms/UpdateAttachmentForm';

export interface UpdateAttachmentsFormType {
  updateAttachmentForms: AttachmentFormType[];
}

const toForm = ({updateAttachmentForms}: UpdateAttachmentsFormType) => ({
  updateAttachmentForms: updateAttachmentForms.map((updateAttachmentForm) =>
    AttachmentForm.toForm(updateAttachmentForm),
  ),
});

const toMutation = ({
  updateAttachmentForms,
}: {
  updateAttachmentForms: UpdateAttachmentFormType[];
}) => {
  return {
    updateAttachmentForms: updateAttachmentForms.map((updateAttachmentForm) => {
      return UpdateAttachmentForm.toMutation(updateAttachmentForm);
    }),
  };
};

const _new = () => ({
  updateAttachmentForms: [] as AttachmentFormType[],
});

const UpdateAttachmentsForm = {
  toForm,
  toMutation,
  new: _new,
};

export default UpdateAttachmentsForm;
