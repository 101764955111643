// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import Modal from '@shared/design/components/Modal';
import ClientFields from 'modules/Client/components/ClientFields';
import StorageProjectInventoryFields from 'modules/Job/V2/Storage/components/StorageProjectInventoryFields';
import StorageProjectStorageFields from 'modules/Job/V2/Storage/components/StorageProjectStorageFields';
import BillingValuesFields from 'modules/Project/Billing/components/BillingValuesFields';
import ProjectInternalFields from 'modules/Project/components/ProjectInternalFields';

const CustomerInformation = ({
  form,
  field,
  index,
  disabled,
  organization,
  isShowingBillingClient,
}) => {
  const isClientSearchEnabled =
    isShowingBillingClient || _.get(form.values, `${field}.isClientSearchEnabled`);
  return (
    <Modal.Block index={index}>
      <ClientFields
        organization={organization}
        form={form}
        field={isShowingBillingClient ? `${field}.billingClientForm` : `${field}.clientForm`}
        isSearchEnabled={isClientSearchEnabled}
        disabled={disabled}
        isShowingBillingClient={isShowingBillingClient}
      />
    </Modal.Block>
  );
};

const StorageInformation = ({form, field, index, organization, isEdit, isFixedBranch}) => {
  return (
    <Modal.Block index={index}>
      <StorageProjectStorageFields
        form={form}
        field={field}
        organization={organization}
        isEdit={isEdit}
        isFixedBranch={isFixedBranch}
      />
    </Modal.Block>
  );
};

const InventoryInformation = ({form, field, index, organization, collections}) => {
  return (
    <Modal.Block index={index}>
      <StorageProjectInventoryFields
        form={form}
        field={field}
        organization={organization}
        collections={collections}
      />
    </Modal.Block>
  );
};

const BillingInformation = ({form, field, index, isEdit}) => {
  return (
    <Modal.Block index={index}>
      <BillingValuesFields form={form} field={field} isEdit={isEdit} />
    </Modal.Block>
  );
};

const InternalInformation = ({form, field, index, organization}) => {
  return (
    <Modal.Block index={index}>
      <ProjectInternalFields
        form={form}
        field={field}
        organization={organization}
        hasWarehouseNotes
        hasStaffInformation
      />
    </Modal.Block>
  );
};

const StorageProjectModalFields = ({
  form,
  field,
  isDisabledClientFields,
  collections,
  isDisabledInventoryInformation,
  isFixedBranch,
  isEdit,
  organization,
}) => {
  return (
    <React.Fragment>
      <CustomerInformation
        form={form}
        field={field}
        index={0}
        disabled={isDisabledClientFields}
        organization={organization}
      />
      <Space height={16} />
      <CustomerInformation
        form={form}
        field={field}
        index={1}
        organization={organization}
        isShowingBillingClient
      />
      <Space height={16} />
      <StorageInformation
        form={form}
        field={field}
        index={1}
        organization={organization}
        isEdit={isEdit}
        isFixedBranch={isFixedBranch}
      />
      {!isDisabledInventoryInformation && (
        <React.Fragment>
          <Space height={16} />
          <InventoryInformation
            form={form}
            field={field}
            index={2}
            organization={organization}
            collections={collections}
          />
        </React.Fragment>
      )}
      <Space height={16} />
      <BillingInformation form={form} field={field} index={3} isEdit={isEdit} />
      <Space height={16} />
      <InternalInformation form={form} field={field} index={4} organization={organization} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageProjectModalFields.fragment = gql`
  ${StorageProjectInventoryFields.fragment}

  fragment StorageProjectModalFields on Collection {
    id
    ...StorageProjectInventoryFields
  }
`;

StorageProjectModalFields.organizationFragment = gql`
  ${ClientFields.fragment}
  ${StorageProjectStorageFields.fragment}
  ${ProjectInternalFields.fragment}

  fragment StorageProjectModalFields_organizationFragment on Organization {
    id
    ...ClientFields
    ...StorageProjectStorageFields
    ...ProjectInternalFields
  }
`;

export default StorageProjectModalFields;
