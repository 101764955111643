// Libraries
import _ from 'lodash';
import React from 'react';

import {Environment, Storage} from '@supermove/sdk';

// APIs
import StripeTerminal from '../../apis/StripeTerminal';

// Components
import StripeTerminalReaderConnectErrorModal from './StripeTerminalReaderConnectErrorModal';

class StripeTerminalConsumer extends React.Component {
  state = {
    status: StripeTerminal.ConnectionStatuses.Connecting,

    // Discover Readers
    isDiscovering: false,
    isDiscovered: false,
    readers: [],

    // Connect to one reader
    isConnecting: false,
    isConnected: false,
    isConnectedError: false,
    reader: null,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentDidUpdate(previousProps) {
    if (!previousProps.isInitialized && this.props.isInitialized) {
      this.fetchConnectedReader();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  fetchConnectedReader = () => {
    this.setState({isConnecting: true}, async () => {
      const {reader} = this.state;
      const connectedReader = await StripeTerminal.getConnectedReader();
      if (!this.mounted) {
        return;
      }

      if (!connectedReader) {
        const serialNumber = await Storage.getItem(StripeTerminal.READER_KEY);
        if (!this.mounted) {
          return;
        }
        if (serialNumber) {
          await this.handleRestoreConnectedReader({serialNumber});
        } else {
          return this.setState({isConnecting: false});
        }
      }

      if (_.get(connectedReader, 'serialNumber') === _.get(reader, 'serialNumber')) {
        return this.setState({isConnecting: false});
      }

      this.setState({
        isConnecting: false,
        isConnected: true,
        reader: connectedReader,
      });
    });
  };

  handleRestoreConnectedReader = async ({serialNumber}) => {
    await this.handleConnectReader({
      deviceType: StripeTerminal.DeviceTypes.Chipper2X,
      isSimulated: Environment.isLocal(),
      serialNumber,
    });
  };

  handleSaveConnectedReader = async (reader) => {
    const {serialNumber} = reader;
    if (serialNumber) {
      await Storage.setItem(StripeTerminal.READER_KEY, serialNumber);
    }
  };

  handleDiscoverReaders = ({deviceType, timeout, simulator}) => {
    if (!this.props.isInitialized) {
      return console.log('Stripe Terminal not initialized, cannot call discoverReaders.');
    }

    console.log('Discovering readers...');
    this.setState({isDiscovering: true, isDiscovered: false}, async () => {
      try {
        const readers = await StripeTerminal.discoverReaders({deviceType, timeout, simulator});
        if (this.mounted) {
          this.setState({isDiscovering: false, isDiscovered: true, readers});
        }
      } catch (error) {
        console.log('Error discovering readers', {error});
        if (this.mounted) {
          this.setState({isDiscovering: false, isDiscovered: true, readers: []});
        }
      }
    });
  };

  handleConnectReader = (reader) => {
    if (!this.props.isInitialized) {
      return console.log('Stripe Terminal not initialized, cannot call connectReader.');
    }

    const {deviceType, isSimulated, serialNumber} = reader;
    console.log(`Connecting to reader: ${serialNumber}...`);
    this.setState({isConnecting: true, isConnected: false, reader}, async () => {
      try {
        const reader = await StripeTerminal.connectReader({
          deviceType,
          simulator: isSimulated,
          serialNumber,

          // Attempt to connect for 10 seconds.
          timeout: 10,
        });
        if (this.mounted) {
          console.log(`Connected successfully to: ${reader.serialNumber}`);
          this.setState({isConnecting: false, isConnected: true, reader}, async () => {
            // Save the connected reader as the most recent on this device.
            await this.handleSaveConnectedReader(reader);
          });
        }
      } catch (error) {
        console.log('Error connecting to reader', {error});
        if (this.mounted) {
          this.setState({
            isConnecting: false,
            isConnected: false,
            isConnectedError: true,
            reader: null,
          });
        }
      }
    });
  };

  render() {
    const {isInitialized} = this.props;
    const {
      status,

      // Discover Readers
      isDiscovering,
      isDiscovered,
      readers,

      // Connect to Reader
      isConnecting,
      isConnected,
      isConnectedError,
      reader,
    } = this.state;

    return (
      <StripeTerminalReaderConnectErrorModal
        isOpen={isConnectedError}
        onClose={() => this.setState({isConnectedError: false})}
      >
        {this.props.children({
          isInitialized,
          status,

          // Discover Reader
          isDiscovering,
          isDiscovered,
          readers,

          // Connect to Reader
          isConnecting,
          isConnected,
          reader,

          // Handlers
          discoverReaders: this.handleDiscoverReaders,
          connectReader: this.handleConnectReader,
        })}
      </StripeTerminalReaderConnectErrorModal>
    );
  }
}

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
StripeTerminalConsumer.propTypes = {};

StripeTerminalConsumer.defaultProps = {};

export default StripeTerminalConsumer;
