const JOB_SYNC = 'JOB_SYNC';

const KIND_DISPLAY_MAP = {
  [JOB_SYNC]: 'Job Saved',
};

const DROPDOWN_OPTIONS = [
  {
    label: KIND_DISPLAY_MAP[JOB_SYNC],
    value: JOB_SYNC,
  },
];

const getDisplayKind = (webhookKind) => {
  return KIND_DISPLAY_MAP[webhookKind];
};

export default {
  JOB_SYNC,
  DROPDOWN_OPTIONS,
  getDisplayKind,
};
