const _new = ({tripId, shipmentIds}) => ({
  tripId,
  shipmentIds,
});

const toForm = ({tripId, shipmentIds}) => {
  return {
    tripId,
    shipmentIds,
  };
};

const toMutation = ({tripId, shipmentIds}) => {
  return {
    tripId,
    shipmentIds,
  };
};

const AssignShipmentsToTripForm = {
  new: _new,
  toForm,
  toMutation,
};

export default AssignShipmentsToTripForm;
