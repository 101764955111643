const _new = ({dayId}) => ({
  dayId,
});

const toForm = ({dayId}) => ({
  dayId,
});

const toMutation = ({dayId}) => ({
  dayId,
});

const SlotForm = {
  new: _new,
  toForm,
  toMutation,
};

export default SlotForm;
