// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useEffect, useRef} from '@supermove/hooks';

const Wrapper = Styled.View`
  margin-bottom: 10px;
  z-index: ${(props) => 100 - props.index};
`;

const DocumentV2Text = ({html, index}) => {
  const divRef = useRef(null);
  useEffect(() => {
    if (!!html && divRef.current) {
      const htmlWithLineBreaks = html.replace(/(?:\r\n|\r|\n)/g, '<br>');
      divRef.current.innerHTML = htmlWithLineBreaks;
    }
  }, [html]);
  return (
    <Wrapper index={index}>{!!html && <div className='document-text-item' ref={divRef} />}</Wrapper>
  );
};

export default DocumentV2Text;
