// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import CostCategoryKind from '@shared/modules/Billing/enums/CostCategoryKind';

const getDisplayKind = withFragment(
  (costCategory, {isShortened} = {}) => {
    switch (costCategory.kind) {
      case CostCategoryKind.COMPENSATION:
        return isShortened ? 'Compensation' : 'Employee Compensation';
      default:
        return 'Other Costs';
    }
  },
  gql`
    fragment CostCategory_getDisplayKind on CostCategory {
      kind
    }
  `,
);

const CostCategory = {
  getDisplayKind,
};

export default CostCategory;
