// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
`;

const Metric = Styled.View`
  width: 50px;
  align-items: flex-end;
`;

const MetricLabel = Styled.H8`
  color: ${colors.gray.tertiary};
  text-transform: uppercase;
  ${fontWeight(500)}
`;

const MetricValue = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const CollectionMetrics = ({collection, showWeight = true, showVolume = true}) => {
  return (
    <Container>
      <Metric>
        <MetricLabel>Take</MetricLabel>
        <Space height={4} />
        <MetricValue>{collection.takeCount}</MetricValue>
      </Metric>
      <Metric>
        <MetricLabel>Leave</MetricLabel>
        <Space height={4} />
        <MetricValue>{collection.leaveCount}</MetricValue>
      </Metric>
      {showVolume && (
        <Metric>
          <MetricLabel>CU FT</MetricLabel>
          <Space height={4} />
          <MetricValue>{_.round(collection.totalVolume, 2)}</MetricValue>
        </Metric>
      )}
      {showWeight && (
        <Metric>
          <MetricLabel>LB</MetricLabel>
          <Space height={4} />
          <MetricValue>{_.round(collection.totalWeight, 2)}</MetricValue>
        </Metric>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CollectionMetrics.fragment = gql`
  fragment CollectionMetrics on Collection {
    id
    takeCount
    leaveCount
    totalVolume
    totalWeight
  }
`;

export default CollectionMetrics;
