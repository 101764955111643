// Supermove
import {gql} from '@supermove/graphql';
import {Json, withFragment} from '@supermove/utils';

const copy = withFragment(
  (customer) => ({
    userId: customer.id,
    organizationId: customer.organizationId,
    names: customer.names,
    aliasName: customer.aliasName,
    firstName: customer.firstName,
    lastName: customer.lastName,
    phoneNumber: customer.phoneNumber,
    email: customer.email,
  }),
  gql`
    fragment CustomerForm_copy on User {
      id
      organizationId
      names
      aliasName
      firstName
      lastName
      phoneNumber
      email
    }
  `,
);

const edit = withFragment(
  (customer) => ({
    userId: customer.id,
    organizationId: customer.organizationId,
    names: customer.names,
    aliasName: customer.aliasName,
    firstName: customer.firstName,
    lastName: customer.lastName,
    phoneNumber: customer.phoneNumber,
    email: customer.email,
  }),
  gql`
    fragment CustomerForm_edit on User {
      id
      organizationId
      names
      aliasName
      firstName
      lastName
      phoneNumber
      email
    }
  `,
);

const _new = ({organizationId}) => ({
  userId: undefined,
  organizationId,
  names: '["", ""]',
  aliasName: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
});

const toForm = ({
  userId,
  organizationId,
  names,
  aliasName,
  firstName,
  lastName,
  phoneNumber,
  email,
}) => ({
  userId,
  organizationId,
  names: Json.toForm(names),
  aliasName,
  firstName,
  lastName,
  phoneNumber,
  email,
});

const toMutation = ({
  userId,
  organizationId,
  names,
  aliasName,
  firstName,
  lastName,
  phoneNumber,
  email,
}) => ({
  userId,
  organizationId,
  names: Json.toMutation(names.filter(Boolean).map((name) => name.trim())),
  aliasName,
  firstName,
  lastName,
  phoneNumber,
  email,
});

const CustomerForm = {
  copy,
  edit,
  new: _new,

  toForm,
  toMutation,
};

export default CustomerForm;
