// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import TextTooltip from 'modules/App/components/TextTooltip';

const Row = Styled.View`
  flex-direction: row;
`;

const ScheduleSummariesListItemContainer = Styled.Button`
  background-color: ${(props) => props.color};
  flex-direction: row;
  box-shadow: none;
  align-items: flex-start;
  justify-content: flex-start;
  padding-vertical: ${(props) => (props.responsive.mobile ? '12px' : '8px')};
  height: auto;
  border-radius: 0px;
`;

const ScheduleDaysContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1px;
`;

const ScheduleDayBox = Styled.View`
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.background};
  border-color: ${(props) => props.borderColor}
  border-width: 1px;
  align-items: center;
  justify-content: center;
`;

const ScheduleDay = Styled.H8`
  font-size: 11px;
  ${fontWeight(700)}
  color: ${(props) => props.color};
  width: 16px;
  text-align: center;
`;

const CheckBox = Styled.View`
  height: 14px;
  width: 14px;
  background-color: ${(props) => (props.isChecked ? colors.blue.interactive : colors.white)};
  border-color: ${(props) => (props.isChecked ? colors.blue.interactive : colors.gray.tertiary)};
  border-width: 1px;
  border-radius: 2px;
  margin-top: 2px;
  align-items: center;
`;

const NameAndBadgesContainer = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`;

const NameText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  margin-right: 4px;
`;

const MoverBadge = Styled.View`
  height: 16px;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
  padding-horizontal: 4px;
  border-radius: 2px;
  margin-vertical: 2px;
`;

const MoverBadgeText = Styled.H8`
  ${fontWeight(500)}
  color: ${(props) => props.color};
  margin-top: 1px;
  text-transform: capitalize;
`;

const getIsAvailableForJob = ({job, scheduleDays}) => {
  const jobDay = _.get(job, 'day.value');
  const scheduleJobDay = _.find(
    scheduleDays,
    (scheduleDay) => jobDay === _.get(scheduleDay, 'day.value'),
  );
  return !_.get(scheduleJobDay, 'userDay') || scheduleJobDay.userDay.scheduleStatus === 'AVAILABLE';
};

const getScheduleDayBoxBackgroundColor = ({isJobOnScheduleDay, isAvailable}) => {
  if (isJobOnScheduleDay && isAvailable) {
    return colors.green.accent;
  }
  if (isAvailable) {
    return colors.green.status;
  }
  return colors.gray.tertiary;
};

const getScheduleDayBoxBorderColor = ({isJobOnScheduleDay, isAvailable}) => {
  if (isJobOnScheduleDay) {
    return colors.gray.primary;
  }
  if (isAvailable) {
    return colors.green.status;
  }
  return colors.gray.tertiary;
};

const getScheduleDayBoxTextColor = ({isJobOnScheduleDay, isAvailable}) => {
  if (isJobOnScheduleDay && isAvailable) {
    return colors.black;
  }
  if (isJobOnScheduleDay && !isAvailable) {
    return colors.black;
  }
  return colors.white;
};

const getListItemBackgroundColor = ({isHovered, isSelected, isDisabled}) => {
  if (isDisabled) {
    return colors.gray.disabled;
  }
  if (isHovered || isSelected) {
    return colors.hover;
  }
  return colors.white;
};

const getIsJobOnScheduleDay = ({job, scheduleDay}) => {
  const isEstimatedRange = Job.getIsEstimatedRange(job);
  if (isEstimatedRange) {
    const isOnOrAfterStartDate = scheduleDay.date >= job.startDate;
    const isOnOrBeforeEndDate = scheduleDay.date <= job.endDate;
    return isOnOrAfterStartDate && isOnOrBeforeEndDate;
  }
  return _.get(job, 'day.value') === _.get(scheduleDay, 'day.value');
};

const HelperTooltip = ({isAvailableForJob, children}) => {
  if (!isAvailableForJob) {
    return <TextTooltip text={'This mover is unavailable for this job.'}>{children}</TextTooltip>;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const CrewUsersAndDriversSchedulesListItem = ({
  crew,
  userSchedule,
  isSelected,
  handleAddUser,
  handleRemoveUser,
}) => {
  const {user, scheduleDays} = userSchedule;
  const isAvailableForJob = getIsAvailableForJob({job: crew.job, scheduleDays});
  const {isHovered, ref} = useHover();
  const responsive = useResponsive();
  const handlePress = () => {
    if (!isSelected) {
      handleAddUser();
    } else {
      handleRemoveUser();
    }
  };
  const jobUser = _.find(crew.job.jobUsers, ['userId', _.toNumber(user.id)]);
  const isJobUserOnTrip = _.get(jobUser, 'isOnTrip');
  const isDisabled = (!isAvailableForJob && !isSelected) || isJobUserOnTrip;
  const moverPosition = jobUser ? jobUser.moverPosition : user.moverPosition;

  return (
    <ScheduleSummariesListItemContainer
      ref={ref}
      color={getListItemBackgroundColor({isHovered, isSelected, isDisabled})}
      onPress={handlePress}
      disabled={isDisabled}
      responsive={responsive}
    >
      <Space width={16} />
      {isJobUserOnTrip ? (
        <TextTooltip text={'A trip driver can only be removed by editing the trip.'}>
          <Row>
            <Space width={1} />
            <Icon
              source={Icon.Lock}
              size={14}
              color={colors.gray.secondary}
              style={{paddingTop: 1}}
            />
            <Space width={1} />
          </Row>
        </TextTooltip>
      ) : (
        <CheckBox isChecked={isSelected}>
          <Icon source={Icon.Check} size={12} color={colors.white} />
        </CheckBox>
      )}
      <Space width={5} />
      <HelperTooltip isAvailableForJob={isAvailableForJob}>
        <NameAndBadgesContainer>
          <NameText numberOfLines={1}>{user.fullName}</NameText>
          <MoverBadge color={colors.getBackgroundColor(colors.gray.secondary)}>
            <MoverBadgeText color={colors.gray.secondary}>{moverPosition.name}</MoverBadgeText>
          </MoverBadge>
          <Space width={4} />
        </NameAndBadgesContainer>
      </HelperTooltip>
      <Space width={4} />
      <ScheduleDaysContainer>
        {scheduleDays.map((scheduleDay, index) => {
          const isJobOnScheduleDay = getIsJobOnScheduleDay({job: crew.job, scheduleDay});
          const isAvailable =
            !scheduleDay.userDay || scheduleDay.userDay.scheduleStatus === 'AVAILABLE';
          return (
            <ScheduleDayBox
              key={index}
              background={getScheduleDayBoxBackgroundColor({isJobOnScheduleDay, isAvailable})}
              borderColor={getScheduleDayBoxBorderColor({isJobOnScheduleDay, isAvailable})}
            >
              <ScheduleDay color={getScheduleDayBoxTextColor({isJobOnScheduleDay, isAvailable})}>
                {scheduleDay.jobs.length}
              </ScheduleDay>
            </ScheduleDayBox>
          );
        })}
        <Space width={20} />
      </ScheduleDaysContainer>
    </ScheduleSummariesListItemContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewUsersAndDriversSchedulesListItem.fragment = gql`
  ${Job.getIsEstimatedRange.fragment}

  fragment CrewUsersAndDriversSchedulesListItem_Crew on Crew {
    id
    job {
      id
      startDate
      endDate
      day {
        id
        value
      }
      jobUsers {
        id
        userId
        isOnTrip
        moverPosition {
          id
          name
        }
      }
      ...Job_getIsEstimatedRange
    }
  }
  fragment CrewUsersAndDriversSchedulesListItem_EmployeeSchedule on EmployeeSchedule {
    user {
      id
      fullName
      moverPosition {
        id
        name
      }
    }
    scheduleDays: employeeScheduleDays {
      date
      day {
        id
        value
      }
      userDay {
        id
        scheduleStatus
      }
      jobs {
        id
      }
    }
  }
`;

export default CrewUsersAndDriversSchedulesListItem;
