// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

const Image = Styled.Image`
`;

const OrganizationLogo = ({organization, style}) => {
  return (
    <React.Fragment>
      {!!organization.logoUri && (
        <Image resizeMode={'contain'} source={{uri: organization.logoUri}} style={style} />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
OrganizationLogo.propTypes = {
  organization: PropTypes.object.isRequired,
  style: PropTypes.object,
};

OrganizationLogo.defaultProps = {
  style: {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationLogo.fragment = gql`
  fragment OrganizationLogo on Organization {
    id
    logoUri
  }
`;

export default OrganizationLogo;
