const _new = ({organizationId}) => ({
  fileId: null,
  organizationId,
});

const toForm = ({fileId, organizationId}) => ({
  fileId,
  organizationId,
});

const toMutation = ({fileId, organizationId}) => ({
  fileId,
  organizationId,
});

const ImportOrganizationProjectsForm = {
  new: _new,
  toForm,
  toMutation,
};

export default ImportOrganizationProjectsForm;
