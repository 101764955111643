// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Shared
import ProjectTypeRoomTypeForm from '@shared/modules/Inventory/forms/ProjectTypeRoomTypeForm';

const edit = withFragment(
  (projectType) => ({
    projectTypeRoomTypeForms: projectType.projectTypeRoomTypes.map((projectTypeRoomType) => {
      return ProjectTypeRoomTypeForm.edit(projectTypeRoomType);
    }),
  }),
  gql`
    ${ProjectTypeRoomTypeForm.edit.fragment}
    fragment ReorderProjectTypeRoomTypesForm_edit on ProjectType {
      id
      projectTypeRoomTypes {
        id
        ...ProjectTypeRoomTypeForm_edit
      }
    }
  `,
);

const toForm = ({projectTypeRoomTypeForms}) => ({
  projectTypeRoomTypeForms: projectTypeRoomTypeForms.map((projectTypeRoomTypeForm) => {
    return ProjectTypeRoomTypeForm.toForm(projectTypeRoomTypeForm);
  }),
});

const toMutation = ({projectTypeRoomTypeForms}) => ({
  projectTypeRoomTypeForms: projectTypeRoomTypeForms.map((projectTypeRoomTypeForm) => {
    return ProjectTypeRoomTypeForm.toMutation(projectTypeRoomTypeForm);
  }),
});

const ReorderProjectTypeRoomTypesForm = {
  edit,
  toForm,
  toMutation,
};

export default ReorderProjectTypeRoomTypesForm;
