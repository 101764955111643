// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import TagForm from '@shared/modules/Tag/forms/TagForm';

const useArchiveTagMutation = ({tagForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      tagForm: TagForm.toForm(tagForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useArchiveTagMutation.mutation,
    variables: {
      tagForm: TagForm.toMutation(form.values.tagForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useArchiveTagMutation.mutation = gql`
  mutation useArchiveTagMutation($tagForm: TagForm!) {
    response: archiveTag(tagForm: $tagForm) {
      ${gql.errors}
      tag {
        id
      }
    }
  }
`;

export default useArchiveTagMutation;
