const HOURS = 'HOURS';
const DAYS = 'DAYS';
const WEEKS = 'WEEKS';

const VALUES = [HOURS, DAYS, WEEKS];

const getDisplayValue = ({value, isPlural}) => {
  const pluralValue = value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();
  const singularValue = pluralValue.substr(0, pluralValue.length - 1);
  return isPlural ? pluralValue : singularValue;
};

const DelayUnitType = {
  // Enum Options
  HOURS,
  DAYS,
  WEEKS,

  VALUES,
  getDisplayValue,
};

export default DelayUnitType;
