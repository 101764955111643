// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import CreateTimesheetBlockForm from '@shared/modules/Timesheet/forms/CreateTimesheetBlockForm';

const getInitialTimesheetBillableEntriesForJob = ({job}) => {
  const testerBlock = _.last(_.get(job, 'timesheetBillableEntries.0.timesheetBlocks'));
  const isAllSameEndTime = job.timesheetBillableEntries.every((entry) => {
    const lastBlock = _.last(entry.timesheetBlocks);
    return lastBlock?.rangeTo === testerBlock?.rangeTo;
  });
  if (isAllSameEndTime) {
    return job.timesheetBillableEntries;
  }
  return [];
};

const getCreateTimesheetBlockForms = ({timesheetBillableEntries}) => {
  const timesheetBillableEntry = timesheetBillableEntries[0];
  const timesheetBlock = _.last(timesheetBillableEntry?.timesheetBlocks);
  const rangeFromDate = timesheetBillableEntry?.timesheetBlocksDateRange?.end || '';
  return [CreateTimesheetBlockForm.new({rangeFrom: timesheetBlock?.rangeTo, rangeFromDate})];
};

const newForJob = withFragment(
  ({job}) => {
    const timesheetBillableEntries = getInitialTimesheetBillableEntriesForJob({job});
    return {
      timesheetBillableEntryIds: timesheetBillableEntries.map((entry) => entry.id),
      createTimesheetBlockForms: getCreateTimesheetBlockForms({timesheetBillableEntries}),
    };
  },
  gql`
    fragment CreateTimesheetBillableBlocksForm_newForJob on Job {
      id
      timesheetBillableEntries {
        id
        timesheetBlocksDateRange {
          end
        }
        timesheetBlocks {
          id
          rangeTo
        }
      }
    }
  `,
);

const toForm = ({timesheetBillableEntryIds, createTimesheetBlockForms}) => ({
  timesheetBillableEntryIds,
  createTimesheetBlockForms: createTimesheetBlockForms.map(CreateTimesheetBlockForm.toForm),
});

const toMutation = ({timesheetBillableEntryIds, createTimesheetBlockForms}) => ({
  timesheetBillableEntryIds,
  createTimesheetBlockForms: createTimesheetBlockForms.map(CreateTimesheetBlockForm.toMutation),
});

const CreateTimesheetBillableBlocksForm = {
  newForJob,
  toForm,
  toMutation,
};

export default CreateTimesheetBillableBlocksForm;
