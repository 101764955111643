// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

const API_PROPERTY = {
  name: 'name',
  assignedToUserId: 'assigned_to_user_id',
  assignedToTeamId: 'assigned_to_team_id',
  isArchived: 'is_archived',
  isCompleted: 'is_completed',
};

const WORKFLOW_BUILDER_TASK_PROPERTY_NAME = 'name';
const WORKFLOW_BUILDER_TASK_PROPERTY_DUE_AT = 'dueAt';
const WORKFLOW_BUILDER_TASK_PROPERTY_ASSIGNEE = 'assignee';
const WORKFLOW_BUILDER_TASK_PROPERTY_DETAILS = 'details';

const WORKFLOW_BUILDER_TASK_PROPERTY = {
  NAME: WORKFLOW_BUILDER_TASK_PROPERTY_NAME,
  DUE_AT: WORKFLOW_BUILDER_TASK_PROPERTY_DUE_AT,
  ASSIGNEE: WORKFLOW_BUILDER_TASK_PROPERTY_ASSIGNEE,
  DETAILS: WORKFLOW_BUILDER_TASK_PROPERTY_DETAILS,
};

const WORKFLOW_BUILDER_TASK_PROPERTY_DROPDOWN_OPTIONS = [
  {value: WORKFLOW_BUILDER_TASK_PROPERTY_NAME, label: 'Name'},
  {value: WORKFLOW_BUILDER_TASK_PROPERTY_DUE_AT, label: 'Due date and time'},
  {value: WORKFLOW_BUILDER_TASK_PROPERTY_ASSIGNEE, label: 'Assignee'},
  {value: WORKFLOW_BUILDER_TASK_PROPERTY_DETAILS, label: 'Details'},
];

const getAssigneeDropdownValue = withFragment(
  ({assignedToUserId, assignedToTeamId}) => {
    if (assignedToUserId) {
      return `USER-${assignedToUserId}`;
    }
    if (assignedToTeamId) {
      return `TEAM-${assignedToTeamId}`;
    }
    return '';
  },
  gql`
    fragment Task_getAssigneeDropdownValue on Task {
      id
      assignedToUserId
      assignedToTeamId
    }
  `,
);

const getDisplayDate = withFragment(
  (task, format = 'ddd M/D, h:mm A') => {
    return Datetime.toDisplayDate(Datetime.fromDatetimeToUtc(task.dueAt), format);
  },
  gql`
    fragment Task_getDisplayDate on Task {
      id
      dueAt
    }
  `,
);

const getDisplayTime = withFragment(
  (task, format = 'h:mm A') => {
    return Datetime.toDisplayDate(Datetime.fromDatetimeToUtc(task.dueAt), format);
  },
  gql`
    fragment Task_getDisplayTime on Task {
      id
      dueAt
    }
  `,
);

const getIsPastDue = withFragment(
  (task) => {
    if (!task.dueAt) {
      return false;
    }
    const currentDatetime = Datetime.toDate(Datetime.now, Datetime.SORT_DATETIME);
    const taskDueDatetime = getDisplayDate(task, Datetime.SORT_DATETIME);
    return currentDatetime > taskDueDatetime;
  },
  gql`
    ${getDisplayDate.fragment}
    fragment Task_getIsPastDue on Task {
      id
      dueAt
      ...Task_getDisplayDate
    }
  `,
);

const getIsDueLaterToday = withFragment(
  (task) => {
    if (!task.dueAt) {
      return false;
    }
    const currentDate = Datetime.toDate(Datetime.now, Datetime.SERVER_DATE);
    const taskDueDate = getDisplayDate(task, Datetime.SERVER_DATE);
    if (currentDate === taskDueDate) {
      return !getIsPastDue(task);
    }
    return false;
  },
  gql`
    ${getIsPastDue.fragment}
    fragment Task_getIsDueLaterToday on Task {
      id
      dueAt
      ...Task_getIsPastDue
    }
  `,
);

const groupByPendingOrCompleted = withFragment(
  (tasks) => {
    const groupedTasks = _.groupBy(tasks, 'isCompleted');
    return {
      pendingTasks: groupedTasks.false || [],
      completedTasks: groupedTasks.true || [],
    };
  },
  gql`
    fragment Task_groupByPendingOrCompleted on Task {
      id
      isCompleted
    }
  `,
);

const Task = {
  API_PROPERTY,
  WORKFLOW_BUILDER_TASK_PROPERTY,
  WORKFLOW_BUILDER_TASK_PROPERTY_DROPDOWN_OPTIONS,

  getAssigneeDropdownValue,
  getDisplayDate,
  getDisplayTime,
  getIsPastDue,
  getIsDueLaterToday,
  groupByPendingOrCompleted,
};

export default Task;
