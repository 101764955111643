// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import useUpdateReportDashboardMutation from '@shared/modules/Reports/hooks/useUpdateReportDashboardMutation';
import ReportDashboardModalFields from 'modules/Organization/Settings/Reports/components/ReportDashboardModalFields';

const EditReportDashboardModal = ({reportDashboardForm, isOpen, handleClose, refetch}) => {
  const {form, submitting, handleSubmit} = useUpdateReportDashboardMutation({
    reportDashboardForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: () => {},
  });

  return (
    <React.Fragment>
      <SmallModal isOpen={isOpen} style={{overflow: 'visible'}}>
        <SmallModal.HeaderText>{'Edit Report'}</SmallModal.HeaderText>
        <Space height={16} />
        <ReportDashboardModalFields form={form} field={'reportDashboardForm'} />
        <SmallModal.Footer>
          <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
          <SmallModal.Button
            color={colors.blue.interactive}
            onPress={handleSubmit}
            isSubmitting={submitting}
          >
            <Icon source={Icon.Check} color={colors.white} size={12} />
            <Space width={6} />
            Save
          </SmallModal.Button>
        </SmallModal.Footer>
      </SmallModal>
    </React.Fragment>
  );
};

export default EditReportDashboardModal;
