// Supermove
import {gql} from '@supermove/graphql';
import {Currency, withFragment} from '@supermove/utils';

const _new = ({moverPositionId, moverPositionName}) => ({
  moverPositionId,
  amount: '',
  amountFormulaId: null,
  externalInvoiceItemId: null,
});

const edit = withFragment(
  (childBillItemType) => ({
    moverPositionId: childBillItemType.moverPositionId,
    amount: childBillItemType.amount,
    amountFormulaId: childBillItemType.amountFormulaId,
    externalInvoiceItemId: childBillItemType.externalInvoiceItem?.id,
  }),
  gql`
    fragment BillItemTypeMoverPositionRateForm_edit on BillItemType {
      moverPositionId
      amount
      amountFormulaId
      externalInvoiceItem {
        id
      }
    }
  `,
);

const toForm = ({moverPositionId, amount, amountFormulaId, externalInvoiceItemId}) => {
  return {
    moverPositionId,
    amount: Currency.toForm(amount),
    amountFormulaId,
    externalInvoiceItemId,
  };
};

const toMutation = ({moverPositionId, amount, amountFormulaId, externalInvoiceItemId}) => {
  return {
    moverPositionId,
    amount: amountFormulaId ? 0 : Currency.toMutation(amount),
    amountFormulaId,
    externalInvoiceItemId,
  };
};

const BillItemTypeMoverPositionRateForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default BillItemTypeMoverPositionRateForm;
