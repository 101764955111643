const _new = (collectionIds) => ({
  containerId: null,
  collectionIds,
});

const toForm = ({containerId, collectionIds}) => ({
  containerId,
  collectionIds,
});

const toMutation = ({containerId, collectionIds}) => {
  return {
    containerId,
    collectionIds,
  };
};

const AssignCollectionsToContainerForm = {
  new: _new,
  toForm,
  toMutation,
};

export default AssignCollectionsToContainerForm;
