// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useEffect, useState} from '@supermove/hooks';
import {TimesheetBlock} from '@supermove/models';
import {Datetime} from '@supermove/utils';

// App
import TimesheetEntriesSlideViewer from '@shared/modules/Timesheet/components/TimesheetEntriesSlideViewer';
import TimesheetEntryCard from '@shared/modules/Timesheet/components/TimesheetEntryCard';
import TimesheetBlockForm from '@shared/modules/Timesheet/forms/TimesheetBlockForm';

const getSingleEntryId = ({form, field}) => {
  return _.get(form.values, `${field}.timesheetBillableEntryId`);
};

const getValidTimesheetBillableEntries = ({form, field, job}) => {
  const singleEntryId = getSingleEntryId({form, field});
  if (singleEntryId) {
    return job.timesheetBillableEntries.filter((entry) => entry.id === singleEntryId);
  }

  const hasMultiEntrySelections = _.some(_.get(form.values, `${field}.timesheetBillableEntryIds`));
  const newBlockStartTime = _.get(form.values, `${field}.createTimesheetBlockForms.0.rangeFrom`);
  return job.timesheetBillableEntries.filter((entry) => {
    if (!hasMultiEntrySelections) {
      return true;
    }

    const isValidTimeFormat = /^(([01]?[1-9]|1[0-2]):[0-5][0-9] (AM|PM))?$/.test(newBlockStartTime);
    if (!isValidTimeFormat) {
      return true;
    }

    const lastBlock = _.last(entry.timesheetBlocks);
    if (!lastBlock) {
      return true;
    }

    return TimesheetBlockForm.getFormRangeTime({datetime: lastBlock.rangeTo}) === newBlockStartTime;
  });
};

const useSetValidEntriesHandler = ({form, field, job, setValidEntries}) => {
  const singleEntryId = getSingleEntryId({form, field});
  const selectedEntriesCount = singleEntryId
    ? 1
    : _.get(form.values, `${field}.timesheetBillableEntryIds`).length;
  useEffect(() => {
    const startTimeInput = _.get(form.values, `${field}.createTimesheetBlockForms.0.rangeFrom`);
    const isValidTimeFormat = /^(([01]?[1-9]|1[0-2]):[0-5][0-9] (AM|PM))?$/.test(startTimeInput);
    if (isValidTimeFormat) {
      setValidEntries(getValidTimesheetBillableEntries({form, field, job}));
    }
  }, [selectedEntriesCount]); // eslint-disable-line react-hooks/exhaustive-deps
};

const useResetErrorsHandler = ({form, field}) => {
  const startTime = _.get(form.values, `${field}.createTimesheetBlockForms.0.rangeFrom`);
  useEffect(() => {
    if (_.some(form.errors)) {
      form.setErrors({});
    }
  }, [startTime]); // eslint-disable-line react-hooks/exhaustive-deps
};

const TimesheetBillableEntryCard = ({
  timesheetEntry: timesheetBillableEntry,
  form,
  field,
  job,
  responsive,
}) => {
  if (!timesheetBillableEntry) {
    return <TimesheetEntryCard.Placeholder />;
  }

  const timesheetBillableEntryIdsField = `${field}.timesheetBillableEntryIds`;
  const timesheetBlockField = `${field}.createTimesheetBlockForms.0`;

  const singleEntryId = getSingleEntryId({form, field});
  const selectedIds = singleEntryId
    ? [singleEntryId]
    : _.get(form.values, timesheetBillableEntryIdsField);
  const isSelected = selectedIds.includes(timesheetBillableEntry.id);

  const {moverPosition, timesheetBlocks} = timesheetBillableEntry;
  const lastBlock = _.last(timesheetBlocks);

  return (
    <TimesheetEntryCard
      isSelected={isSelected}
      isDisabled={!!singleEntryId}
      responsive={responsive}
      label={moverPosition.name}
      description={
        lastBlock ? `Last time ${TimesheetBlock.getDisplayRange(lastBlock)}` : `No times added yet.`
      }
      onPress={() => {
        const isRemoving = selectedIds.includes(timesheetBillableEntry.id);
        const isAdding = !isRemoving;
        const lastBlockRangeTo = lastBlock?.rangeTo;
        const updatedIds = _.xor(selectedIds, [timesheetBillableEntry.id]);

        if (isAdding && lastBlockRangeTo) {
          form.setFieldValue(
            `${timesheetBlockField}.rangeFrom`,
            TimesheetBlock.getDisplayRangeTo(lastBlock, {format: Datetime.FORM_TIME}),
          );
          form.setFieldValue(`${timesheetBlockField}.isFixedRangeFrom`, true);
        }

        if (isRemoving && lastBlockRangeTo) {
          const selectedEntries = job.timesheetBillableEntries.filter((entry) =>
            updatedIds.includes(entry.id),
          );
          const selectedEntriesLastBlockRangeTo = selectedEntries
            .map((entry) => _.last(entry.timesheetBlocks)?.rangeTo)
            .filter(Boolean);

          if (_.isEmpty(selectedEntriesLastBlockRangeTo)) {
            form.setFieldValue(`${timesheetBlockField}.rangeFrom`, '');
            form.setFieldValue(`${timesheetBlockField}.isFixedRangeFrom`, false);
          }
        }

        form.setFieldValue(
          `${timesheetBlockField}.rangeFromDate`,
          timesheetBillableEntry.timesheetBlocksDateRange.end,
        );
        form.setFieldValue(timesheetBillableEntryIdsField, updatedIds);
      }}
    />
  );
};

const TimesheetBillableEntrySelector = ({form, field, job}) => {
  const [validEntries, setValidEntries] = useState(
    getValidTimesheetBillableEntries({form, field, job}),
  );

  useSetValidEntriesHandler({form, field, job, setValidEntries});
  useResetErrorsHandler({form, field});

  const timesheetBillableEntryIdsError = _.get(form.errors, `${field}.timesheetBillableEntryIds`);

  return (
    <TimesheetEntriesSlideViewer
      timesheetEntries={validEntries}
      timesheetEntryIdsError={timesheetBillableEntryIdsError}
      TimesheetEntryCardComponent={TimesheetBillableEntryCard}
      timesheetEntryCardComponentProps={{form, field, job}}
      emptyMessage={'No valid positions for start time.'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TimesheetBillableEntrySelector.fragment = gql`
  ${TimesheetBlock.getDisplayRangeTo.fragment}
  ${TimesheetBlock.getDisplayRange.fragment}

  fragment TimesheetBillableEntrySelector on Job {
    id
    timesheetBillableEntries {
      id
      kind
      moverPosition {
        id
        name
      }
      timesheetBlocksDateRange {
        end
      }
      timesheetBlocks {
        id
        ...TimesheetBlock_getDisplayRangeTo
        ...TimesheetBlock_getDisplayRange
      }
    }
  }
`;

export default TimesheetBillableEntrySelector;
