// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled, Tooltip} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Text = Styled.H7`
  color: ${colors.white};
  ${fontWeight(500)};
`;

const ContentContainer = Styled.View`
`;

const TooltipOverlay = ({text, placement, numberOfLines, style, getTooltipContainer, ...props}) => {
  return (
    <Tooltip
      overlay={
        <Text numberOfLines={numberOfLines} style={style}>
          {text}
        </Text>
      }
      placement={placement}
      mouseEnterDelay={0.0}
      mouseLeaveDelay={0.0}
      {...props}
      overlayStyle={style || {maxWidth: '20%'}}
      getTooltipContainer={getTooltipContainer}
    />
  );
};

const TextTooltip = ({text, children, ...props}) => {
  return text ? (
    <TooltipOverlay text={text} {...props}>
      {children}
    </TooltipOverlay>
  ) : (
    children
  );
};

// When wrapping a child component that is 'flexDirection: row', the tooltip
// will not display correctly. To fix this, you can use TextTooltip.Content
// to simply nest the child component back into a column.
TextTooltip.Content = ContentContainer;

// --------------------------------------------------
// Props
// --------------------------------------------------
TooltipOverlay.propTypes = {
  placement: PropTypes.string,
};

TooltipOverlay.defaultProps = {
  placement: 'bottom',
};

export default TextTooltip;
