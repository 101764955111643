/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Location} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import TextTooltip from 'modules/App/components/TextTooltip';

const Text = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Container = Styled.View`
`;

const IconContainer = Styled.View`
  width: 12px;
  align-items: center;
`;

const getHorizontalLocationText = ({locations}) => {
  const cities = locations.map((location) => Location.getDisplayCityState(location));
  return cities.join(' - ');
};

const JobLocationsText = ({job, direction}) => {
  return (
    <React.Fragment>
      {direction === 'row' ? (
        <Text numberOfLines={1}>{getHorizontalLocationText({locations: job.locations})}</Text>
      ) : (
        <Container>
          {job.locations.map((location) => {
            return (
              <Text key={location.id} numberOfLines={1}>
                {location.city}
              </Text>
            );
          })}
        </Container>
      )}
    </React.Fragment>
  );
};

const JobLocations = ({job, direction}) => {
  return (
    <Row>
      <TextTooltip text={pluralize('Location', job.locations.length, true)}>
        <IconContainer>
          <Icon
            source={Icon.MapPin}
            color={colors.gray.primary}
            size={11}
            style={{marginBottom: 1}}
          />
        </IconContainer>
      </TextTooltip>
      <Space width={6} />
      {job.locations.length === 0 ? (
        <Text>No Location</Text>
      ) : (
        <JobLocationsText job={job} direction={direction} />
      )}
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobLocations.fragment = gql`
  ${Location.getDisplayCityState.fragment}
  fragment JobLocations on Job {
    id
    locations {
      id
      ...Location_getDisplayCityState
    }
  }
`;

export default JobLocations;
