// Supermove
import {Percent} from '@supermove/utils';

const _new = ({amount = null} = {}) => ({
  amount,
});

const toForm = ({amount}) => ({
  amount: amount ? Percent.toForm(amount) : '',
});

const toMutation = ({amount}) => ({
  amount: amount ? Percent.toMutation(amount) : null,
});

const TipOptionForm = {
  new: _new,
  toForm,
  toMutation,
};

export default TipOptionForm;
