// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useSidebar, useHover, usePopover, useNavigationDOM} from '@supermove/hooks';
import {CostItemType, Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import Table from '@shared/design/components/Table';
import CostCategoryKind from '@shared/modules/Billing/enums/CostCategoryKind';
import CostItemUnit from '@shared/modules/Billing/enums/CostItemUnit';
import useDeleteCostItemTypeMutation from '@shared/modules/Billing/hooks/useDeleteCostItemTypeMutation';
import UserRole from '@shared/modules/User/enums/UserRole';
import DeleteModal from 'modules/App/components/DeleteModal';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import SidebarPage from 'modules/App/components/SidebarPage';
import BillingLibraryPageHeader from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryPageHeader';
import BillingLibraryPageNavigationTabs from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryPageNavigationTabs';
import CreateCostItemTypeSidebar from 'modules/Organization/Settings/BillingLibraries/components/CreateCostItemTypeSidebar';
import UpdateCostItemTypeSidebar from 'modules/Organization/Settings/BillingLibraries/components/UpdateCostItemTypeSidebar';

const Container = Styled.View`
`;

const ContentContainer = Styled.View`
  flex: 1;
  padding-top: 12px;
  padding-horizontal: ${({mobile}) => (mobile ? 12 : 24)}px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TableContainer = Styled.View`
  flex-direction: row;
`;

const HeaderText = Styled.Text`
  ${Typography.Label2}
  min-width: 80px;
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary};
`;

const Button = Styled.ButtonV2`
  height: 28px;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
  border-radius: 4px;
  background-color: ${colors.blue.interactive};
`;

const ButtonText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.white};
`;

const TableCellText = Styled.Text`
  ${Typography.Body3}
`;

const TableCellButton = Styled.ButtonV2`
`;

const MenuOptionButton = Styled.ButtonV2`
  background-color: ${(props) => (props.isHovered ? colors.hover : 'transparent')};
  padding-vertical: 4px;
  padding-horizontal: 12px;
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const AddButton = ({children, onPress}) => {
  return (
    <Button onPress={onPress}>
      <Icon source={Icon.Plus} size={10} color={colors.white} />
      <Space width={5} />
      <ButtonText>{children}</ButtonText>
    </Button>
  );
};

const DeleteCompensationItemTypeModal = ({costItemType, isOpen, handleClose, refetch}) => {
  const {handleSubmit} = useDeleteCostItemTypeMutation({
    costItemTypeId: costItemType.id,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Are you sure you want to delete ${costItemType.name}?`}
      subtitle={"You can't undo this action."}
      handleClose={handleClose}
      handleConfirm={handleSubmit}
    />
  );
};

const CompensationItemTypeActionsPopover = ({
  compensationItemTypeActionsPopover,
  deleteCompensationItemTypeModal,
}) => {
  const {isHovered, ref} = useHover();
  const handleOpenDeleteCompensationItemTypeModal = () => {
    deleteCompensationItemTypeModal.handleOpen();
    compensationItemTypeActionsPopover.handleClose();
  };
  return (
    <Popover
      placement={Popover.Positions.RightStart}
      isOpen={compensationItemTypeActionsPopover.isOpen}
      handleOpen={compensationItemTypeActionsPopover.handleOpen}
      handleClose={compensationItemTypeActionsPopover.handleClose}
      reference={compensationItemTypeActionsPopover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={160}>
        <Space height={8} />
        <MenuOptionButton
          onPress={handleOpenDeleteCompensationItemTypeModal}
          isHovered={isHovered}
          ref={ref}
        >
          <MenuItemText>Delete</MenuItemText>
        </MenuOptionButton>
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const CostItemTypeActions = ({costItemType, moverPositions, billItemTypes, billTypes, refetch}) => {
  const editCompensationItemTypeSidebar = useSidebar({name: 'EDIT_COMPENSATION_ITEM_TYPE_SIDEBAR'});
  const compensationItemTypeActionsPopover = usePopover();
  const deleteCompensationItemTypeModal = useModal({name: 'DELETE_COMPENSATION_ITEM_TYPE_MODAL'});
  return (
    <React.Fragment>
      <Row>
        <TableCellButton onPress={editCompensationItemTypeSidebar.handleOpen}>
          <Icon source={Icon.Pen} color={colors.gray.secondary} size={16} />
        </TableCellButton>
        <Space width={25} />
        <Popover.Content innerRef={compensationItemTypeActionsPopover.ref}>
          <TableCellButton onPress={compensationItemTypeActionsPopover.handleToggle}>
            <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
          </TableCellButton>
        </Popover.Content>
      </Row>
      <UpdateCostItemTypeSidebar
        key={editCompensationItemTypeSidebar.key}
        isOpen={editCompensationItemTypeSidebar.isOpen}
        handleClose={editCompensationItemTypeSidebar.handleClose}
        costItemType={costItemType}
        category={CostCategoryKind.COMPENSATION}
        header={'Edit Compensation Item'}
        moverPositions={moverPositions}
        billItemTypeOptions={billItemTypes}
        billTypeOptions={billTypes}
        refetch={refetch}
      />
      <CompensationItemTypeActionsPopover
        key={`COMPENSATION_ITEM_TYPE_ACTIONS_POPOVER-${compensationItemTypeActionsPopover.isOpen}`}
        compensationItemTypeActionsPopover={compensationItemTypeActionsPopover}
        deleteCompensationItemTypeModal={deleteCompensationItemTypeModal}
      />
      <DeleteCompensationItemTypeModal
        key={deleteCompensationItemTypeModal.key}
        isOpen={deleteCompensationItemTypeModal.isOpen}
        handleClose={deleteCompensationItemTypeModal.handleClose}
        costItemType={costItemType}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const makeCompensationColumns = (moverPositions) => {
  return _.reduce(
    moverPositions,
    (acc, moverPosition) => {
      return [
        ...acc,
        {
          width: 160,
          headerContent: () => {
            return <TableHeaderText numberOfLines={1}>{moverPosition.name}</TableHeaderText>;
          },
          cellContent: ({item}) => {
            const costItemTypeRate = _.find(
              item.costItemTypeRates,
              (rate) => _.toString(rate.moverPositionId) === moverPosition.id,
            );

            if (!costItemTypeRate || costItemTypeRate.rate === 0) {
              return null;
            }

            return (
              <TableCellText>
                {item.unit === CostItemUnit.PERCENT
                  ? Percent.display(costItemTypeRate.rate)
                  : Currency.display(costItemTypeRate.rate, {shouldHideCentsIfZero: true})}
                {CostItemType.getDisplaySuffixFromUnit(item.unit)}
              </TableCellText>
            );
          },
        },
      ];
    },
    [],
  );
};

const getColumnDefinitions = ({
  moverPositions,
  billItemTypes,
  billTypes,
  existingCostItemTypes,
  role,
  refetch,
}) => {
  const compensationColumns = makeCompensationColumns(moverPositions);
  return [
    {
      width: 100,
      headerContent: () => {
        return <TableHeaderText>Actions</TableHeaderText>;
      },
      cellContent: ({item}) => (
        <CostItemTypeActions
          costItemType={item}
          moverPositions={moverPositions}
          billItemTypes={billItemTypes.filter((billItemType) => {
            // Don't include billItemTypes that already have cost items
            const existingCostItemTypeForBillItemTypeAndRole = _.find(
              existingCostItemTypes,
              (existingCostItemType) => {
                return (
                  existingCostItemType.billItemTypeId === _.toNumber(billItemType.id) &&
                  existingCostItemType.role === role
                );
              },
            );

            return (
              !existingCostItemTypeForBillItemTypeAndRole ||
              item.id === existingCostItemTypeForBillItemTypeAndRole.id
            );
          })}
          billTypes={billTypes}
          refetch={refetch}
        />
      ),
    },
    {
      width: 160,
      headerContent: () => {
        return <TableHeaderText>Type</TableHeaderText>;
      },
      cellContent: ({item}) => <TableCellText>{CostItemType.getDisplayKind(item)}</TableCellText>,
    },
    {
      width: 160,
      headerContent: () => {
        return <TableHeaderText>Item Name</TableHeaderText>;
      },
      cellContent: ({item}) => <TableCellText>{CostItemType.getDisplayName(item)}</TableCellText>,
    },
    ...compensationColumns,
  ];
};

const RolesSection = ({
  billingLibrary,
  role,
  moverPositions,
  compensationItemTypesByRole,
  refetch,
}) => {
  const newCompensationItemTypeSidebar = useSidebar({
    name: 'CREATE_COMPENSATION_ITEM_TYPE_SIDEBAR',
  });
  const compensationItemTypes = _.sortBy(_.get(compensationItemTypesByRole, role, []), 'kind');

  return (
    <Container>
      <Row>
        <HeaderText>{UserRole.getDisplayRole(role)}</HeaderText>
        <Space width={40} />
        <AddButton onPress={newCompensationItemTypeSidebar.handleOpen}>
          New Compensation Item
        </AddButton>
      </Row>
      <Space height={12} />
      <TableContainer>
        <Table
          columnDefinitions={getColumnDefinitions({
            moverPositions,
            billTypes: billingLibrary.billTypes,
            billItemTypes: billingLibrary.billItemTypes.filter(
              (billItemType) => !billItemType.isParent,
            ),
            existingCostItemTypes: billingLibrary.costItemTypes,
            role,
            refetch,
          })}
          items={compensationItemTypes}
          emptyStateText={'No items to display'}
          isDense
          headerStyle={{backgroundColor: colors.gray.background}}
        />
      </TableContainer>
      <Space height={50} />
      <CreateCostItemTypeSidebar
        key={newCompensationItemTypeSidebar.key}
        isOpen={newCompensationItemTypeSidebar.isOpen}
        handleClose={newCompensationItemTypeSidebar.handleClose}
        category={CostCategoryKind.COMPENSATION}
        header={'Add Compensation Item'}
        billingLibrary={billingLibrary}
        role={role}
        moverPositions={moverPositions}
        refetch={refetch}
      />
    </Container>
  );
};

const BillingLibraryCompensationItemTypesPageContent = ({billingLibrary, refetch}) => {
  const {
    organization: {moverPositionsForAllUserRoles: moverPositions, hasMultipleOrganizations},
    costItemTypes,
  } = billingLibrary;

  const organizationMoverPositions = billingLibrary.organization.features
    .isEnabledMoverPositionMultibranch
    ? Organization.getCompanySettingsMoverPositionsByRole({
        organization: billingLibrary.organization,
        role: null,
      })
    : moverPositions;

  const moverPositionsByRole = _.groupBy(
    organizationMoverPositions,
    (moverPosition) => moverPosition.role,
  );
  const compensationItemTypesByRole = _.groupBy(costItemTypes, (item) => item.role);

  const displayRoles = [
    UserRole.SALESPERSON,
    ...(hasMultipleOrganizations ? [UserRole.CONTRACTOR] : []),
    UserRole.EMPLOYEE,
  ];

  return (
    <Container>
      <Space height={28} />
      {displayRoles.map((role, index) => {
        return (
          <React.Fragment key={`${role}_${index}`}>
            <RolesSection
              billingLibrary={billingLibrary}
              role={role}
              moverPositions={_.get(moverPositionsByRole, role, [])}
              compensationItemTypesByRole={compensationItemTypesByRole}
              refetch={refetch}
            />
          </React.Fragment>
        );
      })}
    </Container>
  );
};

const BillingLibraryCompensationItemTypesPage = () => {
  const {params} = useNavigationDOM();
  return (
    <SidebarPage
      selected={'settings'}
      query={BillingLibraryCompensationItemTypesPage.query}
      variables={{
        billingLibraryUuid: params.billingLibraryUuid,
        category: CostCategoryKind.COMPENSATION,
      }}
      fetchPolicy={'cache-and-network'}
    >
      {({data, refetch}) => {
        return (
          <Container style={{flex: 1}}>
            <BillingLibraryPageHeader billingLibrary={data.billingLibrary} />
            <ContentContainer>
              <BillingLibraryPageNavigationTabs billingLibrary={data.billingLibrary} />
              <ScrollView horizontal contentContainerStyle={{flexGrow: 1}}>
                <ScrollView>
                  <BillingLibraryCompensationItemTypesPageContent
                    billingLibrary={data.billingLibrary}
                    refetch={refetch}
                  />
                </ScrollView>
              </ScrollView>
            </ContentContainer>
          </Container>
        );
      }}
    </SidebarPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryCompensationItemTypesPage.query = gql`
  ${BillingLibraryPageHeader.fragment}
  ${BillingLibraryPageNavigationTabs.fragment}
  ${CreateCostItemTypeSidebar.fragment}
  ${UpdateCostItemTypeSidebar.fragment}
  ${CostItemType.getDisplayKind.fragment}
  ${CostItemType.getDisplayName.fragment}
  ${Organization.getCompanySettingsMoverPositionsByRole.fragment}

  query BillingLibraryCompensationItemTypesPage(
    $billingLibraryUuid: String!,
    $category: String!,
  ) {
    ${gql.query}
    billingLibrary(billingLibraryUuid: $billingLibraryUuid) {
      id
      name
      organization {
        id
        moverPositionsForAllUserRoles {
          id
          name
          role
        }
        companySettings {
          moverPositions {
            id
            role
          }
        }
        hasMultipleOrganizations
        features {
          isEnabledMoverPositionMultibranch: isEnabled(feature: "MOVER_POSITION_MULTIBRANCH")
        }
        ...Organization_getCompanySettingsMoverPositionsByRole
      }
      costItemTypes(category: $category) {
        id
        billItemTypeId
        role
        ...UpdateCostItemTypeSidebar
        ...CostItemType_getDisplayKind
        ...CostItemType_getDisplayName
      }
      billItemTypes {
        id
        name
        isParent
      }
      billTypes {
        id
        name
      }
      ...BillingLibraryPageHeader
      ...BillingLibraryNavigationTabs
      ...CreateCostItemTypeSidebar
    }
  }
`;

export default BillingLibraryCompensationItemTypesPage;
