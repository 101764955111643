// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useRef, useResponsive, useState} from '@supermove/hooks';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ContextSwitcher from '@shared/design/components/ContextSwitcher';
import EmptyState from '@shared/design/components/EmptyState';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import EditProjectLocationsDrawer from 'modules/Project/V2/Show/Blocks/components/EditProjectLocationsDrawer';
import LocationsMap from 'modules/Project/V2/Show/Blocks/components/LocationsMap';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';
import ProjectLocationsList from 'modules/Project/V2/Show/Blocks/components/ProjectLocationsList';
import ProjectHeader from 'modules/Project/V2/Show/components/ProjectHeader';

const MAP_MIN_HEIGHT = 320;

const LocationViewMode = Object.freeze({
  ROUTE: 'ROUTE',
  MAP: 'MAP',
});

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const SkeletonBody = () => {
  return (
    <Row>
      <Column style={{flex: 2}}>
        <ProjectLocationsList.SkeletonComponent />
      </Column>
      <Space width={24} />
      <Column style={{flex: 1}}>
        <LocationsMap.SkeletonComponent height={MAP_MIN_HEIGHT} />
      </Column>
    </Row>
  );
};

const SkeletonComponent = () => {
  return (
    <ActionPanel
      BodyComponent={SkeletonBody}
      ActionButtonComponent={() => (
        <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} width={136} />
      )}
      title={'Saved Locations'}
      style={{width: '100%'}}
    />
  );
};

const EmptyBody = ({editProjectLocationsDrawer}) => {
  return (
    <EmptyState
      icon={Icon.MapMarkedAlt}
      title={'No locations.'}
      message={
        'Add a location and it will appear here.\n' +
        'You will be able to access these saved locations in each job.'
      }
      primaryActionIcon={Icon.Plus}
      primaryActionText={'Add Location'}
      handlePrimaryAction={editProjectLocationsDrawer.handleOpen}
      paddingVertical={64}
    />
  );
};

const EditLocationsButton = ({editProjectLocationsDrawer}) => {
  const responsive = useResponsive();
  return responsive.desktop ? (
    <Button
      iconLeft={Icon.Pen}
      text={'Edit Locations'}
      isSmall
      onPress={editProjectLocationsDrawer.handleOpen}
    />
  ) : (
    <TertiaryButton
      text={`Edit`}
      onPress={editProjectLocationsDrawer.handleOpen}
      isResponsive
      isHitSlop
    />
  );
};

const ProjectLocationsListColumn = ({project, mapRef, locationIdsWithStreetView, refetch}) => {
  return (
    <Column style={{flex: 2}}>
      <ProjectLocationsList
        project={project}
        mapRef={mapRef}
        locationIdsWithStreetView={locationIdsWithStreetView}
        refetch={refetch}
      />
    </Column>
  );
};

const ProjectLocationsMapColumn = ({
  project,
  isReadyForMap,
  mapRef,
  setLocationIdsWithStreetView,
}) => {
  return (
    <Column style={{flex: 1, minHeight: MAP_MIN_HEIGHT, borderRadius: 8, overflow: 'hidden'}}>
      {isReadyForMap && (
        <LocationsMap
          locations={project.locations}
          organization={project.owningOrganization}
          mapRef={mapRef}
          setLocationIdsWithStreetView={setLocationIdsWithStreetView}
        />
      )}
    </Column>
  );
};

const ProjectLocations = ({project, isReadyForMap, refetch}) => {
  const mapRef = useRef();
  const [locationIdsWithStreetView, setLocationIdsWithStreetView] = useState([]);

  return (
    <Row>
      <ProjectLocationsListColumn
        project={project}
        mapRef={mapRef}
        locationIdsWithStreetView={locationIdsWithStreetView}
        refetch={refetch}
      />
      <Space width={24} />
      <ProjectLocationsMapColumn
        project={project}
        isReadyForMap={isReadyForMap}
        mapRef={mapRef}
        setLocationIdsWithStreetView={setLocationIdsWithStreetView}
      />
    </Row>
  );
};

const ProjectLocationsBlockContent = ({project, isReadyForMap, refetch}) => {
  const editProjectLocationsDrawer = useDrawer({
    name: 'Edit Project Locations Drawer',
    enableTracking: true,
  });
  return (
    <React.Fragment>
      <ActionPanel
        BodyComponent={ProjectLocations}
        bodyComponentProps={{project, isReadyForMap, refetch}}
        EmptyBodyComponent={EmptyBody}
        emptyBodyComponentProps={{editProjectLocationsDrawer}}
        ActionButtonComponent={EditLocationsButton}
        actionButtonComponentProps={{editProjectLocationsDrawer}}
        title={'Saved Locations'}
        style={{flex: 1, width: undefined}}
        isEmpty={_.isEmpty(project.locations)}
      />
      <EditProjectLocationsDrawer
        key={editProjectLocationsDrawer.key}
        isOpen={editProjectLocationsDrawer.isOpen}
        handleClose={editProjectLocationsDrawer.handleClose}
        project={project}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const renderLocationViewMode = ({
  selectedView,
  project,
  isReadyForMap,
  mapRef,
  locationIdsWithStreetView,
  setLocationIdsWithStreetView,
  refetch,
}) => {
  switch (selectedView) {
    case LocationViewMode.ROUTE:
      return (
        <ProjectLocationsListColumn
          project={project}
          mapRef={mapRef}
          locationIdsWithStreetView={locationIdsWithStreetView}
          refetch={refetch}
        />
      );
    case LocationViewMode.MAP:
      return (
        <ProjectLocationsMapColumn
          project={project}
          isReadyForMap={isReadyForMap}
          mapRef={mapRef}
          setLocationIdsWithStreetView={setLocationIdsWithStreetView}
        />
      );
    default:
      return null;
  }
};

const ProjectLocationsBlockContentMobile = ({
  project,
  isReadyForMap,
  selectedView,
  setSelectedView,
  refetch,
}) => {
  const editProjectLocationsDrawer = useDrawer({
    name: 'Edit Project Locations Drawer',
    enableTracking: true,
  });
  const mapRef = useRef();
  const [locationIdsWithStreetView, setLocationIdsWithStreetView] = useState([]);

  return (
    <React.Fragment>
      <MobileProjectBlockHeader
        title={`Saved Locations`}
        ActionsComponent={EditLocationsButton}
        actionsComponentProps={{editProjectLocationsDrawer}}
      />
      <Row>
        <ContextSwitcher
          isFullWidth
          contextDefinitions={[
            {
              label: `Route`,
              isSelected: selectedView === LocationViewMode.ROUTE,
              onPress: () => {
                setSelectedView(LocationViewMode.ROUTE);
              },
            },
            {
              label: `Map`,
              isSelected: selectedView === LocationViewMode.MAP,
              onPress: () => {
                setSelectedView(LocationViewMode.MAP);
              },
            },
          ]}
        />
      </Row>
      <Space height={16} />
      {renderLocationViewMode({
        selectedView,
        project,
        isReadyForMap,
        mapRef,
        locationIdsWithStreetView,
        setLocationIdsWithStreetView,
        refetch,
      })}
      <EditProjectLocationsDrawer
        key={editProjectLocationsDrawer.key}
        isOpen={editProjectLocationsDrawer.isOpen}
        handleClose={editProjectLocationsDrawer.handleClose}
        project={project}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const ProjectLocationsBlock = ({project, handleSetPositionY, layoutKey, isReadyForMap, index}) => {
  const responsive = useResponsive();
  const [selectedView, setSelectedView] = useState(LocationViewMode.ROUTE);

  return (
    <ProjectBlockWrapper
      index={index}
      query={ProjectLocationsBlock.query}
      queryVariables={{projectUuid: project.uuid}}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={SkeletonComponent}
    >
      {({data, refetch}) => {
        return (
          <React.Fragment>
            {responsive.desktop ? (
              <ProjectLocationsBlockContent
                project={data.project}
                isReadyForMap={isReadyForMap}
                refetch={refetch}
              />
            ) : (
              <ProjectLocationsBlockContentMobile
                project={data.project}
                isReadyForMap={isReadyForMap}
                selectedView={selectedView}
                setSelectedView={setSelectedView}
                refetch={refetch}
              />
            )}
          </React.Fragment>
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectLocationsBlock.fragment = gql`
  fragment ProjectLocationsBlock on Project {
    id
    uuid
  }
`;

ProjectLocationsBlock.query = gql`
  ${ProjectHeader.listener}
  ${EditProjectLocationsDrawer.fragment}
  ${ProjectLocationsList.fragment}
  ${LocationsMap.fragment}
  query ProjectLocationsBlock($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      totalDistance
      locations {
        id
        ...LocationsMap_Location
      }
      owningOrganization {
        id
        ...LocationsMap_Organization
      }
      ...EditProjectLocationsDrawer
      ...ProjectLocationsList
      ...ProjectHeader_listener
    }
  }
`;

export default ProjectLocationsBlock;
