// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import {URL} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import PayrollReportKind from '@shared/modules/Accounting/enums/PayrollReportKind';
import ListPayrollReportsTabNavigation from 'modules/Accounting/PayrollReport/components/ListPayrollReportsTabNavigation';
import ListPayrollReportsPageContent from 'modules/Accounting/components/ListPayrollReportsPageContent';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';

const Container = Styled.View`
  flex: 1;
`;

const BASE_ROUTE = `/accounting/payroll-reports`;

const getVariablesFromParams = (params) => {
  return {
    kind: PayrollReportKind.ALL_EMPLOYEE,
    pagination: PaginationBar.DEFAULT_PAGINATION,
    ...params,
  };
};

const ListPayrollReportsPage = () => {
  const {navigator, params} = useNavigationDOM();
  const variables = getVariablesFromParams(params);
  const {loading, data} = useQuery(ListPayrollReportsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  // If required params are not set in the route on mount,
  // reload the page with the correct variables
  if (!params.kind || !params.pagination) {
    navigator.replace(URL.getUrlFromVariables(BASE_ROUTE, variables));
  }

  if (loading) {
    return (
      <SidebarPageV2 selected={'payroll'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  return (
    <SidebarPageV2 selected={'payroll'}>
      <Container>
        <StandardOfficeHeader title={'Payroll'} showLine={false} />
        <ListPayrollReportsTabNavigation
          selectedTab={params.kind}
          baseRoute={BASE_ROUTE}
          organization={data.viewer.viewingOrganization}
        />
        <ListPayrollReportsPageContent
          organization={data.viewer.viewingOrganization}
          baseRoute={BASE_ROUTE}
          payrollReportKind={PayrollReportKind.ALL_EMPLOYEE}
        />
      </Container>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListPayrollReportsPage.query = gql`
  ${ListPayrollReportsTabNavigation.fragment}

  query ListPayrollReportsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...ListPayrollReportsTabNavigation
      }
    }
  }
`;

export default ListPayrollReportsPage;
