// Libararies
import React from 'react';

// Supermove
import {Icon, ScrollView, Styled, Space, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, usePopover, useHover} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import Table from '@shared/design/components/Table';
import DataTableKind from '@shared/modules/Billing/enums/DataTableKind';
import DataTableForm from '@shared/modules/Billing/forms/DataTableForm';
import useDeleteDataTableMutation from '@shared/modules/Billing/hooks/useDeleteDataTableMutation';
import DeleteModal from 'modules/App/components/DeleteModal';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import EditDataTableModal from 'modules/Organization/Settings/Billing/Tables/components/EditDataTableModal';
import CannotDeleteModal from 'modules/Organization/Settings/Billing/components/CannotDeleteModal';

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary};
`;

const TableCellText = Styled.Text`
  ${Typography.Body3}
`;

const ActionButton = Styled.ButtonV2`
  min-width: 16px;
  align-items: center;
`;

const Container = Styled.View`
`;

const DescriptionText = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const ActionText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const MenuItemButton = Styled.ButtonV2`
  background-color: ${({isHighlighted}) => (isHighlighted ? colors.hover : 'transparent')};
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  padding-vertical: 8px;
  padding-horizontal: 16px;
`;

const MenuItem = ({onPress, children}) => {
  const {isHovered, ref} = useHover();
  return (
    <MenuItemButton onPress={onPress} isHighlighted={isHovered} ref={ref}>
      <MenuItemText>{children}</MenuItemText>
    </MenuItemButton>
  );
};

const ActionsMenuPopover = ({popover, handleDelete}) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={160}>
        <Space height={8} />
        <MenuItem
          onPress={() => {
            handleDelete();
            popover.handleClose();
          }}
        >
          Delete
        </MenuItem>
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const EllipseButton = ({table, userId, refetch}) => {
  const deleteDataTableModal = useModal({name: 'Delete Data Table Modal'});
  const cannotDeleteVariableTableModal = useModal({name: 'Cannot Delete Variable Table Modal'});
  const cannotDeleteValuationCoverageTableModal = useModal({
    name: 'Cannot Delete Valuation Coverage Table Modal',
  });
  const handleDeleteClick = () => {
    if (!table.isDeletable) {
      if (table.kind === DataTableKind.VARIABLE) {
        cannotDeleteVariableTableModal.handleOpen();
      }
      if (table.kind === DataTableKind.VALUATION_COVERAGE) {
        cannotDeleteValuationCoverageTableModal.handleOpen();
      }
    } else {
      deleteDataTableModal.handleOpen();
    }
  };
  const actionsPopover = usePopover();

  return (
    <React.Fragment>
      <Popover.Content innerRef={actionsPopover.ref}>
        <ActionButton onPress={actionsPopover.handleToggle}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </ActionButton>
      </Popover.Content>
      <ActionsMenuPopover popover={actionsPopover} handleDelete={handleDeleteClick} />
      <DeleteDataTableModal
        key={deleteDataTableModal.key}
        dataTable={table}
        userId={userId}
        isOpen={deleteDataTableModal.isOpen}
        handleClose={deleteDataTableModal.handleClose}
        refetch={refetch}
      />
      <CannotDeleteModal
        key={cannotDeleteVariableTableModal.key}
        titleText='Unable to delete'
        description='The table is being used by active variables and cannot be deleted.'
        cannotDeleteModal={cannotDeleteVariableTableModal}
        listItems={table.variables}
        getListItemText={(variable) => variable.name}
        getListItemUrl={() => `/settings/billing/variables/`}
      />
      <CannotDeleteValuationCoverageTableModal
        key={cannotDeleteValuationCoverageTableModal.key}
        cannotDeleteValuationCoverageTableModal={cannotDeleteValuationCoverageTableModal}
        titleText='Unable to delete'
        description='The table is being used for project type valuation coverage and cannot be deleted.'
      />
    </React.Fragment>
  );
};

const EditButton = ({table, refetch, userId}) => {
  const editDataTableModal = useModal({name: 'Edit Data Table Modal'});

  return (
    <React.Fragment>
      <ActionButton onPress={() => editDataTableModal.handleOpen()}>
        <Icon source={Icon.Pen} color={colors.gray.secondary} size={16} />
      </ActionButton>
      <EditDataTableModal
        key={editDataTableModal.key}
        isOpen={editDataTableModal.isOpen}
        handleClose={editDataTableModal.handleClose}
        refetch={refetch}
        dataTable={table}
        userId={userId}
      />
    </React.Fragment>
  );
};

const ViewButton = ({table, handleOpenDataTableModal}) => {
  const {navigator} = useNavigationDOM();

  return (
    <ActionButton
      onPress={() => {
        navigator.push(`/settings/billing/tables?dataTableUuid=${table.uuid}`);
        handleOpenDataTableModal();
      }}
    >
      <ActionText>View</ActionText>
    </ActionButton>
  );
};

const CannotDeleteValuationCoverageTableModal = ({
  cannotDeleteValuationCoverageTableModal,
  titleText,
  description,
}) => {
  return (
    <SmallModal isOpen={cannotDeleteValuationCoverageTableModal.isOpen}>
      <SmallModal.HeaderText>{titleText}</SmallModal.HeaderText>
      <Space height={8} />
      <SmallModal.Text>{description}</SmallModal.Text>
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button
          color={colors.blue.interactive}
          onPress={cannotDeleteValuationCoverageTableModal.handleClose}
        >
          Confirm
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

const DeleteDataTableModal = ({dataTable, userId, isOpen, handleClose, refetch}) => {
  const dataTableForm = DataTableForm.edit(dataTable, {userId});
  const {handleSubmit} = useDeleteDataTableMutation({
    dataTableForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Are you sure you want to delete ${dataTable.name}?`}
      subtitle={"You can't undo this action."}
      handleClose={handleClose}
      handleConfirm={handleSubmit}
    />
  );
};

const getBillingTablesTableColumnDefinitions = ({refetch, userId, handleOpenDataTableModal}) => {
  return [
    // Name
    {
      width: 350,
      headerContent: () => {
        return <TableHeaderText>Name</TableHeaderText>;
      },
      cellContent: ({item: table}) => {
        return <TableCellText numberOfLines={1}>{table.name}</TableCellText>;
      },
    },
    // Kind
    {
      width: 250,
      headerContent: () => {
        return <TableHeaderText>Kind</TableHeaderText>;
      },
      cellContent: ({item: table}) => {
        return (
          <TableCellText numberOfLines={1}>
            {DataTableKind.getDisplayKind(table.kind)}
          </TableCellText>
        );
      },
    },
    // Last Updated Date
    {
      width: 250,
      headerContent: () => {
        return <TableHeaderText>Last Updated</TableHeaderText>;
      },
      cellContent: ({item: table}) => {
        return (
          <Container>
            <TableCellText>{Datetime.convertToDisplayDatetime(table.updatedAt)}</TableCellText>
            {!!table.updatedBy && (
              <React.Fragment>
                <Space height={4} />
                <DescriptionText>By: {table.updatedBy.fullName}</DescriptionText>
              </React.Fragment>
            )}
          </Container>
        );
      },
    },
    // Actions
    {
      width: 125,
      headerContent: () => {
        return <TableHeaderText>Actions</TableHeaderText>;
      },
      cellContent: ({item: table}) => {
        return (
          <React.Fragment>
            <EditButton table={table} refetch={refetch} userId={userId} />
            <Space width={12} />
            <EllipseButton table={table} refetch={refetch} />
            <Space width={12} />
            <ViewButton table={table} handleOpenDataTableModal={handleOpenDataTableModal} />
          </React.Fragment>
        );
      },
    },
  ];
};

const BillingTablesTable = ({tables, refetch, userId, handleOpenDataTableModal}) => {
  const billingTablesTableColumnDefinitions = getBillingTablesTableColumnDefinitions({
    refetch,
    userId,
    handleOpenDataTableModal,
  });

  return (
    <React.Fragment>
      <ScrollView horizontal>
        <Table
          columnDefinitions={billingTablesTableColumnDefinitions}
          emptyStateText='No tables'
          items={tables}
          headerStyle={{backgroundColor: colors.gray.background}}
          isClickable={false}
          isDense
        />
      </ScrollView>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingTablesTable.fragment = gql`
  ${EditDataTableModal.fragment}
  ${DataTableForm.edit.fragment}

  fragment BillingTablesTable on DataTable {
    id
    uuid
    name
    updatedAt
    kind
    isDeletable
    variables {
      id
      name
    }
    updatedBy {
      id
      fullName
    }
    ...EditDataTableModal
    ...DataTableForm_edit
  }
`;

export default BillingTablesTable;
