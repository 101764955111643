// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {MultiDropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';

const ALL_ORGANIZATIONS = 'ALL_ORGANIZATIONS';

const MultiBranchOrganizationField = ({
  value,
  organizations,
  onChangeValue,
  noBranchOrganizations,
  label,
  width,
  isPortaled,
  index,
  disableAllOption,
}) => {
  const {params} = useNavigationDOM();

  const filteredOrganizations = organizations.filter((organization) => {
    return noBranchOrganizations
      ? ![OrganizationKind.BRANCH, OrganizationKind.CONTRACTOR].includes(organization.kind)
      : true;
  });

  const options = _.sortBy(
    [
      !disableAllOption && {
        label: 'All Branches',
        value: ALL_ORGANIZATIONS,
      },
      ...filteredOrganizations.map((organization) => ({
        label: organization.name,
        value: organization.slug,
      })),
    ],
    'label',
    // Filter out null if disableAllOption is selected
  ).filter((option) => !!option);

  return (
    <FieldInput
      style={{width}}
      component={disableAllOption ? MultiDropdownInput : MultiDropdownCheckboxInput}
      label={label}
      index={index}
      input={{
        isPortaled,
        value,
        style: {width: '100%'},
        options,
        placeholder: 'Select branch(es)',
        onChangeValue: (newSlugs) => {
          // If all is selected and they select a different org, remove all
          const previousSlugs = params.slugs || [];
          if (previousSlugs.includes(ALL_ORGANIZATIONS)) {
            newSlugs = _.filter(newSlugs, (slug) => slug !== ALL_ORGANIZATIONS);
          }
          // If they select all, remove all other orgs, or if they remove everything default to all
          if (
            (!previousSlugs.includes(ALL_ORGANIZATIONS) && newSlugs.includes(ALL_ORGANIZATIONS)) ||
            newSlugs.length === 0
          ) {
            newSlugs = [ALL_ORGANIZATIONS];
          }
          onChangeValue(newSlugs);
        },
      }}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
MultiBranchOrganizationField.propTypes = {
  value: PropTypes.array,
  organizations: PropTypes.array,
  onChangeValue: PropTypes.func,
  noBranchOrganizations: PropTypes.bool,
  label: PropTypes.string,
  width: PropTypes.string,
  isPortaled: PropTypes.bool,
  index: PropTypes.number,
  disableAllOption: PropTypes.bool,
};

MultiBranchOrganizationField.defaultProps = {
  value: [],
  organizations: [],
  onChangeValue: () => {},
  noBranchOrganizations: false,
  label: null,
  width: '200px',
  isPortaled: true,
  index: 0,
  disableAllOption: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MultiBranchOrganizationField.fragment = gql`
  fragment MultiBranchOrganizationField on Organization {
    id
    company {
      id
      organizations {
        id
        name
        slug
        isPrimary
        kind
      }
    }
  }
`;

export default MultiBranchOrganizationField;
