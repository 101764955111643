// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ClaimForm from '@shared/modules/Claim/forms/ClaimForm';

const useCreateClaimMutation = ({claimForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      claimForm: ClaimForm.toForm(claimForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateClaimMutation.mutation,
    variables: {
      claimForm: ClaimForm.toMutation(form.values.claimForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateClaimMutation.mutation = gql`
  mutation useCreateClaimMutation($claimForm: ClaimForm!) {
    response: createClaim(claimForm: $claimForm) {
      ${gql.errors}
      claim {
        id
        identifier
      }
    }
  }
`;

export default useCreateClaimMutation;
