// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import RoomForm from '@shared/modules/Inventory/forms/RoomForm';

const useUpdateRoomMutation = ({roomForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      roomForm: RoomForm.toForm(roomForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateRoomMutation.mutation,
    variables: {
      roomForm: RoomForm.toMutation(form.values.roomForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateRoomMutation.mutation = gql`
  mutation useUpdateRoomMutation($roomForm: RoomForm!) {
    response: updateRoom(roomForm: $roomForm) {
      ${gql.errors}
      room {
        id
      }
    }
  }
`;

export default useUpdateRoomMutation;
