// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getCreditCardClient = withFragment(
  (card) => {
    return {
      createCard: async () => ({
        env: card.env,
        token: card.token,
        card_data: {
          id: card.cardId,
          name: card.name,
          brand: card.brand,
          exp_month: card.expMonth,
          exp_year: card.expYear,
          address_zip: card.addressZip,
          last_4: card.last4,
        },
      }),
    };
  },
  gql`
    fragment PayengineCreditCard_getCreditCardClient on PayEngineCreditCard {
      id
      env
      token
      cardId
      name
      brand
      expMonth
      expYear
      addressZip
      last4
    }
  `,
);

const PayengineCreditCard = {
  getCreditCardClient,
};

export default PayengineCreditCard;
