// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled, Tabs} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useEffect,
  useNavigationDOM,
  useQuery,
  useRef,
  useResponsive,
  useScrollView,
  useState,
  useTabs,
  useToast,
  useUrlFilters,
} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize, uuid} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Callout from '@shared/design/components/Callout';
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import JobForm from '@shared/modules/Job/forms/JobForm';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import ProjectJobsForm from '@shared/modules/Project/forms/ProjectJobsForm';
import useUpdateProjectJobsMutation from '@shared/modules/Project/hooks/useUpdateProjectJobsMutation';
import UserRole from '@shared/modules/User/enums/UserRole';
import Line from 'modules/App/components/Line';
import OfficeHeader from 'modules/App/components/OfficeHeader';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import useAppContext from 'modules/App/context/useAppContext';
import EditJobNotesBlock from 'modules/Project/V2/Edit/components/EditJobNotesBlock';
import EditJobRouteBlock from 'modules/Project/V2/Edit/components/EditJobRouteBlock';
import EditProjectJobBlocks from 'modules/Project/V2/Edit/components/EditProjectJobBlocks';
import EditProjectJobsBar from 'modules/Project/V2/Edit/components/EditProjectJobsBar';
import EditProjectJobsHeader from 'modules/Project/V2/Edit/components/EditProjectJobsHeader';
import ProjectJobNavigation from 'modules/Project/V2/Edit/components/ProjectJobNavigation';

const BLOCK_PADDING = 24;

const Row = Styled.View`
  flex-direction: row;
`;

const VerticalDivider = Styled.View`
  height: 100%;
  width: 1px;
  background-color: ${colors.gray.border}
`;

const PageContainer = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  background-color: ${colors.gray.background};
`;

const ContentBodyContainer = Styled.View`
`;

const MobileActionsContainer = Styled.View`
  padding: 16px;
`;

const MobileJobBadgeContainer = Styled.View`
  background-color: ${({color}) => colors.getBackgroundColor(color)};
  padding-horizontal: 8px;
  padding-vertical: 1px;
  justify-content: center;
  border-radius: 4px;
`;

const MobileJobBadgeLabel = Styled.Text`
  ${Typography.MicroLabel}
  color: ${({color}) => color};
`;

const CalloutContainer = Styled.View`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

const getJobFormField = ({form, params}) => {
  const jobFormsField = 'projectJobsForm.jobForms';

  const jobFormIndex = _.findIndex(_.get(form.values, jobFormsField), ['uuid', params.jobUuid]);

  return `${jobFormsField}.${jobFormIndex}`;
};

const handleAddJob = ({form, field, project, urlFilters, tabs}) => {
  const jobUuid = uuid();
  const jobForms = _.get(form.values, `${field}.jobForms`);
  const newJobForm = JobForm.toForm(JobForm.newForProject(project, {uuid: jobUuid}));

  form.setFieldValue(`${field}.jobForms`, [...jobForms, newJobForm]);
  tabs.setSelectedIndex(jobForms.length);

  return urlFilters.handleUpdate({
    jobUuid,
    block: ProjectBlockKind.EditJobBlocks[0],
    action: null,
  });
};

const handleDuplicateJob = ({form, field, project, urlFilters, jobUuid, tabs}) => {
  const jobForms = _.get(form.values, `${field}.jobForms`);
  const jobFormToCopy = _.find(jobForms, ['uuid', jobUuid]);
  const newUuid = uuid();
  const newJobForm = JobForm.copyJobForm(jobFormToCopy, {uuid: newUuid});

  form.setFieldValue(`${field}.jobForms`, [...jobForms, newJobForm]);
  tabs.setSelectedIndex(jobForms.length);

  return urlFilters.handleUpdate({
    jobUuid: newUuid,
    block: ProjectBlockKind.EditJobBlocks[0],
    action: null,
    actionUuid: null,
  });
};

const handleActionParams = ({
  params,
  form,
  field,
  urlFilters,
  project,
  jobsBarScrollView,
  tabs,
}) => {
  if (params.action === Project.JOB_ACTIONS.ADD) {
    handleAddJob({form, field, urlFilters, project, tabs});
  }
  if (params.action === Project.JOB_ACTIONS.DUPLICATE) {
    handleDuplicateJob({form, field, urlFilters, project, jobUuid: params.actionUuid, tabs});
  }
  setTimeout(() => jobsBarScrollView.handleScrollToEnd({animated: true}), 0);
};

const handleInvalidParams = ({params, form, field, urlFilters, isValidJobUuid}) => {
  // 1. Handle an invalid jobUuid param
  // 2. Handle when a jobUuid or block param is missing
  const jobForms = _.get(form.values, `${field}.jobForms`);
  const {jobUuid, block} = params;

  if (!_.isEmpty(jobForms)) {
    return urlFilters.handleUpdate({
      jobUuid: isValidJobUuid ? jobUuid : jobForms[0].uuid,
      block: block || ProjectBlockKind.EditJobBlocks[0],
    });
  }
};

const MobileJobTab = ({tab, isSelected, handlePress}) => {
  return (
    <Tabs.TabContainer
      isSelected={isSelected}
      onPress={handlePress}
      style={{borderBottomWidth: 0, paddingTop: 12, paddingBottom: 12}}
    >
      <Space width={24} />
      {tab.isNew && (
        <React.Fragment>
          <MobileJobBadgeContainer color={colors.green.status}>
            <MobileJobBadgeLabel color={colors.green.status}>New</MobileJobBadgeLabel>
          </MobileJobBadgeContainer>
          <Space width={8} />
        </React.Fragment>
      )}
      <Tabs.TabText style={{color: isSelected ? colors.blue.interactive : colors.gray.tertiary}}>
        {tab.label}
      </Tabs.TabText>
      {tab.errorCount && (
        <React.Fragment>
          <Space width={8} />
          <MobileJobBadgeContainer color={colors.red.warning}>
            <MobileJobBadgeLabel color={colors.red.warning}>
              {pluralize('Error', tab.errorCount, true)}
            </MobileJobBadgeLabel>
          </MobileJobBadgeContainer>
        </React.Fragment>
      )}
      <Space width={24} />
      {isSelected && <Tabs.TabUnderline />}
    </Tabs.TabContainer>
  );
};

const CloseButton = () => {
  const {navigator} = useNavigationDOM();

  return (
    <TertiaryButton onPress={navigator.goBack}>
      <Icon source={Icon.XmarkLarge} size={22} color={colors.gray.primary} />
    </TertiaryButton>
  );
};

const LoadingComponent = () => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <React.Fragment>
          <OfficeHeader>
            <OfficeHeader.Content style={{flex: 1, padding: 24}}>
              <CloseButton />
              <Space width={16} />
              <SkeletonLoader width={320} height={SkeletonLoader.HEIGHT.Heading1Text} />
              <Space style={{flex: 1}} />
              <Button text={'Save Changes'} iconLeft={Icon.Check} isDisabled />
            </OfficeHeader.Content>
          </OfficeHeader>
          <Line />
          <EditProjectJobsBar.SkeletonLoader />
          <Line />
          <ContentContainer>
            <ProjectJobNavigation.SkeletonLoader />
            <VerticalDivider />
          </ContentContainer>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <EditProjectJobsHeader isLoading navigator={navigator} responsive={responsive} />
          <Line />
          <MobileActionsContainer>
            <SkeletonLoader isFullWidth height={SkeletonLoader.HEIGHT.Button} />
            <Space height={16} />
            <SkeletonLoader isFullWidth height={SkeletonLoader.HEIGHT.Button} />
            <Space height={16} />
            <SkeletonLoader isFullWidth height={SkeletonLoader.HEIGHT.Button} />
          </MobileActionsContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const EditProjectJobBlocksDesktopWrapper = ({
  responsive,
  form,
  field,
  project,
  children,
  isDisabled,
  mapRef,
}) => {
  if (responsive.desktop) {
    return (
      <Row>
        <ContentBodyContainer style={{flex: 2}}>{children}</ContentBodyContainer>
        <Space width={24} />
        <ContentBodyContainer style={{flex: 1}}>
          <EditJobNotesBlock form={form} field={field} isDisabled={isDisabled} />
          <Space height={24} />
          <EditJobRouteBlock form={form} field={field} project={project} mapRef={mapRef} />
        </ContentBodyContainer>
      </Row>
    );
  }

  return children;
};

const EditProjectJobsContentBody = ({
  form,
  field,
  jobsField,
  project,
  urlFilters,
  refetch,
  responsive,
  editProjectJobsBarProps,
  scrollView,
}) => {
  const [blockToPositionY, setBlockToPositionY] = useState({});
  const [contentSize, setContentSize] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const isFinal = _.get(form.values, `${field}.isFinal`);
  const mapRef = useRef();
  const {viewer} = useAppContext();

  return (
    <ScrollView
      ref={scrollView.ref}
      style={{flex: 1, backgroundColor: responsive.desktop ? colors.gray.background : colors.white}}
      contentContainerStyle={{padding: responsive.desktop ? BLOCK_PADDING : 0}}
      onScroll={({nativeEvent}) => {
        const {layoutMeasurement, contentOffset, contentSize} = nativeEvent;
        const isScrollAtBottom = layoutMeasurement.height + contentOffset.y >= contentSize.height;
        setIsAtBottom(isScrollAtBottom);
        setPositionY(contentOffset.y);
      }}
      onContentSizeChange={(width, height) => {
        setContentSize(height);
      }}
      showsVerticalScrollIndicator={responsive.desktop}
    >
      {!responsive.desktop && (
        <React.Fragment>
          <MobileActionsContainer>
            <EditProjectJobsBar {...editProjectJobsBarProps} />
          </MobileActionsContainer>
          <Line style={{height: 4}} />
        </React.Fragment>
      )}
      {_.get(form.errors, field) && (
        <CalloutContainer responsive={responsive}>
          {!responsive.desktop && <Space height={16} />}
          <ErrorCallout text={'Please fix the errors below before saving.'} />
          {responsive.desktop && <Space height={24} />}
        </CalloutContainer>
      )}
      {isFinal && (
        <CalloutContainer responsive={responsive}>
          {!responsive.desktop && <Space height={16} />}
          <Callout text={UserRole.getJobActionDisabledTooltip(viewer?.role)} />
          {responsive.desktop && <Space height={24} />}
        </CalloutContainer>
      )}
      <EditProjectJobBlocksDesktopWrapper
        responsive={responsive}
        form={form}
        field={field}
        project={project}
        isDisabled={isFinal}
        mapRef={mapRef}
      >
        <EditProjectJobBlocks
          form={form}
          field={field}
          jobsField={jobsField}
          project={project}
          urlFilters={urlFilters}
          pageRefetch={refetch}
          blockToPositionY={blockToPositionY}
          setBlockToPositionY={setBlockToPositionY}
          contentSize={contentSize}
          positionY={positionY}
          isAtBottom={isAtBottom}
          scrollView={scrollView}
          map={mapRef.current}
        />
      </EditProjectJobBlocksDesktopWrapper>
    </ScrollView>
  );
};

const EditProjectJobsContent = ({project, urlFilters, refetch}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const jobsBarScrollView = useScrollView();
  const jobContentScrollView = useScrollView();

  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: `Project jobs updated!`,
  });

  const projectJobsForm = ProjectJobsForm.edit(project);
  const {form, handleSubmit, submitting} = useUpdateProjectJobsMutation({
    projectJobsForm,
    onSuccess: () => {
      successToast.handleToast();
      navigator.replace(
        `/projects/${params.projectUuid}/view?jobUuid=${params.jobUuid}&block=${ProjectBlockKind.Job.JOB_DETAILS}`,
      );
    },
    onError: (errors) => {
      console.log({errors});
      jobContentScrollView.handleScrollToTop({animated: true});
    },
  });

  const field = `projectJobsForm`;
  const jobForms = _.get(form.values, `${field}.jobForms`);
  const isValidJobUuid = !!_.find(jobForms, ['uuid', params.jobUuid]);

  const tabs = useTabs({
    initialIndex: _.findIndex(jobForms, (jobForm) => jobForm.uuid === params.jobUuid) || 0,
    onChangeIndex: (index) => {
      urlFilters.handleReset({jobUuid: form.values.projectJobsForm.jobForms[index].uuid});
    },
  });

  useEffect(() => {
    if (!isValidJobUuid || !params.block) {
      handleInvalidParams({params, form, field, urlFilters, isValidJobUuid});
    }
    if (params.action) {
      handleActionParams({params, form, field, urlFilters, project, jobsBarScrollView, tabs});
    }
  }, [params, params.block, params.jobUuid, params.action, isValidJobUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!responsive.desktop) {
      const jobFormIndex = _.findIndex(jobForms, (jobForm) => jobForm.uuid === params.jobUuid) || 0;
      tabs.setSelectedIndex(jobFormIndex);
    }
  }, [params.jobUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  // Define once here so mobile and desktop stay in sync
  const editProjectJobsBarProps = {
    form,
    field,
    urlFilters,
    project,
    scrollView: jobsBarScrollView,
  };

  return (
    <React.Fragment>
      <EditProjectJobsHeader
        project={project}
        handleSubmit={handleSubmit}
        submitting={submitting}
        form={form}
        field={field}
      />
      <Line />
      {responsive.desktop ? (
        <React.Fragment>
          <EditProjectJobsBar {...editProjectJobsBarProps} />
          <Line />
          <ContentContainer>
            <ProjectJobNavigation
              urlFilters={urlFilters}
              form={form}
              field={getJobFormField({form, params})}
            />
            <VerticalDivider />
            {isValidJobUuid && (
              <EditProjectJobsContentBody
                key={params.jobUuid}
                form={form}
                field={getJobFormField({form, params})}
                jobsField={`${field}.jobForms`}
                project={project}
                urlFilters={urlFilters}
                refetch={refetch}
                responsive={responsive}
                scrollView={jobContentScrollView}
              />
            )}
          </ContentContainer>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Tabs
            tabs={tabs}
            TabComponent={MobileJobTab}
            fullWidthBarStyle={{height: 2}}
            showsHorizontalScrollIndicator={false}
            scrollViewRef={jobsBarScrollView.ref}
            style={{flex: 1}}
          >
            {form.values.projectJobsForm.jobForms.map((jobForm, index) => {
              const jobUuid = jobForm.uuid;
              const tab = {
                label: JobForm.getJobName(jobForm, {project}),
                isNew: !jobForm.jobId,
                errorCount: EditProjectJobsBar.getErrorCount({form, field, index}),
              };
              if (!isValidJobUuid || jobForm.uuid !== params.jobUuid) {
                // TODO(dan) Setup a loading state
                return <Tabs.Slide tab={tab} key={jobUuid} />;
              }
              return (
                <EditProjectJobsContentBody
                  tab={tab}
                  key={jobUuid}
                  form={form}
                  field={`${field}.jobForms.${index}`}
                  jobsField={`${field}.jobForms`}
                  project={project}
                  urlFilters={urlFilters}
                  refetch={refetch}
                  responsive={responsive}
                  editProjectJobsBarProps={editProjectJobsBarProps}
                  scrollView={jobContentScrollView}
                />
              );
            })}
          </Tabs>
          <Line />
          <MobileActionsContainer>
            <Button
              text={'Save'}
              iconLeft={Icon.Check}
              onPress={() => {
                const projectForm = _.get(form.values, field);
                form.setFieldValue(field, ProjectJobsForm.handleCleanupForm(projectForm));
                setTimeout(handleSubmit, 0);
              }}
              isSubmitting={submitting}
              isWidthOfContainer
              isResponsive
            />
          </MobileActionsContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const EditProjectJobsPage = () => {
  const {params} = useNavigationDOM();
  const {data, loading, refetch} = useQuery(EditProjectJobsPage.query, {
    fetchPolicy: 'network-only',
    variables: {projectUuid: params.projectUuid},
  });
  const urlFilters = useUrlFilters({
    getRoute: () => `/projects/${params.projectUuid}/edit/jobs`,
    filterKeys: ['jobUuid', 'block', 'field'],
  });

  return (
    <SidebarPageV2 selected={'moves'}>
      <PageContainer>
        <Loading loading={loading} as={LoadingComponent}>
          {() => {
            return (
              <EditProjectJobsContent
                project={data.project}
                urlFilters={urlFilters}
                refetch={refetch}
              />
            );
          }}
        </Loading>
      </PageContainer>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditProjectJobsPage.query = gql`
  ${EditProjectJobsHeader.fragment}
  ${EditJobRouteBlock.fragment}
  ${EditProjectJobBlocks.fragment}
  ${EditProjectJobsBar.fragment}
  ${ProjectJobsForm.edit.fragment}
  ${Project.getName.fragment}
  ${JobForm.newForProject.fragment}

  query EditProjectJobsPage($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      ...EditProjectJobsHeader
      ...EditJobRouteBlock
      ...EditProjectJobBlocks
      ...EditProjectJobsBar
      ...ProjectJobsForm_edit
      ...Project_getName
      ...JobForm_newForProject
    }
  }
`;

export default EditProjectJobsPage;
