// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import JobTypeVariableSectionsForm from '@shared/modules/Variable/forms/JobTypeVariableSectionsForm';

const useUpsertJobTypeVariableSectionsMutation = ({
  jobTypeVariableSectionsForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      jobTypeVariableSectionsForm: JobTypeVariableSectionsForm.toForm(jobTypeVariableSectionsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpsertJobTypeVariableSectionsMutation.mutation,
    variables: {
      jobTypeVariableSectionsForm: JobTypeVariableSectionsForm.toMutation(
        form.values.jobTypeVariableSectionsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpsertJobTypeVariableSectionsMutation.mutation = gql`
  mutation useUpsertJobTypeVariableSectionsMutation($jobTypeVariableSectionsForm: JobTypeVariableSectionsForm!){
    response: upsertJobTypeVariableSections(jobTypeVariableSectionsForm: $jobTypeVariableSectionsForm) {
      ${gql.errors}
      jobTypeVariableSections {
        id
      }
    }
  }
`;

export default useUpsertJobTypeVariableSectionsMutation;
