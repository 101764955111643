// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useDeleteUserMutation = ({userId, onSuccess, onError}) => {
  const form = useForm({
    enableReinitialize: true,
    initialValues: {
      userId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteUserMutation.mutation,
    variables: {
      userId: form.values.userId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteUserMutation.mutation = gql`
  mutation DeleteUserMutation($userId: Int!) {
    response: deleteUser(userId: $userId) {
      ${gql.errors}
      user {
        id
      }
    }
  }
`;

export default useDeleteUserMutation;
