// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import RemoveContainersFromProjectForm from '@shared/modules/Storage/forms/RemoveContainersFromProjectForm';

const useRemoveContainersFromProjectMutation = ({
  removeContainersFromProjectForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      removeContainersFromProjectForm: RemoveContainersFromProjectForm.toForm(
        removeContainersFromProjectForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useRemoveContainersFromProjectMutation.mutation,
    variables: {
      removeContainersFromProjectForm: RemoveContainersFromProjectForm.toMutation(
        form.values.removeContainersFromProjectForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useRemoveContainersFromProjectMutation.mutation = gql`
  mutation RemoveContainersFromProjectMutation($removeContainersFromProjectForm: RemoveContainersFromProjectForm!) {
    response: removeContainersFromProject(removeContainersFromProjectForm: $removeContainersFromProjectForm) {
      ${gql.errors}
      project {
        id
      }
    }
  }
`;

export default useRemoveContainersFromProjectMutation;
