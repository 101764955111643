// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import CommercialEquipmentForm from '@shared/modules/CommercialCatalog/forms/CommercialEquipmentForm';

const Wrapper = Styled.View`
  margin-bottom: 10px;
  z-index: ${(props) => 100 - props.index};
`;

const Header = Styled.View`
  align-self: stretch;
  flex-direction: row;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  margin-bottom: 10px;
  background-color: ${colors.gray.background};
`;

const FlatListCell = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const FlatListSpace = Styled.View`
  height: 10px;
`;

const ItemRow = Styled.View`
  flex-direction: row;
  padding-horizontal: 10px;
`;

const NameCell = Styled.View`
  flex: 3;
  min-width: 140px;
  padding: ${({isEditable}) => (isEditable ? '2px' : '0px')}
`;

const ContentCell = Styled.View`
  flex: 1;
  ${({isEditable}) => (isEditable ? '' : 'align-items: flex-end')}
  padding: ${({isEditable}) => (isEditable ? '2px' : '0px')}
`;

const HeaderText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary};
`;

const Name = Styled.H6`
  ${Typography.Body3};
`;

const ContentText = Styled.Text`
  ${Typography.Body3};
  color: ${colors.gray.secondary};
`;

const EmptyMessage = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.secondary};
  padding-horizontal: 12px;
`;

const AddButton = Styled.ButtonV2`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const AddButtonText = Styled.Text`
  ${Typography.Label1}
  color: ${colors.blue.interactive};
`;

const RemoveRowButton = Styled.ButtonV2`
  width: 24px;
  justify-content: center;
  align-items: flex-end;
`;

const IconCircle = Styled.View`
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: ${({color}) => color};
  height: 20px;
  width: 20px;
  border-radius: 10px;
`;

const handleAddItem = ({form, field, job}) => {
  const commercialEquipmentForm = CommercialEquipmentForm.toForm(
    CommercialEquipmentForm.new({commercialCatalogId: job.commercialCatalog.id, name: ''}),
  );
  const forms = _.get(form.values, field);
  form.setFieldValue(field, [...forms, commercialEquipmentForm]);
};

const handleRemoveItem = ({form, field, index}) => {
  const commercialEquipmentForms = _.get(form.values, field);
  const updatedForms = _.filter(commercialEquipmentForms, (form, formIndex) => formIndex !== index);
  form.setFieldValue(field, updatedForms);
};

const ListHeader = ({isPriceVisible, isEditable}) => {
  return (
    <Header>
      <NameCell isEditable={isEditable}>
        <HeaderText>Equipment</HeaderText>
      </NameCell>
      <ContentCell isEditable={isEditable}>
        <HeaderText>Quantity Requested</HeaderText>
      </ContentCell>
      <ContentCell isEditable={isEditable}>
        <HeaderText>Left on Site</HeaderText>
      </ContentCell>
      {isPriceVisible && (
        <ContentCell isEditable={isEditable}>
          <HeaderText>Price</HeaderText>
        </ContentCell>
      )}
      {isEditable && <RemoveRowButton disabled />}
    </Header>
  );
};

const ViewCommercialEquipmentItem = ({commercialEquipment, isPriceVisible}) => {
  return (
    <ItemRow>
      <NameCell>
        <Name>{commercialEquipment.name}</Name>
      </NameCell>
      <ContentCell>
        <ContentText>{commercialEquipment.quantityRequested}</ContentText>
      </ContentCell>
      <ContentCell>
        <ContentText>{commercialEquipment.quantityDelivered}</ContentText>
      </ContentCell>
      {isPriceVisible && (
        <ContentCell>
          <ContentText>{Currency.display(commercialEquipment.price)}</ContentText>
        </ContentCell>
      )}
    </ItemRow>
  );
};

const EditCommercialEquipmentItem = ({form, field, index}) => {
  return (
    <ItemRow>
      <NameCell isEditable>
        <FieldInput.Memoized
          {...form}
          name={`${field}.${index}.name`}
          input={{
            placeholder: 'Enter material name',
            required: !_.get(form.values, `${field}.${index}.name`),
          }}
        />
      </NameCell>
      <ContentCell isEditable>
        <FieldInput.Memoized
          {...form}
          name={`${field}.${index}.quantityRequested`}
          input={{
            placeholder: 'Enter amount',
          }}
        />
      </ContentCell>
      <ContentCell isEditable>
        <FieldInput.Memoized
          {...form}
          name={`${field}.${index}.quantityDelivered`}
          input={{
            placeholder: 'Enter amount',
          }}
        />
      </ContentCell>
      <RemoveRowButton onPress={() => handleRemoveItem({form, field, index})}>
        <IconCircle color={colors.red.warning}>
          <Icon source={Icon.Minus} size={12} color={colors.red.warning} />
        </IconCircle>
      </RemoveRowButton>
    </ItemRow>
  );
};

const AddItemButton = ({job, form, field}) => {
  return (
    <ItemRow style={{marginTop: 10}}>
      <AddButton onPress={() => handleAddItem({job, form, field})}>
        <IconCircle color={colors.blue.interactive}>
          <Icon source={Icon.Plus} size={12} color={colors.blue.interactive} />
        </IconCircle>
        <Space width={8} />
        <AddButtonText>Add custom equipment</AddButtonText>
      </AddButton>
    </ItemRow>
  );
};

const DocumentV2InputCommercialCatalogEquipmentContentEdit = ({
  job,
  form,
  field,
  isPriceVisible,
}) => {
  const commercialEquipmentFormsField = `${field}.commercialCatalogForm.commercialEquipmentForms`;
  const commercialEquipmentForms = _.get(form.values, commercialEquipmentFormsField);
  return (
    <FlatList
      listKey={'EditCommercialEquipmentsList'}
      data={commercialEquipmentForms}
      keyExtractor={(commercialEquipmentForm, index) => String(index)}
      renderItem={({index}) => {
        return (
          <EditCommercialEquipmentItem
            form={form}
            field={commercialEquipmentFormsField}
            index={index}
          />
        );
      }}
      CellRendererComponent={FlatListCell}
      ItemSeparatorComponent={FlatListSpace}
      ListHeaderComponent={<ListHeader isPriceVisible={isPriceVisible} isEditable />}
      ListEmptyComponent={<EmptyMessage>No Equipment</EmptyMessage>}
      ListFooterComponent={
        <AddItemButton job={job} form={form} field={commercialEquipmentFormsField} />
      }
    />
  );
};

const DocumentV2InputCommercialCatalogEquipmentContentView = ({job, isPriceVisible}) => {
  return (
    <FlatList
      listKey={'ViewCommercialEquipmentsList'}
      data={job.commercialCatalog.commercialEquipments}
      keyExtractor={(commercialEquipment, index) => String(index)}
      renderItem={({item: commercialEquipment}) => {
        return (
          <ViewCommercialEquipmentItem
            commercialEquipment={commercialEquipment}
            isPriceVisible={isPriceVisible}
          />
        );
      }}
      CellRendererComponent={FlatListCell}
      ItemSeparatorComponent={FlatListSpace}
      ListHeaderComponent={<ListHeader isPriceVisible={isPriceVisible} />}
      ListEmptyComponent={<EmptyMessage>No Equipment</EmptyMessage>}
    />
  );
};

const DocumentV2InputCommercialCatalogEquipment = ({
  index,
  isPreview,
  isEditable,
  job,
  form,
  field,
}) => {
  const isPriceEnabled =
    isPreview || job.organization.features.isEnabledShowEquipmentAndMaterialsPriceOnDocuments;
  const isPriceVisible = isPriceEnabled && !isEditable;
  return (
    <Wrapper index={index}>
      {isPreview || !isEditable ? (
        <DocumentV2InputCommercialCatalogEquipmentContentView
          job={isPreview ? MOCK_JOB_DATA : job}
          isPriceVisible={isPriceVisible}
        />
      ) : (
        <DocumentV2InputCommercialCatalogEquipmentContentEdit
          job={job}
          form={form}
          field={field}
          isPriceVisible={isPriceVisible}
        />
      )}
    </Wrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentV2InputCommercialCatalogEquipment.fragment = gql`
  fragment DocumentV2InputCommercialCatalogEquipment on Job {
    id
    organization {
      id
      features {
        isEnabledShowEquipmentAndMaterialsPriceOnDocuments: isEnabled(
          feature: "showEquipmentAndMaterialsPriceOnDocuments"
        )
      }
    }
    commercialCatalog {
      id
      commercialEquipments {
        id
        name
        quantityDelivered
        quantityRequested
        price
      }
    }
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_JOB_DATA = {
  id: 1,
  organization: {
    id: 1,
    features: {
      isEnabledShowEquipmentAndMaterialsPriceOnDocuments: true,
    },
  },
  commercialCatalog: {
    id: 1,
    commercialEquipments: [
      {
        id: 1,
        name: 'Equipment 1',
        quantityDelivered: 1,
        quantityRequested: 2,
        price: 1000,
      },
    ],
  },
};

export default DocumentV2InputCommercialCatalogEquipment;
