// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import LocationForm from '@shared/modules/Location/forms/LocationForm';

const edit = withFragment(
  (shipment) => ({
    shipmentId: shipment.id,
    tripId: shipment.tripId,
    projectId: shipment.project.id,
    status: shipment.status,

    // Private
    weight: shipment.project.weight,
    clientName: shipment.project.client.name,
    clientUuid: shipment.project.client.uuid,
    originLocationForm: LocationForm.edit(shipment.project.startLocation),
    destinationLocationForm: LocationForm.edit(shipment.project.endLocation),

    packJobStartDate: shipment.project.packJobStartDate,
    packJobEndDate: shipment.project.packJobEndDate,
    packJobScheduledDate: shipment.project.packJobScheduledDate,

    deliveryJobStartDate: shipment.project.deliveryJobStartDate,
    deliveryJobEndDate: shipment.project.deliveryJobEndDate,
    deliveryJobScheduledDate: shipment.project.deliveryJobScheduledDate,

    loadJobStartDate: shipment.project.loadJobStartDate,
    loadJobEndDate: shipment.project.loadJobEndDate,
    loadJobScheduledDate: shipment.project.loadJobScheduledDate,
  }),
  gql`
    ${LocationForm.edit.fragment}
    fragment ShipmentForm_edit on Shipment {
      id
      tripId
      status
      project {
        id
        weight
        packJobStartDate
        packJobEndDate
        packJobScheduledDate
        deliveryJobStartDate
        deliveryJobEndDate
        deliveryJobScheduledDate
        loadJobStartDate
        loadJobEndDate
        loadJobScheduledDate
        client {
          id
          name
          uuid
        }
        startLocation {
          id
          ...LocationForm_edit
        }
        endLocation {
          id
          ...LocationForm_edit
        }
      }
    }
  `,
);

const toForm = ({
  shipmentId,
  tripId,
  projectId,
  status,
  weight,
  clientName,
  clientUuid,
  originLocationForm,
  destinationLocationForm,
  packJobStartDate,
  packJobEndDate,
  packJobScheduledDate,
  deliveryJobStartDate,
  deliveryJobEndDate,
  deliveryJobScheduledDate,
  loadJobStartDate,
  loadJobEndDate,
  loadJobScheduledDate,
}) => ({
  shipmentId,
  tripId,
  projectId,
  status,

  // Private
  weight,
  clientName,
  clientUuid,
  originLocationForm,
  destinationLocationForm,
  packJobStartDate,
  packJobEndDate,
  packJobScheduledDate,
  deliveryJobStartDate,
  deliveryJobEndDate,
  deliveryJobScheduledDate,
  loadJobStartDate,
  loadJobEndDate,
  loadJobScheduledDate,
});

const toMutation = ({shipmentId, tripId, projectId, status}) => ({
  shipmentId,
  tripId,
  projectId,
  status,
});

const ShipmentForm = {
  edit,
  toForm,
  toMutation,
};

export default ShipmentForm;
