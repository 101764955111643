// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Document} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import DocumentItemBlockByCategory from '@shared/modules/Document/components/DocumentItemBlockByCategory';
import DocumentV2InputCommercialCatalogEquipment from '@shared/modules/Document/components/DocumentV2InputCommercialCatalogEquipment';
import DocumentV2InputCommercialCatalogMaterial from '@shared/modules/Document/components/DocumentV2InputCommercialCatalogMaterial';
import DocumentV2InputPerMoverTimesheet from '@shared/modules/Document/components/DocumentV2InputPerMoverTimesheet';
import DocumentV2TimesheetInformation from '@shared/modules/Document/components/DocumentV2TimesheetInformation';
import DocumentV2ValuationCoverage from '@shared/modules/Document/components/DocumentV2ValuationCoverage';
import DocumentV2ValuationDeclarationTable from '@shared/modules/Document/components/DocumentV2ValuationDeclarationTable';
import InvoiceDocumentV2SummaryInformation from '@shared/modules/Document/components/InvoiceDocumentItemKind/InvoiceDocumentV2SummaryInformation';
import PrintDocumentV2Wrapper from '@shared/modules/Document/components/PrintDocumentV2Wrapper';
import ProjectDocumentV2BillingInformation from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2BillingInformation';
import ProjectDocumentV2CartonSummary from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2CartonSummary';
import ProjectDocumentV2CustomerSignatureInput from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2CustomerSignatureInput';
import ProjectDocumentV2DocumentHeaderOrganization from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2DocumentHeaderOrganization';
import ProjectDocumentV2GeneralInventoryItems from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2GeneralInventoryItems';
import ProjectDocumentV2GeneralInventorySummary from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2GeneralInventorySummary';
import ProjectDocumentV2InventorySummary from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2InventorySummary';
import ProjectDocumentV2OrganizationLogo from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2OrganizationLogo';
import ProjectDocumentV2OrganizationSignature from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2OrganizationSignature';
import ProjectDocumentV2SurveyItems from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2SurveyItems';
import ProjectDocumentV2SurveyRoomsSummary from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2SurveyRoomsSummary';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';

const PageLoadingIndicatorContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 300px;
`;

const Indicator = Styled.Loading`
`;

const getUniqueItemKindsFromDocumentItems = ({documentItems}) => {
  const itemKinds = documentItems.map((item) => item.itemKind);
  return itemKinds.filter((item, index) => itemKinds.indexOf(item) === index);
};

const getFilteringQueryVariables = ({documentItems}) => {
  const itemKinds = getUniqueItemKindsFromDocumentItems({documentItems});
  return {
    hasBillingInformation: itemKinds.includes(DocumentItemKindV2.BILLING_INFORMATION),
    hasCustomerSignatureInput: itemKinds.includes(DocumentItemKindV2.INPUT_SIGNATURE),
    hasValuationDeclarationTable: itemKinds.includes(
      DocumentItemKindV2.VALUATION_DECLARATION_TABLE,
    ),
    hasTimesheetInformation: itemKinds.includes(DocumentItemKindV2.TIMESHEET_INFORMATION),
    hasInventorySummary: itemKinds.includes(DocumentItemKindV2.INVENTORY_SUMMARY),
    hasInputPerMoverTimesheet: itemKinds.includes(DocumentItemKindV2.INPUT_PER_MOVER_TIMESHEET),
    hasInputCommercialCatalogEquipment: itemKinds.includes(
      DocumentItemKindV2.INPUT_COMMERCIAL_CATALOG_EQUIPMENTS,
    ),
    hasInputCommercialCatalogMaterial: itemKinds.includes(
      DocumentItemKindV2.INPUT_COMMERCIAL_CATALOG_MATERIALS,
    ),
    hasInputValuationCoverage: itemKinds.includes(DocumentItemKindV2.INPUT_VALUATION_COVERAGE),
    hasDocumentHeader: itemKinds.includes(DocumentItemKindV2.DOCUMENT_HEADER),
    hasOrganizationLogo: itemKinds.includes(DocumentItemKindV2.LOGO),
    hasOrganizationSignature: itemKinds.includes(DocumentItemKindV2.ORGANIZATION_SIGNATURE),
    hasSurveyItems: itemKinds.includes(DocumentItemKindV2.SURVEY_ITEMS_SUMMARY),
    hasCartonSummary: itemKinds.includes(DocumentItemKindV2.SURVEY_CARTONS_SUMMARY),
    hasSurveyRoomsSummary: itemKinds.includes(DocumentItemKindV2.SURVEY_ROOMS_SUMMARY),
    hasGeneralInventorySummary: itemKinds.includes(DocumentItemKindV2.GENERAL_INVENTORY_SUMMARY),
    hasInventoryItems: itemKinds.includes(DocumentItemKindV2.INVENTORY_ITEMS_SUMMARY),
  };
};

const PageLoadingIndicator = () => (
  <PageLoadingIndicatorContainer>
    <Indicator size={'large'} color={colors.gray.secondary} />
  </PageLoadingIndicatorContainer>
);

const DocumentV2Content = ({
  job,
  project,
  invoice,
  document,
  documentItems,
  isPreview,
  isEditable,
  form,
  field,
}) => {
  const itemsList = isEditable
    ? _.get(form.values, `${field}.documentForm.documentItemForms`)
    : documentItems;

  return (
    <React.Fragment>
      <Space height={12} />
      {itemsList.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <DocumentItemBlockByCategory
              index={index}
              item={item}
              isPreview={isPreview}
              isEditable={isEditable}
              job={job}
              project={project}
              invoice={invoice}
              document={document}
              form={form}
              field={`${field}.documentForm.documentItemForms.${index}`}
            />
            <Space height={8} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const DocumentV2ContentWrapper = ({isPrintable, isPrintWidthOfContent, children}) => {
  if (isPrintable) {
    return (
      <PrintDocumentV2Wrapper isWidthOfContent={isPrintWidthOfContent}>
        {children}
      </PrintDocumentV2Wrapper>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const DocumentV2ByCategory = ({
  document,
  isPreview,
  isEditable,
  isPrintable,
  form,
  field,
  isPrintWidthOfContent,
}) => {
  const documentItems = Document.getDocumentItems(document);

  const isInvoiceDocument = !_.isEmpty(document.invoice);

  const {loading, data} = useQuery(DocumentV2ByCategory.query, {
    fetchPolicy: 'network-only',
    variables: {
      // this variable is used to pass to conditional render fragments
      isInvoiceDocument,
      isJobDocument: !isInvoiceDocument,
      jobUuid: !isInvoiceDocument && document.job.uuid,
      projectUuid: isInvoiceDocument ? document.invoice.project.uuid : document.job.project.uuid,
      invoiceUuid: isInvoiceDocument && document.invoice.uuid,
      ...getFilteringQueryVariables({documentItems}),
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        return (
          <DocumentV2ContentWrapper
            isPrintable={isPrintable}
            isPrintWidthOfContent={isPrintWidthOfContent}
          >
            <DocumentV2Content
              job={data.job}
              project={data.project}
              invoice={data.invoiceByUuid}
              document={document}
              documentItems={documentItems}
              isPreview={isPreview}
              isEditable={isEditable}
              form={form}
              field={field}
            />
          </DocumentV2ContentWrapper>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
DocumentV2ByCategory.propTypes = {
  document: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  isPreview: PropTypes.bool,
  isPrintable: PropTypes.bool,
  form: PropTypes.object,
  field: PropTypes.string,
};

DocumentV2ByCategory.defaultProps = {
  isEditable: false,
  isPreview: false,
  isPrintable: false,
  form: null,
  field: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentV2ByCategory.fragment = gql`
  ${Document.getDocumentItems.fragment}
  ${DocumentItemBlockByCategory.fragment}
  fragment DocumentV2ByCategory on Document {
    id
    job {
      id
      uuid
      project {
        id
        uuid
      }
    }
    invoice {
      id
      uuid
      project {
        id
        uuid
      }
    }
    ...Document_getDocumentItems
    ...DocumentItemBlockByCategory
  }
`;

DocumentV2ByCategory.query = gql`
  ${ProjectDocumentV2BillingInformation.fragment}
  ${ProjectDocumentV2CustomerSignatureInput.fragment}
  ${DocumentV2ValuationDeclarationTable.fragment}
  ${DocumentV2TimesheetInformation.fragment}
  ${ProjectDocumentV2InventorySummary.fragment}
  ${DocumentV2InputPerMoverTimesheet.fragment}
  ${DocumentV2InputCommercialCatalogEquipment.fragment}
  ${DocumentV2InputCommercialCatalogMaterial.fragment}
  ${DocumentV2ValuationCoverage.fragment}
  ${ProjectDocumentV2DocumentHeaderOrganization.fragment}
  ${ProjectDocumentV2OrganizationLogo.fragment}
  ${ProjectDocumentV2OrganizationSignature.fragment}
  ${ProjectDocumentV2SurveyItems.fragment}
  ${ProjectDocumentV2SurveyRoomsSummary.fragment}
  ${ProjectDocumentV2CartonSummary.fragment}
  ${InvoiceDocumentV2SummaryInformation.fragment}
  ${ProjectDocumentV2GeneralInventorySummary.fragment}
  ${ProjectDocumentV2GeneralInventoryItems.fragment}

  query DocumentV2ByCategory(
      $isInvoiceDocument: Boolean!
      $isJobDocument: Boolean!
      $projectUuid: String!
      $jobUuid: String!
      $invoiceUuid: String!
      $hasBillingInformation: Boolean!
      $hasCustomerSignatureInput: Boolean!
      $hasValuationDeclarationTable: Boolean!
      $hasTimesheetInformation: Boolean!
      $hasInventorySummary: Boolean!
      $hasInputPerMoverTimesheet: Boolean!
      $hasInputCommercialCatalogEquipment: Boolean!
      $hasInputCommercialCatalogMaterial: Boolean!
      $hasInputValuationCoverage: Boolean!
      $hasDocumentHeader: Boolean!
      $hasOrganizationLogo: Boolean!
      $hasOrganizationSignature: Boolean!
      $hasSurveyItems: Boolean!
      $hasCartonSummary: Boolean!
      $hasSurveyRoomsSummary: Boolean!
      $hasGeneralInventorySummary: Boolean!
      $hasInventoryItems: Boolean!
    ) {
    ${gql.query}
    project(uuid: $projectUuid){
      id
      ...ProjectDocumentV2BillingInformation @include(if: $hasBillingInformation)
      ...ProjectDocumentV2CustomerSignatureInput @include(if: $hasCustomerSignatureInput)
      ...ProjectDocumentV2InventorySummary @include(if: $hasInventorySummary)
      ...ProjectDocumentV2DocumentHeaderOrganization @include(if: $hasDocumentHeader)
      ...ProjectDocumentV2OrganizationLogo @include(if: $hasOrganizationLogo)
      ...ProjectDocumentV2OrganizationSignature @include(if: $hasOrganizationSignature)
      ...ProjectDocumentV2SurveyItems @include(if: $hasSurveyItems)
      ...ProjectDocumentV2CartonSummary @include(if: $hasCartonSummary)
      ...ProjectDocumentV2SurveyRoomsSummary @include(if: $hasSurveyRoomsSummary)
    }
    job(uuid: $jobUuid) @include(if: $isJobDocument){
      id
      ...DocumentV2TimesheetInformation @include(if: $hasTimesheetInformation)
      ...DocumentV2InputPerMoverTimesheet @include(if: $hasInputPerMoverTimesheet)
      ...DocumentV2ValuationDeclarationTable @include(if: $hasValuationDeclarationTable)
      ...DocumentV2InputCommercialCatalogEquipment @include(if: $hasInputCommercialCatalogEquipment)
      ...DocumentV2InputCommercialCatalogMaterial @include(if: $hasInputCommercialCatalogMaterial)
      ...DocumentV2ValuationCoverage @include(if: $hasInputValuationCoverage)
      ...ProjectDocumentV2GeneralInventorySummary @include(if: $hasGeneralInventorySummary)
      ...ProjectDocumentV2GeneralInventoryItems @include(if: $hasInventoryItems)
    }
    invoiceByUuid(invoiceUuid: $invoiceUuid) @include(if: $isInvoiceDocument){
      id
      ...InvoiceDocumentV2SummaryInformation @include(if: $isInvoiceDocument)
    }
  }
`;

export default DocumentV2ByCategory;
