// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Loading, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import Callout from '@shared/design/components/Callout';
import ClaimTypesTable from 'modules/Organization/Settings/Company/components/ClaimTypesTable';
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';
import CreateClaimTypeModal from 'modules/Organization/Settings/Company/components/CreateClaimTypeModal';
import OrganizationClaimStatuses from 'modules/Organization/Settings/Company/components/OrganizationClaimStatuses';

const PageContentContainer = Styled.View`
  flex: 1;
  padding-left: 24px;
`;

const PageHeaderText = Styled.Text`
  ${Typography.Heading1}
`;

const PageDescriptionText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const ClaimTypesHeader = ({organization, isEnabledClaimsProjectOrganizationMigration}) => {
  return (
    <React.Fragment>
      <PageHeaderText>Claim Types</PageHeaderText>
      <Space height={12} />
      <PageDescriptionText>Set up and configure claim types and statuses.</PageDescriptionText>
      {isEnabledClaimsProjectOrganizationMigration && !organization.isPrimary && (
        <React.Fragment>
          <Space height={12} />
          <Callout
            text={`Claims settings are set by the company. Please contact the admins at ${organization.company.primaryOrganization.name} to make adjustments.`}
            width={780}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ClaimTypes = ({organization, refetch, isEnabledClaimsProjectOrganizationMigration}) => {
  const createClaimTypeModal = useModal({name: 'Create Claim Type Modal'});

  return (
    <React.Fragment>
      {isEnabledClaimsProjectOrganizationMigration ? (
        organization.isPrimary && (
          <React.Fragment>
            <Button
              iconLeft={Icon.Plus}
              text={'Create Claim Type'}
              onPress={createClaimTypeModal.handleOpen}
            />
            <Space height={24} />
          </React.Fragment>
        )
      ) : (
        <React.Fragment>
          <Button
            iconLeft={Icon.Plus}
            text={'Create Claim Type'}
            onPress={createClaimTypeModal.handleOpen}
          />
          <Space height={24} />
        </React.Fragment>
      )}
      <ClaimTypesTable
        organization={organization}
        refetch={refetch}
        isEnabledClaimsProjectOrganizationMigration={isEnabledClaimsProjectOrganizationMigration}
      />
      <CreateClaimTypeModal
        key={createClaimTypeModal.key} // Ensures values are empty when modal is opened
        isOpen={createClaimTypeModal.isOpen}
        handleClose={createClaimTypeModal.handleClose}
        organizationId={organization.id}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const ClaimStatuses = ({
  organization,
  key,
  refetch,
  isEnabledClaimsProjectOrganizationMigration,
}) => {
  return (
    <OrganizationClaimStatuses
      organization={organization}
      key={key}
      refetch={refetch}
      isEnabledClaimsProjectOrganizationMigration={isEnabledClaimsProjectOrganizationMigration}
    />
  );
};

const SettingsCompanyClaimsPageContent = ({viewer, refetch}) => {
  const {viewingOrganization} = viewer;
  const {isEnabledClaimsProjectOrganizationMigration} = viewingOrganization.features;

  return (
    <PageContentContainer>
      <ScrollView>
        <Space height={24} />
        <ClaimTypesHeader
          organization={viewingOrganization}
          isEnabledClaimsProjectOrganizationMigration={isEnabledClaimsProjectOrganizationMigration}
        />
        <Space height={24} />
        <ClaimTypes
          organization={viewingOrganization}
          refetch={refetch}
          isEnabledClaimsProjectOrganizationMigration={isEnabledClaimsProjectOrganizationMigration}
        />
        <Space height={24} />
        <ClaimStatuses
          organization={viewingOrganization}
          key={viewingOrganization.companySettings.claimStatuses.length}
          refetch={refetch}
          isEnabledClaimsProjectOrganizationMigration={isEnabledClaimsProjectOrganizationMigration}
        />
      </ScrollView>
    </PageContentContainer>
  );
};

const OrganizationSettingsCompanyClaimsPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsCompanyClaimsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <CompanySettingsPage hasNoChildrenStyle>
      <Loading loading={loading}>
        {() => <SettingsCompanyClaimsPageContent viewer={data.viewer} refetch={refetch} />}
      </Loading>
    </CompanySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyClaimsPage.query = gql`
  ${OrganizationClaimStatuses.fragment}
  ${ClaimTypesTable.fragment}

  query OrganizationSettingsCompanyClaimsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        isPrimary
        slug
        company {
          id
          primaryOrganization {
            id
            name
          }
        }
        features {
          isEnabledClaimsProjectOrganizationMigration: isEnabled(feature: "CLAIMS_PROJECT_ORGANIZATION_MIGRATION")
        }
        companySettings {
          claimStatuses {
            id
            rank
          }
        }
        ...ClaimTypesTable
        ...OrganizationClaimStatuses
      }
    }
  }
`;

export default OrganizationSettingsCompanyClaimsPage;
