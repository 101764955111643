// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import BillBillRulesList from 'modules/Bill/components/BillBillRulesList';
import BillLineItemsList from 'modules/Bill/components/BillLineItemsList';
import BillPaymentsList from 'modules/Bill/components/BillPaymentsList';

const Container = Styled.View`
`;

const Section = Styled.View`
  align-self: stretch;
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const SectionHeader = Styled.View`
  margin-bottom: 5px;
  margin-horizontal: 20px;
`;

const SectionTitle = Styled.H6`
  ${fontWeight(700)}
`;

const SectionDescription = Styled.H7`
  margin-bottom: 5px;
  color: ${colors.gray.secondary};
`;

const getQuoteDescription = ({bill}) => {
  return bill.isEstimateAvailable
    ? 'Please note this is our best quote based on your information ' +
        '- the final price may differ from this quote.'
    : null;
};

const AggregateEstimateBillSection = ({sectionIndex, bill}) => {
  const hasPayments = bill.payments.filter((payment) => payment.isPaid).length > 0;

  return (
    <Section sectionIndex={sectionIndex}>
      <SectionHeader>
        <SectionTitle>
          {bill.job.day
            ? Datetime.convertToDisplayDate(bill.job.day.value, 'dddd, MMMM Do YYYY')
            : 'Date to be determined'}
        </SectionTitle>
        <SectionDescription>{getQuoteDescription({bill})}</SectionDescription>
      </SectionHeader>
      <BillLineItemsList
        hideQuantity={!bill.organization.features.isEnabledConfirmationShowQuantity}
        hideTotal={!bill.organization.features.isEnabledConfirmationShowTotal}
        bill={bill}
        style={{
          paddingHorizontal: 20,
        }}
      />
      {hasPayments && (
        <React.Fragment>
          <Space height={10} />
          <BillPaymentsList
            isEditable={false}
            bill={bill}
            hideTotalBalanceDue
            style={{
              paddingHorizontal: 20,
            }}
          />
        </React.Fragment>
      )}
      {_.get(bill, 'hasBillRulesForCustomer', false) && (
        <React.Fragment>
          <SectionHeader
            style={{
              marginTop: 20,
            }}
          >
            <SectionDescription>The following billing rules will apply.</SectionDescription>
          </SectionHeader>
          <BillBillRulesList isCustomer bill={bill} style={{marginHorizontal: 20}} />
        </React.Fragment>
      )}
    </Section>
  );
};

const PreviewProjectAggregateEstimateBill = ({sectionIndex, project}) => {
  return (
    <Container>
      {project.aggregateEstimateBill.bills
        .filter((bill) => bill.job.kind !== 'ESTIMATE')
        .map((bill) => (
          <React.Fragment key={bill.id}>
            <AggregateEstimateBillSection sectionIndex={sectionIndex} bill={bill} />
            <Space height={30} />
          </React.Fragment>
        ))}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PreviewProjectAggregateEstimateBill.fragment = gql`
  ${BillBillRulesList.fragment}
  ${BillLineItemsList.fragment}
  ${BillPaymentsList.fragment}

  fragment PreviewProjectAggregateEstimateBill on Project {
    id
    aggregateEstimateBill {
      bills {
        id
        isEstimateAvailable
        hasBillRulesForCustomer
        organization {
          id
          features {
            isEnabledConfirmationShowQuantity: isEnabled(feature: "CONFIRMATION_SHOW_QUANTITY")
            isEnabledConfirmationShowTotal: isEnabled(feature: "CONFIRMATION_SHOW_TOTAL")
          }
        }
        job {
          id
          kind
          day {
            id
            value
          }
        }
        payments {
          id
          isPaid
        }
        ...BillBillRulesList
        ...BillLineItemsList
        ...BillPaymentsList
      }
    }
  }
`;

export default PreviewProjectAggregateEstimateBill;
