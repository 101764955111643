// Supermove
import {gql} from '@supermove/graphql';
import {Duration, Float, withFragment} from '@supermove/utils';

const PayableItemForm = {
  SOURCE: {
    MANUAL_MOVE_LABOR: 'MANUAL_MOVE_LABOR',
  },

  UNIT: {
    MINUTE: 'MINUTE',
  },

  toForm: ({source, name, description, unit, rate, amount, userId, jobId, reportMoveId}) => ({
    source,
    name,
    description,
    unit,
    rate,
    amount: Float.toForm(Duration.toHours(amount)),
    userId,
    jobId,
    reportMoveId,
  }),

  toMutation: ({source, name, description, unit, rate, amount, userId, jobId, reportMoveId}) => ({
    source,
    name,
    description,
    unit,
    rate,
    amount: Float.toMutation(Duration.toMinutes(amount)),
    userId,
    jobId,
    reportMoveId,
  }),

  edit: withFragment(
    (payableItem) => ({
      source: payableItem.source,
      name: payableItem.name,
      description: payableItem.description,
      unit: payableItem.unit,
      rate: payableItem.rate,
      amount: payableItem.amount,
      userId: payableItem.userId,
      jobId: payableItem.jobId,
      reportMoveId: payableItem.reportMoveId,
    }),
    gql`
      fragment PayableItemForm_edit on PayableItem {
        source
        name
        description
        unit
        rate
        amount
        userId
        jobId
        reportMoveId
      }
    `,
  ),

  new: ({source, unit, rate, userId}) => ({
    source,
    name: '',
    description: '',
    unit,
    rate,
    amount: 0,
    userId,
    jobId: null,
    reportMoveId: null,
  }),
};

export default PayableItemForm;
