// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import TaskDueAtForm from '@shared/modules/TaskManagement/forms/TaskDueAtForm';

const useUpdateTaskDueAtMutation = ({taskDueAtForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      taskDueAtForm: TaskDueAtForm.toForm(taskDueAtForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateTaskDueAtMutation.mutation,
    variables: {
      taskDueAtForm: TaskDueAtForm.toMutation(form.values.taskDueAtForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateTaskDueAtMutation.mutation = gql`
  ${TaskDueAtForm.edit.fragment}
  mutation UpdateTaskDueAtMutation($taskDueAtForm: TaskDueAtForm) {
    response: updateTaskDueAt(taskDueAtForm: $taskDueAtForm) {
      ${gql.errors}
      task {
        id
        ...TaskDueAtForm_edit
      }
    }
  }
`;

export default useUpdateTaskDueAtMutation;
