// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Shared
import LocationForm from '@shared/modules/Location/forms/LocationForm';

const _new = ({organizationId, userId}) => ({
  warehouseId: null,
  organizationId,
  name: '',
  locationForm: LocationForm.new(),
  notes: '',
  isDefault: false,
  createdById: userId,
  updatedById: userId,
});

const edit = withFragment(
  (warehouse, {userId}) => ({
    warehouseId: warehouse.id,
    organizationId: warehouse.organizationId,
    name: warehouse.name,
    locationForm: LocationForm.edit(warehouse.location),
    notes: warehouse.notes,
    isDefault: warehouse.isDefault,
    isDeleted: warehouse.isDeleted,
    createdById: warehouse.createdById,
    updatedById: userId,
  }),
  gql`
    ${LocationForm.edit.fragment}
    fragment WarehouseForm_edit on Warehouse {
      id
      organizationId
      name
      notes
      isDeleted
      isDefault
      createdById
      updatedById
      location {
        id
        ...LocationForm_edit
      }
    }
  `,
);

const toForm = ({
  warehouseId,
  organizationId,
  name,
  locationForm,
  notes,
  isDeleted,
  isDefault,
  createdById,
  updatedById,
}) => ({
  warehouseId,
  organizationId,
  name,
  locationForm: LocationForm.toForm(locationForm),
  notes,
  isDeleted,
  isDefault,
  createdById,
  updatedById,
});

const toMutation = ({
  warehouseId,
  organizationId,
  name,
  locationForm,
  notes,
  isDeleted,
  isDefault,
  createdById,
  updatedById,
}) => ({
  warehouseId,
  organizationId,
  name,
  locationForm: LocationForm.toMutation(locationForm),
  notes,
  isDeleted,
  isDefault,
  createdById,
  updatedById,
});

const WarehouseForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default WarehouseForm;
