/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Tabs} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const TabText = Styled.Text`
  ${Typography.Label2};
  color: ${({color}) => color};
;`;

const SelectedTabBottomBorder = Styled.View`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  border-width: 1px;
  border-radius: 1px;
  border-color: ${colors.blue.interactive};
`;

const getBillingLibraryTabs = (billingLibrary) => {
  const {
    uuid,
    organization: {
      settings: {isCostAndCompensationEnabled},
    },
  } = billingLibrary;

  const baseTabs = [
    {
      label: 'BILL ITEMS',
      url: `/settings/billing/billing-libraries/${uuid}/items`,
    },
    {
      label: 'BILL RULES',
      url: `/settings/billing/billing-libraries/${uuid}/rules`,
    },
  ];

  const billTypeSettingsTab = [
    {
      label: 'BILL TEMPLATES',
      url: `/settings/billing/billing-libraries/${uuid}/bill-templates`,
    },
  ];

  const costItemTypeSettingsTabs = [
    {
      label: 'COST ITEMS',
      url: `/settings/billing/billing-libraries/${uuid}/cost-items`,
    },
    {
      label: 'COMPENSATION ITEMS',
      url: `/settings/billing/billing-libraries/${uuid}/compensation-items`,
    },
  ];

  return [
    ...baseTabs,
    ...billTypeSettingsTab,
    ...(isCostAndCompensationEnabled ? costItemTypeSettingsTabs : []),
  ];
};

const Tab = ({tab, isSelected}) => {
  const {navigator} = useNavigationDOM();

  return (
    <Tabs.TabContainer
      onPress={() => navigator.push(tab.url)}
      isSelected={isSelected}
      style={{
        marginRight: 24,
        borderBottomWidth: 0,
      }}
    >
      <TabText color={isSelected ? colors.blue.interactive : colors.gray.secondary}>
        {tab.label}
        {isSelected && <SelectedTabBottomBorder />}
      </TabText>
    </Tabs.TabContainer>
  );
};

const BillingLibraryNavigationTabs = ({billingLibrary}) => {
  const {navigator} = useNavigationDOM();
  const tabs = getBillingLibraryTabs(billingLibrary);
  const currentTab = _.findIndex(tabs, {url: navigator.location.pathname});

  return (
    <Tabs.TabBar selectedIndex={currentTab} handleChangeIndex={() => {}} TabComponent={Tab}>
      {tabs.map(({label, url}) => (
        <Tabs.Tab key={label} tab={{label, url}} />
      ))}
    </Tabs.TabBar>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryNavigationTabs.fragment = gql`
  fragment BillingLibraryNavigationTabs on BillingLibrary {
    id
    uuid
    organization {
      id
      settings {
        id
        isCostAndCompensationEnabled
      }
    }
  }
`;

export default BillingLibraryNavigationTabs;
