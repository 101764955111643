// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getNoDemoSlug = (slug) => {
  return slug.replace('-demo', '');
};

const getConfirmationSteps = withFragment(
  (organization) => {
    switch (getNoDemoSlug(organization.slug)) {
      case 'a2movingcompany':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$200',
            valueTwo: '3 days',
          },
        ];
      case 'abcmovingsystems':
      case 'abcmovingsystems-mezamovers':
      case 'abcmovingsystems-triplem':
      case 'abcmovingsystems-zaldivar':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CONFIRMATION_CALIFORNIA_BILL_OF_LADING',
            name: 'Agreement for Moving Services',
          },
        ];
      case 'ablemoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'ABLE_MOVING_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$100',
          },
        ];
      case 'ablemoving-commercial':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'ABLE_MOVING_COMMERCIAL_CONFIRMATION_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'ABLE_MOVING_COMMERCIAL_CONFIRMATION_AGREEMENT_FOR_SERVICES',
            name: 'Agreement for Services',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'acemovers':
      case 'affordablemoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$200',
            valueTwo: '3 days',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$200',
          },
        ];
      case 'adamsmovingservice':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'ADAMS_MOVING_SERVICE_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$250',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'afatherandsonmoving':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'afdmovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$100',
            valueTwo: '3 days',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'allamericanmoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'ALL_AMERICAN_MOVING_CONFIRMATION_AGREEMENT_FOR_MOVING_SERVICES_COMMERCIAL',
            name: 'Agreement for Moving Services - Commercial Move',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'ALL_AMERICAN_MOVING_CONFIRMATION_AGREEMENT_FOR_MOVING_SERVICES_RESIDENTIAL',
            name: 'Agreement for Moving Services - Residential Move',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'alltherightmovesltd':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'ALL_THE_RIGHT_MOVES_CONFIRMATION_STORAGE_CONTRACT',
            name: 'Storage Contract',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'alphamovingtn':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$100',
            valueTwo: '5 days',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'ammoving':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'AM_MOVING_CONFIRMATION_AGREEMENT_FOR_MOVING_SERVICES',
            name: 'Agreement for Moving Services',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'AM_MOVING_CONFIRMATION_INTRASTATE_AGREEMENT_FOR_MOVING_SERVICES',
            name: 'Intrastate Agreement for Moving Services',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'armbrustermoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'ARMBRUSTER_MOVING_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'ARMBRUSTER_MOVING_OPTIONAL_WAIVER_OF_INSPECTION',
            name: 'Optional Waiver of Inspection',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'ARMBRUSTER_MOVING_WAREHOUSE_AGREEMENT',
            name: 'Warehouse Agreement',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'ARMBRUSTER_MOVING_SMOOTH_TRANSITIONS',
            name: 'Smooth Transitions',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$0',
            valueTwo: '3 days',
          },
        ];
      case 'armoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CONFIRMATION_CALIFORNIA_BILL_OF_LADING',
            name: 'Agreement for Moving Services',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
        ];
      case 'atgmoving':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'ATG_MOVING_CONFIRMATION_DELIVERY_INFORMATION',
            name: 'Delivery Information',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'ATG_MOVING_CONFIRMATION_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'azvalleywidemovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'AZ_VALLEYWIDE_MOVERS_CONFIRMATION_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'b2movingcompany':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$300',
            valueTwo: '3 days',
          },
          {
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$100',
          },
        ];
      case 'barrysbestmovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$250',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'bigfootmoving':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'BIG_FOOT_MOVING_CONFIRMATION_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'BIG_FOOT_MOVING_STORAGE_RECURRING_AUTHORIZATION_FORM',
            name: 'Storage Recurring Payment Authorization Form',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'bluemoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'BLUE_MOVING_CONFIRMATION_INTERSTATE_BILL_OF_LADING',
            name: 'Bill of Lading - Interstate',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'bluxomemovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'BLUXOME_MOVERS_CONFIRMATION_STATEMENT_OF_GUARANTEE',
            name: 'Statement of Guarantee',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
          {
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$100',
          },
          {
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$200',
            valueTwo: '3 days',
          },
        ];
      case 'boothmovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'BOOTH_MOVERS_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$300',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'BOOTH_MOVERS_WAIVER_AND_RELEASE_FORM',
            name: 'Waiver and Release Form',
          },
        ];
      case 'carolinapackngo':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'carefulbudgetmoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$100',
            valueTwo: '1 days',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'caremoremoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$300',
            valueTwo: '7 days',
          },
        ];
      case 'changingspacesmoving':
      case 'changingspacesmoving-birmingham':
      case 'changingspacesmoving-huntsville':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CHANGING_SPACES_MOVING_CONFIRMATION_CONTRACT_TERMS_AND_CONDITIONS',
            name: 'Contract Terms and Conditions',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CHANGING_SPACES_MOVING_CONFIRMATION_RELEASE_OF_LIABILITY',
            name: 'Release of Liability',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_INVENTORY',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CHANGING_SPACES_MOVING_CONFIRMATION_INTERSTATE_FULL_VALUE_OPTIONS',
            name: 'Full Value Options (Interstate)',
          },
          // {
          //   isDefault: false,
          //   kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
          //   value: 'CHANGING_SPACES_MOVING_CONFIRMATION_LOCAL_MOVE',
          //   name: 'Local Move Confirmation',
          // },
          // {
          //   isDefault: false,
          //   kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
          //   value: 'CHANGING_SPACES_MOVING_CONFIRMATION_COMMERCIAL_MOVE',
          //   name: 'Commercial Move Confirmation',
          // },
          // {
          //   isDefault: false,
          //   kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
          //   value: 'CHANGING_SPACES_MOVING_CONFIRMATION_INTERSTATE_MOVE',
          //   name: 'Interstate Move Confirmation',
          // },
          // {
          //   isDefault: false,
          //   kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
          //   value: 'CHANGING_SPACES_MOVING_CONFIRMATION_INTRASTATE_MOVE',
          //   name: 'Intrastate Move Confirmation',
          // },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CHANGING_SPACES_MOVING_CONFIRMATION_WAIVER_OF_ON_SITE_ESTIMATE',
            name: 'Waiver of On-Site Estimate',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CHANGING_SPACES_MOVING_CONFIRMATION_VEHICLE_TOWING_RELEASE',
            name: 'Vehicle Towing Release',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CHANGING_SPACES_MOVING_CONFIRMATION_VEHICLE_WAIVER_OF_LIABILITY',
            name: 'Vehicle Waiver of Liability',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'cimplylabor':
      case 'cimplylabor-cimplisticlogistics':
      case 'cimplylabor-elitehome':
      case 'cimplylabor-excelmoving':
      case 'cimplylabor-prestigemoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CIMPLY_LABOR_HAZMAT_FORM',
            name: 'Hazmat Form',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CIMPLY_LABOR_STATEMENT_OF_GUARANTEE',
            name: 'Statement of Guarantee',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CIMPLY_LABOR_ACKNOWLEDGEMENT_OF_BOOKING',
            name: 'Acknowledgement of Booking',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CIMPLY_LABOR_SHIPMENT_PROTECTION_PLANS',
            name: 'Shipment Protection Plans',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CIMPLY_LABOR_ACKNOWLEDGEMENT_OF_MOVING_PERSONAL_BELONGINGS',
            name: 'Acknowledgment of Moving Personal Belongings',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
        ];
      case 'cooperspromoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'COOPERS_PRO_MOVING_CONFIRMATION_AGREEMENT_FOR_MOVING_SERVICES',
            name: 'Agreement for Moving Services',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'd1moving':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'D1_MOVING_CONFIRMATION_VA_BOOKLET',
            name: 'VA Booklet',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'D1_MOVING_CONFIRMATION_CLIENT_INFORMATION_PACKET',
            name: 'Client Information Packet',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'D1_MOVING_CONFIRMATION_BREAKDOWN_OF_CHARGES',
            name: 'Breakdown of Charges',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'D1_MOVING_CONFIRMATION_VALUATION_AND_PROPERTY_COVERAGE',
            name: 'Valuation and Moving Property Coverage',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'D1_MOVING_CONFIRMATION_HIGH_VALUE_INVENTORY',
            name: 'High Value Inventory',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'D1_MOVING_CONFIRMATION_PAYMENT_AUTHORIZATION_FORM',
            name: 'Payment Authorization Form',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'D1_MOVING_CONFIRMATION_CREDIT_CARD_AUTHORIZATION_FORM',
            name: 'Credit Card Authorization Form',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'delaneyworldwide':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'districtmoving':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'DISTRICT_MOVING_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
        ];
      case 'division1moving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'DIVISION_1_MOVING_CONFIRMATION_VALUATION_COVERAGE',
            name: 'Valuation and Moving Property Coverage',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'DIVISION_1_MOVING_CONFIRMATION_HIGH_VALUE_INVENTORY',
            name: 'High Value Inventory',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'DIVISION_1_MOVING_CONFIRMATION_CREDIT_CARD_AUTHORIZATION',
            name: 'Payment and Credit Card Authorization',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'donsmovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$300',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'eaglemovingandstorage':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$200',
            valueTwo: '3 days',
          },
        ];
      case 'easternshoretransfer':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$50',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'EASTERN_SHORE_TRANSFER_CONFIRMATION_RELEASE_OF_LIABILITY',
            name: 'Release of Liability',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'EASTERN_SHORE_TRANSFER_CONFIRMATION_BILL_OF_LADING',
            name: 'Bill of Lading',
          },
        ];
      case 'evergreenmtnmovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$350',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'eurmove':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'EURMOVE_CONFIRMATION_MOVING_CONTRACT',
            name: 'Moving Contract',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$250',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'excellentmovers':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'EXCELLENT_MOVERS_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'farnsworthexpress':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$500',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'FARNSWORTH_EXPRESS_CONFIRMATION_ITEM_465',
            name: 'Item 465',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'FARNSWORTH_EXPRESS_CONFIRMATION_VALUATION_COVERAGE_OPTIONS',
            name: 'Valuation Coverage',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'goingmoves':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'gracefultransitions':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$200',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'GRACEFUL_TRANSITIONS_CONFIRMATION_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'gracemoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'GRACE_MOVING_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'hamptonroadsmoving':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'HAMPTON_ROADS_MOVING_CONFIRMATION_VALUATION_OPTIONS',
            name: 'Full Value Protection / Additional Liability Coverage',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'HAMPTON_ROADS_MOVING_CONFIRMATION_INTERSTATE_FULL_VALUE_PROTECTION',
            name: 'Interstate Full Value Protection',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value:
              'HAMPTON_ROADS_MOVING_CONFIRMATION_COD_INTERSTATE_ORDER_FOR_SERVICE_WITH_PACKING',
            name: 'COD Interstate Order for Service with Packing',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value:
              'HAMPTON_ROADS_MOVING_CONFIRMATION_COD_INTERSTATE_ORDER_FOR_SERVICE_WITHOUT_PACKING',
            name: 'COD Interstate Order for Service without Packing',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'herlihymoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'HERLIHY_MOVING_CONFIRMATION_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$500',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'HERLIHY_MOVING_CONFIRMATION_OPTIONAL_WAIVER_OF_VISUAL_INSPECTION',
            name: 'Optional Waiver of Visual Inspection',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'HERLIHY_MOVING_CONFIRMATION_HIGH_VALUE_INVENTORY',
            name: 'Optional High Value Inventory',
          },
        ];
      case 'hiltonmovers':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$500',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'homeandofficetransitions':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CONFIRMATION_STANDARD_BILL_OF_LADING',
            name: 'Agreement for Moving Services',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'imsrelocation':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'IMS_RELOCATION_STORAGE_CONTRACT',
            name: 'Storage Contract',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'IMS_RELOCATION_CREDIT_CARD_AUTH_FORM',
            name: 'Credit Card Authorization Form',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'IMS_RELOCATION_CONFIRMATION_DIRECT_PAYMENT',
            name: 'Agreement for Direct Payment to Mover',
          },
        ];
      case 'integrity1stmoving':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'johnsondalymoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'JOHNSON_DALY_MOVING_CONFIRMATION_BILL_OF_LADING',
            name: 'Bill of Lading',
          },
        ];
      case 'katomoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'KATO_MOVING_VALUATION_COVERAGE_OPTIONS',
            name: 'Valuation Coverage Options',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'KATO_MOVING_LOGISTICS_FORM',
            name: 'Logistics Form',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$100',
            valueTwo: '2 days',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'kowalskimoves':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'labarberamovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$150',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'lakecoeurdalenemovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'lifestylemoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$50',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'localmoverswvky':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$70',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$70',
            valueTwo: '2 days',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'maestromovers':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MAESTRO_MOVERS_CONFIRMATION_AGREEMENT_FOR_MOVING_SERVICES',
            name: 'Agreement for Moving Services',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'marchingantmoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MARCHING_ANT_MOVING_CONFIRMATION_STIPULATIONS',
            name: 'Confirmation Document 2',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'marksmoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MARKS_MOVING_CONFIRMATION_AGREEMENT_OF_RATE_STRUCTURE',
            name: 'Agreement of Rate Structure',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MARKS_MOVING_CONFIRMATION_BILL_OF_LADING',
            name: 'Bill of  Lading',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'massmovers':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$500',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'mattsmoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MATTS_MOVING_CONFIRMATION_SERVICE_AGREEMENT',
            name: 'Service Agreement',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MATTS_MOVING_CONFIRMATION_ACH_CAPTURE_FORM',
            name: 'ACH Capture Form',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'mckmoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$140',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$100',
            valueTwo: '3 days',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'menmovingmountains':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MEN_MOVING_MOUNTAINS_VALUATION_OPTIONS_NC',
            name: 'Valuation Options NC',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MEN_MOVING_MOUNTAINS_VALUATION_OPTIONS_INTERSTATE',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$625',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$500',
            valueTwo: '5 days',
          },
        ];
      case 'merchantsmoves':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MERCHANTS_MOVES_CONFIRMATION_VALUATION_COVERAGE_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'metrovan':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SUPERMOVE_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'meyersmovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MEYERS_MOVERS_ILLINOIS_MOVERS_INFORMATION',
            name: 'Illinois Movers Information',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'minnemovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$200',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'modernmovingcompany':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$100',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MODERN_MOVING_COMPANY_CONFIRMATION_NON_BINDING_ESTIMATE',
            name: 'Agreement for Moving Services - Non-Binding Estimate',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'monarchmovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'movemedo':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$50',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'moveoutmen':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MOVE_OUT_MEN_CONFIRMATION_BILL_OF_LADING',
            name: 'Agreement for Moving Services',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'moveprola':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$200',
            valueTwo: '3 days',
          },
        ];
      case 'movingaheadservices':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MOVING_AHEAD_SERVICES_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MOVING_AHEAD_SERVICES_CONFIRMATION_INTERSTATE_BILL_OF_LADING',
            name: 'Interstate Bill of Lading',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'movingwichita':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MOVING_WICHITA_CONFIRMATION_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'moyerandsons':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MOYER_AND_SONS_CONFIRMATION_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MOYER_AND_SONS_CONFIRMATION_LOCAL_SHIPPING_ORDER',
            name: 'Local Shipping Order',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'mustangmovingtx':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$200',
            valueTwo: '2 days',
          },
          {
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$200',
          },
        ];
      case 'musthavemovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$100',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'myguysmoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MY_GUYS_MOVING_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MY_GUYS_MOVING_CREDIT_CARD_AUTH',
            name: 'Credit Card Authorization',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'MY_GUYS_MOVING_WAREHOUSE_AGREEMENT',
            name: 'Warehouse Agreement, Terms of Service',
          },
        ];
      case 'mypieceofcakemove':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$50',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'nagleegroup':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'NAGLEE_GROUP_CONFIRMATION_NFA_SCOPE_OF_SERVICES_AGREEMENT',
            name: 'NFA - Scope of Services Agreement',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$250',
            valueTwo: '3 days',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'newviewmoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$250',
            valueTwo: '5 days',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$100',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'nomadexmoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$50',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'nomadmovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
          {
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
        ];
      case 'onthegrind':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'paradisemoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$200',
            valueTwo: '7 days',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
          {
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$100',
          },
        ];
      case 'pedrosmoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
        ];
      case 'pnwpreferred':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'PNW_PREFERRED_CONFIRMATION_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'PNW_PREFERRED_CONFIRMATION_ACKNOWLEDGEMENT_WA_MOVING_GUIDE',
            name: 'Acknowledgement of WA Moving Guide',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'primemovingla':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$100',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'profmovingco':
      case 'victorymoverslosangeles':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$0',
            valueTwo: '3 days',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'propermoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$190',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'qrmovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$100',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'randrmoving':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'ridgewoodmoving':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'RIDGEWOOD_MOVING_CONFIRMATION_HIGH_VALUABLES',
            name: 'High Valuables',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'RIDGEWOOD_MOVING_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'RIDGEWOOD_MOVING_CONFIRMATION_ITEMS_OF_HIGH_VALUE',
            name: 'Items of High Value',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'ripplemoving':
      case 'ripplemoving-charleston':
      case 'ripplemoving-columbia':
      case 'ripplemoving-raleigh':
      case 'ripplemoving-wilmington':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$50',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'robbandmesser':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CONFIRMATION_CALIFORNIA_BILL_OF_LADING',
            name: 'Agreement for Moving Services',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
          {
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$100',
          },
          {
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$200',
            valueTwo: '3 days',
          },
        ];
      case 'rollersmoving':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$100',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'rwaymoving':
        return [
          // {
          //   isDefault: false,
          //   kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
          //   value: '$100',
          // },
          // {
          //   isDefault: false,
          //   kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
          //   value: '$0',
          //   valueTwo: '1 days',
          // },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'RWAY_MOVING_CONFIRMATION_CREDIT_CARD_AUTHORIZATION_FORM',
            name: 'Confirmation Deposit - Credit Card Authorization Form',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'shamrockmovingstorage':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SHAMROCK_MOVING_STORAGE_CONFIRMATION_BOL',
            name: 'Agreement for Moving Services',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SHAMROCK_MOVING_STORAGE_COVID_GUIDELINES',
            name: 'Covid Guidelines',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SHAMROCK_MOVING_STORAGE_TERMS_AND_CONDITIONS',
            name: 'Terms and Conditions',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SHAMROCK_MOVING_STORAGE_VALUATION_COVERAGE',
            name: 'Valuation Coverage (Insurance)',
          },
        ];
      case 'shannonmoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$250',
            valueTwo: '3 days',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'simonikallied':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SIMONIK_ALLIED_CONFIRMATION_CREDIT_CARD_AUTHORIZATION_FORM',
            name: 'Allied / Vanline Credit Card Authorization Form',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SIMONIK_ALLIED_CONFIRMATION_LOCAL_CREDIT_CARD_AUTHORIZATION_FORM',
            name: 'Local Moves Credit Card Authorization Form',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SIMONIK_ALLIED_PA_CONFIRMATION_ORDER_FOR_SERVICE',
            name: 'Order for Service - PA',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SIMONIK_ALLIED_PA_CONFIRMATION_SHIPMENT_COVERAGE',
            name: 'Shipment Coverage - PA',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SIMONIK_ALLIED_NJ_CONFIRMATION_SHIPMENT_COVERAGE',
            name: 'Shipment Coverage - NJ',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SIMONIK_ALLIED_PA_CONFIRMATION_TERMS_AND_CONDITIONS',
            name: 'Contract Terms and Conditions - PA',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SIMONIK_ALLIED_NJ_CONFIRMATION_TERMS_AND_CONDITIONS',
            name: 'Contract Terms and Conditions - NJ',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SIMONIK_ALLIED_CONFIRMATION_ALLIED_RELEASE_FORM',
            name: 'Allied Release Form',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SIMONIK_ALLIED_CONFIRMATION_LOCAL_RELEASE_FORM',
            name: 'Local Release Form',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SIMONIK_ALLIED_CONFIRMATION_HIGH_VALUE_ITEMS',
            name: 'High Value Items',
          },
        ];
      case 'simplimove':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$100',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'sonemoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$200',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$200',
            valueTwo: '3 days',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'soundmovingnw':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SOUND_MOVING_NW_LOSS_AND_DAMAGE_PROTECTION',
            name: 'Loss and Damage Protection (Valuation)',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'squaredealmoving':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SQUARE_DEAL_MOVING_CONFIRMATION_BILL_OF_LADING',
            name: 'UNIFORM HOUSEHOLD GOODS BILL OF LADING AND FREIGHT BILL',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'starint':
      case 'starint-warehouse':
      case 'starint-drayage':
      case 'starint-thirdparty':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'STAR_INT_CONFIRMATION_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'stephans':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$100',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CONFIRMATION_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'strattonandsons':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CONFIRMATION_CALIFORNIA_BILL_OF_LADING',
            name: 'Agreement for Moving Services',
          },
        ];
      case 'sunrisemoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SUNRISE_MOVING_CONFIRMATION_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'supermove':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SUPERMOVE_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
          {
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$100',
          },
          {
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$200',
            valueTwo: '3 days',
          },
          {
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'SUPERMOVE_CUSTOMER_INVENTORY',
            name: 'Customer Inventory',
          },
        ];
      case 'texaselitemoving':
        return [
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'TEXAS_ELITE_MOVING_CONFIRMATION_BILL_OF_LADING',
            name: 'Not To Exceed Proposal / Contract for Moving Services',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'thenymover':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$10',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$0',
            valueTwo: '4 days',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'tigermovingservices':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$400',
            valueTwo: '5 days',
          },
        ];
      case 'tracload':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$0',
            valueTwo: '3 days',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'truckandi':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'uniquemoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$200',
            valueTwo: '1 days',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'universalmoversllc':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_INVENTORY',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'UNIVERSAL_MOVERS_LLC_VALUATION_OPTIONS',
            name: 'Valuation Options',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
        ];
      case 'uscmovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$100',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'vetrunmovers':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'watersmoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'WATERS_MOVING_CONFIRMATION_LOCAL_MOVE_BILL_OF_LADING',
            name: 'HHG Agreement for Moving Services',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'WATERS_MOVING_CONFIRMATION_COMMERCIAL_MOVE_BILL_OF_LADING',
            name: 'Commercial Agreement for Moving Services',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'welldonemoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'WELL_DONE_MOVING_CONFIRMATION_RELEASE_OF_LIABILITY',
            name: 'Release of Liability',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE',
            value: 'CONFIRMATION_CALIFORNIA_BILL_OF_LADING',
            name: 'Agreement for Moving Services',
          },
        ];
      case 'wpmoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_MAKE_DEPOSIT',
            value: '$0',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$2400',
            valueTwo: '2 days',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
      case 'ybtmoving':
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SAVE_CREDIT_CARD',
            value: '$300',
            valueTwo: '1 days',
          },
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
        ];
      default:
        return [
          {
            isDefault: true,
            kind: 'CONFIRMATION_STEP_SIGN_DOCUMENT',
          },
          {
            isDefault: false,
            kind: 'CONFIRMATION_STEP_ACCEPT_QUOTE',
          },
        ];
    }
  },
  gql`
    fragment Organization_getConfirmationSteps on Organization {
      id
      slug
    }
  `,
);

export default getConfirmationSteps;
