// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (organization) => ({
    organizationId: organization.id,
    pin: organization.pin,

    // Private
    name: organization.name,
    slug: organization.slug,
  }),
  gql`
    fragment OrganizationTabletSettingsForm_edit on Organization {
      id
      name
      slug
      pin
    }
  `,
);

const toForm = ({organizationId, pin, name, slug}) => ({
  organizationId,
  pin,

  // Private
  name,
  slug,
});

const toMutation = ({organizationId, pin}) => ({
  organizationId,
  pin,
});

const OrganizationTabletSettingsForm = {
  edit,
  toForm,
  toMutation,
};

export default OrganizationTabletSettingsForm;
