// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import DownloadDocumentForm from '@shared/modules/Document/forms/DownloadDocumentForm';

const useDownloadDocumentMutation = ({downloadDocumentForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      downloadDocumentForm: DownloadDocumentForm.toForm(downloadDocumentForm),
    },
  });
  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDownloadDocumentMutation.mutation,
    variables: {
      documentId: form.values.downloadDocumentForm.documentId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDownloadDocumentMutation.mutation = gql`
  mutation DownloadDocumentMutation($documentId: Int!) {
    response: downloadDocument(
      documentId: $documentId,
    ) {
      ${gql.errors}
      document {
        id
      }
      url
    }
  }
`;

export default useDownloadDocumentMutation;
