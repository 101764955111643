// Libraries
import React from 'react';

// Supermove
import {CurrencyInput, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import FieldValue from '@shared/design/components/Field/FieldValue';
import ProjectAttachmentUploader from '@shared/modules/File/components/ProjectAttachmentUploader';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ResponsiveTextInput from 'modules/App/components/ResponsiveTextInput';
import StatusBadge from 'modules/App/components/StatusBadge';
import ClaimStatusDropdown from 'modules/Project/Claim/components/ClaimStatusDropdown';
import ClaimTypeDropdown from 'modules/Project/Claim/components/ClaimTypeDropdown';
import ProjectClientCommunicationButtons from 'modules/Project/components/ProjectClientCommunicationButtons';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SectionHeader = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const ClaimTypeColor = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({color}) => color};
`;

const ClaimInformation = ({label, information}) => {
  return <FieldValue label={label} value={information} isResponsive empty={'None'} />;
};

const ProjectIdentifier = ({project}) => {
  return <ClaimInformation label={'Project'} information={`Project ${project.identifier}`} />;
};

const ClientName = ({project}) => {
  return <ClaimInformation label={'Client'} information={project.client.name} />;
};

const ClaimType = ({form, field, project, index, isViewMode, claim, responsive}) => {
  return (
    <React.Fragment>
      {isViewMode ? (
        <React.Fragment>
          <FieldValue.LabelText isResponsive responsive={responsive}>
            Claim Type
          </FieldValue.LabelText>
          <FieldValue.LabelSpace isResponsive responsive={responsive} />
          <Row>
            <ClaimTypeColor color={claim.claimType.color} />
            <Space width={8} />
            <FieldValue.ValueText responsive={responsive}>
              {claim.claimType.name}
            </FieldValue.ValueText>
          </Row>
        </React.Fragment>
      ) : (
        <ClaimTypeDropdown
          form={form}
          field={field}
          organization={project.organization}
          index={index}
        />
      )}
    </React.Fragment>
  );
};

const ClaimStatus = ({form, field, project, index, isViewMode, claim, responsive}) => {
  return (
    <React.Fragment>
      {isViewMode ? (
        <React.Fragment>
          <FieldValue.LabelText isResponsive responsive={responsive}>
            Status
          </FieldValue.LabelText>
          <FieldValue.LabelSpace isResponsive responsive={responsive} />
          <Row>
            <StatusBadge
              color={claim.claimStatus.color}
              label={claim.claimStatus.name}
              size={StatusBadge.SIZE.LARGE}
            />
          </Row>
        </React.Fragment>
      ) : (
        <ClaimStatusDropdown
          form={form}
          field={field}
          organization={project.organization}
          index={index}
          isDefaulted
        />
      )}
    </React.Fragment>
  );
};

const ClaimAmount = ({form, field, index, isViewMode, claim}) => {
  return (
    <React.Fragment>
      {isViewMode ? (
        <ClaimInformation
          label={'Amount'}
          information={claim.amount && Currency.display(claim.amount)}
        />
      ) : (
        <FieldInput
          {...form}
          name={`${field}.amount`}
          label={'Amount'}
          component={CurrencyInput}
          index={index}
          tooltip={'Enter the expected or exact dollar amount for this claim.'}
          isResponsive
          input={{
            component: FieldInput.TextInput,
            placeholder: '$0.00',
            keyboardType: 'phone-pad',
            setFieldTouched: form.setFieldTouched,
            setFieldValue: form.setFieldValue,
          }}
          style={{
            flex: 1,
          }}
        />
      )}
    </React.Fragment>
  );
};

const ClaimNotes = ({form, field, index, isViewMode, claim}) => {
  return (
    <React.Fragment>
      {isViewMode ? (
        <ClaimInformation label={'Notes'} information={claim.notes} />
      ) : (
        <React.Fragment>
          <FieldInput.LabelText isResponsive>Notes</FieldInput.LabelText>
          <Space height={4} />
          <ResponsiveTextInput
            form={form}
            field={`${field}.notes`}
            minHeight={68}
            isResponsive
            input={{
              placeholder: 'Enter notes',
              style: {zIndex: 100 - index},
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ProjectClaimDrawerFieldsContent = ({
  project,
  form,
  field,
  isViewMode,
  claim,
  refetch,
  handlePressSms,
  handlePressEmail,
}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <Row>
        <SectionHeader responsive={responsive}>Claim Details</SectionHeader>
        <Space style={{flex: 1}} />
        {isViewMode && (
          <ProjectClientCommunicationButtons
            project={project}
            refetch={refetch}
            iconSize={20}
            spaceWidth={8}
            // The negative marginTop makes it so that the buttons don't
            // throw off the vertical alignment of the section header. This
            // way there isn't movement in the header text when toggling
            // from view mode to edit mode.
            buttonStyle={{height: 32, width: 32, marginTop: -6}}
            handlePressSms={handlePressSms}
            handlePressEmail={handlePressEmail}
          />
        )}
      </Row>
      <Space height={16} />
      <ProjectIdentifier project={project} />
      <Space height={16} />
      <ClientName project={project} />
      <Space height={16} />
      <ClaimType
        form={form}
        field={field}
        project={project}
        index={0}
        isViewMode={isViewMode}
        claim={claim}
        responsive={responsive}
      />
      <Space height={16} />
      <ClaimStatus
        form={form}
        field={field}
        project={project}
        index={1}
        isViewMode={isViewMode}
        claim={claim}
        responsive={responsive}
      />
      <Space height={16} />
      <ClaimAmount form={form} field={field} index={2} isViewMode={isViewMode} claim={claim} />
      <Space height={16} />
      <ClaimNotes form={form} field={field} index={3} isViewMode={isViewMode} claim={claim} />
      <Space height={24} />
      <SectionHeader responsive={responsive}>Attachments</SectionHeader>
      <Space height={16} />
      <ProjectAttachmentUploader
        form={form}
        field={`${field}.attachmentForms`}
        project={project}
        isViewMode={isViewMode}
        dragInputContainerStyle={{padding: 0}}
        attachmentRowStyle={{paddingHorizontal: 0}}
      />
    </React.Fragment>
  );
};

const ProjectClaimDrawerFields = ({
  form,
  field,
  projectUuid,
  isOpen,
  isViewMode,
  claim,
  handlePressSms,
  handlePressEmail,
}) => {
  const {data, loading, refetch} = useQuery(ProjectClaimDrawerFields.query, {
    fetchPolicy: 'cache-and-network',
    variables: {projectUuid},
    skip: !isOpen,
  });
  return (
    <Loading loading={loading || !data} as={PageLoadingIndicator}>
      {() => {
        return (
          <ProjectClaimDrawerFieldsContent
            project={data.project}
            form={form}
            field={field}
            isViewMode={isViewMode}
            claim={claim}
            refetch={refetch}
            handlePressSms={handlePressSms}
            handlePressEmail={handlePressEmail}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectClaimDrawerFields.query = gql`
  ${ClaimStatusDropdown.fragment}
  ${ClaimTypeDropdown.fragment}
  ${ProjectAttachmentUploader.fragment}
  ${ProjectClientCommunicationButtons.fragment}
  query ProjectClaimDrawerFields($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      identifier
      client {
        id
        name
      }
      organization {
        id
        ...ClaimStatusDropdown
        ...ClaimTypeDropdown
      }
      ...ProjectAttachmentUploader
      ...ProjectClientCommunicationButtons
    }
  }
`;

ProjectClaimDrawerFields.fragment = gql`
  fragment ProjectClaimDrawerFields on Claim {
    id
    amount
    notes
    claimType {
      id
      name
      color
    }
    claimStatus {
      id
      name
      color
    }
  }
`;

export default ProjectClaimDrawerFields;
