// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useUpdateAllSeenAtInAppNotificationsMutation = ({receiverId, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      receiverId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateAllSeenAtInAppNotificationsMutation.mutation,
    variables: {receiverId},
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateAllSeenAtInAppNotificationsMutation.mutation = gql`
  mutation useUpdateAllSeenAtInAppNotificationsMutation($receiverId: Int!){
    response: updateAllSeenAtInAppNotifications(receiverId: $receiverId){
      ${gql.errors}
    }
  }
`;

export default useUpdateAllSeenAtInAppNotificationsMutation;
