import React from 'react';

import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

import Switch from '@shared/design/components/Switch';
import CodatIntegrationForm from '@shared/modules/Integration/forms/CodatIntegrationForm';
import useUpdateCodatIntegrationMutation from '@shared/modules/Integration/hooks/useUpdateCodatIntegrationMutation';
import TextTooltip from 'modules/App/components/TextTooltip';

const Row = Styled.View`
  flex-direction: row;
`;

const CodatIntegrationActiveSwitch = ({integration, refetch}) => {
  const codatIntegrationForm = CodatIntegrationForm.edit(integration);
  const {form, handleSubmit} = useUpdateCodatIntegrationMutation({
    codatIntegrationForm,
    onSuccess: () => {
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  const hasAnotherActiveIntegration =
    integration.organization.activeCodatIntegration &&
    integration.organization.activeCodatIntegration.id !== integration.id;
  return (
    <TextTooltip
      text={
        hasAnotherActiveIntegration
          ? 'This organization already has another active accounting integration. Disable it before enabling this one.'
          : null
      }
    >
      <Row>
        <Switch
          isOn={codatIntegrationForm.status === 'ACTIVE'}
          disabled={hasAnotherActiveIntegration}
          onChange={() => {
            form.setFieldValue(
              'codatIntegrationForm.status',
              codatIntegrationForm.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
            );
            setImmediate(() => {
              handleSubmit();
            });
          }}
          labelRight={codatIntegrationForm.status === 'ACTIVE' ? 'Enabled' : 'Disabled'}
        />
      </Row>
    </TextTooltip>
  );
};

CodatIntegrationActiveSwitch.fragment = gql`
  ${CodatIntegrationForm.edit.fragment}
  fragment CodatIntegrationActiveSwitch on CodatIntegration {
    id
    status
    ...CodatIntegrationForm_edit
    organization {
      id
      activeCodatIntegration {
        id
      }
    }
  }
`;

export default CodatIntegrationActiveSwitch;
