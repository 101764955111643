// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, DropdownInput, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useQuery} from '@supermove/hooks';
import {Payment} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';
import Switch from '@shared/design/components/Switch';
import InvoicePaymentTerm from '@shared/modules/Billing/enums/InvoicePaymentTerm';
import DocumentTemplateCategory from '@shared/modules/Document/enums/DocumentTemplateCategory';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import ProjectTypeBillingSettingsForm from '@shared/modules/Project/forms/ProjectTypeBillingSettingsForm';
import useUpdateProjectTypeBillSettingsMutation from '@shared/modules/Project/hooks/useUpdateProjectTypeBillSettingsMutation';
import Line from 'components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import TextBadge from 'modules/App/components/TextBadge';
import EditProjectTypeBillingSettingsModal from 'modules/Organization/Settings/ProjectTypes/components/EditProjectTypeBillingSettingsModal';
import ProjectTypeSettingsPageHeader from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSettingsPageHeader';

const PageContainer = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
`;

const PageContentContainer = Styled.View`
  flex: 1;
  padding-left: 24px;
  background-color: ${colors.gray.background};
`;

const ContentContainer = Styled.View`
  max-width: 840px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  background-color: ${colors.white};

`;

const CrewPaymentMethodItemContainer = Styled.View`
  flex: 4;
`;

const GrayText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const Label = Styled.Text`
  ${Typography.Body}
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const HeaderText = Styled.Text`
  ${Typography.Label1}
`;

const RowHeaderText = Styled.Text`
  ${Typography.Heading2}
;`;

const SubHeadingText = Styled.Text`
${Typography.Subheading}
;`;
const RowLabelRow = Styled.View`
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
`;

const PaddingContainer = Styled.View`
  padding-horizontal: 24px;
`;

const ProjectTypeBillingPaymentMethodSection = ({projectType, refetch}) => {
  const editProjectTypeBillingPaymentMethod = useModal({
    name: 'EditProjectTypeBillingPaymentMethod',
  });
  const {crewPaymentMethods} = projectType;

  return (
    <React.Fragment>
      <ContentContainer>
        <RowLabelRow>
          <RowHeaderText>Payment Settings</RowHeaderText>
          <Space style={{flex: 1}} />
          <TertiaryButton
            iconLeft={Icon.Pen}
            text={'Edit'}
            onPress={editProjectTypeBillingPaymentMethod.handleOpen}
          />
          <EditProjectTypeBillingSettingsModal
            projectType={projectType}
            key={editProjectTypeBillingPaymentMethod.key}
            isOpen={editProjectTypeBillingPaymentMethod.isOpen}
            handleClose={editProjectTypeBillingPaymentMethod.handleClose}
            refetch={refetch}
          />
        </RowLabelRow>
        <Line />
        <Space height={24} />
        <PaddingContainer>
          <SubHeadingText>Crew App</SubHeadingText>
        </PaddingContainer>
        <RowLabelRow>
          <CrewPaymentMethodItemContainer>
            <Text>
              {crewPaymentMethods.map((method, index) => (
                <React.Fragment key={index}>
                  <TextBadge
                    text={Payment.getSettingsDisplayMethod({method})}
                    color={colors.blue.interactive}
                  />
                  <Space width={8} height={24} />
                </React.Fragment>
              ))}
            </Text>
          </CrewPaymentMethodItemContainer>
        </RowLabelRow>
      </ContentContainer>
    </React.Fragment>
  );
};

const ProjectTypeBillingAccountingSection = ({projectType}) => {
  const projectTypeBillingSettingsForm = ProjectTypeBillingSettingsForm.edit(projectType);

  const turnOffAccountingExportWarningModal = useModal({
    name: 'Turn Off Accounting Export Warning Modal',
    enableTracking: true,
  });

  const {form, handleSubmit} = useUpdateProjectTypeBillSettingsMutation({
    projectTypeBillingSettingsForm,
    onSuccess: () => {},
    onError: () => {},
  });

  const isEnabledAutomaticallyExportInvoiceToCodatField =
    'projectTypeBillingSettingsForm.isEnabledAutomaticallyExportInvoiceToCodat';

  const isEnabledAutomaticallyFinalizeInvoices =
    'projectTypeBillingSettingsForm.isEnabledAutomaticallyFinalizeInvoices';

  return (
    <React.Fragment>
      <ContentContainer>
        <RowLabelRow>
          <RowHeaderText>Accounting</RowHeaderText>
        </RowLabelRow>
        <Line />
        <RowLabelRow>
          <Switch
            isOn={_.get(form, `values.${isEnabledAutomaticallyFinalizeInvoices}`)}
            onChange={(value) => {
              form.setFieldValue(isEnabledAutomaticallyFinalizeInvoices, value);
              setTimeout(handleSubmit, 0);
            }}
          />
          <Space width={10} />
          <TextBodyContent
            primaryText={'Automatically Finalize Invoices'}
            secondaryText={
              'When enabled, invoices will automatically be locked and finalized when the final job is complete and remaining \nbalance across all invoices is $0. When disabled, invoices must be manually finalized.'
            }
          />
        </RowLabelRow>
        {/* Only show this setting if there is a codat integration set up already */}
        {projectType.organization.activeCodatIntegration && (
          <RowLabelRow>
            <Switch
              isOn={_.get(form, `values.${isEnabledAutomaticallyExportInvoiceToCodatField}`)}
              onChange={(value) => {
                if (value) {
                  form.setFieldValue(isEnabledAutomaticallyExportInvoiceToCodatField, value);
                  setTimeout(handleSubmit, 0);
                } else {
                  turnOffAccountingExportWarningModal.handleOpen();
                }
              }}
            />
            <Space width={10} />
            <TextBodyContent
              primaryText={'Automatically Queue for Quickbooks Export'}
              secondaryText={
                'By enabling this, any changes to bills on an invoice or payments made will automatically be \nqueued for export to Quickbooks'
              }
            />
          </RowLabelRow>
        )}
        <PaddingContainer>
          <RowHeaderText>Invoice Template Settings</RowHeaderText>
          <Space height={8} />
          <GrayText>
            {
              'Select a default invoice template and set the invoice terms for this project type. You can override the default when previewing and sending an invoice in the project.'
            }
          </GrayText>
          <ProjectTypeInvoiceTemplateSettingsFields
            form={form}
            documentTemplatesByCategory={projectType.organization.documentTemplatesByCategory}
            handleSubmit={handleSubmit}
          />
        </PaddingContainer>
        <PaddingContainer>
          <RowHeaderText>Email Template Settings</RowHeaderText>
          <Space height={8} />
          <GrayText>
            {
              'Select the default email templates for invoices and receipt emails that will be sent to the customer. You can override the default when previewing and sending an invoice in the project.'
            }
          </GrayText>
          <Space height={8} />
          <ProjectTypeInvoiceEmailTemplateSettingsFields
            form={form}
            invoiceEmailTemplate={projectType.organization.invoiceEmailTemplate}
            invoiceReceiptEmailTemplate={projectType.organization.invoiceReceiptEmailTemplate}
            handleSubmit={handleSubmit}
          />
        </PaddingContainer>
      </ContentContainer>
      <SuccessModal
        title={'Turn off automatic export?'}
        icon={Icon.ExclamationTriangle}
        subtitle={
          'By turning off automatic export to Quickbooks, invoices will need to be manually queued for export.'
        }
        isOpen={turnOffAccountingExportWarningModal.isOpen}
        handlePrimaryAction={() => {
          form.setFieldValue(isEnabledAutomaticallyExportInvoiceToCodatField, false);
          setTimeout(handleSubmit, 0);
          turnOffAccountingExportWarningModal.handleClose();
        }}
        primaryActionText={'Turn Off'}
        handleSecondaryAction={turnOffAccountingExportWarningModal.handleClose}
        secondaryActionText={'Cancel'}
      />
    </React.Fragment>
  );
};

const ProjectTypeInvoiceTemplateSettingsFields = ({
  form,
  documentTemplatesByCategory,
  handleSubmit,
}) => {
  return (
    <Container>
      <Space height={16} />
      <FieldInput
        {...form}
        index={0}
        label={'Default Invoice Template'}
        name={`projectTypeBillingSettingsForm.defaultInvoiceDocumentTemplateId`}
        component={DropdownInput}
        input={{
          menuPlacement: 'top',
          isPortaled: true,
          required: !_.get(
            form.values,
            `projectTypeBillingSettingsForm.defaultInvoiceDocumentTemplateId`,
          ),
          options: documentTemplatesByCategory.map((documentTemplate) => {
            return {
              value: _.parseInt(documentTemplate.id),
              label: documentTemplate.name,
            };
          }),
          placeholder: 'Select a invoice document template',
          setFieldValue: form.setFieldValue,
          onChangeValue: () => {
            setTimeout(handleSubmit, 0);
          },
          style: {width: '360px'},
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        label={'Invoice Terms'}
        name={`projectTypeBillingSettingsForm.defaultInvoicePaymentTerm`}
        component={DropdownInput}
        input={{
          menuPlacement: 'top',
          isPortaled: true,
          required: !_.get(form.values, `projectTypeBillingSettingsForm.defaultInvoicePaymentTerm`),
          options: InvoicePaymentTerm.DROPDOWN_OPTIONS,
          placeholder: 'Select a payment term',
          setFieldValue: form.setFieldValue,
          onChangeValue: () => {
            setTimeout(handleSubmit, 0);
          },
          style: {width: '360px'},
        }}
      />
      <Space height={16} />
    </Container>
  );
};

const ProjectTypeInvoiceEmailTemplateSettingsFields = ({
  form,
  invoiceEmailTemplate,
  invoiceReceiptEmailTemplate,
  handleSubmit,
}) => {
  return (
    <Container>
      <FieldInput
        {...form}
        index={2}
        label={'Default Invoice Email Template'}
        name={`projectTypeBillingSettingsForm.defaultInvoiceEmailTemplateId`}
        component={DropdownInput}
        input={{
          menuPlacement: 'top',
          isPortaled: true,
          required: !_.get(
            form.values,
            `projectTypeBillingSettingsForm.defaultInvoiceEmailTemplateId`,
          ),
          options: _.isEmpty(invoiceEmailTemplate)
            ? []
            : invoiceEmailTemplate.map((emailTemplate) => {
                return {
                  value: _.parseInt(emailTemplate.id),
                  label: emailTemplate.name,
                };
              }),
          placeholder: 'Select a invoice email template',
          setFieldValue: form.setFieldValue,
          onChangeValue: () => {
            setTimeout(handleSubmit, 0);
          },
          style: {width: '360px'},
        }}
      />
      <Space height={16} />
      <Label>Default Invoice Email Receipt Template</Label>
      <GrayText>
        Selecting a default receipt template will automatically send the receipt email whenever a
        payment is made on an invoice. If you prefer not to send receipt emails, leave this blank.
      </GrayText>
      <Space height={4} />
      <FieldInput
        {...form}
        index={3}
        name={`projectTypeBillingSettingsForm.defaultInvoiceReceiptEmailTemplateId`}
        component={DropdownInput}
        input={{
          menuPlacement: 'top',
          isPortaled: true,
          isClearable: true,
          required: !_.get(
            form.values,
            `projectTypeBillingSettingsForm.defaultInvoiceReceiptEmailTemplateId`,
          ),
          options: _.isEmpty(invoiceReceiptEmailTemplate)
            ? []
            : invoiceReceiptEmailTemplate.map((receiptEmailTemplate) => {
                return {
                  value: _.parseInt(receiptEmailTemplate.id),
                  label: receiptEmailTemplate.name,
                };
              }),
          placeholder: 'Select a invoice receipt email template',
          setFieldValue: form.setFieldValue,
          onChangeValue: () => {
            setTimeout(handleSubmit, 0);
          },
          style: {width: '360px'},
        }}
      />
      <Space height={16} />
    </Container>
  );
};

const TextBodyContent = ({primaryText, secondaryText}) => {
  return (
    <Container>
      <Text>{primaryText}</Text>
      <Space height={4} />
      <GrayText>{secondaryText}</GrayText>
    </Container>
  );
};

const ProjectTypeBillingSettingsContent = ({projectTypeUuid}) => {
  const {loading, data, refetch} = useQuery(ProjectTypeBillingSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectTypeUuid,
      categories: [DocumentTemplateCategory.INVOICE],
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <PageContainer>
      <ProjectTypeSettingsPageHeader projectType={data.projectTypeByUuid} />
      <PageContentContainer>
        <Space height={24} />
        <HeaderText>Configure Billing Settings</HeaderText>
        <Space height={8} />
        <Text>
          {
            'Configure billing settings for this project type. Supermove will automatically apply these settings to your projects when they are created.'
          }
        </Text>
        <Space height={24} />
        <ScrollView>
          {data.projectTypeByUuid.category === ProjectTypeCategory.MOVE && (
            <React.Fragment>
              <ProjectTypeBillingPaymentMethodSection
                projectType={data.projectTypeByUuid}
                refetch={refetch}
              />
              <Space height={16} />
            </React.Fragment>
          )}
          <ProjectTypeBillingAccountingSection
            projectType={data.projectTypeByUuid}
            refetch={refetch}
          />
          <Space height={48} />
        </ScrollView>
        <Space height={48} />
      </PageContentContainer>
    </PageContainer>
  );
};

const ProjectTypeBillingSettingsPage = () => {
  const {params} = useNavigationDOM();

  return (
    <SidebarPageV2 selected={'settings'}>
      <ProjectTypeBillingSettingsContent projectTypeUuid={params.projectTypeUuid} />
    </SidebarPageV2>
  );
};

ProjectTypeBillingSettingsPage.query = gql`
  ${ProjectTypeSettingsPageHeader.fragment}
  ${ProjectTypeBillingSettingsForm.edit.fragment}
  ${EditProjectTypeBillingSettingsModal.fragment}
  query ProjectTypeBillingSettingsPage(
    $projectTypeUuid: String!,
    $categories: [String]!,
  ) {
    ${gql.query}
    projectTypeByUuid(projectTypeUuid: $projectTypeUuid) {
      id
      category
      crewPaymentMethods
      isEnabledAutomaticallyExportInvoiceToCodat
      isEnabledAutomaticallyFinalizeInvoices
      organization {
        id
        activeCodatIntegration {
          id
        }
        documentTemplatesByCategory(categories: $categories) {
          id
          name
        }
        invoiceEmailTemplate: emailTemplatesByKind(emailTemplateKinds: ["CUSTOM_INVOICE"]) {
          id
          name
        }
        invoiceReceiptEmailTemplate: emailTemplatesByKind(emailTemplateKinds: ["CUSTOM_INVOICE_RECEIPT"]) {
          id
          name
        }
      }
      ...ProjectTypeBillingSettingsForm_edit
      ...ProjectTypeSettingsPageHeader
      ...EditProjectTypeBillingSettingsModal
    }
  }
`;

export default ProjectTypeBillingSettingsPage;
