const _new = ({userId}) => ({
  userId,
  viewingOrganizationId: null,
});

const toForm = ({userId, viewingOrganizationId}) => ({
  userId,
  viewingOrganizationId,
});

const toMutation = ({userId, viewingOrganizationId}) => ({
  userId,
  viewingOrganizationId,
});

const UpdateMultibranchAdminViewingOrganizationForm = {
  new: _new,
  toForm,
  toMutation,
};

export default UpdateMultibranchAdminViewingOrganizationForm;
