// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import CreateTimesheetBlockForm from '@shared/modules/Timesheet/forms/CreateTimesheetBlockForm';

const newForJob = withFragment(
  ({job}) => {
    const rangeFromDate = _.get(job, 'timesheetBillableEntries.0.timesheetBlocksDateRange.start');
    return {
      timesheetPayrollEntryIds: [],
      createTimesheetBlockForms: [CreateTimesheetBlockForm.new({rangeFromDate})],
    };
  },
  gql`
    fragment CreateTimesheetPayrollBlocksForm_newForJob on Job {
      id
      timesheetBillableEntries {
        id
        timesheetBlocksDateRange {
          start
        }
      }
    }
  `,
);

const toForm = ({timesheetPayrollEntryIds, createTimesheetBlockForms}) => ({
  timesheetPayrollEntryIds,
  createTimesheetBlockForms: createTimesheetBlockForms.map(CreateTimesheetBlockForm.toForm),
});

const toMutation = ({timesheetPayrollEntryIds, createTimesheetBlockForms}) => ({
  timesheetPayrollEntryIds,
  createTimesheetBlockForms: createTimesheetBlockForms.map(CreateTimesheetBlockForm.toMutation),
});

const CreateTimesheetPayrollBlocksForm = {
  newForJob,
  toForm,
  toMutation,
};

export default CreateTimesheetPayrollBlocksForm;
