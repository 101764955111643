// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Currency, withFragment} from '@supermove/utils';

// App
import CostCategoryKind from '@shared/modules/Billing/enums/CostCategoryKind';
import CostSectionForm from '@shared/modules/Billing/forms/CostSectionForm';

const computeTotal = (costForm) => {
  const costSectionTotals = costForm.costSectionForms.map((costSectionForm) =>
    Currency.convertToCents(costSectionForm.totalCost),
  );
  return _.sum(costSectionTotals);
};

const _new = () => ({
  costId: null,
  name: '',
  category: CostCategoryKind.COMPENSATION,
  projectId: '',
  jobId: '',
  compensationUserId: '',
  isCustom: true,
  costSectionForms: [],
});

const edit = withFragment(
  (cost, {weight, totalDistance, projectRevenue}) => {
    return {
      costId: cost.id,
      name: cost.name,
      category: cost.category,
      projectId: cost.projectId,
      jobId: cost.jobId,
      compensationUserId: cost.compensationUserId,
      isCustom: cost.isCustom,
      costSectionForms: cost.costSections.map((costSection) =>
        CostSectionForm.edit(costSection, {weight, totalDistance, projectRevenue}),
      ),

      // Private
      total: cost.total,
    };
  },
  gql`
    ${CostSectionForm.edit.fragment}

    fragment CostForm_edit on Cost {
      id
      name
      compensationUserId
      category
      projectId
      jobId
      isCustom
      total
      costSections {
        ...CostSectionForm_edit
      }
    }
  `,
);

const toForm = ({
  costId,
  name,
  compensationUserId,
  category,
  projectId,
  jobId,
  isCustom,
  costSectionForms,

  // Private
  total,
}) => {
  return {
    costId,
    name,
    compensationUserId,
    category,
    projectId,
    jobId,
    isCustom,
    costSectionForms: costSectionForms.map((costSectionForm) =>
      CostSectionForm.toForm(costSectionForm),
    ),

    // Private
    total,
  };
};

const toMutation = ({
  costId,
  projectId,
  jobId,
  name,
  compensationUserId,
  category,
  isCustom,
  costSectionForms,
}) => {
  return {
    costId,
    projectId: jobId === '-1' ? projectId : null,
    jobId: jobId === '-1' || !jobId ? null : jobId, // Job ID is only updated on create cost, and -1 indicates a project level cost
    name,
    compensationUserId: compensationUserId || null,
    category,
    isCustom,
    costSectionForms: costSectionForms.map((costSectionForm) =>
      CostSectionForm.toMutation(costSectionForm),
    ),
  };
};

const CostForm = {
  new: _new,
  edit,
  toForm,
  toMutation,

  computeTotal,
};

export default CostForm;
