// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Popover} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import DropdownSheet from '@shared/design/components/DropdownInput/components/DropdownSheet';

export interface ActionMenuPopoverAction {
  text: string;
  color?: string;
  onPress: () => void;
}

const ActionMenuPopover = ({
  popover,
  placement,
  width,
  popoverMaxHeight,
  actions,
  sheetLabel,
  isSheetSearchable,
  NoResultsComponent,
  noResultsComponentProps,
  children,
}: any) => {
  const responsive = useResponsive();
  const isDropdownSheet = !responsive.desktop;

  return (
    <React.Fragment>
      <Popover.Content innerRef={popover.ref}>{children}</Popover.Content>
      <Popover
        key={popover.key}
        placement={placement}
        isOpen={!isDropdownSheet && popover.isOpen}
        handleOpen={popover.handleOpen}
        handleClose={popover.handleClose}
        reference={popover.ref}
        offset={[0, 4]}
      >
        <ActionMenu
          handleClose={popover.handleClose}
          width={width}
          maxHeight={popoverMaxHeight}
          actions={actions}
          // @ts-expect-error TS(2322): Type '{ handleClose: any; width: any; maxHeight: a... Remove this comment to see the full error message
          isResponsive
        />
      </Popover>
      {isDropdownSheet && (
        <DropdownSheet
          isOpen={isDropdownSheet && popover.isOpen}
          handleClose={popover.handleClose}
          headerText={sheetLabel}
          isSearchable={isSheetSearchable}
          options={DropdownSheet.formatActionsForDropdownSheet({
            actions,
            handleClose: popover.handleClose,
          })}
          NoResultsComponent={NoResultsComponent}
          noResultsComponentProps={noResultsComponentProps}
        />
      )}
    </React.Fragment>
  );
};

ActionMenuPopover.Position = Popover.Positions;

// --------------------------------------------------
// Props
// --------------------------------------------------
ActionMenuPopover.propTypes = {
  popover: PropTypes.object.isRequired,
  placement: PropTypes.string,
  width: PropTypes.number,
  popoverMaxHeight: PropTypes.number,
  actions: PropTypes.array,
  sheetLabel: PropTypes.string,
  isSheetSearchable: PropTypes.bool,
  NoResultsComponent: PropTypes.func,
  noResultsComponentProps: PropTypes.object,
  children: PropTypes.node.isRequired,
};

ActionMenuPopover.defaultProps = {
  placement: Popover.Positions.BottomStart,
  width: null,
  popoverMaxHeight: null,
  actions: [],
  sheetLabel: 'Actions',
  isSheetSearchable: false,
  NoResultsComponent: null,
  noResultsComponentProps: {},
};

export default ActionMenuPopover;
