// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import BillTypeForm from '@shared/modules/Billing/forms/BillTypeForm';

const useCreateBillTypeMutation = ({billTypeForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      billTypeForm: BillTypeForm.toForm(billTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateBillTypeMutation.mutation,
    variables: {
      billTypeForm: BillTypeForm.toMutation(form.values.billTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateBillTypeMutation.mutation = gql`
  mutation useCreateBillTypeMutation($billTypeForm: BillTypeForm!) {
    response: createBillType(billTypeForm: $billTypeForm) {
      ${gql.errors}
      billType {
        id
      }
    }
  }
`;

export default useCreateBillTypeMutation;
