const INVOICE = 'INVOICE';
const JOB = 'JOB';

const INVOICE_OPTION = {
  value: INVOICE,
  label: 'Invoice',
};
const JOB_OPTION = {
  value: JOB,
  label: 'Job',
};

const DocumentTemplateCategory = {
  INVOICE,
  JOB,

  DOCUMENT_TEMPLATE_CATEGORY_OPTIONS: [JOB_OPTION, INVOICE_OPTION],
};

export default DocumentTemplateCategory;
