// Libraries
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import TimesheetBlockKind from '@shared/modules/Timesheet/enums/TimesheetBlockKind';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const OptionText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const ColorDot = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({color}) => color};
`;

const getOptions = ({organization}) => {
  return Organization.getTimesheetBlockKinds(organization).map((kind) => {
    return {
      value: kind,
      label: TimesheetBlockKind.KIND_TO_DISPLAY[kind],
      color: TimesheetBlockKind.KIND_TO_COLOR[kind],
    };
  });
};

const ColorOption = ({responsive, isSelected, label, color}) => {
  return (
    <Row>
      <ColorDot color={color} />
      <Space width={8} />
      <OptionText responsive={responsive} style={{color: isSelected ? colors.white : colors.black}}>
        {label}
      </OptionText>
    </Row>
  );
};

const TimesheetBlockKindDropdown = ({form, field, value, label, index, isDisabled, style}) => {
  const responsive = useResponsive();
  const {data} = useQuery(TimesheetBlockKindDropdown.query, {fetchPolicy: 'cache-and-network'});

  return (
    <FieldInput
      {...form}
      label={label}
      name={field}
      component={DropdownInput}
      index={index}
      isResponsive
      style={style}
      value={value}
      input={{
        options: data ? getOptions({organization: data.viewer.viewingOrganization}) : [],
        placeholder: 'Event type',
        setFieldValue: form.setFieldValue,
        isResponsiveSheet: false,
        isDisabled,
        isLoading: !data,
        style,
        components: {
          SingleValue: (props) => {
            return (
              <DropdownInput.SingleValueContainer {...props}>
                <ColorOption
                  responsive={responsive}
                  label={props.data.label}
                  color={props.data.color}
                />
              </DropdownInput.SingleValueContainer>
            );
          },
          Option: (props) => {
            return (
              <DropdownInput.OptionContainer {...props}>
                <ColorOption
                  responsive={responsive}
                  isSelected={props.isSelected}
                  label={props.data.label}
                  color={props.data.color}
                />
              </DropdownInput.OptionContainer>
            );
          },
        },
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TimesheetBlockKindDropdown.query = gql`
  ${Organization.getTimesheetBlockKinds.fragment}

  query EditTimesheetPayrollEntryTimesheetBlocks {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...Organization_getTimesheetBlockKinds
      }
    }
  }
`;

export default TimesheetBlockKindDropdown;
