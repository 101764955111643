// App
import LocationTypeForm from '@shared/modules/Organization/forms/LocationTypeForm';

const OrganizationLocationTypesForm = {
  toForm: ({organizationId, locationTypeForms}) => ({
    organizationId,
    locationTypeForms: locationTypeForms.map((locationTypeForm) =>
      LocationTypeForm.toForm(locationTypeForm),
    ),
  }),

  toMutation: ({organizationId, locationTypeForms}) => ({
    organizationId,
    locationTypeForms: locationTypeForms.map((locationTypeForm) =>
      LocationTypeForm.toMutation(locationTypeForm),
    ),
  }),
};

export default OrganizationLocationTypesForm;
