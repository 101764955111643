// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled, Tooltip} from '@supermove/components';
import {useHover, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

const PopoverContainer = Styled.View`
  width: ${(props: any) => (props.width ? `${props.width}px` : null)};
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: ${colors.white};
  overflow: visible;
`;

const MenuLabelContainer = Styled.View`
  padding: 12px;
`;

const MenuLabelText = Styled.Text`
  ${Typography.Responsive.Label};
`;

const MenuItemTouchable = Styled.ButtonV2`
  background-color: ${({isHovered, disabled}: any) =>
    isHovered && !disabled ? colors.hover : colors.white};
  padding: 12px;
  width: 100%;
  flex-direction: row;
`;

const MenuItemText = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${({disabled, color}: any) =>
    disabled ? colors.gray.tertiary : color || colors.gray.primary};
`;

const ScrollViewContainer = Styled.View`
  max-height: ${({maxHeight}: any) => `${maxHeight}px` || '100%'};
`;

const View = Styled.View`
`;

const TooltipText = Styled.Text`
  ${Typography.Body};
  color: ${colors.white};
`;

const ActionMenuDivider = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  flex: 1;
`;

const ActivityIndicator = Styled.Loading`
`;

const PreventPropagationContainer = ({children, style}: any) => {
  return (
    <View
      onStartShouldSetResponder={(event: any) => true}
      onTouchEnd={(e: any) => {
        e.stopPropagation();
      }}
      style={style}
    >
      {children}
    </View>
  );
};

const ActionMenuItemWrapper = ({tooltip, children}: any) => {
  if (tooltip) {
    return (
      <Tooltip
        overlay={<TooltipText>{tooltip}</TooltipText>}
        placement={'left'}
        mouseEnterDelay={0.0}
        mouseLeaveDelay={0.0}
      >
        {children}
      </Tooltip>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const ActionMenuLabel = ({children, responsive}: any) => {
  return (
    <MenuLabelContainer>
      <MenuLabelText responsive={responsive}>{children}</MenuLabelText>
    </MenuLabelContainer>
  );
};

const ActionMenuSection = ({
  index,
  label,
  actions,
  handleClose,
  isTruncated,
  itemStyle,
  responsive,
}: any) => {
  return (
    <React.Fragment>
      {index > 0 && (
        <React.Fragment>
          <Space height={12} />
          <ActionMenuDivider />
          <Space height={12} />
        </React.Fragment>
      )}
      <ActionMenuLabel responsive={responsive}>{label}</ActionMenuLabel>
      {actions.map(
        (
          {
            onPress,
            text,
            isDisabled,
            isHidden,
            isLoadedAction,
            isLoading,
            tooltip,
            color,
            actionHook,
          }: any,
          index: any,
        ) => (
          // @ts-expect-error TS(2322): Type '{ children: any; key: any; onPress: any; isT... Remove this comment to see the full error message
          <ActionMenuItem
            key={index}
            onPress={onPress}
            isTruncated={isTruncated}
            isDisabled={isDisabled}
            isHidden={isHidden}
            isLoadedAction={isLoadedAction}
            isLoading={isLoading}
            tooltip={tooltip}
            color={color}
            actionHook={actionHook}
            handleClose={handleClose}
            responsive={responsive}
            style={{paddingLeft: 24, ...itemStyle}}
          >
            {text}
          </ActionMenuItem>
        ),
      )}
    </React.Fragment>
  );
};

const ActionMenuItem = ({
  index,
  onPress,
  isTruncated,
  isDisabled,
  isHidden,
  isLoadedAction,
  isLoading,
  tooltip,
  color,
  actionHook,
  handleClose,
  style,
  children,
  label,
  responsive,
  actions,
}: any) => {
  const {isHovered, handleToggleOff, ref} = useHover();
  const hook = actionHook.hook(actionHook.hookArgument);

  if (isHidden) {
    return null;
  }

  if (label && actions) {
    return (
      <ActionMenuSection
        index={index}
        label={label}
        actions={actions}
        handleClose={handleClose}
        isTruncated={isTruncated}
        itemStyle={style}
        responsive={responsive}
      />
    );
  }

  return (
    <ActionMenuItemWrapper tooltip={tooltip}>
      <MenuItemTouchable
        ref={ref}
        isHovered={isHovered}
        disabled={isDisabled || isLoading}
        activeOpacity={0.8}
        onPress={() => {
          onPress();
          hook.handleOpen && hook.handleOpen();
          handleToggleOff();
          !isLoadedAction && handleClose();
        }}
        style={style}
      >
        {isLoading && (
          <React.Fragment>
            <ActivityIndicator size={'small'} color={colors.blue.interactive} />
            <Space width={8} />
          </React.Fragment>
        )}
        <MenuItemText
          numberOfLines={isTruncated ? 1 : 0}
          disabled={isDisabled || isLoading}
          color={color}
          responsive={responsive}
        >
          {children}
        </MenuItemText>
        <PreventPropagationContainer>
          <actionHook.Component {...hook} {...actionHook.componentProps} />
        </PreventPropagationContainer>
      </MenuItemTouchable>
    </ActionMenuItemWrapper>
  );
};

const ActionMenuContainer = ({width, maxHeight, children}: any) => {
  return (
    <PopoverContainer width={width}>
      <ScrollView>
        <ScrollViewContainer maxHeight={maxHeight}>{children}</ScrollViewContainer>
      </ScrollView>
    </PopoverContainer>
  );
};

const ActionMenu = ({
  actions,
  handleClose,
  width,
  maxHeight,
  isTruncated,
  itemStyle,
  isResponsive,
}: any) => {
  const responsive = useResponsive();

  return (
    <ActionMenuContainer width={width} maxHeight={maxHeight}>
      <Space height={12} />
      {actions.map(
        (
          {
            onPress,
            text,
            isDisabled,
            isHidden,
            isLoadedAction,
            isLoading,
            tooltip,
            color,
            actionHook,
            label,
            actions: sectionActions,
          }: any,
          index: any,
        ) => (
          // @ts-expect-error TS(2322): Type '{ children: any; key: any; index: any; onPre... Remove this comment to see the full error message
          <ActionMenuItem
            key={index}
            index={index}
            onPress={onPress}
            isTruncated={isTruncated}
            isDisabled={isDisabled}
            isHidden={isHidden}
            isLoadedAction={isLoadedAction}
            isLoading={isLoading}
            tooltip={tooltip}
            color={color}
            actionHook={actionHook}
            handleClose={handleClose}
            style={itemStyle}
            label={label}
            actions={sectionActions}
            responsive={isResponsive ? responsive : null}
          >
            {text}
          </ActionMenuItem>
        ),
      )}
      <Space height={12} />
    </ActionMenuContainer>
  );
};

ActionMenu.Container = ActionMenuContainer;
ActionMenu.Item = ActionMenuItem;
ActionMenu.Label = ActionMenuLabel;
ActionMenu.Divider = ActionMenuDivider;

// --------------------------------------------------
// Props
// --------------------------------------------------
ActionMenuItem.propTypes = {
  onPress: PropTypes.func,
  isTruncated: PropTypes.bool,
  isDisabled: PropTypes.bool,
  tooltip: PropTypes.string,
  color: PropTypes.string,
  actionHook: PropTypes.shape({
    hook: PropTypes.func,
    hookArgument: PropTypes.object,
    Component: PropTypes.func,
    componentProps: PropTypes.object,
  }),
  handleClose: PropTypes.func,
  label: PropTypes.string,
  actions: PropTypes.array,
  style: PropTypes.object,
};

ActionMenuItem.defaultProps = {
  onPress: () => null,
  isTruncated: false,
  isDisabled: false,
  tooltip: null,
  color: null,
  actionHook: {
    hook: () => ({}),
    hookArgument: {},
    Component: () => null,
    componentProps: {},
  },
  handleClose: () => {},
  label: null,
  actions: null,
  style: {},
};

ActionMenu.propTypes = {
  handleClose: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
  width: PropTypes.number,
  maxHeight: PropTypes.number,
  itemStyle: PropTypes.object,
};

ActionMenu.defaultProps = {
  width: 120,
  maxHeight: null,
  itemStyle: {},
};

export default ActionMenu;
