// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import CartonCategoriesList from '@shared/modules/Inventory/Summary/components/CartonCategoriesList';
import InventorySection from '@shared/modules/Inventory/Summary/components/InventorySection';

const DocumentV2CartonSummary = ({
  isPreview,
  job,
  isDisabled,
  showNotes,
  showWeight,
  showVolume,
}) => {
  return (
    <InventorySection icon={Icon.BoxOpen} title={'Carton summary'}>
      <CartonCategoriesList
        isDisabled={isDisabled}
        inventory={isPreview ? MOCK_INVENTORY_DATA : job.project.inventory}
        showNotes={showNotes}
        showWeight={showWeight}
        showVolume={showVolume}
      />
    </InventorySection>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
DocumentV2CartonSummary.propTypes = {
  isPreview: PropTypes.bool,
  isDisabled: PropTypes.bool,
  showNotes: PropTypes.bool,
  showWeight: PropTypes.bool,
  showVolume: PropTypes.bool,
};
DocumentV2CartonSummary.defaultProps = {
  isPreview: false,
  isDisabled: false,
  showNotes: false,
  showWeight: true,
  showVolume: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentV2CartonSummary.fragment = gql`
  ${CartonCategoriesList.fragment}

  fragment DocumentV2CartonSummary on Job {
    id
    project {
      id
      inventory {
        id
        ...CartonCategoriesList
      }
    }
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_INVENTORY_DATA = {
  id: '1',
  cartonCategories: [
    {
      id: '1',
      category: {
        id: '1',
        name: 'Cartons, Packing, Unpacking',
      },
      collection: {
        id: '1',
        items: [
          {
            id: '1',
            itemTypeId: 1,
            leaveCount: 1,
            takeCount: 1,
            volume: 1,
            name: 'Carton, 1.5',
          },
        ],
        leaveCount: 1,
        takeCount: 1,
        totalVolume: 1,
        totalWeight: 1,
      },
    },
  ],
};

export default DocumentV2CartonSummary;
