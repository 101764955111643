// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import ProjectTypeVariableForm from '@shared/modules/Project/forms/ProjectTypeVariableForm';

const _new = (projectTypeId) => ({
  projectTypeVariableSectionId: null,
  projectTypeId,
  name: '',
  isDefault: false,
  projectTypeVariableForms: [],
});

const edit = withFragment(
  (projectTypeVariableSection) => ({
    projectTypeVariableSectionId: projectTypeVariableSection.id,
    projectTypeId: projectTypeVariableSection.projectTypeId,
    name: projectTypeVariableSection.name,
    isDefault: projectTypeVariableSection.isDefault,
    projectTypeVariableForms: projectTypeVariableSection.orderedVariables
      .filter((variable) => {
        // filter out dynamic variables not editable on FE, like PER_POSITION...
        return !_.has(VariableFormat.DYNAMIC_VARIABLE_FORMATS, variable.format);
      })
      .map((variable) => {
        const projectTypeVariable = _.find(
          projectTypeVariableSection.projectType.projectTypeVariables,
          {
            variableId: _.toNumber(variable.id),
          },
        );
        const variableOptions = JSON.parse(variable.variableOptionsV3);
        if (projectTypeVariable) {
          return ProjectTypeVariableForm.edit(
            {
              ...projectTypeVariable,
            },
            {
              name: variable.name,
              format: variable.format,
              defaultAmountOptions: variableOptions,
              isDerived: variable.isDerived,
              description: variable.description,
            },
          );
        } else {
          return ProjectTypeVariableForm.edit(
            {
              defaultAmount: 0,
              isRequiredForCreateProject: false,
              isVisibleForCreateProject: !!variable.isVisibleForCreateProject,
              projectTypeId: projectTypeVariableSection.projectTypeId,
              variableId: _.toNumber(variable.id),
              isEnabled: true,
            },
            {
              name: variable.name,
              format: variable.format,
              defaultAmountOptions: variableOptions,
              isDerived: variable.isDerived,
              description: variable.description,
            },
          );
        }
      }),

    // Private
    isCollapsed: false,
  }),
  gql`
    ${ProjectTypeVariableForm.edit.fragment}
    fragment ProjectTypeVariableSectionForm_edit on ProjectTypeVariableSection {
      id
      projectTypeId
      name
      isDefault
      orderedVariables {
        id
        name
        isVisibleForCreateProject
        format
        variableOptionsV3
        isDerived
        description
      }
      projectType {
        id
        projectTypeVariables {
          id
          ...ProjectTypeVariableForm_edit
        }
      }
    }
  `,
);

const toForm = ({
  projectTypeVariableSectionId,
  projectTypeId,
  name,
  isDefault,
  projectTypeVariableForms,

  // Private
  isCollapsed,
}) => ({
  projectTypeVariableSectionId,
  projectTypeId,
  name,
  isDefault,
  projectTypeVariableForms: projectTypeVariableForms.map((projectTypeVariableForm) =>
    ProjectTypeVariableForm.toForm(projectTypeVariableForm),
  ),

  // Private
  isCollapsed,
});

const toMutation = ({
  projectTypeVariableSectionId,
  projectTypeId,
  name,
  isDefault,
  projectTypeVariableForms,
}) => ({
  projectTypeVariableSectionId,
  projectTypeId,
  name,
  isDefault,
  // We only pass the dirty/modified forms to the backend
  // Only these Project Type Variables will be upsertted
  projectTypeVariableForms: projectTypeVariableForms
    .filter((projectTypeVariableForm) => _.get(projectTypeVariableForm, 'isDirty'))
    .map((projectTypeVariableForm) => ProjectTypeVariableForm.toMutation(projectTypeVariableForm)),
  // We pass all orderedVariableIds based on the unfiltered list of projectTypeVariableForms
  orderedVariableIds: getVariableIdsFromProjectTypeVariableForms({projectTypeVariableForms}),
});

const removeVariableFormsById = ({projectTypeVariableSectionForm, variableIdsToRemove}) => {
  const projectTypeVariableForms = _.get(
    projectTypeVariableSectionForm,
    'projectTypeVariableForms',
    [],
  );
  return _.remove(projectTypeVariableForms, (projectTypeVariableForm) =>
    _.includes(variableIdsToRemove, _.get(projectTypeVariableForm, 'variableId')),
  );
};

const getVariableIdsFromProjectTypeVariableForms = ({projectTypeVariableForms}) => {
  return _.map(projectTypeVariableForms, (projectTypeVariableForm) =>
    _.get(projectTypeVariableForm, 'variableId'),
  );
};

const getVariableIdsInSection = ({projectTypeVariableSectionForm}) => {
  const projectTypeVariableForms = _.get(
    projectTypeVariableSectionForm,
    'projectTypeVariableForms',
    [],
  );
  return getVariableIdsFromProjectTypeVariableForms({projectTypeVariableForms});
};

const ProjectTypeVariableSectionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,

  removeVariableFormsById,
  getVariableIdsInSection,
};

export default ProjectTypeVariableSectionForm;
