// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

import TertiaryButton from '@shared/design/components/Button/TertiaryButton';

const CheckboxContainer = Styled.View`
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  width: ${(props: any) => props.size}px;
  height: ${(props: any) => props.size}px;
  background-color: ${({isChecked, isDisabled}: any) =>
    getBackgroundColor({isChecked, isDisabled})};
  border-color: ${({isChecked, isDisabled}: any) => getBorderColor({isChecked, isDisabled})};
  border-radius: 4px;
  border-width: 2px;
  margin-top: 1px;
`;

const LabelContainer = Styled.View`
  flex: 1;
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${({color}: any) => color};
`;

const HintText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.secondary};
`;

const getBackgroundColor = ({isChecked, isDisabled}: any) => {
  if (!isChecked) {
    return colors.white;
  }
  if (isDisabled) {
    return colors.gray.disabled;
  }
  return colors.blue.interactive;
};

const getBorderColor = ({isChecked, isDisabled}: any) => {
  if (isDisabled) {
    return colors.gray.disabled;
  }
  if (isChecked) {
    return colors.blue.interactive;
  }
  return colors.gray.tertiary;
};

const CheckboxMark = ({isChecked, isDisabled, isMobile, style}: any) => {
  return (
    <CheckboxContainer
      isChecked={isChecked}
      isDisabled={isDisabled}
      size={isMobile ? 18 : 16}
      style={style}
    >
      {isChecked && <Icon color={colors.white} size={10} source={Icon.Check} />}
    </CheckboxContainer>
  );
};

const Checkbox = ({
  isDisabled,
  isChecked,
  handleToggle,
  children,
  childrenLeft,
  childrenRight,
  label,
  hint,
  style,
  isMobile,
  isResponsive,
  checkboxStyle,
}: any) => {
  const responsive = useResponsive();

  // TODO(dan) Remove all usages of the isMobile prop
  const isMobileResponsive = isMobile || (isResponsive && !responsive.desktop);

  return (
    <TertiaryButton
      isDisabled={isDisabled}
      onPress={() => handleToggle(!isChecked)}
      style={{...style, alignItems: 'flex-start'}}
    >
      {childrenLeft && children}
      <CheckboxMark
        isChecked={isChecked}
        isDisabled={isDisabled}
        isMobile={isMobileResponsive}
        style={checkboxStyle}
      />
      {!!label && (
        <React.Fragment>
          <Space width={8} />
          <LabelContainer>
            <LabelText
              color={isDisabled ? colors.gray.secondary : colors.gray.primary}
              responsive={isMobileResponsive ? responsive : null}
            >
              {label}
            </LabelText>
            {!!hint && (
              <React.Fragment>
                <Space height={4} />
                <HintText responsive={isMobileResponsive ? responsive : null}>{hint}</HintText>
              </React.Fragment>
            )}
          </LabelContainer>
        </React.Fragment>
      )}
      {childrenRight && children}
    </TertiaryButton>
  );
};

Checkbox.CheckMark = CheckboxMark;

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
Checkbox.propTypes = {
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  handleToggle: PropTypes.func,
  style: PropTypes.object,
  isMobile: PropTypes.bool,
  isResponsive: PropTypes.bool,
};

Checkbox.defaultProps = {
  isDisabled: false,
  label: '',
  hint: '',
  handleToggle: () => {},
  style: {},
  isMobile: false,
};

export default Checkbox;
