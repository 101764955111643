/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {Typography} from '@supermove/styles';

const Text = Styled.Text`
  ${Typography.Body3}
  color: ${({color}) => color};
`;

const CustomerChatLink = ({url, color, children}) => {
  return (
    <a href={url} target={'_blank'}>
      <Text color={color}>{children}</Text>
    </a>
  );
};

export default CustomerChatLink;
