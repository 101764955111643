// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ReorderReportDashboardsForm from '@shared/modules/Reports/forms/ReorderReportDashboardsForm';

const useReorderReportDashboardsMutation = ({reorderReportDashboardsForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      reorderReportDashboardsForm: ReorderReportDashboardsForm.toForm(reorderReportDashboardsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useReorderReportDashboardsMutation.mutation,
    variables: {
      reorderReportDashboardsForm: ReorderReportDashboardsForm.toMutation(
        form.values.reorderReportDashboardsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useReorderReportDashboardsMutation.mutation = gql`
  mutation useReorderReportDashboardsMutation($reorderReportDashboardsForm: ReorderReportDashboardsForm!) {
    response: reorderReportDashboards(reorderReportDashboardsForm: $reorderReportDashboardsForm) {
      ${gql.errors}
      dashboards {
        id
      }
    }
  }
`;

export default useReorderReportDashboardsMutation;
