// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, TimeInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Datetime} from '@supermove/utils';

// App
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import FieldInput from '@shared/design/components/Field/FieldInput';
import LargeModal from '@shared/design/components/Modal/LargeModal';
import TimesheetBillableEntrySelector from '@shared/modules/Timesheet/components/TimesheetBillableEntrySelector';
import TimesheetBillableEntryKind from '@shared/modules/Timesheet/enums/TimesheetBillableEntryKind';
import TimesheetBlockForm from '@shared/modules/Timesheet/forms/TimesheetBlockForm';

import TimesheetBlockKindSelector from './TimesheetBlockKindSelector';

const Row = Styled.View`
  flex-direction: row;
`;

const SectionContainer = Styled.View`
  padding-horizontal: 24px;
`;

const GENERIC_ERROR_MESSAGE = `An unexpected error occurred.  Please contact support if the problem persists.`;

const getIsOverlapsPayrollBlock = ({form, field, timesheetBlockForm, job}) => {
  const hasRangeFrom = TimesheetBlockForm.getIsValidTime({time: timesheetBlockForm.rangeFrom});
  const hasRangeTo = TimesheetBlockForm.getIsValidTime({time: timesheetBlockForm.rangeTo});
  if (!hasRangeFrom || !hasRangeTo) {
    return false;
  }

  const rangeFrom = TimesheetBlockForm.getMutationRangeFrom(timesheetBlockForm);
  const rangeTo = TimesheetBlockForm.getMutationRangeTo(timesheetBlockForm);

  const singleBillableEntryId = _.get(form.values, `${field}.timesheetBillableEntryId`);
  const selectedBillableEntryIds = singleBillableEntryId
    ? [singleBillableEntryId]
    : _.get(form.values, `${field}.timesheetBillableEntryIds`);
  const timesheetPayrollEntries = _.flatten(
    selectedBillableEntryIds.map(
      (id) =>
        _.find(job.timesheetBillableEntries, (entry) => entry.id === id).timesheetPayrollEntries,
    ),
  );
  const timesheetBlocks = _.flatten(timesheetPayrollEntries.map((entry) => entry.timesheetBlocks));

  for (const timesheetBlock of timesheetBlocks) {
    const timesheetBlockRangeFrom = Datetime.fromDatetime(timesheetBlock.rangeFrom, true);
    const timesheetBlockRangeTo = Datetime.fromDatetime(timesheetBlock.rangeTo, true);
    if (rangeFrom < timesheetBlockRangeTo && rangeTo > timesheetBlockRangeFrom) {
      return true;
    }
  }

  return false;
};

const handleResetErrors = ({form}) => {
  if (_.some(form.errors)) {
    form.setErrors({});
  }
};

const TimeInputs = ({form, field}) => {
  return (
    <Row>
      <FieldInput
        {...form}
        component={TimeInput}
        name={`${field}.rangeFrom`}
        label={'Start Time'}
        isResponsive
        isRequired
        input={{
          disabled: _.get(form.values, `${field}.isFixedRangeFrom`),
          component: FieldInput.TextInput,
          placeholder: 'Enter start time',
          setFieldValue: form.setFieldValue,
          onChangeTime: () => handleResetErrors({form}),
        }}
        style={{flex: 1}}
      />
      <Space width={16} />
      <FieldInput
        {...form}
        component={TimeInput}
        name={`${field}.rangeTo`}
        label={'End Time'}
        isResponsive
        input={{
          component: FieldInput.TextInput,
          placeholder: 'Enter end time',
          setFieldValue: form.setFieldValue,
          initialValue: _.get(form.values, `${field}.rangeFrom`),
          onChangeTime: () => {
            if (_.get(form.values, `${field}.isFixedRangeTo`)) {
              form.setFieldValue(`${field}.isFixedRangeTo`, false);
            }
            handleResetErrors({form});
          },
        }}
        style={{flex: 1}}
      />
    </Row>
  );
};

const TimesheetBlockCallouts = ({form, field, timesheetBlockField, job, hasError}) => {
  const timesheetBlockError = _.get(form.errors, timesheetBlockField);
  const hasBlockErrorMessage = typeof timesheetBlockError === 'string';
  const internalError = _.get(form.errors, 'internal');
  const showGeneralErrorMessage = hasError || hasBlockErrorMessage || internalError;
  const timesheetBlockForm = _.get(form.values, timesheetBlockField);
  const isIntoNextDay =
    !!timesheetBlockForm &&
    TimesheetBlockForm.getEndTimeIsNextDay(timesheetBlockForm) &&
    !_.get(form.values, `${timesheetBlockField}.isFixedRangeTo`);
  const isOverlapsPayrollBlock =
    !!timesheetBlockForm && getIsOverlapsPayrollBlock({form, field, timesheetBlockForm, job});

  return (
    <SectionContainer>
      {isIntoNextDay && (
        <React.Fragment>
          <Space height={12} />
          <WarningCallout text={'The end time is on the next day.'} />
        </React.Fragment>
      )}
      {isOverlapsPayrollBlock && (
        <React.Fragment>
          <Space height={12} />
          <WarningCallout
            text={
              'These times conflict with some crew hours. The conflicting crew hours will be removed.'
            }
          />
        </React.Fragment>
      )}
      {showGeneralErrorMessage && (
        <React.Fragment>
          <Space height={12} />
          <ErrorCallout text={hasBlockErrorMessage ? timesheetBlockError : GENERIC_ERROR_MESSAGE} />
        </React.Fragment>
      )}
    </SectionContainer>
  );
};

const TimesheetBlockModal = ({
  job,
  form,
  field,
  timesheetBlockField,
  handleSubmit,
  submitting,
  isOpen,
  handleClose,
  isEdit,
  handleDelete,
  hasError,
}) => {
  const responsive = useResponsive();
  const isJobTimesheet = job.timesheetBillableEntryKind === TimesheetBillableEntryKind.JOB;

  return (
    <LargeModal
      isOpen={isOpen}
      title={isEdit ? 'Edit Time' : 'Add Time'}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
      handleDestructiveAction={handleDelete}
      primaryActionText={'Save'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
      screenContainerStyle={{paddingHorizontal: responsive.mobile ? 0 : 96}}
      bodyStyle={{padding: 0}}
      isScrollable
      isResponsive
    >
      <Space height={24} />
      <SectionContainer>
        <TimesheetBlockKindSelector form={form} field={timesheetBlockField} job={job} />
      </SectionContainer>
      <Space height={24} />
      {!isJobTimesheet && (
        <React.Fragment>
          <TimesheetBillableEntrySelector form={form} field={field} job={job} />
          <Space height={24} />
        </React.Fragment>
      )}
      <SectionContainer>
        <TimeInputs form={form} field={timesheetBlockField} />
      </SectionContainer>
      <TimesheetBlockCallouts
        form={form}
        field={field}
        timesheetBlockField={timesheetBlockField}
        job={job}
        hasError={hasError}
      />
      <Space height={24} />
    </LargeModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TimesheetBlockModal.fragment = gql`
  ${TimesheetBillableEntrySelector.fragment}
  ${TimesheetBlockKindSelector.fragment}

  fragment TimesheetBlockModal on Job {
    id
    timesheetBillableEntryKind
    timesheetBillableEntries {
      id
      timesheetPayrollEntries {
        id
        timesheetBlocks {
          id
          rangeFrom
          rangeTo
        }
      }
    }
    ...TimesheetBillableEntrySelector
    ...TimesheetBlockKindSelector
  }
`;

export default TimesheetBlockModal;
