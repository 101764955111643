// Libraries
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {BillRuleType} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import SidebarPage from 'modules/App/components/SidebarPage';
import BillRuleTypesTable from 'modules/Organization/Settings/BillingLibraries/components/BillRuleTypesTable';
import BillingLibraryPageHeader from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryPageHeader';
import BillingLibraryPageNavigationTabs from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryPageNavigationTabs';

const Container = Styled.View`
`;

const ContentContainer = Styled.View`
  flex: 1;
  padding-top: 12px;
  padding-horizontal: ${({mobile}) => (mobile ? 12 : 24)}px;
`;

const HeaderText = Styled.Text`
  ${Typography.Label1}
;`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const BillingLibraryBillRulesContent = ({billingLibrary, refetch, userId}) => {
  return (
    <React.Fragment>
      <Space height={24} />
      <HeaderText>Add Bill Rules</HeaderText>
      <Space height={8} />
      <Text>
        {'Add new bill rules here. Supermove will automatically calculate'}
        <Text style={{color: colors.orange.status}}> orange highlighted items </Text>
        {'based on saved formulas.'}
      </Text>
      <Space height={24} />
      <BillRuleTypesTable billingLibrary={billingLibrary} refetch={refetch} userId={userId} />
      <Space height={36} />
    </React.Fragment>
  );
};

const BillingLibraryBillRulesPage = () => {
  const {params} = useNavigationDOM();
  return (
    <SidebarPage
      selected={'settings'}
      query={BillingLibraryBillRulesPage.query}
      variables={{billingLibraryUuid: params.billingLibraryUuid}}
      fetchPolicy={'cache-and-network'}
    >
      {({data, refetch}) => {
        return (
          <Container style={{flex: 1}}>
            <BillingLibraryPageHeader billingLibrary={data.billingLibrary} />
            <ContentContainer>
              <BillingLibraryPageNavigationTabs billingLibrary={data.billingLibrary} />
              <ScrollView horizontal contentContainerStyle={{flexGrow: 1}}>
                <ScrollView>
                  <BillingLibraryBillRulesContent
                    billingLibrary={data.billingLibrary}
                    refetch={refetch}
                    userId={data.viewer.id}
                  />
                </ScrollView>
              </ScrollView>
            </ContentContainer>
          </Container>
        );
      }}
    </SidebarPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryBillRulesPage.query = gql`
  ${BillingLibraryPageHeader.fragment}
  ${BillingLibraryPageNavigationTabs.fragment}
  ${BillRuleType.getDisplayKind.fragment}
  ${BillRuleType.getDisplayValue.fragment}
  ${BillRuleType.getDisplayUpdatedAt.fragment}
  ${BillRuleType.hasValueFormula.fragment}
  ${BillRuleTypesTable.fragment}
  query BillingLibraryBillRulesPage($billingLibraryUuid: String!) {
    ${gql.query}
    viewer {
      id
    }
    billingLibrary(billingLibraryUuid: $billingLibraryUuid) {
      id
      name
      billRuleTypes {
        id
        name
        description
        updatedBy {
          id
          fullName
        }
        ...BillRuleType_getDisplayKind
        ...BillRuleType_getDisplayValue
        ...BillRuleType_getDisplayUpdatedAt
        ...BillRuleType_hasValueFormula
      }
      ...BillingLibraryPageHeader
      ...BillingLibraryNavigationTabs
      ...BillRuleTypesTable
    }
  }
`;

export default BillingLibraryBillRulesPage;
