// Libraries
import _ from 'lodash';

const getMatches = (string, regex) => {
  return string.match(regex) || [];
};

const sortTrucks = (trucks) => {
  return _.sortBy(trucks, (truck) => {
    const numerics = _.first(getMatches(truck.name, /\d+/g));
    if (numerics && numerics.length > 0) {
      return Number(numerics);
    } else {
      return truck.name;
    }
  });
};

const Truck = {
  getFullName: (truck) => {
    if (!truck.size) {
      return truck.name;
    } else {
      return `${truck.name} (${truck.size})`;
    }
  },
  sort: (trucks) => sortTrucks(trucks),
};

export default Truck;
