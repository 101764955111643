// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

// App
import RecurringTaskForm from '@shared/modules/Accounting/forms/RecurringTaskForm';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import BillForm from '@shared/modules/Billing/forms/BillForm';
import ClientForm from '@shared/modules/Client/forms/ClientForm';
import UpdateAttachmentForm from '@shared/modules/File/forms/UpdateAttachmentForm';

const newFromStorageProject = withFragment(
  (project) => ({
    status: InvoiceStatus.DRAFT,
    date: null,
    paymentTerm: project.projectType.defaultInvoicePaymentTerm,
    customerNotes: '',
    isRecurring: false,
    isAutopay: false,
    projectId: project.id,
    organizationId: project.organization.id,
    emailTemplateId: getEmailTemplateIdFromProjectType(project),
    emailReceiptTemplateId: getEmailReceiptTemplateIdFromProjectType(project),
    documentTemplateId: getDocumentTemplateIdFromProjectType(project),

    // Nested forms
    billingClientForm: getBillingClientForm(project),
    recurringTaskForm: null,
    billForms: [BillForm.new(project)],
    attachmentForms: [],

    // Private
    clientForm: ClientForm.edit(project.client),
    isBillingClientSameAsProjectClient: project.client.id === project.billingClient.id,
    billTotalsPreview: [],
    disableRecurringFields: false,
  }),
  gql`
    ${ClientForm.edit.fragment}
    ${BillForm.new.fragment}

    fragment InvoiceForm_newFromStorageProject on Project {
      id
      organization {
        id
      }
      projectType {
        id
        defaultInvoicePaymentTerm
        defaultInvoiceDocumentTemplate {
          id
          name
        }
        defaultInvoiceEmailTemplate {
          id
        }
        defaultInvoiceReceiptEmailTemplate {
          id
        }
      }
      client {
        id
        ...ClientForm_edit
      }
      billingClient {
        id
        ...ClientForm_edit
      }
      ...BillForm_new
    }
  `,
);

const getBillingClientForm = (project) => {
  return project.billingClient
    ? ClientForm.edit(project.billingClient)
    : ClientForm.edit(project.client);
};

const getEmailTemplateIdFromProjectType = (project) => {
  return !_.isEmpty(project.projectType.defaultInvoiceEmailTemplate)
    ? project.projectType.defaultInvoiceEmailTemplate.id
    : null;
};

const getEmailReceiptTemplateIdFromProjectType = (project) => {
  return !_.isEmpty(project.projectType.defaultInvoiceReceiptEmailTemplate)
    ? project.projectType.defaultInvoiceReceiptEmailTemplate.id
    : null;
};

const getDocumentTemplateIdFromProjectType = (project) => {
  return !_.isEmpty(project.projectType.defaultInvoiceDocumentTemplate)
    ? project.projectType.defaultInvoiceDocumentTemplate.id
    : null;
};

const edit = withFragment(
  (invoice) => ({
    invoiceId: invoice.id,
    status: invoice.status,
    date: invoice.isRecurring ? null : invoice.date,
    paymentTerm: invoice.paymentTerm,
    customerNotes: invoice.customerNotes,
    isRecurring: invoice.isRecurring,
    isAutopay: invoice.isAutopayEnabled,
    projectId: invoice.project.id,
    organizationId: invoice.organization.id,
    emailTemplateId: invoice.emailTemplate
      ? invoice.emailTemplate.id
      : getEmailTemplateIdFromProjectType(invoice.project),
    emailReceiptTemplateId: invoice.emailReceiptTemplate
      ? invoice.emailReceiptTemplate.id
      : getEmailReceiptTemplateIdFromProjectType(invoice.project),
    documentTemplateId: !_.isEmpty(invoice.documentTemplate)
      ? invoice.documentTemplate.id
      : getDocumentTemplateIdFromProjectType(invoice.project),

    // Nested forms
    billingClientForm: ClientForm.edit(invoice.project.billingClient),
    recurringTaskForm: invoice.isRecurring ? RecurringTaskForm.edit(invoice.recurringTask) : null,
    billForms: invoice.bills.map((bill) => {
      return BillForm.edit(bill);
    }),
    attachmentForms: invoice.emailAttachments.map((attachment) => {
      return UpdateAttachmentForm.edit(attachment);
    }),

    // Private
    clientForm: ClientForm.edit(invoice.project.client),
    isBillingClientSameAsProjectClient:
      invoice.project.client.id === invoice.project.billingClient.id,
    identifier: invoice.identifier,
    billTotalsPreview: [],
    disableRecurringFields: invoice.isRecurring && !invoice.isMostRecentInvoiceForRecurringTask,
  }),
  gql`
    ${ClientForm.edit.fragment}
    ${RecurringTaskForm.edit.fragment}
    ${BillForm.edit.fragment}
    ${UpdateAttachmentForm.edit.fragment}

    fragment InvoiceForm_edit on Invoice {
      id
      status
      date
      paymentTerm
      customerNotes
      isRecurring
      isAutopayEnabled
      isMostRecentInvoiceForRecurringTask
      documentTemplate {
        id
      }
      project {
        id
        organization {
          id
        }
        client {
          id
          ...ClientForm_edit
        }
        billingClient {
          id
          ...ClientForm_edit
        }
        projectType {
          id
          defaultInvoicePaymentTerm
          defaultInvoiceDocumentTemplate {
            id
            name
          }
          defaultInvoiceEmailTemplate {
            id
          }
          defaultInvoiceReceiptEmailTemplate {
            id
          }
        }
      }
      organization {
        id
      }
      emailTemplate {
        id
      }
      emailReceiptTemplate {
        id
      }
      recurringTask {
        id
        ...RecurringTaskForm_edit
      }
      bills {
        id
        ...BillForm_edit
      }
      emailAttachments {
        id
        ...UpdateAttachmentForm_edit
      }
    }
  `,
);

const toForm = ({
  invoiceId,
  status,
  date,
  paymentTerm,
  customerNotes,
  isRecurring,
  isAutopay,
  projectId,
  organizationId,
  emailTemplateId,
  emailReceiptTemplateId,
  documentTemplateId,
  recurringTaskForm,
  billingClientForm,
  billForms,
  attachmentForms,

  // Private
  clientForm,
  isBillingClientSameAsProjectClient,
  identifier,
  billTotalsPreview,
  disableRecurringFields,
}) => ({
  invoiceId,
  status,
  date: date ? Datetime.toFormDate(date) : null,
  paymentTerm,
  customerNotes,
  isRecurring,
  isAutopay,
  projectId,
  organizationId,
  emailTemplateId,
  emailReceiptTemplateId,
  documentTemplateId,
  recurringTaskForm: recurringTaskForm && RecurringTaskForm.toForm(recurringTaskForm),
  billingClientForm: billingClientForm && ClientForm.toForm(billingClientForm),
  billForms: billForms.map((billForm) => {
    return BillForm.toForm(billForm);
  }),
  attachmentForms: attachmentForms.map((attachmentForm) => {
    return UpdateAttachmentForm.toForm(attachmentForm);
  }),

  // Private
  clientForm: clientForm && ClientForm.toForm(clientForm),
  isBillingClientSameAsProjectClient,
  identifier,
  billTotalsPreview,
  disableRecurringFields,
});

const toMutation = ({
  invoiceId,
  status,
  date,
  paymentTerm,
  customerNotes,
  isRecurring,
  isAutopay,
  projectId,
  organizationId,
  emailTemplateId,
  emailReceiptTemplateId,
  documentTemplateId,
  recurringTaskForm,
  billingClientForm,
  billForms,
  attachmentForms,
}) => ({
  invoiceId,
  status,
  date: date ? Datetime.toMutationDate(date) : null,
  paymentTerm,
  customerNotes,
  isRecurring,
  isAutopay,
  projectId,
  organizationId,
  emailTemplateId: emailTemplateId ? _.toNumber(emailTemplateId) : null,
  emailReceiptTemplateId: emailReceiptTemplateId ? _.toNumber(emailReceiptTemplateId) : null,
  documentTemplateId: documentTemplateId ? _.toNumber(documentTemplateId) : null,
  recurringTaskForm: recurringTaskForm && RecurringTaskForm.toMutation(recurringTaskForm),
  billingClientForm: billingClientForm && ClientForm.toMutation(billingClientForm),
  billForms: billForms.map((billForm) => {
    return BillForm.toMutation(billForm);
  }),
  attachmentForms: attachmentForms.map((attachmentForm) => {
    return UpdateAttachmentForm.toMutation(attachmentForm);
  }),
});

const InvoiceForm = {
  newFromStorageProject,
  edit,
  toForm,
  toMutation,
};

export default InvoiceForm;
