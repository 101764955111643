const _new = ({destinationOrganizationSlug = ''}) => {
  return {
    sourceOrganizationSlug: '',
    destinationOrganizationSlug,
    documentTemplateIdentifiers: [],
  };
};

const toForm = ({
  sourceOrganizationSlug,
  destinationOrganizationSlug,
  documentTemplateIdentifiers,
}) => {
  return {
    sourceOrganizationSlug,
    destinationOrganizationSlug,
    documentTemplateIdentifiers,
  };
};

const toMutation = ({
  sourceOrganizationSlug,
  destinationOrganizationSlug,
  documentTemplateIdentifiers,
}) => {
  return {
    sourceOrganizationSlug,
    destinationOrganizationSlug,
    documentTemplateIdentifiers,
  };
};

const CopyDocumentTemplatesForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CopyDocumentTemplatesForm;
