// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({threadId, userId}) => {
  return {
    threadId,
    userId,
    body: '',
    subject: '',
  };
};

const edit = withFragment(
  (comment) => ({
    commentId: comment.id,
    threadId: comment.threadId,
    userId: comment.userId,
    subject: comment.subject,
    body: comment.body,
  }),
  gql`
    fragment CommentForm_edit on Comment {
      id
      threadId
      userId
      body
      subject
    }
  `,
);

const toForm = ({commentId, threadId, userId, body, subject}) => ({
  commentId,
  threadId,
  userId,
  body,
  subject,
});

const toMutation = ({commentId, threadId, userId, body, subject}) => ({
  commentId,
  threadId,
  userId,
  body,
  subject,
});

const CommentForm = {
  toForm,
  toMutation,
  new: _new,
  edit,
};

export default CommentForm;
