// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useQuery, useResponsive, useToast} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import WarningModal from '@shared/design/components/Modal/SmallModal/WarningModal';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import UpdateProjectAndJobTypesForProjectForm from '@shared/modules/Project/forms/UpdateProjectAndJobTypesForProjectForm';
import useUpdateProjectAndJobTypesForProjectMutation from '@shared/modules/Project/hooks/useUpdateProjectAndJobTypesForProjectMutation';
import Line from 'modules/App/components/Line';
import OfficeHeader from 'modules/App/components/OfficeHeader';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import EditJobTypesContent from 'modules/Project/V2/Edit/components/EditJobTypesContent';
import EditProjectTypeContent from 'modules/Project/V2/Edit/components/EditProjectTypeContent';

const PageContainer = Styled.View`
  flex: 1;
`;

const MobileSectionContainer = Styled.View`
  padding-horizontal: 16px;
  padding-vertical: 24px;
`;

const MobileHeaderSideColumn = Styled.View`
  flex: 1;
`;

const MobileHeading2Text = Styled.Text`
  ${Typography.Mobile.Heading2};
  font-size: 20px;
`;

const MobileSubheadingText = Styled.Text`
  ${Typography.Mobile.Subheading}
`;

const MobileSaveButtonContainer = Styled.View`
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
  padding-horizontal: 16px;
  padding-vertical: 16px;
`;

const CloseButton = () => {
  const {navigator} = useNavigationDOM();

  return (
    <TertiaryButton onPress={navigator.goBack}>
      <Icon source={Icon.XmarkLarge} size={22} color={colors.gray.primary} />
    </TertiaryButton>
  );
};

const SkeletonBody = () => {
  return (
    <React.Fragment>
      <SkeletonLoader isFullWidth height={300} />
    </React.Fragment>
  );
};

const LoadingComponent = ({navigator}) => {
  const responsive = useResponsive();

  if (!responsive.desktop) {
    return (
      <React.Fragment>
        <PageHeaderMobile onClose={navigator.goBack} />
        <PageContainer style={{paddingHorizontal: 16}}>
          <Space height={16} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Heading1Text} isFullWidth />
          <Space height={16} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Heading1Text} isFullWidth />
          <Space height={16} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Heading1Text} isFullWidth />
        </PageContainer>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <OfficeHeader>
        <OfficeHeader.Content style={{flex: 1, padding: 24}}>
          <CloseButton />
          <Space width={16} />
          <SkeletonLoader width={320} height={SkeletonLoader.HEIGHT.Heading1Text} />
          <Space style={{flex: 1}} />
          <Button text={'Save Changes'} iconLeft={Icon.Check} isDisabled />
        </OfficeHeader.Content>
      </OfficeHeader>
      <Line />
      <ContentScrollContainer>
        <ActionPanel BodyComponent={SkeletonBody} title={'Project Type'} width={800} />
        <Space height={24} />
        <ActionPanel BodyComponent={SkeletonBody} title={'Job Types'} width={800} />
      </ContentScrollContainer>
    </React.Fragment>
  );
};

const ContentScrollContainer = ({children}) => {
  const responsive = useResponsive();

  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: responsive.desktop ? colors.gray.background : colors.white,
      }}
      contentContainerStyle={{
        alignItems: responsive.desktop ? 'center' : 'stretch',
        paddingHorizontal: responsive.desktop ? 24 : 0,
        paddingVertical: responsive.desktop ? 24 : 0,
      }}
    >
      {children}
    </ScrollView>
  );
};

const PageHeader = ({project, handleSubmit, submitting, onClose}) => {
  return (
    <OfficeHeader>
      <OfficeHeader.Content style={{flex: 1, padding: 24}}>
        <TertiaryButton onPress={onClose}>
          <Icon source={Icon.XmarkLarge} size={22} color={colors.gray.primary} />
        </TertiaryButton>
        <Space width={16} />
        <OfficeHeader.Title>{`Edit Project Type & Job Types for ${Project.getName(
          project,
        )}`}</OfficeHeader.Title>
        <Space style={{flex: 1}} />
        <Button
          text={'Save Changes'}
          iconLeft={Icon.Check}
          isSubmitting={submitting}
          onPress={handleSubmit}
        />
      </OfficeHeader.Content>
    </OfficeHeader>
  );
};

const PageHeaderMobile = ({onClose}) => {
  return (
    <OfficeHeader>
      <OfficeHeader.Content
        style={{
          flex: 1,
          justifyContent: 'center',
          paddingHorizontal: 16,
          paddingVertical: 12,
        }}
      >
        <MobileHeaderSideColumn />
        <MobileHeading2Text>{`Edit Project Type & Job Types`}</MobileHeading2Text>
        <MobileHeaderSideColumn style={{alignItems: 'flex-end'}}>
          <TertiaryButton onPress={onClose} isHitSlop>
            <Icon source={Icon.Xmark} size={20} color={colors.gray.primary} />
          </TertiaryButton>
        </MobileHeaderSideColumn>
      </OfficeHeader.Content>
    </OfficeHeader>
  );
};

const MobileSaveButton = ({text, handleSubmit, submitting}) => {
  return (
    <MobileSaveButtonContainer>
      <Button
        onPress={handleSubmit}
        isSubmitting={submitting}
        text={text}
        isWidthOfContainer
        style={{flex: 1, height: 48}}
        isResponsive
      />
    </MobileSaveButtonContainer>
  );
};

const EditProjectAndJobTypesForProjectPageContentDesktop = ({project, form, field}) => {
  return (
    <React.Fragment>
      <ActionPanel
        index={0}
        BodyComponent={EditProjectTypeContent}
        bodyComponentProps={{project, form, field}}
        title={'Project Type'}
        width={800}
      />
      <Space height={24} />
      <ActionPanel
        index={1}
        BodyComponent={EditJobTypesContent}
        bodyComponentProps={{project, form, field}}
        title={'Job Types'}
        width={800}
      />
    </React.Fragment>
  );
};

const EditProjectAndJobTypesForProjectPageContentMobile = ({project, form, field}) => {
  return (
    <React.Fragment>
      <MobileSectionContainer>
        <MobileSubheadingText>Project Type</MobileSubheadingText>
        <Space height={16} />
        <EditProjectTypeContent project={project} form={form} field={field} />
      </MobileSectionContainer>
      <Line style={{height: 4}} />
      <MobileSectionContainer>
        <MobileSubheadingText>Job Types</MobileSubheadingText>
        <Space height={16} />
        <EditJobTypesContent project={project} form={form} field={field} />
      </MobileSectionContainer>
    </React.Fragment>
  );
};

const EditProjectAndJobTypesForProjectPageContent = ({project}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();

  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: `Project updated! Billing may need to be updated.`,
    actionText: 'View Billing',
    handleAction: () => {
      navigator.replace(`/projects/${params.projectUuid}/view?block=BILLING`);
    },
  });
  const exitEditProjectAndJobTypesModal = useModal({name: 'Exit Edit Project And Job Types Modal'});
  const updateProjectAndJobTypesForProjectForm =
    UpdateProjectAndJobTypesForProjectForm.edit(project);
  const {form, handleSubmit, submitting} = useUpdateProjectAndJobTypesForProjectMutation({
    updateProjectAndJobTypesForProjectForm,
    onSuccess: () => {
      successToast.handleToast();
      navigator.goBack();
    },
    onError: (errors) => console.log({errors}),
  });
  const field = 'updateProjectAndJobTypesForProjectForm';

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <PageHeader
          project={project}
          handleSubmit={handleSubmit}
          submitting={submitting}
          onClose={form.dirty ? exitEditProjectAndJobTypesModal.handleOpen : navigator.goBack}
        />
      ) : (
        <PageHeaderMobile
          onClose={form.dirty ? exitEditProjectAndJobTypesModal.handleOpen : navigator.goBack}
        />
      )}
      <Line />
      <ContentScrollContainer>
        {responsive.desktop ? (
          <EditProjectAndJobTypesForProjectPageContentDesktop
            project={project}
            form={form}
            field={field}
          />
        ) : (
          <EditProjectAndJobTypesForProjectPageContentMobile
            project={project}
            form={form}
            field={field}
          />
        )}
        <Space height={120} />
      </ContentScrollContainer>
      {!responsive.desktop && (
        <MobileSaveButton text={'Save'} handleSubmit={handleSubmit} submitting={submitting} />
      )}
      <WarningModal
        title={'Quit editing?'}
        message={'You have unsaved changes. This cannot be undone.'}
        isOpen={exitEditProjectAndJobTypesModal.isOpen}
        primaryActionText={'Confirm'}
        handlePrimaryAction={navigator.goBack}
        secondaryActionText={'Cancel'}
        handleSecondaryAction={exitEditProjectAndJobTypesModal.handleClose}
      />
    </React.Fragment>
  );
};

const EditProjectAndJobTypesForProjectPage = () => {
  const {params, navigator} = useNavigationDOM();
  const {data, loading} = useQuery(EditProjectAndJobTypesForProjectPage.query, {
    fetchPolicy: 'network-only',
    variables: {projectUuid: params.projectUuid},
  });

  return (
    <SidebarPageV2 selected={'moves'}>
      <PageContainer>
        <Loading loading={loading} as={() => <LoadingComponent navigator={navigator} />}>
          {() => {
            return <EditProjectAndJobTypesForProjectPageContent project={data.project} />;
          }}
        </Loading>
      </PageContainer>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditProjectAndJobTypesForProjectPage.query = gql`
  ${EditJobTypesContent.fragment}
  ${EditProjectTypeContent.fragment}
  ${Project.getName.fragment}
  ${UpdateProjectAndJobTypesForProjectForm.edit.fragment}
  query EditProjectAndJobTypesForProjectPage($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      ...EditJobTypesContent
      ...EditProjectTypeContent
      ...Project_getName
      ...UpdateProjectAndJobTypesForProjectForm_edit
    }
  }
`;

export default EditProjectAndJobTypesForProjectPage;
