// Supermove
import {gql} from '@supermove/graphql';
import {Currency, Float, withFragment} from '@supermove/utils';

const edit = withFragment(
  (itemType) => ({
    itemTypeId: itemType.id,
    organizationId: itemType.organizationId,
    kind: itemType.kind,
    name: itemType.name,
    weight: itemType.weight,
    volume: itemType.volume,
    price: itemType.price,
    isDerivedWeight: itemType.isDerivedWeight,
    categoryIds: itemType.itemTypeCategories.map((itemTypeCategory) => {
      return String(itemTypeCategory.categoryId);
    }),
    inventoryLibraryId: itemType.inventoryLibraryId,
    isDeleted: itemType.isDeleted,

    // private
    isDirty: false,
  }),
  gql`
    fragment ItemTypeForm_edit on ItemType {
      id
      organizationId
      kind
      name
      weight
      volume
      price
      isDerivedWeight
      itemTypeCategories {
        id
        categoryId
      }
      inventoryLibraryId
      isDeleted
    }
  `,
);

const _new = ({organizationId, kind, inventoryLibraryId = null}) => ({
  itemTypeId: undefined,
  organizationId,
  kind,
  name: '',
  weight: '',
  volume: '',
  price: '',
  isDerivedWeight: true,
  categoryIds: [],
  inventoryLibraryId,
  isDeleted: false,

  // private
  isDirty: true,
});

const toForm = ({
  itemTypeId,
  organizationId,
  kind,
  name,
  weight,
  volume,
  price,
  isDerivedWeight,
  categoryIds,
  inventoryLibraryId,
  isDeleted,

  // private
  isDirty,
}) => ({
  itemTypeId,
  organizationId,
  kind,
  name,
  weight: Float.toForm(weight),
  volume: Float.toForm(volume),
  price: Currency.toForm(price),
  isDerivedWeight,
  categoryIds,
  inventoryLibraryId,
  isDeleted,

  // private
  isDirty,
});

const toMutation = ({
  itemTypeId,
  organizationId,
  kind,
  name,
  weight,
  volume,
  price,
  isDerivedWeight,
  categoryIds,
  inventoryLibraryId,
  isDeleted,
}) => ({
  itemTypeId,
  organizationId,
  kind,
  name,
  weight: Float.toMutation(weight),
  volume: Float.toMutation(volume),
  price: Currency.toMutation(price),
  isDerivedWeight,
  categoryIds,
  inventoryLibraryId,
  isDeleted,
});

const ItemTypeForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default ItemTypeForm;
