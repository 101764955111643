// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({projectTypeId, roomTypeId, name = ''}) => ({
  projectTypeRoomTypeId: null,
  projectTypeId,
  roomTypeId,
  name,
  isDeleted: false,
});

const edit = withFragment(
  (projectTypeRoomType) => ({
    projectTypeRoomTypeId: projectTypeRoomType.id,
    projectTypeId: projectTypeRoomType.projectTypeId,
    roomTypeId: projectTypeRoomType.roomTypeId,
    name: projectTypeRoomType.name,
    isDeleted: projectTypeRoomType.isDeleted,
  }),
  gql`
    fragment ProjectTypeRoomTypeForm_edit on ProjectTypeRoomType {
      id
      projectTypeId
      roomTypeId
      name
      isDeleted
    }
  `,
);

const toForm = ({projectTypeRoomTypeId, projectTypeId, roomTypeId, name, isDeleted}) => ({
  projectTypeRoomTypeId,
  projectTypeId,
  roomTypeId,
  name,
  isDeleted,
});

const toMutation = ({projectTypeRoomTypeId, projectTypeId, roomTypeId, name, isDeleted}) => ({
  projectTypeRoomTypeId,
  projectTypeId,
  roomTypeId,
  name,
  isDeleted,
});

const ProjectTypeRoomTypeForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ProjectTypeRoomTypeForm;
