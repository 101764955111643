// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useMountEffect, useState} from '@supermove/hooks';
import {PrintLetterStyle, PrintPageStyle, colors, Typography} from '@supermove/styles';
import {Document} from '@supermove/utils';

const Background = Styled.View`
  padding-bottom: 180px;
  background-color: ${colors.gray.background};
  overflow: scroll;
`;

/**
 * We set min-height so the preview looks realistic when
 * there is not enough content to fill a single page.
 * min-height should technically be 1024px to match the 8.5" x 11"
 * paper dimensions. But, this results in two pages when there
 * should only be one; as such, we set a min-height of 960px.
 */
const Wrapper = Styled.View`
  width: 796px;
  min-height: 960px;
  padding-vertical: 36px;
  padding-horizontal: 36px;
  background-color: ${colors.white};
`;

const Container = Styled.View`
  width: 720px;
`;

const Header = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  width: 796px;
  padding-vertical: 20px;
`;

const PrintButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 120px;
  padding-vertical: 10px;
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  border-radius: 3px;
`;

const Text = Styled.Text`
  ${Typography.Body1}
`;

const Indicator = Styled.Loading`
`;

const openPrintDialog = async ({setIsLoading}) => {
  const filename = 'document.pdf';
  const url = window.location.href;
  setIsLoading(true);
  const pdf = await Document.generatePDFFromUrl({filename, url});
  Document.printFile({file: pdf});
  setIsLoading(false);
};

const ServerPrintButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PrintButton disabled={isLoading} onPress={() => openPrintDialog({setIsLoading})}>
      {isLoading ? (
        <Indicator size={'small'} color={colors.gray.secondary} />
      ) : (
        <React.Fragment>
          <Text>Print</Text>
          <Space width={6} />
          <Icon color={colors.gray.primary} size={Icon.Sizes.Large} source={Icon.Print} />
        </React.Fragment>
      )}
    </PrintButton>
  );
};

/* NOTE: `window.isReadyForPDF` is used by the PDF service. */
const Printable = ({children}) => {
  // After component did mount and fonts did load, set `isReadyForPDF`.
  useMountEffect(() => {
    if (document.fonts) {
      document.fonts.ready
        .then(() => {
          window.isReadyForPDF = true;
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      window.isReadyForPDF = true;
    }
  });
  return (
    <Wrapper data-print-wrapper>
      <Container data-print-container>{children}</Container>
    </Wrapper>
  );
};

const PrintDocumentV2Wrapper = ({isWidthOfContent, children}) => {
  return (
    <React.Fragment>
      <Background
        data-print-background
        // If the print document is the width of the content, we don't need to style it.
        // Otherwise, we flex and center to fill the parent container.
        style={isWidthOfContent ? {flexShrink: 1} : {flex: 1, width: '100%', alignItems: 'center'}}
      >
        <PrintLetterStyle />
        <PrintPageStyle />
        <Header data-no-print>
          <ServerPrintButton />
        </Header>
        <Printable children={children} />
      </Background>
    </React.Fragment>
  );
};

export default PrintDocumentV2Wrapper;
