const _new = ({jobTypeId, defaultJobTypeConfigs = []}) => ({
  jobTypeId,
  jobTypeIdsToUpdate: [],
  jobTypeConfigs: defaultJobTypeConfigs,
});

const toForm = ({jobTypeId, jobTypeIdsToUpdate, jobTypeConfigs}) => ({
  jobTypeId,
  jobTypeIdsToUpdate,
  jobTypeConfigs,
});

const toMutation = ({jobTypeId, jobTypeIdsToUpdate, jobTypeConfigs}) => ({
  jobTypeId,
  jobTypeIdsToUpdate,
  jobTypeConfigs,
});

const ApplyJobTypeConfigsToJobTypesForm = {
  new: _new,
  toForm,
  toMutation,
};

export default ApplyJobTypeConfigsToJobTypesForm;
