// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectTypeVariableSectionsForm from '@shared/modules/Variable/forms/ProjectTypeVariableSectionsForm';

const useUpsertProjectTypeVariableSectionsMutation = ({
  projectTypeVariableSectionsForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      projectTypeVariableSectionsForm: ProjectTypeVariableSectionsForm.toForm(
        projectTypeVariableSectionsForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpsertProjectTypeVariableSectionsMutation.mutation,
    variables: {
      projectTypeVariableSectionsForm: ProjectTypeVariableSectionsForm.toMutation(
        form.values.projectTypeVariableSectionsForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpsertProjectTypeVariableSectionsMutation.mutation = gql`
  mutation useUpsertProjectTypeVariableSectionsMutation($projectTypeVariableSectionsForm: ProjectTypeVariableSectionsForm!){
    response: upsertProjectTypeVariableSections(projectTypeVariableSectionsForm: $projectTypeVariableSectionsForm) {
      ${gql.errors}
      projectTypeVariableSections {
        id
      }
    }
  }
`;

export default useUpsertProjectTypeVariableSectionsMutation;
