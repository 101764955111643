// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SettingsForm from '@shared/modules/Settings/forms/SettingsForm';

const useToggleSettingsShouldSkipTipPromptMutation = ({settingsForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      settingsForm: SettingsForm.toForm(settingsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useToggleSettingsShouldSkipTipPromptMutation.mutation,
    variables: {
      settingsForm: SettingsForm.toMutation(form.values.settingsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useToggleSettingsShouldSkipTipPromptMutation.mutation = gql`
  mutation useToggleSettingsShouldSkipTipPromptMutation($settingsForm: SettingsForm!) {
    response: toggleSettingsShouldSkipTipPrompt(settingsForm: $settingsForm) {
      ${gql.errors}
      settings {
        id
        organization {
          id
          shouldSkipTipPrompt
        }
      }
    }
  }
`;

export default useToggleSettingsShouldSkipTipPromptMutation;
