// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Popover, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery, useNavigationDOM, usePopover} from '@supermove/hooks';
import {EmailTemplate} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import Table from '@shared/design/components/Table';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CannotDeleteModal from 'modules/Organization/Settings/Billing/components/CannotDeleteModal';
import DeleteEmailTemplateModal from 'modules/Organization/Settings/Email/components/DeleteEmailTemplateModal';
import EmailAttachmentLibrary from 'modules/Organization/Settings/Email/components/EmailAttachmentLibrary';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const EmailTemplatesTableContainer = Styled.View`
  width: 950px;
`;

const SectionHeaderText = Styled.Text`
  ${Typography.Subheading}
`;

const Text = Styled.Text`
  ${Typography.Body}
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label}
;`;

const EditEmailTemplateButton = Styled.ButtonV2`
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const getEmailTemplateColumnDefinitions = ({refetch, isSystemSection, navigator}) => [
  {
    width: 350,
    headerContent: () => {
      return <TableHeaderText>Name</TableHeaderText>;
    },
    cellContent: ({item: emailTemplate}) => {
      return <Text>{emailTemplate.name}</Text>;
    },
  },
  {
    width: 500,
    headerContent: () => {
      return <TableHeaderText>Subject</TableHeaderText>;
    },
    cellContent: ({item: emailTemplate}) => {
      return <Text numberOfLines={2}>{emailTemplate.subject}</Text>;
    },
  },
  {
    width: 50,
    headerContent: () => {
      return <TableHeaderText>Actions</TableHeaderText>;
    },
    cellContent: ({item: emailTemplate}) => {
      return (
        <EmailTemplateActions
          emailTemplate={emailTemplate}
          refetch={refetch}
          navigator={navigator}
          isSystemSection={isSystemSection}
        />
      );
    },
  },
];

const EmailTemplateActions = ({emailTemplate, refetch, navigator, isSystemSection}) => {
  const emailTemplateActionsPopover = usePopover();
  const deleteEmailTemplateModal = useModal({name: 'Delete Email Template Modal'});
  const blockDeleteEmailTemplateModal = useModal({name: 'Block Delete Email Template Modal'});
  const isDefaultEmailTemplateOnConfirmationSteps = emailTemplate.confirmationSteps.length > 0;

  return (
    <React.Fragment>
      <EditEmailTemplateButton
        onPress={() => navigator.push(`/settings/email/email-templates/${emailTemplate.uuid}/edit`)}
      >
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </EditEmailTemplateButton>
      <Space width={12} />
      {EmailTemplate.isCustomEmailTemplate(emailTemplate) && !isSystemSection && (
        <Popover.Content innerRef={emailTemplateActionsPopover.ref}>
          <MoreActionsButton onPress={emailTemplateActionsPopover.handleOpen}>
            <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
          </MoreActionsButton>
        </Popover.Content>
      )}
      <EmailTemplateActionsMenu
        popover={emailTemplateActionsPopover}
        isDeleteBlocked={isDefaultEmailTemplateOnConfirmationSteps}
        deleteEmailTemplateModal={deleteEmailTemplateModal}
        blockDeleteEmailTemplateModal={blockDeleteEmailTemplateModal}
      />
      <DeleteEmailTemplateModal
        key={deleteEmailTemplateModal.key}
        isOpen={deleteEmailTemplateModal.isOpen}
        emailTemplate={emailTemplate}
        handleClose={deleteEmailTemplateModal.handleClose}
        refetch={refetch}
      />
      <CannotDeleteModal
        key={blockDeleteEmailTemplateModal.key}
        titleText={`Unable to delete ${emailTemplate.name}.`}
        description={
          'This is used as a default email template for quotes and confirmation steps on the following project types:'
        }
        listItems={emailTemplate.confirmationSteps?.map(
          (confirmationStep) => confirmationStep.projectType,
        )}
        cannotDeleteModal={blockDeleteEmailTemplateModal}
        getListItemText={(projectType) => projectType.name}
        getListItemUrl={(projectType) =>
          `/settings/project-types/${projectType.uuid}/quotes-and-confirmations`
        }
        buttonText={'Close'}
      />
    </React.Fragment>
  );
};

const EmailTemplateActionsMenu = ({
  popover,
  isDeleteBlocked,
  deleteEmailTemplateModal,
  blockDeleteEmailTemplateModal,
}) => {
  return (
    <ActionMenuPopover popover={popover} placement={Popover.Positions.BottomStart} width={208}>
      <ActionMenuPopover.MenuItem
        onPress={() => {
          isDeleteBlocked
            ? blockDeleteEmailTemplateModal.handleOpen()
            : deleteEmailTemplateModal.handleOpen();
          popover.handleClose();
        }}
      >
        Delete
      </ActionMenuPopover.MenuItem>
    </ActionMenuPopover>
  );
};

const EmailTemplateSection = ({title, emptyText, emailTemplates, refetch, isSystemSection}) => {
  const {navigator} = useNavigationDOM();
  return (
    <React.Fragment>
      <SectionHeaderText>{title}</SectionHeaderText>
      <Space height={12} />
      <ScrollView horizontal>
        <EmailTemplatesTableContainer>
          <Table
            columnDefinitions={getEmailTemplateColumnDefinitions({
              refetch,
              isSystemSection,
              navigator,
            })}
            emptyStateText={emptyText}
            items={emailTemplates}
            itemKey={'id'}
          />
        </EmailTemplatesTableContainer>
      </ScrollView>
    </React.Fragment>
  );
};

const EmailTemplates = ({organization, refetch}) => {
  const {navigator} = useNavigationDOM();

  const customEmailTemplates = organization.sortedEmailTemplates
    .filter((emailTemplate) => EmailTemplate.isCustomEmailTemplate(emailTemplate))
    .filter((emailTemplate) => !EmailTemplate.isInvoiceSystemEmailTemplate(emailTemplate));

  const systemEmailTemplates = organization.sortedEmailTemplates
    .filter((emailTemplate) => {
      // If email template is of custom type
      if (
        EmailTemplate.isCustomEmailTemplate(emailTemplate) &&
        !EmailTemplate.isInvoiceSystemEmailTemplate(emailTemplate)
      ) {
        return false;
      }
      return true;
    })
    .filter((emailTemplate) => !EmailTemplate.isDeprecatedEmailTemplate(emailTemplate));

  // const systemEmailTemplates
  return (
    <React.Fragment>
      <Button
        text={'Create Email Template'}
        iconLeft={Icon.Plus}
        onPress={() => navigator.push('/settings/email/email-templates/new')}
      />
      <Space height={32} />
      <EmailTemplateSection
        refetch={refetch}
        title={'Custom Email Templates'}
        emptyText={'No custom email templates to display'}
        emailTemplates={customEmailTemplates}
      />
      <Space height={48} />
      <EmailTemplateSection
        refetch={refetch}
        title={'System Email Templates'}
        emptyText={'No system email templates to display'}
        emailTemplates={systemEmailTemplates}
        isSystemSection
      />
    </React.Fragment>
  );
};

const OrganizationSettingsEmailPageContentLoader = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsEmailPageContent.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => (
        <React.Fragment>
          <EmailTemplates organization={data.viewer.viewingOrganization} refetch={refetch} />
          <React.Fragment>
            <Space height={48} />
            <EmailAttachmentLibrary />
          </React.Fragment>
          <Space height={48} />
        </React.Fragment>
      )}
    </Loading>
  );
};

const OrganizationSettingsEmailPageContent = ({isCommunicationSettings}) => {
  const {navigator} = useNavigationDOM();

  return (
    <React.Fragment>
      {isCommunicationSettings ? (
        <ScrollView style={{flex: 1, paddingHorizontal: '24px'}}>
          <Space height={24} />

          <OrganizationSettingsEmailPageContentLoader />
        </ScrollView>
      ) : (
        <SettingsPage
          breadcrumbs={[
            {name: 'Settings', onPress: () => navigator.push('/settings')},
            {name: 'Email'},
          ]}
        >
          <OrganizationSettingsEmailPageContentLoader />
        </SettingsPage>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsEmailPageContent.query = gql`
  ${DeleteEmailTemplateModal.fragment}
  query OrganizationSettingsEmailPageContent {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        sortedEmailTemplates {
          id
          kind
          name
          subject
          body
          uuid
          confirmationSteps {
            id
            projectType {
              id
              name
              uuid
            }
          }
          ...DeleteEmailTemplateModal
        }
      }
    }
  }
`;

export default OrganizationSettingsEmailPageContent;
