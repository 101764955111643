// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Popup, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
`;

const Option = Styled.Touchable`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-vertical: 5px;
  padding-horizontal: 10px;
`;

const Text = Styled.H6`
  color: ${colors.gray.secondary}
`;

const SublabelText = Styled.H8`
  font-style: italic;
  ${fontWeight(500)}
  color: ${colors.gray.primary}
`;

const PopupIconOptions = ({offsetX, offsetY, options, position, trigger}) => {
  return (
    <Popup
      offsetX={offsetX}
      offsetY={offsetY}
      position={position}
      trigger={trigger}
      contentStyle={{
        width: 'fit-content',
        padding: 0,
      }}
    >
      {({handleClose}) => (
        <Container>
          {options.map((option, index) => (
            <Option
              key={index}
              disabled={option.isDisabled}
              onPress={() => option.handlePress({handleClose})}
            >
              {option.icon && (
                <Icon
                  color={colors.gray.secondary}
                  size={Icon.Sizes.Large}
                  source={option.icon}
                  style={{marginRight: 10}}
                />
              )}
              <Text>{option.label}</Text>
              {option.sublabel ? (
                <React.Fragment>
                  <Space width={15} />
                  <SublabelText>{option.sublabel}</SublabelText>
                </React.Fragment>
              ) : null}
            </Option>
          ))}
        </Container>
      )}
    </Popup>
  );
};

PopupIconOptions.Positions = Popup.Positions;

// --------------------------------------------------
// Props
// --------------------------------------------------
PopupIconOptions.propTypes = {
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      isDisabled: PropTypes.bool,
      icon: PropTypes.string,
      label: PropTypes.string.isRequired,
      handlePress: PropTypes.func.isRequired,
    }),
  ).isRequired,
  position: PropTypes.arrayOf(PropTypes.string),
  trigger: PropTypes.object.isRequired,
};

PopupIconOptions.defaultProps = {
  offsetX: 0,
  offsetY: 0,
  position: [Popup.Positions.LeftCenter],
};

export default PopupIconOptions;
