// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useDeleteCommercialMaterialTypeMutation = ({
  commercialMaterialTypeId,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      commercialMaterialTypeId,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteCommercialMaterialTypeMutation.mutation,
    variables: {
      commercialMaterialTypeId: form.values.commercialMaterialTypeId,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteCommercialMaterialTypeMutation.mutation = gql`
  mutation useDeleteCommercialMaterialTypeMutation($commercialMaterialTypeId: Int!) {
    response: deleteCommercialMaterialType(commercialMaterialTypeId: $commercialMaterialTypeId) {
      ${gql.errors}
    }
  }
`;

export default useDeleteCommercialMaterialTypeMutation;
