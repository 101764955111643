// Libraries
import _ from 'lodash';

// Supermove
import {Task} from '@supermove/models';
import {uuid, withFragment} from '@supermove/utils';

// App
// @ts-ignore
import TaskTemplateDueInUnitType from '@shared/modules/TaskManagement/enums/TaskTemplateDueInUnitType';

const getPropertiesToUpdate = ({
  name,
  details,
  dueInUnits,
  assignedToUserId,
}: {
  name: string | null;
  details: string | null;
  dueInUnits: string | null;
  assignedToUserId: number | null;
}): string[] => {
  const propertiesToUpdate = [];
  if (name) {
    propertiesToUpdate.push(Task.WORKFLOW_BUILDER_TASK_PROPERTY.NAME);
  }
  if (assignedToUserId) {
    propertiesToUpdate.push(Task.WORKFLOW_BUILDER_TASK_PROPERTY.ASSIGNEE);
  }
  if (dueInUnits) {
    propertiesToUpdate.push(Task.WORKFLOW_BUILDER_TASK_PROPERTY.DUE_AT);
  }
  if (details) {
    propertiesToUpdate.push(Task.WORKFLOW_BUILDER_TASK_PROPERTY.DETAILS);
  }
  return _.isEmpty(propertiesToUpdate) ? [''] : propertiesToUpdate;
};
export interface UpdateTaskActionFormType {
  workflowRunStepUuid: string;
  taskIdReferencePath: string | null;
  name: string | null;
  details: string | null;
  dueInUnitType: string | null;
  dueInUnits: string | number | null;
  dueAtTime: string | null;
  assignedToUserId: number | null;
  propertiesToUpdate?: string[] | null;
}

const _new = (): UpdateTaskActionFormType => ({
  workflowRunStepUuid: uuid(),
  taskIdReferencePath: null,
  name: '',
  details: '',
  dueInUnitType: TaskTemplateDueInUnitType.DAYS,
  dueInUnits: '',
  dueAtTime: '',
  assignedToUserId: null,

  // Private
  propertiesToUpdate: [''],
});

const edit = ({
  workflowRunStepUuid,
  taskIdReferencePath,
  name,
  details,
  dueInUnitType,
  dueInUnits,
  dueAtTime,
  assignedToUserId,
}: any): UpdateTaskActionFormType => ({
  workflowRunStepUuid,
  taskIdReferencePath,
  name: name || '',
  details: details || '',
  dueInUnitType,
  dueInUnits: dueInUnits ? _.toString(dueInUnits) : '',
  dueAtTime: dueAtTime || '',
  assignedToUserId,

  // Private
  propertiesToUpdate: getPropertiesToUpdate({name, details, dueInUnits, assignedToUserId}),
});

const toForm = (updateTaskActionForm: UpdateTaskActionFormType): UpdateTaskActionFormType => ({
  workflowRunStepUuid: updateTaskActionForm.workflowRunStepUuid,
  taskIdReferencePath: updateTaskActionForm.taskIdReferencePath,
  name: updateTaskActionForm.name,
  details: updateTaskActionForm.details,
  dueInUnitType: updateTaskActionForm.dueInUnitType,
  dueInUnits: updateTaskActionForm.dueInUnits,
  dueAtTime: updateTaskActionForm.dueAtTime,
  assignedToUserId: updateTaskActionForm.assignedToUserId,

  // Private
  propertiesToUpdate: updateTaskActionForm.propertiesToUpdate,
});

const toMutation = (updateTaskActionForm: UpdateTaskActionFormType): UpdateTaskActionFormType => ({
  workflowRunStepUuid: updateTaskActionForm.workflowRunStepUuid,
  taskIdReferencePath: updateTaskActionForm.taskIdReferencePath,
  name: updateTaskActionForm.name || null,
  details: updateTaskActionForm.details || null,
  dueInUnitType: updateTaskActionForm.dueInUnitType || null,
  dueInUnits: _.toNumber(updateTaskActionForm.dueInUnits) || null,
  dueAtTime: updateTaskActionForm.dueAtTime || null,
  assignedToUserId: _.toNumber(updateTaskActionForm.assignedToUserId) || null,
});

const UpdateTaskActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default UpdateTaskActionForm;
