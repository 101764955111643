// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import RecurringTaskForm from '@shared/modules/Accounting/forms/RecurringTaskForm';

const useUpsertRecurringTaskMutation = ({recurringTaskForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      recurringTaskForm: RecurringTaskForm.toForm(recurringTaskForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpsertRecurringTaskMutation.mutation,
    variables: {
      recurringTaskForm: RecurringTaskForm.toMutation(form.values.recurringTaskForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpsertRecurringTaskMutation.mutation = gql`
  mutation useUpsertRecurringTaskMutation($recurringTaskForm: RecurringTaskForm!) {
    response: upsertRecurringTask(recurringTaskForm: $recurringTaskForm) {
      ${gql.errors}
      recurringTask {
        id
      }
    }
  }
`;

export default useUpsertRecurringTaskMutation;
