// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectJobsForm from '@shared/modules/Project/forms/ProjectJobsForm';

const useUpdateProjectJobsMutation = ({projectJobsForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      projectJobsForm: ProjectJobsForm.toForm(projectJobsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectJobsMutation.mutation,
    variables: {
      projectJobsForm: ProjectJobsForm.toMutation(form.values.projectJobsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateProjectJobsMutation.mutation = gql`
  mutation useUpdateProjectJobsMutation($projectJobsForm: ProjectJobsForm!) {
    response: updateProjectJobs(projectJobsForm: $projectJobsForm) {
      ${gql.errors}
      project {
        id
      }
    }
  }
`;

export default useUpdateProjectJobsMutation;
