// Libraries
import React from 'react';

// Supermove
import {useNavigationDOM} from '@supermove/hooks';

// App
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import SettingsHeaderNavigation from 'modules/Organization/Settings/components/SettingsHeaderNavigation';

const ReportDashboardsPageHeader = () => {
  const {navigator} = useNavigationDOM();

  return (
    <StandardOfficeHeader title={''}>
      <SettingsHeaderNavigation>
        <SettingsHeaderNavigation.PreviousScreen onPress={() => navigator.push('/settings')}>
          Settings
        </SettingsHeaderNavigation.PreviousScreen>
        <SettingsHeaderNavigation.CurrentScreen>Reports</SettingsHeaderNavigation.CurrentScreen>
      </SettingsHeaderNavigation>
    </StandardOfficeHeader>
  );
};

export default ReportDashboardsPageHeader;
