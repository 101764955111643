// App
import CapacityTotalComponentKind from '@shared/modules/CapacityCalendarSetting/enums/CapacityTotalComponentKind';

const TOTAL_TRUCKS_AVAILABLE = 'TOTAL_TRUCKS_AVAILABLE';
const AM_PM_TRUCKS_AVAILABLE = 'AM_PM_TRUCKS_AVAILABLE';
const TOTAL_CREW_AVAILABLE = 'TOTAL_CREW_AVAILABLE';
const AM_PM_CREW_AVAILABLE = 'AM_PM_CREW_AVAILABLE';
const TOTAL_CREW_AVAILABLE_BY_POSITION = 'TOTAL_CREW_AVAILABLE_BY_POSITION';
const TOTAL_CREW_AVAILABLE_BY_POSITION_TRUCK_FALLBACK =
  'TOTAL_CREW_AVAILABLE_BY_POSITION_TRUCK_FALLBACK';
const TOTAL_CREW_HOURS_AVAILABLE = 'TOTAL_CREW_HOURS_AVAILABLE';
const TOTAL_JOBS = 'TOTAL_JOBS';
const DAY_NOTES = 'DAY_NOTES';

const getDisplayName = (slotMetricKind) => {
  switch (slotMetricKind) {
    case TOTAL_CREW_AVAILABLE_BY_POSITION:
      return '# of crew committed for position / # of available crew for position';
    case TOTAL_CREW_AVAILABLE_BY_POSITION_TRUCK_FALLBACK:
      return '# of trucks committed / # of available crew for position';
    case TOTAL_TRUCKS_AVAILABLE:
      return '# of trucks committed / # of available trucks';
    case TOTAL_CREW_AVAILABLE:
      return '# of crew committed / # of available crew';
    case TOTAL_JOBS:
      return '# of jobs';
    case DAY_NOTES:
      return 'Day notes';
    default:
      return '';
  }
};

const DROPDOWN_OPTIONS = [
  {
    label: getDisplayName(TOTAL_JOBS),
    value: TOTAL_JOBS,
    description: 'Ex: 5 jobs',
    allowedSlots: [1, 2],
  },
  {
    label: getDisplayName(TOTAL_CREW_AVAILABLE),
    value: TOTAL_CREW_AVAILABLE,
    description: 'Ex: 5/13 crew committed',
    allowedSlots: [1, 2],
  },
  {
    label: getDisplayName(TOTAL_TRUCKS_AVAILABLE),
    value: TOTAL_TRUCKS_AVAILABLE,
    description: 'Ex: 5/13 trucks committed',
    allowedSlots: [1, 2],
  },
  {
    label: getDisplayName(TOTAL_CREW_AVAILABLE_BY_POSITION_TRUCK_FALLBACK),
    value: TOTAL_CREW_AVAILABLE_BY_POSITION_TRUCK_FALLBACK,
    description: 'Ex: 5/13 [position] committed',
    allowedSlots: [1, 2],
  },
  {
    label: getDisplayName(TOTAL_CREW_AVAILABLE_BY_POSITION),
    value: TOTAL_CREW_AVAILABLE_BY_POSITION,
    description: 'Ex: 5/13 [position] committed',
    allowedSlots: [1, 2],
  },
];

// TODO(Kevin): Remove this once migration for secondary metric is done, easiest way to FF
const DROPDOWN_OPTIONS_V1 = [
  {
    label: getDisplayName(TOTAL_CREW_AVAILABLE_BY_POSITION),
    value: TOTAL_CREW_AVAILABLE_BY_POSITION,
    allowedSlots: [1],
  },
  {
    label: getDisplayName(TOTAL_CREW_AVAILABLE_BY_POSITION_TRUCK_FALLBACK),
    value: TOTAL_CREW_AVAILABLE_BY_POSITION_TRUCK_FALLBACK,
    allowedSlots: [1],
  },
  {label: getDisplayName(TOTAL_TRUCKS_AVAILABLE), value: TOTAL_TRUCKS_AVAILABLE, allowedSlots: [1]},
  {label: getDisplayName(TOTAL_CREW_AVAILABLE), value: TOTAL_CREW_AVAILABLE, allowedSlots: [1]},
  {label: getDisplayName(DAY_NOTES), value: DAY_NOTES, allowedSlots: [2]},
];

const SLOT_METRIC_KIND_TO_CAPACITY_METRIC_NAME = {
  [TOTAL_CREW_AVAILABLE_BY_POSITION]: {
    fieldName: 'crewForPositionCapacity',
    componentKind: CapacityTotalComponentKind.CREW_BY_POSITION,
  },
  [TOTAL_CREW_AVAILABLE_BY_POSITION_TRUCK_FALLBACK]: {
    fieldName: 'crewForPositionCapacity',
    componentKind: CapacityTotalComponentKind.CREW_BY_POSITION,
  },
  [TOTAL_TRUCKS_AVAILABLE]: {
    fieldName: 'truckCapacity',
    componentKind: CapacityTotalComponentKind.SINGLE_NUMBER,
  },
  [TOTAL_CREW_AVAILABLE]: {
    fieldName: 'crewCapacity',
    componentKind: CapacityTotalComponentKind.SINGLE_NUMBER,
  },
  [TOTAL_JOBS]: {
    fieldName: 'totalJobCapacity',
    componentKind: CapacityTotalComponentKind.SINGLE_NUMBER,
  },
  [DAY_NOTES]: {
    fieldName: null,
    componentKind: null,
  },
};

const CapacityCalendarSlotMetricKind = {
  // Enum Options
  TOTAL_TRUCKS_AVAILABLE,
  AM_PM_TRUCKS_AVAILABLE,
  TOTAL_CREW_AVAILABLE,
  AM_PM_CREW_AVAILABLE,
  TOTAL_CREW_AVAILABLE_BY_POSITION,
  TOTAL_CREW_HOURS_AVAILABLE,
  TOTAL_CREW_AVAILABLE_BY_POSITION_TRUCK_FALLBACK,
  TOTAL_JOBS,

  DAY_NOTES,

  DROPDOWN_OPTIONS,
  DROPDOWN_OPTIONS_V1,
  SLOT_METRIC_KIND_TO_CAPACITY_METRIC_NAME,
  getDisplayName,
};

export default CapacityCalendarSlotMetricKind;
