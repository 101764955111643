// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getProjectTagAndTagAssignment = withFragment(
  (project) => {
    const tags = project.tagAssignmentsByProject.map((tagAssignment) => ({
      tagAssignmentId: tagAssignment.id,
      id: tagAssignment.tag.id,
      name: tagAssignment.tag.name,
      emoji: tagAssignment.tag.emoji,
      isArchived: tagAssignment.tag.isArchived,
      kind: tagAssignment.tag.kind,
    }));
    return tags;
  },
  gql`
    fragment Tag_getProjectTagAndTagAssignment on Project {
      id
      tagAssignmentsByProject {
        id
        tag {
          id
          name
          emoji
          isArchived
          kind
        }
      }
    }
  `,
);

const getJobAndProjectTagAndTagAssignment = withFragment(
  (job) => {
    const jobTags = job.tagAssignmentsByJob.map((tagAssignment) => ({
      tagAssignmentId: tagAssignment.id,
      id: tagAssignment.tag.id,
      name: tagAssignment.tag.name,
      emoji: tagAssignment.tag.emoji,
      isArchived: tagAssignment.tag.isArchived,
      kind: tagAssignment.tag.kind,
    }));
    const projectTags = job.project.tagAssignmentsByProject.map((tagAssignment) => ({
      tagAssignmentId: tagAssignment.id,
      id: tagAssignment.tag.id,
      name: tagAssignment.tag.name,
      emoji: tagAssignment.tag.emoji,
      isArchived: tagAssignment.tag.isArchived,
      kind: tagAssignment.tag.kind,
    }));

    return [...projectTags, ...jobTags];
  },
  gql`
    fragment Tag_getJobAndProjectTagAndTagAssignment on Job {
      id
      tagAssignmentsByJob {
        id
        tag {
          id
          name
          emoji
          isArchived
          kind
        }
      }
      project {
        id
        tagAssignmentsByProject {
          id
          tag {
            id
            name
            emoji
            isArchived
            kind
          }
        }
      }
    }
  `,
);

const getJobTagAndTagAssignment = withFragment(
  (job) => {
    const jobTags = job.tagAssignmentsByJob.map((tagAssignment) => ({
      tagAssignmentId: tagAssignment.id,
      id: tagAssignment.tag.id,
      name: tagAssignment.tag.name,
      emoji: tagAssignment.tag.emoji,
      isArchived: tagAssignment.tag.isArchived,
      kind: tagAssignment.tag.kind,
    }));

    return jobTags;
  },
  gql`
    fragment Tag_getJobTagAndTagAssignment on Job {
      id
      tagAssignmentsByJob {
        id
        tag {
          id
          name
          emoji
          isArchived
          kind
        }
      }
    }
  `,
);

const Tag = {
  getProjectTagAndTagAssignment,
  getJobTagAndTagAssignment,
  getJobAndProjectTagAndTagAssignment,
};

export default Tag;
