// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import BillStage from '@shared/modules/Billing/enums/BillStage';

const Container = Styled.View`
`;

const Text = Styled.Text`
  ${Typography.Body}
  color: ${(props) => props.color}
`;

const Label = Styled.Text`
  ${Typography.Label}
  color: ${colors.gray.secondary};
`;

const InvoiceSectionLabel = Styled.Text`
  ${Typography.Subheading}
`;

const Line = Styled.View`
  border-top-width: 1px;
  border-color: ${colors.gray.border}
`;

const InvoiceSectionContainer = Styled.View`
  padding: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  border-radius: 4px;
`;

const InvoiceSectionTotalContainer = Styled.View`
  padding: 8px;
  background-color: ${colors.gray.background};
`;

const InvoiceItemContainer = Styled.View`
  flex-direction: row;
  justify-content: ${(props) => (props.isPostSubtotal ? 'space-between' : 'flex-start')}
`;

const InvoiceListPreSubtotalItem = ({invoiceItem, index}) => {
  return (
    <InvoiceItemContainer>
      <Text style={{flex: 3, textAlign: 'left'}}>{`${invoiceItem.name}`}</Text>
      <Text
        color={colors.gray.secondary}
        style={{flex: 1, textAlign: 'right'}}
      >{`${invoiceItem.quantity}`}</Text>
      <Text
        color={colors.gray.secondary}
        style={{flex: 1, textAlign: 'right'}}
      >{`${Currency.display(invoiceItem.amount)}`}</Text>
      <Text style={{flex: 1, textAlign: 'right'}}>{`${Currency.display(invoiceItem.total)}`}</Text>
    </InvoiceItemContainer>
  );
};

const InvoiceListPostSubtotalItem = ({invoiceItem, subtotal, index}) => {
  return (
    <InvoiceItemContainer isPostSubtotal>
      <Text>{`${invoiceItem.name}`}</Text>
      <Text>{`${Currency.display(invoiceItem.total)}`}</Text>
    </InvoiceItemContainer>
  );
};

const InvoiceSectionItem = ({invoiceSection, index}) => {
  return (
    <InvoiceSectionContainer>
      <InvoiceSectionLabel>{`${invoiceSection.title}`}</InvoiceSectionLabel>
      <Space height={8} />
      <InvoiceItemContainer>
        <Label style={{flex: 3, textAlign: 'left'}}>Item</Label>
        <Label style={{flex: 1, textAlign: 'right'}}>Quantity</Label>
        <Label style={{flex: 1, textAlign: 'right'}}>Amount</Label>
        <Label style={{flex: 1, textAlign: 'right'}}>Total</Label>
      </InvoiceItemContainer>
      <Space height={8} />
      <Line />
      <Space height={8} />
      {/* Pre subtotal invoice items */}
      {invoiceSection.invoiceItems
        .filter((item) => item.invoiceItemStage === BillStage.PRE_SUBTOTAL)
        .map((invoiceItem, index) => {
          return (
            <React.Fragment key={`${index}_PRE_SUBTOTAL_INVOICE_ITEM`}>
              <InvoiceListPreSubtotalItem invoiceItem={invoiceItem} index={index} />
              <Space height={8} />
            </React.Fragment>
          );
        })}
      <Line />
      <Space height={8} />
      {/* Subtotal Section */}
      <InvoiceItemContainer isPostSubtotal>
        <Text>Subtotal</Text>
        <Text>{`${Currency.display(invoiceSection.subtotal)}`}</Text>
      </InvoiceItemContainer>
      <Space height={8} />
      {/* Post subtotal section */}
      {invoiceSection.invoiceItems
        .filter((item) => item.invoiceItemStage === BillStage.POST_SUBTOTAL)
        .map((invoiceItem, index) => {
          return (
            <React.Fragment key={`${index}_POST_SUBTOTAL_INVOICE_ITEM`}>
              <InvoiceListPostSubtotalItem
                invoiceItem={invoiceItem}
                subtotal={invoiceSection.subtotal}
                index={index}
              />
              <Space height={8} />
            </React.Fragment>
          );
        })}
      <Space height={8} />
      {/* Invoice section total */}
      <InvoiceSectionTotalContainer>
        <InvoiceItemContainer isPostSubtotal>
          <Text style={{fontWeight: 'bold'}}>Total</Text>
          <Text style={{fontWeight: 'bold'}}>{`${Currency.display(invoiceSection.total)}`}</Text>
        </InvoiceItemContainer>
      </InvoiceSectionTotalContainer>
    </InvoiceSectionContainer>
  );
};

const InvoiceDocumentV2InvoiceSectionsContent = ({invoiceSections}) => {
  return (
    <Container>
      <FlatList
        data={_.sortBy(invoiceSections, (invoiceSection) => {
          return invoiceSection.index;
        })}
        keyExtractor={(invoiceSection) => invoiceSection.id}
        renderItem={({item: invoiceSection, index}) => (
          <React.Fragment key={`${index}_INVOICE_SECTION`}>
            <InvoiceSectionItem invoiceSection={invoiceSection} index={index} />
          </React.Fragment>
        )}
        ItemSeparatorComponent={() => <Space height={16} />}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceDocumentV2InvoiceSectionsContent.fragment = gql`
  fragment InvoiceDocumentV2InvoiceSectionsContent on InvoiceSection {
    id
    title
    subtotal
    total
    index
    invoiceItems {
      id
      name
      amount
      quantity
      percentage
      kind
      invoiceItemStage
      total
    }
  }
`;

export default InvoiceDocumentV2InvoiceSectionsContent;
