// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {withFragment, Json, Currency, Datetime} from '@supermove/utils';

// App
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import ConfirmationStepsKind from '@shared/modules/Proposal/enums/ConfirmationStepsKind';
import ExpirationTimeframeUnitKind from '@shared/modules/Proposal/enums/ExpirationTimeframeUnitKind';

const getProjectTypeProposalExpirationDefaults = withFragment(
  ({projectType, kind}) => {
    switch (kind) {
      case ConfirmationStepsKind.CONFIRMATION:
        return {
          isEnabledExpiration:
            projectType.confirmationConfirmationSteps.isEnabledAutomaticExpiration,
          expirationTimeframeUnitKind:
            projectType.confirmationConfirmationSteps.defaultExpirationTimeframeUnitKind,
          expirationTimeframe: projectType.confirmationConfirmationSteps.defaultExpirationTimeframe,
        };
      case ConfirmationStepsKind.QUOTE:
        return {
          isEnabledExpiration: projectType.quoteConfirmationSteps.isEnabledAutomaticExpiration,
          expirationTimeframeUnitKind:
            projectType.quoteConfirmationSteps.defaultExpirationTimeframeUnitKind,
          expirationTimeframe: projectType.quoteConfirmationSteps.defaultExpirationTimeframe,
        };
      default:
        return {
          isEnabledExpiration: false,
          expirationTimeframeUnitKind: ExpirationTimeframeUnitKind.HOURS,
          expirationTimeframe: '',
        };
    }
  },
  gql`
    fragment Proposal_getProjectTypeProposalExpirationDefaults on ProjectType {
      id
      quoteConfirmationSteps: confirmationStepsByKind(kind: "QUOTE") {
        id
        isEnabledAutomaticExpiration
        defaultExpirationTimeframeUnitKind
        defaultExpirationTimeframe
      }
      confirmationConfirmationSteps: confirmationStepsByKind(kind: "CONFIRMATION") {
        id
        isEnabledAutomaticExpiration
        defaultExpirationTimeframeUnitKind
        defaultExpirationTimeframe
      }
    }
  `,
);

const getProjectTypeConfirmationSteps = withFragment(
  ({projectType, kind}) => {
    switch (kind) {
      case ConfirmationStepsKind.CONFIRMATION:
        return Json.toCamelCaseKeys(
          Json.parse(projectType.confirmationConfirmationSteps.stepsJson),
        );
      case ConfirmationStepsKind.QUOTE:
        return Json.toCamelCaseKeys(Json.parse(projectType.quoteConfirmationSteps.stepsJson));
      default:
        return [];
    }
  },
  gql`
    fragment Proposal_getProjectTypeConfirmationSteps on ProjectType {
      id
      quoteConfirmationSteps: confirmationStepsByKind(kind: "QUOTE") {
        id
        stepsJson
      }
      confirmationConfirmationSteps: confirmationStepsByKind(kind: "CONFIRMATION") {
        id
        stepsJson
      }
    }
  `,
);

const getProjectTypeConfirmationStepsShownByDefault = withFragment(
  ({projectType, kind}) => {
    return _.filter(
      getProjectTypeConfirmationSteps({projectType, kind}),
      (step) => step.showByDefault,
    );
  },
  gql`
    ${getProjectTypeConfirmationSteps.fragment}
    fragment Proposal_getProjectTypeConfirmationStepsShownByDefault on ProjectType {
      id
      ...Proposal_getProjectTypeConfirmationSteps
    }
  `,
);

const stepToForm = (step) => {
  const CREDIT_CARD_STEPS = [
    ConfirmationStepKind.CONFIRMATION_STEP_SAVE_CREDIT_CARD,
    ConfirmationStepKind.CONFIRMATION_STEP_MAKE_DEPOSIT,
  ];
  if (CREDIT_CARD_STEPS.includes(step.kind)) {
    return {
      ...step,
      value: Currency.toForm(step.value),
    };
  }
  return step;
};

const getStatus = withFragment(
  (proposal) => {
    let statusText;
    let statusColor;

    if (proposal.isActive) {
      const formattedExpirationDatetime =
        proposal.expirationDatetime &&
        Datetime.toDisplayDate(
          Datetime.fromDatetime(proposal.expirationDatetime),
          Datetime.DISPLAY_MONTH_DAY_TIME,
        );
      if (proposal.isExpired) {
        statusText = `Expired ${formattedExpirationDatetime}`;
        statusColor = colors.red.warning;
      } else if (proposal.expirationDatetime && !proposal.isComplete) {
        statusText = `Expires ${formattedExpirationDatetime}`;
        statusColor = colors.orange.status;
      } else {
        statusText = 'Active';
        statusColor = colors.green.status;
      }
    } else {
      statusText = 'Inactive';
      statusColor = colors.gray.secondary;
    }

    return {statusText, statusColor};
  },
  gql`
    fragment Proposal_getStatus on Confirmation {
      id
      isActive
      isExpired
      isComplete
      expirationDatetime
    }
  `,
);

const Proposal = {
  getProjectTypeProposalExpirationDefaults,
  getProjectTypeConfirmationSteps,
  getProjectTypeConfirmationStepsShownByDefault,
  stepToForm,
  getStatus,
};

export default Proposal;
