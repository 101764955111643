// Supermove

const _new = ({invoiceId}) => ({
  invoiceId,
});

const toForm = ({invoiceId}) => {
  return {
    invoiceId,
  };
};

const toMutation = ({invoiceId}) => {
  return {
    invoiceId,
  };
};

const DequeueInvoiceForm = {
  new: _new,
  toForm,
  toMutation,
};

export default DequeueInvoiceForm;
