// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal, useResponsive, useSheet, usePopover} from '@supermove/hooks';
import {Project, Invoice, Payment} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {List, Currency, Datetime} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Panel from '@shared/design/components/Panel';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import Line from 'components/Line';
import InvoiceChargeCreditCardDrawer from 'modules/Accounting/components/InvoiceChargeCreditCardDrawer';
import DeletePaymentModal from 'modules/Bill/components/DeletePaymentModal';
import ClientCreditCardsDrawer from 'modules/Client/components/ClientCreditCardsDrawer';
import PaymentSheet from 'modules/Payment/components/PaymentSheet';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import RecordPaymentModal from 'modules/Storage/components/RecordPaymentModal';

const Container = Styled.View`
`;

const TouchableContainer = Styled.Touchable`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const PaymentsSectionContainer = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  padding: 12px;
`;

const LabelText = Styled.Text`
  ${Typography.Label}
  ${({color}) => color && `color: ${color};`}
`;

const MicroLabelText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
  ${({color}) => color && `color: ${color};`}
`;

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const PaymentsActions = ({
  invoice,
  recordPaymentModal,
  chargeCreditCardDrawer,
  manageCreditsCardDrawer,
}) => {
  const isEnabledPayengine = Project.getIsEnabledCreditCards(invoice.project);
  const isInvoiceFinalized = invoice.status === InvoiceStatus.FINALIZED;
  const responsive = useResponsive();

  return (
    <DropdownButton
      isSmall={responsive.desktop}
      text={responsive.desktop ? 'Record Payment' : 'Record'}
      menuPosition={DropdownButton.MENU_POSITION.RIGHT}
      menuWidth={248}
      iconLeft={responsive.desktop ? Icon.Plus : undefined}
      ButtonComponent={TertiaryButton}
      isResponsive
      actions={[
        ...List.insertIf(!isInvoiceFinalized, {
          text: 'Record payment',
          onPress: recordPaymentModal.handleOpen,
        }),
        ...List.insertIf(isEnabledPayengine && !isInvoiceFinalized, {
          text: 'Charge credit card',
          onPress: chargeCreditCardDrawer.handleOpen,
        }),
        ...List.insertIf(isEnabledPayengine, {
          text: 'Manage credit cards',
          onPress: manageCreditsCardDrawer.handleOpen,
        }),
      ]}
    />
  );
};

const getDeletePaymentTooltip = ({isInvoiceFinalized, isCreditCard}) => {
  if (isInvoiceFinalized) {
    return 'Cannot delete payments once invoice is finalized.';
  }
  if (isCreditCard) {
    return 'Cannot delete credit card payments.';
  }
  return null;
};

const PaymentItemActions = ({invoice, payment, deletePaymentModal, paymentItemActionsPopover}) => {
  const isInvoiceFinalized = invoice.status === InvoiceStatus.FINALIZED;
  const isCreditCard = Payment.isCreditCardMethod({method: payment.method});

  return (
    <React.Fragment>
      <ActionMenuPopover
        popover={paymentItemActionsPopover}
        placement={ActionMenuPopover.Position.LeftStart}
        width={200}
        actions={[
          {
            text: 'Delete payment',
            isDisabled: isInvoiceFinalized || isCreditCard,
            tooltip: getDeletePaymentTooltip({isInvoiceFinalized, isCreditCard}),
            onPress: () => {
              paymentItemActionsPopover.handleClose();
              deletePaymentModal.handleOpen();
            },
          },
        ]}
      >
        <TertiaryButton
          onPress={paymentItemActionsPopover.handleToggle}
          style={{paddingHorizontal: 8}}
        >
          <Icon source={Icon.EllipsisV} size={16} color={colors.gray.secondary} />
        </TertiaryButton>
      </ActionMenuPopover>
    </React.Fragment>
  );
};

const PaymentInfoText = ({isMobile, paymentLabel, PaymentInfo}) => {
  return (
    <Row>
      {!isMobile && <MicroLabelText>{`${paymentLabel}: `}</MicroLabelText>}
      <MicroText>{PaymentInfo}</MicroText>
    </Row>
  );
};

const PaymentInfo = ({payment, invoice, isMobile, deletePaymentModal}) => {
  const paymentItemActionsPopover = usePopover();

  return (
    <Container style={{paddingVertical: 12}}>
      <Row style={{alignItems: 'center'}}>
        <LabelText>{payment.name}</LabelText>
        <Space style={{flex: 1}} />
        {isMobile ? (
          <Icon source={Icon.ChevronRight} color={colors.gray.secondary} size={16} />
        ) : (
          <PaymentItemActions
            invoice={invoice}
            payment={payment}
            deletePaymentModal={deletePaymentModal}
            paymentItemActionsPopover={paymentItemActionsPopover}
          />
        )}
      </Row>
      <Space height={8} />
      <PaymentInfoText
        isMobile={isMobile}
        paymentLabel={'Timestamp'}
        PaymentInfo={Datetime.convertToDisplayDatetime(
          payment.createdAt,
          Datetime.DISPLAY_DATETIME,
        )}
      />
      {!isMobile && (
        <React.Fragment>
          <Space height={8} />
          <PaymentInfoText
            isMobile={isMobile}
            paymentLabel={'Method'}
            PaymentInfo={Payment.getDisplayMethod(payment)}
          />
          <Space height={8} />
          <PaymentInfoText
            isMobile={isMobile}
            paymentLabel={'Description'}
            PaymentInfo={payment.description || 'None'}
          />
        </React.Fragment>
      )}
      <Space height={8} />
      <PaymentInfoText
        isMobile={isMobile}
        paymentLabel={'Amount'}
        PaymentInfo={Currency.display(payment.totalAmount)}
      />
    </Container>
  );
};

const PaymentCard = ({payment, invoice, responsive, refetch}) => {
  const isMobile = !responsive.desktop;
  const deletePaymentSheet = useSheet({
    name: 'Delete Payment Sheet',
    enableTracking: true,
  });
  const deletePaymentModal = useModal({
    name: 'Delete Payment Modal',
    enableTracking: true,
  });
  return (
    <React.Fragment>
      {isMobile ? (
        <TouchableContainer onPress={deletePaymentSheet.handleOpen}>
          <PaymentInfo payment={payment} isMobile={isMobile} invoice={invoice} />
        </TouchableContainer>
      ) : (
        <PaymentInfo
          payment={payment}
          isMobile={isMobile}
          deletePaymentModal={deletePaymentModal}
          invoice={invoice}
        />
      )}
      <PaymentSheet
        key={`DeletePaymentSheet_${payment.id}`}
        payment={payment}
        isOpen={deletePaymentSheet.isOpen}
        handleClose={deletePaymentSheet.handleClose}
        refetch={refetch}
      />
      <DeletePaymentModal
        key={`DeletePaymentModal_${payment.id}`}
        isOpen={deletePaymentModal.isOpen}
        handleClose={deletePaymentModal.handleClose}
        payment={payment}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const PaymentCardList = ({payments, invoice, responsive, refetch}) => {
  return payments.map((payment) => {
    return (
      <React.Fragment key={payment.id}>
        <PaymentCard
          invoice={invoice}
          payment={payment}
          responsive={responsive}
          refetch={refetch}
        />
        <Line />
      </React.Fragment>
    );
  });
};

const RemainingBalance = ({invoice}) => {
  const balance = Currency.display(invoice.remainingBalance);
  const balanceColor = Invoice.getInvoiceBalanceColor({invoice});

  return (
    <Row style={{justifyContent: 'space-between', paddingVertical: 12}}>
      <LabelText color={balanceColor}>Remaining Balance</LabelText>
      <LabelText color={balanceColor}>{balance}</LabelText>
    </Row>
  );
};

const PaymentsBody = ({invoice, refetch}) => {
  const {payments} = invoice;
  const responsive = useResponsive();

  return payments.length === 0 ? (
    <MicroText>No Payments</MicroText>
  ) : (
    <React.Fragment>
      <PaymentCardList
        invoice={invoice}
        payments={payments}
        responsive={responsive}
        refetch={refetch}
      />
      <RemainingBalance invoice={invoice} />
    </React.Fragment>
  );
};

const InvoicePaymentsSection = ({invoice, refetch}) => {
  const title = 'Payments';
  const responsive = useResponsive();
  const recordPaymentModal = useModal({name: 'Record Payment Modal'});
  const chargeCreditCardDrawer = useDrawer({
    name: 'Charge Credit Card Drawer',
    enableTracking: true,
  });
  const manageCreditsCardDrawer = useDrawer({
    name: 'Manage Credit Cards Drawer',
    enableTracking: true,
  });

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <Panel>
          <Panel.Header style={{padding: 12}}>
            <Panel.HeaderText>{title}</Panel.HeaderText>
            <Space style={{flex: 1}} />
            <PaymentsActions
              invoice={invoice}
              recordPaymentModal={recordPaymentModal}
              chargeCreditCardDrawer={chargeCreditCardDrawer}
              manageCreditsCardDrawer={manageCreditsCardDrawer}
            />
          </Panel.Header>
          <Panel.Body style={{paddingVertical: 0}}>
            <Space height={12} />
            <PaymentsBody invoice={invoice} refetch={refetch} />
            <Space height={12} />
          </Panel.Body>
        </Panel>
      ) : (
        <React.Fragment>
          <PaymentsSectionContainer>
            <MobileProjectBlockHeader
              title={title}
              ActionsComponent={PaymentsActions}
              actionsComponentProps={{
                invoice,
                recordPaymentModal,
                chargeCreditCardDrawer,
                manageCreditsCardDrawer,
              }}
            />
            <PaymentsBody invoice={invoice} refetch={refetch} />
          </PaymentsSectionContainer>
        </React.Fragment>
      )}
      <RecordPaymentModal
        key={recordPaymentModal.key}
        isOpen={recordPaymentModal.isOpen}
        handleClose={recordPaymentModal.handleClose}
        refetch={refetch}
        invoice={invoice}
      />
      <InvoiceChargeCreditCardDrawer
        key={chargeCreditCardDrawer.key}
        isOpen={chargeCreditCardDrawer.isOpen}
        handleClose={chargeCreditCardDrawer.handleClose}
        invoiceUuid={invoice.uuid}
        refetch={refetch}
      />
      <ClientCreditCardsDrawer
        key={manageCreditsCardDrawer.key}
        isOpen={manageCreditsCardDrawer.isOpen}
        handleClose={manageCreditsCardDrawer.handleClose}
        clientId={invoice.project.billingClientId}
        projectId={invoice.project.id}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoicePaymentsSection.fragment = gql`
  ${Project.getIsEnabledCreditCards.fragment}
  ${RecordPaymentModal.fragment}
  ${PaymentSheet.fragment}
  ${Invoice.getInvoiceBalanceColor.fragment}
  ${DeletePaymentModal.fragment}

  fragment InvoicePaymentsSection on Invoice {
    id
    uuid
    status
    project {
      id
      billingClientId
      ...Project_getIsEnabledCreditCards
    }
    payments {
      id
      name
      method
      createdAt
      totalAmount
      ...DeletePaymentModal
      ...PaymentSheet
    }
    ...RecordPaymentModal
    ...Invoice_getInvoiceBalanceColor
  }
`;

export default InvoicePaymentsSection;
