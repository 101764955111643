// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({organizationId, userId}) => ({
  containerTypeId: null,
  organizationId,
  name: '',
  dimensionLength: null,
  dimensionWidth: null,
  dimensionHeight: null,
  weightCapacity: null,
  notes: '',
  createdById: userId,
  updatedById: userId,
});

const edit = withFragment(
  (containerType, {userId}) => ({
    containerTypeId: containerType.id,
    organizationId: containerType.organizationId,
    name: containerType.name,
    dimensionLength: containerType.dimensionLength ? containerType.dimensionLength : null,
    dimensionWidth: containerType.dimensionWidth ? containerType.dimensionWidth : null,
    dimensionHeight: containerType.dimensionHeight ? containerType.dimensionHeight : null,
    weightCapacity: containerType.weightCapacity ? containerType.weightCapacity : null,
    notes: containerType.notes,
    isDeleted: containerType.isDeleted,
    createdById: containerType.createdById,
    updatedById: userId,
  }),
  gql`
    fragment ContainerTypeForm_edit on ContainerType {
      id
      organizationId
      name
      dimensionLength
      dimensionWidth
      dimensionHeight
      weightCapacity
      notes
      isDeleted
      createdById
      updatedById
    }
  `,
);

const toForm = ({
  containerTypeId,
  organizationId,
  name,
  dimensionLength,
  dimensionWidth,
  dimensionHeight,
  weightCapacity,
  notes,
  isDeleted,
  createdById,
  updatedById,
}) => ({
  containerTypeId,
  organizationId,
  name,
  dimensionLength,
  dimensionWidth,
  dimensionHeight,
  weightCapacity,
  notes,
  isDeleted,
  createdById,
  updatedById,
});

const toMutation = ({
  containerTypeId,
  organizationId,
  name,
  dimensionLength,
  dimensionWidth,
  dimensionHeight,
  weightCapacity,
  notes,
  isDeleted,
  createdById,
  updatedById,
}) => {
  return {
    containerTypeId,
    organizationId,
    name,
    dimensionLength: Number(dimensionLength),
    dimensionWidth: Number(dimensionWidth),
    dimensionHeight: Number(dimensionHeight),
    weightCapacity: Number(weightCapacity),
    notes,
    isDeleted,
    createdById,
    updatedById,
  };
};

const ContainerTypeForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ContainerTypeForm;
