// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Currency, withFragment} from '@supermove/utils';

const edit = withFragment(
  (valuationCoverage) => {
    const {valuationCoverageOptions} = valuationCoverage.valuationCoverageConfig;

    const selectedIndex = _.findIndex(valuationCoverageOptions, (valuationCoverageOption) => {
      return (
        valuationCoverageOption.category === valuationCoverage.category &&
        valuationCoverageOption.deductible === valuationCoverage.deductible
      );
    });
    if (selectedIndex === -1) {
      // Can happen if a previous selected item is no longer available
      // as an option, due to config changes.
      return {
        valuationCoverageId: valuationCoverage.id,
      };
    }

    return {
      valuationCoverageId: valuationCoverage.id,
      amountValue: valuationCoverage.amountValue,
      category: valuationCoverage.category,
      deductible: valuationCoverage.deductible,
      amountMinimum: valuationCoverage.amountMinimum,

      // Private
      isShowingRatesTable: false,
      selectedIndex,
    };
  },
  gql`
    fragment ValuationCoverageForm_edit on ValuationCoverage {
      id
      amountMinimum
      amountValue
      category
      deductible
      valuationCoverageConfig {
        valuationCoverageOptions {
          category
          deductible
        }
      }
    }
  `,
);

const ValuationCoverageForm = {
  toForm: ({
    amountMinimum,
    valuationCoverageId,
    amountValue,
    category,
    deductible,
    selectedIndex,
    isShowingRatesTable,
  }) => ({
    amountMinimum: Currency.toForm(amountMinimum),
    valuationCoverageId,
    amountValue: Currency.toForm(amountValue),
    category,
    deductible: deductible !== null ? Currency.toForm(deductible) : null,

    // Private
    selectedIndex,
    isShowingRatesTable,
  }),

  toMutation: ({amountMinimum, valuationCoverageId, amountValue, category, deductible}) => ({
    amountMinimum: Currency.toMutation(amountMinimum),
    valuationCoverageId,
    amountValue: Currency.toMutation(amountValue),
    category,
    deductible: deductible !== null ? Currency.toMutation(deductible) : deductible,
  }),

  edit,
};

export default ValuationCoverageForm;
