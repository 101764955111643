// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ReportDashboardForm from '@shared/modules/Reports/forms/ReportDashboardForm';

const useDeleteReportDashboardMutation = ({reportDashboardForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      reportDashboardForm: ReportDashboardForm.toForm(reportDashboardForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteReportDashboardMutation.mutation,
    variables: {
      reportDashboardForm: ReportDashboardForm.toMutation(form.values.reportDashboardForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteReportDashboardMutation.mutation = gql`
  mutation useDeleteReportDashboardMutation($reportDashboardForm: DashboardForm!) {
    response: deleteReportDashboard(reportDashboardForm: $reportDashboardForm) {
      ${gql.errors}
      dashboard {
        id
      }
    }
  }
`;

export default useDeleteReportDashboardMutation;
