// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import JobValuationCoverageFields from '@shared/modules/Job/components/JobValuationCoverageFields';

const Container = Styled.View`
`;

const PreviewLabelText = Styled.Text`
  ${Typography.Body3};
  color: ${colors.gray.secondary}
`;

const PreviewSelectedOptionText = Styled.Text`
  ${Typography.Body2};
`;

const DocumentV2ValuationCoverageView = ({valuationCoverage}) => {
  return (
    <React.Fragment>
      <FieldValue label={'Selected Option'} value={valuationCoverage.displayName} />
      {valuationCoverage.additionalCost > 0 && (
        <React.Fragment>
          <Space height={10} />
          <FieldValue
            label={'Selected Value'}
            value={Currency.display(valuationCoverage.amountValue)}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const DocumentV2ValuationCoveragePreview = () => {
  return (
    <Container>
      <PreviewLabelText>Selected Option</PreviewLabelText>
      <PreviewSelectedOptionText>{'{MOVE_VALUATION_COVERAGE}'}</PreviewSelectedOptionText>
    </Container>
  );
};

const DocumentV2ValuationCoverageQueried = ({job, isEditable, form, field}) => {
  if (isEditable) {
    return (
      <JobValuationCoverageFields job={job} form={form} field={`${field}.valuationCoverageForm`} />
    );
  }
  return <DocumentV2ValuationCoverageView valuationCoverage={job.valuationCoverage} />;
};

const DocumentV2ValuationCoverage = ({isPreview, job, isEditable, form, field}) => {
  return (
    <React.Fragment>
      {isPreview ? (
        <DocumentV2ValuationCoveragePreview />
      ) : (
        <DocumentV2ValuationCoverageQueried
          job={job}
          isEditable={isEditable}
          form={form}
          field={field}
        />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------

DocumentV2ValuationCoverage.propTypes = {
  isPreview: PropTypes.bool.isRequired,
};

DocumentV2ValuationCoverage.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentV2ValuationCoverage.fragment = gql`
  ${JobValuationCoverageFields.fragment}

  fragment DocumentV2ValuationCoverage on Job {
    id
    valuationCoverage {
      id
      amountValue
      displayName
      additionalCost
    }
    ...JobValuationCoverageFields
  }
`;

export default DocumentV2ValuationCoverage;
