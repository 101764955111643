// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

const getMinutesWorked = withFragment(
  (timesheetBillableEntry) => {
    return timesheetBillableEntry.billableTimeWorked / 60;
  },
  gql`
    fragment TimesheetBillableEntry_getMinutesWorked on TimesheetBillableEntry {
      id
      billableTimeWorked
    }
  `,
);

const getNumberOfDays = withFragment(
  (timesheetBillableEntry) => {
    const {start, end} = timesheetBillableEntry.timesheetBlocksDateRange;
    const differenceInMilliseconds = Datetime.fromDate(end) - Datetime.fromDate(start);
    const differenceInDays = differenceInMilliseconds / (24 * 60 * 60 * 1000);
    return differenceInDays + 1;
  },
  gql`
    fragment TimesheetBillableEntry_getNumberOfDays on TimesheetBillableEntry {
      id
      timesheetBlocksDateRange {
        start
        end
      }
    }
  `,
);

const getDates = withFragment(
  (timesheetBillableEntry) => {
    const numberOfDays = getNumberOfDays(timesheetBillableEntry);
    const {start} = timesheetBillableEntry.timesheetBlocksDateRange;
    const dates = _.range(0, numberOfDays).map((daysFromStart) => {
      const datetime = Datetime.fromDate(start).add(daysFromStart, 'days');
      return Datetime.toDate(datetime);
    });
    return dates;
  },
  gql`
    ${getNumberOfDays.fragment}
    fragment TimesheetBillableEntry_getDates on TimesheetBillableEntry {
      id
      timesheetBlocksDateRange {
        start
      }
      ...TimesheetBillableEntry_getNumberOfDays
    }
  `,
);

const TimesheetBillableEntry = {
  getMinutesWorked,
  getNumberOfDays,
  getDates,
};

export default TimesheetBillableEntry;
