// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import TextMessageTemplateForm from '@shared/modules/Sms/forms/TextMessageTemplateForm';

const useCreateTextMessageTemplateMutation = ({textMessageTemplateForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      textMessageTemplateForm: TextMessageTemplateForm.toForm(textMessageTemplateForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateTextMessageTemplateMutation.mutation,
    variables: {
      textMessageTemplateForm: TextMessageTemplateForm.toMutation(
        form.values.textMessageTemplateForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCreateTextMessageTemplateMutation.mutation = gql`
  mutation useCreateTextMessageTemplateMutation($textMessageTemplateForm: TextMessageTemplateForm!) {
    response: createTextMessageTemplateMutation(textMessageTemplateForm: $textMessageTemplateForm) {
      ${gql.errors}
      textMessageTemplate {
        id
      }
    }
  }
`;

export default useCreateTextMessageTemplateMutation;
