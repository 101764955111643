// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import ContainerTypeForm from '@shared/modules/Storage/forms/ContainerTypeForm';

const useCreateContainerTypeMutation = ({containerTypeForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      containerTypeForm: ContainerTypeForm.toForm(containerTypeForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateContainerTypeMutation.mutation,
    variables: {
      containerTypeForm: ContainerTypeForm.toMutation(form.values.containerTypeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateContainerTypeMutation.mutation = gql`
  mutation CreateContainerTypeMutation($containerTypeForm: ContainerTypeForm!) {
    response: createContainerType(containerTypeForm: $containerTypeForm) {
      ${gql.errors}
      containerType {
        id
      }
    }
  }
`;

export default useCreateContainerTypeMutation;
