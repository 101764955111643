// Libraries
import _ from 'lodash';

const COORDINATOR = 'COORDINATOR';
const SALESPERSON = 'SALESPERSON';
const CUSTOMER = 'CUSTOMER';
const ASSIGNED_MOVERS = 'ASSIGNED_MOVERS';

const KIND_OPTIONS = {
  COORDINATOR: {
    value: COORDINATOR,
    label: 'Project Coordinator',
  },
  SALESPERSON: {
    value: SALESPERSON,
    label: 'Project Salesperson',
  },
  CUSTOMER: {
    value: CUSTOMER,
    label: 'Customer',
  },
  ASSIGNED_MOVERS: {
    value: ASSIGNED_MOVERS,
    label: 'Assigned movers',
  },
};

const getKindOption = (kind) => {
  return KIND_OPTIONS[kind];
};

export default {
  COORDINATOR,
  SALESPERSON,
  CUSTOMER,
  ASSIGNED_MOVERS,

  DROPDOWN_OPTIONS: _.values(KIND_OPTIONS),
  getKindOption,
};
