// Libraries
import React from 'react';

// App
import Checkbox from '@shared/design/components/Checkbox';

const SurveyDocumentCheckbox = ({
  label,
  isChecked,
  field,
  documentItemIndex,
  setDraftDocumentContentJson,
}) => {
  return (
    <Checkbox
      label={label}
      isChecked={isChecked}
      handleToggle={(value) => {
        setDraftDocumentContentJson((prevState) => {
          // Make sure to add the showNotes property if it does not already exist
          prevState.documentItems[documentItemIndex].input = {
            ...prevState.documentItems[documentItemIndex].input,
            [field]: value,
          };
          return {
            ...prevState,
          };
        });
      }}
    />
  );
};

export default SurveyDocumentCheckbox;
