// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHandleMutationAlertErrors, useResponsive, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import TagKind from '@shared/modules/Tag/enums/TagKind';
import TagAssignmentForm from '@shared/modules/Tag/forms/TagAssignmentForm';
import useDeleteTagAssignmentMutation from '@shared/modules/Tag/hooks/useDeleteTagAssignmentMutation';

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
  line-height: unset;
`;

const BodyText = Styled.Text`
  ${Typography.Responsive.Body}
  line-height: unset;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TagNameContainer = Styled.View`
  border-radius: 16px
  border-width: 1px;
  border-color: ${colors.gray.border};
  background-color: ${({isArchived}) => (isArchived ? colors.gray.border : colors.gray.accent)};
  padding-vertical: 3px;
  padding-horizontal: 8px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${({responsive}) => (responsive.desktop ? 6 : 8)}px;
`;

const TagPill = ({
  tag,
  jobId,
  projectId,
  tagAssignmentId,
  hasDeleteButton,
  maxWidth,
  refetch,
  isSmall,
}) => {
  const responsive = useResponsive();

  const alertHandler = useHandleMutationAlertErrors({ToastComponent: ErrorToast});
  const tagAssignmentForm = TagAssignmentForm.delete({tagAssignmentId, jobId, projectId});
  const tagDeletedToast = useToast({
    ToastComponent: SuccessToast,
    message: `Tag removed`,
    isClosable: true,
  });

  const {submitting, handleSubmit} = useDeleteTagAssignmentMutation({
    tagAssignmentForm,
    onSuccess: () => {
      tagDeletedToast.handleToast();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
      alertHandler.handleMutationAlertErrors({errors});
    },
  });

  return (
    <TagNameContainer isArchived={tag.isArchived} responsive={responsive}>
      <Row>
        <Space width={4} />
        <MicroText responsive={responsive} style={{paddingTop: 1}}>
          {tag.emoji}
        </MicroText>
        <Space width={4} />
        {isSmall ? (
          <MicroText responsive={responsive} style={{maxWidth}} numberOfLines={1}>
            {tag.name}
          </MicroText>
        ) : (
          <BodyText responsive={responsive} style={{maxWidth}} numberOfLines={1}>
            {tag.name}
          </BodyText>
        )}
        <Space width={4} />
        {hasDeleteButton && tag.kind !== TagKind.TRAINING_PROJECT && (
          <React.Fragment>
            <Space width={4} />
            <TertiaryButton
              iconLeft={Icon.Xmark}
              textColor={colors.gray.secondary}
              iconSize={16}
              onPress={handleSubmit}
              isSubmitting={submitting}
            />
            <Space width={4} />
          </React.Fragment>
        )}
      </Row>
    </TagNameContainer>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
TagPill.propTypes = {
  tag: PropTypes.object.isRequired,
  tagAssignmentId: PropTypes.string,
  hasDeleteButton: PropTypes.bool,
  refetch: PropTypes.func,
  maxWidth: PropTypes.number,
  isSmall: PropTypes.bool,
  jobId: PropTypes.number,
  projectId: PropTypes.number,
};

TagPill.defaultProps = {
  tagAssignmentId: '',
  maxWidth: 150,
  isSmall: false,
  hasDeleteButton: true,
  jobId: null,
  projectId: null,
  refetch: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TagPill.fragment = gql`
  fragment TagPill on Tag {
    id
    name
    emoji
    isArchived
    kind
  }
`;

export default TagPill;
