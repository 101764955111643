// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getBillRuleAdjustmentMinutes = withFragment(
  (timesheetPayrollEntry) => {
    return timesheetPayrollEntry.timeWorked.adjustmentsFromBillRules / 60;
  },
  gql`
    fragment TimesheetPayrollEntry_getBillRuleAdjustmentMinutes on TimesheetPayrollEntry {
      id
      timeWorked {
        adjustmentsFromBillRules
      }
    }
  `,
);

const TimesheetPayrollEntry = {
  getBillRuleAdjustmentMinutes,
};

export default TimesheetPayrollEntry;
