// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import DocumentTemplateVersionForm from '@shared/modules/Document/forms/DocumentTemplateVersionForm';

const convertDocumentTemplateInputToJsonString = (documentContentJsonForm) => {
  const documentItems = _.cloneDeep(documentContentJsonForm.documentItems);
  documentItems.reduce((newDocumentItems, currentDocumentItem) => {
    if (currentDocumentItem && currentDocumentItem.input) {
      currentDocumentItem.input = JSON.stringify(currentDocumentItem.input);
    }
    return [...newDocumentItems, currentDocumentItem];
  }, []);
  return {documentItems};
};

const useUpdateDocumentTemplateVersionMutation = ({
  documentTemplateVersionForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      documentTemplateVersionForm: DocumentTemplateVersionForm.toForm(documentTemplateVersionForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateDocumentTemplateVersionMutation.mutation,
    variables: {
      documentTemplateVersionForm: {
        ...DocumentTemplateVersionForm.toMutation(form.values.documentTemplateVersionForm),
        documentContentJsonForm: convertDocumentTemplateInputToJsonString(
          documentTemplateVersionForm.documentContentJsonForm,
        ),
      },
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateDocumentTemplateVersionMutation.mutation = gql`
  mutation useUpdateDocumentTemplateVersionMutation($documentTemplateVersionForm: DocumentTemplateVersionForm!) {
    response: updateDocumentTemplateVersion(documentTemplateVersionForm: $documentTemplateVersionForm) {
      ${gql.errors}
      documentTemplateVersion {
        id
      }
    }
  }
`;

export default useUpdateDocumentTemplateVersionMutation;
