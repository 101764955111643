// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectInternalInformationForm from '@shared/modules/Project/forms/ProjectInternalInformationForm';

const useUpdateProjectInternalInformationMutation = ({
  projectInternalInformationForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      projectInternalInformationForm: ProjectInternalInformationForm.toForm(
        projectInternalInformationForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateProjectInternalInformationMutation.mutation,
    variables: {
      projectInternalInformationForm: ProjectInternalInformationForm.toMutation(
        form.values.projectInternalInformationForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateProjectInternalInformationMutation.mutation = gql`
  mutation useUpdateProjectInternalInformationMutation($projectInternalInformationForm: ProjectInternalInformationForm!){
    response: updateProjectInternalInformation(projectInternalInformationForm: $projectInternalInformationForm) {
      ${gql.errors}
      project {
        id
      }
    }
  }
`;

export default useUpdateProjectInternalInformationMutation;
