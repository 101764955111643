// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import TextTooltip from 'modules/App/components/TextTooltip';

const TooltipContainer = Styled.View`
`;

const ErrorText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.red.warning};
`;

const CheckboxField = ({form, field, isDisabled, label}) => {
  const error = _.get(form.errors, field);
  return (
    <React.Fragment>
      <Checkbox
        isDisabled={isDisabled}
        isChecked={_.get(form.values, field)}
        handleToggle={(value) => {
          form.setFieldValue(field, value);
        }}
        label={label}
      />
      {error && (
        <React.Fragment>
          <Space height={4} />
          <ErrorText>{error}</ErrorText>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const getTooltipText = ({isCreate, isDefault}) => {
  if (isCreate) {
    return 'You must publish this document in order to use it for quotes & confirmations';
  } else if (isDefault) {
    return 'To remove this as default, please set another document as default';
  } else {
    return 'This can be overridden in Project Type settings';
  }
};

const TooltipWrapper = ({isDefault, isCreate, children}) => {
  return (
    <TextTooltip text={getTooltipText({isCreate, isDefault})}>
      <TooltipContainer>{children}</TooltipContainer>
    </TextTooltip>
  );
};

const DefaultQuoteConfirmationDocumentTemplateFields = ({
  form,
  field,
  isDefaultConfirmationDocumentTemplate,
  isDefaultQuoteDocumentTemplate,
  isCreate,
}) => {
  return (
    <React.Fragment>
      <TooltipWrapper isDefault={isDefaultConfirmationDocumentTemplate} isCreate={isCreate}>
        <CheckboxField
          form={form}
          field={`${field}.isDefaultConfirmationDocumentTemplate`}
          isDisabled={isDefaultConfirmationDocumentTemplate || isCreate}
          label={'Set as default template for confirmation documents'}
        />
      </TooltipWrapper>
      <Space height={16} />
      <TooltipWrapper isDefault={isDefaultQuoteDocumentTemplate} isCreate={isCreate}>
        <CheckboxField
          form={form}
          field={`${field}.isDefaultQuoteDocumentTemplate`}
          isDisabled={isDefaultQuoteDocumentTemplate || isCreate}
          label={'Set as default template for quote documents'}
        />
      </TooltipWrapper>
      <Space height={16} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
DefaultQuoteConfirmationDocumentTemplateFields.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  isDefaultConfirmationDocumentTemplate: PropTypes.bool,
  isDefaultQuoteDocumentTemplate: PropTypes.bool,
};

DefaultQuoteConfirmationDocumentTemplateFields.defaultProps = {
  isDefaultConfirmationDocumentTemplate: false,
  isDefaultQuoteDocumentTemplate: false,
};

export default DefaultQuoteConfirmationDocumentTemplateFields;
