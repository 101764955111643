// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';

const Wrapper = Styled.View`
  margin-bottom: 10px;
  z-index: ${(props) => 100 - props.index};
`;

const CellRow = Styled.View`
  flex-direction: row;
`;

const Cell = Styled.View`
  padding-vertical: 3px;
  padding-horizontal: 5px;
  border-width: 1px;
  border-left-width: ${({columnIndex}) => (columnIndex > 0 ? '0px' : '1px')};
  border-color: ${colors.gray.border};
  min-height: 22px;
`;

const HeaderText = Styled.Text`
  ${Typography.Label3}
`;

const Text = Styled.Text`
  ${Typography.Body4}
`;

const EmptyTableBody = Styled.View`
  height: 48px;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-top-width: 0px;
  border-color: ${colors.gray.border};
`;

const AddRowContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-vertical: 6px;
  padding-horizontal: 12px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  border-style: dashed;
`;

const AddRowButton = Styled.ButtonV2`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const AddRowButtonText = Styled.Text`
  ${Typography.Label1}
  color: ${colors.blue.interactive};
`;

const RemoveRowButton = Styled.ButtonV2`
  width: 32px;
  justify-content: center;
  align-items: flex-end;
`;

const IconCircle = Styled.View`
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: ${({color}) => color};
  height: 20px;
  width: 20px;
  border-radius: 10px;
`;

const handleAddRow = ({form, inputRowsField, columns}) => {
  const rows = _.get(form.values, inputRowsField);
  form.setFieldValue(inputRowsField, [...rows, columns.map((column) => '')]);
};

const handleRemoveRow = ({form, inputRowsField, rowIndex}) => {
  const rows = _.get(form.values, inputRowsField);
  const beforeRows = rows.slice(0, rowIndex);
  const afterRows = rows.slice(rowIndex + 1);
  form.setFieldValue(inputRowsField, [...beforeRows, ...afterRows]);
};

const HeaderRow = ({columns, isEditable}) => {
  return (
    <CellRow>
      {columns.map((column, index) => {
        const isHidden = _.get(column, 'isHidden', false);

        if (isHidden) {
          return null;
        }

        return (
          <Cell key={index} columnIndex={index} style={{flex: 1}}>
            <HeaderText>{column.name}</HeaderText>
          </Cell>
        );
      })}
      {isEditable && <RemoveRowButton disabled />}
    </CellRow>
  );
};

const InputRow = ({rowIndex, row, columns, isEditable, form, inputRowsField, isDeletable}) => {
  return (
    <CellRow>
      {row.map((columnValue, columnIndex) => {
        const isHidden = _.get(columns, `${columnIndex}.isHidden`, false);

        if (isHidden) {
          return null;
        }

        return (
          <Cell key={columnIndex} columnIndex={columnIndex} style={{flex: 1, borderTopWidth: 0}}>
            {isEditable ? (
              <FieldInput
                {...form}
                name={`${inputRowsField}.${rowIndex}.${columnIndex}`}
                input={{
                  placeholder: 'Enter value',
                }}
              />
            ) : (
              <Text>{columnValue}</Text>
            )}
          </Cell>
        );
      })}
      {isEditable && (
        <RemoveRowButton
          disabled={!isDeletable}
          onPress={() => handleRemoveRow({form, inputRowsField, rowIndex})}
        >
          <IconCircle color={isDeletable ? colors.red.warning : colors.gray.border}>
            <Icon
              source={Icon.Minus}
              size={12}
              color={isDeletable ? colors.red.warning : colors.gray.border}
            />
          </IconCircle>
        </RemoveRowButton>
      )}
    </CellRow>
  );
};

const InputGridView = ({columns, input}) => {
  const inputRows = _.get(input.value, 'values', []);
  return (
    <React.Fragment>
      {_.isEmpty(inputRows) ? (
        <EmptyTableBody>
          <Text>No Items</Text>
        </EmptyTableBody>
      ) : (
        inputRows.map((row, rowIndex) => {
          return <InputRow key={rowIndex} row={row} columns={columns} />;
        })
      )}
    </React.Fragment>
  );
};

const InputGridEdit = ({columns, form, field}) => {
  const inputRowsField = `${field}.input.value.values`;
  const inputRows = _.get(form.values, inputRowsField);
  return (
    <React.Fragment>
      {inputRows.map((row, rowIndex) => {
        return (
          <InputRow
            key={rowIndex}
            rowIndex={rowIndex}
            row={row}
            columns={columns}
            isEditable
            form={form}
            inputRowsField={inputRowsField}
            isDeletable={inputRows.length > 1}
          />
        );
      })}
      <Space height={4} />
      <CellRow style={{flex: 1}}>
        <AddRowContainer>
          <AddRowButton onPress={() => handleAddRow({form, inputRowsField, columns})}>
            <IconCircle color={colors.blue.interactive}>
              <Icon source={Icon.Plus} size={12} color={colors.blue.interactive} />
            </IconCircle>
            <Space width={8} />
            <AddRowButtonText>Add a row</AddRowButtonText>
          </AddRowButton>
        </AddRowContainer>
        <RemoveRowButton disabled />
      </CellRow>
    </React.Fragment>
  );
};

const DocumentV2InputGrid = ({input, index, isEditable, form, field}) => {
  const {metadata} = input;
  const columns = _.get(metadata, 'columns', []);

  return (
    <Wrapper index={index}>
      <HeaderRow columns={columns} isEditable={isEditable} />
      {isEditable ? (
        <InputGridEdit columns={columns} form={form} field={field} />
      ) : (
        <InputGridView columns={columns} input={input} />
      )}
    </Wrapper>
  );
};

export default DocumentV2InputGrid;
