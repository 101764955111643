// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, Popover} from '@supermove/components';
import {FilteredProjectsForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useDebouncedCallback, usePopover} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import Tabs from '@shared/design/components/Tabs';
import ProjectStatus from '@shared/modules/Project/enums/ProjectStatus';
import ListProjectsPageFiltersPopover from 'modules/Project/List/components/ListProjectsPageFiltersPopover';

const HeaderContainer = Styled.View`
`;

const LeftContainer = Styled.View`
  flex: 3;
  flex-direction: row;
  align-items: center;
`;

const RightContainer = Styled.View`
  flex: 2;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const FiltersContainers = Styled.View`
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const TabsContainer = Styled.View`
`;

const Notification = Styled.View`
  background-color: ${colors.red.warning};
  margin-left: 8px;
  border-radius: 20px;
  padding: 3px 8px;
`;

const NotificationText = Styled.Text`
  ${Typography.Label4}
  color: ${colors.white};
`;

const getTabDefinitions = ({
  leadsCount,
  holdsCount,
  bookedCount,
  completedCount,
  cancelledCount,
}) => [
  {
    key: _.lowerCase(ProjectStatus.LEAD),
    label: 'Leads',
    count: leadsCount,
    status: 'leads',
  },
  {
    key: _.lowerCase(ProjectStatus.HOLD),
    label: 'Holds',
    count: holdsCount,
    status: 'holds',
  },
  {
    key: _.lowerCase(ProjectStatus.BOOKED),
    label: 'Booked',
    count: bookedCount,
    status: `${_.lowerCase(ProjectStatus.BOOKED)}`,
  },
  {
    key: _.lowerCase(ProjectStatus.COMPLETED),
    label: 'Completed',
    count: completedCount,
    status: `${_.lowerCase(ProjectStatus.COMPLETED)}`,
  },
  {
    key: _.lowerCase(ProjectStatus.CANCELLED),
    label: 'Cancelled',
    count: cancelledCount,
    status: `${_.lowerCase(ProjectStatus.CANCELLED)}`,
  },
];

const TabHeader = ({
  activeTabKey,
  filteredProjectCountsByStatus,
  form,
  isEnabledProjectHoldStatus,
  isEnabledSalesStatusDepositReceived,
}) => {
  const tabDefinitions = getTabDefinitions(filteredProjectCountsByStatus);
  const activeTabIndex = _.findIndex(
    tabDefinitions,
    (definition) => definition.status === activeTabKey,
  );

  return (
    <TabsContainer>
      <Tabs
        tabs={tabDefinitions}
        handlePressTab={({status}) => {
          form.setFieldValue('filteredProjectsForm.status', status);

          // Reset sales status filters since they are dependent on the selected status
          const defaultSalesStatuses = FilteredProjectsForm.getAvailableSalesStatuses({
            status,
            isEnabledProjectHoldStatus,
            isEnabledSalesStatusDepositReceived,
          });
          form.setFieldValue('filteredProjectsForm.salesStatuses', defaultSalesStatuses);
        }}
        activeTabIndex={activeTabIndex}
        TabComponent={Tabs.SecondaryTab}
      />
    </TabsContainer>
  );
};

const ViewJobRequestsButton = ({navigator, jobRequestsCount}) => {
  return (
    <SecondaryButton text={'View Requests'} onPress={() => navigator.push('/jobs/requests/new')}>
      {jobRequestsCount > 0 && (
        <Notification>
          <NotificationText>{jobRequestsCount}</NotificationText>
        </Notification>
      )}
    </SecondaryButton>
  );
};

const ProjectsFilterButton = ({form, organization}) => {
  const listProjectsPageFiltersPopover = usePopover({name: 'List Projects Page Filters Popover'});
  // Determine number of filters applied
  const numberOfFiltersApplied = FilteredProjectsForm.getNumberOfFiltersApplied({
    form,
    isPrimary: organization.isPrimary,
  });

  return (
    <React.Fragment>
      <Popover.Content innerRef={listProjectsPageFiltersPopover.ref}>
        <SecondaryButton
          text={numberOfFiltersApplied === 0 ? 'Filter' : `Filter (${numberOfFiltersApplied})`}
          iconLeft={Icon.Filter}
          onPress={listProjectsPageFiltersPopover.handleToggle}
        />
      </Popover.Content>
      <ListProjectsPageFiltersPopover
        organization={organization}
        form={form}
        popover={listProjectsPageFiltersPopover}
      />
    </React.Fragment>
  );
};

const ListProjectsPageFilters = ({
  form,
  organization,
  jobRequestsCount,
  filteredProjectCountsByStatus,
}) => {
  const {navigator, params} = useNavigationDOM();

  const handleChangeSearch = useDebouncedCallback(
    (input) => form.setFieldValue('filteredProjectsForm.searchQuery', input),
    250,
    {leading: true},
  );

  const {isEnabledProjectHoldStatus, isEnabledSalesStatusDepositReceived} = organization.features;

  return (
    <HeaderContainer>
      <FiltersContainers>
        <LeftContainer>
          <SearchBar
            onChangeText={handleChangeSearch}
            placeholder='Search by customer name or project details'
            style={{width: '348px'}}
            defaultValue={_.get(form.values, 'filteredProjectsForm.searchQuery')}
            isClearable
          />
          <Space width={12} />
          <ProjectsFilterButton organization={organization} form={form} />
        </LeftContainer>
        <RightContainer>
          <ViewJobRequestsButton navigator={navigator} jobRequestsCount={jobRequestsCount} />
        </RightContainer>
      </FiltersContainers>
      <Space height={16} />
      <TabHeader
        isEnabledProjectHoldStatus={isEnabledProjectHoldStatus}
        isEnabledSalesStatusDepositReceived={isEnabledSalesStatusDepositReceived}
        activeTabKey={params.status}
        filteredProjectCountsByStatus={filteredProjectCountsByStatus}
        form={form}
      />
    </HeaderContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListProjectsPageFilters.fragment = gql`
  ${ListProjectsPageFiltersPopover.fragment}

  fragment ListProjectsPageFilters on Organization {
    id
    isPrimary
    features {
      isEnabledProjectHoldStatus: isEnabled(feature: "PROJECT_HOLD_STATUS")
      isEnabledSalesStatusDepositReceived: isEnabled(feature: "SALES_STATUS_DEPOSIT_RECEIVED")
    }
    ...ListProjectsPageFiltersPopover
  }
`;

ListProjectsPageFilters.countsFragment = gql`
  fragment ListProjectsPageFilters_countsFragment on ProjectCountsByStatus {
    leadsCount
    holdsCount
    bookedCount
    completedCount
    cancelledCount
  }
`;

export default ListProjectsPageFilters;
