/**
 * Component - v2.1.0
 */

// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const TableV1 = Styled.View`
`;

// --------------------------------------------------
// TableV1.Row
// --------------------------------------------------
const TableRow = Styled.View`
  flex-direction: row;
  backgroundColor: ${(props: any) => props.backgroundColor};
`;

TableRow.propTypes = {
  backgroundColor: PropTypes.string,
};

TableRow.defaultProps = {
  backgroundColor: colors.white,
};

// --------------------------------------------------
// TableV1.HeaderItem
// --------------------------------------------------
const HeaderItem = Styled.View`
  width: ${(props: any) => props.width};
  padding-horizontal: 10px;
  padding-vertical: 5px;
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const HeaderText = Styled.Text`
  ${Typography.Label2}
`;

const TableHeaderItem = ({width, text}: any) => {
  return (
    <HeaderItem width={width}>
      <HeaderText>{text}</HeaderText>
    </HeaderItem>
  );
};

// --------------------------------------------------
// TableV1.Item
// --------------------------------------------------
const Item = Styled.View`
  justify-content: center;
  width: ${(props: any) => props.width};
  padding-horizontal: 10px;
  padding-vertical: 5px;
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const TableItem = ({width, text, children}: any) => {
  return (
    <Item width={width}>
      {!!text && <Text>{text}</Text>}
      {children}
    </Item>
  );
};

TableV1.HeaderItem = TableHeaderItem;
TableV1.Item = TableItem;
TableV1.Row = TableRow;
TableV1.Text = Text;

// --------------------------------------------------
// Props
// --------------------------------------------------
TableV1.propTypes = {};

TableV1.defaultProps = {};

export default TableV1;
