// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SettingsForm from '@shared/modules/Settings/forms/SettingsForm';
import useToggleSettingsIsStorageEnabledMutation from '@shared/modules/Settings/hooks/useToggleSettingsIsStorageEnabledMutation';
import SuperAdminSettingsPage from 'modules/Organization/Settings/SuperAdmin/components/SuperAdminSettingsPage';
import SuperAdminSettingsSection from 'modules/Organization/Settings/SuperAdmin/components/SuperAdminSettingsSection';
import SuperAdminSettingsSectionOption from 'modules/Organization/Settings/SuperAdmin/components/SuperAdminSettingsSectionOption';

const SuperAdminStorageSettingsPageContent = ({settings, viewer}) => {
  const {navigator} = useNavigationDOM();
  const settingsForm = SettingsForm.edit(settings, {updatedById: viewer.id});
  const {handleSubmit} = useToggleSettingsIsStorageEnabledMutation({
    settingsForm,
    onSuccess: () => {},
    onError: (errors) => console.log({errors}),
  });
  return (
    <SuperAdminSettingsSection>
      <SuperAdminSettingsSectionOption
        name={'Storage'}
        description={'Allows full access to the Storage module.'}
        isEnabled={settings.isStorageEnabled}
        onPress={handleSubmit}
        ActionButtonComponent={SecondaryButton}
        actionButtonComponentProps={{
          text: 'Configure Storage',
          iconLeft: Icon.ArrowUpRightFromSquare,
          iconSize: Icon.Sizes.Medium,
          onPress: () => navigator.pushNewTab('/settings/storage'),
          isSmall: true,
        }}
      />
    </SuperAdminSettingsSection>
  );
};
const SuperAdminStorageSettingsPage = () => {
  const {loading, data} = useQuery(SuperAdminStorageSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <SuperAdminSettingsPage
      title={'Storage'}
      description={
        'Configure Storage and any integrated features. These changes will apply to the company, and if applicable, its branches.'
      }
      isLoading={loading}
      viewer={data?.viewer}
    >
      <SuperAdminStorageSettingsPageContent
        settings={data?.viewer.viewingOrganization.settings}
        viewer={data?.viewer}
      />
    </SuperAdminSettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SuperAdminStorageSettingsPage.query = gql`
  ${SettingsForm.edit.fragment}
  ${SuperAdminSettingsPage.fragment}

  query SuperAdminStorageSettingsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        settings {
          id
          isStorageEnabled
          ...SettingsForm_edit
        }
      }
      ...SuperAdminSettingsPage
    }
  }
`;

export default SuperAdminStorageSettingsPage;
