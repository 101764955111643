// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';

// App
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import FieldInput from '@shared/design/components/Field/FieldInput';
import Table from '@shared/design/components/Table';
import PasscodeCell from 'modules/Organization/Settings/Company/components/PasscodeCell';

const sortBySlug = (a, b) => a.slug.localeCompare(b.slug);

// --------------------------------------------------
// Column Definitions
// --------------------------------------------------

const nameCellContent = ({item: organization}) => {
  return <Table.CellText>{organization.name}</Table.CellText>;
};

const loginCellContent = ({item: organization}) => {
  return <Table.CellText>{organization.slug}</Table.CellText>;
};

const getColumnDefinitions = ({form, isEdit}) => {
  return [
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText>Name</Table.HeaderText>;
      },
      viewCellContent: nameCellContent,
      editCellContent: nameCellContent,
    },
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText>Tablet Login</Table.HeaderText>;
      },
      viewCellContent: loginCellContent,
      editCellContent: loginCellContent,
    },
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText isRequired={isEdit}>Passcode</Table.HeaderText>;
      },
      viewCellContent: ({item: organization}) => {
        return <PasscodeCell passcode={organization.pin} />;
      },
      editCellContent: ({item: organizationTabletSettingsForm, rowIndex}) => {
        return (
          <FieldInput
            {...form}
            value={organizationTabletSettingsForm.pin}
            name={`companyTabletSettingsForm.organizationTabletSettingsForms.${rowIndex}.pin`}
            showErrorMessage={false}
            input={{
              placeholder: `Enter a passcode`,
            }}
          />
        );
      },
    },
  ];
};

const getViewColumnDefinitions = () => {
  const columnDefinitions = getColumnDefinitions({form: null, isEdit: false});
  return columnDefinitions.map((columnDefinition) => ({
    ...columnDefinition,
    cellContent: columnDefinition.viewCellContent,
  }));
};

const getEditColumnDefinitions = ({form}) => {
  const columnDefinitions = getColumnDefinitions({form, isEdit: true});
  return columnDefinitions.map((columnDefinition) => ({
    ...columnDefinition,
    cellContent: columnDefinition.editCellContent,
  }));
};

// --------------------------------------------------
// TabletLoginsPanel
// --------------------------------------------------

const TabletLoginsPanel = ({organization}) => {
  const organizations = organization.isPrimary
    ? organization.company.organizations
    : [organization];

  return (
    <Table
      items={organizations.sort(sortBySlug)}
      itemKey={'id'}
      columnDefinitions={getViewColumnDefinitions()}
      isDense
    />
  );
};

// --------------------------------------------------
// TabletLoginsPanelEdit
// --------------------------------------------------

const hasPasscodeError = ({form}) => {
  return (
    !_.isEmpty(form.errors) &&
    form.errors.companyTabletSettingsForm.organizationTabletSettingsForms.some(
      (organizationTabletSettingsForm) =>
        organizationTabletSettingsForm &&
        // It seems Object.hasOwn() has to be used here instead of Object.hasOwnProperty() since
        // using the latter was returning false. One difference between Object.hasOwn() and
        // Object.hasOwnProperty() is that Object.hasOwn() works for objects created using
        // Object.create(null) (source: Mozilla). The error object in the form object is constructed
        // in setFormServerErrors() using lodash, which presumably uses Object.create(null) to
        // create the object.
        Object.hasOwn(organizationTabletSettingsForm, 'pin'),
    )
  );
};

const TabletLoginsPanelEdit = ({form}) => {
  const {organizationTabletSettingsForms} = form.values.companyTabletSettingsForm;

  return (
    <React.Fragment>
      {hasPasscodeError({form}) && (
        <React.Fragment>
          <ErrorCallout text={'Passcodes must be at least 4 characters long.'} />
          <Space height={16} />
        </React.Fragment>
      )}
      <Table
        items={organizationTabletSettingsForms.sort(sortBySlug)}
        itemKey={'organizationId'}
        columnDefinitions={getEditColumnDefinitions({form})}
      />
    </React.Fragment>
  );
};

TabletLoginsPanel.Edit = TabletLoginsPanelEdit;

export default TabletLoginsPanel;
