import {ExecutionResult, MutationResult, MutationFunctionOptions} from 'react-apollo';

// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {Form, MutationResponse} from '@supermove/hooks/src/forms/types';

import BeginPaymentV3Form, {
  New as BeginPaymentV3NewForm,
  FormikForm as FormBeginPaymentAppForm,
  GraphQLForm as BeginPaymentV3GraphQLForm,
} from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import PayengineCreditCardForm, {
  PayengineCreditCardFormInterface,
} from '@shared/modules/Payment/forms/PayengineCreditCardForm';
import {CreateCardResponse} from '@shared/modules/Payment/types';

export type ChargePayEngineCreditCardForm = {
  form: {
    beginPaymentV3Form?: BeginPaymentV3GraphQLForm;
    jobId?: number;
    createCardResponse?: CreateCardResponse;
    saveToClientId?: number;
  };
};

export type ChargePayEngineCreditCardResponse = {
  payment: {
    id: number;
  };
  savedCardId?: number;
};
export type GraphQLResponse = MutationResponse<ChargePayEngineCreditCardResponse>;

type Args = {
  beginPaymentV3Form: BeginPaymentV3NewForm;
  jobId?: number;
  createCardResponse?: CreateCardResponse;
  saveToClientId?: number;
};

// TODO(dan) Fix up type for form
type Result = {
  form: Form<any>;
  handleSubmit: Function;
  submitting: boolean;
};

const useChargePayEngineCreditCardMutationV2 = ({
  beginPaymentV3Form,
  jobId,
  createCardResponse,
  saveToClientId,
}: Args): Result => {
  const form = useForm({
    initialValues: {
      chargePayengineCreditCardForm: {
        payengineCreditCardForm: PayengineCreditCardForm.toForm(PayengineCreditCardForm.new()),
        beginPaymentV3Form: BeginPaymentV3Form.toForm(beginPaymentV3Form),
        saveToClientId,
      },
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useChargePayEngineCreditCardMutationV2.mutation,
    variables: {
      chargePayengineCreditCardForm: {
        beginPaymentV3Form: BeginPaymentV3Form.toMutation(
          form.values.chargePayengineCreditCardForm.beginPaymentV3Form,
        ),
        payengineCreditCardForm: PayengineCreditCardForm.toMutation(
          form.values.chargePayengineCreditCardForm.payengineCreditCardForm,
        ),
        saveToClientId: form.values.chargePayengineCreditCardForm.saveToClientId,
        jobId,
        createCardResponse,
      },
    },
    onSuccess: () => {},
    onError: () => {},
  });

  return {
    form,
    handleSubmit,
    submitting,
  };
};

useChargePayEngineCreditCardMutationV2.mutation = gql`
  mutation ChargePayEngineCreditCardMutation(
    $chargePayengineCreditCardForm: ChargePayEngineCreditCardForm!
  ) {
    response: chargePayEngineCreditCard(
      form: $chargePayengineCreditCardForm,
    ) {
      ${gql.errors}
      payment {
        id
      }
      savedCardId
    }
  }
`;

export default useChargePayEngineCreditCardMutationV2;
