const _new = ({moverPositionId}) => ({
  moverPositionId,
});

const toForm = ({moverPositionId}) => ({
  moverPositionId,
});

const toMutation = ({moverPositionId}) => ({
  moverPositionId,
});

const DeleteMoverPositionForm = {
  new: _new,
  toForm,
  toMutation,
};

export default DeleteMoverPositionForm;
