// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (jobTypeStep) => ({
    jobTypeStepId: jobTypeStep.id,
    jobTypeId: jobTypeStep.jobTypeId,
    organizationId: jobTypeStep.organizationId,
    updatedById: jobTypeStep.updatedById,
    createdById: jobTypeStep.createdById,
    kind: jobTypeStep.kind,
    documentTemplateKinds: jobTypeStep.documentTemplateKinds,
    documentTemplateOptions: jobTypeStep.documentTemplateOptions,
    isEnabled: jobTypeStep.isEnabled,
    canHaveDocuments: jobTypeStep.canHaveDocuments,
  }),
  gql`
    fragment JobTypeStepForm_edit on JobTypeStep {
      id
      organizationId
      jobTypeId
      updatedById
      createdById
      kind
      isEnabled
      documentTemplateKinds
      documentTemplateOptions
      canHaveDocuments
    }
  `,
);

const toForm = ({
  jobTypeStepId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled,
  documentTemplateKinds,
  documentTemplateOptions,
  canHaveDocuments,
}) => ({
  jobTypeStepId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled,
  documentTemplateKinds,
  documentTemplateOptions,
  canHaveDocuments,
});

const toMutation = ({
  jobTypeStepId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled,
  documentTemplateKinds,
  canHaveDocuments,
}) => ({
  jobTypeStepId,
  jobTypeId,
  organizationId,
  updatedById,
  createdById,
  kind,
  isEnabled: canHaveDocuments && !_.isEmpty(documentTemplateKinds) ? true : isEnabled,
  documentTemplateKinds,
});

const JobTypeStepForm = {
  edit,
  toForm,
  toMutation,
};

export default JobTypeStepForm;
