// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SettingsForm from '@shared/modules/Settings/forms/SettingsForm';

const useToggleSettingsIsStripePayengineGatewayEnabledMutation = ({
  settingsForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      settingsForm: SettingsForm.toForm(settingsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useToggleSettingsIsStripePayengineGatewayEnabledMutation.mutation,
    variables: {
      settingsForm: SettingsForm.toMutation(form.values.settingsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useToggleSettingsIsStripePayengineGatewayEnabledMutation.mutation = gql`
  mutation useToggleSettingsIsStripePayengineGatewayEnabledMutation($settingsForm: SettingsForm!) {
    response: toggleSettingsIsStripePayengineGatewayEnabled(settingsForm: $settingsForm) {
      ${gql.errors}
      settings {
        id
        isStripePayengineGatewayEnabled
      }
    }
  }
`;

export default useToggleSettingsIsStripePayengineGatewayEnabledMutation;
