/**
 * Component - v2.0.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, DropdownInput, PercentInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight, Typography} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemUnit from '@shared/modules/Billing/enums/BillItemUnit';
import BillStage from '@shared/modules/Billing/enums/BillStage';
import BillItemTypeForm from '@shared/modules/Billing/forms/BillItemTypeForm';
import useCreateBillItemTypeMutation from '@shared/modules/Billing/hooks/useCreateBillItemTypeMutation';
import Field from 'modules/App/components/Field';

const Row = Styled.View`
  flex-direction: row;
`;

const TextInput = Styled.TextInput`
  ${Typography.Label2};
  ${fontWeight(300)}
`;

const Label = Styled.Text`
  ${Typography.Body3}
`;

const LeftToggleButton = Styled.ButtonV2`
  width: 176px;
  align-items: center;
  padding-vertical: 12px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-width: 1px;
  border-right-width: 0px;
  border-color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.gray.tertiary)};
  background-color: ${({isSelected, disabled}) =>
    isSelected ? colors.blue.accent : disabled ? colors.gray.border : colors.white};
`;

const RightToggleButton = Styled.ButtonV2`
  width: 176px;
  align-items: center;
  padding-vertical: 12px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-width: 1px;
  border-left-width: 0px;
  border-color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.gray.tertiary)};
  background-color: ${({isSelected, disabled}) =>
    isSelected ? colors.blue.accent : disabled ? colors.gray.border : colors.white};
`;

const ToggleDivider = Styled.View`
  border-right-width: 1px;
  border-color: ${colors.blue.interactive};
`;

const ToggleButtonText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const getHeaderText = (category) => {
  if (category === BillItemTypeCategory.SUPPLIES) {
    return 'Add Supply';
  }
  if (category === BillItemTypeCategory.FEES) {
    return 'Add Fee';
  }
  return 'Add Discount';
};

const getUnitOptions = (category) => {
  const unitOptions = [
    {label: '$', value: BillItemUnit.DOLLAR},
    {label: '%', value: BillItemUnit.PERCENT},
  ];
  if (category === BillItemTypeCategory.FEES) {
    unitOptions.push({label: '/ hour', value: BillItemUnit.HOUR});
  }
  return unitOptions;
};

const handleChangeUnit = ({unit, form}) => {
  if (unit === BillItemUnit.PERCENT) {
    form.setFieldValue('billItemTypeForm.billStage', BillStage.POST_SUBTOTAL);
    form.setFieldValue('billItemTypeForm.amount', '');
  } else if (unit === BillItemUnit.HOUR) {
    form.setFieldValue('billItemTypeForm.billStage', BillStage.PRE_SUBTOTAL);
    form.setFieldValue('billItemTypeForm.percentage', '');
  } else {
    form.setFieldValue('billItemTypeForm.percentage', '');
  }
};

const PriceField = ({form}) => {
  return (
    <Field
      {...form}
      label={'Price'}
      name={'billItemTypeForm.amount'}
      component={CurrencyInput}
      input={{
        required: !_.get(form.values, 'billItemTypeForm.amount'),
        placeholder: '$100',
        component: TextInput,
        setFieldTouched: form.setFieldTouched,
        setFieldValue: form.setFieldValue,
      }}
    />
  );
};

const ValueField = ({form, category}) => {
  const unit = _.get(form.values, 'billItemTypeForm.unit');
  const isUnitPercent = unit === BillItemUnit.PERCENT;
  return (
    <Row style={{zIndex: 100}}>
      {isUnitPercent ? (
        <Field
          {...form}
          label={'Value'}
          name={'billItemTypeForm.percentage'}
          component={PercentInput}
          input={{
            required: !_.get(form.values, 'billItemTypeForm.percentage'),
            placeholder: '50%',
            component: TextInput,
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
          }}
          style={{width: 228}}
        />
      ) : (
        <Field
          {...form}
          label={'Value'}
          name={'billItemTypeForm.amount'}
          component={CurrencyInput}
          input={{
            required: !_.get(form.values, 'billItemTypeForm.amount'),
            placeholder: '$100',
            component: TextInput,
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
          }}
          style={{width: 228}}
        />
      )}
      <Space width={8} />
      <Field
        {...form}
        label={'Unit'}
        name={'billItemTypeForm.unit'}
        component={DropdownInput}
        input={{
          options: getUnitOptions(category),
          onChangeValue: (value) => handleChangeUnit({unit: value, form}),
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          style: {width: 116},
        }}
      />
    </Row>
  );
};

const BillStageField = ({form}) => {
  const field = 'billItemTypeForm.billStage';
  const billStage = _.get(form.values, field);
  const isBefore = billStage === BillStage.PRE_SUBTOTAL;
  const unit = _.get(form.values, 'billItemTypeForm.unit');
  const isUnitPercent = unit === BillItemUnit.PERCENT;
  const isUnitHour = unit === BillItemUnit.HOUR;

  return (
    <React.Fragment>
      <Label>Before / After Subtotal</Label>
      <Space height={4} />
      <Row>
        <LeftToggleButton
          isSelected={isBefore}
          onPress={() => form.setFieldValue(field, BillStage.PRE_SUBTOTAL)}
          disabled={isUnitPercent}
        >
          <ToggleButtonText
            style={isBefore ? {fontWeight: '700', color: colors.blue.interactive} : null}
          >
            Before Subtotal
          </ToggleButtonText>
        </LeftToggleButton>
        <ToggleDivider />
        <RightToggleButton
          isSelected={!isBefore}
          onPress={() => form.setFieldValue(field, BillStage.POST_SUBTOTAL)}
          disabled={isUnitHour}
        >
          <ToggleButtonText
            style={!isBefore ? {fontWeight: '700', color: colors.blue.interactive} : null}
          >
            After Subtotal
          </ToggleButtonText>
        </RightToggleButton>
      </Row>
    </React.Fragment>
  );
};

const BillingLibraryAddBillItemModalV1 = ({
  billingLibrary,
  category,
  isOpen,
  handleClose,
  refetch,
  userId,
}) => {
  const billItemTypeForm = BillItemTypeForm.new({
    billingLibraryId: billingLibrary.id,
    organizationId: billingLibrary.organizationId,
    category,
    userId,
  });
  const {form, submitting, handleSubmit} = useCreateBillItemTypeMutation({
    billItemTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  const isCategorySupplies = category === BillItemTypeCategory.SUPPLIES;
  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose}>
      <SmallModal.HeaderText>{getHeaderText(category)}</SmallModal.HeaderText>
      <Space height={20} />
      <Field
        {...form}
        label={'Name'}
        name={'billItemTypeForm.name'}
        input={{
          required: !_.get(form.values, 'billItemTypeForm.name'),
          placeholder: 'Enter a name',
          autoFocus: true,
        }}
      />
      <Space height={20} />
      {isCategorySupplies ? (
        <PriceField form={form} />
      ) : (
        <React.Fragment>
          <ValueField form={form} category={category} />
          <Space height={20} />
          <BillStageField form={form} />
        </React.Fragment>
      )}
      <Space height={20} />
      <Field
        {...form}
        label={'Description (Optional)'}
        name={'billItemTypeForm.description'}
        input={{placeholder: 'Enter a description'}}
      />
      <Space height={36} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose} isDisabled={submitting}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          onPress={handleSubmit}
          color={colors.blue.interactive}
          isSubmitting={submitting}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryAddBillItemModalV1.fragment = gql`
  fragment BillingLibraryAddBillItemModalV1 on BillingLibrary {
    id
    organizationId
  }
`;

export default BillingLibraryAddBillItemModalV1;
