// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (jobTruck) => ({
    jobTruckId: jobTruck.id,
    jobId: jobTruck.jobId,
    truckId: jobTruck.truckId,
  }),
  gql`
    fragment JobTruckForm_edit on JobTruck {
      id
      jobId
      truckId
    }
  `,
);

const _new = ({jobId, truckId}) => ({
  jobTruckId: null,
  jobId,
  truckId,
});

const toForm = ({jobTruckId, jobId, truckId}) => ({
  jobTruckId,
  jobId,
  truckId,
});

const toMutation = ({jobTruckId, jobId, truckId}) => ({
  jobTruckId,
  jobId,
  truckId,
});

const JobTruckForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default JobTruckForm;
