// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import Modal from '@shared/design/components/Modal';
import StorageProjectForm from '@shared/modules/Project/Storage/forms/StorageProjectForm';
import useCreateStorageProjectMutation from '@shared/modules/Project/Storage/hooks/useCreateStorageProjectMutation';
import StorageProjectModalFields from 'modules/Job/V2/Storage/components/StorageProjectModalFields';

const Container = Styled.View`
  flex: 1;
`;

const CreateStorageProjectModalContent = ({
  handleClose,
  refetch,
  organization,
  isDisabledClientFields,
  createProjectSuccessModal,
  setNewUuid,
}) => {
  const storageProjectForm = StorageProjectForm.newForOrganization(organization);
  const {form, submitting, handleSubmit} = useCreateStorageProjectMutation({
    storageProjectForm,
    onSuccess: ({project}) => {
      // Create storage project without attached move project on Storage Projects List page
      setNewUuid(project.uuid);
      createProjectSuccessModal.handleOpen();
      handleClose();
      refetch();
    },
    // TODO(dan) Update error handling
    onError: (error) => console.log({error}),
  });

  return (
    <Container>
      <Modal.Header style={{paddingHorizontal: 24, paddingVertical: 16}}>
        <Modal.HeaderText>{'Create Storage Project'}</Modal.HeaderText>
      </Modal.Header>
      <Modal.Body isScrollable>
        <StorageProjectModalFields
          form={form}
          organization={organization}
          field={'storageProjectForm'}
          isDisabledClientFields={isDisabledClientFields}
          isDisabledInventoryInformation
        />
      </Modal.Body>
      <Modal.Footer style={{paddingHorizontal: 16, paddingVertical: 12}}>
        <Modal.Button onPress={handleClose}>{'Cancel'}</Modal.Button>
        <Space width={8} />
        <Modal.Button
          onPress={() => {
            if (_.get(form.values, `storageProjectForm.isSameBillingClient`)) {
              // Set billing client to same value as client
              form.setFieldValue(
                `storageProjectForm.billingClientForm`,
                _.get(form.values, `storageProjectForm.clientForm`),
              );
            }
            setTimeout(() => handleSubmit(), 0);
          }}
          isSubmitting={submitting}
          color={colors.blue.interactive}
        >
          {'Create'}
        </Modal.Button>
      </Modal.Footer>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateStorageProjectModalContent.fragment = gql`
  ${StorageProjectForm.newForOrganization.fragment}
  ${StorageProjectModalFields.organizationFragment}

  fragment CreateStorageProjectModalContent on Organization {
    id
    ...StorageProjectModalFields_organizationFragment
    ...StorageProjectForm_newForOrganization
  }
`;

export default CreateStorageProjectModalContent;
