// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {BillRule} from '@supermove/models';
import {Currency, withFragment} from '@supermove/utils';

// App
import BillRuleKind from '@shared/modules/Billing/enums/BillRuleKind';

const toFormValues = (kind, values) => {
  if (!Array.isArray(values) || !_.get(values, '0')) {
    return [''];
  }

  const valueOne = _.get(values, '0', null);
  switch (kind) {
    case BillRule.KINDS.BEFORE_JOB_MINIMUM_PAYMENT:
    case BillRule.KINDS.MINIMUM_DOLLAR_AMOUNT:
    case BillRule.KINDS.NOT_TO_EXCEED_PRICE:
      return [Currency.toForm(valueOne)];
    case BillRule.KINDS.CUSTOM:
    default:
      return [String(_.get(values, '0', ''))];
  }
};

const toMutationValues = (kind, values) => {
  const valueOne = _.get(values, '0', null);
  switch (kind) {
    case BillRule.KINDS.BEFORE_JOB_MINIMUM_PAYMENT:
    case BillRule.KINDS.MINIMUM_DOLLAR_AMOUNT:
    case BillRule.KINDS.NOT_TO_EXCEED_PRICE:
      return [Currency.toMutation(valueOne)];
    case BillRule.KINDS.CUSTOM:
    default:
      return values;
  }
};

const getDisplayValues = ({kind, values}) => {
  if (!Array.isArray(values)) {
    return '';
  }
  const valueOne = _.get(values, '0', null);
  switch (kind) {
    case BillRule.KINDS.BEFORE_JOB_MINIMUM_PAYMENT:
    case BillRule.KINDS.MINIMUM_DOLLAR_AMOUNT:
    case BillRule.KINDS.NOT_TO_EXCEED_PRICE:
    case BillRule.KINDS.CUSTOM:
      return valueOne;
    case BillRule.KINDS.ADDITIONAL_HOURS:
    case BillRule.KINDS.MINIMUM_HOURS:
      return valueOne ? `${valueOne} hours` : '';
    default:
      return '';
  }
};

const getValueType = ({kind}) => {
  switch (kind) {
    case BillRule.KINDS.BEFORE_JOB_MINIMUM_PAYMENT:
    case BillRule.KINDS.MINIMUM_DOLLAR_AMOUNT:
    case BillRule.KINDS.NOT_TO_EXCEED_PRICE:
      return BillRule.VALUE_TYPES.CURRENCY;
    case BillRule.KINDS.ADDITIONAL_HOURS:
    case BillRule.KINDS.MINIMUM_HOURS:
      return BillRule.VALUE_TYPES.HOURS;
    case BillRule.KINDS.CUSTOM:
    default:
      return BillRule.VALUE_TYPES.CUSTOM;
  }
};

const getFormValues = ({kind, values}) => {
  if (!Array.isArray(values)) {
    return [''];
  }

  const valueOne = _.get(values, '0', '');
  switch (kind) {
    case BillRule.KINDS.BEFORE_JOB_MINIMUM_PAYMENT:
    case BillRule.KINDS.MINIMUM_DOLLAR_AMOUNT:
    case BillRule.KINDS.NOT_TO_EXCEED_PRICE:
      return [Currency.toForm(Currency.convertToCents(valueOne))];
    case BillRule.KINDS.CUSTOM:
    default:
      return values;
  }
};

const validate = ({billRuleForm, formField}) => {
  const errors = {};
  if (!billRuleForm.kind) {
    _.set(errors, `${formField}.kind`, 'Please select a category');
  }
  if (!billRuleForm.name) {
    _.set(errors, `${formField}.name`, 'Please enter a name');
  }
  return errors;
};

const _new = ({kind}) => ({
  kind: kind || '',
  name: kind ? BillRule.getDefaultNameByKind(kind) : '',
  description: '',
  values: [''],
  isVisibleToCustomer: true,
  descriptionFormulaId: null,
  nameFormulaId: null,
  valueFormulaId: null,

  // Private
  isEditable: true,
});

const edit = withFragment(
  (billRule) => ({
    kind: billRule.kind,
    name: billRule.name,
    description: billRule.description,
    values: billRule.values,
    descriptionFormulaId: billRule.descriptionFormulaId,
    nameFormulaId: billRule.nameFormulaId,
    valueFormulaId: billRule.valueFormulaId,
    isVisibleToCustomer: billRule.isVisibleToCustomer,
  }),
  gql`
    fragment BillRuleForm_edit on BillRule {
      kind
      name
      description
      values
      descriptionFormulaId
      nameFormulaId
      valueFormulaId
      isVisibleToCustomer
    }
  `,
);

const getValuesFromBillRuleType = ({value, stringValue, kind}) => {
  if (kind === BillRuleKind.CUSTOM) {
    return stringValue ? [stringValue] : [''];
  }
  return value ? [value] : [''];
};

const editFromBillRuleType = withFragment(
  (billRuleType) => ({
    kind: billRuleType.kind,
    name: billRuleType.name,
    description: billRuleType.description,
    values: getValuesFromBillRuleType({
      value: billRuleType.value,
      stringValue: billRuleType.stringValue,
      kind: billRuleType.kind,
    }),
    descriptionFormulaId: billRuleType.descriptionFormulaId,
    nameFormulaId: billRuleType.nameFormulaId,
    valueFormulaId: billRuleType.valueFormulaId,
    isVisibleToCustomer: billRuleType.isVisibleToCustomer,
  }),
  gql`
    fragment BillRuleForm_editFromBillRuleType on BillRuleType {
      kind
      name
      description
      value
      stringValue
      descriptionFormulaId
      nameFormulaId
      valueFormulaId
      isVisibleToCustomer
    }
  `,
);

const toForm = ({
  kind,
  name,
  description,
  values,
  descriptionFormulaId,
  nameFormulaId,
  valueFormulaId,
  isVisibleToCustomer,
}) => ({
  kind,
  name,
  description,
  values: toFormValues(kind, values),
  descriptionFormulaId,
  nameFormulaId,
  valueFormulaId,
  isVisibleToCustomer,

  // Private
  isEditable: false,
});

const toMutation = ({
  kind,
  name,
  description,
  values,
  descriptionFormulaId,
  nameFormulaId,
  valueFormulaId,
  isVisibleToCustomer,
}) => ({
  kind,
  name,
  description,
  values: toMutationValues(kind, values),
  descriptionFormulaId,
  nameFormulaId,
  valueFormulaId,
  isVisibleToCustomer,
});

const BillRuleForm = {
  getDisplayValues,
  getFormValues,
  getValueType,
  validate,

  new: _new,
  edit,
  editFromBillRuleType,

  toForm,
  toMutation,
};

export default BillRuleForm;
