// Libraries
import _ from 'lodash';

// App
import RecurringTaskCadenceRepeatsOnKind from '@shared/modules/Accounting/enums/RecurringTaskCadenceRepeatsOnKind';

const CADENCE_REPEATS_ON_WEEKDAY_MAP = {
  MONDAY: {value: 0, label: 'Monday'},
  TUESDAY: {value: 1, label: 'Tuesday'},
  WEDNESDAY: {value: 2, label: 'Wednesday'},
  THURSDAY: {value: 3, label: 'Thursday'},
  FRIDAY: {value: 4, label: 'Friday'},
  SATURDAY: {value: 5, label: 'Saturday'},
  SUNDAY: {value: 6, label: 'Sunday'},
};

const getCadenceRepeatsOnOptions = (cadenceRepeatsOnKind) => {
  switch (cadenceRepeatsOnKind) {
    case RecurringTaskCadenceRepeatsOnKind.WEEKDAY:
      return _.map(CADENCE_REPEATS_ON_WEEKDAY_MAP);
    case RecurringTaskCadenceRepeatsOnKind.EXACT_DATE:
      return [
        {
          label: 'Every 1st of the month',
          value: 1,
        },
      ];
    case RecurringTaskCadenceRepeatsOnKind.FIRST_AND_FIFTEENTH:
      return [
        {
          label: 'Every 1st and 15th',
          value: null,
        },
      ];
    default:
      return [];
  }
};

export default {
  CADENCE_REPEATS_ON_WEEKDAY_MAP,

  getCadenceRepeatsOnOptions,
};
