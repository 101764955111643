// Supermove
import {gql} from '@supermove/graphql';
import {Float, withFragment} from '@supermove/utils';

const edit = withFragment(
  (room) => ({
    roomId: room.id,
    inventoryId: room.inventoryId,
    roomTypeId: room.roomTypeId,
    name: room.name,
    description: room.description,
    floorNumber: room.floorNumber,
  }),
  gql`
    fragment RoomForm_edit on Room {
      id
      inventoryId
      roomTypeId
      name
      description
      floorNumber
    }
  `,
);

const _new = ({inventoryId}) => ({
  roomId: undefined,
  inventoryId,
  roomTypeId: undefined,
  name: '',
  description: '',
  floorNumber: null,
});

const toForm = ({roomId, inventoryId, roomTypeId, name, description, floorNumber}) => ({
  roomId,
  inventoryId,
  roomTypeId,
  name,
  description,
  floorNumber: Float.toForm(floorNumber),
});

const toMutation = ({roomId, inventoryId, roomTypeId, name, description, floorNumber}) => ({
  roomId,
  inventoryId,
  roomTypeId,
  name,
  description,
  floorNumber: Float.toMutation(floorNumber),
});

const RoomForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default RoomForm;
