// Libraries
import _ from 'lodash';
import React from 'react';
import sectionListGetItemLayout from 'react-native-section-list-get-item-layout';

// Supermove
import {Styled, SectionList} from '@supermove/components';
import {useCallback, useState, useEffect, useRef, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from 'modules/App/components/TextTooltip';

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const SectionHeader = Styled.View`
  padding-horizontal: 16px;
  background-color: ${colors.gray.background};
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  justify-content: center;
`;

const SectionHeaderText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
  color: ${colors.gray.primary};
`;

const AlphabetScrubber = Styled.View`
  height: 100%;
  background-color: ${colors.white};
  width: 32px;
  border-color: ${colors.gray.border};
  border-left-width: 1px;
  cursor: pointer;
`;

const AlphabetScrubberItemContainer = Styled.View`
  align-items: center;
  justify-content: center;
`;

const AlphabetScrubberItemText = Styled.Text`
  ${Typography.Responsive.Micro}
  user-select: none;
`;

// Sorts all items by name into groups using the first letter.
// If the first letter is not a letter, it is grouped into the '*' section.
// Empty sections are removed.
// Example output: {*: [item], a: [item1, item2], b: [item3], d: [item4], e: [], ...}
const getAlphabetizedItems = ({scrubberKeys, items}) => {
  const sortedItems = _.sortBy(items, (item) => item.name.toLowerCase());
  const alphabetObject = _.zipObject(scrubberKeys, Array(scrubberKeys.length).fill([]));
  sortedItems.forEach((item) => {
    const firstChar = item.name.charAt(0).toLowerCase();
    const targetKey = alphabetObject[firstChar] ? firstChar : '*';
    alphabetObject[targetKey] = [...alphabetObject[targetKey], item];
  });
  return _.pickBy(alphabetObject, (value) => !_.isEmpty(value));
};

// Calculates the index of the item based on the tap position and the scrubber height.
// Since all scrubber keys are the same height, the percentage of the tap position is used to calculate the index.
const getItemIndex = ({tapPosition, scrubberHeight, itemCount}) => {
  const percentage = (tapPosition / scrubberHeight) * 100;
  const itemPercentageRange = 100 / itemCount;
  const index = Math.floor(percentage / itemPercentageRange);
  return Math.min(index, itemCount - 1);
};

const handleScrollToSection = ({
  position,
  scrubberTop,
  scrubberHeight,
  scrubberKeys,
  sectionKeys,
  setSelectedKey,
  selectedKey,
  sectionListRef,
}) => {
  const targetNumber = position - scrubberTop;
  const index = getItemIndex({
    tapPosition: targetNumber,
    scrubberHeight,
    itemCount: scrubberKeys.length,
  });
  const scrubberKey = scrubberKeys[index];
  if (_.includes(sectionKeys, scrubberKey) && scrubberKey !== selectedKey) {
    setSelectedKey(scrubberKey);
    if (sectionListRef?.current) {
      sectionListRef.current.scrollToLocation({
        itemIndex: -1,
        sectionIndex: _.findIndex(sectionKeys, (key) => key === scrubberKey),
        animated: false,
      });
    }
  }
};

const AlphabetScrubberItem = ({sectionKey, isDisabled, isSelected, style}) => {
  const displayKey = sectionKey.toUpperCase();
  return (
    <AlphabetScrubberItemContainer key={sectionKey} style={style}>
      {/* isVisible is set manually based on if the item is "selected" since there isn't a hover on these items */}
      <TextTooltip text={displayKey} placement={'right'} visible={isSelected}>
        <AlphabetScrubberItemText
          style={{
            color: isDisabled
              ? colors.gray.disabled
              : isSelected
                ? colors.blue.interactive
                : colors.gray.primary,
          }}
        >
          {displayKey}
        </AlphabetScrubberItemText>
      </TextTooltip>
    </AlphabetScrubberItemContainer>
  );
};

const AlphabetSectionList = React.memo(
  ({items, selectedRoomIndex, ItemComponent, itemComponentProps, itemHeight}) => {
    const responsive = useResponsive();
    const sectionListRef = useRef(null);
    const alphabetScrubberRef = useRef(null);
    const [scrubberTop, setScrubberTop] = useState(0);
    const [scrubberHeight, setScrubberHeight] = useState(0);
    const [selectedKey, setSelectedKey] = useState('');

    const scrubberKeys = ['*', ...Array.from({length: 26}, (_, i) => String.fromCharCode(97 + i))];
    const alphabetizedItems = getAlphabetizedItems({scrubberKeys, items});
    const sectionKeys = Object.keys(alphabetizedItems);
    const scrubberPadding = 8;
    const scrubberSectionPercentHeight = (1 / scrubberKeys.length) * 100;
    const sectionHeaderHeight = 24;

    // When the selected room index changes, we scroll to the top of the section list.
    useEffect(() => {
      if (sectionListRef?.current && !_.isEmpty(sectionListRef.current.props.sections)) {
        sectionListRef.current.scrollToLocation({
          itemIndex: -1,
          sectionIndex: 0,
          animated: false,
        });
      }
    }, [selectedRoomIndex]);

    // When the ref is available, we measure the height of the element immediately so other calculations can be made.
    useEffect(() => {
      if (alphabetScrubberRef.current) {
        alphabetScrubberRef.current.measure((fx, fy, width, height, px, py) => {
          setScrubberHeight(height);
        });
      }
    }, [alphabetScrubberRef]);

    // On any layout change, we need to recalculate the scrubber height and top position.
    // Since the layout is slower than the ref useEffect, we run these separately.
    const handleScrubberLayout = useCallback(
      ({nativeEvent}) => {
        setScrubberHeight(nativeEvent.layout.height);
        if (alphabetScrubberRef.current) {
          alphabetScrubberRef.current.measure((fx, fy, width, height, px, py) => {
            setScrubberTop(py);
          });
        }
      },
      [setScrubberTop, setScrubberHeight, alphabetScrubberRef],
    );

    return (
      <Container>
        <SectionList
          ref={sectionListRef}
          sections={sectionKeys.map((sectionKey) => ({
            sectionKey,
            data: alphabetizedItems[sectionKey],
          }))}
          keyExtractor={(item) => item.id}
          renderSectionHeader={({section}) => {
            const {sectionKey} = section;
            return (
              <SectionHeader key={sectionKey} style={{height: sectionHeaderHeight}}>
                <SectionHeaderText responsive={responsive}>
                  {sectionKey.toUpperCase()}
                </SectionHeaderText>
              </SectionHeader>
            );
          }}
          renderItem={({item}) => <ItemComponent item={item} {...itemComponentProps} />}
          getItemLayout={sectionListGetItemLayout({
            getItemHeight: () => itemHeight,
            getSectionHeaderHeight: () => sectionHeaderHeight,
          })}
        />
        <AlphabetScrubber
          ref={alphabetScrubberRef}
          onLayout={handleScrubberLayout}
          // The responder event handlers must return true for the event to be passed properly.
          onStartShouldSetResponder={(e) => {
            handleScrollToSection({
              position: e.nativeEvent.pageY,
              scrubberTop,
              scrubberHeight,
              sectionKeys,
              scrubberKeys,
              setSelectedKey,
              selectedKey,
              sectionListRef,
            });
            return true;
          }}
          onResponderMove={(e) => {
            handleScrollToSection({
              position: e.nativeEvent.pageY,
              scrubberTop,
              scrubberHeight,
              sectionKeys,
              scrubberKeys,
              setSelectedKey,
              selectedKey,
              sectionListRef,
            });
            return true;
          }}
          onResponderRelease={(e) => {
            handleScrollToSection({
              position: e.nativeEvent.pageY,
              scrubberTop,
              scrubberHeight,
              sectionKeys,
              scrubberKeys,
              setSelectedKey,
              selectedKey,
              sectionListRef,
            });
            // Ensure the selected key is cleared when the touch event is released.
            setSelectedKey('');
            return true;
          }}
          style={{paddingVertical: scrubberPadding}}
        >
          {scrubberKeys.map((scrubberKey) => (
            <AlphabetScrubberItem
              key={scrubberKey}
              sectionKey={scrubberKey}
              isDisabled={!_.includes(sectionKeys, scrubberKey)}
              isSelected={selectedKey === scrubberKey}
              style={{height: `${scrubberSectionPercentHeight}%`}}
            />
          ))}
        </AlphabetScrubber>
      </Container>
    );
  },
  // itemComponentProps is not included in the memo comparison since we're memoizing ItemComponent separately
  (prevProps, nextProps) =>
    prevProps.items === nextProps.items &&
    prevProps.selectedRoomIndex === nextProps.selectedRoomIndex &&
    prevProps.itemHeight === nextProps.itemHeight &&
    prevProps.ItemComponent === nextProps.ItemComponent,
);

AlphabetSectionList.SectionHeader = SectionHeader;
AlphabetSectionList.SectionHeaderText = SectionHeaderText;

export default AlphabetSectionList;
