// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Space, Styled} from '@supermove/components';
import {useResponsive, useState} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const LoadingField = ({isLoading, label, children}) => {
  const responsive = useResponsive();
  return isLoading ? (
    <Container>
      <FieldInput.LabelText isResponsive isRequired>
        {label}
      </FieldInput.LabelText>
      <Space height={responsive.desktop ? 6 : 8} />
      <SkeletonLoader isFullWidth height={responsive.desktop ? 36 : 48} />
    </Container>
  ) : (
    children
  );
};

const EditBillItemFields = ({previewBillItemMutation, form, field}) => {
  const billItemForm = _.get(form.values, field);
  const [isMaxQuantityFieldVisible, setIsMaxQuantityFieldVisible] = useState(
    !!billItemForm.maxQuantity,
  );
  const {hasNameFormula, hasQuantityFormula, hasAmountFormula} =
    BillItemForm.getHasFormulas(billItemForm);
  return (
    <React.Fragment>
      <LoadingField isLoading={previewBillItemMutation.submitting && hasNameFormula} label={'Name'}>
        <FieldInput
          {...form}
          label={'Name'}
          name={`${field}.name`}
          isRequired
          isResponsive
          input={{
            placeholder: 'Enter name',
            disabled: hasNameFormula,
          }}
        />
      </LoadingField>
      <Space height={16} />
      <LoadingField
        isLoading={previewBillItemMutation.submitting && hasQuantityFormula}
        label={'Quantity'}
      >
        <Row style={{flex: 1}}>
          <FieldInput
            {...form}
            label={'Quantity'}
            name={`${field}.minQuantity`}
            isResponsive
            handleBlur={() => BillItemForm.handleQuantityOnBlur({form, field, billItemForm})}
            input={{
              placeholder: 'Enter quantity',
              disabled: hasQuantityFormula,
            }}
            style={{flex: 1}}
            actionText={isMaxQuantityFieldVisible ? null : 'Add Max Quantity'}
            handleAction={() => setIsMaxQuantityFieldVisible(true)}
          />
          {isMaxQuantityFieldVisible && (
            <Row>
              <Space width={16} />
              <FieldInput
                {...form}
                label={' '}
                isResponsive
                name={`${field}.maxQuantity`}
                handleBlur={() => BillItemForm.handleQuantityOnBlur({form, field, billItemForm})}
                input={{
                  placeholder: 'Enter max quantity',
                  disabled: hasQuantityFormula,
                }}
                style={{flex: 1}}
                actionText={isMaxQuantityFieldVisible ? 'Hide Max Quantity' : null}
                handleAction={() => setIsMaxQuantityFieldVisible(false)}
              />
            </Row>
          )}
        </Row>
      </LoadingField>
      <Space height={16} />
      <LoadingField
        isLoading={previewBillItemMutation.submitting && hasAmountFormula}
        label={'Amount'}
      >
        <FieldInput
          {...form}
          label={'Amount'}
          name={`${field}.amount`}
          isResponsive
          component={CurrencyInput}
          input={{
            placeholder: 'Enter amount',
            disabled: hasAmountFormula,
            component: TextInput,
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
          }}
        />
      </LoadingField>
    </React.Fragment>
  );
};

export default EditBillItemFields;
