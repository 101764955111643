// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import AssignTruckToSlotForm from '@shared/modules/Dispatch/forms/AssignTruckToSlotForm';

const useAssignTruckToSlotMutation = ({assignTruckToSlotForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      assignTruckToSlotForm: AssignTruckToSlotForm.toForm(assignTruckToSlotForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useAssignTruckToSlotMutation.mutation,
    variables: {
      assignTruckToSlotForm: AssignTruckToSlotForm.toMutation(form.values.assignTruckToSlotForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useAssignTruckToSlotMutation.mutation = gql`
  mutation useAssignTruckToSlotMutation($assignTruckToSlotForm: AssignTruckToSlotForm!) {
    response: assignTruckToSlot(assignTruckToSlotForm: $assignTruckToSlotForm) {
      ${gql.errors}
      slot {
        id
      }
    }
  }
`;

export default useAssignTruckToSlotMutation;
