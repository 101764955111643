// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import JobTypeStepForm from '@shared/modules/Job/forms/JobTypeStepForm';

const useUpsertJobTypeStepMutation = ({jobTypeStepForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      jobTypeStepForm: JobTypeStepForm.toForm(jobTypeStepForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpsertJobTypeStepMutation.mutation,
    variables: {
      jobTypeStepForm: JobTypeStepForm.toMutation(form.values.jobTypeStepForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpsertJobTypeStepMutation.mutation = gql`
  mutation useUpsertJobTypeStepMutation($jobTypeStepForm: JobTypeStepForm!){
    response: upsertJobTypeStep(jobTypeStepForm: $jobTypeStepForm) {
      ${gql.errors}
      jobTypeStep{
        id
        isEnabled
        documentTemplateKinds
      }
    }
  }
`;

export default useUpsertJobTypeStepMutation;
