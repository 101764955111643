// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ItemTypesForm from '@shared/modules/Inventory/forms/ItemTypesForm';

const useUpsertItemTypesMutation = ({itemTypesForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      itemTypesForm: ItemTypesForm.toForm(itemTypesForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpsertItemTypesMutation.mutation,
    variables: {
      itemTypesForm: ItemTypesForm.toMutation(form.values.itemTypesForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpsertItemTypesMutation.mutation = gql`
  mutation useUpsertItemTypesMutation($itemTypesForm: ItemTypesForm!) {
    response: upsertItemTypes(itemTypesForm: $itemTypesForm) {
      ${gql.errors}
    }
  }
`;

export default useUpsertItemTypesMutation;
