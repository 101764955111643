// Libraries
import _ from 'lodash';

// Supermove
import {BillModifierForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {Currency, Percent, withFragment} from '@supermove/utils';

// Models
import BillItemType from './BillItemType';
import LineItem from './LineItem';

const toMutationDiscountPercentage = ({discountPercentage}) => {
  return Math.abs(Percent.toMutation(discountPercentage || 0)) * -1;
};

const getEstimateBalance = withFragment(
  (bill) => {
    const {estimateBalanceMin, estimateBalanceMax} = bill;
    return Currency.formatRange({
      min: estimateBalanceMin,
      max: estimateBalanceMax,
    });
  },
  gql`
    fragment Bill_getEstimateBalance on Bill {
      estimateBalanceMin
      estimateBalanceMax
    }
  `,
);

const getEstimateBalanceWithoutTip = withFragment(
  (bill) => {
    if (bill.estimateBalanceMin !== bill.estimateBalanceMax) {
      return (
        `${Currency.display(bill.estimateBalanceMin - bill.tip, {shouldHideCentsIfZero: true})}` +
        ` - ${Currency.display(bill.estimateBalanceMax - bill.tip, {shouldHideCentsIfZero: true})}`
      );
    } else {
      return Currency.display(bill.estimateBalanceMin - bill.tip, {shouldHideCentsIfZero: true});
    }
  },
  gql`
    fragment Bill_getEstimateBalanceWithoutTip on Bill {
      estimateBalanceMin
      estimateBalanceMax
      tip
    }
  `,
);

const getEstimateDiscount = withFragment(
  (bill) => {
    if (bill.estimateDiscountMin !== bill.estimateDiscountMax) {
      return (
        `(${Currency.display(-bill.estimateDiscountMin, {shouldHideCentsIfZero: true})}` +
        ` - ${Currency.display(-bill.estimateDiscountMax, {shouldHideCentsIfZero: true})})`
      );
    } else {
      return `(${Currency.display(-bill.estimateDiscountMin, {shouldHideCentsIfZero: true})})`;
    }
  },
  gql`
    fragment Bill_getEstimateDiscount on Bill {
      estimateDiscountMin
      estimateDiscountMax
    }
  `,
);

const getEstimateSalesTaxAmount = withFragment(
  (bill) => {
    if (bill.estimateSalesTaxAmountMin !== bill.estimateSalesTaxAmountMax) {
      return (
        `${Currency.display(bill.estimateSalesTaxAmountMin, {shouldHideCentsIfZero: true})}` +
        ` - ${Currency.display(bill.estimateSalesTaxAmountMax, {shouldHideCentsIfZero: true})}`
      );
    } else {
      return Currency.display(bill.estimateSalesTaxAmountMin, {shouldHideCentsIfZero: true});
    }
  },
  gql`
    fragment Bill_getEstimateSalesTaxAmount on Bill {
      estimateSalesTaxAmountMin
      estimateSalesTaxAmountMax
    }
  `,
);

const getEstimateSubtotal = withFragment(
  (bill) => {
    if (bill.estimateSubtotalMin !== bill.estimateSubtotalMax) {
      return (
        `${Currency.display(bill.estimateSubtotalMin, {shouldHideCentsIfZero: true})}` +
        ` - ${Currency.display(bill.estimateSubtotalMax, {shouldHideCentsIfZero: true})}`
      );
    } else {
      return Currency.display(bill.estimateSubtotalMin, {shouldHideCentsIfZero: true});
    }
  },
  gql`
    fragment Bill_getEstimateSubtotal on Bill {
      estimateSubtotalMin
      estimateSubtotalMax
    }
  `,
);

const getEstimateTotal = withFragment(
  (bill) => {
    if (bill.estimateTotalMin !== bill.estimateTotalMax) {
      return Currency.formatRange({min: bill.estimateTotalMin, max: bill.estimateTotalMax});
    } else {
      return Currency.format({value: bill.estimateTotalMin});
    }
  },
  gql`
    fragment Bill_getEstimateTotal on Bill {
      estimateTotalMin
      estimateTotalMax
    }
  `,
);

const getJobName = withFragment(
  (bill) => {
    return _.get(bill, 'job.fullName') || '';
  },
  gql`
    fragment Bill_getJobName on Bill {
      id
      job {
        id
        fullName
      }
    }
  `,
);

const getBillModifierTypesForBill = withFragment(
  (bill) => {
    return BillItemType.getBillModifierTypesFromBillItemTypes(
      bill.project.projectType.billingLibrary.billItemTypes,
    );
  },
  gql`
    ${BillItemType.getBillModifierTypesFromBillItemTypes.fragment}
    fragment Bill_getBillModifierTypesForBill on Bill {
      id
      project {
        id
        projectType {
          id
          billingLibrary {
            id
            billItemTypes {
              id
              ...BillItemType_getBillModifierTypesFromBillItemTypes
            }
          }
        }
      }
    }
  `,
);

const getTemplateLineItemsForBill = withFragment(
  (bill) => {
    return BillItemType.getTemplateLineItemsFromBillItemTypes(
      bill.project.projectType.billingLibrary.billItemTypes,
    );
  },
  gql`
    ${BillItemType.getTemplateLineItemsFromBillItemTypes.fragment}
    fragment Bill_getTemplateLineItemsForBill on Bill {
      id
      project {
        id
        projectType {
          id
          billingLibrary {
            id
            billItemTypes {
              id
              ...BillItemType_getTemplateLineItemsFromBillItemTypes
            }
          }
        }
      }
    }
  `,
);

const getBillRuleTypesForBill = withFragment(
  (bill) => {
    return bill.project.projectType.billingLibrary.billRuleTypes;
  },
  gql`
    fragment Bill_getBillRuleTypesForBill on Bill {
      id
      project {
        id
        projectType {
          id
          billingLibrary {
            id
            billRuleTypes {
              id
              kind
              name
              description
              value
              valueFormulaId
              isVisibleToCustomer
            }
          }
        }
      }
    }
  `,
);

const getIsDeletable = withFragment(
  (bill) => {
    const {isEnabledBillingEngineTimesheetVariables} = bill.organization.features;
    return isEnabledBillingEngineTimesheetVariables || !bill.jobId;
  },
  gql`
    fragment Bill_getIsDeletable on Bill {
      id
      jobId
      organization {
        id
        features {
          isEnabledBillingEngineTimesheetVariables: isEnabled(
            feature: "BILLING_ENGINE_TIMESHEET_VARIABLES"
          )
        }
      }
    }
  `,
);

const Bill = {
  // We do not include 'JOB_ESTIMATE' in `KINDS_ORDER` because
  // we do not want to render the estimate bill to the customer.
  KINDS_ORDER: ['JOB_MAIN', 'JOB_ADDITIONAL', 'CUSTOM'],
  KINDS: {
    JOB_MAIN: 'JOB_MAIN',
    JOB_ADDITIONAL: 'JOB_ADDITIONAL',
    JOB_ESTIMATE: 'JOB_ESTIMATE',
    CUSTOM: 'CUSTOM',
  },
  getBillModifierTypesForBill,
  getBillRuleTypesForBill,
  getTemplateLineItemsForBill,
  getEstimateBalance,
  getEstimateBalanceWithoutTip,
  getEstimateDiscount,
  getEstimateSalesTaxAmount,
  getEstimateSubtotal,
  getEstimateTotal,
  getIsDeletable,
  getJobName,

  getFinishScreen: ({kind}) => {
    switch (kind) {
      case 'JOB_MAIN':
        return 'PassFinishCustomerJob';
      case 'JOB_ADDITIONAL':
      case 'CUSTOM':
      default:
        return 'FinishCustomerBillJob';
    }
  },

  getFinishParams: ({kind}, params) => {
    switch (kind) {
      case 'JOB_MAIN':
        return {uuid: params.uuid};
      case 'JOB_ADDITIONAL':
      case 'CUSTOM':
      default:
        return {uuid: params.uuid, billUuid: params.billUuid};
    }
  },

  getTitle: ({kind}) => {
    switch (kind) {
      case Bill.KINDS.JOB_ESTIMATE:
        return 'Estimate Bill';
      case Bill.KINDS.JOB_MAIN:
        return 'Main Bill';
      case Bill.KINDS.JOB_ADDITIONAL:
      case Bill.KINDS.CUSTOM:
      default:
        return 'Additional Charge Bill';
    }
  },

  getSigningSubtitle: ({kind}) => {
    switch (kind) {
      case Bill.KINDS.JOB_MAIN:
        return 'By signing, you agree that all of your items have been properly moved and fully inspected.';
      case Bill.KINDS.JOB_ADDITIONAL:
      case Bill.KINDS.CUSTOM:
      default:
        return 'By signing, you confirm that you have paid the total amount.';
    }
  },

  /**
   * NOTE
   * `toForm` and `toMutation` do not contain all the fields of
   * the `Bill` table. This is because we previously did not use
   * the `toForm` and `toMutation` pattern. If you find yourself
   * using these methods, you can finish this up and remove this comment.
   */
  toForm: ({id, billId, discountPercentage, lineItems = [], billModifierForms = []}) => ({
    billId: id || billId,
    discountPercentage: discountPercentage ? Percent.toForm(Math.abs(discountPercentage)) : '',
    lineItems: lineItems.map((lineItem) => LineItem.toForm(lineItem)),
    billModifierForms: billModifierForms.map((billModifierForm) => {
      return BillModifierForm.toForm(billModifierForm);
    }),
  }),

  toMutation: ({billId, discountPercentage, lineItems = [], billModifierForms = []}) => ({
    billId,
    discountPercentage: toMutationDiscountPercentage({discountPercentage}),
    lineItems: lineItems.map((lineItem) => LineItem.toMutation(lineItem)),
    billModifierForms: billModifierForms.map((billModifierForm) => {
      return BillModifierForm.toMutation(billModifierForm);
    }),
  }),
};

export default Bill;
