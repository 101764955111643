// Libraries
import _ from 'lodash';

// Supermove
import {humanize} from '@supermove/utils';

// App
import DocumentTemplateCategory from '@shared/modules/Document/enums/DocumentTemplateCategory';

const TEXT = 'TEXT';
const ORGANIZATION_SIGNATURE = 'ORGANIZATION_SIGNATURE';
const BILLING_INFORMATION = 'BILLING_INFORMATION';
const TIMESHEET_INFORMATION = 'TIMESHEET_INFORMATION';
const VALUATION_DECLARATION_TABLE = 'VALUATION_DECLARATION_TABLE';
const SURVEY_CARTONS_SUMMARY = 'SURVEY_CARTONS_SUMMARY';
const SURVEY_ITEMS_SUMMARY = 'SURVEY_ITEMS_SUMMARY';
const SURVEY_ROOMS_SUMMARY = 'SURVEY_ROOMS_SUMMARY';
const INVENTORY_SUMMARY = 'INVENTORY_SUMMARY';
const GENERAL_INVENTORY_SUMMARY = 'GENERAL_INVENTORY_SUMMARY';
const INVENTORY_ITEMS_SUMMARY = 'INVENTORY_ITEMS_SUMMARY';
const INPUT_PER_MOVER_TIMESHEET = 'INPUT_PER_MOVER_TIMESHEET';
const INPUT_COMMERCIAL_CATALOG_EQUIPMENTS = 'INPUT_COMMERCIAL_CATALOG_EQUIPMENTS';
const INPUT_COMMERCIAL_CATALOG_MATERIALS = 'INPUT_COMMERCIAL_CATALOG_MATERIALS';
const INPUT_GRID = 'INPUT_GRID';
const INPUT_LIST = 'INPUT_LIST';
const INPUT_RADIO_BUTTONS = 'INPUT_RADIO_BUTTONS';
const INPUT_TEXT_INPUTS = 'INPUT_TEXT_INPUTS';
const INPUT_TEXT_AREAS = 'INPUT_TEXT_AREAS';
const INPUT_VALUATION_COVERAGE = 'INPUT_VALUATION_COVERAGE';
const INPUT_SIGNATURE = 'INPUT_SIGNATURE';
const GENERIC_SIGNATURE = 'GENERIC_SIGNATURE';
const DOCUMENT_HEADER = 'DOCUMENT_HEADER';
const LOGO = 'LOGO';
const IMAGE = 'IMAGE';

// INVOICE category document items
const INVOICE_SUMMARY_INFORMATION = 'INVOICE_SUMMARY_INFORMATION';

// DEPRECATED: These document item kinds were deprecated with the introduction of TEXT.
// They are still definied here for backwards compatibility, but are omitted intentionally from VALUES.
const TEXT_TITLE = 'TEXT_TITLE';
const TEXT_PARAGRAPH = 'TEXT_PARAGRAPH';
const SECTION_TITLE = 'SECTION_TITLE';

const getItemDisplayName = (itemKind) => {
  const itemConfig = _.get(DocumentItemKindV2.DOCUMENT_ITEM_KIND_TABLE_VALUES, itemKind);
  return _.get(itemConfig, 'displayName') || humanize(itemKind);
};

const getDocumentItemKindBasedOnCategory = ({category, isEnabledGenericSignatureDocumentItem}) => {
  const notShownItemsForInvoice = [BILLING_INFORMATION, SURVEY_CARTONS_SUMMARY];

  return DocumentItemKindV2.VALUES.filter((value) => {
    if (!isEnabledGenericSignatureDocumentItem && value === GENERIC_SIGNATURE) {
      return false;
    }
    // (Kevin): We can refactor this out if we need to support projects,
    // for now this is only used invoice which filters out all JOB document item kinds
    return category === DocumentTemplateCategory.INVOICE
      ? DocumentItemKindV2.DOCUMENT_ITEM_KIND_TABLE_VALUES[value].category !==
          DocumentTemplateCategory.JOB && !notShownItemsForInvoice.includes(value)
      : DocumentItemKindV2.DOCUMENT_ITEM_KIND_TABLE_VALUES[value].category !==
          DocumentTemplateCategory.INVOICE;
  });
};

const getConvertedItemText = ({item}) => {
  const textWithLineBreaks = item.text.replace(/(?:\r\n|\r|\n)/g, '<br>');
  switch (item.itemKind) {
    case TEXT_PARAGRAPH:
      return `<p>${textWithLineBreaks}</p>`;
    case TEXT_TITLE:
      return `<h2>${textWithLineBreaks}</h2>`;
    case SECTION_TITLE:
      return `<h1 class='ql-align-center'>${textWithLineBreaks}</h1>`;
    default:
      return item.text;
  }
};

const convertTextItems = ({documentItems}) => {
  let hasUpdatedDocumentItems = false;
  const convertedDocumentItems = documentItems.map((item) => {
    if (_.includes([TEXT_PARAGRAPH, TEXT_TITLE, SECTION_TITLE], item.itemKind)) {
      hasUpdatedDocumentItems = true;
      return {
        ...item,
        text: getConvertedItemText({item}),
        itemKind: 'TEXT',
      };
    } else {
      return item;
    }
  });
  return {
    hasUpdatedDocumentItems,
    documentItems: convertedDocumentItems,
  };
};

const mergeTextItems = ({documentItems}) => {
  const mergedDocumentItems = [];

  for (let i = 0; i < documentItems.length; i += 1) {
    const currentItem = documentItems[i];
    if (currentItem.itemKind === 'TEXT') {
      // Combine consecutive TEXT items
      let combinedText = currentItem.text;

      // Continue combining until a non-TEXT item is encountered or end of array
      while (i + 1 < documentItems.length && documentItems[i + 1].itemKind === 'TEXT') {
        // Since existing items have space between them, add a p tag with a line break (emulates the rich text input behavior)
        combinedText += `<p><br></p>${documentItems[i + 1].text}`;
        i += 1;
      }

      mergedDocumentItems.push({itemKind: 'TEXT', text: combinedText});
    } else {
      // Keep non-TEXT items as they are
      mergedDocumentItems.push(currentItem);
    }
  }

  return mergedDocumentItems;
};

const DocumentItemKindV2 = {
  TEXT,
  TEXT_TITLE,
  TEXT_PARAGRAPH,
  ORGANIZATION_SIGNATURE,
  BILLING_INFORMATION,
  TIMESHEET_INFORMATION,
  VALUATION_DECLARATION_TABLE,
  SURVEY_CARTONS_SUMMARY,
  SURVEY_ITEMS_SUMMARY,
  SURVEY_ROOMS_SUMMARY,
  INVENTORY_SUMMARY,
  GENERAL_INVENTORY_SUMMARY,
  IMAGE,
  INVENTORY_ITEMS_SUMMARY,
  INPUT_PER_MOVER_TIMESHEET,
  INPUT_COMMERCIAL_CATALOG_EQUIPMENTS,
  INPUT_COMMERCIAL_CATALOG_MATERIALS,
  INPUT_GRID,
  INPUT_LIST,
  INPUT_RADIO_BUTTONS,
  INPUT_TEXT_INPUTS,
  INPUT_TEXT_AREAS,
  INPUT_VALUATION_COVERAGE,
  INPUT_SIGNATURE,
  GENERIC_SIGNATURE,
  SECTION_TITLE,
  DOCUMENT_HEADER,
  LOGO,
  // INVOICE Category document item kind
  INVOICE_SUMMARY_INFORMATION,

  DOCUMENT_ITEM_KIND_TABLE_VALUES: {
    DOCUMENT_HEADER: {
      displayName: 'Document Header',
      displayDescription: 'Business logo and info',
    },
    LOGO: {
      displayName: 'Logo',
      displayDescription: 'Business logo',
    },
    TEXT: {
      displayName: 'Text',
      displayDescription: 'Block of text',
    },
    ORGANIZATION_SIGNATURE: {
      displayName: 'Business Signature',
      displayDescription: 'Saved signature for the business signature',
    },
    BILLING_INFORMATION: {
      displayName: 'Billing Information',
      displayDescription: 'Per-move billing information',
    },
    INVOICE_SUMMARY_INFORMATION: {
      category: DocumentTemplateCategory.INVOICE,
      displayName: 'Invoice Summary Information',
      displayDescription: 'Invoice summary information',
    },
    TIMESHEET_INFORMATION: {
      category: DocumentTemplateCategory.JOB,
      displayName: 'Job Timesheet',
      displayDescription: 'Single day timesheet information',
    },
    VALUATION_DECLARATION_TABLE: {
      category: DocumentTemplateCategory.JOB,
      displayName: 'Valuation Options Table',
      displayDescription: 'Table of valuation coverage options',
    },
    SURVEY_CARTONS_SUMMARY: {
      displayName: 'Survey Cartons Summary',
      displayDescription: 'Survey cartons summary information',
    },
    SURVEY_ITEMS_SUMMARY: {
      displayName: 'Survey Items Summary',
      displayDescription: 'Survey items summary information',
    },
    SURVEY_ROOMS_SUMMARY: {
      displayName: 'Survey Rooms Summary',
      displayDescription: 'Room summary information',
    },
    INVENTORY_SUMMARY: {
      displayName: 'Survey Items / Cartons Summary',
      displayDescription: 'Summary of items and cartons captured during the survey',
    },
    GENERAL_INVENTORY_SUMMARY: {
      displayName: 'Inventory Summary',
      displayDescription: 'Summary of driver inventory items and cartons captured',
    },
    INVENTORY_ITEMS_SUMMARY: {
      displayName: 'Inventory Items Summary',
      displayDescription: 'Driver inventory items summary information',
    },
    INPUT_PER_MOVER_TIMESHEET: {
      category: DocumentTemplateCategory.JOB,
      displayName: 'Per-Mover Timesheet Input',
      displayDescription: 'Input to capture the individual timesheet broken down per mover',
    },
    INPUT_COMMERCIAL_CATALOG_EQUIPMENTS: {
      category: DocumentTemplateCategory.JOB,
      displayName: 'Equipment Input',
      displayDescription: 'Input to capture the list of equipment used',
    },
    INPUT_COMMERCIAL_CATALOG_MATERIALS: {
      category: DocumentTemplateCategory.JOB,
      displayName: 'Materials Input',
      displayDescription: 'Input to capture the list of materials used',
    },
    INPUT_GRID: {
      displayName: 'Grid Input',
      displayDescription: 'Input to capture rows and columns of data',
    },
    INPUT_LIST: {
      displayName: 'List Input',
      displayDescription: 'Input to capture a list of values',
    },
    INPUT_RADIO_BUTTONS: {
      displayName: 'Radio Buttons',
      displayDescription: 'Input to capture answers from a list of options',
    },
    INPUT_TEXT_INPUTS: {
      displayName: 'Field Inputs',
      displayDescription: 'Input to capture a set of fields by name',
    },
    INPUT_TEXT_AREAS: {
      displayName: 'Multi-line Field Inputs',
      displayDescription: 'Input to capture a set of multi-line fields by name',
    },
    INPUT_VALUATION_COVERAGE: {
      category: DocumentTemplateCategory.JOB,
      displayName: 'Valuation Coverage Input',
      displayDescription: 'Input to capture the selected valuation coverage',
    },
    INPUT_SIGNATURE: {
      displayName: 'Customer Signature',
      displayDescription: "Input to capture the customer's signature",
    },
    GENERIC_SIGNATURE: {
      displayName: 'Generic Signature',
      displayDescription: 'Input to capture any user signature',
    },
    IMAGE: {
      displayName: 'Image',
      displayDescription: 'Adds an image',
    },
    // DEPRECATED
    SECTION_TITLE: {
      displayName: 'Section Title',
      displayDescription: 'Bold centered title for a new section',
    },
    TEXT_TITLE: {
      displayName: 'Paragraph Title',
      displayDescription: 'Title for a new paragraph',
    },
    TEXT_PARAGRAPH: {
      displayName: 'Paragraph',
      displayDescription: 'Block of text',
    },
  },
  VALUES: [
    DOCUMENT_HEADER,
    LOGO,
    TEXT,
    ORGANIZATION_SIGNATURE,
    BILLING_INFORMATION,
    INVOICE_SUMMARY_INFORMATION,
    VALUATION_DECLARATION_TABLE,
    TIMESHEET_INFORMATION,
    SURVEY_CARTONS_SUMMARY,
    SURVEY_ITEMS_SUMMARY,
    SURVEY_ROOMS_SUMMARY,
    INVENTORY_SUMMARY,
    GENERAL_INVENTORY_SUMMARY,
    IMAGE,
    INVENTORY_ITEMS_SUMMARY,
    INPUT_TEXT_INPUTS,
    INPUT_TEXT_AREAS,
    INPUT_RADIO_BUTTONS,
    INPUT_LIST,
    INPUT_GRID,
    INPUT_SIGNATURE,
    GENERIC_SIGNATURE,
    INPUT_VALUATION_COVERAGE,
    INPUT_PER_MOVER_TIMESHEET,
    INPUT_COMMERCIAL_CATALOG_EQUIPMENTS,
    INPUT_COMMERCIAL_CATALOG_MATERIALS,
  ],

  getDocumentItemKindBasedOnCategory,
  getItemDisplayName,
  convertTextItems,
  mergeTextItems,
};

export default DocumentItemKindV2;
