// Libraries
import React from 'react';

// Supermove
import {Loading, PdfViewV2, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMountEffect, useState} from '@supermove/hooks';

// App
import DownloadDocumentForm from '@shared/modules/Document/forms/DownloadDocumentForm';
import useDownloadDocumentMutation from '@shared/modules/Document/hooks/useDownloadDocumentMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const PAGE_PADDING = 36;

const PdfContainer = Styled.View`
  height: ${({height}) => height}px;
  width:  ${({width}) => width}px;
`;

const DocumentV2Pdf = ({document, viewerWidth}) => {
  const [pdfHeight, setPdfHeight] = useState(600);
  const [documentUrl, setDocumentUrl] = useState('');

  const ATTACHMENT_VIEWER_WIDTH = viewerWidth || 792;
  const PDF_PAGE_HEIGHT = ATTACHMENT_VIEWER_WIDTH * PdfViewV2.PAGE_ASPECT_RATIO;

  const downloadDocumentForm = DownloadDocumentForm.new({
    documentId: document.id,
  });
  const {handleSubmit, submitting} = useDownloadDocumentMutation({
    downloadDocumentForm,
    onSuccess: (response) => {
      setDocumentUrl(response.url);
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  useMountEffect(() => {
    handleSubmit();
  });

  return (
    <PdfContainer key={pdfHeight} height={pdfHeight} width={ATTACHMENT_VIEWER_WIDTH}>
      <Loading loading={submitting} as={PageLoadingIndicator}>
        {() => (
          <PdfViewV2
            source={{uri: documentUrl}}
            onLoad={({numberOfPages}) => {
              setPdfHeight(numberOfPages * PDF_PAGE_HEIGHT);
            }}
            pageWidth={ATTACHMENT_VIEWER_WIDTH - PAGE_PADDING * 2}
            pagePadding={PAGE_PADDING}
          />
        )}
      </Loading>
    </PdfContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentV2Pdf.fragment = gql`
  ${DownloadDocumentForm.new.fragment}
  fragment DocumentV2Pdf on Document {
    id
    uuid
    ...DownloadDocumentForm_new
  }
`;

export default DocumentV2Pdf;
