// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import TripStatusForm from '@shared/modules/Dispatch/forms/TripStatusForm';

const useUpdateTripStatusMutation = ({tripStatusForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      tripStatusForm: TripStatusForm.toForm(tripStatusForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateTripStatusMutation.mutation,
    variables: {
      tripStatusForm: TripStatusForm.toMutation(form.values.tripStatusForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
// Return shipments statuses so that shipment statuses can update on the ui
// when a change in trip status changes all the shipment statuses.
useUpdateTripStatusMutation.mutation = gql`
  mutation useUpdateTripStatusMutation($tripStatusForm: TripStatusForm!) {
    response: updateTripStatus(tripStatusForm: $tripStatusForm) {
      ${gql.errors}
      trip {
        id
        status
        shipments {
          id
          status
        }
      }
    }
  }
`;

export default useUpdateTripStatusMutation;
