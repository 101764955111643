// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ProjectForm from '@shared/modules/Project/forms/ProjectForm';

const useCopyProjectMutation = ({projectForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      projectForm: ProjectForm.toForm(projectForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCopyProjectMutation.mutation,
    variables: {
      projectForm: ProjectForm.toMutation(form.values.projectForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCopyProjectMutation.mutation = gql`
  mutation CopyProjectMutation($projectForm: ProjectForm!) {
    response: copyProject(projectForm: $projectForm) {
      ${gql.errors}
      project {
        id
        uuid
        identifier
        jobs {
          id
          uuid
        }
      }
    }
  }
`;

export default useCopyProjectMutation;
