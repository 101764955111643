// Libraries
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import SourceKind from '@shared/modules/Project/enums/SourceKind';
import SidebarPage from 'modules/App/components/SidebarPage';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import TrainingJobSettings from 'modules/Organization/Settings/components/TrainingJobSettings';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  width: ${(props) => (props.mobile ? '100%' : 'fit-content')};
  padding-horizontal: ${(props) => (props.mobile ? 12 : 24)}px;
  padding-bottom: 80px;
`;

const Section = Styled.View`
  margin-bottom: 30px;
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const SectionTitle = Styled.H4`
  margin-bottom: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const SectionSubtitle = Styled.H6`
  margin-bottom: 10px;
  color: ${colors.gray.primary};
`;

const Button = Styled.Button`
  align-self: flex-start;
  flex-direction: row;
  justify-content: space-between;
  width: ${(props) => (props.mobile ? '100%' : 'fit-content')};
  height: 40px;
  padding-horizontal: 10px;
  margin-top: ${(props) => (props.mobile && !props.isFirst ? '10px' : '0px')};
  margin-right: ${(props) => (props.mobile ? '0px' : '10px')};
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const UsersSettings = ({sectionIndex, navigator}) => (
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>Staff</SectionTitle>
    <SectionSubtitle>Settings related to your office staff and movers.</SectionSubtitle>
    <Button onPress={() => navigator.push('/settings/users/staff')}>
      <ButtonText>View/Edit Staff</ButtonText>
    </Button>
  </Section>
);

const CommunicationSettings = ({sectionIndex, navigator}) => (
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>Communication</SectionTitle>
    <SectionSubtitle>Manage your SMS and email settings.</SectionSubtitle>
    <Button onPress={() => navigator.push('settings/communication/general')}>
      <ButtonText>View/Edit Communication Setting</ButtonText>
    </Button>
  </Section>
);

const ProjectTypesSettings = ({sectionIndex, navigator, isViewOnly}) => {
  return (
    <Section sectionIndex={sectionIndex}>
      <SectionTitle>{'Project Types'}</SectionTitle>
      <SectionSubtitle>{`View${
        isViewOnly ? '' : ' and edit'
      } your project types.`}</SectionSubtitle>
      <Button onPress={() => navigator.push('/settings/project-types')}>
        <ButtonText>{`View${isViewOnly ? '' : '/Edit'} Project Types`}</ButtonText>
      </Button>
    </Section>
  );
};

const ProjectSettings = ({sectionIndex, navigator, isViewOnly}) => {
  return (
    <Section sectionIndex={sectionIndex}>
      <SectionTitle>{'Projects'}</SectionTitle>
      <SectionSubtitle>{`View${
        isViewOnly ? '' : ' and edit'
      } your project settings, including project and job types, billing libraries, and tags.`}</SectionSubtitle>
      <Button onPress={() => navigator.push('/settings/project-types')}>
        <ButtonText>{`View${isViewOnly ? '' : '/Edit'} Project`}</ButtonText>
      </Button>
    </Section>
  );
};

const BillingLibrariesSettings = ({sectionIndex, navigator}) => (
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>{'Billing'}</SectionTitle>
    <SectionSubtitle>{'View and edit your billing.'}</SectionSubtitle>
    <Button onPress={() => navigator.push('/settings/billing/billing-libraries')}>
      <ButtonText>{'View/Edit Billing Settings'}</ButtonText>
    </Button>
  </Section>
);

const DocumentTemplateSettings = ({sectionIndex, navigator}) => (
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>{'Document Library'}</SectionTitle>
    <SectionSubtitle>{'View and edit your document library.'}</SectionSubtitle>
    <Button onPress={() => navigator.push('/settings/documents/document-templates')}>
      <ButtonText>{'View/Edit Document Library'}</ButtonText>
    </Button>
  </Section>
);

const WorkflowSettings = ({sectionIndex, navigator}) => (
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>{'Automations'}</SectionTitle>
    <SectionSubtitle>{'View and edit your automations.'}</SectionSubtitle>
    <Button onPress={() => navigator.push('/settings/automations')}>
      <ButtonText>{'View/Edit Automations'}</ButtonText>
    </Button>
  </Section>
);

const TasksSettings = ({sectionIndex, navigator}) => (
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>{'Tasks'}</SectionTitle>
    <SectionSubtitle>{'Manage your task templates.'}</SectionSubtitle>
    <Button onPress={() => navigator.push('/settings/tasks')}>
      <ButtonText>{'Manage Tasks'}</ButtonText>
    </Button>
  </Section>
);

const ReportsSettings = ({sectionIndex, navigator}) => (
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>{'Reports'}</SectionTitle>
    <SectionSubtitle>{'Manage your reports.'}</SectionSubtitle>
    <Button onPress={() => navigator.push('/settings/reports')}>
      <ButtonText>{'Manage Reports'}</ButtonText>
    </Button>
  </Section>
);

const CompanySettings = ({sectionIndex, navigator}) => (
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>Company Settings</SectionTitle>
    <SectionSubtitle>View and edit your company settings.</SectionSubtitle>
    <Button onPress={() => navigator.push('/settings/company/business-info')}>
      <ButtonText>View/Edit Company Settings</ButtonText>
    </Button>
  </Section>
);

const MovesSettings = ({sectionIndex, navigator}) => {
  return (
    <Section sectionIndex={sectionIndex}>
      <SectionTitle>Moves</SectionTitle>
      <SectionSubtitle>{`Manage your moves (calendars, trucks & slots, locations, equipment & materials).`}</SectionSubtitle>
      <Button onPress={() => navigator.push('/settings/moves/calendars')}>
        <ButtonText>Manage Moves</ButtonText>
      </Button>
    </Section>
  );
};

const InventorySettings = ({sectionIndex, navigator}) => (
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>Inventory</SectionTitle>
    <SectionSubtitle>Manage your inventory.</SectionSubtitle>
    <Button onPress={() => navigator.push('/settings/inventory/general')}>
      <ButtonText>Manage Inventory</ButtonText>
    </Button>
  </Section>
);

const JobSettings = ({sectionIndex, navigator}) => (
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>Import Jobs</SectionTitle>
    <SectionSubtitle>Use a CSV file to import past jobs.</SectionSubtitle>
    <Button onPress={() => navigator.push('/settings/jobs')}>
      <ButtonText>Import Jobs</ButtonText>
    </Button>
  </Section>
);

const IntegrationSettings = ({sectionIndex, navigator}) => (
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>API Integrations</SectionTitle>
    <SectionSubtitle>View and edit API integrations.</SectionSubtitle>
    <Button onPress={() => navigator.push('/settings/integrations/webhooks')}>
      <ButtonText>View/Edit Integrations</ButtonText>
    </Button>
  </Section>
);

const StorageSettings = ({sectionIndex, navigator}) => (
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>Storage</SectionTitle>
    <SectionSubtitle>Manage storage settings.</SectionSubtitle>
    <Button onPress={() => navigator.push('/settings/storage')}>
      <ButtonText>Manage Storage</ButtonText>
    </Button>
  </Section>
);

const SettingsBlock = ({index, block, viewer, navigator, source}) => {
  switch (block.kind) {
    case 'MANAGER_SETTINGS_BILLING':
      return (
        <React.Fragment>
          {viewer.viewingOrganization.features.isEnabledProjectTagSetting ? (
            <ProjectSettings sectionIndex={index} navigator={navigator} />
          ) : (
            <ProjectTypesSettings sectionIndex={index} navigator={navigator} />
          )}
          <BillingLibrariesSettings sectionIndex={index} navigator={navigator} />
        </React.Fragment>
      );
    case 'MANAGER_SETTINGS_COMPANY_SETTINGS':
      return <CompanySettings sectionIndex={index} navigator={navigator} />;
    case 'MANAGER_SETTINGS_CREATE_TRAINING_JOB':
      if (!viewer.viewingOrganization.canCreateJobs) {
        return null;
      }
      return (
        <Section sectionIndex={index}>
          <TrainingJobSettings />
        </Section>
      );
    case 'MANAGER_DOCUMENT_TEMPLATE_SETTINGS':
      return <DocumentTemplateSettings sectionIndex={index} navigator={navigator} />;
    case 'MANAGER_WORKFLOW_SETTINGS_BLOCK':
      return <WorkflowSettings sectionIndex={index} navigator={navigator} />;
    case 'MANAGER_SETTINGS_TASKS':
      return <TasksSettings sectionIndex={index} navigator={navigator} />;
    case 'MANAGER_SETTINGS_COMMUNICATION':
      return <CommunicationSettings sectionIndex={index} navigator={navigator} />;
    case 'MANAGER_SETTINGS_INVENTORY':
      return <InventorySettings sectionIndex={index} navigator={navigator} />;
    case 'MANAGER_SETTINGS_MOVES':
      return <MovesSettings sectionIndex={index} navigator={navigator} />;
    case 'MANAGER_SETTINGS_JOBS':
      return <JobSettings sectionIndex={index} navigator={navigator} />;
    case 'MANAGER_SETTINGS_STAFF':
      return <UsersSettings sectionIndex={index} navigator={navigator} />;
    case 'MANAGER_SETTINGS_STORAGE':
      if (!viewer.viewingOrganization.settings.isStorageEnabled) {
        return null;
      }
      return <StorageSettings sectionIndex={index} navigator={navigator} />;
    case 'MANAGER_SETTINGS_INTEGRATIONS':
      return <IntegrationSettings sectionIndex={index} navigator={navigator} />;
    case 'MANAGER_SETTINGS_REPORTS':
      return <ReportsSettings sectionIndex={index} navigator={navigator} />;
    default:
      return null;
  }
};

const OrganizationSettingsPageContent = ({viewer}) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();

  return (
    <Container {...responsive}>
      <Space height={20} />
      {viewer.viewingOrganization.managerSettingsPageBlock.blocks.map((block, index) => (
        <SettingsBlock
          key={index}
          index={index}
          block={block}
          viewer={viewer}
          source={viewer.viewingOrganization.source}
          navigator={navigator}
        />
      ))}
    </Container>
  );
};

const OrganizationSettingsPage = () => (
  <SidebarPage
    selected={'settings'}
    query={OrganizationSettingsPage.query}
    variables={{sourceKind: SourceKind.OFFICE_TRAINING_PROJECT}}
  >
    {({data}) => (
      <Wrapper>
        <StandardOfficeHeader title={'Settings'} />
        <ScrollView style={{flex: 1}}>
          <OrganizationSettingsPageContent viewer={data.viewer} />
        </ScrollView>
      </Wrapper>
    )}
  </SidebarPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsPage.query = gql`
  query OrganizationSettingsPage($sourceKind: String!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        name
        canCreateJobs
        isPrimary
        company {
          id
          nonPrimaryOrganizations {
            id
          }
        }
        features {
          isEnabledEquipmentAndMaterial: isEnabled(feature: "COMMERCIAL_EQUIPMENT_AND_MATERIALS")
          isEnabledProjectTagSetting: isEnabled(feature: "PROJECT_TAG_SETTING")
        }
        settings {
          id
          isStorageEnabled
        }
        managerSettingsPageBlock: block(kind: "MANAGER_SETTINGS_PAGE") {
          blocks {
            kind
          }
        }
        source(sourceKind:$sourceKind) {
          id
        }
      }
    }
  }
`;

export default OrganizationSettingsPage;
