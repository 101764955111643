// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import TagForm from '@shared/modules/Tag/forms/TagForm';

const useUpdateTagMutation = ({tagForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      tagForm: TagForm.toForm(tagForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateTagMutation.mutation,
    variables: {
      tagForm: TagForm.toMutation(form.values.tagForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateTagMutation.mutation = gql`
  mutation useUpdateTagMutation($tagForm: TagForm!) {
    response: updateTag(tagForm: $tagForm) {
      ${gql.errors}
      tag {
        id
      }
    }
  }
`;

export default useUpdateTagMutation;
