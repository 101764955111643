// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {BillRule, BillRuleType, Formula} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import BillRuleKind from '@shared/modules/Billing/enums/BillRuleKind';
import BillRuleTypeForm from '@shared/modules/Billing/forms/BillRuleTypeForm';
import Switch from 'modules/App/components/Switch';
import TextInputWithDropdown from 'modules/App/components/TextInputWithDropdown';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const handleChangeKind = ({kind, prevKind, form, field}) => {
  const isValueDisabled = kind === BillRuleKind.WAIVE_OVERTIME;
  const isValueTypeChanged =
    BillRuleType.getValueType(kind) !== BillRuleType.getValueType(prevKind);
  if (isValueDisabled || isValueTypeChanged) {
    form.setFieldValue(`${field}.value`, '');
    form.setFieldValue(`${field}.stringValue`, '');
    form.setFieldValue(`${field}.valueFormulaId`, null);
  }
};

const BillRuleTypeFields = ({form, field}) => {
  const {data} = useQuery(BillRuleTypeFields.query, {
    fetchPolicy: 'cache-and-network',
  });

  const billRuleTypeForm = _.get(form.values, field);
  const {kind} = billRuleTypeForm;
  const isValueDisabled = kind === BillRuleKind.WAIVE_OVERTIME;
  const formulas = _.get(data, 'viewer.viewingOrganization.formulas', []);
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        index={0}
        label={'Kind'}
        name={`${field}.kind`}
        component={DropdownInput}
        input={{
          required: !_.get(form.values, `${field}.kind`),
          options: BillRule.KINDS_DROPDOWN_OPTIONS,
          placeholder: 'Select a kind',
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          onChangeValue: (value, option, prevValue) =>
            handleChangeKind({kind: value, prevKind: prevValue, form, field}),
          style: {width: '100%'},
        }}
      />
      <Space height={20} />
      <TextInputWithDropdown
        form={form}
        index={1}
        textValueField={`${field}.name`}
        dropdownValueField={`${field}.nameFormulaId`}
        clearTextValue={''}
        label={'Name'}
        dropdownOptions={Formula.getFormulaDropdownOptions(formulas)}
        placeholder={'Formula or custom name'}
        noOptionsMessage={'No formula options'}
        isRequired={() =>
          !_.get(form.values, `${field}.name`) || !_.get(form.values, `${field}.nameFormulaId`)
        }
      />
      <Space height={20} />
      <TextInputWithDropdown
        form={form}
        index={2}
        textValueField={`${field}.value`}
        dropdownValueField={`${field}.valueFormulaId`}
        clearTextValue={''}
        label={'Value'}
        dropdownOptions={Formula.getFormulaDropdownOptions(formulas)}
        placeholder={isValueDisabled ? 'N/A' : 'Formula or custom value'}
        noOptionsMessage={'No formula options'}
        disabled={isValueDisabled}
        onBlur={() => {
          const billRuleTypeForm = _.get(form.values, `${field}`);
          const value = BillRuleTypeForm.getFormValueFromInput(billRuleTypeForm);
          form.setFieldValue(`${field}.value`, value);
        }}
      />
      <Space height={20} />
      <TextInputWithDropdown
        form={form}
        index={3}
        textValueField={`${field}.description`}
        dropdownValueField={`${field}.descriptionFormulaId`}
        clearTextValue={''}
        label={'Description'}
        dropdownOptions={Formula.getFormulaDropdownOptions(formulas)}
        placeholder={'Formula or custom description'}
        noOptionsMessage={'No formula options'}
      />
      <Space height={20} />
      <Row>
        <FieldInput.LabelText>Show Customers?</FieldInput.LabelText>
        <Space width={8} />
        <Switch
          isOn={billRuleTypeForm.isVisibleToCustomer}
          onChange={() =>
            form.setFieldValue(
              `${field}.isVisibleToCustomer`,
              !billRuleTypeForm.isVisibleToCustomer,
            )
          }
          color={colors.blue.interactive}
        />
      </Row>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillRuleTypeFields.query = gql`
  ${Formula.getFormulaDropdownOptions.fragment}
  query BillRuleTypeFields {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        formulas {
          id
          ...Formula_getFormulaDropdownOptions
        }
      }
    }
  }
`;

export default BillRuleTypeFields;
