// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  Checkbox,
  CurrencyInput,
  DropdownInput,
  Icon,
  Modal,
  Space,
  Styled,
} from '@supermove/components';
import {BillRuleForm} from '@supermove/forms';
import {useForm} from '@supermove/hooks';
import {BillRule} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// App
import Field from 'modules/App/components/Field';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const MobileContainer = Styled.View`
  background-color: ${colors.gray.background};
  flex: 1;
`;

const HeaderContainer = Styled.View`
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  padding-horizontal: 4px;
`;

const TitleText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const HeaderButton = Styled.ButtonV2`
  padding-horizontal: 12px;
  align-items: center;
`;

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const FieldsContainer = Styled.View`
`;

const IndexContainer = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const FieldInput = Styled.TextInput.H6`
  border-color: ${colors.gray.border};
`;

const InputLabelText = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)};
  padding-bottom: 4px;
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  height: 40px;
  padding-horizontal: 30px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;

const ButtonText = Styled.H5`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const ShowCustomerButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const ShowCustomerText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.blue.interactive};
`;

const ActivityIndicator = Styled.Loading`
`;

const CustomBillRuleHeader = ({handleClose}) => {
  return (
    <HeaderContainer>
      <Space height={16} />
      <Row>
        <HeaderButton onPress={handleClose}>
          <Icon source={Icon.ArrowLeft} size={20} color={colors.gray.primary} />
        </HeaderButton>
        <TitleText>Add Custom Rule</TitleText>
      </Row>
      <Space height={16} />
    </HeaderContainer>
  );
};

const BillRuleKindField = ({form, field}) => {
  return (
    <IndexContainer index={0}>
      <InputLabelText>Category</InputLabelText>
      <Field
        {...form}
        component={DropdownInput}
        name={field}
        input={{
          options: BillRule.KINDS_DROPDOWN_OPTIONS,
          placeholder: 'Select category',
          setFieldValue: form.setFieldValue,
          required: !_.get(form.values, field),
          style: {
            flex: 1,
            borderColor: colors.gray.border,
          },
          // When changing the category we reset the value to prevent
          // currency values from showing up in a non currency type category
          onChangeValue: () => form.setFieldValue('billRuleForm.values.0', ''),
        }}
      />
    </IndexContainer>
  );
};

const BillRuleNameField = ({form, field}) => {
  return (
    <IndexContainer index={1}>
      <InputLabelText>Name</InputLabelText>
      <Field
        {...form}
        name={field}
        component={FieldInput}
        input={{
          placeholder: 'Enter name',
          required: !_.get(form.values, field),
        }}
      />
    </IndexContainer>
  );
};

const BillRuleCurrencyValueField = ({form, field}) => {
  return (
    <IndexContainer index={2}>
      <InputLabelText>Value</InputLabelText>
      <Field
        {...form}
        name={field}
        component={CurrencyInput}
        input={{
          component: FieldInput,
          placeholder: 'Enter Value',
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          options: {
            enableEmptyValues: true,
          },
        }}
      />
    </IndexContainer>
  );
};

const BillRuleValueField = ({form, field}) => {
  return (
    <IndexContainer index={2}>
      <InputLabelText>Value</InputLabelText>
      <Field
        {...form}
        name={field}
        component={FieldInput}
        input={{
          placeholder: 'Enter Value',
          keyboardType: 'number-pad',
        }}
      />
    </IndexContainer>
  );
};

const BillRuleShowCustomerField = ({form, field}) => {
  const checked = _.get(form.values, field, false);
  return (
    <IndexContainer index={3}>
      <ShowCustomerButton onPress={() => form.setFieldValue(field, !checked)}>
        <Checkbox
          checked={checked}
          color={colors.blue.interactive}
          borderColor={checked ? colors.blue.interactive : colors.gray.border}
          size={20}
          iconSize={14}
          disabled
          onChange={() => {}}
        />
        <Space width={8} />
        <ShowCustomerText>Show Customer?</ShowCustomerText>
      </ShowCustomerButton>
    </IndexContainer>
  );
};

const CustomBillRuleFields = ({form}) => {
  const valueType = BillRuleForm.getValueType(form.values.billRuleForm);
  return (
    <FieldsContainer>
      <Space height={20} />
      <BillRuleKindField form={form} field={'billRuleForm.kind'} />
      <Space height={16} />
      <BillRuleNameField form={form} field={'billRuleForm.name'} />
      <Space height={16} />
      {valueType === BillRule.VALUE_TYPES.CURRENCY && (
        <React.Fragment>
          <BillRuleCurrencyValueField form={form} field={'billRuleForm.values.0'} />
          <Space height={16} />
        </React.Fragment>
      )}
      {valueType === BillRule.VALUE_TYPES.HOURS && (
        <React.Fragment>
          <BillRuleValueField form={form} field={'billRuleForm.values.0'} />
          <Space height={16} />
        </React.Fragment>
      )}
      <BillRuleShowCustomerField form={form} field={'billRuleForm.isVisibleToCustomer'} />
      <Space height={16} />
    </FieldsContainer>
  );
};

const CustomBillRuleFooter = ({handleClose, handleSubmit, submitting}) => {
  return (
    <Actions>
      <Button color={colors.gray.background} onPress={handleClose} disabled={submitting}>
        <ButtonText color={colors.gray.tertiary}>Cancel</ButtonText>
      </Button>
      <Space width={8} />
      <Button
        color={submitting ? colors.gray.border : colors.blue.interactive}
        onPress={handleSubmit}
        disabled={submitting}
      >
        {submitting ? (
          <ActivityIndicator color={colors.white} size={'small'} />
        ) : (
          <ButtonText color={colors.white}>Done</ButtonText>
        )}
      </Button>
    </Actions>
  );
};

const EditBillCustomRuleModal = ({isOpen, handleClose, handleSubmit}) => {
  const form = useForm({
    initialValues: {
      billRuleForm: BillRuleForm.new({}),
    },
    validate: ({billRuleForm}) => BillRuleForm.validate({billRuleForm, formField: 'billRuleForm'}),
    onSubmit: ({billRuleForm}) => {
      handleSubmit(billRuleForm);
      handleClose();
    },
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <MobileContainer>
        <CustomBillRuleHeader handleClose={handleClose} />
        <Space height={16} />
        <ContentContainer>
          <IndexContainer index={0}>
            <CustomBillRuleFields form={form} />
          </IndexContainer>
          <Space height={16} />
          <IndexContainer index={1}>
            <CustomBillRuleFooter
              handleClose={handleClose}
              // We wrap in a set timeout to ensure all fields complete any onBlur
              // logic before submitting the form.
              handleSubmit={() => setTimeout(() => form.submitForm(), 0)}
            />
          </IndexContainer>
        </ContentContainer>
      </MobileContainer>
    </Modal.Content>
  );
};

export default EditBillCustomRuleModal;
