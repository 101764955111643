// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon, Popover, MultiDropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, usePopover} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import TagDropdownInputField from 'modules/Tag/components/TagDropdownInputField';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const Header = Styled.Text`
  ${Typography.Subheading}
`;

const FilterContainer = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const getProjectTypeDropdownOptions = (projectTypes) => {
  return _.map(projectTypes, (projectType) => ({
    label: projectType.name,
    value: projectType.id,
  }));
};

const getSalespersonDropdownOptions = (salespersons) => {
  return _.map(salespersons, (salesperson) => ({
    label: salesperson.label,
    value: _.toString(salesperson.value),
  }));
};

const getButtonText = ({params}) => {
  const {date, slugs, searchQuery, ...countedFilters} = params;
  const activeFilters = _.compact([..._.values(countedFilters)]);
  return `Filters${_.isEmpty(activeFilters) ? '' : ` (${activeFilters.length})`}`;
};

const ClearFiltersButton = ({handleClearProjectsListFilters}) => {
  const {params, navigator} = useNavigationDOM();
  const {location} = navigator;

  return (
    <SecondaryButton
      text={'Clear Filters'}
      onPress={() => {
        handleClearProjectsListFilters({baseUrl: location.pathname, navigator, params});
      }}
    />
  );
};

const TagsFilter = ({index, handleUpdateParam, organization}) => {
  const {params, navigator} = useNavigationDOM();
  const {location} = navigator;

  const filterByArchivedTag = organization.companySettings.tags.filter((tag) => !tag.isArchived);

  const projectTags = filterByArchivedTag.filter((tag) => tag.category === TagCategory.PROJECT);
  const jobTags = filterByArchivedTag.filter((tag) => tag.category === TagCategory.JOB);

  return (
    <React.Fragment>
      <TagDropdownInputField
        options={
          projectTags
            ? projectTags.map((tag) => ({
                value: tag.id,
                label: `${tag.emoji} ${tag.name}`,
              }))
            : []
        }
        placeholder={'Select project tag'}
        label={'Project Tag(s)'}
        index={index}
        value={_.get(params, 'projectTagIds', [])}
        onChangeValue={(projectTagIds) => {
          handleUpdateParam({
            baseUrl: location.pathname,
            navigator,
            params,
            paramKey: 'projectTagIds',
            paramValue: projectTagIds,
          });
        }}
      />
      <Space height={12} />
      <TagDropdownInputField
        options={
          jobTags
            ? jobTags.map((tag) => ({
                value: tag.id,
                label: `${tag.emoji} ${tag.name}`,
              }))
            : []
        }
        placeholder={'Select job tag'}
        label={'Job Tag(s)'}
        index={index + 1}
        value={_.get(params, 'jobTagIds', [])}
        onChangeValue={(jobTagIds) => {
          handleUpdateParam({
            baseUrl: location.pathname,
            navigator,
            params,
            paramKey: 'jobTagIds',
            paramValue: jobTagIds,
          });
        }}
      />
    </React.Fragment>
  );
};

const ProjectTypesFilter = ({index, handleUpdateParam, organization}) => {
  const {params, navigator} = useNavigationDOM();
  const {location} = navigator;

  return (
    <FilterContainer index={index}>
      <FieldInput.LabelText>Project Type(s)</FieldInput.LabelText>
      <Space height={4} />
      <MultiDropdownInput
        options={
          organization
            ? getProjectTypeDropdownOptions(Organization.getProjectTypes(organization))
            : []
        }
        placeholder={'Select project type(s)'}
        style={{width: '100%'}}
        value={_.get(params, 'projectTypes', [])}
        onChangeValue={(projectTypes) => {
          handleUpdateParam({
            baseUrl: location.pathname,
            navigator,
            params,
            paramKey: 'projectTypes',
            paramValue: projectTypes,
          });
        }}
        isSearchable
      />
    </FilterContainer>
  );
};

const SalespersonFilter = ({index, handleUpdateParam, organization}) => {
  const {params, navigator} = useNavigationDOM();
  const {location} = navigator;

  return (
    <FilterContainer index={index}>
      <FieldInput.LabelText>Salespeople</FieldInput.LabelText>
      <Space height={4} />
      <MultiDropdownInput
        options={
          organization
            ? getSalespersonDropdownOptions(Organization.getSalespersonOptions(organization))
            : []
        }
        placeholder={'Select salespeople'}
        style={{width: '100%'}}
        value={_.get(params, 'salespersons', [])}
        onChangeValue={(salespersons) => {
          handleUpdateParam({
            baseUrl: location.pathname,
            navigator,
            params,
            paramKey: 'salespersons',
            paramValue: salespersons,
          });
        }}
        isSearchable
      />
    </FilterContainer>
  );
};

const CapacityProjectListPopoverContent = ({
  handleUpdateParam,
  handleClearProjectsListFilters,
  organization,
}) => {
  return (
    <ResponsivePopover.StaticContainer width={288}>
      <ContentContainer>
        <Space height={16} />
        <Header>Filters</Header>
        <Space height={12} />
        <ProjectTypesFilter
          index={1}
          handleUpdateParam={handleUpdateParam}
          organization={organization}
        />
        <Space height={12} />
        <SalespersonFilter
          index={2}
          handleUpdateParam={handleUpdateParam}
          organization={organization}
        />
        <Space height={12} />
        {organization.features.isEnabledProjectTag && (
          <React.Fragment>
            <TagsFilter
              index={3}
              handleUpdateParam={handleUpdateParam}
              organization={organization}
            />
            <Space height={16} />
          </React.Fragment>
        )}
        <ClearFiltersButton handleClearProjectsListFilters={handleClearProjectsListFilters} />
        <Space height={16} />
      </ContentContainer>
    </ResponsivePopover.StaticContainer>
  );
};

const CapacityProjectListFiltersPopover = ({
  popover,
  handleUpdateParam,
  handleClearProjectsListFilters,
  organization,
}) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <CapacityProjectListPopoverContent
        handleUpdateParam={handleUpdateParam}
        handleClearProjectsListFilters={handleClearProjectsListFilters}
        organization={organization}
      />
    </Popover>
  );
};

const CapacityProjectListFilters = ({
  handleUpdateParam,
  organization,
  handleClearProjectsListFilters,
}) => {
  const {params} = useNavigationDOM();
  const capacityProjectListFiltersPopover = usePopover({
    name: 'Capacity project list filters popover',
  });

  return (
    <React.Fragment>
      <Popover.Content innerRef={capacityProjectListFiltersPopover.ref}>
        <SecondaryButton
          text={getButtonText({params})}
          onPress={capacityProjectListFiltersPopover.handleOpen}
          iconLeft={Icon.Filter}
        />
      </Popover.Content>
      <CapacityProjectListFiltersPopover
        popover={capacityProjectListFiltersPopover}
        handleUpdateParam={handleUpdateParam}
        handleClearProjectsListFilters={handleClearProjectsListFilters}
        organization={organization}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityProjectListFilters.fragment = gql`
  ${Organization.getProjectTypes.fragment}
  ${Organization.getSalespersonOptions.fragment}
  ${TagDropdownInputField.fragment}

  fragment CapacityProjectListFilters on Organization {
    id
    companySettings {
      tags {
        id
        name
        emoji
        category
        isArchived
        ...TagDropdownInputField
      }
    }
    features {
      isEnabledProjectTag: isEnabled(feature: "PROJECT_TAG")
    }
    ...Organization_getProjectTypes
    ...Organization_getSalespersonOptions
  }
`;

export default CapacityProjectListFilters;
