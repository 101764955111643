// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import ManagerSearchField from 'modules/App/components/ManagerSearch/components/ManagerSearchField';

const Wrapper = Styled.View`
  flex: 1;
  height: 100%;
  align-items: center;
  padding-top: ${(props) => (props.mobile ? 50 : 100)}px;
  padding-horizontal: 20px;
  background-color: rgba(75, 75, 75, 0.75);
`;

const ManagerSearch = ({organization, trigger}) => {
  const responsive = useResponsive();

  return (
    <Modal trigger={trigger}>
      {({handleClose}) => (
        <Wrapper pointerEvents={'box-none'} {...responsive}>
          {organization && (
            <ManagerSearchField organization={organization} handleClose={handleClose} />
          )}
        </Wrapper>
      )}
    </Modal>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ManagerSearch.propTypes = {
  organization: PropTypes.object.isRequired,
  trigger: PropTypes.func.isRequired,
};

ManagerSearch.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
ManagerSearch.fragment = gql`
  ${ManagerSearchField.fragment}

  fragment ManagerSearch on User {
    id
    ...ManagerSearchField
  }
`;

export default ManagerSearch;
