// Libraries
import React from 'react';

// Supermove
import {Space, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import BillTypeForm from '@shared/modules/Billing/forms/BillTypeForm';
import useUpdateBillTypeMutation from '@shared/modules/Billing/hooks/useUpdateBillTypeMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import SimpleHeader from 'modules/App/components/SimpleHeader';
import BillingLibraryBillTypeEditor from 'modules/Organization/Settings/BillingLibraries/BillTypes/components/BillingLibraryBillTypeEditor';

const Container = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  flex: 1;
  height: 100%;
  background-color: ${colors.gray.background};
`;

const Content = Styled.View`
  flex: 1;
`;

const BillTypeEditorBoxContainer = Styled.View`
  z-index: 100;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`;

const BillTypeEditorBox = Styled.View`
  flex: 1;
  max-width: 825px;
  background-color: ${colors.white};
  border-radius: 20px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  padding-horizontal: 32px;
`;

const UpdateBillTypePageContent = ({billType, userId}) => {
  const {navigator} = useNavigationDOM();
  const billTypeForm = BillTypeForm.edit(billType, {userId});
  const {form, handleSubmit} = useUpdateBillTypeMutation({
    billTypeForm,
    onSuccess: () => {
      navigator.push(
        `/settings/billing/billing-libraries/${billType.billingLibrary.uuid}/bill-templates`,
      );
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <Container>
      <SimpleHeader
        title={'Edit Bill Template'}
        showLine
        showActionButton
        showBackButton
        actionButtonText={'Save'}
        onPress={handleSubmit}
      />
      <ContentContainer>
        <ScrollView contentContainerStyle={{flexGrow: 1}}>
          <Content>
            <Space height={24} />
            <BillTypeEditorBoxContainer>
              <BillTypeEditorBox>
                <BillingLibraryBillTypeEditor
                  form={form}
                  billingLibrary={billType.billingLibrary}
                  userId={userId}
                />
              </BillTypeEditorBox>
            </BillTypeEditorBoxContainer>
            <Space height={24} />
          </Content>
        </ScrollView>
      </ContentContainer>
    </Container>
  );
};

const UpdateBillTypePage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(UpdateBillTypePage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      billTypeUuid: params.billTypeUuid,
    },
  });

  if (loading) {
    return (
      <SidebarPageV2 selected={'settings'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  return (
    <SidebarPageV2 selected={'settings'}>
      <UpdateBillTypePageContent billType={data.billType} userId={data.viewer.id} />
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateBillTypePage.query = gql`
  ${BillTypeForm.edit.fragment}
  ${BillingLibraryBillTypeEditor.fragment}

  query UpdateBillTypePage(
    $billTypeUuid: String!,
  ) {
    ${gql.query}
    viewer {
      id
    }
    billType(billTypeUuid: $billTypeUuid) {
      id
      ...BillTypeForm_edit
      billingLibrary {
        id
        uuid
        ...BillingLibraryBillTypeEditor
      }
    }
  }
`;

export default UpdateBillTypePage;
