// Libararies
import React from 'react';

// Supermove
import {Styled, Icon} from '@supermove/components';
import {useState, useHover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import TextTooltip from 'modules/App/components/TextTooltip';

const TextInput = Styled.TextInput`
  ${({vars}) => (vars.isMedium ? Typography.Body2 : Typography.Body3)}
  height: ${({vars}) => (vars.isMedium ? 44 : 36)}px;
  color: ${({disabled}) => (disabled ? colors.gray.secondary : colors.gray.primary)};
  background-color: ${({disabled}) => (disabled ? colors.gray.disabled : colors.white)};
  padding-right: 40px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
`;

const IconButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  position: relative;
  right: 29px;
`;

const FIELD_SIZE = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
};

const getIsMedium = (size) => {
  return size === FIELD_SIZE.MEDIUM;
};

const Input = ({innerRef, size, style, tooltipText, secureTextEntry, ...props}) => {
  const [textIsHidden, setTextIsHidden] = useState(secureTextEntry);
  const {ref, isHovered} = useHover();

  return (
    <Row>
      <TextTooltip text={tooltipText}>
        <Container ref={ref}>
          <TextInput
            ref={innerRef}
            style={{
              ...style,
              borderColor: isHovered ? colors.Blue201 : colors.gray.tertiary,
            }}
            vars={{isMedium: getIsMedium(size)}}
            {...props}
            secureTextEntry={textIsHidden}
            disabled
          />
        </Container>
      </TextTooltip>
      {secureTextEntry && (
        <IconButton onPress={() => setTextIsHidden(!textIsHidden)}>
          <Icon
            source={textIsHidden ? Icon.Eye : Icon.EyeSlash}
            color={colors.gray.secondary}
            size={16}
          />
        </IconButton>
      )}
    </Row>
  );
};

const FieldInputClickToCopy = ({label, style, value, isSecure, ...props}) => {
  const defaultTooltipText = 'Click to copy to clipboard';
  const isCopiedText = 'Copied to clipboard';
  const [textIsCopied, setTextIsCopied] = useState(false);

  return (
    <FieldInput
      label={label}
      component={Input}
      input={{
        style: {cursor: 'pointer', ...style},
        value,
        secureTextEntry: isSecure,
        tooltipText: textIsCopied ? isCopiedText : defaultTooltipText,
        onPointerDown: () => {
          /* global navigator */
          navigator.clipboard.writeText(value);
          setTextIsCopied(true);
        },
        onMouseLeave: () => {
          setTextIsCopied(false);
        },
      }}
      {...props}
    />
  );
};

export default FieldInputClickToCopy;
