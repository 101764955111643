// Supermove
import {Currency} from '@supermove/utils';

// App
import ValueForm from '@shared/modules/Billing/forms/ValueForm';

const editFromBillItemForm = (billItemForm, {organizationId, valueForms}) => ({
  organizationId,
  nameFormulaId: billItemForm.nameFormulaId,
  amountFormulaId: billItemForm.amountFormulaId,
  minQuantityFormulaId: billItemForm.minQuantityFormulaId,
  maxQuantityFormulaId: billItemForm.maxQuantityFormulaId,
  moverPositionId: billItemForm.moverPositionId,

  amount: billItemForm.amount,
  maxQuantity: billItemForm.maxQuantity,
  minQuantity: billItemForm.minQuantity,
  name: billItemForm.name,

  valueForms,
});

const toMutation = ({
  organizationId,
  nameFormulaId,
  amountFormulaId,
  minQuantityFormulaId,
  maxQuantityFormulaId,
  moverPositionId,
  amount,
  maxQuantity,
  minQuantity,
  name,
  valueForms,
}) => ({
  organizationId,
  nameFormulaId,
  amountFormulaId,
  minQuantityFormulaId,
  maxQuantityFormulaId,
  moverPositionId,
  amount: Currency.toMutation(amount),
  maxQuantity: Number(maxQuantity),
  minQuantity: Number(minQuantity),
  name,
  valueForms: valueForms.map((valueForm) => ValueForm.toMutation(valueForm)),
});

const PreviewBillItemForm = {
  editFromBillItemForm,
  toMutation,
};

export default PreviewBillItemForm;
