// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Datetime} from '@supermove/utils';

// App
import RecurringTaskCadenceKind from '@shared/modules/Accounting/enums/RecurringTaskCadenceKind';
import RecurringTaskCadenceRepeatsOn from '@shared/modules/Accounting/enums/RecurringTaskCadenceRepeatsOn';
import RecurringTaskCadenceRepeatsOnKind from '@shared/modules/Accounting/enums/RecurringTaskCadenceRepeatsOnKind';

const _new = ({organizationId, kind}) => ({
  // Relations
  recurringTaskId: null,
  organizationId,

  // Inputs
  kind,
  cadenceKind: RecurringTaskCadenceKind.WEEKLY,
  cadenceRepeatsOnKind: RecurringTaskCadenceRepeatsOnKind.WEEKDAY,
  cadenceInterval: 1,
  cadenceRepeatsOn: RecurringTaskCadenceRepeatsOn.CADENCE_REPEATS_ON_WEEKDAY_MAP.MONDAY.value,
  startThresholdDate: null,
  endThresholdDate: null,
  isEnabled: true,
  isDeleted: false,
});

const newForMonthly = ({organizationId, kind, cadenceKind}) => ({
  // Relations
  recurringTaskId: null,
  organizationId,

  // Inputs
  kind,
  cadenceKind,
  cadenceRepeatsOnKind: RecurringTaskCadenceRepeatsOnKind.EXACT_DATE,
  cadenceInterval: 1,
  cadenceRepeatsOn: 1,
  startThresholdDate: null,
  endThresholdDate: null,
  isEnabled: true,
  isDeleted: false,
});

const edit = withFragment(
  (recurringTask) => ({
    recurringTaskId: recurringTask.id,
    organizationId: recurringTask.organizationId,
    kind: recurringTask.kind,
    cadenceKind: recurringTask.cadenceKind,
    cadenceRepeatsOnKind: recurringTask.cadenceRepeatsOnKind,
    cadenceInterval: recurringTask.cadenceInterval,
    cadenceRepeatsOn: recurringTask.cadenceRepeatsOn,
    startThresholdDate: recurringTask.startThresholdDate,
    endThresholdDate: recurringTask.endThresholdDate,
    isEnabled: recurringTask.isEnabled,
    isDeleted: recurringTask.isDeleted,
  }),
  gql`
    fragment RecurringTaskForm_edit on RecurringTask {
      id
      organizationId
      kind
      cadenceKind
      cadenceRepeatsOnKind
      cadenceInterval
      cadenceRepeatsOn
      startThresholdDate
      endThresholdDate
      isEnabled
      isDeleted
    }
  `,
);

const upsert = withFragment(
  (recurringTask, {organizationId, kind}) => {
    if (recurringTask) {
      return edit(recurringTask);
    } else {
      return _new({organizationId, kind});
    }
  },
  gql`
    ${edit.fragment}

    fragment RecurringTaskForm_upsert on RecurringTask {
      id
      ...RecurringTaskForm_edit
    }
  `,
);

const toForm = ({
  recurringTaskId,
  organizationId,
  kind,
  cadenceKind,
  cadenceRepeatsOnKind,
  cadenceInterval,
  cadenceRepeatsOn,
  startThresholdDate,
  endThresholdDate,
  isEnabled,
  isDeleted,
}) => {
  return {
    recurringTaskId,
    organizationId,
    kind,
    cadenceKind,
    cadenceRepeatsOnKind,
    cadenceInterval,
    cadenceRepeatsOn,
    startThresholdDate: startThresholdDate ? Datetime.toFormDate(startThresholdDate) : null,
    endThresholdDate: endThresholdDate ? Datetime.toFormDate(endThresholdDate) : null,
    isEnabled,
    isDeleted,
  };
};

const toMutation = ({
  recurringTaskId,
  organizationId,
  kind,
  cadenceKind,
  cadenceRepeatsOnKind,
  cadenceInterval,
  cadenceRepeatsOn,
  startThresholdDate,
  endThresholdDate,
  isEnabled,
  isDeleted,
}) => {
  return {
    recurringTaskId,
    organizationId,
    kind,
    cadenceKind,
    cadenceRepeatsOnKind,
    cadenceInterval,
    cadenceRepeatsOn,
    startThresholdDate: startThresholdDate ? Datetime.toMutationDate(startThresholdDate) : null,
    endThresholdDate: endThresholdDate ? Datetime.toMutationDate(endThresholdDate) : null,
    isEnabled,
    isDeleted,
  };
};

const RecurringTaskForm = {
  new: _new,
  newForMonthly,
  edit,
  upsert,
  toForm,
  toMutation,
};

export default RecurringTaskForm;
