// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import InviteUserForm from '@shared/modules/User/forms/InviteUserForm';

const useInviteUserToOrganizationMutation = ({inviteUserForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      inviteUserForm: InviteUserForm.toForm(inviteUserForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useInviteUserToOrganizationMutation.mutation,
    variables: {
      inviteUserForm: InviteUserForm.toMutation(form.values.inviteUserForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useInviteUserToOrganizationMutation.mutation = gql`
  mutation InviteUserToOrganizationMutation($inviteUserForm: InviteUserForm!) {
    response: inviteUserToOrganization(inviteUserForm: $inviteUserForm) {
      ${gql.errors}
      user {
        id
      }
    }
  }
`;

export default useInviteUserToOrganizationMutation;
