// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job, Location, Organization, Project, User} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Phone, pluralize} from '@supermove/utils';

// App
import CommercialEquipmentsList from 'modules/CommercialCatalog/components/CommercialEquipmentsList';
import CommercialMaterialsList from 'modules/CommercialCatalog/components/CommercialMaterialsList';
import {DocumentHeaderOrganization} from 'modules/Document/components';
import BillingProjectBillsListV1 from 'modules/Project/Billing/components/BillingProjectBillsListV1';
import BillingProjectPaymentsList from 'modules/Project/Billing/components/BillingProjectPaymentsList';

import PrintFieldValue from './PrintFieldValue';

const Container = Styled.View`
  width: 100%;
  background-color: ${colors.white};
`;

const Section = Styled.View`
  width: 100%;
  z-index: ${({sectionIndex = 0}) => 100 - sectionIndex};
`;

const SectionSpace = Styled.View`
  margin-top: 20px;
`;

const Title = Styled.H5`
  margin-bottom: 5px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const LocationTitle = Styled.H6`
  margin-bottom: 5px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Row = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
`;

const RowSpace = Styled.View`
  margin-top: 5px;
`;

const FieldSpace = Styled.View`
  margin-left: 5px;
`;

const SmallLine = Styled.View`
  height: 2px;
  margin-vertical: 10px;
  background-color: ${colors.gray.border};
`;

const NOT_APPLICABLE_STRING = 'n/a';

const ProjectSection = ({sectionIndex, job}) => {
  const {client} = job.project;
  const isPrimaryClientNameDifferent = client.name !== client.primaryContact.fullName;

  return (
    <Section sectionIndex={sectionIndex}>
      <Title>Project Information</Title>
      <Row>
        <PrintFieldValue
          label={'Project Name'}
          value={Project.getName(job.project)}
          style={{
            flex: 1,
            marginTop: 3,
          }}
        />
      </Row>
      {!!job.project.size && (
        <Row>
          <PrintFieldValue
            label={'Project Size'}
            value={job.project.size}
            style={{
              flex: 1,
              marginTop: 3,
            }}
          />
        </Row>
      )}
      <Row>
        <PrintFieldValue
          label={'Project Description'}
          value={job.project.description}
          empty={'None'}
          style={{
            flex: 1,
            marginTop: 3,
          }}
        />
      </Row>
      <Row>
        <PrintFieldValue
          label={'Job Name'}
          value={job.name}
          empty={'None'}
          style={{
            flex: 1,
            marginTop: 3,
          }}
        />
      </Row>
      <Row>
        <PrintFieldValue
          label={'Job Description'}
          value={job.description}
          empty={'None'}
          style={{
            flex: 1,
            marginTop: 3,
          }}
        />
      </Row>
      {isPrimaryClientNameDifferent && (
        <Row>
          <PrintFieldValue
            label={'Company Name'}
            value={client.name}
            style={{
              flex: 1,
              marginTop: 3,
            }}
          />
        </Row>
      )}
      <Row>
        <PrintFieldValue
          label={'Contact Name'}
          value={client.primaryContact.fullName}
          style={{
            flex: 1,
            marginTop: 3,
          }}
        />
        <PrintFieldValue
          label={'Contact Phone'}
          value={Phone.display(client.primaryContact.phoneNumber)}
          empty={'None'}
          style={{
            flex: 1,
            marginTop: 3,
          }}
        />
        <PrintFieldValue
          label={'Contact Email'}
          value={client.primaryContact.email}
          empty={'None'}
          style={{
            flex: 2,
            marginTop: 3,
          }}
        />
      </Row>
      <Row>
        <PrintFieldValue
          label={'Salesperson'}
          value={job.bookedBy ? User.getFullName(job.bookedBy) : ''}
          empty={'None'}
          style={{
            flex: 1,
            marginTop: 3,
          }}
        />
      </Row>
    </Section>
  );
};

const EstimateSection = ({sectionIndex, job}) => (
  <Section sectionIndex={sectionIndex}>
    <Title>Survey Information</Title>
    <Row index={0}>
      <PrintFieldValue
        empty={'Not Scheduled'}
        label={'Date'}
        value={Job.getDisplayDate(job, 'ddd, M/D/YY')}
        style={{
          flex: 1,
          marginTop: 3,
        }}
      />
      <FieldSpace />
      <PrintFieldValue
        empty={'Not Scheduled'}
        label={job.organization.jobStartTimeField}
        value={Job.getDisplayArrivalWindow(job)}
        style={{
          flex: 1,
          marginTop: 3,
        }}
      />
    </Row>
  </Section>
);

const MoveSection = ({sectionIndex, job}) => (
  <Section sectionIndex={sectionIndex}>
    <Title>Move Information</Title>
    <Row index={0}>
      <PrintFieldValue
        empty={'Not Scheduled'}
        label={'Date'}
        value={Job.getDisplayDate(job, 'ddd, M/D/YY')}
        style={{
          flex: 1,
          marginTop: 3,
        }}
      />
      <FieldSpace />
      <PrintFieldValue
        empty={'Not Scheduled'}
        label={job.organization.jobStartTimeField}
        value={Job.getDisplayArrivalWindow(job)}
        style={{
          flex: 1,
          marginTop: 3,
        }}
      />
    </Row>
    <Row index={1}>
      <PrintFieldValue
        label={'Trucks'}
        value={[
          `${pluralize('truck', job.numberOfTrucks, true)} required`,
          job.jobTrucks.map((jobTruck) => jobTruck.truck.name).join(', '),
        ].join('\n')}
        style={{
          flex: 1,
          marginTop: 3,
        }}
      />
      <FieldSpace />
      <PrintFieldValue
        label={'Movers'}
        value={[
          `${Job.getCrewSizeText(job)} required`,
          job.activeJobUsers.map((jobUser) => jobUser.user.fullName).join(', '),
        ].join('\n')}
        style={{
          flex: 1,
          marginTop: 3,
        }}
      />
    </Row>
  </Section>
);

const ProjectBillingSection = ({sectionIndex, job}) => {
  return (
    <Section sectionIndex={sectionIndex}>
      <Title>Billing Information</Title>
      <BillingProjectBillsListV1 project={job.project} isDocument showBillRules />
      <Space height={20} />
      <BillingProjectPaymentsList project={job.project} showBalance={false} />
    </Section>
  );
};

const BillingSection = ({sectionIndex, job}) => {
  return <ProjectBillingSection sectionIndex={sectionIndex} job={job} />;
};

// eslint-disable-next-line
const CommercialCatalogSection = ({sectionIndex, job}) => {
  if (!job.organization.features.isEnabledCommercialEquipmentAndMaterials) {
    return null;
  }

  return (
    <Section sectionIndex={sectionIndex}>
      <Title>Equipment / Materials Information</Title>
      <CommercialEquipmentsList job={job} />
      <Space height={8} />
      <CommercialMaterialsList job={job} />
    </Section>
  );
};

const LocationsSection = ({sectionIndex, job}) => (
  <Section sectionIndex={sectionIndex}>
    <Title>Locations Information</Title>
    {job.locations.map((location, index) => (
      <React.Fragment key={index}>
        <LocationSection count={job.locations.length} index={index} location={location} />
        {index < job.locations.length - 1 && <SmallLine />}
      </React.Fragment>
    ))}
  </Section>
);

const LocationSection = ({count, index, location}) => (
  <Section sectionIndex={index}>
    <LocationTitle>{Location.getDisplayName(location, {count})}</LocationTitle>
    <Row index={0}>
      <PrintFieldValue
        label={'Address'}
        style={{
          flex: 1,
        }}
        value={Location.getDisplayLocation(location)}
      />
    </Row>
    <RowSpace />
    <Row index={1}>
      <PrintFieldValue
        label={'Location Type'}
        style={{
          flex: 1,
        }}
        empty={NOT_APPLICABLE_STRING}
        value={location.buildingType}
      />
      <PrintFieldValue
        label={'Floor #'}
        style={{
          flex: 1,
        }}
        empty={NOT_APPLICABLE_STRING}
        value={location.floorNumber}
      />
      <PrintFieldValue
        label={'# of Stairs'}
        style={{
          flex: 1,
        }}
        empty={NOT_APPLICABLE_STRING}
        value={location.stairDescription}
      />
      <PrintFieldValue
        label={'Elevator?'}
        value={location.hasElevator ? 'Yes' : 'No'}
        style={{
          flex: 1,
        }}
      />
      <PrintFieldValue
        label={'Long Walk?'}
        value={location.hasLongWalk ? 'Yes' : 'No'}
        style={{
          flex: 1,
        }}
      />
    </Row>
    <RowSpace />
    <Row index={2}>
      <PrintFieldValue
        label={Job.FIELDS.LOCATION_NOTES}
        style={{
          flex: 1,
        }}
        empty={'None'}
        value={location.notes}
      />
    </Row>
  </Section>
);

const AdditionalSection = ({sectionIndex, job}) => (
  <Section sectionIndex={sectionIndex}>
    <Title>Additional Information</Title>
    {_.map(Job.getAdditionalItems(job), (value, key) => (
      <React.Fragment key={key}>
        <Row>
          <PrintFieldValue
            label={Organization.getJobFormAdditionalItemLabel(job.organization, {key})}
            value={Organization.getJobFormAdditionalItemValue(job.organization, {key, value})}
            empty={'None'}
            style={{
              flex: 1,
            }}
          />
        </Row>
        <RowSpace />
      </React.Fragment>
    ))}
  </Section>
);

const PrepareFieldValues = ({job}) => (
  <Section>
    <ProjectSection sectionIndex={0} job={job} />
    <SectionSpace />
    {job.jobType.kind === Job.KIND.ESTIMATE ? (
      <EstimateSection sectionIndex={1} job={job} />
    ) : (
      <MoveSection sectionIndex={1} job={job} />
    )}
    <SectionSpace />
    <LocationsSection sectionIndex={2} job={job} />
    {!_.isEmpty(_.map(Job.getAdditionalItems(job))) && (
      <React.Fragment>
        <SectionSpace />
        <AdditionalSection sectionIndex={3} job={job} />
      </React.Fragment>
    )}
    <SectionSpace />
    <BillingSection sectionIndex={4} job={job} />
    <SectionSpace />
    {/* <CommercialCatalogSection sectionIndex={5} job={job} /> */}
  </Section>
);

const PrintJobDocument = ({job}) => (
  <Container>
    <DocumentHeaderOrganization
      title={`${job.jobType.name} ${job.identifier} Information`}
      organization={job.organization}
    />
    <PrepareFieldValues job={job} />
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
PrintJobDocument.propTypes = {
  job: PropTypes.object.isRequired,
};

PrintJobDocument.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
PrintJobDocument.fragment = gql`
  ${Job.getDisplayArrivalWindow.fragment}
  ${Job.getDisplayDate.fragment}
  ${Location.getDisplayName.fragment}
  ${Location.getDisplayLocation.fragment}
  ${Organization.getJobFormAdditionalItemLabel.fragment}
  ${Organization.getJobFormAdditionalItemValue.fragment}
  ${Project.getName.fragment}
  ${BillingProjectBillsListV1.fragment}
  ${BillingProjectPaymentsList.fragment}
  ${CommercialEquipmentsList.fragment}
  ${CommercialMaterialsList.fragment}
  ${DocumentHeaderOrganization.fragment}

  fragment PrintJobDocument on Job {
    id
    identifier
    name
    description
    crewSize
    numberOfTrucks
    hourlyRate
    travelFee
    fuelFee
    hourMinimum
    totalDistance
    startTime1
    startTime2
    additionalNotes
    additionalItems
    additionalAddresses
    customerInputNotes
    parkingRestrictions
    organization {
      id
      slug
      hasFuelFee
      jobStartTimeField
      settings {
        id
        jobFormCustomValues
      }
      features {
        isEnabledCommercialEquipmentAndMaterials: isEnabled(
          feature: "COMMERCIAL_EQUIPMENT_AND_MATERIALS"
        )
      }
      ...DocumentHeaderOrganization
      ...Organization_getJobFormAdditionalItemLabel
      ...Organization_getJobFormAdditionalItemValue
    }
    project {
      id
      size
      description
      client {
        id
        name
        primaryContact {
          id
          fullName
          phoneNumber
          email
        }
      }
      ...BillingProjectBillsListV1
      ...BillingProjectPaymentsList
      ...Project_getName
    }
    jobType {
      id
      kind
      name
    }
    locations {
      id
      floorNumber
      notes
      hasElevator
      hasLongWalk
      stairCount
      stairDescription
      walkingDistance
      elevatorReservationTime
      buildingType
      ...Location_getDisplayLocation
      ...Location_getDisplayName
    }
    bookedBy {
      id
      firstName
      lastName
    }
    jobTrucks {
      id
      truck {
        id
        name
      }
    }
    activeJobUsers {
      id
      user {
        id
        fullName
      }
    }
    ...Job_getDisplayArrivalWindow
    ...Job_getDisplayDate
    ...CommercialEquipmentsList
    ...CommercialMaterialsList
  }
`;

export default PrintJobDocument;
