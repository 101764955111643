// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {FlatList, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Bill, BillModifier, LineItem} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Currency} from '@supermove/utils';

const Container = Styled.View`
  align-self: stretch;
`;

const Header = Styled.View`
  align-self: stretch;
  flex-direction: row;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  background-color: ${colors.gray.background};
`;

const FlatListCell = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const FlatListSpace = Styled.View`
  height: 10px;
`;

const Item = Styled.View`
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding-horizontal: 10px;
`;

const Cell = Styled.View`
`;

const Text = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
`;

const Name = Styled.H7`
`;

const Description = Styled.H7`
  color: ${colors.gray.secondary};
`;

const Quantity = Styled.H7`
  color: ${colors.gray.secondary};
`;

const Price = Styled.H7`
  color: ${colors.gray.secondary};
`;

const Amount = Styled.H7`
  text-align: right;
`;

const Subtotal = Styled.H7`
`;

const Total = Styled.H7`
  ${fontWeight(700)}
`;

const Footer = Styled.View`
  align-self: stretch;
`;

const DashedLine = Styled.View`
  border-top-width: 1px;
  border-top-style: dashed;
  border-top-color: ${colors.gray.tertiary};
`;

const EmptyContainer = Styled.View`
  padding-horizontal: 12px;
`;

const EmptyMessage = Styled.H7`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const BillHeader = ({hidePrices, hideQuantity, hideTotal}) => (
  <Header>
    <Cell
      style={{
        flex: 1,
        minWidth: 140,
      }}
    >
      <Text>Item</Text>
    </Cell>
    {hideQuantity ? null : (
      <Cell
        style={{
          alignItems: 'flex-end',
          width: 80,
        }}
      >
        <Text>Quantity</Text>
      </Cell>
    )}
    {hidePrices ? null : (
      <React.Fragment>
        <Cell
          style={{
            alignItems: 'flex-end',
            width: 120,
          }}
        >
          <Text>Price</Text>
        </Cell>
        {hideTotal ? null : (
          <Cell
            style={{
              alignItems: 'flex-end',
              width: 160,
            }}
          >
            <Text>Total</Text>
          </Cell>
        )}
      </React.Fragment>
    )}
  </Header>
);

const LineItemItem = ({hidePrices, hideQuantity, hideTotal, bill, lineItem}) => {
  const isDiscount = lineItem.kind === LineItem.KINDS.DISCOUNT;

  return (
    <Item>
      <Cell
        style={{
          flex: 1,
          minWidth: 140,
        }}
      >
        <Name>{lineItem.name}</Name>
        <Description>{lineItem.description}</Description>
      </Cell>
      {hideQuantity ? null : (
        <Cell
          style={{
            alignItems: 'flex-end',
            width: 80,
          }}
        >
          <Quantity>{LineItem.getEstimateQuantity(lineItem)}</Quantity>
        </Cell>
      )}
      {hidePrices ? null : (
        <React.Fragment>
          <Cell
            style={{
              alignItems: 'flex-end',
              width: 120,
            }}
          >
            <Price>{LineItem.getDisplayPrice(lineItem)}</Price>
          </Cell>
          {hideTotal ? null : isDiscount ? (
            <Cell
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: 160,
              }}
            >
              <Icon
                style={{transform: 'rotate(90deg)'}}
                color={colors.green.status}
                size={14}
                source={Icon.Tag}
              />
              <Space width={5} />
              <Amount style={{color: colors.green.status}}>
                {LineItem.getEstimateTotal(lineItem)}
              </Amount>
            </Cell>
          ) : (
            <Cell
              style={{
                alignItems: 'flex-end',
                width: 160,
              }}
            >
              <Amount>{LineItem.getEstimateTotal(lineItem)}</Amount>
            </Cell>
          )}
        </React.Fragment>
      )}
    </Item>
  );
};

const BillFooter = ({hideTotal, bill}) => {
  return (
    <Footer>
      {bill.isSubtotalDifferent && (
        <React.Fragment>
          <DashedLine />
          <FlatListSpace />
          <Item>
            <Cell
              style={{
                minWidth: 140,
                alignItems: 'flex-start',
              }}
            >
              <Total>Subtotal</Total>
            </Cell>
            <Cell
              style={{
                flex: 1,
                alignItems: 'flex-end',
              }}
            >
              <Total>{bill.isEstimateAvailable ? Bill.getEstimateSubtotal(bill) : 'TBD'}</Total>
            </Cell>
          </Item>
          <FlatListSpace />
          {bill.estimateSalesTaxAmountMin > 0 && (
            <React.Fragment>
              <Item>
                <Cell
                  style={{
                    minWidth: 140,
                    alignItems: 'flex-start',
                  }}
                >
                  <Subtotal>Sales Tax</Subtotal>
                </Cell>
                <Cell
                  style={{
                    flex: 1,
                    alignItems: 'flex-end',
                  }}
                >
                  <Subtotal>
                    {bill.isEstimateAvailable ? Bill.getEstimateSalesTaxAmount(bill) : 'TBD'}
                  </Subtotal>
                </Cell>
              </Item>
              <FlatListSpace />
            </React.Fragment>
          )}
          {bill.tip > 0 && (
            <React.Fragment>
              <Item>
                <Cell
                  style={{
                    minWidth: 140,
                    alignItems: 'flex-start',
                  }}
                >
                  <Subtotal>Tip</Subtotal>
                </Cell>
                <Cell
                  style={{
                    flex: 1,
                    alignItems: 'flex-end',
                  }}
                >
                  <Subtotal>{Currency.display(bill.tip, {shouldHideCentsIfZero: true})}</Subtotal>
                </Cell>
              </Item>
              <FlatListSpace />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {bill.billModifiers.map((billModifier, index) => {
        const isDiscount =
          (billModifier.kind === BillModifier.KINDS.AMOUNT && billModifier.estimateTotalMin < 0) ||
          (billModifier.kind === BillModifier.KINDS.PERCENTAGE && billModifier.percentage < 0);
        return (
          <React.Fragment key={index}>
            <Item>
              <Cell
                style={{
                  minWidth: 140,
                  alignItems: 'flex-start',
                }}
              >
                <Subtotal>{BillModifier.getDisplayName(billModifier)}</Subtotal>
                <Description>{billModifier.description}</Description>
              </Cell>
              {isDiscount ? (
                <Cell
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Icon
                    style={{transform: 'rotate(90deg)'}}
                    color={colors.green.status}
                    size={14}
                    source={Icon.Tag}
                  />
                  <Space width={5} />
                  <Subtotal style={{color: colors.green.status}}>
                    {BillModifier.getDisplayTotal(billModifier, {
                      isEstimateAvailable: bill.isEstimateAvailable,
                    })}
                  </Subtotal>
                </Cell>
              ) : (
                <Cell
                  style={{
                    flex: 1,
                    alignItems: 'flex-end',
                  }}
                >
                  <Subtotal>
                    {BillModifier.getDisplayTotal(billModifier, {
                      isEstimateAvailable: bill.isEstimateAvailable,
                    })}
                  </Subtotal>
                </Cell>
              )}
            </Item>
            <FlatListSpace />
          </React.Fragment>
        );
      })}
      {hideTotal ? null : (
        <React.Fragment>
          <DashedLine />
          <FlatListSpace />
          <Item>
            <Cell
              style={{
                alignItems: 'flex-start',
                width: 160,
              }}
            >
              <Total>Total</Total>
            </Cell>
            <Cell
              style={{
                flex: 1,
                alignItems: 'flex-end',
              }}
            >
              <Total>{bill.isEstimateAvailable ? Bill.getEstimateTotal(bill) : 'TBD'}</Total>
            </Cell>
          </Item>
        </React.Fragment>
      )}
    </Footer>
  );
};

const BillLineItemsList = ({hidePrices, hideQuantity, hideTotal, bill, style}) => {
  return (
    <Container style={style}>
      <FlatList
        data={bill.lineItems}
        keyExtractor={(lineItem, index) => String(index)}
        renderItem={({item: lineItem}) => (
          <LineItemItem
            hidePrices={hidePrices}
            hideQuantity={hideQuantity}
            hideTotal={hideTotal}
            bill={bill}
            lineItem={lineItem}
          />
        )}
        CellRendererComponent={FlatListCell}
        ItemSeparatorComponent={FlatListSpace}
        ListHeaderComponent={() => (
          <React.Fragment>
            <BillHeader hidePrices={hidePrices} hideQuantity={hideQuantity} hideTotal={hideTotal} />
            <FlatListSpace />
          </React.Fragment>
        )}
        ListFooterComponent={() => (
          <React.Fragment>
            <FlatListSpace />
            <BillFooter hideTotal={hideTotal} bill={bill} />
          </React.Fragment>
        )}
        ListEmptyComponent={() => (
          <EmptyContainer>
            <EmptyMessage>No billing items</EmptyMessage>
          </EmptyContainer>
        )}
      />
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
BillLineItemsList.propTypes = {
  hidePrices: PropTypes.bool,
  hideQuantity: PropTypes.bool,
  hideTotal: PropTypes.bool,
  bill: PropTypes.object.isRequired,
  style: PropTypes.object,
};

BillLineItemsList.defaultProps = {
  hidePrices: false,
  hideQuantity: false,
  hideTotal: false,
  style: {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillLineItemsList.fragment = gql`
  ${Bill.getEstimateSalesTaxAmount.fragment}
  ${Bill.getEstimateSubtotal.fragment}
  ${Bill.getEstimateTotal.fragment}
  ${BillModifier.getDisplayName.fragment}
  ${BillModifier.getDisplayTotal.fragment}
  ${LineItem.getDisplayPrice.fragment}
  ${LineItem.getEstimateQuantity.fragment}
  ${LineItem.getEstimateTotal.fragment}

  fragment BillLineItemsList on Bill {
    id
    kind
    isEstimateAvailable
    isSubtotalDifferent
    estimateSalesTaxAmountMin
    tip
    lineItems {
      name
      description
      price
      kind
      category
      unit
      ...LineItem_getDisplayPrice
      ...LineItem_getEstimateQuantity
      ...LineItem_getEstimateTotal
    }
    billModifiers {
      description
      ...BillModifier_getDisplayName
      ...BillModifier_getDisplayTotal
    }
    ...Bill_getEstimateSalesTaxAmount
    ...Bill_getEstimateSubtotal
    ...Bill_getEstimateTotal
  }
`;

export default BillLineItemsList;
