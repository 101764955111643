// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({kind, inventoryLibraryId}) => ({
  itemTagId: undefined,
  inventoryLibraryId,
  kind,
  name: '',
  shortCode: '',
  isDeleted: false,

  // private
  isDirty: true,
});

const edit = withFragment(
  (itemTag) => ({
    itemTagId: itemTag.id,
    inventoryLibraryId: itemTag.inventoryLibraryId,
    kind: itemTag.kind,
    name: itemTag.name,
    shortCode: itemTag.shortCode,
    isDeleted: itemTag.isDeleted,

    // private
    isDirty: false,
  }),
  gql`
    fragment ItemTagForm_edit on ItemTag {
      id
      inventoryLibraryId
      kind
      name
      shortCode
      isDeleted
    }
  `,
);

const toForm = ({
  itemTagId,
  inventoryLibraryId,
  kind,
  name,
  shortCode,
  isDeleted,

  // private
  isDirty,
}) => ({
  itemTagId,
  inventoryLibraryId,
  kind,
  name,
  shortCode,
  isDeleted,

  // private
  isDirty,
});

const toMutation = ({itemTagId, inventoryLibraryId, kind, name, shortCode, isDeleted}) => ({
  itemTagId,
  inventoryLibraryId,
  kind,
  name,
  shortCode,
  isDeleted,
});

const ItemTagForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ItemTagForm;
