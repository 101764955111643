// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import JobCrewHoursAndTipsBlock from 'modules/Project/V2/Show/Blocks/Job/JobCrewHoursAndTipsBlock';
import JobDetailsBlock from 'modules/Project/V2/Show/Blocks/Job/JobDetailsBlock';
import JobDispatchBlock from 'modules/Project/V2/Show/Blocks/Job/JobDispatchBlock';
import JobEquipmentAndMaterialsBlock from 'modules/Project/V2/Show/Blocks/Job/JobEquipmentAndMaterialsBlock';
import JobJobTimesheetBlock from 'modules/Project/V2/Show/Blocks/Job/JobJobTimesheetBlock';
import JobLocationsBlock from 'modules/Project/V2/Show/Blocks/Job/JobLocationsBlock';
import JobTimesheetsBlock from 'modules/Project/V2/Show/Blocks/Job/JobTimesheetsBlock';
import JobTipPayoutsBlock from 'modules/Project/V2/Show/Blocks/Job/JobTipPayoutsBlock';

const ProjectJobBlock = ({
  index,
  project,
  kind,
  blockToPositionY,
  handleSetPositionY,
  layoutKey,
  jobUuid,
  isReadyForMap,
  urlFilters,
  pageRefetch,
  refetchAndReset,
}) => {
  // If we we are showing a job block without a jobUuid, the project page will
  // update the url with a jobUuid. There is an edge case where the screen size
  // flips between desktop and mobile/tablet where we could render this block
  // without a jobUuid, so we handle that here while the url gets updated.
  if (!jobUuid) {
    return null;
  }

  switch (kind) {
    case ProjectBlockKind.Job.JOB_DETAILS:
      return (
        <JobDetailsBlock
          project={project}
          index={index}
          pageRefetch={pageRefetch}
          jobUuid={jobUuid}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          refetchAndReset={refetchAndReset}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.Job.STOPS:
      return (
        <JobLocationsBlock
          index={index}
          project={project}
          jobUuid={jobUuid}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          isReadyForMap={isReadyForMap}
        />
      );
    case ProjectBlockKind.Job.DISPATCH:
      return (
        <JobDispatchBlock
          index={index}
          jobUuid={jobUuid}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          pageRefetch={pageRefetch}
        />
      );
    case ProjectBlockKind.Job.EQUIPMENT_AND_MATERIALS:
      return (
        <JobEquipmentAndMaterialsBlock
          index={index}
          jobUuid={jobUuid}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.Job.TIMESHEETS:
      return (
        <JobTimesheetsBlock
          project={project}
          index={index}
          jobUuid={jobUuid}
          blockToPositionY={blockToPositionY}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          urlFilters={urlFilters}
          isEnabledTimesheetsV2={project.projectType.features.timesheetsV2}
        />
      );
    case ProjectBlockKind.Job.JOB_TIMESHEET:
      return (
        <JobJobTimesheetBlock
          project={project}
          index={index}
          jobUuid={jobUuid}
          blockToPositionY={blockToPositionY}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          pageRefetch={pageRefetch}
        />
      );
    case ProjectBlockKind.Job.CREW_HOURS_AND_TIPS:
    case ProjectBlockKind.Job.CREW_HOURS:
      return (
        <JobCrewHoursAndTipsBlock
          project={project}
          index={index}
          jobUuid={jobUuid}
          blockToPositionY={blockToPositionY}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          isCrewHoursOnly={kind === ProjectBlockKind.Job.CREW_HOURS}
        />
      );
    case ProjectBlockKind.Job.TIP_PAYOUTS:
      return (
        <JobTipPayoutsBlock
          project={project}
          index={index}
          jobUuid={jobUuid}
          blockToPositionY={blockToPositionY}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          urlFilters={urlFilters}
        />
      );
    default:
      return null;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectJobBlock.listener = gql`
  ${JobTimesheetsBlock.listener}
  ${JobJobTimesheetBlock.listener}
  ${JobCrewHoursAndTipsBlock.listener}
  ${JobTipPayoutsBlock.listener}

  fragment ProjectJobBlock_listener on Project {
    id
    ...JobTimesheetsBlock_listener
    ...JobJobTimesheetBlock_listener
    ...JobCrewHoursAndTipsBlock_listener
    ...JobTipPayoutsBlock_listener
  }
`;

ProjectJobBlock.fragment = gql`
  ${JobDetailsBlock.fragment}
  ${JobLocationsBlock.fragment}

  fragment ProjectJobBlock on Project {
    id
    projectType {
      id
      features {
        timesheetsV2
      }
    }
    ...JobDetailsBlock
    ...JobLocationsBlock
  }
`;

export default ProjectJobBlock;
