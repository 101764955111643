const _new = ({inventoryLibraryId}) => ({
  fileId: null,
  inventoryLibraryId,
});

const toForm = ({fileId, inventoryLibraryId}) => ({
  fileId,
  inventoryLibraryId,
});

const toMutation = ({fileId, inventoryLibraryId}) => ({
  fileId,
  inventoryLibraryId,
});

const ImportInventoryLibraryCategoriesForm = {
  new: _new,
  toForm,
  toMutation,
};

export default ImportInventoryLibraryCategoriesForm;
