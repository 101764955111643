// Supermove
import {gql} from '@supermove/graphql';
import {BillRuleType} from '@supermove/models';
import {Currency, Float, withFragment} from '@supermove/utils';

const getIsCurrency = ({kind}) => BillRuleType.getValueType(kind) === 'CURRENCY';
const getIsString = ({kind}) => BillRuleType.getValueType(kind) === 'STRING';

const getFormValueFromInput = ({valueFormulaId, kind, value}) => {
  if (valueFormulaId) {
    return '';
  }
  if (getIsString({kind})) {
    return value;
  }
  if (getIsCurrency({kind})) {
    return Currency.toForm(Currency.convertToCents(value));
  }
  return Float.toForm(value);
};

const getFormValue = ({valueFormulaId, kind, value, stringValue}) => {
  if (valueFormulaId) {
    return '';
  }
  if (getIsString({kind})) {
    return stringValue || '';
  }
  if (getIsCurrency({kind})) {
    return Currency.toForm(value);
  }
  return Float.toForm(value);
};

const getMutationValue = ({valueFormulaId, kind, value}) => {
  if (valueFormulaId || getIsString({kind})) {
    return null;
  }
  if (getIsCurrency({kind})) {
    return Currency.toMutation(value);
  }
  return Float.toMutation(value);
};

const edit = withFragment(
  (billRuleType, {userId}) => ({
    billingLibraryId: billRuleType.billingLibraryId,
    billRuleTypeId: billRuleType.id,
    organizationId: billRuleType.organizationId,
    descriptionFormulaId: billRuleType.descriptionFormulaId,
    nameFormulaId: billRuleType.nameFormulaId,
    valueFormulaId: billRuleType.valueFormulaId,
    kind: billRuleType.kind,
    name: billRuleType.name,
    description: billRuleType.description,
    value: billRuleType.value,
    stringValue: billRuleType.stringValue,
    isVisibleToCustomer: billRuleType.isVisibleToCustomer,
    identifier: billRuleType.identifier,
    createdById: billRuleType.createdById,
    updatedById: userId,
  }),
  gql`
    fragment BillRuleTypeForm_edit on BillRuleType {
      id
      billingLibraryId
      organizationId
      descriptionFormulaId
      nameFormulaId
      valueFormulaId
      kind
      name
      description
      value
      stringValue
      isVisibleToCustomer
      identifier
      createdById
      updatedById
    }
  `,
);

const _new = ({billingLibraryId, organizationId, kind, userId}) => ({
  kind,
  name: '',
  description: '',
  value: '',
  isVisibleToCustomer: false,
  billRuleTypeId: null,
  billingLibraryId,
  organizationId,
  descriptionFormulaId: null,
  nameFormulaId: null,
  valueFormulaId: null,
  identifier: null,
  createdById: userId,
  updatedById: userId,
});

const toForm = ({
  kind,
  name,
  description,
  value,
  stringValue,
  isVisibleToCustomer,
  billRuleTypeId,
  billingLibraryId,
  organizationId,
  descriptionFormulaId,
  nameFormulaId,
  valueFormulaId,
  identifier,
  createdById,
  updatedById,
}) => {
  return {
    kind,
    name,
    description,
    value: getFormValue({valueFormulaId, kind, value, stringValue}),
    isVisibleToCustomer,
    billRuleTypeId,
    billingLibraryId,
    organizationId,
    descriptionFormulaId,
    nameFormulaId,
    valueFormulaId,
    identifier,
    createdById,
    updatedById,
  };
};

const toMutation = ({
  kind,
  name,
  description,
  value,
  isVisibleToCustomer,
  billingLibraryId,
  billRuleTypeId,
  organizationId,
  descriptionFormulaId,
  nameFormulaId,
  valueFormulaId,
  identifier,
  createdById,
  updatedById,
}) => {
  return {
    kind,
    name,
    description,
    value: getMutationValue({valueFormulaId, kind, value}),
    stringValue: getIsString({kind}) ? value : '',
    isVisibleToCustomer,
    billingLibraryId,
    billRuleTypeId,
    organizationId,
    descriptionFormulaId,
    nameFormulaId,
    valueFormulaId,
    identifier,
    createdById,
    updatedById,
  };
};

const BillRuleTypeForm = {
  edit,
  new: _new,
  toForm,
  toMutation,

  getDisplayKind: BillRuleType.getDisplayKind,
  getDisplayValue: BillRuleType.getDisplayValue,
  getIsString,
  getIsCurrency,
  getFormValueFromInput,
  getMutationValue,
};

export default BillRuleTypeForm;
