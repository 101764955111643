// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CodatIntegrationForm from '@shared/modules/Integration/forms/CodatIntegrationForm';

const useUpdateCodatIntegrationMutation = ({codatIntegrationForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      codatIntegrationForm: CodatIntegrationForm.toForm(codatIntegrationForm),
    },
  });
  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCodatIntegrationMutation.mutation,
    variables: {
      codatIntegrationForm: CodatIntegrationForm.toMutation(form.values.codatIntegrationForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCodatIntegrationMutation.mutation = gql`
  mutation useUpdateCodatIntegrationMutation($codatIntegrationForm: CodatIntegrationForm!) {
    response: updateCodatIntegration(codatIntegrationForm: $codatIntegrationForm) {
      ${gql.errors}
      codatIntegration {
        id
      }
    }
  }
`;

export default useUpdateCodatIntegrationMutation;
