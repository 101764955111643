import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getDropdownOptions = withFragment(
  ({referralSources}) => {
    return [
      ...referralSources.map((referralSource) => ({
        value: referralSource.name,
        label: referralSource.name,
      })),
      {value: 'Other', label: 'Other'},
    ];
  },
  gql`
    fragment ReferralSource_getDropdownOptions on ReferralSource {
      name
    }
  `,
);

const ReferralSource = {
  getDropdownOptions,
};

export default ReferralSource;
