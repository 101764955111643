// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useResponsive, useState} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import EditCostCostItemsModalV2 from 'modules/Cost/components/EditCostCostItemsModalV2';
import NewCompensationEntryModal from 'modules/Cost/components/NewCompensationEntryModal';
import CostAndCompTable from 'modules/Job/V2/Move/components/CostAndCompTable';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';
import ProjectBills from 'modules/Project/V2/Show/components/ProjectBills';
import ProjectCostsBlockRevenueSummaryV1 from 'modules/Project/components/ProjectCostsBlockRevenueSummaryV1';
import ProjectCostsTable from 'modules/Project/components/ProjectCostsTable';

const BLOCK_TITLE = 'Cost and Compensation';

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const getFirstCostId = ({project}) => {
  const costCategory = _.find(project.aggregateCost.costCategories, (costCategory) =>
    _.some(costCategory.costs),
  );
  return _.get(costCategory, 'costs.0.id');
};

const SkeletonBody = () => {
  return (
    <React.Fragment>
      <SkeletonLoader isFullWidth height={36} />
      <Space height={16} />
      <ProjectBills.SkeletonLoader />
    </React.Fragment>
  );
};

const SkeletonComponent = () => {
  return <ActionPanel BodyComponent={SkeletonBody} title={BLOCK_TITLE} style={{width: '100%'}} />;
};

const BodyComponent = ({project, refetch}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      {project.organization.features.isEnabledJobLevelCosts ? (
        <React.Fragment>
          <CostAndCompTable project={project} refetch={refetch} />
          {project.organization.settings.isCostAndCompensationEnabled && (
            <React.Fragment>
              <Space height={responsive.mobile ? 8 : 16} />
              <ProjectCostsBlockRevenueSummaryV1 project={project} />
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ProjectCostsTable project={project} refetch={refetch} />
          {project.organization.settings.isCostAndCompensationEnabled && (
            <React.Fragment>
              <Space height={responsive.mobile ? 8 : 16} />
              <ProjectCostsBlockRevenueSummaryV1 project={project} />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const CreateCostAction = ({project, newCompensationEntryModal}) => {
  return (
    <React.Fragment>
      {project.organization.features.isEnabledJobLevelCosts && (
        <SecondaryButton
          text={'Create Entry'}
          iconLeft={Icon.Plus}
          onPress={newCompensationEntryModal.handleOpen}
        />
      )}
    </React.Fragment>
  );
};

const ProjectCostAndCompensationBlockContent = ({
  project,
  refetch,
  setCostId,
  editCostCostItemsModalV2,
}) => {
  const newCompensationEntryModal = useModal({name: 'New Compensation Entry Modal'});

  return (
    <React.Fragment>
      <ActionPanel
        BodyComponent={BodyComponent}
        bodyComponentProps={{project, refetch}}
        ActionButtonComponent={CreateCostAction}
        actionButtonComponentProps={{project, newCompensationEntryModal}}
        title={BLOCK_TITLE}
        style={{width: '100%'}}
      />
      <NewCompensationEntryModal
        key={newCompensationEntryModal.key}
        isOpen={newCompensationEntryModal.isOpen}
        setCostId={setCostId}
        handleOpenEditCostCostItemsModal={editCostCostItemsModalV2.handleOpen}
        handleClose={newCompensationEntryModal.handleClose}
        project={project}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const EditButtonMobile = ({editCostCostItemsModalV2}) => {
  return (
    <TertiaryButton text={`Edit`} onPress={editCostCostItemsModalV2.handleOpen} isResponsive />
  );
};

const ProjectCostAndCompensationBlockContentMobile = ({project, editCostCostItemsModalV2}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <MobileProjectBlockHeader
        title={BLOCK_TITLE}
        ActionsComponent={EditButtonMobile}
        actionsComponentProps={{editCostCostItemsModalV2}}
      />
      <LabelText responsive={responsive}>Employee Compensation</LabelText>
      <Space height={12} />
      <ProjectCostsTable.Mobile project={project} />
    </React.Fragment>
  );
};

const ProjectCostAndCompensationBlock = ({project, handleSetPositionY, layoutKey, index}) => {
  const responsive = useResponsive();
  const [costId, setCostId] = useState();
  const editCostCostItemsModalV2 = useModal({name: 'Edit Cost Cost Items Modal V2'});

  return (
    <ProjectBlockWrapper
      key={`${project.costsHash}_${project.costItemRatesHash}_${project.totalRevenue}`}
      index={index}
      query={ProjectCostAndCompensationBlock.query}
      queryVariables={{
        projectUuid: project.uuid,
        isDesktop: responsive.desktop,
      }}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={SkeletonComponent}
    >
      {({data, refetch}) => {
        const {project} = data;
        return (
          <React.Fragment>
            {responsive.desktop ? (
              <ProjectCostAndCompensationBlockContent
                project={project}
                refetch={refetch}
                setCostId={setCostId}
                editCostCostItemsModalV2={editCostCostItemsModalV2}
              />
            ) : (
              <ProjectCostAndCompensationBlockContentMobile
                project={project}
                editCostCostItemsModalV2={editCostCostItemsModalV2}
              />
            )}
            <EditCostCostItemsModalV2
              key={editCostCostItemsModalV2.key}
              isOpen={editCostCostItemsModalV2.isOpen}
              handleClose={editCostCostItemsModalV2.handleClose}
              costId={responsive.desktop ? costId : getFirstCostId({project})}
              aggregateCost={project.aggregateCost}
              refetch={refetch}
            />
          </React.Fragment>
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectCostAndCompensationBlock.listener = gql`
  fragment ProjectCostAndCompensationBlock_listener on Project {
    id
    costsHash
    costItemRatesHash
    totalRevenue
  }
`;

ProjectCostAndCompensationBlock.fragment = gql`
  ${ProjectCostAndCompensationBlock.listener}
  fragment ProjectCostAndCompensationBlock on Project {
    id
    uuid
    ...ProjectCostAndCompensationBlock_listener
  }
`;

ProjectCostAndCompensationBlock.query = gql`
  ${CostAndCompTable.fragment}
  ${EditCostCostItemsModalV2.fragment}
  ${NewCompensationEntryModal.fragment}
  ${ProjectBills.fragment}
  ${ProjectCostAndCompensationBlock.listener}
  ${ProjectCostsBlockRevenueSummaryV1.fragment}
  ${ProjectCostsTable.fragment}
  ${ProjectCostsTable.Mobile.fragment}

  query ProjectCostAndCompensationBlock($projectUuid: String!, $isDesktop: Boolean!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      uuid
      aggregateCost {
        ...EditCostCostItemsModalV2
      }
      organization {
        id
        features {
          isEnabledJobLevelCosts: isEnabled(feature: "JOB_LEVEL_COSTS")
        }
        settings {
          id
          isCostAndCompensationEnabled
        }
      }
      ...CostAndCompTable
      ...NewCompensationEntryModal
      ...ProjectBills
      ...ProjectCostAndCompensationBlock_listener
      ...ProjectCostsBlockRevenueSummaryV1
      ...ProjectCostsTable @include(if: $isDesktop)
      ...ProjectCostsTable_Mobile @skip(if: $isDesktop)
    }
  }
`;

export default ProjectCostAndCompensationBlock;
