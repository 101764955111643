/* eslint-disable react-hooks/rules-of-hooks */
// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {DropdownInput, FileDragInput, ScrollView, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import FileViewer from '@shared/modules/File/components/FileViewer';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import useCheckMuxVideoIsReady from '@shared/modules/File/hooks/useCheckMuxVideoIsReady';
import useCreateAttachmentForm from '@shared/modules/File/hooks/useCreateAttachmentForm';
import useUploadFileForm from '@shared/modules/File/hooks/useUploadFileForm';
import LargeModal from 'modules/App/Modal/components/LargeModal';
import Field from 'modules/App/components/Field';

const Title = Styled.H5`
  ${fontWeight(700)}
  margin-bottom: 5px;
  color: ${colors.gray.primary};
`;

const Section = Styled.View`
  padding-horizontal: ${(props) => (props.mobile ? 20 : 30)}px;
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const FileDragInputContainer = Styled.View`
  height: 200px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  border-style: dotted;
  border-width: 3px;
  cursor: pointer;
  border-color: ${colors.gray.secondary};
`;

const FileDragInputText = Styled.H6`
  width: 100%;
  text-align: center;
`;

const FilenameText = Styled.H6`
  width: 100%;
  text-align: center;
`;

const SectionSpace = Styled.View`
  margin-top: 20px;
`;

const SubmitButton = Styled.LoadingButton`
  height: 40px;
`;

const DestructiveButton = Styled.Button`
  height: 40px;
  width: 100px;
`;

const ButtonText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
  text-align: center;
`;

const ValidationError = Styled.H7`
  margin-top: 5px;
  text-align: center;
  color: ${colors.red.warning};
`;

const SectionTitle = Styled.H7`
`;

const SectionSubtitle = Styled.H7`
  color: ${colors.gray.secondary};
`;

const FilePreviewContainer = Styled.View`
  height: 400px;
`;

const TextInput = Styled.TextInput.H7`
`;

const resetUploadFileModal = ({form, setUploadedFile}) => {
  form.setFieldValue('uploadFileForm.requestUploadFileForm.mimetype', null);
  form.setFieldValue('uploadFileForm.requestUploadFileForm.filename', null);
  form.setFieldValue('uploadFileForm.file', null);
  setUploadedFile(null);
};

const VideoLoading = () => {
  return (
    <FileDragInputContainer>
      <FilenameText>Loading...</FilenameText>
    </FileDragInputContainer>
  );
};

const ChangeFileButton = ({form, setUploadedFile}) => {
  return (
    <DestructiveButton
      color={colors.red.warning}
      onPress={() => resetUploadFileModal({form, setUploadedFile})}
    >
      <ButtonText>Change</ButtonText>
    </DestructiveButton>
  );
};

const FileInputField = ({
  uploadedFile,
  setUploadedFile,
  uploadFileForm,
  createAttachmentForm,
  submitting,
  handleSubmit,
}) => {
  // Show a preview of the file if we have one selected.
  if (uploadedFile) {
    const {isReady} = useCheckMuxVideoIsReady({url: uploadedFile.playbackUrl});
    return isReady ? (
      <React.Fragment>
        <FilePreviewContainer>
          <FileViewer
            file={{
              id: -1,
              filename: uploadedFile.filename,
              mimetype: uploadedFile.mimetype,
              downloadUrl: uploadedFile.downloadUrl,
              playbackUrl: uploadedFile.playbackUrl,
            }}
          />
        </FilePreviewContainer>
        <Space height={10} />
        <ChangeFileButton form={uploadFileForm} setUploadedFile={setUploadedFile} />
      </React.Fragment>
    ) : (
      <VideoLoading />
    );
  }

  if (submitting) {
    return <VideoLoading />;
  }

  return (
    <React.Fragment>
      <FileDragInput
        onFilesChange={(files) => {
          const file = files[0];
          uploadFileForm.setFieldValue('uploadFileForm.requestUploadFileForm.mimetype', file.type);
          uploadFileForm.setFieldValue('uploadFileForm.requestUploadFileForm.filename', file.name);
          uploadFileForm.setFieldValue('uploadFileForm.file', file);

          // TODO(peter): Temporary hack to fix flickering of loading indicator
          uploadFileForm.setSubmitting(true);
          handleSubmit();
        }}
      >
        {({isDragActive}) => (
          <FileDragInputContainer>
            <FileDragInputText>
              {isDragActive ? 'Drop the file here' : 'Drag a file or click to upload'}
            </FileDragInputText>
          </FileDragInputContainer>
        )}
      </FileDragInput>
      <ValidationError>
        {_.get(uploadFileForm.errors, 'uploadFileForm.requestUploadFileForm.mimetype')}
      </ValidationError>
      <ValidationError>
        {_.get(createAttachmentForm.errors, 'createAttachmentForm.fileId')}
      </ValidationError>
      <ValidationError>
        {_.get(uploadFileForm.errors, 'uploadFileForm.requestUploadFileForm.filename')}
      </ValidationError>
    </React.Fragment>
  );
};

const DescriptionField = ({form}) => {
  return (
    <Field
      {...form}
      component={TextInput}
      name={'createAttachmentForm.description'}
      input={{
        placeholder: `Enter a description`,
      }}
    />
  );
};

const AddAttachmentModal = ({
  isOpen,
  handleClose,
  viewer,
  refetch,
  initialCreateAttachmentForm,
  jobIdDropdownOptions,
  attachmentCategoryKindsDropdownOptions,
}) => {
  const responsive = useResponsive();
  const [uploadedFile, setUploadedFile] = useState();

  const {
    form: uploadFileForm,
    submitting: submittingUploadFileForm,
    handleSubmit: handleSubmitUploadFileForm,
  } = useUploadFileForm({
    uploadFileForm: UploadFileForm.new({
      organizationId: viewer.viewingOrganization.id,
      creatorId: viewer.id,
    }),
    onSuccess: ({file}) => {
      createAttachmentForm.setFieldValue('createAttachmentForm.fileId', file.id);
      setUploadedFile(file);
    },
    onError: (errors) => resetUploadFileModal({form: uploadFileForm, setUploadedFile}),
  });

  const {
    form: createAttachmentForm,
    submitting: submittingCreateAttachmentForm,
    handleSubmit: handleSubmitCreateAttachmentForm,
  } = useCreateAttachmentForm({
    createAttachmentForm: initialCreateAttachmentForm,
    onSuccess: () => {
      resetUploadFileModal({form: uploadFileForm, setUploadedFile});
      createAttachmentForm.setFieldValue('createAttachmentForm.fileId', null);
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <LargeModal
      isOpen={isOpen}
      handleClose={() => {
        resetUploadFileModal({form: uploadFileForm, setUploadedFile});
        handleClose();
      }}
    >
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 40,
        }}
      >
        <Section {...responsive} sectionIndex={0}>
          <Title>Add Attachment</Title>
        </Section>
        <SectionSpace />
        <Section {...responsive} sectionIndex={1}>
          <SectionTitle>What category does this attachment belong to?</SectionTitle>
          <SectionSubtitle>
            Office Only: attachments for office record keeping (not shown to crew)
          </SectionSubtitle>
          <SectionSubtitle>
            Instructions for Crew: attachments that crew can view on tablet
          </SectionSubtitle>
          <SectionSubtitle>Crew Photo: photos taken on tablet by crew</SectionSubtitle>
          <Space height={5} />
          <Field
            {...createAttachmentForm}
            component={DropdownInput}
            name={'createAttachmentForm.attachmentCategoryKinds.0'}
            input={{
              options: attachmentCategoryKindsDropdownOptions,
              placeholder: 'Select one',
              setFieldValue: createAttachmentForm.setFieldValue,
              style: {
                width: '100%',
              },
              disabled: attachmentCategoryKindsDropdownOptions.length === 1,
            }}
            style={{
              width: 300,
            }}
          />
        </Section>
        {jobIdDropdownOptions.length > 1 && (
          <React.Fragment>
            <SectionSpace />
            <Section {...responsive} sectionIndex={2}>
              <SectionTitle>
                Which job(s) in this project does this attachment belong to?
              </SectionTitle>
              <Space height={5} />
              <Field
                {...createAttachmentForm}
                component={DropdownInput}
                name={'createAttachmentForm.jobId'}
                input={{
                  options: jobIdDropdownOptions,
                  placeholder: 'Select one',
                  setFieldValue: createAttachmentForm.setFieldValue,
                  style: {
                    width: '100%',
                  },
                }}
                style={{
                  width: 300,
                }}
              />
            </Section>
          </React.Fragment>
        )}
        <SectionSpace />
        <Section {...responsive} sectionIndex={3}>
          <SectionSubtitle>
            Accepted file types: PDF, PNG, JPG, CSV, TXT, DOC, DOCX, XLS, XLSX, MP4, WAV, MOV
          </SectionSubtitle>
          <Space height={5} />
          <FileInputField
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
            uploadFileForm={uploadFileForm}
            createAttachmentForm={createAttachmentForm}
            submitting={submittingUploadFileForm}
            handleSubmit={handleSubmitUploadFileForm}
          />
          <Space height={4} />
          <DescriptionField form={createAttachmentForm} />
        </Section>
      </ScrollView>
      <Section
        {...responsive}
        sectionIndex={4}
        style={{
          paddingVertical: 20,
          boxShadow: '0 -2px 2px rgba(0,0,0,0.1)',
        }}
      >
        <SubmitButton
          disabled={submittingUploadFileForm}
          loading={submittingCreateAttachmentForm}
          onPress={handleSubmitCreateAttachmentForm}
        >
          <ButtonText>Create</ButtonText>
        </SubmitButton>
      </Section>
    </LargeModal>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
AddAttachmentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  viewer: PropTypes.object.isRequired,
  initialCreateAttachmentForm: PropTypes.object.isRequired,
  jobIdDropdownOptions: PropTypes.array,
};

AddAttachmentModal.defaultProps = {
  jobIdDropdownOptions: [],
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddAttachmentModal.fragment = gql`
  fragment AddAttachmentModal on User {
    id
    viewingOrganization {
      id
    }
  }
`;

export default AddAttachmentModal;
