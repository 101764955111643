// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CollectionFormV2 from '@shared/modules/Storage/forms/CollectionFormV2';

const useDeleteCollectionMutation = ({collectionForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      collectionForm: CollectionFormV2.toForm(collectionForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteCollectionMutation.mutation,
    variables: {
      collectionForm: CollectionFormV2.toMutation(form.values.collectionForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteCollectionMutation.mutation = gql`
  mutation useDeleteCollectionMutation($collectionForm: CollectionFormV2) {
    response: deleteCollection(collectionForm: $collectionForm) {
      ${gql.errors}
      collection {
        id
      }
    }
  }
`;

export default useDeleteCollectionMutation;
