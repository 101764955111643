// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import SurveyMethodKind from '@shared/modules/Inventory/enums/SurveyMethodKind';

const getDisplayKind = withFragment(
  (inventory) => {
    switch (inventory.kind) {
      case 'NONE':
        return '';
      case 'ONLINE':
        return 'Online';
      case 'PHONE':
        return 'Phone';
      case 'CUSTOMER_SUBMITTED':
        return 'Customer Submitted';
      case 'IN_HOME_ESTIMATE':
        return 'In-Person Estimate';
      case 'VIRTUAL_WALKTHROUGH':
        return 'Virtual Walkthrough';
      default:
        return '';
    }
  },
  gql`
    fragment Inventory_getDisplayKind on Inventory {
      kind
    }
  `,
);

const getIsVirtualWalkthrough = withFragment(
  (inventory) => {
    if (inventory.surveyMethod) {
      return inventory.surveyMethod.kind === SurveyMethodKind.VIRTUAL_WALKTHROUGH;
    }
    return inventory.kind === SurveyMethodKind.VIRTUAL_WALKTHROUGH;
  },
  gql`
    fragment Inventory_getIsVirtualWalkthrough on Inventory {
      id
      kind
      surveyMethod {
        id
        kind
      }
    }
  `,
);

const getIsNoSurvey = withFragment(
  (inventory) => {
    if (inventory.kind === 'CUSTOMER_SUBMITTED') {
      return false;
    }
    if (inventory.surveyMethod) {
      return inventory.surveyMethod.kind === SurveyMethodKind.NO_SURVEY;
    }
    return inventory.kind === 'NONE';
  },
  gql`
    fragment Inventory_getIsNoSurvey on Inventory {
      id
      kind
      surveyMethod {
        id
        kind
      }
    }
  `,
);

const getMatches = (string, regex) => {
  return _.toString(string).match(regex) || [];
};

const formatNumberValue = (value) => {
  if (value === '') {
    return 0;
  }

  const numerics = getMatches(value, /[.]?\d+[.\d+]?/g).join('');
  const alpha = getMatches(value, /[a-zA-Z]+/g)
    .join('')
    .toLowerCase();

  if (alpha.length > 0) {
    return 0;
  }

  return parseFloat(numerics);
};

const getFloatValue = (value) => {
  return formatNumberValue(value);
};

const containsOnlyDigits = (value) => {
  const digitsRegex = /^\d+$/;

  return digitsRegex.test(value);
};

const COLOR_OPTIONS = {
  Purple: '#9B51E0',
  Blue: '#34ACE0',
  Green: '#27AE60',
  Yellow: '#F1C40F',
  Orange: '#F2A12C',
  Red: '#E74C3C',
  Pink: '#E84393',
  Grey: '#616161',
  Black: '#000000',
  White: '#FFFFFF',
};

const getColorOptions = () =>
  Object.keys(COLOR_OPTIONS).map((key) => ({label: key, value: COLOR_OPTIONS[key]}));

const getColorNameFromHex = (hex) => {
  const colorName = _.findKey(COLOR_OPTIONS, (value) => value === hex);
  return colorName || '';
};

const getColorHexFromName = (color) => {
  return COLOR_OPTIONS[color] || '';
};

const Inventory = {
  getDisplayKind,
  getIsVirtualWalkthrough,
  getIsNoSurvey,
  getFloatValue,
  containsOnlyDigits,
  getColorOptions,
  getColorNameFromHex,
  getColorHexFromName,
};

export default Inventory;
