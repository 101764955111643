// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (project) => ({
    projectId: project.id,
  }),
  gql`
    fragment SyncAllCommercialCatalogBillsForm_edit on Project {
      id
    }
  `,
);

const _new = () => ({
  projectId: undefined,
});

const toForm = ({projectId}) => ({
  projectId,
});

const toMutation = ({projectId}) => ({
  projectId,
});

const SyncAllCommercialCatalogBillsForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default SyncAllCommercialCatalogBillsForm;
