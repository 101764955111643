// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  ({trip}) => ({
    tripId: trip.id,
    driverId: trip.driverId ? trip.driverId : null,
    // TODO (Dathan): Right now there is only one trip segment per trip
    // This will be updated in the future to support multiple trip segments
    truckIds: trip.tripSegments[0] ? trip.tripSegments[0].truckIds.map((id) => _.toString(id)) : [],
  }),
  gql`
    fragment AssignDriverAndTrucksToTripForm_edit on Trip {
      id
      driverId
      tripSegments {
        id
        truckIds
      }
    }
  `,
);

const toForm = ({tripId, driverId, truckIds}) => ({
  tripId,
  driverId,
  truckIds,
});

const toMutation = ({tripId, driverId, truckIds}) => ({
  tripId,
  driverId,
  truckIds,
});

const AssignDriverAndTrucksToTripForm = {
  edit,
  toForm,
  toMutation,
};

export default AssignDriverAndTrucksToTripForm;
