// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {S3} from '@supermove/sdk';

// App
import CompleteUploadFileForm from '@shared/modules/File/forms/CompleteUploadFileForm';
import CreateAttachmentForm from '@shared/modules/File/forms/CreateAttachmentForm';
import RequestUploadFileForm from '@shared/modules/File/forms/RequestUploadFileForm';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';

const useUploadAttachmentForm = ({uploadFileForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      uploadFileForm: UploadFileForm.toForm(uploadFileForm),
    },
  });

  // Create Attachment (#4)
  const {handleSubmit: handleSubmitCreateAttachment} = useFormMutation({
    form,
    mutation: useUploadAttachmentForm.createAttachmentMutation,
    variables: {
      createAttachmentForm: CreateAttachmentForm.toMutation(
        form.values.uploadFileForm.createAttachmentForm,
      ),
    },
    onSuccess,
    onError,
  });

  // Complete Upload File (#3)
  const {submitting: submittingCompleteUploadFile, handleSubmit: handleSubmitCompleteUploadFile} =
    useFormMutation({
      form,
      mutation: useUploadAttachmentForm.completeUploadFileMutation,
      variables: {
        completeUploadFileForm: CompleteUploadFileForm.toMutation(
          form.values.uploadFileForm.completeUploadFileForm,
        ),
      },
      onSuccess: () => {
        handleSubmitCreateAttachment();
      },
      onError,
    });

  // Upload to S3 (#2)
  const uploadFile = async ({url, fields, file, setSubmitting}) => {
    setSubmitting(true);
    await S3.uploadFile({
      url,
      fields: JSON.parse(fields),
      file,
      onProgress: (progress) => console.log({progress, submitting: form.isSubmitting}),
    });
    setSubmitting(false);
    handleSubmitCompleteUploadFile();
  };

  // Request Upload File (#1)
  const {submitting: submittingRequestUploadFile, handleSubmit: handleSubmitRequestUploadFile} =
    useFormMutation({
      form,
      mutation: useUploadAttachmentForm.requestUploadFileMutation,
      variables: {
        requestUploadFileForm: RequestUploadFileForm.toMutation(
          form.values.uploadFileForm.requestUploadFileForm,
        ),
      },
      onSuccess: async ({file}) => {
        form.setFieldValue('uploadFileForm.createAttachmentForm.fileId', file.id);
        form.setFieldValue('uploadFileForm.completeUploadFileForm.uuid', file.uuid);
        uploadFile({
          url: file.s3PresignedPostMetadata.url,
          fields: file.s3PresignedPostMetadata.fields,
          file: form.values.uploadFileForm.file,
          setSubmitting: form.setSubmitting,
        });
      },
      onError: (errors) => {
        _.forEachRight(errors, (error) =>
          form.setFieldError(_.camelCase(error.field), error.message),
        );
        onError(errors);
      },
    });

  return {
    form,
    submitting: submittingRequestUploadFile || submittingCompleteUploadFile || form.isSubmitting,
    handleSubmit: handleSubmitRequestUploadFile,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUploadAttachmentForm.requestUploadFileMutation = gql`
  mutation useUploadAttachmentForm($requestUploadFileForm: RequestUploadFileForm!) {
    response: requestUploadFile(requestUploadFileForm: $requestUploadFileForm) {
      ${gql.errors}
      file {
        id
        uuid
        s3PresignedPostMetadata {
          url
          fields
        }
      }
    }
  }
`;

useUploadAttachmentForm.completeUploadFileMutation = gql`
  mutation useUploadAttachmentForm($completeUploadFileForm: CompleteUploadFileForm!) {
    response: completeUploadFile(completeUploadFileForm: $completeUploadFileForm) {
      ${gql.errors}
      file {
        id
      }
    }
  }
`;

useUploadAttachmentForm.createAttachmentMutation = gql`
  mutation useCreateAttachmentForm($createAttachmentForm: CreateAttachmentForm!) {
    response: createAttachment(createAttachmentForm: $createAttachmentForm) {
      ${gql.errors}
      attachment {
        id
      }
    }
  }
`;

export default useUploadAttachmentForm;
