// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import InventoryRoomsList from '@shared/modules/Inventory/Summary/components/InventoryRoomsList';
import InventorySection from '@shared/modules/Inventory/Summary/components/InventorySection';

const ProjectDocumentV2SurveyItems = ({
  isPreview,
  project,
  isDisabled,
  showNotes,
  showWeight,
  showVolume,
}) => {
  return (
    <InventorySection icon={Icon.Archive} title={'Room items summary'}>
      <InventoryRoomsList
        isDisabled={isDisabled}
        inventory={isPreview ? MOCK_INVENTORY_DATA : project.inventory}
        showNotes={showNotes}
        showWeight={showWeight}
        showVolume={showVolume}
        isCartonsVisible={false}
      />
    </InventorySection>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
ProjectDocumentV2SurveyItems.propTypes = {
  isPreview: PropTypes.bool,
  isDisabled: PropTypes.bool,
  showNotes: PropTypes.bool,
  showWeight: PropTypes.bool,
  showVolume: PropTypes.bool,
};
ProjectDocumentV2SurveyItems.defaultProps = {
  isPreview: false,
  isDisabled: false,
  showNotes: false,
  showWeight: true,
  showVolume: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectDocumentV2SurveyItems.fragment = gql`
  ${InventoryRoomsList.fragment}
  fragment ProjectDocumentV2SurveyItems on Project {
    id
    inventory {
      id
      ...InventoryRoomsList
    }
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_INVENTORY_DATA = {
  id: '1',
  rooms: [
    {
      id: '1',
      name: 'Room',
      description: 'Description',
      collection: {
        id: '1',
        leaveCount: 1,
        takeCount: 1,
        totalVolume: 1,
        totalWeight: 1,
        filteredItems: [
          {
            id: '1',
            itemTypeId: 1,
            leaveCount: 1,
            takeCount: 1,
            volume: 1,
            name: 'Bed',
            notes: 'This is a note',
          },
        ],
      },
    },
  ],
};

export default ProjectDocumentV2SurveyItems;
