// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import OrganizationBusinessInfoAddressForm from '@shared/modules/Organization/forms/OrganizationBusinessInfoAddressForm';

const useUpdateOrganizationBusinessInfoAddressMutation = ({
  organizationBusinessInfoAddressForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      organizationBusinessInfoAddressForm: OrganizationBusinessInfoAddressForm.toForm(
        organizationBusinessInfoAddressForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationBusinessInfoAddressMutation.mutation,
    variables: {
      organizationBusinessInfoAddressForm: OrganizationBusinessInfoAddressForm.toMutation(
        form.values.organizationBusinessInfoAddressForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationBusinessInfoAddressMutation.mutation = gql`
  ${OrganizationBusinessInfoAddressForm.edit.fragment}
  mutation useUpdateOrganizationBusinessInfoAddressMutation($organizationBusinessInfoAddressForm: OrganizationBusinessInfoAddressForm!) {
    response: updateOrganizationBusinessInfoAddress(organizationBusinessInfoAddressForm: $organizationBusinessInfoAddressForm) {
      ${gql.errors}
      organization {
        id
        ...OrganizationBusinessInfoAddressForm_edit
      }
    }
  }
`;

export default useUpdateOrganizationBusinessInfoAddressMutation;
