import {Environment} from '@supermove/sdk';

import {ChannelType, SSEChannelSubscriptionRequest} from '@shared/modules/SSE/interfaces';

export const getSSEServerHost = (): string => {
  if (Environment.isLocal()) {
    return 'http://localhost:5002';
  }
  const env = Environment.getAppEnv();
  if (env === 'production') {
    return 'https://sse.production.supermove.co';
  }
  if (env === 'staging') {
    return 'https://sse.staging.supermove.co';
  }
  if (env === 'development') {
    return 'https://sse.development.supermove.co';
  }

  return '';
};

// This returns the channels that the client will subscribe to in the manager app.
// Clients may subscribe and unsubscribe from channels as they please, but these are
// the channels that will be subscribed to by default, since they are used by the shared
// sidebar and header.
export const getManagerSSEChannels = ({
  viewer,
}: {
  viewer?: {id: string; organization: {id: string}};
}): SSEChannelSubscriptionRequest[] => {
  const channels: SSEChannelSubscriptionRequest[] = [];
  if (!viewer) {
    return channels;
  }
  channels.push({channelType: ChannelType.USER, channelId: viewer?.id});
  if (viewer?.organization?.id) {
    channels.push({channelType: ChannelType.ORGANIZATION, channelId: viewer?.organization?.id});
  }
  return channels;
};

export const getSalesSSEChannels = ({
  viewer,
}: {
  viewer?: {id: string; organization: {id: string}};
}): SSEChannelSubscriptionRequest[] => {
  const channels: SSEChannelSubscriptionRequest[] = [];
  if (!viewer) {
    return channels;
  }
  channels.push({channelType: ChannelType.SALES_USER, channelId: viewer?.id});
  if (viewer?.organization?.id) {
    channels.push({channelType: ChannelType.ORGANIZATION, channelId: viewer?.organization?.id});
  }
  return channels;
};

export const getSSEUrl = ({
  channels,
  userId,
  organizationId,
}: {
  channels: SSEChannelSubscriptionRequest[];
  userId: number;
  organizationId: number;
}) => {
  const params = new URLSearchParams();
  params.append('channels', JSON.stringify(channels));
  params.append('userId', userId.toString());
  params.append('organizationId', organizationId.toString());
  const encodedParams = params.toString();
  return `${getSSEServerHost()}/events?${encodedParams}`;
};
