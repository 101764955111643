// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Datetime} from '@supermove/utils';

const edit = withFragment(
  (job) => ({
    jobId: job.id,
    endDate: job.endDate,
  }),
  gql`
    fragment CompleteEstimatedRangeJobForm_edit on Job {
      id
      endDate
    }
  `,
);

const toForm = ({jobId, endDate}) => ({
  jobId,
  endDate: Datetime.toFormDate(endDate),
});

const toMutation = ({jobId, endDate}) => ({
  jobId,
  endDate: Datetime.toMutationDate(endDate),
});

const CompleteEstimatedRangeJobForm = {
  edit,
  toForm,
  toMutation,
};

export default CompleteEstimatedRangeJobForm;
